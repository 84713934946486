import React from "react";

const Pause = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.8962 46.2903C28.0883 48.2092 22.7887 48.5221 17.7884 47.1822L17.7884 47.1821C13.6163 46.0641 9.78598 43.8303 6.73539 40.6711C3.29705 37.1103 1.0682 32.6008 0.299343 27.7786C-0.272529 24.1916 -0.0198501 20.5423 1.01306 17.1007C1.3727 15.9025 1.82692 14.7294 2.37385 13.5929C3.59488 11.0554 5.26427 8.72878 7.3289 6.73513C11.6452 2.5672 17.3556 0.17651 23.3273 0.00925978C23.3339 0.00907462 23.3405 0.00895159 23.3471 0.00889015C25.1214 -0.0394175 26.9057 0.108978 28.6672 0.458201C33.7451 1.4649 38.3614 4.0865 41.8273 7.93181C45.2932 11.7771 47.4229 16.64 47.8985 21.7949C48.3742 26.9497 47.1703 32.1202 44.4666 36.5348C41.7629 40.9494 37.7042 44.3714 32.8962 46.2903ZM4.33971 14.5465C4.4506 14.3159 4.56568 14.087 4.68494 13.8598C6.87216 9.69359 10.3478 6.34625 14.5934 4.31725C18.8389 2.28826 23.6267 1.68638 28.2423 2.60144C32.8579 3.51649 37.054 5.89942 40.2043 9.39465C43.3547 12.8899 45.2905 17.3101 45.7228 21.9956C46.1551 26.6812 45.0609 31.3809 42.6033 35.3936C40.1457 39.4063 36.4566 42.5168 32.0863 44.261C29.0423 45.4759 25.7814 45.9826 22.5467 45.7666C22.5402 45.7661 22.5337 45.7656 22.5272 45.7652C17.1447 45.4011 12.0725 43.0527 8.30716 39.1533C4.28821 34.9913 2.08722 29.4032 2.18839 23.6184C2.20073 22.9129 2.24719 22.2115 2.32681 21.5165C2.60271 19.1089 3.27919 16.752 4.33971 14.5465Z"
          fill="#D1F310"
        />
        <circle cx="24" cy="24" r="17" fill="#1F2024" />
        <rect
          x="25.5103"
          y="17.0107"
          width="3"
          height="14"
          rx="1"
          fill="#D1F310"
        />
        <rect
          x="22.5103"
          y="31.0098"
          width="3"
          height="14"
          rx="1"
          transform="rotate(180 22.5103 31.0098)"
          fill="#D1F310"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="48" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Pause;
