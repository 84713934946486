import React from "react";

const FavoritesAnalytics = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_4537_8221)">
        <circle cx="23" cy="23" r="16" fill="white" fillOpacity="0.06" />
      </g>
      <circle cx="23" cy="23" r="22.5" stroke="#D1F310" />
      <path
        d="M29.7462 16.6022C28.8263 15.682 27.6066 15.1225 26.309 15.0255C25.0114 14.9285 23.7221 15.3004 22.6755 16.0737C21.573 15.2536 20.2007 14.8818 18.8351 15.033C17.4694 15.1843 16.2117 15.8473 15.3153 16.8887C14.419 17.9301 13.9504 19.2724 14.0042 20.6454C14.0579 22.0184 14.6298 23.32 15.6048 24.2882L22.0603 30.7437C22.1408 30.8249 22.2367 30.8894 22.3423 30.9334C22.4478 30.9774 22.5611 31 22.6755 31C22.7899 31 22.9031 30.9774 23.0087 30.9334C23.1143 30.8894 23.2102 30.8249 23.2907 30.7437L29.7462 24.2882C30.2511 23.7836 30.6516 23.1846 30.9249 22.5252C31.1981 21.8658 31.3388 21.159 31.3388 20.4452C31.3388 19.7314 31.1981 19.0247 30.9249 18.3653C30.6516 17.7059 30.2511 17.1068 29.7462 16.6022ZM28.5244 23.0664L22.6755 28.9067L16.8266 23.0664C16.3111 22.5488 15.96 21.8906 15.8173 21.1742C15.6745 20.4579 15.7465 19.7153 16.0241 19.0397C16.3018 18.3641 16.7727 17.7855 17.378 17.3766C17.9832 16.9676 18.6957 16.7465 19.4261 16.7409C20.4019 16.7433 21.3368 17.1328 22.0256 17.824C22.1062 17.9052 22.202 17.9697 22.3076 18.0137C22.4132 18.0577 22.5264 18.0803 22.6408 18.0803C22.7552 18.0803 22.8685 18.0577 22.9741 18.0137C23.0797 17.9697 23.1755 17.9052 23.2561 17.824C23.9651 17.2096 24.8812 16.8874 25.8188 16.9229C26.7564 16.9583 27.6455 17.3486 28.3062 18.0148C28.9669 18.681 29.3499 19.5734 29.3775 20.5112C29.4051 21.4491 29.0754 22.3624 28.4551 23.0664H28.5244Z"
        fill="#D4F70E"
      />
      <defs>
        <filter
          id="filter0_b_4537_8221"
          x="-17"
          y="-17"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4537_8221"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4537_8221"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default FavoritesAnalytics;
