import React from "react";
import { useCallback, useMemo } from "react";
import { GENERAL_ROUTES, AUTH_ROUTES, REGISTER_PAYPAL } from ".";
import { Switch } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { PublicRoute } from "./PublicRoute";
import { RegisterRouter } from "./RegisterRouter";

export const RenderRoutes = () => {
  const loggedRoutes = useMemo(() => {
    return [...GENERAL_ROUTES];
  }, []);

  const renderLoggedRoutes = useCallback(() => {
    return loggedRoutes.map((route) => {
      return <PrivateRoute key={route.path} {...route} />;
    });
  }, [loggedRoutes]);

  const renderAuthRoutes = useCallback(() => {
    return AUTH_ROUTES.map((route) => {
      return <PublicRoute key={route.path} {...route} />;
    });
  }, []);

  const renderRegister = useCallback(() => {
    return REGISTER_PAYPAL.map((route) => {
      return <RegisterRouter key={route.path} {...route} />;
    });
  }, []);

  return (
    <Switch>
      {renderRegister()}
      {renderLoggedRoutes()}
      {renderAuthRoutes()}
    </Switch>
  );
};
