import { Box, Flex, Divider } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonIndividualTrack() {
  return (
    <Flex
      display={{ base: "block", md: "flex" }}
      w="100%"
      flexWrap="nowrap"
      gridGap={{ base: "10px", md: "15px", lg: "20px", xl: "30px" }}
    >
      <Box borderRadius="8px" w={{ base: "100%", md: "35%", xl: "30%" }}>
        <Box
          background="rgba(36, 37, 42, 0.3)"
          padding={{ base: "25px 20px", lg: "25px 35px" }}
        >
          <Box display={{ base: "flex", md: "block" }} alignItems="center">
            <Skeleton
              margin={{ base: "unset", md: "auto" }}
              background="#535353"
              w={{ base: "114px", md: "132px", lg: "210px" }}
              h={{ base: "114px", md: "132px", lg: "210px" }}
              mr="none"
              mb={{ base: "0px", md: "25px" }}
            />
            <Box ml="16px">
              <Skeleton
                mr="none"
                background="#535353"
                w="130px"
                h="20px"
                display={{ base: "flex", md: "none" }}
              />
              <Flex m="8px 0px" display={{ base: "flex", md: "none" }}>
                <Skeleton
                  mr="10px"
                  background="#535353"
                  w="70px"
                  h="20px"
                  ml="0px"
                />
                <Skeleton
                  margin="auto"
                  background="#535353"
                  w="130px"
                  h="20px"
                  ml="0px"
                />
              </Flex>
              <Flex display={{ base: "flex", md: "none" }}>
                {Array.from({ length: 4 }).map(() => (
                  <Skeleton
                    margin="auto"
                    background="#535353"
                    w="50px"
                    h="20px"
                  />
                ))}
              </Flex>
            </Box>
          </Box>

          <Skeleton
            margin="auto"
            mr="none"
            background="#535353"
            w="130px"
            h="20px"
            display={{ base: "none", md: "block" }}
          />
          <Skeleton
            margin="auto"
            mr="none"
            background="#535353"
            w="100px"
            h="20px"
            display={{ base: "none", md: "block" }}
          />

          <Skeleton
            background="#535353"
            h="40px"
            w="100%"
            display={{ base: "block", md: "none" }}
            mt="20px"
          />
          <Flex
            mt="20px"
            justifyContent={{ base: "space-around", md: "space-between" }}
          >
            {Array.from({ length: 4 }).map(() => (
              <Skeleton background="#535353" h="40px" w="40px" />
            ))}
          </Flex>
          <Divider
            m="20px 0px 15px 0px"
            borderColor="rgba(255, 255, 255, 0.04)"
          />
          <Skeleton mb="15px" background="#535353" w="100px" h="20px" />
          <Skeleton mb="15px" background="#535353" w="100%" h="20px" />
          <Skeleton background="#535353" w="100px" h="20px" />
          {Array.from({ length: 2 }).map(() => (
            <Flex mt="10px" justifyContent="space-between">
              <Skeleton background="#535353" w="100px" h="20px" />
              <Skeleton background="#535353" w="100px" h="20px" />
            </Flex>
          ))}
          <Flex mt="15px">
            {Array.from({ length: 4 }).map(() => (
              <Skeleton background="#535353" w="100px" h="20px" />
            ))}
          </Flex>
        </Box>
        <Box
          borderRadius="8px"
          mt="20px"
          background="rgba(36, 37, 42, 0.3)"
          padding={{ base: "25px 20px", lg: "25px 35px" }}
          display={{ base: "none", md: "block" }}
        >
          <Skeleton background="#535353" w="100px" h="20px" />
          {Array.from({ length: 2 }).map(() => (
            <Flex mt="15px" alignItems="center">
              <Skeleton
                background="#535353"
                w="48px"
                h="48px"
                borderRadius="full"
              />
              <Box>
                <Skeleton background="#535353" w="120px" />
                <Skeleton background="#535353" w="120px" />
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
      <Box w={{ base: "100%", md: "65%", xl: "70%" }}>
        <Flex
          background="rgba(36, 37, 42, 0.3)"
          padding="20px 35px"
          borderRadius="8px"
          justifyContent="space-between"
          height="90px"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
        >
          <Skeleton mt="5px" background="#535353" w="90%" h="30px" />
          <Skeleton
            background="#535353"
            w="40px"
            h="40px"
            borderRadius="full"
          />
        </Flex>
        <Box
          background="rgba(36, 37, 42, 0.3)"
          borderRadius="8px"
          padding={{ base: "25px 20px", md: "25px 35px" }}
          mt="20px"
        >
          <Skeleton background="#535353" w="150px" h="25px" />
          <Flex mt="30px" display={{ base: "block", md: "flex" }}>
            {Array.from({ length: 3 }).map(() => (
              <Skeleton
                background="#535353"
                w={{ base: "100%", md: "250px" }}
                h="100px"
                mt={{ base: "10px", md: "0px" }}
              />
            ))}
          </Flex>
          <Skeleton mt="20px" background="#535353" w="150px" h="25px" />
          <Divider
            m="20px 0px 15px 0px"
            borderColor="rgba(255, 255, 255, 0.04)"
          />
          <Flex alignItems="center" justifyContent="space-between">
            <Skeleton mt="20px" background="#535353" w="80px" h="25px" />
            <Skeleton mt="20px" background="#535353" w="150px" h="50px" />
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
}
