import { Box, Image, Text } from "@chakra-ui/react";
import creditCard from "app/assets/images/credit_card.png";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import AmexIconStripe from "app/assets/images/amexIconStripe.svg";
import DinnersIconStripe from "app/assets/images/dinersIconStripe.svg";
import DiscoverIconStripe from "app/assets/images/discoverIconStripe.svg";
import JcbCardconStripe from "app/assets/images/JCBCardIconStripe.svg";
import MasterCardIconStripe from "app/assets/images/MastercardIconStripe.svg";
import UnionPayIconStripe from "app/assets/images/unionpayIconStripe.svg";
import VisaIconStripe from "app/assets/images/visaIconStripe.svg";
import CartesBancairesIconStripe from "app/assets/images/cartesBancairesIconStripe.svg";
import CardIcon from "app/assets/images/cardIcon.svg";

import { cardIconType } from "constants/cartType";

interface CreditCardProps {
  name?: string;
  accountNumber?: number;
  date?: any;
  isPaymentMethod?: boolean;
  brand?: string;
}

export default function CreditCard({
  name,
  accountNumber,
  date,
  isPaymentMethod = false,
  brand,
}: CreditCardProps) {
  const { t } = useTranslation();

  const iconStripe = useCallback((card: string) => {
    const iconCard = {
      [cardIconType.VISA]: <Image width="45px" src={VisaIconStripe} />,
      [cardIconType.AMEX]: <Image width="45px" src={AmexIconStripe} />,
      [cardIconType.CARTES_BANCAIRES]: (
        <Image width="45px" src={CartesBancairesIconStripe} />
      ),
      [cardIconType.DINERS]: <Image width="45px" src={DinnersIconStripe} />,
      [cardIconType.DISCOVER]: <Image width="45px" src={DiscoverIconStripe} />,
      [cardIconType.JCB]: <Image width="45px" src={JcbCardconStripe} />,
      [cardIconType.MASTERCARD]: (
        <Image width="45px" src={MasterCardIconStripe} />
      ),
      [cardIconType.UNIONPAY]: <Image width="45px" src={UnionPayIconStripe} />,
      [cardIconType.DEFAULT]: <Image width="40px" src={CardIcon} />,
    };
    return iconCard[card];
  }, []);

  return (
    <Box
      position="relative"
      backgroundImage={creditCard}
      backgroundRepeat="no-repeat"
      backgroundSize="contain"
      width={"520px"}
      height="320px"
      overflow="hidden"
    >
      {isPaymentMethod ? (
        <Box>
          <Box position="absolute" top="45px" left="45px">
            {iconStripe(brand)}
          </Box>
          <Box position="absolute" top="90px" left="45px">
            {iconStripe(cardIconType.DEFAULT)}
          </Box>
          <Text position="absolute" bottom="90px" left="50px">
            {name}
          </Text>
          <Text position="absolute" bottom="60px" left="50px" color="#535353">
            *****{accountNumber}
          </Text>
          <Text position="absolute" bottom="60px" right="160px">
            {date?.exp_month}/{date?.exp_year}
          </Text>
        </Box>
      ) : (
        <Box>
          <Box position="absolute" top="45px" left="45px">
            {iconStripe(brand)}
          </Box>
          <Box position="absolute" top="90px" left="45px">
            {iconStripe(cardIconType.DEFAULT)}
          </Box>
          <Text position="absolute" bottom="90px" left="50px">
            {t("cardHolderName")}
          </Text>
          <Text position="absolute" bottom="60px" left="50px" color="#535353">
            {t("cardNumber")}
          </Text>
          <Text position="absolute" bottom="60px" right="160px">
            MM/YY
          </Text>
        </Box>
      )}
    </Box>
  );
}
