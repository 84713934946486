import { Box, Text } from "@chakra-ui/react";
import TextInput from "app/components/AddTrackComponents/TextInput";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export default function Description() {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const description = watch("description");

  return (
    <Box>
      <Text fontSize="20px" mb="28px" fontWeight="600">
        {t("fileDescription")}
      </Text>
      <TextInput
        type="textarea"
        name="description"
        placeholder={t("enterDescription")}
        label={t("fileDescription")}
        errors={errors}
        control={control}
      />
      <Text color="#535353">
        {description?.length}
        {t("maximumText")}
      </Text>
    </Box>
  );
}
