import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  CloseButton,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Spinner,
} from "@chakra-ui/react";
import AddNewCard from "app/assets/icons/addIcon";
import IconAddTrackPlaylist from "app/assets/icons/IconAddTrackPlaylist";
import IconAddTrackPlaylistSuccess from "app/assets/icons/IconAddTrackPlaylistSuccess";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { Playlists } from "app/models";
import {
  addTrackToPlaylist,
  prepareEditPlaylist,
  removeTrackToPlaylist,
} from "app/redux/actions/playlist";
import { PlaylistType, searchPlaylists } from "constants/playlistType";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyPlaylist from "./emptyPlaylist";
import "./styles.scss";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { usePlaylists } from "hooks/playlists/usePlaylists";

interface ModalAddToPlaylistProps {
  showModal: boolean;
  setShowModal: () => void;
  trackId: string;
  setShowModalCreatePlaylist: () => void;
  handleSearchText: () => void;
  debouncedSearchText: string;
  privateTrack?: boolean;
}

export default function ModalAddToPlaylist({
  showModal,
  setShowModal,
  trackId,
  setShowModalCreatePlaylist,
  handleSearchText,
  debouncedSearchText,
  privateTrack = false,
}: ModalAddToPlaylistProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedPlaylist, setSelectedPlaylist] = useState("");
  const { playlists, handleGetPlaylists } = usePlaylists();
  const isLoadingAddTrackPlaylist = useSelector((state: RootState) =>
    isLoadingSelector([addTrackToPlaylist.typePrefix], state)
  );
  const filterPlaylists = playlists.filter(
    (item) => item.visibility === PlaylistType.PRIVATE
  );

  const listPlaylists = useMemo(() => {
    if (privateTrack) {
      return filterPlaylists;
    }
    return playlists;
  }, [filterPlaylists, playlists, privateTrack]);

  const isLoadingRemoveTrackPlaylist = useSelector((state: RootState) =>
    isLoadingSelector([removeTrackToPlaylist.typePrefix], state)
  );
  const trackInPlaylist = useCallback(
    (trackIds) => {
      return trackIds?.includes(trackId);
    },
    [trackId]
  );

  useEffect(() => {
    handleGetPlaylists();
  }, [handleGetPlaylists]);

  const handleShowModalCreatePlaylist = useCallback(() => {
    setShowModalCreatePlaylist();
    setShowModal();
    dispatch(
      prepareEditPlaylist({
        visibility: PlaylistType.PUBLIC,
      })
    );
  }, [dispatch, setShowModal, setShowModalCreatePlaylist]);

  const handleAddTrackToPlaylist = useCallback(
    async (id) => {
      setSelectedPlaylist(id);
      await dispatch(addTrackToPlaylist({ playlistId: id, trackId: trackId }));
      setShowModal();
    },
    [dispatch, setShowModal, trackId]
  );
  const handleAddRemoveToPlaylist = useCallback(
    async (id) => {
      setSelectedPlaylist(id);
      await dispatch(
        removeTrackToPlaylist({ playlistId: id, trackId: trackId })
      );
      setShowModal();
    },
    [dispatch, setShowModal, trackId]
  );

  const dataPlaylists = useMemo(() => {
    return searchPlaylists(listPlaylists, debouncedSearchText);
  }, [debouncedSearchText, listPlaylists]);

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={showModal}
      onClose={setShowModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "600px", xl: "750px" }}
        margin={{ base: "0px 10px", md: "0px" }}
        maxH="600px"
      >
        <ModalHeader
          fontSize={{ base: "26px", md: "32px" }}
          fontWeight="500"
          fontFamily="Cera Pro Regular"
        >
          {t("addToPlaylist")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top={{ base: "15px", md: "25px" }}
          fontSize={{ base: "20px", md: "24px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalBody
          maxHeight="650px"
          overflow="scroll"
          padding="10px 24px 40px 24px"
          className="add-track-playlist"
        >
          <Flex alignItems="center" justifyContent="space-between">
            <Text fontSize="16px" fontWeight="500">
              {t("myPlaylists")}
            </Text>
            <Button
              leftIcon={<AddNewCard />}
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              padding="23px 20px"
              onClick={handleShowModalCreatePlaylist}
            >
              {t("createNew")}
            </Button>
          </Flex>
          <InputGroup m="30px 0px">
            <InputLeftElement pointerEvents="none">
              <SearchIcon color="#535353" />
            </InputLeftElement>
            <Input
              type="tel"
              placeholder={t("searchPlaylist")}
              onChange={handleSearchText}
            />
          </InputGroup>
          {(listPlaylists || [])?.length <= 0 ? (
            <EmptyPlaylist height="30vh" />
          ) : (
            (dataPlaylists || []).map((item: Playlists) => (
              <Flex
                mb="15px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Flex>
                  <Image
                    fallbackSrc={ImageDefault}
                    w="58px"
                    height="58px"
                    borderRadius="4px"
                    src={item?.coverUrl}
                  />
                  <Box ml="5px">
                    <Text
                      fontSize={{ base: "16px", md: "20px" }}
                      fontWeight="500"
                      noOfLines={1}
                    >
                      {item.name}
                    </Text>
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight="400"
                      color="#535353"
                    >
                      {item.totalTracks} {t("tracks")}
                    </Text>
                  </Box>
                </Flex>
                <Box cursor="pointer">
                  {trackInPlaylist(item.trackIds) ? (
                    <Box onClick={() => handleAddRemoveToPlaylist(item._id)}>
                      {isLoadingRemoveTrackPlaylist &&
                      selectedPlaylist === item._id ? (
                        <Spinner color="#D4F70E" />
                      ) : (
                        <IconAddTrackPlaylistSuccess />
                      )}
                    </Box>
                  ) : (
                    <Box onClick={() => handleAddTrackToPlaylist(item._id)}>
                      {isLoadingAddTrackPlaylist &&
                      selectedPlaylist === item._id ? (
                        <Spinner color="#D4F70E" />
                      ) : (
                        <IconAddTrackPlaylist />
                      )}
                    </Box>
                  )}
                </Box>
              </Flex>
            ))
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
