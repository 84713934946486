import { Box, Flex, Image, SimpleGrid, Text } from "@chakra-ui/react";
import { useUser } from "hooks/user/useUser";
import React, { useCallback, useEffect } from "react";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import { useDispatch, useSelector } from "react-redux";
import { getArtistsSpotifyOfUser } from "app/redux/actions/users";
import { selectorsArtistsSpotifyOfUser } from "app/redux/selectors/analytics";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { useTranslation } from "react-i18next";
import { RootState } from "types/RootState";
import { isLoadingSelector } from "app/redux/selectors/status";
import SkeletonCreditProfile from "./skeletonCreditProfile";

function MyCreditProfile() {
  const { userById } = useUser();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const artistsSpotify = useSelector(selectorsArtistsSpotifyOfUser);
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getArtistsSpotifyOfUser.typePrefix], state)
  );

  useEffect(() => {
    dispatch(getArtistsSpotifyOfUser(userById?.artistSpotifyList));
  }, [userById?.artistSpotifyList, dispatch]);

  const handleRedirectSpotify = useCallback((link: string) => {
    window.open(link);
  }, []);

  return isLoading ? (
    <SkeletonCreditProfile />
  ) : (
    <Box padding={{ base: "5px 0px", lg: "5px 0px 5px 30px" }}>
      {userById?.trackSpotifyList?.length > 0 ||
      userById?.artistSpotifyList?.length > 0 ? (
        <Box>
          <Box>
            <Text mb="25px" fontWeight={700}>
              {t("artistWhoIWorkWith")}
            </Text>
            {artistsSpotify?.length > 0 ? (
              <SimpleGrid
                columns={{ base: 1, lg: 2, xl: 3 }}
                spacing="10px"
                mb="25px"
              >
                {artistsSpotify?.map((item) => (
                  <Flex
                    alignItems="center"
                    background="#1C1C20"
                    border="1px solid #25262A"
                    borderRadius="4px"
                    padding="6px 16px"
                    _hover={{
                      border: "1px solid #505B18",
                    }}
                    overflow="hidden"
                    mb="8px"
                  >
                    <Image
                      w="36px"
                      height="36px"
                      borderRadius="full"
                      src={item?.images[0]?.url}
                      fallbackSrc={AvatarUser}
                    />
                    <Text
                      onClick={() =>
                        handleRedirectSpotify(item?.external_urls?.spotify)
                      }
                      ml="15px"
                      noOfLines={1}
                      cursor="pointer"
                    >
                      {item?.name}
                    </Text>
                  </Flex>
                ))}
              </SimpleGrid>
            ) : (
              <EmptyTrackSoundkit isHomePage />
            )}
          </Box>
          <Box>
            <Text mb="25px" fontWeight={700}>
              {t("Credits")}
            </Text>
            {userById?.trackSpotifyList?.length > 0 ? (
              <SimpleGrid columns={{ base: 1, lg: 2, xl: 3 }} spacing="10px">
                {userById?.trackSpotifyList?.map((item) => (
                  <iframe
                    src={`https://open.spotify.com/embed/track/${item}`}
                    frameBorder="0"
                    allowtransparency="true"
                    allow="encrypted-media"
                    height="100"
                    width="100%"
                  ></iframe>
                ))}
              </SimpleGrid>
            ) : (
              <EmptyTrackSoundkit isHomePage />
            )}
          </Box>
        </Box>
      ) : (
        <EmptyTrackSoundkit />
      )}
    </Box>
  );
}
export default React.memo(MyCreditProfile);
