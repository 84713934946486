import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import IconAddSoundpackHeader from "app/assets/icons/IconAddSoundpackHeader";
import IconAddTrackHeader from "app/assets/icons/IconAddTrackHeader";
import IconUploadHeader from "app/assets/icons/IconUploadHeader";
import GlobalLoading from "app/components/GlobalLoading";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useHistory } from "react-router-dom";
import { checkUploadTrack, clearForm } from "app/redux/actions/tracks";
import {
  checkUploadSoundPacks,
  clearFormSoundkit,
} from "app/redux/actions/addSoundkit";
import { selectUserAdmin, selectLoggedIn } from "app/redux/selectors/users";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { setSessionStorage } from "helpers/session";
import { BeatLoader } from "react-spinners";

interface UploadHeaderProps {
  setIsPaymentMethods: (value: boolean) => void;
  checkPlanSubscription: boolean;
  setShowModalAddTrack: () => void;
}

const UploadHeader = ({
  checkPlanSubscription,
  setIsPaymentMethods,
  setShowModalAddTrack,
}: UploadHeaderProps) => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector(selectUserAdmin);
  const loggedIn = useSelector(selectLoggedIn);

  const isLoadingCheckUploadTrack = useSelector((state: RootState) =>
    isLoadingSelector([checkUploadTrack.typePrefix], state)
  );
  const isLoadingCheckUploadSoundPack = useSelector((state: RootState) =>
    isLoadingSelector([checkUploadSoundPacks.typePrefix], state)
  );

  const checkFirstLastName = useMemo(() => {
    return !!currentUser.lastName && !!currentUser.firstName;
  }, [currentUser.firstName, currentUser.lastName]);

  const checkConnectPaymentMethods = useMemo(() => {
    return (
      currentUser?.sellerConnectedPayPal || currentUser?.sellerConnectedStripe
    );
  }, [currentUser?.sellerConnectedPayPal, currentUser?.sellerConnectedStripe]);

  const handleAddTrack = useCallback(async () => {
    if (!checkFirstLastName) {
      setShowModalAddTrack();
      return;
    }
    if (!checkConnectPaymentMethods) {
      setShowModalAddTrack();
      setIsPaymentMethods(true);
      return;
    }
    const isUploadTrack = await dispatch(checkUploadTrack());
    if (isUploadTrack.payload) {
      dispatch(clearForm());
      checkPlanSubscription
        ? history.push({ pathname: "/add-track" })
        : history.push({ pathname: "/upload-track-files" });
      return;
    }
    ToastPlanSubscription({
      title: t("uploadTrackLimitReached"),
      description: t("youHaveReachedTrack"),
    });
  }, [
    checkPlanSubscription,
    checkConnectPaymentMethods,
    checkFirstLastName,
    dispatch,
    history,
    setIsPaymentMethods,
    setShowModalAddTrack,
    t,
  ]);

  const handleClickAddSoundKit = useCallback(async () => {
    if (!checkFirstLastName) {
      setShowModalAddTrack();
      return;
    }
    if (!checkConnectPaymentMethods) {
      setShowModalAddTrack();
      setIsPaymentMethods(true);
      return;
    }
    const isUploadSoundkit = await dispatch(checkUploadSoundPacks());
    if (isUploadSoundkit.payload) {
      dispatch(clearFormSoundkit());
      history.push({
        pathname: "/add-soundkit",
      });
      return;
    }
    ToastPlanSubscription({
      title: t("titleLimitUploadSoundPacks"),
      description: t("limitUploadSoundPacks"),
    });
  }, [
    checkConnectPaymentMethods,
    checkFirstLastName,
    dispatch,
    history,
    setIsPaymentMethods,
    setShowModalAddTrack,
    t,
  ]);

  const handleLogoutAddTrack = useCallback((router, callBack) => {
    setSessionStorage("router", router);
    callBack();
  }, []);

  const listMenuProfile = [
    {
      id: 0,
      name: t("addTrack"),
      icon: <IconAddTrackHeader />,
      isLoading: isLoadingCheckUploadTrack,
      onClick: () => handleAddTrack(),
      router: "add-track",
    },
    {
      id: 1,
      name: t("addSoundkit"),
      icon: <IconAddSoundpackHeader />,
      isLoading: isLoadingCheckUploadSoundPack,
      onClick: () => handleClickAddSoundKit(),
      router: "add-soundkit",
    },
  ];

  return (
    <Box className="upload-header" w={{ base: "100%", md: "max-content" }}>
      {isOpen && <GlobalLoading />}
      <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Flex className="button-upload">
                <Flex
                  alignItems="center"
                  padding={{ base: "7px 30px", md: "11px 30px" }}
                  cursor="pointer"
                  position="relative"
                  background="linear-gradient(to right, #36204d, #38244b, #402e49, #483b43, #524a3c)"
                  borderRadius="4px 0px 4px 4px"
                  w="100%"
                  justifyContent="center"
                >
                  <Box position="absolute" top="-13px" left="-18px">
                    <IconUploadHeader />
                  </Box>
                  <Text ml="5px" fontWeight="500" fontSize="12px" color="white">
                    {t("upload")}
                  </Text>
                </Flex>
              </Flex>
            </PopoverTrigger>

            <PopoverContent
              background="#24252A"
              borderColor="#24252A"
              _focus={{ boxShadow: "none" }}
            >
              <PopoverArrow />
              <PopoverBody overflow="auto" padding="12px 17px">
                {listMenuProfile.map((item) =>
                  item.isLoading ? (
                    <Flex justifyContent="center">
                      <BeatLoader size={10} color="white" />
                    </Flex>
                  ) : (
                    <Flex
                      alignItems="center"
                      key={item.id}
                      _hover={{
                        background:
                          "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                        borderLeft: "1px solid #D4F70E",
                        borderRadius: "0px",
                      }}
                      padding="6px 5px"
                      onClick={() => {
                        loggedIn
                          ? item.onClick()
                          : handleLogoutAddTrack(item.router, onClose);
                      }}
                      cursor="pointer"
                      background={"#1F1F23"}
                      mb="5px"
                    >
                      {item.icon}
                      <Text
                        className="fontCeraProRegular"
                        ml="15px"
                        fontSize="14px"
                        fontWeight="400"
                        color={"white"}
                      >
                        {item.name}
                      </Text>
                    </Flex>
                  )
                )}
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default UploadHeader;
