import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonItemTablePurchased() {
  return (
    <Box display={{ base: "none", md: "block" }}>
      <Flex w="100%" mt="20px" alignItems="center" gap="15px">
        <Flex w="8%" alignItems="center" gap="15px" />
        <Box w={{ md: "20%", xl: "21%" }}>
          <Skeleton mr="0px" w="95px" h="16px" />
        </Box>
        <Flex w={{ md: "18%", xl: "19%" }}>
          <Skeleton mr="0px" w="95px" h="16px" />
        </Flex>
        <Box w={{ md: "10%", xl: "8%" }}>
          <Skeleton mr="0px" w={{ md: "85px", xl: "95px" }} h="16px" />
        </Box>
        <Box w={{ md: "11%", xl: "10%" }}>
          <Skeleton mr="0px" w={{ md: "85px", xl: "95px" }} h="16px" />
        </Box>
        <Flex w="12%">
          <Skeleton mr="0px" w={{ md: "85px", xl: "95px" }} h="16px" />
        </Flex>
        <Flex w="10%">
          <Skeleton mr="0px" w={{ md: "85px", xl: "95px" }} h="16px" />
        </Flex>
        <Flex w="12%" gap="10px" justifyContent="center">
          <Skeleton mr="0px" w="95px" h="16px" />
        </Flex>
      </Flex>
    </Box>
  );
}
