import {
  Box,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import algoliasearch from "algoliasearch";
import { Edit } from "app/assets/icons";
import PlayIcon from "app/assets/icons/PlayIcon";
import EditUser from "app/assets/icons/editUser";
import House from "app/assets/icons/house";
import UserIcon from "app/assets/icons/user";
import AvatarUser from "app/assets/images/UserAvatar.png";
import ButtonItem from "app/components/ButtonItem";
import NotFoundPage from "app/components/NotFoundPage/notfoundPage";
import { getConversation } from "app/redux/actions/conversations";
import { getFavoritesSK, getRepostsSK } from "app/redux/actions/mySoundKit";
import { fetchFavorites, fetchReposts } from "app/redux/actions/myTrack";
import { followUnfollowUser, getUserByUsername } from "app/redux/actions/users";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { useProfile } from "hooks/profile/useProfile";
import { useUser } from "hooks/user/useUser";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { InstantSearch } from "react-instantsearch";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { formatNumber } from "utils/convertTracks";
import { checkHadData } from "utils/errorMessage";
import FilterTrendingBeats from "../Home/filterTrendingBeats";
import ModalEditAvatarCrop from "./modalEditAvatarCrop";
import RenderBulkOffersUser from "./renderBulkOffersUser";
import RenderCountryUser from "./renderCountryUser";
import RenderDescriptionUser from "./renderDescriptionUser";
import RenderFollowerUser from "./renderFollowerUser";
import SkeletonLeftProfilePage from "./skeletonLeftProfilePage";
import "./styles.scss";
import TabListProfile from "./tabListProfile";
import MusicIcon from "app/assets/icons/music";
import IconSPVerified from "app/assets/icons/IconSPVerified";
import IconFireHotProducer from "app/assets/icons/IconFireHotProducer";
import IconPaymentsActive from "app/assets/icons/IconPaymentsActive";
import { getDawValue } from "constants/dawTypeProfile";

const TrackSoundpacksInstantSearch = React.memo(
  ({
    profileImage,
    setShowModalEditAvatar,
    progress,
    userById,
    showingMore,
    setShowingMore,
    typeOfUser,
    tabActive,
    handleGetListFollowers,
    userAdmin,
    handleEditProfile,
    onLogoutGuest,
    setTabActive,
    callApiPlaylists,
    handleGetListBulkOffers,
    handleFollowPress,
    handleSendMessage,
    isLoading,
  }: any) => {
    const { t } = useTranslation();
    const searchClient = useMemo(
      () =>
        algoliasearch(
          process.env.REACT_APP_APPLICATION_ID_ALGOLIA as string,
          process.env.REACT_APP_API_KEY_ALGOLIA as string
        ),
      []
    );

    const indexName = useMemo(() => {
      if (tabActive === 1) {
        return process.env.REACT_APP_KEY_TRACKS_SEARCH;
      }
      if (tabActive === 2) {
        return process.env.REACT_APP_KEY_SOUNDPACKS_SEARCH;
      }
    }, [tabActive]);

    const userStatsItems = [
      {
        icon: <UserIcon />,
        amount: userById?.followers,
        title: t("Followers"),
        action: () => handleGetListFollowers(userById._id),
      },
      {
        icon: <PlayIcon />,
        amount: userById?.plays && formatNumber(Number(userById?.plays || 0)),
        title: t("Plays"),
      },
      {
        icon: <MusicIcon />,
        amount: userById?.tracks,
        title: t("Tracks"),
      },
    ];
    return (
      <InstantSearch
        key={indexName as string}
        searchClient={searchClient}
        indexName={indexName as string}
        future={{ preserveSharedStateOnUnmount: true }}
      >
        {!callApiPlaylists ? (
          <Box w={{ base: "100%", md: "40%", lg: "30%", xl: "27%" }} mt="10px">
            {userAdmin._id === userById._id ? (
              <SkeletonLeftProfilePage isFilter isUser />
            ) : (
              <SkeletonLeftProfilePage isFilter />
            )}
          </Box>
        ) : (
          <Box
            w={{ base: "100%", md: "40%", lg: "30%", xl: "27%" }}
            mt="10px"
            height={{ md: "120vh" }}
            overflow="scroll"
          >
            <Box
              bg={{ base: "none", md: "rgba(36, 37, 42, 0.3)" }}
              my={{ base: "0px", md: "10px" }}
              px={{ base: "16px", md: "20px" }}
              pt={{ base: "16px", md: "0px" }}
              pb={{ base: "0px" }}
            >
              <Flex
                direction={{ base: "row", md: "column" }}
                position="relative"
                bg={{
                  md: "linear-gradient(180deg, rgba(255, 255, 255, 0.125) 0%, rgba(255, 255, 255, 0) 100%)",
                }}
                mx={{ md: "-20px" }}
                borderRadius="8px"
                gap={{ base: "20px", md: "0px" }}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  pt={{ md: "24px", xl: "40px" }}
                  w={{ base: "90px", md: "100px", xl: "120px" }}
                  position="relative"
                  margin={{ md: "auto" }}
                  zIndex="2"
                >
                  <Image
                    w={{ base: "90px", md: "100px", xl: "120px" }}
                    h={{ base: "90px", md: "100px", xl: "120px" }}
                    borderRadius="50%"
                    src={profileImage}
                    fallbackSrc={AvatarUser}
                  />
                  {userAdmin._id === userById._id && (
                    <Box
                      cursor="pointer"
                      right="-8px"
                      bottom={{ base: "24px", md: "10px" }}
                      position="relative"
                    >
                      <Box
                        onClick={setShowModalEditAvatar}
                        cursor="pointer"
                        className="upload-avatar"
                      >
                        <EditUser />
                      </Box>
                      {progress > 0 && (
                        <Text className="progress">
                          {progress}% {t("uploaded")}
                        </Text>
                      )}
                    </Box>
                  )}
                </Box>
                <Box>
                  <Text
                    pt={{ md: "14px" }}
                    fontSize={{ base: "24px", md: "28px", xl: "32px" }}
                    lineHeight={{ md: "38px" }}
                    fontWeight="700"
                    fontFamily="Cera Pro Bold"
                    textAlign={{ base: "start", md: "center" }}
                  >
                    {userById?.name}
                  </Text>
                  <RenderCountryUser
                    userById={userById}
                    typeOfUser={typeOfUser}
                  />
                  <RenderDescriptionUser
                    userById={userById}
                    showingMore={showingMore}
                    setShowingMore={setShowingMore}
                  />
                </Box>
              </Flex>
              <Flex
                pt={{ base: "24px", md: "28px", xl: "32px" }}
                alignItems="center"
                gap="30px"
                w="max-content"
                margin="auto"
              >
                {userStatsItems.map((i, k) => (
                  <Box key={k}>
                    <Flex alignItems="center" gap={{ base: "8px", xl: "10px" }}>
                      {i.icon}
                      <Text
                        fontSize={{ base: "18px", md: "20px", xl: "22px" }}
                        fontWeight="bold"
                        color="#F1F2F3"
                      >
                        {i.amount}
                      </Text>
                    </Flex>
                    <Text
                      cursor="pointer"
                      onClick={i.action}
                      fontSize="14px"
                      color=" #535353"
                      lineHeight="18px"
                      className="fontCeraPro"
                      pl={{ base: "23px", md: "0px" }}
                    >
                      {i.title}
                    </Text>
                  </Box>
                ))}
              </Flex>
              <Flex
                py={{ base: "24px", md: "30px" }}
                justifyContent="space-between"
              >
                {userAdmin._id === userById._id ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    w="100%"
                    flexWrap="wrap"
                  >
                    <ButtonItem
                      icon={<Edit />}
                      text={t("MyProfile")}
                      onClick={handleEditProfile}
                      minWidth="165px"
                    />
                  </Box>
                ) : (
                  <Box w="100%" justifyContent="center">
                    <Box
                      mb="10px"
                      display="flex"
                      justifyContent="center"
                      w="100%"
                      flexWrap="wrap"
                    >
                      <ButtonItem
                        minWidth="120px"
                        text={
                          userById?.isFollowing ? t("unfollow") : t("follow")
                        }
                        onClick={() => onLogoutGuest(handleFollowPress)}
                        backgroundColor={"#363B1E"}
                        color={"#D4F70E"}
                        border={"1px solid rgba(212, 247, 14, 0.1)"}
                      />
                      <ButtonItem
                        minWidth="120px"
                        backgroundColor={"#232326"}
                        color={"#D4F70E"}
                        border={"1px solid #5D5E5D"}
                        text={t("message")}
                        onClick={() => onLogoutGuest(handleSendMessage)}
                      />
                    </Box>
                    <Box m="auto" display="flex" justifyContent="center">
                      <ButtonItem
                        minWidth="242px"
                        onClick={() => onLogoutGuest(handleGetListBulkOffers)}
                        icon={<House bulkOffers />}
                        text={t("buyInBulkDeals")}
                        border={"1px solid #2D2D2D"}
                      />
                    </Box>
                  </Box>
                )}
              </Flex>
            </Box>
            <Box
              background={{ base: "none", md: "rgba(36, 37, 42, 0.3)" }}
              padding={{ base: "16px", md: "20px 5px 20px 20px" }}
              mb="10px"
              display={{ base: "none", lg: "block" }}
            >
              <Box mb={userById?.expertises?.length > 0 ? "25px" : "0px"}>
                {userById?.expertises?.length > 0 && (
                  <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                    {t("expertise")}
                  </Text>
                )}
                <Flex gap="5px" flexWrap="wrap">
                  {userById?.expertises?.slice(0, 3)?.map((item) => (
                    <Flex
                      background="#222226"
                      border="1px solid #535353"
                      padding="2px 8px"
                      borderRadius="4px"
                      alignItems="center"
                      key={item}
                    >
                      <Text ml="5px" fontSize="12px" color="#D4F70E">
                        {item}
                      </Text>
                    </Flex>
                  ))}
                  {userById?.expertises?.slice(3)?.length > 0 && (
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Flex
                          background="#222226"
                          border="1px solid #535353"
                          padding="2px 4px"
                          borderRadius="4px"
                          alignItems="center"
                          cursor="pointer"
                        >
                          <Text fontSize="12px" color="#D4F70E">
                            {userById?.expertises?.slice(3)?.length} +
                          </Text>
                        </Flex>
                      </PopoverTrigger>
                      <PopoverContent
                        backgroundColor="#25252A"
                        border="none"
                        mt="5px"
                        maxW="125px"
                      >
                        <PopoverBody>
                          {userById?.expertises?.slice(3)?.map((item) => (
                            <Flex
                              background="#222226"
                              border="1px solid #535353"
                              padding="2px 8px"
                              borderRadius="4px"
                              alignItems="center"
                              alignSelf="center"
                              mb="8px"
                            >
                              <Text ml="5px" fontSize="12px" color="#D4F70E">
                                {item}
                              </Text>
                            </Flex>
                          ))}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </Flex>
              </Box>

              <Box mb={userById?.daws?.length ? "25px" : "0px"}>
                {userById?.daws?.length > 0 && (
                  <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                    {t("daw")}
                  </Text>
                )}
                <Flex gap="5px" flexWrap="wrap">
                  {userById?.daws?.slice(0, 3)?.map((item) => (
                    <Flex
                      background="#222226"
                      border="1px solid #535353"
                      padding="2px 8px"
                      borderRadius="4px"
                      alignItems="center"
                      maxW="98px"
                      w="max-content"
                      key={item}
                    >
                      {getDawValue(item)?.icon}
                      <Text
                        noOfLines={1}
                        ml="5px"
                        fontSize="12px"
                        color="#D4F70E"
                      >
                        {getDawValue(item)?.name}
                      </Text>
                    </Flex>
                  ))}
                  {userById?.daws?.slice(3)?.length > 0 && (
                    <Popover trigger="hover">
                      <PopoverTrigger>
                        <Flex
                          background="#222226"
                          border="1px solid #535353"
                          padding="2px 4px"
                          borderRadius="4px"
                          alignItems="center"
                          cursor="pointer"
                          w="max-content"
                        >
                          <Text fontSize="12px" color="#D4F70E">
                            {userById?.daws?.slice(3)?.length} +
                          </Text>
                        </Flex>
                      </PopoverTrigger>
                      <PopoverContent
                        backgroundColor="#25252A"
                        border="none"
                        mt="5px"
                        maxW="125px"
                      >
                        <PopoverBody>
                          {userById?.daws?.slice(3)?.map((item) => (
                            <Flex
                              background="#222226"
                              border="1px solid #535353"
                              padding="2px 8px"
                              borderRadius="4px"
                              alignItems="center"
                              alignSelf="center"
                              mb="8px"
                              key={item}
                            >
                              {getDawValue(item)?.icon}
                              <Text ml="5px" fontSize="12px" color="#D4F70E">
                                {getDawValue(item)?.name}
                              </Text>
                            </Flex>
                          ))}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </Flex>
              </Box>

              <Box>
                <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                  {t("badges")}
                </Text>
                <Flex gap="5px" flexWrap="wrap">
                  {userById?.verifiedEmail && (
                    <Flex
                      background="#222226"
                      border="1px solid #535353"
                      padding="2px 8px"
                      borderRadius="4px"
                      alignItems="center"
                    >
                      <IconSPVerified />
                      <Text ml="5px" fontSize="12px" color="#D4F70E">
                        {t("spVerified")}
                      </Text>
                    </Flex>
                  )}
                  {userById?.hotProducer && (
                    <Flex
                      background="#222226"
                      border="1px solid #535353"
                      padding="2px 8px"
                      borderRadius="4px"
                      alignItems="center"
                    >
                      <IconFireHotProducer />
                      <Text ml="5px" fontSize="12px" color="#D4F70E">
                        {t("hotProducer")}
                      </Text>
                    </Flex>
                  )}
                  {userById?.sellerConnectedPayPal &&
                    userById?.sellerConnectedStripe && (
                      <Flex
                        background="#222226"
                        border="1px solid #535353"
                        padding="2px 8px"
                        borderRadius="4px"
                        alignItems="center"
                      >
                        <IconPaymentsActive />
                        <Text ml="5px" fontSize="12px" color="#D4F70E">
                          {t("paymentsActive")}
                        </Text>
                      </Flex>
                    )}
                </Flex>
              </Box>
            </Box>

            {(tabActive === 1 || tabActive === 2) && (
              <Box mb="20px" display={{ base: "none", md: "block" }}>
                <FilterTrendingBeats tabActive={tabActive} />
              </Box>
            )}
          </Box>
        )}
        <TabListProfile
          userById={userById}
          tabActive={tabActive}
          setTabActive={setTabActive}
          isLoading={isLoading}
        />
      </InstantSearch>
    );
  }
);

function Profile() {
  const { t } = useTranslation();
  const {
    progress,
    profileImage,
    handleEditProfile,
    typeOfUser,
    isShowModal,
    handleGetListBulkOffers,
    handleCloseModal,
    userBulkOffers = [],
    isFollowers,
    userFollowers,
    onSearchUserFollowers,
    handleGetListFollowers,
    handleClickUser,
    showModalEditAvatar,
    setShowModalEditAvatar,
    setPreviewAvatar,
    previewAvatar,
    handleUpload,
  } = useProfile();
  const { userById, isLoading, isError } = useUser();
  const userAdmin = useSelector(selectUserAdmin);
  const loggedIn = useSelector(selectLoggedIn);

  const checkUserName = checkHadData(userById);

  const [tabActive, setTabActive] = useState(1);
  const { username } = useParams<{ username: string }>();
  const [showingMore, setShowingMore] = useState(false);
  const [callApiPlaylists, setCallApiPlaylists] = useState(false);
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    setCallApiPlaylists(false);
    const callApi = async () => {
      const res = await dispatch(
        getUserByUsername(username, loggedIn ? false : true)
      );
      if (res) {
        setCallApiPlaylists(true);
      }
    };
    callApi();
  }, [dispatch, loggedIn, username]);

  useEffect(() => {
    if (loggedIn) {
      dispatch(fetchReposts());
      dispatch(getRepostsSK());
      dispatch(fetchFavorites());
      dispatch(getFavoritesSK());
    }
  }, [dispatch, loggedIn]);

  const handleFollowPress = useCallback(
    () => dispatch(followUnfollowUser(userById?._id)),
    [dispatch, userById?._id]
  );

  const handleSendMessage = useCallback(() => {
    dispatch(getConversation(userById?._id))
      .then((conversationId: { payload: string }) => {
        history.push(`/messages?tab=all&room=${conversationId?.payload}`);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, [dispatch, history, userById?._id]);

  const onLogoutGuest = useCallback(
    (callback) => {
      if (!loggedIn) {
        return null;
      } else {
        callback();
      }
    },
    [loggedIn]
  );

  const userStatsItemsSecondary = [
    {
      icon: <UserIcon />,
      amount: userById?.followers,
      title: t("Followers"),
      action: () => handleGetListFollowers(userById._id),
    },
    {
      icon: <PlayIcon />,
      amount: userById?.plays && formatNumber(Number(userById?.plays || 0)),
      title: t("Plays"),
    },
    {
      icon: <MusicIcon />,
      amount: userById?.tracks,
      title: t("Tracks"),
    },
  ];

  return (
    <>
      {!isLoading && checkUserName && isError ? (
        <NotFoundPage marginTop={"250px"} />
      ) : (
        <Flex
          className="profile-page"
          gridGap="20px"
          w="100%"
          padding={{ md: "20px", xl: "40px 20px 20px 80px" }}
          direction={{ base: "column", md: "row" }}
        >
          {tabActive === 1 || tabActive === 2 ? (
            <TrackSoundpacksInstantSearch
              profileImage={profileImage}
              setShowModalEditAvatar={setShowModalEditAvatar}
              progress={progress}
              userById={userById}
              showingMore={showingMore}
              setShowingMore={setShowingMore}
              typeOfUser={typeOfUser}
              tabActive={tabActive}
              handleGetListFollowers={handleGetListFollowers}
              userAdmin={userAdmin}
              handleEditProfile={handleEditProfile}
              onLogoutGuest={onLogoutGuest}
              setTabActive={setTabActive}
              callApiPlaylists={callApiPlaylists}
              handleGetListBulkOffers={handleGetListBulkOffers}
              handleFollowPress={handleFollowPress}
              handleSendMessage={handleSendMessage}
              isLoading={isLoading}
            />
          ) : (
            <>
              {!callApiPlaylists ? (
                <Box w={{ base: "100%", md: "40%", xl: "27%" }} mt="10px">
                  {userAdmin._id === userById._id ? (
                    <SkeletonLeftProfilePage isFilter isUser />
                  ) : (
                    <SkeletonLeftProfilePage isFilter />
                  )}
                </Box>
              ) : (
                <Box w={{ base: "100%", md: "40%", xl: "27%" }} mt="10px">
                  <Box
                    bg="rgba(36, 37, 42, 0.3)"
                    my={{ base: "0px", md: "10px" }}
                    px={{ base: "16px", md: "20px" }}
                    pt={{ base: "16px", md: "0px" }}
                    pb={{ base: "0px" }}
                  >
                    <Flex
                      direction={{ base: "row", md: "column" }}
                      position="relative"
                      bg={{
                        md: "linear-gradient(180deg, rgba(255, 255, 255, 0.125) 0%, rgba(255, 255, 255, 0) 100%)",
                      }}
                      mx={{ md: "-20px" }}
                      borderRadius="8px"
                      gap={{ base: "20px", md: "0px" }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        pt={{ md: "24px", xl: "40px" }}
                        w={{ base: "90px", md: "100px", xl: "120px" }}
                        position="relative"
                        margin={{ md: "auto" }}
                        zIndex="2"
                      >
                        <Image
                          w={{ base: "90px", md: "100px", xl: "120px" }}
                          h={{ base: "90px", md: "100px", xl: "120px" }}
                          borderRadius="50%"
                          src={profileImage}
                          fallbackSrc={AvatarUser}
                        />
                        {userAdmin._id === userById._id && (
                          <Box
                            cursor="pointer"
                            right="-8px"
                            bottom={{ base: "24px", md: "10px" }}
                            position="relative"
                          >
                            <Box
                              onClick={setShowModalEditAvatar}
                              cursor="pointer"
                              className="upload-avatar"
                            >
                              <EditUser />
                            </Box>
                            {progress > 0 && (
                              <Text className="progress">
                                {progress}% {t("uploaded")}
                              </Text>
                            )}
                          </Box>
                        )}
                      </Box>
                      <Box>
                        <Text
                          pt={{ md: "14px" }}
                          fontSize={{ base: "24px", md: "28px", xl: "32px" }}
                          lineHeight={{ md: "38px" }}
                          fontWeight="700"
                          fontFamily="Cera Pro Bold"
                          textAlign={{ base: "start", md: "center" }}
                        >
                          {userById?.name}
                        </Text>
                        <RenderCountryUser
                          userById={userById}
                          typeOfUser={typeOfUser}
                        />
                        <RenderDescriptionUser
                          userById={userById}
                          showingMore={showingMore}
                          setShowingMore={setShowingMore}
                        />
                      </Box>
                    </Flex>
                    <Flex
                      pt={{ base: "24px", md: "28px", xl: "32px" }}
                      alignItems="center"
                      gap="30px"
                      w="max-content"
                      margin="auto"
                    >
                      {userStatsItemsSecondary.map((i, k) => (
                        <Box key={k}>
                          <Flex
                            alignItems="center"
                            gap={{ base: "8px", xl: "10px" }}
                          >
                            {i.icon}
                            <Text
                              fontSize={{
                                base: "18px",
                                md: "20px",
                                xl: "22px",
                              }}
                              fontWeight="bold"
                              color="#F1F2F3"
                            >
                              {i.amount}
                            </Text>
                          </Flex>
                          <Text
                            cursor="pointer"
                            onClick={i.action}
                            fontSize="14px"
                            color=" #535353"
                            lineHeight="18px"
                            className="fontCeraPro"
                            pl={{ base: "23px", md: "0px" }}
                          >
                            {i.title}
                          </Text>
                        </Box>
                      ))}
                    </Flex>
                    <Flex
                      py={{ base: "24px", md: "30px" }}
                      justifyContent="space-between"
                    >
                      {userAdmin._id === userById._id ? (
                        <Box
                          display="flex"
                          justifyContent="center"
                          w="100%"
                          flexWrap="wrap"
                        >
                          <ButtonItem
                            icon={<Edit />}
                            text={t("MyProfile")}
                            onClick={handleEditProfile}
                            minWidth="165px"
                          />
                        </Box>
                      ) : (
                        <Box w="100%" justifyContent="center">
                          <Box
                            mb="10px"
                            display="flex"
                            justifyContent="center"
                            w="100%"
                            flexWrap="wrap"
                          >
                            <ButtonItem
                              minWidth="120px"
                              text={
                                userById?.isFollowing
                                  ? t("unfollow")
                                  : t("follow")
                              }
                              onClick={() => onLogoutGuest(handleFollowPress)}
                              backgroundColor={"#363B1E"}
                              color={"#D4F70E"}
                              border={"1px solid rgba(212, 247, 14, 0.1)"}
                            />
                            <ButtonItem
                              minWidth="120px"
                              text={t("message")}
                              backgroundColor={"#232326"}
                              color={"#D4F70E"}
                              border={"1px solid #5D5E5D"}
                              onClick={() => onLogoutGuest(handleSendMessage)}
                            />
                          </Box>
                          <Box m="auto" display="flex" justifyContent="center">
                            <ButtonItem
                              minWidth="242px"
                              onClick={() =>
                                onLogoutGuest(handleGetListBulkOffers)
                              }
                              icon={<House bulkOffers />}
                              text={t("buyInBulkDeals")}
                            />
                          </Box>
                        </Box>
                      )}
                    </Flex>
                  </Box>
                  <Box
                    background={{ base: "none", md: "rgba(36, 37, 42, 0.3)" }}
                    padding={{ base: "16px", md: "20px 5px 20px 20px" }}
                    mb="10px"
                    display={{ base: "none", lg: "block" }}
                  >
                    <Box mb={userById?.expertises?.length > 0 ? "25px" : "0px"}>
                      {userById?.expertises?.length > 0 && (
                        <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                          {t("expertise")}
                        </Text>
                      )}
                      <Flex gap="5px" flexWrap="wrap">
                        {userById?.expertises?.slice(0, 3)?.map((item) => (
                          <Flex
                            background="#222226"
                            border="1px solid #535353"
                            padding="2px 8px"
                            borderRadius="4px"
                            alignItems="center"
                            key={item}
                          >
                            <Text ml="5px" fontSize="12px" color="#D4F70E">
                              {item}
                            </Text>
                          </Flex>
                        ))}
                        {userById?.expertises?.slice(3)?.length > 0 && (
                          <Popover trigger="hover">
                            <PopoverTrigger>
                              <Flex
                                background="#222226"
                                border="1px solid #535353"
                                padding="2px 4px"
                                borderRadius="4px"
                                alignItems="center"
                                cursor="pointer"
                              >
                                <Text fontSize="12px" color="#D4F70E">
                                  {userById?.expertises?.slice(3)?.length} +
                                </Text>
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent
                              backgroundColor="#25252A"
                              border="none"
                              mt="5px"
                              maxW="125px"
                            >
                              <PopoverBody>
                                {userById?.expertises?.slice(3)?.map((item) => (
                                  <Flex
                                    background="#222226"
                                    border="1px solid #535353"
                                    padding="2px 8px"
                                    borderRadius="4px"
                                    alignItems="center"
                                    alignSelf="center"
                                    mb="8px"
                                  >
                                    <Text
                                      ml="5px"
                                      fontSize="12px"
                                      color="#D4F70E"
                                    >
                                      {item}
                                    </Text>
                                  </Flex>
                                ))}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )}
                      </Flex>
                    </Box>

                    <Box mb={userById?.daws?.length ? "25px" : "0px"}>
                      {userById?.daws?.length > 0 && (
                        <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                          {t("daw")}
                        </Text>
                      )}
                      <Flex gap="5px" flexWrap="wrap">
                        {userById?.daws?.slice(0, 3)?.map((item) => (
                          <Flex
                            background="#222226"
                            border="1px solid #535353"
                            padding="2px 8px"
                            borderRadius="4px"
                            alignItems="center"
                            maxW="98px"
                            w="max-content"
                            key={item}
                          >
                            {getDawValue(item)?.icon}
                            <Text
                              noOfLines={1}
                              ml="5px"
                              fontSize="12px"
                              color="#D4F70E"
                            >
                              {getDawValue(item)?.name}
                            </Text>
                          </Flex>
                        ))}
                        {userById?.daws?.slice(3)?.length > 0 && (
                          <Popover trigger="hover">
                            <PopoverTrigger>
                              <Flex
                                background="#222226"
                                border="1px solid #535353"
                                padding="2px 4px"
                                borderRadius="4px"
                                alignItems="center"
                                cursor="pointer"
                                w="max-content"
                              >
                                <Text fontSize="12px" color="#D4F70E">
                                  {userById?.daws?.slice(3)?.length} +
                                </Text>
                              </Flex>
                            </PopoverTrigger>
                            <PopoverContent
                              backgroundColor="#25252A"
                              border="none"
                              mt="5px"
                              maxW="125px"
                            >
                              <PopoverBody>
                                {userById?.daws?.slice(3)?.map((item) => (
                                  <Flex
                                    background="#222226"
                                    border="1px solid #535353"
                                    padding="2px 8px"
                                    borderRadius="4px"
                                    alignItems="center"
                                    alignSelf="center"
                                    mb="8px"
                                    key={item}
                                  >
                                    {getDawValue(item)?.icon}
                                    <Text
                                      ml="5px"
                                      fontSize="12px"
                                      color="#D4F70E"
                                    >
                                      {getDawValue(item)?.name}
                                    </Text>
                                  </Flex>
                                ))}
                              </PopoverBody>
                            </PopoverContent>
                          </Popover>
                        )}
                      </Flex>
                    </Box>
                    <Box>
                      <Text mb={{ base: "10px", md: "10px" }} fontSize="14px">
                        {t("badges")}
                      </Text>
                      <Flex gap="5px" flexWrap="wrap">
                        {userById?.verifiedEmail && (
                          <Flex
                            alignItems="center"
                            background="#222226"
                            border="1px solid #535353"
                            padding="2px 8px"
                            borderRadius="4px"
                          >
                            <IconSPVerified />
                            <Text ml="5px" fontSize="12px" color="#D4F70E">
                              {t("spVerified")}
                            </Text>
                          </Flex>
                        )}
                        {userById?.hotProducer && (
                          <Flex
                            background="#222226"
                            border="1px solid #535353"
                            padding="2px 8px"
                            borderRadius="4px"
                            alignItems="center"
                          >
                            <IconFireHotProducer />
                            <Text ml="5px" fontSize="12px" color="#D4F70E">
                              {t("hotProducer")}
                            </Text>
                          </Flex>
                        )}
                        {userById?.sellerConnectedPayPal &&
                          userById?.sellerConnectedStripe && (
                            <Flex
                              background="#222226"
                              border="1px solid #535353"
                              padding="2px 8px"
                              borderRadius="4px"
                              alignItems="center"
                            >
                              <IconPaymentsActive />
                              <Text ml="5px" fontSize="12px" color="#D4F70E">
                                {t("paymentsActive")}
                              </Text>
                            </Flex>
                          )}
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              )}

              <TabListProfile
                userById={userById}
                tabActive={tabActive}
                setTabActive={setTabActive}
              />
            </>
          )}
        </Flex>
      )}
      {isShowModal && (
        <Box>
          <Modal
            colorScheme="black"
            isOpen={isShowModal}
            onClose={handleCloseModal}
          >
            <ModalOverlay />
            <ModalContent p="40px" pt="0px" pl="0px">
              <ModalHeader
                pb="6px"
                fontWeight="400px"
                fontSize="22px"
                color="#FFFFFF"
                pl="40px"
              >
                {!isFollowers ? t("bulkOfferDetails") : t("Followers")}
              </ModalHeader>
              <CloseButton
                _focus={{
                  borderWidth: "0px",
                }}
                onClick={handleCloseModal}
                right={3}
                top="15px"
                fontSize="20px"
                position="absolute"
                borderColor="#38383D"
                _hover={{
                  border: "1px solid #38383D !important",
                  padding: "14px",
                }}
                padding="14px"
                color="#d4f70e"
              />
              <ModalBody p="0px" mt="20px">
                {!isFollowers ? (
                  <RenderBulkOffersUser
                    typeOfUser={typeOfUser}
                    userBulkOffers={userBulkOffers}
                    userById={userById}
                  />
                ) : (
                  <RenderFollowerUser
                    onSearchUserFollowers={onSearchUserFollowers}
                    handleClickUser={handleClickUser}
                    userFollowers={userFollowers}
                  />
                )}
              </ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
      <ModalEditAvatarCrop
        setShowModal={setShowModalEditAvatar}
        showModal={showModalEditAvatar}
        previewAvatar={previewAvatar}
        setPreviewAvatar={setPreviewAvatar}
        handleUpload={handleUpload}
      />
    </>
  );
}

export default React.memo(Profile);
