import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "lottie-react";
import trackDraftRelease from "../../assets/LottieIAnimations/trackLiveDraft.json";
import trackEdit from "../../assets/LottieIAnimations/trackEdit.json";
import trackReleaseJson from "../../assets/LottieIAnimations/trackRelease.json";
import trackDraftJson from "../../assets/LottieIAnimations/trackDraft.json";
import trackPrivateJson from "../../assets/LottieIAnimations/trackPrivate.json";
import trackScheduledJson from "../../assets/LottieIAnimations/trackScheduled.json";
import soundkitReleaseJson from "../../assets/LottieIAnimations/soundkitRelease.json";
import soundkitDraftReleaseJson from "../../assets/LottieIAnimations/soundkitLiveDraft.json";
import soundkitDraftJson from "../../assets/LottieIAnimations/soundkitDraft.json";
import soundkitScheduledJson from "../../assets/LottieIAnimations/soundkitScheduled.json";
import soundkitPrivateJson from "../../assets/LottieIAnimations/soundkitPrivate.json";
import soundkitEdit from "../../assets/LottieIAnimations/soundkitEdit.json";
import addCollaboratorsSoundkitJson from "../../assets/LottieIAnimations/soundkitCollaborationsInvite.json";
import { ACTIONSADDSOUNDKIT, ACTIONSADDTRACK } from "utils/actionText";

interface SuccessModalAddTrackSoundpackProps {
  isSuccess?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onButtonClick?: () => void;
  title: string;
  buttonLabel: string;
}

export default function SuccessModalAddTrackSoundPack({
  isSuccess = true,
  isOpen,
  title,
  buttonLabel,
  onClose,
  onButtonClick,
}: SuccessModalAddTrackSoundpackProps) {
  const { t } = useTranslation();
  const contentModal = useMemo(() => {
    if (title === ACTIONSADDTRACK.RELEASE_TRACK_DRAFT) {
      return <Lottie animationData={trackDraftRelease} loop={false} />;
    }
    if (title === ACTIONSADDTRACK.EDIT_TRACK) {
      return <Lottie animationData={trackEdit} loop={false} />;
    }
    if (title === ACTIONSADDTRACK.RELEASE_TRACK) {
      return <Lottie animationData={trackReleaseJson} loop={false} />;
    }
    if (title === ACTIONSADDTRACK.TRACK_DRAFT) {
      return <Lottie animationData={trackDraftJson} loop={false} />;
    }
    if (title === ACTIONSADDTRACK.PRIVATE_TRACK) {
      return <Lottie animationData={trackPrivateJson} loop={false} />;
    }
    if (title === ACTIONSADDTRACK.SCHEDULED_TRACK) {
      return <Lottie animationData={trackScheduledJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.RELEASE_SOUNDKIT) {
      return <Lottie animationData={soundkitReleaseJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.RELEASE_SOUNDKIT_DRAFT) {
      return <Lottie animationData={soundkitDraftReleaseJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.SOUNDKIT_DRAFT) {
      return <Lottie animationData={soundkitDraftJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.SCHEDULED_SOUNDKIT) {
      return <Lottie animationData={soundkitScheduledJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.PRIVATE_SOUNDKIT) {
      return <Lottie animationData={soundkitPrivateJson} loop={false} />;
    }
    if (title === ACTIONSADDSOUNDKIT.ADD_COLLABORATORS_SOUNDKIT) {
      return (
        <Lottie animationData={addCollaboratorsSoundkitJson} loop={false} />
      );
    }
    if (title === ACTIONSADDSOUNDKIT.EDIT_SOUNDKIT) {
      return <Lottie animationData={soundkitEdit} loop={false} />;
    }
  }, [title]);

  return (
    <Modal colorScheme="black" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p="0px"
        minW={{ base: "350px", md: "500px" }}
        position="relative"
        margin="0px 16px"
      >
        <ModalBody p="0px">{contentModal}</ModalBody>

        <ModalFooter
          position="absolute"
          bottom="20px"
          transform="translateX(-50%)"
          left="50%"
        >
          <Button
            onClick={onButtonClick}
            backgroundColor="rgba(255, 255, 255, 0.08)"
            color="white"
            fontSize="14px"
            borderRadius="4px"
            p="15px 30px"
            fontFamily="Cera Pro Regular"
            _hover={{ boxShadow: "none" }}
            marginBottom={{ base: "0px", md: "20px" }}
          >
            {t(buttonLabel)}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
