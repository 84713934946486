import { Box, Checkbox, Flex, Image, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { ConversationInfo } from "app/models";
import { useUsersInfo } from "../../hooks/useUsersInfo";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useLastMessage } from "../../hooks/useLastMessage";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import AvatarUser from "app/assets/images/UserAvatar.png";
interface ConversationItemProps {
  conversation: ConversationInfo;
  valueCheckBox: string[];
  handleCheckBox: (id: string) => void;
  handleShowChatMobile?: () => void;
}

export default function ConversationItem({
  conversation,
  valueCheckBox,
  handleCheckBox,
  handleShowChatMobile,
}: ConversationItemProps) {
  const currentUser = useSelector(selectUserAdmin);
  const history = useHistory();
  const location = useLocation();
  const { data: users, loading } = useUsersInfo(conversation?.users);

  const isMobileDevice = () => {
    return window.innerWidth <= 767;
  };

  const otherUsers = users?.filter((user) => user.id !== currentUser?._id);

  const tabMessages = queryString.parse(location.search);

  const {
    data: lastMessage,
    loading: lastMessageLoading,
    error: lastMessageError,
  } = useLastMessage(conversation?.id);

  const handleSelectConversation = useCallback(() => {
    if (isMobileDevice() && handleShowChatMobile) {
      handleShowChatMobile();
    }
    if (tabMessages.tab === "all") {
      history.push(`/messages?tab=all&room=${conversation.id}`);
    } else {
      history.push(`/messages?tab=all&room=${conversation.id}`);
    }
  }, [conversation.id, handleShowChatMobile, history, tabMessages.tab]);

  return (
    <Flex mb="16px" w="100%">
      <Checkbox
        borderRadius="4px"
        _focusVisible={{ outline: "none" }}
        size="lg"
        isChecked={!!valueCheckBox?.find((item) => item === conversation?.id)}
        onChange={() => handleCheckBox(conversation?.id)}
      />
      <Flex
        background={"#1F1F23"}
        borderRadius="8px"
        padding="11px 26px 21px 26px"
        ml="22px"
        w="100%"
        onClick={handleSelectConversation}
        cursor="pointer"
      >
        <Image
          w="48px"
          height="48px"
          borderRadius="full"
          alt="User"
          src={otherUsers?.[0]?.data()?.profileImage}
          fallbackSrc={AvatarUser}
        />
        <Box ml="20px" w="100%">
          <Flex display="flex" justifyContent="space-between">
            <Flex alignItems="center">
              {!conversation.readByMe ? (
                <Box
                  w="8px"
                  height="8px"
                  background="#D4F70E"
                  borderRadius="100%"
                  mr="8px"
                />
              ) : (
                <Box w="8px" mr="8px" />
              )}

              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight="500"
                color={conversation.readByMe ? "#535353" : "#FFFFF"}
              >
                {otherUsers?.[0]?.data()?.name}
              </Text>
            </Flex>
            <Box>
              <Text
                fontSize="14px"
                fontWeight="400"
                color={conversation.readByMe ? "#535353" : "#FFFFF"}
              >
                {lastMessage?.time}
              </Text>
            </Box>
          </Flex>
          <Text
            fontSize="14px"
            lineHeight="20px"
            fontWeight="400"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            mt="5px"
            ml="15px"
            color={conversation.readByMe ? "#535353" : "#FFFFF"}
          >
            {lastMessage?.message}
          </Text>
        </Box>
      </Flex>
    </Flex>
  );
}
