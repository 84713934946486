import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "app/assets/icons";
import classNames from "classnames";
import { useMySoundkit } from "hooks/soundkit/useMySoundkit";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import SkeletonMyTrackNew from "../Skeleton/skeletonMyTrackNew";
import MyDraftSoundkit from "./MyDraftsSoundkit";
import MyPrivateSoundkit from "./MyPrivateSoundkit";
import MyReleasedSoundkit from "./MyReleasedSoundkit";
import MyScheduleSoundkit from "./MyScheduleSoundkit";
import "./styles.scss";
import SkeletonMyTrackNewMobile from "../Skeleton/skeletonMyTrackNewMobile";
import MyPendingSoundkit from "./MyPedingSoundkit";

export default function MySoundkitsTab() {
  const { t } = useTranslation();
  const columnHeader = [
    { label: t("packNo") },
    { label: t("soundPacksName") },
    { label: t("dateReleased") },
    { label: t("fileDelivery") },
    { label: t("actions") },
  ];
  const { path } = useRouteMatch();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const location = useLocation();
  const {
    isLoading,
    handleSearchText,
    selectCheckbox,
    handleCheckbox,
    handleRemoveSoundkit,
    handleSelectAll,
    debouncedSearchText,
    isSelectAll,
  } = useMySoundkit();

  const isActiveTrackRoute = useCallback(
    (routeName) => {
      return location.pathname === routeName;
    },
    [location.pathname]
  );
  const routerType = location.pathname.split("/").pop();

  const myTrackRoutes = [
    {
      name: "released",
      exact: true,
      value: "",
      component: (
        <MyReleasedSoundkit
          selectCheckboxSK={selectCheckbox}
          handleCheckboxSK={handleCheckbox}
          searchTextItem={debouncedSearchText}
        />
      ),
    },
    {
      name: "schedule",
      value: "/my-schedule-soundkits",
      component: (
        <MyScheduleSoundkit
          searchTextItem={debouncedSearchText}
          selectCheckboxSK={selectCheckbox}
          handleCheckboxSK={handleCheckbox}
        />
      ),
    },
    {
      name: "private",
      value: "/my-private-soundkits",
      component: (
        <MyPrivateSoundkit
          searchTextItem={debouncedSearchText}
          selectCheckboxSK={selectCheckbox}
          handleCheckboxSK={handleCheckbox}
        />
      ),
    },
    {
      name: "drafts",
      value: "/my-draft-soundkits",
      component: (
        <MyDraftSoundkit
          searchTextItem={debouncedSearchText}
          selectCheckboxSK={selectCheckbox}
          handleCheckboxSK={handleCheckbox}
        />
      ),
    },
    {
      name: "pending",
      value: "/my-pending-soundkits",
      component: (
        <MyPendingSoundkit
          selectCheckboxSK={selectCheckbox}
          handleCheckboxSK={handleCheckbox}
          searchTextItem={debouncedSearchText}
        />
      ),
    },
  ];

  return (
    <Box className="components-mytrack-item">
      <Flex
        alignItems="center"
        position="sticky"
        top="228px"
        zIndex={90}
        height="90px"
        background="#18181c"
      >
        <Text fontSize="32px" mr="15px">
          {t("mySoundKits")}
        </Text>
      </Flex>

      <Box w="100%">
        <Box position="sticky" top="317px" background="#18181c" zIndex={90}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            gap={{ base: "10px", md: "0px" }}
          >
            <Flex>
              {myTrackRoutes.map((item, index) => (
                <Link key={index} to={`${path}${item.value}`}>
                  <Text
                    padding="5px 10px"
                    cursor="pointer"
                    color="#49494D"
                    fontSize="16px"
                    lineHeight="29px"
                    className={classNames({
                      "active-my-track": isActiveTrackRoute(
                        `${path}${item.value}`
                      ),
                    })}
                    _hover={{
                      border: "1px solid #38383D",
                      background: "#222227 !important",
                      borderRadius: "6px",
                    }}
                  >
                    {t(item.name)}
                  </Text>
                </Link>
              ))}
            </Flex>

            <Flex
              alignItems="center"
              paddingLeft="5px"
              borderTopLeftRadius="6px"
              borderTopRightRadius="6px"
            >
              <Text mr="15px" color="#49494D">
                {t("filter")}
              </Text>
              <InputGroup
                border="none"
                borderLeft="1px solid rgba(255, 255, 255, 0.04)"
              >
                <InputLeftElement children={<SearchIcon />} />
                <Input
                  type="tel"
                  placeholder={t("searchSoundPacks")}
                  border="0px"
                  p="0px 0px 0px 40px"
                  _placeholder={{ color: "#FFFFFF" }}
                  onChange={handleSearchText}
                  fontSize="14px"
                  cursor="pointer"
                />
              </InputGroup>
            </Flex>
          </Flex>
        </Box>
        {isLoading &&
          (isLessThan766 ? (
            <SkeletonMyTrackNewMobile />
          ) : (
            <SkeletonMyTrackNew isSoundkit={true} columnHeader={columnHeader} />
          ))}
        <Switch>
          {myTrackRoutes.map((tab, index) => {
            return (
              <Route key={index} exact={tab.exact} path={`${path}${tab.value}`}>
                {tab.component}
              </Route>
            );
          })}
        </Switch>
      </Box>
      {selectCheckbox.length > 1 && (
        <>
          <Box className="basket-item">
            <Checkbox
              onChange={(e) => handleSelectAll(e.target.checked, routerType)}
              _focusVisible={{ outline: "none" }}
              size="lg"
              mt="20px"
              isChecked={isSelectAll}
            >
              <Text ml="30px" color="#D8FD01">
                {t("selectAll")}
              </Text>
            </Checkbox>
          </Box>
          <Button
            mt="50px"
            backgroundColor="#24252A"
            border="1px solid #D8FD01"
            _hover={{ backgroundColor: "none" }}
            borderRadius="4px"
            _active={{ backgroundColor: "#D8FD01" }}
            onClick={handleRemoveSoundkit}
          >
            {t("removeFromSoundkit")}
          </Button>
        </>
      )}
    </Box>
  );
}
