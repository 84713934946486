import { Box } from "@chakra-ui/react";
import Header from "app/layouts/Header";
import React from "react";
import "./styles.scss";

interface GeneralLayoutProps {
  children: Element;
}

const GeneralLayout = ({ children }: GeneralLayoutProps) => {
  return (
    <Box maxW="1440px" margin="auto">
      <Box
        w={{ base: "100%", xl: "1440px" }}
        position="fixed"
        top="0"
        background="#18181c"
        zIndex={100}
      >
        <Header />
      </Box>
      <Box marginTop="100px">{children}</Box>
    </Box>
  );
};

export default GeneralLayout;
