import React from "react";

interface IconPriceProps {
  isMyTrack?: boolean;
  isReceive?: boolean;
}

const PriceButton = ({
  isMyTrack = false,
  isReceive = false,
}: IconPriceProps) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14"
        cy="14"
        r="14"
        fill={isMyTrack ? "#25252A" : !isReceive ? "#FF4117" : "#D4F70E"}
      />
      <path
        d="M19.9335 12.9109C18.0029 16.9997 17.3748 16.9997 17 16.9997H13.3077C12.0145 16.9997 11.0235 16.2391 10.7831 15.0612C10.7757 15.0252 10.0446 11.4368 9.85769 10.4343C9.76585 9.94278 9.53231 9.61508 8.69231 9.61508C8.30969 9.61508 8 9.30539 8 8.92278C8 8.54016 8.30969 8.23047 8.69231 8.23047C10.0862 8.23047 10.9834 8.92278 11.2188 10.18C11.4038 11.1719 12.1326 14.7483 12.14 14.7848C12.2872 15.5075 12.9348 15.6155 13.3077 15.6155H16.8177C17.1948 15.2103 18.0542 13.6489 18.6814 12.3197C18.8448 11.974 19.2578 11.8268 19.6031 11.9892C19.9492 12.1522 20.0969 12.5648 19.9335 12.9109ZM12.8462 17.4612C12.2088 17.4612 11.6923 17.9777 11.6923 18.6151C11.6923 19.2525 12.2088 19.7689 12.8462 19.7689C13.4835 19.7689 14 19.2525 14 18.6151C14 17.9777 13.4835 17.4612 12.8462 17.4612ZM17 17.4612C16.3626 17.4612 15.8462 17.9777 15.8462 18.6151C15.8462 19.2525 16.3626 19.7689 17 19.7689C17.6374 19.7689 18.1538 19.2525 18.1538 18.6151C18.1538 17.9777 17.6374 17.4612 17 17.4612ZM14.7675 13.6969C14.8137 13.7343 14.8705 13.7523 14.9272 13.7523C14.984 13.7523 15.0398 13.7343 15.0869 13.6969C15.1331 13.6605 16.3922 12.4323 17.0789 11.4105C17.132 11.3311 17.1371 11.2295 17.0918 11.1455C17.048 11.062 16.9603 11.0094 16.8657 11.0094H15.8383C15.8383 11.0094 15.7382 9.02893 15.6726 8.93708C15.5775 8.80139 15.1848 8.70908 14.9268 8.70908C14.6678 8.70908 14.2682 8.80139 14.1726 8.93708C14.1075 9.02847 14.0212 11.0094 14.0212 11.0094H12.9805C12.8854 11.0094 12.7982 11.062 12.7534 11.1455C12.7086 11.2295 12.7137 11.3315 12.7663 11.4105C13.4535 12.4328 14.7218 13.6605 14.7675 13.6969Z"
        fill={isMyTrack ? "#D4F70E" : "black"}
      />
    </svg>
  );
};
export default PriceButton;
