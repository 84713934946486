import { Box, Text, Flex, Button } from "@chakra-ui/react";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import IconShareLink from "app/assets/icons/shareLinkIcon";
import IconCopyReferral from "app/assets/icons/IconCopyReferral";
import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useSelector } from "react-redux";
import { useReferrals } from "hooks/Referrals/useReferrals";

export default function ShareSocialMedia() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);
  const { handleSetTotalReferred } = useReferrals();

  const linkRef = useMemo(() => {
    return `${window.location.origin}/auth/signup?ref=${currentUser?.name}`;
  }, [currentUser?.name]);

  const clipBoardLink = useCallback(() => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(linkRef);
    }
  }, [linkRef]);

  const handleShareLinkFb = useCallback(() => {
    handleSetTotalReferred();
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${linkRef}`,
      "_blank"
    );
  }, [handleSetTotalReferred, linkRef]);

  const handleShareLinkTwitter = useCallback(() => {
    handleSetTotalReferred();
    window.open(`https://twitter.com/intent/tweet?text=${linkRef}`, "_blank");
  }, [handleSetTotalReferred, linkRef]);

  const handleShareLinkInstagram = useCallback(() => {
    handleSetTotalReferred();
    window.open(`https://www.instagram.com/?url=${linkRef}`, "_blank");
  }, [handleSetTotalReferred, linkRef]);

  const listSocialMenu = useMemo(
    () => [
      // {
      //   id: 0,
      //   icon: <TfiYoutube color="#D4F70E" fontSize={25} />,
      //   onClick: () => handleShareLinkFb(),
      // },
      {
        id: 1,
        icon: <BsTwitter color="#D4F70E" fontSize={25} />,
        onClick: () => handleShareLinkTwitter(),
      },
      {
        id: 2,
        icon: <FaFacebookF color="#D4F70E" fontSize={25} />,
        onClick: () => handleShareLinkFb(),
      },
      {
        id: 3,
        icon: <BsInstagram color="#D4F70E" fontSize={25} />,
        onClick: () => handleShareLinkInstagram(),
      },
      // {
      //   id: 4,
      //   icon: <FaTiktok color="#D4F70E" fontSize={25} />,
      //   onClick: () => handleShareLinkFb(),
      // },
    ],
    [handleShareLinkFb, handleShareLinkInstagram, handleShareLinkTwitter]
  );

  return (
    <Box>
      <Box
        borderRadius="8px"
        background="rgba(56, 56, 61, 0.25)"
        padding={{ base: "40px 16px", md: "40px 20px", lg: "40px" }}
      >
        <Text
          fontSize={{ base: "18px", md: "20px" }}
          fontWeight="400"
          opacity="0.8"
          mb="20px"
        >
          {t("shareThroughSocialMedia")}
        </Text>
        <Text
          mb="15px"
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="400"
          color="#535353"
        >
          {t("yourShareLink")}
        </Text>

        <Flex mb="25px" overflow="hidden" w="100%">
          <Flex
            background="#18181C"
            alignItems="center"
            borderRadius="4px"
            padding="12px 10px"
            w="70%"
          >
            <Box ml="5px">
              <IconShareLink />
            </Box>
            <Text
              noOfLines={1}
              opacity="0.5"
              ml="10px"
              borderLeft="1px solid white"
              paddingLeft="10px"
            >
              {linkRef}
            </Text>
          </Flex>

          <Button
            height="48px"
            background="#202023"
            color="#D8FD01"
            _hover={{
              background: "#202023",
            }}
            borderRadius="0px"
            border="1px solid #D4F70E"
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
            minW={{ base: "90px", md: "105px" }}
            onClick={clipBoardLink}
          >
            <Text mr="10px" fontSize={{ base: "14px", md: "16px" }}>
              {t("copy")}
            </Text>
            <IconCopyReferral />
          </Button>
        </Flex>
        <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
          {t("unlockRewards")}
        </Text>

        <Flex mt="25px" justifyContent="center" gap="30px">
          {listSocialMenu.map((item) => (
            <Box
              key={item.id}
              w="58px"
              h="58px"
              background="#2A2A2E"
              borderRadius="full"
              display="flex"
              alignItems="center"
              justifyContent="center"
              cursor="pointer"
              onClick={item.onClick}
            >
              {item.icon}
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
}
