import * as yup from "yup";

const formSchema = (
  profitShare: number,
  publishing: number,
  isSoundkit: boolean
) =>
  yup.object().shape({
    user: yup
      .object()
      .shape({
        name: yup.string().required("userRequired"),
        user: yup.string().required("userRequired"),
      })
      .required("userRequired"),
    role: yup.string().required("roleRequired"),
    profitShare: yup
      .number()
      .required("profitShareRequired")
      .min(1, "profitShareMin")
      .max(profitShare, `profitShareMax ${profitShare}`),
    publishing:
      !isSoundkit &&
      yup
        .number()
        .required("publishingRequired")
        .min(1, "publishingMin")
        .max(publishing, `publishingMax ${publishing}`),
  });

export default formSchema;
