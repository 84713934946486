import { Box, Button, Divider, Flex, Image, Text } from "@chakra-ui/react";
import IconAddToCart from "app/assets/icons/iconAddToCart";
import PauseAudioIcon from "app/assets/icons/PauseAudio";
import AudioVisualizer from "app/components/AudioVisualizer";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import TooltipLabel from "app/components/TooltipLabel";
import { TrackSoundKit } from "app/models";
import { individualSoundKitSelector } from "app/redux/selectors/mySoundKit";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import PaypalNew from "app/assets/images/PaypalNew.svg";
import StripeNew from "app/assets/images/StripeNew.svg";
import { isNotForSaleTrackSoundPacks } from "utils/convertTracks";
import { addToCart, removeFromCart } from "app/redux/actions/cart";
import { isInCartSelector } from "app/redux/selectors/cart";
import Cart from "app/assets/icons/cart";

export default function RightIndividualSoundkit() {
  const soundkitDetail: TrackSoundKit = useSelector(individualSoundKitSelector);

  const {
    isPlaying,
    handleTogglePlayPause,
    playingTrack,
    handlePlayOrPause,
    isLoading,
  } = usePlayers();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDisabled = isNotForSaleTrackSoundPacks(soundkitDetail);
  const isInCart = useSelector(isInCartSelector(soundkitDetail));

  const checkPlaying = useMemo(() => {
    return soundkitDetail?._id === playingTrack?._id;
  }, [playingTrack?._id, soundkitDetail?._id]);
  const onClickToTrack = useCallback(
    async (track) => {
      if (!track?.mergeAudio && !track?.audioFileUrl) {
        return;
      }
      handlePlayOrPause(track);
    },
    [handlePlayOrPause]
  );

  const handleAddToCart = useCallback(() => {
    dispatch(addToCart(soundkitDetail));
  }, [dispatch, soundkitDetail]);

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart([soundkitDetail?._id]));
  }, [dispatch, soundkitDetail?._id]);

  return (
    <Box
      w={{ base: "100%", md: "65%", xl: "70%" }}
      height="100px"
      className="c_tright_tag"
    >
      <Box
        background={`url(${soundkitDetail?.coverUrl})`}
        className="c_rt_ptlt_box"
        display={{ base: "none", md: "block" }}
      >
        <Box
          padding={{ base: "25px 10px", md: "25px 20px", lg: "25px" }}
          borderRadius="8px"
          position="relative"
          mb="10px"
          className="c_rt_tlt_box"
        >
          <Text
            cursor="pointer"
            _hover={{
              textDecor: "underline",
            }}
            fontSize={{ base: "14px", md: "24px", lg: "30px" }}
            lineHeight="36px"
            fontWeight="700"
          >
            {soundkitDetail?.title}
          </Text>
          {checkPlaying && <AudioVisualizer checkPlaying={checkPlaying} />}

          {checkPlaying ? (
            <Box position="absolute" top="30px" right="20px">
              <PlayButton
                handleTogglePlayPause={handleTogglePlayPause}
                isPlaying={isPlaying}
                size={40}
                isLoading={isLoading}
              />
            </Box>
          ) : (
            <Box position="absolute" top="30px" right="20px">
              <Flex
                width="40px"
                height="40px"
                border="1px solid #D1F310"
                borderRadius="25px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => onClickToTrack(soundkitDetail)}
              >
                <TooltipLabel label={t("Play")}>
                  <Flex
                    background="rgba(255, 255, 255, 0.06)"
                    width="28px"
                    height="28px"
                    borderRadius="25px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PauseAudioIcon />
                  </Flex>
                </TooltipLabel>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        background="rgba(36, 37, 42, 0.3)"
        borderRadius="8px"
        padding={{
          base: "45px 10px 10px 10px",
          md: "45px 20px",
          lg: "45px 25px",
        }}
      >
        <Text
          fontSize={{ base: "24px", lg: "32px" }}
          lineHeight="24px"
          fontWeight="500"
        >
          {t("fileDescription")}
        </Text>
        <Text
          fontSize="18px"
          lineHeight="21px"
          fontWeight="600"
          mt="30px"
          color="rgba(255, 255, 255, 0.5)"
        >
          {soundkitDetail?.description}
        </Text>
        <Flex
          m="30px 0px 25px 0px"
          alignItems="center"
          display={{ base: "block", md: "flex" }}
        >
          <Text
            fontSize="18px"
            lineHeight="24px"
            mr="10px"
            mb={{ base: "10px", md: "0px" }}
          >
            {t("acceptPayment")}
          </Text>
          <Flex gap="10px">
            {soundkitDetail?.createdBy?.sellerConnectedStripe && (
              <Image fallbackSrc={StripeNew} />
            )}
            {soundkitDetail?.createdBy?.sellerConnectedPayPal && (
              <Image fallbackSrc={PaypalNew} />
            )}
          </Flex>
        </Flex>
        {!soundkitDetail?.createdBy?.sellerConnectedPayPal &&
          !soundkitDetail?.createdBy?.sellerConnectedStripe && (
            <Text color="red" fontSize="14px" mt="5px">
              {t("errorAddToCart")}
            </Text>
          )}
        <Divider borderColor="rgba(255, 255, 255, 0.04)" />

        <Flex mt="40px" justifyContent="space-between" alignItems="center">
          <Text fontSize="24px" lineHeight="24px" fontWeight="400">
            ${Number(soundkitDetail?.basicPrice)?.toFixed(2)}
          </Text>
          {isInCart ? (
            <Button
              leftIcon={
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="25px"
                  width="25px"
                  borderRadius="50%"
                  bg="#24252A"
                >
                  <Cart />
                </Box>
              }
              backgroundColor="#D4F70E"
              borderRadius="4px"
              color="black"
              _hover={{ background: "#D4F70E" }}
              onClick={handleRemoveToCartButton}
            >
              {t("inCart")}
            </Button>
          ) : (
            <Button
              leftIcon={<IconAddToCart />}
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              borderRadius="4px"
              _active={{ background: "#34391A" }}
              isDisabled={
                (!soundkitDetail?.createdBy?.sellerConnectedPayPal &&
                  !soundkitDetail?.createdBy?.sellerConnectedStripe) ||
                isDisabled
              }
              onClick={handleAddToCart}
            >
              {t("addToCart")}
            </Button>
          )}
        </Flex>
      </Box>
    </Box>
  );
}
