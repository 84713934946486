export const errorMessage = (e: any) => {
  return e?.response?.data?.message || e?.response?.data?.error;
};

export const checkHadData = (data: {}) => {
  if (Object?.keys(data)?.length === 0 || !data) {
    return true;
  }
  return false;
};
