import { Box, Text } from "@chakra-ui/react";
import MusicCard from "app/components/MusicCard";
import {
  RecentAction,
  TrackSoundKit,
  HandleFreeDownloadModel,
  ShortLinkModel,
} from "app/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { actionText, recentActions, typeMusic } from "utils/actionText";
import { infoProfile } from "utils/convertData";
import { format } from "utils/timeAgo";
import PopoverInfoProfile from "./popoverInfoProfile";
import "./styles.scss";
import { ACTIONS } from "constants/index";
import PlaylistItemInProfile from "../MyFeed/playlistItemProfile";
import i18next from "i18next";

interface RecentActionProps {
  isProfile?: boolean;
  item: RecentAction;
  index: number;
  onOpenOptionsModal: (track: TrackSoundKit, isLyric?: boolean) => void;
  handleShowModalAddTrack: (id: string) => void;
  handleFreeDownload?: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void | undefined;
  onOpenModalShareLink?: (track: TrackSoundKit) => void;
  setShortLinkUrl: (value: ShortLinkModel) => void;
  setLoadingUrl: (value: boolean) => void;
  listPlaylistTracksSoundPacks: TrackSoundKit[];
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

export default function RecentActions({
  item,
  isProfile = false,
  index,
  onOpenOptionsModal,
  handleShowModalAddTrack,
  handleFreeDownload,
  onOpenModalShareLink,
  setShortLinkUrl,
  setLoadingUrl,
  listPlaylistTracksSoundPacks,
  handleShowModalFreeDownloadMailchimp,
}: RecentActionProps) {
  const { track, icon: Icon, soundkit, playlist } = item;
  const { t } = useTranslation();

  return (
    <>
      <Box
        justifyContent="space-between"
        mt="8px"
        display="flex"
        mb="10px"
        alignItems="center"
      >
        <Box alignItems="center" display="flex" ml={{ xl: "25px" }}>
          <PopoverInfoProfile item={infoProfile(item)} />
          {Icon ? <Icon /> : null}
          <Text
            fontSize="14px"
            ml="6px"
            lineHeight="17px"
            color="rgba(255, 255, 255, 0.27)"
          >
            {t(actionText(item.action || ""))}
          </Text>
          {item?.actionType === recentActions.favorites && (
            <Text fontSize="14px" lineHeight="17px" ml="6px">
              {item.type === typeMusic.TRACK
                ? t("trackTo")
                : item.type === ACTIONS.PLAYLIST
                ? t("playlist")
                : t("soundkit")}
            </Text>
          )}

          {item?.actionType === recentActions.favorites && (
            <Text
              fontSize="14px"
              lineHeight="17px"
              ml="6px"
              color="rgba(255, 255, 255, 0.27)"
            >
              {t("toFavorites")}
            </Text>
          )}
          {item.actionType !== recentActions.favorites && (
            <Text fontSize="14px" ml="6px" lineHeight="17px" color="white">
              {item?.type === ACTIONS.PLAYLIST
                ? t("playlist")
                : item?.actionType}
            </Text>
          )}
        </Box>
        <Text
          color="rgba(255, 255, 255, 0.27)"
          fontSize="14px"
          lineHeight="17px"
          marginRight="10px"
        >
          {format(item, i18next.language)}
        </Text>
      </Box>

      {track && (
        <MusicCard
          onOpenOptionsModal={onOpenOptionsModal}
          item={track}
          index={index}
          isProfile={isProfile}
          isRecentAction
          handleShowModalAddTrack={handleShowModalAddTrack}
          handleFreeDownload={handleFreeDownload}
          onOpenModalShareLink={onOpenModalShareLink}
          setShortLinkUrl={setShortLinkUrl}
          setLoadingUrl={setLoadingUrl}
          listPlaylistTracksSoundPacks={listPlaylistTracksSoundPacks}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {soundkit && (
        <MusicCard
          onOpenOptionsModal={onOpenOptionsModal}
          item={soundkit}
          isSK
          index={index}
          isProfile={isProfile}
          isRecentAction
          handleShowModalAddTrack={handleShowModalAddTrack}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {playlist && <PlaylistItemInProfile playlist={playlist} />}
    </>
  );
}
