import { ShortLinkModel, HandleFreeDownloadModel } from "app/models";
import { lyricsSelector } from "app/redux/selectors/tracks";
import { useToggle } from "hooks/useToggle";
import { useCallback, useState } from "react";
import { useSelector } from "react-redux";

const useTrackSelect = () => {
  const [showOptionsModal, setShowOptionsModal] = useToggle();
  const [showModalShareLink, setShowModalShareLink] = useToggle();
  const [showModalFreeMailchimp, setShowModalFreeMailchimp] = useToggle();
  const [showModalFreeDownload, setShowModalFreeDownload] = useToggle();
  const [showLyrics, setShowLyrics] = useToggle();
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [isEditLyrics, setIsEditLyrics] = useState<boolean>(false);
  const [shortLink, setShortLink] = useState<ShortLinkModel>({
    originalURL: "",
    shortURL: "",
  });

  const [linkTitleDownload, setLinkTitleDownload] = useState({
    url: "",
    title: "",
    _id: "",
    isTrack: true,
  });

  const [loadingUrl, setLoadingUrl] = useState(false);

  const lyricsData = useSelector(lyricsSelector);

  const onOpenOptionsModal = useCallback(
    (track, isLyrics) => {
      setSelectedTrack(track);
      if (isLyrics) {
        setShowLyrics();
        setIsEditLyrics(false);
        return;
      }
      setShowOptionsModal();
    },
    [setShowLyrics, setShowOptionsModal]
  );

  const handleShowModalFreeDownloadMailchimp = useCallback(
    (track) => {
      setSelectedTrack(track);
      setShowModalFreeMailchimp();
    },
    [setShowModalFreeMailchimp]
  );

  const showModalAndGetdataShareLink = useCallback(
    (track) => {
      setShowModalShareLink();
      setSelectedTrack(track);
    },
    [setShowModalShareLink]
  );

  const handleFreeDownload = useCallback(
    ({ url, title, _id, isTrack }: HandleFreeDownloadModel) => {
      setShowModalFreeDownload();
      setLinkTitleDownload({
        url: url,
        title: title,
        _id: _id,
        isTrack: isTrack,
      });
    },
    [setShowModalFreeDownload]
  );

  return {
    showOptionsModal,
    setShowOptionsModal,
    selectedTrack,
    setSelectedTrack,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    showModalShareLink,
    setShowModalShareLink,
    showModalAndGetdataShareLink,
    setShortLink,
    shortLink,
    setLoadingUrl,
    loadingUrl,
    setShowModalFreeDownload,
    showModalFreeDownload,
    handleFreeDownload,
    linkTitleDownload,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
    handleShowModalFreeDownloadMailchimp,
  };
};

export default useTrackSelect;
