import { Box, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import BackgroundImagePlaylistIcon from "app/assets/icons/BackgroundImagePlaylistIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";

export default function SkeletonCreditProfile() {
  const fakeDataSekeleton = Array.from({ length: 5 });

  return (
    <Box padding={{ base: "5px 0px", lg: "5px 0px 5px 30px" }}>
      <Box mt="10px">
        <Skeleton w="200px" mb="25px" />
        <SimpleGrid
          columns={{ base: 1, lg: 2, xl: 3 }}
          spacing="10px"
          mb="25px"
        >
          {fakeDataSekeleton.map(() => (
            <Skeleton w="100%" h="50px" borderRadius="4px" mr="0px" />
          ))}
        </SimpleGrid>
        <Skeleton w="200px" mb="25px" />

        <SimpleGrid
          columns={{ base: 1, lg: 2, xl: 3 }}
          spacing="10px"
          mb="25px"
        >
          {fakeDataSekeleton.map(() => (
            <Skeleton w="100%" h="100px" mr="0px" />
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
}
