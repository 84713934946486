import { Box, Flex, Switch, Text } from "@chakra-ui/react";
import { PromoCode } from "app/models";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { dayjsFormat } from "utils/timeAgo";
import PopoverAction from "./PopoverAction";
import "./styles.scss";

interface IPromoCodeItemProps {
  promoCodeItem: PromoCode;
  onChangeActivePromoCode: (item: PromoCode) => void;
  onModalPromoCodeOpen: (type: string, item: PromoCode) => void;
  isActivePromoCode: (
    active: boolean,
    nonExpiring: boolean,
    expirationDate: string
  ) => boolean;
}

export default function PromoCodeItem({
  promoCodeItem,
  onChangeActivePromoCode = () => {},
  onModalPromoCodeOpen = () => {},
  isActivePromoCode,
}: IPromoCodeItemProps) {
  const {
    active = false,
    promoCode,
    percent,
    productType,
    expirationDate,
    nonExpiring,
  } = promoCodeItem;
  const { t } = useTranslation();

  const isActive = useMemo(
    () => isActivePromoCode(active, nonExpiring, expirationDate),
    [active, nonExpiring, expirationDate, isActivePromoCode]
  );

  const renderExpiredDate = useMemo(() => {
    if (nonExpiring) {
      return `${percent}% OFF - ${productType} -   This is a Non-Expiring Promo Code`;
    } else {
      return `${percent}% OFF - ${productType} -   EXPIRES ON ${dayjsFormat(
        expirationDate
      ).format("MM/DD/YYYY")}`;
    }
  }, [nonExpiring, percent, productType, expirationDate]);

  return (
    <Flex
      py="22px"
      alignItems="center"
      justifyContent="space-between"
      borderBottom="1px solid rgba(197, 221, 92, 0.04)"
      className="custom-switch popover-discount"
    >
      <Flex>
        <Switch
          isChecked={isActive}
          colorScheme="#D8FD01"
          size="lg"
          onChange={() => onChangeActivePromoCode(promoCodeItem)}
        />
        <Box ml="22px">
          <Text fontSize="14px" fontWeight="bold" pb="3px" lineHeight="16px">
            {promoCode}
          </Text>
          <Text fontSize="12px" lineHeight="16px" opacity="0.2">
            {renderExpiredDate}
          </Text>
        </Box>
      </Flex>
      <PopoverAction
        title={t("ModifyingPromoCode")}
        item={promoCodeItem}
        onActionClick={onModalPromoCodeOpen}
      />
    </Flex>
  );
}
