import React from "react";

const Report = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.38489 1.14622C6.32931 1.19635 6.28519 1.25599 6.25508 1.3217C6.22497 1.38741 6.20947 1.45789 6.20947 1.52907C6.20947 1.60026 6.22497 1.67074 6.25508 1.73645C6.28519 1.80216 6.32931 1.8618 6.38489 1.91192L8.1166 3.48647L3.3722 3.48647C2.74305 3.48647 2.13967 3.71372 1.6948 4.11822C1.24993 4.52272 1 5.07134 1 5.64339L1 11.2352C1 11.3782 1.06248 11.5154 1.1737 11.6165C1.28492 11.7176 1.43576 11.7744 1.59305 11.7744C1.75034 11.7744 1.90118 11.7176 2.0124 11.6165C2.12362 11.5154 2.1861 11.3782 2.1861 11.2352L2.1861 5.64339C2.1861 5.35736 2.31106 5.08305 2.5335 4.8808C2.75594 4.67855 3.05763 4.56493 3.3722 4.56493L8.1166 4.56493L6.38489 6.13948C6.27086 6.2403 6.20555 6.37818 6.20332 6.52279C6.2011 6.6674 6.26214 6.80689 6.37303 6.91057C6.48392 7.01426 6.63556 7.07364 6.7946 7.07566C6.95365 7.07769 7.10706 7.02218 7.22109 6.92136L9.96691 4.43012C10.021 4.37786 10.0651 4.31771 10.0974 4.25218C10.1288 4.18763 10.145 4.11784 10.145 4.04727C10.145 3.9767 10.1288 3.90691 10.0974 3.84236C10.0674 3.77572 10.023 3.71522 9.96691 3.66442L7.22109 1.16779C7.11314 1.06444 6.96458 1.00415 6.80789 1.00011C6.65119 0.996071 6.49911 1.0486 6.38489 1.14622Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        strokeWidth="0.3"
      />
      <path
        d="M8.34191 14.8538C8.3975 14.8037 8.44162 14.744 8.47173 14.6783C8.50184 14.6126 8.51734 14.5421 8.51734 14.4709C8.51734 14.3997 8.50184 14.3293 8.47173 14.2636C8.44162 14.1978 8.3975 14.1382 8.34191 14.0881L6.61021 12.5135L11.3546 12.5135C11.9838 12.5135 12.5871 12.2863 13.032 11.8818C13.4769 11.4773 13.7268 10.9287 13.7268 10.3566L13.7268 4.76481C13.7268 4.6218 13.6643 4.48465 13.5531 4.38352C13.4419 4.2824 13.291 4.22559 13.1338 4.22559C12.9765 4.22559 12.8256 4.2824 12.7144 4.38352C12.6032 4.48465 12.5407 4.6218 12.5407 4.76481L12.5407 10.3566C12.5407 10.6426 12.4157 10.9169 12.1933 11.1192C11.9709 11.3214 11.6692 11.4351 11.3546 11.4351L6.61021 11.4351L8.34191 9.86052C8.45595 9.7597 8.52126 9.62182 8.52348 9.47721C8.52571 9.3326 8.46466 9.19311 8.35378 9.08943C8.24289 8.98574 8.09124 8.92636 7.9322 8.92434C7.77316 8.92231 7.61975 8.97782 7.50572 9.07864L4.75989 11.5699C4.70576 11.6221 4.66166 11.6823 4.62942 11.7478C4.59805 11.8124 4.58184 11.8822 4.58184 11.9527C4.58184 12.0233 4.59805 12.0931 4.62942 12.1576C4.65941 12.2243 4.70378 12.2848 4.75989 12.3356L7.50571 14.8322C7.61366 14.9356 7.76222 14.9958 7.91892 14.9999C8.07561 15.0039 8.22769 14.9514 8.34191 14.8538Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        strokeWidth="0.3"
      />
    </svg>
  );
};
export default Report;
