import {
  Box,
  Button,
  Flex,
  Input as TextInput,
  SimpleGrid,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import Close from "app/assets/icons/Close";
import React, { Fragment, useCallback, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export default function AddTag({
  currentTags,
  handleAddTag,
  isCreatePlaylist = false,
}: {
  currentTags: string[];
  handleAddTag: (tags: string[]) => void;
  isCreatePlaylist?: boolean;
}) {
  const { t } = useTranslation();
  const [currentInput, setCurrentInput] = useState<string>("");

  const onAddNewTag = useCallback(() => {
    if (!currentTags.includes(currentInput)) {
      handleAddTag([currentInput, ...currentTags]);
    }
    setCurrentInput("");
  }, [handleAddTag, currentTags, currentInput]);

  const onDeleteTag = useCallback(
    (val) => {
      handleAddTag(currentTags.filter((tag: string) => tag !== val));
    },
    [handleAddTag, currentTags]
  );

  const onChangeTags = useCallback(
    (e) => {
      const { value } = e.target;
      const currentInputValue =
        currentInput.length === 0 && value !== "#" ? `#${value}` : value;
      setCurrentInput(currentInputValue);
    },
    [currentInput.length]
  );

  const disable = useMemo(() => currentTags.length === 3, [currentTags.length]);

  return (
    <Fragment>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing="15px"
        mt="10px"
        display={isCreatePlaylist ? "block" : "grid"}
      >
        <Box>
          <Text
            color={isCreatePlaylist ? "white" : "#535353"}
            fontFamily="Cera Pro Regular"
            fontSize={isCreatePlaylist ? "14px" : "16px"}
          >
            {t("tags")}
          </Text>
          <Flex alignItems="center" mt={isCreatePlaylist ? "5px" : "10px"}>
            <TextInput
              placeholder={
                isCreatePlaylist ? t("enterUpToPlaylistTag") : t("enterTag")
              }
              backgroundColor={isCreatePlaylist ? "#18181c" : "#25252A"}
              border={isCreatePlaylist ? "1px solid #383839" : "none"}
              borderColor={isCreatePlaylist ? "#383839" : "none"}
              size="lg"
              borderLeftRadius="4px"
              borderRadius="none"
              mr="1px"
              value={currentInput}
              onChange={onChangeTags}
              _placeholder={{
                color: "#535353",
                fontSize: "16px",
                fontFamily: "Cera Pro Regular",
              }}
              _hover={{
                borderColor: "none",
              }}
              _focus={{
                borderColor: "none",
              }}
            />
            <Button
              backgroundColor="#D8FD02"
              size="lg"
              color="black"
              fontSize="16px"
              fontFamily="Cera Pro Regular"
              borderRadius="none"
              borderRightRadius="8px"
              onClick={onAddNewTag}
              disabled={isCreatePlaylist ? disable : false}
            >
              {t("addTags")}
            </Button>
          </Flex>
        </Box>
      </SimpleGrid>
      <Wrap mt="10px" gridGap="10px">
        {(currentTags || []).map((tag) => {
          return (
            <WrapItem
              key={tag}
              padding="10px"
              border="1px solid #D8FD01"
              borderRadius="4px"
              cursor="pointer"
              backgroundColor="#24252A"
              alignItems="center"
              onClick={() => onDeleteTag(tag)}
            >
              <Text color="white" mr="10px">
                {tag}
              </Text>
              <Close />
            </WrapItem>
          );
        })}
      </Wrap>
    </Fragment>
  );
}
