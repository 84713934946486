import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PriceDownloadButton from "app/components/PriceButton";
import TagGenreButton from "app/components/TagGenreButton";
import { transaction } from "app/models";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatTimeByDayMonthYear } from "utils/timeAgo";
import { TRACK_TYPE } from "constants/trackTypes";
import countryList from "react-select-country-list";
import AvatarUserLoading from "app/assets/images/UserAvatar.png";
import { roleText } from "constants/collaboratorType";
import { TRACK_STATE } from "constants/index";
import "./styles.scss";

interface transactionItemsProps {
  transactionItems: transaction;
}

export default function PaymentsTranferItem({
  transactionItems,
}: transactionItemsProps) {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  const fullNameCountry = useCallback(
    (label) => options.find((item) => item.value === label),
    [options]
  );

  const isExclusive = useMemo(
    () =>
      (transactionItems?.track?.licenses || []).some(
        (license) => license.exclusive
      ),
    [transactionItems?.track?.licenses]
  );

  const licensesName = useMemo(
    () =>
      (transactionItems?.track?.licenses || [])
        .filter((license) => license._id === transactionItems?.license)
        .map((item) => item.name),
    [transactionItems?.license, transactionItems?.track?.licenses]
  );

  const checkTrackOrSoundPack = useMemo(() => {
    return !!transactionItems.track;
  }, [transactionItems.track]);

  return (
    <>
      <Box>
        <Box overflow="scroll">
          <Flex
            direction={{ base: "column", lg: "row" }}
            mt={{ lg: "20px" }}
            alignItems="center"
            w="100%"
            pb={{ base: "40px", lg: "20px" }}
            borderBottom="1px solid #26262A"
            minWidth={{ lg: "1024px" }}
            gap={{ lg: "10px", xl: "15px" }}
          >
            <Flex
              alignItems={{ base: "center", lg: "unset" }}
              gap={{ base: "10px", lg: "unset" }}
              w="5%"
              overflow="hidden"
            >
              <Image
                ml={{ xl: "15px" }}
                src={
                  checkTrackOrSoundPack
                    ? transactionItems?.track?.coverUrl
                    : transactionItems?.soundkit?.coverUrl || ImageDefault
                }
                fallbackSrc={ImageDefault}
                w="40px"
                height="43px"
                borderRadius="6px"
              />
            </Flex>
            <Text
              w="17%"
              fontSize={{ base: "14px", lg: "16px" }}
              lineHeight="16px"
              fontWeight="700"
              noOfLines={2}
            >
              {checkTrackOrSoundPack
                ? transactionItems?.track?.title
                : transactionItems?.soundkit?.title}
            </Text>
            <Flex w={{ base: "100%", lg: "15%" }} alignItems="center">
              <Image
                w="40px"
                h="40px"
                src={transactionItems?.buyerId?.profileImage}
                fallbackSrc={AvatarUserLoading}
              />
              <Box ml="10px">
                <Text
                  fontSize={{ base: "14px", lg: "16px" }}
                  fontWeight="700"
                  noOfLines={2}
                >
                  {transactionItems?.buyerId?.name}
                </Text>
                <Text
                  color="#4B4B4E"
                  fontSize={{ base: "12px", lg: "14px" }}
                  fontWeight="500"
                >
                  {roleText(transactionItems?.buyerId?.role?.[0])}
                </Text>
              </Box>
            </Flex>
            <Text
              w={{ base: "100%", lg: "10%" }}
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
              noOfLines={2}
              textAlign={{ lg: "center" }}
            >{`${transactionItems?._id}`}</Text>
            <Text
              textAlign={{ lg: "center" }}
              w={{ base: "100%", lg: "8%" }}
              fontSize="12px"
              lineHeight="16px"
              fontWeight="400"
            >
              {formatTimeByDayMonthYear(transactionItems?.createdAt)}
            </Text>
            {checkTrackOrSoundPack ? (
              <Box
                w={{ base: "100%", lg: "15%" }}
                display="flex"
                flexDirection="column"
                textAlign={{ lg: "center" }}
              >
                <TagGenreButton data={licensesName} isMyTrack />
                <Box
                  display="flex"
                  alignItems="center"
                  height="20px"
                  borderRadius="4px"
                  padding="0px 5px"
                  mt="5px"
                  backgroundColor="#2b2e1b"
                  width="fit-content"
                >
                  <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                    {isExclusive
                      ? t("exclusiveContract")
                      : t("nonExclusiveContract")}
                  </Text>
                </Box>
              </Box>
            ) : (
              <Flex
                w={{ base: "100%", lg: "15%" }}
                flexDirection="column"
                alignItems={{ lg: "center" }}
              >
                <Text
                  height="20px"
                  borderRadius="4px"
                  padding="5px 10px"
                  mr="5px"
                  mb="5px"
                  backgroundColor="#2b2e1b"
                  fontSize="12px"
                  lineHeight="14px"
                  color="#d4f70e"
                  fontFamily="Cera Pro Regular"
                  w="max-content"
                >
                  {t(TRACK_TYPE[transactionItems?.soundkit?.type])}
                </Text>
              </Flex>
            )}
            <Text
              textAlign={{ lg: "center" }}
              fontSize="12px"
              fontWeight="400"
              w={{ base: "100%", lg: "10%" }}
              noOfLines={2}
            >
              {fullNameCountry(transactionItems?.ipAddress)?.label}
            </Text>
            {transactionItems?.statusDownload === TRACK_STATE.PENDING ? (
              <Flex
                w={{ base: "100%", lg: "10%" }}
                justifyContent={{ lg: "center" }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="4px"
                  padding="4px 10px"
                  backgroundColor="#193347"
                >
                  <Text fontSize="12px" fontWeight="500" color="#1DA1F2">
                    {t("Pending")}
                  </Text>
                </Box>
              </Flex>
            ) : (
              <Flex
                w={{ base: "100%", lg: "10%" }}
                display="flex"
                justifyContent={{ lg: "center" }}
              >
                <Box>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    borderRadius="4px"
                    padding="4px 10px"
                    mb="5px"
                    backgroundColor="#2b2e1b"
                  >
                    <Text fontSize="12px" fontWeight="500" color="#d4f70e">
                      {t("Downloaded")}
                    </Text>
                  </Flex>
                  <Text
                    mt={{ base: "4px", lg: "0px" }}
                    w={{ lg: "90%" }}
                    textAlign="center"
                    fontSize="10px"
                    fontWeight="400"
                  >
                    {formatTimeByDayMonthYear(transactionItems?.timeDownload)}
                  </Text>
                </Box>
              </Flex>
            )}
            <Flex
              w={{ base: "100%", lg: "10%" }}
              justifyContent={{ lg: "flex-end" }}
            >
              <PriceDownloadButton transactionItems={transactionItems} />
            </Flex>
          </Flex>
        </Box>
      </Box>
    </>
  );
}
