export const TRANSLATIONS_EN = {
  loopAndSample: "3rd Party Loops & Samples",
  thisSample: "Where did you get this sample?",
  nameSample: "What is the name of the sample / loop?",
  addSample: "Add another Loop/Sample",
  welcome: "Welcome to the tutorial",
  uploadeTrack: "Add Track",
  addSoundkit: "Add Sound Kit",
  dashboard: "Dashboard",
  home: "Home",
  privacy: "Privacy",
  terms: "Terms",
  contactUs: "Contact Us",
  profile: "Profile",
  stats: "Stats",
  analytics: "Analytics",
  myTracks: "My Tracks",
  collaborations: "Collaborations",
  discounts: "Discounts",
  payment: "Payments & Wallets",
  subscription: "Subscription Plans",
  contracts: "Licenses & Contracts",
  negotiations: "Negotiations",
  tracks: "Tracks",
  today: "Today",
  week: "Week",
  month: "Month",
  year: "Year",
  addNewTrack: "You can add new track",
  openFile: "Open file or Drag",
  uploadNewTrack: "Upload New Track",
  showStats: "Show full statistic",
  newArtists: "New Artists on Superproductor",
  Statistic: "Statistic",
  CustomPeriod: "Custom Period",
  Week: "Week",
  Month: "Month",
  Year: "Year",
  TotalPlays: "Total Plays",
  Top10Countries: " Top 10 countries",
  payments: "Payments & Wallets",
  stripe: "Stripe",
  paypal: "PayPal",
  connect:
    "Connect your Stripe Account and start accepting a wide range of payment methods including credit and debit cards.",
  yourEmail: "Your email",
  connectStripe: "Connect Stripe",
  connectpaypal:
    "Connect your PayPal account to accept debit and credit card payments effortlessly.",
  connectPaypal: "Connect PayPal",
  save: "Save",
  TopTrack: "Top tracks",
  TopUser: "Top",
  Most: "Most:",
  Website: " Website:",
  Period: "Period:",
  extended: "Extended overview",
  totalSales: "Total Sales:",
  PRICINGPLANS: "PRICING PLANS",
  flexiblePlan: "Choose the Perfect Plan for You",
  Annually: "Annually",
  Monthly: "Monthly",
  fullDetails: "Show full details",
  FreeDemoDownloads: "Free Demo Downloads",
  FastPayoutsFunds: "Fast Payouts Funds",
  AcceptCreditCardAndPaypal: "Accept Credit Card and Paypal",
  UploadSoundKits: "Upload Sound Kits",
  UploadTrackoutStems: "Upload Track out Stems",
  withdraw: "Withdraw",
  addNewCard: "Add new card",
  myTrack: "My Tracks",
  released: "Released",
  purchased: "Purchased",
  favorites: "Favorites",
  drafts: "Drafts",
  filter: "Filter",
  Discounts: "Discounts",
  PromoCode: "Promo Code",
  noDiscounts: " You have no discounts ",
  createPromo: "Create a New Promo Code",
  BulkOffer: "Bulk Offer",
  noBulkOffers: " You have no bulk offers",
  NewBulkOffer: " Create a New Bulk Offer",
  ApplyPromoCode: "Apply Promo Code to the following licenses",
  Create: "Create",
  Cancle: "Cancel",
  Back: "Back to discounts",
  Percent: "Percent Off 1%-50% Only",
  ProductType: "Product Type",
  ExpirationDate: "Expiration Date",
  NonExpiring: "This is a Non-Expiring Promo Code",
  Standard: "Standard License (MP3)",
  Premium: "Premium License (MP3) (WAV)",
  Pro: "Pro (MP3) (WAV) (STEMS)",
  NameBulkOffer: "Name Bulk Offer",
  Sample: "Sample",
  Minimum: "Minimum Number of Track(s) in cart",
  willBeForFree: "Tracks that will be for free",
  bulkDiscountOption: "Create a New bulk discount option",
  bulkDiscount: "Apply this bulk discount to following licenses",
  licensesContracts: "Licenses & Contracts",
  createALicense: "Create a license",
  exclusive: "Exclusive",
  nonExclusive: "Non-Exclusive",
  editLicense: "Edit the license?",
  youWillNotChange: "You are able to reset it back.",
  cancel: "Cancel",
  done: "Done",
  deleteLicense: "Delete the license?",
  notRecoverIt: "This action may potentially cause track licensing  issues.",
  delete: "Delete",
  createNewLicense: "Create new license",
  generalInfo: "General Info",
  description: "Optional Info",
  meataData: "Metadata",
  contractDetails: "Contract Details",
  address: "Address",
  licensesAvailability: "Licenses & Availability",
  collaborators: "Collaborators",
  backToLicense: "Back to licenses",
  name: "Name",
  price: "Price",
  typeOfContract: "Type Of Contract",
  minimum: "Minimum negotiation price (Optional)",
  enableAllow: "Enable ‘Allow offers’ ",
  enable: "Enable",
  filesToDeliver: "Files to deliver",
  includeMp3: "Include MP3 (required)",
  includeWav: "Include WAV",
  includeTrackout: "Include Trackout STEMS",
  contractDetail: "Contract Details",
  distribution: "Distribution copies",
  audioStreams: " Audio Streams",
  videoStreams: "Video Streams",
  musicStreams: " Music video",
  paidPerformances: "Paid performances",
  profitPerformances: "Non profit perfomances",
  unlimited: "Unlimited",
  optionInformation: "Optional info",
  descriptionOption: "Brief description (optional)",
  featureList: "Feature list",
  separateEach: "Separate each item with a line break",
  reset: "Reset",
  broadcastingRight: "Allow Broadcasting rights",
  allowProfitPerformances: "Allow Profit Performances",
  newLicense: "New license",
  exclusivePrice: "Exclusive rights price",
  allowOffers: "Allow Offers",
  SaveToDraft: "Save to Draft",
  PublishTrack: "PublishTrack",
  BeatsPublishing: "Beats publishing %",
  BeatsWithHook: "Beats with hook publishing %",
  TopLines: "Top Lines & vocals publishing %",
  ExclusiveLicenses: "Enable ‘Make offers only’ for Exclusive Licenses",
  QuickCustomization: "Quick Customization",
  addTrack: "Add Track",
  metadata: "Metadata",
  trackTitle: "Track Title",
  beatLegacy: "Beat Legacy",
  trackCover: "Track Cover",
  addPicture: "Add Picture",
  trackType: "Track Type",
  releaseDate: "Release Date",
  url: "URL",
  audioFiles: "Audio File for MP3 download",
  selectAudioFileForStreaming: "Select Audio File For Streaming",
  saveToDraft: "Save to Draft",
  publishTrack: "Publish Track",
  coverSize: "Cover Size",
  requireDownload: "Require Downloaders to",
  twitter: "Twitter",
  soundCloud: "Soundcloud",
  superProductor: "Superproductor",
  socialFollow: "Social Follow",
  connectTwitter: "Connect Twitter",
  connectSoundcloud: "Connect Soundcloud",
  freeDownload: "Free Download Options",
  no: "No",
  yesWithVoice: "Yes, with voice tag (MP3 Version)",
  yesUntagged: "Yes, Untagged (MP3 Version - 320kbps)",
  allowAnonymous: "Allow Anonymous Guest  Download",
  maximumText: " out of 355 Maximum characters allowed",
  bpm: "BPM",
  key: "Key",
  tags: "Tags",
  addTags: "Add Tag",
  standardLicense: "Standard License (MP3 Price)",
  miniumAmount: "Minimum amount  offer",
  toDisableALicense:
    "To disable a License for this specific Track please enter 0 (or 0.00).",
  thisWillDisable: "This will disable purchases for that specific",
  user: "User",
  role: "Role",
  profitShare: "Profit share",
  publishing: "Publishing",
  Notifications: "Notifications",
  addACollaborator: "Add a collaborator ",
  moreNotifications: " more unread notifications",
  ClearAll: "Clear all",
  NoNotification: "You're all caught up! You have no new notifications.",
  soundKitTitle: "Sound Kit Title",
  soundKitName: "Enter Sound Kit Name",
  cover: "Cover",
  soundKitType: "Sound Kit Type",
  UploadingSoundKit: "Uploading Sound Kit",
  UploadNewSoundKit: "Upload New Sound Kit",
  UploadFiles: "Upload Files",
  OpenorDrag: "Open Or Drag",
  AudioFile: "Audio File",
  Edit: "Edit",
  Uploading: "Uploading...",
  addCoverArt: "Add Cover Art",
  beforeYouAddCollaborators:
    "Before you add Collaborators, please make sure that you & your Collaborators meet the requirements below:",
  youMustBe: "- You must be the “Primary Contributor” to add Collaborators.",
  makeSure: "- Make sure you follow each other.",
  yourCollaborator:
    "- Your Collaborator has to be a Superproductor Registered member and at least subscribed to a “FREE” Subscription Account.",
  needToHave: "- Needs to have their PayPal Email Address properly Setup.",
  youCanOnly: "- You can only add up to Five (5) Collaborators per Track.",
  chooseFile: "Choose file (or drag them here)",
  upLoadFiles: "Upload files",
  chooseFiles: "Choose files",
  passWordOptional: "Password (optional)",
  orCompress: "Or compress track stems into a single .zip file",
  uploadAZipFile: "Upload a .zip file",
  clientName: "Client Name",
  modifying: "Modifying This License ?",
  numberOfRadio: "Number of Radio Stations",
  numberOfProfit: "Number of Profit Perfomances",
  licenseRenewalTerm: "License Renewal Terms",
  freeDownloads: " Free Downloads",
  nonMonetizedVideo: "Non-monetized videos streams",
  monetizedVideos: "Monetized videos Streams",
  yearTerm: "Year Term",
  stateOrProvidence: "State or Providence",
  country: "Country",
  Modifying: "Modifying This Bulk Offer?",
  EditOffer: "Edit this Bulk Offer?",
  EditPromoCode: "Edit this Promo Code",
  DeleteOffer: "Delete this Bulk Offer",
  DeletePromoCode: "Delete this Promo Code",
  resetItBack: "You are able to reset it back.",
  deleteThisOffer: "Are you sure you want to delete this item?",
  Reactivate: "Reactivate Promo Code",
  Activate: "Re-Activate",
  Active: "Active",
  Free: "Free",
  Upgrade: "Upgrade",
  CancelSubscription: "Cancel Subscription",
  MainstreamPlan: "Do you want to keep your current Mainstream Plan?",
  cancelyourCurrent:
    "This will cancel your current plan and drop back to free plan.",
  Proceed: "Proceed",
  emailAdress: "Email address",
  login: "Login",
  password: "Password",
  username: "Username",
  signUp: "Sign Up",
  seeYourSupport: "Discover Your Sound: Your One-Stop Shop for Beats",
  signUpWithEmail: "or Sign up with Email",
  iGreeToThe: "I agree to the",
  termsConditions: "Terms & Conditions",
  alreadyAccount: "Already have an Account?",
  signIn: " Sign in",
  superproductorAllRight: "Superproductor™ All Rights Reserved,",
  support: "See your growth and get consulting support!",
  Consistent:
    "Consistent quality and experience across all platforms and devices.",
  Streaming:
    "Streaming platform for artists to discover the hottest rap beats for their next hit!",
  Superproductor: "Superproductor™ All Rights Reserved,",
  CreateAnAccount: "Create an Account",
  NotRegisteredYet: "Not registered yet?",
  ForgetPassword: "Forget Password?",
  Email: "Email",
  Password: "Password",
  SignUpWithEmail: "or Sign up with Email",
  RememberMe: "Remember me",
  EditProfile: "Edit Profile",
  OfersBulkDeals: "Ofers Bulk Deals",
  Followers: "Followers",
  Plays: "Plays",
  Tracks: "Tracks",
  LastActivity: "Last Activity",
  MyProfile: "My Profile",

  // TRACK TYPE
  SoundKits: "Sound Kits",
  beat: "Beat",
  beatWithChorus: "Beat With Chorus",
  topLine: "Top Line",
  vocals: "Vocals",
  song: "Song",
  all: "All In One",
  oneShot: "One Shot",
  drumLoops: "Drum Loops",
  midiPack: "Midi Pack",
  melodicLoops: "Melodic Loops",
  effects: "Effects",
  presets: "Presets",

  // KEY
  aFlatMinor: "A-flat Minor (A♭m)",
  aFlatMajor: "A-flat Major (A♭M)",
  aMinor: "A Minor (Am)",
  aMajor: "A Major (AM)",
  aSharpMinor: "A-Sharp Minor (A#m)",
  aSharpMajor: "A-Sharp Major (A#M)",
  bFlatMinor: "B-flat Minor (B♭m)",
  bFlatMajor: "B-flat Major (B♭M)",
  bMinor: "B Minor (Bm)",
  bMajor: "B Major (BM)",
  bSharpMinor: "B-Sharp Minor (B#m)",
  bSharpMajor: "B-Sharp Major (B#M)",
  cFlatMinor: "C-flat Minor (C♭m)",
  cFlatMajor: "C-flat Major (C♭M)",
  cMinor: "C Minor (Cm)",
  cMajor: "C Major (CM)",
  cSharpMinor: "C-Sharp Minor (C#m)",
  cSharpMajor: "C-Sharp Major (C#M)",
  dFlatMinor: "D-flat Minor (D♭m)",
  dFlatMajor: "D-flat Major (D♭M)",
  dMinor: "D Minor (Dm)",
  dMajor: "D Major (DM)",
  dSharpMinor: "D-Sharp Minor (D#m)",
  dSharpMajor: "D-Sharp Major (D#M)",
  eFlatMinor: "E-flat Minor (E♭m)",
  eFlatMajor: "E-flat Major (E♭M)",
  eMinor: "E Minor (Em)",
  eMajor: "E Major (EM)",
  eSharpMinor: "E-Sharp Minor (E#m)",
  eSharpMajor: "E-Sharp Major (E#M)",
  fFlatMinor: "F-flat Minor (F♭m)",
  fFlatMajor: "F-flat Major (F♭M)",
  fMinor: "F Minor (Fm)",
  fMajor: "F Major (FM)",
  fSharpMinor: "F-Sharp Minor (F#m)",
  fSharpMajor: "F-Sharp Major (F#M)",
  gFlatMinor: "G-flat Minor (G♭m)",
  gFlatMajor: "G-flat Major (G♭M)",
  gMinor: "G Minor (Gm)",
  gMajor: "G Major (GM)",
  gSharpMinor: "G-Sharp Minor (G#m)",
  gSharpMajor: "G-Sharp Major (G#M)",

  // ADD TRACK REQUIRED
  yes: "Yes",
  soundkitAddedSuccessfully: "Soundkit Added Successfully",
  soundkitAddedDescription:
    "Your soundkit was successfully added to the page. go to the feed  to see it ",
  emptyString: "Empty string",
  publishSoundkit: "Publish Sound Kit",
  trackTitleRequired: "Please add a track title",
  urlRequired: "Please add a url",
  soundkitTitleRequired: "Please add a soundkit title",
  soundkitBasicPriceRequired: "Please add a price",
  coverUrlRequired: "Please add a cover image",
  trackTypeRequired: "Please select a track type",
  soundkitTypeRequired: "Please select a soundkit type",
  bpmRequired: "BPM is a required field",
  keyRequired: "Select a key",
  primaryGenreRequired: "Select a primary genre",
  secondaryGenreRequired: "Select a sub genre",
  primaryMoodRequired: "Select a primary mood",
  descriptionRequired: "Description is a required field",
  sampleRequired: "This is a required field",
  contractTypeRequired: "Please select contract type",
  untaggedFileUrlRequired: "Please upload an audio file",
  licenseRequired: "Please select at least one license",
  tagsRequired: "Please create at least 4 tags",
  uploadTrackStems: "Please upload track STEMS",
  uploadTrackWav: "Please upload track WAV",
  collaboratorsRequired: "Please sellect at least a collaborator",
  audioFileUrlRequired: "Audio is required",
  downloadFilesUrlRequired: "Download file is required",
  taggedFileUrlRequired: "Please upload an tagged audio file",

  yourFileMustBeWavOrFlac:
    "- Your file must be a WAV or FLAC format with a sample rate of 44.1 kHz in stereo and compressed in 16-bit.",
  uploadAudioFile: "Upload Audio File",
  uploadCompressFile: "Upload Compress File",
  WAVorMP3: ".MP3 ( or WAV )",
  fileDescription: "Description",
  uploadCover: "Upload Cover",
  SelectTypeOfContract: "Select type of contract",
  SelectTrackType: "Select track type",
  SelectSoundKitType: "Select Sound Kit Type",
  selectGenre: "Select genre",
  selectSubGenre: "Select Sub-Genre",
  selectKey: "Select Key",
  view: "View",
  selectPrimaryMood: "Select Primary Mood",
  selectSecondMood: "Select Second Mood",
  enterDescription: "Enter Description Here",
  maxBpm: "Maximun of Bpm is 999",
  maximunDescription: "355 Maximum characters allowed",
  cropCover: "Crop cover",
  enableAllowOffersNonExclusive:
    " Enable “Allow Offers” for Non-Exclusive Licenses",
  includeBulkOfferDiscout: "Include in bulk discounts",
  licenseNote: "Note: ",
  customizeYourLicense: "You may customize your licenses throught here: ",
  LicenseAndContracts: "Licenses & Contracts",
  private: "Private",
  excludeBulkOfferDiscout: "Exclude From Bulk Discounts",
  notForSale: "Not For Sale",
  freeDownloadEnabledRequired: "Please select a Free Download Option",

  includesMp3AndWav: "Includes MP3 and WAV",
  includesMp3WavAndTrackStems: "Includes MP3, WAV and Track Stems",
  includesMp3AndTrackStems: "Includes MP3 and Track Stems",
  includesMp3: "Includes MP3",
  tagPreview: "Tag Preview",
  audioTagForStreaming: "Audio Tag for Streaming",
  defaultAudioVoiceTag: "Superproductor Default Tag.mp3",
  uploadFile: "Uploaded File:",
  uploadAudioVoiceTag: " Custom Tag.mp3",
  noteUploadCover: "Minimum: 500x500, Preferred: 1500x1500",
  uploadSuccess: "Uploaded successfully!",
  MP3CustomVoiceTag: "MP3 Custom Voice Tag",
  MP3CustomVoiceTagNote:
    "If you don't own a Tag: \n A Superproductor Tag will play by default.\n Tag will begin after 1 second and then tag will play every 30 seconds.",
  trackStems: "Track Stems",
  trackStemsDetail: ".ZIP to Download",
  untaggedWAV: "Untagged WAV",
  untaggedMP3: "Untagged MP3",
  minBitrateWAV: ".WAV ( File recommended in 44.1 WAV 24-bit or higher )",
  minBitrateWAVorMP3: ".MP3 ( Min. Bitrate 128 )",
  errorUploadAudio: "Error when uploading file",
  minimunBitrate: "Please select an audio has bitrate at least 128kbps",
  fieldToDeliver: "Field to Deliver",
  NoDescriptionYet: "No description yet",
  EditAvatar: "Edit Avatar",
  MinimumSize: "Minimum: 500x500px, Preferred: 1500x1500px ",
  Country: "Country",
  About: "About",
  Symbols: "Symbols",
  NotWriteMore: " you can not write more than words",
  Credits: "Credits",
  SeparateEachLine: "Separate each line break by pressing enter button.",
  editLicenses: "Edit License",
  update: "Update",
  upToDate: "You're up to date!",
  uploaded: "uploaded",
  displayName: "Display Name",
  trackAddedSuccessfully: "A Track Has Been Added To Your Profile",
  trackAddedDescription:
    "Your track was successfully added to the page. Go to the feed  to see it ",
  backToFeed: "Back to feed",
  contract: "Contract",
  producer: "Producer",
  selectRole: "Select role",
  publishingRequired: "Publishing required",
  profitShareRequired: "Profit Share required",
  roleRequired: "Role required",
  profitShareMin: "At least 1% Profit Share as minimum",
  profitShareMax: "Profit Share must be have maximum",
  publishingMin: "At least 1% Publishing as minimum",
  publishingMax: "Publishing must be have maximum",
  publishingMaxExclusive: "Publishing must be have maximum 50%",
  profitShareNumber: "Profit Share must be a number",
  publishingNumber: "Profit Share must be a number",
  exclusiveContract: "Exclusive Contract",
  nonExclusiveContract: "Non-Exclusive Contract",
  requiredUploadFiles:
    "To active this license, you must upload  following files: ",
  passwordUser: "password",
  PasswordNotMatch: "Password not match",
  confirmPassword: "Confirm Password",
  ResetYourPassword: "Reset your password?",
  emailSent: "Email sent with instructions ",
  enterYourEmail:
    "Enter your email below and we will send instructions on how to create a new one.",
  forgotYourPassword: "Forgot your password",

  // SUBCRIPTION PLAN
  freePlan: "Standard",
  proPlan: "Community Plan",
  freePlanPrice: "Free",
  free: "Free",
  freePlanPriceDescription: "$0.00",
  freePlanDescription: "Start Free, Grow Big: Scalable Pricing for Musicians",
  mainstreamMonthlyPlan: "Mainstream Plan",
  mainstreamAnnualPlan: "Mainstream Plan",
  mainstreamMonthlyPlanPrice: "$1.67 / Month",
  mainstreamAnnualPlanPrice: "$19.99 / Year",
  mainstreamPlanPriceDescriptionMonth: "Billed every month",
  mainstreamPlanPriceDescriptionYear: "$99.88 / Year",
  mainstreamPlanPriceDiscountMonth: "0% Off",
  mainstreamPlanPriceDiscountYear: "17% Off",
  mainstreamMonthlyPlanDescription:
    "For sellers who want to expand their music business and access to selected features",
  mainstreamAnnualPlanDescription:
    "Unlock 0% Fees: Upgrade for Just $19.99 Today!",
  activePlan: "Active Plan",
  active: "Active",
  upgrade: "Upgrade",
  cancelDateText: "This subscription is active until ",
  cancelSubscription: "Cancel Subscription",
  discoutOff: "-17% Off",
  cancelDescription: `Your Mainstream Subscription Plan has been cancelled. When you cancel your subscription at any point during your monthly or annual cycle, your account remains active until the end of your billing period. \n\nHowever your account will remain active as Free account. If it's an error, let us know and we will assit immediately.​​`,
  upgradeDescription: `Your Mainstream Subscription Plan has been confirmed. When you cancel your subscription at any point during your monthly or annual cycle, your account remains active until the end of your billing period. \n\nHowever your account will remain active as Free account. If it's an error, let us know and we will assit immediately.​`,
  cancelLabel: "Subscription Cancelled",
  upgradeLabel: "Subscription Confirm",
  cancelYourCurrent:
    "This will downgrade your current plan and drop back to free plan.",
  mainstreamAnnualPriceDescription: "Billed every year",
  mainstreamMonthlyPriceDescription: "Billed every month",
  planUpgrade: "Plan Upgrade",
  planReview: "Plan Review",
  thanksForBuying: "Thanks For Buying:",
  upgradeToMainstreamPlan: "Upgrade To Mainstream Plan",
  pleaseCheckEmail:
    "Please check your email for order confirmation and \ndetailed delivery information.",
  // END SUBCRIPTION PLAN
  cart: "Cart",
  basketIsEmpty:
    "Your basket is empty. Explore new track in your search for your music library",
  purchaseDetail: "Purchase Details ",
  cartSummary: "Cart Summary:",
  gotPromoCode: "Got promo code?",
  placeYourOrder: "Place your order",
  chooseLicense: "Choose License",
  addToCart: "Add to Cart",
  acceptPayment: "Accepted Payments:",
  debitOrCredit: "Debit or Credit Card",
  usageTerms: "Usage Terms",
  errorAddToCart:
    "This user still not connect Paypal or stripe . You can not Add to cart this track.",
  distributionCopies: " Distribution Copies",
  nonVideoStreams: " Non-Monetized Video Streams",
  monetizedVideoStreams: " Monetized Video Streams",
  profitLive: " Profit Live Performances",
  profitPerformance: " Profit Performances",
  yearLicenseRenewal: " Year(s) License Renewal",
  mustCredit: "Must Credit:",
  prodBy: "Prod by:",
  filesToBeDelivered: "Files To Be Delivered:",
  mp3: "MP3",
  wav: "WAV",
  stems: "STEMS",
  unlimitedFreeDownload: "Unlimited Free download",
  license: "License",
  licenseReview: "License Review",
  playTrack: "Play track",
  remove: "Remove",
  businessName: "Business Name",
  bankAccount: "Bank Account",
  transactionId: "Transaction ID",
  saleDate: "Sale Date",
  specification: "Specification",
  addStripe: "Add Stripe",
  addPaypal: "Add Paypal",
  registerSuccessfully: "Register successfully",
  backToPayment: "Back to payment & wallets",
  selectAll: "Select All",
  removeFromCart: "Remove From Cart",
  removeFromTrack: "Remove From Track",
  removeFromSoundkit: "Remove From Soundkit",
  paymentMethods: "Payment methods",
  confirm: "Confirm",
  paymentMethod: "Payment Method:",
  checkoutWithPaypal: "Checkout with Paypal",
  confirmPayment: "Confirm Payment",
  checkOut: "Checkout",
  yourTrackOrder: "We've got your ",
  trackOrder: "track order.",
  contactYourPurchase:
    "There was an error with your purchase. Please contact your purchase.",
  contactYourBank: "Please contact your bank. A button to Retry again",
  serverError: "Internal Server Error",
  backToCheckout: "Back to checkout",
  creditCard: "Credit Card:",
  cardHolderName: "Cardholder Name",
  cardNumber: "Card Number",
  date: "Date",
  cvc: "CVC",
  continue: "Continue",
  errorCardNumber: "Invalid Card Number",
  dateIsRequired: "Date is a required field",
  invalidCvc: "Invalid CVC",
  nameIsRequired: "Name is required field",
  change: "Change",
  disconnect: "Disconnect",
  youWantToDisconnect:
    "Disconnecting your PayPal account will prevent you from offering PayPal services and products on our website.",
  paymentConflict: "Do you wish to continue?",
  paymentAccounts: "Payment Accounts",
  disconnectSuccessfully: "Disconnect successfully",
  disconnectFailed: "Disconnect failed",
  rememberThisCard: "Remember this card for future transactions",
  offersBulkDeals: "Offers Bulk Deals",
  message: "Message",
  follow: "Follow",
  bulkOfferDetails: "Bulk Offer Details",
  addToQueue: "Add To Queue",
  removeFromQueue: "Remove From Queue",
  missingTrackTitle: "Missing track title",
  missingTrackFiles: "Missing Files",
  missingTrackLicenses: "Missing License & Contract",
  alreadyRepost: "You already reposted this track",
  alreadyRepostSK: "You already reposted this sound Kit",
  lyricTitle: "Lyrics Title",
  writeLyricTitle: "Write lyrics title",
  lyrics: "Lyrics",
  writeLyricshere: "Write your lyrics here...",
  editLyrics: "Edit Lyrics",
  saveLyrics: "Save Lyrics",
  trackTo: "Track ",
  toFavorites: "to Favorites",
  missTypeSoundkit: "Missing type soundkit",
  editTrack: "Edit Track",
  soundkit: "Sound kit",
  writeLyrics: "Write Lyrics",
  backToMyTrack: "Back to my track",
  editTrackSuccessfully: "Edit Track Successfully",
  editSoundkit: "Edit Sound Kit",
  editSoundkitSuccess: "Edit Soundkit Successfully",
  backToMySoundkit: "Back to my sound kit",
  minimumTracksRequired: "Minimum tracks is a required field",
  freeTracksLessThan: "Number of free tracks must be less than minimum tracks",
  freeTracksRequired: "Number of free tracks is a required field",
  atLeastOneLicense: "Please select at least one license",
  EditBulkOffer: "Edit Bulk Offer",
  ApplyToLicense: "Apply to following license",
  editExitBulkOffer: "Edit an exist Bulk Offer",
  queue: "Queue",
  playingQueue: "Playing queue",
  clearQueue: "Clear Queue",
  ModifyingPromoCode: "Modifying PromoCode",
  promoCodeRequired: "Promo code is a required field",
  productType: "Product Type",
  productTypeRequired: "Product Type is a required field",
  percent: "Percent Off (1% - 50% Only)",
  percentPositive: "Percent Off should be a positive number",
  percentLessThan50: "Percent Off should be less than 50",
  percentRequired: "Percent Off is a required field",
  expirationDate: "Expiration Date",
  setExpirationDate: "Set Promo Code Expiration Date",
  expirationDateRequired: "Expiration Date is a required field",
  nonExpiring: "This is a Non-Expiring Promo Code",
  licenses: "Apply this promo code to the following licenses",
  NewPromoCode: "Create a New Promo Code",
  mustBeLaterThanToday: "Date must be later than today.",
  editExistPromoCode: "Edit exist Promo code",
  editPromo: "Edit Promo code",
  startNegotiation: "Start Negotiation",
  youHaveNo: "You have no",
  negotiation: "negotiations",
  NoHaveNegotiations: "If you have negotiations, they will appear on this page",
  negotiationDeal: "Negotiation Deal",
  paymentCompleted: "Payment Completed",
  negotiationsStatus: {
    ACCEPTED: "Waiting for the  payment",
    COUNTEROFFER: "Counteroffer sent",
    EXPIRED: "Expired",
    REJECT_BY_SELLER: "Reject by seller",
    CANCEL_BY_BUYER: "Cancel by buyer",
    COUNTEROFFER_ACCEPTED: "Waiting for the  payment",
    COMPLETED: "Completed",
    PENDING: "Pending",
    SOLD_TO_SOMEONE_ELSE: "Sold to someone else",
  },
  counterofferTimeout:
    "Counteroffer Timeout. Please create a new couteroffer to continue.",
  expiresIn: "Expires in",
  expiredDayOn: "Expired on ",
  exclusiveLicense: " Exclusive License",
  publishingNegotiation: "Publishing:",
  accept: "Accept",
  counteroffer: "Counteroffer",
  paynow: "Pay now",
  reject: "Reject",
  upgradePlan: "Upgrade Plan",
  myProfile: "My profile",
  messages: "Messages",
  playlistsHub: "Playlists Hub",
  offerExpiresIn: "Offer Expires in",
  negotiationOffer: "Negotiation Offer",
  yourCounterOffer: "Your Counteroffer",
  reviewCounteroffer: "Review Counteroffer",
  yourOffer: "Your Offer",
  quantity: "Quantity",
  total: "Total",
  submitOffer: "Submit Offer",
  editOffer: "Edit Offer",
  newConversations: "New Conversation",
  startConversation: "Start Conversation",
  typeYourMessageHere: "Type your message here...",
  send: "Send",
  emptyConversation: "When you get a message, you’ll see it here.",
  youHaveCollaborate: "You have",
  invitationToCollaborate: "invitation to Collaborate",
  youHaveOpen:
    "If you have open invites to collaborate, they will appear on this page",
  openInvitations: "Open Invitations",
  closeInvitations: "Close Invitations",
  profitShareCollaborations: "Profit Share",
  publishCollaborations: "Publishing",
  emptyNotificatioon: "When you get a notifications, you’ll see it here.",
  selectTypeBeforeCollabotations:
    "Select contract type before adding collaborators",
  selectLicenseBeforeCollabotations:
    "Select License before adding collaborators",
  invieExpiredOn: "Invite Expired on ",
  youHaveNoAnalytics: "You have no analytics activity",
  dataAnalytics: "Data Analytics",
  this: "this",
  ofMyTrack: "of my tracks",
  emptyTopCountry: "You have no recent activity",
  hideExtendedOverview: "Hide extended overview",
  showExtendedOverview: "Show extended overview",
  mostPopularLicense: "Most Popular License",
  youHaveNoRecentActivity: "You have no recent activity",
  schedule: "Schedule",
  charactersLeft: "characters left.",
  aMinimumOfMaximum: "A minimum of 0 to 70 maximum characters will be accepted",
  openCollaborations: "Open Invitations",
  forThe: "for The",
  of: "of ",
  productorPerformance: "Product Performance",
  consumerBeHaviour: "Consumer behaviour",
  noLyricsYet: "No write lyrics yet",
  contentEmptyLyrics:
    "There’s no feeling like composing, Start now writing your lyrics!",
  createLyrics: "Create Lyrics",
  accepted: "Accepted",
  from: "from",
  youHave: "You have",
  freeTrackRemain: "free tracks remain",
  addMore: "Add more ",
  toApplyBulkOffer: "tracks to apply bulk offer: ",
  noneDiscount: "None Discount",
  discount: "Discount:",
  bulkDealApplied: "Bulk Deal Applied",
  applyPromoCode: "Apply Promo Code",
  checkPromoCode: "Check Promo Code",
  invalidCode: "Invalid code",
  promoCodeDiscount: "Promo Code Discount:",
  buyInBulkDeals: "Buy In Bulk Deals",
  feed: "Feed",
  discover: "Discover",
  explore: "Explore",
  hotProducers: "Hot Producers",
  recentActions: "Recent Actions",
  newTalents: "New Talents",
  recommended: "Recommended",
  freeBeats: "Free Beats",
  trendingTags: "Trending Tags",
  track: "Track",
  slugTrack: "Slug already exists",
  underRadar: "Under Radar",
  trendingBeats: "Trending Beats",
  genres: "Genres",
  moods: "Moods",
  refresh: "Refresh",
  newRelease: "New release:",
  searchResultsFor: "Search results for:",
  trackName: "Track Name",
  musicianName: "Musician Name",
  soundPacksName: "Sound Kit Name",
  playlistName: "Playlist Name",
  nameTag: "Name Tag",
  musicians: "Musicians",
  soundPacks: "Sound Kits",
  playlist: "Playlist",
  subGenres: "Sub Genres",
  showMore: "Show more",
  showLess: "Show less",
  location: "Location",
  noNewRelease: "No new release",
  globalCounsumers: "Global Consumers",
  unfollow: "Unfollow",
  textDescriptionFeedHome:
    "You'll have quick access to head straight to new tracks based on your music taste, making it easy to find new inspiration.",
  textDescriptionDiscoverHome:
    "Discover today's hottest beats worldwide. You're sure to find your next favorite track with new talents. So take a listen and let your imagination run wild!",
  textDescriptionExploreHome:
    "Whether you're exploring a new genre or just trying to find some new sounds to enjoy, this is a great way to expand your musical horizons.",
  inActive: "In Active",
  mySoundKits: "My Sound Kits",
  searchSoundPacks: "Search Sound Kit",
  shareLink: "Share Link",
  shortLink: "Short Link",
  copy: "Copy",
  FREE: "FREE",
  noDescription: "No description",
  whoAreYou: "Who are you?",
  next: "Next",
  tellUsAboutYour: "Tell us a little bit about your background!",
  musicListener: "Are you a  Music Listener or Music Supervisor? *",
  songWrite: "Are you a Singer, Songwriter, Dancer or Content Creator? ",
  artist: "Artist",
  doYouMakeBeats: "Do you make beats? ",
  chooseYourInterest: "Choose your interest",
  descriptionInterest:
    "You are free to choose anny interested to music genre. So that it would help us to show content based on your interests.",
  allNotification: "View all notifications",
  unRead: "Unread",
  limitUploadTrack: "You have reached uploads track limit",
  limitNewLicense: "You have reached create license",
  noData: "No Data",
  guest: "You're logged as guest user",
  updatePictureProfile: "Update Picture Profile",
  pictureProfile: "Picture Profile",
  preview: "Preview",
  chooseFileImage: "Choose File",
  saveChanges: "Save Changes",
  titleLicenseLimit: "E-License Agreements Limit Reached",
  descriptionLimit:
    "Hello! You have reached the 4 e-License Agreements limit. Upgrade your plan to continue creating more agreements.",
  upgradeYourPlanToUpload: "Upgrade Your Plan to Upload MP3 Custom Voice Tag",
  pleaseUpgradeYourPlan:
    "Hey there! To upload an MP3 Custom Voice Tag, please upgrade your plan.",
  uploadTrackLimitReached: "Uploading Track Limit Reached",
  youHaveReachedTrack:
    "Oops! You have reached the 10-track limit of uploaded tracks. Upgrade your plan to continue uploading",
  limitUploadSoundPacks: "Upgrade your plan to continue uploading",
  titleLimitUploadSoundPacks: "Upload sound kits",
  messagesLimitReached: "Message Limit Reached",
  descriptionFiveMessagesLimitReached:
    "Hey there! You have reached the 5 limits of messages. Upgrade your plan to continue sending messages.",
  descriptionTwentyMessagesLimitReached:
    "Hey there! You have reached your limit of 20 messages per month",
  charactersRemaining: "characters remaining",
  youHaveExceededTheMaximum: "You have exceeded the maximum character limit",
  add: "Add",
  more: "more",
  leaveAddTrackPage: "Leave Add Track page?",
  descriptionLeaveTrackPage:
    "Track will be saved as draft. Resume where you left off later.",
  leave: "Leave",
  stay: "Stay",
  termAndConditions: "Terms & Conditions",
  privacyPolicy: "Privacy Policy",
  enterTrackTitle: "Enter Track Title",
  enterTag: "Enter Tag",
  enterBpm: "Enter BPM",
  inCart: "In Cart",
  descriptionNonExclusive:
    "This non-exclusive beat license gives you the right to use the beat, but the owner can still give others permission to use it too. This means that other artists who also have permission to use the beat may also use it.",
  descriptionExclusive:
    "An exclusive beat license means you'll be the only one who can use the beat. The owner can't let anyone else use it during the period of the license.",
  descriptionAddTrack:
    "Looking for the Perfect Beat? Look No Further! Our Track Library has you Covered! From Catchy Grooves to Genre-specific Styles, We Have It All. Whether It's an EP, Album, Mixtape, Music Video, or any Project that needs a Beat, We've Got You Covered!",
  collaborationInviteSent: "Collaboration Invite Sent !",
  descriptionCollaborationInviteSent:
    "Let’s wait until the collaborator(s) send the response. The track should be standing at the dashboard inside the tracks on the draft section waiting for approval to be published.",
  firstNameIsRequired: "First Name is required field",
  lastNameIsRequired: "Last Name is required field",
  completeYourProfile: "Complete Your Profile",
  beforePublishingYourTracks:
    "Before publishing your tracks, please take a moment to complete your profile.",
  pseudonymName: "Pseudonym Name",
  pageNotFound: "Page not found",
  editCard: "Edit card",
  editCreditOrDebitCard: "Edit credit or debit card",
  invalidDate: "Invalid Date",
  removeCard: "Remove card",
  removeThisCard: "Remove this card?",
  areYouSureRemoveCard: "Are you sure you want to remove this card?",
  action: "Action",
  soundPack: "Sound Kit",
  soundPackIsNotForSale: "This sound kit is not for sale.",
  availability: "Availability",
  addPaymentAndWallets: "Add Payout Account",
  desAddPaymentAndWallets:
    "Before publishing your tracks, please take a moment to add payout accounts.",
  open: "Open",
  tyOfContract: "Type of contract",
  sharePrivately: "Share Privately",
  setLinkExpiration: "Set Link Expiration",
  expirationMode: "Expiration Mode",
  expireAfter: "Expire After",
  clicks: "Clicks",
  usersAdded: "Users Added",
  placeHolderLinkExpiration:
    "Hey there!\nI've got something special for you – an exclusive track just for a select few, and you're one of them! Click the private link below to listen and enjoy.Let me know what you think! Your feedback means a lot to me.",
  desUserAdded: "Only people with access can open this link",
  createPassword: "Create Password",
  optional: "Optional",
  userRequired: "Please select at least one user to continue.",
  expireRequired: "Please provide a valid date to continue.",
  aTrackHasBeenSharePrivately: "A Track Has Been Share Privately",
  congratulationsDes:
    "Congratulations! Your track has been successfully shared privately with selected users using our secure and exclusive private share link.",
  trackSaveAsDraft: "Track Save as Draft",
  desTrackSaveAsDraft:
    "Great progress! Your track has been saved as a Draft. It's not quite ready to meet the world, but you're on your way. Feel free to come back anytime.",
  trackHasChangedToRelease: "Track Release Confirmation",
  desTrackHasChangedToRelease:
    "Congratulations! Your track has successfully moved from Draft to Release. It is now ready to reach its audience.",
  playlists: "Playlists",
  createNew: "Create New",
  createNewPlaylist: "Create New Playlist",
  enterPlaylistName: "Enter your playlist name",
  enterUpToPlaylistTag: "Enter up to 3 playlist tag",
  enterAnOptionalDescription: "Enter an optional description",
  visibility: "Visibility",
  savePlaylist: "Save Playlist",
  datedAdded: "Dated Added :",
  playlistOwner: "Playlist Owner: ",
  information: "Information",
  published: "Published",
  runtime: "Runtime",
  lastUpdated: "Last Updated",
  noPlaylists: "No Playlists",
  thereAreNoPlaylistAvailable: "There are no playlist available.",
  addToPlaylist: "Add to playlist",
  myPlaylists: "My Playlists",
  editPlaylist: "Edit Playlist",
  alreadyRepostPlaylist: "You already reposted this playlist",
  noTracks: "No Tracks",
  thereAreNoTracksAvailable: "There are no track available.",
  progress: "Progress",
  myPlaylist: "My Playlist",
  following: "Following",
  playAll: "Play All",
  emptyPurchasedHistory: "Empty Purchase History",
  item: "Item",
  purchaser: "Purchaser",
  seller: "Seller",
  purchasedDate: "Purchased Date",
  downloadConfirmation: "Download Confirmation",
  downloadFiles: "Download Files",
  Pending: "Pending",
  Downloaded: "Downloaded",
  geographicalIpFinder: "Geographical IP Finder",
  Eng: "English",
  Spa: "Spanish",
  Por: "Portuguese",
  placeHolderSearchInputHeader: "Type what you are looking for",
  clear: "Clear",
  logout: "Logout",
  downloads: "Downloads",
  sales: "Sales",
  itemSold: "Item Sold",
  sold: "Sold",
  position: "Position",
  gross: "Gross",
  collab: "Collab%",
  super: "Super%",
  earnings: "Earnings",
  by: "By",
  played: "Played",
  favorited: "Favorited",
  listeners: "Listeners",
  userWithMoreDownloads: "User with more downloads",
  userWithMoreRepost: "User with more repost",
  buyers: "Buyers",
  searchTrack: "Search Track",
  selectPromoCodeType: "Select Promo Code Type",
  get: "get",
  freeBulk: "free",
  comingSoon: "Coming Soon",
  licenseName: "License Name",
  selectTypeOfContract: "Select Type Of Contract",
  enterStateOrProvidence: "Enter state or providence",
  enterBeatsPublishing: "Enter beats publishing",
  enterBeatsHookPublishing: "Enter beats hook publishing",
  enterTopLinesVocalsPublishing: "Enter top lines & vocals publishing",
  selectYearTerm: "Select year term",
  automaticallyDeducts:
    "Automatically deducts customer's payment method on selected yearly renewal term.",
  enterPromoCode: "Enter Promo Code",
  newestFirst: "Newest First",
  lowestPrice: "Lowest Price",
  highestPrice: "Highest Price",
  "Released a": "Released a",
  "Reposted a": "Reposted a",
  searchPlaylist: "Search Playlist",
  rejected: "Rejected",
  youInvite: "You invite",
  toCollaborateAs: "to collaborate as a",
  inviteYouToCollaborateAs: "invite you to collaborate as a",
  invitationSentToCollaborateAs: "Invitation sent to collaborate as a",
  hasAccepted: "has accepted",
  hasRejected: "has rejected",
  invitationToCollaborateAs: "invitation to collaborate as a",
  you: "You",
  offersYou: "offers you",
  for: "for",
  Item: "item",
  youCreatedAOffers: "You created a offers",
  offerWithPrice: "Offer with price",
  isPending: "is pending",
  your: "Your",
  offerHasExpire: "offer has Expire",
  offer: "Offer",
  hasBeenCanceledBy: "has been canceled by",
  hasBeenRejectBy: "has been reject by",
  counterOfferPrice: "counter offer price",
  hasSent: "has sent",
  youA: "you a",
  youHaveReceivedPayment: "You have received a payment",
  thanksForYourPayment: "Thanks for your payment",
  fromNow: "from now",
  searchCollaborators: "Search Collaborators",
  enterPublishing: "Enter Publishing",
  enterProfitShare: "Enter Profit share",
  "Instant payouts funds": "Instant payouts funds",
  "Unlimited Automated e-License Agreements":
    "Unlimited Automated e-License Agreements",
  "Collaborations with Revenue Splits": "Collaborations with Revenue Splits",
  "Discounts Offers": "Discounts Offers",
  "Custom Voice Tag": "Custom Voice Tag",
  "Sales Analytics": "Sales Analytics",
  "Accept Offers & Negotiate": "Accept Offers & Negotiate",
  "Schedule Uploads": "Schedule Uploads",
  "Free Demo Downloads": "Free Demo Downloads",
  "Upload Up to 10 Trackout Stems (.zip) Files":
    "Upload Up to 10 Trackout Stems (.zip) Files",
  "Only 10 Trackout Restrictions for Free Users":
    "Only 10 Trackout Restrictions for Free Users",
  "Upload Unlimited Trackout Stems (.zip) Files":
    "Upload Unlimited Trackout Stems (.zip) Files",
  "Up to 20 Private Monthly Messages": "Up to 20 Private Monthly Messages",
  "Up to 5 Private Monthly Messages": "Up to 5 Private Monthly Messages",
  "Only 5 Monthly Messages Restrictions for Free Users":
    "Only 5 Monthly Messages Restrictions for Free Users",
  "Accept Credit Card and Paypal": "Accept Credit Card and Paypal",
  "Upload Up to 10 Tracks": "Upload Up to 10 Tracks",
  "Only 10 Tracks Restrictions for Free Users":
    "Only 10 Tracks Restrictions for Free Users",
  "Upload Unlimited Tracks": "Upload Unlimited Tracks",
  "Coupon Codes": "Coupon Codes",
  "Limited-Time Only": "Limited-Time Only",
  "Sales Automation": "Sales Automation",
  "Keep 100% your revenue": "Keep 100% Sales Revenue",
  "Keep 90% your revenue": "Keep 90% Sales Revenue",
  "Platform Fee Waive On Free Plans": "Platform Fee Waive On Free Plans",
  "MP3 Custom Voice Tag ( Audio Tag for Streaming ) Restrictions for Free Users":
    "MP3 Custom Voice Tag ( Audio Tag for Streaming ) Restrictions for Free Users",
  "Sales & Premium Analytics": "Sales & Premium Analytics",
  "Upload 10 Sound Kits (.zip) Files": "Upload 10 Sound Kits (.zip) Files",
  "Exclusive Tracks Link Sharing": "Exclusive Tracks Link Sharing",
  All: "All",
  "Add ": "Add ",
  useForMusicRecording: "Use For Music Recording",
  dontUse: "Don't use",
  use: "Use",
  upToFreeDownloads: "Up to 0 Free Downloads",
  unlimitedFreeDownloads: "Unlimited Free Downloads",
  allowed: "Allowed",
  notAllow: "Not Allow",
  UpTo: "Up to 0",
  allowedBroadcastingRights: "Allowed Broadcasting Rights",
  notAllowBroadcastingRights: "Not Allow Broadcasting Rights",
  others: "Others",
  upload: "Upload",
  shareLinkPlaylist: "Share Link Playlist",
  searchItem: "Search Item",
  searchCountry: "Search Country",
  proOption: "PRO (Optional)",
  publisherIpi: "Publisher IPI (Optional)",
  selectProSociety: "Select PRO Society",
  enterIpi: "Enter IPI",
  requireDownloadersTo: "Require Downloaders to",
  freeDownloadUsagePolicy: "Free Download Usage Policy",
  pleaseNoteFreeDownload:
    "Please note that free downloads are for non-profit use only. This means no distribution, public performances, or monetization. Enjoy responsibly!",
  useTrackCommercially:
    "To use this track commercially, you'll need a license from the producer. You can buy one right where you got the free version, if available.",
  disagree: "Disagree",
  agree: "Agree",
  followForAFreeDownload: "Follow for a Free Download",
  desFollowGetDownload:
    "Tap 'Follow' to get your free download! Once you're following the creator, the file is all yours.",
  sortBy: "Sort By",
  proSocietyIsRequired: "Please include your IPI with the PRO selection.",
  minIpi: "IPI min 9 characters",
  maxIpi: "IPI max 11 characters",
  referralProgram: "Referral Program",
  referrals: "Referrals",
  totalReach: "Total Reach",
  totalUsersReferred: "Total Users Referred",
  totalSuccessfullReferrals: "Total Successful Referrals",
  referralsConverted: "Referrals Converted",
  pendingInvites: "Pending Invites",
  inviteYourFriendsEmail: "Invite your friends through email",
  doubleTheBenefits: "Double the Benefits: Refer and Earn!",
  invite: "Invite",
  desSharingYourReferralLink:
    "Sharing your referral link to give your friends a 5% off discount on their first-annual membership. If they use it, we’ll reward you with $5 commission too!",
  criteriaNewReferrals: "Criteria for New Referrals",
  uniqueNewSubscriber: "1. Unique New Subscriber:",
  desUniqueNewSubscriber:
    "Each referred subscriber must have aunique email and cannot have a pre-existing Superproductor account.",
  noSelfReferral: "2. No Self-Referral:",
  desNoSelfReferral:
    "Users cannot refer themselves; the referrer's and referee's details must be distinct.",
  successfullPayment: "3. Successful Payment:",
  desSuccessfullPayment:
    "The referral is only validated when the new subscriber makes a successful payment for their first-annual membership.",
  validReferralLink: "4. Valid Referral Link:",
  desValidReferralLink:
    "Referrals must use the unique link provided by an existing Superproductor user for signup.",
  activeAccount: "5. Active Account:",
  desActiveAccount:
    "The new subscriber should show activity (e.g., log in, use features) within the first 30 days post-signup.",
  termsOfService: "Terms of Service",
  shareThroughSocialMedia: "Share through social media ",
  yourShareLink: "Your share link",
  commissionForYou:
    "$5 Commissions for You, 5% Discount for New Subscribers off their first-annual membership.",
  recentReferrals: "Recent Referrals",
  users: "Users",
  comissionsEarn: "Comissions Earn",
  emailAdressIsRequired: "Email Address is required field",
  emailAdressIsNotValid: "Email Address is not valid",
  sendLinkReferralSuccess: "Send link referral successful",
  noRecentReferrals: "No Recent Referrals",
  licenseStatus: "License Status",
  expireOn: "Expire On",
  indefinitely: "Indefinitely",
  congratulationsUnlock: "Congratulations! You've unlocked a special offer!",
  doubleYourRewards:
    "Double Your Rewards: Two Exciting Choices for Your Referral Sign-up",
  signUpAndEarn: "Sign Up & Earn: Annual Subscription,",
  noCommissionFee: "No Commission Fee!",
  tryMainstream: "Try Mainstream Monthly Plan for Free:",
  absolutelyFree: "1 Month, Absolutely Free!",
  activateNow: "Activate Now",
  monthlyReferrals:
    "📆 Monthly Referrals: Refer friends to get a 1-month free trial.",
  asTheReferrer:
    "As the referrer, you earn $5 credits for each successful referral; remember, the 10% commission on sales still applies.",
  annualReferrals:
    "🎉 Annual Referrals: Refer friends to enjoy a special annual rate of $199.99 (just $16.67/month). As the referrer, you will earn $10 credits per referral, capped at $200.",
  commissionWaiverOnReferral:
    "Plus, the 10% commission waiver on referral sales is the cherry on top!",
  creditBalance: "Credit Balance",
  enterEmailAddress: "Enter email address",
  unlockRewards: "Unlock Rewards: Share & Earn!",
  dashboardOverview: "Dashboard Overview",
  monthlyActiveListeners: "Monthly Active Listeners",
  thanLastMonth: "than last month",
  dateJoined: "Date joined",
  totalCustomers: "Total Customers",
  totalRefers: "Total Refers",
  totalRevenue: "Total Revenue",
  totalItems: "Total Items",
  lastLoggedInOn: "Last logged in on ",
  quickAnalytics: "Quick Analytics: ",
  yourDataAtAGlance: "Your Data at a Glance",
  repost: "Repost",
  newFollowers: "New Followers",
  upcomingLicenseExpiry: "Sold Licenses Monitoring",
  search: "Search",
  sendReminder: "Send Reminder",
  totalFavorites: "Total Favorites",
  totalRepost: "Total Repost",
  notificationLicenseExpiry: "Your license for ",
  isAboutToExpire: "is about to expire.",
  pleaseRenewToContinue: " Please renew to continue enjoying the product.",
  sendReminderSuccess: "Send Reminder Successful",
  noUpcomingLicenseExpiry: "No Upcoming License Expiry",
  freeDownloadButton: "Free Download",
  audioFilesWav: "Audio File for WAV download",
  pending: "Pending",
  last30Days: "Last 30 Days",
  last90Days: "Last 90 Days",
  last365Days: "Last 365 Days",
  last730Days: "Last 730 Days",
  verificationCode: "Verification Code",
  pleaseEnterYourConfirmationWeSentViaEmail:
    "Please enter your confirmation we sent via email",
  theSaferEasierWayToPay: "The Safer, easier way to pay",
  desDiconnectStripe:
    "Disconnecting your Stripe account will prevent you from receiving payouts and offering services and products on our website",
  trackNo: "Track no",
  licenseAndContract: "License & Contract",
  dateReleased: "Date Released",
  fileDelivery: "File Delivery",
  actions: "Actions",
  packNo: "Pack no",
  soundPackType: "Sound Kit Type",
  playlistNo: "Playlist no",
  playlistStatus: "Playlist Status",
  playlistData: "Playlist Data",
  dateAdded: "Date Added",
  trackAvailability: "Track Availability",
  chooseYourLicense: "Choose Your License",
  uploadTrackFiles: "Upload Track Files",
  autoTagged: "Auto Tagged",
  customTagged: "Custom Tagged",
  trackoutStems: "Trackout Stems",
  reviewFiles: "Review Files",
  previousStep: "Previous Step",
  nextStep: "Next Step",
  selectAFile: "Select a file",
  uploadGuidelinesForUntaggedWavFiles:
    "Upload Guidelines for Untagged WAV Files",
  forOptimalQualityUploadUntagged:
    "For optimal quality, upload untagged WAV files at 44.1 kHz/24-bit or higher. Simply drag your file into the untagged audio section. ( WAV file size to no more than 164 MB ).",
  uploadingWavFileHereAutomatically:
    "Uploading a WAV file here automatically generates an mp3 version with a 'Superproductor' tag for track protection.",
  uploadGuidelinesForUntaggedMp3Files: "Upload Guidelines for Tagged MP3 Files",
  forOptimalPerformanceUploadYourMp3:
    "For optimal performance, upload your MP3 with custom tags (128kbps, 44.1kHz recommended)( MP3 file size to no more than 164 MB ).",
  thisFileWillBeAvailableForStreaming:
    "This file will be available for streaming use or free download if its offered. Otherwise, the default Superproductor tag will be applied.",
  uploadGuidelinesForTrackoutZipFiles:
    "Upload Guidelines for Trackout ZIP Files",
  uploadZipFileOfYourTrackStems:
    "Upload a ZIP file (ZIP no more than 1GB) of your track stems. Although optional, stems are often expected for pro licenses or exclusive agreements.",
  includingThemCanSignificantlyIncrease:
    "Including them can significantly increase the appeal of your track to potential buyers.",
  proceedAddTrack: "Proceed to Add Track",
  wavOrMp3FilesSizeNoMoreLargerThan:
    "( WAV files size no more larger than 164 MB )",
  mp3FileSizeNoMoreLargerThan: "( MP3 file size no more larger than 164 MB )",
  zipFileSizeNoMoreLargerThan: "ZIP file size no more than 2GB",
  minimunMegabyte: "Please select an audio has size less than 164 MB",
  minimunGigabyte: "Please select an audio has size less than 2GB",
  uploadReady: "Tracks Successfully Uploaded",
  goToEditTrack: "Proceed to Edit Track",
  proceedToEdit: "Proceed to Edit",
  fileSelectionNeededForNextTrack: "File Selection Needed for Next Track",
  stepIsCrucialToAchieve:
    "This step is crucial to achieve a perfect match between your delivered files and your contracts.",
  readyToFineTuneYourTrack:
    "Ready to fine-tune your tracks? Edit track details for seamless release.",
  wavFilesAutoConverted: "WAV files auto-converted 320 kbps MP3 copies",
  weAutomaticallyConvertYourWavFilesToMp3:
    "We automatically convert your WAV files to 320 kbps MP3s, guaranteeing high-quality audio for both delivery and streaming. This ensures your music is ready to impress, with no extra work from you.",
  mp3StreamingTracksTagAutoGenerated: "MP3 Streaming Tracks Tag Auto-Generated",
  autoGeneratedMp3TagsSimplifyYourTrack:
    "Auto-generated MP3 tags simplify your track's streaming and boost security. Your music is set for discovery and enjoyment on our platforms by listeners worldwide.",
  yourOfferIsLowerThanMinimumOfferPrice:
    "Your offer is lower than the minimum offer price",
  resumeEditingYourTrackAwait: "Resume Editing: Your Tracks Await",
  desResumeEditingYourTrackAwait:
    "Additional tracks are awaiting to be edited. Please navigate to 'Review your Uploads' your tracks are closer to reach your audience.",
  reviewYourUploads: "Review your Uploads",
  enabled: "Enabled",
  highlight: "Highlight",
  popular: "POPULAR",
  quickStepNeeded: "Quick Step Needed! 👍 ",
  toAddItemsToYourCart:
    "To add items to your cart, a quick sign-in or account setup is required. It helps keep your shopping secure and personal. ",
  shoppingContinue:
    "Don’t worry, it’s quick and you’ll return right back to this page to continue your shopping journey with us.",
  alreadyHaveAnAccount: "Already have an account?",
  promoCodeAccepted: "Promo code accepted!",
  desPromoCodeAccepted:
    "We've applied your promo code. Your discount is reflected in the total. Ready to place your order?",
  share: "Share",
  like: "Like",
  goToTrack: "Go to Track",
  goToProfile: "Go to Profile",
  unlike: "UnLike",
  buy: "Buy",
  goToSoundPack: "Go to Sound Kit",
  coverArt: "Cover Art",
  badges: "Badges",
  paymentsActive: "Payments Active",
  hotProducer: "Hot Producer",
  spVerified: "SP Verified",
  searchByArtistSpotify: "Search by Aritst in Spotify",
  searchByTracktSpotify: "Search for Track",
  artistWhoIWorkWith: "Artist who I work with",
  artists: "Artists",
  expertise: "Expertise",
  daw: "DAW",
  accountSettings: "Account Settings",
  loginAndSecurity: "Login & Security",
  currentPassword: "Current Password",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  passwordMustBeAtLeastCharacters: "Password must be at least 8 characters",
  passwordDoesNotMatch: "Password does not match",
  logoutAllDevices: "Log out of all devices",
  Connect: "Connect",
  searchListMailChimp: "Search list mailchimp",
  linkedAccounts: "Third-Party Marketing Add-Ons",
  emailCapture: "Email Capture",
  inputYourEmailAddressAndFullName: "Input Your Email Address and Full Name",
  weWillDeliverYourFreeTrackToTheEmailAddress:
    "We will deliver your free track immediately. Thank You!",
  emailAddress: "Email Address",
  provideEmailAddress: "Provide Email Address",
  provideName: "Provide Name",
  fullName: "Full Name",
  continueUploadMoreTracks: "Continue Uploading More Tracks",
  startOverWithNewUpload: "Start Over with New Upload",
  bulkTrackUpload: "Bulk Track Upload",
  lastName: "Last Name",
  provideLastName: "Provide Last Name",
  customers: "Customers",
  myCustomers: "My Customers",
  type: "Type",
  downloadDate: "Download Date",
  downloadedItem: "Downloaded Item",
  downloadOn: "Download on",
  searchEmail: "Search Email",
  deleted: "Deleted",
  recentCustomerActivity: "Recent Customer Activity",
  thatJustMonth: "That's just $1.67 / month!",
  startNowDayTrial: "Start Now 7-Day Trial",
  deactivateTrial: "Deactivate Trial",
  upgradeYourPlanToContinue: "Upgrade your plan to continue",
  featureIsLocked: "This Feature Is Locked—Upgrade Your Plan to Gain Access",
  upgradeYourPlan: "Upgrade your plan",
  titleUpgradeYourPlanToEnableFreeDownload:
    "Upgrade Your Plan to Enable Free Download",
  desUpgradeYourPlanToEnableFreeDownload:
    "Hey there! To enable free download options please upgrade your plan",
  titleUpgradeYourPlanAddCollaborator: "Upgrade Your Plan to Add Collaborator",
  desUpgradeYourPlanAddCollaborator:
    "Hey there! To add collaborator please upgrade your plan",
  titleUpgradeYourPlanChooseAContractExclusive:
    "Upgrade Your Plan to Choose A Contract Exclusive",
  desUpgradeYourPlanChooseAContractExclusive:
    "Hey there! To choose a contract exclusive please upgrade your plan",
  chooseYourFeeStructure: "Choose Your Fee Structure",
  agreedToCoverFee: "I agreed to cover the 10% fee",
  buyerCoverFee: "Buyer covers the 10% fee",
  joinNow: "Join Now!",
  selectChooseYourFeeStructure: "Select Choose Your Fee Structure",
  totalService: "Total Service",
};
