import ToastPlanSubscription from "app/components/toast/toastplan";
import { AddSoundkitState } from "app/redux/reducers/addSoundkit";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";

export default class AddSoundKitService {
  static async submitSoundKit(soundKit: AddSoundkitState) {
    try {
      const response = await axiosService.post("/soundkit", soundKit);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async createSKDraft(draftSoundkit: AddSoundkitState) {
    try {
      const response = await axiosService.post(
        `/soundkit/draft`,
        draftSoundkit
      );
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async editSoundKit(body: AddSoundkitState) {
    try {
      const response = await axiosService.put(`/soundkit/${body._id}`, body);
      return response?.data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async checkUploadSoundPacks() {
    try {
      const response = await axiosService.get(
        "/subscriptions/check-upload-track",
        {
          params: { isAddSoundPack: true },
        }
      );
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async updateSKDraft(draft: AddSoundkitState) {
    try {
      const response = await axiosService.patch(
        `/soundkit/${draft._id}/draft`,
        draft
      );

      return response?.data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deleteSoundkitRelease(soundkitId: string | undefined) {
    try {
      await axiosService.delete(`/soundkit/${soundkitId}`);
      ToastPlanSubscription({ description: "Delete success", isError: false });
      return soundkitId;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({
        description: "Cannot delete the release soundkit",
      });
      throw new Error(message);
    }
  }

  static async deleteSoundkitDraft(soundkitId: string | undefined) {
    try {
      await axiosService.delete(`/soundkit/${soundkitId}/draft`);
      return soundkitId;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({
        description: "Cannot delete the draft soundkit",
      });
      throw new Error(message);
    }
  }
}
