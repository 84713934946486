import React from "react";

interface IconShareFeedPageMobileProps {
  color?: string;
}
const IconShareFeedPageMobile = ({
  color = "#D4F70E",
}: IconShareFeedPageMobileProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 20H6C4.89543 20 4 19.1046 4 18V7C4 5.89543 4.89543 5 6 5H10V7H6V18H17V14H19V18C19 19.1046 18.1046 20 17 20ZM11.7 13.707L10.29 12.293L16.583 6H13V4H20V11H18V7.415L11.7 13.707Z"
        fill={color}
      />
    </svg>
  );
};
export default IconShareFeedPageMobile;
