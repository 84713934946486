import {
  Box,
  Image,
  Text,
  Spinner,
  AbsoluteCenter,
  Flex,
} from "@chakra-ui/react";
import Plus from "app/assets/icons/plus";
import paypal from "app/assets/images/PaypalIcon.svg";
import stripe from "app/assets/images/Stripe.svg";
import PaypalCardImage from "app/assets/images/stripe_bank_account.png";
import { User } from "app/models";
import {
  totalPaypalProfitSelector,
  totalStripeProfitSelector,
} from "app/redux/selectors/paymentWallets";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

interface CartPaymentProps {
  isPaypal?: boolean;
  legalName?: string;
  addWallets?: boolean;
  userAdmin: User;
  accountDetails?: any;
  onAddWallet?: () => void;
  isLoadingAddPayment?: boolean;
}

export default function CardPayment({
  isPaypal,
  legalName,
  addWallets = false,
  userAdmin,
  accountDetails,
  onAddWallet,
  isLoadingAddPayment,
}: CartPaymentProps) {
  const { t } = useTranslation();
  const totalPaypalProfit = useSelector(totalPaypalProfitSelector);
  const totalStripeProfit = useSelector(totalStripeProfitSelector);

  return (
    <Box>
      <Box position="relative" w={{ base: "100%", md: "420px" }}>
        <Image
          height="210px"
          src={PaypalCardImage}
          fallbackSrc={PaypalCardImage}
        />
        {!addWallets ? (
          <Box>
            <Box pl="20px" position="absolute" top="20px">
              <Image
                top="0px"
                left={{ base: "8px", md: "8px" }}
                position="absolute"
                height="22px"
                src={isPaypal ? paypal : stripe}
                fallbackSrc={isPaypal ? paypal : stripe}
              />
              <Text
                mt="60px"
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="500"
                color="rgba(255, 255, 255, 0.5)"
              >
                {isPaypal ? t("businessName") : t("bankAccount")}
              </Text>
              <Text fontSize={{ base: "14px", md: "16px" }}>
                {isPaypal ? legalName : accountDetails?.bank_name}
              </Text>
            </Box>

            {!isPaypal && (
              <>
                <Text position="absolute" top="150px" left="20px">
                  {accountDetails?.routing_number} **** {accountDetails?.last4}
                </Text>
              </>
            )}
            <Box position="absolute" top="150px" right="20px">
              <Text
                fontSize="14px"
                lineHeight="17px"
                fontWeight="500"
                textAlign={{ base: "end", md: "start" }}
              >
                Total Profit
              </Text>
              <Text
                color="#D4F70E"
                fontSize="24px"
                lineHeight="38px"
                fontWeight="600"
              >
                $
                {isPaypal
                  ? Number(totalPaypalProfit)?.toFixed(2)
                  : Number(totalStripeProfit)?.toFixed(2)}
              </Text>
            </Box>
          </Box>
        ) : (
          <>
            <AbsoluteCenter>
              <Flex
                justifyContent="center"
                direction="column"
                alignItems="center"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="28px"
                  height="28px"
                  bg="black"
                  borderRadius="50%"
                  onClick={onAddWallet}
                  cursor="pointer"
                >
                  {isLoadingAddPayment ? <Spinner color="#d4f70e" /> : <Plus />}
                </Flex>
                <Text fontSize="12px" mt="6px">
                  {userAdmin?.sellerConnectedPayPal
                    ? t("addStripe")
                    : t("addPaypal")}
                </Text>
              </Flex>
            </AbsoluteCenter>
          </>
        )}
      </Box>
    </Box>
  );
}
