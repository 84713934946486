import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { ItemNotifications } from "app/models";
import EmptyNotification from "app/pages/Notifications/EmptyNotification";
import NotificationItem from "app/pages/Notifications/NotificationItem";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import { useNotification } from "hooks/useNotifications/useNotifications";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import ModalNegotiations from "../Negotiations/NegotiationsDashboard/ModalNegotiations/modalNegotiations";
import "./styles.scss";
import { NOTIFICATION_TYPE } from "constants/statusType";
import { heightLoadMore } from "utils/convertTracks";
import { FadeLoader } from "react-spinners";

const NotificationDetail = () => {
  const {
    onGetNotifications,
    data,
    totalNotificationsUnread,
    totalNotifications,
    onReadNotifications,
    optionsNotifications,
  } = useNotification();
  const location = useLocation();
  const history = useHistory();
  const typeParams = queryString.parse(location.search);
  const [notificationTab, setNotifiationTab] = useState(0);

  useEffect(() => {
    onGetNotifications({ page: 1 });
  }, [onGetNotifications]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(optionsNotifications, e)) {
        onGetNotifications({
          page: 1 + optionsNotifications.page,
          loadingMore: true,
        });
      }
    },
    [onGetNotifications, optionsNotifications]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const {
    showNegotiationDetail,
    setShowNegotiationDetail,
    negotiationTrack,
    setShowContent,
    showContent,
    handleSelecNotification,
    isLoadingShowModalNotification,
  } = useNegotiations();

  const handleShowSelectNegotiation = useCallback(
    (idNegotiation, idNotification) => {
      onReadNotifications({ ids: [idNotification] });
      handleSelecNotification(idNegotiation);
      setShowNegotiationDetail();
      setShowContent({
        showContentNegotiationDeal: true,
        showNegotiationOffer: false,
        showSubmitNegotiation: false,
      });
    },
    [
      setShowNegotiationDetail,
      setShowContent,
      onReadNotifications,
      handleSelecNotification,
    ]
  );

  const nameParams = useCallback((value) => {
    const textParams: { [index: number]: string } = {
      0: NOTIFICATION_TYPE.ALL,
      1: NOTIFICATION_TYPE.UNREAD,
    };
    return textParams[value];
  }, []);

  const handleChangeTab = useCallback(
    (value) => {
      setNotifiationTab(value);
      const params = nameParams(value);
      history.push(`?notificationType=${params}`);
    },
    [history, nameParams]
  );

  useEffect(() => {
    const notificationTypes = [NOTIFICATION_TYPE.ALL, NOTIFICATION_TYPE.UNREAD];
    const selectedTab =
      notificationTypes.indexOf(typeParams?.notificationType) || 0;
    setNotifiationTab(selectedTab);
  }, [typeParams.notificationType]);

  return (
    <Box className="notificationDetail-component">
      <Tabs
        className="tabs-panels"
        variant="unstyled"
        onChange={handleChangeTab}
        index={notificationTab}
      >
        <TabList overflow="auto" className="tab-list-panels">
          <Tab className="tab" _selected={{ boxShadow: "none" }}>
            <Flex w="100%" justifyContent="space-between">
              <Text ml="55px">{`All (${totalNotifications})`} </Text>
              <Box
                height="25px"
                borderLeft="1px solid rgba(255, 255, 255, 0.2)"
              ></Box>
            </Flex>
          </Tab>
          <Tab className="tab-two" _selected={{ boxShadow: "none" }}>
            <Flex w="100%" justifyContent="space-between">
              <Text mb="3px" ml="55px">
                {`Unread (${totalNotificationsUnread})`}
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel padding="0px" pt="32px">
            <Box>
              <Box w={{ base: "100%", lg: "85%" }}>
                {data?.length > 0 ? (
                  (data || [])?.map((item, index) => {
                    return Object.keys(item).map((timeTitle) => {
                      return (
                        <Box key={index}>
                          <Text
                            fontSize={{ base: "24px", md: "28px" }}
                            lineHeight="24px"
                            fontWeight="500"
                            m="10px 0px"
                          >
                            {timeTitle}
                          </Text>
                          {(item[timeTitle] || [])?.map(
                            (
                              notification: ItemNotifications,
                              index: number
                            ) => {
                              return (
                                <NotificationItem
                                  key={index}
                                  notification={notification}
                                  handleShowSelectNegotiation={
                                    handleShowSelectNegotiation
                                  }
                                />
                              );
                            }
                          )}
                          <Box display="flex" justifyContent="center">
                            <FadeLoader
                              color={"#d4f70e"}
                              loading={optionsNotifications.loadingMore}
                            />
                          </Box>
                        </Box>
                      );
                    });
                  })
                ) : (
                  <EmptyNotification />
                )}

                <ModalNegotiations
                  showModal={showNegotiationDetail}
                  setShowModal={setShowNegotiationDetail}
                  negotiationsItem={negotiationTrack}
                  showContent={showContent}
                  setShowContent={setShowContent}
                  isLoading={isLoadingShowModalNotification}
                />
              </Box>
            </Box>
          </TabPanel>
          <TabPanel padding="0px" pt="32px">
            <Box>
              <Box w={{ base: "100%", lg: "85%" }}>
                {(data || [])?.map((item, index) => {
                  return Object.keys(item).map((timeTitle) => {
                    const titleTime = (item[timeTitle] || [])?.filter(
                      (notification: ItemNotifications) => !notification.isRead
                    );
                    return (
                      <Box key={index}>
                        {titleTime.length > 0 && (
                          <Text
                            fontSize={{ base: "24px", md: "28px" }}
                            lineHeight="24px"
                            fontWeight="500"
                            m="10px 0px"
                          >
                            {timeTitle}
                          </Text>
                        )}
                        {titleTime.length > 0 &&
                          titleTime.map(
                            (
                              notificationItem: ItemNotifications,
                              index: number
                            ) => {
                              return (
                                <NotificationItem
                                  key={index}
                                  notification={notificationItem}
                                  handleShowSelectNegotiation={
                                    handleShowSelectNegotiation
                                  }
                                />
                              );
                            }
                          )}
                      </Box>
                    );
                  });
                })}
                <ModalNegotiations
                  showModal={showNegotiationDetail}
                  setShowModal={setShowNegotiationDetail}
                  negotiationsItem={negotiationTrack}
                  showContent={showContent}
                  setShowContent={setShowContent}
                  isLoading={isLoadingShowModalNotification}
                />
              </Box>
              {totalNotificationsUnread === 0 && <EmptyNotification />}
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NotificationDetail;
