import client from "services/axios.service";
import axios from "axios";

export const uploadFileService = (
  file: File,
  onProgress: Function,
  isUploadFiles?: boolean,
  updateValue = ""
): Promise<string | void> => {
  return new Promise(async (resolve, reject) => {
    const isImage = file.type.includes("image/");
    try {
      let linkResponse;
      const body = {
        name: file.name,
        type: file.type,
        isPublic: isImage,
      };
      if (updateValue) {
        linkResponse = await client.put(`/storage/${updateValue}`, body);
      } else {
        linkResponse = await client.post("/storage", body);
      }
      const { signed, cloudFrondSigned } = linkResponse.data;

      const options = {
        headers: {
          "Content-Type": file.type,
        },
        onUploadProgress: (ev: { loaded: number; total: number }) => {
          onProgress && onProgress(Math.round((ev.loaded / ev.total) * 100));
        },
      };
      isUploadFiles && resolve(cloudFrondSigned);
      axios
        .put(signed, file, options)
        .then(() => {
          resolve(cloudFrondSigned);
        })
        .catch((err) => {
          reject(err);
        });
    } catch (err) {
      reject(err);
    }
  });
};
