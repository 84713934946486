import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from "app/apis/users";

export const TYPES = {
  CREATE_CONVERSATION: "CREATE_CONVERSATION",
};

export const getConversation = createAsyncThunk(
  TYPES.CREATE_CONVERSATION,
  async (userId: string) => await UserService.getConversation(userId)
);
