import { createSelector } from "@reduxjs/toolkit";

export const selectHomePgae = (state: any) => state.homePage;

export const selectorsHotProducers = createSelector(
  selectHomePgae,
  ({ hotproducersItem }) => {
    return hotproducersItem;
  }
);

export const selectRecentActionsItemsHomePage = createSelector(
  selectHomePgae,
  (homePage) => homePage?.recentActions?.items
);

export const selectRecentActionsOptionsHomePage = createSelector(
  selectHomePgae,
  (homePage) => {
    return homePage?.recentActions?.options;
  }
);

export const newArtistsSelector = createSelector(
  selectHomePgae,
  ({ newArtists }) => newArtists
);

export const newProducersSelector = createSelector(
  selectHomePgae,
  ({ newProducers }) => newProducers
);

export const recommendedMusicSelector = createSelector(
  selectHomePgae,
  ({ recommendedMusic }) => recommendedMusic
);

export const recommendedArtistsSelector = createSelector(
  selectHomePgae,
  ({ recommendedArtists }) => recommendedArtists
);

export const freeBeatsSelector = createSelector(
  selectHomePgae,
  ({ freeBeats }) => freeBeats
);

export const discountSelector = createSelector(
  selectHomePgae,
  ({ discount }) => discount
);
