import React from "react";

interface IconHeartFeedPageUnlikeProps {
  color?: string;
}

const IconHeartFeedPageUnlike = ({
  color = "#656566",
}: IconHeartFeedPageUnlikeProps) => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 21.0001C12.7744 20.4281 11.9543 19.8331 11.0869 19.2001H11.0757C8.02128 16.9801 4.55965 14.4681 3.03078 11.4581C2.52848 10.4999 2.26229 9.45727 2.25001 8.40015C2.24666 6.94963 2.90114 5.55913 4.06556 4.54291C5.22998 3.5267 6.80603 2.97054 8.43753 3.00015C9.76573 3.00201 11.0653 3.34323 12.1815 3.98315C12.672 4.26612 13.1157 4.6084 13.5 5.00015C13.8864 4.60994 14.3303 4.26786 14.8196 3.98315C15.9354 3.3431 17.2346 3.00187 18.5625 3.00015C20.194 2.97054 21.7701 3.5267 22.9345 4.54291C24.0989 5.55913 24.7534 6.94963 24.75 8.40015C24.7386 9.45896 24.4723 10.5033 23.9693 11.4631C22.4404 14.4731 18.9799 16.9841 15.9255 19.2001L15.9143 19.2081C15.0458 19.8371 14.2268 20.4321 13.5012 21.0081L13.5 21.0001ZM8.43753 5.00015C7.3896 4.98849 6.37886 5.34499 5.62503 5.99215C4.89871 6.62631 4.49277 7.49519 4.49993 8.40015C4.51277 9.17065 4.70908 9.93 5.07603 10.6281C5.79772 11.9269 6.77152 13.1022 7.95265 14.1001C9.06753 15.1001 10.35 16.0681 11.4593 16.8821C11.7664 17.1071 12.0792 17.3341 12.3919 17.5611L12.5888 17.7041C12.8891 17.9221 13.1996 18.1481 13.5 18.3701L13.5146 18.3581L13.5214 18.3531H13.5281L13.5383 18.3461H13.5439H13.5495L13.5698 18.3311L13.6159 18.2981L13.6238 18.2921L13.6361 18.2841H13.6429L13.653 18.2761L14.4 17.7311L14.5958 17.5881C14.9119 17.3591 15.2246 17.1321 15.5318 16.9071C16.641 16.0931 17.9246 15.1261 19.0395 14.1211C20.2208 13.1237 21.1946 11.9487 21.9161 10.6501C22.2897 9.94595 22.4888 9.17865 22.5001 8.40015C22.5047 7.49799 22.099 6.63244 21.375 6.00015C20.6226 5.35007 19.6117 4.99063 18.5625 5.00015C17.2822 4.99048 16.0582 5.46752 15.1988 6.31115L13.5 8.05115L11.8013 6.31115C10.9418 5.46752 9.71786 4.99048 8.43753 5.00015Z"
        fill={color}
      />
    </svg>
  );
};
export default IconHeartFeedPageUnlike;
