import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonCollaborations() {
  const renderSkeleton = () => {
    return (
      <>
        <Flex
          p={{
            base: "8px 12px",
            md: "8px 20px 8px 20px",
            xl: "8px 30px 8px 40px",
          }}
          background="#24252A"
          alignItems="center"
          borderRadius="8px"
        >
          <Skeleton w="98px" h="90px" borderRadius="6px" m="0px" />
          <Box w="100%">
            <Skeleton w={{ base: "90px", md: "120px" }} h="18px" />
            <Skeleton w={{ base: "40px", lg: "250px" }} h="18px" />
            <Flex justifyContent="space-between" alignItems="center">
              <Flex>
                <Skeleton w={{ base: "90px", md: "120px" }} h="18px" />
                <Skeleton
                  display={{ base: "none", xl: "block" }}
                  w="120px"
                  h="18px"
                />
              </Flex>
              <Flex>
                <Skeleton w="40px" h="40px" borderRadius="full" />
                <Skeleton w="40px" h="40px" borderRadius="full" />
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </>
    );
  };

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing={{ base: "20px", md: "30px" }}
    >
      {Array.from({ length: 6 }).map(() => renderSkeleton())}
    </SimpleGrid>
  );
}
