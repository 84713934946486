import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";
class DiscountsService {
  static async getBulkOffers(userId = "") {
    try {
      const response = await axiosService.get(
        `/bulk-offers${userId ? `/${userId}` : ""}`
      );
      const {
        data: { success, data },
      } = response;
      if (!success) {
        throw Error("Not successful");
      }
      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default DiscountsService;
