import React from "react";

interface IconRepostIndividualTrackProps {
  isRepost?: boolean
}

const IconRepostIndividualTrack = ({ isRepost }: IconRepostIndividualTrackProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="27" height="34" viewBox="0 0 27 34" fill="none">
      <path d="M11.1361 0.339244C11.0211 0.455467 10.9299 0.593738 10.8676 0.746084C10.8054 0.898431 10.7733 1.06184 10.7733 1.22688C10.7733 1.39192 10.8054 1.55532 10.8676 1.70767C10.9299 1.86002 11.0211 1.99829 11.1361 2.11451L14.7173 5.76506L4.90576 5.76506C3.60467 5.76506 2.35687 6.29193 1.43686 7.22975C0.516855 8.16758 -6.8726e-07 9.43954 -6.28608e-07 10.7658L-5.52866e-08 23.7303C-4.06237e-08 24.0619 0.129214 24.3798 0.359216 24.6143C0.589218 24.8488 0.901168 24.9805 1.22644 24.9805C1.55171 24.9805 1.86366 24.8488 2.09366 24.6143C2.32367 24.3798 2.45288 24.0619 2.45288 23.7303L2.45288 10.7658C2.45288 10.1027 2.71131 9.4667 3.17131 8.99779C3.63132 8.52887 4.25522 8.26544 4.90576 8.26544L14.7173 8.26544L11.1361 11.916C10.9003 12.1498 10.7652 12.4694 10.7606 12.8047C10.756 13.14 10.8822 13.4634 11.1115 13.7038C11.3409 13.9442 11.6545 14.0818 11.9834 14.0865C12.3123 14.0912 12.6295 13.9625 12.8654 13.7288L18.5438 7.9529C18.6557 7.83173 18.7469 7.69227 18.8136 7.54033C18.8785 7.39068 18.912 7.22887 18.912 7.06526C18.912 6.90165 18.8785 6.73984 18.8136 6.59019C18.7516 6.43569 18.6598 6.2954 18.5438 6.17762L12.8654 0.389251C12.6421 0.149637 12.3349 0.00986809 12.0108 0.000497299C11.6868 -0.00887349 11.3723 0.112918 11.1361 0.339244Z" fill={isRepost ? "#D4F70E" : "white"} />
      <path d="M15.1833 32.7711C15.2983 32.6549 15.3895 32.5166 15.4518 32.3643C15.514 32.2119 15.5461 32.0485 15.5461 31.8835C15.5461 31.7184 15.514 31.555 15.4518 31.4027C15.3895 31.2503 15.2983 31.1121 15.1833 30.9958L11.6021 27.3453L21.4136 27.3453C22.7147 27.3453 23.9625 26.8184 24.8825 25.8806C25.8025 24.9428 26.3194 23.6708 26.3194 22.3445L26.3194 9.38007C26.3194 9.0485 26.1902 8.73051 25.9602 8.49605C25.7302 8.2616 25.4182 8.12988 25.093 8.12988C24.7677 8.12988 24.4557 8.2616 24.2257 8.49605C23.9957 8.73051 23.8665 9.0485 23.8665 9.38007L23.8665 22.3445C23.8665 23.0077 23.6081 23.6437 23.1481 24.1126C22.6881 24.5815 22.0642 24.8449 21.4136 24.8449L11.6021 24.8449L15.1833 21.1944C15.4191 20.9606 15.5542 20.6409 15.5588 20.3057C15.5634 19.9704 15.4372 19.647 15.2078 19.4066C14.9785 19.1662 14.6649 19.0285 14.336 19.0238C14.0071 19.0191 13.6899 19.1478 13.454 19.3816L7.77562 25.1575C7.66367 25.2786 7.57247 25.4181 7.5058 25.57C7.44092 25.7197 7.4074 25.8815 7.4074 26.0451C7.4074 26.2087 7.44092 26.3705 7.5058 26.5202C7.56781 26.6747 7.65956 26.8149 7.77562 26.9327L13.454 32.7211C13.6773 32.9607 13.9845 33.1005 14.3086 33.1099C14.6326 33.1192 14.9471 32.9974 15.1833 32.7711Z" fill={isRepost ? "#D4F70E" : "white"} />
    </svg>
  );
};
export default IconRepostIndividualTrack;
