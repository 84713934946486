import { Box, Text } from "@chakra-ui/react";
import IconEmptyLyrics from "app/assets/icons/IconEmptyLyrics";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmptyLyrics() {
  const { t } = useTranslation();
  return (
    <Box
      m="50px auto"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minH="200px"
    >
      <IconEmptyLyrics />
      <Text
        m="0px 0px 0px 10px"
        fontSize={{ base: "14px", md: "16px" }}
        lineHeight="20px"
        color="#535353"
      >
        {t("noLyricsYet")}
      </Text>
    </Box>
  );
}
