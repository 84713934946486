import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  ModalHeader,
  Flex,
  Box,
  Image,
  Button,
  CloseButton,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, Controller } from "react-hook-form";
import { useMyTrack } from "hooks/track/useMyTrack";
import TextInput from "../TextInput";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllUser } from "app/redux/actions/myTrack";
import Avatar from "app/assets/images/UserAvatar.png";
import "./styles.scss";
import { CheckBoxField } from "../CustomFIeld/CheckBoxField/CheckBoxField";
import { selectUserAdmin } from "app/redux/selectors/users";
import { TrackSoundKit } from "app/models";
import { shortLinkTrackSoundkitDetail } from "utils/getData";
import { BeatLoader } from "react-spinners";

interface ModalLinkExpirationProps {
  showModal: boolean;
  setShowModal: () => void;
  selectedTrack: TrackSoundKit | null;
  setShowModalSuccess: () => void;
}

export default function ModalLinkExpiration({
  setShowModal,
  showModal,
  selectedTrack,
  setShowModalSuccess,
}: ModalLinkExpirationProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { methods, allUser } = useMyTrack();
  const currentUser = useSelector(selectUserAdmin);
  const [loadingCreateShortLink, setLoadingCreateShortLink] = useState(false);

  useEffect(() => {
    dispatch(getAllUser());
  }, [dispatch]);

  const convertAllUser = useMemo(() => {
    return (allUser || [])
      .filter((item) => item._id !== currentUser?._id)
      .map((user) => {
        return {
          value: user.name,
          label: user.name,
          profileImage: user.profileImage,
          id: user._id,
        };
      });
  }, [allUser, currentUser?._id]);

  const userSelected = methods.watch("user");
  const hostName = window.location.host;

  const handleCreateShortLinkEx = useCallback(
    async (values) => {
      const body = {
        originalURL: selectedTrack?.trackState
          ? `${hostName}/track/${selectedTrack?.slug}`
          : `${hostName}/soundkit/${selectedTrack?.slug}`,
        expiresAt: new Date(values?.expiresAt).valueOf(),
        user: values.user,
        description: values.description
          ? values.description
          : t("placeHolderLinkExpiration"),
        notifyUser: values.notifyUser,
        password: values.password,
      };
      setLoadingCreateShortLink(true);
      await shortLinkTrackSoundkitDetail(body, true);
      setLoadingCreateShortLink(false);
      setShowModalSuccess();
      setShowModal();
    },
    [
      hostName,
      selectedTrack?.slug,
      selectedTrack?.trackState,
      setShowModal,
      setShowModalSuccess,
      t,
    ]
  );

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent
        margin={{ base: "0px 16px", md: "0px" }}
        minW={{ base: "350px", md: "600px", lg: "740px" }}
      >
        <ModalHeader
          padding={{ base: "16px 16px", md: "16px 30px", lg: "16px 40px" }}
          fontSize={{ base: "24px", md: "32px" }}
        >
          {t("setLinkExpiration")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top="15px"
          fontSize="20px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "14px",
          }}
          padding="14px"
          color="#d4f70e"
        />
        <ModalBody
          padding={{
            base: "10px 20px",
            md: "10px 30px 50px 30px",
            lg: "10px 120px 100px 40px",
          }}
          className="modal-link-expiration"
          maxH={{ base: "550px", md: "700px" }}
          overflow="scroll"
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleCreateShortLinkEx)}>
              <Flex
                w="100%"
                alignItems="center"
                gridGap="10px"
                mb={userSelected?.length >= 3 ? "20px" : "0px"}
                display={{ base: "block", md: "flex" }}
              >
                <Text
                  w="30%"
                  mb={{ base: "10px", md: "25px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                >
                  {t("user")}
                </Text>
                <Box w={{ base: "100%", md: "70%" }}>
                  <Controller
                    name={"user"}
                    render={({ field }) => (
                      <Select
                        options={convertAllUser}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Search by username or email"}
                        isMulti
                        {...field}
                      />
                    )}
                    control={methods.control}
                  />
                  {methods.formState.errors?.user && (
                    <Text
                      fontSize="14px"
                      color="red"
                      marginTop="-20px"
                      mb="10px"
                    >
                      {t(methods.formState.errors?.user?.message)}
                    </Text>
                  )}
                </Box>
              </Flex>
              {userSelected?.length > 0 && (
                <>
                  <Flex
                    gridGap="10px"
                    mb="20px"
                    w="100%"
                    alignItems="center"
                    display={{ base: "block", md: "flex" }}
                  >
                    <Text
                      w={{ base: "100%", md: "30%" }}
                      fontSize={{ base: "14px", md: "16px" }}
                    >
                      {t("usersAdded")}
                    </Text>
                    <Flex
                      w={{ base: "100%", md: "70%" }}
                      marginLeft={{ base: "0px", md: "18px" }}
                      alignItems="center"
                    >
                      <Flex
                        w={{ base: "max-content", md: "60%" }}
                        wrap="wrap"
                        mt={{ base: "10px", md: "0px" }}
                      >
                        {(userSelected || []).map((item) => (
                          <Image
                            src={item?.profileImage}
                            fallbackSrc={Avatar}
                            w="40px"
                            height="40px"
                            borderRadius="full"
                            marginLeft={{ base: "0px", md: "-10px" }}
                            marginBottom="10px"
                          />
                        ))}
                      </Flex>

                      <Box
                        marginLeft={"20px"}
                        w={{ base: "max-content", md: "40%" }}
                      >
                        <CheckBoxField
                          name="notifyUser"
                          label="Notify Users"
                          control={methods.control}
                        />
                      </Box>
                    </Flex>
                  </Flex>
                  <Flex w="100%" gridGap="10px">
                    <Box w="30%" display={{ base: "none", md: "block" }} />
                    <Text
                      w={{ base: "100%", md: "70%" }}
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight="400"
                      color="#FEFFFF"
                      opacity="0.25"
                      mb={{ base: "10px", md: "20px" }}
                    >
                      {t("desUserAdded")}
                    </Text>
                  </Flex>
                </>
              )}

              <Flex
                mb={{ base: "20px", md: "30px" }}
                justifyContent="space-between"
                alignItems="center"
                gridGap="10px"
                w="100%"
                display={{ base: "block", md: "flex" }}
              >
                <Text
                  w="30%"
                  mb={{ base: "10px", md: "0px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                >
                  {t("fileDescription")}
                </Text>
                <Box w={{ base: "100%", md: "70%" }}>
                  <TextInput
                    className="input-textarea"
                    type="textarea"
                    name="description"
                    placeholder={t("placeHolderLinkExpiration")}
                    errors={methods.formState.errors}
                    control={methods.control}
                  />
                </Box>
              </Flex>
              <Flex
                w="100%"
                mb={{ base: "20px", md: "30px" }}
                justifyContent="space-between"
                alignItems="center"
                gridGap="10px"
                display={{ base: "block", md: "flex" }}
              >
                <Text
                  w="30%"
                  mb={{ base: "10px", md: "0px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                >
                  {t("expireAfter")}
                </Text>
                <Box w={{ base: "100%", md: "70%" }}>
                  <TextInput
                    className="date-time"
                    name="expiresAt"
                    type={"datetime-local"}
                    control={methods.control}
                  />
                  {methods.formState.errors?.expiresAt && (
                    <Text fontSize="14px" color="red" mt="5px">
                      {t("expireRequired")}
                    </Text>
                  )}
                </Box>
              </Flex>
              <Flex
                w="100%"
                mb={{ base: "20px", md: "30px" }}
                justifyContent="space-between"
                alignItems="center"
                gridGap="10px"
                display={{ base: "block", md: "flex" }}
              >
                <Box
                  w={{ base: "100%", md: "30%" }}
                  mb={{ base: "10px", md: "0px" }}
                >
                  <Text fontSize={{ base: "14px", md: "16px" }}>
                    {t("createPassword")}
                  </Text>
                  <Text fontSize="14px" fontWeight="400" color="#535353">
                    ({t("optional")})
                  </Text>
                </Box>
                <Box w={{ base: "100%", md: "70%" }}>
                  <Flex alignItems="center">
                    <Box w="100%">
                      <TextInput
                        className="date-time"
                        name="password"
                        type={"text"}
                        errors={methods.formState.errors}
                        control={methods.control}
                      />
                    </Box>

                    <Button
                      background="rgba(216, 253, 1, 0.20)"
                      color="#D8FD01"
                      border="1px solid #D8FD01"
                      height="47px"
                      borderRadius="4px"
                      padding="16px 35px"
                      marginTop="4px"
                      isLoading={loadingCreateShortLink}
                      type="submit"
                      _hover={{ background: "rgba(216, 253, 1, 0.20)" }}
                      spinner={<BeatLoader size={8} color="white" />}
                    >
                      {t("done")}
                    </Button>
                  </Flex>
                </Box>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
