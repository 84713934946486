import { Box, Flex, Image, Text } from "@chakra-ui/react";
// import CloseAudioIcon from "app/assets/icons/CloseAudio";
import LoopAudioIcon from "app/assets/icons/LoopAudio";
import NextIcon from "app/assets/icons/NextIcon";
import PreviousIcon from "app/assets/icons/PreviousIcon";
import ShuffleAudioIcon from "app/assets/icons/ShuffleAudio";
import AvataRecentActions from "app/assets/images/ImageDefaultLoading.png";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import TooltipLabel from "app/components/TooltipLabel";
import { LOOP_TYPE } from "constants/audioOptions";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback, useEffect, useMemo } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { TYPE_PARAMS } from "utils/actionText";
import { formatAudioDuration } from "utils/formatAudioDuration";
import MarqueeText from "../MarqueeText";
import SoundControl from "./SoundControl";
import "./styles.scss";
import WaveForm from "./WaveForm";
import CartIcon from "../CartIcon";
import { getPriceTrack } from "utils/getPrice";
import { useSelector, useDispatch } from "react-redux";
import { isInCartSelector } from "app/redux/selectors/cart";
import { TrackSoundKit } from "app/models";
import { removeFromCart, addToCart } from "app/redux/actions/cart";
import TagItem from "./TagItem";
import ImageAudioBottom from "./ImageAudioBottom";
import IconToggleModeFooterPlayer from "app/assets/icons/IconToggleModeFooterPlayer";
import { selectLoggedIn } from "app/redux/selectors/users";
import { CgAddR } from "react-icons/cg";
import { formatNumber, isNotForSaleTrackSoundPacks } from "utils/convertTracks";
import BiDotsComlumnFooterPlayer from "app/assets/icons/biDotColumnFooterPlayerMobile";

interface FooterPlayerProps {
  onOpenOptionsModal: (track: TrackSoundKit, isLyric?: boolean) => void;
  handleShowModalAddTrack: (id: string, isPrivate?: boolean) => void;
  setShowModalProfile?: (value: boolean) => void;
}

const FooterPlayer = ({
  onOpenOptionsModal = () => {},
  handleShowModalAddTrack = () => {},
  setShowModalProfile = () => {},
}: FooterPlayerProps): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    showFooterPlayer,
    isLoading,
    playingTrack,
    waveformRef,
    wavesurfer,
    isPlaying,
    loopType,
    isShuffle,
    volume,
    loadWaveform,
    handleTogglePlayPause,
    setIsAudioPlay,
    handleNextOrPrevious,
    onSetLoopAudioType,
    onToggleShuffleAudio,
    onVolumeChange,
    handleChangeCurrentTime,
    hanldeShowImageBottom,
  } = usePlayers();
  const isInCart = useSelector(isInCartSelector(playingTrack));
  const loggedIn = useSelector(selectLoggedIn);

  const checkTypeMusic = useMemo(() => {
    return !!playingTrack?.trackState;
  }, [playingTrack?.trackState]);

  const isExclusive = useMemo(
    () => (playingTrack?.licenses || []).every((license) => license.exclusive),
    [playingTrack?.licenses]
  );
  const isMakeOffersOnly = useMemo(
    () =>
      (playingTrack?.licenses || []).every(
        (license) => license?.makeOffersOnly
      ),
    [playingTrack?.licenses]
  );

  useEffect(() => {
    if (wavesurfer.current) {
      wavesurfer.current.destroy();
    }
    loadWaveform();
    return () => wavesurfer.current?.destroy();
  }, [loadWaveform, wavesurfer]);

  useHotkeys("space", (e) => {
    e.preventDefault();
    handleTogglePlayPause();
  });

  const handleClosePlay = useCallback(() => {
    wavesurfer.current.pause();
    wavesurfer.current = null;
    setIsAudioPlay(false);
  }, [setIsAudioPlay, wavesurfer]);

  useEffect(() => {
    var slider = document.getElementById("player-progress-audio");
    var slidertitle = document.getElementById("slidertitle");
    var totalTime = document.getElementById("totalTime");
    if (slider && slidertitle) {
      var sliderOffsetX =
        slider.getBoundingClientRect().left -
        document.documentElement.getBoundingClientRect().left;
      var sliderWidth = slider.offsetWidth - 1;

      slider.addEventListener("mousemove", function (event) {
        var currentMouseXPos =
          event.clientX + window.pageXOffset - sliderOffsetX;
        var sliderValAtPos = Math.round((currentMouseXPos / sliderWidth) * 100);
        const currentTime = (sliderValAtPos / 100) * totalTime?.innerHTML;
        slidertitle.innerHTML = formatAudioDuration(currentTime);
        slidertitle.style.top = event.offsetY;
        slidertitle.style.left = event.offsetX - sliderValAtPos + "px";
      });
    }
  }, []);
  const isDisabled = isNotForSaleTrackSoundPacks(playingTrack);

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart([playingTrack?._id]));
  }, [dispatch, playingTrack?._id]);
  const addToCarts = useCallback(() => {
    if (isDisabled) {
      return;
    }
    if (isInCart) {
      handleRemoveToCartButton();
    } else if (!checkTypeMusic) {
      dispatch(addToCart(playingTrack));
    } else {
      onOpenOptionsModal(playingTrack as TrackSoundKit, false);
    }
  }, [
    checkTypeMusic,
    dispatch,
    handleRemoveToCartButton,
    isInCart,
    onOpenOptionsModal,
    playingTrack,
    isDisabled,
  ]);

  return (
    <Box>
      <Box display={showFooterPlayer ? "block" : "none"}>
        <Box className="container-progress-audio">
          <div id="container">
            <input
              id="player-progress-audio"
              className={`progress-bar`}
              onChange={handleChangeCurrentTime}
              type="range"
              min="1"
              max="100"
              defaultValue="0"
              step="0.1"
            />
            <span id="slidertitle"></span>
            <span style={{ display: "none" }} id="totalTime"></span>
          </div>
        </Box>
        <Flex
          w="100%"
          gap="10px"
          className="container-footer"
          justifyContent="space-between"
          zIndex={10}
          padding={{ base: "15px", md: "19px 25px", lg: "19px 40px" }}
        >
          <Flex
            position="relative"
            className=""
            alignItems="center"
            w={{ base: "70%", md: "43%" }}
          >
            <Image
              width="60px"
              height="60px"
              objectFit="cover"
              src={playingTrack?.coverUrl}
              borderRadius={"4px"}
              fallbackSrc={AvataRecentActions}
              opacity={{ base: 0.4, md: 1 }}
            />
            <Flex
              position="absolute"
              left="10px"
              display={{ base: "flex", md: "none" }}
            >
              <PlayButton
                handleTogglePlayPause={handleTogglePlayPause}
                isPlaying={isPlaying}
                size={40}
                isLoading={isLoading}
              />
            </Flex>
            <Box
              className="title"
              marginLeft={{ base: "10px", md: "15px" }}
              w={{ base: "180px", md: "210px", lg: "350px" }}
            >
              <Link
                to={`/${
                  checkTypeMusic ? TYPE_PARAMS.track : TYPE_PARAMS.soundKit
                }/${playingTrack?.slug}`}
              >
                <MarqueeText text={playingTrack?.title} />
              </Link>

              <Flex alignItems="center" flexWrap="wrap">
                {playingTrack?.type && TRACK_TYPE[playingTrack?.type] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    height={{ base: "16px", md: "20px" }}
                    borderRadius="4px"
                    padding="0px 5px"
                    mr="5px"
                    mb={{ base: "0px", md: "5px" }}
                    whiteSpace="nowrap"
                    background="rgba(212, 247, 14, 0.1)"
                  >
                    <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                      {t(TRACK_TYPE[playingTrack?.type])}
                    </Text>
                  </Box>
                )}
                <Link to={`/user/${playingTrack?.createdBy?.name}`}>
                  <Text
                    cursor="pointer"
                    opacity="0.6"
                    fontSize={{ base: "12px", md: "14px" }}
                    lineHeight="16px"
                    className="text-song"
                    maxW="160px"
                    whiteSpace="nowrap"
                    _hover={{
                      textDecor: "underline",
                    }}
                    color="#a0a0a1"
                  >
                    {playingTrack?.createdBy?.name}
                  </Text>
                </Link>
                <Flex alignItems="center" ml={{ base: "5px", md: "10px" }}>
                  <Box
                    w="4px"
                    h="4px"
                    borderRadius="full"
                    background="#a0a0a1"
                    mr={{ base: "3px", md: "5px" }}
                  />
                  <Text
                    textAlign="center"
                    color="#a0a0a1"
                    fontSize={{ base: "12px", md: "14px" }}
                    lineHeight="16px"
                  >
                    {`${formatNumber(
                      Number(playingTrack?.totalPlay || 0)
                    )} Plays`}
                  </Text>
                </Flex>
              </Flex>

              {(playingTrack?.tags || []).length > 0 && (
                <Flex display={{ base: "none", md: "flex" }} flexWrap={"wrap"}>
                  {(playingTrack?.tags || []).slice(0, 3).map((tag, index) => (
                    <TagItem key={index} tag={tag} index={index} />
                  ))}
                </Flex>
              )}
            </Box>
          </Flex>

          <Flex
            gridGap="13px"
            alignItems="center"
            w={{ base: "10%", md: "27%" }}
            display={{ base: "none", md: "flex" }}
          >
            <TooltipLabel label={t("Repeat")}>
              <Box
                paddingRight="10px"
                cursor="pointer"
                onClick={onSetLoopAudioType}
                position="relative"
                display={{ base: "none", md: "block" }}
              >
                <LoopAudioIcon />
                {loopType !== LOOP_TYPE.NO_LOOP && (
                  <Box
                    position="absolute"
                    background="rgba(212, 247, 14, 0.1)"
                    w="25px"
                    height="25px"
                    borderRadius="full"
                    border="0.5px solid rgba(212, 247, 14, 0.1)"
                    top="-20px"
                    right="10px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      color="#D4F70E"
                      textAlign="center"
                      fontSize="12px"
                      fontWeight={"600"}
                    >
                      {loopType === LOOP_TYPE.LOOP_ONE ? 1 : "All"}
                    </Text>
                  </Box>
                )}
              </Box>
            </TooltipLabel>

            <TooltipLabel label={t("Previous")}>
              <Box
                cursor="pointer"
                onClick={() => handleNextOrPrevious(false)}
                display={{ base: "none", md: "block" }}
              >
                <PreviousIcon />
              </Box>
            </TooltipLabel>
            <PlayButton
              handleTogglePlayPause={handleTogglePlayPause}
              isPlaying={isPlaying}
              size={40}
              isLoading={isLoading}
            />
            <TooltipLabel label={t("Next")}>
              <Box
                display={{ base: "none", md: "block" }}
                cursor="pointer"
                onClick={() => handleNextOrPrevious(true)}
              >
                <NextIcon />
              </Box>
            </TooltipLabel>

            <TooltipLabel label={t("Shuffle")}>
              <Box
                marginLeft="8px"
                cursor="pointer"
                onClick={onToggleShuffleAudio}
                display={{ base: "none", md: "block" }}
              >
                <ShuffleAudioIcon color={isShuffle ? "#D4F70E" : "#535353"} />
              </Box>
            </TooltipLabel>
          </Flex>
          <WaveForm wavesurfer={wavesurfer} waveformRef={waveformRef} />
          <Flex
            alignItems="center"
            gridGap={{ base: "10px", md: "30px" }}
            w={{ base: "30%", md: "30%" }}
            justifyContent="flex-end"
          >
            <SoundControl onVolumeChange={onVolumeChange} volume={volume} />
            <Box
              cursor="pointer"
              onClick={() =>
                loggedIn
                  ? handleShowModalAddTrack(
                      playingTrack?._id,
                      playingTrack?.private
                    )
                  : null
              }
              display={{ base: "none", md: "block" }}
            >
              <CgAddR color="#535353" size={22} />
            </Box>
            <Box
              cursor="pointer"
              onClick={hanldeShowImageBottom}
              display={{ base: "none", md: "block" }}
            >
              <IconToggleModeFooterPlayer />
            </Box>
            <CartIcon
              onClick={loggedIn ? addToCarts : () => null}
              price={getPriceTrack(playingTrack)}
              active={isInCart}
              isOffer={
                !checkTypeMusic ? false : isExclusive && isMakeOffersOnly
              }
              isDisabled={isDisabled}
            />
            <Flex
              padding={{ base: "5px 2px", md: "0px" }}
              cursor="pointer"
              _hover={{
                background: { base: "none", md: "#24252A" },
                padding: { base: "5px 2px", md: "0px" },
                border: { base: "none", md: "1px solid #38383D" },
                borderRadius: "8px",
              }}
              display={{ base: "flex", md: "none" }}
              justifyContent="flex-end"
              onClick={() => setShowModalProfile(true)}
            >
              <BiDotsComlumnFooterPlayer />
            </Flex>
            {/* <TooltipLabel label={t("Close")}>
              <Flex
                width="40px"
                height="40px"
                border="1px solid #535353"
                borderRadius="25px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={handleClosePlay}
              >
                <CloseAudioIcon />
              </Flex>
            </TooltipLabel> */}
          </Flex>
        </Flex>
      </Box>
      <Box display={showFooterPlayer ? "none" : "block"}>
        <ImageAudioBottom
          handleTogglePlayPause={handleTogglePlayPause}
          isPlaying={isPlaying}
        />
      </Box>
    </Box>
  );
};

export default React.memo(FooterPlayer);
