import React from "react";

const AddNegotiations = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 14.1667C8.75 14.6269 8.3769 15 7.91667 15H7.08333C6.6231 15 6.25 14.6269 6.25 14.1667L6.25 0.833334C6.25 0.373097 6.6231 -1.6026e-08 7.08333 0L7.91667 1.16825e-07C8.3769 1.32851e-07 8.75 0.373096 8.75 0.833333L8.75 14.1667Z"
        fill="#CAEA11"
      />
      <path
        d="M14.1667 6.25C14.6269 6.25 15 6.6231 15 7.08333L15 7.91667C15 8.3769 14.6269 8.75 14.1667 8.75L0.833334 8.75C0.373097 8.75 1.67972e-07 8.37691 1.16823e-07 7.91667L0 7.08333C-5.11483e-08 6.6231 0.373096 6.25 0.833333 6.25L14.1667 6.25Z"
        fill="#CAEA11"
      />
    </svg>
  );
};
export default AddNegotiations;
