import { Progress, Box, Text, Flex } from "@chakra-ui/react";
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

interface ProgressBarProps {
  progress: number;
  isCreatePlaylist?: boolean;
}
export default function ProgressBar({
  progress,
  isCreatePlaylist = false,
}: ProgressBarProps) {
  const { t } = useTranslation();
  return (
    <Box mt="10px" className="progress-bar">
      {isCreatePlaylist ? (
        <Flex justifyContent="space-between">
          <Text fontWeight="400" fontSize="12px">
            {t("progress")}
          </Text>
          <Text fontWeight="400" fontSize="12px">
            {progress} %
          </Text>
        </Flex>
      ) : (
        <Text textAlign="right">{progress} %</Text>
      )}
      <Progress
        className="progress-bar__upload"
        colorScheme="Chartreuse"
        size="sm"
        borderRadius="8px"
        w="100%"
        value={progress}
      />
    </Box>
  );
}
