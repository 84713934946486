import React from "react";

const IconTotalSuccessfulReferrals = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="13" cy="9" r="4" stroke="#D4F70E" stroke-width="1.5" />
      <path
        d="M24 13H22M22 13H20M22 13L22 11M22 13L22 15"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M20.9975 21C21 20.8358 21 20.669 21 20.5C21 18.0147 17.4183 16 13 16C8.58172 16 5 18.0147 5 20.5C5 22.9853 5 25 13 25C15.231 25 16.8398 24.8433 18 24.5634"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default IconTotalSuccessfulReferrals;
