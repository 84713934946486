import { ChangeEvent, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "hooks/useDebounce";

import {
  fetchSoundkitsPending,
  getDraftsSK,
  getPrivateSK,
  getReleasesSK,
  getScheduleSK,
  updateSoundkitsReleased,
} from "app/redux/actions/mySoundKit";
import {
  draftsSKSelector,
  isLoadingSoundkitSelector,
  pendingSKSelector,
  privateSKSelector,
  releasesSKSelector,
  scheduleSKSelector,
} from "app/redux/selectors/mySoundKit";
import { typeMyTrackRouter } from "utils/actionText";

export const useMySoundkit = () => {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState<string>("");

  const [selectCheckbox, setSelectCheckbox] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);

  const releasedSK = useSelector(releasesSKSelector);
  const privateSK = useSelector(privateSKSelector);
  const scheduleSK = useSelector(scheduleSKSelector);
  const draftsSK = useSelector(draftsSKSelector);
  const pendingSK = useSelector(pendingSKSelector);
  const isLoading = useSelector(isLoadingSoundkitSelector);

  const debouncedSearchText = useDebounce<string>(searchText, 200);

  const onGetReleasedSoundkit = useCallback(
    (filter, value?: string) => {
      dispatch(getReleasesSK({ search: value, ...filter }));
    },
    [dispatch]
  );

  const onGetPrivateSoundkits = useCallback(
    (filter, value?: string) => {
      dispatch(getPrivateSK({ search: value, private: true, ...filter }));
    },
    [dispatch]
  );

  const onGetScheduleSoundkits = useCallback(
    (filter, value?: string) => {
      dispatch(getScheduleSK({ search: value, schedule: true, ...filter }));
    },
    [dispatch]
  );

  const onGetDraftsSoundkits = useCallback(
    (filter, value?: string) => {
      dispatch(getDraftsSK({ search: value, isDraft: true, ...filter }));
    },
    [dispatch]
  );

  const onGetPendingSoundkits = useCallback(
    (filter, value?: string) => {
      dispatch(fetchSoundkitsPending({ search: value, ...filter }));
    },
    [dispatch]
  );

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleCheckbox = useCallback(
    (id) => {
      const isChecked = selectCheckbox.find((item: string) => item === id);
      if (isChecked) {
        setSelectCheckbox(selectCheckbox.filter((item) => item !== id));
      } else {
        setSelectCheckbox([...selectCheckbox, id]);
      }
    },
    [selectCheckbox]
  );
  const handleRemoveSoundkit = useCallback(() => {
    dispatch(updateSoundkitsReleased(selectCheckbox));
    setSelectCheckbox([]);
  }, [dispatch, selectCheckbox]);

  const idMySoundpack = useCallback((tracks) => {
    return tracks.items?.map((item) => item._id ?? "");
  }, []);

  const dataCheckBox = useCallback(
    (type) => {
      const roleText = {
        [typeMyTrackRouter.MY_SCHEDULE_SOUNDKITS]: idMySoundpack(scheduleSK),
        [typeMyTrackRouter.MY_PRIVATE_SOUNDKITS]: idMySoundpack(privateSK),
        [typeMyTrackRouter.MY_DRAFT_SOUNDKITS]: idMySoundpack(draftsSK),
        [typeMyTrackRouter.MY_PENDING_SOUNDKITS]: idMySoundpack(pendingSK),
      };
      return roleText[type] || idMySoundpack(releasedSK);
    },
    [draftsSK, idMySoundpack, pendingSK, privateSK, releasedSK, scheduleSK]
  );

  const handleSelectAll = useCallback(
    (isChecked: boolean, type?: string) => {
      if (isChecked) {
        setIsSelectAll(true);
        setSelectCheckbox(dataCheckBox(type));
      } else {
        setSelectCheckbox([]);
        setIsSelectAll(false);
      }
    },
    [dataCheckBox]
  );

  return {
    scheduleSK,
    privateSK,
    releasedSK,
    draftsSK,
    isLoading,
    handleSearchText,
    onGetDraftsSoundkits,
    onGetReleasedSoundkit,
    onGetPrivateSoundkits,
    onGetScheduleSoundkits,
    selectCheckbox,
    handleCheckbox,
    handleRemoveSoundkit,
    handleSelectAll,
    debouncedSearchText,
    isSelectAll,
    onGetPendingSoundkits,
    pendingSK,
  };
};
