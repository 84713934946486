import { NewPromoCodeState } from "app/redux/reducers/promoCode";
import client from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class PromoCodeService {
  static async getPromoCodes() {
    try {
      const response = await client.get("/promo-codes");

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async createPromoCode(promoCode: NewPromoCodeState) {
    try {
      const response = await client.post("/promo-codes", promoCode);
      ToastPlanSubscription({
        description: "Add promo code successfully!",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async editPromoCode(promoCode: NewPromoCodeState) {
    try {
      const response = await client.put(
        `/promo-codes/${promoCode._id}`,
        promoCode
      );

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async changeActivePromoCode({
    promoCodeId,
    ...restData
  }: {
    promoCodeId: string;
  }) {
    try {
      const response = await client.patch(
        `/promo-codes/${promoCodeId}/active`,
        { ...restData }
      );
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async deletePromoCode(id: string) {
    try {
      await client.delete(`/promo-codes/${id}`);
      ToastPlanSubscription({
        description: "Delete Promo Code success",
        isError: false,
      });
      return id;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default PromoCodeService;
