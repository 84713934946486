import React from "react";

const IconAddExpertise = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="3.9" stroke="#D4F70E" stroke-width="1.2" />
      <path
        d="M6 4L6 8"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M8 6L4 6"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default IconAddExpertise;
