import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import ImageEmptyPurchased from "app/assets/images/ImageEmptyPurchased.svg";
import { useTranslation } from "react-i18next";

export default function EmptyPurchased() {
  const { t } = useTranslation();

  return (
    <Box
      height="50vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Image
        w={{ base: "64px", md: "120px" }}
        h={{ base: "64px", md: "120px" }}
        fallbackSrc={ImageEmptyPurchased}
      />
      <Text fontSize={{ base: "20px", md: "24px" }} fontWeight="500" mt="15px">
        {t("emptyPurchasedHistory")}
      </Text>
    </Box>
  );
}
