import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PROFILE_TYPE } from "constants/contractType";
import { User } from "app/models";

interface MusiciansProps {
  item: User;
}

function Musicians({ item }: MusiciansProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleClickUserItem = useCallback(() => {
    history.push(`/user/${item?.name}?profileType=${PROFILE_TYPE.TRACKS}`);
  }, [history, item?.name]);
  const redirectTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      history.push(`track/${item?.newRelease?.slug}`);
    },
    [history, item?.newRelease?.slug]
  );

  return (
    <Box>
      <Flex
        display="flex"
        alignItems="center"
        mt={{ base: "0px", md: "10px" }}
        _hover={{
          background: "rgba(36, 37, 42, 0.8)",
          borderRadius: "4px",
          padding: "15px",
        }}
        padding="15px"
        cursor="pointer"
        onClick={handleClickUserItem}
      >
        <Image
          w={{ base: "75px", md: "90px" }}
          height={{ base: "75px", md: "90px" }}
          borderRadius="full"
          src={item?.profileImage || AvatarUser}
          fallbackSrc={AvatarUser}
        />
        <Box ml={{ base: "15px", md: "25px" }}>
          <Text
            _hover={{ textDecoration: "underline" }}
            fontSize={{ base: "16px", md: "18px" }}
            lineHeight="21px"
            fontWeight="600"
          >
            {item?.name}
          </Text>
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            lineHeight="17px"
            fontWeight="400"
            color="rgba(255, 255, 255, 0.32)"
          >
            {item?.role?.[0]}
          </Text>

          <Flex mt="10px" alignItems="center">
            <Text
              minW={{ base: "75px", md: "80px" }}
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="18px"
              fontWeight="400"
              color="rgba(255, 255, 255, 0.32)"
            >
              {t("newRelease")}
            </Text>
            <Text
              onClick={redirectTrackDetail}
              _hover={{
                textDecor: "underline",
              }}
              fontSize={{ base: "12px", md: "14px" }}
              lineHeight="18px"
              fontWeight="400"
              ml="5px"
              textOverflow="ellipsis"
              w={{ base: "200px", md: "600px" }}
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {item?.newRelease?.title || t("noNewRelease")}
            </Text>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
}

export default React.memo(Musicians);
