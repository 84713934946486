import { getAllTrackLyrics } from "app/redux/actions/tracks";
import { isLoadingSelector } from "app/redux/selectors/status";
import { allLyricsSelector } from "app/redux/selectors/tracks";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";

export const useLyrics = () => {
  const dispatch = useDispatch();
  const allLyrics = useSelector(allLyricsSelector);
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getAllTrackLyrics.typePrefix], state)
  );
  const onGetAllLyrics = useCallback(() => {
    dispatch(getAllTrackLyrics());
  }, [dispatch]);

  return {
    onGetAllLyrics,
    allLyrics,
    isLoading,
  };
};
