import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { BackReturn } from "app/assets/icons";
import AddNegotiations from "app/assets/icons/AddNegotiations";
import AvatarUser from "app/assets/images/UserAvatar.png";
import SelectInput from "app/components/AddTrackComponents/SelectInput";
import { OfferNegotiations } from "app/models";
import { createNegotiation } from "app/redux/actions/negotiations";
import {
  selectorsLoadingNegotiation,
  selectorsTrackNegotiation,
} from "app/redux/selectors/negotiations";
import { selectUserAdmin } from "app/redux/selectors/users";
import { STATUS_COLOR } from "constants/negotiationType";
import { TRACK_TYPE } from "constants/trackTypes";
import React, { useCallback, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { getDayOptions, timeFormat } from "utils/timeAgo";
import TextInput from "../../TextInput";
import formSchema from "./formSchema";
import "./styles.scss";

export default function NegotiationDeal() {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);
  const dispatch = useDispatch();

  const dateOptions = useMemo(() => {
    return [...Array(5).keys()].map((element) => {
      const date = getDayOptions(element);
      return {
        label: `${timeFormat(date)}`,
        value: date,
      };
    });
  }, []);

  const trackNegotiation = useSelector(selectorsTrackNegotiation);

  const loading = useSelector(selectorsLoadingNegotiation);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(formSchema(trackNegotiation?.licenses[0]?.minPrice)),
  });

  const history = useHistory();

  const redirectNegotiations = useCallback(() => {
    history.push("/dashboard/negotiations");
  }, [history]);

  const handleCreatedNegotiation = (values: OfferNegotiations) => {
    const convertDate = new Date(+values.expiredDate);

    const data = {
      offerPrice: values.offerPrice.toString(),
      expiredDate: convertDate,
      trackId: [trackNegotiation?._id],
      createdBy: currentUser?._id,
      userId: trackNegotiation?.createdBy?._id,
      licenses: [trackNegotiation?.licenses[0]?._id],
      status: STATUS_COLOR.PENDING,
    };

    dispatch(createNegotiation(data));
    redirectNegotiations();
  };

  return (
    <Box
      className="negotiationsDeal-components"
      padding={{ base: "16px", md: "30px 60px", xl: "50px 80px" }}
    >
      <Flex alignItems="center">
        <Box onClick={redirectNegotiations} cursor="pointer">
          <BackReturn />
        </Box>
        <Text
          ml="15px"
          fontSize={{ base: "30px", xl: "32px" }}
          lineHeight="24px"
        >
          {t("negotiations")}
        </Text>
      </Flex>

      <Flex
        direction={{ base: "column", md: "row" }}
        mt="30px"
        w="100%"
        gridGap="20px"
      >
        <Box
          w={{ base: "100%", md: "40%", xl: "30%" }}
          background="rgba(36, 37, 42, 0.3)"
          borderRadius="8px"
          padding={{ base: "24px", md: "21px" }}
        >
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            lineHeight="24px"
            color="rgba(255, 255, 255, 0.51)"
            fontWeight="600"
            textAlign="center"
            mb="30px"
          >
            Start Negotiations With:
          </Text>
          <Flex display="flex" alignItems="center" justifyContent="center">
            <Box mr="15px">
              <Image
                w="63px"
                height="63px"
                borderRadius="full"
                alt="User"
                src={currentUser?.profileImage}
                fallbackSrc={AvatarUser}
              />
              <Text
                textAlign="center"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight="19px"
                mt="10px"
              >
                You
              </Text>
            </Box>
            <Box mb="20px">
              <AddNegotiations />
            </Box>

            <Box ml="15px">
              <Box
                position="absolute"
                background="#D4F70E"
                filter="blur(94px)"
                w="63px"
                height="70px"
                opacity="0.8"
              />
              <Image
                position="relative"
                w="63px"
                height="63px"
                borderRadius="full"
                alt="User"
                src={trackNegotiation?.createdBy?.profileImage}
                fallbackSrc={AvatarUser}
              />

              <Text
                textAlign="center"
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight="19px"
                mt="10px"
              >
                {trackNegotiation?.createdBy?.name}
              </Text>
            </Box>
          </Flex>
          <Text
            m="30px 0px"
            fontSize={{ base: "18px", md: "22px" }}
            lineHeight="16px"
            fontWeight="600"
          >
            Deal Details
          </Text>
          <form
            className="custom-label"
            onSubmit={handleSubmit(handleCreatedNegotiation)}
          >
            <SelectInput
              name={"expiredDate"}
              placeholder={t("offerExpiresIn")}
              label={t("typeOfContract")}
              data={dateOptions}
              error={errors?.expiredDate?.message}
              control={control}
            />
            <Divider m="20px 0px" borderColor="rgba(255, 255, 255, 0.06)" />
            <Text
              fontSize={{ base: "16px", md: "18px" }}
              lineHeight="25px"
              color="rgba(241, 242, 243, 0.3)"
              mb="5px"
            >
              Propposed offer (min. ${trackNegotiation?.licenses[0]?.minPrice})
            </Text>
            <TextInput
              name="offerPrice"
              type={"group"}
              control={control}
              errors={errors}
              border="none"
              backgroundColor="#25252A"
              placeholder=""
            />
            <Button
              background="#3D4419"
              color="#D4F70E"
              w="100%"
              m="30px 0px 10px 0px"
              size="lg"
              fontSize={{ base: "16px", md: "18px" }}
              _hover={{ background: "#2A2B20" }}
              _active={{ background: "#D4F70E", color: "black" }}
              _focus={{ boxShadow: "none" }}
              type="submit"
              isLoading={loading}
              spinner={<BeatLoader size={8} color="white" />}
            >
              {t("startNegotiation")}
            </Button>
          </form>
        </Box>

        <Box
          background="rgba(36, 37, 42, 0.3)"
          borderRadius="8px"
          padding={{ base: "16px", md: "24px", xl: "30px 40px 170px 40px" }}
          pt={{ base: "28px" }}
          pb={{ base: "16px", md: "24px" }}
          px={{ base: "16px", md: "24px", xl: "40px" }}
          w={{ base: "100%", md: "60%", lg: "80%", xl: "70%" }}
          height="100%"
        >
          <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={10}>
            <Box>
              <Text
                fontSize={{ base: "16px", md: "18px" }}
                color="#535353"
                mb={{ base: "16px", md: "20px" }}
              >
                Track
              </Text>
              <Flex alignItems="center">
                <Image
                  w="72px"
                  height="72px"
                  alt="User"
                  src={trackNegotiation?.coverUrl || AvatarUser}
                  borderRadius="4px"
                />
                <Box ml={{ base: "16px", md: "20px" }}>
                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    lineHeight="16px"
                  >
                    {trackNegotiation?.title}
                  </Text>
                  <Flex
                    direction={{ base: "column-reverse", lg: "row" }}
                    mt="5px"
                    gap={{ base: "12px", md: "4px" }}
                  >
                    {TRACK_TYPE[trackNegotiation?.type] && (
                      <Box
                        display="flex"
                        alignItems="center"
                        height="20px"
                        borderRadius="4px"
                        px="5px"
                        backgroundColor="#2b2e1b"
                        w="max-content"
                      >
                        <Text
                          fontSize={{ base: "12px", md: "14px" }}
                          lineHeight="14px"
                          color="#d4f70e"
                        >
                          {t(TRACK_TYPE[trackNegotiation?.type])}
                        </Text>
                      </Box>
                    )}

                    <Text
                      fontSize={{ base: "12px", md: "14px" }}
                      color="#464649"
                    >
                      {`By ${trackNegotiation?.createdBy?.name}`}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box>
              <Text
                fontSize={{ base: "16px", md: "18px" }}
                color="#535353"
                mb={{ base: "16px", md: "20px" }}
              >
                License
              </Text>
              <Flex alignItems="center">
                <Text fontSize={{ base: "14px", md: "16px" }} color="#464649">
                  Exclusive License
                </Text>
              </Flex>
            </Box>
            <Box>
              <Text
                fontSize={{ base: "16px", md: "18px" }}
                color="#535353"
                mb={{ base: "16px", md: "20px" }}
                display="flex"
              >
                Publishing
              </Text>
              <Flex alignItems="center">
                <Text
                  fontSize={{ base: "28px", md: "32px", xl: "36px" }}
                  lineHeight="24px"
                  color="#464649"
                  fontWeight="900"
                  display="flex"
                >
                  {trackNegotiation?.licenses[0]?.beatsPublishing || 0}{" "}
                  <span>%</span>
                </Text>
              </Flex>
            </Box>
          </SimpleGrid>
          <Divider
            display={{ base: "none", lg: "block" }}
            borderColor="rgba(255, 255, 255, 0.06)"
            mt="20px"
          />
        </Box>
      </Flex>
    </Box>
  );
}
