import React from "react";

const IconEmptyNotification = () => {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M94.5 42C94.5 33.6457 91.1813 25.6335 85.2739 19.7261C79.3665 13.8187 71.3543 10.5 63 10.5C54.6457 10.5 46.6335 13.8187 40.7261 19.7261C34.8187 25.6335 31.5 33.6457 31.5 42C31.5 78.75 15.75 89.25 15.75 89.25H110.25C110.25 89.25 94.5 78.75 94.5 42Z"
        stroke="#282829"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.0825 110.25C71.1595 111.841 69.8347 113.162 68.2407 114.08C66.6467 114.998 64.8395 115.481 63 115.481C61.1605 115.481 59.3533 114.998 57.7593 114.08C56.1653 113.162 54.8405 111.841 53.9175 110.25"
        stroke="#282829"
        strokeWidth="8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconEmptyNotification;
