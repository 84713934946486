import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Checkbox,
  Progress,
} from "@chakra-ui/react";
import React, { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import AudioTrackFiles from "./audioTrackFiles";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import { Untagged, TrackStems, VoiceTag } from "app/assets/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./styles.scss";
import { UploadTrackFilesModel, SelectedFilesModel } from "app/models";
import { TYPE_AUDIO_FILES } from "utils/fileToDeliver";
import { useDispatch } from "react-redux";
import { removeLinkUploadTrackFiles } from "app/redux/actions/tracks";
import ToastPlanSubscription from "../toast/toastplan";
import IconCheckmarkUploadFiles from "app/assets/icons/IconCheckmarkUploadFiles";

interface SelectTrackFilesProps {
  autoTaggedFiles: UploadTrackFilesModel[];
  customTaggedFiles: UploadTrackFilesModel[];
  trackoutStemsFiles: UploadTrackFilesModel[];
  setAutoTaggedFiles: (value: UploadTrackFilesModel[]) => void;
  setCustomTaggedFiles: (value: UploadTrackFilesModel[]) => void;
  setTrackoutStemsFiles: (value: UploadTrackFilesModel[]) => void;
  selectedFile: SelectedFilesModel;
  selectedAutoTagged: UploadTrackFilesModel;
  setSelectedAutoTagged: (value: UploadTrackFilesModel) => void;
  selectedCustomTagged: UploadTrackFilesModel;
  setSelectedCustomTagged: (value: UploadTrackFilesModel) => void;
  selectedTrackoutStems: UploadTrackFilesModel;
  setSelectedTrackoutStems: (value: UploadTrackFilesModel) => void;
}
export default function SelectTrackFiles({
  autoTaggedFiles,
  customTaggedFiles,
  trackoutStemsFiles,
  setAutoTaggedFiles,
  setCustomTaggedFiles,
  setTrackoutStemsFiles,
  selectedFile,
  selectedAutoTagged,
  setSelectedAutoTagged,
  selectedCustomTagged,
  setSelectedCustomTagged,
  selectedTrackoutStems,
  setSelectedTrackoutStems,
}: SelectTrackFilesProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [valueCheckboxAutoTagged, setValueCheckboxAutoTagged] = useState<
    { id: string; nameUrl: string }[]
  >([]);
  const [valueCheckboxCustomTagged, setValueCheckboxCustomTagged] = useState<
    { id: string; nameUrl: string }[]
  >([]);
  const [valueCheckboxTrackoutStems, setValueCheckboxTrackoutStems] = useState<
    { id: string; nameUrl: string }[]
  >([]);

  const listUpload = useMemo(
    () => [
      {
        name: t("autoTagged"),
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/wav",
        id: 0,
      },
      {
        name: t("customTagged"),
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/mp3",
        id: 1,
      },
      {
        name: t("trackoutStems"),
        type: FILE_UPLOAD_TYPE.ZIP,
        id: 2,
      },
    ],
    [t]
  );

  const handleCheckbox = useCallback(
    (id, type, nameUrl) => {
      const splitNameUrl = nameUrl.split("?")[0].split("/");
      const audioLink = splitNameUrl[splitNameUrl.length - 1];

      if (type === TYPE_AUDIO_FILES.AUDIO_TAGGED) {
        const isChecked = valueCheckboxAutoTagged.find(
          (item) => item.id === id
        );
        if (isChecked) {
          setValueCheckboxAutoTagged(
            valueCheckboxAutoTagged.filter((item) => item.id !== id)
          );
        } else {
          setValueCheckboxAutoTagged([
            ...valueCheckboxAutoTagged,
            { id: id, nameUrl: audioLink },
          ]);
        }
      } else if (type === TYPE_AUDIO_FILES.CUSTOM_TAGGED) {
        const isChecked = valueCheckboxCustomTagged.find(
          (item) => item.id === id
        );
        if (isChecked) {
          setValueCheckboxCustomTagged(
            valueCheckboxCustomTagged.filter((item) => item.id !== id)
          );
        } else {
          setValueCheckboxCustomTagged([
            ...valueCheckboxCustomTagged,
            { id: id, nameUrl: audioLink },
          ]);
        }
      } else {
        const isChecked = valueCheckboxTrackoutStems.find(
          (item) => item.id === id
        );
        if (isChecked) {
          setValueCheckboxTrackoutStems(
            valueCheckboxTrackoutStems.filter((item) => item.id !== id)
          );
        } else {
          setValueCheckboxTrackoutStems([
            ...valueCheckboxTrackoutStems,
            { id: id, nameUrl: audioLink },
          ]);
        }
      }
    },
    [
      valueCheckboxAutoTagged,
      valueCheckboxCustomTagged,
      valueCheckboxTrackoutStems,
    ]
  );

  const onDragEnd = useCallback((result, itemsDrag, setItemsDrag) => {
    if (!result.destination) return;
    const newItems = Array.from(itemsDrag);
    const [removed] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, removed);
    setItemsDrag(newItems);
  }, []);

  const handleRemoveAudioFiles = useCallback(
    (type) => {
      if (type === TYPE_AUDIO_FILES.AUDIO_TAGGED) {
        const nameLink = (valueCheckboxAutoTagged || []).map(
          (item) => item.nameUrl
        );
        const removeAutoTagged = (autoTaggedFiles || []).filter((item) => {
          return !(valueCheckboxAutoTagged || []).some(
            (value) => value.id === item.id
          );
        });
        const checkRemoveSelectedAutoTagged = valueCheckboxAutoTagged.some(
          (item) => item.id === selectedAutoTagged.id
        );
        if (checkRemoveSelectedAutoTagged) {
          setSelectedAutoTagged({});
        }
        setAutoTaggedFiles(removeAutoTagged);
        dispatch(removeLinkUploadTrackFiles(nameLink));
        setValueCheckboxAutoTagged([]);
      } else if (type === TYPE_AUDIO_FILES.CUSTOM_TAGGED) {
        const nameLink = (valueCheckboxCustomTagged || []).map(
          (item) => item.nameUrl
        );
        const removeCustomTagged = (customTaggedFiles || []).filter((item) => {
          return !(valueCheckboxCustomTagged || []).some(
            (value) => value.id === item.id
          );
        });
        const checkRemoveSelectedCustomTagged = valueCheckboxCustomTagged.some(
          (item) => item.id === selectedCustomTagged.id
        );
        if (checkRemoveSelectedCustomTagged) {
          setSelectedCustomTagged({});
        }
        setCustomTaggedFiles(removeCustomTagged);
        dispatch(removeLinkUploadTrackFiles(nameLink));
        setValueCheckboxCustomTagged([]);
      } else {
        const nameLink = (valueCheckboxTrackoutStems || []).map(
          (item) => item.nameUrl
        );
        const removeTrackoutStems = (trackoutStemsFiles || []).filter(
          (item) => {
            return !(valueCheckboxTrackoutStems || []).some(
              (value) => value.id === item.id
            );
          }
        );
        const checkRemoveSelectedTrackoutStems =
          valueCheckboxTrackoutStems.some(
            (item) => item.id === selectedTrackoutStems.id
          );
        if (checkRemoveSelectedTrackoutStems) {
          setSelectedTrackoutStems({});
        }
        setTrackoutStemsFiles(removeTrackoutStems);
        dispatch(removeLinkUploadTrackFiles(nameLink));
        setValueCheckboxTrackoutStems([]);
      }
    },
    [
      valueCheckboxAutoTagged,
      autoTaggedFiles,
      setAutoTaggedFiles,
      dispatch,
      selectedAutoTagged.id,
      setSelectedAutoTagged,
      valueCheckboxCustomTagged,
      customTaggedFiles,
      setCustomTaggedFiles,
      selectedCustomTagged.id,
      setSelectedCustomTagged,
      valueCheckboxTrackoutStems,
      trackoutStemsFiles,
      setTrackoutStemsFiles,
      selectedTrackoutStems.id,
      setSelectedTrackoutStems,
    ]
  );

  return (
    <Box mt="30px">
      {selectedFile.name === t("reviewFiles") ? (
        <Box mt="30px">
          <Text
            fontSize={{ base: "18px", md: "24px" }}
            textAlign="center"
            mb="20px"
          >
            {t("fileSelectionNeededForNextTrack")}
          </Text>
          <Text
            fontSize={{ base: "16px", md: "18px" }}
            color="#D4F70E"
            textAlign="center"
          >
            {t("stepIsCrucialToAchieve")}
          </Text>
        </Box>
      ) : (
        <AudioTrackFiles
          name={selectedFile.name}
          type={selectedFile.type}
          fileTypeAudio={selectedFile.fileType}
          setAutoTaggedFiles={setAutoTaggedFiles}
          setCustomTaggedFiles={setCustomTaggedFiles}
          setTrackoutStemsFiles={setTrackoutStemsFiles}
        />
      )}
      <Box w="100%" mt="40px">
        <SimpleGrid
          w="100%"
          columns={3}
          spacing={{ md: 3, lg: 10 }}
          display={{ base: "none", md: "grid" }}
        >
          {listUpload.map((item) => (
            <Text
              key={item.id}
              textDecoration={
                item.name === selectedFile.name ? "underline" : "none"
              }
              textDecorationColor="#D4F70E"
              textUnderlineOffset="10px"
              textAlign="center"
              cursor="pointer"
              fontSize={{ md: "28px", lg: "32px" }}
            >
              {item.name}
            </Text>
          ))}
        </SimpleGrid>
        <SimpleGrid
          w="100%"
          columns={{ base: 1, md: 3 }}
          spacing={{ base: 3, lg: 10 }}
          mt="40px"
        >
          <Box>
            {autoTaggedFiles?.length > 0 ? (
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd(result, autoTaggedFiles, setAutoTaggedFiles)
                }
              >
                <Droppable droppableId="autoTaggedFiles">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      <Text
                        fontSize="24px"
                        display={{ base: "block", md: "none" }}
                      >
                        {t("autoTagged")}
                      </Text>
                      {(autoTaggedFiles || []).map((item, index) => (
                        <Draggable
                          key={item?.id}
                          index={index}
                          draggableId={item?.id}
                        >
                          {(provided) => (
                            <Flex
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              h="150px"
                              alignItems="center"
                            >
                              <Flex w="10%" alignItems="center">
                                {item?.urlFile && (
                                  <Checkbox
                                    borderRadius="4px"
                                    _focusVisible={{ outline: "none" }}
                                    size="lg"
                                    mr="5px"
                                    onChange={() =>
                                      handleCheckbox(
                                        item?.id,
                                        TYPE_AUDIO_FILES.AUDIO_TAGGED,
                                        item?.urlFile
                                      )
                                    }
                                    isChecked={valueCheckboxAutoTagged.some(
                                      (value) => value.id === item?.id
                                    )}
                                  />
                                )}
                                <Text>{index + 1}</Text>
                              </Flex>

                              <Box
                                background="#1F1F23"
                                borderRadius="8px"
                                padding="15px"
                                minH="70px"
                                w="90%"
                                className="drag-drop-item"
                                _active={{ border: "1px solid #D4F70E" }}
                                border={
                                  selectedAutoTagged?.name === item.name
                                    ? `1px solid #D4F70E`
                                    : "1px solid #535353"
                                }
                                onClick={
                                  selectedFile.name === t("reviewFiles") &&
                                  item?.urlFile &&
                                  item?.progress === 100
                                    ? () => setSelectedAutoTagged(item)
                                    : selectedFile.name !== t("reviewFiles")
                                    ? () => null
                                    : () =>
                                        ToastPlanSubscription({
                                          description:
                                            "Please wait until the upload completed",
                                        })
                                }
                                position="relative"
                              >
                                {item.progress === 100 &&
                                  selectedAutoTagged?.name === item.name && (
                                    <Box
                                      position="absolute"
                                      top="-15px"
                                      right="-12px"
                                    >
                                      <IconCheckmarkUploadFiles />
                                    </Box>
                                  )}

                                <Flex alignItems="center" gap="10px">
                                  <Box w="10%">
                                    <Untagged color="#D4F70E" />
                                  </Box>
                                  <Text w="90%" noOfLines={2}>
                                    {item?.name}
                                  </Text>
                                </Flex>
                                <Progress
                                  background="#3A3A3A"
                                  value={item?.progress}
                                  mt="10px"
                                  size="xs"
                                  borderRadius="6px"
                                  className="progress-bar-upload"
                                />
                              </Box>
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
                {valueCheckboxAutoTagged?.length > 0 && (
                  <Text
                    onClick={() =>
                      handleRemoveAudioFiles(TYPE_AUDIO_FILES.AUDIO_TAGGED)
                    }
                    fontSize="16px"
                    color="#D4F70E"
                    cursor="pointer"
                  >
                    {t("remove")}
                  </Text>
                )}
              </DragDropContext>
            ) : (
              <Box>
                <Text
                  fontSize="24px"
                  mb="40px"
                  display={{ base: "block", md: "none" }}
                >
                  {t("autoTagged")}
                </Text>
                <Text
                  mb="30px"
                  textAlign="center"
                  fontSize="14px"
                  color="#D4F70E"
                >
                  {t("uploadGuidelinesForUntaggedWavFiles")}
                </Text>
                <Text
                  mb="30px"
                  fontSize="14px"
                  textAlign="center"
                  color="#535353"
                >
                  {t("forOptimalQualityUploadUntagged")}
                </Text>
                <Text
                  mb="30px"
                  color="#D4F70E"
                  textAlign="center"
                  fontSize={{ base: "14px", md: "16px" }}
                  backgroundColor="#211A3A"
                  padding="10px"
                  borderRadius="10px"
                >
                  {t("licenseNote")}
                  <span style={{ color: "#7826E7" }}>
                    {t("uploadingWavFileHereAutomatically")}
                  </span>
                </Text>
              </Box>
            )}
          </Box>

          <Box>
            {customTaggedFiles?.length > 0 ? (
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd(result, customTaggedFiles, setCustomTaggedFiles)
                }
              >
                <Droppable droppableId="customTaggedFiles">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      <Text
                        mb="10px"
                        fontSize="24px"
                        display={{ base: "block", md: "none" }}
                      >
                        {t("customTagged")}
                      </Text>
                      {(customTaggedFiles || []).map((item, index) => (
                        <Draggable
                          key={item?.id}
                          index={index}
                          draggableId={item?.id}
                        >
                          {(provided) => (
                            <Flex
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              h="150px"
                              alignItems="center"
                            >
                              <Flex w="10%" alignItems="center">
                                {item?.urlFile && (
                                  <Checkbox
                                    borderRadius="4px"
                                    _focusVisible={{ outline: "none" }}
                                    size="lg"
                                    mr="5px"
                                    onChange={() =>
                                      handleCheckbox(
                                        item?.id,
                                        TYPE_AUDIO_FILES.CUSTOM_TAGGED,
                                        item?.urlFile
                                      )
                                    }
                                    isChecked={valueCheckboxCustomTagged.some(
                                      (value) => value.id === item?.id
                                    )}
                                  />
                                )}
                                <Text>{index + 1}</Text>
                              </Flex>
                              <Box
                                border={
                                  selectedCustomTagged.name === item.name
                                    ? "1px solid #D4F70E"
                                    : "1px solid #535353"
                                }
                                background="#1F1F23"
                                borderRadius="8px"
                                padding="15px"
                                minH="70px"
                                w="90%"
                                className="drag-drop-item"
                                _active={{ border: "1px solid #D4F70E" }}
                                onClick={
                                  selectedFile.name === t("reviewFiles")
                                    ? () => setSelectedCustomTagged(item)
                                    : () => null
                                }
                                position="relative"
                              >
                                {item.progress === 100 &&
                                  selectedCustomTagged.name === item.name && (
                                    <Box
                                      position="absolute"
                                      top="-15px"
                                      right="-12px"
                                    >
                                      <IconCheckmarkUploadFiles />
                                    </Box>
                                  )}
                                <Flex alignItems="center" gap="10px">
                                  <Box w="10%">
                                    <VoiceTag color="#D4F70E" />
                                  </Box>
                                  <Text w="90%" noOfLines={2}>
                                    {item?.name}
                                  </Text>
                                </Flex>
                                <Progress
                                  background="#3A3A3A"
                                  value={item?.progress}
                                  mt="10px"
                                  size="xs"
                                  borderRadius="6px"
                                  className="progress-bar-upload"
                                />
                              </Box>
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
                {valueCheckboxCustomTagged?.length > 0 && (
                  <Text
                    onClick={() =>
                      handleRemoveAudioFiles(TYPE_AUDIO_FILES.CUSTOM_TAGGED)
                    }
                    fontSize="16px"
                    color="#D4F70E"
                    cursor="pointer"
                  >
                    {t("remove")}
                  </Text>
                )}
              </DragDropContext>
            ) : (
              <Box>
                <Text
                  fontSize="24px"
                  mb="40px"
                  display={{ base: "block", md: "none" }}
                >
                  {t("customTagged")}
                </Text>
                <Text
                  mb="30px"
                  textAlign="center"
                  fontSize="14px"
                  color="#D4F70E"
                >
                  {t("uploadGuidelinesForUntaggedMp3Files")}
                </Text>
                <Text
                  mb="30px"
                  fontSize="14px"
                  textAlign="center"
                  color="#535353"
                >
                  {t("forOptimalPerformanceUploadYourMp3")}
                </Text>
                <Text
                  mb="30px"
                  color="#D4F70E"
                  textAlign="center"
                  fontSize={{ base: "14px", md: "16px" }}
                  backgroundColor="#211A3A"
                  padding="10px"
                  borderRadius="10px"
                >
                  {t("licenseNote")}
                  <span style={{ color: "#7826E7" }}>
                    {t("thisFileWillBeAvailableForStreaming")}
                  </span>
                </Text>
              </Box>
            )}
          </Box>

          <Box>
            {trackoutStemsFiles?.length > 0 ? (
              <DragDropContext
                onDragEnd={(result) =>
                  onDragEnd(result, trackoutStemsFiles, setTrackoutStemsFiles)
                }
              >
                <Droppable droppableId="trackoutStemsFiles">
                  {(provided) => (
                    <Box ref={provided.innerRef} {...provided.droppableProps}>
                      <Text
                        fontSize="24px"
                        mb="10px"
                        display={{ base: "block", md: "none" }}
                      >
                        {t("trackoutStems")}
                      </Text>
                      {(trackoutStemsFiles || []).map((item, index) => (
                        <Draggable
                          key={item?.id}
                          index={index}
                          draggableId={item?.id}
                        >
                          {(provided) => (
                            <Flex
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              h="150px"
                              alignItems="center"
                            >
                              <Flex w="10%" alignItems="center">
                                {item?.urlFile && (
                                  <Checkbox
                                    borderRadius="4px"
                                    _focusVisible={{ outline: "none" }}
                                    size="lg"
                                    mr="5px"
                                    onChange={() =>
                                      handleCheckbox(
                                        item?.id,
                                        TYPE_AUDIO_FILES.TRACKOUT_STEMS,
                                        item?.urlFile
                                      )
                                    }
                                    isChecked={valueCheckboxTrackoutStems.some(
                                      (value) => value.id === item?.id
                                    )}
                                  />
                                )}
                                <Text>{index + 1}</Text>
                              </Flex>
                              <Box
                                border={
                                  selectedTrackoutStems.name === item.name
                                    ? "1px solid #D4F70E"
                                    : "1px solid #535353"
                                }
                                background="#1F1F23"
                                borderRadius="8px"
                                padding="15px"
                                minH="70px"
                                w="90%"
                                className="drag-drop-item"
                                _active={{ border: "1px solid #D4F70E" }}
                                onClick={
                                  selectedFile.name === t("reviewFiles")
                                    ? () => setSelectedTrackoutStems(item)
                                    : () => null
                                }
                                position="relative"
                              >
                                {item.progress === 100 &&
                                  selectedTrackoutStems?.name === item.name && (
                                    <Box
                                      position="absolute"
                                      top="-15px"
                                      right="-12px"
                                    >
                                      <IconCheckmarkUploadFiles />
                                    </Box>
                                  )}
                                <Flex alignItems="center" gap="10px">
                                  <Box w="10%">
                                    <TrackStems color="#D4F70E" />
                                  </Box>
                                  <Text w="90%" noOfLines={2}>
                                    {item?.name}
                                  </Text>
                                </Flex>
                                <Progress
                                  background="#3A3A3A"
                                  value={item?.progress}
                                  mt="10px"
                                  size="xs"
                                  borderRadius="6px"
                                  className="progress-bar-upload"
                                />
                              </Box>
                            </Flex>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Box>
                  )}
                </Droppable>
                {valueCheckboxTrackoutStems?.length > 0 && (
                  <Text
                    onClick={() =>
                      handleRemoveAudioFiles(TYPE_AUDIO_FILES.TRACKOUT_STEMS)
                    }
                    fontSize="16px"
                    color="#D4F70E"
                    cursor="pointer"
                  >
                    {t("remove")}
                  </Text>
                )}
              </DragDropContext>
            ) : (
              <Box>
                <Text
                  fontSize="24px"
                  mb="40px"
                  display={{ base: "block", md: "none" }}
                >
                  {t("trackoutStems")}
                </Text>
                <Text
                  mb="30px"
                  textAlign="center"
                  fontSize="14px"
                  color="#D4F70E"
                >
                  {t("uploadGuidelinesForTrackoutZipFiles")}
                </Text>
                <Text
                  mb="30px"
                  fontSize="14px"
                  textAlign="center"
                  color="#535353"
                >
                  {t("uploadZipFileOfYourTrackStems")}
                </Text>
                <Text
                  mb="30px"
                  color="#D4F70E"
                  textAlign="center"
                  fontSize={{ base: "14px", md: "16px" }}
                  backgroundColor="#211A3A"
                  padding="10px"
                  borderRadius="10px"
                >
                  {t("licenseNote")}
                  <span style={{ color: "#7826E7" }}>
                    {t("includingThemCanSignificantlyIncrease")}
                  </span>
                </Text>
              </Box>
            )}
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  );
}
