import LicenseService from "app/apis/licenseAndContract";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { Dispatch } from "redux";
import { generateActionTypes } from "../actionTypes";
import { LicensePayload } from "./interface";

export const CREATE_LICENSE = generateActionTypes("CREATE_LICENSE");
export const DELETE_LICENSE = generateActionTypes("DELETE_LICENSE");
export const GET_LICENSE = generateActionTypes("GET_LICENSE");
export const EDIT_LICENSE = generateActionTypes("EDIT_LICENSE");
export const START_EDIT_LICENSE = generateActionTypes("START_EDIT_LICENSE");
export const CLEAR_FORM_LICENSE = generateActionTypes("CLEAR_FORM_LICENSE");
export const CHANGE_ACTIVE_LICENSE = generateActionTypes(
  "CHANGE_ACTIVE_LICENSE"
);
export const CHANGE_HIGHLIGHT_LICENSE = generateActionTypes(
  "CHANGE_HIGHLIGHT_LICENSE"
);

// --------------------------------------------------------------
// GET LICENSE CONTRACT
// --------------------------------------------------------------

const getLicenseRequest = () => ({
  type: GET_LICENSE.REQUEST,
  payload: [],
});

const getLicenseError = (error: any) => ({
  type: GET_LICENSE.ERROR,
  payload: { error },
});

const getLicenseSuccess = (payload: any) => ({
  type: GET_LICENSE.SUCCESS,
  payload: [...payload],
});

const deleteLicenseSuccess = (id: string) => ({
  type: DELETE_LICENSE.SUCCESS,
  payload: id,
});
const deleteLicenseLicenseError = (error: any) => ({
  type: DELETE_LICENSE.ERROR,
  payload: { error },
});

export const getLicenseContract = () => async (dispatch: Dispatch) => {
  dispatch(getLicenseRequest());
  try {
    const payload = await LicenseService.getLicenseContract();
    dispatch(getLicenseSuccess(payload));
  } catch (error: any) {
    dispatch(getLicenseError(error.message));
  }
};

// --------------------------------------------------------------
// CREATE LICENSE
// --------------------------------------------------------------

const createLicenseRequest = () => ({
  type: CREATE_LICENSE.REQUEST,
});

const createLicenseError = (error: any) => ({
  type: CREATE_LICENSE.ERROR,
  payload: { error },
});

const createLicenseSuccess = (payload: any) => {
  return {
    type: CREATE_LICENSE.SUCCESS,
    payload: { ...payload },
  };
};

export const createLicense = (license: any) => async (dispatch: Dispatch) => {
  dispatch(createLicenseRequest());
  try {
    const payload = await LicenseService.createLicense(license);
    dispatch(createLicenseSuccess(payload));
    ToastPlanSubscription({
      description: "Create new license",
      isError: false,
    });
  } catch (error: any) {
    dispatch(createLicenseError(error.message));
    ToastPlanSubscription({ description: "Invalid new license" });
  }
};
// DELETE LICENSE CONTRACT
// --------------------------------------------------------------

export const deleteLicenseContract =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      await LicenseService.deleteLicenseContract(id);
      dispatch(deleteLicenseSuccess(id));
    } catch (error: any) {
      dispatch(deleteLicenseLicenseError(error.message));
    }
  };

// --------------------------------------------------------------
// START EDIT LICENSE
// --------------------------------------------------------------
const startEditLicenseRequest = (id: string) => {
  return {
    type: START_EDIT_LICENSE.REQUEST,
    payload: id,
  };
};

export const startEditLicense = (id: string) => async (dispatch: Dispatch) => {
  dispatch(startEditLicenseRequest(id));
};

// --------------------------------------------------------------
// EDIT LICENSE
// --------------------------------------------------------------

const editLicenseRequest = () => ({
  type: EDIT_LICENSE.REQUEST,
});

const editLicenseError = (error: any) => ({
  type: EDIT_LICENSE.ERROR,
  payload: { error },
});

const editLicenseSuccess = (payload: any) => {
  return {
    type: EDIT_LICENSE.SUCCESS,
    payload: { ...payload },
  };
};

export const editLicense =
  (id: string, license: LicensePayload) => async (dispatch: Dispatch) => {
    dispatch(editLicenseRequest());
    try {
      const payload = await LicenseService.editLicense(id, license);

      dispatch(editLicenseSuccess(payload));
      ToastPlanSubscription({
        description: "Update license success",
        isError: false,
      });
    } catch (error: any) {
      dispatch(editLicenseError(error.message));
      ToastPlanSubscription({ description: "Invalid new license" });
    }
  };

// --------------------------------------------------------------
// CLEAR FORM LICENSE
// --------------------------------------------------------------
const clearFormLicenseSuccess = () => {
  return {
    type: CLEAR_FORM_LICENSE.SUCCESS,
  };
};

export const clearFormLicense = () => async (dispatch: Dispatch) => {
  dispatch(clearFormLicenseSuccess());
};

// CHANGE ACTIVE LICENSE
// --------------------------------------------------------------
const changeActiveLicenseError = (error: any) => ({
  type: CHANGE_ACTIVE_LICENSE.ERROR,
  payload: { error },
});

const changeActiveLicenseSuccess = (payload: any) => {
  return {
    type: CHANGE_ACTIVE_LICENSE.SUCCESS,
    payload: { ...payload },
  };
};

// CHANGE HIGHLIGHT LICENSE
// --------------------------------------------------------------
const changeHighlightLicenseError = (error: any) => ({
  type: CHANGE_HIGHLIGHT_LICENSE.ERROR,
  payload: { error },
});

const changeHighlightLicenseSuccess = (payload: any) => {
  return {
    type: CHANGE_HIGHLIGHT_LICENSE.SUCCESS,
    payload: { ...payload },
  };
};

export const changeActiveLicense =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      const payload = await LicenseService.changeActiveLicense(id);
      dispatch(changeActiveLicenseSuccess(payload));
    } catch (error: any) {
      dispatch(changeActiveLicenseError(error.message));
    }
  };

export const changeHighlightLicense =
  (id: string) => async (dispatch: Dispatch) => {
    try {
      const payload = await LicenseService.changeHighlightLicense(id);
      dispatch(changeHighlightLicenseSuccess(payload));
    } catch (error: any) {
      dispatch(changeHighlightLicenseError(error.message));
    }
  };
