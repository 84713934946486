import React from "react";

interface IconHeartFeedPageUnlikeMobileProps {
  color?: string;
}

const IconHeartFeedPageUnlikeMobile = ({
  color = "#656566",
}: IconHeartFeedPageUnlikeMobileProps) => {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00262 12.0007C6.57262 11.6194 6.08662 11.2227 5.57262 10.8007H5.56595C3.75595 9.32075 1.70462 7.64608 0.798619 5.63942C0.500964 5.00057 0.343222 4.3055 0.335945 3.60075C0.333957 2.63374 0.7218 1.70674 1.41182 1.02926C2.10185 0.351782 3.03581 -0.0189875 4.00262 0.000749519C4.78971 0.00199272 5.55984 0.22947 6.22129 0.656083C6.51193 0.844729 6.7749 1.07292 7.00262 1.33408C7.23161 1.07394 7.49465 0.845888 7.78462 0.656083C8.44578 0.229386 9.21573 0.00189522 10.0026 0.000749519C10.9694 -0.0189875 11.9034 0.351782 12.5934 1.02926C13.2834 1.70674 13.6713 2.63374 13.6693 3.60075C13.6625 4.30663 13.5047 5.00288 13.2066 5.64275C12.3006 7.64942 10.25 9.32341 8.43995 10.8007L8.43329 10.8061C7.91862 11.2254 7.43329 11.6221 7.00329 12.0061L7.00262 12.0007ZM4.00262 1.33408C3.38163 1.32631 2.78267 1.56398 2.33595 1.99542C1.90554 2.41819 1.66499 2.99745 1.66923 3.60075C1.67684 4.11442 1.79317 4.62065 2.01062 5.08608C2.43829 5.95189 3.01536 6.73545 3.71529 7.40075C4.37595 8.06742 5.13595 8.71275 5.79329 9.25541C5.97529 9.40541 6.16062 9.55675 6.34595 9.70808L6.46262 9.80342C6.64062 9.94875 6.82462 10.0994 7.00262 10.2474L7.01129 10.2394L7.01529 10.2361H7.01929L7.02529 10.2314H7.02862H7.03195L7.04395 10.2214L7.07129 10.1994L7.07595 10.1954L7.08329 10.1901H7.08729L7.09329 10.1847L7.53595 9.82141L7.65195 9.72608C7.83929 9.57342 8.02462 9.42208 8.20662 9.27208C8.86395 8.72942 9.62462 8.08475 10.2853 7.41475C10.9853 6.74979 11.5624 5.96642 11.99 5.10075C12.2113 4.63128 12.3293 4.11975 12.336 3.60075C12.3387 2.99931 12.0983 2.42228 11.6693 2.00075C11.2234 1.56736 10.6244 1.32774 10.0026 1.33408C9.2439 1.32763 8.51858 1.64566 8.00929 2.20808L7.00262 3.36808L5.99595 2.20808C5.48666 1.64566 4.76134 1.32763 4.00262 1.33408Z"
        fill={color}
      />
    </svg>
  );
};
export default IconHeartFeedPageUnlikeMobile;
