import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { useSignup } from "hooks/signup/useSignup";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import StepOneSignup from "./stepOneSignup";
import StepTwoSignup from "./stepTwoSignup";
import dayjs from "dayjs";
import ReCAPTCHA from "react-google-recaptcha";
import { BeatLoader } from "react-spinners";

interface FormSignUpProps {
  stepSignup: number;
  handleStepForm: () => void;
}

export default function FormSignUp({
  stepSignup,
  handleStepForm,
}: FormSignUpProps) {
  const { t } = useTranslation();
  const [isChecked, setIsChecked] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const { handleSubmit } = useFormContext();
  const yearSuper = dayjs().year();
  const { onSubmit, select, onSelect, isLoading, handleSetRole, roleUser } =
    useSignup();

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {stepSignup === 0 && (
          <StepOneSignup
            setIsChecked={setIsChecked}
            select={select}
            onSelect={onSelect}
          />
        )}
        {stepSignup === 1 && (
          <StepTwoSignup roleUser={roleUser} handleChooseRole={handleSetRole} />
        )}
        {stepSignup === 0 && (
          <Button
            background="#34391A"
            color="#D4F70E"
            _hover={{ background: "#34391A" }}
            _active={{
              border: "1px solid #D4F70E",
            }}
            border="1px solid #D4F70E"
            mt={{ base: "28px", md: "32px", lg: "36px" }}
            isLoading={isLoading}
            disabled={!isChecked || !select}
            size="lg"
            w="100%"
            borderRadius="4px"
            onClick={handleStepForm}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("next")}
          </Button>
        )}
        {stepSignup === 1 && (
          <Flex mt="35px">
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY as string}
              onChange={() => setRecaptcha(true)}
              style={{
                width: "100%",
              }}
              onExpired={() => setRecaptcha(false)}
              className="recaptcha"
            />
          </Flex>
        )}

        {stepSignup === 1 && (
          <Button
            background="#34391A"
            color="#D4F70E"
            _hover={{ background: "#34391A" }}
            _active={{
              border: "1px solid #D4F70E",
            }}
            border="1px solid #D4F70E"
            type="submit"
            mt={{ base: "28px", md: "32px", lg: "36px" }}
            isLoading={isLoading}
            disabled={roleUser.length <= 0 || !recaptcha}
            size="lg"
            w="100%"
            borderRadius="4px"
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("signUp")}
          </Button>
        )}

        {stepSignup === 0 && (
          <Flex mt="20px">
            <Link to="/auth/login">
              <Flex>
                <Text fontSize="14px" mr="3px" lineHeight="24px">
                  {t("alreadyAccount")}
                </Text>
                <Text fontSize="14px" color="#D3F70E" lineHeight="24px">
                  {t("signIn")}
                </Text>
              </Flex>
            </Link>
          </Flex>
        )}
        <Text color="#343434" mt="30px" textAlign="center">
          {t("superproductorAllRight")} {yearSuper}.
        </Text>
      </form>
    </Box>
  );
}
