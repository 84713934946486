import { Box } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useInfiniteHits } from "react-instantsearch";
import { FadeLoader } from "react-spinners";

function InfiniteHits({ hitComponent: HitComponent, ...props }) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const sentinelRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver((entries) => {
        const [entry] = entries;

        if (entry.isIntersecting && !isLastPage) {
          showMore();
          setIsLoading(entry.isIntersecting);
        }
      });
      observer.observe(sentinelRef?.current);
      return () => {
        observer.disconnect();
        setIsLoading(false);
      };
    }
  }, [isLastPage, showMore]);

  return (
    <div className="ais-InfiniteHits">
      <ul className="ais-InfiniteHits-list">
        {hits?.map((hit, index) => (
          <li key={hit.objectID} className="ais-InfiniteHits-item">
            <HitComponent hit={hit} index={index} />
          </li>
        ))}
        <li
          className="ais-InfiniteHits-sentinel"
          ref={sentinelRef}
          aria-hidden="true"
        />
      </ul>

      <Box display="flex" justifyContent="center">
        <FadeLoader color={"#d4f70e"} loading={isLoading} />
      </Box>
    </div>
  );
}

export default React.memo(InfiniteHits);
