import { RecentAction, TrackSoundKit } from "app/models";
import {
  ALLOW_BROAD_CASTING,
  EXCLUSIVE_LICENSE,
  LICENSE_RENEWAL,
} from "constants/index";

export const convertData = (value: any) => {
  return value || "";
};

export const convertDataUnlimited = (value: any) => {
  return value === -1 ? "Unlimited" : "0";
};

export const convertDataToBoolean = (value: any, type?: any) => {
  if (type === null) {
    return value || null;
  }
  return value || false;
};

export const convertDataToAllow = (value: any) => {
  return value ? ALLOW_BROAD_CASTING.ALLOW : ALLOW_BROAD_CASTING.NOT_ALLOW;
};

export const convertDataDefault = (value: any, type?: any) => {
  if (type === "Unlimited") {
    return value === -1 ? "Unlimited" : value === 0 ? "0" : "Unlimited";
  } else if (type === LICENSE_RENEWAL.ONE_YEAR) {
    return value === 1
      ? LICENSE_RENEWAL.ONE_YEAR
      : value === 2
      ? LICENSE_RENEWAL.TWO_YEAR
      : null;
  }
  return value === false
    ? EXCLUSIVE_LICENSE.NON_EXCLUSIVE
    : value === true
    ? EXCLUSIVE_LICENSE.EXCLUSIVE
    : null;
};

export const updateFollowUnfollow = (item: RecentAction) => {
  return {
    isFollowing: !item?.createdBy?.isFollowing,
    followers: item?.createdBy?.isFollowing
      ? item?.createdBy?.followers - 1
      : item?.createdBy?.followers + 1,
  };
};

export const infoProfile = (item: TrackSoundKit | RecentAction) => {
  return {
    id: item?.createdBy?._id,
    name: item?.createdBy?.name,
    profileImage: item?.createdBy?.profileImage,
  };
};
