import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNewBulkOffer,
  selectedBulkSelector,
} from "app/redux/selectors/bulkOffer";
import { yupResolver } from "@hookform/resolvers/yup";
import formSchema from "./formSchema";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import { RootState } from "types";
import { useToggle } from "hooks/useToggle";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NewBulkOfferState } from "app/redux/reducers/bulkOffer";
import {
  createBulkOffer,
  getBulkOfferById,
  editBulkOffer,
} from "app/redux/actions/bulkOffer";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import { useHistory, useParams } from "react-router-dom";

export const useAddBulkOffer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSuccessModal, toggleShowSuccessModal] = useToggle();
  const [hasAddedBulkoffer, setHasAddedBulkoffer] = useState(false);
  const initialValues: NewBulkOfferState = useSelector(selectNewBulkOffer);
  const { id } = useParams<{ id: string }>();

  const isLoadingAddAction = useSelector((state: RootState) =>
    isLoadingSelector([createBulkOffer.typePrefix], state)
  );

  const isLoadingEditAction = useSelector((state: RootState) =>
    isLoadingSelector([editBulkOffer.typePrefix], state)
  );

  const isSuccessAddAction = useSelector((state: RootState) =>
    successSelector([createBulkOffer.typePrefix], state)
  );
  const isSuccessEditAction = useSelector((state: RootState) =>
    successSelector([editBulkOffer.typePrefix], state)
  );

  const isLoading = useMemo(
    () => isLoadingAddAction || isLoadingEditAction,
    [isLoadingAddAction, isLoadingEditAction]
  );

  const isSuccess = useMemo(
    () => isSuccessAddAction || isSuccessEditAction,
    [isSuccessAddAction, isSuccessEditAction]
  );

  const selectedBulk = useSelector(selectedBulkSelector);
  const { LicenseContracts: licensesList, getLicenseAndContracts } =
    useLicenseContracts();

  useEffect(() => {
    if (id) {
      dispatch(getBulkOfferById(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (isSuccess && hasAddedBulkoffer) {
      setHasAddedBulkoffer(false);
      history.push("/dashboard/discounts");
    }
  }, [isSuccess, history, hasAddedBulkoffer, setHasAddedBulkoffer]);

  const methods = useForm<NewBulkOfferState>({
    resolver: yupResolver(formSchema),
    defaultValues: { ...initialValues, ...(selectedBulk ?? {}) },
  });

  const { watch } = methods;
  const addBulkofferWatch = watch();

  const { minimumTracks, freeTracks, licenses } = addBulkofferWatch;

  const idEdit = useMemo(() => !!id, [id]);

  const tabsMenu = useMemo(
    () => [
      {
        name: !idEdit ? t("NewBulkOffer") : t("EditBulkOffer"),
        id: 0,
        isCheck: !!minimumTracks && !!freeTracks,
      },
      { name: t("ApplyToLicense"), id: 1, isCheck: licenses.length > 0 },
    ],
    [t, minimumTracks, freeTracks, licenses, idEdit]
  );

  const onAddBulkOffer = useCallback(
    (values: NewBulkOfferState) => {
      const { minimumTracks, freeTracks } = values;
      setHasAddedBulkoffer(true);
      if (idEdit) {
        dispatch(
          editBulkOffer({
            ...values,
            name: `Buy ${minimumTracks} tracks, get ${freeTracks} free!`,
          })
        );
      } else {
        dispatch(
          createBulkOffer({
            ...values,
            name: `Buy ${minimumTracks} tracks, get ${freeTracks} free!`,
            active: true,
          })
        );
      }
    },
    [idEdit, setHasAddedBulkoffer, dispatch]
  );

  return {
    methods,
    showSuccessModal,
    tabsMenu,
    licenses: licensesList?.licenses || [],
    isLoading,
    idEdit,
    onAddBulkOffer,
    toggleShowSuccessModal,
    getLicenseAndContracts,
  };
};
