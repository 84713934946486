import { Box, Button, Flex, Text } from "@chakra-ui/react";
import BuyTrackSuccess from "app/assets/icons/buyTrackSuccess";
import IconErrorPayment from "app/assets/icons/iconErrorPayment";
import ThankYouBackground from "app/assets/images/ThankYouBackground.png";
import { TrackSoundKit } from "app/models";
import {
  selectPaymentMethods,
  clearAppliedPromoCode,
} from "app/redux/actions/cart";
import { cartErrorSelector, lastCartSelector } from "app/redux/selectors/cart";
import { useCart } from "hooks/cart/useCart";
import queryString from "query-string";
import React, { useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import ThankYouItemTrack from "./ThankYouItemTrack";

export default function ThankYou() {
  const { t } = useTranslation();
  const shoppinlasgCart = useSelector(lastCartSelector);
  const cartError = useSelector(cartErrorSelector);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const params = location.search;
  const status = queryString.parse(params);
  const { paymentMethods } = useCart();

  const redirectDashboard = useCallback(() => {
    history.push("/home");
    paymentMethods.length > 0 &&
      dispatch(selectPaymentMethods(paymentMethods[paymentMethods.length - 1]));
  }, [dispatch, history, paymentMethods]);

  const redirectCheckout = useCallback(() => {
    paymentMethods.length > 0 &&
      dispatch(selectPaymentMethods(paymentMethods[paymentMethods.length - 1]));
    history.push("cart");
  }, [dispatch, history, paymentMethods]);

  const statusSuccess = useMemo(() => {
    return status.status === "success";
  }, [status.status]);
  useEffect(() => {
    dispatch(clearAppliedPromoCode());
  }, [dispatch]);

  return (
    <Box
      p={{ base: "40px 16px", md: "40px", lg: "40px 80px" }}
      overflow="hidden"
    >
      <Flex
        backgroundImage={ThankYouBackground}
        backgroundRepeat="round"
        backgroundSize="cover"
        width="100%"
        height={{ base: "500px", md: "600px" }}
        alignItems="center"
        justifyContent="center"
      >
        <Flex flexDirection="column" alignItems="center">
          {statusSuccess ? (
            <Box mt="50px">
              <BuyTrackSuccess />
            </Box>
          ) : (
            <Flex
              backgroundColor="#D4F70E"
              width="125px"
              height="125px"
              alignItems="center"
              justifyContent="center"
              borderRadius="25px"
              boxShadow="-35px 39px 50px 8px rgba(212,247,14,0.19)"
            >
              <IconErrorPayment />
            </Flex>
          )}

          {statusSuccess ? (
            <>
              <Text
                textAlign="center"
                fontSize={{ base: "22px", md: "24px" }}
                lineHeight="28px"
                mt="20px"
              >
                {t("thanksForBuying")}
              </Text>
              <Text
                textAlign="center"
                fontSize={{ base: "24px", md: "32px" }}
                lineHeight="38px"
                color="#D4F70E"
                m="5px 0px 20px"
              >
                {`${t("yourTrackOrder")} ${shoppinlasgCart.length} ${t(
                  "trackOrder"
                )} `}
              </Text>
            </>
          ) : (
            <>
              {cartError ? (
                <Text
                  textAlign="center"
                  fontSize="24px"
                  lineHeight="28px"
                  mt="32px"
                >
                  {cartError}
                </Text>
              ) : (
                <>
                  <Text
                    textAlign="center"
                    fontSize="24px"
                    lineHeight="28px"
                    mt="32px"
                  >
                    {t("serverError")}
                  </Text>
                  {/* <Text
                        textAlign="center"
                        fontSize="24px"
                        lineHeight="28px"
                        mt="10px"
                      >
                        {t("contactYourBank")}
                      </Text> */}
                </>
              )}
            </>
          )}

          {statusSuccess && (
            <Box mt="20px" height={"270px"} overflow="scroll">
              {shoppinlasgCart.map((item: TrackSoundKit, index: any) => (
                <Box key={index}>
                  <ThankYouItemTrack track={item} />
                </Box>
              ))}
            </Box>
          )}
        </Flex>
      </Flex>

      <Flex flexDirection="column" alignItems="center">
        {statusSuccess && (
          <Text
            whiteSpace="pre-line"
            textAlign="center"
            fontSize="14px"
            lineHeight="26px"
            mb="20px"
            mt="30px"
          >
            {t("pleaseCheckEmail")}
          </Text>
        )}

        <Button
          height="54px"
          width="340px"
          borderRadius="4px"
          background="#34391A"
          color="#D4F70E"
          border="1px solid #D4F70E"
          _hover={{
            background: "#34391A",
          }}
          fontSize="17px"
          lineHeight="20px"
          onClick={statusSuccess ? redirectDashboard : redirectCheckout}
        >
          {statusSuccess ? t("backToFeed") : t("backToCheckout")}
        </Button>
      </Flex>
    </Box>
  );
}
