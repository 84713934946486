import { Flex, Text, CloseButton } from "@chakra-ui/react";
import React from "react";
import { useCurrentRefinements } from "react-instantsearch";

function CurrentRefinements() {
  const { items, refine } = useCurrentRefinements();

  return (
    <Flex flexWrap="wrap">
      {items?.map((item) =>
        item.refinements.map((filter) => (
          <Flex alignItems="center">
            <Text>{filter.label}</Text>
            <CloseButton
              _focus={{ boxShadow: "none" }}
              color="#d4f70e"
              onClick={() => refine(filter)}
            />
          </Flex>
        ))
      )}
    </Flex>
  );
}
export default React.memo(CurrentRefinements);
