import { Icon } from "@chakra-ui/react";
import React from "react";

const PlayIcon = () => {
  return (
    <Icon
      width={{ base: "15px", md: "20px" }}
      height="16px"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.527 8 2.255 2.41v11.18L11.527 8Zm1.855 1.118c.824-.497.824-1.739 0-2.236L2.255.175C1.43-.322.4.299.4 1.293v13.414c0 .994 1.03 1.615 1.855 1.118l11.127-6.707Z"
        fill="#F1F2F3"
      />
    </Icon>
  );
};
export default PlayIcon;
