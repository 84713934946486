import { Box, Image, Text, Flex } from "@chakra-ui/react";
import ImageHotProducers from "app/assets/images/ImageDefaultLoading.png";
import { User } from "app/models";
import React from "react";
import RedirectPage from "./redirectPage";

interface HotProducersMobileProps {
  hotproducers: User;
}

export default function HotProducersMobile({
  hotproducers,
}: HotProducersMobileProps) {
  const { redirectPage, pageProfile } = RedirectPage();

  return (
    <Flex minW="210px" alignItems="center" mr="10px">
      <Image
        cursor="pointer"
        height={{ base: "68px", md: "148px" }}
        w={{ base: "68px", md: "148px" }}
        src={hotproducers.profileImage}
        fallbackSrc={ImageHotProducers}
        borderRadius="6px"
        onClick={() => redirectPage(pageProfile(hotproducers?.name))}
      />
      <Box
        background="#25252A"
        padding="5px 10px"
        w="145px"
        h="68px"
        borderRadius="6px"
        ml="5px"
        overflow="hidden"
        _hover={{
          background: "#374216",
          border: "1px solid #D4F70E",
        }}
        border="1px solid #535353"
      >
        <Text
          onClick={() => redirectPage(pageProfile(hotproducers?.name))}
          fontSize="16px"
          fontWeight="500"
          fontFamily="Cera Pro Regular"
          _hover={{
            textDecor: "underline",
          }}
          lineHeight="normal"
          noOfLines={1}
          cursor="pointer"
        >
          {hotproducers.name}
        </Text>
        {!!hotproducers?.newRelease?.title && (
          <Box>
            <Text
              fontSize="12px"
              fontWeight="400"
              color="#D4F70E"
              fontFamily="Cera Pro Regular"
              cursor="pointer"
              onClick={() =>
                redirectPage(`track/${hotproducers?.newRelease?.slug}`)
              }
            >
              New release:
            </Text>
            <Text
              fontSize="12px"
              fontWeight="400"
              color="rgba(255, 255, 255, 0.32)"
              noOfLines={1}
              fontFamily="Cera Pro Regular"
              _hover={{
                textDecor: "underline",
              }}
              cursor="pointer"
              onClick={() =>
                redirectPage(`track/${hotproducers?.newRelease?.slug}`)
              }
            >
              {hotproducers?.newRelease?.title}
            </Text>
          </Box>
        )}
      </Box>
    </Flex>
  );
}
