import * as yup from "yup";

const formSchemaShortLink = () =>
  yup.object().shape({
    user: yup
      .array(yup.object())
      .required("userRequired")
      .min(1, "userRequired"),
    expiresAt: yup.date().required("expireRequired"),
  });

export default formSchemaShortLink;
