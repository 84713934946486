import {
  existingOptionsModel,
  RecentAction,
  Track,
  TrackSoundKit,
} from "app/models";
import { DOWNLOAD_OPTIONS } from "constants/downloadOptions";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";
import { typeMusic } from "utils/actionText";

var slugify = require("slugify");

export const convertTracks = (tracks: Track[], isSk?: boolean) => {
  return (tracks || []).map((track: Track) => {
    return {
      ...track,
      data: track?.loopAndSamplesType?.loopAndSamplesData,
      typeLoopSample: track?.loopAndSamplesType?.typeLoopSample,
      isSK: isSk,
      key: TRACK_KEY_NOTATION[track.key],
      stringKey: track.key,
    };
  });
};

export const filterTracks = (itemTracks: TrackSoundKit[]) => [
  ...new Map((itemTracks || []).map((item) => [item._id, item])).values(),
];

export const heightLoadMore = (existingOptions: existingOptionsModel, e) => {
  return (
    window.innerHeight + e.target.documentElement.scrollTop >=
      e.target.documentElement.scrollHeight &&
    existingOptions?.hasMore &&
    !existingOptions?.loadingMore
  );
};

export const slugTrackSoundkit = (title: string) => {
  return `${slugify(title || "", {
    lower: true,
  })}${!!title ? `-${uuidv4().slice(0, 6)}` : ""}`;
};

export const searchData = (data, searchText) => {
  return (data || []).filter(
    (item) =>
      item?.title?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  );
};

export const checkFreeDownload = (track?: TrackSoundKit) => {
  return (
    track?.freeDownloadEnabled === DOWNLOAD_OPTIONS.YES_UNTAGGED ||
    track?.freeDownloadEnabled === DOWNLOAD_OPTIONS.YES_WITH_VOICE_TAG ||
    track?.freeDownloadEnabled === DOWNLOAD_OPTIONS.YES
  );
};
export const checkTypeDownload = (track: TrackSoundKit) => {
  return track?.freeDownloadEnabled === DOWNLOAD_OPTIONS.YES_WITH_VOICE_TAG;
};

export default function downloadFromUrl(url: string, name: string) {
  const checkTypeFileDownload = url.includes("wav");
  const checkFileZip = url.includes("zip");
  saveAs(
    url,
    `${name}.${checkFileZip ? "zip" : checkTypeFileDownload ? "wav" : "mp3"}`
  );
}

export const checkIsSKInRecentAction = (item: RecentAction) => {
  return item?.type === typeMusic.SOUND_KIT;
};

export const downloadSoundpack = (url: string, name: string) => {
  const anchor = document.createElement("a");
  anchor.download = name;
  anchor.href = url;
  anchor.click();
};

export const checkTypeTrackOrSoundPack = (item: TrackSoundKit) => {
  return !!item?.trackState;
};

export const isNotForSaleTrackSoundPacks = (item: TrackSoundKit) =>
  item?.notForSale || item?.private;

export const formatNumber = (number: number) => {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + "M";
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + "K";
  } else {
    return number.toString();
  }
};

export const capitalizeTheFirst = (item: string) => {
  return item.charAt(0).toLocaleUpperCase() + item.slice(1).toLocaleLowerCase();
};
