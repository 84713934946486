import React from "react";

const IconToggleModeFooterPlayer = () => {
  return (
    <svg
      width="42"
      height="33"
      viewBox="0 0 42 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.75"
        y="0.75"
        width="39.8521"
        height="31.2"
        rx="2.55"
        fill="#24252A"
        fill-opacity="0.8"
        stroke="#535353"
        strokeWidth="1.5"
      />
      <path
        d="M4.32755 0C1.93841 0 0 1.93841 0 4.32755V28.3695C0 30.7586 1.93841 32.697 4.32755 32.697H37.0246C39.4137 32.697 41.3521 30.7586 41.3521 28.3695V4.32755C41.3521 1.93841 39.4137 0 37.0246 0H4.32755ZM4.32755 0.961678H37.0246C38.8976 0.961678 40.3905 2.45454 40.3905 4.32755V28.3695C40.3905 30.2425 38.8976 31.7354 37.0246 31.7354H4.32755C2.45454 31.7354 0.961678 30.2425 0.961678 28.3695V4.32755C0.961678 2.45454 2.45454 0.961678 4.32755 0.961678Z"
        fill="#535353"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 9H10.5V7.5H13.5V4.5H15V7.5C15 8.3205 14.3205 9 13.5 9ZM13.5 21H10.5V22.5H13.5V25.5H15V22.5C15 21.6795 14.3205 21 13.5 21ZM27 18C27 18.8205 26.3205 19.5 25.5 19.5H16.5C15.6795 19.5 15 18.8205 15 18V12C15 11.1795 15.6795 10.5 16.5 10.5H25.5C26.3205 10.5 27 11.1795 27 12V18ZM24 13.5H18V16.5H24V13.5ZM27 22.5V25.5H28.5V22.5H31.5V21H28.5C27.6795 21 27 21.6795 27 22.5ZM28.5 7.5V4.5H27V7.5C27 8.3205 27.6795 9 28.5 9H31.5V7.5H28.5Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconToggleModeFooterPlayer;
