import React from "react";

const IconSuccessNegotiation = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="21.9999" height="21.9999" rx="11" fill="#24252A" />
      <rect
        width="14.7694"
        height="14.7694"
        rx="7.38468"
        transform="matrix(0.707115 -0.707099 0.707115 0.707099 0.443848 11.0039)"
        fill="#D4F70E"
      />
      <path
        d="M8.44385 11.5041L10.4438 13.0042L13.4438 9.00391"
        stroke="#24252A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconSuccessNegotiation;
