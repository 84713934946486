import { Box, Flex, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import PlanDetailModal from "app/components/Subscription/PlanDetailModal";
import { SubscriptionPlan, TrackSoundKit } from "app/models";
import {
  getListSoundkitOfCart,
  getListTrackOfCart,
  removeFromCart,
  selectPaymentType,
  validatePromoCode,
} from "app/redux/actions/cart";
import {
  currentCartSelector,
  cartPromoCodeSelector,
} from "app/redux/selectors/cart";
import { useCart } from "hooks/cart/useCart";
import useTrackSelect from "hooks/track/useTrackSelect";
import { useToggle } from "hooks/useToggle";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ModalPurchase from "../ModalPurchase";
import ModalPromoCode from "../PaymentMethods/CartSummary/modalPromoCode";
import BasketItem from "./BasketItem";
import CartEmpty from "./CartEmpty";
import "./styles.scss";
import SkeletonCartItem from "./skeletonCartItem";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";

function CartItem() {
  const listCart = useSelector(currentCartSelector);

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showModalPlanDetail, setShowModalPlanDetail] = useToggle();
  const [showModalPromoCode, setShowModalPromoCode] = useToggle();
  const promoCode = useSelector(cartPromoCodeSelector);
  const isLoadingGetTrack = useSelector((state: RootState) =>
    isLoadingSelector([getListTrackOfCart.typePrefix], state)
  );
  const isLoadingGetSoundkit = useSelector((state: RootState) =>
    isLoadingSelector([getListSoundkitOfCart.typePrefix], state)
  );

  const {
    showOptionsModal,
    setShowOptionsModal,
    selectedTrack,
    onOpenOptionsModal,
  } = useTrackSelect();

  const {
    selectAllCheckbox,
    handleCheckbox,
    handleSelectAll,
    paymentMethod,
    setSelectAllCheckbox,
  } = useCart();

  const redirectDashboard = () => {
    history.push({
      pathname: "/dashboard",
      state: { label: "" },
    });
  };

  const redirectConfirm = () => {
    history.push("/cart/payment-methods");
  };

  const trackIds: any[] = useMemo(() => {
    return listCart?.filter((item) => item?.trackState).map((id) => id?._id);
  }, [listCart]);
  const soundkitIds: any[] = useMemo(() => {
    return listCart?.filter((item) => item?.soundkitState).map((id) => id?._id);
  }, [listCart]);

  useEffect(() => {
    if (trackIds?.length > 0) {
      dispatch(getListTrackOfCart(trackIds));
    }
    if (soundkitIds?.length > 0) {
      dispatch(getListSoundkitOfCart(soundkitIds));
    }
  }, []);
  useEffect(() => {
    if (promoCode?.promoCode) {
      dispatch(validatePromoCode(promoCode?.promoCode));
    }
  }, [dispatch, promoCode?.promoCode]);

  const data = (listCart || [])
    .filter((item: TrackSoundKit) => {
      if (item.trackState) {
        return item.trackState === "EXCLUSIVE_PURCHASED";
      } else {
        return item.soundkitState === "EXCLUSIVE_PURCHASED";
      }
    })
    .map((trackItem: TrackSoundKit) => trackItem?._id);

  useEffect(() => {
    dispatch(selectPaymentType(""));
    if (data.length > 0) {
      dispatch(removeFromCart(data));
    }
  }, [data, dispatch]);

  return (
    <Box
      p={{ base: "0px 16px 10px 16px", md: "0px 30px", xl: "0px 80px" }}
      className="cart-item-components"
    >
      <Flex
        alignItems="center"
        mb="32px"
        position="sticky"
        top="95px"
        background="#18181c"
        zIndex={90}
        height="100px"
      >
        <Box
          cursor="pointer"
          className="vector-button"
          onClick={redirectDashboard}
        >
          <Back />
        </Box>
        <Text fontSize="32px">{t("cart")}</Text>
      </Flex>

      {isLoadingGetTrack || isLoadingGetSoundkit ? (
        <SkeletonCartItem />
      ) : listCart?.length > 0 ? (
        <BasketItem
          data={listCart}
          onOpenOptionsModal={onOpenOptionsModal}
          handleCheckbox={handleCheckbox}
          handleSelectAll={handleSelectAll}
          valueCheckbox={selectAllCheckbox}
          onClick={redirectConfirm}
          nameStripe={paymentMethod?.payload?.billing_details?.name}
          setValueCheckBox={setSelectAllCheckbox}
          setShowModalPromoCode={setShowModalPromoCode}
        />
      ) : (
        <CartEmpty />
      )}
      <ModalPurchase
        showModal={showOptionsModal}
        setShowModal={setShowOptionsModal}
        setShowModalPlanDetail={setShowModalPlanDetail}
        selectedTrack={selectedTrack}
      />
      <PlanDetailModal
        planDetail={(selectedTrack || {}) as SubscriptionPlan}
        isOpen={showModalPlanDetail}
        onClose={setShowModalPlanDetail}
      />
      <ModalPromoCode
        showModalPromCode={showModalPromoCode}
        setShowModalPromoCode={setShowModalPromoCode}
      />
    </Box>
  );
}

export default React.memo(CartItem);
