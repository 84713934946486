import React from "react";

const IconMyPlaylistProfile = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 11H16"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 17H13"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 5H20"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5Z"
        fill="#D4F70E"
      />
      <path
        d="M20 18.5V10.6C20 10.2686 20.2686 10 20.6 10H22M20 18.5C20 19.3284 19.3284 20 18.5 20C17.6716 20 17 19.3284 17 18.5C17 17.6716 17.6716 17 18.5 17C19.3284 17 20 17.6716 20 18.5Z"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default IconMyPlaylistProfile;
