import React from "react";

const IconSharePlaylistIndividual = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0065 0.0929827C11.4776 -0.100445 12.0178 0.0136814 12.3727 0.381613L20.8954 9.21846C21.3681 9.70863 21.3681 10.4899 20.8954 10.9801L12.3727 19.817C12.0178 20.1849 11.4776 20.299 11.0065 20.1056C10.5354 19.9122 10.2272 19.4498 10.2272 18.9361V13.886C8.38918 13.8152 5.23257 14.3209 2.20954 17.9778C1.87239 18.3857 1.31849 18.5367 0.823929 18.3556C0.329368 18.1745 0 17.7001 0 17.1688C0 15.206 0.557867 12.2848 2.27203 9.81629C3.86389 7.52387 6.41537 5.68535 10.2272 5.35508V1.26245C10.2272 0.748827 10.5354 0.286411 11.0065 0.0929827ZM12.7272 4.36779V6.56455C12.7272 7.26176 12.1676 7.82696 11.4772 7.82696C7.80777 7.82696 5.62707 9.38203 4.31885 11.266C3.79312 12.0231 3.40143 12.8452 3.11896 13.6597C6.69193 11.0496 10.1159 11.2343 11.6881 11.506C12.2883 11.6098 12.7272 12.1353 12.7272 12.7503V15.8308L18.255 10.0993L12.7272 4.36779Z"
        fill="white"
      />
    </svg>
  );
};
export default IconSharePlaylistIndividual;
