import React from "react";

const IconBookRenawal = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5C5.75 4.58579 6.08579 4.25 6.5 4.25H20.5C20.9142 4.25 21.25 4.58579 21.25 5V22C21.25 22.4142 20.9142 22.75 20.5 22.75H6.5C6.08579 22.75 5.75 22.4142 5.75 22V5ZM7.25 5.75V21.25H19.75V5.75H7.25Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.75 2.5C2.75 1.80965 3.30965 1.25 4 1.25H17.5C17.9142 1.25 18.25 1.58579 18.25 2V5C18.25 5.41421 17.9142 5.75 17.5 5.75C17.0858 5.75 16.75 5.41421 16.75 5V2.75H4.25V18.25H6.5C6.91421 18.25 7.25 18.5858 7.25 19C7.25 19.4142 6.91421 19.75 6.5 19.75H3.5C3.08579 19.75 2.75 19.4142 2.75 19V2.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 11C9.75 10.5858 10.0858 10.25 10.5 10.25H16.5C16.9142 10.25 17.25 10.5858 17.25 11C17.25 11.4142 16.9142 11.75 16.5 11.75H10.5C10.0858 11.75 9.75 11.4142 9.75 11Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75 15C9.75 14.5858 10.0858 14.25 10.5 14.25H16.5C16.9142 14.25 17.25 14.5858 17.25 15C17.25 15.4142 16.9142 15.75 16.5 15.75H10.5C10.0858 15.75 9.75 15.4142 9.75 15Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconBookRenawal;
