import {
  Button,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { useLoginForm } from "hooks/login/useLoginForm";

interface ModalProps {
  showModal: boolean;
  setShowModal: () => void;
}

export default function ModalQuickStepNeeded({
  showModal = false,
  setShowModal,
}: ModalProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { onLogout } = useLoginForm();
  const handleRedirect = useCallback(
    async (router) => {
      await onLogout();
      history.push(`${router}`);
    },
    [history, onLogout]
  );
  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent minW={{ base: "350px", md: "500px" }} margin="10px">
        <ModalHeader
          fontSize={{ base: "28px", md: "32px" }}
          fontFamily="Cera Pro Regular"
          color="#d4f70e"
          display="flex"
          justifyContent="center"
          mt={{ base: "15px", md: "25px" }}
        >
          {t("quickStepNeeded")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={2}
          top="10px"
          fontSize="22px"
          position="absolute"
          color="#d4f70e"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "12px",
          }}
          padding="12px"
        />
        <ModalBody padding="10px 40px 20px 40px">
          <Text fontSize="14px" textAlign="center" mb="10px">
            {t("toAddItemsToYourCart")}
          </Text>
          <Text fontSize="14px" textAlign="center">
            {t("shoppingContinue")}
          </Text>
          <Flex justifyContent="center" m="15px 0px">
            <Button
              background="#474E23"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{ background: "#474E23" }}
              w="40%"
              size="lg"
              onClick={() => handleRedirect("/auth/signup")}
            >
              {t("signUp")}
            </Button>
          </Flex>
          <Text
            fontSize="14px"
            textAlign="center"
            onClick={() => handleRedirect("/auth/login")}
            cursor="pointer"
          >
            {t("alreadyHaveAnAccount")}{" "}
            <span style={{ color: "#D4F70E" }}>{t("login")}</span>
          </Text>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
