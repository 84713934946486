import { createReducer, current } from "@reduxjs/toolkit";
import { customers, transaction } from "app/models";
import {
  getPurchased,
  createDownloadLog,
  getListEmailCustomers,
} from "../actions/purchased";
import { AnalyticType } from "constants/analyticsType";

export interface InitPurchasedState {
  purchasedList: Array<transaction>;
  fetchTransactionOptions: {
    page: number;
    loadingMore: boolean;
    refreshing: boolean;
    hasMore: boolean;
  };
  emailCustomers: Array<customers>;
  fetchEmailCustomersOptions: {
    page: number;
    loadingMore: boolean;
    refreshing: boolean;
    hasMore: boolean;
  };
}

const initialState: InitPurchasedState = {
  purchasedList: [],
  fetchTransactionOptions: {
    page: 1,
    loadingMore: false,
    refreshing: false,
    hasMore: true,
  },
  emailCustomers: [],
  fetchEmailCustomersOptions: {
    page: 1,
    loadingMore: false,
    refreshing: false,
    hasMore: true,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(getPurchased.pending, (state, action) => {
      return {
        ...state,
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          page: action?.meta?.arg?.page || 1,
          loadingMore: action?.meta?.arg?.loadingMore || false,
        },
      };
    })
    .addCase(getPurchased.fulfilled, (state, action) => {
      return {
        ...state,
        purchasedList:
          state.fetchTransactionOptions.page === 1
            ? action.payload
            : [...state.purchasedList, ...action.payload],
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          loadingMore: false,
          hasMore: action.payload.length === 25,
        },
      };
    })
    .addCase(getPurchased.rejected, (state, action) => {
      return {
        ...state,
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          page: action?.meta?.arg?.page - 1,
          loadingMore: false,
        },
      };
    })
    .addCase(createDownloadLog.fulfilled, (state, action) => {
      const currentPurchased = current(state.purchasedList);
      const updatePurchased = (currentPurchased || []).map((item) => {
        if (item._id === action.payload) {
          return {
            ...item,
            statusDownload: AnalyticType.DOWNLOADED,
            timeDownload: new Date().toString(),
          };
        } else {
          return item;
        }
      });
      return {
        ...state,
        purchasedList: updatePurchased,
      };
    })
    .addCase(getListEmailCustomers.pending, (state, action) => {
      return {
        ...state,
        fetchEmailCustomersOptions: {
          ...state.fetchEmailCustomersOptions,
          page: action?.meta?.arg?.page || 1,
          loadingMore: action?.meta?.arg?.loadingMore || false,
        },
      };
    })
    .addCase(getListEmailCustomers.fulfilled, (state, action) => {
      return {
        ...state,
        emailCustomers:
          state.fetchEmailCustomersOptions.page === 1
            ? action.payload
            : [...state.emailCustomers, ...action.payload],
        fetchEmailCustomersOptions: {
          ...state.fetchEmailCustomersOptions,
          loadingMore: false,
          hasMore: action.payload.length === 25,
        },
      };
    })
    .addCase(getListEmailCustomers.rejected, (state, action) => {
      return {
        ...state,
        fetchEmailCustomersOptions: {
          ...state.fetchEmailCustomersOptions,
          page: action?.meta?.arg?.page - 1,
          loadingMore: false,
        },
      };
    })
);
