export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

export const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

export const typeDivice = {
  APPLE: "Apple",
};
