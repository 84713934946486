import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import ErrorMessage from "app/components/ErrorMessage";
import SelectInput from "app/components/SelectInput";
import TextInput from "app/components/TextInput";
import { Collaborator, UserInCollaborators, User } from "app/models";
import { collaboratorType } from "constants/collaboratorType";
import { useAddCollaborator } from "hooks/track/useAddCollaborator";
import React, { useEffect, useMemo } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "types";
import { successSelector } from "app/redux/selectors/status";
import { submitTrack } from "app/redux/actions/tracks";
import { selectUserAdmin } from "app/redux/selectors/users";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: ({
    target: { value },
  }: {
    target: {
      value: Collaborator[];
    };
  }) => void;
  value: Collaborator[];
  newCollaborators: Collaborator[];
  isAddSoudkit?: boolean;
  selectedCollaEdit: Collaborator;
}

export default function ModalShowCollaborator({
  isOpen,
  onClose,
  onChange,
  value,
  newCollaborators,
  isAddSoudkit = false,
  selectedCollaEdit,
}: ModalProps) {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);
  const isSuccess = useSelector((state: RootState) =>
    successSelector([submitTrack.typePrefix], state)
  );
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const isCurrentUser = useMemo(() => {
    return currentUser?.name === (selectedCollaEdit?.user as User)?.name;
  }, [currentUser, selectedCollaEdit]);

  const validatePublishing = useMemo(() => {
    const mainCollaboration = newCollaborators[0];
    if ((selectedCollaEdit?.user as User)?.name) {
      return {
        ...(mainCollaboration ? { ...mainCollaboration } : {}),
        profitShare:
          Number(mainCollaboration?.profitShare || 0) +
          Number(selectedCollaEdit?.profitShare || 0),
        publishing:
          Number(mainCollaboration?.publishing || 0) +
          Number(selectedCollaEdit?.publishing || 0),
      };
    }
    return mainCollaboration;
  }, [
    newCollaborators,
    selectedCollaEdit?.profitShare,
    selectedCollaEdit?.publishing,
    selectedCollaEdit?.user,
  ]);
  const { methods, getCollaborators, collaborations } = useAddCollaborator(
    validatePublishing,
    isAddSoudkit
  );

  useEffect(() => {
    getCollaborators();
  }, [getCollaborators, isSuccess]);

  const convertData = useMemo(() => {
    let listCollaborators: Collaborator[] = [];
    collaborations.map((item: UserInCollaborators) => {
      return listCollaborators.push({
        value: item?.name,
        label: item?.name,
        name: item?.name,
        avatar: item?.profileImage,
        user: item?._id,
      });
    });

    return listCollaborators;
  }, [collaborations]);

  const {
    formState: { errors },
    control,
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    if ((selectedCollaEdit?.user as User)?.name) {
      reset({
        user: (selectedCollaEdit?.user as User)?.name
          ? { ...selectedCollaEdit.user }
          : null,
        publishing: selectedCollaEdit.publishing,
        profitShare: selectedCollaEdit.profitShare,
        role: selectedCollaEdit.role,
      });
    } else {
      reset({
        user: null,
        publishing: "",
        profitShare: "",
        role: "",
      });
    }
  }, [
    reset,
    selectedCollaEdit.profitShare,
    selectedCollaEdit.publishing,
    selectedCollaEdit.role,
    selectedCollaEdit.user,
  ]);

  const formatOptionLabel = ({ name, avatar }: any) => (
    <Flex
      background="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="45px"
      padding="5px 15px"
      margin="0px"
    >
      <Text minW="200px" className="color" fontSize="14px" fontWeight="600">
        {name}
      </Text>
      {name && <Image borderRadius="50%" h="34px" w="34px" src={avatar} />}
    </Flex>
  );

  const onAddCollaborator = (values: any) => {
    const { profitShare, publishing, role, user } = values;

    const collaboratorValue = {
      profitShare: Number(profitShare),
      publishing: isAddSoudkit
        ? 0
        : isCurrentUser
        ? Number(value[0]?.publishing)
        : Number(publishing),
      role: role,
      user: user?.user,
      avatar: user?.avatar,
      name: user?.name || "",
    };
    reset();
    onClose();
    let collaborations;
    const currenCollaborator = value.find(
      (item) => item.user === collaboratorValue.user
    );
    if (currenCollaborator) {
      collaborations = value.map((collaborator) => {
        if (collaborator?.user === collaboratorValue.user) {
          return collaboratorValue;
        }
        return collaborator;
      });
    } else {
      collaborations = [...value, collaboratorValue];
    }

    onChange({
      target: {
        value: collaborations,
      },
    });
  };

  return (
    <Modal colorScheme="black" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p={{ base: "16px", md: "40px" }}
        minW={{ base: "350px", md: "500px" }}
        margin={{ base: "0px 10px", md: "0px" }}
      >
        <ModalHeader fontSize={{ base: "24px", md: "32px" }} p="0px">
          {t("addACollaborator")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={onClose}
          right={2}
          top="15px"
          fontSize={{ base: "20px", md: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <form>
          <ModalBody p="0px" mt="20px">
            <Text pb="6px" color="#535353">
              {t("user")}
            </Text>
            <Controller
              name={"user"}
              render={({ field }) => (
                <Select
                  options={convertData}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder={t("searchCollaborators")}
                  formatOptionLabel={formatOptionLabel}
                  {...field}
                  isDisabled={isCurrentUser}
                />
              )}
              control={control}
            />
            <ErrorMessage error={errors.user?.message} />
            <Flex justifyContent="space-between" gridGap="20px" mt="15px">
              {!isAddSoudkit && (
                <Box w={{ base: "50%", md: "max-content" }}>
                  <TextInput
                    type={"number"}
                    name={"publishing"}
                    border="none"
                    labelClassName="label-add-track"
                    backgroundColor="#25252A"
                    label={t("publishing")}
                    placeholder={t("enterPublishing")}
                    errors={errors}
                    control={control}
                    disabled={isCurrentUser}
                    height="100%"
                  />
                </Box>
              )}

              <Box w={isAddSoudkit ? "100%" : isLessThan766 ? "50%" : ""}>
                <TextInput
                  type={"number"}
                  name={"profitShare"}
                  border="none"
                  labelClassName="label-add-track"
                  backgroundColor="#25252A"
                  label={t("profitShare")}
                  placeholder={t("enterProfitShare")}
                  errors={errors}
                  control={control}
                  disabled={isCurrentUser}
                />
              </Box>
            </Flex>

            <Box mt="15px">
              <Controller
                name={"role"}
                render={({ field }) => (
                  <SelectInput
                    placeholder={t("selectRole")}
                    label={t("role")}
                    data={collaboratorType}
                    {...field}
                    error={errors.role?.message}
                  />
                )}
                control={control}
              />
            </Box>
          </ModalBody>

          <ModalFooter justifyContent="flex-start" p="0px" mt="30px">
            <Button
              backgroundColor="#D3F70E"
              color="black"
              borderRadius="4px"
              p="15px 30px"
              onClick={handleSubmit(onAddCollaborator)}
              _hover={{ boxShadow: "none" }}
            >
              {t("addACollaborator")}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
