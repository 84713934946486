import React from "react";

const IconCreateConversation = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_4884_9564)">
        <rect width="24" height="24" rx="12" fill="#24252A" />
        <rect
          x="1"
          y="1"
          width="22"
          height="22"
          rx="11"
          stroke="#D4F70E"
          strokeWidth="2"
        />
      </g>
      <rect
        x="7.5"
        y="12.75"
        width="1.5"
        height="9"
        rx="0.75"
        transform="rotate(-90 7.5 12.75)"
        fill="#CAEA11"
      />
      <rect
        x="12.75"
        y="16.5"
        width="1.5"
        height="9"
        rx="0.75"
        transform="rotate(180 12.75 16.5)"
        fill="#CAEA11"
      />
      <defs>
        <filter
          id="filter0_b_4884_9564"
          x="-14"
          y="-14"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="7" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_4884_9564"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_4884_9564"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default IconCreateConversation;
