import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  Flex,
  Box,
  UnorderedList,
  ListItem,
  Button,
  Image,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useToggle } from "hooks/useToggle";
import ImageNotifiUserRef from "app/assets/images/ImageNotifiUserRef.svg";
import { useHistory } from "react-router-dom";

export default function ModalNotifiUserRef() {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useToggle(true);
  const history = useHistory();
  const handleActive = useCallback(() => {
    localStorage.setItem("userRef", "false");
    history.push("/dashboard/subscription");
  }, [history]);

  const onCloseModal = useCallback(() => {
    localStorage.setItem("userRef", "false");
    history.push("/dashboard");
    setShowModal();
  }, [history, setShowModal]);

  return (
    <Modal returnFocusOnClose={false} isOpen={showModal} isCentered>
      <ModalOverlay />
      <ModalContent
        border="4px solid #D4F70E"
        minW={{ base: "350px", md: "680px", xl: "840px" }}
        maxHeight="468px"
        overflow="scroll"
        margin="10px"
      >
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={onCloseModal}
          right={5}
          top="20px"
          fontSize="18px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "14px",
          }}
          padding="14px"
          color="#0F1418"
        />
        <ModalBody padding={{ base: "0px", xl: "0px 0px 0px 40px" }}>
          <Flex w="100%">
            <Box
              w={{ base: "100%", md: "55%" }}
              padding={{
                base: "35px 30px 35px 30px",
                xl: "35px 30px 35px 0px",
              }}
            >
              <Text
                fontSize={{ base: "30px", xl: "40px" }}
                fontWeight="700"
                lineHeight={{ base: "36px", xl: "48px" }}
              >
                {t("congratulationsUnlock")}
              </Text>
              <Text
                w={{ base: "100%", xl: "85%" }}
                mt="10px"
                fontSize="16px"
                fontWeight="400"
              >
                {t("doubleYourRewards")}
              </Text>
              <UnorderedList mt="15px">
                <ListItem mb="10px" ml="10px" fontSize="14px" fontWeight="400">
                  {t("signUpAndEarn")}{" "}
                  <span style={{ color: "#D4F70E", fontWeight: "700" }}>
                    {t("noCommissionFee")}
                  </span>
                </ListItem>
                <ListItem mb="10px" ml="10px" fontSize="14px" fontWeight="400">
                  {t("tryMainstream")}{" "}
                  <span style={{ color: "#D4F70E", fontWeight: "700" }}>
                    {t("absolutelyFree")}
                  </span>
                </ListItem>
              </UnorderedList>
              <Flex justifyContent="center" m="15px 0px 35px 0px">
                <Button
                  background="#34391A"
                  color="#D4F70E"
                  border="1px solid #D4F70E"
                  _hover={{
                    background: "#34391A",
                  }}
                  borderRadius="24px"
                  padding="25px 20px"
                  fontSize="14px"
                  fontWeight="500"
                  onClick={handleActive}
                >
                  {t("activateNow")}
                </Button>
              </Flex>
            </Box>
            <Box
              w="45%"
              background="#C9CDB6"
              display={{ base: "none", md: "flex" }}
              flexDirection="column"
              justifyContent="center"
            >
              <Image fallbackSrc={ImageNotifiUserRef} />
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
