import {
  Box,
  Text,
  Flex,
  Image,
  ListItem,
  UnorderedList,
  SimpleGrid,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AvatarUserLoading from "app/assets/images/UserAvatar.png";
import "./styles.scss";
import CalenderIcon from "app/assets/icons/calenderIcon";
import ReactCountryFlag from "react-country-flag";
import IconTotalCustomer from "app/assets/icons/IconTotalCustomers";
import IconTotalRefers from "app/assets/icons/IconTotalRefers";
import IconTotalRevenue from "app/assets/icons/IconTotalRevenue";
import IconTotalItems from "app/assets/icons/IconTotalItems";
import IconHeartFeedPageUnlike from "app/assets/icons/IconHeartFeedPageUnlike";
import IconRepeatFeed from "app/assets/icons/IconRepeatFeedPage";
import IconNewFollowers from "app/assets/icons/IconNewFollowers";
import IconNegotiationCart from "app/assets/icons/IconNegotiationCart";
import FreeDownload from "app/assets/icons/freeDownload";
import IconCollaborationsDashboardCover from "app/assets/icons/IconCollaborations";
import ItemLicenseExpiry from "./itemLicenseExpiry";
import { useSelector, useDispatch } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";
import { formatNumber } from "utils/convertTracks";
import { formatMonthYear, formatTimeByDayMonthYear } from "utils/timeAgo";
import countryList from "react-select-country-list";
import { roleText } from "constants/collaboratorType";
import {
  getAnalyticsCurrentUser,
  getListLicenseExpiry,
  getUserOverView,
} from "app/redux/actions/users";
import {
  selectorsAnalyticsCurrentUser,
  selectorsLicenseExpiry,
  selectorsUserOverView,
} from "app/redux/selectors/analytics";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import SkeletonLicenseExpiry from "./skeletonLicenseExpiry";
import ChooseInterest from "../Interest";
import ModalNotifiUserRef from "app/components/ModalNotifiUserRef";
import DeviceDetector from "device-detector-js";
import { typeDivice } from "utils/checkScreen";
import Lottie from "lottie-react";
import loadingAvatar from "../../assets/LottieIAnimations/loadingAvatar.json";
import IconHeartFeedPageUnlikeMobile from "app/assets/icons/IconHeartFeedPageUnlikeMobile";
import IconRepeatDashboardMobile from "app/assets/icons/IconRepeatDashboardMobile";
import IconNewFollowersMobile from "app/assets/icons/IconNewFollowersMobile";
import IconNegotiationCartMobile from "app/assets/icons/IconNegotiationCartMobile";
import IconCollaborationsDashboardCoverMobile from "app/assets/icons/IconCollaborationsMobile";
import IconAddressDashboardCoverMobile from "app/assets/icons/IconAddressDashboardCoverMobile";
import SkeletonLicenseExpiryMobile from "./skeletonLicenseExpiryMobile";
import ItemCustomersDashboardCover from "./recentCustomerActivity";
import { usePurchased } from "hooks/usePurchased";

export default function DashboardCover() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserAdmin);
  const analyticsCurrentUser = useSelector(selectorsAnalyticsCurrentUser);
  const tracksLicenseExpiry = useSelector(selectorsLicenseExpiry);
  const userOverView = useSelector(selectorsUserOverView);
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const [timeExpiry, setTimeExpiry] = useState("");
  const [isLessThan768] = useMediaQuery("(max-width: 766px)");
  const { isLoadingEmailCustomers, fetchEmailCustomersOptions } =
    usePurchased();

  const isLoadingAnalyticsCurrentUser = useSelector((state: RootState) =>
    isLoadingSelector([getAnalyticsCurrentUser.typePrefix], state)
  );
  const isLoadingUserOverView = useSelector((state: RootState) =>
    isLoadingSelector([getUserOverView.typePrefix], state)
  );
  const isLoadingUpComingLicenseExpiry = useSelector((state: RootState) =>
    isLoadingSelector([getListLicenseExpiry.typePrefix], state)
  );
  const options = useMemo(() => countryList().getData(), []);
  const fullNameCountry = useCallback(
    (label) => options.find((item) => item.value === label),
    [options]
  );
  const checkUserRef = useMemo(() => {
    return localStorage.getItem("userRef");
  }, []);

  useEffect(() => {
    dispatch(getUserOverView());
    dispatch(getAnalyticsCurrentUser());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getListLicenseExpiry(timeExpiry));
  }, [dispatch, timeExpiry]);
  const totalItems = useCallback((currentTotal, lastTotal) => {
    if (Number(currentTotal || 0) - Number(lastTotal || 0) > 0) {
      return `+${Number(currentTotal || 0) - Number(lastTotal || 0)}`;
    } else {
      return Number(currentTotal || 0) - Number(lastTotal || 0);
    }
  }, []);

  const widthPercent = useCallback((currentValue, previousValue) => {
    if (currentValue <= previousValue) {
      return 0;
    } else {
      return ((currentValue - previousValue) / previousValue) * 100 > 100
        ? 100
        : ((currentValue - previousValue) / previousValue) * 100;
    }
  }, []);

  const totalInfo = useMemo(
    () => [
      {
        name: t("totalCustomers"),
        icon: <IconTotalCustomer />,
        total: `${formatNumber(userOverView?.totalCustomers || 0)}`,
        width: widthPercent(
          userOverView?.totalCustomersMonth,
          userOverView?.totalCustomersLastMonth
        ),
      },
      {
        name: t("totalRefers"),
        icon: <IconTotalRefers />,
        total: `${formatNumber(userOverView?.totalRefers || 0)}`,
        width: widthPercent(
          userOverView?.totalRefersMonth,
          userOverView?.totalRefersLastMonth
        ),
      },
      {
        name: t("totalRevenue"),
        icon: <IconTotalRevenue />,
        total: `${formatNumber(userOverView?.totalRevenue || 0)}`,
        width: widthPercent(
          userOverView?.totalRevenueOfMonth,
          userOverView?.totalRevenueOfLastMonth
        ),
      },
      {
        name: t("totalItems"),
        icon: <IconTotalItems />,
        total: `${formatNumber(userOverView?.totalItems || 0)}`,
        width: widthPercent(
          userOverView?.totalItemsMonth,
          userOverView?.totalItemsLastMonth
        ),
      },
    ],
    [
      userOverView?.totalCustomers,
      userOverView?.totalCustomersLastMonth,
      userOverView?.totalCustomersMonth,
      userOverView?.totalItems,
      userOverView?.totalItemsLastMonth,
      userOverView?.totalItemsMonth,
      userOverView?.totalRefers,
      userOverView?.totalRefersLastMonth,
      userOverView?.totalRefersMonth,
      userOverView?.totalRevenue,
      userOverView?.totalRevenueOfLastMonth,
      userOverView?.totalRevenueOfMonth,
      t,
      widthPercent,
    ]
  );
  const dataAnalytics = useMemo(
    () => [
      {
        name: t("favorites"),
        icon: isLessThan768 ? (
          <IconHeartFeedPageUnlikeMobile color="#D8FD01" />
        ) : (
          <IconHeartFeedPageUnlike color="#D8FD01" />
        ),
        total: `${formatNumber(analyticsCurrentUser?.totalFavorites || 0)}`,
        moreTotal: `(${totalItems(
          analyticsCurrentUser?.totalFavoritesOfMonth,
          analyticsCurrentUser?.totalFavoritesOfLastMonth
        )})`,
        des: `${t("thanLastMonth")}`,
        color: analyticsCurrentUser?.totalFavorites > 0 ? "white" : "#535353",
        labelTooltip: t("totalFavorites"),
      },
      {
        name: t("repost"),
        icon: isLessThan768 ? (
          <IconRepeatDashboardMobile />
        ) : (
          <IconRepeatFeed />
        ),
        total: `${formatNumber(analyticsCurrentUser?.totalRepost || 0)}`,
        moreTotal: `(${totalItems(
          analyticsCurrentUser?.totalRepostOfMonth,
          analyticsCurrentUser?.totalRepostOfLastMonth
        )})`,
        des: `${t("thanLastMonth")}`,
        color: analyticsCurrentUser?.totalRepost > 0 ? "white" : "#535353",
        labelTooltip: t("totalRepost"),
      },
      {
        name: t("newFollowers"),
        icon: isLessThan768 ? <IconNewFollowersMobile /> : <IconNewFollowers />,
        total: `${formatNumber(
          analyticsCurrentUser?.totalFollowersOfMonth || 0
        )}`,
        moreTotal:
          analyticsCurrentUser?.totalFollowersOfMonth > 0
            ? `(${totalItems(
                analyticsCurrentUser?.totalFollowersOfMonth,
                analyticsCurrentUser?.totalFollowersOfLastMonth
              )})`
            : "",
        des:
          analyticsCurrentUser?.totalFollowersOfMonth > 0
            ? `${t("thanLastMonth")}`
            : "",
        color:
          analyticsCurrentUser?.totalFollowersOfMonth > 0 ? "white" : "#535353",
      },
      {
        name: t("negotiations"),
        icon: isLessThan768 ? (
          <IconNegotiationCartMobile />
        ) : (
          <IconNegotiationCart />
        ),
        total: `${formatNumber(analyticsCurrentUser?.totalNegotiations || 0)}`,
        status: analyticsCurrentUser?.totalNegotiations > 0 ? "Pending" : "",
        color:
          analyticsCurrentUser?.totalNegotiations > 0 ? "white" : "#535353",
      },
      {
        name: t("downloads"),
        icon: <FreeDownload />,
        total: `${formatNumber(
          analyticsCurrentUser?.totalDownloadsMonth || 0
        )}`,
        moreTotal:
          analyticsCurrentUser?.totalDownloadsMonth > 0
            ? `(${totalItems(
                analyticsCurrentUser?.totalDownloadsMonth,
                analyticsCurrentUser?.totalDownloadsLastMonth
              )})`
            : "",
        des:
          analyticsCurrentUser?.totalDownloadsMonth > 0
            ? `${t("thanLastMonth")}`
            : "",
        color:
          analyticsCurrentUser?.totalDownloadsMonth > 0 ? "white" : "#535353",
      },
      {
        name: t("collaborations"),
        icon: isLessThan768 ? (
          <IconCollaborationsDashboardCoverMobile />
        ) : (
          <IconCollaborationsDashboardCover />
        ),
        total: `${formatNumber(
          analyticsCurrentUser?.totalCollaborations || 0
        )}`,
        status: analyticsCurrentUser?.totalCollaborations > 0 ? "Pending" : "",
        color:
          analyticsCurrentUser?.totalCollaborations > 0 ? "white" : "#535353",
      },
    ],
    [analyticsCurrentUser, t, totalItems, isLessThan768]
  );

  return currentUser && currentUser?.userInterest?.length === 0 ? (
    <ChooseInterest />
  ) : currentUser &&
    currentUser?.firstSubMonthly &&
    currentUser?.refBy &&
    !checkUserRef ? (
    <ModalNotifiUserRef />
  ) : (
    <Box className="dashboard-cover">
      {isLoadingUserOverView ? (
        <>
          <Skeleton
            borderRadius="20px"
            mt={{ base: "20px", md: "40px" }}
            w="265px"
            h={{ base: "24px", md: "32px" }}
            mb="30px"
          />
          <Skeleton w="100%" h={{ base: "315px", md: "362px" }} />
        </>
      ) : (
        <>
          <Text
            mt={{ base: "20px", md: "40px" }}
            mb={{ base: "15px", md: "30px" }}
            fontSize={{ base: "20px", md: "32px" }}
            fontWeight="500"
          >
            {t("dashboardOverview")}
          </Text>
          <Box className="boxed" position="relative" overflow="hidden">
            <Flex
              display={{ base: "block", md: "flex" }}
              padding={{
                base: "30px 20px 15px 20px",
                xl: "60px 30px 20px 30px",
              }}
              m={{ base: "0px", md: "1px" }}
              borderRadius="15px"
              background={{
                base: "linear-gradient(to bottom, #505B18, #505A18, #363D1C, #2A2C1D, #1E201C, #18181C)",
                md: "linear-gradient(to right, #505B18, #505A18, #363D1C, #2A2C1D, #1E201C, #18181C)",
              }}
            >
              <Box
                position="absolute"
                top={"-3px"}
                left={"-31px"}
                w="365px"
                h="365px"
                display={{ base: "none", xl: "block" }}
              >
                <Lottie animationData={loadingAvatar} loop={false} />
              </Box>
              <Image
                fallbackSrc={AvatarUserLoading}
                w={{ base: "110px", lg: "220px", xl: "240px" }}
                h={{ base: "110px", lg: "220px", xl: "240px" }}
                borderRadius="full"
                src={userOverView?.profileImage}
                mr={{ base: "0xp", md: "20px", xl: "35px" }}
                display={{ base: "none", md: "block" }}
              />
              <Flex
                display={{ base: "flex", md: "none" }}
                justifyContent="center"
                position="relative"
              >
                <Image
                  fallbackSrc={AvatarUserLoading}
                  w={{ base: "110px", xl: "240px" }}
                  h={{ base: "110px", xl: "240px" }}
                  borderRadius="full"
                  src={userOverView?.profileImage}
                />
                <Box position="absolute" bottom="-3px" left="35%">
                  <ReactCountryFlag
                    countryCode={userOverView?.country}
                    style={{
                      fontSize: "32px",
                    }}
                    svg={
                      device.device?.brand === typeDivice.APPLE ? false : true
                    }
                  />
                </Box>
              </Flex>

              <Box w="100%">
                <Flex
                  w="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  display={{ base: "block", md: "flex" }}
                >
                  <Text
                    fontSize={{ base: "24px", xl: "52px" }}
                    fontWeight="700"
                    mr={{ base: "0px", md: "16px" }}
                    textAlign={{ base: "center", md: "unset" }}
                    mt={{ base: "16px", md: "0px" }}
                    mb={{ base: "8px", md: "0px" }}
                  >
                    {userOverView?.name}
                  </Text>
                  <Flex
                    display={{ base: "flex", md: "none" }}
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    <IconAddressDashboardCoverMobile />
                    <Text
                      lineHeight={{ base: "none", md: "11px" }}
                      fontSize={{ base: "16px", xl: "32px" }}
                      fontWeight="400"
                      color="#A9AAA5"
                      mr={"10px"}
                    >
                      {`${
                        userOverView?.state ? `${userOverView?.state},` : ""
                      } `}
                      {fullNameCountry(userOverView?.country)?.label}
                    </Text>
                    {roleText(userOverView?.role?.[0]) && (
                      <UnorderedList>
                        <ListItem
                          lineHeight="11px"
                          fontSize={{ base: "16px", xl: "32px" }}
                          fontWeight="400"
                          color="#A9AAA5"
                        >
                          {roleText(userOverView?.role?.[0])}
                        </ListItem>
                      </UnorderedList>
                    )}
                  </Flex>

                  <Flex
                    alignItems="center"
                    mt="16px"
                    mb="16px"
                    display={{ base: "flex", md: "none" }}
                    justifyContent="center"
                  >
                    <CalenderIcon />
                    <Text ml="5px" fontSize="14px" fontWeight="500">
                      {t("dateJoined")}
                      <span
                        style={{
                          color: "#D4F70E",
                          fontWeight: "400",
                          marginLeft: "5px",
                        }}
                      >
                        {formatMonthYear(userOverView?.createdAt)}
                      </span>
                    </Text>
                  </Flex>

                  <Flex
                    alignItems="center"
                    display={{ base: "block", md: "flex" }}
                  >
                    <Text
                      mr={{ base: "0px", md: "10px", xl: "15px" }}
                      className="text-outline"
                      fontSize={{ base: "50px", xl: "65px" }}
                      textAlign={{ base: "center", md: "unset" }}
                    >
                      {formatNumber(userOverView?.playsMonth || 0)}
                    </Text>
                    <Box mb={{ base: "0px", xl: "18px" }} whiteSpace="nowrap">
                      <Text
                        fontSize={{ base: "14px", xl: "18px" }}
                        fontWeight="700"
                        textAlign={{ base: "center", md: "unset" }}
                      >
                        {t("monthlyActiveListeners")}
                      </Text>
                      {userOverView?.playsMonth && (
                        <Text
                          fontSize={{ base: "12px", md: "14px" }}
                          fontWeight="300"
                          color="#D8FD01"
                          textAlign={{ base: "center", md: "unset" }}
                        >
                          (
                          {`${totalItems(
                            userOverView?.playsMonth,
                            userOverView?.playsLastMonth
                          )}`}
                          ){" "}
                          <span style={{ color: "white" }}>
                            {t("thanLastMonth")}
                          </span>
                        </Text>
                      )}
                    </Box>
                  </Flex>
                </Flex>
                <Box>
                  <Flex
                    alignItems="center"
                    mb="10px"
                    display={{ base: "none", md: "flex" }}
                  >
                    <CalenderIcon />
                    <Text ml="5px" fontSize="14px" fontWeight="500">
                      {t("dateJoined")}
                      <span
                        style={{
                          color: "#D4F70E",
                          fontWeight: "400",
                          marginLeft: "5px",
                        }}
                      >
                        {formatMonthYear(userOverView?.createdAt)}
                      </span>
                    </Text>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent={{ base: "start", md: "start" }}
                    mb="10px"
                    display={{ base: "none", md: "flex" }}
                  >
                    <ReactCountryFlag
                      countryCode={userOverView?.country}
                      style={{
                        fontSize: "40px",
                      }}
                      svg={
                        device.device?.brand === typeDivice.APPLE ? false : true
                      }
                    />
                    <Text
                      lineHeight="11px"
                      fontSize={{ base: "20px", xl: "32px" }}
                      fontWeight="400"
                      color="#A9AAA5"
                      mr={{ base: "10px", md: "20px" }}
                      ml="10px"
                    >
                      {`${
                        userOverView?.state ? `${userOverView?.state},` : ""
                      } `}
                      {fullNameCountry(userOverView?.country)?.label}
                    </Text>
                    {roleText(userOverView?.role?.[0]) && (
                      <UnorderedList>
                        <ListItem
                          lineHeight="11px"
                          fontSize={{ base: "22px", xl: "32px" }}
                          fontWeight="400"
                          color="#A9AAA5"
                        >
                          {roleText(userOverView?.role?.[0])}
                        </ListItem>
                      </UnorderedList>
                    )}
                  </Flex>
                  <Flex
                    gap="30px"
                    display={{ base: "grid", md: "flex" }}
                    gridTemplateColumns="repeat(2, minmax(0px, 1fr))"
                    padding={{ base: "0px 20px", md: "0px" }}
                  >
                    {totalInfo.map((item) => (
                      <Box key={item.name}>
                        <Flex
                          alignItems="center"
                          mb="5px"
                          display={{ base: "block", md: "flex" }}
                        >
                          <Box
                            background="#2B2E23"
                            borderRadius="6px"
                            w="25px"
                            h="25px"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            mr="10px"
                          >
                            {item.icon}
                          </Box>
                          <Text fontSize={{ md: "14px", xl: "16px" }}>
                            {item.name}
                          </Text>
                        </Flex>
                        <Text
                          mb="5px"
                          fontSize={{ md: "16px", xl: "18px" }}
                          fontWeight="700"
                        >
                          {item.total}
                        </Text>
                        <Box
                          w="100px"
                          borderRadius="8px"
                          h="4px"
                          background="#1A1A1E"
                        >
                          <Box
                            w={`${item.width}%`}
                            h="4px"
                            borderRadius="8px"
                            background="#D8FD01"
                          />
                        </Box>
                      </Box>
                    ))}
                  </Flex>
                  <Text
                    mt={{ base: "10px", xl: "20px" }}
                    fontSize={{ base: "13px", xl: "14px" }}
                    fontWeight="500"
                    display={{ base: "none", md: "block" }}
                  >
                    {t("lastLoggedInOn")}{" "}
                    {formatTimeByDayMonthYear(userOverView?.lastLogged)}
                  </Text>
                </Box>
              </Box>
            </Flex>
          </Box>
        </>
      )}
      {isLoadingAnalyticsCurrentUser ? (
        <Box mt={{ base: "25px", md: "33px" }}>
          <Skeleton
            w={{ base: "265px", md: "432px" }}
            borderRadius="20px"
            h={{ base: "24px", md: "32px" }}
          />
          <SimpleGrid
            mt={{ base: "25px", md: "35px" }}
            columns={{ base: 2, md: 3 }}
            spacing={{ base: 3, md: 4, xl: 8 }}
          >
            {Array.from({ length: 6 }).map(() => (
              <Skeleton h="90px" w="100%" />
            ))}
          </SimpleGrid>
        </Box>
      ) : (
        <Box mt={{ base: "25px", md: "33px" }}>
          <Text
            fontSize={{ base: "20px", md: "24px" }}
            fontWeight="700"
            lineHeight="28px"
          >
            {t("quickAnalytics")}
            <span>{t("yourDataAtAGlance")}</span>
          </Text>

          <SimpleGrid
            mt={{ base: "25px", md: "35px" }}
            columns={{ base: 2, md: 3 }}
            spacing={{ base: 3, md: 4, xl: 8 }}
          >
            {dataAnalytics.map((item) => (
              <Flex
                bg="#1E2023"
                minH="80px"
                borderRadius="15px"
                alignItems="center"
                justifyContent="space-between"
                padding={{ base: "10px", md: "20px" }}
                key={item.name}
              >
                <Box>
                  <Tooltip label={item?.labelTooltip} placement="top-start">
                    <Text cursor="pointer" fontSize="12px" fontWeight="700">
                      {item.name}
                    </Text>
                  </Tooltip>

                  <Flex
                    mt="5px"
                    alignItems="center"
                    display={{ base: "block", md: "flex" }}
                  >
                    <Text
                      display={{ base: "block", md: "flex" }}
                      alignItems="center"
                      gap="5px"
                      fontSize={{ base: "16px", md: "18px" }}
                      fontWeight="700"
                      color={item.color}
                    >
                      {item.total}
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#D8FD01",
                        }}
                      >
                        {item?.moreTotal}
                      </span>
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "white",
                        }}
                      >
                        {item?.des}
                      </span>
                    </Text>
                    {item?.status && (
                      <Flex
                        background="#342A23"
                        borderRadius="4px"
                        padding="3px 12px"
                        alignItems="center"
                        ml="10px"
                      >
                        <Text color="#F87F27" fontSize="12px" fontWeight="400">
                          {item.status}
                        </Text>
                      </Flex>
                    )}
                  </Flex>
                </Box>
                <Box>{item.icon}</Box>
              </Flex>
            ))}
          </SimpleGrid>
        </Box>
      )}

      <Box mt={{ base: "25px", md: "33px" }}>
        {isLoadingEmailCustomers && !fetchEmailCustomersOptions.loadingMore ? (
          <Skeleton
            w={{ base: "265px", md: "432px" }}
            borderRadius="20px"
            h={{ base: "24px", md: "32px" }}
          />
        ) : (
          <Text fontSize={{ base: "20px", md: "24px" }} fontWeight="700">
            {t("recentCustomerActivity")}
          </Text>
        )}
        <Box
          backgroundColor="#24252A"
          borderRadius="15px"
          padding="10px 20px 20px"
          mt={{ base: "25px", md: "35px" }}
        >
          {isLoadingEmailCustomers &&
          !fetchEmailCustomersOptions.loadingMore ? (
            <Skeleton w="150px" />
          ) : (
            <Text
              textDecoration="underline"
              textDecorationColor="#D4F70E"
              textUnderlineOffset="8px"
            >
              {t("freeDownloads")}
            </Text>
          )}
          <ItemCustomersDashboardCover />
        </Box>
      </Box>
      {isLoadingUpComingLicenseExpiry ? (
        isLessThan768 ? (
          <SkeletonLicenseExpiryMobile />
        ) : (
          <SkeletonLicenseExpiry />
        )
      ) : (
        <Box my={{ base: "20px", md: "25px", xl: "30px" }}>
          <Text
            mb={{ base: "25px", md: "30px" }}
            fontSize={{ base: "20px", md: "22px", xl: "24px" }}
            fontWeight="700"
            lineHeight="28px"
          >
            {t("upcomingLicenseExpiry")}
          </Text>

          <Box
            padding={{
              base: "0px 16px 16px 16px",
              md: "10px 20px 20px 20px",
              xl: "20px 50px 50px 50px",
            }}
            background="#25252A"
            borderRadius="8px"
          >
            <ItemLicenseExpiry
              tracksLicenseExpiry={tracksLicenseExpiry}
              setTimeExpiry={setTimeExpiry}
              timeExpiry={timeExpiry}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}
