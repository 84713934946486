import { Box } from "@chakra-ui/react";
import React from "react";
import SliderBannerFeed from "./bannerFeed";
import HomePage from "./homePage";

export default function Home() {
  return (
    <Box
      p={{
        base: "20px 16px",
        md: "30px",
        lg: "40px",
        xl: "40px 80px 0px 40px",
      }}
    >
      <SliderBannerFeed />
      <HomePage />
    </Box>
  );
}
