export const TRACK_KEY_NOTATION: { [key: string]: string } = {
  C_MAJOR: "C",
  C_MAJOR_DB_MAJOR: "C#/Db",
  D_MAJOR: "D",
  D_MAJOR_EB_MAJOR: "D#/Eb",
  E_MAJOR: "E",
  F_MAJOR: "F",
  F_MAJOR_GB_MAJOR: "F#/Gb",
  G_MAJOR: "G",
  G_MAJOR_AB_MAJOR: "G#/Ab",
  A_MAJOR: "A",
  A_MAJOR_BB_MAJOR: "A#/Bb",
  B_MAJOR: "B",
  A_MINOR: "Am",
  A_MINOR_BB_MINOR: "A#m/BBm",
  B_MINOR: "Bm",
  C_MINOR: "Cm",
  C_MINOR_DB_MINOR: "C#m/Dbm",
  D_MINOR: "Dm",
  D_MINOR_EB_MINOR: "D#m/Ebm",
  E_MINOR: "Em",
  F_MINOR: "Fm",
  F_MINOR_GB_MINOR: "F#m/Gbm",
  G_MINOR: "Gm",
  G_MINOR_AB_MINOR: "G#m/Abm",

  //old key
  A_FLAT_MINOR: "A♭m",
  A_FLAT_MAJOR: "A♭M",
  // A_MINOR: "Am",
  // A_MAJOR: "AM",
  A_SHARP_MINOR: "A#m",
  A_SHARP_MAJOR: "A#M",
  B_FLAT_MINOR: "B♭m",
  B_FLAT_MAJOR: "B♭M",
  // B_MINOR: "Bm",
  // B_MAJOR: "BM",
  B_SHARP_MINOR: "B#m",
  B_SHARP_MAJOR: "B#M",
  C_FLAT_MINOR: "C♭m",
  C_FLAT_MAJOR: "C♭M",
  // C_MINOR: "Cm",
  // C_MAJOR: "CM",
  C_SHARP_MINOR: "C#m",
  C_SHARP_MAJOR: "C#M",
  D_FLAT_MINOR: "D♭m",
  D_FLAT_MAJOR: "D♭M",
  // D_MINOR: "Dm",
  // D_MAJOR: "DM",
  D_SHARP_MINOR: "D#m",
  D_SHARP_MAJOR: "D#M",
  E_FLAT_MINOR: "E♭m",
  E_FLAT_MAJOR: "E♭M",
  // E_MINOR: "Em",
  // E_MAJOR: "EM",
  E_SHARP_MINOR: "E#m",
  E_SHARP_MAJOR: "E#M",
  F_FLAT_MINOR: "F♭m",
  F_FLAT_MAJOR: "F♭M",
  // F_MINOR: "Fm",
  // F_MAJOR: "FM",
  F_SHARP_MINOR: "F#m",
  F_SHARP_MAJOR: "F#M",
  G_FLAT_MINOR: "G♭m",
  G_FLAT_MAJOR: "G♭M",
  // G_MINOR: "Gm",
  // G_MAJOR: "GM",
  G_SHARP_MINOR: "G#m",
  G_SHARP_MAJOR: "G#M",
};

export const convertTrackKey = (item) => {
  return {
    ...item,
    key: TRACK_KEY_NOTATION[item.key],
  };
};
