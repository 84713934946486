import { createReducer, current } from "@reduxjs/toolkit";
import { BulkOffers } from "app/models";
import * as bulkOfferActions from "app/redux/actions/bulkOffer";
import { Nullable } from "constants/types";

interface ILicense {
  id: string;
  name: string;
}
export interface NewBulkOfferState {
  _id?: string;
  name: string;
  minimumTracks: string | number;
  freeTracks: string | number;
  licenses: ILicense[] | string[];
  active?: boolean;
}

export interface InitBulkOfferState {
  newBulkOffer: NewBulkOfferState;
  bulkOffer: BulkOffers[];
  selectedBulk: Nullable<NewBulkOfferState>;
}

const initialState: InitBulkOfferState = {
  newBulkOffer: {
    name: "",
    minimumTracks: "",
    freeTracks: "",
    licenses: [],
    active: false,
  },
  bulkOffer: [],
  selectedBulk: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(bulkOfferActions.fetchBulkOffers.fulfilled, (state, action) => {
      return {
        ...state,
        bulkOffer: action.payload,
      };
    })
    .addCase(bulkOfferActions.fetchBulkOffers.pending, (state) => {
      return {
        ...state,
        bulkOffer: [],
      };
    })
    .addCase(bulkOfferActions.deleteBulkOffer.fulfilled, (state, action) => {
      const { bulkOffer } = current(state);
      const newBulkOffer = bulkOffer.filter(
        (bulkItem) => bulkItem?._id !== action.payload
      );

      return {
        ...state,
        bulkOffer: newBulkOffer,
      };
    })
    .addCase(
      bulkOfferActions.changeActiveBulkOffer.pending,
      (state, action) => {
        const { bulkOffer } = current(state);
        const newBulkOffer = bulkOffer.map((bulkItem) =>
          bulkItem?._id === action.meta.arg
            ? { ...bulkItem, active: !bulkItem.active }
            : bulkItem
        );

        return {
          ...state,
          bulkOffer: newBulkOffer,
        };
      }
    )
    .addCase(
      bulkOfferActions.changeActiveBulkOffer.fulfilled,
      (state, action) => {
        const { bulkOffer } = current(state);
        const newBulkOffer = bulkOffer.map((bulkItem) =>
          bulkItem?._id === action.payload?._id ? action.payload : bulkItem
        );

        return {
          ...state,
          bulkOffer: newBulkOffer,
        };
      }
    )
    .addCase(bulkOfferActions.getBulkOfferById, (state, action) => {
      const { bulkOffer } = current(state);
      const bulkOfferId = action.payload;
      const selectedBulk = bulkOffer.find(
        (bulkOfferItem) => bulkOfferItem?._id === bulkOfferId
      );
      return {
        ...state,
        selectedBulk: selectedBulk
          ? {
              ...selectedBulk,
              licenses: (selectedBulk.licenses || []).map(
                (license) => license?._id
              ),
            }
          : null,
      };
    })
    .addCase(bulkOfferActions.removeBulkOffer, (state, action) => {
      return {
        ...state,
        selectedBulk: null,
      };
    })
);
