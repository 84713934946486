import { Box, Flex, Text } from "@chakra-ui/react";
import MusicCard from "app/components/MusicCard";
import {
  TrackSoundKit,
  HandleFreeDownloadModel,
  ShortLinkModel,
} from "app/models";
import React, { useMemo } from "react";
import { format } from "utils/timeAgo";
import PopoverInfoProfile from "app/components/RecentAction/popoverInfoProfile";
import { infoProfile } from "utils/convertData";
import { convertTrackKey } from "constants/trackKey";
import i18next from "i18next";

interface TrackSearchProps {
  item: TrackSoundKit;
  onOpenOptionsModal?: (track: TrackSoundKit, isLyric?: boolean) => void;
  handleShowModalAddTrack?: (id: string) => void;
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
  onOpenModalShareLink?: (track: TrackSoundKit) => void;
  setShortLinkUrl: (value: ShortLinkModel) => void;
  setLoadingUrl: (value: boolean) => void;
  hits: any;
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

function TrackSearch({
  item,
  handleShowModalAddTrack = () => {},
  onOpenOptionsModal = () => {},
  handleFreeDownload,
  onOpenModalShareLink,
  setLoadingUrl,
  setShortLinkUrl,
  hits,
  handleShowModalFreeDownloadMailchimp,
}: TrackSearchProps) {
  const convertKeyTrack = convertTrackKey(item);
  const checkIsSK = useMemo(() => {
    return item.soundkitState;
  }, [item.soundkitState]);

  return (
    <>
      <Box
        _hover={{
          background: "rgba(36, 37, 42, 0.8)",
          borderRadius: "8px",
          py: { base: "8px", md: "12px", xl: "15px" },
        }}
        py={{ base: "8px", md: "12px", xl: "15px" }}
      >
        <Flex
          justifyContent="space-between"
          mt={{ base: "0px", xl: "8px" }}
          mb={{ base: "2px", xl: "10px" }}
          alignItems="center"
        >
          <Flex alignItems="center" ml={{ base: "0px", xl: "18px" }}>
            <PopoverInfoProfile isRecentActions item={infoProfile(item)} />
          </Flex>
          <Text
            color="rgba(255, 255, 255, 0.27)"
            fontSize={{ base: "12px", xl: "14px" }}
            lineHeight="17px"
            marginRight="10px"
          >
            {format(item, i18next.language)}
          </Text>
        </Flex>

        <MusicCard
          onOpenOptionsModal={onOpenOptionsModal}
          item={convertKeyTrack}
          isProfile
          isRecentAction
          isSK={!!checkIsSK}
          handleShowModalAddTrack={handleShowModalAddTrack}
          handleFreeDownload={handleFreeDownload}
          onOpenModalShareLink={onOpenModalShareLink}
          setShortLinkUrl={setShortLinkUrl}
          setLoadingUrl={setLoadingUrl}
          listPlaylistTracksSoundPacks={hits}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      </Box>
    </>
  );
}

export default React.memo(TrackSearch);
