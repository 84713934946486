import { LoadMorePage } from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import getOptions from "utils/getOptions";
import ToastPlanSubscription from "app/components/toast/toastplan";

const accountLinkBody = {
  refresh_url: `${window.location.origin}/dashboard/payment`,
  return_url: `${window.location.origin}/dashboard/payment`,
  type: "account_onboarding",
};
class PaymentAndWallets {
  static async paypalParterReferrals(): Promise<any> {
    try {
      const { data } = await axiosService.get(
        "/payments/paypal-parter-referrals"
      );
      return data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
  static async updatePaypalMerchantID(body: any) {
    try {
      const { data } = await axiosService.post(
        "/payments/update-paypal-merchant-id",
        body
      );
      return data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getLastestTransaction(payload: LoadMorePage) {
    const options = getOptions(payload.page, payload.search);
    try {
      const { data } = await axiosService.get(
        "/purchases/lastest-transaction",
        {
          params: { ...options },
        }
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async paypalSellerStatus(merchantIdInPayPal: string) {
    try {
      const { data } = await axiosService.get(
        `/payments/paypal-seller-status/${merchantIdInPayPal}`
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async startAccountLink() {
    try {
      const { data } = await axiosService.post(
        "/payments/account-link",
        accountLinkBody
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getAccountDetails() {
    try {
      const { data } = await axiosService.get("/payments/account-link");
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async updateConnectPaymentType(body: any) {
    try {
      const { data } = await axiosService.patch(
        "/payments/toggle-payment-type",
        body
      );
      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
  static async getBalanceInStripe() {
    try {
      const { data } = await axiosService.get(
        "/user/balance/retrieve-stripe-balance"
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getTotalProfit(paymentType: string) {
    try {
      const { data } = await axiosService.get("/purchases/total-profit", {
        params: { paymentType },
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
}

export default PaymentAndWallets;
