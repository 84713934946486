import { yupResolver } from "@hookform/resolvers/yup";
import { SignupPayload } from "app/redux/actions/users/interface";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { createUser } from "app/redux/actions/users";
import { selectUserState } from "app/redux/selectors/users";
import { DEVICE_TYPE } from "constants/index";

interface SignupFormInput {
  email: string;
  password: string;
  name: string;
  role: string;
}

export const useSignup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectUserState);

  const history = useHistory();
  const [select, setSelect] = useState("");
  const [stepSignup, setStepSignUp] = useState(0);
  const [roleUser, setRoleUser] = useState<string[]>([]);

  const onSelect = (code: any) => {
    return setSelect(code.value);
  };

  const refBy = localStorage.getItem("ref")?.split("=")?.[1];

  const loginValidationSchema = yup.object().shape({
    name: yup.string().label(t("username")).required().min(3),
    email: yup.string().label(t("emailAdress")).required().email(),
    password: yup.string().label(t("password")).required().min(8),
  });

  const methods = useForm<SignupFormInput>({
    resolver: yupResolver(loginValidationSchema),
  });

  const handleStepForm = useCallback(() => {
    setStepSignUp(1);
  }, []);

  const handleSetRole = (role: string) => {
    const checkRole = roleUser.includes(role);
    if (checkRole) {
      setRoleUser([]);
      return;
    }
    setRoleUser([role]);
  };

  const onSubmit = (data: SignupFormInput) => {
    const { name, email, password } = data;

    const payload: SignupPayload = {
      name,
      email,
      password,
      role: roleUser[0],
      country: select,
      deviceType: DEVICE_TYPE.WEB,
      refBy: decodeURIComponent(refBy || ""),
    };
    dispatch(createUser(payload)).then((res: boolean) => {
      if (res) {
        history.push("/auth/login");
        return;
      }
    });
  };

  return {
    select,
    isLoading,
    methods,
    stepSignup,
    roleUser,
    onSubmit,
    setSelect,
    onSelect,
    handleStepForm,
    setStepSignUp,
    handleSetRole,
  };
};
