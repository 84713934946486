import { createReducer, current } from "@reduxjs/toolkit";
import * as promoCodeActions from "app/redux/actions/promoCode";
import { Nullable } from "constants/types";
import { formatDate } from "utils/timeAgo";
import dayjs from "dayjs";

interface ILicense {
  _id: string;
  name: string;
}
export interface NewPromoCodeState {
  _id?: string;
  promoCode: string;
  productType: string;
  percent?: number;
  nonExpiring: boolean;
  expirationDate: string;
  licenses?: ILicense[] | string[];
  active?: boolean;
}

export interface InitPromoCodeState {
  newPromoCode: NewPromoCodeState;
  promoCodes: NewPromoCodeState[];
  selectedPromoCode: Nullable<NewPromoCodeState>;
}

const initialState: InitPromoCodeState = {
  newPromoCode: {
    promoCode: "",
    productType: "",
    expirationDate: dayjs().add(1, "day").format("YYYY-MM-DDTHH:mm"),
    nonExpiring: false,
    licenses: [],
    active: true,
  },
  promoCodes: [],
  selectedPromoCode: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(promoCodeActions.fetchPromoCode.fulfilled, (state, action) => {
      return {
        ...state,
        promoCodes: action.payload,
      };
    })
    .addCase(promoCodeActions.fetchPromoCode.pending, (state) => {
      return {
        ...state,
        promoCodes: [],
      };
    })
    .addCase(promoCodeActions.deletePromoCode.fulfilled, (state, action) => {
      const { promoCodes } = current(state);
      const newPromoCode = promoCodes.filter(
        (promoCodeItem) => promoCodeItem?._id !== action.payload
      );

      return {
        ...state,
        promoCodes: newPromoCode,
      };
    })
    .addCase(
      promoCodeActions.changeActivePromoCode.pending,
      (state, action) => {
        const { promoCodes } = current(state);
        const newPromoCode = promoCodes.map((promoCodeItem) =>
          promoCodeItem?._id === action.meta.arg?.promoCodeId
            ? { ...promoCodeItem, active: !promoCodeItem.active }
            : promoCodeItem
        );

        return {
          ...state,
          promoCodes: newPromoCode,
        };
      }
    )
    .addCase(
      promoCodeActions.changeActivePromoCode.fulfilled,
      (state, action) => {
        const { promoCodes } = current(state);
        const newPromoCode = promoCodes.map((promoCodeItem) =>
          promoCodeItem?._id === action.payload?._id
            ? action.payload
            : promoCodeItem
        );

        return {
          ...state,
          promoCodes: newPromoCode,
        };
      }
    )
    .addCase(promoCodeActions.getPromoCodeById, (state, action) => {
      const { promoCodes } = current(state);
      const promoCodeId = action.payload;
      const selectedPromoCode = promoCodes.find(
        (promoCodeItem) => promoCodeItem?._id === promoCodeId
      );
      return {
        ...state,
        selectedPromoCode: selectedPromoCode
          ? {
              ...selectedPromoCode,
              licenses: (selectedPromoCode.licenses || []).map(
                (license) => (license as ILicense)?._id
              ),
              expirationDate: formatDate(selectedPromoCode.expirationDate),
            }
          : null,
      };
    })
    .addCase(promoCodeActions.removeProCode, (state, action) => {
      return {
        ...state,
        selectedPromoCode: null,
      };
    })
);
