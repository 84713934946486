import { createContext, useRef } from "react";

export const WareformContext = createContext(null);

export const useWareformContext = () => {
  const wavesurfer = useRef<any>(null);

  return {
    wavesurfer,
  };
};
