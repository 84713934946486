import { Box, GridItem, Text } from "@chakra-ui/react";
import { TrackStems, Untagged } from "app/assets/icons";
import AudioFile from "app/components/AudioFile";
import { FileDeliver } from "app/models";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import React, { useCallback, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface FilesToDeliverProps {
  isSoundkit?: boolean;
}

export default function FilesToDeliver({
  isSoundkit = false,
}: FilesToDeliverProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const fileUploads = useMemo(
    () => [
      {
        isDisplay: true,
        enableBitrateCheck: true,
        headerTitle: "selectAudioFileForStreaming",
        title: "uploadAudioFile",
        name: "audioFileUrl",
        detail: "WAVorMP3",
        type: FILE_UPLOAD_TYPE.AUDIO,
        fileType: "audio/*",
        icon: <Untagged />,
      },
      {
        isDisplay: true,
        headerTitle: "",
        title: "uploadCompressFile",
        detail: "trackStemsDetail",
        name: "downloadFilesUrl",
        type: FILE_UPLOAD_TYPE.ZIP,
        icon: <TrackStems />,
      },
    ],
    []
  );

  const [currentFileDeliver, setFileDeliver] = useState<FileDeliver>(
    fileUploads[0]
  );

  const onSetFileDeliver = useCallback((fileDeliver: FileDeliver) => {
    setFileDeliver(fileDeliver);
  }, []);

  return (
    <GridItem colSpan={2}>
      {fileUploads.map((item, index) => (
        <Box key={index} display={item.isDisplay ? "block" : "none"} mb="25px">
          <Text color="#535353">{t(item.headerTitle)}</Text>
          <Controller
            name={item.name}
            render={({ field }) => (
              <AudioFile
                error={errors[item.name]?.message}
                data={item}
                currentFileDeliver={currentFileDeliver}
                onSetFileDeliver={onSetFileDeliver}
                value={field.value}
                setFileUrl={(value: string | void) => {
                  field.onChange({
                    target: {
                      value,
                    },
                  });
                }}
                isSoundkit={isSoundkit}
              />
            )}
            control={control}
          />
        </Box>
      ))}
      <Text fontSize="12px" lineHeight="15px" fontWeight="500" color="#535353">
        {t("yourFileMustBeWavOrFlac")}
      </Text>
    </GridItem>
  );
}
