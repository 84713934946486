import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getUserRecentActions,
  getTracksUser,
  getSoundKitsUser,
  fetchUserBulkOffers,
  fetchUserFollowers,
} from "app/redux/actions/profile";
import {
  setFavorite,
  setRepost,
  unsetFavorite,
} from "app/redux/actions/myTrack";
import { editUserInfo } from "app/redux/actions/users";

import {
  selectIsLoadingProfile,
  selectRecentActionsItemsProfile,
  selectSoundKitItemsProfile,
  selectTracksItemsProfile,
  selectUserBulkOffers,
  selectUserFollowers,
} from "app/redux/selectors/profile";
import useFileUpload from "hooks/fileUpload/useFileUpload";
import { useUser } from "hooks/user/useUser";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "utils/capitalize";
import {
  setFavoriteSK,
  setRepostSK,
  unsetFavoriteSK,
} from "app/redux/actions/mySoundKit";
import { PROFILE_TYPE } from "constants/contractType";
import {
  selectUserAdmin,
  selectLoadingUpdateUser,
  selectLoggedIn,
} from "app/redux/selectors/users";
import { useToggle } from "hooks/useToggle";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { yupResolver } from "@hookform/resolvers/yup";
import formSchema from "./formSchema";

export const useProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const recentActionItems = useSelector(selectRecentActionsItemsProfile);
  const isLoading = useSelector(selectIsLoadingProfile);
  const isLoadingUpdateProfile = useSelector(selectLoadingUpdateUser);
  const trackItems = useSelector(selectTracksItemsProfile);
  const soundKitsItems = useSelector(selectSoundKitItemsProfile);
  const userBulkOffers = useSelector(selectUserBulkOffers);
  const Followers = useSelector(selectUserFollowers);
  const loggedIn = useSelector(selectLoggedIn);

  const { userById } = useUser();
  const currentUser = useSelector(selectUserAdmin);
  const history = useHistory();
  const [profileImage, setProfileImage] = useState(userById?.profileImage);
  const [edit, setEdit] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isFollowers, setFollowers] = useState(false);
  const [showModalEditAvatar, setShowModalEditAvatar] = useToggle(false);
  const [userFollowers, setUserFollowers] = useState([]);
  const [previewAvatar, setPreviewAvatar] = useState<string | null>(null);
  const [delayTimeCallApi, setDelayTimeCallApi] = useState(1000);
  const [expertises, setExpertises] = useState<string[]>(
    currentUser?.expertises || []
  );
  const [daws, setDaws] = useState<string[]>(currentUser?.daws || []);

  useEffect(() => {
    setUserFollowers(Followers || []);
  }, [Followers]);

  const handleClickUser = useCallback(
    (userId) => {
      setIsShowModal(false);
      history.push(`/user/${userId}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );

  const onSearchUserFollowers = useCallback(
    (e) => {
      const { value = "" } = e.target;
      let result = Followers || [];
      if (value.toLowerCase().trim() !== "") {
        result = [...result].filter((item) =>
          item.follower.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        result = Followers;
      }
      setUserFollowers(result);
    },
    [Followers]
  );

  const handleEditProfile = useCallback(() => {
    history.push("/user/account-settings");
  }, [history]);

  const handleGetListBulkOffers = useCallback(() => {
    setIsShowModal(true);
    setFollowers(false);
    dispatch(fetchUserBulkOffers(userById?._id));
  }, [dispatch, userById?._id]);

  const handleGetListFollowers = useCallback(
    (userId) => {
      setIsShowModal(true);
      setFollowers(true);
      dispatch(fetchUserFollowers(userId));
    },
    [dispatch]
  );

  const handleCloseModal = useCallback(() => {
    setIsShowModal(false);
  }, []);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<any>({
    resolver: yupResolver(formSchema()),
    defaultValues: currentUser,
  });

  const symbols = watch("aboutMe")?.length || userById?.aboutMe?.length;
  const nameUser = watch("name");
  const proSociety = watch("proCountry");

  const onSubmit = async (dataSubmit: any) => {
    Object.keys(dataSubmit).forEach((key) =>
      dataSubmit[key] === undefined ? delete dataSubmit[key] : {}
    );
    const idArtistsSpotify = dataSubmit?.artistSpotifyList?.map((item) => {
      if (item?.id) {
        return item.id;
      }
      return item;
    });
    const idTracksSpotify = dataSubmit?.trackSpotifyList?.map((item) => {
      if (item?.id) {
        return item.id;
      }
      return item;
    });
    const data = {
      ...currentUser,
      ...dataSubmit,
      profileImage: currentUser?.profileImage,
      country: dataSubmit?.country?.value || dataSubmit?.country,
      proCountry: dataSubmit?.proCountry?.label,
      publisherIpi: Number(dataSubmit?.publisherIpi),
      artistSpotifyList: idArtistsSpotify,
      trackSpotifyList: idTracksSpotify,
      daws,
      expertises,
    };
    dispatch(editUserInfo(data));
  };

  const [progress, handleUpload] = useFileUpload({
    onValueChange: (value: any) => {
      setProfileImage(value);
      dispatch(editUserInfo({ ...currentUser, profileImage: value }));
    },
  });

  const addPhoto = useCallback(
    (e) => {
      const file = e.target.files && e.target.files[0];
      handleUpload(file);
    },
    [handleUpload]
  );

  useEffect(() => {
    setProfileImage(userById?.profileImage);
  }, [userById?.profileImage]);

  const typeOfUser = useCallback(
    (typeOfUser) => {
      const dash = typeOfUser === "typeOfUser" ? "" : "-";
      return userById?.role && `${dash} ${capitalize(userById?.role[0])}`;
    },
    [userById?.role]
  );

  const getRecentActionItems = useCallback(
    (filter, userId) => {
      dispatch(
        getUserRecentActions({
          ...filter,
          userId,
          loggedInGuest: loggedIn ? false : true,
        })
      );
    },
    [dispatch, loggedIn]
  );

  const getTracksUserItem = useCallback(
    (filter, userId) => {
      dispatch(
        getTracksUser({
          ...filter,
          userId,
        })
      );
    },
    [dispatch]
  );

  const getSoundKitsUserItem = useCallback(
    (filter, userId) => {
      dispatch(
        getSoundKitsUser({
          ...filter,
          userId,
        })
      );
    },
    [dispatch]
  );
  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 1000);
  }, []);

  const handleAddFavorites = useCallback(
    (track, isSK, isFavorite, isRecentAction) => {
      increaseTime();
      if (isSK) {
        if (isFavorite) {
          dispatch(
            unsetFavoriteSK(
              {
                id: track._id,
                timeCallApi: delayTimeCallApi,
              },
              isRecentAction
            )
          );
        } else {
          dispatch(
            setFavoriteSK(
              { id: track._id, timeCallAPi: delayTimeCallApi },
              isRecentAction
            )
          );
        }
      } else {
        if (isFavorite) {
          dispatch(
            unsetFavorite(
              {
                id: track._id,
                timeCallApi: delayTimeCallApi,
              },
              isRecentAction
            )
          );
        } else {
          dispatch(
            setFavorite(
              { id: track._id, timeCallAPi: delayTimeCallApi },
              isRecentAction
            )
          );
        }
      }
    },
    [delayTimeCallApi, dispatch, increaseTime]
  );

  const handleAddRepost = useCallback(
    (track, isSK, isRepost, isRecentAction) => {
      if (isSK) {
        if (isRepost) {
          ToastPlanSubscription({
            description: t("alreadyRepostSK"),
          });
        } else {
          dispatch(setRepostSK(track._id, isRecentAction));
        }
      } else {
        if (isRepost) {
          ToastPlanSubscription({ description: t("alreadyRepost") });
        } else {
          dispatch(setRepost(track._id, isRecentAction));
        }
      }
    },
    [dispatch, t]
  );

  return {
    recentActionItems,
    getRecentActionItems,
    getTracksUserItem,
    trackItems,
    getSoundKitsUserItem,
    soundKitsItems,
    handleSubmit,
    errors,
    control,
    onSubmit,
    addPhoto,
    progress,
    handleUpload,
    profileImage,
    edit,
    handleEditProfile,
    typeOfUser,
    symbols,
    isShowModal,
    handleGetListBulkOffers,
    handleCloseModal,
    handleGetListFollowers,
    userById,
    userBulkOffers,
    isFollowers,
    userFollowers,
    onSearchUserFollowers,
    handleClickUser,
    handleAddFavorites,
    handleAddRepost,
    isLoading,
    setEdit,
    showModalEditAvatar,
    setShowModalEditAvatar,
    previewAvatar,
    setPreviewAvatar,
    nameUser,
    isLoadingUpdateProfile,
    proSociety,
    expertises,
    setExpertises,
    daws,
    setDaws,
  };
};
