import { createSelector } from "@reduxjs/toolkit";

export const selectNegotiations = (state: any) => state.negotiations;

export const selectActiveNegotiations = createSelector(
  selectNegotiations,
  ({ activeNegotiations }) => {
    return activeNegotiations.items;
  }
);
export const selectInactiveNegotiations = createSelector(
  selectNegotiations,
  ({ inactiveNegotiations }) => {
    return inactiveNegotiations.items;
  }
);

export const selectorsTrackNegotiation = createSelector(
  selectNegotiations,
  ({ trackNegotiation }) => {
    return trackNegotiation;
  }
);

export const selectorsLoadingNegotiation = createSelector(
  selectNegotiations,
  ({ isLoading }) => {
    return isLoading;
  }
);

export const selectorsNegotiation = createSelector(
  selectNegotiations,
  ({ negotiation }) => {
    return negotiation;
  }
);
