import { Box, Text } from "@chakra-ui/react";
import IconEmptyAnalytics from "app/assets/icons/EmptyAnalytics";
import React from "react";

interface NoUploadTrackProps {
  text: string;
}

export default function NoUploadTrack({ text }: NoUploadTrackProps) {
  return (
    <Box
      height="50vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconEmptyAnalytics />
      <Text mt="5px" fontSize="14px" color="#49494D">
        {text}
      </Text>
    </Box>
  );
}
