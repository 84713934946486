import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationController from "app/apis/notifications";

export const TYPES = {
  GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
  READ_NOTIFICATIONS: "READ_NOTIFICATIONS",
  DELETE_NOTIFICATIONS: "DELETE_NOTIFICATIONS",
};

export const getNotifications = createAsyncThunk(
  TYPES.GET_NOTIFICATIONS,
  NotificationController.getNotifications
);

export const readNotifications = createAsyncThunk(
  TYPES.READ_NOTIFICATIONS,
  NotificationController.readNotifications
);

export const deleteNotifications = createAsyncThunk(
  TYPES.DELETE_NOTIFICATIONS,
  NotificationController.deleteNotifications
);
