import { Box, Flex } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonLicenseContract() {
  const renderSkeleton = () => {
    return (
      <Flex
        background="rgba(32, 33, 38, 0.8)"
        borderRadius="6px"
        w="100%"
        mb="10px"
        mt="30px"
        p={{ base: "25px 15px", md: "30px 25px" }}
        alignItems="center"
      >
        <Box w={{ base: "25%", lg: "20%" }}>
          <Skeleton w="90px" />
          <Skeleton w="70px" />
        </Box>
        <Flex alignItems="center" w={{ base: "30%", lg: "15%" }}>
          <Skeleton w="50px" h="32px" borderRadius="full" />
          <Skeleton w="65px" />
        </Flex>
        <Flex alignItems="center" w={{ base: "30%", lg: "15%" }}>
          <Skeleton w="50px" h="32px" borderRadius="full" />
          <Skeleton w="70px" />
        </Flex>
        <Flex
          w="35%"
          display={{ base: "none", lg: "flex" }}
          justifyContent="center"
        >
          <Skeleton w="320px" h="40px" />
        </Flex>
        <Flex w="15%" justifyContent="end">
          <Skeleton w="40px" />
        </Flex>
      </Flex>
    );
  };

  return <Box>{Array.from({ length: 5 }).map(() => renderSkeleton())}</Box>;
}
