import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonItemPurchased() {
  return (
    <Box>
      {Array.from({ length: 10 }).map((item, index) => (
        <Box key={index}>
          <Flex
            w="100%"
            mt="20px"
            alignItems="center"
            gap={{ md: "0px", xl: "15px" }}
            display={{ base: "block", md: "flex" }}
          >
            <Flex
              w={{ base: "100%", md: "8%" }}
              alignItems="center"
              gap={{ base: "20px", md: "7px", lg: "15px" }}
              mb={{ base: "16px", md: "0px" }}
            >
              <Skeleton
                w="10px"
                background="#535353"
                borderRadius="4px"
                mr="0px"
                display={{ base: "block", md: "none", lg: "block" }}
              />
              <Skeleton
                w={{ base: "72px", md: "52px", lg: "62px", xl: "72px" }}
                h={{ base: "72px", md: "52px", lg: "62px", xl: "72px" }}
                background="#535353"
                borderRadius="6px"
              />
            </Flex>
            <Box w={{ base: "100%", md: "20%", xl: "21%" }}>
              <Skeleton
                w="100px"
                h="16px"
                background="#535353"
                mb="10px"
                display={{ base: "block", md: "none" }}
              />
              <Skeleton
                w="100px"
                h="18px"
                background="#535353"
                borderRadius="4px"
              />
              <Flex>
                <Skeleton
                  w="40px"
                  h="18px"
                  background="#535353"
                  borderRadius="4px"
                />
                <Skeleton
                  w="100px"
                  h="18px"
                  background="#535353"
                  borderRadius="4px"
                />
              </Flex>
            </Box>
            <Skeleton
              w="100px"
              h="16px"
              background="#535353"
              mb="8px"
              mt="16px"
              display={{ base: "block", md: "none" }}
            />

            <Flex w={{ base: "100%", md: "18%", xl: "19%" }}>
              <Skeleton
                w="40px"
                h="40px"
                borderRadius="full"
                background="#535353"
              />
              <Box>
                <Skeleton
                  w={{ base: "100px", md: "85px", lg: "100px" }}
                  h="18px"
                  background="#535353"
                  borderRadius="4px"
                />
                <Skeleton
                  w="60px"
                  h="18px"
                  background="#535353"
                  borderRadius="4px"
                />
              </Box>
            </Flex>

            <Box w={{ base: "100%", md: "9%", xl: "8%" }}>
              <Skeleton
                w="100px"
                h="16px"
                background="#535353"
                mb="8px"
                mt="16px"
                display={{ base: "block", md: "none" }}
              />
              <Skeleton
                w={{ base: "110px", md: "60px", lg: "90px", xl: "110px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
            </Box>
            <Skeleton
              w="100px"
              h="16px"
              background="#535353"
              mb="8px"
              mt="16px"
              display={{ base: "block", md: "none" }}
            />
            <Box
              w={{ base: "100%", md: "10%" }}
              display="flex"
              alignItems="center"
              justifyContent={{ base: "start", md: "center" }}
            >
              <Skeleton
                w={{ base: "110px", md: "60px", lg: "90px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
            </Box>
            <Skeleton
              w="100px"
              h="16px"
              background="#535353"
              mb="8px"
              mt="16px"
              display={{ base: "block", md: "none" }}
            />
            <Box
              w={{ base: "100%", md: "12%" }}
              display="flex"
              flexDirection="column"
              alignItems={{ base: "start", md: "center" }}
            >
              <Skeleton
                w={{ base: "120px", md: "90px", xl: "120px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
              <Skeleton
                w={{ base: "80px", md: "60px", xl: "80px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
            </Box>
            <Skeleton
              w="100px"
              h="16px"
              background="#535353"
              mb="8px"
              mt="16px"
              display={{ base: "block", md: "none" }}
            />
            <Box
              w={{ base: "100%", md: "11%", xl: "10%" }}
              display="flex"
              flexDirection="column"
              alignItems={{ base: "start", md: "center" }}
            >
              <Skeleton
                w={{ base: "120px", md: "90px", xl: "120px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
              <Skeleton
                w={{ base: "80px", md: "60px", xl: "80px" }}
                h="20px"
                background="#535353"
                borderRadius="4px"
              />
            </Box>
            <Skeleton
              w="100px"
              h="16px"
              background="#535353"
              mb="8px"
              mt="16px"
              display={{ base: "block", md: "none" }}
            />
            <Flex
              w={{ base: "100%", md: "12%" }}
              gap={{ base: "10px", md: "5px", lg: "10px" }}
            >
              {Array.from({ length: 3 }).map(() => (
                <Skeleton
                  w={{ base: "50px", md: "100%", lg: "50px" }}
                  h="40px"
                  borderRadius="4px"
                  background="#535353"
                  mr="0px"
                />
              ))}
            </Flex>
          </Flex>
          <Divider mt="10px" borderColor="#535353" />
        </Box>
      ))}
    </Box>
  );
}
