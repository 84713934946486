import React from "react";

const LeftArrow = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="24" cy="24" r="24" transform="rotate(-180 24 24)" fill="#24252A"/>
    <path d="M26 31L19.5 24.5L26 18" stroke="white" stroke-width="2"/>
    </svg>
  );
};
export default LeftArrow;
