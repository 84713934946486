import React from "react";

const VerifiedHomePage = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.54297 1.54953C7.33275 0.709608 8.66725 0.709608 9.45703 1.54953L10.0205 2.14881C10.3599 2.50971 10.8211 2.73182 11.3149 2.77212L12.1347 2.83905C13.2838 2.93284 14.1159 3.97619 13.9516 5.11735L13.8344 5.93156C13.7639 6.4219 13.8778 6.92098 14.1541 7.33214L14.613 8.01486C15.2561 8.97175 14.9591 10.2728 13.9645 10.8559L13.2549 11.2719C12.8275 11.5224 12.5083 11.9227 12.3592 12.3951L12.1115 13.1795C11.7644 14.2789 10.562 14.8579 9.48601 14.4439L8.7183 14.1484C8.25596 13.9705 7.74404 13.9705 7.2817 14.1484L6.51399 14.4439C5.43799 14.8579 4.23565 14.2789 3.88851 13.1795L3.64082 12.3951C3.49166 11.9227 3.17249 11.5224 2.74512 11.2719L2.03548 10.8559C1.04087 10.2728 0.743917 8.97175 1.38704 8.01486L1.8459 7.33214C2.12224 6.92098 2.23615 6.4219 2.16557 5.93156L2.04838 5.11735C1.88412 3.97619 2.71616 2.93284 3.86527 2.83905L4.68514 2.77212C5.17889 2.73182 5.64011 2.50971 5.97946 2.14881L6.54297 1.54953Z"
        fill="#579AFF"
      />
      <path
        d="M5.5 8.5L7.57143 10L10.5 6"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default VerifiedHomePage;
