import { Box, Text } from "@chakra-ui/react";
import IconEmptyAnalytics from "app/assets/icons/EmptyAnalytics";
import React from "react";
import { useTranslation } from "react-i18next";

interface EmptyTrackSoundkitProps {
  isHomePage?: boolean;
}

export default function EmptyTrackSoundkit({
  isHomePage,
}: EmptyTrackSoundkitProps) {
  const { t } = useTranslation();
  return (
    <Box
      height={isHomePage ? "15vh" : "50vh"}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <IconEmptyAnalytics />
      <Text
        mt="5px"
        fontSize="14px"
        color="#49494D"
        fontFamily="Cera Pro Regular"
      >
        {t("noData")}
      </Text>
    </Box>
  );
}
