import { ITEMS_PER_PAGE } from "constants/index";

const getOptions = (page = 1, filterType = "") => {
  const type = filterType ? { type: filterType } : {};
  const skip = ITEMS_PER_PAGE * (page - 1);
  return {
    limit: 25,
    ...(skip > 0 ? { skip } : {}),
    ...type,
  };
};

export default getOptions;
