import { Flex, Box } from "@chakra-ui/react";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";

export default function SkeletonShowLyrics() {
  return (
    <Box>
      <Flex
        alignItems="center"
        padding="10px 0px"
        justifyContent="space-between"
        margin={{ base: "0px 10px", md: "0px" }}
      >
        <Flex alignItems="center">
          <IconSkeletonMusic />
          <Box ml="20px">
            <Skeleton w={{ base: "250px", md: "140px" }} h="16px" />
            <Flex alignItems="center">
              <Skeleton w="44px" h="21px" borderRadius="2px" />
              <Skeleton w="109px" h="16px" />
            </Flex>
          </Box>
        </Flex>

        <Flex
          display={{ base: "none", md: "flex" }}
          position="relative"
          justifyContent="flex-end"
        >
          <Skeleton h="35px" w="70px" borderRadius="20px" />
          <Skeleton
            position="absolute"
            right="5px"
            top="3px"
            h="28px"
            w="28px"
            borderRadius="full"
            background="#24252A"
          />
        </Flex>
      </Flex>
      <Flex w="100%" display={{ base: "flex", md: "none" }} m="10px 0px">
        <Skeleton h="35px" w="100%" borderRadius="20px" />
      </Flex>
      <Skeleton
        w={{ base: "150px", md: "187px" }}
        h={{ base: "20px", md: "27px" }}
        borderRadius="30px"
        mb="20px"
      />
      <Skeleton w={{ base: "70%", md: "392px" }} h="16px" />
      <Skeleton w={{ base: "60%", md: "345px" }} h="16px" />
      <Skeleton w={{ base: "70%", md: "392px" }} h="16px" />
      <Skeleton mb="20px" w={{ base: "90%", md: "476px" }} h="16px" />

      <Skeleton w={{ base: "70%", md: "392px" }} h="16px" />
      <Skeleton w={{ base: "60%", md: "345px" }} h="16px" />
      <Skeleton w={{ base: "70%", md: "392px" }} h="16px" />
      <Skeleton w={{ base: "90%", md: "476px" }} h="16px" />
      <Flex justifyContent="flex-end" mt="30px">
        <Skeleton w="156px" h="48px" borderRadius="4px" />
      </Flex>
    </Box>
  );
}
