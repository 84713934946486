import { Box, Text } from "@chakra-ui/react";
import IconEmptyCollaborations from "app/assets/icons/IconEmptyCollaborations";
import React from "react";
import { useTranslation } from "react-i18next";

interface EmptyCollaborationsProps {
  isActive?: boolean;
}

export default function EmptyCollaborations({
  isActive,
}: EmptyCollaborationsProps) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={{ base: "16px", md: "50px" }}
    >
      <IconEmptyCollaborations />
      <Text fontSize="20px" lineHeight="25px" color="#535353" mt="30px">{`${t(
        "youHaveCollaborate"
      )} ${isActive ? "opens" : "close"} ${t(
        "invitationToCollaborate"
      )}`}</Text>
      <Text
        fontSize="16px"
        lineHeight="20px"
        color="#535353"
        textAlign="center"
        mt="10px"
        px={{ base: "56px", md: "0px" }}
      >
        {t("youHaveOpen")}
      </Text>
    </Box>
  );
}
