import React from "react";

const IconRadio = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 7.25C3.36193 7.25 3.25 7.36193 3.25 7.5V19.5C3.25 19.6381 3.36193 19.75 3.5 19.75H20.5C20.6381 19.75 20.75 19.6381 20.75 19.5V7.5C20.75 7.36193 20.6381 7.25 20.5 7.25H3.5ZM1.75 7.5C1.75 6.5335 2.5335 5.75 3.5 5.75H20.5C21.4665 5.75 22.25 6.5335 22.25 7.5V19.5C22.25 20.4665 21.4665 21.25 20.5 21.25H3.5C2.5335 21.25 1.75 20.4665 1.75 19.5V7.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 11.75C7.75736 11.75 6.75 12.7574 6.75 14C6.75 15.2426 7.75736 16.25 9 16.25C10.2426 16.25 11.25 15.2426 11.25 14C11.25 12.7574 10.2426 11.75 9 11.75ZM5.25 14C5.25 11.9289 6.92893 10.25 9 10.25C11.0711 10.25 12.75 11.9289 12.75 14C12.75 16.0711 11.0711 17.75 9 17.75C6.92893 17.75 5.25 16.0711 5.25 14Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 12C14.25 11.5858 14.5858 11.25 15 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H15C14.5858 12.75 14.25 12.4142 14.25 12Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.25 16C14.25 15.5858 14.5858 15.25 15 15.25H18C18.4142 15.25 18.75 15.5858 18.75 16C18.75 16.4142 18.4142 16.75 18 16.75H15C14.5858 16.75 14.25 16.4142 14.25 16Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.171 1.66475C13.3562 2.03524 13.2061 2.48574 12.8356 2.67098L3.83557 7.17098C3.46509 7.35623 3.01459 7.20606 2.82934 6.83557C2.6441 6.46509 2.79427 6.01459 3.16475 5.82934L12.1648 1.32934C12.5352 1.1441 12.9857 1.29427 13.171 1.66475Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconRadio;
