import { createAsyncThunk } from "@reduxjs/toolkit";
import PurchasedService from "app/apis/purchased";
import { LoadMorePage, CreateDownloadLog } from "app/models";

export const TYPES = {
  GET_PURCHASED: "GET_PURCHASED",
  CREATE_DOWNLOAD_LOG: "CREATE_DOWNLOAD_LOG",
  LIST_EMAIL_CUSTOMERS: "LIST_EMAIL_CUSTOMERS",
};

export const getPurchased = createAsyncThunk(
  TYPES.GET_PURCHASED,
  (payload: LoadMorePage) => PurchasedService.getPurchased(payload)
);

export const createDownloadLog = createAsyncThunk(
  TYPES.CREATE_DOWNLOAD_LOG,
  (payload: CreateDownloadLog) => PurchasedService.createDownloadLog(payload)
);

export const getListEmailCustomers = createAsyncThunk(
  TYPES.LIST_EMAIL_CUSTOMERS,
  (payload: LoadMorePage) => PurchasedService.getListEmailCustomers(payload)
);
