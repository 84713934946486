import React from "react";

interface IconStripeProps {
  isCheckout?: boolean;
}

const IconStripeCard = ({ isCheckout }: IconStripeProps) => {
  return (
    <svg
      width={isCheckout ? "20" : "41"}
      height={isCheckout ? "18" : "38"}
      viewBox="0 0 41 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.451172"
        y="11.8525"
        width="39.9106"
        height="25.6239"
        rx="2"
        fill="white"
      />
      <ellipse
        cx="13.7551"
        cy="19.1733"
        rx="3.80101"
        ry="3.66056"
        fill="#1E1F23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.32173 15.7216C8.92494 15.5863 8.49788 15.5127 8.05297 15.5127C5.95372 15.5127 4.25195 17.1516 4.25195 19.1733C4.25195 21.1949 5.95372 22.8338 8.05297 22.8338C8.49704 22.8338 8.92333 22.7605 9.31948 22.6257C8.52749 21.6828 8.05313 20.4822 8.05313 19.175C8.05313 17.8665 8.52839 16.6649 9.32173 15.7216Z"
        fill="#1E1F23"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.2299 10.0199L34.6543 6.36492L28.9615 10.0199H37.2299ZM22.3099 10.0199L32.7299 3.634L30.9311 1.08129C30.2949 0.178372 29.0295 -0.0637162 28.1049 0.540566L13.6006 10.0199H22.3099Z"
        fill="#D4F70E"
      />
      <rect
        x="4.25195"
        y="30.1543"
        width="7.60203"
        height="3.66056"
        rx="1"
        fill="#1E1F23"
      />
    </svg>
  );
};
export default IconStripeCard;
