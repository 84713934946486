import { Box, Flex, Image, Text } from "@chakra-ui/react";
import BackgroundImagePlaylistIcon from "app/assets/icons/BackgroundImagePlaylistIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { Playlists } from "app/models";
import { PROFILE_TYPE } from "constants/contractType";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface PlaylistItemProps {
  playlist: Playlists;
  index?: number;
  isProfile?: boolean;
}

export default function PlaylistItemInProfile({
  playlist,
  index,
  isProfile,
}: PlaylistItemProps) {
  const { t } = useTranslation();

  const history = useHistory();
  const handleIndividualPlaylist = useCallback(() => {
    history.push(`/playlist/${playlist._id}`);
  }, [history, playlist._id]);

  const redirectProfile = useCallback(
    (e, name) => {
      e.stopPropagation();
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );

  return (
    <Flex
      className="playlist-item"
      cursor="pointer"
      alignItems="center"
      role="group"
      mb="15px"
      onClick={handleIndividualPlaylist}
    >
      <Flex
        w="100%"
        padding={{ base: "10px 0px", md: "10px 5px 10px 0px" }}
        alignItems="center"
        _groupHover={{
          background: "rgba(36, 37, 42, 0.8)",
          borderRadius: "4px",
        }}
      >
        {
          <Box w="8%" display={{ base: "none", xl: "block" }}>
            <Text textAlign="center" fontWeight="500">
              {isProfile ? Number(index) + 1 : ""}
            </Text>
          </Box>
        }
        <Flex w={{ base: "16%", md: "20%", xl: "10%" }}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            onClick={handleIndividualPlaylist}
            cursor="pointer"
            width={{ base: "52px", md: "72px" }}
            height={{ base: "52px", md: "72px" }}
          >
            <Box>
              <BackgroundImagePlaylistIcon />
            </Box>
            <Image
              src={playlist.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              w={{ base: "36px", md: "48px" }}
              height={{ base: "36px", md: "48px" }}
              mt={{ base: "-32px", md: "-40px" }}
            />
          </Box>
        </Flex>

        <Box w={{ base: "58%", md: "32%" }}>
          <Text
            fontWeight="600"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            _hover={{
              textDecoration: "underline",
            }}
            onClick={handleIndividualPlaylist}
            cursor="pointer"
            w={{ base: "80%", md: "100%" }}
          >
            {playlist.name}
          </Text>
          <Flex alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              borderRadius="4px"
              padding={{ base: "3px", md: "5px" }}
              mr="5px"
              backgroundColor="#2b2e1b"
            >
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="14px"
                color="#d4f70e"
                fontFamily="Cera Pro Regular"
              >
                {t("playlist")}
              </Text>
            </Box>
            <Text
              _hover={{
                textDecoration: "underline",
              }}
              fontSize="14px"
              color="#535353"
              onClick={(e) => redirectProfile(e, playlist?.createdBy?.name)}
            >
              By <span>{playlist?.createdBy?.name}</span>
            </Text>
          </Flex>
        </Box>

        <Flex
          alignItems="center"
          w="30%"
          display={{ base: "none", md: "flex" }}
        >
          <Image
            src={playlist?.createdBy?.profileImage}
            fallbackSrc={AvatarUser}
            w="40px"
            height="40px"
            borderRadius="100%"
          />
          <Box ml="5px" w="80%">
            <Text fontWeight="700" noOfLines={1}>
              {playlist?.createdBy?.name}
            </Text>
            <Text color="#535353" fontSize="14px" fontWeight="400">
              Owner
            </Text>
          </Box>
        </Flex>

        <Flex
          w={{ base: "25%", md: "20%" }}
          gap="30px"
          justifyContent="flex-end"
        >
          <Box>
            <Text fontWeight="900">{playlist?.followers?.length || 0}</Text>
            <Text fontSize="12px" color="#535353" fontWeight="400">
              {t("Followers")}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="900">{playlist.totalTracks || 0}</Text>
            <Text fontSize="12px" color="#535353" fontWeight="400">
              {t("Tracks")}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}
