import {
  userInfo,
  userAdmin,
  registerGoogle,
  loginGoogle,
  createUserToFacebook,
  updateUser,
  clearStore,
  resetPassword,
  forgotPassword,
  followUnfollow,
  getRecentActionByUserId,
} from "./../actions/users/index";
import { TYPES } from "app/redux/actions/users";
import ToastPlanSubscription from "app/components/toast/toastplan";

const initialState = {
  loggedIn: false,
  profile: {},
  token: null,
  userId: null,
  isLoading: false,
  userAdmin: {},
  userInfo: {},
  isEmailSent: false,
  userRecentAction: {},
  isError: false,
  isLoadingUpdateUser: false,
  isLoadingLogin: false,
};

const authReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case TYPES.LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: true,
      };
    case TYPES.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: payload.loggedIn,
        isLoadingLogin: false,
      };
    case TYPES.LOGIN_ERROR:
      return {
        ...state,
        isLoadingLogin: false,
      };

    case TYPES.CREATE_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TYPES.CREATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case TYPES.CREATE_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case userAdmin.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case userAdmin.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userAdmin: { ...payload },
      };
    case userAdmin.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case userInfo.REQUEST:
      return {
        ...state,
        isLoading: true,
        userInfo: {},
        isError: false,
      };
    case userInfo.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userInfo: { ...payload },
      };
    case userInfo.ERROR:
      return {
        ...state,
        isLoading: false,
        userInfo: {},
        isError: true,
      };
    case getRecentActionByUserId.REQUEST:
      return {
        ...state,
        isLoading: true,
        userRecentAction: {},
      };
    case getRecentActionByUserId.SUCCESS:
      return {
        ...state,
        isLoading: false,
        userRecentAction: payload,
      };
    case getRecentActionByUserId.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case registerGoogle.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case registerGoogle.SUCCESS:
      ToastPlanSubscription({
        description: "Register google sucessfully",
        isError: false,
      });
      return {
        ...state,
        isLoading: false,
      };
    case registerGoogle.ERROR:
      ToastPlanSubscription({ description: payload });
      return {
        ...state,
        isLoading: false,
      };
    case loginGoogle.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case loginGoogle.SUCCESS:
      return {
        ...state,
        isLoading: false,
        loggedIn: true,
      };
    case loginGoogle.ERROR:
      ToastPlanSubscription({ description: payload });
      return {
        ...state,
        isLoading: false,
      };

    case updateUser.REQUEST:
      return {
        ...state,
        isLoadingUpdateUser: true,
        userInfo: {},
      };
    case updateUser.SUCCESS:
      ToastPlanSubscription({ description: "Success", isError: false });
      return {
        ...state,
        isLoadingUpdateUser: false,
        userInfo: { ...payload },
        userAdmin: { ...payload },
      };
    case updateUser.ERROR:
      ToastPlanSubscription({ description: payload.error });
      return {
        ...state,
        isLoadingUpdateUser: false,
      };
    case forgotPassword.REQUEST:
      return {
        ...state,
        isLoading: true,
        isEmailSent: false,
      };
    case forgotPassword.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isEmailSent: true,
      };
    case forgotPassword.ERROR:
      ToastPlanSubscription({ description: payload });
      return {
        ...state,
        isLoading: false,
        isEmailSent: true,
      };
    case resetPassword.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case resetPassword.SUCCESS:
      ToastPlanSubscription({
        description: "Reset password sucessfully",
        isError: false,
      });
      return {
        ...state,
        isLoading: false,
      };
    case resetPassword.ERROR:
      ToastPlanSubscription({ description: payload });
      return {
        ...state,
        isLoading: false,
      };
    case createUserToFacebook.SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case clearStore.SUCCESS:
      return {
        ...state,
        loggedIn: false,
        profile: {},
        token: null,
        userId: null,
        isLoading: false,
        userAdmin: {},
        userInfo: {},
        isEmailSent: false,
      };

    case followUnfollow.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case followUnfollow.SUCCESS:
      const updateUserFollow = (isFollowing: boolean, followers: number) =>
        isFollowing ? followers - 1 : followers + 1;

      return {
        ...state,
        isLoading: false,
        userInfo: {
          ...state.userInfo,
          isFollowing: !state.userInfo?.isFollowing,
          followers: updateUserFollow(
            state.userInfo?.isFollowing,
            state.userInfo?.followers
          ),
        },
        userRecentAction: {
          ...state.userRecentAction,
          isFollowing: !state.userRecentAction?.isFollowing,
          followers: updateUserFollow(
            state.userRecentAction?.isFollowing,
            state.userRecentAction?.followers
          ),
        },
      };
    case followUnfollow.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
