import axiosService from "services/axios.service";
import {
  LoginPayload,
  SignupResponse,
  SignupPayload,
  ForgotPassword,
  VerificationCode,
} from "app/redux/actions/users/interface";
import { errorMessage } from "utils/errorMessage";
import {
  CreateUserOnFirestore,
  User,
  AnalyticsCurrentUserModel,
  AddMemberMailchimpModel,
} from "app/models";
import { setLocalStorage } from "helpers/local-storage";
import { JWT_LOCALSTORAGE, IS_REMEMBER_ME } from "constants/index";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { setSessionStorage } from "helpers/session";
import { getIpAddress } from "utils/getData";
import countryList from "react-select-country-list";
class UserService {
  static async login(credentials: LoginPayload): Promise<string> {
    const ipAddress = await getIpAddress();
    const options = countryList().getData();
    const fullNameCountry = (country: string) =>
      options.filter((item) => item.value === country);
    const nameCountry = fullNameCountry(ipAddress);
    try {
      const res = await axiosService.post("/auth/login", {
        email: credentials.email,
        password: credentials.password,
        country: nameCountry[0]?.label,
        device: credentials?.device,
      });
      const {
        data: { data },
      } = res;
      return data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async logout({
    token,
    isLogoutAll,
    userId,
  }: {
    token: string;
    isLogoutAll?: boolean;
    userId?: string;
  }) {
    try {
      const res = await axiosService.delete("/auth/logout", {
        data: {
          token,
          isLogoutAll,
          userId,
        },
      });
      const {
        data: { data },
      } = res;
      return data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async checkVerificationCode(
    credentials: VerificationCode
  ): Promise<string> {
    try {
      const res = await axiosService.post("/auth/check-otp", {
        email: credentials.email,
        otp: credentials.otp,
        device: credentials?.device,
      });
      const {
        data: { data },
      } = res;
      if (credentials.isRememberMe) {
        setLocalStorage(JWT_LOCALSTORAGE, data);
      } else {
        setSessionStorage(JWT_LOCALSTORAGE, data);
      }
      setLocalStorage(IS_REMEMBER_ME, credentials.isRememberMe);
      return data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createUser(body: SignupPayload): Promise<SignupResponse> {
    try {
      const res = await axiosService.post("/auth/register", body);
      const {
        data: { success },
      } = res;
      ToastPlanSubscription({ description: res?.data?.data, isError: false });
      return success;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getUserInfo(): Promise<User> {
    try {
      const res = await axiosService.get("/user/me");
      return res?.data?.data[0];
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getUserByUsername(
    username: string,
    loginAsGuest: boolean
  ): Promise<User> {
    try {
      const res = await axiosService.get(
        loginAsGuest
          ? `/guest/get-by-username/${username}`
          : `/user/get-by-username/${username}`
      );
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async editUserInfo(value: User) {
    try {
      const res = await axiosService.patch("/user", value);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async loginFacebook(body: any) {
    try {
      const res = await axiosService.post("/auth/login/facebook", body);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createUserGoogle(body: any) {
    try {
      const res = await axiosService.post("/auth/register/google", body);
      return res?.data?.success;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createUserFacebook(body: any): Promise<any> {
    try {
      const res = await axiosService.post("/auth/register/facebook", body);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async loginGoogle(body: any) {
    try {
      const res = await axiosService.post("/auth/login/google", body);
      await setLocalStorage(JWT_LOCALSTORAGE, res?.data?.data);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async forgotPasswordUser(payload: ForgotPassword) {
    try {
      const res = await axiosService.post(
        "/auth/forgot-password/user",
        payload
      );
      ToastPlanSubscription({ description: res?.data?.data, isError: false });
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async resetPasswordUser(password: string, token: string) {
    try {
      await axiosService.patch("/auth/reset-password", {
        newPassword: password,
        token,
      });
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async fetchUserFollowers(userId: string) {
    try {
      const data = await axiosService.get(`/followers/${userId}`);
      return data?.data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getConversation(userId: string) {
    try {
      const { data } = await axiosService.post("/auth/getConversation", {
        selectUserId: userId,
      });
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async followUnfollow(userId: string) {
    try {
      const { data } = await axiosService.post(`/followers/${userId}`);
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async checkAndCreateUserOnFirestore(body: CreateUserOnFirestore) {
    try {
      const data = await axiosService.post(
        "/auth/checkAndCreateUserOnFirestoreIfNotExist",
        body
      );
      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getUserByUserId(id: string): Promise<User> {
    try {
      const res = await axiosService.get(`/user/${id}`);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async refLinkClickedCount(name: string): Promise<User> {
    try {
      const res = await axiosService.get(`/referrals/count-ref/${name}`);
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getAnaLyticsCurrentUser(): Promise<AnalyticsCurrentUserModel> {
    try {
      const res = await axiosService.get("/user/analytics", {
        params: { tz: Intl.DateTimeFormat().resolvedOptions().timeZone },
      });
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getUserOverView(): Promise<AnalyticsCurrentUserModel> {
    try {
      const res = await axiosService.get("/user/overview", {
        params: { tz: Intl.DateTimeFormat().resolvedOptions().timeZone },
      });
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getListLicenseExpiry(timeExpiry: string) {
    try {
      const res = await axiosService.get("/user/license-expiry", {
        params: { timeExpiry },
      });
      return res?.data?.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getListSpotifyArtist(title: string) {
    try {
      const res = await axiosService.get("/auth/list/spotify-artist", {
        params: {
          title: title || "",
        },
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async getListSpotifyTrack(title: string) {
    try {
      const res = await axiosService.get("/auth/list/spotify-track", {
        params: {
          title: title || "",
        },
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getArtistsSpotify(ids: String[]) {
    try {
      const res = await axiosService.get("/user/artists/spotify", {
        params: {
          ids,
        },
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getTracksSpotify(ids: String[]) {
    try {
      const res = await axiosService.get("/user/tracks/spotify", {
        params: {
          ids,
        },
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async changeCurrentPassword(
    email: string,
    password: string,
    newPassword: string
  ) {
    try {
      const res = await axiosService.post("/auth/change/new-password", {
        email,
        password,
        newPassword,
      });
      ToastPlanSubscription({
        description: "Change password success",
        isError: false,
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async connectMailChimp() {
    try {
      const res = await axiosService.get("/user/connect/mailchimp");
      window.open(res?.data?.data);
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async disconnectMailChimp() {
    try {
      const res = await axiosService.get("/user/disconnect/mailchimp");
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getAccessTokenMailChimp(code: string) {
    try {
      const res = await axiosService.get(
        `/user/oauth/mailchimp/callback?code=${code}`
      );
      return res?.data?.data;
    } catch (error: any) {
      const message = error?.response?.data?.error;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async selectedListMailChimp(webId: number) {
    try {
      const res = await axiosService.post(`/user/selected/mailchimp`, {
        webId,
      });
      ToastPlanSubscription({
        description: "Selected mailchimp success",
        isError: false,
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = error?.response?.data?.error;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async addMemberMailChimp(body: AddMemberMailchimpModel) {
    try {
      const res = await axiosService.post(`/user/add-member/mailchimp`, {
        webId: body.webId,
        email: body.email,
        name: body.name,
        lastName: body.lastName,
        dc: body.dc,
        accessToken: body.accessToken,
        userId: body.userId,
        trackId: body?.trackId,
        soundPackId: body?.soundPackId,
      });
      ToastPlanSubscription({
        description: "Send mailchimp success",
        isError: false,
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = error?.response?.data?.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async changeFeeStructure(feeStructure: string) {
    try {
      const res = await axiosService.put(`/user/change-platform-fee`, {
        feeStructure,
      });
      ToastPlanSubscription({
        description: "Change Fee Structure success",
        isError: false,
      });
      return res?.data?.data;
    } catch (error: any) {
      const message = error?.response?.data?.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default UserService;
