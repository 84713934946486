import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import House from "app/assets/icons/house";
import IconClosePromoCode from "app/assets/icons/IconClosePromoCode";
import IconPaypalCard from "app/assets/icons/iconPaypal";
import IconStripeCard from "app/assets/icons/iconStripe";
import { clearAppliedPromoCode } from "app/redux/actions/cart";
import {
  cartPromoCodeSelector,
  currentCartSelector,
  totalDiscountCartSelector,
  totalServiceSelector,
  totalWillPaidCartSelector,
} from "app/redux/selectors/cart";
import { useCart } from "hooks/cart/useCart";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

interface CartSummaryProps {
  promo?: boolean;
  nameStripe?: string;
  setShowModalPromoCode: () => void;
}

export default function CartSummary({
  promo = false,
  nameStripe,
  setShowModalPromoCode,
}: CartSummaryProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const totalCart = useSelector(totalWillPaidCartSelector);
  const listCart = useSelector(currentCartSelector);
  const totalDiscount = useSelector(totalDiscountCartSelector);
  const totalService = useSelector(totalServiceSelector);

  const applyPromoCode = useSelector(cartPromoCodeSelector);

  const { isPaypalSelect, isStripeSelect } = useCart();
  const handleChangePayment = useCallback(() => {
    history.push("/cart/payment-methods");
  }, [history]);

  const isNotTotalDiscount = totalDiscount === Number(0).toFixed(2);
  const clearValuePromoCode = useCallback(() => {
    dispatch(clearAppliedPromoCode());
  }, [dispatch]);

  return (
    <Box>
      <Box
        backgroundColor="#24252A"
        borderRadius="8px"
        padding="25px 25px 15px 25px"
      >
        <Text fontSize="14px" color="#535353">
          {t("cartSummary")}
        </Text>
        <Flex display="flex" justifyContent="space-between" m="10px 0px">
          <Text fontSize="14px">
            {listCart?.length} Items: {totalCart}
          </Text>
          <Text
            fontSize="14px"
            color={isNotTotalDiscount ? "#535353" : "#D4F70E"}
          >
            {isNotTotalDiscount
              ? t("noneDiscount")
              : `${t("discount")} -${totalDiscount}`}
          </Text>
        </Flex>
        <Flex display="flex" justifyContent="space-between" m="10px 0px">
          <Text fontSize="14px" color="#535353">
            {t("totalService")}
          </Text>
          <Text fontSize="14px">${totalService}</Text>
        </Flex>

        <Divider mt="25px" borderColor="rgba(250, 250, 250, 0.04)" />

        {(isPaypalSelect || isStripeSelect) && (
          <Text fontSize="14px" color="#535353" mt="25px">
            {t("paymentMethod")}
          </Text>
        )}
        {isPaypalSelect && (
          <Flex alignItems="center" mt="10px">
            <IconPaypalCard isCheckout />
            <Text ml="15px" fontSize="14px">
              {t("checkoutWithPaypal")}
            </Text>
          </Flex>
        )}
        {isStripeSelect && (
          <Flex alignItems="center" mt="10px" justifyContent="space-between">
            <Flex>
              <IconStripeCard isCheckout />
              <Text ml="10px" fontSize="14px">
                {t("creditCard")}
              </Text>
              <Text
                ml="5px"
                fontSize="14px"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                overflow="hidden"
                w="90px"
              >
                {nameStripe}
              </Text>
            </Flex>

            <Text
              fontSize="14px"
              color="#535353"
              cursor="pointer"
              onClick={handleChangePayment}
            >
              {t("change")}
            </Text>
          </Flex>
        )}

        <Text
          fontSize="24px"
          fontWeight="600"
          color="#D4F70E"
          margin="15px 0px"
        >
          {`$${(
            Number(totalCart) +
            Number(totalService) -
            Number(totalDiscount)
          ).toFixed(2)}`}
        </Text>

        <Divider mb="10px" borderColor="rgba(250, 250, 250, 0.04)" />
        {!applyPromoCode || !applyPromoCode.promoCode ? (
          <Flex
            onClick={setShowModalPromoCode}
            alignItems="center"
            justifyContent="center"
            marginTop={{ base: "20px", md: "10px" }}
            cursor="pointer"
            mb={{ base: "10px", md: "0px" }}
          >
            <House />
            <Text ml="15px" fontSize="16px" lineHeight="20px" fontWeight="400">
              {t("gotPromoCode")}
            </Text>
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            justifyContent="center"
            marginTop={"10px"}
            cursor="pointer"
          >
            <House />
            <Text
              m="0px 15px"
              fontSize="16px"
              lineHeight="20px"
              fontWeight="400"
            >
              {applyPromoCode?.promoCode}
            </Text>
            <Box onClick={clearValuePromoCode}>
              <IconClosePromoCode />
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  );
}
