import { Flex, Select, Text } from "@chakra-ui/react";
import { AnalyticType, timeType } from "constants/analyticsType";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface OptionAnalyticsProps {
  selectOptionMost: string;
  setSelectOptionMost: (value: string) => void;
  setSelectOptionPeriod: (value: string) => void;
  selectOptionPeriod: string;
  isTopUser?: boolean;
}

export default function OptionsAnalytics({
  setSelectOptionMost,
  setSelectOptionPeriod,
  selectOptionMost,
  selectOptionPeriod,
  isTopUser = false,
}: OptionAnalyticsProps) {
  const { t } = useTranslation();

  const optionsMostTopTracks = useMemo(
    () => [
      { label: t("played"), value: AnalyticType.PLAYED },
      {
        label: t("Downloaded"),
        value: AnalyticType.DOWNLOADED,
      },
      { label: t("sold"), value: AnalyticType.SOLD },
      // { label: "Purchased", value: AnalyticType.PURCHASED },
      { label: t("favorited"), value: AnalyticType.FAVORITED },
    ],
    [t]
  );

  const optionsMostTopUser = useMemo(
    () => [
      { label: t("listeners"), value: AnalyticType.LISTENER },
      {
        label: t("userWithMoreDownloads"),
        value: AnalyticType.DOWNLOADER,
      },
      { label: t("userWithMoreRepost"), value: AnalyticType.REPOSTER },
      { label: t("buyers"), value: AnalyticType.BUYER },
    ],
    [t]
  );

  const optionsPeriod = useMemo(
    () => [
      { label: t("today"), value: timeType.DAY },
      {
        label: t("week"),
        value: timeType.WEEK,
      },
      { label: t("month"), value: timeType.MONTH },
      { label: t("year"), value: timeType.YEAR },
    ],
    [t]
  );

  return (
    <Flex m="10px 0px 20px 0px">
      <Flex w="50%">
        <Text fontSize="16px" lineHeight="38px" mr="10px">
          {t("Most")}
        </Text>
        <Select
          w="60%"
          border="none"
          background="#24252A"
          value={selectOptionMost}
          onChange={(option) => setSelectOptionMost(option.target.value)}
        >
          {(isTopUser ? optionsMostTopUser : optionsMostTopTracks).map(
            (item) => (
              <option value={item.value}>{item.label}</option>
            )
          )}
        </Select>
      </Flex>

      <Flex w="50%" justifyContent="flex-end">
        <Text fontSize="16px" lineHeight="38px" mr="10px">
          {t("Period")}
        </Text>
        <Select
          w="60%"
          border="none"
          background="#24252A"
          value={selectOptionPeriod}
          onChange={(option) => setSelectOptionPeriod(option.target.value)}
        >
          {optionsPeriod.map((item) => (
            <option value={item.value}>{item.label}</option>
          ))}
        </Select>
      </Flex>
    </Flex>
  );
}
