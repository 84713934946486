import React from "react";

const IconAddSoundpackHeader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M10.3001 12.6591C9.07583 12.6591 8.0834 13.6515 8.0834 14.8758C8.0834 16.1 9.07583 17.0924 10.3001 17.0924C11.5243 17.0924 12.5167 16.1 12.5167 14.8758C12.5167 14.762 12.5081 14.6502 12.4916 14.541C12.508 14.483 12.5167 14.422 12.5167 14.3591V9.85911H14.9501C15.3182 9.85911 15.6167 9.56059 15.6167 9.19245C15.6167 8.8243 15.3182 8.52578 14.9501 8.52578H11.8501C11.4819 8.52578 11.1834 8.8243 11.1834 9.19245V12.8422C10.9126 12.7244 10.6139 12.6591 10.3001 12.6591ZM9.41673 14.8758C9.41673 14.3879 9.81221 13.9924 10.3001 13.9924C10.7879 13.9924 11.1834 14.3879 11.1834 14.8758C11.1834 15.3636 10.7879 15.7591 10.3001 15.7591C9.81221 15.7591 9.41673 15.3636 9.41673 14.8758Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
      <path
        d="M3.58334 0.775781C2.07376 0.775781 0.85 1.99954 0.85 3.50911V18.2628C0.85 19.8306 2.00862 21.2258 3.58333 21.2258H20.1167C21.6914 21.2258 22.85 19.8306 22.85 18.2628V6.78137C22.85 5.21367 21.6914 3.8184 20.1167 3.8184H9.53919L7.23656 1.55841C6.7255 1.05682 6.03803 0.775812 5.32195 0.775812L3.58334 0.775781ZM6.30257 2.50995L6.40764 2.4029L6.30257 2.50995L8.79969 4.96083C8.92434 5.08317 9.09202 5.1517 9.26667 5.1517H20.1167C20.8269 5.1517 21.5167 5.81495 21.5167 6.78134V18.2628C21.5167 19.2292 20.8269 19.8924 20.1167 19.8924H3.58333C2.87316 19.8924 2.18333 19.2292 2.18333 18.2628V3.50911C2.18333 2.73591 2.81013 2.10911 3.58333 2.10911H5.32192C5.68869 2.10911 6.04082 2.25304 6.30257 2.50995Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
      <path
        d="M9.78327 0.775781C9.41508 0.775781 9.1166 1.07426 9.1166 1.44245C9.1166 1.81064 9.41508 2.10911 9.78327 2.10911H20.1166C20.4847 2.10911 20.7833 1.81064 20.7833 1.44245C20.7833 1.07425 20.4847 0.775781 20.1166 0.775781H9.78327Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
    </svg>
  );
};
export default IconAddSoundpackHeader;
