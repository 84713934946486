import React from "react";
import { Icon } from "@chakra-ui/react";

const BackgroundImagePlaylistIcon = () => {
  return (
    <Icon
      width={{ base: "52px", md: "72px" }}
      height={{ base: "52px", md: "72px" }}
      viewBox="0 0 77 59"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M74 58H3C1.89543 58 1 57.1046 1 56V18.1832C1 17.0786 1.89543 16.1832 3 16.1832H39.1982C39.9881 16.1832 40.704 15.7183 41.0253 14.9966L42.2765 12.1865C42.5977 11.4649 43.3136 11 44.1035 11H74C75.1046 11 76 11.8954 76 13V56C76 57.1046 75.1046 58 74 58Z"
        fill="url(#paint0_linear_4537_8221)"
        strokeWidth="0.5"
      />
      <path
        d="M74 58.6968H3C1.75736 58.6968 0.75 57.6894 0.75 56.4468V3C0.75 1.75736 1.75736 0.75 3 0.75H33.1575C33.9724 0.75 34.7236 1.1906 35.1213 1.90183L36.284 3.98086C36.5933 4.53404 37.1776 4.87673 37.8114 4.87673H74C75.2426 4.87673 76.25 5.88409 76.25 7.12673V56.4468C76.25 57.6894 75.2426 58.6968 74 58.6968Z"
        stroke="url(#paint2_linear_4537_8221)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4537_8221"
          x1="38.5"
          y1="11"
          x2="38.5"
          y2="58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="0.166667" stopColor="#535353" />
          <stop offset="0.333333" stopColor="#535353" />
          <stop offset="0.5" stopColor="#535353" />
          <stop offset="0.666667" stopColor="#535353" stopOpacity="0.666941" />
          <stop offset="0.833333" stopColor="#535353" stopOpacity="0.454995" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4537_8221"
          x1="38.5"
          y1="11"
          x2="38.5"
          y2="58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4537_8221"
          x1="38.5"
          y1="1"
          x2="38.5"
          y2="58.4468"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
};
export default BackgroundImagePlaylistIcon;
