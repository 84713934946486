import React from "react";

const IconTotalUsersReferred = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="12" cy="9" r="4" stroke="#D4F70E" stroke-width="1.5" />
      <path
        d="M18 12C19.6569 12 21 10.6569 21 9C21 7.34315 19.6569 6 18 6"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.88915 23.5843C9.82627 23.8504 10.8826 24 12 24C15.866 24 19 22.2091 19 20C19 17.7909 15.866 16 12 16C8.13401 16 5 17.7909 5 20C5 20.3453 5.07657 20.6804 5.22053 21"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M21 17C22.7542 17.3847 24 18.3589 24 19.5C24 20.5293 22.9863 21.4229 21.5 21.8704"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default IconTotalUsersReferred;
