import { Tooltip } from "@chakra-ui/react";
import React from "react";

interface ITooltipLabelProps {
  label: string;
  children: React.ReactNode;
}
export default function TooltipLabel({ label, children }: ITooltipLabelProps) {
  return (
    <Tooltip label={label} placement="top">
      {children}
    </Tooltip>
  );
}
