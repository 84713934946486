import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { Message } from "app/assets/icons";
import IconDashboardProfile from "app/assets/icons/IconDashboardProfile";
import IconHomeProfile from "app/assets/icons/IconHomeProfile";
import IconLyricsProfile from "app/assets/icons/IconLyricsProfile";
import IconMyPlaylistProfile from "app/assets/icons/IconMyPlaylistProfile";
import IconPeopleProfile from "app/assets/icons/IconPeopleProfile";
import IconPrivacyPolicy from "app/assets/icons/IconPrivacyPolicy";
import IconReferralProgram from "app/assets/icons/IconReferralProgram";
import IconTermAndConditions from "app/assets/icons/IconTerm&Conditions";
import UpgradePlanIcon from "app/assets/icons/IconUpgradePlan";
import PurchasedImage from "app/assets/images/BsHandbag.png";
import Avatar from "app/assets/images/UserAvatar.png";
import { removeCart, removeStripePaymentMethod } from "app/redux/actions/cart";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { PROFILE_TYPE } from "constants/contractType";
import { setSessionStorage } from "helpers/session";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import IconAccountSettings from "app/assets/icons/IconAccountSettings";
import { logout } from "app/redux/actions/users";

interface DrawerSideBarHeaderProps {
  isOpen: boolean;
  onClose: any;
  setShowModalAddTrack: () => void;
  setIsPaymentMethods: (value: boolean) => void;
  checkPlanSubscription: boolean;
}
export default function DrawerSideBarHeader({
  isOpen,
  onClose,
  setShowModalAddTrack,
  setIsPaymentMethods,
  checkPlanSubscription,
}: DrawerSideBarHeaderProps) {
  const { t } = useTranslation();
  const userAdmin = useSelector(selectUserAdmin);
  const loggedIn = useSelector(selectLoggedIn);

  const dispatch = useDispatch();
  const history = useHistory();
  const { wavesurfer, setIsAudioPlay } = usePlayers();

  const redirectHomeDashboard = useCallback(
    (page) => {
      history.push(`/${page}`);
    },
    [history]
  );

  const onLogout = useCallback(
    (router?: string) => {
      router && setSessionStorage("router", router);
      dispatch(removeStripePaymentMethod());
      dispatch(removeCart());
      history.push("/auth/login");

      if (wavesurfer) {
        wavesurfer.current?.pause();
        wavesurfer.current = null;
        setIsAudioPlay(false);
      }
    },
    [dispatch, history, setIsAudioPlay, wavesurfer]
  );
  const handleLogout = useCallback(() => {
    dispatch(logout({ isLogoutAll: false }));
    dispatch(removeStripePaymentMethod());
    dispatch(removeCart());
    history.push("/auth/login");
    if (wavesurfer) {
      wavesurfer.current?.pause();
      wavesurfer.current = null;
      setIsAudioPlay(false);
    }
  }, [dispatch, history, setIsAudioPlay, wavesurfer]);
  const handleClickUserItem = useCallback(() => {
    history.push(`/user/${userAdmin?.name}?profileType=${PROFILE_TYPE.TRACKS}`);
  }, [history, userAdmin?.name]);

  const handleClickMessages = useCallback(() => {
    history.push("/messages?tab=all");
  }, [history]);

  const listMenuProfile = [
    {
      icon: <IconHomeProfile />,
      content: t("home"),
      id: 0,
      onClickButton: () => redirectHomeDashboard("home"),
    },
    {
      icon: <IconPeopleProfile />,
      content: t("profile"),
      id: 1,
      onClickButton: () => (!loggedIn ? onLogout() : handleClickUserItem()),
    },
    {
      icon: <IconDashboardProfile />,
      content: t("dashboard"),
      id: 2,
      onClickButton: () =>
        !loggedIn ? onLogout("dashboard") : redirectHomeDashboard("dashboard"),
    },
    {
      icon: <Message />,
      content: t("messages"),
      id: 3,
      onClickButton: () =>
        !loggedIn ? onLogout("messages?tab=all") : handleClickMessages(),
    },
    {
      icon: <IconLyricsProfile />,
      content: t("lyrics"),
      id: 4,
      onClickButton: () =>
        !loggedIn ? onLogout("lyrics") : redirectHomeDashboard("lyrics"),
    },
    {
      icon: <Image fallbackSrc={PurchasedImage} />,
      content: t("purchased"),
      id: 7,
      onClickButton: () =>
        !loggedIn ? onLogout("purchased") : redirectHomeDashboard("purchased"),
    },
    {
      icon: <IconMyPlaylistProfile />,
      content: t("playlistsHub"),
      id: 8,
      onClickButton: () =>
        !loggedIn ? onLogout("playlists") : redirectHomeDashboard("playlists"),
    },
    {
      icon: <IconAccountSettings />,
      content: t("accountSettings"),
      id: 10,
      onClickButton: () =>
        !loggedIn
          ? onLogout("user/account-settings")
          : redirectHomeDashboard("user/account-settings"),
    },
    {
      icon: <IconReferralProgram />,
      content: t("referralProgram"),
      id: 9,
      onClickButton: () =>
        !loggedIn ? onLogout("referral") : redirectHomeDashboard("referral"),
    },
    {
      icon: <IconTermAndConditions />,
      content: t("termAndConditions"),
      id: 5,
      onClickButton: () => redirectHomeDashboard("term-condition"),
    },
    {
      icon: <IconPrivacyPolicy />,
      content: t("privacyPolicy"),
      id: 6,
      onClickButton: () => redirectHomeDashboard("privacy-policy"),
    },
  ];

  const handleUpgradePlan = useCallback(() => {
    loggedIn
      ? history.push("/dashboard/subscription")
      : onLogout("dashboard/subscription");
  }, [history, loggedIn, onLogout]);
  return (
    <Drawer placement="left" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>
          <Flex alignItems="center">
            <Image
              w="54px"
              height="54px"
              borderRadius="full"
              alt="User"
              src={userAdmin?.profileImage}
              fallbackSrc={Avatar}
            />
            <Box ml="16px">
              <Text
                fontSize="16px"
                lineHeight="20px"
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {!loggedIn ? t("guest") : userAdmin?.name}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="15px"
                fontWeight="400"
                color="#949599"
                mt="5px"
                fontFamily="Cera Pro Regular"
              >
                {userAdmin?.state}
              </Text>
            </Box>
          </Flex>
          <Button
            background="rgba(212, 247, 14, 0.04)"
            border="1px solid rgba(212, 247, 14, 0.1)"
            borderRadius="4px"
            _hover={{ background: "rgba(212, 247, 14, 0.04)" }}
            fontSize="12px"
            lineHeight="14px"
            color="#D4F70E"
            leftIcon={<UpgradePlanIcon />}
            size="sm"
            w="85%"
            mt="20px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin={" 20px auto"}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              handleUpgradePlan();
              onClose();
            }}
            fontFamily="Cera Pro Medium"
          >
            {t("upgradePlan")}
          </Button>
        </DrawerHeader>
        <DrawerBody>
          {listMenuProfile.map((item) => (
            <Flex
              alignItems="center"
              key={item.id}
              _hover={{
                background:
                  "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                borderLeft: "1px solid #D4F70E",
                borderRadius: "0px",
              }}
              padding="13px 10px"
              onClick={() => {
                item.onClickButton();
                onClose();
              }}
              cursor="pointer"
              mb="3px"
            >
              <Box w="20px">{item.icon}</Box>

              <Text
                className="fontCeraProRegular"
                ml="20px"
                fontSize="16px"
                lineHeight="20px"
              >
                {item.content}
              </Text>
            </Flex>
          ))}
          <Button
            background="rgba(212, 247, 14, 0.04)"
            border="1px solid rgba(212, 247, 14, 0.1)"
            borderRadius="4px"
            _hover={{ background: "rgba(212, 247, 14, 0.04)" }}
            _focus={{ boxShadow: "none" }}
            fontSize="12px"
            lineHeight="14px"
            color="#D4F70E"
            display="flex"
            justifyContent="center"
            alignItems="center"
            w="100%"
            fontFamily="Cera Pro Medium"
            onClick={loggedIn ? handleLogout : () => onLogout()}
          >
            {t("logout")}
          </Button>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
