import React from "react";

const NextIcon = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.88464e-06 2.35097L1.13291e-06 10.9498C1.06008e-06 11.7829 0.959102 12.2507 1.61564 11.7378L7.01096 7.52269C7.51723 7.12716 7.52453 6.36368 7.02591 5.95855L1.63059 1.57486C0.97708 1.04388 1.95825e-06 1.50894 1.88464e-06 2.35097Z"
        fill="white"
      />
      <rect
        x="9"
        y="13"
        width="1.5"
        height="13"
        rx="0.750001"
        transform="rotate(-180 9 13)"
        fill="white"
      />
    </svg>
  );
};
export default NextIcon;
