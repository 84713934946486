import {
  fetchPromoCode,
  deletePromoCode,
  changeActivePromoCode,
} from "app/redux/actions/promoCode";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { promoCodesSelector } from "app/redux/selectors/promoCode";
import { isBeforeFromNow } from "utils/timeAgo";

export const usePromoCode = () => {
  const dispatch = useDispatch();
  const promoCodes = useSelector(promoCodesSelector);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchPromoCode.typePrefix], state)
  );
  const onFetchPromoCode = useCallback(() => {
    dispatch(fetchPromoCode());
  }, [dispatch]);

  const onDeletePromoCode = useCallback(
    (promoCodeId) => {
      dispatch(deletePromoCode(promoCodeId));
    },
    [dispatch]
  );

  const onChangeActivePromoCode = useCallback(
    (data) => {
      dispatch(changeActivePromoCode(data));
    },
    [dispatch]
  );

  const isActivePromoCode = (
    active: boolean,
    nonExpiring: boolean,
    expirationDate: string
  ): boolean => {
    return active && (nonExpiring || isBeforeFromNow(expirationDate));
  };
  return {
    isLoading,
    promoCodes,
    isActivePromoCode,
    onDeletePromoCode,
    onFetchPromoCode,
    onChangeActivePromoCode,
  };
};
