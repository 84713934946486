import React from "react";
interface IShuffleAudioProps {
  color?: string;
}
const ShuffleAudio = ({ color = "#535353" }: IShuffleAudioProps) => {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3092_6347)">
        <path
          d="M18.999 2.04075L13.3742 1.43936C13.2431 1.42535 13.1105 1.43729 12.984 1.47451C12.8575 1.51173 12.7396 1.57351 12.6369 1.65631C12.5343 1.7391 12.449 1.84131 12.3859 1.95707C12.3228 2.07284 12.283 2.19991 12.269 2.33102C12.255 2.46213 12.267 2.59472 12.3042 2.72122C12.3414 2.84771 12.4032 2.96564 12.486 3.06826C12.5688 3.17088 12.671 3.25619 12.7867 3.31932C12.9025 3.38244 13.0296 3.42215 13.1607 3.43617L16.3887 3.77418L3.47712 14.1915C3.27071 14.358 3.13891 14.5997 3.11071 14.8634C3.08251 15.1272 3.16023 15.3913 3.32677 15.5977C3.49331 15.8041 3.73502 15.9359 3.99873 15.9641C4.26245 15.9923 4.52656 15.9146 4.73297 15.748L17.6445 5.33073L17.2924 8.5572C17.2779 8.68841 17.2894 8.8212 17.3264 8.94792C17.3634 9.07464 17.4252 9.19277 17.5081 9.29551C17.5909 9.39826 17.6934 9.48357 17.8094 9.54653C17.9254 9.60949 18.0528 9.64885 18.1841 9.66235C18.3153 9.67692 18.4481 9.66537 18.5748 9.62837C18.7015 9.59136 18.8197 9.52963 18.9224 9.44673C19.0251 9.36384 19.1105 9.26142 19.1734 9.14539C19.2364 9.02936 19.2757 8.90201 19.2892 8.77069L19.8906 3.1459C19.9018 3.01471 19.8869 2.88262 19.8469 2.7572C19.7718 2.50492 19.6003 2.29239 19.3696 2.16572C19.2555 2.10007 19.1295 2.0576 18.999 2.04075Z"
          fill={color}
        />
        <path
          d="M18.999 20.971L13.3742 21.5724C13.2431 21.5864 13.1105 21.5744 12.984 21.5372C12.8575 21.5 12.7396 21.4382 12.6369 21.3554C12.5343 21.2726 12.449 21.1704 12.3859 21.0546C12.3228 20.9389 12.283 20.8118 12.269 20.6807C12.255 20.5496 12.267 20.417 12.3042 20.2905C12.3414 20.164 12.4032 20.0461 12.486 19.9435C12.5688 19.8408 12.671 19.7555 12.7867 19.6924C12.9025 19.6293 13.0296 19.5896 13.1607 19.5756L16.3887 19.2375L3.47712 8.82024C3.27071 8.65371 3.13891 8.412 3.11071 8.14828C3.08251 7.88457 3.16023 7.62046 3.32677 7.41404C3.49331 7.20763 3.73502 7.07583 3.99873 7.04764C4.26245 7.01944 4.52656 7.09716 4.73297 7.2637L17.6445 17.681L17.2924 14.4545C17.2779 14.3233 17.2894 14.1905 17.3264 14.0638C17.3634 13.9371 17.4252 13.8189 17.5081 13.7162C17.5909 13.6135 17.6934 13.5282 17.8094 13.4652C17.9254 13.4022 18.0528 13.3629 18.1841 13.3494C18.3153 13.3348 18.4481 13.3463 18.5748 13.3834C18.7015 13.4204 18.8197 13.4821 18.9224 13.565C19.0251 13.6479 19.1105 13.7503 19.1734 13.8663C19.2364 13.9824 19.2757 14.1097 19.2892 14.241L19.8906 19.8658C19.9018 19.997 19.8869 20.1291 19.8469 20.2545C19.7718 20.5068 19.6003 20.7193 19.3696 20.846C19.2555 20.9117 19.1295 20.9541 18.999 20.971Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3092_6347">
          <rect width="23" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default ShuffleAudio;
