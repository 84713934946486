import { createSelector } from "@reduxjs/toolkit";
import { LICENSE_RENEWAL } from "constants/index";
import {
  convertData,
  convertDataDefault,
  convertDataToAllow,
  convertDataToBoolean,
  convertDataUnlimited,
} from "utils/convertData";

export const selectLicenseAndContractsState = (state: any) =>
  state.licenseAndContracts;

export const LicenseAndContracts = createSelector(
  selectLicenseAndContractsState,
  (licenseAndContracts) => licenseAndContracts
);

export const selectLicenseDetail = createSelector(
  selectLicenseAndContractsState,
  ({ license }) => ({
    ...license,
    name: convertData(license.name),
    exclusive: convertDataDefault(license.exclusive),
    extraWav: convertDataToBoolean(license.extraWav),
    trackStems: convertDataToBoolean(license.trackStems),
    distributionCopies: convertDataDefault(
      license.distributionCopies,
      "Unlimited"
    ),
    freeDownloads: convertDataUnlimited(license.freeDownloads),
    audioStreams: convertDataUnlimited(license.audioStreams),
    musicVideo: convertDataUnlimited(license.musicVideo),
    nonMonetizedVideo: convertDataDefault(
      license.nonMonetizedVideo,
      "Unlimited"
    ),
    monetizedVideo: convertDataUnlimited(license.monetizedVideo),
    allowBroadCasting: convertDataToAllow(license.allowBroadCasting),
    radioStation: convertDataUnlimited(license.radioStation),
    profitPerformance: convertDataToAllow(license.profitPerformance),
    notProfitPerformance: convertDataUnlimited(license.notProfitPerformance),
    beatsPublishing: convertDataToBoolean(license.beatsPublishing, null),
    beatsHookPublishing: convertDataToBoolean(
      license.beatsHookPublishing,
      null
    ),
    topLinesAndVocals: convertDataToBoolean(license.topLinesAndVocals, null),
    minPrice: convertDataToBoolean(license.minPrice, null),
    defaultTrackPrice: convertDataToBoolean(license.defaultTrackPrice, null),
    makeOffersOnly: convertDataToBoolean(license.makeOffersOnly),
    state: convertData(license.state),
    country: convertData(license.country),
    mp3: true,
    description: convertData(license.description),
    licenseRenewal: convertDataDefault(
      license.licenseRenewal,
      LICENSE_RENEWAL.ONE_YEAR
    ),
  })
);
