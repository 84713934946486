import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import IconCollaborationsPlaylistIndividual from "app/assets/icons/IconCollaborationsPlaylistIndividual";
import IconLikePlaylistIndividual from "app/assets/icons/IconLikePlaylistIndividual";
import IconPlaylistIndividual from "app/assets/icons/IconPlaylistIndividual";
import IconRepostPlaylistIndividual from "app/assets/icons/IconRepostPlaylistIndividual";
import IconSharePlaylistIndividual from "app/assets/icons/IconSharePlaylistIndividual";
import PlayAudioIcon from "app/assets/icons/PlayAudioIcon";
import PlayIcon from "app/assets/icons/PlayIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import MusicCard from "app/components/MusicCard";
import ToastPlanSubscription from "app/components/toast/toastplan";
import {
  checkIsPlayPlaylists,
  checkPlaylistIdPlaying,
  fetchFavoritesPlaylists,
  fetchRepostPlaylists,
  followUnfollowPlaylist,
  getIndividualPlaylist,
  likePlaylistIndividual,
  prepareEditPlaylist,
  repostPlaylistIndividual,
  unlikePlaylistIndividual,
} from "app/redux/actions/playlist";
import {
  isFavoritePlaylistSelector,
  isRepostPlaylistSelector,
} from "app/redux/selectors/playlist";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { usePlayers } from "hooks/player/usePlayers";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import useTrackSelect from "hooks/track/useTrackSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { formatTimeByEn } from "utils/timeAgo";
import EmptyPlaylist from "./emptyPlaylist";
import ModalAddToPlaylist from "./modalAddToPlaylist";
import ModalCreatePlaylist from "./modalCreatePlaylist";
import SkeletonIndividualPlaylist from "./skeletonIndividualPlaylist";
import { convertTrackKey } from "constants/trackKey";
import { convertToHoursAndMinutes } from "constants/playlistType";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import * as playlistActions from "app/redux/actions/playlist";
import { LOOP_TYPE } from "constants/audioOptions";
import IconPlaylistIndividualMobile from "app/assets/icons/IconPlaylistIndividualMobile";
import { useToggle } from "hooks/useToggle";
import SkeletonIndividualPlaylistMobile from "./skeletonIndividualPlaylistMobile";
import ModalShareLinkPlaylist from "../Home/modalShareLinkPlaylist";
import { shortLinkTrackSoundkitDetail } from "utils/getData";
import ModalLyrics from "app/components/ModalShowLyrics";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";

export default function IndividualPlaylist() {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserAdmin);
  const [delayTimeCallApi, setDelayTimeCallApi] = useState(0);
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const [isLessThan990] = useMediaQuery("(max-width: 990px)");
  const [showDes, setShowDes] = useToggle();
  const {
    playlistIndividual,
    isLoadingIndividualPlaylist,
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
    setEditPlaylist,
    editPlaylist,
    playlistItem,
    showModalShareLinkPlaylist,
    setShowModalShareLinkPlaylist,
    shortLinkPlaylist,
    setPlaylistItem,
    setShortLinkPlaylist,
  } = usePlaylists();

  const {
    selectedTrack,
    onOpenOptionsModal,
    showOptionsModal,
    setShowOptionsModal,
    showModalFreeDownload,
    setShowModalFreeDownload,
    linkTitleDownload,
    handleFreeDownload,
    loadingUrl,
    setLoadingUrl,
    showLyrics,
    setShowLyrics,
    setIsEditLyrics,
    isEditLyrics,
    lyricsData,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();
  const loggedIn = useSelector(selectLoggedIn);

  const runTime = (playlistIndividual?.tracks || []).map((item) => {
    return item?.timeTagDuration
      ? Number(item?.timeTagDuration?.replace(":", "."))
      : Number(item?.timeUntagDuration?.replace(":", ".")) || 0;
  });

  const totalRunTime = (runTime || []).reduce((accumulator, currentValue) => {
    return Number(accumulator) + Number(currentValue);
  }, 0);

  const { hours, minutes, seconds } = convertToHoursAndMinutes(totalRunTime);
  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 2000);
  }, []);

  const timeRun = useMemo(() => {
    if (hours) {
      return `${hours}h${minutes}m${seconds}s`;
    }
    if (minutes) {
      return `${minutes}m${seconds}s`;
    }
    return `${seconds}s`;
  }, [hours, minutes, seconds]);

  const {
    playingTrackId,
    isPlaying,
    handlePlayOrPause,
    handleTogglePlayPause,
    isLoading,
    handlePlayPlaylists,
    playlistIdPlaying,
  } = usePlayers();

  const isFavorite = useSelector(
    isFavoritePlaylistSelector(playlistIndividual)
  );
  const isRepost = useSelector(isRepostPlaylistSelector(playlistIndividual));
  const isFollow = useMemo(() => {
    return playlistIndividual?.followers?.includes(currentUser?._id);
  }, [currentUser?._id, playlistIndividual?.followers]);

  const handleEditPlaylist = useCallback(() => {
    dispatch(prepareEditPlaylist(playlistIndividual));
    setShowModalCreatePlaylist();
    setEditPlaylist(true);
  }, [
    dispatch,
    playlistIndividual,
    setEditPlaylist,
    setShowModalCreatePlaylist,
  ]);

  const informationMenu = useMemo(
    () => [
      {
        name: t("published"),
        id: 0,
        value: formatTimeByEn(playlistIndividual?.createdAt),
      },
      {
        name: t("runtime"),
        id: 1,
        value: `${timeRun}, ${playlistIndividual?.totalTracks} Tracks`,
      },
      {
        name: t("lastUpdated"),
        id: 2,
        value: formatTimeByEn(playlistIndividual?.updatedAt),
      },
    ],
    [
      playlistIndividual?.createdAt,
      playlistIndividual?.totalTracks,
      playlistIndividual?.updatedAt,
      t,
      timeRun,
    ]
  );

  useEffect(() => {
    dispatch(getIndividualPlaylist(id));
    if (loggedIn) {
      dispatch(fetchFavoritesPlaylists());
      dispatch(fetchRepostPlaylists());
    }
  }, [dispatch, id, loggedIn]);

  const handleLikeUnLikePlaylist = useCallback(async () => {
    increaseTime();
    if (!isFavorite) {
      dispatch(
        likePlaylistIndividual({
          id: playlistIndividual?._id || "",
          timeCallAPi: delayTimeCallApi,
        })
      );
    } else {
      dispatch(
        unlikePlaylistIndividual({
          id: playlistIndividual?._id || "",
          timeCallAPi: delayTimeCallApi,
        })
      );
    }
  }, [
    delayTimeCallApi,
    dispatch,
    increaseTime,
    isFavorite,
    playlistIndividual?._id,
  ]);

  const createShortLinkUrl = useCallback(async () => {
    const hostName = window.location.host;
    setShowModalShareLinkPlaylist();
    setPlaylistItem(playlistIndividual);
    setLoadingUrl(true);
    const shortLink = await shortLinkTrackSoundkitDetail({
      originalURL: `${hostName}/playlist/${playlistIndividual?._id}`,
    });
    setShortLinkPlaylist(shortLink?.data?.data);
    setLoadingUrl(false);
  }, [
    playlistIndividual,
    setLoadingUrl,
    setPlaylistItem,
    setShortLinkPlaylist,
    setShowModalShareLinkPlaylist,
  ]);

  const handleRepostPlaylist = useCallback(() => {
    if (isRepost) {
      ToastPlanSubscription({
        description: t("alreadyRepostPlaylist"),
      });
    } else {
      dispatch(repostPlaylistIndividual(playlistIndividual?._id || ""));
    }
  }, [dispatch, isRepost, playlistIndividual?._id, t]);

  const iconPlaylistIndividual = useMemo(
    () => [
      {
        icon: <IconLikePlaylistIndividual isFavorite={isFavorite} />,
        total: playlistIndividual?.totalFavs || 0,
        id: 0,
        onClick: loggedIn ? () => handleLikeUnLikePlaylist() : () => null,
      },
      {
        icon: <IconRepostPlaylistIndividual isRepost={isRepost} />,
        total: playlistIndividual?.totalRepost || 0,
        id: 1,
        onClick: loggedIn ? () => handleRepostPlaylist() : () => null,
        marginTop: "-2px",
      },
      {
        icon: <IconCollaborationsPlaylistIndividual />,
        total: playlistIndividual?.followers?.length || 0,
        id: 2,
        onClick: () => null,
      },
      {
        icon: <IconSharePlaylistIndividual />,
        total: "",
        id: 3,
        onClick: () => createShortLinkUrl(),
      },
    ],
    [
      isFavorite,
      playlistIndividual?.totalFavs,
      playlistIndividual?.totalRepost,
      playlistIndividual?.followers?.length,
      isRepost,
      handleLikeUnLikePlaylist,
      handleRepostPlaylist,
      createShortLinkUrl,
      loggedIn,
    ]
  );

  const handleFollowUnfollowPlaylist = useCallback(() => {
    dispatch(followUnfollowPlaylist({ playlistId: playlistIndividual?._id }));
  }, [dispatch, playlistIndividual?._id]);

  const checkTrackPlayInPlaylists = useMemo(() => {
    return playlistIndividual?._id === playlistIdPlaying;
  }, [playlistIdPlaying, playlistIndividual?._id]);

  const findIndexPlayTrackInPlaylists = useMemo(() => {
    return playlistIndividual?.tracks?.findIndex(
      (item) => item?._id === playingTrackId
    );
  }, [playingTrackId, playlistIndividual?.tracks]);

  const handlePlayAllPlaylists = useCallback(() => {
    if (checkTrackPlayInPlaylists && findIndexPlayTrackInPlaylists !== -1) {
      handlePlayOrPause(
        playlistIndividual?.tracks?.[findIndexPlayTrackInPlaylists || 0]
      );
    } else {
      handlePlayOrPause(playlistIndividual?.tracks?.[0]);
    }
    handlePlayPlaylists(playlistIndividual?.tracks);
    dispatch(checkIsPlayPlaylists(false));
    dispatch(checkPlaylistIdPlaying(playlistIndividual?._id));
    dispatch(playlistActions.setLoopAudioType(LOOP_TYPE.LOOP));
  }, [
    checkTrackPlayInPlaylists,
    dispatch,
    findIndexPlayTrackInPlaylists,
    handlePlayOrPause,
    handlePlayPlaylists,
    playlistIndividual?._id,
    playlistIndividual?.tracks,
  ]);

  return (
    <Flex
      p={{ base: "20px 16px 40px", xl: "40px 80px" }}
      w="100%"
      gap="20px"
      display={{ base: "block", lg: "flex" }}
    >
      {isLoadingIndividualPlaylist ? (
        isLessThan990 ? (
          <SkeletonIndividualPlaylistMobile />
        ) : (
          <SkeletonIndividualPlaylist />
        )
      ) : (
        <>
          <Box w={{ base: "100%", lg: "30%" }}>
            <Box
              background={{ base: "none", md: "rgba(36, 37, 42, 0.30)" }}
              padding={{
                base: "20px 0px",
                md: "20px 40px",
                lg: "20px",
                xl: "20px 40px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                flexDirection={{ base: "row", lg: "column" }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  position="relative"
                  role="group"
                  cursor="pointer"
                  onClick={
                    isPlaying ? handleTogglePlayPause : handlePlayAllPlaylists
                  }
                >
                  <Box>
                    {isLessThan766 ? (
                      <IconPlaylistIndividualMobile />
                    ) : (
                      <IconPlaylistIndividual />
                    )}
                  </Box>
                  <Image
                    fallbackSrc={ImageDefault}
                    borderRadius="4px"
                    objectFit="cover"
                    w={{ base: "65px", md: "150px", lg: "180px" }}
                    height={{ base: "65px", md: "150px", lg: "180px" }}
                    mt={{ base: "-40px", md: "-130px" }}
                    src={playlistIndividual?.coverUrl}
                    _groupHover={{
                      opacity: 0.5,
                    }}
                  />
                  <Flex
                    position="absolute"
                    top="65%"
                    left="50%"
                    transform="translate(-50%,-50%)"
                    alignItems="center"
                    display="none"
                    _groupHover={{
                      display: "flex",
                    }}
                  >
                    {isLoading ? (
                      <Spinner color="#D4F70E" />
                    ) : isPlaying && checkTrackPlayInPlaylists ? (
                      <Flex
                        background="rgba(255, 255, 255, 0.06)"
                        width={`28px`}
                        height={`28px`}
                        borderRadius="25px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PlayAudioIcon />
                      </Flex>
                    ) : (
                      <>
                        <PlayIcon />
                        <Text
                          fontWeight="400"
                          fontSize={{ base: "12px", md: "20px" }}
                          ml="10px"
                        >
                          {t("playAll")}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Box>
                <Box ml={{ base: "19px", lg: "0px" }}>
                  <Text
                    mt="10px"
                    fontSize={{ base: "24px", md: "28px", xl: "32px" }}
                    fontWeight="700"
                    noOfLines={2}
                    textAlign={{ base: "start", lg: "center" }}
                  >
                    {playlistIndividual?.name}
                  </Text>
                  <Text
                    fontSize="14px"
                    fontWeight="400"
                    color="rgba(255, 255, 255, 0.51)"
                    textAlign={{ base: "start", lg: "center" }}
                  >
                    {t("playlistOwner")}
                    <span>{playlistIndividual?.createdBy?.name}</span>
                  </Text>
                  <Box display={{ base: "block", lg: "none" }}>
                    {showDes ? (
                      <Text
                        color="rgba(255, 255, 255, 0.50)"
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {playlistIndividual?.description || "No description"}
                      </Text>
                    ) : (
                      <Text
                        color="rgba(255, 255, 255, 0.50)"
                        fontWeight="500"
                        fontSize="12px"
                        noOfLines={2}
                      >
                        {playlistIndividual?.description?.slice(0, 125) ||
                          "No description"}
                      </Text>
                    )}
                    {playlistIndividual?.description?.length > 125 &&
                      !showDes && (
                        <Text
                          fontSize="12px"
                          color="#D4F70E"
                          onClick={setShowDes}
                        >
                          {t("showMore")}
                        </Text>
                      )}
                    {playlistIndividual?.description?.length > 125 &&
                      showDes && (
                        <Text
                          fontSize="12px"
                          color="#D4F70E"
                          onClick={setShowDes}
                        >
                          {t("showLess")}
                        </Text>
                      )}
                  </Box>
                </Box>
              </Box>

              <Flex
                justifyContent={{
                  base: "center",
                  md: "space-around",
                  lg: "space-between",
                }}
                m="30px 0px"
                gap={{ base: "40px", md: "0px" }}
              >
                {iconPlaylistIndividual.map((item) => (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    key={item.id}
                    onClick={item.onClick}
                    cursor="pointer"
                    justifyContent="space-between"
                  >
                    <Box
                      _hover={{
                        border: "1px solid #38383D",
                        background: "#222227 !important",
                        borderRadius: "6px",
                        padding: "10px",
                      }}
                      padding="10px"
                      borderWidth="1px"
                      borderColor="rgba(36, 37, 42, 0.3)"
                      mt={item?.marginTop}
                    >
                      {item.icon}
                    </Box>
                    <Text
                      fontSize="20px"
                      lineHeight="24px"
                      fontWeight="400"
                      color="#535353"
                    >
                      {item.total}
                    </Text>
                  </Box>
                ))}
              </Flex>
              {currentUser?._id === playlistIndividual?.createdBy?._id ? (
                <Flex justifyContent="center">
                  <Button
                    w={{ base: "70%", lg: "100%" }}
                    background={{ base: "#24252A", md: "#18181C" }}
                    color="white"
                    borderRadius="4px"
                    fontSize="14px"
                    fontWeight="500"
                    height="48px"
                    _hover={{
                      background: isLessThan766 ? "#24252A" : "#18181C",
                    }}
                    onClick={handleEditPlaylist}
                  >
                    {t("editPlaylist")}
                  </Button>
                </Flex>
              ) : (
                <Flex justifyContent="center">
                  <Button
                    border={isFollow ? "1px solid #D4F70E" : "none"}
                    w={{ base: "70%", lg: "100%" }}
                    background={
                      isFollow
                        ? "rgba(212, 247, 14, 0.20)"
                        : isLessThan766
                        ? "#24252A"
                        : "#18181C"
                    }
                    color={isFollow ? "#D4F70E" : "white"}
                    borderRadius="4px"
                    fontSize="14px"
                    fontWeight="500"
                    height="48px"
                    _hover={{
                      background: isFollow
                        ? "rgba(212, 247, 14, 0.20)"
                        : isLessThan766
                        ? "#24252A"
                        : "#18181C",
                    }}
                    onClick={
                      loggedIn ? handleFollowUnfollowPlaylist : () => null
                    }
                  >
                    {isFollow ? t("unfollow") : t("Follow")}
                  </Button>
                </Flex>
              )}

              <Divider
                margin="20px 0px"
                borderColor="rgba(255, 255, 255, 0.04)"
              />

              <Text
                fontSize="13px"
                fontWeight="600"
                mb="10px"
                display={{ base: "none", lg: "block" }}
              >
                {t("information")}
              </Text>
              {informationMenu.map((item) => (
                <Flex
                  mt="5px"
                  justifyContent="space-between"
                  key={item.id}
                  gap="20px"
                  display={{ base: "none", lg: "flex" }}
                >
                  <Text
                    textAlign="end"
                    fontWeight="500"
                    color="rgba(255, 255, 255, 0.50)"
                  >
                    {item.name}
                  </Text>
                  <Text
                    textAlign="end"
                    fontWeight="700"
                    color="rgba(255, 255, 255, 0.50)"
                  >
                    {item.value}
                  </Text>
                </Flex>
              ))}
              <Text
                textAlign="left"
                fontSize="13px"
                fontWeight="600"
                m="15px 0px"
                display={{ base: "none", lg: "block" }}
              >
                {t("fileDescription")}
              </Text>
              <Box display={{ base: "none", lg: "block" }}>
                <Text
                  color="rgba(255, 255, 255, 0.50)"
                  fontWeight="500"
                  noOfLines={3}
                >
                  {playlistIndividual?.description || "No description"}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box w={{ base: "100%", lg: "70%" }}>
            <Text
              fontSize={{ base: "24px", md: "32px" }}
              fontWeight="500"
              ml={{ base: "0px", md: "30px" }}
            >
              {playlistIndividual?.tracks?.length || 0} <span>Tracks</span>
            </Text>
            <Box>
              {(playlistIndividual?.tracks || [])?.length > 0 ? (
                playlistIndividual?.tracks?.map((item, index) => {
                  const convertKeyTrack = convertTrackKey(item);
                  return (
                    <MusicCard
                      item={convertKeyTrack}
                      index={index}
                      key={item?._id}
                      isProfile
                      onOpenOptionsModal={onOpenOptionsModal}
                      handleShowModalAddTrack={handleShowModalAddTrack}
                      isPlaylists
                      playlistId={playlistIndividual?._id}
                      handleFreeDownload={handleFreeDownload}
                      listPlaylistTracksSoundPacks={playlistIndividual?.tracks}
                      handleShowModalFreeDownloadMailchimp={
                        handleShowModalFreeDownloadMailchimp
                      }
                    />
                  );
                })
              ) : (
                <EmptyPlaylist isPlaylistIndividual />
              )}
            </Box>
          </Box>
        </>
      )}
      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showModalShareLinkPlaylist && (
        <ModalShareLinkPlaylist
          showModalShareLink={showModalShareLinkPlaylist}
          setShowModalShareLink={setShowModalShareLinkPlaylist}
          selectedPlaylist={playlistItem}
          shortLinkUrl={shortLinkPlaylist}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalAddToPlaylist && (
        <ModalAddToPlaylist
          trackId={trackId}
          setShowModal={setShowModalAddToPlaylist}
          showModal={showModalAddToPlaylist}
          setShowModalCreatePlaylist={setShowModalCreatePlaylist}
          playlists={playlists}
          handleSearchText={handleSearchText}
          debouncedSearchText={debouncedSearchText}
        />
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
          editPlaylist={editPlaylist}
        />
      )}
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Flex>
  );
}
