import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { shoppingCartSelector } from "app/redux/selectors/cart";
import CartService from "app/apis/cart";
import {
  BulkOffers,
  CartLicenses,
  CreditCard,
  TrackSoundKit,
} from "app/models";

import { trackSoundKitIds } from "utils/trackSoundkitId";
import ToastPlanSubscription from "app/components/toast/toastplan";
export const TYPES = {
  ADD_TO_CART: "ADD_TO_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  SELECT_LICENSE: "SELECT_LICENSE",
  SELECT_PAYMENT_TYPE: "SELECT_PAYMENT_TYPE",
  PURCHASE_CART: "PURCHASE_CART",
  CAPTURE_PAYPAL: "CAPTURE_PAYPAL",
  LIST_STRIPE_PAYMENT_TYPE: "LIST_STRIPE_PAYMENT_TYPE",
  SELECT_PAYMENT_METHODS: "SELECT_PAYMENT_METHODS",
  VALIDATE_PROMO_CODE: "VALIDATE_PROMO_CODE",
  APPLY_PROMO_CODE: "APPLY_PROMO_CODE",
  CLEAR_VALIDATED_PROMO_CODE: "CLEAR_VALIDATED_PROMO_CODE",
  CLEAR_APPLIED_PROMO_CODE: "CLEAR_APPLIED_PROMO_CODE",
  REMOVE_STRIPE_PAYMENT_TYPE: "REMOVE_STRIPE_PAYMENT_TYPE",
  REMOVE_CART: "REMOVE_CART",
  UPDATE_CREDIT_CARD: "UPDATE_CREDIT_CARD",
  DELETE_CREDIT_CARD: "DELETE_CREDIT_CARD",
  ADD_LICENSES_FROM_GUEST: "ADD_LICENSES_FROM_GUEST",
  ADD_CARTS_FROM_GUEST: "ADD_CARTS_FROM_GUEST",
  GET_LIST_TRACK_OF_CART: "GET_LIST_TRACK_OF_CART",
  GET_LIST_SOUNDKIT_OF_CART: "GET_LIST_SOUNDKIT_OF_CART",
};

interface capturePaypalProps {
  orderId: string;
  cart: TrackSoundKit[];
  licenses: CartLicenses[];
  bulkOffers: BulkOffers[];
  ipAddress: string;
  promoCode?: string;
}

interface purchasePaypalProps {
  cart: TrackSoundKit[];
  subscriptionId: string;
  licenses: CartLicenses[];
  bulkOffers: BulkOffers[];
  totalAmount: number;
  paymentType?: string;
  paymentMethodId: string | undefined;
  rememberCard: boolean | undefined;
  promoCode?: string;
  ipAddress?: string;
}

export const addToCart = createAsyncThunk(
  TYPES.ADD_TO_CART,
  (track: any, thunkApi) => {
    const currentCart = [...shoppingCartSelector(thunkApi.getState()), track];
    const paypalPaymentAvailable = currentCart.every(
      (item) => item?.createdBy?.sellerConnectedPayPal
    );
    const stripePaymentAvailable = currentCart.every(
      (item) => item?.createdBy?.sellerConnectedStripe
    );

    if (paypalPaymentAvailable || stripePaymentAvailable) {
      return track;
    } else {
      ToastPlanSubscription({
        title: "Mixed Payment Methods Error",
        description:
          "Please select only one payment method (either PayPal or Stripe) for your purchase. Mixed payment methods are not supported in a single transaction. If you have items in your cart that require different payment methods, please proceed with separate transactions. For any assistance, please reach out to our support team. Thank you for your understanding.",
      });
      return thunkApi.rejectWithValue("Error");
    }
  }
);

export const selectedLisence = createAction(
  TYPES.SELECT_LICENSE,
  ({ trackId, license, negotiation }) => {
    return {
      payload: {
        trackId,
        license,
        negotiation,
      },
    };
  }
);

export const removeFromCart = createAction(TYPES.REMOVE_FROM_CART, (id) => {
  return { payload: id };
});

export const selectPaymentType = createAction(
  TYPES.SELECT_PAYMENT_TYPE,
  (type) => {
    return { payload: type };
  }
);

export const purchaseCart = createAsyncThunk(
  TYPES.PURCHASE_CART,
  async (
    {
      cart,
      subscriptionId,
      promoCode,
      licenses,
      totalAmount,
      paymentType,
      paymentMethodId,
      rememberCard,
      bulkOffers,
      ipAddress,
    }: purchasePaypalProps,
    thunkApi
  ) => {
    return await CartService.purchaseCart({
      trackIds: cart
        .filter((item: TrackSoundKit) => {
          return licenses.find(
            (license: CartLicenses) => license.trackId === item._id
          )?.licenseId;
        })
        .map((item: TrackSoundKit) => item._id),
      subscriptionId,
      licenses,
      totalAmount,
      soundkitIds: cart
        .filter(
          (item) =>
            !licenses.find((license) => license.trackId === item._id)?.licenseId
        )
        .map((item) => item._id),
      paymentType,
      paymentMethodId,
      bulkOffers,
      rememberCard,
      promoCode,
      ipAddress,
    });

    // await thunkApi.dispatch(fetchCurrentUserInfo());

    // return cart;
  }
);

export const capturePaypal = createAsyncThunk(
  TYPES.CAPTURE_PAYPAL,
  async ({
    orderId,
    cart,
    licenses,
    bulkOffers,
    ipAddress,
    promoCode,
  }: capturePaypalProps) => {
    const isSoundKitCheck = true;
    return await CartService.capturePaypal({
      orderId,
      licenses,
      trackIds: trackSoundKitIds(cart, licenses, !isSoundKitCheck),
      soundkitIds: trackSoundKitIds(cart, licenses, isSoundKitCheck),
      bulkOffers,
      ipAddress,
      promoCode,
    });
  }
);

export const listStripePaymentMethod = createAsyncThunk(
  TYPES.LIST_STRIPE_PAYMENT_TYPE,
  CartService.listPaymentMethods
);

export const removeStripePaymentMethod = createAction(
  TYPES.REMOVE_STRIPE_PAYMENT_TYPE
);
export const removeCart = createAction(TYPES.REMOVE_CART);

export const selectPaymentMethods = createAsyncThunk(
  TYPES.SELECT_PAYMENT_METHODS,
  (method: any) => {
    return { payload: method };
  }
);

export const validatePromoCode = createAsyncThunk(
  TYPES.VALIDATE_PROMO_CODE,
  CartService.validatePromoCode
);

export const applyPromoCode = createAction(TYPES.APPLY_PROMO_CODE);

export const clearValidatedPromoCode = createAction(
  TYPES.CLEAR_VALIDATED_PROMO_CODE
);

export const clearAppliedPromoCode = createAction(
  TYPES.CLEAR_APPLIED_PROMO_CODE
);

export const updateCreditCard = createAsyncThunk(
  TYPES.UPDATE_CREDIT_CARD,
  (data: CreditCard) => CartService.updateCreditCard(data)
);

export const deleteCreditCard = createAsyncThunk(
  TYPES.DELETE_CREDIT_CARD,
  (paymentMethodId: string) => CartService.deleteCreditCard(paymentMethodId)
);

export const addLisenceFromGuest = createAction(TYPES.ADD_LICENSES_FROM_GUEST);

export const addListCartFromGuest = createAction(TYPES.ADD_CARTS_FROM_GUEST);

export const getListTrackOfCart = createAsyncThunk(
  TYPES.GET_LIST_TRACK_OF_CART,
  (trackIds: string[]) => CartService.getTrackCart({ trackIds })
);

export const getListSoundkitOfCart = createAsyncThunk(
  TYPES.GET_LIST_SOUNDKIT_OF_CART,
  (soundkitIds: string[]) => CartService.getSoundkitCart({ soundkitIds })
);
