import { Box, Flex, Text } from "@chakra-ui/react";
import ErrorMessage from "app/components/ErrorMessage";
import ModalShowCollaborator from "app/components/ModalShowCollaborator";
import { Collaborator } from "app/models";
import { useToggle } from "hooks/useToggle";
import React, { useCallback, useMemo, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { convertNewCollaborators } from "utils/convertCollaborators";
import AddCollaborationsItem from "./collaborationsItem";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import ToastPlanSubscription from "../toast/toastplan";

interface CollaboratorsProps {
  isAddSoundkit?: boolean;
}

export default function Collaborators({ isAddSoundkit }: CollaboratorsProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const [showModalCollaborator, setModalCollaborator] = useToggle();
  const collaborators = watch("collaborators");
  const contractType = watch("contractType");
  const licenseWatch = watch("licenses");
  const [selectTypeContract, setSelectTypeContract] = useState(false);
  const [selectLicense, setSelectLicense] = useState(false);
  const [selectedCollaEdit, setselectedCollaEdit] = useState({});
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  const handleDeleteCollaborators = useCallback(
    (userId) => {
      return collaborators.filter((item: Collaborator) => item.user !== userId);
    },
    [collaborators]
  );

  const newCollaborators = convertNewCollaborators(
    collaborators,
    isAddSoundkit
  );

  const handleSelectLicenseContract = useCallback(() => {
    setModalCollaborator();
    setSelectTypeContract(false);
    setSelectLicense(false);
  }, [setModalCollaborator]);

  const handleShowModal = useCallback(() => {
    if (isAddSoundkit) {
      handleSelectLicenseContract();
    } else if (!contractType || (licenseWatch || [])?.length <= 0) {
      setSelectTypeContract(true);
      (licenseWatch || [])?.length <= 0 && setSelectLicense(true);
    } else {
      if (checkPlanSubscription) {
        ToastPlanSubscription({
          title: t("titleUpgradeYourPlanAddCollaborator"),
          description: t("desUpgradeYourPlanAddCollaborator"),
        });
      } else {
        handleSelectLicenseContract();
      }
    }
  }, [
    checkPlanSubscription,
    contractType,
    handleSelectLicenseContract,
    isAddSoundkit,
    licenseWatch,
    t,
  ]);

  const onSetSelectedCollaborator = useCallback(
    (item) => {
      setselectedCollaEdit(item);
      setModalCollaborator();
    },
    [setModalCollaborator]
  );

  const onClose = useCallback(() => {
    setselectedCollaEdit({});
    setModalCollaborator();
  }, [setModalCollaborator]);

  return (
    <>
      <Controller
        name={"collaborators"}
        render={({ field }) => {
          const { value, onChange } = field;

          return (
            <>
              <Box>
                <Text
                  fontSize="20px"
                  lineHeight="24px"
                  mt="30px"
                  mb="25px"
                  fontFamily="Cera Pro Medium"
                >
                  {t("collaborators")}
                </Text>
                <Flex mb="5px" fontSize={{ base: "14px", md: "16px" }}>
                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    w={isAddSoundkit ? "30%" : "25%"}
                    color="#535353"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("user")}
                  </Text>
                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    w={isAddSoundkit ? "30%" : "25%"}
                    color="#535353"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("role")}
                  </Text>
                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    w={isAddSoundkit ? "25%" : "20%"}
                    color="#535353"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("profitShare")}
                  </Text>
                  {!isAddSoundkit && (
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      w="20%"
                      color="#535353"
                      fontFamily="Cera Pro Regular"
                    >
                      {t("publishing")}
                    </Text>
                  )}
                  <Text
                    textAlign="end"
                    fontSize={{ base: "14px", md: "16px" }}
                    w={isAddSoundkit ? "15%" : "10%"}
                    color="#535353"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("action")}
                  </Text>
                </Flex>
                {newCollaborators.map(
                  (collaborator: Collaborator, index: number) => {
                    const {
                      avatar,
                      name,
                      role,
                      profitShare,
                      publishing,
                      user,
                    } = collaborator;
                    const handleDelete = () => {
                      const filterCollaborators =
                        handleDeleteCollaborators(user);
                      onChange({
                        target: {
                          value: filterCollaborators,
                        },
                      });
                    };

                    return (
                      <AddCollaborationsItem
                        key={index}
                        avatar={avatar}
                        name={name}
                        role={role}
                        profitShare={profitShare}
                        publishing={publishing}
                        user={user as string}
                        isAddSoundkit={isAddSoundkit}
                        handleDeleteCollaborators={handleDelete}
                        onSetSelectedCollaborator={onSetSelectedCollaborator}
                      />
                    );
                  }
                )}
              </Box>
              {selectTypeContract && !contractType && (
                <Text color="red">{t("selectTypeBeforeCollabotations")}</Text>
              )}
              {selectLicense && licenseWatch?.length <= 0 && (
                <Text color="red">
                  {t("selectLicenseBeforeCollabotations")}
                </Text>
              )}
              <Text
                mt="35px"
                color="#D8FD01"
                onClick={handleShowModal}
                cursor="pointer"
                fontFamily="Cera Pro Regular"
              >
                {t("addACollaborator")} +
              </Text>

              <Box
                backgroundColor="#25252A"
                p="25px 19px"
                mt="30px"
                borderRadius="4px"
                display={{ base: "block", md: "none" }}
              >
                <Box flexWrap="wrap" display="flex">
                  <Text fontFamily="Cera Pro Regular">
                    {t("beforeYouAddCollaborators")}
                  </Text>
                  <Text
                    fontSize="14px"
                    mt="20px"
                    color="#66666A"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("youMustBe")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#66666A"
                    mt="20px"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("makeSure")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#66666A"
                    mt="20px"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("yourCollaborator")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#66666A"
                    mt="20px"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("needToHave")}
                  </Text>
                  <Text
                    fontSize="14px"
                    color="#66666A"
                    mt="20px"
                    fontFamily="Cera Pro Regular"
                  >
                    {t("youCanOnly")}
                  </Text>
                </Box>
              </Box>

              <ModalShowCollaborator
                isOpen={showModalCollaborator}
                newCollaborators={newCollaborators}
                onClose={onClose}
                value={value}
                onChange={onChange}
                isAddSoudkit={isAddSoundkit}
                selectedCollaEdit={selectedCollaEdit}
              />
            </>
          );
        }}
        control={control}
      />
      <ErrorMessage error={errors.collaborators?.message} />
    </>
  );
}
