import { createAction } from "@reduxjs/toolkit";

export const TYPES = {
  SET_PLAYING_TRACK: "SET_PLAYING_TRACK",
  SET_IS_PLAYING: "SET_IS_PLAYING",
  SET_PLAYING_TRACK_ID: "SET_PLAYING_TRACK_ID",
  SET_IS_AUDIO_PLAY: "SET_IS_AUDIO_PLAY",
  SET_IS_LOADING_PLAY: "SET_IS_LOADING_PLAY",
  SET_SHOW_IMAGE_BOTTOM: "SET_SHOW_IMAGE_BOTTOM",
  SET_SHOW_FOOTERLAYER: "SET_SHOW_FOOTERLAYER",
  SET_DURATION_UNTAG_TRACK: "SET_DURATION_UNTAG_TRACK",
  SET_DURATION_TAG_TRACK: "SET_DURATION_TAG_TRACK",
};

export const setPlayingTrack = createAction(
  TYPES.SET_PLAYING_TRACK,
  (track) => {
    return {
      payload: track,
    };
  }
);

export const setIsAudioPlay = createAction(
  TYPES.SET_IS_AUDIO_PLAY,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setIsPlaying = createAction(TYPES.SET_IS_PLAYING, (payload) => {
  return {
    payload,
  };
});

export const setPlayingTrackId = createAction(
  TYPES.SET_PLAYING_TRACK_ID,
  (trackId) => {
    return {
      payload: trackId,
    };
  }
);

export const setIsLoadingPlay = createAction(
  TYPES.SET_IS_LOADING_PLAY,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setShowFooterPlayer = createAction(
  TYPES.SET_SHOW_FOOTERLAYER,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setDurationUnTagTracks = createAction(
  TYPES.SET_DURATION_UNTAG_TRACK,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setDurationTagTracks = createAction(
  TYPES.SET_DURATION_TAG_TRACK,
  (payload) => {
    return {
      payload,
    };
  }
);
