import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
  CloseButton,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import Avatar from "react-avatar-edit";
import { useTranslation } from "react-i18next";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { v4 as uuidv4 } from "uuid";
import ChooseImage from "app/assets/images/ChooseImage.png";
interface ModalEditAvatarCropProps {
  showModal: boolean;
  setShowModal: () => void;
  previewAvatar: string | null;
  setPreviewAvatar: (image: string | null) => void;
  handleUpload: (file: File) => void;
}

export default function ModalEditAvatarCrop({
  showModal,
  setShowModal,
  previewAvatar,
  setPreviewAvatar,
  handleUpload,
}: ModalEditAvatarCropProps) {
  const { t } = useTranslation();
  const widthAvatar = useBreakpointValue({ base: 300, md: 450 }) || 450;
  const handleCrop = useCallback(
    (view) => {
      setPreviewAvatar(view);
    },
    [setPreviewAvatar]
  );
  const handleClose = useCallback(() => {
    setPreviewAvatar(null);
  }, [setPreviewAvatar]);

  const handleCloseModal = useCallback(() => {
    setShowModal();
    setPreviewAvatar(null);
  }, [setPreviewAvatar, setShowModal]);
  const labelInput = useMemo(() => {
    return (
      <Box
        w="100%"
        height="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Text
          color="white"
          lineHeight="24px"
          fontSize="24px"
          fontWeight="500"
          mb="5px"
          fontFamily="Cera Pro Medium"
        >
          {t("chooseFileImage")}
        </Text>
        <Box>
          <Image src={ChooseImage} alt="chooseImage" />
        </Box>
      </Box>
    );
  }, [t]);

  const handleConvertFile = useCallback(
    (base64, filename) => {
      var arr = base64.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const fileImage = new File([u8arr], filename, { type: mime });
      handleUpload(fileImage);
      handleCloseModal();
    },
    [handleCloseModal, handleUpload]
  );

  return (
    <Modal isOpen={showModal} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <Box>
        <ModalContent
          minW={{ base: "unset", md: "700px" }}
          maxH="650px"
          mx={{ base: "10px", md: "0px" }}
          overflow="auto"
        >
          <ModalHeader
            fontSize={{ base: "20px", md: "28px", xl: "32px" }}
            fontFamily="Cera Pro Medium"
            pt={{ base: "30px", md: "16px" }}
            position="sticky"
            top="0px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            zIndex={10}
            backdropFilter="blur(10px)"
          >
            {t("updatePictureProfile")}
            <CloseButton
              _focus={{
                borderWidth: "0px",
              }}
              onClick={handleCloseModal}
              right={{ base: "20px", md: "12px" }}
              top={{ base: "28px", md: "25px" }}
              fontSize={{ base: "18px", md: "24px", xl: "28px" }}
              borderColor="#38383D"
              _hover={{
                border: "1px solid #38383D !important",
                padding: "18px",
              }}
              padding={{ base: "8px", md: "12px", xl: "18px" }}
              color="#d4f70e"
            />
          </ModalHeader>
          <ModalBody
            padding={{
              base: "20px 16px 0px 16px",
              md: "36px 20px",
              xl: "50px 20px",
            }}
          >
            <Flex
              gridGap={{ base: "40px", md: "20px" }}
              alignItems="center"
              justifyContent="space-between"
              direction={{ base: "column", md: "row" }}
            >
              <Flex
                w={{ base: "100%", md: "70%" }}
                pl={{ base: "0px", md: "10px" }}
                justifyContent="center"
              >
                <Avatar
                  backgroundColor="#24252A"
                  width={widthAvatar}
                  height={300}
                  onCrop={handleCrop}
                  onClose={handleClose}
                  src={undefined}
                  label={labelInput}
                  labelStyle={{
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                  borderStyle={{
                    border: "none",
                    background: "#24252A",
                    borderRadius: "8px",
                  }}
                />
              </Flex>

              <Box
                w={{ base: "100%", md: "30%" }}
                pb="20px"
                height={{ base: "fit-content", md: "300px" }}
                display="flex"
                flexDirection="column"
                alignItems="center"
                position="relative"
              >
                {!previewAvatar ? (
                  <Image fallbackSrc={AvatarUser} />
                ) : (
                  <img src={previewAvatar} alt="Preview" />
                )}
                <Text
                  mt="5px"
                  fontSize={{ base: "16px", md: "18px" }}
                  lineHeight="24px"
                  fontFamily="Cera Pro Medium"
                >
                  {t("pictureProfile")}
                </Text>
                <Text
                  mt="2px"
                  fontSize={{ base: "22px", md: "24px" }}
                  lineHeight="24px"
                  fontWeight="500"
                  fontFamily="Cera Pro Medium"
                >
                  {t("preview")}
                </Text>
                <Button
                  background="#34391A"
                  color="#D4F70E"
                  border="1px solid #D4F70E"
                  _hover={{
                    background: "#34391A",
                  }}
                  borderRadius="4px"
                  p="22px 20px"
                  mt={{ base: "50px", md: "30px" }}
                  position={{ base: "static", md: "absolute" }}
                  bottom="0px"
                  onClick={() => handleConvertFile(previewAvatar, uuidv4())}
                  fontFamily="Cera Pro Medium"
                  fontSize={{ base: "14px", md: "16px", xl: "18px" }}
                >
                  {t("saveChanges")}
                </Button>
              </Box>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Box>
    </Modal>
  );
}
