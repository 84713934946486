import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonRecentActions() {
  return (
    <>
      {Array.from({ length: 10 }).map((item, index) => (
        <>
          <Flex
            justifyContent="space-between"
            alignItems="center"
            mb="10px"
            mt="20px"
            key={index}
          >
            <Flex alignItems="center">
              <Skeleton w="35px" h="35px" borderRadius="full" />
              <Flex gap="5px">
                <Skeleton w="40px" h={{ base: "14px", md: "17px" }} />
                <Skeleton
                  w={{ base: "50px" }}
                  h={{ base: "14px", md: "17px" }}
                />
                <Skeleton
                  w={{ base: "50px" }}
                  h={{ base: "14px", md: "17px" }}
                />
              </Flex>
            </Flex>
            <Skeleton
              w={{ base: "64px", md: "80px" }}
              h={{ base: "14px", md: "17px" }}
            />
          </Flex>
          <Flex
            borderRadius="8px 8px 0px 0px"
            background="#24252B"
            w="100%"
            padding={{ base: "10px", md: "20px" }}
            alignItems={{ base: "unset", md: "center" }}
            overflow="hidden"
            gap={{ md: "20px", xl: "25px" }}
          >
            <Flex justifyContent={{ base: "center", md: "start" }}>
              <Image
                w={{ base: "82px", md: "180px" }}
                h={{ base: "82px", md: "180px" }}
                fallbackSrc={ImageDefault}
              />
            </Flex>

            <Box
              mt={"0px"}
              w={{ base: "80%", md: "68%", lg: "80%", xl: "80%" }}
              ml={{ base: "15px", md: "0px" }}
            >
              <Flex
                mb="8px"
                justifyContent="space-between"
                alignItems="center"
                position="relative"
              >
                <Skeleton
                  w={{ base: "30%" }}
                  h={{ base: "16px", lg: "18px", xl: "23px" }}
                />
              </Flex>
              <Flex gap="5px" mb={{ base: "0px", md: "15px" }}>
                <Skeleton w="40px" />
                <Skeleton w={{ base: "50px" }} />
                <Skeleton w={{ base: "50px" }} />
              </Flex>
              <Box
                display={"flex"}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>
                  <Skeleton
                    w={{ base: "150px", md: "280px", xl: "420px" }}
                    h={{ base: "16px", lg: "18px", xl: "23px" }}
                    mb="15px"
                  />
                  <Skeleton
                    w={{ base: "150px", md: "280px", xl: "420px" }}
                    h={{ base: "16px", lg: "18px", xl: "23px" }}
                    mb="15px"
                    display={{ base: "none", md: "block" }}
                  />
                </Box>
                <Box position="relative">
                  <Skeleton w="70px" h="32px" borderRadius="30px" />
                  <Skeleton
                    position="absolute"
                    w="26px"
                    h="14px"
                    right="32px"
                    top="9px"
                    display={{ base: "block", md: "block" }}
                  />
                  <Skeleton
                    position="absolute"
                    w="20px"
                    h="20px"
                    borderRadius="full"
                    right="8px"
                    top="6px"
                    display={{ base: "block", md: "block" }}
                  />
                </Box>
              </Box>
            </Box>
          </Flex>
          <Flex
            padding="20px 25px"
            background="#1F2026"
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex gap="20px">
              <Flex>
                <Skeleton w="24px" borderRadius="10px" />
                <Skeleton w="16px" borderRadius="4px" />
              </Flex>
              <Flex>
                <Skeleton w="24px" borderRadius="10px" />
                <Skeleton w="16px" borderRadius="4px" />
              </Flex>
            </Flex>
            <Flex gap="5px">
              <Skeleton w="24px" borderRadius="10px" />
            </Flex>
          </Flex>
        </>
      ))}
    </>
  );
}
