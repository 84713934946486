import { Box } from "@chakra-ui/react";
import SildeImage1 from "app/assets/images/slide1.png";
import SildeImage2 from "app/assets/images/slide2.png";
import SildeImage3 from "app/assets/images/slide3.png";
import SildeImage4 from "app/assets/images/slide4.png";

import React from "react";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";
import { Carousel } from "app/components/Carousel";

function SlideBanner() {
  const slides = [
    {
      key: uuidv4(),
      content: <img alt="" src={SildeImage1} />,
    },
    {
      key: uuidv4(),
      content: <img alt="" src={SildeImage2} />,
    },
    {
      key: uuidv4(),
      content: <img alt="" src={SildeImage3} />,
    },
    {
      key: uuidv4(),
      content: <img alt="" src={SildeImage4} />,
    },
  ];

  return (
    <Box className="slide-banner">
      <Carousel
        slidesDefault={slides.map((s) => s.content)}
        arrowBorders={false}
      />
    </Box>
  );
}

export default React.memo(SlideBanner);
