import { yupResolver } from "@hookform/resolvers/yup";
import {
  checkUploadTrack,
  prepareTrackCollaborator,
  submitTrack,
} from "app/redux/actions/tracks";
import { NewTrackState } from "app/redux/reducers/track";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import {
  isUploadTrackSelector,
  lyricsSelector,
  selectNewTrack,
} from "app/redux/selectors/tracks";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useToggle } from "hooks/useToggle";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { normalizeDraftData } from "utils/normalizeData";
import formSchema from "./formSchema";
import { slugTrackSoundkit } from "utils/convertTracks";
import dayjs from "dayjs";
import { selectAudio } from "app/redux/selectors/player";

export const useAddTrack = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSuccessModal, toggleShowSuccessModal] = useToggle();
  const [showModal, setShowModal] = useState(false);
  const [collaborationModal, setCollaborationModal] = useToggle();
  const [addTrackDraft, setAddTrackDraft] = useState(false);
  const [addTrackPrivate, setAddTrackPrivate] = useState(false);
  const [addTrackScheduled, setAddTrackScheduled] = useState(false);
  const [releaseTrackDraft, setReleaseTrackDraft] = useState(false);
  const [showModalCollaborationInvite, setShowModalCollaborationInvite] =
    useToggle(false);
  const { timeTagDurationTrack, timeUntagDurationTrack } =
    useSelector(selectAudio);

  const lyricsData = useSelector(lyricsSelector);
  const initialValues: NewTrackState = useSelector(selectNewTrack);

  const currentUser = useSelector(selectUserAdmin);
  const isUploadTrack = useSelector(isUploadTrackSelector);

  const isLoadingCheckUploadTrack = useSelector((state: RootState) =>
    isLoadingSelector([checkUploadTrack.typePrefix], state)
  );
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([submitTrack.typePrefix], state)
  );
  const isSuccess = useSelector((state: RootState) =>
    successSelector([submitTrack.typePrefix], state)
  );

  const initialValueTrack = useMemo(() => {
    return {
      ...initialValues,
      releaseDate: dayjs().format("YYYY-MM-DDTHH:mm"),
    };
  }, [initialValues]);

  const methods = useForm<NewTrackState>({
    resolver: yupResolver(formSchema()),
    defaultValues: initialValueTrack,
  });

  const { watch } = methods;
  const addTrackWatch = watch();

  const {
    title,
    contractType,
    type,
    mainGenre,
    subGenre,
    key,
    bpm,
    primaryMood,
    tags,
    licenses,
    collaborators,
    private: privateTrack,
    releaseDate,
  } = addTrackWatch;

  const isDataEditTrack = methods.getValues();

  useEffect(() => {
    if (isSuccess && showModal) {
      toggleShowSuccessModal();
    }
  }, [isSuccess, showModal, toggleShowSuccessModal]);

  useEffect(() => {
    if (isSuccess && collaborationModal) {
      setShowModalCollaborationInvite();
    }
  }, [isSuccess, setShowModalCollaborationInvite, collaborationModal]);

  const descriptionOptional = useCallback(
    (values) => {
      return !!values.description
        ? values.description
        : t("descriptionAddTrack");
    },
    [t]
  );
  const compareArrays = useCallback((arrA, arrB) => {
    if (arrA.length !== arrB.length) {
      return false;
    }
    // Tạo một đối tượng để lưu trữ dữ liệu user từ mảng B
    const userDataB = {};
    for (const item of arrB) {
      userDataB[item.user] = {
        profitShare: item.profitShare,
        publishing: item.publishing,
        role: item.role,
      };
    }

    for (const item of arrA) {
      const userDataA = userDataB[item.user];
      if (
        !userDataA ||
        userDataA.profitShare !== item.profitShare ||
        userDataA.publishing !== item.publishing ||
        userDataA.role !== item.role
      ) {
        return false;
      }
    }

    return true;
  }, []);

  const onPublishTrack = (values: NewTrackState) => {
    const checkChangeColla = compareArrays(
      initialValueTrack.collaborators,
      values?.collaborators
    );
    if (isDataEditTrack?._id) {
      dispatch(
        submitTrack({
          ...values,
          description: descriptionOptional(values),
          isEdit: true,
          idLyrics: lyricsData?._id,
          isDraft: false,
          timeTagDuration: timeTagDurationTrack
            ? timeTagDurationTrack
            : values?.timeTagDuration,
          timeUntagDuration: timeUntagDurationTrack
            ? timeUntagDurationTrack
            : values?.timeUntagDuration,
        })
      );
      setShowModal(true);
      privateTrack && setAddTrackPrivate(true);
      if (!checkChangeColla) {
        setCollaborationModal();
      }
      dayjs().add(0.5, "h").isBefore(values?.releaseDate) &&
        setAddTrackScheduled(true);
      return;
    }
    dispatch(
      submitTrack({
        ...values,
        description: descriptionOptional(values),
        slug: slugTrackSoundkit(title),
        timeTagDuration: timeTagDurationTrack
          ? timeTagDurationTrack
          : values?.timeTagDuration,
        timeUntagDuration: timeUntagDurationTrack
          ? timeUntagDurationTrack
          : values?.timeUntagDuration,
      })
    );
    if (!checkChangeColla) {
      setCollaborationModal();
    } else {
      setShowModal(true);
      privateTrack && setAddTrackPrivate(true);
      dayjs().add(0.5, "h").isBefore(releaseDate) && setAddTrackScheduled(true);
    }
  };

  const tabsMenu = useMemo(
    () => [
      {
        name: t("generalInfo"),
        id: 0,
        isCheck: !!title && !!contractType && !!type,
      },
      {
        name: t("licensesAvailability"),
        id: 3,
        isCheck: licenses?.length >= 1,
      },
      {
        name: t("description"),
        id: 1,
        isCheck:
          !!title && !!contractType && !!type && licenses?.length >= 1 && true,
      },
      {
        name: t("metadata"),
        id: 2,
        isCheck:
          !!mainGenre &&
          !!subGenre &&
          !!key &&
          !!bpm &&
          !!primaryMood &&
          (tags || []).length >= 3,
      },
      { name: t("collaborators"), id: 4, isCheck: collaborators?.length > 1 },
    ],
    [
      t,
      title,
      contractType,
      type,
      mainGenre,
      subGenre,
      key,
      bpm,
      primaryMood,
      tags,
      licenses,
      collaborators,
    ]
  );

  const onSubmitDraft = (type?: string) => {
    const track = normalizeDraftData(addTrackWatch);
    if (type === "edit") {
      dispatch(
        submitTrack({
          ...track,
          releaseDate: new Date(track.releaseDate),
          slug: slugTrackSoundkit(title),
          description: descriptionOptional(track),
          isDraft: true,
          isEdit: true,
          timeTagDuration: timeTagDurationTrack
            ? timeTagDurationTrack
            : track?.timeTagDuration,
          timeUntagDuration: timeUntagDurationTrack
            ? timeUntagDurationTrack
            : track?.timeUntagDuration,
        })
      );
      setShowModal(true);
      return;
    } else if (addTrackWatch?._id) {
      setReleaseTrackDraft(true);
      dispatch(
        submitTrack({
          ...track,
          releaseDate: new Date(track.releaseDate),
          description: descriptionOptional(track),
          slug: slugTrackSoundkit(title),
          isDraft: true,
          timeTagDuration: timeTagDurationTrack
            ? timeTagDurationTrack
            : track?.timeTagDuration,
          timeUntagDuration: timeUntagDurationTrack
            ? timeUntagDurationTrack
            : track?.timeUntagDuration,
        })
      );
      setShowModal(true);
      if (track.collaborators.length > 1) {
        setCollaborationModal();
      }
      privateTrack && setAddTrackPrivate(true);
      dayjs().add(0.5, "h").isBefore(releaseDate) && setAddTrackScheduled(true);
      return;
    }
    dispatch(
      submitTrack({
        ...track,
        releaseDate: new Date(track.releaseDate),
        description: descriptionOptional(track),
        slug: slugTrackSoundkit(title),
        isDraft: true,
        timeTagDuration: timeTagDurationTrack,
        timeUntagDuration: timeUntagDurationTrack,
      })
    );
    setShowModal(true);
  };

  useEffect(() => {
    if (!!isDataEditTrack?._id) {
      if (initialValues?.licenses?.[0]?.exclusive) {
        dispatch(
          prepareTrackCollaborator({
            ...currentUser,
            publishing:
              initialValues?.licenses?.[0]?.beatsHookPublishing ||
              initialValues?.licenses?.[0]?.beatsPublishing ||
              initialValues?.licenses?.[0]?.topLinesAndVocals,
          })
        );
      } else {
        dispatch(prepareTrackCollaborator(currentUser));
      }
    } else {
      dispatch(prepareTrackCollaborator(currentUser));
    }
  }, [
    currentUser,
    dispatch,
    initialValues?.licenses,
    isDataEditTrack?._id,
    isSuccess,
  ]);

  useEffect(() => {
    methods.reset(initialValueTrack);
  }, [initialValueTrack, isSuccess, methods]);

  return {
    showModalCollaborationInvite,
    methods,
    showSuccessModal,
    isLoading,
    tabsMenu,
    onPublishTrack,
    onSubmitDraft,
    toggleShowSuccessModal,
    setShowModal,
    setShowModalCollaborationInvite,
    isUploadTrack,
    isLoadingCheckUploadTrack,
    addTrackWatch,
    initialValues,
    setAddTrackDraft,
    addTrackDraft,
    releaseTrackDraft,
    addTrackPrivate,
    addTrackScheduled,
    setAddTrackScheduled,
  };
};
