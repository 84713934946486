import { Text, WrapItem, Divider } from "@chakra-ui/react";
import React, { useMemo, useCallback } from "react";
import { useHistory } from "react-router-dom";

const TagItem = ({
  tag,
  index,
}: {
  tag: string;
  index: number;
}): JSX.Element => {
  const history = useHistory();
  const tagText = useMemo(
    () => (tag.charAt(0) === "#" ? tag : `#${tag}`),
    [tag]
  );
  const redirectTagPage = useCallback(
    (item) => {
      history.push(`/search?type=tags&q=${item}`);
    },
    [history]
  );

  return (
    <WrapItem
      // background="rgba(216, 253, 1, 0.1)"
      // border="0.5px solid rgba(212, 247, 14, 0.1)"
      // borderRadius="40px"
      px="3px"
      cursor="pointer"
      onClick={() => redirectTagPage(tagText)}
    >
      <Text fontSize="12px" lineHeight="15px" color="#D4F70E">
        {tagText}
      </Text>
      {index !== 2 && (
        <Divider ml="5px" borderColor="#D4F70E" orientation="vertical" />
      )}
    </WrapItem>
  );
};

export default React.memo(TagItem);
