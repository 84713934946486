import { getUserByUsername } from "app/redux/actions/users";
import {
  selectIsSuccess,
  selectLoadingUserRecentActions,
  selectLoggedIn,
  selectUserById,
} from "app/redux/selectors/users";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useUser = () => {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const userById = useSelector(selectUserById);
  const isLoading = useSelector(selectLoadingUserRecentActions);
  const isError = useSelector(selectIsSuccess);

  const onGetUserByUsername = useCallback(
    (username) => {
      dispatch(getUserByUsername(username, loggedIn ? false : true));
    },
    [dispatch, loggedIn]
  );

  return {
    onGetUserByUsername,
    userById,
    isLoading,
    isError,
  };
};
