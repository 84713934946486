import { Box, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";

export default function SkeletonSubscription() {
  const listSkeleton = [
    {
      id: 0,
      width: "150px",
      height: "20px",
    },
    {
      id: 1,
      width: "100px",
      height: "20px",
      marginBottom: "25px",
    },
    {
      id: 2,
      width: "100%",
      height: "20px",
      marginBottom: "30px",
    },
    {
      id: 3,
      width: "100%",
      height: "20px",
      marginBottom: "40px",
    },
  ];
  const renderItemSubscription = () => {
    return (
      <Box
        h="600px"
        background="rgba(32, 33, 38, 0.8)"
        padding={{ base: "20px", xl: "50px 50px" }}
        borderRadius="10px"
      >
        {listSkeleton.map((item) => (
          <Skeleton
            key={item.id}
            w={item.width}
            h={item.height}
            mb={item.marginBottom}
            background="#535353"
            borderRadius="10px"
          />
        ))}

        {Array.from({ length: 10 }).map(() => (
          <Skeleton
            mb="15px"
            w="100%"
            h="20px"
            background="#535353"
            borderRadius="10px"
          />
        ))}
      </Box>
    );
  };

  return (
    <Box mt="50px">
      <Box
        display="flex"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Skeleton
          mb="10px"
          w="200px"
          h="20px"
          background="#535353"
          borderRadius="10px"
        />
        <Skeleton
          mb="20px"
          w="350px"
          h="30px"
          background="#535353"
          borderRadius="10px"
        />
      </Box>
      <SimpleGrid
        padding={{ base: "10px 20px", xl: "10px 80px" }}
        columns={{ base: 1, md: 3 }}
        spacing={5}
      >
        {renderItemSubscription()}
        {renderItemSubscription()}
        {renderItemSubscription()}
      </SimpleGrid>
    </Box>
  );
}
