import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class ReferralsService {
  static async getGeneralInfoReferrals() {
    try {
      const { data } = await axiosService.get("/referrals/general/info");
      return data.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      return errorMessage(e);
    }
  }

  static async setTotalReferred() {
    try {
      const { data } = await axiosService.get("/referrals/total-referred");
      return data.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      return errorMessage(e);
    }
  }

  static async getRecentReferrals() {
    try {
      const { data } = await axiosService.get("/referrals");
      return data.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      return errorMessage(e);
    }
  }
}
export default ReferralsService;
