import React from "react";

interface IconRepeatFeedMobileProps {
  color?: string;
}

const IconRepeatFeedMobile = ({
  color = "#D4F70E",
}: IconRepeatFeedMobileProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 22L3 18L7 14V17H17V13H19V18C19 18.5523 18.5523 19 18 19H7V22ZM7 11H5V6C5 5.44772 5.44772 5 6 5H17V2L21 6L17 10V7H7V11Z"
        fill={color}
      />
    </svg>
  );
};
export default IconRepeatFeedMobile;
