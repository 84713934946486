export const soundKitTypes = [
  { label: "all", value: "ALL" },
  {
    label: "oneShot",
    value: "ONE_SHORT",
  },
  { label: "drumLoops", value: "DRUM_LOOPS" },
  { label: "melodicLoops", value: "MELODIC_LOOPS" },
  { label: "effects", value: "EFFECTS" },
  { label: "vocals", value: "VOCALS" },
  { label: "presets", value: "PRESETS" },
  { label: "midiPack", value: "MIDI_PACK" },
];
