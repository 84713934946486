import { Box, Checkbox, Flex, Text } from "@chakra-ui/react";
import { InputItem } from "app/components/InputItem";
import React, { useMemo, useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import countryList from "react-select-country-list";
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import "./styles.scss";
import DeviceDetector from "device-detector-js";
import { typeDivice } from "utils/checkScreen";
import { useHistory } from "react-router-dom";

interface StepOneSignupProps {
  setIsChecked: (value: boolean) => void;
  select: string;
  onSelect: (value: any) => void;
}
export default function StepOneSignup({
  setIsChecked,
  onSelect,
}: StepOneSignupProps) {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  const history = useHistory();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);

  const redirectPage = useCallback(
    (page) => {
      history.push(`/${page}`);
    },
    [history]
  );

  const contryList = options.map((item) => {
    return {
      ...item,
      icon: (
        <ReactCountryFlag
          countryCode={item.value}
          style={{
            fontSize: "30px",
          }}
          svg={device.device?.brand === typeDivice.APPLE ? false : true}
        />
      ),
    };
  });
  const formatOptionLabel = ({ label, icon }: any) => (
    <Flex
      background="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="48px"
      padding="5px 15px"
      margin="0px"
    >
      <Text fontSize="14px" fontWeight="600">
        {label}
      </Text>
      {icon}
    </Flex>
  );

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box className="step-one-signup">
      <Flex mt="15px">
        <Text
          mr="1px"
          color="#FFFFFF"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="600"
        >
          Email
        </Text>
        <Text color="#D4F70E">*</Text>
      </Flex>
      <InputItem
        placeholder={"Enter Email"}
        className="email"
        control={control}
        name="email"
        errors={errors.email}
      />
      <Flex mt="15px">
        <Text
          mr="1px"
          color="#FFFFFF"
          fontSize="14px"
          lineHeight="24px"
          fontWeight="600"
        >
          Password
        </Text>
        <Text color="#D4F70E">*</Text>
      </Flex>
      <InputItem
        placeholder={"Min. 8 character"}
        className="password"
        control={control}
        type="password"
        name="password"
        errors={errors.password}
      />
      <Text
        mt="15px"
        color="#FFFFFF"
        fontSize="14px"
        lineHeight="24px"
        fontWeight="600"
        mb="10px"
      >
        {t("Country")}
      </Text>

      <Select
        className="select-flag"
        classNamePrefix="select"
        options={contryList}
        placeholder={t("searchCountry")}
        formatOptionLabel={formatOptionLabel}
        onChange={onSelect}
      />

      <Checkbox mt="15px" onChange={(e) => setIsChecked(e.target.checked)}>
        <Flex>
          <Text fontSize="14px" mr="3px" lineHeight="24px">
            {t("iGreeToThe")}
          </Text>
          <Text
            fontSize="14px"
            color="#D3F70E"
            lineHeight="24px"
            onClick={() => redirectPage("term-condition")}
          >
            {t("termsConditions")}
          </Text>
        </Flex>
      </Checkbox>
    </Box>
  );
}
