import { Box, Flex, Text } from "@chakra-ui/react";
import Cart from "app/assets/icons/cart";
import Heart from "app/assets/icons/heart";
import React from "react";
import { useTranslation } from "react-i18next";

interface CartIconProps {
  price?: number;
  isDisabled?: boolean;
  onClick?: any;
  isProfile?: boolean;
  active?: boolean;
  isOffer?: boolean;
  isLyricsModal?: boolean;
}

export default function CartIcon({
  price = 0,
  isDisabled,
  onClick,
  isProfile = false,
  active = false,
  isOffer = false,
  isLyricsModal = false,
}: CartIconProps) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      fontSize="14px"
      alignItems="center"
    >
      {isProfile && (
        <Box pl="10px" cursor="pointer">
          <Heart isProfile />
        </Box>
      )}

      <Flex
        bg={active ? "#d4f70e" : "#38383D"}
        borderRadius="34px"
        alignItems="center"
        gap={{ base: "0px", md: "4px" }}
        padding={{
          base: "4px 3px 4px 8px",
          md: "4px 3px 4px 10px",
        }}
        cursor="pointer"
        onClick={onClick}
        w="100%"
        justifyContent="space-between"
      >
        <Text
          textAlign={isLyricsModal ? "end" : "unset"}
          fontSize={{ base: "12px", xl: "14px" }}
          fontWeight="600"
          color={isDisabled ? "#49494D" : active ? "black" : "white"}
          fontFamily="Cera Pro Medium"
          w={isLyricsModal ? "50%" : "max-content"}
        >
          {active ? t("inCart") : t("buy")}
        </Text>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height="25px"
          width="25px"
          borderRadius="50%"
          bg="#24252A"
          ml="5px"
        >
          <Cart />
        </Box>
      </Flex>
    </Box>
  );
}
