import { Box, Flex } from "@chakra-ui/react";
import Radio from "app/components/Radio";
import classnames from "classnames";
import React, { Fragment } from "react";
import "./styles.scss";

interface TabMenuType {
  id: number;
  name: string;
  isCheck?: boolean;
}

interface TabsMenuHeaderProps {
  tabsMenu: TabMenuType[];
}

export default function TabsMenuHeader({ tabsMenu }: TabsMenuHeaderProps) {
  return (
    <Flex overflow="auto" alignItems="center" className="tab-list-panels">
      {tabsMenu.map((item: TabMenuType, index: number) => {
        const { isCheck, name, id } = item;
        return (
          <Fragment key={id}>
            <Flex
              minWidth="104px"
              className={classnames("tab", {
                "active-tab": isCheck,
              })}
              padding={{ base: "10px 10px", lg: "12px 20px", xl: "12px 25px" }}
            >
              <Radio value={name} title={name} isChecked={isCheck} />
            </Flex>
            {index !== tabsMenu.length - 1 && (
              <Box height="24px" w="1px" backgroundColor="#49494D" />
            )}
          </Fragment>
        );
      })}
    </Flex>
  );
}
