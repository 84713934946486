import React from "react";

const BiDotsHorizontal = () => {
  return (
    <svg
      width="22"
      height="10"
      viewBox="0 0 22 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="4" height="4" rx="2" fill="white" />
      <rect x="9" width="4" height="4" rx="2" fill="white" />
      <rect x="18" width="4" height="4" rx="2" fill="white" />
    </svg>
  );
};
export default BiDotsHorizontal;
