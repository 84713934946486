import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

type IDataType = string | { name: string };

interface TagGenreProps {
  data: IDataType[];
  isMyTrack?: boolean;
  isRequiredFiles?: boolean;
}

export default function TagGenreButton({
  data = [],
  isMyTrack = false,
  isRequiredFiles = false,
}: TagGenreProps) {
  const { t } = useTranslation();

  return (
    <Flex justifyContent={isMyTrack ? "start" : "center"} flexWrap="wrap">
      {data.map((item: IDataType, index: number) => (
        <Box
          display="flex"
          alignItems="center"
          key={index}
          borderRadius="4px"
          padding="0px 5px"
          mr="5px"
          mb="5px"
          backgroundColor="#2b2e1b"
        >
          <Text
            fontSize="14px"
            fontWeight="500"
            color={isMyTrack ? "#d4f70e" : "#ffffff"}
          >
            {(item as { name: string })?.name ?? item}
          </Text>
        </Box>
      ))}
      {!data.length && (
        <Box
          display="flex"
          alignItems="center"
          height="20px"
          borderRadius="4px"
          padding="0px 5px"
          mr="5px"
          mb="5px"
          backgroundColor="#2d1b1f"
          whiteSpace="nowrap"
        >
          <Text
            fontSize="12px"
            lineHeight="14px"
            fontWeight="500"
            color={"#ED3636"}
          >
            {isRequiredFiles
              ? t("missingTrackFiles")
              : t("missingTrackLicenses")}
          </Text>
        </Box>
      )}
    </Flex>
  );
}
