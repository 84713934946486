import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import { lastLicensesSelector } from "app/redux/selectors/cart";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { checkTypeTrackOrSoundPack } from "utils/convertTracks";

interface ThankYouTrackProps {
  track: TrackSoundKit;
}

export default function ThankYouItemTrack({ track }: ThankYouTrackProps) {
  const { t } = useTranslation();
  const cartLicenses = useSelector(lastLicensesSelector);
  const currentLicense = cartLicenses?.find(
    (license: any) => license?.trackId === track?._id
  );

  return (
    <Box margin="0px auto">
      <Flex
        borderBottom="1px solid #24252A"
        paddingBottom="15px"
        alignItems="center"
        marginBottom="15px"
        w="100%"
      >
        <Image
          ml="10px"
          src={track?.coverUrl}
          fallbackSrc={ImageDefault}
          w={{ base: "48px", md: "60px" }}
          height={{ base: "48px", md: "60px" }}
          borderRadius="4px"
        />
        <Box ml="15px" overflow="hidden">
          <Text fontSize="16px" noOfLines={1}>
            {track?.title}
          </Text>
          <Flex>
            <Box display="flex" alignItems="center">
              <Text
                backgroundColor="#2b2e1b"
                fontSize="12px"
                color="#d4f70e"
                padding="0px 5px"
                borderRadius="4px"
                mr="10px"
              >
                {track?.type}
              </Text>
            </Box>
            <Text fontSize="14px" color="#464649">
              {track?.createdBy?.name}
            </Text>
          </Flex>
          {checkTypeTrackOrSoundPack(track) && (
            <Text fontSize="14px">
              {currentLicense?.license?.name} {t("license")}
            </Text>
          )}
        </Box>
      </Flex>
    </Box>
  );
}
