import { Box, Button, Image, SimpleGrid, Text } from "@chakra-ui/react";
import PaypalImage from "app/assets/images/PaypalIcon.svg";
import StripeImage from "app/assets/images/Stripe.svg";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { BeatLoader } from "react-spinners";

interface PaymentsProps {
  handleClickPaypal: () => void;
  handleClickStripe: () => void;
  isLoadingPaypal?: boolean;
  isLoadingStripe?: boolean;
}

export default function Payments({
  handleClickPaypal,
  handleClickStripe,
  isLoadingPaypal,
  isLoadingStripe,
}: PaymentsProps) {
  const { t } = useTranslation();

  return (
    <Box mt="35px">
      <Text
        mb={{ base: "20px", md: "40px", lg: "50px" }}
        fontSize={{ base: "24px", md: "32px" }}
      >
        {t("payments")}
      </Text>
      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        spacing={{ base: "20px", xl: "70px" }}
      >
        <Box
          backgroundColor="#25252A"
          p={{ base: "20px", lg: "40px" }}
          borderRadius="8px"
        >
          <Image src={StripeImage} fallbackSrc={StripeImage} mb="30px" />
          <Text
            fontSize={{ base: "16px", md: "18px", lg: "20px" }}
            lineHeight="28px"
            fontWeight="400"
            opacity="0.8"
          >
            {t("connect")}
          </Text>

          <Button
            border="1px solid #D4F70E"
            backgroundColor="rgba(212, 247, 14, 0.20)"
            color="#D4F70E"
            _hover={{ backgroundColor: "none" }}
            _active={{ backgroundColor: "none" }}
            mt="30px"
            borderRadius="4px"
            onClick={handleClickStripe}
            isLoading={isLoadingStripe}
            spinner={<BeatLoader size={8} color="white" />}
            w={{ base: "100%", md: "max-content" }}
          >
            {t("connectStripe")}
          </Button>
        </Box>
        <Box
          backgroundColor="#25252A"
          p={{ base: "20px", lg: "40px" }}
          borderRadius="8px"
        >
          <Image src={PaypalImage} fallbackSrc={PaypalImage} mb="30px" />
          <Text
            fontSize={{ base: "16px", md: "18px", lg: "20px" }}
            lineHeight="28px"
            fontWeight="400"
            opacity="0.8"
          >
            {t("connectpaypal")}
          </Text>

          <Button
            border="1px solid #D4F70E"
            backgroundColor="rgba(212, 247, 14, 0.20)"
            color="#D4F70E"
            _hover={{ backgroundColor: "none" }}
            _active={{ backgroundColor: "none" }}
            mt="30px"
            borderRadius="4px"
            onClick={handleClickPaypal}
            isLoading={isLoadingPaypal}
            spinner={<BeatLoader size={8} color="white" />}
            w={{ base: "100%", md: "max-content" }}
          >
            {t("connectPaypal")}
          </Button>
        </Box>
      </SimpleGrid>
    </Box>
  );
}
