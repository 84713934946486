import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AddSoundkitState } from "app/redux/reducers/addSoundkit";
import AddSoundkitService from "app/apis/addSoundkit";
import { convertNewCollaborators } from "utils/convertCollaborators";

export const TYPES = {
  SUBMIT_SOUNDKIT: "SUBMIT_SOUNDKIT",
  PREPARE_EDIT_SOUNDKIT: "PREPARE_EDIT_SOUNDKIT",
  CLEAR_FORM: "CLEAR_FORM",
  DELETE_SOUNDKIT_RELEASE: "DELETE_SOUNDKIT_RELEASE",
  DELETE_SOUNDKIT_DRAFT: "DELETE_SOUNDKIT_DRAFT",
  CHECK_UPLOAD_SOUNDPACKS: "CHECK_UPLOAD_SOUNDPACKS",
};

export const submitSoundkit = createAsyncThunk(
  TYPES.SUBMIT_SOUNDKIT,
  async (values: AddSoundkitState, thunkApi) => {
    const { isEdit, ...body } = values;

    const newCollaborators = convertNewCollaborators(body?.collaborators);
    const newSoundkit = {
      ...body,
      collaborators: newCollaborators,
    };

    try {
      if (values.isDraft && values._id) {
        await AddSoundkitService.updateSKDraft(newSoundkit);
      } else if (values.isDraft) {
        await AddSoundkitService.createSKDraft(newSoundkit);
      } else if (isEdit) {
        await AddSoundkitService.editSoundKit(newSoundkit);
      } else {
        await AddSoundkitService.submitSoundKit(newSoundkit);
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const clearFormSoundkit = createAction(TYPES.CLEAR_FORM);

export const prepareEditSoundkit = createAction(
  TYPES.PREPARE_EDIT_SOUNDKIT,
  (payload) => {
    return {
      payload,
    };
  }
);

export const deleteSoundkitRelease = createAsyncThunk(
  TYPES.DELETE_SOUNDKIT_RELEASE,
  async (soundkitId: string | undefined) => {
    return await AddSoundkitService.deleteSoundkitRelease(soundkitId);
  }
);

export const deleteSoundkitDraft = createAsyncThunk(
  TYPES.DELETE_SOUNDKIT_DRAFT,
  async (soundkitId: string | undefined) => {
    return await AddSoundkitService.deleteSoundkitDraft(soundkitId);
  }
);

export const checkUploadSoundPacks = createAsyncThunk(
  TYPES.CHECK_UPLOAD_SOUNDPACKS,
  AddSoundkitService.checkUploadSoundPacks
);
