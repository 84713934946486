import {
  getListEmailCustomers,
  getPurchased,
} from "app/redux/actions/purchased";
import { useCallback, useState, ChangeEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectorPurchased } from "app/redux/selectors/purchased";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useDebounce } from "hooks/useDebounce";

export const usePurchased = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");

  const {
    purchasedList,
    fetchTransactionOptions,
    emailCustomers,
    fetchEmailCustomersOptions,
  } = useSelector(selectorPurchased);
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getPurchased.typePrefix], state)
  );
  const isLoadingEmailCustomers = useSelector((state: RootState) =>
    isLoadingSelector([getListEmailCustomers.typePrefix], state)
  );
  const getPurchasedTransaction = useCallback(
    (option) => {
      dispatch(getPurchased(option));
    },
    [dispatch]
  );

  const getEmailCustomers = useCallback(
    (option) => {
      dispatch(getListEmailCustomers(option));
    },
    [dispatch]
  );

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const debouncedSearchText = useDebounce<string>(searchText, 200);

  return {
    getPurchasedTransaction,
    purchasedList,
    fetchTransactionOptions,
    isLoading,
    debouncedSearchText,
    handleSearchText,
    getEmailCustomers,
    emailCustomers,
    fetchEmailCustomersOptions,
    isLoadingEmailCustomers,
  };
};
