import { Flex, Select } from "@chakra-ui/react";
import IconSortByAlgolia from "app/assets/icons/IconSortByAlgolia";
import React, { useState } from "react";
import { useSortBy } from "react-instantsearch";
import "./styles.scss";
import { useTranslation } from "react-i18next";

function CustomSortBy({ sortBy }) {
  const { refine } = useSortBy({
    items: sortBy,
  });
  const { t } = useTranslation();
  const [valueSelect, setValueSelect] = useState(
    t("sortBy").replaceAll(" ", "")
  );

  return (
    <Flex
      mr="17px"
      cursor="pointer"
      display={{ base: "none", xl: "flex" }}
      alignItems="center"
      justifyContent="end"
      className={
        valueSelect === "SortBy" || valueSelect === "Tracks_Search_Stag"
          ? "custom-sortby"
          : "sort-by"
      }
    >
      <Select
        fontSize="16px"
        fontFamily="Cera Pro Medium"
        border="none"
        _focus={{ borderColor: " none" }}
        onChange={(e) => {
          refine(e.target.value);
          setValueSelect(e.target.value);
        }}
        cursor="pointer"
        icon={<IconSortByAlgolia />}
      >
        {sortBy.map((item) => (
          <option
            key={item.label}
            value={item.value}
            className={`${item.label.replaceAll(" ", "")}`}
          >
            {item.label}
          </option>
        ))}
      </Select>
    </Flex>
  );
}

export default React.memo(CustomSortBy);
