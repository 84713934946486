import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { PlayAnalyticsModel } from "app/models";
import { useDispatch, useSelector } from "react-redux";
import { getListAnalyticsTopCountry } from "app/redux/actions/Analytics";
import { analyticsTypeDispatch } from "constants/analyticsType";
import { selectorsAnalyticsTopCountry } from "app/redux/selectors/analytics";
import { isLoadingSelector } from "app/redux/selectors/status";
import { RootState } from "types";
import { FadeLoader } from "react-spinners";
import EmptyAnalytic from "../EmptyAnalytics";
import IconEmptyTopCountry from "app/assets/icons/IconEmptyTopCountry";
import MapBasicComponents from "./mapBasic";
import { totalAnalytics } from "utils/getPrice";
import ReactCountryFlag from "react-country-flag";
import DeviceDetector from "device-detector-js";
import { typeDivice } from "utils/checkScreen";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import UpgradeYourPlan from "../NotFoundPage/upgradeYourPlan";

interface MapComponentProps {
  analyticsType: PlayAnalyticsModel;
  timeDay: string;
}

export default function MapComponent({
  analyticsType,
  timeDay,
}: MapComponentProps) {
  const { t } = useTranslation();
  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const dispatch = useDispatch();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  const countries = require("country-data").countries;

  const analyticsTopCountry = useSelector(selectorsAnalyticsTopCountry);

  const analyticsTypeQuery = analyticsTypeDispatch(analyticsType.type);

  useEffect(() => {
    if (!checkPlanSubscription) {
      dispatch(
        getListAnalyticsTopCountry({
          timeType: timeDay,
          analyticType: analyticsTypeQuery,
        })
      );
    }
  }, [analyticsTypeQuery, checkPlanSubscription, dispatch, timeDay]);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getListAnalyticsTopCountry.typePrefix], state)
  );

  return (
    <>
      <Flex mt="40px" background="#1c1c20" flexWrap="wrap">
        <Flex
          pt="34px"
          borderRight="1px solid rgba(255, 255, 255, 0.08);"
          position="relative"
          w={isLargerThan1280 ? "68%" : "100%"}
          pr="25px"
        >
          <Text
            fontSize="20px"
            fontWeight="600"
            color="#FFFFFF"
            position="absolute"
            mt={{ base: "0px", md: "20px" }}
            left="40px"
          >
            {t("globalCounsumers")}
          </Text>
          <Box w="100%">
            {checkPlanSubscription ? (
              <Box mt="40px">
                <UpgradeYourPlan />
              </Box>
            ) : (
              <MapBasicComponents topCountry={analyticsTopCountry} />
            )}
          </Box>
          {!checkPlanSubscription && (
            <Box
              className="stats-components-map"
              bottom={isLargerThan1280 ? "40px" : "0px"}
            >
              <Box className="play-components-map">
                {analyticsType.icon}
                <Box ml="15px">
                  <Text fontSize="14px" color="#303033">
                    {`${t("total")} ${analyticsType.name}`}
                  </Text>
                  <Text fontSize="24px">
                    {totalAnalytics(analyticsType.price)}
                  </Text>
                </Box>
              </Box>
            </Box>
          )}
        </Flex>
        <Box
          w={isLargerThan1280 ? "32%" : "100%"}
          fontSize="20px"
          fontWeight="600"
          lineHeight="24px"
          pl="38px"
          pr="38px"
          pt="34px"
        >
          <Text mb="24px"> {t("Top10Countries")}</Text>
          {checkPlanSubscription ? (
            <UpgradeYourPlan />
          ) : isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50vh"
            >
              <FadeLoader color={"#d4f70e"} loading={isLoading} />
            </Box>
          ) : (analyticsTopCountry || []).length <= 0 ? (
            <EmptyAnalytic
              title={t("emptyTopCountry")}
              icon={<IconEmptyTopCountry />}
            />
          ) : (
            (analyticsTopCountry || []).map((item: any, index: number) => {
              return (
                <Flex mb="24px" key={index} justifyContent={"space-between"}>
                  {item?._id !== "Not found" && (
                    <>
                      <Flex>
                        <Text mr="25px" fontSize="16px" fontWeight="500">
                          {index + 1}
                        </Text>

                        <ReactCountryFlag
                          countryCode={item?._id}
                          style={{
                            fontSize: "30px",
                          }}
                          svg={
                            device.device?.brand === typeDivice.APPLE
                              ? false
                              : true
                          }
                        />
                        <Text fontSize="16px" fontWeight="500" ml="40px">
                          {countries[item?._id]?.name}
                        </Text>
                      </Flex>

                      <Text fontSize="16px" fontWeight="500" textAlign="end">
                        {item.total}
                      </Text>
                    </>
                  )}
                </Flex>
              );
            })
          )}
        </Box>
      </Flex>
    </>
  );
}
