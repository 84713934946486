import React from "react";

const IconMusicVideo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11.25C2.86193 11.25 2.75 11.3619 2.75 11.5V19.5C2.75 19.6381 2.86193 19.75 3 19.75H16.5C16.6381 19.75 16.75 19.6381 16.75 19.5V11.5C16.75 11.3619 16.6381 11.25 16.5 11.25H3ZM1.25 11.5C1.25 10.5335 2.0335 9.75 3 9.75H16.5C17.4665 9.75 18.25 10.5335 18.25 11.5V19.5C18.25 20.4665 17.4665 21.25 16.5 21.25H3C2.0335 21.25 1.25 20.4665 1.25 19.5V11.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25 13.5C9.25 13.0858 9.58579 12.75 10 12.75H14.5C14.9142 12.75 15.25 13.0858 15.25 13.5V17.5C15.25 17.9142 14.9142 18.25 14.5 18.25H10C9.58579 18.25 9.25 17.9142 9.25 17.5V13.5ZM10.75 14.25V16.75H13.75V14.25H10.75Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 4.75C12.5335 4.75 11.75 5.5335 11.75 6.5C11.75 7.4665 12.5335 8.25 13.5 8.25C14.4665 8.25 15.25 7.4665 15.25 6.5C15.25 5.5335 14.4665 4.75 13.5 4.75ZM10.25 6.5C10.25 4.70507 11.7051 3.25 13.5 3.25C15.2949 3.25 16.75 4.70507 16.75 6.5C16.75 8.29493 15.2949 9.75 13.5 9.75C11.7051 9.75 10.25 8.29493 10.25 6.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 4.75C5.5335 4.75 4.75 5.5335 4.75 6.5C4.75 7.4665 5.5335 8.25 6.5 8.25C7.4665 8.25 8.25 7.4665 8.25 6.5C8.25 5.5335 7.4665 4.75 6.5 4.75ZM3.25 6.5C3.25 4.70507 4.70507 3.25 6.5 3.25C8.29493 3.25 9.75 4.70507 9.75 6.5C9.75 8.29493 8.29493 9.75 6.5 9.75C4.70507 9.75 3.25 8.29493 3.25 6.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4089 10.8711C22.6217 11.0095 22.7502 11.2461 22.7502 11.5V19.5C22.7502 19.7539 22.6217 19.9905 22.4089 20.1289C22.196 20.2672 21.9276 20.2885 21.6956 20.1854L17.1956 18.1854C16.8171 18.0171 16.6466 17.5739 16.8148 17.1954C16.9831 16.8169 17.4263 16.6464 17.8048 16.8146L21.2502 18.3459V12.6541L17.8048 14.1854C17.4263 14.3536 16.9831 14.1831 16.8148 13.8046C16.6466 13.4261 16.8171 12.9829 17.1956 12.8146L21.6956 10.8146C21.9276 10.7115 22.196 10.7328 22.4089 10.8711Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconMusicVideo;
