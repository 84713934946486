import {
  Box,
  Checkbox,
  Flex,
  Text,
  Image,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Button,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import "./styles.scss";
import BackgroundImagePlaylistIcon from "app/assets/icons/BackgroundImagePlaylistIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { useTranslation } from "react-i18next";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { Playlists } from "app/models";
import { visibilityType } from "constants/playlistType";
import { formatTimePlaylist } from "utils/timeAgo";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  prepareEditPlaylist,
  deletePlaylists,
} from "app/redux/actions/playlist";
import { PROFILE_TYPE } from "constants/contractType";
import { BsThreeDots } from "react-icons/bs";

interface PlaylistItemProps {
  playlist: Playlists;
  index: number;
  setShowModalCreatePlaylist: () => void;
  setEditPlaylist: (value: boolean) => void;
  handleCheckBox: (id: string) => void;
  choosePlaylists: string[];
  isFollowing?: boolean;
}

export default function PlaylistItem({
  playlist,
  index,
  setShowModalCreatePlaylist,
  setEditPlaylist,
  handleCheckBox,
  choosePlaylists,
  isFollowing = false,
}: PlaylistItemProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const handleIndividualPlaylist = useCallback(() => {
    history.push(`/playlist/${playlist._id}`);
  }, [history, playlist._id]);

  const handleEditPlaylist = useCallback(() => {
    dispatch(prepareEditPlaylist(playlist));
    setShowModalCreatePlaylist();
    setEditPlaylist(true);
  }, [dispatch, playlist, setEditPlaylist, setShowModalCreatePlaylist]);

  const isChecked = useMemo(() => {
    return !!(choosePlaylists || []).find((item) => item === playlist._id);
  }, [choosePlaylists, playlist._id]);

  const handleDeletePlaylist = useCallback(
    (id) => {
      dispatch(deletePlaylists([id]));
    },
    [dispatch]
  );
  const redirectProfile = useCallback(
    (name) => {
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );

  return (
    <Box
      borderBottom={{ base: "1px solid rgba(255, 255, 255, 0.06)", md: "none" }}
    >
      <Flex
        className="playlist-item"
        padding="2px 0px 15px 0px"
        borderBottom={{ md: "1px solid rgba(255, 255, 255, 0.06)" }}
        marginTop="15px"
        w="100%"
        alignItems="center"
      >
        <Flex alignItems="center" w={{ base: "20%", md: "21%", lg: "16%" }}>
          <Checkbox
            borderRadius="4px"
            _focusVisible={{ outline: "none" }}
            size="lg"
            mr="15px"
            onChange={() => handleCheckBox(playlist._id || "")}
            isChecked={isChecked}
          />
          <Text
            ml={{ base: "0px", md: "15px", xl: "35px" }}
            mr={{ base: "0px", md: "15px", xl: "35px" }}
            fontSize="16px"
            fontWeight="500"
          >
            {index + 1}
          </Text>
          <Box
            display={{ base: "none", md: "flex" }}
            flexDirection="column"
            alignItems="center"
            onClick={handleIndividualPlaylist}
            cursor="pointer"
          >
            <Box>
              <BackgroundImagePlaylistIcon />
            </Box>
            <Image
              src={playlist.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              w="55px"
              height="55px"
              marginTop="-35px"
            />
          </Box>
        </Flex>

        <Box
          w={{ base: "65%", md: "30%", lg: "20%" }}
          display={{ base: "flex", md: "block" }}
          alignItems="center"
          overflow="hidden"
        >
          <Image
            display={{ base: "block", md: "none" }}
            src={playlist.coverUrl}
            fallbackSrc={ImageDefault}
            borderRadius="4px"
            objectFit="cover"
            w={{ base: "35px", md: "55px" }}
            height={{ base: "36px", md: "55px" }}
            mr="10px"
          />
          <Box>
            <Text
              fontWeight="600"
              noOfLines={1}
              _hover={{
                textDecoration: "underline",
              }}
              onClick={handleIndividualPlaylist}
              cursor="pointer"
            >
              {playlist.name}
            </Text>
            <Flex alignItems={"center"}>
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                padding="2px 8px"
                mr="5px"
                backgroundColor="#2b2e1b"
              >
                <Text
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="14px"
                  color="#d4f70e"
                  fontFamily="Cera Pro Regular"
                >
                  {t("playlist")}
                </Text>
              </Box>
              <Text
                noOfLines={1}
                _hover={{
                  textDecoration: "underline",
                }}
                cursor="pointer"
                fontSize="14px"
                color="#535353"
                onClick={() => redirectProfile(playlist?.createdBy?.name)}
              >
                By <span>{playlist?.createdBy?.name}</span>
              </Text>
            </Flex>
          </Box>
        </Box>

        <Flex
          w="10%"
          alignItems="center"
          display={{ base: "none", md: "flex" }}
        >
          <Flex gap="10px">
            <Box
              display="flex"
              alignItems="center"
              borderRadius="4px"
              padding="2px 10px"
              mr="5px"
              backgroundColor="#2b2e1b"
            >
              <Text
                fontSize="12px"
                fontWeight="500"
                lineHeight="14px"
                color="#d4f70e"
                fontFamily="Cera Pro Regular"
              >
                {visibilityType(playlist?.visibility)}
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex
          alignItems="center"
          w="20%"
          display={{ base: "none", lg: "flex" }}
        >
          <Image
            src={playlist?.createdBy?.profileImage}
            fallbackSrc={AvatarUser}
            w="40px"
            height="40px"
            borderRadius="100%"
          />
          <Box ml="5px">
            <Text fontWeight="700">{playlist?.createdBy?.name}</Text>
            <Text color="#535353" fontSize="14px" fontWeight="400">
              Owner
            </Text>
          </Box>
        </Flex>

        <Flex
          w={{ md: "20%", lg: "15%" }}
          gap="20px"
          display={{ base: "none", md: "flex" }}
        >
          <Box>
            <Text fontWeight="900">{playlist?.followers?.length}</Text>
            <Text fontSize="12px" color="#535353" fontWeight="400">
              {t("Followers")}
            </Text>
          </Box>
          <Box>
            <Text fontWeight="900">{playlist.totalTracks}</Text>
            <Text fontSize="12px" color="#535353" fontWeight="400">
              {t("Tracks")}
            </Text>
          </Box>
        </Flex>

        <Box w="15%" display={{ base: "none", md: "flex" }}>
          <Text fontSize="14px" color="#535353">
            {t("datedAdded")}{" "}
            <span>{formatTimePlaylist(playlist.createdAt)}</span>
          </Text>
        </Box>
        {!isFollowing && (
          <Flex w={{ base: "15%", md: "4%" }} justifyContent="flex-end">
            <Popover closeOnBlur={true}>
              <PopoverTrigger>
                <Box
                  cursor="pointer"
                  padding="3px 5px"
                  _hover={{
                    background: "#24252A",
                    padding: "3px 5px",
                    border: "1px solid #38383D",
                    borderRadius: "8px",
                  }}
                >
                  <BsThreeDots size={24} />
                </Box>
              </PopoverTrigger>
              <Box zIndex={120}>
                <PopoverContent
                  backgroundColor="#25252A"
                  border="none"
                  mt="5px"
                  maxW="200px"
                >
                  <PopoverArrow
                    backgroundColor="#25252A "
                    borderColor="#25252A "
                    boxShadow="unset !important"
                  />

                  <PopoverBody>
                    <Flex flexDirection="column">
                      <Button
                        backgroundColor="#2D2E33"
                        mb="10px"
                        _hover={{ background: "#2D2E33" }}
                        onClick={handleEditPlaylist}
                      >
                        {t("Edit")}
                      </Button>

                      <Button
                        _hover={{ background: "#ED3536" }}
                        backgroundColor="#ED3536"
                        onClick={() => handleDeletePlaylist(playlist._id)}
                      >
                        {t("delete")}
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Popover>
          </Flex>
        )}
      </Flex>
      <Flex w="100%" mb="30px" display={{ base: "flex", md: "none" }}>
        <Box w="20%" />
        <Flex w="65%">
          <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            padding="3px 20px"
            mr="5px"
            backgroundColor="#2b2e1b"
            alignSelf="baseline"
          >
            <Text
              fontSize="12px"
              fontWeight="500"
              lineHeight="14px"
              color="#d4f70e"
              fontFamily="Cera Pro Regular"
            >
              {visibilityType(playlist?.visibility)}
            </Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
}
