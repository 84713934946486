import AddTrack from "app/components/AddTrack";
import CartItem from "app/components/CartItem";
import CartConfirm from "app/components/CartItem/CartConfirm";
import ThankYou from "app/components/CartItem/ThankYou";
import ConnectPayments from "app/components/ConnectPayments";
import CreateNewLicense from "app/components/CreateNewLicense";
import PaymentMethods from "app/components/PaymentMethods";
import CapturePaypal from "app/components/PaymentMethods/CapturePaypal";
import HocElementStripe from "app/components/PaymentMethods/HocElementsStripe";
import GeneralLayout from "app/layouts";
import AuthLayout from "app/layouts/Auth";
import Dashboard from "app/pages/Dashboard";
import { ForgotPassword } from "app/pages/ForgotPassword";
import Home from "app/pages/Home";
import { Login } from "app/pages/Login";
import Profile from "app/pages/Profile";
import { ResetPassword } from "app/pages/ResetPassword";
import LicenseReview from "app/pages/LicenseReview";
import { Signup } from "app/pages/Signup";
import AddSoundKit from "app/pages/AddSoundkit";
import AddBulkOffer from "app/pages/AddBulkOffer";
import AddPromoCode from "app/pages/AddPromoCode";
import NegotiationDeal from "app/components/Negotiations/NegotiationsDeal/negotiationsDeal";
import ConversationsComponents from "app/pages/ConversationsPage";
import NotificationsComponents from "app/pages/Notifications";
import IndividualTrackPage from "app/pages/IndividualTrackPage";
import SearchPage from "app/pages/SearchFilterPage/searchPage";
import IndividualSoundkitPage from "app/pages/IndividualSoundkitPage";
import EditProfile from "app/pages/Profile/editProfile";
import LyricsPage from "app/pages/LyricsPage";
import NotFoundPage from "app/components/NotFoundPage/notfoundPage";
import IndividualPlaylist from "app/pages/Playlists/individualPlaylist";
import PlaylistsPage from "app/pages/Playlists";
import PurchasedPage from "app/pages/Purchased";
import ReferralLinkPage from "app/pages/ReferralLink";
import TermConditions from "app/components/ProfileComponent/termConditions";
import PrivacyPolicy from "app/components/ProfileComponent/privacyPolicy";
import UploadTrackFiles from "app/components/AddTrack/uploadTrackFiles";

export const GENERAL_ROUTES = [
  {
    path: "/dashboard",
    name: "Dashboard",
    exact: false,
    layout: GeneralLayout,
    component: Dashboard,
  },
  {
    path: "/license",
    name: "HomePage",
    exact: true,
    layout: GeneralLayout,
    component: CreateNewLicense,
  },
  {
    path: "/add-track",
    name: "Add Track",
    exact: true,
    layout: GeneralLayout,
    component: AddTrack,
  },
  {
    path: "/upload-track-files",
    name: "Upload Track Files",
    exact: true,
    layout: GeneralLayout,
    component: UploadTrackFiles,
  },
  {
    path: "/add-bulkoffer",
    name: "Add BulkOffer",
    exact: true,
    layout: GeneralLayout,
    component: AddBulkOffer,
  },
  {
    path: "/add-promocode",
    name: "Add PromoCode",
    exact: true,
    layout: GeneralLayout,
    component: AddPromoCode,
  },
  {
    path: "/edit-bulkoffer/:id",
    name: "Edit BulkOffer",
    exact: true,
    layout: GeneralLayout,
    component: AddBulkOffer,
  },
  {
    path: "/edit-promocode/:id",
    name: "Edit PromoCODE",
    exact: true,
    layout: GeneralLayout,
    component: AddPromoCode,
  },
  {
    path: "/add-soundkit",
    name: "Add Soundkit",
    exact: true,
    layout: GeneralLayout,
    component: AddSoundKit,
  },
  {
    path: "/cart",
    name: "Cart",
    exact: true,
    layout: GeneralLayout,
    component: CartItem,
  },
  {
    path: "/thank-you",
    name: "Thank you",
    exact: true,
    layout: GeneralLayout,
    component: ThankYou,
  },
  {
    path: "/cart/license-review/:id",
    name: "LicenseReview",
    exact: true,
    layout: GeneralLayout,
    component: LicenseReview,
  },
  {
    path: "/cart/payment-methods",
    name: "LicenseReview",
    exact: true,
    layout: GeneralLayout,
    component: PaymentMethods,
  },
  {
    path: "/cart/stripe-payment",
    name: "LicenseReview",
    exact: true,
    layout: GeneralLayout,
    component: HocElementStripe,
  },
  {
    path: "/cart/confirm",
    name: "LicenseReview",
    exact: true,
    layout: GeneralLayout,
    component: CartConfirm,
  },
  {
    path: "/wait/capture-paypal",
    name: "LicenseReview",
    exact: true,
    layout: GeneralLayout,
    component: CapturePaypal,
  },
  {
    path: "/user/account-settings",
    name: "Edit Profile",
    exact: true,
    layout: GeneralLayout,
    component: EditProfile,
  },
  {
    path: "/negotiations",
    name: "Negotiations",
    exact: true,
    layout: GeneralLayout,
    component: NegotiationDeal,
  },
  {
    path: "/messages",
    name: "Conversations",
    exact: true,
    layout: GeneralLayout,
    component: ConversationsComponents,
  },
  {
    path: "/lyrics",
    name: "Lyrics",
    exact: true,
    layout: GeneralLayout,
    component: LyricsPage,
  },
  {
    path: "/notification",
    name: "Notifications",
    exact: true,
    layout: GeneralLayout,
    component: NotificationsComponents,
  },
  {
    path: "/notfound",
    name: "Search Page",
    exact: true,
    layout: GeneralLayout,
    component: NotFoundPage,
  },
  {
    path: "/playlists",
    name: "Playlists",
    exact: false,
    layout: GeneralLayout,
    component: PlaylistsPage,
  },
  {
    path: "/purchased",
    name: "Purchased page",
    exact: true,
    layout: GeneralLayout,
    component: PurchasedPage,
  },
  {
    path: "/referral",
    name: "Referral link page",
    exact: true,
    layout: GeneralLayout,
    component: ReferralLinkPage,
  },
];
export const AUTH_ROUTES = [
  {
    path: "/auth/signup",
    name: "Sign up",
    exact: true,
    layout: AuthLayout,
    component: Signup,
    restricted: true,
  },
  {
    path: "/auth/login",
    name: "Login",
    exact: true,
    layout: AuthLayout,
    component: Login,
    restricted: true,
  },
  {
    path: "/auth/forgot-password",
    name: "forgot-password",
    exact: true,
    layout: AuthLayout,
    component: ForgotPassword,
    restricted: true,
  },
  {
    path: "/auth/reset-password/:token",
    name: "change-password",
    exact: true,
    layout: AuthLayout,
    component: ResetPassword,
    restricted: true,
  },
  {
    path: "/term-condition",
    name: "Terms of service",
    exact: true,
    layout: AuthLayout,
    component: TermConditions,
  },
  {
    path: "/privacy-policy",
    name: "Privacy policy",
    exact: true,
    layout: AuthLayout,
    component: PrivacyPolicy,
  },
  {
    path: "/track/:slug",
    name: "Tracks Detail",
    exact: true,
    layout: GeneralLayout,
    component: IndividualTrackPage,
  },
  {
    path: "/soundkit/:slug",
    name: "Soundkit Detail",
    exact: true,
    layout: GeneralLayout,
    component: IndividualSoundkitPage,
  },
  {
    path: "/",
    name: "HomePage",
    exact: true,
    layout: GeneralLayout,
    component: Home,
  },
  {
    path: "/home",
    name: "HomePage",
    exact: false,
    layout: GeneralLayout,
    component: Home,
  },
  {
    path: "/user/:username",
    name: "Profile",
    exact: true,
    layout: GeneralLayout,
    component: Profile,
  },
  {
    path: "/search",
    name: "Search Page",
    exact: true,
    layout: GeneralLayout,
    component: SearchPage,
  },
  {
    path: "/playlist/:id",
    name: "Individual Playlist",
    exact: true,
    layout: GeneralLayout,
    component: IndividualPlaylist,
  },
];

export const REGISTER_PAYPAL = [
  {
    path: "/connect-payment",
    name: "connect-payment",
    exact: true,
    layout: GeneralLayout,
    component: ConnectPayments,
  },
];
