import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SuccessIcon from "app/assets/icons/Success";
import { useHistory } from "react-router-dom";

interface ModalUploadTrackFilesReadyProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function ModalUploadTrackFilesReady({
  showModal,
  setShowModal,
}: ModalUploadTrackFilesReadyProps) {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const timer = setTimeout(() => {
      history.push("/add-track");
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [history]);

  return (
    <Modal
      colorScheme="black"
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      isCentered
    >
      <ModalOverlay />
      <ModalContent p="40px 5px" margin={{ base: "0px 16px", md: "0px" }}>
        <ModalCloseButton color="#D8FD02" />
        <ModalBody p="0px" mt="20px">
          <Flex alignItems="center" flexDirection="column">
            <Flex
              alignItems="center"
              justifyContent="center"
              w="125px"
              height="122px"
              backgroundColor="#D8FD01"
              borderRadius="23px"
              mb="30px"
              boxShadow="-31px -12px 40px 22px rgba(216,253,1,0.1)"
            >
              <SuccessIcon />
            </Flex>
            <Text
              textAlign="center"
              fontSize={{ base: "28px", md: "31px" }}
              lineHeight={{ base: "34px", md: "48px" }}
              pb="8px"
              color="#D8FD02"
            >
              {t("uploadReady")}
            </Text>
            <Text p="40px 30px" fontSize="14px" textAlign="center">
              {t("readyToFineTuneYourTrack")}
            </Text>
            <Text fontSize="14px" mb="40px">
              {t("goToEditTrack")}
            </Text>
            <Button
              w="80%"
              background="#36363B"
              borderRadius="4px"
              fontSize="14px"
            >
              {t("proceedToEdit")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
