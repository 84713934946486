import {
  CancleNegotiations,
  CreateNegotiations,
  QueryNegotiations,
} from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class NegotiationController {
  static async getNegotiations(query: QueryNegotiations) {
    try {
      const { data } = await axiosService.get("/negotiations", {
        params: query,
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getNegotiation(negotiationId: string) {
    try {
      const { data } = await axiosService.get(`/negotiations/${negotiationId}`);
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async createNegotiation(body: CreateNegotiations) {
    try {
      const { data } = await axiosService.post("/negotiations", body);

      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async cancelOrRejectNegotiation(body: CancleNegotiations) {
    try {
      const { data } = await axiosService.post(
        "/negotiations/cancel-or-reject",
        body
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async counterOfferNegotiation(body: CancleNegotiations) {
    try {
      const { data } = await axiosService.post(
        "/negotiations/counteroffer",
        body
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async acceptNegotiation(body: CancleNegotiations) {
    try {
      const { data } = await axiosService.post("/negotiations/accept", body);
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async acceptCounterOffer(body: CancleNegotiations) {
    try {
      const { data } = await axiosService.post(
        "/negotiations/accept-counteroffer",
        body
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
}
export default NegotiationController;
