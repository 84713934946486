import React from "react";
interface IUnMutedAudioProps {
  volume?: number;
}
const UnMutedAudio = ({ volume = 1 }: IUnMutedAudioProps) => {

  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3540_5999)">
        {volume > 0 && <path
          d="M8.62148 10.4993H4.8125C4.69647 10.4993 4.58519 10.5454 4.50314 10.6274C4.42109 10.7095 4.375 10.8207 4.375 10.9368V17.0618C4.375 17.1778 4.42109 17.2891 4.50314 17.3711C4.58519 17.4532 4.69647 17.4993 4.8125 17.4993H8.62148C8.82371 17.4994 9.01966 17.5695 9.17601 17.6978L14.1783 21.7994C14.2435 21.8473 14.3208 21.8763 14.4015 21.8829C14.4822 21.8896 14.5631 21.8737 14.6354 21.8371C14.7076 21.8005 14.7682 21.7446 14.8105 21.6755C14.8528 21.6065 14.8752 21.527 14.875 21.4461V6.55248C14.875 6.4716 14.8525 6.39232 14.8101 6.32344C14.7677 6.25456 14.7071 6.19877 14.6349 6.16228C14.5627 6.1258 14.4818 6.11003 14.4012 6.11674C14.3206 6.12345 14.2434 6.15238 14.1783 6.2003L9.17601 10.3019C9.01952 10.4297 8.82358 10.4995 8.62148 10.4993Z"
          stroke='#535353'
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />}
        {
          volume > 0 && <path
            d="M19.25 17.502C19.7827 16.4405 20.125 15.2702 20.125 14.002C20.125 12.7162 19.7969 11.5738 19.25 10.502"
            stroke='#535353'
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        }
        {
          volume > 0.5 && <path
            d="M21.875 20.1269C22.9403 18.2676 23.625 16.627 23.625 14.002C23.625 11.377 22.9688 9.75273 21.875 7.87695"
            stroke='#535353'
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        }
        {
          volume === 1 && <path
            d="M24.7002 22C25.9177 19.4196 26.7002 17.1429 26.7002 13.5C26.7002 9.85714 25.9502 7.60312 24.7002 5"
            stroke='#535353'
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        }

      </g>
      <defs>
        <clipPath id="clip0_3540_5999">
          <rect width="28" height="28" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default UnMutedAudio;
