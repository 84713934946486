import React, { useCallback, useEffect } from "react";

import { doc } from "firebase/firestore";
import { useDocumentQuery } from "../hooks/useDocumentQuery";
import { useHistory, useLocation } from "react-router-dom";
import ChatView from "./RightConversations/ChatView";
import InputSection from "./InputSendMessages/InputSection";
import { db } from "../config/firebase";
import { Box } from "@chakra-ui/react";
import ChatHeader from "./RightConversations/ChatHeader";
import queryString from "query-string";
import Close from "app/assets/icons/Close";

const ConversationDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const tabMessages = queryString.parse(location.search);
  const id = tabMessages.room;

  const { data, loading } = useDocumentQuery(
    `conversation-${id}`,
    doc(db, "conversations", id as string)
  );

  const conversation = data?.data();

  useEffect(() => {
    if (conversation?.theme)
      document.body.style.setProperty("--primary-color", conversation.theme);
  }, [conversation?.theme]);

  const handleCloseConversation = useCallback(() => {
    history.push("/messages?tab=all");
  }, [history]);

  return (
    <Box height="100%">
      {loading ? (
        <Box />
      ) : (
        <Box
          p={{ base: "0px", md: "24px", xl: "32px" }}
          background="#1F1F23"
          borderRadius="8px"
          mb="16px"
          position="relative"
        >
          <Box
            onClick={handleCloseConversation}
            position="absolute"
            top="20px"
            right="25px"
            cursor="pointer"
            display={{ base: "none", md: "block" }}
          >
            <Close />
          </Box>
          <ChatHeader conversation={conversation} />
          <ChatView conversation={conversation} />
          <InputSection disabled={false} conversation={conversation} />
        </Box>
      )}
    </Box>
  );
};

export default ConversationDetail;
