import React from "react";

const PlayTrack = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8.13397C15.1667 8.51887 15.1667 9.48113 14.5 9.86603L2.5 16.7942C1.83333 17.1791 0.999999 16.698 0.999999 15.9282L1 2.0718C1 1.302 1.83333 0.82087 2.5 1.20577L14.5 8.13397Z"
        stroke="#D4F70E"
        strokeWidth="2"
      />
    </svg>
  );
};
export default PlayTrack;
