import React from "react";

const IconMoodsFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.06 1.212C4.77423 1.212 3.54112 1.72277 2.63195 2.63195C1.72277 3.54112 1.212 4.77423 1.212 6.06C1.212 6.69665 1.3374 7.32706 1.58103 7.91525C1.82467 8.50344 2.18177 9.03787 2.63195 9.48805C3.08212 9.93823 3.61656 10.2953 4.20475 10.539C4.79294 10.7826 5.42335 10.908 6.06 10.908C6.69665 10.908 7.32706 10.7826 7.91525 10.539C8.50344 10.2953 9.03787 9.93823 9.48805 9.48805C9.93823 9.03787 10.2953 8.50344 10.539 7.91525C10.7826 7.32706 10.908 6.69665 10.908 6.06C10.908 4.77423 10.3972 3.54112 9.48805 2.63195C8.57888 1.72277 7.34577 1.212 6.06 1.212ZM1.77493 1.77493C2.9114 0.638462 4.45279 0 6.06 0C7.66721 0 9.2086 0.638463 10.3451 1.77493C11.4815 2.9114 12.12 4.45279 12.12 6.06C12.12 6.85581 11.9633 7.64383 11.6587 8.37906C11.3542 9.1143 10.9078 9.78234 10.3451 10.3451C9.78234 10.9078 9.1143 11.3542 8.37906 11.6587C7.64383 11.9633 6.85581 12.12 6.06 12.12C5.26419 12.12 4.47617 11.9633 3.74094 11.6587C3.00571 11.3542 2.33766 10.9078 1.77493 10.3451C1.21221 9.78234 0.765834 9.1143 0.46129 8.37906C0.156746 7.64383 0 6.85581 0 6.06C3.61204e-08 4.45279 0.638462 2.9114 1.77493 1.77493ZM3.636 4.848C3.636 4.51332 3.90732 4.242 4.242 4.242H4.24806C4.58274 4.242 4.85406 4.51332 4.85406 4.848C4.85406 5.18268 4.58274 5.454 4.24806 5.454H4.242C3.90732 5.454 3.636 5.18268 3.636 4.848ZM7.272 4.848C7.272 4.51332 7.54332 4.242 7.878 4.242H7.88406C8.21875 4.242 8.49006 4.51332 8.49006 4.848C8.49006 5.18268 8.21875 5.454 7.88406 5.454H7.878C7.54332 5.454 7.272 5.18268 7.272 4.848ZM3.91766 7.34533C4.15428 7.10863 4.53798 7.10857 4.77467 7.3452C5.1156 7.68602 5.57793 7.87748 6.06 7.87748C6.54207 7.87748 7.0044 7.68602 7.34533 7.3452C7.58202 7.10857 7.96572 7.10863 8.20234 7.34533C8.43896 7.58202 8.4389 7.96572 8.20221 8.20234C7.634 8.77038 6.86345 9.08948 6.06 9.08948C5.25655 9.08948 4.486 8.77038 3.91779 8.20234C3.6811 7.96572 3.68104 7.58202 3.91766 7.34533Z"
        fill="#D4F70E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.06 14.212C18.7742 14.212 17.5411 14.7228 16.6319 15.6319C15.7228 16.5411 15.212 17.7742 15.212 19.06C15.212 19.6966 15.3374 20.3271 15.581 20.9152C15.8247 21.5034 16.1818 22.0379 16.6319 22.4881C17.0821 22.9382 17.6166 23.2953 18.2048 23.539C18.7929 23.7826 19.4234 23.908 20.06 23.908C20.6966 23.908 21.3271 23.7826 21.9152 23.539C22.5034 23.2953 23.0379 22.9382 23.4881 22.4881C23.9382 22.0379 24.2953 21.5034 24.539 20.9152C24.7826 20.3271 24.908 19.6966 24.908 19.06C24.908 17.7742 24.3972 16.5411 23.4881 15.6319C22.5789 14.7228 21.3458 14.212 20.06 14.212ZM15.7749 14.7749C16.9114 13.6385 18.4528 13 20.06 13C21.6672 13 23.2086 13.6385 24.3451 14.7749C25.4815 15.9114 26.12 17.4528 26.12 19.06C26.12 19.8558 25.9633 20.6438 25.6587 21.3791C25.3542 22.1143 24.9078 22.7823 24.3451 23.3451C23.7823 23.9078 23.1143 24.3542 22.3791 24.6587C21.6438 24.9633 20.8558 25.12 20.06 25.12C19.2642 25.12 18.4762 24.9633 17.7409 24.6587C17.0057 24.3542 16.3377 23.9078 15.7749 23.3451C15.2122 22.7823 14.7658 22.1143 14.4613 21.3791C14.1567 20.6438 14 19.8558 14 19.06C14 17.4528 14.6385 15.9114 15.7749 14.7749ZM17.636 17.848C17.636 17.5133 17.9073 17.242 18.242 17.242H18.2481C18.5827 17.242 18.8541 17.5133 18.8541 17.848C18.8541 18.1827 18.5827 18.454 18.2481 18.454H18.242C17.9073 18.454 17.636 18.1827 17.636 17.848ZM21.272 17.848C21.272 17.5133 21.5433 17.242 21.878 17.242H21.8841C22.2187 17.242 22.4901 17.5133 22.4901 17.848C22.4901 18.1827 22.2187 18.454 21.8841 18.454H21.878C21.5433 18.454 21.272 18.1827 21.272 17.848ZM20.06 21.4845C19.5779 21.4845 19.1156 21.676 18.7747 22.0168C18.538 22.2534 18.1543 22.2534 17.9177 22.0167C17.681 21.78 17.6811 21.3963 17.9178 21.1597C18.486 20.5916 19.2566 20.2725 20.06 20.2725C20.8634 20.2725 21.634 20.5916 22.2022 21.1597C22.4389 21.3963 22.439 21.78 22.2023 22.0167C21.9657 22.2534 21.582 22.2534 21.3453 22.0168C21.0044 21.676 20.5421 21.4845 20.06 21.4845Z"
        fill="#D4F70E"
      />
      <path
        d="M14.3189 3.69541L14.2938 3.79253L14.391 3.81733C15.9353 4.21141 17.3855 4.90975 18.6566 5.87141C19.352 6.39791 19.9883 6.99778 20.5546 7.66048L19.6874 7.43357L19.5907 7.40826L19.5654 7.50496L19.3192 8.44442L19.2939 8.54114L19.3906 8.5665L21.8866 9.22084L21.8867 9.22087C21.9673 9.24188 22.0514 9.24549 22.1334 9.23146C22.2155 9.21742 22.2936 9.18607 22.3625 9.13948C22.4315 9.0929 22.4898 9.03215 22.5334 8.96128C22.5771 8.89041 22.6052 8.81106 22.6158 8.7285L22.6158 8.72812L22.935 6.16331L22.9474 6.06396L22.848 6.05171L21.8827 5.93271L21.7836 5.92049L21.7713 6.01963L21.6345 7.1204C19.8497 4.94146 17.4154 3.38613 14.6835 2.68354L14.5868 2.65866L14.5618 2.75537L14.3189 3.69541Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.2"
      />
      <path
        d="M9.3443 20.4951L9.37941 20.4011L9.28535 20.3663C7.79063 19.813 6.42138 18.9669 5.25782 17.8776C4.6212 17.2813 4.05107 16.6182 3.5572 15.8999L4.3959 16.2163L4.48944 16.2515L4.52474 16.158L4.86775 15.2494L4.90306 15.1559L4.80952 15.1206L2.39557 14.2089L2.39546 14.2089C2.31755 14.1795 2.2343 14.1672 2.15124 14.1725C2.06817 14.1779 1.98721 14.2009 1.91374 14.2401C1.84027 14.2792 1.77598 14.3335 1.72515 14.3994C1.67431 14.4653 1.63811 14.5413 1.61895 14.6223L1.61886 14.6227L1.03329 17.1401L1.01061 17.2376L1.10815 17.2602L2.05572 17.4794L2.15306 17.5019L2.17567 17.4046L2.42676 16.3242C3.97404 18.6778 6.23244 20.479 8.87586 21.4633L8.96946 21.4982L9.00442 21.4046L9.3443 20.4951Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.2"
      />
    </svg>
  );
};
export default IconMoodsFilter;
