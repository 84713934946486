import { Box, Text } from "@chakra-ui/react";
import IconEmptyNotification from "app/assets/icons/IconEmptyNotification";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmptyNotification() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="auto"
      height="50vh"
    >
      <IconEmptyNotification />
      <Text mt="20px" fontSize="24px" lineHeight="30px" color="#535353">
        {t("emptyNotificatioon")}
      </Text>
    </Box>
  );
}
