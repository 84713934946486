import { createSelector } from "@reduxjs/toolkit";
import { TrackSoundKit } from "app/models";
import { RootState } from "types";

const selectMyTrack = (state: RootState) => state.myTrack;

export const selectorMyTrack = createSelector(selectMyTrack, (state) => state);

export const myTracksSelector = (state: any) => state.myTrack;

export const favoritesSelector = createSelector(
  myTracksSelector,
  ({ favorites }) => favorites
);

export const isFavoriteSelector = (item: TrackSoundKit) =>
  createSelector(myTracksSelector, ({ favorites }) => {
    return (
      (favorites || []).findIndex(
        (favItem: TrackSoundKit) => favItem?._id === item?._id
      ) !== -1
    );
  });

export const repostsSelector = createSelector(
  myTracksSelector,
  ({ reposts }) => reposts
);

export const isRepostsSelector = (item: TrackSoundKit) =>
  createSelector(myTracksSelector, ({ reposts }) => {
    return (
      reposts?.findIndex(
        (favItem: TrackSoundKit) => favItem?._id === item?._id
      ) !== -1
    );
  });

export const selectorTrackDetail = createSelector(
  myTracksSelector,
  ({ trackDetail }) => trackDetail || {}
);

export const selectorIsLoadingDelete = createSelector(
  myTracksSelector,
  ({ isLoadingDelete }) => isLoadingDelete
);

export const selectorIsLoading = createSelector(
  myTracksSelector,
  ({ isLoading }) => isLoading
);

export const getAllUserSelector = createSelector(
  myTracksSelector,
  ({ allUser }) => allUser
);
