import { createReducer, current } from "@reduxjs/toolkit";
import unionBy from "lodash/unionBy";
import * as cartActions from "app/redux/actions/cart";
import { filterExistId } from "utils/filterExistId";
import {
  Licenses,
  purchaseSuccess,
  SubscriptionPlan,
  TrackSoundKit,
  ValidatedPromoCodeModel,
} from "app/models";
import { PAYMENT_TYPE_METHOD } from "constants/paymentType";
import { Undefinedable } from "constants/types";
import { getSessionStorage, setSessionStorage } from "helpers/session";
import { JWT_LOCALSTORAGE } from "constants/index";

export interface InitCartState {
  cart: TrackSoundKit[];
  subscription: SubscriptionPlan[];
  loading: boolean;
  licenses: Licenses[];
  paymentType: string | undefined;
  lastCart: TrackSoundKit[];
  lastLicenses: Licenses[];
  paymentMethods: any;
  paymentMethod: any;
  lastSubscription: SubscriptionPlan[];
  cartError: Undefinedable<string>;
  invalidCode: boolean;
  validatedPromoCode: ValidatedPromoCodeModel;
  promoCode?: ValidatedPromoCodeModel;
}

const initialState: InitCartState = {
  cart: [],
  subscription: [],
  licenses: [],
  loading: false,
  paymentType: undefined,
  lastCart: [],
  lastSubscription: [],
  lastLicenses: [],
  paymentMethods: [],
  paymentMethod: {},
  cartError: "",
  invalidCode: false,
  validatedPromoCode: {},
  promoCode: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(cartActions.addToCart.fulfilled, (state, action: any) => {
      const cartItem = { ...action.payload };
      const userGuest = getSessionStorage(JWT_LOCALSTORAGE);
      const cart = unionBy(state.cart, [cartItem], "_id");
      if (userGuest === process.env.REACT_APP_TOKEN_GUEST) {
        setSessionStorage("cart", cart);
      }
      return {
        ...state,
        cart: cart,
      };
    })

    .addCase(cartActions.selectedLisence, (state, action) => {
      const oldLicenses = state.licenses;
      const existLicense = oldLicenses.find(
        (item: any) => item.trackId === action.payload?.trackId
      );

      let newLicenses;
      if (existLicense) {
        newLicenses = oldLicenses.map((license: any) => {
          if (license.trackId === action.payload?.trackId) {
            return {
              license: action.payload?.license,
              trackId: action.payload?.trackId,
              negotiation: action.payload?.negotiation,
            };
          }
          return license;
        });
      } else {
        newLicenses = [
          ...oldLicenses,
          {
            license: action.payload?.license,
            trackId: action.payload?.trackId,
            negotiation: action.payload?.negotiation,
          },
        ];
      }
      const userGuest = getSessionStorage(JWT_LOCALSTORAGE);
      if (userGuest === process.env.REACT_APP_TOKEN_GUEST) {
        setSessionStorage("licenses", newLicenses);
      }
      return {
        ...state,
        licenses: newLicenses,
      };
    })

    .addCase(cartActions.removeFromCart, (state, action: any) => {
      const { cart, licenses } = current(state);
      const newCart = filterExistId(cart, action.payload);
      const newLicenses = [...licenses].filter(
        (item) => !action.payload.includes(item.trackId)
      );
      const userGuest = getSessionStorage(JWT_LOCALSTORAGE);
      if (userGuest === process.env.REACT_APP_TOKEN_GUEST) {
        setSessionStorage("licenses", newLicenses);
        setSessionStorage("cart", newCart);
      }
      return {
        ...state,
        cart: newCart,
        licenses: newLicenses,
      };
    })

    .addCase(cartActions.selectPaymentType, (state, action) => {
      return {
        ...state,
        paymentType: action.payload,
      };
    })

    .addCase(cartActions.purchaseCart.pending, (state) => {
      return {
        ...state,
        loading: true,
        cartError: "",
      };
    })
    .addCase(cartActions.purchaseCart.fulfilled, (state, action) => {
      const {
        cart: currentCart,
        subscription,
        licenses,
        paymentType,
      } = current(state);
      const isSubscription = subscription.length > 0;

      if (PAYMENT_TYPE_METHOD.STRIPE === paymentType) {
        const purchasedCart = action.payload;
        const remainCart = currentCart.filter((cart) =>
          purchasedCart.every(
            (item: purchaseSuccess) => !(item?.id === cart?._id && item.success)
          )
        );
        const remainLicense = licenses.filter((license) =>
          purchasedCart.every(
            (item: purchaseSuccess) =>
              !(item.id === license.trackId && item.success)
          )
        );
        let lastCart: TrackSoundKit[] = [];
        currentCart.forEach((cart) => {
          const trackInPurchasedCart = purchasedCart.find(
            (item: purchaseSuccess) => item?.id === cart?._id && item.success
          );
          if (trackInPurchasedCart) {
            lastCart = [
              ...lastCart,
              {
                ...cart,
                success: trackInPurchasedCart?.success,
                error: trackInPurchasedCart?.error,
              },
            ];
          }
        });
        const hasLastSubscription = subscription.find((subscriptionItem) =>
          purchasedCart.some(
            (item: purchaseSuccess) =>
              item?.id === String(subscriptionItem?._id) && item.success
          )
        );

        return {
          ...state,
          cart: remainCart,
          lastCart: lastCart,
          lastLicenses: state.licenses,
          licenses: remainLicense,
          subscription:
            isSubscription && hasLastSubscription ? [] : subscription,
          lastSubscription:
            isSubscription && hasLastSubscription ? subscription : [],
          loading: false,
          cartError: action?.payload?.[0]?.error,
        };
      } else {
        return {
          ...state,
          lastCart: currentCart,
          loading: false,
        };
      }
    })
    .addCase(cartActions.purchaseCart.rejected, (state, action) => {
      return {
        ...state,
        cartError: action?.error?.message,
        loading: false,
      };
    })

    .addCase(cartActions.capturePaypal.pending, (state) => {
      return {
        ...state,
        loading: true,
      };
    })
    .addCase(cartActions.capturePaypal.fulfilled, (state, action) => {
      const currentCart = state.cart;
      if (action.payload.success) {
        const purchasedCart = action.payload.data;
        const remainCart = currentCart.filter((cart) =>
          (purchasedCart || [])?.some(
            (item: purchaseSuccess) => item.id === cart._id && !item.success
          )
        );

        const remainLicense = state.licenses.filter((license) =>
          (purchasedCart || [])?.some(
            (item: purchaseSuccess) =>
              item.id === license.trackId && !item.success
          )
        );
        const lastCart = currentCart.map((cart) => {
          const trackInPurchasedCart = (purchasedCart || [])?.find(
            (item: purchaseSuccess) => item.id === cart._id
          );
          return {
            ...cart,
            success: trackInPurchasedCart?.success,
            error: trackInPurchasedCart?.error,
          };
        });

        return {
          ...state,
          cart: remainCart,
          lastCart: lastCart,
          lastLicenses: state.licenses,
          licenses: remainLicense,
          loading: false,
        };
      } else {
        return {
          ...state,
        };
      }
    })
    .addCase(cartActions.capturePaypal.rejected, (state) => {
      return {
        ...state,
        loading: false,
      };
    })

    .addCase(cartActions.listStripePaymentMethod.pending, (state, action) => {
      return {
        ...state,
        paymentMethods: state.paymentMethods,
      };
    })
    .addCase(cartActions.listStripePaymentMethod.fulfilled, (state, action) => {
      return {
        ...state,
        paymentMethods: action.payload,
      };
    })

    .addCase(cartActions.removeStripePaymentMethod, (state) => {
      return {
        ...state,
        paymentMethods: [],
      };
    })
    .addCase(cartActions.removeCart, (state) => {
      return {
        ...state,
        cart: [],
      };
    })

    .addCase(cartActions.selectPaymentMethods.pending, (state, action) => {
      return {
        ...state,
        paymentMethod: {},
      };
    })
    .addCase(cartActions.selectPaymentMethods.fulfilled, (state, action) => {
      return {
        ...state,
        paymentMethod: action.payload,
      };
    })
    .addCase(cartActions.validatePromoCode.fulfilled, (state, action) => {
      return {
        ...state,
        invalidCode: false,
        validatedPromoCode: action.payload,
      };
    })
    .addCase(cartActions.validatePromoCode.rejected, (state, action) => {
      return {
        ...state,
        invalidCode: true,
        promoCode: {},
        validatedPromoCode: {},
      };
    })
    .addCase(cartActions.applyPromoCode, (state, action) => {
      return {
        ...state,
        promoCode: action.payload,
      };
    })
    .addCase(cartActions.clearValidatedPromoCode, (state, action) => {
      return {
        ...state,
        invalidCode: false,
        validatedPromoCode: {},
      };
    })
    .addCase(cartActions.clearAppliedPromoCode, (state, action) => {
      return {
        ...state,
        promoCode: initialState.promoCode,
        validatedPromoCode: {},
      };
    })
    .addCase(cartActions.updateCreditCard.fulfilled, (state, action) => {
      return {
        ...state,
        paymentMethod: action,
      };
    })
    .addCase(cartActions.deleteCreditCard.fulfilled, (state, action) => {
      return {
        ...state,
        paymentMethod: {},
        paymentMethods: action.payload,
      };
    })
    .addCase(cartActions.addLisenceFromGuest, (state, action) => {
      return {
        ...state,
        licenses: [...action.payload],
      };
    })
    .addCase(cartActions.addListCartFromGuest, (state, action) => {
      return {
        ...state,
        cart: [...action.payload],
      };
    })
    .addCase(cartActions.getListTrackOfCart.fulfilled, (state, action) => {
      const { cart } = current(state);
      const updateTrackCart = cart.map((currentCart) => {
        const updateTrack = action.payload.find(
          (item) => item._id === currentCart._id
        );
        return updateTrack ? updateTrack : currentCart;
      });
      return {
        ...state,
        cart: [...updateTrackCart],
      };
    })
    .addCase(cartActions.getListSoundkitOfCart.fulfilled, (state, action) => {
      const { cart } = current(state);
      const updateSoundkitCart = cart.map((currentCart) => {
        const updateSoundkit = action.payload.find(
          (item) => item._id === currentCart._id
        );
        return updateSoundkit ? updateSoundkit : currentCart;
      });
      return {
        ...state,
        cart: [...updateSoundkitCart],
      };
    })
);
