import { Genre, SubGenre } from "app/models";

export const pureReplace = (arr, el, i) => [
  ...arr.slice(0, i),
  el,
  ...arr.slice(++i),
];

export const getSubgenresFromSelected = (genres: Genre[]) => {
  let subgenres: any[] = [];
  genres.forEach(
    (genre) =>
      genre.selected &&
      subgenres.push(
        ...genre.subGenres.map((g) => ({
          genre: genre.label,
          label: g,
          value: g,
        }))
      )
  );
  return subgenres;
};

export const filterByGenre = (subgenres: SubGenre[], genreName: string) => {
  return subgenres.filter((subgenre) => subgenre.genre !== genreName);
};

export const firstGenreSubgenre = (genres: Genre[] | SubGenre[]) =>
  genres.slice(0, Math.trunc(genres.length / 2));

export const remainingGenreSubgenre = (genres: Genre[] | SubGenre[]) =>
  genres.slice(Math.trunc(genres.length / 2));

export const getSelectedInterestValues = (interests: Genre[] | SubGenre[]) =>
  interests?.reduce((accInterest, interest) => {
    return interest?.selected ? [...accInterest, interest?.label] : accInterest;
  }, []);
