import { Box, Flex, Text, Input } from "@chakra-ui/react";
import React, { useCallback } from "react";
import SelectTrackFilesIcon from "app/assets/icons/SelectFilesUploadTrack";
import { useTranslation } from "react-i18next";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import { TYPE_UPLOAD } from "constants/audioOptions";
import { UploadTrackFilesModel } from "app/models";
import { v4 as uuidv4 } from "uuid";
import { formatAudioDuration } from "utils/formatAudioDuration";
import {
  MINIMUM_AUDIO_MEGABYTE,
  MINIMUM_AUDIO_GIGABYTE,
} from "constants/index";
import ToastPlanSubscription from "../toast/toastplan";
import { getMegaByteAndDuration } from "utils/getMegabyteAnDurationAudio";
import { uploadMoreFilesService } from "services/uploadMoreFiles";

interface AudioTrackFilesProps {
  name: string;
  type: string;
  fileTypeAudio?: string;
  setAutoTaggedFiles: (value: UploadTrackFilesModel[]) => void;
  setCustomTaggedFiles: (value: UploadTrackFilesModel[]) => void;
  setTrackoutStemsFiles: (value: UploadTrackFilesModel[]) => void;
}
export default function AudioTrackFiles({
  name,
  type,
  fileTypeAudio,
  setAutoTaggedFiles,
  setCustomTaggedFiles,
  setTrackoutStemsFiles,
}: AudioTrackFilesProps) {
  const { t } = useTranslation();
  const onUploadFile = useCallback(
    async (e, name) => {
      try {
        const files = Array.from(e.target.files);
        const promises = files.map(async (file) => {
          const id = uuidv4();
          let newFile = { name: file?.name, id, progress: 0 };

          // Function to update file progress
          const updateFileProgress = (value) => {
            if (name === t("autoTagged")) {
              setAutoTaggedFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? { ...prevFile, progress: value }
                    : prevFile
                )
              );
            } else if (name === t("customTagged")) {
              setCustomTaggedFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? { ...prevFile, progress: value }
                    : prevFile
                )
              );
            } else if (name === t("trackoutStems")) {
              setTrackoutStemsFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? { ...prevFile, progress: value }
                    : prevFile
                )
              );
            }
          };

          // Validate file size
          let checkFile;
          if (name === t("trackoutStems")) {
            checkFile = await getMegaByteAndDuration(
              file,
              FILE_UPLOAD_TYPE.ZIP
            );
            if (Number(checkFile?.fileSizeInGB) > MINIMUM_AUDIO_GIGABYTE) {
              ToastPlanSubscription({ description: t("minimunGigabyte") });
              return;
            }
          } else {
            checkFile = await getMegaByteAndDuration(file);
            if (Number(checkFile?.fileSize) > MINIMUM_AUDIO_MEGABYTE) {
              ToastPlanSubscription({ description: t("minimunMegabyte") });
              return;
            }
          }

          // Add file to state with initial progress
          if (name === t("autoTagged")) {
            setAutoTaggedFiles((prevFiles) => [...prevFiles, newFile]);
          } else if (name === t("customTagged")) {
            setCustomTaggedFiles((prevFiles) => [...prevFiles, newFile]);
          } else if (name === t("trackoutStems")) {
            setTrackoutStemsFiles((prevFiles) => [...prevFiles, newFile]);
          }

          try {
            // Upload file
            const urlFile = await uploadMoreFilesService(
              file,
              updateFileProgress,
              true
            );

            // Update state with file URL and final progress
            if (name === t("autoTagged")) {
              const time = formatAudioDuration(checkFile?.duration);
              setAutoTaggedFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? {
                        ...prevFile,
                        urlFile,
                        timeUntagDuration: time,
                        progress: 100,
                      }
                    : prevFile
                )
              );
            } else if (name === t("customTagged")) {
              const time = formatAudioDuration(checkFile?.duration);
              setCustomTaggedFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? {
                        ...prevFile,
                        urlFile,
                        timeTagDuration: time,
                        progress: 100,
                      }
                    : prevFile
                )
              );
            } else if (name === t("trackoutStems")) {
              setTrackoutStemsFiles((prevFiles) =>
                prevFiles.map((prevFile) =>
                  prevFile.id === id
                    ? { ...prevFile, urlFile, progress: 100 }
                    : prevFile
                )
              );
            }
          } catch (uploadError) {
            console.error(`Error uploading file ${file.name}:`, uploadError);
          }
        });

        await Promise.all(promises);
        console.log("All files uploaded");
      } catch (e) {
        console.error("Error in file upload process:", e);
      }
    },
    [setAutoTaggedFiles, setCustomTaggedFiles, setTrackoutStemsFiles, t]
  );

  return (
    <Box>
      <Flex justifyContent="center">
        <Box
          padding="40px 0px"
          w={{ base: "358px", md: "324px" }}
          background="#212125"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <Input
            id={`${name}`}
            type="file"
            onChange={(e) => onUploadFile(e, name)}
            display="none"
            accept={
              type === FILE_UPLOAD_TYPE.ZIP
                ? TYPE_UPLOAD.ZIP
                : fileTypeAudio ?? TYPE_UPLOAD.AUDIO
            }
            multiple
          />
          <label htmlFor={`${name}`} className="select-track-files">
            <SelectTrackFilesIcon />
            <Text color="#D4F70E" m="20px 0px 10px">
              {t("selectAFile")}
            </Text>
            <Text color="#4D4D51" fontSize="12px" textAlign="center">
              {name === t("autoTagged")
                ? t("wavOrMp3FilesSizeNoMoreLargerThan")
                : name === t("customTagged")
                ? t("mp3FileSizeNoMoreLargerThan")
                : t("zipFileSizeNoMoreLargerThan")}
            </Text>
          </label>
        </Box>
      </Flex>
    </Box>
  );
}
