import React from "react";

const IconAddToCart = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.875 17.5C7.56536 17.5 8.125 16.9404 8.125 16.25C8.125 15.5596 7.56536 15 6.875 15C6.18464 15 5.625 15.5596 5.625 16.25C5.625 16.9404 6.18464 17.5 6.875 17.5Z"
        fill="#D4F70E"
      />
      <path
        d="M15.625 17.5C16.3154 17.5 16.875 16.9404 16.875 16.25C16.875 15.5596 16.3154 15 15.625 15C14.9346 15 14.375 15.5596 14.375 16.25C14.375 16.9404 14.9346 17.5 15.625 17.5Z"
        fill="#D4F70E"
      />
      <path
        d="M6.5539 11.875H16.7625L18.2625 4.375H5.23008L4.89922 2.5H1.25V3.75H3.85078L5.72578 14.375H16.875V13.125H6.77422L6.5539 11.875Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconAddToCart;
