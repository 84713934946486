import { Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import "./styles.scss";
import { useLocation } from "react-router-dom";

interface AuthLayoutProps {
  children: Element;
}

export default function Auth({ children }: AuthLayoutProps) {
  const location = useLocation();
  const checkPrivacyTermCondition = useMemo(() => {
    return ["/privacy-policy", "/term-condition"].includes(location.pathname);
  }, [location.pathname]);

  return (
    <Flex
      className="container"
      justifyContent="center"
      alignItems="center"
      direction="column"
      height={checkPrivacyTermCondition ? "100%" : "100vh"}
      overflow="auto"
      bgColor={{ base: "#111518", md: "none" }}
      style={{
        zoom: checkPrivacyTermCondition ? 1.12 : 1,
      }}
    >
      {children}
    </Flex>
  );
}
