import { QueryPlayTrack, TrackSoundKit, LoadMorePage } from "app/models";
import { createAsyncThunk, Dispatch, createAction } from "@reduxjs/toolkit";

import { generateActionTypes } from "../actionTypes";
import {
  updateLikesSoundKit,
  updateLikesSoundKitInRecentAction,
  updateRepostSoundKit,
  updateRepostSoundKitInRecentAction,
  updateUnLikesSoundKit,
  updateUnLikesSoundKitInRecentAction,
} from "../profile";
import SoundKitService from "app/apis/soundKit";
import { errorUnLikeSoundkitRecentActionsHomepage } from "../home";

export const setFavoritesSK = generateActionTypes("SET_FAVORITE_SK");
export const unSetFavoritesSK = generateActionTypes("UNSET_FAVORITE_SK");
export const fetchFavoritesSK = generateActionTypes("FETCH_FAVORITES_SK");
export const fetchReleaseSK = generateActionTypes("FETCH_RELEASED_SOUND_KITS");
export const fetchPrivateSK = generateActionTypes("FETCH_PRIVATE_SOUND_KITS");
export const fetchScheduleSK = generateActionTypes("FETCH_SCHEDULE_SOUND_KITS");

export const fetchDraftsSK = generateActionTypes("FETCH_DRAFTS_SOUND_KITS");
export const fetchRepostsSK = generateActionTypes("FETCH_REPOSTS_SK");
export const setRepostsSK = generateActionTypes("SET_REPOSTS_SK");
export const getIndividualSK = generateActionTypes("GET_INDIVIDUAL_SOUNDKIT");
export const updateSoundkitReleased = generateActionTypes(
  "UPDATE_SOUNDKIT_RELEASED"
);
export const TYPES = {
  REPOST_SOUNDPACKS_DETAIL: "REPOST_SOUNDPACKS_DETAIL",
  LIKE_SOUNDPACKS_DETAIL: "LIKE_SOUNDPACKS_DETAIL",
  UNLIKE_SOUNDPACKS_DETAIL: "UNLIKE_SOUNDPACKS_DETAIL",
  LIKE_SOUNDKIT_DETAIL_ERROR: "LIKE_SOUNDKIT_DETAIL_ERROR",
  UNLIKE_SOUNDKIT_DETAIL_ERROR: "UNLIKE_SOUNDKIT_DETAIL_ERROR",
  FETCH_SOUNDKITS_PENDING: "FETCH_SOUNDKITS_PENDING",
};

// --------------------------------------------------------------
// FETCH_ADD_FAVORITES_SK
// --------------------------------------------------------------
const fetchFavoritesSKRequest = () => ({
  type: fetchFavoritesSK.REQUEST,
  payload: [],
});

const fetchFavoritesSKSuccess = (payload: TrackSoundKit[]) => ({
  type: fetchFavoritesSK.SUCCESS,
  payload: payload,
});

const fetchFavoritesSKError = (error: any) => ({
  type: fetchFavoritesSK.ERROR,
  payload: error,
});

export const getFavoritesSK =
  (value?: string | undefined) => async (dispatch: Dispatch) => {
    dispatch(fetchFavoritesSKRequest());
    try {
      const data = await SoundKitService.getFavoritesSK(value);
      dispatch(fetchFavoritesSKSuccess(data));
    } catch (error: any) {
      dispatch(fetchFavoritesSKError(error.message));
    }
  };

// --------------------------------------------------------------
// SET_FAVORITE_SK
// --------------------------------------------------------------

const setFavoritesSKSuccess = (payload: { _id: string }) => ({
  type: setFavoritesSK.SUCCESS,
  payload: payload,
});

const setFavoritesError = (error: any) => ({
  type: setFavoritesSK.ERROR,
  payload: error,
});

export const setFavoriteSKRecentActionsHomePage =
  (id: string) => async (dispatch: any) => {
    const body = { _id: id };
    dispatch(setFavoritesSKSuccess(body));
  };

export const setFavoriteSKRecentActionsHomePageError =
  (id: string) => async (dispatch: any) => {
    const body = { _id: id };
    dispatch(setFavoritesError(body));
  };

export const setFavoriteSK =
  (query: QueryPlayTrack, isRecentAction: boolean) => async (dispatch: any) => {
    const body = { _id: query.id };
    dispatch(setFavoritesSKSuccess(body));
    if (isRecentAction) {
      dispatch(updateLikesSoundKitInRecentAction(body));
    } else {
      dispatch(updateLikesSoundKit(body));
    }
    setTimeout(async () => {
      try {
        await SoundKitService.setFavorite(query);
      } catch (error) {
        dispatch(setFavoritesError(body));
      }
    }, query.timeCallAPi);
  };

// --------------------------------------------------------------
// UNSET_FAVORITE
// --------------------------------------------------------------

const unSetFavoritesSuccess = (payload: { _id: string }) => ({
  type: unSetFavoritesSK.SUCCESS,
  payload: payload,
});

const unSetFavoritesError = (error: any) => ({
  type: unSetFavoritesSK.ERROR,
  payload: error,
});

export const unsetFavoriteSKRecentActionsHomePage =
  ({ id, timeCallApi }: { id: string; timeCallApi?: number }) =>
  async (dispatch: any) => {
    const body = { _id: id };
    dispatch(unSetFavoritesSuccess(body));
    setTimeout(async () => {
      try {
        await SoundKitService.unsetFavorite(id);
      } catch (error) {
        dispatch(unSetFavoritesError(id));
        dispatch(errorUnLikeSoundkitRecentActionsHomepage(id));
        dispatch(errorUnLikeSoundkitDetail());
      }
    }, timeCallApi);
    return { soundkitId: id };
  };

export const unsetFavoriteSK =
  (
    {
      id,
      timeCallApi,
    }: {
      id: string;
      timeCallApi?: number;
    },
    isRecentAction: boolean
  ) =>
  async (dispatch: any) => {
    const body = { _id: id };
    dispatch(unSetFavoritesSuccess(body));
    if (isRecentAction) {
      dispatch(updateUnLikesSoundKitInRecentAction(body));
    } else {
      dispatch(updateUnLikesSoundKit(body));
    }
    setTimeout(async () => {
      try {
        await SoundKitService.unsetFavorite(id);
      } catch (error) {
        dispatch(unSetFavoritesError(id));
      }
    }, timeCallApi);
  };

// --------------------------------------------------------------
// GET_SOUNDKIT
// --------------------------------------------------------------

const fetchReleasesSKRequest = (query: LoadMorePage) => ({
  type: fetchReleaseSK.REQUEST,
  payload: query,
});

const fetchReleasesSKSuccess = (payload: TrackSoundKit[]) => {
  return {
    type: fetchReleaseSK.SUCCESS,
    payload: payload,
  };
};

const fetchReleasesSKError = (error: any) => ({
  type: fetchReleaseSK.ERROR,
  payload: error,
});

export const getReleasesSK = (query: {}) => async (dispatch: Dispatch) => {
  dispatch(fetchReleasesSKRequest(query));
  try {
    const data = await SoundKitService.getReleasedSK(query);
    dispatch(fetchReleasesSKSuccess(data));
  } catch (error: any) {
    dispatch(fetchReleasesSKError(query));
  }
};

// --------------------------------------------------------------
// GET_PRIVATE_SOUNDKIT
// --------------------------------------------------------------

const fetchPrivateSKRequest = (query: LoadMorePage) => ({
  type: fetchPrivateSK.REQUEST,
  payload: query,
});

const fetchPrivateSKSuccess = (payload: TrackSoundKit[]) => {
  return {
    type: fetchPrivateSK.SUCCESS,
    payload: payload,
  };
};

const fetchPrivateSKError = (error: any) => ({
  type: fetchPrivateSK.ERROR,
  payload: error,
});

export const getPrivateSK = (query: {}) => async (dispatch: Dispatch) => {
  dispatch(fetchPrivateSKRequest(query));
  try {
    const data = await SoundKitService.getReleasedSK(query);
    dispatch(fetchPrivateSKSuccess(data));
  } catch (error: any) {
    dispatch(fetchPrivateSKError(query));
  }
};

// --------------------------------------------------------------
// GET_SCHEDULE_SOUNDKIT
// --------------------------------------------------------------

const fetchScheduleSKRequest = (query: LoadMorePage) => ({
  type: fetchScheduleSK.REQUEST,
  payload: query,
});

const fetchScheduleSKSuccess = (payload: TrackSoundKit[]) => {
  return {
    type: fetchScheduleSK.SUCCESS,
    payload: payload,
  };
};

const fetchScheduleSKError = (error: any) => ({
  type: fetchScheduleSK.ERROR,
  payload: error,
});

export const getScheduleSK = (query: {}) => async (dispatch: Dispatch) => {
  dispatch(fetchScheduleSKRequest(query));
  try {
    const data = await SoundKitService.getReleasedSK(query);
    dispatch(fetchScheduleSKSuccess(data));
  } catch (error: any) {
    dispatch(fetchScheduleSKError(query));
  }
};

// --------------------------------------------------------------
// GET_DRAFTS_SOUNDKIT
// --------------------------------------------------------------

const fetchDraftsSKRequest = (query: LoadMorePage) => ({
  type: fetchDraftsSK.REQUEST,
  payload: query,
});

const fetchDraftsSKSuccess = (payload: TrackSoundKit[]) => {
  return {
    type: fetchDraftsSK.SUCCESS,
    payload: payload,
  };
};

const fetchDraftsSKError = (error: any) => ({
  type: fetchDraftsSK.ERROR,
  payload: error,
});

export const getDraftsSK = (query: {}) => async (dispatch: Dispatch) => {
  dispatch(fetchDraftsSKRequest(query));
  try {
    const data = await SoundKitService.getReleasedSK(query);
    dispatch(fetchDraftsSKSuccess(data));
  } catch (error: any) {
    dispatch(fetchDraftsSKError(query));
  }
};

// FETCH_ADD_REPOST_SK
// --------------------------------------------------------------
const fetchRepostsSKRequest = () => ({
  type: fetchRepostsSK.REQUEST,
  payload: [],
});

const fetchRepostsSKSuccess = (payload: TrackSoundKit[]) => ({
  type: fetchRepostsSK.SUCCESS,
  payload: payload,
});

const fetchRepostsSKError = (error: any) => ({
  type: fetchRepostsSK.ERROR,
  payload: error,
});

export const getRepostsSK = () => async (dispatch: Dispatch) => {
  dispatch(fetchRepostsSKRequest());
  try {
    const data = await SoundKitService.getRepostsSK();
    dispatch(fetchRepostsSKSuccess(data));
  } catch (error: any) {
    dispatch(fetchRepostsSKError(error.message));
  }
};

// --------------------------------------------------------------
// SET_REPOST_SK
// --------------------------------------------------------------

const setRepostSKsSKSuccess = (payload: { _id: string }) => ({
  type: setRepostsSK.SUCCESS,
  payload: payload,
});

const setRepostSKsError = (error: any) => ({
  type: setRepostsSK.ERROR,
  payload: error,
});

export const setRepostSKRecentActionsHomePage =
  (id: string) => async (dispatch: any) => {
    const body = { _id: id };
    dispatch(setRepostSKsSKSuccess(body));
  };

export const setRepostSK =
  (id: string, isRecentAction: boolean) => async (dispatch: any) => {
    try {
      const body = { _id: id };
      dispatch(setRepostSKsSKSuccess(body));
      if (isRecentAction) {
        dispatch(updateRepostSoundKitInRecentAction(body));
      } else {
        dispatch(updateRepostSoundKit(body));
      }
      await SoundKitService.setRepostSK(id);
    } catch (error: any) {
      dispatch(setRepostSKsError(error.message));
    }
  };

// GET_INDIVIDUAL_SOUNDKIT
// --------------------------------------------------------------
const getIndividualSKRequest = () => ({
  type: getIndividualSK.REQUEST,
  payload: [],
});

const getIndividualSKSuccess = (payload: TrackSoundKit) => ({
  type: getIndividualSK.SUCCESS,
  payload: payload,
});

const getIndividualSKError = (error: any) => ({
  type: getIndividualSK.ERROR,
  payload: error,
});

export const getIndividualSoundkit =
  (slug: string) => async (dispatch: Dispatch) => {
    dispatch(getIndividualSKRequest());
    try {
      const data = await SoundKitService.getSoundkitSlug(slug);
      dispatch(getIndividualSKSuccess(data));
    } catch (error: any) {
      dispatch(getIndividualSKError(error.message));
    }
  };

const updateSoundkitsReleasedRequest = () => ({
  type: updateSoundkitReleased.REQUEST,
  payload: [],
});

const updateSoundkitsReleasedSuccess = (payload: string[]) => ({
  type: updateSoundkitReleased.SUCCESS,
  payload: payload,
});

const updateSoundkitsReleasedError = (error: any) => ({
  type: updateSoundkitReleased.ERROR,
  payload: error,
});

export const updateSoundkitsReleased =
  (selectedSK: string[]) => async (dispatch: Dispatch) => {
    dispatch(updateSoundkitsReleasedRequest());
    try {
      await SoundKitService.deleteSoundkits(selectedSK);
      dispatch(updateSoundkitsReleasedSuccess(selectedSK));
    } catch (error: any) {
      dispatch(updateSoundkitsReleasedError(error.message));
    }
  };

export const likeSoundPacksDetail = createAsyncThunk(
  TYPES.LIKE_SOUNDPACKS_DETAIL,
  async (query: QueryPlayTrack, thunkAPI) => {
    thunkAPI.dispatch(setFavoriteSKRecentActionsHomePage(query.id));
    setTimeout(async () => {
      try {
        await SoundKitService.setFavorite(query);
      } catch (error) {
        thunkAPI.dispatch(setFavoriteSKRecentActionsHomePageError(query.id));
        thunkAPI.dispatch(errorLikeSoundkitDetail(query.id));
        console.log(error, "error");
      }
    }, query.timeCallAPi);
    return { soundkitId: query?.id };
  }
);

export const unLikeSoundPacksDetail = createAsyncThunk(
  TYPES.UNLIKE_SOUNDPACKS_DETAIL,
  async ({ id, timeCallApi }: { id: string; timeCallApi?: number }, thunkAPI) =>
    thunkAPI.dispatch(
      unsetFavoriteSKRecentActionsHomePage({ id: id, timeCallApi: timeCallApi })
    )
);

export const repostSoundPacksDetail = createAsyncThunk(
  TYPES.REPOST_SOUNDPACKS_DETAIL,
  async (id: string, thunkAPI) => {
    const soundkitRepost = await SoundKitService.setRepostSK(id);
    thunkAPI.dispatch(setRepostSKRecentActionsHomePage(id));
    return soundkitRepost;
  }
);

export const errorLikeSoundkitDetail = createAction(
  TYPES.LIKE_SOUNDKIT_DETAIL_ERROR
);
export const errorUnLikeSoundkitDetail = createAction(
  TYPES.UNLIKE_SOUNDKIT_DETAIL_ERROR
);

export const fetchSoundkitsPending = createAsyncThunk(
  TYPES.FETCH_SOUNDKITS_PENDING,
  async (query: {}) => {
    return await SoundKitService.getPendingSK(query);
  }
);
