import React from "react";

const PlayAudioIcon = () => {
  return (
    <svg
      width="6"
      height="10"
      viewBox="0 0 6 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="4" width="1.99985" height="10" rx="0.999924" fill="#D1F310" />
      <rect width="1.99985" height="10" rx="0.999924" fill="#D1F310" />
    </svg>
  );
};
export default PlayAudioIcon;
