import { Box, Flex, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import ModalPurchase from "app/components/ModalPurchase";
import { TrackSoundKit } from "app/models";
import {
  getListSoundkitOfCart,
  getListTrackOfCart,
  purchaseCart,
  removeFromCart,
} from "app/redux/actions/cart";
import {
  cartLicensesSelector,
  paymentTypeSelector,
  totalCartSelector,
  currentCartSelector,
  selectedSubscriptionSelector,
  totalDiscountCartSelector,
  bulkOffersSelector,
  cartPromoCodeSelector,
} from "app/redux/selectors/cart";
import { PAYMENT_TYPE_METHOD } from "constants/paymentType";
import { useCart } from "hooks/cart/useCart";
import useTrackSelect from "hooks/track/useTrackSelect";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getIpAddress } from "utils/getData";
import BasketItem from "../BasketItem";
import "../styles.scss";
import ModalTransactionProcess from "app/components/SuccessModal/modalTransactionProcess";
import transactionProcess from "../../../assets/LottieIAnimations/transactionProcessCheckout.json";

function CartConfirm() {
  const cartLicenses = useSelector(cartLicensesSelector);
  const totalCart = useSelector(totalCartSelector);
  const paymentType = useSelector(paymentTypeSelector);
  const listCart = useSelector(currentCartSelector);
  const selectedSubscription = useSelector(selectedSubscriptionSelector);
  const totalDiscount = useSelector(totalDiscountCartSelector);
  const bulkOffers = useSelector(bulkOffersSelector);
  const promoCode = useSelector(cartPromoCodeSelector);
  const [showModalTransactionProcess, setShowModalTransactionProcess] =
    useState(false);

  const { t } = useTranslation();
  const history = useHistory();
  const {
    showOptionsModal,
    setShowOptionsModal,
    selectedTrack,
    onOpenOptionsModal,
  } = useTrackSelect();
  const dispatch = useDispatch();
  const {
    handleCheckbox,
    handleSelectAll,
    selectAllCheckbox,
    paymentMethod,
    setSelectAllCheckbox,
  } = useCart();

  const trackIds: any[] = useMemo(() => {
    return listCart?.filter((item) => item?.trackState).map((id) => id?._id);
  }, [listCart]);
  const soundkitIds: any[] = useMemo(() => {
    return listCart?.filter((item) => item?.soundkitState).map((id) => id?._id);
  }, [listCart]);

  const redirectDashboard = () => {
    history.push({
      pathname: "/cart/payment-methods",
    });
  };

  useEffect(() => {
    if (trackIds?.length > 0) {
      dispatch(getListTrackOfCart(trackIds));
    }
    if (soundkitIds?.length > 0) {
      dispatch(getListSoundkitOfCart(soundkitIds));
    }
  }, []);

  const data = (listCart || [])
    .filter((item: TrackSoundKit) => {
      if (item.trackState) {
        return item.trackState === "EXCLUSIVE_PURCHASED";
      } else {
        return item.soundkitState === "EXCLUSIVE_PURCHASED";
      }
    })
    .map((trackItem: TrackSoundKit) => trackItem?._id);

  useEffect(() => {
    if (data.length > 0) {
      dispatch(removeFromCart(data));
    }
  }, [data, dispatch]);

  const handleConfirmPaymentButton = useCallback(async () => {
    const subscriptionId =
      selectedSubscription.length > 0
        ? selectedSubscription[0]?._id
        : undefined;
    const ipAddress = await getIpAddress();
    setShowModalTransactionProcess(true);
    dispatch(
      purchaseCart({
        subscriptionId,
        cart: listCart,
        licenses: (cartLicenses || []).map((license: any) => {
          return {
            trackId: license?.trackId,
            licenseId: license?.license?._id,
            negotiationId: license?.negotiation?._id,
          };
        }),
        totalAmount: Number(totalCart) - Number(totalDiscount),
        paymentType,
        paymentMethodId: paymentMethod?.payload?.id,
        rememberCard: undefined,
        promoCode: promoCode?.promoCode,
        bulkOffers,
        ipAddress,
      })
    ).then((res: any) => {
      const error =
        res?.payload &&
        res?.payload.length &&
        (res?.payload || []).every((item: any) => item?.error);
      if (res.error || error) {
        history.push("/thank-you?status=error");
      } else {
        if (PAYMENT_TYPE_METHOD.PAYPAL === paymentType) {
          const paypalUrls = res?.payload?.links;
          const approveUrlPaypal = paypalUrls?.find(
            (url: any) => url?.rel === "approve"
          );
          window.location.assign(approveUrlPaypal?.href);
          return;
        }
        setShowModalTransactionProcess(false);
        return history.push("/thank-you?status=success");
      }
    });
  }, [
    selectedSubscription,
    dispatch,
    listCart,
    cartLicenses,
    totalCart,
    totalDiscount,
    paymentType,
    paymentMethod?.payload?.id,
    promoCode?.promoCode,
    bulkOffers,
    history,
  ]);
  return (
    <Box
      p={{ base: "20px 20px", md: "20px 30px", xl: "40px 80px" }}
      className="cart-item-components"
    >
      <Flex
        alignItems="center"
        mb="32px"
        position="sticky"
        top="95px"
        background="#18181c"
        zIndex={90}
        height="100px"
      >
        <Box
          cursor="pointer"
          className="vector-button"
          onClick={redirectDashboard}
        >
          <Back />
        </Box>
        <Text fontSize={{ base: "28px", md: "32px" }}>
          {t("confirmPayment")}
        </Text>
      </Flex>

      <BasketItem
        data={listCart}
        onOpenOptionsModal={onOpenOptionsModal}
        handleCheckbox={handleCheckbox}
        handleSelectAll={handleSelectAll}
        valueCheckbox={selectAllCheckbox}
        isConfirm
        onClick={handleConfirmPaymentButton}
        nameStripe={paymentMethod?.payload?.billing_details?.name}
        setValueCheckBox={setSelectAllCheckbox}
      />

      <ModalPurchase
        showModal={showOptionsModal}
        setShowModal={setShowOptionsModal}
        selectedTrack={selectedTrack}
      />
      {showModalTransactionProcess &&
        PAYMENT_TYPE_METHOD.STRIPE === paymentType && (
          <ModalTransactionProcess
            isOpen={showModalTransactionProcess}
            iconJson={transactionProcess}
            onClose={setShowModalTransactionProcess}
          />
        )}
    </Box>
  );
}

export default React.memo(CartConfirm);
