import { Licenses } from "app/models";

export const fileToDeliverRecentAction = (item: any) => {
  const fileToDeliver = (item?.licenses || []).map((license: Licenses) => {
    const wav = license?.extraWav && "WAV";
    const stems = license?.trackStems && "STEMS";
    const mp3 = license?.filesDeliver === "YES_UNTAGGED" && "MP3";
    return [wav, stems, mp3];
  });

  const flatArray = Array.from(
    new Set(Array.prototype.concat.apply([], fileToDeliver))
  );

  const removeIndex = flatArray.indexOf(false);
  if (removeIndex > -1) {
    flatArray.splice(removeIndex, 1);
  }
  return flatArray;
};

export const TYPE_AUDIO_FILES = {
  AUDIO_TAGGED: "audio-tagged",
  CUSTOM_TAGGED: "custom-tagged",
  TRACKOUT_STEMS: "trackout-stems",
};
