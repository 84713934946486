import React from "react";

const Back = () => {
  return (
    <svg
      width="6"
      height="8"
      viewBox="0 0 6 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.46761 4.00002L5.12261 1.34502C5.2623 1.2045 5.3407 1.01441 5.3407 0.816272C5.3407 0.618133 5.2623 0.428044 5.12261 0.287523C5.05289 0.217226 4.96994 0.16143 4.87854 0.123354C4.78715 0.0852773 4.68912 0.0656738 4.59011 0.0656738C4.4911 0.0656738 4.39307 0.0852773 4.30168 0.123354C4.21028 0.16143 4.12733 0.217226 4.05761 0.287523L0.87761 3.46752C0.807314 3.53724 0.751519 3.6202 0.713442 3.71159C0.675366 3.80298 0.655762 3.90101 0.655762 4.00002C0.655762 4.09903 0.675366 4.19706 0.713442 4.28846C0.751519 4.37985 0.807314 4.4628 0.87761 4.53252L4.05761 7.75002C4.12769 7.81953 4.2108 7.87453 4.30218 7.91185C4.39356 7.94917 4.49141 7.96809 4.59011 7.96752C4.68882 7.96809 4.78666 7.94917 4.87804 7.91185C4.96942 7.87453 5.05253 7.81953 5.12261 7.75002C5.2623 7.6095 5.3407 7.41941 5.3407 7.22127C5.3407 7.02313 5.2623 6.83304 5.12261 6.69252L2.46761 4.00002Z"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default Back;
