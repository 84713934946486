import { createReducer, PayloadAction, current } from "@reduxjs/toolkit";
import { TrackSoundKit } from "app/models";
import {
  fetchFavoritesSK,
  setFavoritesSK,
  unSetFavoritesSK,
  fetchReleaseSK,
  fetchDraftsSK,
  fetchRepostsSK,
  setRepostsSK,
  fetchPrivateSK,
  fetchScheduleSK,
  getIndividualSK,
  updateSoundkitReleased,
} from "../actions/mySoundKit";
import * as soundkitActions from "app/redux/actions/addSoundkit";
import * as mySoundkitActions from "app/redux/actions/mySoundKit";

export interface MySoundKitState {
  favoritesSK: TrackSoundKit[];
  releasedSK: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  draftsSK: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  isLoading: boolean;
  repostsSK: TrackSoundKit[];
  privateSK: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  scheduleSK: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  pendingSK: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  soundkitDetail: TrackSoundKit;
}

const initialState: MySoundKitState = {
  favoritesSK: [],
  releasedSK: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  draftsSK: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  isLoading: false,
  repostsSK: [],
  privateSK: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  scheduleSK: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  pendingSK: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  soundkitDetail: {},
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchFavoritesSK.REQUEST, (state) => {
      return {
        ...state,
        isLoading: true,
        favoritesSK: [],
      };
    })
    .addCase(fetchFavoritesSK.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favoritesSK: action.payload,
        isLoading: false,
      };
    })
    .addCase(fetchFavoritesSK.ERROR, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    })
    .addCase(setFavoritesSK.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favoritesSK: [...state.favoritesSK, action.payload],
      };
    })
    .addCase(setFavoritesSK.ERROR, (state, action: PayloadAction<any>) => {
      const removeFavorites = state.favoritesSK.filter(
        (item) => item._id !== action.payload?._id
      );
      return {
        ...state,
        favoritesSK: removeFavorites,
      };
    })
    .addCase(unSetFavoritesSK.SUCCESS, (state, action: PayloadAction<any>) => {
      const favoritesSK = state.favoritesSK.filter(
        (item: TrackSoundKit) => item?._id !== action.payload?._id
      );
      return {
        ...state,
        favoritesSK: [...favoritesSK],
      };
    })
    .addCase(unSetFavoritesSK.ERROR, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favoritesSK: [...state.favoritesSK, { _id: action?.payload }],
      };
    })
    .addCase(fetchReleaseSK.REQUEST, (state, action) => {
      return {
        ...state,
        isLoading: true,
        releasedSK: {
          ...state.releasedSK,
          loadingMore: action?.payload?.loadingMore || false,
          page: action?.payload?.page,
        },
      };
    })
    .addCase(fetchReleaseSK.SUCCESS, (state, action: PayloadAction<any>) => {
      const newReleaseSK =
        state.releasedSK.page === 1
          ? action.payload
          : [...state.releasedSK.items, ...action.payload];
      return {
        ...state,
        releasedSK: {
          ...state.releasedSK,
          items: newReleaseSK,
          hasMore: action.payload.length === 25,
          loadingMore: false,
        },
        isLoading: false,
      };
    })
    .addCase(fetchReleaseSK.ERROR, (state, action) => {
      return {
        ...state,
        releasedSK: {
          ...state.releasedSK,
          loadingMore: false,
          page: action?.payload?.page - 1,
        },
        isLoading: false,
      };
    })
    .addCase(
      updateSoundkitReleased.SUCCESS,
      (state, action: PayloadAction<any>) => {
        const currentReleaseState = current(state).releasedSK;
        const currentScheduleState = current(state).scheduleSK;
        const currentPrivateState = current(state).privateSK;
        const currentDraftsState = current(state).draftsSK;
        const newTrack = (trackOld) => {
          return trackOld.items.filter((item) => {
            return !action.payload.includes(item._id);
          });
        };
        return {
          ...state,
          releasedSK: {
            ...state.releasedSK,
            items: newTrack(currentReleaseState),
          },
          scheduleSK: {
            ...state.scheduleSK,
            items: newTrack(currentScheduleState),
          },
          privateSK: {
            ...state.privateSK,
            items: newTrack(currentPrivateState),
          },
          draftsSK: {
            ...state.draftsSK,
            items: newTrack(currentDraftsState),
          },
        };
      }
    )
    .addCase(fetchDraftsSK.REQUEST, (state, action) => {
      return {
        ...state,
        isLoading: true,
        draftsSK: {
          ...state.draftsSK,
          loadingMore: action?.payload?.loadingMore || false,
          page: action?.payload?.page,
        },
      };
    })
    .addCase(fetchDraftsSK.SUCCESS, (state, action: PayloadAction<any>) => {
      const newDraftSK =
        state.draftsSK.page === 1
          ? action.payload
          : [...state.draftsSK.items, ...action.payload];
      return {
        ...state,
        draftsSK: {
          ...state.draftsSK,
          items: newDraftSK,
          hasMore: action.payload.length === 25,
          loadingMore: false,
        },
        isLoading: false,
      };
    })
    .addCase(fetchDraftsSK.ERROR, (state, action) => {
      return {
        ...state,
        isLoading: false,
        draftsSK: {
          ...state.draftsSK,
          loadingMore: false,
          page: action?.payload?.page - 1,
        },
      };
    })
    .addCase(
      mySoundkitActions.fetchSoundkitsPending.pending,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
          pendingSK: {
            ...state.pendingSK,
            loadingMore: action.meta.arg?.loadingMore || false,
            page: action.meta.arg?.page,
          },
        };
      }
    )
    .addCase(
      mySoundkitActions.fetchSoundkitsPending.fulfilled,
      (state, action: PayloadAction<any>) => {
        const statePending = current(state.pendingSK);
        const newPendingSK =
          statePending?.page === 1
            ? action.payload
            : [...statePending?.items, ...action.payload];
        return {
          ...state,
          pendingSK: {
            ...state.pendingSK,
            items: newPendingSK,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
          isLoading: false,
        };
      }
    )
    .addCase(
      mySoundkitActions.fetchSoundkitsPending.rejected,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          pendingSK: {
            ...state.pendingSK,
            loadingMore: false,
            page: action.meta.arg?.page - 1,
          },
        };
      }
    )
    .addCase(fetchRepostsSK.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        repostsSK: action.payload,
      };
    })
    .addCase(setRepostsSK.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        repostsSK: [...state.repostsSK, action.payload],
      };
    })

    .addCase(soundkitActions.deleteSoundkitRelease.pending, (state, action) => {
      return {
        ...state,
      };
    })

    .addCase(
      soundkitActions.deleteSoundkitRelease.fulfilled,
      (state, action: PayloadAction<any>) => {
        const deleteItem = (items: TrackSoundKit[]) =>
          items.filter((item) => item._id !== action.payload);

        return {
          ...state,
          releasedSK: {
            ...state.releasedSK,
            items: deleteItem(state.releasedSK.items),
          },
          scheduleSK: {
            ...state.scheduleSK,
            items: deleteItem(state.scheduleSK.items),
          },
          draftsSK: {
            ...state.draftsSK,
            items: deleteItem(state.draftsSK.items),
          },
          privateSK: {
            ...state.privateSK,
            items: deleteItem(state.privateSK.items),
          },
        };
      }
    )

    .addCase(fetchPrivateSK.REQUEST, (state, action) => {
      return {
        ...state,
        isLoading: true,
        privateSK: {
          ...state.privateSK,
          loadingMore: action?.payload?.loadingMore || false,
          page: action?.payload?.page,
        },
      };
    })
    .addCase(fetchPrivateSK.SUCCESS, (state, action: PayloadAction<any>) => {
      const newPrivateSK =
        state.privateSK.page === 1
          ? action.payload
          : [...state.privateSK.items, ...action.payload];
      return {
        ...state,
        privateSK: {
          ...state.privateSK,
          items: newPrivateSK,
          hasMore: action.payload.length === 25,
          loadingMore: false,
        },
        isLoading: false,
      };
    })
    .addCase(fetchPrivateSK.ERROR, (state, action) => {
      return {
        ...state,
        isLoading: false,
        privateSK: {
          ...state.privateSK,
          loadingMore: false,
          page: action?.payload?.page - 1,
        },
      };
    })
    .addCase(fetchScheduleSK.REQUEST, (state, action) => {
      return {
        ...state,
        isLoading: true,
        scheduleSK: {
          ...state.scheduleSK,
          loadingMore: action?.payload?.loadingMore || false,
          page: action?.payload?.page,
        },
      };
    })
    .addCase(fetchScheduleSK.SUCCESS, (state, action: PayloadAction<any>) => {
      const newScheduleSK =
        state.scheduleSK.page === 1
          ? action.payload
          : [...state.scheduleSK.items, ...action.payload];
      return {
        ...state,
        scheduleSK: {
          ...state.scheduleSK,
          items: newScheduleSK,
          hasMore: action.payload.length === 25,
          loadingMore: false,
        },
        isLoading: false,
      };
    })
    .addCase(fetchScheduleSK.ERROR, (state, action) => {
      return {
        ...state,
        isLoading: false,
        scheduleSK: {
          ...state.scheduleSK,
          loadingMore: false,
          page: action?.payload?.page - 1,
        },
      };
    })
    .addCase(getIndividualSK.REQUEST, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(getIndividualSK.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isLoading: false,
        soundkitDetail: action.payload,
      };
    })
    .addCase(getIndividualSK.ERROR, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    })
    .addCase(
      mySoundkitActions.likeSoundPacksDetail.fulfilled,
      (state, action) => {
        const currentSoundPacksDetail = current(state).soundkitDetail;
        return {
          ...state,
          soundkitDetail: {
            ...state.soundkitDetail,
            totalFavs: Number(currentSoundPacksDetail.totalFavs) + 1,
          },
        };
      }
    )
    .addCase(mySoundkitActions.errorLikeSoundkitDetail, (state, action) => {
      const currentSoundPacksDetail = current(state).soundkitDetail;
      return {
        ...state,
        soundkitDetail: {
          ...state.soundkitDetail,
          totalFavs: Number(currentSoundPacksDetail.totalFavs) - 1,
        },
      };
    })
    .addCase(mySoundkitActions.errorUnLikeSoundkitDetail, (state, action) => {
      const currentSoundPacksDetail = current(state).soundkitDetail;
      return {
        ...state,
        soundkitDetail: {
          ...state.soundkitDetail,
          totalFavs: Number(currentSoundPacksDetail.totalFavs) + 1,
        },
      };
    })
    .addCase(
      mySoundkitActions.unLikeSoundPacksDetail.fulfilled,
      (state, action) => {
        const currentSoundPacksDetail = current(state).soundkitDetail;
        return {
          ...state,
          soundkitDetail: {
            ...state.soundkitDetail,
            totalFavs: Number(currentSoundPacksDetail.totalFavs) - 1,
          },
        };
      }
    )
    .addCase(
      mySoundkitActions.repostSoundPacksDetail.fulfilled,
      (state, action) => {
        const currentSoundPacksDetail = current(state).soundkitDetail;
        return {
          ...state,
          soundkitDetail: {
            ...state.soundkitDetail,
            totalRepost: Number(currentSoundPacksDetail.totalRepost) + 1,
          },
        };
      }
    )
);
