import { Box, Flex, Text, Image, Divider } from "@chakra-ui/react";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import AvatarUser from "app/assets/images/UserAvatar.png";
import IconDownloadFiles from "app/assets/icons/IconDownloadFiles";
import { transaction } from "app/models";
import { TRACK_TYPE } from "constants/trackTypes";
import { formatTimeByDayMonthYear } from "utils/timeAgo";
import { TRACK_STATE } from "constants/index";
import downloadFromUrl from "utils/convertTracks";
import DeviceDetector from "device-detector-js";
import { useDispatch } from "react-redux";
import { getIpAddress } from "utils/getData";
import { createDownloadLog } from "app/redux/actions/purchased";
import dayjs from "dayjs";
import { createModalDownloadTrackAnalytics } from "app/redux/actions/tracks";

interface ItemPurchasedMobileProps {
  purchased: transaction;
  index: number;
}

export default function ItemPurchasedMobile({
  index,
  purchased,
}: ItemPurchasedMobileProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const currentDate = dayjs();

  const trackOrSoundPack = useMemo(() => {
    if (!!purchased.track) {
      return purchased?.track;
    } else {
      return purchased?.soundkit;
    }
  }, [purchased?.soundkit, purchased.track]);

  const collaborations = useMemo(() => {
    if (trackOrSoundPack?.collaborators?.length > 1) {
      return trackOrSoundPack?.collaborators?.filter(
        (item) => item.user?._id !== trackOrSoundPack?.createdBy?._id
      );
    }
  }, [trackOrSoundPack?.collaborators, trackOrSoundPack?.createdBy?._id]);

  const handleCreateDownloadLog = useCallback(
    async (
      file: string,
      trackIdOrSoundPackId: string,
      title: string,
      isTrack?: boolean
    ) => {
      const ipAddress = await getIpAddress();
      let bodyCreateTrackDownloadLog = {};
      if (isTrack) {
        bodyCreateTrackDownloadLog = {
          ipAddress: ipAddress,
          fileDownload: file,
          transactionId: purchased?._id,
          trackId: trackIdOrSoundPackId,
          device: device?.os?.name,
          browser: device.client?.name,
        };
      } else {
        bodyCreateTrackDownloadLog = {
          ipAddress: ipAddress,
          fileDownload: file,
          transactionId: purchased?._id,
          soundPackId: trackIdOrSoundPackId,
          device: device.os?.name,
          browser: device.client?.name,
        };
      }
      downloadFromUrl(file, title);
      dispatch(createDownloadLog(bodyCreateTrackDownloadLog));
      dispatch(
        createModalDownloadTrackAnalytics({
          _id: trackIdOrSoundPackId,
          isTrack: isTrack,
        })
      );
    },

    [device.client?.name, device.os?.name, dispatch, purchased?._id]
  );

  const downloadFileTrack = useMemo(
    () => [
      {
        id: 1,
        name: purchased?.track?.untaggedFileUrl
          ? purchased?.track?.untaggedFileUrl?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.untaggedFileUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 4,
        name: purchased?.track?.untaggedFileUrlWav
          ? purchased?.track?.untaggedFileUrlWav?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.untaggedFileUrlWav,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 0,
        name: purchased?.track?.taggedFileUrl
          ? purchased?.track?.taggedFileUrl?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.taggedFileUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 2,
        name: purchased?.track?.trackStemsUrl ? "STEMS" : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.trackStemsUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
    ],
    [
      handleCreateDownloadLog,
      purchased?.track?._id,
      purchased?.track?.taggedFileUrl,
      purchased?.track?.title,
      purchased?.track?.trackStemsUrl,
      purchased?.track?.untaggedFileUrl,
      purchased?.track?.untaggedFileUrlWav,
    ]
  );

  const downloadFileSoundPacks = useMemo(
    () => [
      {
        id: 0,
        name: purchased?.soundkit?.audioFileUrl?.includes("mp3")
          ? "MP3"
          : "WAV",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.soundkit?.audioFileUrl,
            purchased?.soundkit?._id,
            purchased?.soundkit?.title
          ),
      },
      {
        id: 1,
        name: "ZIP",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.soundkit?.downloadFilesUrl,
            purchased?.soundkit?._id,
            purchased?.soundkit?.title
          ),
      },
    ],
    [
      handleCreateDownloadLog,
      purchased?.soundkit?._id,
      purchased?.soundkit?.audioFileUrl,
      purchased?.soundkit?.downloadFilesUrl,
      purchased?.soundkit?.title,
    ]
  );

  const createdAt = dayjs(purchased?.createdAt);

  const licensesName = useMemo(() => {
    if (!!purchased?.track) {
      return (trackOrSoundPack?.licenses || []).filter(
        (license) => license._id === purchased?.license
      );
    }
  }, [purchased?.license, purchased?.track, trackOrSoundPack?.licenses]);

  const timeAndStatusEx = useMemo(() => {
    if (!!purchased?.track) {
      const timeEx = createdAt.add(licensesName?.[0]?.licenseRenewal, "year");
      const checkTimeEx = currentDate.isAfter(
        purchased?.expirationDate || timeEx
      );
      if (licensesName?.[0]?.exclusive) {
        return { name: t("indefinitely"), status: t("Active") };
      } else {
        return {
          name: formatTimeByDayMonthYear(purchased?.expirationDate || timeEx),
          status: checkTimeEx ? t("negotiationsStatus.EXPIRED") : t("Active"),
        };
      }
    }
    return { name: t("indefinitely"), status: t("Active") };
  }, [
    createdAt,
    currentDate,
    licensesName,
    purchased?.track,
    t,
    purchased?.expirationDate,
  ]);

  return (
    <>
      <Box
        padding="20px 20px 30px 20px"
        background="#1E1F23"
        mb="20px"
        borderRadius="4px"
      >
        <Flex alignItems="center" justifyContent="space-between" mb="5px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("trackName")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("seller")}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb="10px">
          <Flex alignItems="center" w="70%">
            <Image
              w="35px"
              h="36px"
              borderRadius="6px"
              fallbackSrc={ImageDefault}
              src={trackOrSoundPack?.coverUrl}
            />
            <Box ml="10px" w="80%" overflow="hidden">
              <Text fontSize="16px" fontWeight="400" noOfLines={1}>
                {trackOrSoundPack?.title}
              </Text>
              <Flex mt="5px">
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="4px"
                  padding={{ base: "2px 6px", lg: "2px 10px" }}
                  mr="5px"
                  mb="5px"
                  backgroundColor="#2b2e1b"
                  maxW={{ base: "100%", md: "85px", lg: "100%" }}
                >
                  <Text
                    fontSize="12px"
                    lineHeight="14px"
                    color="#d4f70e"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {t(TRACK_TYPE[trackOrSoundPack?.type])}
                  </Text>
                </Box>
                <Text
                  fontSize="14px"
                  fontWeight="400"
                  color="#464649"
                  cursor="pointer"
                  noOfLines={1}
                >
                  {`By ${trackOrSoundPack?.createdBy?.name}`}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Flex gap="10px" w="30%" justifyContent="end">
            <Image
              w="40px"
              h="40px"
              borderRadius="full"
              fallbackSrc={AvatarUser}
              src={purchased?.sellerId?.profileImage}
            />
            {collaborations &&
              collaborations?.map((item) => (
                <Image
                  key={item?._id}
                  w="40px"
                  h="40px"
                  borderRadius="full"
                  fallbackSrc={AvatarUser}
                  src={item?.user?.profileImage}
                  ml="-20px"
                />
              ))}
            <Box overflow="hidden">
              <Flex gap="5px" overflow="hidden">
                <Text fontSize="14px" fontWeight="400" noOfLines={1}>
                  {purchased?.sellerId?.name}
                </Text>
                {collaborations && (
                  <Text fontSize="14px" fontWeight="400" noOfLines={1}>
                    + {collaborations?.length} {t("others")}
                  </Text>
                )}
              </Flex>

              <Text
                fontSize="14px"
                fontWeight="400"
                color="#464649"
                noOfLines={1}
              >
                {t("seller")}
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("transactionId")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("purchasedDate")}
          </Text>
        </Flex>

        <Flex justifyContent="space-between" alignItems="center" mb="20px">
          <Text fontSize="12px" fontWeight="400" w="50%">
            {purchased?._id}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            w="50%"
            textAlign="end"
            noOfLines={1}
          >
            {formatTimeByDayMonthYear(purchased?.createdAt)}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("downloadConfirmation")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("licenseStatus")}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" mb="10px">
          {purchased?.statusDownload === TRACK_STATE.PENDING ? (
            <Flex w="50%">
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                padding="4px 10px"
                backgroundColor="#193347"
                alignSelf="start"
              >
                <Text fontSize="12px" fontWeight="500" color="#1DA1F2">
                  {t("Pending")}
                </Text>
              </Box>
            </Flex>
          ) : (
            <Box
              w="50%"
              display="flex"
              flexDirection="column"
              alignItems={{ base: "start", md: "center" }}
            >
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                padding="4px 10px"
                mb="8px"
                backgroundColor="#2b2e1b"
              >
                <Text fontSize="12px" fontWeight="500" color="#d4f70e">
                  {t("Downloaded")}
                </Text>
              </Box>
              <Text
                w={{ base: "100%", md: "70%" }}
                textAlign={{ base: "unset", md: "center" }}
                fontSize="10px"
                fontWeight="400"
              >
                {formatTimeByDayMonthYear(purchased?.timeDownload)}
              </Text>
            </Box>
          )}
          <Box display="flex" flexDirection="column" alignItems="end">
            <Box
              display="flex"
              alignItems="center"
              borderRadius="4px"
              padding="4px 10px"
              mb="5px"
              backgroundColor={
                timeAndStatusEx?.status === t("Active") ? "#2b2e1b" : "#431E21"
              }
            >
              <Text
                fontSize="12px"
                fontWeight="500"
                color={
                  timeAndStatusEx?.status === t("Active")
                    ? "#d4f70e"
                    : "#ED3636"
                }
              >
                {timeAndStatusEx?.status}
              </Text>
            </Box>
            <Text fontSize="10px" fontWeight="400" textAlign="end">
              {t("expireOn")} {""}
              <span>{timeAndStatusEx?.name}</span>
            </Text>
          </Box>
        </Flex>
        <Text fontSize="14px" color="#777777" fontWeight="400" mb="10px">
          {t("downloadFiles")}
        </Text>
        <Flex gap="10px">
          {(!!purchased.track
            ? downloadFileTrack
            : downloadFileSoundPacks
          )?.map(
            (item) =>
              item.name && (
                <Flex key={item.id}>
                  <Box>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      padding="2px 30px"
                      background="#3D4419"
                      color="#D4F70E"
                      borderRadius="4px"
                      onClick={item.onClick}
                      cursor="pointer"
                    >
                      <IconDownloadFiles />
                      <Text fontSize="8px" fontWeight="400">
                        {item.name}
                      </Text>
                    </Box>
                  </Box>
                </Flex>
              )
          )}
        </Flex>
      </Box>
      <Divider borderColor="#535353" m="20px 0px" />
    </>
  );
}
