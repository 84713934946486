import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";

export default function SkeletonShareShortLink() {
  return (
    <Box mb="30px">
      <Flex alignItems="center">
        <Image
          w="72px"
          h="72px"
          borderRadius="4px"
          fallbackSrc={ImageDefault}
        />
        <Box ml={{ base: "20px", xl: "30px" }}>
          <Skeleton w="140px" h="16px" />
          <Flex alignItems="center">
            <Skeleton w="44px" h="21px" borderRadius="2px" />
            <Skeleton w="109px" h="16px" />
          </Flex>
        </Box>
      </Flex>
      <Box mt={{ base: "30px", xl: "50px" }}>
        <Skeleton w="109px" h="16px" />
        <Flex gap="10px" mt="15px" mb="20px">
          <Skeleton
            w="237px"
            h={{ base: "38px", xl: "48px" }}
            borderRadius="4px"
          />
          <Skeleton
            w="109px"
            h={{ base: "38px", xl: "48px" }}
            borderRadius="4px"
          />
        </Flex>
        <Skeleton w="109px" h="16px" />
        <Flex gap="10px" mt="15px">
          <Skeleton
            w="635px"
            h={{ base: "38px", xl: "48px" }}
            borderRadius="4px"
          />
          <Skeleton
            w="109px"
            h={{ base: "38px", xl: "48px" }}
            borderRadius="4px"
          />
        </Flex>
      </Box>
    </Box>
  );
}
