import { createReducer, current } from "@reduxjs/toolkit";
import {
  RecentAction,
  RecommendedModel,
  TrackSoundKit,
  User,
} from "app/models";
import * as homePageActions from "app/redux/actions/home";
import * as increasingTrackActions from "app/redux/actions/tracks";
import {
  addRepostRecentAction,
  addRepostSKRecentAction,
  handleLikeOrUnlikeSoundKitRecentActionProfile,
  handleLikeOrUnLikeTrackInRecentAction,
  handleLikeOrUnLikePlaylist,
  addRepostPlaylistRecentAction,
} from "utils/addFavorite";
import { updateFollowUnfollow } from "utils/convertData";

export interface HomePageState {
  hotproducersItem: User[];
  recentActions: any;
  newArtists: RecommendedModel[];
  newProducers: RecommendedModel[];
  recommendedArtists: RecommendedModel[];
  recommendedMusic: RecommendedModel[];
  freeBeats: TrackSoundKit[];
  discount: TrackSoundKit[];
}

const initialState: HomePageState = {
  hotproducersItem: [],
  recentActions: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      refreshing: false,
      hasMore: true,
    },
  },
  newArtists: [],
  newProducers: [],
  recommendedArtists: [],
  recommendedMusic: [],
  freeBeats: [],
  discount: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(homePageActions.fetchHotProducer.fulfilled, (state, action) => {
      return {
        ...state,
        hotproducersItem: action.payload,
      };
    })
    .addCase(
      homePageActions.fetchRecentActionsHomePage.pending,
      (state, action) => {
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            options: {
              ...state.recentActions.options,
              ...action.meta.arg,
            },
          },
        };
      }
    )
    .addCase(
      homePageActions.fetchRecentActionsHomePage.fulfilled,
      (state, action) => {
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items:
              state.recentActions.options.page === 1
                ? Array.from(action?.payload?.items)
                : [...state.recentActions.items, ...action?.payload?.items],
            options: {
              ...state.recentActions.options,
              hasMore: action.payload.hasMoreItems,
              refreshing: false,
              loadingMore: false,
            },
          },
        };
      }
    )

    .addCase(homePageActions.fetchNewArtists.fulfilled, (state, action) => {
      return {
        ...state,
        newArtists: action.payload,
      };
    })
    .addCase(homePageActions.fetchNewProducers.fulfilled, (state, action) => {
      return {
        ...state,
        newProducers: action.payload,
      };
    })
    .addCase(
      homePageActions.fetchRecommendedArtists.fulfilled,
      (state, action) => {
        return {
          ...state,
          recommendedArtists: action.payload,
        };
      }
    )
    .addCase(
      homePageActions.fetchRecommendedMusic.fulfilled,
      (state, action) => {
        return {
          ...state,
          recommendedMusic: action.payload,
        };
      }
    )
    .addCase(homePageActions.fetchFreeBeats.fulfilled, (state, action) => {
      return {
        ...state,
        freeBeats: action.payload,
      };
    })
    .addCase(homePageActions.fetchDiscount.fulfilled, (state, action) => {
      return {
        ...state,
        discount: action.payload,
      };
    })
    .addCase(
      homePageActions.followUnfollowRecentActionsHome,
      (state, action) => {
        const { recentActions } = current(state);
        return {
          ...state,
          recentActions: {
            ...recentActions,
            items: recentActions.items.map((item: RecentAction) => {
              if (item.createdBy._id === action.payload) {
                return {
                  ...item,
                  createdBy: {
                    ...item.createdBy,
                    isFollowing: updateFollowUnfollow(item).isFollowing,
                    followers: updateFollowUnfollow(item).followers,
                  },
                };
              } else {
                return item;
              }
            }),
          },
        };
      }
    )
    .addCase(
      homePageActions.likeTrackInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const _id = action?.payload?.trackId;
        const currentState = current(state);
        return handleLikeOrUnLikeTrackInRecentAction(
          currentState,
          { _id },
          true
        );
      }
    )
    .addCase(
      homePageActions.errorLikeRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findTrack = recentActionsHomePage.map((item) => {
          if (item?.track?._id === action.payload) {
            return {
              ...item,
              track: {
                ...item.track,
                totalFavs: item?.track?.totalFavs - 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findTrack,
          },
        };
      }
    )

    .addCase(
      homePageActions.unLikeTrackInRecentActionsHomepage.fulfilled,
      (state, action) => {
        if (action.payload?.trackId) {
          const _id = action?.payload?.trackId;
          const currentState = current(state);
          return handleLikeOrUnLikeTrackInRecentAction(
            currentState,
            { _id },
            false
          );
        }
      }
    )
    .addCase(
      homePageActions.errorUnLikeRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findTrack = recentActionsHomePage.map((item) => {
          if (item?.track?._id === action.payload) {
            return {
              ...item,
              track: {
                ...item.track,
                totalFavs: item?.track?.totalFavs + 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findTrack,
          },
        };
      }
    )
    .addCase(
      homePageActions.likeSoundkitInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const _id = action?.payload?.soundkitId;
        const currentState = current(state);
        return handleLikeOrUnlikeSoundKitRecentActionProfile(
          currentState,
          { _id },
          true
        );
      }
    )
    .addCase(
      homePageActions.errorLikeSoundkitRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findSoundkit = recentActionsHomePage.map((item) => {
          if (item?.soundkit?._id === action.payload) {
            return {
              ...item,
              soundkit: {
                ...item.soundkit,
                totalFavs: item?.soundkit?.totalFavs - 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findSoundkit,
          },
        };
      }
    )
    .addCase(
      homePageActions.unLikeSoundkitInRecentActionsHomepage.fulfilled,
      (state, action) => {
        if (action?.payload?.soundkitId) {
          const _id = action?.payload?.soundkitId;
          const currentState = current(state);
          return handleLikeOrUnlikeSoundKitRecentActionProfile(
            currentState,
            { _id },
            false
          );
        }
      }
    )
    .addCase(
      homePageActions.errorUnLikeSoundkitRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findSoundkit = recentActionsHomePage.map((item) => {
          if (item?.soundkit?._id === action.payload) {
            return {
              ...item,
              soundkit: {
                ...item.soundkit,
                totalFavs: item?.soundkit?.totalFavs + 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findSoundkit,
          },
        };
      }
    )
    .addCase(
      homePageActions.repostTrackInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const recentActionReposted = (
          (state.recentActions.items as RecentAction[]) || []
        ).find(
          (it: RecentAction) => it?.track?._id === action?.payload?.trackId
        );
        const newArrRecentAction = addRepostRecentAction(
          state.recentActions.items,
          recentActionReposted,
          true
        );
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: [...newArrRecentAction],
          },
        };
      }
    )
    .addCase(
      homePageActions.repostSoundkitInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const soundKitRecentActionRepost = (
          (state.recentActions.items as RecentAction[]) || []
        ).find((it: RecentAction) => it?.soundkit?._id === action?.payload);

        const newRepost = addRepostSKRecentAction(
          state.recentActions.items,
          soundKitRecentActionRepost,
          true
        );
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: [...newRepost],
          },
        };
      }
    )
    .addCase(
      homePageActions.likePlaylistInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const _id = action?.payload?.playlistId;
        const currentState = current(state);
        return handleLikeOrUnLikePlaylist(currentState, { _id }, true);
      }
    )
    .addCase(
      homePageActions.errorLikePlaylistsRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findPlaylists = recentActionsHomePage.map((item) => {
          if (item?.playlist?._id === action.payload) {
            return {
              ...item,
              playlist: {
                ...item.playlist,
                totalFavs: item?.playlist?.totalFavs - 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findPlaylists,
          },
        };
      }
    )
    .addCase(
      homePageActions.errorUnLikePlaylistsRecentActionsHomepage,
      (state, action) => {
        const recentActionsHomePage = current(state.recentActions.items);
        const findPlaylists = recentActionsHomePage.map((item) => {
          if (item?.playlist?._id === action.payload) {
            return {
              ...item,
              playlist: {
                ...item.playlist,
                totalFavs: item?.playlist?.totalFavs + 1,
              },
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: findPlaylists,
          },
        };
      }
    )
    .addCase(
      homePageActions.unlikePlaylistInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const _id = action?.payload?.playlistId;
        const currentState = current(state);
        return handleLikeOrUnLikePlaylist(currentState, { _id }, false);
      }
    )
    .addCase(
      homePageActions.repostPlaylistInRecentActionsHomepage.fulfilled,
      (state, action) => {
        const recentActionReposted = (
          (state.recentActions.items as RecentAction[]) || []
        ).find(
          (it: RecentAction) =>
            it?.playlist?._id === action?.payload?.playlistId
        );
        const newArrRecentAction = addRepostPlaylistRecentAction(
          state.recentActions.items,
          recentActionReposted,
          true
        );
        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: [...newArrRecentAction],
          },
        };
      }
    )
    .addCase(
      increasingTrackActions.increasingTrackPlay.fulfilled,
      (state, action) => {
        const currentRecentActions = current(state.recentActions);
        const updateRecentAction = (currentRecentActions.items || []).map(
          (item: RecentAction) => {
            if (item?.track?._id === action.payload) {
              return {
                ...item,
                track: {
                  ...item.track,
                  totalPlay: Number(item?.track?.totalPlay) + 1,
                },
              };
            } else {
              return item;
            }
          }
        );

        return {
          ...state,
          recentActions: {
            ...state.recentActions,
            items: updateRecentAction,
          },
        };
      }
    )
);
