import React from "react";

const IconMusicRecording = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 9.75C3.36193 9.75 3.25 9.86193 3.25 10V20C3.25 20.1381 3.36193 20.25 3.5 20.25H20.5C20.6381 20.25 20.75 20.1381 20.75 20V10C20.75 9.86193 20.6381 9.75 20.5 9.75H3.5ZM1.75 10C1.75 9.0335 2.5335 8.25 3.5 8.25H20.5C21.4665 8.25 22.25 9.0335 22.25 10V20C22.25 20.9665 21.4665 21.75 20.5 21.75H3.5C2.5335 21.75 1.75 20.9665 1.75 20V10Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 6C3.25 5.58579 3.58579 5.25 4 5.25H20C20.4142 5.25 20.75 5.58579 20.75 6C20.75 6.41421 20.4142 6.75 20 6.75H4C3.58579 6.75 3.25 6.41421 3.25 6Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 3C6.75 2.58579 7.08579 2.25 7.5 2.25H16.5C16.9142 2.25 17.25 2.58579 17.25 3C17.25 3.41421 16.9142 3.75 16.5 3.75H7.5C7.08579 3.75 6.75 3.41421 6.75 3Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 11.25C13.4142 11.25 13.75 11.5858 13.75 12V15C13.75 15.4142 13.4142 15.75 13 15.75C12.5858 15.75 12.25 15.4142 12.25 15V12C12.25 11.5858 12.5858 11.25 13 11.25Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.44 15.75C10.0257 15.75 9.75 16.0621 9.75 16.3745V16.6255C9.75 16.9379 10.0257 17.25 10.44 17.25H11.56C11.9743 17.25 12.25 16.9379 12.25 16.6255V15.75H10.44ZM8.25 16.3745C8.25 15.1687 9.26374 14.25 10.44 14.25H13C13.4142 14.25 13.75 14.5858 13.75 15V16.6255C13.75 17.8313 12.7363 18.75 11.56 18.75H10.44C9.26374 18.75 8.25 17.8313 8.25 16.6255V16.3745Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2647 11.8531C12.3459 11.4469 12.7411 11.1835 13.1472 11.2647L15.6472 11.7647C16.0534 11.8459 16.3168 12.2411 16.2356 12.6472C16.1543 13.0534 15.7592 13.3168 15.3531 13.2356L12.8531 12.7356C12.4469 12.6543 12.1835 12.2592 12.2647 11.8531Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconMusicRecording;
