import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBoolean,
  Image,
} from "@chakra-ui/react";
import BiDotsHorizontal from "app/assets/icons/biDotHorizon";
import { selectUserAdmin } from "app/redux/selectors/users";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AvatarUser from "app/assets/images/UserAvatar.png";

interface AddCollaborationsItemProps {
  avatar?: string;
  name?: string;
  role?: string;
  profitShare?: number;
  publishing?: number;
  user?: string;
  isAddSoundkit?: boolean;
  handleDeleteCollaborators: () => void;
  onSetSelectedCollaborator: ({
    user,
    publishing,
    profitShare,
    role,
  }: {
    user: {
      name: string;
      avatar: string;
      user: string;
    };
    publishing: number;
    profitShare: number;
    role: string;
  }) => void;
}

export default function AddCollaborationsItem({
  user = "",
  avatar = "",
  name = "",
  role = "",
  profitShare = 0,
  publishing = 0,
  isAddSoundkit,
  onSetSelectedCollaborator,
  handleDeleteCollaborators,
}: AddCollaborationsItemProps) {
  const { t } = useTranslation();
  const userAdmin = useSelector(selectUserAdmin);
  const [showPopove, setShowPopove] = useBoolean();

  const handleEditUser = useCallback(() => {
    onSetSelectedCollaborator({
      user: {
        name: name,
        avatar: avatar,
        user: user,
      },
      publishing,
      profitShare,
      role,
    });
  }, [
    avatar,
    name,
    onSetSelectedCollaborator,
    profitShare,
    publishing,
    role,
    user,
  ]);

  return (
    <Flex
      mb="10px"
      borderBottom="1px solid #26262A"
      paddingBottom="10px"
      alignItems="center"
    >
      <Flex alignItems="center" w={isAddSoundkit ? "30%" : "25%"}>
        <Image
          fallbackSrc={AvatarUser}
          w="32px"
          height="32px"
          borderRadius="full"
          src={avatar}
        />
        <Text
          ml={{ base: "5px", md: "15px" }}
          fontFamily="Cera Pro Regular"
          fontSize={{ base: "14px", md: "16px" }}
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          w="70%"
        >
          {name}
        </Text>
      </Flex>
      <Text
        fontSize={{ base: "14px", md: "16px" }}
        w={isAddSoundkit ? "30%" : "25%"}
        fontFamily="Cera Pro Regular"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflow="hidden"
      >
        {role}
      </Text>
      <Text
        fontSize={{ base: "14px", md: "16px" }}
        w={isAddSoundkit ? "30%" : "20%"}
        fontFamily="Cera Pro Regular"
      >
        {profitShare}
      </Text>
      {!isAddSoundkit && (
        <Text
          fontSize={{ base: "14px", md: "16px" }}
          w="20%"
          fontFamily="Cera Pro Regular"
        >
          {publishing}
        </Text>
      )}
      <Box w="10%" display="flex" justifyContent="flex-end">
        <Popover
          isOpen={showPopove}
          closeOnBlur={true}
          onOpen={setShowPopove.on}
          onClose={setShowPopove.off}
        >
          <PopoverTrigger>
            <Box cursor="pointer">
              <BiDotsHorizontal />
            </Box>
          </PopoverTrigger>
          <Box zIndex={120}>
            <PopoverContent
              backgroundColor="#25252A"
              border="none"
              maxW="180px"
              _focus={{
                boxShadow: "none",
              }}
            >
              <PopoverArrow
                backgroundColor="#25252A "
                borderColor="#25252A "
                boxShadow="unset !important"
              />

              <PopoverBody>
                <Flex flexDirection="column">
                  <Button
                    backgroundColor="#2D2E33"
                    _hover={{ background: "#2D2E33" }}
                    onClick={handleEditUser}
                  >
                    {t("Edit")}
                  </Button>
                  {name !== userAdmin?.name && (
                    <Button
                      mt="10px"
                      _hover={{ background: "#ED3536" }}
                      backgroundColor="#ED3536"
                      onClick={handleDeleteCollaborators}
                    >
                      {t("delete")}
                    </Button>
                  )}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Popover>
      </Box>
    </Flex>
  );
}
