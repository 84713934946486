import { yupResolver } from "@hookform/resolvers/yup";
import {
  LicenseAndContracts,
  selectLicenseDetail,
} from "app/redux/selectors/licenseAndContracts";
import { useForm } from "react-hook-form";
import {
  changeActiveLicense,
  clearFormLicense,
  deleteLicenseContract,
  getLicenseContract,
  startEditLicense,
  changeHighlightLicense,
} from "app/redux/actions/licenseAndContracts";
import { useCallback, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import formSchemaLicense from "./formSchema";
import { selectIsCreateNewLicenseSelector } from "app/redux/selectors/subscription";
import { checkCreateNewLicense } from "app/redux/actions/subscriptions";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useTranslation } from "react-i18next";
import { EXCLUSIVE_LICENSE } from "constants/index";
import { selectUserAdmin } from "app/redux/selectors/users";

export const useLicenseContracts = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const LicenseContracts = useSelector(LicenseAndContracts);
  const isCreateNewLicense = useSelector(selectIsCreateNewLicenseSelector);
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([checkCreateNewLicense.typePrefix], state)
  );
  const currentUser = useSelector(selectUserAdmin);

  const licenseDetail = useSelector(selectLicenseDetail);

  const initvalueLicense = {
    ...licenseDetail,
    state: currentUser?.state,
    country: licenseDetail?.country || currentUser?.country,
  };

  const [isShowInput, setIsShowInput] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const methods = useForm({
    defaultValues: initvalueLicense,
    resolver: yupResolver(formSchemaLicense()),
  });

  const { watch } = methods;
  const newLicense = watch();

  const {
    name,
    defaultTrackPrice,
    exclusive: typeExclusive,
    beatsPublishing,
    beatsHookPublishing,
    topLinesAndVocals,
    licenseRenewal,
    state,
    country,
  } = newLicense;

  const tabsMenu = useMemo(
    () => [
      {
        name: t("generalInfo"),
        id: 0,
        isCheck: !!name && !!defaultTrackPrice && !!typeExclusive,
      },
      {
        name: t("fieldToDeliver"),
        id: 1,
        isCheck: !!name && !!defaultTrackPrice && !!typeExclusive && true,
      },
      {
        name: t("contractDetail"),
        id: 2,
        isCheck:
          typeExclusive === EXCLUSIVE_LICENSE.EXCLUSIVE
            ? !!beatsHookPublishing || !!beatsPublishing || !!topLinesAndVocals
            : !!licenseRenewal,
      },
      {
        name: t("address"),
        id: 3,
        isCheck:
          !!name &&
          !!defaultTrackPrice &&
          !!typeExclusive &&
          typeExclusive === EXCLUSIVE_LICENSE.EXCLUSIVE
            ? !!beatsHookPublishing || !!beatsPublishing || !!topLinesAndVocals
            : !!licenseRenewal && !!state && !!country,
      },
      {
        name: t("description"),
        id: 4,
        isCheck:
          !!name &&
          !!defaultTrackPrice &&
          !!typeExclusive &&
          typeExclusive === EXCLUSIVE_LICENSE.EXCLUSIVE
            ? !!beatsHookPublishing || !!beatsPublishing || !!topLinesAndVocals
            : !!licenseRenewal && !!state && !!country && true,
      },
    ],
    [
      beatsHookPublishing,
      beatsPublishing,
      country,
      defaultTrackPrice,
      licenseRenewal,
      name,
      state,
      t,
      topLinesAndVocals,
      typeExclusive,
    ]
  );

  const getLicenseAndContracts = useCallback(() => {
    dispatch(getLicenseContract());
  }, [dispatch]);

  const checkCreateLicense = useCallback(() => {
    dispatch(checkCreateNewLicense());
  }, [dispatch]);

  const nonExclusive = (LicenseContracts?.licenses || []).filter(
    (item: any) => item.exclusive === false
  );

  const exclusive = (LicenseContracts?.licenses || []).filter(
    (item: any) => item.exclusive === true
  );

  const handleDeleteLicenseContract = (id: string) => {
    dispatch(deleteLicenseContract(id));
  };

  const handleStartEditLicense = (id: string) => {
    dispatch(startEditLicense(id));
  };

  const handleClearFormLicense = () => {
    dispatch(clearFormLicense());
  };

  const handleChangeActiveLicense = (id: string) => {
    dispatch(changeActiveLicense(id));
  };

  const handleChangeHighlightLicense = (id: string) => {
    dispatch(changeHighlightLicense(id));
  };

  return {
    tabsMenu,
    LicenseContracts,
    nonExclusive,
    exclusive,
    methods,
    isCreateNewLicense,
    isLoading,
    handleDeleteLicenseContract,
    getLicenseAndContracts,
    handleStartEditLicense,
    handleClearFormLicense,
    licenseDetail,
    isShowInput,
    setIsShowInput,
    handleChangeActiveLicense,
    checkCreateLicense,
    handleChangeHighlightLicense,
  };
};
