import PaymentAndWallets from "app/apis/paymentAndWallets";
import { Dispatch } from "redux";
import { generateActionTypes } from "../actionTypes";

export const getlastestTransaction = generateActionTypes(
  "GET-LASTEST-TRANSACTION"
);
export const getPaypalSeller = generateActionTypes("GET-PAYPAL-SELLER");
export const getAccountDetail = generateActionTypes("GET-ACCTOUNT-DETAIL");
export const updateConnectPayment = generateActionTypes(
  "UPDATE-CONNECT-PAYMENT"
);
export const BalanceInStripe = generateActionTypes("BALANCE-INSTRIPE");
export const getTotalProfit = generateActionTypes("GET_TOTAL_PROFIT_IN_PAYPAL");

// --------------------------------------------------------------
// GET LASTEST TRANSACTON
// --------------------------------------------------------------

const getLastestTransactionRequest = (payload: {}) => ({
  type: getlastestTransaction.REQUEST,
  payload: payload,
});

const getLastestTransactionSuccess = (payload: any) => ({
  type: getlastestTransaction.SUCCESS,
  payload: [...payload],
});

const getLastestTransactionError = (payload: {}) => ({
  type: getlastestTransaction.ERROR,
  payload: payload,
});

export const lastestTransaction =
  (payload: {}) => async (dispatch: Dispatch) => {
    dispatch(getLastestTransactionRequest(payload));
    try {
      const data = await PaymentAndWallets.getLastestTransaction(payload);
      dispatch(getLastestTransactionSuccess(data));
    } catch (error: any) {
      dispatch(getLastestTransactionError(payload));
    }
  };

// --------------------------------------------------------------
// GET PAYPAL SELLER
// --------------------------------------------------------------

const getPaypalSellerStatusRequest = () => ({
  type: getPaypalSeller.REQUEST,
  payload: {},
});

const getPaypalSellerStatusSuccess = (payload: any) => ({
  type: getPaypalSeller.SUCCESS,
  payload: payload,
});

const getPaypalSellerStatusError = (error: any) => ({
  type: getPaypalSeller.ERROR,
  payload: { error },
});

export const paypalSellerStatus =
  (merchantIdInPayPal: string) => async (dispatch: Dispatch) => {
    dispatch(getPaypalSellerStatusRequest());
    try {
      const data = await PaymentAndWallets.paypalSellerStatus(
        merchantIdInPayPal
      );
      dispatch(getPaypalSellerStatusSuccess(data));
    } catch (error: any) {
      dispatch(getPaypalSellerStatusError(error.message));
    }
  };

// --------------------------------------------------------------
// GET ACCOUNT DETAIL
// --------------------------------------------------------------

const getAccountDetailRequest = () => ({
  type: getAccountDetail.REQUEST,
  payload: {},
});

const getAccountDetailSuccess = (payload: any) => ({
  type: getAccountDetail.SUCCESS,
  payload: payload,
});

const getAccountDetailError = (error: any) => ({
  type: getAccountDetail.ERROR,
  payload: { error },
});
export const getAccountDetails = () => async (dispatch: Dispatch) => {
  dispatch(getAccountDetailRequest());
  try {
    const data = await PaymentAndWallets.getAccountDetails();
    dispatch(getAccountDetailSuccess(data));
    return data;
  } catch (error: any) {
    dispatch(getAccountDetailError(error.message));
  }
};

// --------------------------------------------------------------
// UPDATE CONNECT PAYMENT
// --------------------------------------------------------------

const updateConnectPaymentRequest = () => ({
  type: updateConnectPayment.REQUEST,
  payload: {},
});

const updateConnectPaymentSuccess = (payload: any) => ({
  type: updateConnectPayment.SUCCESS,
  payload: payload,
});

const updateConnectPaymentError = (error: any) => ({
  type: updateConnectPayment.ERROR,
  payload: { error },
});
export const updateConnectPaymentType =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(updateConnectPaymentRequest());
    try {
      const data = await PaymentAndWallets.updateConnectPaymentType(payload);
      dispatch(updateConnectPaymentSuccess(data));
    } catch (error: any) {
      dispatch(updateConnectPaymentError(error.message));
    }
  };

// --------------------------------------------------------------
// GET BALANCE IN STRIPE
// --------------------------------------------------------------

const getBalanceInStripeRequest = () => ({
  type: BalanceInStripe.REQUEST,
  payload: {},
});

const getBalanceInStripeSuccess = (payload: any) => ({
  type: BalanceInStripe.SUCCESS,
  payload: payload,
});

const getBalanceInStripeError = (error: any) => ({
  type: BalanceInStripe.ERROR,
  payload: { error },
});
export const getBalanceInStripe = () => async (dispatch: Dispatch) => {
  dispatch(getBalanceInStripeRequest());
  try {
    const data = await PaymentAndWallets.getBalanceInStripe();
    dispatch(getBalanceInStripeSuccess(data));
  } catch (error: any) {
    dispatch(getBalanceInStripeError(error.message));
  }
};

// --------------------------------------------------------------
// GET TOTAL PROFIT
// --------------------------------------------------------------

const getTotalProfitRequest = () => ({
  type: getTotalProfit.REQUEST,
  payload: {},
});

const getTotalProfitSuccess = (data: any, paymentType: string) => ({
  type: getTotalProfit.SUCCESS,
  payload: { data, paymentType },
});

const getTotalProfitError = (error: any) => ({
  type: getTotalProfit.ERROR,
  payload: { error },
});

export const getTotalProfitPayment =
  (paymentType: string) => async (dispatch: Dispatch) => {
    dispatch(getTotalProfitRequest());
    try {
      const data = await PaymentAndWallets.getTotalProfit(paymentType);

      dispatch(getTotalProfitSuccess(data, paymentType));
    } catch (error: any) {
      dispatch(getTotalProfitError(error.message));
    }
  };
