import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonItemPurchasedMobile() {
  return (
    <Box>
      {Array.from({ length: 10 }).map((item, index) => (
        <Box key={index}>
          <Box
            padding="20px 20px 30px 20px"
            background="#1E1F23"
            mb="20px"
            borderRadius="4px"
          >
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Flex alignContent="center">
                <Skeleton
                  w={"35px"}
                  h={"36px"}
                  background="#535353"
                  borderRadius="6px"
                />
                <Box>
                  <Skeleton w="120px" h="14px" />
                  <Flex alignItems="center">
                    <Skeleton borderRadius="4px" />
                    <Skeleton w="100px" h="14px" />
                  </Flex>
                </Box>
              </Flex>
              <Flex>
                <Skeleton w="40px" h="40px" borderRadius="full" />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Skeleton h="14px" mr="0px" />
                  <Skeleton h="14px" mr="0px" />
                </Box>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Skeleton w="110px" h="14px" />
              <Skeleton w="110px" mr="0px" h="14px" />
            </Flex>
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Box>
                <Skeleton w="90px" borderRadius="4px" />
                <Skeleton w="170px" h="14px" />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="end">
                <Skeleton w="90px" borderRadius="4px" mr="0px" />
                <Skeleton w="170px" h="14px" mr="0px" />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex
              w={{ base: "100%", md: "12%" }}
              gap={{ base: "10px", md: "5px", lg: "10px" }}
            >
              {Array.from({ length: 3 }).map(() => (
                <Skeleton
                  w={{ base: "70px", md: "100%", lg: "50px" }}
                  h="40px"
                  borderRadius="4px"
                  background="#535353"
                  mr="0px"
                />
              ))}
            </Flex>
          </Box>
          <Divider m="20px 0px" borderColor="#535353" />
        </Box>
      ))}
    </Box>
  );
}
