import {
  Box,
  Button,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import UserIcon from "app/assets/icons/user";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { CreateUserOnFirestore } from "app/models";
import { followUnfollowUser, getUserById } from "app/redux/actions/users";
import {
  selectLoadingUserRecentActions,
  selectUserAdmin,
  selectUserRecentActions,
} from "app/redux/selectors/users";
import { PROFILE_TYPE } from "constants/contractType";
import { useProfile } from "hooks/profile/useProfile";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaPlay } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { formatNumber } from "utils/convertTracks";
import ModalShowFollowerUer from "./modalShowFollowerUser";

interface PopoverInfoProfileProps {
  item: CreateUserOnFirestore;
  isRecentActions?: boolean;
  isPageMessages?: boolean;
}

export default function PopoverInfoProfile({
  item,
  isRecentActions = false,
  isPageMessages = false,
}: PopoverInfoProfileProps) {
  const { t } = useTranslation();
  const userAdmin = useSelector(selectUserAdmin);
  const userRecentActions = useSelector(selectUserRecentActions);
  const isLoading = useSelector(selectLoadingUserRecentActions);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClickUserItem = useCallback(() => {
    history.push(`/user/${item?.name}?profileType=${PROFILE_TYPE.TRACKS}`);
  }, [history, item?.name]);

  const {
    handleGetListFollowers,
    isShowModal,
    handleCloseModal,
    handleClickUser,
    onSearchUserFollowers,
    userFollowers,
  } = useProfile();

  const followersTracksPlays = useMemo(
    () => [
      {
        name: t("Followers"),
        total: userRecentActions?.followers,
        icon: <UserIcon />,
        handleClick: () => handleGetListFollowers(userRecentActions?._id),
      },
      {
        name: t("Plays"),
        total: formatNumber(Number(userRecentActions?.plays)),
        icon: <FaPlay />,
        handleClick: () => null,
      },
    ],
    [
      handleGetListFollowers,
      t,
      userRecentActions?._id,
      userRecentActions?.followers,
      userRecentActions?.plays,
    ]
  );

  const handleFollowPress = useCallback(
    () => dispatch(followUnfollowUser(userRecentActions?._id)),
    [dispatch, userRecentActions?._id]
  );

  const handleGetUserId = useCallback(
    (id) => {
      dispatch(getUserById(id));
    },
    [dispatch]
  );

  return (
    <>
      <Popover trigger="hover" onOpen={() => handleGetUserId(item.id)}>
        <PopoverTrigger>
          <Flex
            alignItems="center"
            gap={{ base: "8px", md: "14px", xl: "16px" }}
            cursor="pointer"
            onClick={isRecentActions ? handleClickUserItem : () => null}
          >
            <Image
              w={isPageMessages ? "48px" : "35px"}
              h={isPageMessages ? "48px" : "35px"}
              src={item?.profileImage}
              fallbackSrc={AvatarUser}
              borderRadius="full"
            />
            <Text
              fontSize={{ base: "14px", md: "16px" }}
              fontWeight="500"
              _hover={{
                textDecor: "underline",
              }}
              fontFamily="Cera Pro Medium"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              mr="5px"
            >
              {item?.name}
            </Text>
          </Flex>
        </PopoverTrigger>
        <PopoverContent w="280px" background="#1F2026" borderWidth="0px">
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="25vh"
            >
              <FadeLoader color={"#d4f70e"} loading={isLoading} />
            </Box>
          ) : (
            <>
              <PopoverHeader
                borderBottomWidth="0px"
                display="flex"
                justifyContent="center"
                padding="8px 12px 0px 12px"
              >
                <Image
                  w={"68px"}
                  h={"68px"}
                  src={userRecentActions?.profileImage}
                  fallbackSrc={AvatarUser}
                  borderRadius="full"
                />
              </PopoverHeader>
              <PopoverBody textAlign="center">
                <Text
                  onClick={isRecentActions ? handleClickUserItem : () => null}
                  cursor="pointer"
                  _hover={{ textDecoration: "underline" }}
                  fontSize="18px"
                  fontWeight="500"
                  lineHeight="21px"
                >
                  {userRecentActions?.name}
                </Text>
                <Text
                  color="rgba(255, 255, 255, 0.32)"
                  fontSize="16px"
                  lineHeight="20px"
                  fontWeight="400"
                  m="5px 0px"
                >
                  {userRecentActions?.role?.[0]}
                </Text>
                <Flex justifyContent="space-between" padding="10px 25px">
                  {followersTracksPlays.map((item, index) => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      key={index}
                    >
                      <Flex alignItems="center">
                        {item.icon}
                        <Text
                          ml="5px"
                          fontSize="24px"
                          lineHeight="30px"
                          fontWeight="400"
                        >
                          {item.total}
                        </Text>
                      </Flex>
                      <Text
                        color="#535353"
                        fontSize="14px"
                        lineHeight="18px"
                        fontWeight="400"
                        cursor={"pointer"}
                        onClick={item.handleClick}
                      >
                        {item.name}
                      </Text>
                    </Box>
                  ))}
                </Flex>
                {userAdmin?._id !== userRecentActions?._id && (
                  <Button
                    m="10px 0px"
                    background="#34391A"
                    color="#D4F70E"
                    border="1px solid #D4F70E"
                    _hover={{
                      background: "#34391A",
                    }}
                    w="85%"
                    borderRadius="4px"
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="600"
                    onClick={handleFollowPress}
                  >
                    {userRecentActions?.isFollowing
                      ? t("unfollow")
                      : t("follow")}
                  </Button>
                )}
              </PopoverBody>
            </>
          )}
        </PopoverContent>
      </Popover>

      <ModalShowFollowerUer
        isShowModal={isShowModal}
        handleCloseModal={handleCloseModal}
        handleClickUser={handleClickUser}
        onSearchUserFollowers={onSearchUserFollowers}
        userFollowers={userFollowers}
      />
    </>
  );
}
