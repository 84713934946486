import React from "react";

const RightArrow = () => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#24252A" />
      <path d="M22 17L28.5 23.5L22 30" stroke="white" stroke-width="2" />
    </svg>
  );
};
export default RightArrow;
