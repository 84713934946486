import { Box, Flex, Image, Text } from "@chakra-ui/react";
import { Negotiations, TrackSoundKit } from "app/models";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import React, { useCallback, useMemo } from "react";
import NegotiationTimelineItem from "../negotiationTimelineItem";
import AvatarUser from "app/assets/images/AvatarMusicLoading.svg";
import { ShowContentProps } from "../modalNegotiations";

interface NegotiationsItemContentModalProps {
  setShowModal: () => void;
  negotiationsItem: Negotiations;
  setShowContent: (data: ShowContentProps) => void;
}

export default function NegotiationItemContentModal({
  setShowModal,
  negotiationsItem,
  setShowContent,
}: NegotiationsItemContentModalProps) {
  const { isExpiredDate, onCancelOrRejectNegotiation, onAcceptNegotiation } =
    useNegotiations();

  const currentLicense = useMemo(
    () => negotiationsItem?.licenses?.[0],
    [negotiationsItem?.licenses]
  );

  const isExpired = useMemo(
    (): boolean =>
      !!negotiationsItem?.expiredDate &&
      isExpiredDate(negotiationsItem?.expiredDate),
    [isExpiredDate, negotiationsItem?.expiredDate]
  );

  const handlecounterOffer = useCallback(() => {
    setShowContent({
      showContentNegotiationDeal: false,
      showNegotiationOffer: true,
      showSubmitNegotiation: false,
    });
  }, [setShowContent]);

  return (
    <>
      <NegotiationTimelineItem
        negotiationItem={negotiationsItem}
        hasTimeline={false}
        isExpired={isExpired}
        onCancelOrRejectNegotiation={onCancelOrRejectNegotiation}
        setShowModal={setShowModal}
        onCounterOffer={handlecounterOffer}
        onAcceptNegotiation={onAcceptNegotiation}
      />
      {(negotiationsItem?.tracks || []).map(
        (item: TrackSoundKit, index: number) => (
          <Flex
            background="#24252A"
            padding="8px"
            w={{ base: "100%", md: "343px" }}
            borderRadius="8px"
            alignItems="center"
            key={index}
            mb="10px"
          >
            <Image
              w="69px"
              height="69px"
              alt="User"
              src={item.coverUrl || AvatarUser}
              borderRadius="6px"
              fallbackSrc={AvatarUser}
            />
            <Box ml="15px">
              <Text fontSize="16px" lineHeight="19px" fontWeight="700">
                {item.title}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="16px"
                fontWeight="400"
                color="rgba(255, 255, 255, 0.3)"
                mt="10px"
              >
                {currentLicense?.name} (MP3{currentLicense?.extraWav && ", WAV"}
                {currentLicense?.trackStems && " and STEMS"})
              </Text>
            </Box>
          </Flex>
        )
      )}

      {(negotiationsItem?.negotiations || []).map(
        (item: Negotiations, index: number) => (
          <NegotiationTimelineItem negotiationItem={item} key={index} />
        )
      )}
    </>
  );
}
