import React from "react";

const AddCover = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.6875C3.96793 0.6875 0.6875 3.96793 0.6875 8C0.6875 12.0321 3.96793 15.3125 8 15.3125C12.0321 15.3125 15.3125 12.0321 15.3125 8C15.3125 3.96793 12.0321 0.6875 8 0.6875ZM10.8125 8.5625H8.5625V10.8125C8.5625 10.9617 8.50324 11.1048 8.39775 11.2102C8.29226 11.3157 8.14918 11.375 8 11.375C7.85081 11.375 7.70774 11.3157 7.60225 11.2102C7.49676 11.1048 7.4375 10.9617 7.4375 10.8125V8.5625H5.1875C5.03832 8.5625 4.89524 8.50323 4.78975 8.39775C4.68426 8.29226 4.625 8.14918 4.625 8C4.625 7.85081 4.68426 7.70774 4.78975 7.60225C4.89524 7.49676 5.03832 7.4375 5.1875 7.4375H7.4375V5.1875C7.4375 5.03831 7.49676 4.89524 7.60225 4.78975C7.70774 4.68426 7.85081 4.625 8 4.625C8.14918 4.625 8.29226 4.68426 8.39775 4.78975C8.50324 4.89524 8.5625 5.03831 8.5625 5.1875V7.4375H10.8125C10.9617 7.4375 11.1048 7.49676 11.2102 7.60225C11.3157 7.70774 11.375 7.85081 11.375 8C11.375 8.14918 11.3157 8.29226 11.2102 8.39775C11.1048 8.50323 10.9617 8.5625 10.8125 8.5625Z"
        fill="white"
      />
    </svg>
  );
};
export default AddCover;
