export const capitalize = (data: string = "") => {
  const inputData = data.toLowerCase();
  return inputData.charAt(0).toUpperCase() + inputData.slice(1);
};

export const openLinkCreateSubscription = (url: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.click();
};
