import { Input } from '@chakra-ui/react';
import React from 'react';
import './styles.scss';

interface TextInputProps {
    value: string;
    field: { onChange: (data: any) => void };
    loopAndSamplesType: { id: number }[];
    item: { id: number };
    name: string;
}

export default function InputLoopSampleController({ value, loopAndSamplesType, field, item, name }: TextInputProps) {
    const handleLoopAndSample = (item: { id: number }) => {
        return loopAndSamplesType.find((loop: { id: number }) => loop.id === item?.id);
    };

    const handleNewData = (key: string, value: string, item: { id: number }) => {
        return loopAndSamplesType.map((loop: { id: number }) => {
            if (loop.id === item?.id) {
                return {
                    ...handleLoopAndSample(item),
                    [key]: value,
                };
            }
            return loop;
        });
    };

    return (
        <Input
            className={'form-input'}
            backgroundColor='#25252A'
            border='none'
            mt='10px'
            placeholder='Type...'
            _placeholder={{
                fontSize: '16px',
                color: '#535353',
            }}
            value={value}
            onChange={(e) => {
                field.onChange({
                    target: {
                        value: [...handleNewData(name, e.target.value, item)],
                    },
                });
            }}
        />
    );
}
