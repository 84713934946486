import { Box, Text } from "@chakra-ui/react";
import IconEmptyNegotiation from "app/assets/icons/EmptyNegotiationsIcon";
import React from "react";
import { useTranslation } from "react-i18next";

interface EmptyNegotiationProps {
  isActive?: boolean;
}

export default function EmptyNegotiation({ isActive }: EmptyNegotiationProps) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mt={{ base: "16px", md: "50px" }}
    >
      <IconEmptyNegotiation />
      <Text fontSize="20px" lineHeight="25px" color="#535353" mt="30px">{`${t(
        "youHaveNo"
      )} ${isActive ? "Active" : "Inactive"} ${t("negotiation")}`}</Text>
      <Text
        fontSize="16px"
        lineHeight="20px"
        color="#535353"
        mt="10px"
        px={{ base: "56px", md: "0px" }}
      >
        {t("NoHaveNegotiations")}
      </Text>
    </Box>
  );
}
