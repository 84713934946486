import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  collection,
  doc,
  limitToLast,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";

import InfiniteScroll from "react-infinite-scroll-component";

import { useCollectionQuery } from "../../hooks/useCollectionQuery";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";
import { db } from "../../config/firebase";
import { Box, Spinner, Text } from "@chakra-ui/react";
import RightMessage from "./Messages/RightMessage";
import LeftMessage from "./Messages/LeftMessage";
import queryString from "query-string";
import { ConversationInfo } from "app/models";

interface ChatViewProps {
  conversation: ConversationInfo;
}

const ChatView = ({ conversation }: ChatViewProps) => {
  const location = useLocation();
  const tabMessages = queryString.parse(location.search);
  const conversationId = tabMessages.room;

  const currentUser = useSelector(selectUserAdmin);

  const scrollBottomRef = useRef<HTMLDivElement>(null);

  const [limitCount, setLimitCount] = useState(10);

  const { data, loading, error } = useCollectionQuery(
    `conversation-data-${conversationId}-${limitCount}`,
    query(
      collection(db, "conversations", conversationId as string, "messages"),
      orderBy("createdAt"),
      limitToLast(limitCount)
    )
  );

  const dataRef = useRef(data);
  const conversationIdRef = useRef(conversationId);
  const isWindowFocus = useRef(true);

  useEffect(() => {
    dataRef.current = data;
  }, [data]);

  useEffect(() => {
    conversationIdRef.current = conversationId;
  }, [conversationId]);

  const updateSeenStatus = useCallback(() => {
    if (dataRef.current?.empty) return;

    const lastDoc = dataRef.current?.docs?.slice(-1)?.[0];

    if (!lastDoc) return;

    updateDoc(doc(db, "conversations", conversationIdRef.current as string), {
      [`seen.${currentUser?._id}`]: lastDoc.id,
      lastMessageId: lastDoc.id,
    });
  }, [currentUser?._id]);

  useEffect(() => {
    if (isWindowFocus.current) updateSeenStatus();

    scrollBottomRef.current?.scrollIntoView();

    setTimeout(() => {
      scrollBottomRef.current?.scrollIntoView();
    }, 100);
  }, [updateSeenStatus]);

  const isMobileDevice = () => {
    return window.innerWidth <= 767;
  };

  useEffect(() => {
    const focusHandler = () => {
      isWindowFocus.current = true;

      updateSeenStatus();
    };

    const blurHandler = () => {
      isWindowFocus.current = false;
    };

    window.addEventListener("focus", focusHandler);
    window.addEventListener("blur", blurHandler);

    return () => {
      window.removeEventListener("focus", focusHandler);
      window.removeEventListener("blur", blurHandler);
    };
  }, [updateSeenStatus]);

  if (loading)
    return (
      <div className="flex flex-grow items-center justify-center">
        <Spinner />
      </div>
    );

  if (error)
    return (
      <Box display="flex" justifyContent="center" mt="50px">
        <Text>Something went wrong</Text>
      </Box>
    );

  if (data?.empty)
    return (
      <Box
        height="320px"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Text>No message recently. Start chatting now.</Text>
      </Box>
    );

  return (
    <InfiniteScroll
      dataLength={data?.size as number}
      next={() => setLimitCount((prev) => prev + 10)}
      inverse
      hasMore={(data?.size as number) >= limitCount}
      loader={
        <Box>
          <Spinner />
        </Box>
      }
      style={{
        display: "flex",
        flexDirection: "column-reverse",
        padding: isMobileDevice() ? "16px" : "0px",
      }}
      height="320px"
    >
      <Box fontSize={{ base: "14px", md: "16px" }}>
        {data?.docs
          .map((doc) => ({ id: doc.id, ...doc.data() } as any))
          .map((item) => {
            return (
              <Fragment key={item.id}>
                {item.sender === currentUser?._id ? (
                  <RightMessage message={item} />
                ) : (
                  <LeftMessage message={item} />
                )}
              </Fragment>
            );
          })}
        {/* <Box ref={scrollBottomRef} /> */}
      </Box>
    </InfiniteScroll>
  );
};

export default ChatView;
