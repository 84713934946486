import React, { useCallback, useState } from "react";
import { Box, Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Playlists, ShortLinkModel } from "app/models";
import { useHistory } from "react-router-dom";
import { PROFILE_TYPE } from "constants/contractType";
import IconHeartFeedPageUnlike from "app/assets/icons/IconHeartFeedPageUnlike";
import IconHeartFeedPageLike from "app/assets/icons/IconHeartFeedPageLike";
import IconRepeatFeed from "app/assets/icons/IconRepeatFeedPage";
import IconUnRepeatFeed from "app/assets/icons/IconUnRepeatFeedPage";
import IconShareFeedPage from "app/assets/icons/IconShareFeedPage";
import { shortLinkTrackSoundkitDetail } from "utils/getData";
import { useDispatch, useSelector } from "react-redux";
import {
  likePlaylistInRecentActionsHomepage,
  unlikePlaylistInRecentActionsHomepage,
  repostPlaylistInRecentActionsHomepage,
} from "app/redux/actions/home";
import {
  isFavoritePlaylistSelector,
  isRepostPlaylistSelector,
} from "app/redux/selectors/playlist";
import ToastPlanSubscription from "app/components/toast/toastplan";
import ImagePlaylist from "app/assets/images/imagePlaylist.png";
import IconHeartFeedPageLikeMobile from "app/assets/icons/IconHeartFeedPageLikeMobile";
import IconRepeatFeedMobile from "app/assets/icons/IconRepeatFeedPageMobile";
import IconShareFeedPageMobile from "app/assets/icons/IconShareFeedPageMobile";
import { selectLoggedIn } from "app/redux/selectors/users";

interface PlaylistItemRecentActionProps {
  playlist: Playlists;
  setShowModalShareLinkPlaylist: () => void;
  setPlaylistItem: (item: Playlists) => void;
  setShortLinkPlaylist: (value: ShortLinkModel) => void;
  setLoadingUrl: (value: boolean) => void;
}

export default function PlaylistItemRecentAction({
  playlist,
  setShowModalShareLinkPlaylist,
  setPlaylistItem,
  setShortLinkPlaylist,
  setLoadingUrl,
}: PlaylistItemRecentActionProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [delayTimeCallApi, setDelayTimeCallApi] = useState(0);
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const isFavorite = useSelector(isFavoritePlaylistSelector(playlist));
  const isRepost = useSelector(isRepostPlaylistSelector(playlist));
  const loggedIn = useSelector(selectLoggedIn);

  const redirectProfile = useCallback(
    (name) => {
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );

  const redirectPlaylistIndividual = useCallback(() => {
    history.push(`/playlist/${playlist?._id}`);
  }, [history, playlist?._id]);
  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 2000);
  }, []);

  const handleLikeUnLikePlaylist = useCallback(async () => {
    increaseTime();
    if (!isFavorite) {
      dispatch(
        likePlaylistInRecentActionsHomepage({
          id: playlist?._id || "",
          timeCallAPi: delayTimeCallApi,
        })
      );
    } else {
      dispatch(
        unlikePlaylistInRecentActionsHomepage({
          id: playlist?._id || "",
          timeCallAPi: delayTimeCallApi,
        })
      );
    }
  }, [delayTimeCallApi, dispatch, increaseTime, isFavorite, playlist?._id]);

  const handleRepostPlaylist = useCallback(() => {
    if (isRepost) {
      ToastPlanSubscription({
        description: t("alreadyRepostPlaylist"),
      });
    } else {
      dispatch(repostPlaylistInRecentActionsHomepage(playlist?._id || ""));
    }
  }, [dispatch, isRepost, playlist._id, t]);

  const createShortLinkUrl = useCallback(async () => {
    const hostName = window.location.host;
    setShowModalShareLinkPlaylist();
    setPlaylistItem(playlist);
    setLoadingUrl(true);
    const shortLink = await shortLinkTrackSoundkitDetail({
      originalURL: `${hostName}/playlist/${playlist?._id}`,
    });
    setShortLinkPlaylist(shortLink?.data?.data);
    setLoadingUrl(false);
  }, [
    playlist,
    setPlaylistItem,
    setShortLinkPlaylist,
    setShowModalShareLinkPlaylist,
    setLoadingUrl,
  ]);

  return (
    <>
      <Box
        mt="20px"
        background="#24252B"
        padding={{ base: "10px", md: "20px 20px 20px 16px" }}
        borderRadius="8px 8px 0px 0px"
        position="relative"
        role="group"
      >
        <Flex alignItems="center" w="100%">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            cursor="pointer"
            w={{ base: "85px", md: "180px" }}
            onClick={redirectPlaylistIndividual}
          >
            <Box>
              <Image
                src={playlist?.coverUrl}
                fallbackSrc={ImagePlaylist}
                borderRadius="4px"
                objectFit="cover"
                w={{ base: "82px", md: "180px" }}
                height={{ base: "82px", md: "180px" }}
              />
            </Box>
          </Box>
          <Box
            w={{ base: "75%", md: "70%", lg: "80%" }}
            ml={{ base: "15px", md: "20px" }}
            mt={{ base: "10px", md: "20px" }}
          >
            <Text
              fontSize={{ base: "16px", md: "32px" }}
              fontWeight="700"
              lineHeight={{ base: "24px", md: "32px" }}
              noOfLines={1}
              _hover={{
                textDecoration: "underline",
                color: "white",
              }}
              cursor="pointer"
              fontFamily="Cera Pro Medium"
              onClick={redirectPlaylistIndividual}
            >
              {playlist?.name}
            </Text>
            <Box
              display="flex"
              alignItems="center"
              mt={{ base: "8px", md: "13px" }}
            >
              <Text
                fontSize={{ base: "14px", md: "16px" }}
                lineHeight="19px"
                color="#d4f70e"
                padding="0px 5px"
                borderRadius="4px"
                fontFamily="Cera Pro Regular"
                mr="10px"
                backgroundColor="#2b2e1b"
              >
                {t("playlist")}
              </Text>
              <Text
                fontSize={{ base: "14px", md: "16px" }}
                fontWeight="400"
                lineHeight="19px"
                color="#5B5C5F"
                cursor={"pointer"}
                fontFamily="Cera Pro Regular"
                _hover={{
                  textDecoration: "underline",
                  color: "white",
                }}
                onClick={() => redirectProfile(playlist?.createdBy?.name)}
              >
                {`By ${playlist?.createdBy?.name}`}
              </Text>
            </Box>
            <Text
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="24px"
              fontWeight="400"
              noOfLines={3}
              fontFamily="Cera Pro Regular"
              mt={{ base: "8px", md: "15px" }}
            >
              {playlist?.description || t("noDescription")}
            </Text>
          </Box>
        </Flex>
      </Box>

      <Flex
        w="100%"
        background="#1F2026"
        padding={{ base: "10px", md: "15px" }}
        justifyContent="space-between"
        mb="20px"
        borderRadius="0px 0px 8px 8px"
      >
        <Flex>
          <Flex
            alignItems="center"
            mr="100px"
            cursor="pointer"
            onClick={loggedIn ? handleLikeUnLikePlaylist : () => null}
            _hover={{
              border: "1px solid #38383D",
              borderRadius: "8px",
              padding: "5px",
            }}
            borderWidth="1px"
            borderColor="#1F2026"
            padding="5px"
          >
            {isFavorite ? (
              isLessThan768 ? (
                <IconHeartFeedPageLikeMobile />
              ) : (
                <IconHeartFeedPageLike />
              )
            ) : isLessThan768 ? (
              <IconHeartFeedPageLikeMobile color="#656566" />
            ) : (
              <IconHeartFeedPageUnlike />
            )}
            <Text
              ml="5px"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="500"
              fontFamily="Cera Pro Medium"
            >
              {playlist?.totalFavs || 0}
            </Text>
          </Flex>
          <Flex
            cursor="pointer"
            alignItems="center"
            onClick={loggedIn ? handleRepostPlaylist : () => null}
            _hover={{
              border: "1px solid #38383D",
              borderRadius: "8px",
              padding: "5px",
            }}
            borderWidth="1px"
            borderColor="#1F2026"
            padding="5px"
          >
            {isRepost ? (
              isLessThan768 ? (
                <IconRepeatFeedMobile />
              ) : (
                <IconRepeatFeed />
              )
            ) : isLessThan768 ? (
              <IconRepeatFeedMobile color="#656566" />
            ) : (
              <IconUnRepeatFeed />
            )}

            <Text
              ml="5px"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="500"
              fontFamily="Cera Pro Medium"
            >
              {playlist?.totalRepost || 0}
            </Text>
          </Flex>
        </Flex>

        <Flex
          display="flex"
          justifyContent="flex-end"
          alignContent="flex-end"
          alignItems="center"
          cursor="pointer"
          onClick={createShortLinkUrl}
          _hover={{
            border: "1px solid #38383D",
            borderRadius: "8px",
            padding: "5px",
          }}
          borderWidth="1px"
          borderColor="#1F2026"
          padding="5px"
        >
          {isLessThan768 ? (
            <IconShareFeedPageMobile color="#656566" />
          ) : (
            <IconShareFeedPage color="#656566" />
          )}
        </Flex>
      </Flex>
    </>
  );
}
