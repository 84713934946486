export const PAYMENT_TYPE = [
  {
    id: 1,
    type: "STRIPE",
  },
  {
    id: 2,
    type: "PAYPAL",
  },
];

export const PAYMENT_TYPE_METHOD = {
  STRIPE: "STRIPE",
  PAYPAL: "PAYPAL",
};
