import React from "react";

const BistarLicense = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.25801 7.16223L2.51301 10.3882C2.48994 10.4859 2.49687 10.5883 2.53289 10.682C2.5689 10.7757 2.63233 10.8563 2.7149 10.9134C2.79746 10.9705 2.89532 11.0013 2.99569 11.0019C3.09606 11.0025 3.19428 10.9728 3.27751 10.9167L6.00001 9.10173L8.72251 10.9167C8.80768 10.9733 8.90812 11.0024 9.01033 11.0001C9.11254 10.9979 9.2116 10.9644 9.29421 10.9042C9.37682 10.844 9.439 10.7599 9.4724 10.6633C9.50579 10.5666 9.5088 10.4621 9.48101 10.3637L8.56651 7.16373L10.8345 5.12273C10.9072 5.05733 10.959 4.97206 10.9837 4.87749C11.0084 4.78291 11.0049 4.68317 10.9735 4.5906C10.9421 4.49803 10.8842 4.4167 10.8071 4.35665C10.73 4.2966 10.637 4.26047 10.5395 4.25273L7.68901 4.02573L6.45551 1.29523C6.41618 1.20731 6.35224 1.13265 6.27142 1.08026C6.19059 1.02788 6.09633 1 6.00001 1C5.90369 1 5.80943 1.02788 5.72861 1.08026C5.64778 1.13265 5.58385 1.20731 5.54451 1.29523L4.31101 4.02573L1.46051 4.25223C1.36474 4.25982 1.27318 4.29484 1.19679 4.3531C1.1204 4.41136 1.06241 4.49039 1.02976 4.58075C0.99711 4.6711 0.991182 4.76895 1.01269 4.86259C1.03419 4.95622 1.08221 5.04168 1.15101 5.10873L3.25801 7.16223ZM4.68451 4.99923C4.77376 4.9922 4.85947 4.96129 4.93266 4.90974C5.00586 4.85819 5.06383 4.7879 5.10051 4.70623L6.00001 2.71573L6.89951 4.70623C6.93619 4.7879 6.99417 4.85819 7.06736 4.90974C7.14055 4.96129 7.22627 4.9922 7.31551 4.99923L9.30151 5.15673L7.66601 6.62873C7.52401 6.75673 7.46751 6.95373 7.51951 7.13773L8.14601 9.33023L6.27801 8.08473C6.19602 8.0297 6.09951 8.00032 6.00076 8.00032C5.90202 8.00032 5.8055 8.0297 5.72351 8.08473L3.77151 9.38623L4.29651 7.11323C4.31576 7.02963 4.31318 6.94249 4.28901 6.86018C4.26484 6.77786 4.2199 6.70316 4.15851 6.64323L2.63951 5.16223L4.68451 4.99923Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default BistarLicense;
