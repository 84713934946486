import { Box, Flex, Checkbox, Text } from "@chakra-ui/react";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";

interface SkeletonMyTrackProps {
  isProfile?: boolean;
  isLyricPage?: boolean;
}

export default function SkeletonMyTrack({
  isProfile,
  isLyricPage,
}: SkeletonMyTrackProps) {
  const fakeDataSekeleton = Array.from({ length: 10 });

  return (
    <Box mt="20px">
      {fakeDataSekeleton.map((item, index) => (
        <Flex
          alignItems="center"
          mb="30px"
          key={index}
          display={{ base: "block", md: "flex" }}
        >
          <Flex
            w="100%"
            display={{ base: "none", md: "flex" }}
            alignItems="center"
          >
            <Flex w={"10%"} alignItems="center" justifyContent="space-between">
              <Skeleton w="10px" h="21px" borderRadius="2px" />
              <IconSkeletonMusic />
            </Flex>

            <Box w="30%" ml={"20px"}>
              <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
              <Flex>
                <Skeleton h="16px" w="35px" mb="0px" />
                <Skeleton
                  h="16px"
                  w="109px"
                  borderRadius="30px"
                  mb="0px"
                  mr="0px"
                />
              </Flex>
            </Box>
            <Flex w="55%" justifyContent="end">
              <Skeleton w="150px" h="45px" />
              <Skeleton w="150px" h="45px" />
            </Flex>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            display={{ base: "flex", md: "none" }}
          >
            <Flex alignItems="center">
              <Flex alignItems="center">
                <Skeleton
                  w="10px"
                  h="21px"
                  borderRadius="2px"
                  mr="30px"
                  ml="20px"
                />
                <IconSkeletonMusic />
              </Flex>
              <Box ml={"20px"}>
                <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
                <Flex>
                  <Skeleton h="16px" w="35px" mb="0px" />
                  <Skeleton
                    h="16px"
                    w="109px"
                    borderRadius="30px"
                    mb="0px"
                    mr="0px"
                  />
                </Flex>
              </Box>
            </Flex>
          </Flex>
          <Flex
            mt="20px"
            justifyContent="center"
            display={{ base: "flex", md: "none" }}
          >
            <Skeleton w="150px" h="45px" />
            <Skeleton w="150px" h="45px" />
          </Flex>
        </Flex>
      ))}
    </Box>
  );
}
