import React from "react";

const IconRemoveExpertise = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="4.5" stroke="#D4F70E" stroke-width="1.2" />
      <path d="M3.75 6H8.25" stroke="#D4F70E" stroke-width="1.2" />
    </svg>
  );
};
export default IconRemoveExpertise;
