import { Box } from "@chakra-ui/react";
import { PlayAnalyticsModel } from "app/models";
import {
  selectorsDownloadAnalytics,
  selectorsFavoritesAnalytics,
  selectorsPlaysAnalytics,
  selectorsSoldAnalytics,
} from "app/redux/selectors/analytics";
import {
  anaLytics,
  monthNames,
  queryAnalyticsType,
  timeType,
} from "constants/analyticsType";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import "./styles.scss";
const borderRadius = require("highcharts-border-radius");
borderRadius(Highcharts);
interface HighChartsProps {
  analyticsType: PlayAnalyticsModel;
  timeDay: string;
}

export default function HighChartsComponnent({
  analyticsType,
  timeDay,
}: HighChartsProps) {
  const playsAnalytics = useSelector(selectorsPlaysAnalytics);
  // const purchasedAnalytics = useSelector(selectorsPurchasedAnalytics);
  const soldAnalytics = useSelector(selectorsSoldAnalytics);
  const downloadAnalytics = useSelector(selectorsDownloadAnalytics);
  const favoritesAnalytics = useSelector(selectorsFavoritesAnalytics);

  const data = useMemo(() => {
    switch (analyticsType.type) {
      case queryAnalyticsType.Plays:
        return { values: playsAnalytics.values, labels: playsAnalytics.labels };
      case queryAnalyticsType.Sales:
      case queryAnalyticsType.Sold:
        return { values: soldAnalytics.values, labels: soldAnalytics.labels };
      case queryAnalyticsType.Downloads:
        return {
          values: downloadAnalytics.values,
          labels: downloadAnalytics.labels,
        };
      case queryAnalyticsType.Favorites:
        return {
          values: favoritesAnalytics.values,
          labels: favoritesAnalytics.labels,
        };
      default:
        break;
    }
  }, [
    analyticsType.type,
    downloadAnalytics.labels,
    downloadAnalytics.values,
    favoritesAnalytics.labels,
    favoritesAnalytics.values,
    playsAnalytics.labels,
    playsAnalytics.values,
    soldAnalytics.labels,
    soldAnalytics.values,
  ]);

  const month = new Date().getMonth();

  const labels = useMemo(() => {
    switch (timeDay) {
      case timeType.DAY:
        return (data?.labels || []).map(
          (label: string) => `From ${label}:00 to ${Number(label) + 4}:00`
        );
      case timeType.WEEK:
        return data?.labels;
      case timeType.YEAR:
        return data?.labels.map((item: string) => {
          return anaLytics[item];
        });
      case timeType.MONTH:
        return data?.labels.map((item: string) => {
          return `${monthNames[month]} ${item}`;
        });
      default:
        break;
    }
  }, [month, data?.labels, timeDay]);

  const options = {
    title: {
      text: "",
      style: {
        display: "none",
      },
    },
    chart: {
      display: "none",
      type: "column",
      backgroundColor: "#1C1C20",
      // padding: 20,
      marginTop: 50,
      marginBottom: 40,
    },
    plotOptions: {
      column: {
        borderRadiusTopLeft: 5,
        borderRadiusTopRight: 5,
      },
    },
    yAxis: {
      gridLineColor: "#3D3C41",
      title: {
        enabled: false,
      },
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "SF Pro Display",
        },
      },
    },
    xAxis: {
      categories: labels,
      lineColor: "#3D3C41",
      labels: {
        style: {
          fontSize: "14px",
          fontFamily: "Cera Pro Regular",
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      backgroundColor: "rgba(58, 58, 60, 0.7)",
      borderRadius: 30,
      borderWidth: 0,
      shared: false,
      useHTML: true,
      formatter: function () {
        return `<div class="number">${analyticsType.name}</div>  <div class="total">${this.y}</div>`;
      },
    },
    series: [
      {
        color: "#D4F70E",
        data: data?.values,
        enabled: true,
      },
    ],
  };

  return (
    <Box mt="20px" className="highchart">
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
}
