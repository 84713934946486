import * as yup from "yup";
import { Licenses } from "app/models";
import { valueKey } from "constants/keys";

const formSchema = () =>
  yup.object().shape({
    title: yup.string().required("trackTitleRequired"),
    contractType: yup.string().required("contractTypeRequired"),
    type: yup.string().required("trackTypeRequired"),
    licenses: yup.array().required("licenseRequired").min(1, "licenseRequired"),
    untaggedFileUrl: yup.string().url().required("untaggedFileUrlRequired"),
    trackStemsUrl: yup
      .string()
      .url()
      .when("licenses", (licenses, schema) => {
        const canUploadStems = licenses.some(
          (license: Licenses) => license?.trackStems
        );
        return canUploadStems ? schema.required("uploadTrackStems") : schema;
      }),
    untaggedFileUrlWav: yup
      .string()
      .url()
      .when("licenses", (licenses, schema) => {
        const canUploadStems = licenses.some(
          (license: Licenses) => license?.extraWav
        );
        return canUploadStems ? schema.required("uploadTrackWav") : schema;
      }),
    coverUrl: yup.string().required("coverUrlRequired"),
    freeDownloadEnabled: yup.string().required("freeDownloadEnabledRequired"),
    mainGenre: yup.string().required("primaryGenreRequired"),
    subGenre: yup.string().required("secondaryGenreRequired"),
    key: yup.string().required("keyRequired").oneOf(valueKey, "keyRequired"),
    bpm: yup.number().positive().max(999, "maxBpm").required("bpmRequired"),
    primaryMood: yup.string().required("primaryMoodRequired"),
    secondaryMood: yup.string(),
    tags: yup
      .array(yup.string())
      .required("tagsRequired")
      .min(4, "tagsRequired"),
    releaseDate: yup.date().required(),
    mergeAudio: yup.string().url(),
    collaborators: yup
      .array(yup.object())
      .required("collaboratorsRequired")
      .min(1, "collaboratorsRequired"),
    data: yup.array().of(
      yup.object().shape({
        nameLoopSample: yup.string().required("sampleRequired"),
        whereLoopSample: yup.string().required("sampleRequired"),
      })
    ),
  });

export default formSchema;
