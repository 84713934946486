import React from "react";

interface IconRepostPlaylistIndividualProps {
  isRepost?: boolean;
}

const IconRepostPlaylistIndividual = ({
  isRepost = false,
}: IconRepostPlaylistIndividualProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
    >
      <path
        d="M7.66944 0.739115C7.59931 0.810026 7.54364 0.894389 7.50565 0.98734C7.46766 1.08029 7.4481 1.17999 7.4481 1.28069C7.4481 1.38138 7.46766 1.48108 7.50565 1.57403C7.54364 1.66698 7.59931 1.75135 7.66944 1.82226L9.85444 4.04956L3.86815 4.04956C3.07431 4.04956 2.313 4.37102 1.75167 4.94321C1.19035 5.51541 0.875 6.29147 0.875 7.10067L0.875 15.0107C0.875 15.213 0.953837 15.407 1.09417 15.55C1.2345 15.6931 1.42483 15.7734 1.62329 15.7734C1.82174 15.7734 2.01207 15.6931 2.15241 15.55C2.29274 15.407 2.37157 15.213 2.37157 15.0107L2.37157 7.10067C2.37157 6.69607 2.52925 6.30804 2.80991 6.02194C3.09057 5.73584 3.47123 5.57512 3.86815 5.57512L9.85444 5.57512L7.66944 7.80242C7.52556 7.94505 7.44315 8.14009 7.44035 8.34465C7.43754 8.54921 7.51456 8.74653 7.65448 8.89319C7.79439 9.03986 7.98573 9.12387 8.1864 9.12673C8.38708 9.12959 8.58065 9.05107 8.72453 8.90845L12.1891 5.38442C12.2574 5.31049 12.313 5.22541 12.3537 5.13271C12.3933 5.0414 12.4138 4.94268 12.4138 4.84285C12.4138 4.74303 12.3933 4.6443 12.3537 4.553C12.3159 4.45873 12.2599 4.37314 12.1891 4.30128L8.72453 0.769626C8.58832 0.62343 8.40087 0.538152 8.20316 0.532435C8.00545 0.526718 7.81356 0.601026 7.66944 0.739115Z"
        fill={isRepost ? "#D4F70E" : "white"}
      />
      <path
        d="M10.1388 20.5265C10.2089 20.4556 10.2646 20.3712 10.3026 20.2783C10.3406 20.1853 10.3601 20.0856 10.3601 19.9849C10.3601 19.8842 10.3406 19.7845 10.3026 19.6916C10.2646 19.5986 10.2089 19.5143 10.1388 19.4434L7.95379 17.2161L13.9401 17.2161C14.7339 17.2161 15.4952 16.8946 16.0566 16.3224C16.6179 15.7502 16.9332 14.9742 16.9332 14.165L16.9332 6.25496C16.9332 6.05266 16.8544 5.85865 16.7141 5.7156C16.5737 5.57255 16.3834 5.49219 16.1849 5.49219C15.9865 5.49219 15.7962 5.57255 15.6558 5.7156C15.5155 5.85865 15.4367 6.05266 15.4367 6.25496L15.4367 14.165C15.4367 14.5696 15.279 14.9576 14.9983 15.2437C14.7177 15.5298 14.337 15.6905 13.9401 15.6905L7.95379 15.6905L10.1388 13.4632C10.2827 13.3206 10.3651 13.1255 10.3679 12.921C10.3707 12.7164 10.2937 12.5191 10.1538 12.3724C10.0138 12.2258 9.8225 12.1418 9.62182 12.1389C9.42115 12.136 9.22758 12.2146 9.0837 12.3572L5.61913 15.8812C5.55083 15.9551 5.49518 16.0402 5.45451 16.1329C5.41492 16.2242 5.39447 16.3229 5.39447 16.4228C5.39447 16.5226 5.41492 16.6213 5.45451 16.7126C5.49235 16.8069 5.54832 16.8925 5.61913 16.9643L9.0837 20.496C9.21991 20.6422 9.40735 20.7275 9.60506 20.7332C9.80278 20.7389 9.99467 20.6646 10.1388 20.5265Z"
        fill={isRepost ? "#D4F70E" : "white"}
      />
    </svg>
  );
};
export default IconRepostPlaylistIndividual;
