import { Box, Text, Button, useMediaQuery } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function CriteriaNewReferrals() {
  const { t } = useTranslation();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const desCriteriaNewReferrals = useMemo(
    () => [
      {
        id: 1,
        title: t("uniqueNewSubscriber"),
        des: t("desUniqueNewSubscriber"),
      },
      {
        id: 2,
        title: t("noSelfReferral"),
        des: t("desNoSelfReferral"),
      },
      {
        id: 3,
        title: t("successfullPayment"),
        des: t("desSuccessfullPayment"),
      },
      {
        id: 4,
        title: t("validReferralLink"),
        des: t("desValidReferralLink"),
      },
      {
        id: 5,
        title: t("activeAccount"),
        des: t("desActiveAccount"),
      },
    ],
    [t]
  );

  return (
    <Box mt="16px" mb={{ base: "16px", md: "0px" }}>
      <Box
        borderRadius="8px"
        background="rgba(56, 56, 61, 0.25)"
        padding={{ base: "40px 16px", md: "40px 20px", lg: "40px 35px" }}
      >
        <Text
          mb="20px"
          fontSize={{ base: "18px", md: "20px" }}
          fontWeight="400"
          opacity="0.8"
        >
          {t("criteriaNewReferrals")}
        </Text>
        {desCriteriaNewReferrals.map((item) => (
          <Text
            mb="15px"
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight="700"
            key={item.id}
          >
            {item.title}{" "}
            <span
              style={{
                fontSize: isLessThan766 ? "14px" : "16px",
                fontWeight: "400",
              }}
            >
              {item.des}
            </span>
          </Text>
        ))}
        <a
          href={process.env.REACT_APP_TERMS_CONDITIONS}
          target="_blank"
          rel="noreferrer"
        >
          <Button
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            padding="17px 21px"
            borderRadius="4px"
            height="54px"
            mt="80px"
            fontSize={{ base: "14px", md: "16px" }}
          >
            {t("termsOfService")}
          </Button>
        </a>
      </Box>
    </Box>
  );
}
