import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { checkOtpCode } from "app/redux/actions/users";
import DeviceDetector from "device-detector-js";
interface ModalVerificationCodeProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  valueEmail: string;
  isRememberMe: boolean;
}

export default function ModalVerificationCode({
  showModal,
  setShowModal,
  valueEmail,
  isRememberMe,
}: ModalVerificationCodeProps) {
  const { t } = useTranslation();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  useEffect(() => {
    if (otp.length >= 4) {
      dispatch(
        checkOtpCode({
          email: valueEmail,
          otp: Number(otp),
          isRememberMe: isRememberMe,
          device: device?.device?.type,
        })
      ).then((res) => {
        if (res.payload) {
          setShowModal(false);
          window.location.reload();
        }
      });
    }
  }, [
    dispatch,
    isRememberMe,
    otp,
    setShowModal,
    valueEmail,
    device?.device?.type,
  ]);

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      isCentered
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent w={{ base: "80%", md: "100%" }}>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => setShowModal(false)}
          right={3}
          top="10px"
          fontSize={{ base: "20px", md: "22px", lg: "24px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalBody padding={{ base: "20px", md: "60px 40px" }}>
          <Text
            mb="10px"
            textAlign="center"
            fontSize={{ base: "20px", md: "22px", lg: "24px" }}
            fontWeight="600"
          >
            {t("verificationCode")}
          </Text>
          <Text
            fontSize={{ base: "12px", md: "14px" }}
            textAlign="center"
            fontWeight="400"
            color="#898989"
          >
            {t("pleaseEnterYourConfirmationWeSentViaEmail")}
          </Text>
          <OtpInput
            containerStyle={{
              justifyContent: "center",
              marginTop: "10px",
            }}
            value={otp}
            onChange={setOtp}
            numInputs={4}
            inputType="number"
            renderSeparator={
              <span
                style={{
                  fontSize: "12px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              />
            }
            renderInput={(props) => <input {...props} />}
            inputStyle={{
              width: "40px",
              height: "40px",
              borderRadius: "4px",
              background: "#2E3036",
              border: "1px solid #D4F70E",
              margin: "10px 0px",
            }}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
