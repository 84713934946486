import React from "react";

const IconEmptyLyrics = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M11.0015 8.00108C10.4081 8.00108 9.82796 8.17705 9.33455 8.50674C8.84113 8.83643 8.45657 9.30503 8.22947 9.85328C8.00238 10.4015 7.94296 11.0048 8.05873 11.5868C8.1745 12.1688 8.46026 12.7035 8.87988 13.1231C9.29949 13.5427 9.83411 13.8284 10.4161 13.9442C10.9981 14.06 11.6014 14.0006 12.1497 13.7735C12.6979 13.5464 13.1665 13.1618 13.4962 12.6684C13.8259 12.175 14.0019 11.5949 14.0019 11.0015C14.0019 10.2057 13.6858 9.44256 13.1231 8.87988C12.5604 8.31719 11.7972 8.00108 11.0015 8.00108ZM38.2451 17.4223L21.4228 0.580109C21.2359 0.394722 21.0143 0.248053 20.7706 0.14851C20.5269 0.0489682 20.2659 -0.00148855 20.0027 3.3433e-05H2.0003C1.46979 3.3433e-05 0.961019 0.210775 0.585897 0.585897C0.210775 0.961019 3.3433e-05 1.46979 3.3433e-05 2.0003V20.0027C-0.00148855 20.2659 0.0489682 20.5269 0.14851 20.7706C0.248053 21.0143 0.394722 21.2359 0.580109 21.4228L17.4223 38.2451C18.5475 39.3688 20.0727 40 21.6629 40C23.2531 40 24.7783 39.3688 25.9034 38.2451L38.2451 26.0034C39.3688 24.8783 40 23.3531 40 21.7629C40 20.1727 39.3688 18.6475 38.2451 17.5223V17.4223ZM35.4247 23.0631L23.0631 35.4047C22.6883 35.7772 22.1813 35.9863 21.6529 35.9863C21.1244 35.9863 20.6175 35.7772 20.2427 35.4047L4.00056 19.1826V4.00056H19.1826L35.4247 20.2427C35.6101 20.4296 35.7567 20.6513 35.8563 20.895C35.9558 21.1387 36.0063 21.3996 36.0048 21.6629C36.0025 22.1876 35.7942 22.6905 35.4247 23.0631Z"
        fill="#777777"
      />
    </svg>
  );
};
export default IconEmptyLyrics;
