import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Message } from "app/assets/icons";
import IconDashboardProfile from "app/assets/icons/IconDashboardProfile";
import IconHomeProfile from "app/assets/icons/IconHomeProfile";
import IconLyricsProfile from "app/assets/icons/IconLyricsProfile";
import IconMyPlaylistProfile from "app/assets/icons/IconMyPlaylistProfile";
import IconPeopleProfile from "app/assets/icons/IconPeopleProfile";
import IconPrivacyPolicy from "app/assets/icons/IconPrivacyPolicy";
import IconTermAndConditions from "app/assets/icons/IconTerm&Conditions";
import UpgradePlanIcon from "app/assets/icons/IconUpgradePlan";
import Avatar from "app/assets/images/UserAvatar.png";
import { removeStripePaymentMethod, removeCart } from "app/redux/actions/cart";
import PurchasedImage from "app/assets/images/BsHandbag.png";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { PROFILE_TYPE, ROUTER_HEADER } from "constants/contractType";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.scss";
import { usePlayers } from "hooks/player/usePlayers";
import GlobalLoading from "../GlobalLoading";
import IconReferralProgram from "app/assets/icons/IconReferralProgram";
import { setSessionStorage } from "helpers/session";
import IconAccountSettings from "app/assets/icons/IconAccountSettings";
import { logout } from "app/redux/actions/users";

const ProfileHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const userAdmin = useSelector(selectUserAdmin);
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [titleHeader, setTitleHeader] = useState({
    title: t("home"),
    color: "white",
  });
  const loggedIn = useSelector(selectLoggedIn);

  const { wavesurfer, setIsAudioPlay } = usePlayers();
  const location = useLocation();

  const params = location.pathname.split("/")[1];

  const onLogout = useCallback(
    (router?: string) => {
      router && setSessionStorage("router", router);
      dispatch(removeStripePaymentMethod());
      dispatch(removeCart());
      history.push("/auth/login");

      if (wavesurfer) {
        wavesurfer.current?.pause();
        wavesurfer.current = null;
        setIsAudioPlay(false);
      }
    },
    [dispatch, setIsAudioPlay, history, wavesurfer]
  );

  const handleLogout = useCallback(() => {
    dispatch(logout({ isLogoutAll: false }));
    dispatch(removeStripePaymentMethod());
    dispatch(removeCart());
    history.push("/auth/login");
    if (wavesurfer) {
      wavesurfer.current?.pause();
      wavesurfer.current = null;
      setIsAudioPlay(false);
    }
  }, [dispatch, history, setIsAudioPlay, wavesurfer]);

  const handleClickUserItem = useCallback(() => {
    history.push(`/user/${userAdmin?.name}?profileType=${PROFILE_TYPE.TRACKS}`);
  }, [history, userAdmin?.name]);

  const handleClickMessages = useCallback(() => {
    history.push("/messages?tab=all");
  }, [history]);

  const handleClickLyrics = useCallback(() => {
    history.push("/lyrics");
  }, [history]);

  const redirectHomeDashboard = useCallback(
    (page) => {
      history.push(`/${page}`);
    },
    [history]
  );

  const listMenuProfile = [
    {
      icon: <IconHomeProfile />,
      content: t("home"),
      id: 0,
      onClickButton: () => redirectHomeDashboard("home"),
    },
    {
      icon: <IconPeopleProfile />,
      content: t("profile"),
      id: 1,
      onClickButton: () => (loggedIn ? handleClickUserItem() : onLogout()),
    },
    {
      icon: <IconDashboardProfile />,
      content: t("dashboard"),
      id: 2,
      onClickButton: () =>
        loggedIn ? redirectHomeDashboard("dashboard") : onLogout("dashboard"),
    },
    {
      icon: <Message />,
      content: t("messages"),
      id: 3,
      onClickButton: () =>
        loggedIn ? handleClickMessages() : onLogout("messages?tab=all"),
    },
    {
      icon: <IconLyricsProfile />,
      content: t("lyrics"),
      id: 4,
      onClickButton: () =>
        loggedIn ? handleClickLyrics() : onLogout("lyrics"),
    },
    {
      icon: <Image fallbackSrc={PurchasedImage} />,
      content: t("purchased"),
      id: 7,
      onClickButton: () =>
        loggedIn ? redirectHomeDashboard("purchased") : onLogout("purchased"),
    },
    {
      icon: <IconMyPlaylistProfile />,
      content: t("playlistsHub"),
      id: 8,
      onClickButton: () =>
        loggedIn ? redirectHomeDashboard("playlists") : onLogout("playlists"),
    },
    {
      icon: <IconAccountSettings />,
      content: t("accountSettings"),
      id: 10,
      onClickButton: () =>
        loggedIn
          ? redirectHomeDashboard("user/account-settings")
          : onLogout("user/account-settings"),
    },
    {
      icon: <IconReferralProgram />,
      content: t("referralProgram"),
      id: 9,
      onClickButton: () =>
        loggedIn ? redirectHomeDashboard("referral") : onLogout("referral"),
    },
    {
      icon: <IconTermAndConditions />,
      content: t("termAndConditions"),
      id: 5,
      onClickButton: () => redirectHomeDashboard("term-condition"),
    },
    {
      icon: <IconPrivacyPolicy />,
      content: t("privacyPolicy"),
      id: 6,
      onClickButton: () => redirectHomeDashboard("privacy-policy"),
    },
  ];

  useEffect(() => {
    switch (params) {
      case ROUTER_HEADER.HOME:
        setTitleHeader({
          title: t("home"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.DASHBOARD:
        setTitleHeader({
          title: t("dashboard"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.MESSAGES:
        setTitleHeader({
          title: t("messages"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.USER:
        setTitleHeader({
          title: t("profile"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.LYRICS:
        setTitleHeader({
          title: t("lyrics"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.PLAYLISTS:
        setTitleHeader({
          title: t("playlistsHub"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.PURCHASED:
        setTitleHeader({
          title: t("purchased"),
          color: "#D4F70E",
        });
        break;
      case ROUTER_HEADER.REFERRAL:
        setTitleHeader({
          title: t("referralProgram"),
          color: "#D4F70E",
        });
        break;

      default:
        break;
    }
  }, [params, t]);

  const handleUpgradePlan = useCallback(() => {
    loggedIn
      ? redirectHomeDashboard("dashboard/subscription")
      : onLogout("dashboard/subscription");
  }, [loggedIn, onLogout, redirectHomeDashboard]);

  return (
    <Box
      p={{ md: "0px 5px", lg: "0px 15px 0px 10px" }}
      className="profile-component"
    >
      {isOpen && <GlobalLoading />}
      <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                bg="none"
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                _focus={{ boxShadow: "none" }}
                p="0px"
                rightIcon={<ChevronDownIcon color="#D4F70E" />}
                color={titleHeader.color}
                className="fontCeraProRegular"
                fontSize="14px"
              >
                {titleHeader.title}
              </Button>
            </PopoverTrigger>

            <PopoverContent
              background="#24252A"
              borderColor="#24252A"
              padding="10px"
              _focus={{ boxShadow: "none" }}
            >
              <PopoverArrow />
              <PopoverHeader>
                <Flex alignItems="center">
                  <Image
                    w="54px"
                    height="54px"
                    borderRadius="full"
                    alt="User"
                    src={userAdmin?.profileImage}
                    fallbackSrc={Avatar}
                  />
                  <Box ml="16px">
                    <Text
                      fontSize="16px"
                      lineHeight="20px"
                      fontWeight="500"
                      fontFamily="Cera Pro Medium"
                    >
                      {loggedIn ? userAdmin?.name : t("guest")}
                    </Text>
                    <Text
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#949599"
                      mt="5px"
                      fontFamily="Cera Pro Regular"
                    >
                      {userAdmin?.state}
                    </Text>
                  </Box>
                </Flex>
                <Button
                  background="rgba(212, 247, 14, 0.04)"
                  border="1px solid rgba(212, 247, 14, 0.1)"
                  borderRadius="4px"
                  _hover={{ background: "rgba(212, 247, 14, 0.04)" }}
                  fontSize="12px"
                  lineHeight="14px"
                  color="#D4F70E"
                  leftIcon={<UpgradePlanIcon />}
                  size="sm"
                  w="85%"
                  mt="20px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  margin={" 20px auto"}
                  _focus={{ boxShadow: "none" }}
                  onClick={() => {
                    loggedIn
                      ? handleUpgradePlan()
                      : onLogout("dashboard/subscription");
                    onClose();
                  }}
                  fontFamily="Cera Pro Medium"
                >
                  {t("upgradePlan")}
                </Button>
              </PopoverHeader>
              <PopoverBody overflow="auto" padding={"0px 25px"}>
                {listMenuProfile.map((item) => (
                  <Flex
                    alignItems="center"
                    key={item.id}
                    _hover={{
                      background:
                        "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                      borderLeft: "1px solid #D4F70E",
                      borderRadius: "0px",
                    }}
                    padding="13px 10px"
                    onClick={() => {
                      item.onClickButton();
                      onClose();
                    }}
                    background={
                      titleHeader.title === item.content
                        ? "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)"
                        : "none"
                    }
                    borderLeft={
                      titleHeader.title === item.content
                        ? "1px solid #D4F70E"
                        : "none"
                    }
                    cursor="pointer"
                    mb="3px"
                  >
                    <Box w="20px">{item.icon}</Box>

                    <Text
                      className="fontCeraProRegular"
                      ml="20px"
                      fontSize="16px"
                      lineHeight="20px"
                    >
                      {item.content}
                    </Text>
                  </Flex>
                ))}
              </PopoverBody>

              <PopoverFooter>
                <Button
                  background="rgba(212, 247, 14, 0.04)"
                  border="1px solid rgba(212, 247, 14, 0.1)"
                  borderRadius="4px"
                  _hover={{ background: "rgba(212, 247, 14, 0.04)" }}
                  _focus={{ boxShadow: "none" }}
                  fontSize="12px"
                  lineHeight="14px"
                  color="#D4F70E"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  w="100%"
                  fontFamily="Cera Pro Medium"
                  onClick={loggedIn ? handleLogout : () => onLogout()}
                >
                  {t("logout")}
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default ProfileHeader;
