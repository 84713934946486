export const formatAudioDuration = (totalSeconds?: number) => {
  if (!totalSeconds && totalSeconds !== 0) {
    return "00:00";
  }
  const hours = Math.floor(totalSeconds / 3600);
  const remainingMinutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const remainingSeconds = Math.floor(
    totalSeconds - hours * 3600 - remainingMinutes * 60
  );

  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes =
    remainingMinutes < 10 ? `0${remainingMinutes}` : `${remainingMinutes}`;
  const formattedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : `${remainingSeconds}`;

  if (hours > 0) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else {
    return `${formattedMinutes}:${formattedSeconds}`;
  }
};

export const sleepDeplayTime = (ms: number) =>
  new Promise((r) => setTimeout(r, ms));
