import { PROFILE_TYPE } from "constants/contractType";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export default function RedirectPage() {
  const history = useHistory();
  const redirectPage = useCallback(
    (page) => {
      history.push(`/${page}`);
    },
    [history]
  );

  const pageProfile = useCallback((name) => {
    return `user/${name}?profileType=${PROFILE_TYPE.TRACKS}`;
  }, []);

  return { redirectPage, pageProfile };
}
