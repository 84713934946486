import { Licenses, TrackSoundKit } from "app/models";
import { planType } from "constants/planType";

const sumTotalItems = (cart: any, licenses = []) => {
  return (cart || [])?.reduce((acum: any, item: any) => {
    const currentLicence = licenses.find(
      (license: Licenses) => license.trackId === item?._id
    );
    let currentPrice = 0;
    if (currentLicence) {
      const { license, negotiation } = currentLicence;
      if (!license) {
        currentPrice = item.basicPrice;
      } else if (negotiation) {
        currentPrice = negotiation?.offerPrice;
      } else if (!license?.exclusive) {
        currentPrice = license?.trackPrice
          ? license?.trackPrice
          : license?.defaultTrackPrice;
      } else {
        currentPrice = Number(license?.exclusiveLicensePrice);
      }
    } else {
      if (item.basicPrice) {
        currentPrice = item.basicPrice;
      } else if (!item.licenses?.[0]?.exclusive) {
        currentPrice = item.licenses?.[0]?.trackPrice
          ? item.licenses?.[0]?.trackPrice
          : item.licenses?.[0]?.defaultTrackPrice;
      } else {
        currentPrice = Number(item.licenses?.[0]?.exclusiveLicensePrice);
      }
    }
    return acum + Number(currentPrice);
  }, 0);
};

const duplicateParse = (currentArray) => {
  const currentSet = new Set();
  return currentArray.filter((item) => {
    const duplicate = currentSet.has(item._id);
    currentSet.add(item._id);
    return !duplicate;
  });
};

const sumTotalDiscount = (cart: any) =>
  cart.reduce(
    (acum: any, { appliedDiscount }: any) => acum + appliedDiscount,
    0
  );

const maxFreeTracks = (carts: TrackSoundKit[] = [], trackLicenses = []) => {
  let bulkOffers = [];
  let selectedBulkOffers = [];

  carts.forEach((cartItem) => {
    (cartItem?.bulkOffers || []).forEach((bulkOffer) => {
      const { licenses = [], _id, minimumTracks, name, freeTracks } = bulkOffer;
      // usingLicensesIds is array of licenseId
      const usingLicensesIds = licenses.filter((licenseId) =>
        trackLicenses.some(
          (license) =>
            license?.license?._id === licenseId &&
            license?.trackId === cartItem?._id &&
            !cartItem?.excludeFromBulkDiscounts
        )
      );

      // allCurrentLicenses is array of license using for each selected track
      let allCurrentLicenses = trackLicenses.filter((license) =>
        usingLicensesIds.includes(license?.license?._id)
      );

      // excludeBulkDiscountsLicenses is array of license will be use in bulk discount
      let excludeBulkDiscountsLicenses = allCurrentLicenses.filter((license) =>
        carts.some(
          (track) =>
            license?.trackId === track?._id && !track?.excludeFromBulkDiscounts
        )
      );

      excludeBulkDiscountsLicenses = excludeBulkDiscountsLicenses.sort(
        (a, b) =>
          (Number(
            a.license?.trackPrice
              ? a.license?.trackPrice
              : a.license.defaultTrackPrice
          ) >
            Number(
              b.license?.trackPrice
                ? b.license?.trackPrice
                : b.license?.defaultTrackPrice
            ) &&
            1) ||
          -1
      );

      bulkOffers.push({
        _id,
        minimumTracks,
        name,
        freeTracks,
        createdBy: cartItem.createdBy,
        allCurrentLicenses,
        usingLicenses: excludeBulkDiscountsLicenses.slice(
          0,
          excludeBulkDiscountsLicenses.length > minimumTracks + freeTracks
            ? freeTracks
            : Math.max(excludeBulkDiscountsLicenses.length - minimumTracks, 0)
        ),
        hasApplyBulkOffer: excludeBulkDiscountsLicenses.length >= minimumTracks,
      });
    });
  });

  const filteredDuplicateBulks = duplicateParse(bulkOffers);

  filteredDuplicateBulks.forEach((filteredBulk) => {
    const hasSameAuthorAndAppliedBulkOffer = selectedBulkOffers.some(
      (i) =>
        i.createdBy === filteredBulk.createdBy && filteredBulk.hasApplyBulkOffer
    );
    if (hasSameAuthorAndAppliedBulkOffer) {
      selectedBulkOffers = selectedBulkOffers.map((selectedBulk) =>
        selectedBulk.allCurrentLicenses.length >
        filteredBulk.allCurrentLicenses.length
          ? selectedBulk
          : filteredBulk
      );
    } else {
      selectedBulkOffers = [...selectedBulkOffers, filteredBulk];
    }
  });

  return selectedBulkOffers;
};

const promoCodeApplyCheck = (
  currentLicense,
  promoCode,
  trackId,
  createdById
) => {
  return (
    currentLicense?.trackId === trackId &&
    (promoCode?.licenses || []).some(
      (promoCodeLicense) =>
        promoCodeLicense?._id === currentLicense.license?._id
    ) &&
    createdById === promoCode?.createdBy
  );
};

const sumTotalFeeService = (cart: any, licenses = []) => {
  const bulkOffers = maxFreeTracks(cart, licenses);

  return (cart || [])?.reduce((acum: any, item: any) => {
    const currentLicence = licenses.find(
      (license: Licenses) => license.trackId === item?._id
    );
    const isApplyBulk = bulkOffers.some((bulk) =>
      (bulk?.usingLicenses || []).some(
        (license) => license?.trackId === item?._id && bulk.hasApplyBulkOffer
      )
    );
    let currentPrice = 0;
    if (currentLicence) {
      const { license, negotiation } = currentLicence;
      if (!license) {
        currentPrice = item.basicPrice;
      } else if (negotiation) {
        currentPrice = negotiation?.offerPrice;
      } else if (!license?.exclusive) {
        currentPrice = license?.trackPrice
          ? license?.trackPrice
          : license?.defaultTrackPrice;
      } else {
        currentPrice = Number(license?.exclusiveLicensePrice);
      }
    } else {
      if (item.basicPrice) {
        currentPrice = item.basicPrice;
      } else if (!item.licenses?.[0]?.exclusive) {
        currentPrice = item.licenses?.[0]?.trackPrice
          ? item.licenses?.[0]?.trackPrice
          : item.licenses?.[0]?.defaultTrackPrice;
      } else {
        currentPrice = Number(item.licenses?.[0]?.exclusiveLicensePrice);
      }
    }
    if (isApplyBulk) {
      currentPrice = 0;
    } else if (
      (item?.createdBy?.platformFee === 0 &&
        item?.createdBy?.subscriptionType === planType.MAINSTREAM) ||
      (item?.createdBy?.platformFee === 10 &&
        item?.createdBy?.subscriptionType !== planType.MAINSTREAM)
    ) {
      currentPrice = currentPrice * 0.1;
    } else {
      currentPrice = 0;
    }
    return acum + Number(currentPrice);
  }, 0);
};

export {
  sumTotalItems,
  sumTotalDiscount,
  maxFreeTracks,
  promoCodeApplyCheck,
  sumTotalFeeService,
};
