import {
  Box,
  Flex,
  Image,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { CollaborationsModel } from "app/models";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import CollaborationsContent from "./collaborationsContent";
import AvatarUser from "app/assets/images/AvatarMusicLoading.svg";
import { useCollaborations } from "hooks/Collaborations/useCollaborations";
import { STATUS_COLOR } from "constants/negotiationType";

interface ModalCollaborationsProps {
  showModal: boolean;
  setShowModal: () => void;
  collaborationItem: CollaborationsModel;
}

export default function ModalCollaborations({
  showModal,
  setShowModal,
  collaborationItem,
}: ModalCollaborationsProps) {
  const { t } = useTranslation();
  const { createdByMe } = useCollaborations();
  const checkTypeCollaborations = !!collaborationItem?.trackId;

  const textPercent = [
    {
      name: t("profitShareCollaborations"),
      width: "50%",
    },
    {
      name: checkTypeCollaborations && t("publishCollaborations"),
      width: "50%",
    },
  ];

  const pricePercent = [
    {
      price: `${collaborationItem?.profitShare}%`,
      width: "50%",
    },
    {
      price: checkTypeCollaborations && `${collaborationItem?.publishing}%`,
      width: "50%",
    },
  ];

  const currentLicense = useMemo(
    () => collaborationItem?.licenses?.[0],
    [collaborationItem?.licenses]
  );
  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={{ base: "unset", md: "700px" }}
        mx={{ base: "10px", md: "0px" }}
        maxH={{ base: "650px", md: "unset" }}
        overflow="scroll"
      >
        <ModalHeader
          fontSize={{ base: "22px", md: "24px", xl: "28px" }}
          lineHeight="24px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          backdropFilter="blur(10px)"
          position="sticky"
          top="0px"
        >
          {t("collaborations")}
          <CloseButton
            _focus={{
              borderWidth: "0px",
            }}
            onClick={setShowModal}
            top={{ base: "8px", md: "15px" }}
            fontSize={{ base: "20px", md: "22px", xl: "26px" }}
            borderColor="#38383D"
            _hover={{
              border: "1px solid #38383D !important",
              padding: "20px",
            }}
            padding={{ md: "20px" }}
            color="#d4f70e"
          />
        </ModalHeader>
        <ModalBody
          margin="auto"
          padding={{
            base: "0px 12px 20px 12px",
            md: "0px 12px 20px 12px",
            xl: "0px 30px 50px 30px",
          }}
        >
          <CollaborationsContent
            collaboration={collaborationItem}
            typeCollaborations={checkTypeCollaborations}
          />

          <Flex
            background="#24252A"
            padding="8px"
            w={{ base: "100%", md: "343px" }}
            borderRadius="8px"
            alignItems="center"
            mb="10px"
          >
            <Image
              w="99px"
              height="102px"
              alt="User"
              src={
                checkTypeCollaborations
                  ? collaborationItem?.trackId?.coverUrl
                  : collaborationItem?.soundkitId?.coverUrl
              }
              borderRadius="6px"
              fallbackSrc={AvatarUser}
            />
            <Box ml="15px" w="60%">
              <Box mb="20px">
                <Text
                  noOfLines={2}
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="700"
                >
                  {checkTypeCollaborations
                    ? collaborationItem?.trackId?.title
                    : collaborationItem?.soundkitId?.title}
                </Text>
                {checkTypeCollaborations && (
                  <Text
                    fontSize="12px"
                    lineHeight="16px"
                    fontWeight="400"
                    color="rgba(255, 255, 255, 0.3)"
                    mt="5px"
                  >
                    {currentLicense?.exclusive ? "Exclusive" : "Non Exclusive"}{" "}
                    Contract
                  </Text>
                )}
              </Box>

              <Box>
                <Flex mb="3px">
                  {pricePercent.map((item, index) => (
                    <Text
                      fontSize="16px"
                      lineHeight="20px"
                      fontWeight="900"
                      key={index}
                      w={item.width}
                    >
                      {item.price}
                    </Text>
                  ))}
                </Flex>

                <Flex>
                  {textPercent.map((item, index) => (
                    <Text
                      fontSize="12px"
                      lineHeight="15px"
                      fontWeight="400"
                      color="#535353"
                      key={index}
                      w={item.width}
                    >
                      {item.name}
                    </Text>
                  ))}
                </Flex>
              </Box>
            </Box>
          </Flex>
          {createdByMe(collaborationItem?.createdBy?._id) ? (
            <CollaborationsContent
              collaboration={collaborationItem}
              isRectangBox
            />
          ) : (
            collaborationItem?.status !== STATUS_COLOR.PENDING && (
              <CollaborationsContent
                collaboration={collaborationItem}
                isRectangBox
              />
            )
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
