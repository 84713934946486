import { createSelector } from "@reduxjs/toolkit";

export const selectUserState = (state: any) => state.user;

export const selectToken = createSelector(
  selectUserState,
  (user) => user.token
);

export const selectUserId = createSelector(
  selectUserState,
  (user) => user.userId
);

export const selectSocialMediaLogin = createSelector(
  selectUserState,
  (user) => user.socialMediaLogin
);

export const selectLoggedIn = createSelector(
  selectUserState,
  (user) => user.loggedIn
);

export const selectUserHasBiometryEnabled = createSelector(
  selectUserState,
  (user) => user.userHasBiometryEnabled
);

export const selectUserAdmin = createSelector(
  selectUserState,
  (user) => user.userAdmin
);

export const selectUserById = createSelector(
  selectUserState,
  (user) => user.userInfo
);

export const selectUserRecentActions = createSelector(
  selectUserState,
  (user) => user.userRecentAction
);

export const selectLoadingUserRecentActions = createSelector(
  selectUserState,
  (user) => user.isLoading
);

export const selectLoadingUpdateUser = createSelector(
  selectUserState,
  (user) => user.isLoadingUpdateUser
);

export const selectIsSuccess = createSelector(
  selectUserState,
  (user) => user.isError
);
