import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  CloseButton,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface ModalNotificationAddTrackProps {
  showModal: boolean;
  setShowModal: () => void;
  isPaymentMethods?: boolean;
}

export default function ModalNotificationAddTrack({
  showModal,
  setShowModal,
  isPaymentMethods = false,
}: ModalNotificationAddTrackProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const handleEditProfile = useCallback(() => {
    history.push("/user/account-settings");
    setShowModal();
  }, [history, setShowModal]);

  const handleAddPaymentMethods = useCallback(() => {
    history.push("/dashboard/payment");
    setShowModal();
  }, [history, setShowModal]);

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent minW="500px">
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top="15px"
          fontSize="22px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalHeader
          fontFamily="Cera Pro Regular"
          fontSize="28px"
          textAlign="center"
          padding="30px 30px 0px 30px"
        >
          {isPaymentMethods
            ? t("addPaymentAndWallets")
            : t("completeYourProfile")}
        </ModalHeader>
        <ModalBody padding="10px 60px">
          <Text
            fontFamily="Cera Pro Regular"
            textAlign="center"
            fontSize="16px"
            lineHeight="20px"
          >
            {isPaymentMethods
              ? t("desAddPaymentAndWallets")
              : t("beforePublishingYourTracks")}
          </Text>
          <Flex
            margin="30px 0px"
            w="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              padding="25px 50px"
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              borderRadius="4px"
              fontFamily="Cera Pro Regular"
              onClick={
                isPaymentMethods ? handleAddPaymentMethods : handleEditProfile
              }
            >
              {isPaymentMethods ? t("add") : t("EditProfile")}
            </Button>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
