import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import { selectPaymentType } from "app/redux/actions/cart";
import { PAYMENT_TYPE_METHOD } from "constants/paymentType";
import { useCart } from "hooks/cart/useCart";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CartSummary from "./CartSummary";
import SelectPayment from "./SelectPayment";
import "./styles.scss";
import StripeCheckoutIcon from "app/assets/images/StripeCheckoutIcon.svg";
import PaypalIconCheckout from "app/assets/icons/PaypalIconCheckout";

export default function PaymentMethods() {
  const dispatch = useDispatch();
  const { isStripeSelect, paymentMethod, isPaypalSelect, paymentType } =
    useCart();

  const { t } = useTranslation();
  const history = useHistory();

  const redirectDashboard = () => {
    history.push({
      pathname: "/cart",
    });
  };

  const onConfirm = useCallback(() => {
    if (isStripeSelect) {
      return (
        dispatch(selectPaymentType(PAYMENT_TYPE_METHOD.STRIPE)),
        history.push("/cart/confirm")
      );
    }
    return (
      dispatch(selectPaymentType(PAYMENT_TYPE_METHOD.PAYPAL)),
      history.push("/cart/confirm")
    );
  }, [isStripeSelect, dispatch, history]);

  return (
    <Box
      p={{ base: "20px 16px", md: "20px 30px", xl: "40px 80px" }}
      className="payment-methods"
    >
      <Flex alignItems="center" mb="32px">
        <Box
          cursor="pointer"
          className="vector-button"
          mr={{ base: "10px", md: "24px" }}
          onClick={redirectDashboard}
        >
          <Back />
        </Box>
        <Text fontSize={{ base: "24px", xl: "32px" }}>
          {t("paymentMethods")}
        </Text>
      </Flex>
      <Flex
        display={{ base: "block", md: "flex" }}
        gridGap="20px"
        justifyContent="space-between"
      >
        <Box w={{ base: "100%", md: "60%", xl: "55%" }}>
          <SelectPayment />
        </Box>
        <Box w={{ base: "100%", md: "40%", xl: "25%" }} mt="20px">
          <>
            <CartSummary
              nameStripe={paymentMethod?.payload?.billing_details?.name}
            />
            <Button
              backgroundColor="#D4F70E"
              color="#000000"
              fontSize="14px"
              _hover={{ backgroundColor: "none" }}
              p="22px 60px"
              borderRadius="4px"
              w="100%"
              marginTop="20px"
              onClick={onConfirm}
              isDisabled={
                (!isPaypalSelect &&
                  !paymentMethod?.payload?.billing_details?.name) ||
                !paymentType
              }
            >
              {isPaypalSelect ? <PaypalIconCheckout /> : t("confirm")}
            </Button>
            <Flex justifyContent="center" mt="20px">
              {isStripeSelect && <Image fallbackSrc={StripeCheckoutIcon} />}
              {isPaypalSelect && (
                <Text fontSize="14px" fontWeight="400">
                  {t("theSaferEasierWayToPay")}
                </Text>
              )}
            </Flex>
          </>
        </Box>
      </Flex>
    </Box>
  );
}
