import { createSelector } from "@reduxjs/toolkit";
import { InitFileProgressState } from "../reducers/filesUpload";

export const selectedFilesUpload = (state: {
  filesUpload: InitFileProgressState;
}) => state.filesUpload;

export const filesAutoTaggedProgressSelector = createSelector(
  selectedFilesUpload,
  ({ filesAutoTaggedProgress }) => {
    return filesAutoTaggedProgress;
  }
);

export const filesCustomTaggedProgressSelector = createSelector(
  selectedFilesUpload,
  ({ filesCustomTaggedProgress }) => {
    return filesCustomTaggedProgress;
  }
);

export const filesTrackoutStemsProgressSelector = createSelector(
  selectedFilesUpload,
  ({ filesTrackoutStemsProgress }) => {
    return filesTrackoutStemsProgress;
  }
);
