import {
  fetchBulkOffers,
  deleteBulkOffer,
  changeActiveBulkOffer,
} from "app/redux/actions/bulkOffer";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { bulkOfferSelector } from "app/redux/selectors/bulkOffer";

export const useBulkOffer = () => {
  const dispatch = useDispatch();
  const bulkOffer = useSelector(bulkOfferSelector);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchBulkOffers.typePrefix], state)
  );
  const onFetchBulkOffers = useCallback(() => {
    dispatch(fetchBulkOffers());
  }, [dispatch]);

  const onDeleteBulkOffer = useCallback(
    (bulkOfferId) => {
      dispatch(deleteBulkOffer(bulkOfferId));
    },
    [dispatch]
  );

  const onChangeActiveBulkOffer = useCallback(
    (bulkOfferId) => {
      dispatch(changeActiveBulkOffer(bulkOfferId));
    },
    [dispatch]
  );

  return {
    isLoading,
    bulkOffer,
    onDeleteBulkOffer,
    onFetchBulkOffers,
    onChangeActiveBulkOffer,
  };
};
