import React, { useCallback, useMemo } from "react";
import {
  Box,
  Flex,
  Text,
  Image,
  Select,
  InputGroup,
  InputLeftElement,
  Input,
  useMediaQuery,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import AvatarUserLoading from "app/assets/images/UserAvatar.png";
import SendReminder from "app/assets/icons/IconSendReminder";
import { transaction } from "app/models";
import { TRACK_TYPE } from "constants/trackTypes";
import { roleText } from "constants/collaboratorType";
import dayjs from "dayjs";
import { formatTimeByDayMonthYear } from "utils/timeAgo";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../ConversationsPage/config/firebase";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { SearchIcon } from "app/assets/icons";
import { useMyTrack } from "hooks/track/useMyTrack";
import EmptyLicenseExpiry from "./emptyLicenseExpiry";
interface ItemLicenseExpiryprops {
  tracksLicenseExpiry: transaction[];
  setTimeExpiry: (value: string) => void;
  timeExpiry: string;
}

export default function ItemLicenseExpiry({
  tracksLicenseExpiry,
  setTimeExpiry,
  timeExpiry,
}: ItemLicenseExpiryprops) {
  const currentDate = dayjs();
  const { t } = useTranslation();
  const { handleSearchText, debouncedSearchText } = useMyTrack();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const tableName = [
    t("trackName"),
    t("purchaser"),
    t("license"),
    t("licenseStatus"),
    t("price"),
    t("sendReminder"),
  ];
  const licensesName = useCallback(
    (item: transaction) =>
      (item?.track?.licenses || [])
        .filter((license) => license._id === item?.license)
        .map((item) => item.name),
    []
  );

  const sendMessage = useCallback(
    async (buyerId, sellerId, name) => {
      const sorted = [buyerId, sellerId].sort();
      const msg = query(
        collection(db, "conversations"),
        where("users", "==", sorted),
        where("wasDeleted", "==", false)
      );
      const querySnapshot = await getDocs(msg);
      addDoc(
        collection(
          db,
          "conversations",
          querySnapshot?.docs?.[0]?.id as string,
          "messages"
        ),
        {
          sender: sellerId,
          content: `${t("notificationLicenseExpiry")}${name} ${t(
            "isAboutToExpire"
          )}${t("pleaseRenewToContinue")}`,
          type: "text",
          createdAt: serverTimestamp(),
        }
      );
      ToastPlanSubscription({
        description: t("sendReminderSuccess"),
        isError: false,
      });
    },
    [t]
  );

  const filterSearch = useMemo(() => {
    return (tracksLicenseExpiry || []).filter(
      (item) =>
        item?.track?.title
          ?.toLowerCase()
          ?.indexOf(debouncedSearchText.toLowerCase()) !== -1
    );
  }, [debouncedSearchText, tracksLicenseExpiry]);

  return (
    <Box mt="30px" className="item-license-expiry">
      <Flex justifyContent="end" m="0px 0px 20px 0px">
        <InputGroup
          w={{ base: "100%", md: "20%" }}
          _focusVisible={{
            outline: "none",
            border: "none",
          }}
          border="none"
          background=" #222226"
          borderTopLeftRadius="8px"
          borderBottomLeftRadius="8px"
          _focusWithin={{
            border: "none",
          }}
          minW={{ base: "180px", md: "240", xl: "280px" }}
        >
          <InputLeftElement children={<SearchIcon color="white" />} />
          <Input
            _focus={{
              boxShadow: "none !important",
            }}
            placeholder={t("search")}
            border="none"
            fontSize="14px"
            fontFamily="Cera Pro Medium"
            padding="0px 10px 0px 40px"
            onChange={handleSearchText}
          />
        </InputGroup>
        <Select
          placeholder="Select option"
          w="100%"
          minW="160px"
          maxW="160px"
          border="none"
          background=" #222226"
          borderTopLeftRadius="0px"
          borderBottomLeftRadius="0px"
          borderLeft="1px solid #2C2C30"
          _focus={{
            borderColor: "#2C2C30",
          }}
          _hover={{
            borderColor: "#2C2C30",
          }}
          value={timeExpiry}
          onChange={(e) => setTimeExpiry(e.target.value)}
          fontSize={{ base: "14px", md: "16px" }}
        >
          <option value="30">{t("last30Days")}</option>
          <option value="90">{t("last90Days")}</option>
          <option value="365">{t("last365Days")}</option>
          <option value="730">{t("last730Days")}</option>
        </Select>
      </Flex>
      <Box maxH="700px" overflow="scroll">
        <Flex
          minW={{ base: "900px", md: "700px" }}
          w="100%"
          position="sticky"
          top="0px"
          backdropFilter="blur(20px)"
          zIndex={2}
          display={{ base: "none", md: "flex" }}
        >
          {tableName.map((item, index) => (
            <Text
              key={index}
              className={`table-${index}`}
              fontSize={{ base: "14px", md: "16px" }}
              color="#D3D3D4"
              py="20px"
              position={index === 0 ? "sticky" : "unset"}
              backdropFilter="blur(20px)"
              w={index === 0 ? "120px" : ""}
              zIndex={index === 0 ? "5" : ""}
              left={0}
            >
              {item}
            </Text>
          ))}
        </Flex>
        {filterSearch?.length > 0 ? (
          (filterSearch || []).map((item) =>
            isLessThan766 ? (
              <>
                <Box mb="20px">
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb="10px"
                  >
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("trackName")}
                    </Text>
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("purchaser")}
                    </Text>
                  </Flex>

                  <Flex justifyContent="space-between" mb="10px">
                    <Flex alignItems="center" w="75%">
                      <Image
                        w="35px"
                        h="36px"
                        borderRadius="6px"
                        fallbackSrc={ImageDefault}
                        src={item?.track?.coverUrl}
                      />
                      <Box ml="10px" w="80%" overflow="hidden">
                        <Text fontSize="16px" fontWeight="400" noOfLines={1}>
                          {item?.track?.title}
                        </Text>
                        <Flex mt="5px">
                          <Box
                            display="flex"
                            alignItems="center"
                            borderRadius="4px"
                            padding={{ base: "2px 6px", lg: "2px 10px" }}
                            mr="5px"
                            mb="5px"
                            backgroundColor="#2b2e1b"
                            maxW={{ base: "100%", md: "85px", lg: "100%" }}
                          >
                            <Text
                              fontSize="12px"
                              lineHeight="14px"
                              color="#d4f70e"
                              textOverflow="ellipsis"
                              overflow="hidden"
                              whiteSpace="nowrap"
                            >
                              {t(TRACK_TYPE[item?.track?.type])}
                            </Text>
                          </Box>
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#464649"
                            cursor="pointer"
                            noOfLines={1}
                          >
                            {`By ${item?.track?.createdBy?.name}`}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex gap="10px" w="25%" justifyContent="end">
                      <Image
                        fallbackSrc={AvatarUserLoading}
                        w="40px"
                        h="40px"
                        borderRadius="full"
                        src={item?.buyerId?.profileImage}
                      />
                      <Box overflow="hidden">
                        <Flex gap="5px" overflow="hidden">
                          <Text fontSize="14px" fontWeight="400" noOfLines={1}>
                            {item.buyerId?.name}
                          </Text>
                        </Flex>
                        <Text
                          fontSize="14px"
                          fontWeight="400"
                          color="#464649"
                          noOfLines={1}
                        >
                          {roleText(item.buyerId?.role?.[0])}
                        </Text>
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb="10px"
                  >
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("license")}
                    </Text>
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("licenseStatus")}
                    </Text>
                  </Flex>
                  <Flex justifyContent="space-between" mb="10px">
                    <Box display="flex">
                      <Box
                        display="inline-block"
                        alignItems="center"
                        justifyContent="center"
                        background="#475025"
                        padding="3px 10px"
                        borderRadius="4px"
                        alignSelf="baseline"
                      >
                        <Text color="#D4F70E" fontSize="12px" fontWeight="500">
                          {licensesName(item)}
                        </Text>
                      </Box>
                    </Box>
                    <Box
                      w={{ base: "100%", md: "15%" }}
                      display="flex"
                      flexDirection="column"
                      alignItems="end"
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        borderRadius="4px"
                        padding="4px 10px"
                        mb="5px"
                        backgroundColor={
                          currentDate.isAfter(item?.expirationDate)
                            ? "#472527"
                            : "#2b2e1b"
                        }
                      >
                        <Text
                          fontSize="12px"
                          fontWeight="500"
                          color={
                            currentDate.isAfter(item?.expirationDate)
                              ? "#ED3636"
                              : "#d4f70e"
                          }
                        >
                          {currentDate.isAfter(item?.expirationDate)
                            ? t("negotiationsStatus.EXPIRED")
                            : t("Active")}
                        </Text>
                      </Box>
                      <Flex display={"block"} flexWrap="wrap">
                        <Text
                          fontSize="12px"
                          fontWeight="400"
                          mr="3px"
                          textAlign="end"
                        >
                          {t("ExpirationDate")}
                        </Text>
                        <Text textAlign="end" fontSize="12px" fontWeight="400">
                          {formatTimeByDayMonthYear(item?.expirationDate)}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex
                    alignItems="center"
                    justifyContent="space-between"
                    mb="10px"
                  >
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("price")}
                    </Text>
                    <Text fontSize="14px" color="#777777" fontWeight="400">
                      {t("sendReminder")}
                    </Text>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      fontWeight={{ base: "500", md: "700" }}
                    >
                      ${Number(item.price)?.toFixed(2)}
                    </Text>

                    <Box
                      onClick={() =>
                        sendMessage(
                          item?.buyerId?._id,
                          item?.sellerId?._id,
                          item?.track?.title
                        )
                      }
                      display="flex"
                      justifyContent="center"
                      w="15%"
                      cursor="pointer"
                    >
                      <Box
                        _hover={{
                          background: "#24252A",
                          padding: "10px",
                          border: "1px solid #38383D",
                          borderRadius: "8px",
                        }}
                      >
                        <SendReminder />
                      </Box>
                    </Box>
                  </Flex>
                </Box>
                <Divider borderColor="#535353" m="20px 0px" />
              </>
            ) : (
              <Flex
                gap="0px"
                alignItems="center"
                pt={{ base: "15px", md: "15px", xl: "25px" }}
                pb={{ base: "25px", md: "20px", xl: "30px" }}
                borderBottom="1px"
                borderBottomColor="#2D2E33"
                key={item._id}
                minW={{ md: "700px" }}
                display={{ base: "block", md: "flex" }}
              >
                <Flex
                  alignItems="center"
                  w={{ base: "100%", md: "30%" }}
                  position="sticky"
                  height={"100%"}
                  left="0px"
                  zIndex={1}
                  backdropFilter="blur(20px)"
                  overflow="hidden"
                >
                  <Image
                    w={{ base: "72px", md: "48px" }}
                    h={{ base: "72px", md: "48px" }}
                    borderRadius="4px"
                    fallbackSrc={ImageDefault}
                    src={item?.track?.coverUrl}
                  />
                  <Box
                    ml={{ base: "16px", md: "10px" }}
                    w={{ base: "100%", md: "75%" }}
                  >
                    <Text
                      className="title-text"
                      fontSize="16px"
                      fontWeight="600"
                      mb="2px"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="clip"
                      w="90%"
                    >
                      {item?.track?.title}
                    </Text>
                    <Flex alignItems="center">
                      <Box
                        background="#363A27"
                        padding="1px 8px"
                        borderRadius="4px"
                      >
                        <Text fontSize="12px" fontWeight="500" color="#D4F70E">
                          {t(TRACK_TYPE[item?.track?.type])}
                        </Text>
                      </Box>
                      <Text
                        ml="10px"
                        fontSize="14px"
                        fontWeight="400"
                        color="#505155"
                      >
                        {item?.track?.createdBy?.name}
                      </Text>
                    </Flex>
                  </Box>
                </Flex>
                <Flex w={{ base: "100%", md: "20%" }} alignItems="center">
                  <Image
                    fallbackSrc={AvatarUserLoading}
                    w="40px"
                    h="40px"
                    borderRadius="full"
                    src={item?.buyerId?.profileImage}
                  />
                  <Box ml="10px">
                    <Text fontSize="16px" fontWeight="700">
                      {item.buyerId?.name}
                    </Text>
                    <Text fontSize="14px" fontWeight="500" color="#545559">
                      {roleText(item.buyerId?.role?.[0])}
                    </Text>
                  </Box>
                </Flex>
                <Box
                  w={{ base: "100%", md: "10%" }}
                  display="flex"
                  justifyContent={{ base: "start", md: "center" }}
                >
                  <Box
                    display="inline-block"
                    alignItems="center"
                    justifyContent="center"
                    background="#475025"
                    padding="3px 10px"
                    borderRadius="4px"
                  >
                    <Text color="#D4F70E" fontSize="12px" fontWeight="500">
                      {licensesName(item)}
                    </Text>
                  </Box>
                </Box>
                <Box
                  w={{ base: "100%", md: "15%" }}
                  display="flex"
                  flexDirection="column"
                  alignItems={{ base: "start", md: "center" }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="4px"
                    padding="4px 10px"
                    mb="5px"
                    backgroundColor={
                      currentDate.isAfter(item?.expirationDate)
                        ? "#472527"
                        : "#2b2e1b"
                    }
                  >
                    <Text
                      fontSize="12px"
                      fontWeight="500"
                      color={
                        currentDate.isAfter(item?.expirationDate)
                          ? "#ED3636"
                          : "#d4f70e"
                      }
                    >
                      {currentDate.isAfter(item?.expirationDate)
                        ? t("negotiationsStatus.EXPIRED")
                        : t("Active")}
                    </Text>
                  </Box>
                  <Flex display={{ base: "flex", md: "block" }} flexWrap="wrap">
                    <Text
                      fontSize={{ base: "16px", md: "10px" }}
                      fontWeight="400"
                      mr={{ base: "3px", md: "0px" }}
                      textAlign="center"
                    >
                      {t("ExpirationDate")}
                    </Text>
                    <Text
                      textAlign="center"
                      fontSize={{ base: "16px", md: "10px" }}
                      fontWeight="400"
                    >
                      {formatTimeByDayMonthYear(item?.expirationDate)}
                    </Text>
                  </Flex>
                </Box>
                <Text
                  textAlign={{ base: "unset", md: "center" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight={{ base: "500", md: "700" }}
                  w={{ base: "100%", md: "10%" }}
                >
                  ${Number(item.price)?.toFixed(2)}
                </Text>

                <Box
                  onClick={() =>
                    sendMessage(
                      item?.buyerId?._id,
                      item?.sellerId?._id,
                      item?.track?.title
                    )
                  }
                  display="flex"
                  justifyContent="center"
                  w="15%"
                  cursor="pointer"
                >
                  <Box
                    _hover={{
                      background: "#24252A",
                      padding: "10px",
                      border: "1px solid #38383D",
                      borderRadius: "8px",
                    }}
                  >
                    <SendReminder />
                  </Box>
                </Box>
              </Flex>
            )
          )
        ) : (
          <EmptyLicenseExpiry />
        )}
      </Box>
    </Box>
  );
}
