import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAllGenres } from "app/redux/selectors/genres";
import { fetchGenres } from "app/redux/actions/genres";

export const useGenre = () => {
  const dispatch = useDispatch();

  const genres = useSelector(selectAllGenres);

  const getGenres = useCallback(() => {
    dispatch(fetchGenres());
  }, [dispatch]);

  const getSubGenres = useCallback(
    (selectedGenreName) => {
      const selectedGenre = genres.find(
        (genre) => genre.displayName === selectedGenreName
      );
      if (selectedGenre) {
        return (selectedGenre?.subGenres || []).map((subGenre: string) => ({
          label: subGenre,
          value: subGenre,
        }));
      } else {
        return [];
      }
    },
    [genres]
  );

  return {
    genres,
    getSubGenres,
    getGenres,
  };
};
