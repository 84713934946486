import React from "react";

const SendReminder = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
    >
      <path
        d="M21.0658 2.51013C14.9959 5.04848 8.92589 7.58683 2.85592 10.1252C4.18028 10.677 5.615 11.2288 6.93935 11.7806C7.82226 11.2288 17.0927 5.82102 17.3135 5.82102C17.4238 5.82102 17.5342 5.93139 17.5342 6.04175C17.5342 6.37284 11.2435 12.5532 10.471 13.3257L9.58807 16.9677L12.899 14.2086L17.7549 18.7335C18.086 17.078 20.514 4.71739 20.9555 2.51013H21.0658ZM20.6244 0.19251C22.3902 -0.469668 23.9353 0.633962 23.4938 2.6205C23.4938 2.6205 20.514 17.7402 20.1829 19.2853C19.9622 20.9408 17.7549 21.6029 16.3202 20.3889L12.899 17.2988L10.1399 19.6164C8.81552 20.6097 7.71189 19.3957 7.27044 18.1817C7.04972 17.5195 6.16681 14.8708 5.83572 13.7672C4.73209 13.3257 2.19374 12.3324 1.53157 12.0014C-0.454968 11.2288 -0.565331 8.91119 1.53157 8.13865C7.93262 5.48993 14.3337 2.6205 20.7347 0.0821468L20.6244 0.19251Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default SendReminder;
