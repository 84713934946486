export const shuffle = (audioArray: string[]) => {
  let audioLength = audioArray.length,
    temp,
    index;

  // While there remain elements to shuffle…
  while (audioLength) {
    // Pick a remaining element…
    index = Math.floor(Math.random() * audioLength--);

    // And swap it with the current element.
    temp = audioArray[audioLength];
    audioArray[audioLength] = audioArray[index];
    audioArray[index] = temp;
  }

  return audioArray;
};

export const getRandomNoRepeats = <T>(selectArray: T[]) => {
  let copyArray = selectArray.slice(0);
  return function () {
    if (copyArray.length < 1) {
      copyArray = selectArray.slice(0);
    }
    let index = Math.floor(Math.random() * copyArray.length);
    let item = copyArray[index];
    copyArray.splice(index, 1);
    return item;
  };
};
