import * as yup from "yup";

const formSchema = yup.object().shape({
  promoCode: yup.string().required("promoCodeRequired"),
  productType: yup.string().required("productTypeRequired"),
  percent: yup
    .number()
    .positive("percentPositive")
    .lessThan(50.00001, "percentLessThan50")
    .required("percentRequired"),
  nonExpiring: yup.boolean().notRequired(),
  expirationDate: yup.date().when("nonExpiring", {
    is: true,
    then: yup.date(),
    otherwise: yup.date().required("expirationDateRequired"),
  }),
  licenses: yup.array().when("productType", {
    is: "TRACK",
    then: yup.array().min(1, "atLeastOneLicense"),
    otherwise: yup.array(),
  }),
});

export default formSchema;
