import ToastPlanSubscription from "app/components/toast/toastplan";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import { CreatePlaylist } from "app/models";

export default class PlaylistsService {
  static async createPlaylists(playlist: CreatePlaylist) {
    try {
      const response = await axiosService.post("/playlists", playlist);
      ToastPlanSubscription({
        description: "Create playlist success",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async editPlaylists(playlist: CreatePlaylist) {
    try {
      const response = await axiosService.put(
        `/playlists/${playlist._id}`,
        playlist
      );
      ToastPlanSubscription({
        description: "Edit playlist success",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getPlaylists(visibility?: string) {
    try {
      const response = await axiosService.get("/playlists", {
        params: { visibility },
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getPlaylistsInProfile(createdBy?: string) {
    try {
      const response = await axiosService.get("/playlists/profile", {
        params: { createdBy },
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async addTrackToPlaylist(playlist: CreatePlaylist) {
    try {
      await axiosService.post("/playlists/add-track", playlist);
      ToastPlanSubscription({
        description: "Add track to playlist success",
        isError: false,
      });
      return playlist;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async removeTrackToPlaylist(playlist: CreatePlaylist) {
    try {
      await axiosService.post("/playlists/remove-track", playlist);
      ToastPlanSubscription({
        description: "Remove track to playlist success",
        isError: false,
      });
      return playlist;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getIndividualPlaylist(id: string) {
    try {
      const response = await axiosService.get(`/playlists/${id}`);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deletePlaylists(playlistIds: string[]) {
    try {
      await axiosService.delete(`/playlists`, {
        data: playlistIds,
      });
      ToastPlanSubscription({
        description: "Delete playlist success",
        isError: false,
      });
      return playlistIds;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getFavoritesPlaylist() {
    try {
      const { data } = await axiosService.get("/playlists/favorites");
      return data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getRepostPlaylists() {
    try {
      const { data } = await axiosService.get("/playlists/reposts");
      return data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async followUnfollowPlaylists(playlist: CreatePlaylist) {
    try {
      const response = await axiosService.post(
        "/playlists/follow-unfollow",
        playlist
      );
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getPlaylistsFollowing() {
    try {
      const response = await axiosService.get("/playlists/following");
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}
