import React from "react";

const VectorButton = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_15:27)">
        <circle cx="23" cy="23" r="16" fill="white" fillOpacity="0.06" />
      </g>
      <path
        d="M29.1481 20.3323L20.5606 15.4074C20.0959 15.1391 19.5685 14.9985 19.0319 15C18.4953 15.0015 17.9687 15.1449 17.5055 15.4158C17.0423 15.6867 16.6591 16.0753 16.3947 16.5423C16.1303 17.0092 15.9942 17.5378 16.0002 18.0743V27.9597C16.0002 28.766 16.3205 29.5394 16.8907 30.1095C17.4608 30.6797 18.2341 31 19.0405 31C19.5742 30.9991 20.0984 30.8581 20.5606 30.5911L29.1481 25.6662C29.6095 25.3991 29.9926 25.0154 30.2589 24.5536C30.5253 24.0917 30.6655 23.5679 30.6655 23.0348C30.6655 22.5017 30.5253 21.9779 30.2589 21.5161C29.9926 21.0542 29.6095 20.6705 29.1481 20.4035V20.3323ZM28.2591 24.0571L19.6716 29.0532C19.4792 29.1622 19.2617 29.2196 19.0405 29.2196C18.8192 29.2196 18.6018 29.1622 18.4093 29.0532C18.2174 28.9423 18.058 28.7829 17.9472 28.591C17.8364 28.3991 17.7781 28.1813 17.7781 27.9597V18.0388C17.7781 17.8172 17.8364 17.5994 17.9472 17.4075C18.058 17.2156 18.2174 17.0562 18.4093 16.9454C18.6026 16.8379 18.8194 16.7799 19.0405 16.7764C19.2614 16.781 19.478 16.8389 19.6716 16.9454L28.2591 21.9058C28.4511 22.0166 28.6106 22.1759 28.7214 22.3679C28.8323 22.5598 28.8907 22.7776 28.8907 22.9993C28.8907 23.2209 28.8323 23.4387 28.7214 23.6306C28.6106 23.8226 28.4511 23.9819 28.2591 24.0927V24.0571Z"
        fill="#D1F310"
      />
      <circle cx="23" cy="23" r="22.5" stroke="#D1F310" />
      <defs>
        <filter
          id="filter0_b_15:27"
          x="-17"
          y="-17"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_15:27"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_15:27"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default VectorButton;
