import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useBoolean,
} from "@chakra-ui/react";
import { PromoCode } from "app/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEllipsisH } from "react-icons/fa";
import "./styles.scss";
import { ACTION_TYPE } from "./actionType";
import BackgroundDrop from "../BackgroundDrop";

interface IPopoverActionProps {
  title: string;
  item: PromoCode;
  onActionClick: (type: string, item: PromoCode) => void;
}

export default function PopoverAction({
  title,
  item,
  onActionClick = () => {},
}: IPopoverActionProps) {
  const { t } = useTranslation();
  const [showPopove, setShowPopove] = useBoolean();

  return (
    <Box display="flex">
      {showPopove && <BackgroundDrop />}
      <Popover
        placement="top"
        isOpen={showPopove}
        closeOnBlur={true}
        onOpen={setShowPopove.on}
        onClose={setShowPopove.off}
      >
        <PopoverTrigger>
          <Box
            cursor="pointer"
            padding="6px 8px !important"
            _hover={{
              background: "#24252A",
              padding: "6px 8px",
              border: "1px solid #38383D",
              borderRadius: "8px",
            }}
            borderColor="#24252A"
            borderWidth="1px"
          >
            <FaEllipsisH />
          </Box>
        </PopoverTrigger>
        <Box zIndex={1200000}>
          <PopoverContent
            backgroundColor="#25252A"
            border="none"
            w="100%"
            borderColor="#24252A"
          >
            <PopoverArrow
              backgroundColor="#25252A "
              borderColor="#25252A "
              boxShadow="unset !important"
            />
            <PopoverHeader
              fontSize="14px"
              textAlign={"center"}
              justifyContent="center"
              borderBottom="none"
              display="flex"
            >
              {title}
            </PopoverHeader>
            <PopoverBody>
              <Flex flexDirection="column">
                <Button
                  _hover={{
                    background: "#2D2E33",
                    border: "1px solid #37383C",
                  }}
                  _active={{
                    background: "#2D2E33",
                  }}
                  borderColor="#37383C"
                  color="white"
                  bg="#25252A"
                  m=" 3px 5px "
                  fontSize="14px"
                  fontWeight="500"
                  borderRadius="4px"
                  onClick={() => onActionClick(ACTION_TYPE.EDIT, item)}
                >
                  {t("Edit")}
                </Button>
                <Button
                  _hover={{
                    background: "#2C262A",
                    border: "1px solid #ED363680",
                  }}
                  _active={{ background: "#2C262A" }}
                  color="red"
                  borderColor="#ED363680"
                  bg="#25252A"
                  m=" 3px 5px "
                  fontSize="14px"
                  fontWeight="500"
                  borderRadius="4px"
                  onClick={() => onActionClick(ACTION_TYPE.DELETE, item)}
                >
                  {t("delete")}
                </Button>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Box>
      </Popover>
    </Box>
  );
}
