import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import downloadFromUrl from "utils/convertTracks";
import { useDispatch } from "react-redux";
import { createModalDownloadTrackAnalytics } from "app/redux/actions/tracks";

interface ModalShowFreeDownloadProps {
  showModal: boolean;
  setShowModal: () => void;
  linkTitleFreeDownload: {
    url: string;
    title: string;
    _id: string;
    isTrack?: boolean;
  };
}

export default function ModalShowFreeDownload({
  showModal,
  setShowModal,
  linkTitleFreeDownload,
}: ModalShowFreeDownloadProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDownloadTrackSoundPacks = useCallback(() => {
    downloadFromUrl(linkTitleFreeDownload?.url, linkTitleFreeDownload?.title);
    dispatch(
      createModalDownloadTrackAnalytics({
        _id: linkTitleFreeDownload._id,
        isTrack: linkTitleFreeDownload.isTrack,
      })
    );
    setShowModal();
  }, [
    dispatch,
    linkTitleFreeDownload._id,
    linkTitleFreeDownload.isTrack,
    linkTitleFreeDownload?.title,
    linkTitleFreeDownload?.url,
    setShowModal,
  ]);

  return (
    <Modal
      isOpen={showModal}
      onClose={setShowModal}
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "500px" }}
        m={{ base: "15px", xl: "0px" }}
      >
        <ModalHeader
          display="flex"
          justifyContent="center"
          fontSize={{ base: "20px", md: "24px" }}
          fontWeight="400"
          padding={{ base: "20px 20px", md: "30px", xl: "35px 70px" }}
          textAlign="center"
        >
          {t("freeDownloadUsagePolicy")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={2}
          top="10px"
          fontSize="24px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody
          padding={{
            base: "0px 20px 20px 20px",
            md: "0px 30px 30px 30px",
            xl: "0px 80px 40px 80px",
          }}
        >
          <Text
            opacity="0.8"
            mb="10px"
            textAlign="center"
            fontSize="12px"
            fontWeight="400"
          >
            {t("pleaseNoteFreeDownload")}
          </Text>
          <Text
            fontSize="12px"
            textAlign="center"
            fontWeight="400"
            color="#D4F70E"
            opacity="0.8"
          >
            {t("useTrackCommercially")}
          </Text>
        </ModalBody>

        <ModalFooter
          padding={{ base: "10px 60px 40px", xl: "10px 80px 40px" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="15px"
        >
          <Button
            w="50%"
            background="rgba(255, 255, 255, 0.04)"
            borderRadius="4px"
            fontSize="14px"
            fontWeight="500"
            padding="25px 0px"
            onClick={setShowModal}
            _hover={{
              background: "rgba(255, 255, 255, 0.04)",
            }}
          >
            {t("disagree")}
          </Button>

          <Button
            fontSize="14px"
            fontWeight="500"
            w="50%"
            borderRadius="4px"
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            padding="25px 0px"
            onClick={handleDownloadTrackSoundPacks}
          >
            {t("agree")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
