import { Box, Text } from "@chakra-ui/react";
import { User } from "app/models";
import React from "react";
import { useTranslation } from "react-i18next";

interface RenderDescriptionUserProps {
  setShowingMore: (value: boolean) => void;
  userById: User;
  showingMore: boolean;
}

export default function RenderDescriptionUser({
  setShowingMore,
  userById,
  showingMore,
}: RenderDescriptionUserProps) {
  const { t } = useTranslation();

  if (!userById?.aboutMe) {
    return (
      <Text
        color="rgba(241, 242, 243, 0.3)"
        fontSize="14px"
        pt={{ base: "0px", md: "24px", xl: "32px" }}
        textAlign={{ base: "start", md: "center" }}
        lineHeight="20px"
        className="fontCeraPro"
      >
        {t("NoDescriptionYet")}
      </Text>
    );
  }

  return (
    <Box
      pt={{ base: "0px", md: "24px", xl: "32px" }}
      maxW={{ base: "200px", md: "313px" }}
      margin="0px 20px"
    >
      <Text
        textAlign={{ base: "start", md: "center" }}
        color="rgba(241, 242, 243, 0.3)"
        fontSize="14px"
        className="fontCeraPro"
        lineHeight="20px"
        m={{ md: "auto" }}
        cursor="pointer"
        noOfLines={showingMore ? 10 : 1}
      >
        {showingMore ? userById?.aboutMe : userById?.aboutMe?.slice(0, 48)}
      </Text>
      {showingMore ? (
        <Box onClick={() => setShowingMore(false)} cursor="pointer">
          <Text
            fontSize="14px"
            lineHeight="20px"
            className="fontCeraPro"
            textAlign={{ base: "start", md: "center" }}
          >
            {t("showLess")}
          </Text>
        </Box>
      ) : (
        <Box onClick={() => setShowingMore(true)} cursor="pointer">
          <Text
            textAlign={{ base: "start", md: "center" }}
            fontSize="14px"
            lineHeight="20px"
            className="fontCeraPro"
          >
            {t("showMore")}
          </Text>
        </Box>
      )}
    </Box>
  );
}
