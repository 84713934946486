import React from "react";

const IconAddTrackHeader = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <path
        d="M11.5 20.35C6.33613 20.35 2.15 16.1639 2.15 11C2.15 5.83613 6.33613 1.65 11.5 1.65C16.6639 1.65 20.85 5.83613 20.85 11C20.85 11.3589 21.1411 11.65 21.5 11.65C21.8589 11.65 22.15 11.3589 22.15 11C22.15 5.11817 17.3818 0.35 11.5 0.35C5.61817 0.35 0.85 5.11817 0.85 11C0.85 16.8818 5.61817 21.65 11.5 21.65C11.8589 21.65 12.15 21.3589 12.15 21C12.15 20.6411 11.8589 20.35 11.5 20.35Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
      <path
        d="M12.15 6.71298C13.689 7.01553 14.85 8.3723 14.85 10C14.85 10.3589 15.1411 10.65 15.5 10.65C15.8589 10.65 16.15 10.3589 16.15 10C16.15 7.43188 14.0681 5.35 11.5 5.35C11.1411 5.35 10.85 5.64101 10.85 6V10.7192C10.4546 10.4847 9.99286 10.35 9.5 10.35C8.03645 10.35 6.85 11.5365 6.85 13C6.85 14.4635 8.03645 15.65 9.5 15.65C10.9635 15.65 12.15 14.4635 12.15 13V6.71298ZM9.5 11.65C10.2456 11.65 10.85 12.2544 10.85 13C10.85 13.7456 10.2456 14.35 9.5 14.35C8.75441 14.35 8.15 13.7456 8.15 13C8.15 12.2544 8.75441 11.65 9.5 11.65Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
      <path
        d="M16.4859 17.6818L17.35 16.7096V21C17.35 21.3589 17.6411 21.65 18 21.65C18.359 21.65 18.65 21.3589 18.65 21V16.7096L19.5142 17.6819L19.5142 17.6819C19.7528 17.9502 20.1636 17.9744 20.4319 17.7358C20.7002 17.4973 20.7244 17.0865 20.4858 16.8181C20.4858 16.8181 20.4858 16.8181 20.4858 16.8181L18.4858 14.5681L18.4858 14.5681C18.3625 14.4294 18.1857 14.35 18 14.35C17.8144 14.35 17.6376 14.4294 17.5142 14.5681L17.5142 14.5681L15.5142 16.8181C15.5142 16.8181 15.5142 16.8181 15.5142 16.8181C15.2757 17.0865 15.2999 17.4973 15.5682 17.7358C15.8365 17.9743 16.2474 17.9502 16.4858 17.6819C16.4858 17.6818 16.4859 17.6818 16.4859 17.6818Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.3"
      />
    </svg>
  );
};
export default IconAddTrackHeader;
