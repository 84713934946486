import { createReducer } from "@reduxjs/toolkit";
import {
  Collaborator,
  Licenses,
  LyricsModel,
  Mood,
  User,
  UserInCollaborators,
} from "app/models";
import * as actions from "app/redux/actions/tracks";
import { TRACK_LICENSE } from "constants/contractType";
import { releaseFormatDate } from "utils/timeAgo";

export interface NewTrackState {
  _id?: string;
  coverUrl: string;
  title: string;
  isDraft?: boolean;
  type: string;
  contractType?: string;
  bpm: undefined | string;
  key: string;
  mainGenre: string;
  subGenre: string;
  primaryMood?: string;
  secondaryMood?: string;
  tags?: string[];
  description: string;
  lyrics?: string;
  lyricTitle?: string;
  releaseDate?: undefined | string;
  slug?: undefined | string;
  licenses: Licenses[] | string[];
  private?: boolean;
  excludeFromBulkDiscounts?: boolean;
  notForSale?: boolean;
  collaborators: Collaborator[];
  isEdit?: boolean;
  freeDownloadEnabled?: string;
  loopAndSamplesType?: any;
  mergeAudio?: string;
  untaggedFileUrl?: string;
  untaggedFileUrlWav?: string;
  trackStemsUrl?: string;
  taggedFileUrl?: string;
  idLyrics?: string;
  typeLoopSample: string;
  data: object[];
  timeTagDuration?: string;
  timeUntagDuration?: string;
  requireDownloadSp?: boolean;
  playUntaggedFile?: string;
  requireEmailCapture?: boolean;
}

export interface InitTrackState {
  moods: Mood[];
  validSections: string[];
  _id: undefined | string;
  newTrack: NewTrackState;
  lyricsTrack: LyricsModel;
  collaborators: Collaborator[];
  listCollaborators: UserInCollaborators[];
  isUploadTrack: boolean;
  trackHadLyrics: LyricsModel[];
  filesAutoTagged: any[];
  filesCustomTagged: any[];
  filesTrackoutStems: any[];
}

const initialState: InitTrackState = {
  moods: [],
  validSections: [],
  _id: undefined,
  newTrack: {
    title: "",
    coverUrl: "",
    isDraft: false,
    type: "",
    contractType: "",
    bpm: "",
    key: "",
    mainGenre: "",
    subGenre: "",
    primaryMood: "",
    secondaryMood: "",
    tags: [],
    description: "",
    lyrics: "",
    lyricTitle: "",
    slug: "",
    // NOTE: update me in pull request  dayjs
    releaseDate: "",
    licenses: [],
    excludeFromBulkDiscounts: false,
    private: false,
    notForSale: false,
    freeDownloadEnabled: "",
    loopAndSamplesType: [],
    mergeAudio: "",
    untaggedFileUrl: "",
    untaggedFileUrlWav: "",
    taggedFileUrl: "",
    trackStemsUrl: "",
    collaborators: [],
    typeLoopSample: "false",
    data: [],
    requireDownloadSp: false,
    requireEmailCapture: false,
  },
  lyricsTrack: {
    lyricTitle: "",
    lyrics: "",
  },
  collaborators: [],
  listCollaborators: [],
  isUploadTrack: true,
  trackHadLyrics: [],
  filesAutoTagged: [],
  filesCustomTagged: [],
  filesTrackoutStems: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.submitTrack.fulfilled, (state) => ({
      ...state,
      newTrack: {
        ...initialState.newTrack,
        collaborators: state.newTrack.collaborators,
      },
      moods: state.moods,
    }))
    .addCase(actions.clearForm, (state) => ({
      ...state,
      newTrack: initialState.newTrack,
    }))
    .addCase(actions.fetchMoods.fulfilled, (state, action) => ({
      ...state,
      moods: action.payload,
    }))
    .addCase(actions.prepareTrackCollaborator.fulfilled, (state, action) => {
      const currentCollaborator: Collaborator = action.payload;
      return {
        ...state,
        collaborators: [currentCollaborator],
      };
    })

    .addCase(actions.getLyrics.fulfilled, (state, action) => {
      return {
        ...state,
        lyricsTrack: action.payload,
        newTrack: {
          ...state.newTrack,
          lyrics: action.payload.lyrics,
          lyricTitle: action.payload.lyricTitle,
        },
      };
    })

    .addCase(actions.createLyrics.fulfilled, (state, action) => {
      return {
        ...state,
        lyricsTrack: action.payload,
      };
    })

    .addCase(actions.updateLyrics.fulfilled, (state, action) => {
      return {
        ...state,
        lyricsTrack: action.payload,
      };
    })

    .addCase(actions.prepareEditTrack, (state, action) => {
      const {
        title,
        licenses,
        releaseDate,
        stringKey,
        coverUrl,
        isDraft,
        type,
        bpm,
        mainGenre,
        primaryMood,
        secondaryMood,
        tags,
        lyrics,
        lyricTitle,
        slug,
        excludeFromBulkDiscounts,
        private: trackPrivate,
        notForSale,
        collaborators,
        subGenre,
        description,
        freeDownloadEnabled,
        loopAndSamplesType,
        _id,
        mergeAudio,
        untaggedFileUrl,
        untaggedFileUrlWav,
        trackStemsUrl,
        taggedFileUrl,
        data,
        typeLoopSample,
        requireDownloadSp,
        requireEmailCapture,
      } = action.payload;
      return {
        ...state,
        newTrack: {
          contractType: licenses?.[0]?.exclusive
            ? TRACK_LICENSE.EXCLUSIVE
            : TRACK_LICENSE.NON_EXCLUSIVE,
          releaseDate: releaseFormatDate(releaseDate),
          title,
          key: stringKey,
          coverUrl,
          isDraft,
          type,
          bpm,
          mainGenre,
          subGenre,
          primaryMood,
          secondaryMood,
          tags,
          description,
          lyrics,
          lyricTitle,
          slug,
          licenses,
          excludeFromBulkDiscounts,
          private: trackPrivate,
          notForSale,
          collaborators: (collaborators || []).map(
            (collaborator: Collaborator) => {
              return {
                name: (collaborator?.user as User)?.name,
                profitShare: collaborator?.profitShare,
                publishing: collaborator?.publishing,
                avatar: (collaborator?.user as User)?.profileImage,
                user: (collaborator?.user as User)?._id,
                role: collaborator?.role,
              };
            }
          ),

          freeDownloadEnabled,
          _id,
          loopAndSamplesType,
          mergeAudio,
          untaggedFileUrl,
          untaggedFileUrlWav,
          taggedFileUrl,
          trackStemsUrl,
          data,
          typeLoopSample: typeLoopSample?.toString(),
          requireDownloadSp,
          requireEmailCapture,
        },
      };
    })

    .addCase(actions.prepareUploadTrackFile, (state, action) => {
      return {
        ...state,
        newTrack: {
          ...state.newTrack,
          trackStemsUrl: action.payload?.trackStemsUrl,
          untaggedFileUrl: action.payload?.untaggedFileUrl,
          untaggedFileUrlWav: action.payload?.untaggedFileUrlWav,
          taggedFileUrl: action.payload?.taggedFileUrl,
          title: action.payload?.title,
          timeTagDuration: action.payload?.timeTagDuration,
          timeUntagDuration: action.payload?.timeUntagDuration,
          playUntaggedFile: action.payload?.playUntaggedFile,
        },
      };
    })

    .addCase(actions.fetchCollaborators.fulfilled, (state, action) => {
      return {
        ...state,
        listCollaborators: action.payload,
      };
    })
    .addCase(actions.checkUploadTrack.fulfilled, (state, action) => {
      return {
        ...state,
        isUploadTrack: action.payload,
      };
    })
    .addCase(actions.getAllTrackLyrics.fulfilled, (state, action) => {
      return {
        ...state,
        trackHadLyrics: action.payload,
      };
    })
    .addCase(actions.deleteLyric.fulfilled, (state, action) => {
      return {
        ...state,
        trackHadLyrics: action.payload,
      };
    })
    .addCase(actions.setFileAutoTagged, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesAutoTagged: updateProgress,
      };
    })
    .addCase(actions.setFileCustomTagged, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesCustomTagged: updateProgress,
      };
    })
    .addCase(actions.setFileTrackoutStems, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesTrackoutStems: updateProgress,
      };
    })
);
