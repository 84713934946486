import React from "react";

const Success = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M47.8111 8.19577C36.8926 -2.72281 19.121 -2.72281 8.20244 8.19577C2.91345 13.4868 0 20.52 0 28.0001C0 35.4801 2.91345 42.5133 8.20244 47.8023C13.6628 53.2626 20.8348 55.9917 28.0067 55.9917C35.1786 55.9917 42.3507 53.2626 47.811 47.8023C58.7296 36.8837 58.7296 19.1164 47.8111 8.19577ZM44.7436 44.7347C35.515 53.9633 20.4985 53.9633 11.2699 44.7347C6.801 40.2658 4.33873 34.3217 4.33873 28.0001C4.33873 21.6784 6.801 15.7343 11.2699 11.2632C20.4985 2.03461 35.515 2.0368 44.7436 11.2632C53.97 20.4918 53.97 35.5083 44.7436 44.7347Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.438 22.61C38.2057 23.4041 38.1842 24.6703 37.39 25.438L28.0797 34.438C27.3045 35.1873 26.0748 35.1873 25.2996 34.438L19.61 28.938C18.8158 28.1703 18.7943 26.9041 19.562 26.11C20.3297 25.3158 21.5959 25.2943 22.39 26.062L26.6897 30.2183L34.61 22.562C35.4041 21.7943 36.6703 21.8158 37.438 22.61Z"
        fill="black"
      />
    </svg>
  );
};
export default Success;
