import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import TextInput from "./TextInput";

export default function Description() {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const description = watch("description");

  return (
    <Box>
      <Text fontSize="20px" mb="28px" fontFamily="Cera Pro Medium">
        {t("description")}
      </Text>
      <TextInput
        type="textarea"
        name="description"
        placeholder={t("descriptionAddTrack")}
        label={t("description")}
        errors={errors}
        control={control}
      />
      <Text
        mt={{ base: "5px", md: "0px" }}
        color="#535353"
        fontFamily="Cera Pro Regular"
      >
        {description?.length}
        {t("maximumText")}
      </Text>
    </Box>
  );
}
