import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ModalTransactionProcessProps {
  isOpen: boolean;
  onClose?: () => void;
  iconJson: any;
  onButtonClick?: () => void;
  buttonLabel?: string;
}

export default function ModalTransactionProcess({
  isOpen,
  onClose,
  iconJson,
  onButtonClick,
  buttonLabel,
}: ModalTransactionProcessProps) {
  const { t } = useTranslation();

  return (
    <Modal colorScheme="black" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="0px" minW="500px" position="relative">
        <ModalBody p="0px">
          <Lottie animationData={iconJson} loop={false} />
        </ModalBody>
        {buttonLabel && (
          <ModalFooter
            position="absolute"
            bottom="20px"
            transform="translateX(-50%)"
            left="50%"
          >
            <Button
              onClick={onButtonClick}
              backgroundColor="rgba(255, 255, 255, 0.08)"
              color="white"
              fontSize="14px"
              borderRadius="4px"
              p="15px 30px"
              fontFamily="Cera Pro Regular"
              _hover={{ boxShadow: "none" }}
              marginBottom="20px"
            >
              {t(buttonLabel)}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}
