import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Image,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { customers } from "app/models";
import { roleText } from "constants/collaboratorType";
import { formatTimePlaylist } from "utils/timeAgo";

interface ItemCustomersProps {
  emailCustomers: customers[];
}

export default function ItemCustomers({ emailCustomers }: ItemCustomersProps) {
  const { t } = useTranslation();
  const columnHeader = [
    { label: t("type") },
    { label: t("Email") },
    { label: t("name") },
    { label: t("lastName") },
    { label: t("downloadedItem") },
    { label: t("downloadDate") },
  ];

  return (
    <Box mt="20px">
      <TableContainer>
        <Table fontFamily="Cera Pro Regular">
          <Thead>
            <Tr>
              {columnHeader.map((item, k) => (
                <Th
                  key={k}
                  fontSize="14px"
                  color="#ffffff"
                  fontFamily="Cera Pro Regular"
                  textTransform="capitalize"
                  borderColor="transparent"
                  position={k === 0 ? "sticky" : "static"}
                  left={k === 0 ? "0px" : ""}
                  zIndex={k === 0 ? "1" : ""}
                  backdropFilter={k === 0 ? "blur(20px)" : " "}
                  textAlign={k === 0 ? "start" : "center"}
                >
                  {item.label}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {(emailCustomers || [])?.map((item) => (
              <Tr
                key={item._id}
                _hover={{
                  background: "rgba(36, 37, 42, 0.8)",
                  borderRadius: "8px",
                  cursor: "pointer",
                }}
              >
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  position="sticky"
                  left="0px"
                  zIndex="2"
                  backdropFilter="blur(20px)"
                  fontSize="14px"
                >
                  {roleText(item?.type)}
                </Td>
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  fontSize="14px"
                  textAlign="center"
                >
                  {item.email}
                </Td>
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  fontSize="14px"
                  textAlign="center"
                >
                  {item.name}
                </Td>
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  fontSize="14px"
                  textAlign="center"
                >
                  {item.lastName}
                </Td>
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  fontSize="14px"
                >
                  <Flex alignItems="center">
                    <Image
                      src={
                        item?.trackId?.coverUrl || item?.soundPackId?.coverUrl
                      }
                      fallbackSrc={ImageDefault}
                      borderRadius="4px"
                      objectFit="cover"
                      w="36px"
                      height="36px"
                    />
                    <Text
                      ml="10px"
                      isTruncated
                      maxW="200px"
                      color={
                        item?.trackId?.title || item?.soundPackId?.title
                          ? "#fffff"
                          : "red"
                      }
                    >
                      {item?.trackId?.title ||
                        item?.soundPackId?.title ||
                        t("deleted")}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  paddingTop="25px"
                  paddingBottom="40px"
                  borderColor="rgba(36, 37, 42, 0.8)"
                  fontSize="14px"
                  textAlign="center"
                >
                  <Flex justifyContent="center">
                    <Text
                      background="#192631"
                      color="#1DA1F2"
                      w="max-content"
                      padding="2px 7px"
                      borderRadius="4px"
                    >
                      {t("downloadOn")} {formatTimePlaylist(item?.createdAt)}
                    </Text>
                  </Flex>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}
