import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import Music from "app/assets/icons/MusicHomePage";
import Playlist from "app/assets/icons/PlaylistHomePage";
import Repost from "app/assets/icons/RepostHomePage";
import Like from "app/assets/icons/LikeHomePage";
import Video from "app/assets/icons/VideoHomePage";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import getOptions from "utils/getOptions";
import ToastPlanSubscription from "app/components/toast/toastplan";

export const ACTIONS = {
  RELEASE_MUSIC: "RELEASE_MUSIC",
  CREATE_PLAYLIST: "CREATE_PLAYLIST",
  RELEASE_PLAYLIST: "RELEASE_PLAYLIST",
  RELEASE_VIDEO: "RELEASE_VIDEO",
  REPOST: "REPOST",
  FAVORITE: "FAVORITE",
  FOLLOW: "FOLLOW",
};

const getIcon = (action: string) => {
  switch (action) {
    case ACTIONS.RELEASE_MUSIC:
      return Music;
    case ACTIONS.RELEASE_PLAYLIST:
      return Playlist;
    case ACTIONS.REPOST:
      return Repost;
    case ACTIONS.FAVORITE:
      return Like;
    case ACTIONS.RELEASE_VIDEO:
      return Video;
    default:
      return null;
  }
};

const getActionType = (action, isSK) => {
  switch (action) {
    case ACTIONS.RELEASE_MUSIC:
      return isSK ? "Sound Kit" : "Track";
    case ACTIONS.RELEASE_PLAYLIST:
      return "Released a Playlist";
    case ACTIONS.REPOST:
      return isSK ? "Sound Kit" : "Track";
    case ACTIONS.FAVORITE:
      return "Favorites";
    case ACTIONS.RELEASE_VIDEO:
      return "Video";
    default:
      return null;
  }
};

class HomePageService {
  static async getHotProducers(isLoginGuest = false) {
    try {
      const { data } = await axiosService.get(
        isLoginGuest ? "/guest/hot-producers" : "/user/hot-producers"
      );
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getRecentActions({
    page = 1,
    filterType = "",
    userId = null,
    isLoginGuest = false,
  }) {
    try {
      const options = { ...getOptions(page, filterType), userId };
      const res = await axiosService.get(
        isLoginGuest ? "/guest/recent-actions" : "/recent-actions",
        {
          params: { ...options, userId },
        }
      );
      const {
        data: { data },
      } = res;

      const items = data.map((d) => {
        if (d.track) {
          return {
            ...d,
            track: {
              ...d.track,
              key: TRACK_KEY_NOTATION[d.track.key],
              stringKey: d.key,
            },
            action: d.action,
            actionType: getActionType(d.action, false),
            icon: getIcon(d.action),
          };
        } else if (d.playlist) {
          return {
            ...d,
            playlist: {
              ...d.playlist,
            },
            action: d.action,
            actionType: getActionType(d.action, true),
            icon: getIcon(d.action),
          };
        } else {
          return {
            ...d,
            soundkit: {
              ...d.soundkit,
              key: TRACK_KEY_NOTATION[d.soundkit.key],
              stringKey: d.key,
            },
            action: d.action,
            actionType: getActionType(d.action, true),
            icon: getIcon(d.action),
          };
        }
      });

      return {
        items,
        hasMoreItems: items.length === 25,
      };
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getNewArtists() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get("/new-talents/artists", {
        params: { ...options },
      });

      const items = data.data.map((item) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });

      return items;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getNewProducers() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get("/new-talents/producers", {
        params: { ...options },
      });

      const items = data.data.map((item) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });

      return items;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getRecommendedArtists() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get("/user?recommended=true", {
        params: options,
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getRecommendedMusic() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get(
        "/tracks/all-tracks?recommended=true",
        {
          params: options,
        }
      );

      const items = data.data.map((item) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });

      return items;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getFreeBeats() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get(
        "/tracks/all-tracks?freeTrack=true",
        {
          params: options,
        }
      );

      const items = data.data.map((item) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });

      return items;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getBulkOffers() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get("/tracks/bulk-offers", {
        params: options,
      });

      const items = data.data.map((item) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });

      return items;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
}
export default HomePageService;
