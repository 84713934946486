import Heart from "app/assets/icons/heart";
import MusicSvg from "app/assets/icons/musicSvg";
import Playlist from "app/assets/icons/playlist";
import Video from "app/assets/icons/video";
import Repost from "app/assets/icons/report";

export const JWT_LOCALSTORAGE = "jwt";
export const IS_REMEMBER_ME = "isRememberMe";
export const AUTHORIZATION = "Authorization";
export const ROW_PER_PAGE_OPTIONS = [10, 25, 50];
export const ITEMS_PER_PAGE = 25;
export const TRACK = "track";
export const ARTIST = "artist";
export const MP4_FILE = "mp4";
export const MP3_FILE = "mp3";
export const WAV_FILE = "wav";
export const VIDEO_TYPE = "video/mp4";
export const IMAGE_TYPE = "image";
export const AUDIO_TYPE = "audio/mpeg";
export const WAV_TYPE = "audio/wav";
export const MP3_320KBPS_FILE = "MP3 320KBPS";
export const MP4 = "MP4";
export const AUDIO = "audio";
export const VIDEO = "video";
export const SIZE_FILE_BY_SLOT = 250;
export const SIZE_FILE_TO_MB = 1000000;
export const VALUE_SHOW_LIKES = "Show Likes";
export const VALUE_SHOW_PURCHASES = "Show Purchases";
export const ACCESS_TOKEN_SOCIAL = "access_token=";
export const ID_TOKEN_SOCIAL = "id_token=";
export const PHONE_REGEX =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const MENTION_USER = "mentionUser:";
export const AUDIO_SUPERPRODUCTOR =
  "https://s3.amazonaws.com/superproductor.dev-uploads/uploads/Superproductor%20Official%20Tag%2024bit.wav";
export const MINIMUM_AUDIO_BITRATE = 128;
export const MINIMUM_AUDIO_MEGABYTE = 164;
export const MINIMUM_AUDIO_GIGABYTE = 2;

export const URLTRACKSOUNDKIT = {
  track: "https://superproductor.com/track/",
  soundkit: "https://superproductor.com/soundkit/",
};

export const CURRENCY = {
  USD: "usd",
};
export const DEVICE_TYPE = {
  WEB: "WEB",
};
export const PAYMENT_TYPE = {
  PAYPAL: "paypal",
  STRIPE: "stripe",
};
export const ALLOW_BROAD_CASTING = {
  ALLOW: "ALLOW",
  NOT_ALLOW: "NOT_ALLOW",
};
export const LICENSE_RENEWAL = {
  ONE_YEAR: "1_YEAR",
  TWO_YEAR: "2_YEAR",
};
export const EXCLUSIVE_LICENSE = {
  NON_EXCLUSIVE: "NON_EXCLUSIVE",
  EXCLUSIVE: "EXCLUSIVE",
};
export const ACTIONS = {
  RELEASE_MUSIC: "RELEASE_MUSIC",
  CREATE_PLAYLIST: "CREATE_PLAYLIST",
  RELEASE_VIDEO: "RELEASE_VIDEO",
  REPOST: "REPOST",
  FAVORITE: "FAVORITE",
  FOLLOW: "FOLLOW",
  TRACK: "TRACK",
  PLAYLIST: "PLAYLIST",
  SOUNDKIT: "SOUNDKIT",
  RELEASE_PLAYLIST: "RELEASE_PLAYLIST",
};

export const TRACK_STATE = {
  EXCLUSIVE_PURCHASED: "EXCLUSIVE_PURCHASED",
  PENDING: "PENDING",
};

export const recentActions = {
  title: "Recent actions",
  releasedA: "Released a",
  releaseA: "Released a",
  createPlaylist: "created a playlist",
  addToFavorites: "add to favorites",
  favorites: "Favorites",
  track: "Track",
  soundKit: "Sound Kit",
  followed: "followed",
  reposted: "Reposted a",
  repost: "Repost a",
  releasedVideo: "uploaded a new video",
  releaseVideo: "upload new videos",
  filter: "Filter",
  filterBy: "Filter by",
  all: "All",
  tracks: "Tracks",
  albums: "Albums",
  videos: "Videos",
  playlists: "Playlists",
  addTo: "Add to",
  addedTo: "Added to",
  uploadNew: "Upload new",
  createA: "Create a",
  createdA: "Create a",
  playlist: "Playlist",
  video: "Video",
};

export enum LoopSampleType {
  NAME_LOOP = "nameLoopSample",
  WHERE_LOOP = "whereLoopSample",
}

export const getIcon = (action: any) => {
  switch (action) {
    case ACTIONS.RELEASE_MUSIC:
      return MusicSvg;
    case ACTIONS.CREATE_PLAYLIST:
      return Playlist;
    case ACTIONS.REPOST:
      return Repost;
    case ACTIONS.FAVORITE:
      return Heart;
    case ACTIONS.RELEASE_VIDEO:
      return Video;
    default:
      return null;
  }
};

const checkIsSk = (type: string) =>
  type === ACTIONS.SOUNDKIT ? recentActions.soundKit : recentActions.track;

export const getActionType = (action: string, type: string) => {
  switch (action) {
    case ACTIONS.RELEASE_MUSIC:
    case ACTIONS.REPOST:
      return checkIsSk(type);
    case ACTIONS.CREATE_PLAYLIST:
      return recentActions.createPlaylist;
    case ACTIONS.FAVORITE:
      return recentActions.favorites;
    case ACTIONS.RELEASE_VIDEO:
      return recentActions.video;
    default:
      return null;
  }
};
