import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import NegotiationService from "app/apis/negotiations";
import { CancleNegotiations, CreateNegotiations } from "app/models";

export const TYPES = {
  GET_NEGOTIATIONS: "GET_NEGOTIATIONS",
  GET_NEGOTIATION: "GET_NEGOTIATION",
  GET_NEGOTIATION_BY_ID: "GET_NEGOTIATION_BY_ID",
  ACCEPT_NEGOTIATIONS: "ACCEPT_NEGOTIATIONS",
  CANCEL_OR_REJECT_NEGOTIATIONS: "CANCEL_OR_REJECT_NEGOTIATIONS",
  COUNTEROFFER_NEGOTIATIONS: "COUNTEROFFER_NEGOTIATIONS",
  ACCEPT_COUNTEROFFER: "ACCEPT_COUNTEROFFER",
  SET_TAB: "SET_TAB",
  SELECTED_TRACK_NEGOTIATION: "SELECTED_TRACK_NEGOTIATION",
  CREATE_NEGOTIATION: "CREATE_NEGOTIATION",
};

export const getNegotiations = createAsyncThunk(
  TYPES.GET_NEGOTIATIONS,
  NegotiationService.getNegotiations
);

export const getNegotiation = createAsyncThunk(
  TYPES.GET_NEGOTIATION,
  NegotiationService.getNegotiation
);

export const selectTrackNegotiation = createAction(
  TYPES.SELECTED_TRACK_NEGOTIATION,
  (payload) => {
    return {
      payload,
    };
  }
);

export const createNegotiation = createAsyncThunk(
  TYPES.CREATE_NEGOTIATION,
  async (data: CreateNegotiations, thunkAPI) => {
    const newNegotiation = await NegotiationService.createNegotiation(data);
    thunkAPI.dispatch(getNegotiations({ isActive: true }));
    return newNegotiation;
  }
);

export const cancelOrRejectNegotiation = createAsyncThunk(
  TYPES.CANCEL_OR_REJECT_NEGOTIATIONS,
  async (body: CancleNegotiations) => {
    const negotiation = await NegotiationService.cancelOrRejectNegotiation(
      body
    );
    const negotiations = await NegotiationService.getNegotiations({
      isActive: false,
    });
    return { negotiation, negotiations };
  }
);

export const counterofferNegotiation = createAsyncThunk(
  TYPES.COUNTEROFFER_NEGOTIATIONS,
  async (body: CancleNegotiations) => {
    const negotiation = await NegotiationService.counterOfferNegotiation(body);
    const negotiations = await NegotiationService.getNegotiations({
      isActive: true,
    });
    return { negotiation, negotiations };
  }
);

export const acceptNegotiation = createAsyncThunk(
  TYPES.ACCEPT_NEGOTIATIONS,
  async (restBody: CancleNegotiations) => {
    const negotiation = await NegotiationService.acceptNegotiation(restBody);
    const negotiations = await NegotiationService.getNegotiations({
      isActive: true,
    });
    return { negotiation, negotiations };
  }
);

export const acceptCounteroffer = createAsyncThunk(
  TYPES.ACCEPT_COUNTEROFFER,
  async (body: CancleNegotiations) => {
    const negotiation = await NegotiationService.acceptCounterOffer(body);
    const negotiations = await NegotiationService.getNegotiations({
      isActive: true,
    });
    return { negotiation, negotiations };
  }
);
