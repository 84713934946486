import React from "react";

const EmptyCover = () => {
  return (
    <svg
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.1252 16.6265V14.2515C64.1197 12.3635 63.3673 10.5544 62.0323 9.21939C60.6973 7.88438 58.8882 7.13195 57.0002 7.12646H9.50024C7.61226 7.13195 5.80317 7.88438 4.46816 9.21939C3.13315 10.5544 2.38073 12.3635 2.37524 14.2515V52.2515C2.38073 54.1394 3.13315 55.9485 4.46816 57.2835C5.80317 58.6185 7.61226 59.371 9.50024 59.3765H11.8752"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M66.7985 18.998H21.0768C17.3065 18.998 14.2501 22.0544 14.2501 25.8247V62.0464C14.2501 65.8166 17.3065 68.873 21.0768 68.873H66.7985C70.5687 68.873 73.6251 65.8166 73.6251 62.0464V25.8247C73.6251 22.0544 70.5687 18.998 66.7985 18.998Z"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M55.3555 37.1379C57.878 37.1379 59.9229 35.1076 59.9229 32.6031C59.9229 30.0986 57.878 28.0684 55.3555 28.0684C52.833 28.0684 50.7881 30.0986 50.7881 32.6031C50.7881 35.1076 52.833 37.1379 55.3555 37.1379Z"
        stroke="#D4F70E"
        strokeWidth="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M50.788 55.2425L37.8517 42.419C37.0247 41.6019 35.9186 41.129 34.7565 41.0956C33.5945 41.0623 32.463 41.471 31.5906 42.2393L14.2501 57.5388"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.37 68.8741L56.9732 51.3985C57.7832 50.5996 58.8605 50.1292 59.997 50.0779C61.1335 50.0267 62.2488 50.3982 63.1274 51.1209L73.6249 59.806"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EmptyCover;
