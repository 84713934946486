import { Box, ChakraProvider, extendTheme } from "@chakra-ui/react";
import FooterPlayer from "app/components/FooterPlayer";
import { fetchRecentActionsHomePage } from "app/redux/actions/home";
import { getUserInfo } from "app/redux/actions/users";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectLoggedIn } from "app/redux/selectors/users";
import "app/translations/i18n";
import { usePlayers } from "hooks/player/usePlayers";
import {
  useWareformContext,
  WareformContext,
} from "hooks/player/wareformContext";
import * as React from "react";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RenderRoutes } from "routes/RenderRoutes";
import "styles/main.scss";
import { RootState } from "types";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import useTrackSelect from "hooks/track/useTrackSelect";
import ModalAddToPlaylist from "app/pages/Playlists/modalAddToPlaylist";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalCreatePlaylist from "app/pages/Playlists/modalCreatePlaylist";
import DeviceDetector from "device-detector-js";
import { getSessionStorage, removeSessionStorage } from "helpers/session";
import {
  addListCartFromGuest,
  addLisenceFromGuest,
} from "app/redux/actions/cart";
import { checkHadData } from "utils/errorMessage";
import ModalPopoverProfile from "app/components/MusicCard/modalPopoverProfile";
import ModalShareLinkRecentActions from "app/pages/Home/modalShareLinkRecentActions";
import ModalLyrics from "app/components/ModalShowLyrics";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";
import { useMyTrack } from "hooks/track/useMyTrack";
import ToastResumeUploading from "app/components/toast/toastResumeUploading";
import {
  setFileAutoTaggedClosePage,
  setFileCustomTaggedClosePage,
  setFileTrackoutStemsClosePage,
} from "app/redux/actions/tracks";

const theme = extendTheme({
  colors: {
    raisinBlack: {
      500: "#212125", // you need this
    },
    Chartreuse: {
      500: "#D8FD02", // you need this
    },
  },
  breakpoints: {
    base: "0px",
    sm: "480px",
    md: "768px ",
    lg: "992px",
    xl: "1280px",
  },
});

export function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector(selectLoggedIn);
  const location: any = useLocation();
  const { isAudioPlay, playingTrack } = usePlayers();
  const { wavesurfer } = useWareformContext();
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchRecentActionsHomePage.typePrefix], state)
  );
  const history = useHistory();

  const {
    selectedTrack,
    showOptionsModal,
    setShowOptionsModal,
    onOpenOptionsModal,
    handleFreeDownload,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    handleSearchText,
    debouncedSearchText,
    trackPrivate,
  } = usePlaylists();

  const { autoTaggedInProgress } = useMyTrack();

  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const checkHomePage = useMemo(() => {
    return location.pathname === "/" || location.pathname === "/home";
  }, [location.pathname]);

  const [showModalProfile, setShowModalProfile] = useState(false);

  const handleContinueUpload = useCallback(() => {
    history.push("/upload-track-files");
  }, [history]);

  const handleStartNewUpload = useCallback(() => {
    dispatch(setFileAutoTaggedClosePage([]));
    dispatch(setFileCustomTaggedClosePage([]));
    dispatch(setFileTrackoutStemsClosePage([]));
    history.push("/upload-track-files");
  }, [dispatch, history]);

  useEffect(() => {
    if (!location.pathname.startsWith("/auth") && loggedIn) {
      dispatch(getUserInfo());
    }
    window.scrollTo({ top: 0 });
  }, [dispatch, location.pathname, loggedIn]);

  useEffect(() => {
    if (
      autoTaggedInProgress?.length > 0 &&
      !location.pathname.startsWith("/auth") &&
      loggedIn
    ) {
      ToastResumeUploading({
        title: "Resume Uploading: Your Tracks Await",
        description:
          "Looks like you have some unfinished uploads. Want to pick up where you left off?",
        cancelButtonText: "Start Fresh",
        submitButtonText: "Add More Tracks",
        onClickCancelButton: handleStartNewUpload,
        onClickButton: handleContinueUpload,
      });
    }
  }, [
    autoTaggedInProgress?.length,
    handleContinueUpload,
    handleStartNewUpload,
    location.pathname,
    loggedIn,
  ]);

  const checkLocalStorage = localStorage.getItem("checkLocal");
  const cartGuest = getSessionStorage("cart");
  const licenseGuest = getSessionStorage("licenses");
  const routerGuest = getSessionStorage("router");
  const checkIsSK = useMemo(() => {
    return playingTrack?.soundkitState;
  }, [playingTrack?.soundkitState]);

  useEffect(() => {
    if (cartGuest?.length > 0) {
      dispatch(addListCartFromGuest(cartGuest));
      loggedIn && removeSessionStorage("cart");
    }
    if (licenseGuest?.length > 0) {
      dispatch(addLisenceFromGuest(licenseGuest));
      loggedIn && removeSessionStorage("licenses");
    }
  }, [cartGuest, dispatch, licenseGuest, loggedIn]);
  useEffect(() => {
    if (
      !location.pathname.startsWith("/auth") &&
      !checkHadData(routerGuest) &&
      loggedIn
    ) {
      history.push(`/${routerGuest}`);
      removeSessionStorage("router");
    }
  }, [history, location.pathname, loggedIn, routerGuest]);

  useEffect(() => {
    if (!checkLocalStorage) {
      localStorage.clear();
      localStorage.setItem("checkLocal", "true");
    }
  }, [checkLocalStorage]);

  return (
    <ChakraProvider theme={theme}>
      <WareformContext.Provider value={wavesurfer}>
        <Box
          className="zoom-page"
          paddingBottom={isAudioPlay ? "120px" : "0px"}
        >
          {isLoading && checkHomePage && (
            <div
              className={`progress-6 ${
                device.client?.name === "Safari" ? "loading-top" : ""
              }`}
            ></div>
          )}
          <RenderRoutes />
        </Box>
        <ToastContainer autoClose={3000} />
        {isAudioPlay && (
          <FooterPlayer
            onOpenOptionsModal={onOpenOptionsModal}
            handleShowModalAddTrack={handleShowModalAddTrack}
            setShowModalProfile={setShowModalProfile}
          />
        )}

        {showOptionsModal && (
          <ModalPriceTracks
            selectedTrack={selectedTrack}
            showModal={showOptionsModal}
            setShowModal={setShowOptionsModal}
            handleFreeDownload={handleFreeDownload}
            handleShowModalFreeDownloadMailchimp={
              handleShowModalFreeDownloadMailchimp
            }
          />
        )}
        {showModalAddToPlaylist && (
          <ModalAddToPlaylist
            trackId={trackId}
            setShowModal={setShowModalAddToPlaylist}
            showModal={showModalAddToPlaylist}
            setShowModalCreatePlaylist={setShowModalCreatePlaylist}
            handleSearchText={handleSearchText}
            debouncedSearchText={debouncedSearchText}
            privateTrack={trackPrivate}
          />
        )}
        {showModalCreatePlaylist && (
          <ModalCreatePlaylist
            showModal={showModalCreatePlaylist}
            setShowModal={setShowModalCreatePlaylist}
          />
        )}
        {showModalProfile && (
          <ModalPopoverProfile
            showModal={showModalProfile}
            setShowModal={setShowModalProfile}
            track={playingTrack}
            isSK={!!checkIsSK}
            onOpenOptionsModal={onOpenOptionsModal}
            onOpenModalShareLink={showModalAndGetdataShareLink}
            setLoadingUrl={setLoadingUrl}
            setShortLinkUrl={setShortLink}
            handleShowModalAddTrack={handleShowModalAddTrack}
          />
        )}
        {showModalShareLink && (
          <ModalShareLinkRecentActions
            showModalShareLink={showModalShareLink}
            setShowModalShareLink={setShowModalShareLink}
            selectedTrackSoundkit={selectedTrack}
            shortLinkUrl={shortLink}
            loadingUrl={loadingUrl}
          />
        )}
        {showLyrics && (
          <ModalLyrics
            showModal={showLyrics}
            setShowModal={setShowLyrics}
            selectedTrack={selectedTrack}
            onOpenOptionsModal={onOpenOptionsModal}
            isEditLyrics={isEditLyrics}
            setIsEditLyrics={setIsEditLyrics}
            lyricsData={lyricsData}
          />
        )}
        {showModalFreeMailchimp && (
          <ModalShowFreeDownloadFormMailChimp
            showModal={showModalFreeMailchimp}
            setShowModal={setShowModalFreeMailchimp}
            selectedTrack={selectedTrack}
            handleFreeDownload={handleFreeDownload}
          />
        )}
      </WareformContext.Provider>
    </ChakraProvider>
  );
}
