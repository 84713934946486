import { Divider } from "@chakra-ui/react";
import React from "react";

interface DividerProps {
  marginTop?: string;
}

export default function DividerComponent({ marginTop = "40px" }: DividerProps) {
  return (
    <Divider
      mt={marginTop}
      borderBottomWidth="none"
      borderColor="none"
      borderBottom="3px solid #24252A"
      mb={{ base: "20px", md: "40px" }}
    />
  );
}
