import { fetchMoods } from "app/redux/actions/tracks";
import { selectAllMoods } from "app/redux/selectors/tracks";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { alphabeticallyItems } from "constants/alphabeticallyItems";

export const useMood = () => {
  const dispatch = useDispatch();
  const moods = useSelector(selectAllMoods);

  const getMoods = useCallback(() => {
    dispatch(fetchMoods());
  }, [dispatch]);
  const convertMoods = alphabeticallyItems(moods);

  const getMoodsType = useCallback(
    (value: string) => convertMoods.filter((mood) => value !== mood.value),
    [convertMoods]
  );

  return {
    moods,
    getMoodsType,
    getMoods,
  };
};
