import React from "react";

const Earned = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_15:27)">
        <circle cx="23" cy="23" r="16" fill="white" fillOpacity="0.06" />
      </g>
      <circle cx="23" cy="23" r="22.5" stroke="#D1F310" />
      <path
        d="M25.4 22.2H24.6V19H26.2C26.4122 19 26.6157 19.0843 26.7657 19.2343C26.9157 19.3843 27 19.5878 27 19.8C27 20.0122 27.0843 20.2157 27.2343 20.3657C27.3843 20.5157 27.5878 20.6 27.8 20.6C28.0122 20.6 28.2157 20.5157 28.3657 20.3657C28.5157 20.2157 28.6 20.0122 28.6 19.8C28.6 19.1635 28.3471 18.553 27.8971 18.1029C27.447 17.6529 26.8365 17.4 26.2 17.4H24.6V15.8C24.6 15.5878 24.5157 15.3843 24.3657 15.2343C24.2157 15.0843 24.0122 15 23.8 15C23.5878 15 23.3843 15.0843 23.2343 15.2343C23.0843 15.3843 23 15.5878 23 15.8V17.4H22.2C21.3513 17.4 20.5374 17.7371 19.9373 18.3373C19.3371 18.9374 19 19.7513 19 20.6C19 21.4487 19.3371 22.2626 19.9373 22.8627C20.5374 23.4629 21.3513 23.8 22.2 23.8H23V27H21.4C21.1878 27 20.9843 26.9157 20.8343 26.7657C20.6843 26.6157 20.6 26.4122 20.6 26.2C20.6 25.9878 20.5157 25.7843 20.3657 25.6343C20.2157 25.4843 20.0122 25.4 19.8 25.4C19.5878 25.4 19.3843 25.4843 19.2343 25.6343C19.0843 25.7843 19 25.9878 19 26.2C19 26.8365 19.2529 27.447 19.7029 27.8971C20.153 28.3471 20.7635 28.6 21.4 28.6H23V30.2C23 30.4122 23.0843 30.6157 23.2343 30.7657C23.3843 30.9157 23.5878 31 23.8 31C24.0122 31 24.2157 30.9157 24.3657 30.7657C24.5157 30.6157 24.6 30.4122 24.6 30.2V28.6H25.4C26.2487 28.6 27.0626 28.2629 27.6627 27.6627C28.2629 27.0626 28.6 26.2487 28.6 25.4C28.6 24.5513 28.2629 23.7374 27.6627 23.1373C27.0626 22.5371 26.2487 22.2 25.4 22.2ZM23 22.2H22.2C21.7757 22.2 21.3687 22.0314 21.0686 21.7314C20.7686 21.4313 20.6 21.0243 20.6 20.6C20.6 20.1757 20.7686 19.7687 21.0686 19.4686C21.3687 19.1686 21.7757 19 22.2 19H23V22.2ZM25.4 27H24.6V23.8H25.4C25.8243 23.8 26.2313 23.9686 26.5314 24.2686C26.8314 24.5687 27 24.9757 27 25.4C27 25.8243 26.8314 26.2313 26.5314 26.5314C26.2313 26.8314 25.8243 27 25.4 27Z"
        fill="#D1F310"
      />
      <defs>
        <filter
          id="filter0_b_15:27"
          x="-17"
          y="-17"
          width="80"
          height="80"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_15:27"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_15:27"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default Earned;
