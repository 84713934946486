import React from "react";

const BackReturn = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="23.5" stroke="white" strokeOpacity="0.15" />
      <path
        d="M22.4675 23.9998L25.1225 21.3448C25.2622 21.2043 25.3406 21.0142 25.3406 20.816C25.3406 20.6179 25.2622 20.4278 25.1225 20.2873C25.0528 20.217 24.9698 20.1612 24.8784 20.1231C24.787 20.085 24.689 20.0654 24.59 20.0654C24.491 20.0654 24.393 20.085 24.3016 20.1231C24.2102 20.1612 24.1272 20.217 24.0575 20.2873L20.8775 23.4673C20.8072 23.537 20.7514 23.62 20.7133 23.7113C20.6752 23.8027 20.6556 23.9008 20.6556 23.9998C20.6556 24.0988 20.6752 24.1968 20.7133 24.2882C20.7514 24.3796 20.8072 24.4626 20.8775 24.5323L24.0575 27.7498C24.1276 27.8193 24.2107 27.8743 24.3021 27.9116C24.3934 27.9489 24.4913 27.9678 24.59 27.9673C24.6887 27.9678 24.7865 27.9489 24.8779 27.9116C24.9693 27.8743 25.0524 27.8193 25.1225 27.7498C25.2622 27.6093 25.3406 27.4192 25.3406 27.221C25.3406 27.0229 25.2622 26.8328 25.1225 26.6923L22.4675 23.9998Z"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default BackReturn;
