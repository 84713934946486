import React from "react";

const NoDiscount = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.24967 6.66658C7.93651 6.66658 7.63039 6.75944 7.37001 6.93342C7.10963 7.10739 6.90669 7.35468 6.78686 7.64399C6.66702 7.93331 6.63566 8.25167 6.69675 8.5588C6.75785 8.86594 6.90865 9.14806 7.13008 9.3695C7.35151 9.59093 7.63364 9.74173 7.94077 9.80282C8.24791 9.86391 8.56626 9.83256 8.85558 9.71272C9.1449 9.59288 9.39218 9.38994 9.56616 9.12956C9.74014 8.86918 9.833 8.56306 9.833 8.24991C9.833 7.82998 9.66618 7.42726 9.36925 7.13032C9.07232 6.83339 8.66959 6.66658 8.24967 6.66658ZM22.6263 11.6382L13.7491 2.75046C13.6505 2.65263 13.5335 2.57524 13.4049 2.52271C13.2763 2.47018 13.1386 2.44355 12.9997 2.44435H3.49967C3.21971 2.44435 2.95123 2.55556 2.75327 2.75352C2.55532 2.95147 2.44411 3.21996 2.44411 3.49991V12.9999C2.44331 13.1388 2.46993 13.2765 2.52246 13.4051C2.57499 13.5338 2.65239 13.6507 2.75022 13.7494L11.638 22.6266C12.2318 23.2196 13.0366 23.5527 13.8758 23.5527C14.7149 23.5527 15.5198 23.2196 16.1136 22.6266L22.6263 16.1666C23.2193 15.5728 23.5524 14.768 23.5524 13.9288C23.5524 13.0896 23.2193 12.2848 22.6263 11.691V11.6382ZM21.138 14.6149L14.6147 21.1277C14.4169 21.3243 14.1494 21.4346 13.8705 21.4346C13.5916 21.4346 13.3241 21.3243 13.1263 21.1277L4.55522 12.5671V4.55546H12.5669L21.138 13.1266C21.2358 13.2252 21.3132 13.3422 21.3658 13.4708C21.4183 13.5994 21.4449 13.7371 21.4441 13.876C21.4429 14.1529 21.333 14.4183 21.138 14.6149Z"
        fill="#373834"
      />
    </svg>
  );
};
export default NoDiscount;
