import { Box, SimpleGrid, Text, Flex } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import IconTotalReach from "app/assets/icons/IconTotalReach";
import { useReferrals } from "hooks/Referrals/useReferrals";
import IconTotalUsersReferred from "app/assets/icons/IconTotalUsersReferred";
import IconTotalSuccessfulReferrals from "app/assets/icons/IconTotalSuccessfulReferrals";
import IconTotalReferralsConverted from "app/assets/icons/IconTotalReferralsConverted";

export default function ReferralPerformance() {
  const { t } = useTranslation();
  const { generalReferrals } = useReferrals();
  const generalInfoReferrals = useMemo(
    () => [
      {
        id: 0,
        name: t("totalReach"),
        icon: <IconTotalReach />,
        total: generalReferrals?.totalReach,
      },
      {
        id: 1,
        name: t("totalUsersReferred"),
        icon: <IconTotalUsersReferred />,
        total: generalReferrals?.totalUsersReferred,
      },
      {
        id: 2,
        name: t("totalSuccessfullReferrals"),
        icon: <IconTotalSuccessfulReferrals />,
        total: generalReferrals?.totalSuccessfulReferrals,
      },
      {
        id: 3,
        name: t("referralsConverted"),
        icon: <IconTotalReferralsConverted />,
        total: generalReferrals?.totalConvertedReferrals,
      },
    ],
    [generalReferrals, t]
  );

  return (
    <Box>
      <Flex
        justifyContent="space-between"
        padding={{ base: "40px 20px", lg: "40px 80px" }}
        background="linear-gradient(92deg, rgba(212, 247, 14, 0.25) 0%, rgba(56, 56, 61, 0.00) 100%)"
        borderRadius="8px"
        border="1px solid #38383D"
        mb="15px"
      >
        <Text fontSize={{ base: "16px", md: "20px" }} fontWeight="500">
          {t("creditBalance")}
        </Text>
        <Text fontSize={{ base: "16px", md: "20px" }} fontWeight="500">
          ${generalReferrals?.totalBalance || 0}
        </Text>
      </Flex>
      <SimpleGrid columns={2} spacing={{ base: 3, md: 5 }} mb="16px">
        {generalInfoReferrals.map((item) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            key={item.id}
            bg="rgba(56, 56, 61, 0.25)"
            flexDirection="column"
            padding="20px"
            borderRadius="6px"
            border="1px solid #38383D"
          >
            {item.icon}
            <Text fontSize={{ base: "18px", md: "24px" }} fontWeight="500">
              {item.total}
            </Text>
            <Text
              textAlign="center"
              fontSize={{ base: "13px", md: "15px" }}
              fontWeight="400"
            >
              {item.name}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
      <Box
        bg="rgba(56, 56, 61, 0.25)"
        padding="15px 30px"
        borderRadius="6px"
        border="1px solid #38383D"
      >
        <Text fontSize={{ base: "16px", md: "20px" }} fontWeight="400">
          {t("pendingInvites")} {`( ${generalReferrals?.pendingInvites} )`}
        </Text>
      </Box>
    </Box>
  );
}
