import React from "react";

const IconDownloadFiles = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M0.492188 9.74609C0.622724 9.74609 0.747914 9.79795 0.840217 9.89025C0.93252 9.98256 0.984375 10.1077 0.984375 10.2383V12.6992C0.984375 12.9603 1.08809 13.2107 1.27269 13.3953C1.4573 13.5799 1.70768 13.6836 1.96875 13.6836H13.7812C14.0423 13.6836 14.2927 13.5799 14.4773 13.3953C14.6619 13.2107 14.7656 12.9603 14.7656 12.6992V10.2383C14.7656 10.1077 14.8175 9.98256 14.9098 9.89025C15.0021 9.79795 15.1273 9.74609 15.2578 9.74609C15.3883 9.74609 15.5135 9.79795 15.6058 9.89025C15.6981 9.98256 15.75 10.1077 15.75 10.2383V12.6992C15.75 13.2214 15.5426 13.7221 15.1734 14.0913C14.8042 14.4605 14.3034 14.668 13.7812 14.668H1.96875C1.4466 14.668 0.945846 14.4605 0.576634 14.0913C0.207421 13.7221 0 13.2214 0 12.6992V10.2383C0 10.1077 0.0518553 9.98256 0.144158 9.89025C0.236461 9.79795 0.361651 9.74609 0.492188 9.74609Z"
        fill="#D4F70E"
      />
      <path
        d="M7.52667 11.6688C7.57239 11.7146 7.6267 11.751 7.68649 11.7758C7.74629 11.8006 7.81039 11.8134 7.87513 11.8134C7.93987 11.8134 8.00398 11.8006 8.06377 11.7758C8.12357 11.751 8.17788 11.7146 8.2236 11.6688L11.1767 8.71566C11.2691 8.62324 11.3211 8.49789 11.3211 8.36719C11.3211 8.23649 11.2691 8.11114 11.1767 8.01872C11.0843 7.9263 10.959 7.87438 10.8283 7.87438C10.6976 7.87438 10.5722 7.9263 10.4798 8.01872L8.36732 10.1322V1.47656C8.36732 1.34603 8.31547 1.22084 8.22316 1.12853C8.13086 1.03623 8.00567 0.984375 7.87513 0.984375C7.7446 0.984375 7.61941 1.03623 7.52711 1.12853C7.4348 1.22084 7.38295 1.34603 7.38295 1.47656V10.1322L5.27048 8.01872C5.17806 7.9263 5.05271 7.87438 4.92201 7.87438C4.79131 7.87438 4.66596 7.9263 4.57354 8.01872C4.48112 8.11114 4.4292 8.23649 4.4292 8.36719C4.4292 8.49789 4.48112 8.62324 4.57354 8.71566L7.52667 11.6688Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconDownloadFiles;
