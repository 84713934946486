import React from "react";

const IconTermAndConditions = () => {
  return (
    <svg
      width="20"
      height="27"
      viewBox="0 0 20 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6714 0H3.30138C1.48565 0 0.000191897 1.48546 0.000191897 3.30118L0 19.1196C0 20.9353 1.48546 22.4208 3.30119 22.4208H3.879C3.82393 22.1456 3.82392 21.843 3.82392 21.5404C3.82392 21.2377 3.85137 20.9625 3.879 20.6599H3.30119C2.44842 20.6599 1.733 19.9722 1.733 19.0917L1.73319 3.30133C1.73319 2.44856 2.42095 1.76081 3.30138 1.76081H16.6714C17.5241 1.76081 18.2119 2.44856 18.2119 3.30133V19.1197C18.2119 19.9725 17.5241 20.6879 16.6714 20.6879H16.0936C16.1486 20.9631 16.1486 21.2657 16.1486 21.5684C16.1486 21.8708 16.1486 22.146 16.121 22.4209H16.6988C18.5145 22.4209 20 20.9355 20 19.1197V3.30133C19.9726 1.4856 18.4869 0.000147389 16.6712 0.000147389L16.6714 0Z"
        fill="#D4F70E"
      />
      <path
        d="M14.5811 4.51172H5.39263C4.89755 4.51172 4.51221 4.89685 4.51221 5.39215C4.51221 5.88723 4.89734 6.27257 5.39263 6.27257H14.5536C15.0486 6.27257 15.434 5.88744 15.434 5.39215C15.4614 4.89686 15.0487 4.51172 14.5811 4.51172Z"
        fill="#D4F70E"
      />
      <path
        d="M14.5811 8.83105H5.39263C4.89755 8.83105 4.51221 9.21619 4.51221 9.71148C4.51221 10.2066 4.89734 10.5919 5.39263 10.5919H14.5536C15.0486 10.5919 15.434 10.2068 15.434 9.71148C15.4614 9.2162 15.0487 8.83105 14.5811 8.83105Z"
        fill="#D4F70E"
      />
      <path
        d="M14.5811 13.1504H5.39263C4.89755 13.1504 4.51221 13.5355 4.51221 14.0308C4.51221 14.5259 4.89734 14.9112 5.39263 14.9112H14.5536C15.0486 14.9112 15.434 14.5261 15.434 14.0308C15.4614 13.5355 15.0487 13.1504 14.5811 13.1504Z"
        fill="#D4F70E"
      />
      <path
        d="M9.98631 16.9736C7.45536 16.9736 5.39209 19.0369 5.39209 21.5679C5.39209 24.0988 7.45536 26.1621 9.98631 26.1621C12.5173 26.1621 14.5805 24.0713 14.5805 21.5404C14.5805 19.0095 12.5173 16.9737 9.98631 16.9737V16.9736ZM12.1321 21.1552L9.90383 23.3835C9.73879 23.5486 9.54613 23.6311 9.32601 23.6311C9.1059 23.6311 8.91344 23.5486 8.7482 23.3835L7.64788 22.2832C7.34526 21.9806 7.34526 21.4579 7.64788 21.1552C7.95051 20.8526 8.47324 20.8526 8.77585 21.1552L9.32601 21.7054L10.9767 20.0547C11.2793 19.7521 11.8021 19.7521 12.1047 20.0547C12.4622 20.3299 12.4622 20.8526 12.1321 21.1552L12.1321 21.1552Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconTermAndConditions;
