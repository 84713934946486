import { Text, Flex } from "@chakra-ui/react";
import { User } from "app/models";
import React, { useMemo } from "react";
import countryList from "react-select-country-list";
import IconAddressDashboardCoverMobile from "app/assets/icons/IconAddressDashboardCoverMobile";

interface RenderCountryUserProps {
  typeOfUser: (value: string) => void;
  userById: User;
}

export default function RenderCountryUser({
  typeOfUser,
  userById,
}: RenderCountryUserProps) {
  const options = useMemo(() => countryList().getData(), []);
  const fullNameCountry = options.filter(
    (item) => item.value === userById?.country
  );

  return (
    <Flex
      justifyContent={{ base: "start", md: "center" }}
      alignItems="center"
      mb={{ base: "16px", md: "0px" }}
    >
      <IconAddressDashboardCoverMobile color="#D4F70E" />
      <Text color="rgba(255, 255, 255, 0.51)" fontSize="14px" lineHeight="17px">
        {fullNameCountry[0]?.label}&nbsp;
        {userById?.role && userById?.role[0] ? typeOfUser("") : ""}
      </Text>
    </Flex>
  );
}
