import React from "react";

const IconPaymentsActive = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="9" cy="9" r="8" fill="#D4F70E" fill-opacity="0.21" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.08706 6.38886C3.75 6.89331 3.75 7.59554 3.75 9C3.75 10.4045 3.75 11.1067 4.08706 11.6111C4.23298 11.8295 4.42048 12.017 4.63886 12.1629C5.14331 12.5 5.84554 12.5 7.25 12.5H10.75C12.1545 12.5 12.8567 12.5 13.3611 12.1629C13.5795 12.017 13.767 11.8295 13.9129 11.6111C14.25 11.1067 14.25 10.4045 14.25 9C14.25 7.59554 14.25 6.89331 13.9129 6.38886C13.767 6.17048 13.5795 5.98298 13.3611 5.83706C12.8567 5.5 12.1545 5.5 10.75 5.5H7.25C5.84554 5.5 5.14331 5.5 4.63886 5.83706C4.42048 5.98298 4.23298 6.17048 4.08706 6.38886ZM9 10.75C9.9665 10.75 10.75 9.9665 10.75 9C10.75 8.0335 9.9665 7.25 9 7.25C8.0335 7.25 7.25 8.0335 7.25 9C7.25 9.9665 8.0335 10.75 9 10.75Z"
        fill="#7E869E"
        fill-opacity="0.25"
      />
      <rect
        x="3.75"
        y="5.5"
        width="10.5"
        height="7"
        rx="2"
        stroke="#D4F70E"
        stroke-width="1.2"
      />
      <path
        d="M5.5 7.25L6.66667 7.25"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M11.3359 10.75H12.5026"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <circle cx="9" cy="9" r="1.15" stroke="#D4F70E" stroke-width="1.2" />
    </svg>
  );
};
export default IconPaymentsActive;
