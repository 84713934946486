import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import IconShareLink from "app/assets/icons/shareLinkIcon";
import { ShortLinkModel, TrackSoundKit } from "app/models";
import { TRACK_TYPE } from "constants/trackTypes";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import SkeletonShareShortLink from "./skeletonShareShortLink";
import Skeleton from "app/components/common/Skeleton/skeleton";

interface ModalProps {
  showModalShareLink: boolean;
  setShowModalShareLink: () => void;
  selectedTrackSoundkit: TrackSoundKit | null;
  shortLinkUrl: ShortLinkModel;
  loadingUrl: boolean;
}

export default function ModalShareLinkRecentActions({
  showModalShareLink = false,
  setShowModalShareLink,
  selectedTrackSoundkit,
  shortLinkUrl,
  loadingUrl,
}: ModalProps) {
  const { t } = useTranslation();

  const hostName = window.location.host;
  const checkTypeUrl = !!selectedTrackSoundkit?.trackState;
  const shortShareLink = useMemo(
    () => [
      {
        id: 0,
        link: shortLinkUrl?.shortURL,
        name: t("shortLink"),
        width: "50%",
      },
      {
        id: 1,
        link: checkTypeUrl
          ? `${hostName}/track/${selectedTrackSoundkit?.slug}`
          : `${hostName}/soundkit/${selectedTrackSoundkit?.slug}`,
        name: t("shareLink"),
        width: "80%",
        showLoading: false,
      },
    ],
    [
      checkTypeUrl,
      hostName,
      selectedTrackSoundkit?.slug,
      shortLinkUrl?.shortURL,
      t,
    ]
  );

  const clipBoardLink = useCallback((link) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(link);
    }
  }, []);

  return (
    <Modal
      colorScheme="black"
      isOpen={showModalShareLink}
      onClose={setShowModalShareLink}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "650px", xl: "800px" }}
        margin="10px"
        maxH="600px"
      >
        <ModalHeader
          fontSize={{ base: "24px", xl: "32px" }}
          lineHeight="24px"
          fontWeight="500"
          p="16px 24px 0px"
        >
          {loadingUrl ? (
            <Skeleton
              w="150px"
              h={{ base: "22px", xl: "32px" }}
              borderRadius="30px"
            />
          ) : (
            <Text>{t("shareLink")}</Text>
          )}
        </ModalHeader>

        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModalShareLink}
          right={3}
          top="13px"
          fontSize="26px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody mt="40px" overflow="scroll">
          {loadingUrl ? (
            <SkeletonShareShortLink />
          ) : (
            <>
              <Flex alignItems="center">
                <Image
                  w="72px"
                  h="72px"
                  src={selectedTrackSoundkit?.coverUrl}
                  borderRadius="4px"
                  cursor="pointer"
                  fallbackSrc={ImageDefault}
                />
                <Box ml={{ base: "20px", xl: "25px" }}>
                  <Text
                    fontSize={{ base: "14px", xl: "16px" }}
                    lineHeight="16px"
                    fontWeight="700"
                    noOfLines={1}
                    fontFamily="Cera Pro Regular"
                  >
                    {selectedTrackSoundkit?.title}
                  </Text>
                  <Flex mt="10px">
                    <Text
                      fontSize={{ base: "12px", xl: "14px" }}
                      lineHeight="17px"
                      color="#d4f70e"
                      padding="0px 5px"
                      borderRadius="4px"
                      mr="10px"
                      backgroundColor="#2b2e1b"
                    >
                      {t(TRACK_TYPE[selectedTrackSoundkit?.type || ""])}
                    </Text>
                    <Text
                      fontSize={{ base: "12px", xl: "14px" }}
                      lineHeight="16px"
                      fontWeight="400"
                      color="#49494D"
                      fontFamily="Cera Pro Regular"
                    >
                      {selectedTrackSoundkit?.createdBy?.name}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Box mt={{ base: "30px", xl: "50px" }}>
                {shortShareLink.map((item) => (
                  <Box mb="20px" key={item.id}>
                    <Text
                      fontSize={{ base: "14px", xl: "16px" }}
                      mb={{ base: "5px", xl: "10px" }}
                      fontFamily="Cera Pro Regular"
                    >
                      {item.name}
                    </Text>
                    <Flex>
                      <Flex
                        background="rgba(255, 255, 255, 0.04)"
                        alignItems="center"
                        w={item.width}
                        mr={{ base: "10px", md: "20px" }}
                        borderRadius="4px"
                        padding="0 10px"
                      >
                        <Box ml="5px">
                          <IconShareLink />
                        </Box>
                        <Text
                          noOfLines={1}
                          opacity="0.5"
                          ml="10px"
                          borderLeft="1px solid white"
                          paddingLeft="10px"
                        >
                          {item.link}
                        </Text>
                      </Flex>

                      <Button
                        background="rgba(216, 253, 1, 0.2)"
                        color="#D8FD01"
                        _hover={{
                          background: "rgba(216, 253, 1, 0.2)",
                        }}
                        onClick={() => clipBoardLink(item.link)}
                      >
                        {t("copy")}
                      </Button>
                    </Flex>
                  </Box>
                ))}
              </Box>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
