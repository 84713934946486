import * as yup from "yup";

const formSchemaLicense = () =>
  yup.object().shape({
    name: yup.string().required("Name is required"),
    defaultTrackPrice: yup
      .number()
      .nullable()
      .required("Default price is required"),
    makeOffersOnly: yup.boolean(),
    minPrice: yup
      .number()
      .typeError("Minimum price is required")
      .when("makeOffersOnly", (makeOffersOnly, schema) => {
        return makeOffersOnly
          ? schema
              .lessThan(
                yup.ref("defaultTrackPrice"),
                "Min price must be less than default price"
              )
              .required("Minimum price is required")
          : schema
              .transform((_, val) => (val !== "" ? Number(val) : null))
              .nullable(true);
      }),
    state: yup.string().required("State is required"),
    exclusive: yup
      .string()
      .typeError("Select type of contract is required")
      .required("Select type of contract is required"),

    beatsPublishing: yup
      .number()
      .nullable()
      .transform((value) => (Number.isNaN(value) ? null : value))
      .max(50, "Beats publishing maximum value is 50%")
      .when(
        ["exclusive", "beatsHookPublishing", "topLinesAndVocals"],
        (exclusive, beatsHookPublishing, topLinesAndVocals, schema) => {
          return exclusive === "EXCLUSIVE" &&
            !beatsHookPublishing &&
            !topLinesAndVocals
            ? schema.required("Beats publishing is required")
            : schema;
        }
      ),
    beatsHookPublishing: yup.lazy((value, originalValue) => {
      return yup
        .number()
        .nullable()
        .transform((value) => (Number.isNaN(value) ? null : value))
        .max(50, "Beats hook publishing maximum value is 50%")
        .when(
          ["exclusive", "beatsPublishing", "topLinesAndVocals"],
          (exclusive, beatsPublishing, topLinesAndVocals, schema) => {
            return exclusive === "EXCLUSIVE" &&
              !beatsPublishing &&
              !topLinesAndVocals
              ? schema.required("Beats hook publishing is required")
              : schema;
          }
        );
    }),
    topLinesAndVocals: yup.lazy((value, originalValue) => {
      return yup
        .number()
        .nullable()
        .max(50, "Top lines & vocals publishing maximum value is 50%")
        .transform((value) => (Number.isNaN(value) ? null : value))
        .when(
          ["exclusive", "beatsHookPublishing", "beatsPublishing"],
          (exclusive, beatsHookPublishing, beatsPublishing, schema) => {
            return exclusive === "EXCLUSIVE" &&
              !beatsHookPublishing &&
              !beatsPublishing
              ? schema.required("Top lines and vocals is required")
              : schema;
          }
        );
    }),
    licenseRenewal: yup
      .string()
      .typeError("License Renewal is required")
      .when("exclusive", (exclusive, schema) => {
        return exclusive === "NON_EXCLUSIVE"
          ? schema.required("License Renewal is required")
          : yup.string().nullable().notRequired();
      }),
  });

export default formSchemaLicense;
