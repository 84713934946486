import { Box } from "@chakra-ui/react";
import React from "react";
import "./styles.scss";

interface Props { }

const GlobalLoading: React.FC<Props> = () => {
  return (
    <Box className="global-loading-container">
      {/* <Spinner margin="400px auto" display="flex" /> */}
    </Box>
  );
};

export default GlobalLoading;
