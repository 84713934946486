import { Box, Divider, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";

export default function SkeletonItemPaymentTransfer() {
  return (
    <>
      {Array.from({ length: 10 }).map((item, index) => (
        <Box key={index}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            mt={{ lg: "20px" }}
            alignItems="center"
            w="100%"
            pb={{ base: "40px", lg: "20px" }}
            borderBottom="1px solid #26262A"
            minWidth={{ lg: "1024px" }}
            gap="15px"
          >
            <Box w="5%">
              <Skeleton
                w="42px"
                h="42px"
                background="#535353"
                borderRadius="4px"
              />
            </Box>
            <Box w="17%">
              <Skeleton w="140px" h="16px" background="#535353" />
            </Box>
            <Flex w="15%">
              <Skeleton
                w="40px"
                h="40px"
                borderRadius="full"
                background="#535353"
              />
              <Box>
                <Skeleton w="95px" h="16px" background="#535353" />
                <Skeleton
                  w="34px"
                  h="16px"
                  background="#535353"
                  borderRadius="8px"
                />
              </Box>
            </Flex>
            <Box w="10%" display="flex" justifyContent="center">
              <Skeleton mr="0px" w="100px" h="16px" background="#535353" />
            </Box>
            <Box w="8%">
              <Skeleton mr="0px" w="90px" h="16px" background="#535353" />
            </Box>
            <Box display="flex" justifyContent="center" w="15%">
              <Skeleton
                w="120px"
                h="20px"
                background="#535353"
                borderRadius="4px"
                mr="0px"
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              w={{ base: "100%", lg: "10%" }}
            >
              <Skeleton
                w="100px"
                h="20px"
                borderRadius="4px"
                background="#535353"
              />
            </Box>
            <Flex justifyContent="center" w={{ base: "100%", lg: "10%" }}>
              <Skeleton
                w="80px"
                h="20px"
                borderRadius="4px"
                background="#535353"
              />
            </Flex>
            <Flex
              position="relative"
              w={{ base: "100%", lg: "10%" }}
              justifyContent={{ lg: "flex-end" }}
            >
              <Skeleton h="30px" w="92px" borderRadius="20px" m="0px" />
              <Skeleton
                position="absolute"
                right="5px"
                top="3px"
                left={{ base: "60px", lg: "unset" }}
                h="22px"
                w="22px"
                borderRadius="full"
                background="#24252A"
              />
            </Flex>
          </Flex>
          <Divider mt="10px" borderColor="#535353" />
        </Box>
      ))}
    </>
  );
}
