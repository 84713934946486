import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";

export default function SkeletonItemPaymentTransferMobile() {
  return (
    <>
      {Array.from({ length: 10 }).map((item, index) => (
        <Box key={index}>
          <Flex
            direction={{ base: "column", lg: "row" }}
            w="100%"
            background="#1E2023"
            borderRadius="10px"
            padding="23px 20px"
            mb="20px"
            border="1px solid #343639"
          >
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Flex alignItems="center">
                <Skeleton
                  w={"35px"}
                  h={"36px"}
                  background="#535353"
                  borderRadius="6px"
                />
                <Skeleton w="120px" h="14px" />
              </Flex>
              <Flex>
                <Skeleton w="40px" h="40px" borderRadius="full" />
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Skeleton h="14px" mr="0px" />
                  <Skeleton h="14px" mr="0px" />
                </Box>
              </Flex>
            </Flex>
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Skeleton w="110px" h="14px" />
              <Skeleton w="110px" mr="0px" h="14px" />
            </Flex>
            <Flex justifyContent="space-between" mb="10px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Box>
                <Skeleton w="90px" borderRadius="4px" />
                <Skeleton w="170px" h="14px" />
              </Box>
              <Box display="flex" flexDirection="column" alignItems="end">
                <Skeleton w="90px" h="14px" mr="0px" />
              </Box>
            </Flex>
            <Flex justifyContent="space-between" mb="15px">
              <Skeleton w="110px" />
              <Skeleton w="110px" mr="0px" />
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Skeleton w="90px" borderRadius="4px" />
              <Skeleton w="60px" h="36px" borderRadius="full" />
            </Flex>
          </Flex>
        </Box>
      ))}
    </>
  );
}
