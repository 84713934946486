import React from "react";

const IconSPVerified = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="8" fill="#D4F70E" fill-opacity="0.21" />
      <circle cx="10" cy="10" r="4.5" fill="#D4F70E" fill-opacity="0.1" />
      <path
        d="M8.5 9L9.7581 9.94358C10.1766 10.2574 10.7662 10.1957 11.1107 9.80208L14 6.5"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
      <path
        d="M14.5 10C14.5 10.9402 14.2055 11.8569 13.6578 12.6211C13.1101 13.3854 12.3368 13.959 11.4465 14.2612C10.5561 14.5634 9.59348 14.5792 8.69372 14.3062C7.79396 14.0333 7.0023 13.4854 6.42991 12.7394C5.85752 11.9935 5.53317 11.087 5.50241 10.1472C5.47165 9.20749 5.73601 8.28172 6.25839 7.49993C6.78076 6.71815 7.53489 6.11962 8.41487 5.78842C9.29486 5.45722 10.2565 5.40998 11.1647 5.65333"
        stroke="#D4F70E"
        stroke-width="1.2"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default IconSPVerified;
