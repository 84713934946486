import { Box, Flex } from "@chakra-ui/react";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";

interface SkeletonTrackItemProps {
  isProfile?: boolean;
}
export default function SkeletonTrackItem({
  isProfile = false,
}: SkeletonTrackItemProps) {
  const fakeDataSekeleton = Array.from({ length: 10 });

  return (
    <Box mt="20px">
      {fakeDataSekeleton.map((item, index) => (
        <Flex alignItems="center" mb={{ base: "12px", md: "30px" }} key={index}>
          <Box
            w="9%"
            display={{ base: "none", xl: "flex" }}
            justifyContent="center"
          >
            {isProfile && <Skeleton w="10px" h="21px" borderRadius="2px" />}
          </Box>
          <Flex w="50%" alignItems="center">
            <Box mr="12px">
              <IconSkeletonMusic />
            </Box>
            <Box>
              <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
              <Flex>
                <Skeleton h="16px" w="35px" mb="0px" />
                <Skeleton
                  h="16px"
                  w="109px"
                  borderRadius="30px"
                  mb="0px"
                  mr="0px"
                />
              </Flex>
            </Box>
          </Flex>

          <Flex
            w="50%"
            justifyContent="end"
            display={{ base: "flex", md: "none" }}
          >
            <Flex alignItems="center" gap="8px" w="max-content">
              <Skeleton w="32px" h="32px" borderRadius="full" />
              <Skeleton w="10px" h="40px" />
            </Flex>
          </Flex>

          <Flex
            justifyContent={{ md: "end", xl: "center" }}
            display={{ base: "none", md: "flex" }}
            w={{ md: "50%", xl: "23%" }}
          >
            <Skeleton w="80px" h="20px" borderRadius="6px" />
            <Skeleton
              w="80px"
              h="20px"
              display={{ md: "none", xl: "block" }}
              borderRadius="6px"
            />
          </Flex>
          <Flex
            display={{ base: "none", xl: "flex" }}
            position="relative"
            w="12%"
            justifyContent="flex-end"
          >
            <Skeleton h="32px" w="70px" borderRadius="20px" />
            <Skeleton
              position="absolute"
              right="5px"
              top="4px"
              h="24px"
              w="24px"
              borderRadius="full"
              background="#24252A"
            />
          </Flex>
          <Flex
            w="6%"
            justifyContent="end"
            display={{ base: "none", xl: "flex" }}
          >
            <Skeleton w="10px" h="32px" />
          </Flex>
        </Flex>
      ))}
    </Box>
  );
}
