import { Box } from "@chakra-ui/react";
import PaymentAndWallets from "app/apis/paymentAndWallets";
import PaymentAndWallet from "app/components/PaymentAndWallets";
import {
  getAccountDetails,
  updateConnectPaymentType,
} from "app/redux/actions/paymentWallets";
import { selectUserAdmin } from "app/redux/selectors/users";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Payments from "../Payments";
import { getUserInfo } from "app/redux/actions/users";

export default function ConnectPayment() {
  const dispatch: any = useDispatch();
  const userAdmin = useSelector(selectUserAdmin);
  const [isLoadingAddPayment, setIsLoadingAddPayment] = useState(false);
  const [isLoadingConnectStripe, setIsLoadingConnectStripe] = useState(false);
  const [isLoadingConnectPaypal, setIsLoadingConnectPaypal] = useState(false);

  const handleClickPaypal = async () => {
    try {
      setIsLoadingAddPayment(true);
      setIsLoadingConnectPaypal(true);
      const data = await PaymentAndWallets.paypalParterReferrals();
      if (data.success) {
        setIsLoadingAddPayment(false);
        setIsLoadingConnectPaypal(false);
        window.location.assign(data.data?.href);
      } else {
        setIsLoadingConnectPaypal(false);
      }
    } catch (e) {
      setIsLoadingConnectPaypal(false);
      setIsLoadingAddPayment(false);
      throw e;
    }
  };

  const handleClickStripe = async () => {
    try {
      setIsLoadingAddPayment(true);
      setIsLoadingConnectStripe(true);
      const data = await PaymentAndWallets.startAccountLink();
      if (data) {
        window.location.assign(data?.stripeResponse?.url);
        setIsLoadingAddPayment(false);
        setIsLoadingConnectStripe(false);
      }
    } catch (e) {
      setIsLoadingAddPayment(false);
      setIsLoadingConnectStripe(false);
      throw e;
    }
  };

  useEffect(() => {
    if (userAdmin?.accountId && !userAdmin?.sellerConnectedStripe) {
      dispatch(getAccountDetails()).then(async (res) => {
        if (res?.external_accounts?.data?.length > 0) {
          await dispatch(
            updateConnectPaymentType({ sellerConnectedStripe: true })
          );
          await dispatch(getUserInfo());
        }
      });
    }
  }, [dispatch, userAdmin?.accountId, userAdmin?.sellerConnectedStripe]);

  useEffect(() => {
    dispatch(getAccountDetails());
  }, [dispatch]);

  const handleAddWallet = useCallback(() => {
    if (userAdmin?.sellerConnectedPayPal && userAdmin?.sellerConnectedStripe) {
      return;
    } else if (
      userAdmin?.sellerConnectedPayPal &&
      !userAdmin?.sellerConnectedStripe
    ) {
      handleClickStripe();
    } else if (
      !userAdmin?.sellerConnectedPayPal &&
      userAdmin?.sellerConnectedStripe
    ) {
      handleClickPaypal();
    }
  }, [userAdmin?.sellerConnectedPayPal, userAdmin?.sellerConnectedStripe]);

  return (
    <Box>
      {userAdmin.sellerConnectedPayPal || userAdmin.sellerConnectedStripe ? (
        <PaymentAndWallet
          userAdmin={userAdmin}
          onAddWallet={handleAddWallet}
          isLoadingAddPayment={isLoadingAddPayment}
        />
      ) : (
        <Payments
          handleClickPaypal={handleClickPaypal}
          handleClickStripe={handleClickStripe}
          isLoadingPaypal={isLoadingConnectPaypal}
          isLoadingStripe={isLoadingConnectStripe}
        />
      )}
    </Box>
  );
}
