import { createReducer } from "@reduxjs/toolkit";
import { TrackSoundKit } from "app/models";
import { Nullable } from "constants/types";
import {
  setIsAudioPlay,
  setPlayingTrack,
  setIsPlaying,
  setPlayingTrackId,
  setIsLoadingPlay,
  setShowFooterPlayer,
  setDurationUnTagTracks,
  setDurationTagTracks,
} from "app/redux/actions/player";

export interface InitPlayerState {
  playingTrackId: string;
  playingTrack: Nullable<TrackSoundKit>;
  isAudioPlay: boolean;
  isPlaying: boolean;
  isLoading: boolean;
  showFooterPlayer: boolean;
  timeUntagDurationTrack: string;
  timeTagDurationTrack: string;
}

const initialState: InitPlayerState = {
  playingTrackId: "",
  playingTrack: null,
  isAudioPlay: false,
  isPlaying: false,
  isLoading: false,
  showFooterPlayer: true,
  timeUntagDurationTrack: "",
  timeTagDurationTrack: "",
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setPlayingTrack, (state, action) => {
      return {
        ...state,
        playingTrack: action.payload,
      };
    })
    .addCase(setIsAudioPlay, (state, action) => {
      const isAudioPlay = action.payload;
      return {
        ...state,
        isAudioPlay,
        ...(!isAudioPlay ? { playingTrackId: "" } : {}),
      };
    })
    .addCase(setIsPlaying, (state, action) => {
      return {
        ...state,
        isPlaying: action.payload,
      };
    })

    .addCase(setPlayingTrackId, (state, action) => {
      return {
        ...state,
        playingTrackId: action.payload,
      };
    })
    .addCase(setIsLoadingPlay, (state, action) => {
      return {
        ...state,
        isLoading: action.payload,
      };
    })
    .addCase(setShowFooterPlayer, (state, action) => {
      return {
        ...state,
        showFooterPlayer: action.payload,
      };
    })
    .addCase(setDurationUnTagTracks, (state, action) => {
      return {
        ...state,
        timeUntagDurationTrack: action.payload,
      };
    })
    .addCase(setDurationTagTracks, (state, action) => {
      return {
        ...state,
        timeTagDurationTrack: action.payload,
      };
    })
);
