import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useMediaQuery,
  UnorderedList,
  ListItem,
  AbsoluteCenter,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import AvatarUser from "app/assets/images/ImageDefaultLoading.png";
import AvatarCoverUrl from "app/assets/images/AvatarMusicLoading.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CollaborationsModel, TrackSoundKit } from "app/models";
import { roleText } from "constants/collaboratorType";
import {
  individualSoundKitSelector,
  isFavoritesSKSelector,
  isRepostsSKSelector,
} from "app/redux/selectors/mySoundKit";
import {
  likeSoundPacksDetail,
  repostSoundPacksDetail,
  unLikeSoundPacksDetail,
} from "app/redux/actions/mySoundKit";
import { Link } from "react-router-dom";
import LinkToTrackOrProfile from "../IndividualTrackPage/linkToTrackProfile";
import ToastPlanSubscription from "app/components/toast/toastplan";
import IconLikeIndividualTrack from "app/assets/icons/iconLikeIndividual";
import IconRepostIndividualTrack from "app/assets/icons/iconRepostIndividual";
import IconLyricsIndividualTrack from "app/assets/icons/iconLyricsIndividual";
import IconLikeIndividualTrackMobile from "app/assets/icons/iconLikeIndividualMobile";
import IconRepostIndividualTrackMobile from "app/assets/icons/iconRepostIndividualMobile";
import IconLyricsIndividualTrackMobile from "app/assets/icons/iconLyricsIndividualTrackMobile";
import { CgAddR } from "react-icons/cg";
import { TRACK_TYPE } from "constants/trackTypes";
import AudioVisualizer from "app/components/AudioVisualizer";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import TooltipLabel from "app/components/TooltipLabel";
import PauseAudioIcon from "app/assets/icons/PauseAudio";
import { usePlayers } from "hooks/player/usePlayers";

export default function LeftIndividualSoundkit() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const soundkitDetail: TrackSoundKit = useSelector(individualSoundKitSelector);
  const [delayTimeCallApi, setDelayTimeCallApi] = useState(0);
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const {
    isPlaying,
    handleTogglePlayPause,
    playingTrack,
    handlePlayOrPause,
    isLoading,
  } = usePlayers();

  const checkPlaying = useMemo(() => {
    return soundkitDetail?._id === playingTrack?._id;
  }, [playingTrack?._id, soundkitDetail?._id]);
  const isFavorite = useSelector(isFavoritesSKSelector(soundkitDetail));
  const onClickToTrack = useCallback(
    async (track) => {
      if (!track?.mergeAudio && !track?.audioFileUrl) {
        return;
      }
      handlePlayOrPause(track);
    },
    [handlePlayOrPause]
  );

  const isRepost = useSelector(isRepostsSKSelector(soundkitDetail));
  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 2000);
  }, []);
  const handleLikeUnlikeSoundPacksDetail = useCallback(async () => {
    increaseTime();
    if (isFavorite) {
      dispatch(
        unLikeSoundPacksDetail({
          id: soundkitDetail?._id || "",
          timeCallApi: delayTimeCallApi,
        })
      );
      return;
    }
    dispatch(
      likeSoundPacksDetail({
        id: soundkitDetail?._id || "",
        timeCallAPi: delayTimeCallApi,
      })
    );
  }, [
    delayTimeCallApi,
    dispatch,
    increaseTime,
    isFavorite,
    soundkitDetail?._id,
  ]);

  const handleRepostSoundPacksDetail = useCallback(() => {
    if (isRepost) {
      ToastPlanSubscription({ description: t("alreadyRepostSK") });
    } else {
      dispatch(repostSoundPacksDetail(soundkitDetail?._id || ""));
    }
  }, [dispatch, isRepost, soundkitDetail?._id, t]);

  const iconTrackDetail = useMemo(
    () => [
      {
        icon: isLessThan768 ? (
          <IconLikeIndividualTrackMobile isFavorite={isFavorite} />
        ) : (
          <IconLikeIndividualTrack isFavorite={isFavorite} />
        ),
        total: soundkitDetail?.totalFavs || 0,
        id: 0,
        onClick: () => handleLikeUnlikeSoundPacksDetail(),
      },
      {
        icon: isLessThan768 ? (
          <IconRepostIndividualTrackMobile isRepost={isRepost} />
        ) : (
          <IconRepostIndividualTrack isRepost={isRepost} />
        ),
        total: soundkitDetail?.totalRepost || 0,
        id: 1,
        onClick: () => handleRepostSoundPacksDetail(),
        marginTop: "-5px",
      },
      {
        icon: isLessThan768 ? (
          <IconLyricsIndividualTrackMobile />
        ) : (
          <IconLyricsIndividualTrack />
        ),
        total: "",
        id: 2,
        onClick: () => null,
      },
      {
        icon: <CgAddR size={isLessThan768 ? 24 : 36} />,
        total: "",
        id: 3,
        onClick: () => null,
      },
    ],
    [
      isLessThan768,
      handleLikeUnlikeSoundPacksDetail,
      handleRepostSoundPacksDetail,
      isFavorite,
      isRepost,
      soundkitDetail?.totalFavs,
      soundkitDetail?.totalRepost,
    ]
  );
  const metaData = useMemo(
    () => [
      {
        name: "Genre",
        label: soundkitDetail?.mainGenre,
        id: 1,
      },
      {
        name: "Sub-Genre",
        label: soundkitDetail?.subGenre,
        id: 1,
      },
      {
        name: "Sound Kit Type",
        label: soundkitDetail?.type,
        id: 2,
      },
    ],
    [soundkitDetail?.mainGenre, soundkitDetail?.subGenre, soundkitDetail?.type]
  );

  const collaborationRole = useCallback((role) => {
    return roleText(role);
  }, []);

  return (
    <Box w={{ base: "100%", md: "35%", xl: "30%" }}>
      <Box
        background="rgba(36, 37, 42, 0.3)"
        padding={{ base: "25px 10px", xl: "25px 35px" }}
        borderRadius="8px"
        mb="16px"
      >
        <Box
          display="flex"
          flexDirection={{ base: "row", md: "column" }}
          alignItems="center"
        >
          <Box
            position="relative"
            w={{ base: "100px", md: "132px", lg: "210px" }}
            h={{ base: "100px", md: "132px", lg: "210px" }}
          >
            <Image
              src={soundkitDetail?.coverUrl}
              fallbackSrc={AvatarCoverUrl}
              borderRadius="8px"
              objectFit="cover"
              w={{ base: "100px", md: "132px", lg: "210px" }}
              h={{ base: "100px", md: "132px", lg: "210px" }}
            />
            <AbsoluteCenter display={{ base: "block", md: "none" }}>
              {checkPlaying ? (
                <PlayButton
                  handleTogglePlayPause={handleTogglePlayPause}
                  isPlaying={isPlaying}
                  size={40}
                  isLoading={isLoading}
                />
              ) : (
                <Flex
                  width="40px"
                  height="40px"
                  border="1px solid #D1F310"
                  borderRadius="25px"
                  alignItems="center"
                  justifyContent="center"
                  cursor="pointer"
                  onClick={() => onClickToTrack(soundkitDetail)}
                >
                  <TooltipLabel label={t("Play")}>
                    <Flex
                      background="rgba(255, 255, 255, 0.06)"
                      width="28px"
                      height="28px"
                      borderRadius="25px"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <PauseAudioIcon />
                    </Flex>
                  </TooltipLabel>
                </Flex>
              )}
            </AbsoluteCenter>
          </Box>
          <Box
            background={`url(${soundkitDetail?.coverUrl})`}
            className="c_rt_ptlt_box"
            overflow="scroll"
            w={{ base: "75%", md: "100%" }}
            ml="7px"
            mr="-2px"
            display={{ base: "block", md: "none" }}
          >
            <Box
              padding={"20px 9px"}
              borderRadius="8px"
              position="relative"
              className="c_rt_tlt_box"
            >
              <Text
                cursor="pointer"
                fontSize="24px"
                lineHeight="38px"
                fontWeight="700"
                textAlign={{ base: "unset", md: "center" }}
                m={{ base: "0px", md: "20px 0px 0px 0px" }}
                noOfLines={1}
                _hover={{
                  textDecoration: "underline",
                }}
                fontFamily="Cera Pro Regular"
              >
                {soundkitDetail?.title}
              </Text>
              <Flex>
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  lineHeight="19px"
                  color="#d4f70e"
                  padding="0px 5px"
                  borderRadius="4px"
                  fontFamily="Cera Pro Regular"
                  mr="10px"
                  backgroundColor="#2b2e1b"
                >
                  {t(TRACK_TYPE[soundkitDetail?.type])}
                </Text>

                <Text
                  cursor="pointer"
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight="400"
                  textAlign={{ base: "unset", md: "center" }}
                  color="rgba(255, 255, 255, 0.51)"
                  fontFamily="Cera Pro Regular"
                >
                  <LinkToTrackOrProfile
                    name={soundkitDetail?.createdBy?.name || ""}
                    type="user"
                  />
                </Text>
              </Flex>
            </Box>
          </Box>
        </Box>
        <Text
          fontSize="24px"
          lineHeight="38px"
          fontWeight="700"
          textAlign="center"
          m="20px 0px 0px 0px"
          noOfLines={2}
          _hover={{
            textDecor: "underline",
          }}
          cursor="pointer"
          display={{ base: "none", md: "-webkit-box" }}
        >
          {soundkitDetail?.title}
        </Text>
        <Text
          display={{ base: "none", md: "block" }}
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="400"
          textAlign="center"
          color="rgba(255, 255, 255, 0.51)"
        >
          <LinkToTrackOrProfile
            name={soundkitDetail?.createdBy?.name || ""}
            type="user"
          />
        </Text>
        <Flex
          className="c_rt_ptlt_box"
          background={`url(${soundkitDetail?.coverUrl})`}
          mt="4px"
          alignItems="center"
          justifyContent="center"
          display={{ base: "flex", md: "none" }}
        >
          <Flex
            justifyContent="center"
            w="100%"
            className="c_rt_tlt_box"
            padding="2px"
            borderRadius="4px"
          >
            {soundkitDetail?.bpm && (
              <Text fontSize="12px" mr="5px">
                Bpm <span>{soundkitDetail?.bpm}</span>
              </Text>
            )}

            <UnorderedList display="flex" gap="25px">
              {soundkitDetail?.key && (
                <ListItem fontSize="12px">KEY: {soundkitDetail?.key}</ListItem>
              )}
              {soundkitDetail?.mainGenre && (
                <ListItem fontSize="12px">{soundkitDetail?.mainGenre}</ListItem>
              )}
              {soundkitDetail?.subGenre && (
                <ListItem fontSize="12px">{soundkitDetail?.subGenre}</ListItem>
              )}
            </UnorderedList>
          </Flex>
        </Flex>

        {isLessThan766 && (
          <Box
            background={`url(${soundkitDetail?.coverUrl})`}
            className="c_rt_ptlt_box"
            display={{ base: "block", md: "none" }}
            mt="4px"
            mr="-2px"
          >
            <Box
              position="relative"
              mb="10px"
              className="c_rt_tlt_box"
              padding={"15px 10px"}
              borderRadius="8px"
              minH="70px"
            >
              {checkPlaying && <AudioVisualizer checkPlaying={checkPlaying} />}
            </Box>
          </Box>
        )}

        <Flex
          justifyContent={{ base: "space-around", md: "space-between" }}
          mt="30px"
        >
          {iconTrackDetail.map((item) => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={item.id}
              onClick={item.onClick}
              cursor="pointer"
              justifyContent="space-between"
            >
              <Box
                _hover={{
                  border: "1px solid #38383D",
                  background: "#222227 !important",
                  borderRadius: "6px",
                  padding: "10px",
                }}
                padding="10px"
                borderWidth="1px"
                borderColor="rgba(36, 37, 42, 0.3)"
                mt={item?.marginTop}
              >
                {item.icon}
              </Box>
              <Text
                fontSize="20px"
                lineHeight="24px"
                fontWeight="400"
                color="#535353"
              >
                {item.total}
              </Text>
            </Box>
          ))}
        </Flex>
        <Divider
          m="20px 0px 15px 0px"
          borderColor="rgba(255, 255, 255, 0.04)"
        />

        <Text mb="15px" fontSize="16px" lineHeight="19px" fontWeight="600">
          {t("meataData")}
        </Text>
        {metaData.map((item) => (
          <Flex mb="15px" justifyContent="space-between" key={item.id}>
            <Text
              color="rgba(255, 255, 255, 0.5)"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="600"
            >
              {item.name}
            </Text>
            <Text
              color="rgba(255, 255, 255, 0.5)"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="600"
            >
              {item.label}
            </Text>
          </Flex>
        ))}
        <Flex flexWrap="wrap">
          {(soundkitDetail?.tags || []).map((item) => (
            <Box
              background="#2E2E32"
              border="1px solid #464649"
              padding="0px 7px"
              mr="5px"
              borderRadius="4px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb="5px"
              _hover={{
                background: "#454C22",
                color: "#d4f70e",
                border: "1px solid #d4f70e",
              }}
            >
              <Text
                fontSize="16px"
                fontWeight="500"
                fontFamily="Cera Pro Regular"
              >
                {item}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
      {soundkitDetail?.collaborators?.length > 0 && (
        <Box
          background="rgba(36, 37, 42, 0.3)"
          padding={{ base: "25px 10px", xl: "25px 35px" }}
          borderRadius="8px"
        >
          <Text fontSize="16px" lineHeight="19px" fontWeight="600" mb="16px">
            {t("collaborators")}
          </Text>
          {(soundkitDetail?.collaborators || []).map(
            (item: CollaborationsModel) => (
              <Link to={`/user/${item?.user?.name}`}>
                <Flex alignItems="center" mb="20px">
                  <Image
                    w={"48px"}
                    h={"48px"}
                    src={item?.user.profileImage}
                    fallbackSrc={AvatarUser}
                    borderRadius="full"
                    cursor="pointer"
                  />
                  <Box ml="16px">
                    <Text
                      _hover={{
                        textDecor: "underline",
                      }}
                      fontSize="16px"
                      lineHeight="16px"
                      fontWeight="600"
                    >
                      {item?.user.name}
                    </Text>
                    <Text
                      fontSize="13px"
                      lineHeight="24px"
                      fontWeight="400"
                      opacity={0.5}
                    >
                      {collaborationRole(item.role)}
                    </Text>
                  </Box>
                </Flex>
              </Link>
            )
          )}
        </Box>
      )}
    </Box>
  );
}
