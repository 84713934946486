import React from "react";

const EmptyPlaylistIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
    >
      <g opacity="0.5" clip-path="url(#clip0_6483_42990)">
        <path
          d="M49.3129 20.8988V9.27733C49.3129 8.60805 48.9741 7.98395 48.4127 7.61961C47.8513 7.25506 47.1436 7.19942 46.5323 7.47184L29.9958 14.8368C29.297 15.1479 28.8707 15.8146 28.8298 16.5286C28.8258 16.491 28.823 16.8914 28.823 16.8914V39.487C27.723 38.9142 26.4645 38.61 25.1352 38.61C22.5259 38.61 19.8736 39.766 17.8583 41.7815C14.0718 45.5685 13.6258 51.284 16.8639 54.5224C18.2675 55.9261 20.2082 56.6991 22.3283 56.6991C24.9375 56.6991 27.5896 55.5433 29.6052 53.528C31.3881 51.7451 32.4982 49.4794 32.7313 47.1482C32.7674 46.7859 32.7763 46.0378 32.7763 46.0174V29.5313L48.1389 22.7053C48.8528 22.388 49.3129 21.6801 49.3129 20.8988ZM28.7977 46.7548C28.6553 48.1806 27.9491 49.5932 26.8098 50.7325C25.5302 52.0121 23.8965 52.7459 22.3283 52.7459C21.5756 52.7459 20.5013 52.5692 19.6592 51.7269C17.9623 50.0299 18.4085 46.8222 20.6538 44.5767C21.9334 43.2971 23.5667 42.5632 25.1353 42.5632C25.8877 42.5632 26.962 42.7399 27.8043 43.5825C28.5726 44.3507 28.9254 45.4774 28.7977 46.7548ZM45.3596 19.6142L32.7763 25.2054V17.9258L45.3596 12.3216V19.6142Z"
          fill="white"
        />
        <path
          d="M57.9408 0H6.05929C2.71812 0 0 2.71822 0 6.05929V57.9407C0 61.2819 2.71822 64 6.05929 64H57.9407C61.2817 64 63.9999 61.2818 63.9999 57.9407V6.05929C64 2.71822 61.2818 0 57.9408 0ZM60.0467 57.9407C60.0467 59.102 59.1022 60.0467 57.9408 60.0467H6.05929C4.89799 60.0467 3.95326 59.1019 3.95326 57.9407V6.05929C3.95326 4.89799 4.89809 3.95326 6.05929 3.95326H57.9407C59.1021 3.95326 60.0466 4.89809 60.0466 6.05929V57.9407H60.0467Z"
          fill="white"
        />
        <path
          d="M10.1489 15.9063H18.9752C20.0667 15.9063 20.9518 15.0214 20.9518 13.9297C20.9518 12.8381 20.0667 11.9531 18.9752 11.9531H10.1489C9.05738 11.9531 8.17236 12.8381 8.17236 13.9297C8.17226 15.0214 9.05717 15.9063 10.1489 15.9063Z"
          fill="white"
        />
        <path
          d="M20.9516 23.3545C20.9516 22.2629 20.0665 21.3779 18.975 21.3779H10.1489C9.05738 21.3779 8.17236 22.2629 8.17236 23.3545C8.17236 24.4463 9.05738 25.3311 10.1489 25.3311H18.9752C20.0665 25.3312 20.9516 24.4463 20.9516 23.3545Z"
          fill="white"
        />
        <path
          d="M53.851 38.6689H45.0244C43.9329 38.6689 43.0479 39.554 43.0479 40.6455C43.0479 41.7371 43.9329 42.6221 45.0244 42.6221H53.851C54.9425 42.6221 55.8276 41.7371 55.8276 40.6455C55.8276 39.554 54.9425 38.6689 53.851 38.6689Z"
          fill="white"
        />
        <path
          d="M53.851 48.0938H45.0244C43.9329 48.0938 43.0479 48.9788 43.0479 50.0703C43.0479 51.1619 43.9329 52.0469 45.0244 52.0469H53.851C54.9425 52.0469 55.8276 51.1619 55.8276 50.0703C55.8276 48.9789 54.9425 48.0938 53.851 48.0938Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6483_42990">
          <rect width="64" height="64" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default EmptyPlaylistIcon;
