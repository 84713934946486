import React from "react";

interface TrackStemsProps {
  color?: string;
}

const TrackStems = ({ color = "white" }: TrackStemsProps) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.55172 2.44824H2.89655C2.21214 2.44824 1.65517 3.00521 1.65517 3.68962V13.8276H1.24138C0.556965 13.8276 0 14.3845 0 15.0689V16.7241C0 17.4085 0.556965 17.9655 1.24138 17.9655H1.65517V20.3105C1.65517 20.9949 2.21214 21.5518 2.89655 21.5518H4.55172C5.23614 21.5518 5.7931 20.9949 5.7931 20.3105V17.9655H6.2069C6.89131 17.9655 7.44828 17.4085 7.44828 16.7241V15.0689C7.44828 14.3845 6.89131 13.8276 6.2069 13.8276H5.7931V3.68962C5.7931 3.00521 5.23614 2.44824 4.55172 2.44824ZM2.48276 3.68962C2.48276 3.46162 2.66814 3.27583 2.89655 3.27583H4.55172C4.78014 3.27583 4.96552 3.46162 4.96552 3.68962V13.8276H2.48276V3.68962ZM4.96552 20.31C4.96552 20.5385 4.78014 20.7238 4.55172 20.7238H2.89655C2.66814 20.7238 2.48276 20.5385 2.48276 20.31V17.9651H4.96552V20.31ZM6.62069 15.0689V16.7241C6.62069 16.9521 6.43531 17.1379 6.2069 17.1379H5.37931H2.06897H1.24138C1.01297 17.1379 0.827586 16.9521 0.827586 16.7241V15.0689C0.827586 14.8409 1.01297 14.6551 1.24138 14.6551H2.06897H5.37931H6.2069C6.43531 14.6551 6.62069 14.8409 6.62069 15.0689Z"
        fill={color}
      />
      <path
        d="M12.8276 2.44824H11.1724C10.488 2.44824 9.93105 3.00521 9.93105 3.68962V9.68962H9.51726C8.83284 9.68962 8.27588 10.2466 8.27588 10.931V12.5862C8.27588 13.2706 8.83284 13.8276 9.51726 13.8276H9.93105V20.3105C9.93105 20.9949 10.488 21.5518 11.1724 21.5518H12.8276C13.512 21.5518 14.069 20.9949 14.069 20.3105V13.8276H14.4828C15.1672 13.8276 15.7242 13.2706 15.7242 12.5862V10.931C15.7242 10.2466 15.1672 9.68962 14.4828 9.68962H14.069V3.68962C14.069 3.00521 13.512 2.44824 12.8276 2.44824ZM10.7586 3.68962C10.7586 3.46162 10.944 3.27583 11.1724 3.27583H12.8276C13.056 3.27583 13.2414 3.46162 13.2414 3.68962V9.68962H10.7586V3.68962ZM13.2414 20.31C13.2414 20.5385 13.056 20.7238 12.8276 20.7238H11.1724C10.944 20.7238 10.7586 20.5385 10.7586 20.31V13.8276H13.2414V20.31ZM14.8966 10.931V12.5862C14.8966 12.8142 14.7112 13 14.4828 13H13.6552H10.3448H9.51726C9.28884 13 9.10347 12.8142 9.10347 12.5862V10.931C9.10347 10.703 9.28884 10.5172 9.51726 10.5172H10.3448H13.6552H14.4828C14.7112 10.5172 14.8966 10.703 14.8966 10.931Z"
        fill={color}
      />
      <path
        d="M22.7587 12.5862H22.3449V3.68962C22.3449 3.00521 21.7879 2.44824 21.1035 2.44824H19.4483C18.7639 2.44824 18.2069 3.00521 18.2069 3.68962V12.5862H17.7931C17.1087 12.5862 16.5518 13.1431 16.5518 13.8276V15.4827C16.5518 16.1671 17.1087 16.7241 17.7931 16.7241H18.2069V20.3105C18.2069 20.9949 18.7639 21.5518 19.4483 21.5518H21.1035C21.7879 21.5518 22.3449 20.9949 22.3449 20.3105V16.7241H22.7587C23.4431 16.7241 24 16.1671 24 15.4827V13.8276C24 13.1427 23.4431 12.5862 22.7587 12.5862ZM19.0345 3.68962C19.0345 3.46162 19.2199 3.27583 19.4483 3.27583H21.1035C21.3319 3.27583 21.5173 3.46162 21.5173 3.68962V12.5862H19.0345V3.68962ZM21.5173 20.31C21.5173 20.5385 21.3319 20.7238 21.1035 20.7238H19.4483C19.2199 20.7238 19.0345 20.5385 19.0345 20.31V16.7237H21.5173V20.31ZM23.1724 15.4827C23.1724 15.7107 22.9871 15.8965 22.7587 15.8965H21.9311H18.6207H17.7931C17.5647 15.8965 17.3793 15.7107 17.3793 15.4827V13.8276C17.3793 13.5996 17.5647 13.4138 17.7931 13.4138H18.6207H21.9311H22.7587C22.9871 13.4138 23.1724 13.5996 23.1724 13.8276V15.4827Z"
        fill={color}
      />
    </svg>
  );
};
export default TrackStems;
