import axiosService from "services/axios.service";
import { RecentAction } from "app/models";
import { getData } from "utils/getData";
import getOptions from "utils/getOptions";
import ToastPlanSubscription from "app/components/toast/toastplan";

class RecentActionsService {
  static async getUserRecentActions(
    page = 1,
    filterType = "",
    userId = null,
    loggedAsGuest = false
  ): Promise<any> {
    try {
      const options = { ...getOptions(page, filterType), userId };
      const res = await axiosService.get(
        loggedAsGuest ? "/guest/recent-actions" : "/recent-actions",
        {
          params: options,
        }
      );

      const items = (res?.data?.data || []).map((d: RecentAction) => {
        return getData(d);
      });

      return {
        items,
        hasMoreItems: items.length === 25,
      };
    } catch (e: any) {
      switch (e?.response?.status) {
        case 401:
          const message = e.response.data.message;
          ToastPlanSubscription({ description: message });
          throw new Error(message);
        default:
          ToastPlanSubscription({});
          throw new Error("Server Error");
      }
    }
  }
}
export default RecentActionsService;
