import { Box, Flex, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { ChevronDownIcon } from "@chakra-ui/icons";

interface SkeletonLeftProfileProps {
  isFilter?: boolean;
  isUser?: boolean;
}

export default function SkeletonLeftProfilePage({
  isFilter = false,
  isUser = false,
}: SkeletonLeftProfileProps) {
  return (
    <Box>
      <Box
        display={{ base: "none", md: "flex" }}
        bg="linear-gradient(180deg, rgba(255, 255, 255, 0.125) 0%, rgba(255, 255, 255, 0) 100%)"
        mt="25px"
        padding="0px 20px"
        mb="15px"
        borderRadius="8px"
        justifyContent="center"
        flexDirection="column"
        alignItems="center"
      >
        <Skeleton mt="40px" mb="23px" w="120px" h="120px" borderRadius="full" />
        <Skeleton w="87px" h="23px" />
        <Skeleton w="132px" h="13px" />
        <Box mt="30px" mb="30px">
          <Skeleton w="278px" h="13px" mr="0px" />
          <Skeleton w="278px" h="13px" mr="0px" />
        </Box>
        <Flex justifyContent="space-between" gap="10px">
          {Array.from({ length: 3 }).map((i, k) => (
            <Flex flexDirection="column" alignItems="center" key={k}>
              <Skeleton w="87px" h="23px" mr="0px" />
              <Skeleton w="70px" h="13px" mr="0px" />
            </Flex>
          ))}
        </Flex>

        {!isUser && (
          <Flex
            w="100%"
            mt="30px"
            mb="20px"
            justifyContent="space-between"
            gap="10px"
          >
            <Skeleton w="50%" h="48px" borderRadius="4px" mr="0px" />
            <Skeleton w="50%" h="48px" borderRadius="4px" mr="0px" />
          </Flex>
        )}

        <Skeleton
          mt={isUser ? "20px" : "0px"}
          w="70%"
          h="48px"
          borderRadius="4px"
          mb="20px"
        />
      </Box>
      {/* mobile */}
      <Box p="16px 16px 0px 16px" display={{ base: "block", md: "none" }}>
        <Flex gap="20px">
          <Skeleton w="90px" h="90px" borderRadius="full" />
          <Box>
            <Skeleton w="87px" h="24px" mb="8px" />
            <Skeleton w="132px" h="16px" mb="16px" />
            <Skeleton w="132px" h="16px" />
            <Skeleton w="87px" h="16px" />
          </Box>
        </Flex>
        <Flex justifyContent="center" gap="20px" mt="30px">
          {Array.from({ length: 3 }).map((i, k) => (
            <Flex flexDirection="column" alignItems="center" key={k}>
              <Skeleton w="80px" h="16px" mr="0px" />
              <Skeleton w="40px" h="12px" mr="0px" />
            </Flex>
          ))}
        </Flex>
        {!isUser && (
          <Flex w="100%" mt="20px" mb="20px" justifyContent="center" gap="10px">
            <Skeleton w="30%" h="32px" borderRadius="4px" mr="0px" />
            <Skeleton w="30%" h="32px" borderRadius="4px" mr="0px" />
          </Flex>
        )}
        <Flex justifyContent="center">
          <Skeleton
            mt={isUser ? "20px" : "0px"}
            w="40%"
            h="32px"
            borderRadius="4px"
            mb="20px"
          />
        </Flex>
      </Box>

      {isFilter && (
        <Box
          background="rgba(36, 37, 42, 0.30)"
          padding="20px 25px"
          display={{ base: "none", md: "block" }}
        >
          <Flex justifyContent="space-between" alignItems="center" mb="20px">
            <Skeleton w="150px" h="23px" mr="0px" />
            <Skeleton w="45px" h="13px" mr="0px" />
          </Flex>
          {Array.from({ length: 5 }).map((item, index) => (
            <>
              <Flex
                justifyContent="space-between"
                padding="25px 0px"
                key={index}
              >
                <Flex>
                  <Skeleton w="23px" h="13px" borderRadius="2px" />
                  <Skeleton w="76px" h="13px" />
                </Flex>
                <ChevronDownIcon color="#656566" />
              </Flex>
              <Divider borderColor="#656566" />
            </>
          ))}
        </Box>
      )}
    </Box>
  );
}
