import React from "react";

const Message = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0001 7H6.00011C5.73489 7 5.48054 7.10536 5.293 7.29289C5.10547 7.48043 5.00011 7.73478 5.00011 8C5.00011 8.26522 5.10547 8.51957 5.293 8.70711C5.48054 8.89464 5.73489 9 6.00011 9H16.0001C16.2653 9 16.5197 8.89464 16.7072 8.70711C16.8948 8.51957 17.0001 8.26522 17.0001 8C17.0001 7.73478 16.8948 7.48043 16.7072 7.29289C16.5197 7.10536 16.2653 7 16.0001 7ZM12.0001 11H6.00011C5.73489 11 5.48054 11.1054 5.293 11.2929C5.10547 11.4804 5.00011 11.7348 5.00011 12C5.00011 12.2652 5.10547 12.5196 5.293 12.7071C5.48054 12.8946 5.73489 13 6.00011 13H12.0001C12.2653 13 12.5197 12.8946 12.7072 12.7071C12.8948 12.5196 13.0001 12.2652 13.0001 12C13.0001 11.7348 12.8948 11.4804 12.7072 11.2929C12.5197 11.1054 12.2653 11 12.0001 11ZM11.0001 0C9.68689 0 8.38653 0.258658 7.17328 0.761205C5.96002 1.26375 4.85763 2.00035 3.92904 2.92893C2.05368 4.8043 1.00011 7.34784 1.00011 10C0.991368 12.3091 1.7909 14.5485 3.26011 16.33L1.26011 18.33C1.12135 18.4706 1.02736 18.6492 0.989982 18.8432C0.952608 19.0372 0.973531 19.2379 1.05011 19.42C1.13317 19.5999 1.26781 19.7511 1.43696 19.8544C1.6061 19.9577 1.80211 20.0083 2.00011 20H11.0001C13.6523 20 16.1958 18.9464 18.0712 17.0711C19.9465 15.1957 21.0001 12.6522 21.0001 10C21.0001 7.34784 19.9465 4.8043 18.0712 2.92893C16.1958 1.05357 13.6523 0 11.0001 0ZM11.0001 18H4.41011L5.34011 17.07C5.52636 16.8826 5.6309 16.6292 5.6309 16.365C5.6309 16.1008 5.52636 15.8474 5.34011 15.66C4.0307 14.352 3.21528 12.6305 3.0328 10.7888C2.85032 8.94705 3.31205 7.09901 4.33934 5.55952C5.36662 4.02004 6.8959 2.88436 8.66663 2.34597C10.4374 1.80759 12.34 1.8998 14.0503 2.60691C15.7607 3.31402 17.173 4.59227 18.0465 6.22389C18.9201 7.85551 19.201 9.73954 18.8412 11.555C18.4815 13.3705 17.5034 15.005 16.0736 16.1802C14.6439 17.3554 12.8509 17.9985 11.0001 18Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default Message;
