import {
  Button,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface ModalProps {
  isOpen?: boolean;
  handleClick?: any;
}

export default function ModalEditLicense({
  isOpen = false,
  handleClick,
}: ModalProps) {
  const { t } = useTranslation();
  return (
    <Modal
      colorScheme="black"
      isOpen={isOpen}
      onClose={() => handleClick("edit")}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          fontSize="32px"
          display="flex"
          mt="20px"
          justifyContent="center"
          p="16px 24px 0px"
        >
          {t("editLicense")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => handleClick("edit")}
          right={2}
          top="25px"
          fontSize="22px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalBody display="flex" justifyContent="center">
          <Text>{t("youWillNotChange")}</Text>
        </ModalBody>

        <ModalFooter mb="20px">
          <Flex justifyContent="center" w="full">
            <Button
              backgroundColor="#212125"
              borderRadius="4px"
              mr={3}
              onClick={() => handleClick("edit")}
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
            >
              {t("cancel")}
            </Button>
            <Button
              backgroundColor="#D3F70E"
              color="black"
              borderRadius="4px"
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
            >
              {t("Edit")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
