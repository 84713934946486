import { Box, Text } from "@chakra-ui/react";
import TextInput from "app/components/TextInput";
import React from "react";
import { useFormContext } from "react-hook-form";
import "./styles.scss";

interface ICustomInputProps {
  title: string;
  name: string;
  placeholder?: string;
  type?: string;
}

export default function CustomInput({
  title,
  name,
  placeholder = "0",
  type = "text",
  ...props
}: ICustomInputProps) {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box w={{ base: "100%", md: "90%" }} className="discount-input">
      <Text pb="10px" mt={{ base: "20px", md: "0px" }}>
        {title}
      </Text>
      <TextInput
        type={type}
        name={name}
        placeholder={placeholder}
        border="none"
        borderRadius="4px"
        background="#24252A"
        w="98%"
        labelClassName="label-add-track"
        _placeholder={{ color: "#535353" }}
        errors={errors}
        control={control}
        {...props}
      />
    </Box>
  );
}
