import { Box, Flex, Text } from "@chakra-ui/react";
import { Back } from "app/assets/icons";
import { Licenses } from "app/models";
import { cartLicensesSelector, licenseReview } from "app/redux/selectors/cart";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import ExclusiveLicense from "./ExclusiveLicense";
import NonExclusiveLicense from "./NonExclusiveLicense";
import "./styles.scss";

export default function LicenseReview() {
  const { t } = useTranslation();
  const id: string = useParams();
  const history = useHistory();
  const track = useSelector(licenseReview(id.id));

  const cartLicenses = useSelector(cartLicensesSelector);

  const currentLicense = useMemo(() => {
    return cartLicenses.find((item: Licenses) => item.trackId === track?._id);
  }, [cartLicenses, track?._id]);

  const redirectCart = useCallback(() => {
    history.push("/cart");
  }, [history]);

  return (
    <Box
      p={{ base: "0px 20px", md: "20px 40px", xl: "40px 80px" }}
      className="license-review"
    >
      <Flex alignItems="center" mb="32px">
        <Box cursor="pointer" className="vector-button" onClick={redirectCart}>
          <Back />
        </Box>
        <Text fontSize={{ base: "24px", md: "32px" }}>
          {t("licenseReview")}
        </Text>
      </Flex>
      {currentLicense?.license?.exclusive ? (
        <ExclusiveLicense track={track} license={currentLicense?.license} />
      ) : (
        <NonExclusiveLicense track={track} license={currentLicense?.license} />
      )}
    </Box>
  );
}
