import { Box, Flex, Text } from "@chakra-ui/react";
import algoliasearch from "algoliasearch";
import queryString from "query-string";
import React, { useMemo, useState } from "react";
import { Configure, InstantSearch } from "react-instantsearch";
import { useLocation } from "react-router-dom";
import { indexNameAlgolia } from "utils/actionText";
import FilterSearch from "./FilterSearch/filterSearch";
import ResultsComponents from "./ResultsComponents";
import SkeletonFilterSearch from "./skeltonFilterSearch";
import ModalFilterSearch from "./FilterSearch/modalFilterSearch";
import { useTranslation } from "react-i18next";
import IconFilterSearch from "app/assets/icons/IconFilterSearch";

function SearchPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParamsParsed = queryString.parse(location.search);
  const searchType = queryParamsParsed.type || "";
  const [isLoading, setIsLoading] = useState(true);
  const [showModalFilter, setShowModalFilter] = useState(false);

  const searchClient = useMemo(
    () =>
      algoliasearch(
        process.env.REACT_APP_APPLICATION_ID_ALGOLIA as string,
        process.env.REACT_APP_API_KEY_ALGOLIA as string
      ),
    []
  );

  const indexName = useMemo(
    () => indexNameAlgolia(searchType as string),
    [searchType]
  );
  const typeParams = queryString.parse(location.search)?.type;

  return (
    <InstantSearch
      key={indexName}
      searchClient={searchClient}
      indexName={indexName}
      future={{
        preserveSharedStateOnUnmount: true,
      }}
    >
      <Configure hitsPerPage={15} />
      <Box padding={{ base: "20px 16px", xl: "40px 20px 40px 80px" }}>
        <Flex
          w="100%"
          flexWrap="nowrap"
          gridGap={{ base: "20px", xl: "30px" }}
          display={{ base: "block", md: "flex" }}
        >
          <Box
            display={{ base: "none", md: "block" }}
            w={{ base: "100%", md: "35%", xl: "30%" }}
            mt={{ md: "20px" }}
          >
            {isLoading ? (
              <SkeletonFilterSearch />
            ) : (
              <>
                <Box
                  position="sticky"
                  top="120px"
                  overflow="scroll"
                  height={{ base: "30vh", md: "70vh" }}
                >
                  <FilterSearch typeParams={typeParams} />
                </Box>
              </>
            )}
          </Box>
          <Flex
            alignItems="center"
            display={{ base: "flex", md: "none" }}
            backgroundColor="#18181c"
            position="sticky"
            top="94px"
            zIndex={90}
            onClick={() => setShowModalFilter(true)}
            padding="5px 0px 2px 0px"
          >
            <IconFilterSearch />
            <Text ml="10px">{t("filter")}</Text>
          </Flex>
          <Box w={{ base: "100%", md: "65%", xl: "70%" }}>
            <ResultsComponents
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Box>

          <Box display={showModalFilter ? "block" : "none"}>
            <ModalFilterSearch
              typeParams={typeParams}
              setShowModal={setShowModalFilter}
            />
          </Box>
        </Flex>
      </Box>
    </InstantSearch>
  );
}
export default React.memo(SearchPage);
