import React from "react";

const PlaylistHomePage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="M1.08398 5.95801H8.66732"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08398 9.20801H7.04232"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.08398 2.70801H10.834"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.834 10.0205C10.834 10.4692 10.4702 10.833 10.0215 10.833C9.57277 10.833 9.20898 10.4692 9.20898 10.0205C9.20898 9.57179 9.57277 9.20801 10.0215 9.20801C10.4702 9.20801 10.834 9.57179 10.834 10.0205Z"
        fill="#D4F70E"
      />
      <path
        d="M10.834 10.0212V5.74199C10.834 5.56248 10.9795 5.41699 11.159 5.41699H11.9173M10.834 10.0212C10.834 10.4699 10.4702 10.8337 10.0215 10.8337C9.57277 10.8337 9.20898 10.4699 9.20898 10.0212C9.20898 9.57244 9.57277 9.20866 10.0215 9.20866C10.4702 9.20866 10.834 9.57244 10.834 10.0212Z"
        stroke="#D4F70E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default PlaylistHomePage;
