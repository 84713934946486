import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";

export const getMegaByteAndDuration = (file, type?: string) => {
  return new Promise((resolve, reject) => {
    if (type === FILE_UPLOAD_TYPE.ZIP) {
      const fileSizeInBytes = file.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      const fileSizeInGB = fileSizeInMB / 1024; // Chuyển từ MB sang GB
      const result = {
        fileSizeInGB: Math.round(fileSizeInGB),
      };
      resolve(result);
    } else {
      const audioElement = document.createElement("audio");
      audioElement.src = URL.createObjectURL(file);

      audioElement.addEventListener("loadedmetadata", () => {
        const durationInSeconds = audioElement.duration;
        const fileSizeInBytes = file.size;
        const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
        const fileSizeInGB = fileSizeInMB / 1024; // Chuyển từ MB sang GB

        const result = {
          duration: durationInSeconds,
          fileSize: Math.round(fileSizeInMB),
          fileSizeInGB: Math.round(fileSizeInGB),
        };

        resolve(result);
      });
      audioElement.addEventListener("error", () => {
        reject("Error occurred while loading the audio file.");
      });
    }
  });
};
