import React from "react";

interface CartProps {
  fill?: string;
}
const Cart = ({ fill = "#D4F70E" }: CartProps) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.9335 4.91093C10.0029 8.9997 9.37477 8.9997 9 8.9997H5.30769C4.01446 8.9997 3.02354 8.23908 2.78308 7.06124C2.77569 7.02524 2.04462 3.43678 1.85769 2.43431C1.76585 1.94278 1.53231 1.61508 0.692308 1.61508C0.309692 1.61508 0 1.30539 0 0.922776C0 0.540161 0.309692 0.230469 0.692308 0.230469C2.08615 0.230469 2.98338 0.922776 3.21877 2.18001C3.40385 3.17185 4.13261 6.74832 4.14 6.78478C4.28723 7.50755 4.93477 7.61555 5.30769 7.61555H8.81769C9.19477 7.21031 10.0542 5.64893 10.6814 4.3197C10.8448 3.97401 11.2578 3.82678 11.6031 3.98924C11.9492 4.15216 12.0969 4.56478 11.9335 4.91093ZM4.84615 9.46124C4.20877 9.46124 3.69231 9.9777 3.69231 10.6151C3.69231 11.2525 4.20877 11.7689 4.84615 11.7689C5.48354 11.7689 6 11.2525 6 10.6151C6 9.9777 5.48354 9.46124 4.84615 9.46124ZM9 9.46124C8.36261 9.46124 7.84615 9.9777 7.84615 10.6151C7.84615 11.2525 8.36261 11.7689 9 11.7689C9.63738 11.7689 10.1538 11.2525 10.1538 10.6151C10.1538 9.9777 9.63738 9.46124 9 9.46124ZM6.76754 5.69693C6.81369 5.73432 6.87046 5.75232 6.92723 5.75232C6.984 5.75232 7.03985 5.73432 7.08692 5.69693C7.13308 5.66047 8.39215 4.43231 9.07892 3.41047C9.132 3.33108 9.13708 3.22955 9.09184 3.14555C9.048 3.06201 8.96031 3.00939 8.86569 3.00939H7.83831C7.83831 3.00939 7.73815 1.02893 7.67261 0.937084C7.57754 0.801392 7.18477 0.709084 6.92677 0.709084C6.66785 0.709084 6.26815 0.801392 6.17261 0.937084C6.10754 1.02847 6.02123 3.00939 6.02123 3.00939H4.98046C4.88538 3.00939 4.79815 3.06201 4.75338 3.14555C4.70861 3.22955 4.71369 3.33155 4.76631 3.41047C5.45354 4.43278 6.72185 5.66047 6.76754 5.69693Z"
        fill={fill}
      />
    </svg>
  );
};
export default Cart;
