import {
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

interface IModalActionBulkOfferProps {
  isOpen: boolean;
  isEdit: boolean;
  isPromocodeSelected: boolean;
  setOpen: (value: boolean) => void;
  onActionClick: () => void;
}

export default function ModalActionBulkOffer({
  isOpen,
  isPromocodeSelected,
  isEdit,
  setOpen,
  onActionClick,
}: IModalActionBulkOfferProps) {
  const { t } = useTranslation();

  const renderTitle = useCallback(() => {
    if (isPromocodeSelected) {
      return isEdit ? t("EditPromoCode") : t("DeletePromoCode");
    }
    return isEdit ? t("EditOffer") : t("DeleteOffer");
  }, [isPromocodeSelected, isEdit, t]);

  return (
    <Modal isOpen={isOpen} onClose={() => setOpen(false)} isCentered>
      <ModalOverlay />
      <ModalContent py="20px" mx="10px">
        <ModalHeader p={{ base: "20px 12px", md: "0px" }}>
          <Flex flexDirection="column" alignItems="center">
            <Text
              fontSize={{ base: "24px", md: "28px", xl: "32px" }}
              lineHeight="24px"
              mx="12px"
            >
              {renderTitle()}
            </Text>
            <Text
              py={{
                base: isEdit ? "10px" : "12px",
                md: isEdit ? "15px" : "20px",
              }}
              fontSize={{ base: "14px", md: "16px" }}
              color="#FEFFFF"
              lineHeight="24px"
              maxWidth="60%"
              textAlign="center"
            >
              {isEdit ? t("resetItBack") : t("deleteThisOffer")}
            </Text>
          </Flex>
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => setOpen(false)}
          right={2}
          top={{ base: "10px", md: "15px" }}
          fontSize={{ base: "20px", md: "22px", xl: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalFooter p="0px" justifyContent="center" alignSelf="center">
          <Flex justifyContent="center">
            <Button
              _hover={{
                background: "black",
              }}
              color="white"
              bg="black"
              fontSize="14px"
              fontWeight="500"
              borderRadius="4px"
              mr={3}
              onClick={() => setOpen(false)}
              height="50px"
              w="150px"
              _active={{ background: "black" }}
            >
              {t("cancel")}
            </Button>
            <Button
              _hover={
                isEdit ? { background: "#34391A" } : { background: "#ED3636" }
              }
              _active={
                isEdit ? { background: "#34391A" } : { background: "#ED3636" }
              }
              color={isEdit ? "#D4F70E" : "black"}
              bg={isEdit ? "#34391A" : "#ED3636"}
              fontSize="14px"
              fontWeight="500"
              borderRadius="4px"
              height="48px"
              w="150px"
              onClick={onActionClick}
              border={isEdit ? "1px solid #D4F70E" : "none"}
            >
              {isEdit ? t("Edit") : t("delete")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
