import { Box, Flex, Text } from "@chakra-ui/react";
import { PlayAnalyticsModel } from "app/models";
import { timeType } from "constants/analyticsType";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import HighChartsComponnent from "../HighCharts";
import "./styles.scss";

interface ExtendedOverviewProps {
  analyticsType: PlayAnalyticsModel;
  totalAnalytics?: number;
  timeDay: string;
}

function ExtendedOverview({
  analyticsType,
  totalAnalytics,
  timeDay,
}: ExtendedOverviewProps) {
  const { t } = useTranslation();

  const convertTimeDate = useCallback(() => {
    const time = {
      [timeType.DAY]: t("today"),
      [timeType.WEEK]: t("week"),
      [timeType.MONTH]: t("month"),
      [timeType.YEAR]: t("year"),
    };

    return time[timeDay];
  }, [timeDay, t]);

  return (
    <>
      <Box>
        <Flex direction={{ base: "column", md: "row" }} alignItems="center">
          <Text
            fontSize={{ base: "22px", xl: "24px" }}
            mr={{ base: "0px", md: "30px" }}
            className="fontCeraPro"
          >
            {t("extended")}
          </Text>
          <Flex alignItems="center" mt={{ base: "12px", md: "0px" }}>
            <Box display={{ base: "none", md: "block" }}>
              {analyticsType.icon}
            </Box>
            <Text color="#777779" ml="10px" mr="10px">
              {t("total")} {analyticsType.name} {t("forThe")}{" "}
              {convertTimeDate()}:
            </Text>
            {totalAnalytics}
          </Flex>
        </Flex>

        <HighChartsComponnent analyticsType={analyticsType} timeDay={timeDay} />
      </Box>
    </>
  );
}

export default React.memo(ExtendedOverview);
