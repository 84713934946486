import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import TabsMenuHeader from "app/components/AddTrack/TabHeader";
import SelectInput from "app/components/SelectInput";
import { Licenses } from "app/models";
import { NewPromoCodeState } from "app/redux/reducers/promoCode";
import React, { useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ApplyLicenses from "../CreateBulkOffer/ApplyLicenses";
import DiscountInput from "../DiscountInput";
import "./styles.scss";
import { typeMusic } from "utils/actionText";
import { BeatLoader } from "react-spinners";

interface Tabsmenu {
  isCheck: boolean;
  name: string;
  id: number;
}

interface ICreatePromoCodeProps {
  licenses?: Licenses[];
  onAddPromoCode: (values: NewPromoCodeState) => void;
  isLoading?: boolean;
  idEdit?: boolean;
  tabsMenu: Tabsmenu[];
}

export default function CreatePromoCode({
  tabsMenu = [],
  licenses = [],
  isLoading,
  idEdit,
  onAddPromoCode,
}: ICreatePromoCodeProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useFormContext();
  const { nonExpiring, productType } = watch();
  const checkTypePromoCode = useMemo(() => {
    return productType === typeMusic.TRACK;
  }, [productType]);

  const PROMOCODE_TYPE = [
    {
      value: "TRACK",
      label: t("track"),
    },
    {
      value: "SOUNDKIT",
      label: t("soundPack"),
    },
  ];

  return (
    <form onSubmit={handleSubmit(onAddPromoCode)}>
      <Box position="relative" className="create-bulkoffer">
        <Flex
          justifyContent="space-between"
          display={{ base: "none", md: "flex" }}
        >
          <TabsMenuHeader tabsMenu={tabsMenu} />
          <Box>
            <Button
              height="50px"
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              type="submit"
              isLoading={isLoading}
              spinner={<BeatLoader size={8} color="white" />}
            >
              {idEdit ? t("Edit") : t("Create")}
            </Button>
            <Button
              height="50px"
              ml="8px"
              _hover={{ background: "black" }}
              bg="rgba(255, 255, 255, 0.04)"
              onClick={() => history.push("/dashboard/discounts")}
            >
              {t("cancel")}
            </Button>
          </Box>
        </Flex>

        <Flex
          w="max-content"
          mt="30px"
          alignItems="center"
          mb="32px"
          ml={{ base: "0px", md: "10px" }}
          gap="20px"
          cursor="pointer"
          onClick={() => {
            history.push({
              pathname: "/dashboard/discounts",
            });
          }}
        >
          <Flex
            border="1px solid rgba(255, 255, 255, 0.04)"
            w="50px"
            h="50px"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <Back />
          </Flex>

          <Text fontSize="16px" lineHeight="20px" fontWeight="500">
            {t("Back")}
          </Text>
        </Flex>
        <Text
          fontWeight="500"
          fontSize={{ base: "24px", md: "26px" }}
          lineHeight="30px"
          w={{ base: "100%", md: "79%" }}
          pb="30px"
          borderBottom="1px solid rgba(255, 255, 255, 0.04)"
        >
          {!idEdit ? t("createPromo") : t("editPromo")}
        </Text>
        <Flex
          mt="20px"
          w={{ base: "100%", md: "80%" }}
          display={{ base: "block", md: "flex" }}
        >
          <DiscountInput
            title={t("PromoCode")}
            placeholder={t("enterPromoCode")}
            name={"promoCode"}
          />
          <DiscountInput
            title={t("Percent")}
            placeholder="Enter 1% - 50%"
            name={"percent"}
          />
        </Flex>
        <Flex
          mt="20px"
          w={{ base: "100%", md: "80%" }}
          display={{ base: "block", md: "flex" }}
        >
          <Box w={{ base: "100%", md: "90%" }} className="track-title">
            <Controller
              name={"productType"}
              render={({ field }) => (
                <SelectInput
                  labelColor="#FFFFFF"
                  placeholder={t("selectPromoCodeType")}
                  label={t("ProductType")}
                  data={PROMOCODE_TYPE}
                  w="98%"
                  mt="14px"
                  {...field}
                  error={errors?.productType?.message}
                />
              )}
              control={control}
            />
          </Box>
          <DiscountInput
            type={"datetime-local"}
            title={t("ExpirationDate")}
            name={"expirationDate"}
          />
        </Flex>
        <Controller
          name={"nonExpiring"}
          render={({ field }) => (
            <Flex className="switch" pt="24px" alignItems="center">
              <Switch
                isChecked={nonExpiring}
                colorScheme="#D8FD01"
                size="lg"
                mr="25px"
                onChange={(event) =>
                  field.onChange({
                    target: {
                      value: event.target.checked,
                    },
                  })
                }
              />
              <Text color="#535353" fontSize="14px" lineHeight="18px">
                {t("NonExpiring")}
              </Text>
            </Flex>
          )}
          control={control}
        />

        {checkTypePromoCode && (
          <>
            <Text
              pt={{ base: "30px", md: "40px" }}
              fontSize="24px"
              lineHeight="30px"
              fontWeight="500"
              pb="10px"
              borderBottom="1px solid rgba(255, 255, 255, 0.04)"
              mb="30px"
            >
              {t("ApplyPromoCode")}
            </Text>
            <ApplyLicenses licenses={licenses} />
          </>
        )}
      </Box>

      <Flex
        mt="20px"
        display={{ base: "flex", md: "none" }}
        justifyContent="center"
        gap="5px"
      >
        <Button
          height="50px"
          background="#34391A"
          color="#D4F70E"
          border="1px solid #D4F70E"
          _hover={{
            background: "#34391A",
          }}
          w="40%"
          type="submit"
          isLoading={isLoading}
          spinner={<BeatLoader size={8} color="white" />}
        >
          {idEdit ? t("Edit") : t("Create")}
        </Button>
        <Button
          w="40%"
          height="50px"
          ml="8px"
          _hover={{ background: "black" }}
          bg="rgba(255, 255, 255, 0.04)"
          onClick={() => history.push("/dashboard/discounts")}
        >
          {t("cancel")}
        </Button>
      </Flex>
    </form>
  );
}
