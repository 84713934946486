export const TRANSLATIONS_SPA = {
  loopAndSample: "Bucles y Muestras de Terceros",
  thisSample: "¿De dónde obtuviste esta muestra?",
  nameSample: "¿Cuál es el nombre de la muestra/bucle?",
  addSample: "Agregar otro Bucle/Muestra",
  welcome: "Bienvenido al tutorial",
  uploadeTrack: "Agregar Pista",
  addSoundkit: "Agregar Kit de Sonido",
  dashboard: "Tablero",
  home: "Inicio",
  privacy: "Privacidad",
  terms: "Términos",
  contactUs: "Contáctanos",
  profile: "Perfil",
  stats: "Estadísticas",
  analytics: "Analítica",
  myTracks: "Mis Pistas",
  collaborations: "Colaboraciones",
  discounts: "Descuentos",
  payment: "Pagos y Billeteras",
  subscription: "Planes de Suscripción",
  contracts: "Licencias y Contratos",
  negotiations: "Negociaciones",
  tracks: "Pistas",
  today: "Hoy",
  week: "Semana",
  month: "Mes",
  year: "Año",
  addNewTrack: "Puedes agregar una nueva pista",
  openFile: "Abrir archivo o arrastrar",
  uploadNewTrack: "Subir nueva pista",
  showStats: "Mostrar estadísticas completas",
  newArtists: "Nuevos artistas en Superproductor",
  Statistic: "Estadística",
  CustomPeriod: "Período personalizado",
  Week: "Semana",
  Month: "Mes",
  Year: "Año",
  TotalPlays: "Reproducciones totales",
  Top10Countries: "Top 10 países",
  payments: "Pagos y billeteras",
  stripe: "Stripe",
  paypal: "PayPal",
  connect:
    "Conecta tu cuenta de Stripe y comienza a aceptar una amplia variedad de métodos de pago, incluyendo tarjetas de crédito y débito.",
  yourEmail: "Tu correo electrónico",
  connectStripe: "Conectar Stripe",
  connectpaypal:
    "Conecta tu cuenta de PayPal para aceptar pagos con tarjetas de débito y crédito sin esfuerzo.",
  connectPaypal: "Conectar PayPal",
  save: "Guardar",
  TopTrack: "Mejores pistas",
  TopUser: "Mejores",
  Most: "Más:",
  Website: "Sitio web:",
  Period: "Período:",
  extended: "Vista general ampliada",
  totalSales: "Ventas totales:",
  PRICINGPLANS: "PLANES DE PRECIOS",
  flexiblePlan: "Elige el plan perfecto para ti",
  Annually: "Anualmente",
  Monthly: "Mensualmente",
  fullDetails: "Mostrar detalles completos",
  FreeDemoDownloads: "Descargas de demos gratuitas",
  FastPayoutsFunds: "Pagos rápidos de fondos",
  AcceptCreditCardAndPaypal: "Acepta tarjetas de crédito y PayPal",
  UploadSoundKits: "Subir kits de sonido",
  UploadTrackoutStems: "Subir pistas individuales",
  withdraw: "Retirar",
  addNewCard: "Agregar nueva tarjeta",
  myTrack: "Mis pistas",
  released: "Lanzadas",
  purchased: "Compradas",
  favorites: "Favoritas",
  drafts: "Borradores",
  filter: "Filtrar",
  Discounts: "Descuentos",
  PromoCode: "Código promocional",
  noDiscounts: "No tienes descuentos",
  createPromo: "Crear un nuevo código promocional",
  BulkOffer: "Oferta a granel",
  noBulkOffers: "No tienes ofertas a granel",
  NewBulkOffer: "Crear una nueva oferta a granel",
  ApplyPromoCode: "Aplicar código promocional a las siguientes licencias",
  Create: "Crear",
  Cancle: "Cancelar",
  Back: "Volver a los descuentos",
  Percent: "Porcentaje de descuento del 1% al 50% solamente",
  ProductType: "Tipo de producto",
  ExpirationDate: "Fecha de caducidad",
  NonExpiring: "Este es un código promocional sin fecha de caducidad",
  Standard: "Licencia estándar (MP3)",
  Premium: "Licencia premium (MP3) (WAV)",
  Pro: "Pro (MP3) (WAV) (STEMS)",
  NameBulkOffer: "Nombre de la oferta a granel",
  Sample: "Muestra",
  Minimum: "Número mínimo de pistas en el carrito",
  willBeForFree: "Pistas que serán gratuitas",
  bulkDiscountOption: "Crear una nueva opción de descuento a granel",
  bulkDiscount: "Aplicar este descuento a granel a las siguientes licencias",
  licensesContracts: "Licencias y contratos",
  createALicense: "Crear una licencia",
  exclusive: "Exclusiva",
  nonExclusive: "No-exclusiva",
  editLicense: "¿Editar la licencia?",
  youWillNotChange: "Puedes restablecerlo.",
  cancel: "Cancelar",
  done: "Hecho",
  deleteLicense: "¿Eliminar la licencia?",
  notRecoverIt:
    "Esta acción puede causar problemas con la licencia de la pista.",
  delete: "Eliminar",
  createNewLicense: "Crear nueva licencia",
  generalInfo: "Información general",
  description: "Información opcional",
  meataData: "Metadatos",
  contractDetails: "Detalles del contrato",
  address: "Dirección",
  licensesAvailability: "Licencias y disponibilidad",
  collaborators: "Colaboradores",
  backToLicense: "Volver a las licencias",
  name: "Nombre",
  price: "Precio",
  typeOfContract: "Tipo de contrato",
  minimum: "Precio mínimo de negociación (opcional)",
  enableAllow: "Habilitar 'Permitir ofertas'",
  enable: "Habilitar",
  filesToDeliver: "Archivos a entregar",
  includeMp3: "Incluir MP3 (obligatorio)",
  includeWav: "Incluir WAV",
  includeTrackout: "Incluir pistas individuales (STEMS)",
  contractDetail: "Detalles del contrato",
  distribution: "Copias de distribución",
  audioStreams: "Flujos de audio",
  videoStreams: "Flujos de video",
  musicStreams: "Videos musicales",
  paidPerformances: "Actuaciones pagadas",
  profitPerformances: "Actuaciones sin fines de lucro",
  unlimited: "Ilimitado",
  optionInformation: "Información opcional",
  descriptionOption: "Breve descripción (opcional)",
  featureList: "Lista de características",
  separateEach: "Separa cada elemento con un salto de línea",
  reset: "Restablecer",
  broadcastingRight: "Permitir derechos de radiodifusión",
  allowProfitPerformances: "Permitir actuaciones con fines de lucro",
  newLicense: "Nueva licencia",
  exclusivePrice: "Precio de derechos exclusivos",
  allowOffers: "Permitir Ofertas",
  SaveToDraft: "Guardar como borrador",
  PublishTrack: "Publicar pista",
  BeatsPublishing: "Porcentaje de publicación de beats",
  BeatsWithHook: "Porcentaje de publicación de beats con gancho",
  TopLines: "Porcentaje de publicación de líneas principales y vocales",
  ExclusiveLicenses: "Habilitar 'Hacer ofertas solo' para licencias exclusivas",
  QuickCustomization: "Personalización rápida",
  addTrack: "Agregar pista",
  metadata: "Metadatos",
  trackTitle: "Título de la pista",
  beatLegacy: "Beat Legacy",
  trackCover: "Portada de la pista",
  addPicture: "Agregar imagen",
  trackType: "Tipo de pista",
  releaseDate: "Fecha de lanzamiento",
  url: "URL",
  audioFiles: "Archivos de audio MP3 para descarga",
  selectAudioFileForStreaming: "Seleccionar archivo de audio para transmisión",
  saveToDraft: "Guardar como borrador",
  publishTrack: "Publicar pista",
  coverSize: "Tamaño de la portada",
  requireDownload: "Requerir descarga",
  twitter: "Twitter",
  soundCloud: "SoundCloud",
  superProductor: "Superproductor",
  socialFollow: "Seguir en redes sociales",
  connectTwitter: "Conectar con Twitter",
  connectSoundcloud: "Conectar con SoundCloud",
  freeDownload: "Opciones de descarga gratuita",
  no: "No",
  yesWithVoice: "Sí, con etiqueta de voz (versión MP3)",
  yesUntagged: "Sí, sin etiquetas (versión MP3 - 320kbps)",
  allowAnonymous: "Permitir descarga anónima de invitados",
  maximumText: "de 355 caracteres máximos permitidos",
  bpm: "BPM",
  key: "Tono",
  tags: "Etiquetas",
  addTags: "Agregar etiqueta",
  standardLicense: "Licencia estándar (precio en MP3)",
  miniumAmount: "Monto mínimo de oferta",
  toDisableALicense:
    "Para desactivar una licencia para esta pista específica, ingresa 0 (o 0.00).",
  thisWillDisable: "Esto desactivará las compras para ese usuario específico",
  user: "Usuario",
  role: "Rol",
  profitShare: "Participación en las ganancias",
  publishing: "Publicación",
  Notifications: "Notificaciones",
  addACollaborator: "Agregar un colaborador",
  moreNotifications: " más notificaciones no leídas",
  ClearAll: "Borrar todo",
  NoNotification: "¡Estás al día! No tienes nuevas notificaciones.",
  soundKitTitle: "Título del kit de sonido",
  soundKitName: "Ingresa el nombre del kit de sonido",
  cover: "Portada",
  soundKitType: "Tipo de kit de sonido",
  UploadingSoundKit: "Subiendo Kit de Sonido",
  UploadNewSoundKit: "Subir Nuevo Kit de Sonido",
  UploadFiles: "Subir Archivos",
  OpenorDrag: "Abrir o Arrastrar",
  AudioFile: "Archivo de Audio",
  Edit: "Editar",
  Uploading: "Subiendo...",
  addCoverArt: "Agregar Portada",
  beforeYouAddCollaborators:
    "Antes de agregar colaboradores, asegúrate de que tú y tus colaboradores cumplan con los siguientes requisitos:",
  youMustBe:
    "- Debes ser el “Contribuyente Principal” para agregar colaboradores.",
  makeSure: "- Asegúrate de seguirse mutuamente.",
  yourCollaborator:
    "- Tu colaborador debe ser un miembro registrado de Superproductor y al menos suscrito a una cuenta de suscripción “GRATUITA”.",
  needToHave:
    "- Necesita tener su dirección de correo electrónico de PayPal configurada correctamente.",
  youCanOnly: "- Solo puedes agregar hasta cinco (5) colaboradores por pista.",
  chooseFile: "Elegir archivo (o arrástralos aquí)",
  upLoadFiles: "Subir archivos",
  chooseFiles: "Elegir archivos",
  passWordOptional: "Contraseña (opcional)",
  orCompress: "O comprime las pistas en un solo archivo .zip",
  uploadAZipFile: "Subir un archivo .zip",
  clientName: "Nombre del Cliente",
  modifying: "¿Modificar esta Licencia?",
  numberOfRadio: "Número de Emisoras de Radio",
  numberOfProfit: "Número de Actuaciones con Fines de Lucro",
  licenseRenewalTerm: "Términos de Renovación de la Licencia",
  freeDownloads: "Descargas Gratuitas",
  nonMonetizedVideo: "Transmisiones de Video No Monetizadas",
  monetizedVideos: "Transmisiones de Video Monetizadas",
  yearTerm: "Término de Año",
  stateOrProvidence: "Estado o Provincia",
  country: "País",
  Modifying: "¿Modificar esta Oferta a Granel?",
  EditOffer: "Editar esta Oferta a Granel",
  EditPromoCode: "Editar este Código Promocional",
  DeleteOffer: "Eliminar esta Oferta a Granel",
  DeletePromoCode: "Eliminar este Código Promocional",
  resetItBack: "Puedes restablecerlo.",
  deleteThisOffer: "¿Estás seguro de que quieres eliminar este elemento?",
  Reactivate: "Reactivar Código Promocional",
  Activate: "Reactivar",
  Active: "Activo",
  Free: "Gratuito",
  Upgrade: "Actualizar",
  CancelSubscription: "Cancelar Suscripción",
  MainstreamPlan: "¿Quieres mantener tu plan actual de Mainstream?",
  cancelyourCurrent:
    "Esto cancelará tu plan actual y volverás al plan gratuito.",
  Proceed: "Continuar",
  emailAdress: "Dirección de Correo Electrónico",
  login: "Iniciar Sesión",
  password: "Contraseña",
  username: "Nombre de Usuario",
  signUp: "Registrarse",
  seeYourSupport: "Descubre tu Sonido: Tu Única Parada para Ritmos",
  signUpWithEmail: "o Registrarse con Correo Electrónico",
  iGreeToThe: "Acepto los",
  termsConditions: "Términos y Condiciones",
  alreadyAccount: "¿Ya tienes una cuenta?",
  signIn: " Iniciar Sesión",
  superproductorAllRight:
    "Superproductor™ Todos los Derechos Reservados, 2023.",
  support: "¡Descubre tu crecimiento y obtén soporte de consultoría!",
  Consistent:
    "Calidad y experiencia consistentes en todas las plataformas y dispositivos.",
  Streaming:
    "Plataforma de transmisión para que los artistas descubran los ritmos de rap más populares para su próximo éxito.",
  Superproductor: "Superproductor™ Todos los Derechos Reservados, 2023.",
  CreateAnAccount: "Crear una Cuenta",
  NotRegisteredYet: "¿Todavía no estás registrado?",
  ForgetPassword: "¿Olvidaste tu Contraseña?",
  Email: "Correo Electrónico",
  Password: "Contraseña",
  SignUpWithEmail: "o Registrarse con Correo Electrónico",
  RememberMe: "Recuérdame",
  EditProfile: "Editar Perfil",
  OfersBulkDeals: "Ofertas a Granel",
  Followers: "Seguidores",
  Plays: "Reproducciones",
  Tracks: "Pistas",
  LastActivity: "Última Actividad",
  MyProfile: "Mi Perfil",

  // TRACK TYPE
  SoundKits: "Kits de Sonido",
  beat: "Ritmo",
  beatWithChorus: "Ritmo con Coro",
  topLine: "Línea Principal",
  vocals: "Voces",
  song: "Canción",
  all: "Todo en Uno",
  oneShot: "Disparo Único",
  drumLoops: "Bucles de Batería",
  midiPack: "Kit de MIDI",
  melodicLoops: "Bucles Melódicos",
  effects: "Efectos",
  presets: "Preajustes",

  // KEY
  aFlatMinor: "La Bemol Menor (La♭m)",
  aFlatMajor: "La Bemol Mayor (La♭M)",
  aMinor: "La Menor (Am)",
  aMajor: "La Mayor (AM)",
  aSharpMinor: "La Sostenido Menor (La♯m)",
  aSharpMajor: "La Sostenido Mayor (La♯M)",
  bFlatMinor: "Si Bemol Menor (Si♭m)",
  bFlatMajor: "Si Bemol Mayor (Si♭M)",
  bMinor: "Si Menor (Bm)",
  bMajor: "Si Mayor (BM)",
  bSharpMinor: "Si Sostenido Menor (Si♯m)",
  bSharpMajor: "Si Sostenido Mayor (Si♯M)",
  cFlatMinor: "Do Bemol Menor (Do♭m)",
  cFlatMajor: "Do Bemol Mayor (Do♭M)",
  cMinor: "Do Menor (Cm)",
  cMajor: "Do Mayor (CM)",
  cSharpMinor: "Do Sostenido Menor (Do♯m)",
  cSharpMajor: "Do Sostenido Mayor (Do♯M)",
  dFlatMinor: "Re Bemol Menor (Re♭m)",
  dFlatMajor: "Re Bemol Mayor (Re♭M)",
  dMinor: "Re Menor (Dm)",
  dMajor: "Re Mayor (DM)",
  dSharpMinor: "Re Sostenido Menor (Re♯m)",
  dSharpMajor: "Re Sostenido Mayor (Re♯M)",
  eFlatMinor: "Mi Bemol Menor (Mi♭m)",
  eFlatMajor: "Mi Bemol Mayor (Mi♭M)",
  eMinor: "Mi Menor (Em)",
  eMajor: "Mi Mayor (EM)",
  eSharpMinor: "Mi Sostenido Menor (Mi♯m)",
  eSharpMajor: "Mi Sostenido Mayor (Mi♯M)",
  fFlatMinor: "Fa Bemol Menor (Fa♭m)",
  fFlatMajor: "Fa Bemol Mayor (Fa♭M)",
  fMinor: "Fa Menor (Fm)",
  fMajor: "Fa Mayor (FM)",
  fSharpMinor: "Fa Sostenido Menor (Fa♯m)",
  fSharpMajor: "Fa Sostenido Mayor (Fa♯M)",
  gFlatMinor: "Sol Bemol Menor (Sol♭m)",
  gFlatMajor: "Sol Bemol Mayor (Sol♭M)",
  gMinor: "Sol Menor (Gm)",
  gMajor: "Sol Mayor (GM)",
  gSharpMinor: "Sol Sostenido Menor (Sol♯m)",
  gSharpMajor: "Sol Sostenido Mayor (Sol♯M)",

  // ADD TRACK REQUIRED
  yes: "Sí",
  soundkitAddedSuccessfully: "Kit de Sonido Agregado Exitosamente",
  soundkitAddedDescription:
    "Tu kit de sonido se agregó con éxito a la página. Ve al muro para verlo",
  emptyString: "Cadena vacía",
  publishSoundkit: "Publicar Kit de Sonido",
  trackTitleRequired: "Por favor, agrega un título de pista",
  urlRequired: "Por favor, agrega una URL",
  soundkitTitleRequired: "Por favor, agrega un título de kit de sonido",
  soundkitBasicPriceRequired: "Por favor, agrega un precio",
  coverUrlRequired: "Por favor, agrega una imagen de portada",
  trackTypeRequired: "Por favor, selecciona un tipo de pista",
  soundkitTypeRequired: "Por favor, selecciona un tipo de kit de sonido",
  bpmRequired: "El BPM es un campo obligatorio",
  keyRequired: "Selecciona una tonalidad",
  primaryGenreRequired: "Selecciona un género principal",
  secondaryGenreRequired: "Selecciona un subgénero",
  primaryMoodRequired: "Selecciona un estado de ánimo principal",
  descriptionRequired: "La descripción es un campo obligatorio",
  sampleRequired: "Este es un campo obligatorio",
  contractTypeRequired: "Por favor, selecciona un tipo de contrato",
  untaggedFileUrlRequired: "Por favor, sube un archivo de audio",
  licenseRequired: "Por favor, selecciona al menos una licencia",
  tagsRequired: "Por favor, crea al menos 4 etiquetas",
  uploadTrackStems: "Por favor, sube los STEMS de la pista",
  uploadTrackWav: "Por favor, sube los WAV de la pista",
  collaboratorsRequired: "Por favor, selecciona al menos un colaborador",
  audioFileUrlRequired: "El audio es obligatorio",
  downloadFilesUrlRequired: "Se requiere el archivo de descarga",
  taggedFileUrlRequired: "Por favor, sube un archivo de audio etiquetado",

  yourFileMustBeWavOrFlac:
    "- Tu archivo debe ser en formato WAV o FLAC con una frecuencia de muestreo de 44.1 kHz en estéreo y comprimido en 16 bits.",
  uploadAudioFile: "Subir Archivo de Audio",
  uploadCompressFile: "Subir Archivo Comprimido",
  WAVorMP3: ".MP3 (o WAV)",
  fileDescription: "Descripción",
  uploadCover: "Subir Portada",
  SelectTypeOfContract: "Selecciona el tipo de contrato",
  SelectTrackType: "Selecciona el tipo de pista",
  SelectSoundKitType: "Selecciona el Tipo de kit de Sonido",
  selectGenre: "Selecciona un género",
  selectSubGenre: "Selecciona un subgénero",
  selectKey: "Selecciona una tonalidad",
  view: "Ver",
  selectPrimaryMood: "Selecciona un estado de ánimo principal",
  selectSecondMood: "Selecciona un estado de ánimo secundario",
  enterDescription: "Ingresa la descripción aquí",
  maxBpm: "El BPM máximo es 999",
  maximunDescription: "Máximo de 355 caracteres permitidos",
  cropCover: "Recortar portada",
  enableAllowOffersNonExclusive:
    "Habilitar 'Permitir Ofertas' para Licencias No Exclusivas",
  includeBulkOfferDiscout: "Incluir en descuentos a granel",
  licenseNote: "Nota: ",
  customizeYourLicense: "Puedes personalizar tus licencias aquí: ",
  LicenseAndContracts: "Licencias y Contratos",
  private: "Privado",
  excludeBulkOfferDiscout: "Excluir de Descuentos a Granel",
  notForSale: "No Disponible para la Venta",
  freeDownloadEnabledRequired:
    "Por favor, selecciona una Opción de Descarga Gratuita",

  includesMp3AndWav: "Incluye MP3 y WAV",
  includesMp3WavAndTrackStems: "Incluye MP3, WAV y STEMS de Pista",
  includesMp3AndTrackStems: "Incluye MP3 y STEMS de Pista",
  includesMp3: "Incluye MP3",
  tagPreview: "Vista Previa de Etiqueta",
  audioTagForStreaming: "Etiqueta de Audio para Transmisión",
  defaultAudioVoiceTag: "Etiqueta Predeterminada de Superproductor.mp3",
  uploadFile: "Archivo Subido:",
  uploadAudioVoiceTag: "Etiqueta de Audio Personalizada.mp3",
  noteUploadCover: "Mínimo: 500x500, Preferido: 1500x1500",
  uploadSuccess: "¡Subido con éxito!",
  MP3CustomVoiceTag: "Etiqueta de Voz Personalizada MP3",
  MP3CustomVoiceTagNote:
    "Si no tienes una etiqueta: \n Una etiqueta predeterminada de Superproductor se reproducirá automáticamente.\n La etiqueta comenzará después de 1 segundo y luego se reproducirá cada 30 segundos.",
  trackStems: "STEMS de Pista",
  trackStemsDetail: ".ZIP para Descargar",
  untaggedWAV: "WAV sin Etiquetas",
  untaggedMP3: "MP3 sin Etiquetas",
  minBitrateWAV: ".WAV (Archivo recomendado en 44.1 WAV 24 bits o superior)",
  minBitrateWAVorMP3: ".MP3 (Mín. Bitrate 128)",
  errorUploadAudio: "Error al subir el archivo",
  minimunBitrate:
    "Por favor, selecciona un audio con una tasa de bits de al menos 128 kbps",
  fieldToDeliver: "Campo para Entregar",
  NoDescriptionYet: "Aún no hay descripción",
  EditAvatar: "Editar Avatar",
  MinimumSize: "Mínimo: 500x500 píxeles, Preferido: 1500x1500 píxeles",
  Country: "País",
  About: "Acerca de",
  Symbols: "Símbolos",
  NotWriteMore: "no puedes escribir más palabras",
  Credits: "Créditos",
  SeparateEachLine: "Separa cada línea presionando el botón Enter.",
  editLicenses: "Editar Licencia",
  update: "Actualizar",
  upToDate: "¡Estás actualizado!",
  uploaded: "subido",
  displayName: "Nombre de Usuario",
  trackAddedSuccessfully: "Se ha agregado una Pista a tu Perfil",
  trackAddedDescription:
    "Tu pista se agregó con éxito a la página. Ve al muro para verla",
  backToFeed: "Volver al muro",
  contract: "Contrato",
  producer: "Productor",
  selectRole: "Selecciona un rol",
  publishingRequired: "Se requiere la publicación",
  profitShareRequired: "Se requiere el reparto de ganancias",
  roleRequired: "Se requiere un rol",
  profitShareMin: "Al menos 1% de reparto de ganancias como mínimo",
  profitShareMax: "El reparto de ganancias debe tener un máximo",
  publishingMin: "Al menos 1% de publicación como mínimo",
  publishingMax: "La publicación debe tener un máximo",
  publishingMaxExclusive: "La publicación debe tener un máximo del 50%",
  profitShareNumber: "El reparto de ganancias debe ser un número",
  publishingNumber: "El reparto de ganancias debe ser un número",
  exclusiveContract: "Contrato Exclusivo",
  nonExclusiveContract: "Contrato No Exclusivo",
  requiredUploadFiles:
    "Para activar esta licencia, debes subir los siguientes archivos:",
  passwordUser: "contraseña",
  PasswordNotMatch: "Contraseña no coincide",
  confirmPassword: "Confirmar Contraseña",
  ResetYourPassword: "¿Restablecer tu contraseña?",
  emailSent: "Correo electrónico enviado con instrucciones",
  enterYourEmail:
    "Ingresa tu correo electrónico a continuación y te enviaremos instrucciones sobre cómo crear una nueva contraseña.",
  forgotYourPassword: "¿Olvidaste tu contraseña?",

  // SUBCRIPTION PLAN
  freePlan: "Estándar",
  proPlan: "Plan Comunitario",
  freePlanPrice: "Gratis",
  free: "Gratis",
  freePlanPriceDescription: "$0.00",
  freePlanDescription:
    "Empieza Gratis, Crece a lo Grande: Precios Escalables para Músicos",
  mainstreamMonthlyPlan: "Plan Mainstream",
  mainstreamAnnualPlan: "Plan Mainstream",
  mainstreamMonthlyPlanPrice: "$1.67 / Mes",
  mainstreamAnnualPlanPrice: "$19.99 / Año",
  mainstreamPlanPriceDescriptionMonth: "Facturado cada mes",
  mainstreamPlanPriceDescriptionYear: "$99.88 / Año",
  mainstreamPlanPriceDiscountMonth: "0% de Descuento",
  mainstreamPlanPriceDiscountYear: "17% de Descuento",
  mainstreamMonthlyPlanDescription:
    "Para vendedores que desean expandir su negocio de música y acceder a funciones seleccionadas",
  mainstreamAnnualPlanDescription:
    "Desbloquea 0% de Tarifas: ¡Mejora por Solo $19.99 Hoy!",
  activePlan: "Plan Activo",
  active: "Activo",
  upgrade: "Mejorar",
  cancelDateText: "Esta suscripción está activa hasta ",
  cancelSubscription: "Cancelar Suscripción",
  discoutOff: "-17% de Descuento",
  cancelDescription: `Tu Plan de Suscripción Mainstream ha sido cancelado. Cuando cancelas tu suscripción en cualquier momento durante tu ciclo mensual o anual, tu cuenta permanece activa hasta el final de tu período de facturación. \n\nSin embargo, tu cuenta permanecerá activa como una cuenta gratuita. Si es un error, avísanos y te ayudaremos de inmediato.​`,
  upgradeDescription: `Tu Plan de Suscripción Mainstream ha sido confirmado. Cuando cancelas tu suscripción en cualquier momento durante tu ciclo mensual o anual, tu cuenta permanece activa hasta el final de tu período de facturación. \n\nSin embargo, tu cuenta permanecerá activa como una cuenta gratuita. Si es un error, avísanos y te ayudaremos de inmediato.​`,
  cancelLabel: "Suscripción Cancelada",
  upgradeLabel: "Suscripción Confirmada",
  cancelYourCurrent: "Esto degradar tu plan actual y volverá al plan gratuito.",
  mainstreamAnnualPriceDescription: "Facturado cada año",
  mainstreamMonthlyPriceDescription: "Facturado cada mes",
  planUpgrade: "Mejorar Plan",
  planReview: "Revisión del Plan",
  thanksForBuying: "Gracias por comprar:",
  upgradeToMainstreamPlan: "Mejorar al Plan Mainstream",
  pleaseCheckEmail:
    "Por favor, verifica tu correo electrónico para confirmación de pedido y \ninformación detallada de entrega.",
  // END SUBCRIPTION PLAN
  cart: "Carrito",
  basketIsEmpty:
    "Tu carrito está vacío. Explora nuevas pistas en tu búsqueda para tu biblioteca musical",
  purchaseDetail: "Detalles de la Compra",
  cartSummary: "Resumen del Carrito:",
  gotPromoCode: "¿Tienes un código promocional?",
  placeYourOrder: "Realiza tu pedido",
  chooseLicense: "Elige la Licencia",
  addToCart: "Agregar al Carrito",
  acceptPayment: "Formas de Pago Aceptadas:",
  debitOrCredit: "Tarjeta de Débito o Crédito",
  usageTerms: "Términos de Uso",
  errorAddToCart:
    "Este usuario aún no ha conectado PayPal o Stripe. No puedes agregar esta pista al carrito.",
  distributionCopies: "Copias de Distribución",
  nonVideoStreams: "Transmisiones de Video No Monetizadas",
  monetizedVideoStreams: "Transmisiones de Video Monetizadas",
  profitLive: "Ganancias de Actuaciones en Vivo",
  profitPerformance: "Ganancias de Actuaciones",
  yearLicenseRenewal: "Renovación de Licencia Anual",
  mustCredit: "Debe Acreditar:",
  prodBy: "Prod. por:",
  filesToBeDelivered: "Archivos a Entregar:",
  mp3: "MP3",
  wav: "WAV",
  stems: "STEMS",
  unlimitedFreeDownload: "Descarga Ilimitada Gratuita",
  license: "Licencia",
  licenseReview: "Revisión de la Licencia",
  playTrack: "Reproducir pista",
  remove: "Eliminar",
  businessName: "Nombre de Negocio",
  bankAccount: "Cuenta Bancaria",
  transactionId: "ID de Transacción",
  saleDate: "Fecha de Venta",
  specification: "Especificaciones",
  addStripe: "Agregar Stripe",
  addPaypal: "Agregar Paypal",
  registerSuccessfully: "Registro exitoso",
  backToPayment: "Volver a Pagos y Billeteras",
  selectAll: "Seleccionar Todo",
  removeFromCart: "Eliminar del Carrito",
  removeFromTrack: "Eliminar de la Pista",
  removeFromSoundkit: "Eliminar del Kit de Sonido",
  paymentMethods: "Métodos de Pago",
  confirm: "Confirmar",
  paymentMethod: "Método de Pago:",
  checkoutWithPaypal: "Pagar con Paypal",
  confirmPayment: "Confirmar Pago",
  checkOut: "Finalizar Compra",
  yourTrackOrder: "Hemos recibido tu ",
  trackOrder: "pedido de pista.",
  contactYourPurchase:
    "Hubo un error con tu compra. Por favor, contacta a tu proveedor.",
  contactYourBank:
    "Por favor, contacta a tu banco. Un botón para volver a intentar.",
  serverError: "Error Interno del Servidor",
  backToCheckout: "Volver al proceso de compra",
  creditCard: "Tarjeta de Crédito:",
  cardHolderName: "Nombre del Titular de la Tarjeta",
  cardNumber: "Número de Tarjeta",
  date: "Fecha",
  cvc: "CVC",
  continue: "Continuar",
  errorCardNumber: "Número de Tarjeta Inválido",
  dateIsRequired: "La Fecha es un campo obligatorio",
  invalidCvc: "CVC Inválido",
  nameIsRequired: "El Nombre es un campo obligatorio",
  change: "Cambiar",
  disconnect: "Desconectar",
  youWantToDisconnect:
    "Desconectar tu cuenta de PayPal evitará que ofrezcas servicios y productos de PayPal en nuestro sitio web.",
  paymentConflict: "¿Deseas continuar?",
  paymentAccounts: "Cuentas de Pago",
  disconnectSuccessfully: "Desconexión exitosa",
  disconnectFailed: "Desconexión fallida",
  rememberThisCard: "Recordar esta tarjeta para transacciones futuras",
  offersBulkDeals: "Ofertas a Granel",
  message: "Mensaje",
  follow: "Seguir",
  bulkOfferDetails: "Detalles de la Oferta a Granel",
  addToQueue: "Agregar a la Cola",
  removeFromQueue: "Eliminar de la Cola",
  missingTrackTitle: "Falta el título de la pista",
  missingTrackFiles: "Faltan Archivos",
  missingTrackLicenses: "Faltan Licencias y Contratos",
  alreadyRepost: "Ya has compartido esta pista previamente",
  alreadyRepostSK: "Ya has compartido este kit de sonido previamente",
  lyricTitle: "Título de Letra",
  writeLyricTitle: "Escribe el título de la letra",
  lyrics: "Letra",
  writeLyricshere: "Escribe tu letra aquí...",
  editLyrics: "Editar Letra",
  saveLyrics: "Guardar Letra",
  trackTo: "Pista ",
  toFavorites: "a Favoritos",
  missTypeSoundkit: "Falta el tipo de kit de sonido",
  editTrack: "Editar Pista",
  soundkit: "Kit de Sonido",
  writeLyrics: "Escribe la Letra",
  backToMyTrack: "Volver a mis pistas",
  editTrackSuccessfully: "Pista Editada Exitosamente",
  editSoundkit: "Editar Kit de Sonido",
  editSoundkitSuccess: "Kit de Sonido Editado Exitosamente",
  backToMySoundkit: "Volver a mis kits de sonido",
  minimumTracksRequired: "Se requiere un número mínimo de pistas",
  freeTracksLessThan:
    "El número de pistas gratuitas debe ser menor que el número mínimo de pistas",
  freeTracksRequired: "Se requiere un número mínimo de pistas gratuitas",
  atLeastOneLicense: "Por favor, selecciona al menos una licencia",
  EditBulkOffer: "Editar Oferta a Granel",
  ApplyToLicense: "Aplicar a las siguientes licencias",
  editExitBulkOffer: "Editar una Oferta a Granel existente",
  queue: "Cola",
  playingQueue: "Cola de Reproducción",
  clearQueue: "Limpiar Cola",
  ModifyingPromoCode: "Modificando Código Promocional",
  promoCodeRequired: "El código promocional es un campo obligatorio",
  productType: "Tipo de Producto",
  productTypeRequired: "El Tipo de Producto es un campo obligatorio",
  percent: "Porcentaje de Descuento (1% - 50% solamente)",
  percentPositive: "El Porcentaje de Descuento debe ser un número positivo",
  percentLessThan50: "El Porcentaje de Descuento debe ser menor que 50",
  percentRequired: "El Porcentaje de Descuento es un campo obligatorio",
  expirationDate: "Fecha de Vencimiento",
  setExpirationDate: "Establecer Fecha de Vencimiento del Código Promocional",
  expirationDateRequired: "La Fecha de Vencimiento es un campo obligatorio",
  nonExpiring: "Este es un Código Promocional que no vence",
  licenses: "Aplicar este código promocional a las siguientes licencias",
  NewPromoCode: "Crear un Nuevo Código Promocional",
  mustBeLaterThanToday: "La fecha debe ser posterior a la de hoy.",
  editExistPromoCode: "Editar código promocional existente",
  editPromo: "Editar Código Promocional",
  startNegotiation: "Iniciar Negociación",
  youHaveNo: "No tienes",
  negotiation: "negociaciones",
  NoHaveNegotiations: "Si tienes negociaciones, aparecerán en esta página",
  negotiationDeal: "Acuerdo de Negociación",
  paymentCompleted: "Pago Completado",
  negotiationsStatus: {
    ACCEPTED: "Esperando el pago",
    COUNTEROFFER: "Contraoferta enviada",
    EXPIRED: "Expirada",
    REJECT_BY_SELLER: "Rechazada por el vendedor",
    CANCEL_BY_BUYER: "Cancelada por el comprador",
    COUNTEROFFER_ACCEPTED: "Esperando el pago",
    COMPLETED: "Completada",
    PENDING: "Pendiente",
    SOLD_TO_SOMEONE_ELSE: "Vendida a otra persona",
  },
  counterofferTimeout:
    "Tiempo de espera de la contraoferta. Por favor, crea una nueva contraoferta para continuar.",
  expiresIn: "Expira en",
  expiredDayOn: "Expira el ",
  exclusiveLicense: "Licencia Exclusiva",
  publishingNegotiation: "Publicación:",
  accept: "Aceptar",
  counteroffer: "Contraoferta",
  paynow: "Pagar ahora",
  reject: "Rechazar",
  upgradePlan: "Mejorar Plan",
  myProfile: "Mi perfil",
  messages: "Mensajes",
  playlistsHub: "Centro de listas de reproducción",
  offerExpiresIn: "Oferta expira en",
  negotiationOffer: "Oferta de negociación",
  yourCounterOffer: "Tu contraoferta",
  reviewCounteroffer: "Revisar contraoferta",
  yourOffer: "Tu oferta",
  quantity: "Cantidad",
  total: "Total",
  submitOffer: "Enviar oferta",
  editOffer: "Editar oferta",
  newConversations: "Nueva conversación",
  startConversation: "Iniciar conversación",
  typeYourMessageHere: "Escribe tu mensaje aquí...",
  send: "Enviar",
  emptyConversation: "Cuando recibas un mensaje, lo verás aquí.",
  youHaveCollaborate: "Tienes",
  invitationToCollaborate: "invitaciones para colaborar",
  youHaveOpen:
    "Si tienes invitaciones abiertas para colaborar, aparecerán en esta página.",
  openInvitations: "Invitaciones abiertas",
  closeInvitations: "Cerrar invitaciones",
  profitShareCollaborations: "Participación en las ganancias",
  publishCollaborations: "Publicación",
  emptyNotification: "Cuando recibas notificaciones, las verás aquí.",
  selectTypeBeforeCollaborations:
    "Selecciona el tipo de contrato antes de añadir colaboradores.",
  selectLicenseBeforeCollaborations:
    "Selecciona la licencia antes de añadir colaboradores.",
  inviteExpiredOn: "Invitación caducada el ",
  youHaveNoAnalytics: "No tienes actividad de análisis",
  dataAnalytics: "Análisis de datos",
  this: "esto",
  ofMyTrack: "de mis pistas",
  emptyTopCountry: "No tienes actividad reciente",
  hideExtendedOverview: "Ocultar descripción general extendida",
  showExtendedOverview: "Mostrar descripción general extendida",
  mostPopularLicense: "Licencia más popular",
  youHaveNoRecentActivity: "No tienes actividad reciente",
  schedule: "Programado",
  charactersLeft: "caracteres restantes.",
  aMinimumOfMaximum: "Se aceptarán un mínimo de 0 a 70 caracteres como máximo",
  openCollaborations: "Invitaciones abiertas",
  forThe: "para el",
  of: "de",
  productorPerformance: "Rendimiento del producto",
  consumerBeHaviour: "Comportamiento del consumidor",
  noLyricsYet: "Aún no hay letras",
  contentEmptyLyrics:
    "No hay nada como componer. ¡Comienza ahora a escribir tus letras!",
  createLyrics: "Crear letras",
  accepted: "Aceptada",
  from: "de",
  youHave: "Tienes",
  freeTrackRemain: "pistas gratuitas restantes",
  addMore: "Añadir más",
  toApplyBulkOffer: "pistas para aplicar la oferta a granel: ",
  noneDiscount: "Sin descuento",
  discount: "Descuento:",
  bulkDealApplied: "Oferta a granel aplicada",
  applyPromoCode: "Aplicar código promocional",
  checkPromoCode: "Comprobar código promocional",
  invalidCode: "Código no válido",
  promoCodeDiscount: "Descuento con código promocional:",
  buyInBulkDeals: "Comprar en ofertas a granel",
  feed: "Muro",
  discover: "Descubrir",
  explore: "Explorar",
  hotProducers: "Productores destacados",
  recentActions: "Acciones recientes",
  newTalents: "Nuevos talentos",
  recommended: "Recomendado",
  freeBeats: "Beats gratuitos",
  trendingTags: "Etiquetas populares",
  track: "Pista",
  slugTrack: "La slug ya existe",
  underRadar: "Bajo el radar",
  trendingBeats: "Beats populares",
  genres: "Géneros",
  moods: "Moods",
  refresh: "Actualizar",
  newRelease: "Nuevo lanzamiento:",
  searchResultsFor: "Resultados de búsqueda para:",
  trackName: "Nombre de la pista",
  musicianName: "Nombre del músico",
  soundPacksName: "Nombre del kit de sonido",
  playlistName: "Nombre de la lista de reproducción",
  nameTag: "Etiqueta de nombre",
  musicians: "Músicos",
  soundPacks: "Kits de Sonido",
  playlist: "Lista de reproducción",
  subGenres: "Subgéneros",
  showMore: "Mostrar más",
  showLess: "Mostrar menos",
  location: "Ubicación",
  noNewRelease: "Sin nuevos lanzamientos",
  globalCounsumers: "Consumidores globales",
  unfollow: "Dejar de seguir",
  textDescriptionFeedHome:
    "Tendrás acceso rápido para escuchar nuevas pistas basadas en tus gustos musicales, lo que facilita encontrar nueva inspiración.",
  textDescriptionDiscoverHome:
    "Descubre los beats más candentes de hoy en todo el mundo. Seguro que encontrarás tu próxima pista favorita con nuevos talentos. ¡Así que escucha y deja volar tu imaginación!",
  textDescriptionExploreHome:
    "Ya sea que estés explorando un nuevo género o simplemente buscando nuevos sonido para disfrutar, esta es una excelente manera de ampliar tus horizontes musicales.",
  inActive: "Inactivo",
  mySoundKits: "Mis kits de sonido",
  searchSoundPacks: "Buscar kits de sonido",
  shareLink: "Compartir enlace",
  shortLink: "Enlace corto",
  copy: "Copiar",
  FREE: "GRATIS",
  noDescription: "Sin descripción",
  whoAreYou: "¿Quién eres?",
  next: "Siguiente",
  tellUsAboutYour: "¡Cuéntanos un poco sobre tu experiencia!",
  musicListener: "¿Eres un oyente de música o supervisor musical? *",
  songWrite: "¿Eres cantante, compositor, bailarín o creador de contenido?",
  artist: "Artista",
  doYouMakeBeats: "¿Haces beats?",
  chooseYourInterest: "Elige tu interés",
  descriptionInterest:
    "Eres libre de elegir cualquier interés en género musical. Esto nos ayudará a mostrar contenido basado en tus intereses.",
  allNotification: "Ver todas las notificaciones",
  unRead: "No leído",
  limitUploadTrack: "Has alcanzado el límite de subida de pistas",
  limitNewLicense: "Has alcanzado el límite de creación de licencias",
  noData: "Sin datos",
  guest: "Invitado",
  updatePictureProfile: "Actualizar imagen de perfil",
  pictureProfile: "Imagen de perfil",
  preview: "Vista previa",
  chooseFileImage: "Elegir archivo",
  saveChanges: "Guardar cambios",
  titleLicenseLimit: "Límite de Acuerdos de Licencia Electrónica Alcanzado",
  descriptionLimit:
    "¡Hola! Has alcanzado el límite de 4 Acuerdos de Licencia Electrónica. Actualiza tu plan para seguir creando más acuerdos.",
  upgradeYourPlanToUpload: "Actualiza tu plan para subir MP3 Custom Voice Tag",
  pleaseUpgradeYourPlan:
    "¡Hola! Para subir un MP3 Custom Voice Tag, por favor, actualiza tu plan.",
  uploadTrackLimitReached: "Límite de subida de pistas alcanzado",
  youHaveReachedTrack:
    "¡Ups! Has alcanzado el límite de 10 pistas subidas. Actualiza tu plan para seguir subiendo.",
  limitUploadSoundPacks: "Actualiza tu plan para seguir subiendo",
  titleLimitUploadSoundPacks: "Subir kits de sonido",
  messagesLimitReached: "Límite de mensajes alcanzado",
  descriptionFiveMessagesLimitReached:
    "¡Hola! Has alcanzado el límite de 5 mensajes. Actualiza tu plan para seguir enviando mensajes.",
  descriptionTwentyMessagesLimitReached:
    "¡Hola! Has alcanzado tu límite de 20 mensajes al mes",
  charactersRemaining: "caracteres restantes",
  youHaveExceededTheMaximum: "Has excedido el límite máximo de caracteres",
  add: "Añade",
  more: "más",
  leaveAddTrackPage: "¿Salir de la página de agregar pista?",
  descriptionLeaveTrackPage:
    "La pista se guardará como borrador. Puedes retomar donde lo dejaste más tarde.",
  leave: "Salir",
  stay: "Quedarse",
  termAndConditions: "Términos y Condiciones",
  privacyPolicy: "Política de privacidad",
  enterTrackTitle: "Ingresa el título de la pista",
  enterTag: "Ingresa una etiqueta",
  enterBpm: "Ingresa el BPM",
  inCart: "En el carrito",
  descriptionNonExclusive:
    "Esta licencia no exclusiva te otorga el derecho de usar el beat, pero el propietario aún puede dar permiso a otros para usarlo. Esto significa que otros artistas que también tengan permiso para usar el beat también pueden hacerlo.",
  descriptionExclusive:
    "Una licencia exclusiva significa que serás el único que puede usar el beat. El propietario no puede permitir que nadie más lo use durante el período de la licencia.",
  descriptionAddTrack:
    "¿Buscas el Beat perfecto? ¡No busques más! ¡Nuestra Biblioteca de Pistas te tiene cubierto! Desde ritmos pegajosos hasta estilos específicos de género, lo tenemos todo. Ya sea un EP, álbum, mixtape, video musical o cualquier proyecto que necesite un Beat, ¡te tenemos cubierto!",
  collaborationInviteSent: "¡Invitación de colaboración enviada!",
  descriptionCollaborationInviteSent:
    "Esperemos a que el(los) colaborador(es) envíe(n) la respuesta. La pista debería estar en el panel dentro de las pistas en la sección de borradores esperando la aprobación para ser publicada.",
  firstNameIsRequired: "El nombre es un campo obligatorio",
  lastNameIsRequired: "El apellido es un campo obligatorio",
  completeYourProfile: "Completa tu perfil",
  beforePublishingYourTracks:
    "Antes de publicar tus pistas, tómate un momento para completar tu perfil.",
  pseudonymName: "Nombre de seudónimo",
  pageNotFound: "Página no encontrada",
  editCard: "Editar tarjeta",
  editCreditOrDebitCard: "Editar tarjeta de crédito o débito",
  invalidDate: "Fecha no válida",
  removeCard: "Eliminar tarjeta",
  removeThisCard: "¿Eliminar esta tarjeta?",
  areYouSureRemoveCard: "¿Estás seguro de que deseas eliminar esta tarjeta?",
  action: "Acción",
  soundPack: "Kit de sonido",
  soundPackIsNotForSale: "Este kit de sonido no está a la venta.",
  availability: "Disponibilidad",
  addPaymentAndWallets: "Agregar cuenta de pago",
  desAddPaymentAndWallets:
    "Antes de publicar tus pistas, tómate un momento para agregar una cuenta de pago.",
  open: "Abrir",
  tyOfContract: "Tipo de contrato",
  sharePrivately: "Compartir de forma privada",
  setLinkExpiration: "Establecer vencimiento del enlace",
  expirationMode: "Modo de vencimiento",
  expireAfter: "Expira después de",
  clicks: "clics",
  usersAdded: "Usuarios añadidos",
  placeHolderLinkExpiration:
    "¡Hola! Tengo algo especial para ti: una pista exclusiva solo para unos pocos elegidos, ¡y tú eres uno de ellos! Haz clic en el enlace privado de abajo para escucharla y disfrutarla. ¡Déjame saber lo que piensas! Tu opinión significa mucho para mí.",
  desUserAdded: "Solo las personas con acceso pueden abrir este enlace.",
  createPassword: "Crear contraseña",
  optional: "Opcional",
  userRequired: "Selecciona al menos un usuario para continuar.",
  expireRequired: "Proporciona una fecha válida para continuar.",
  aTrackHasBeenSharePrivately: "Se ha compartido una pista de forma privada",
  congratulationsDes:
    "¡Felicidades! Tu pista se ha compartido con éxito de forma privada con usuarios seleccionados utilizando nuestro enlace de compartición privada seguro y exclusivo.",
  trackSaveAsDraft: "Guardar pista como borrador",
  desTrackSaveAsDraft:
    "¡Gran progreso! Tu pista se ha guardado como borrador. Aún no está lista para enfrentarse al mundo, pero estás en camino. Siéntete libre de volver en cualquier momento.",
  trackHasChangedToRelease: "Confirmación de lanzamiento de la pista",
  desTrackHasChangedToRelease:
    "¡Felicidades! Tu pista ha pasado con éxito de borrador a lanzamiento. Ahora está lista para llegar a su audiencia.",
  playlists: "Listas de reproducción",
  createNew: "Crear nueva",
  createNewPlaylist: "Crear nueva lista de reproducción",
  enterPlaylistName: "Ingresa el nombre de tu lista de reproducción",
  enterUpToPlaylistTag: "Ingresa hasta 3 etiquetas de lista de reproducción",
  enterAnOptionalDescription: "Ingresa una descripción opcional",
  visibility: "Visibilidad",
  savePlaylist: "Guardar lista de reproducción",
  datedAdded: "Fecha de agregación:",
  playlistOwner: "Propietario de la lista de reproducción:",
  information: "Información",
  published: "Publicada",
  runtime: "Duración",
  lastUpdated: "Última actualización",
  noPlaylists: "Sin listas de reproducción",
  thereAreNoPlaylistAvailable: "No hay listas de reproducción disponibles.",
  addToPlaylist: "Agregar a lista de reproducción",
  myPlaylists: "Mis listas de reproducción",
  editPlaylist: "Editar lista de reproducción",
  alreadyRepostPlaylist: "Ya has repostado esta lista de reproducción",
  noTracks: "Sin pistas",
  thereAreNoTracksAvailable: "No hay pistas disponibles.",
  progress: "Progreso",
  myPlaylist: "Mi lista de reproducción",
  following: "Siguiendo",
  playAll: "Reproducir todo",
  emptyPurchasedHistory: "Historial de compras vacío",
  item: "Artículo",
  purchaser: "Comprador",
  seller: "Vendedor",
  purchasedDate: "Fecha de compra",
  downloadConfirmation: "Confirmación de descarga",
  downloadFiles: "Descargar archivos",
  Pending: "Pendiente",
  Downloaded: "Descargado",
  geographicalIpFinder: "Localizador de IP geográfico",
  Eng: "Inglés",
  Spa: "Español",
  Por: "Portugués",
  placeHolderSearchInputHeader: "Escribe lo que estás buscando",
  clear: "Limpiar",
  logout: "Cerrar sesión",
  downloads: "Descargas",
  sales: "Ventas",
  itemSold: "Artículo Vendido",
  sold: "Vendido",
  Position: "Posición",
  gross: "Ganancias Brutas",
  collab: "Colaboración%",
  super: "Super%",
  earnings: "Ganancias",
  by: "Por",
  played: "Reproducido",
  favorited: "Marcado como favorito",
  listeners: "Oyentes",
  userWithMoreDownloads: "Usuario con más descargas",
  userWithMoreRepost: "Usuario con más reposteos",
  buyers: "Compradores",
  searchTrack: "Buscar Pista",
  selectPromoCodeType: "Seleccionar Tipo de Código Promocional",
  get: "obtener",
  freeBulk: "gratis",
  comingSoon: "Próximamente",
  licenseName: "Nombre de la Licencia",
  selectTypeOfContract: "Seleccionar Tipo de Contrato",
  enterStateOrProvidence: "Ingrese estado o provincia",
  enterBeatsPublishing: "Ingresar la publicación de beats",
  enterBeatsHookPublishing: "Introducir la publicación de ganchos de beats",
  enterTopLinesVocalsPublishing:
    "Ingrese la publicación de líneas principales y vocales",
  selectYearTerm: "Seleccionar plazo en años",
  automaticallyDeducts:
    "Deduce automáticamente el método de pago del cliente en el plazo de renovación anual seleccionado.",
  enterPromoCode: "Introducir código promocional",
  newestFirst: "Más recientes primero",
  lowestPrice: "Lowest Price",
  highestPrice: "Highest Price",
  "Released a": "Publicó una",
  "Reposted a": "Reposteó una",
  searchPlaylist: "Buscar lista de reproducción",
  rejected: "Rechazado",
  youInvite: "Tú invitas",
  toCollaborateAs: "colaborar como un",
  inviteYouToCollaborateAs: "te invitamos a colaborar como un",
  invitationSentToCollaborateAs: "Invitación enviada para colaborar como un",
  hasAccepted: "ha aceptada",
  hasRejected: "ha rechazado",
  invitationToCollaborateAs: "invitación para colaborar como un",
  you: "Usted",
  offersYou: "le ofrece",
  for: "para",
  Item: "elemento",
  youCreatedAOffers: "Tú creaste una oferta",
  offerWithPrice: "Oferta con precio",
  isPending: "está pendiente",
  your: "Su",
  offerHasExpire: "la oferta ha expirado",
  offer: "Oferta",
  hasBeenCanceledBy: "ha sido cancelada por",
  hasBeenRejectBy: "ha sido rechazada por",
  counterOfferPrice: "precio de contraoferta",
  hasSent: "ha enviado",
  youA: "Usted un",
  youHaveReceivedPayment: "Has recibido un pago",
  thanksForYourPayment: "Gracias por su pago",
  fromNow: "a partir de ahora",
  searchCollaborators: "Buscar colaboradores",
  enterPublishing: "Entrar en la publicación",
  enterProfitShare: "Ingresar en el reparto de ganancias",
  "Instant payouts funds": "Fondos de pagos instantáneos",
  "Unlimited Automated e-License Agreements":
    "Acuerdos de licencia electrónica automatizada ilimitados",
  "Collaborations with Revenue Splits":
    "Colaboraciones con repartos de ingresos",
  "Discounts Offers": "Ofertas de descuento",
  "Custom Voice Tag": "Etiqueta de voz personalizada",
  "Sales Analytics": "Análisis de ventas",
  "Accept Offers & Negotiate": "Aceptar ofertas y negociar",
  "Schedule Uploads": "Programar subidas",
  "Free Demo Downloads": "Descargas de demostración gratuitas",
  "Upload Up to 10 Trackout Stems (.zip) Files":
    "Subir hasta 10 archivos de pistas en formato Stem (.zip)",
  "Only 10 Trackout Restrictions for Free Users":
    "Solo 10 restricciones de pistas para usuarios gratuitos",
  "Upload Unlimited Trackout Stems (.zip) Files":
    "Subir archivos de pistas en formato Stem (.zip) ilimitados",
  "Up to 20 Private Monthly Messages": "Hasta 20 mensajes privados mensuales",
  "Up to 5 Private Monthly Messages": "Hasta 5 mensajes privados mensuales",
  "Only 5 Monthly Messages Restrictions for Free Users":
    "Solo 5 restricciones de mensajes mensuales para usuarios gratuitos",
  "Accept Credit Card and Paypal": "Aceptar tarjeta de crédito y Paypal",
  "Upload Up to 10 Tracks": "Subir hasta 10 pistas",
  "Only 10 Tracks Restrictions for Free Users":
    "Solo 10 restricciones de pistas para usuarios gratuitos",
  "Upload Unlimited Tracks": "Subir pistas ilimitadas",
  "Coupon Codes": "Códigos de cupón",
  "Limited-Time Only": "Solo por tiempo limitado",
  "Sales Automation": "Automatización de ventas",
  "Keep 100% your revenue": "Conserva el 100% de los ingresos por ventas",
  "Keep 90% your revenue": "Conserva el 90% de los ingresos por ventas",
  "Platform Fee Waive On Free Plans":
    "Exención de tarifas de plataforma en planes gratuitos",
  "MP3 Custom Voice Tag ( Audio Tag for Streaming ) Restrictions for Free Users":
    "Restricciones de etiqueta de voz personalizada en MP3 (etiqueta de audio para transmisión) para usuarios gratuitos",
  "Sales & Premium Analytics": "Análisis de ventas y premium",
  "Upload 10 Sound Kits (.zip) Files": "Subir 10 kits de sonido (.zip)",
  "Exclusive Tracks Link Sharing": "Compartir enlaces de pistas exclusivas",
  All: "Todo",
  "Add ": "Añade ",
  useForMusicRecording: "Usar para grabación de música",
  dontUse: "No usar",
  use: "Usar",
  upToFreeDownloads: "Hasta 0 descargas gratuitas",
  unlimitedFreeDownloads: "Descargas gratuitas ilimitadas",
  allowed: "Permitido",
  notAllow: "No permitido",
  UpTo: "Hasta 0",
  allowedBroadcastingRights: "Derechos de transmisión permitidos",
  notAllowBroadcastingRights: "No permitir derechos de transmisión",
  others: "Otros",
  upload: "Subir",
  shareLinkPlaylist: "Compartir Enlace de Lista de Reproducción",
  searchItem: "Buscar Articulo",
  searchCountry: "Buscar País",
  proOption: "PRO (Opcional)",
  publisherIpi: "Editor IPI (Opcional)",
  selectProSociety: "Seleccionar Sociedad PRO",
  enterIpi: "Ingrese IPI",
  requireDownloadersTo: "Requerir a los Descargadores que",
  freeDownloadUsagePolicy: "Política de Uso de Descarga Gratuita",
  pleaseNoteFreeDownload:
    "Ten en cuenta que las descargas gratuitas son solo para uso no lucrativo. Esto significa que no se permite la distribución, actuaciones públicas ni monetización. ¡Disfruta con responsabilidad!",
  useTrackCommercially:
    "Para utilizar esta pista con fines comerciales, necesitarás una licencia del productor. Puedes comprar una justo donde obtuviste la versión gratuita, si está disponible.",
  disagree: "Discrepar",
  agree: "Concordar",
  followForAFreeDownload: "Sigue para una descarga gratuita",
  desFollowGetDownload:
    "Toque 'Seguir' para obtener tu descarga gratuita. Una vez que estés siguiendo al creador, el archivo será completamente tuyo.",
  sortBy: "Sort By",
  proSocietyIsRequired: "Por favor, incluya su IPI con la selección PRO.",
  minIpi: "IPI mínimo 9 caracteres",
  maxIpi: "IPI máximo 11 caracteres",
  referralProgram: "Programa de Referidos",
  referrals: "Referidos",
  totalReach: "Alcance Total",
  totalUsersReferred: "Usuarios Totales Referidos",
  totalSuccessfullReferrals: "Total de Referencias Exitosas",
  referralsConverted: "Referidos Convertidos",
  pendingInvites: "Invitaciones Pendientes",
  inviteYourFriendsEmail: "Invita a tus amigos por correo electrónico",
  doubleTheBenefits: "Duplica los Beneficios: ¡Refiere y Gana!",
  invite: "Invitar",
  desSharingYourReferralLink:
    "Comparte tu enlace de referencia para darles a tus amigos un descuento del 5% en su primera membresía anual. Si lo utilizan, ¡te recompensaremos con una comisión de $5 también!",
  criteriaNewReferrals: "Criterios para Nuevas Referencias",
  uniqueNewSubscriber: "1. Nuevo Suscriptor Único:",
  desUniqueNewSubscriber:
    "Cada suscriptor referido debe tener un correo electrónico único y no puede tener una cuenta preexistente de Superproductor.",
  noSelfReferral: "2. No Autoreferencias:",
  desNoSelfReferral:
    "Los usuarios no pueden referirse a sí mismos; los detalles del referente y el referido deben ser distintos.",
  successfullPayment: "3. Pago Exitoso:",
  desSuccessfullPayment:
    "La referencia solo se valida cuando el nuevo suscriptor realiza un pago exitoso por su primera membresía anual.",
  validReferralLink: "4. Enlace de Referencia Válido:",
  desValidReferralLink:
    "Los referidos deben utilizar el enlace único proporcionado por un usuario existente de Superproductor para registrarse.",
  activeAccount: "5. Cuenta Activa:",
  desActiveAccount:
    "El nuevo suscriptor debe mostrar actividad (por ejemplo, iniciar sesión, utilizar funciones) durante los primeros 30 días después de registrarse.",
  termsOfService: "Términos de Servicio",
  shareThroughSocialMedia: "Compartir a través de redes sociales ",
  yourShareLink: "Tu enlace para compartir",
  commissionForYou:
    "Comisiones de $5 para ti, un descuento del 5% para los nuevos suscriptores en su primera membresía anual.",
  recentReferrals: "Referencias Recientes",
  users: "Usuarios",
  comissionsEarn: "Comisiones Ganadas",
  emailAdressIsRequired: "El correo electrónico es un campo obligatorio",
  emailAdressIsNotValid: "La dirección de correo electrónico no es válida",
  sendLinkReferralSuccess: "Enviar enlace de referencia exitosamente",
  noRecentReferrals: "Sin Referencias Recientes",
  licenseStatus: "Estado de Licencia",
  expireOn: "Caduca en",
  indefinitely: "Indefinidamente",
  monthlyReferrals:
    "📆 Referidos Mensuales: Recomienda a tus amigos y obtén una prueba gratuita de 1 mes.",
  annualReferrals:
    "🎉 Referencias Anuales: Refiere a tus amigos para disfrutar de una tarifa anual especial de $199.99 (solo $16.67 al mes). Como el que refiere, ganarás $10 en créditos por cada recomendación, con un límite de $200.",
  asTheReferrer:
    "Como el que refiere, ganarás $5 en créditos por cada recomendación exitosa; recuerda que aún se aplica una comisión del 10% sobre las ventas.",
  commissionWaiverOnReferral:
    "Además, la exención del 10% de comisión en las ventas de referencia es el toque final.",
  creditBalance: "Saldo de Crédito",
  enterEmailAddress: "Introducir dirección de correo electrónico",
  unlockRewards: "Desbloquear Recompensas: Compartir & Ganar!",
  dashboardOverview: "Resumen del Panel de Control",
  monthlyActiveListeners: "Oyentes Activos Mensuales",
  thanLastMonth: "que el mes pasado",
  dateJoined: "Fecha de ingreso",
  totalCustomers: "Clientes Totales",
  totalRefers: "Referidos Totales",
  totalRevenue: "Ingresos Totales",
  totalItems: "Artículos Totales",
  lastLoggedInOn: "Última sesión iniciada el ",
  quickAnalytics: "Análisis Rápido: ",
  yourDataAtAGlance: "Tus Datos de un Vistazo",
  repost: "Repost",
  newFollowers: "Nuevos Seguidores",
  upcomingLicenseExpiry: "Monitoreo de Licencias Vendidas",
  search: "Buscar",
  sendReminder: "Enviar Recordatorio",
  totalFavorites: "Favoritos Totales",
  totalRepost: "Reposts Totales",
  notificationLicenseExpiry: "Tu licencia para ",
  isAboutToExpire: "está a punto de vencer.",
  pleaseRenewToContinue:
    " Por favor, renueva para seguir disfrutando del producto.",
  sendReminderSuccess: "Recordatorio Enviado con Éxito",
  noUpcomingLicenseExpiry: "Sin Próxima Caducidad de Licencia",
  freeDownloadButton: "Descarga gratuita",
  audioFilesWav: "Archivos de audio WAV para descarga",
  pending: "Pendientes",
  last30Days: "Últimos 30 días",
  last90Days: "Últimos 90 días",
  last365Days: "Últimos 365 días",
  last730Days: "Últimos 730 días",
  theSaferEasierWayToPay: "La forma más segura y fácil de pagar",
  desDiconnectStripe:
    "Desconectar tu cuenta de Stripe evitará que recibas pagos y ofrezcas servicios y productos en nuestro sitio web",
  trackNo: "Numero De Pista",
  licenseAndContract: "Licencias y Contratos",
  dateReleased: "Fecha de lanzamiento",
  fileDelivery: "Entrega de archivo",
  actions: "Acciones",
  packNo: "Pack no",
  soundPackType: "Tipo de Kit de Sonido",
  playlistNo: "Playlist no",
  playlistStatus: "Estado de la lista de reproducción",
  playlistData: "Datos de la lista de reproducción",
  dateAdded: "Fecha de añadido",
  trackAvailability: "Disponibilidad de seguimiento",
  chooseYourLicense: "Elige tu licencia",
  uploadTrackFiles: "Subir archivos de pistas",
  autoTagged: "Etiquetado automático",
  customTagged: "Etiquetado personalizado",
  trackoutStems: "Pistas de separación",
  reviewFiles: "Revisar archivos",
  previousStep: "Paso anterior",
  nextStep: "Próximo paso",
  selectAFile: "Seleccionar un archivo",
  uploadGuidelinesForUntaggedWavFiles:
    "Subir pautas para archivos WAV sin etiquetar",
  forOptimalQualityUploadUntagged:
    "Para obtener la mejor calidad, sube archivos WAV sin etiquetar a 44.1 kHz/24 bits o superior. Simplemente arrastra tu archivo a la sección de audio sin etiquetar. (El tamaño del archivo WAV no debe superar los 164 MB).",
  uploadingWavFileHereAutomatically:
    "Subir un archivo WAV aquí genera automáticamente una versión en mp3 con una etiqueta 'Superproductor' para la protección de la pista.",
  uploadGuidelinesForUntaggedMp3Files:
    "Pautas de carga para archivos MP3 etiquetados",
  forOptimalPerformanceUploadYourMp3:
    "Para un rendimiento óptimo, carga tu MP3 con etiquetas personalizadas (se recomienda 128kbps, 44.1kHz) (El tamaño del archivo MP3 no debe superar los 164 MB).",
  thisFileWillBeAvailableForStreaming:
    "Este archivo estará disponible para transmisión o descarga gratuita si se ofrece. De lo contrario, se aplicará la etiqueta predeterminada de Superproductor.",
  uploadGuidelinesForTrackoutZipFiles:
    "Pautas de carga para archivos ZIP de pistas separadas",
  uploadZipFileOfYourTrackStems:
    "Sube un archivo ZIP (ZIP de no más de 1 GB) de las pistas de tu canción. Aunque es opcional, las pistas a menudo se esperan para licencias profesionales o acuerdos exclusivos.",
  includingThemCanSignificantlyIncrease:
    "Incluirlos puede aumentar significativamente el atractivo de tu canción para posibles compradores.",
  proceedAddTrack: "Continuar para Agregar Pista",
  wavOrMp3FilesSizeNoMoreLargerThan:
    "( Archivos WAV con un tamaño no superior a 164 MB )",
  mp3FileSizeNoMoreLargerThan: "( Tamaño de archivo MP3 no superior a 164 MB )",
  zipFileSizeNoMoreLargerThan: "Tamaño de archivo ZIP no superior a 2 GB",
  minimunMegabyte:
    "Por favor, selecciona un audio que tenga un tamaño inferior a 164 MB",
  minimunGigabyte:
    "Por favor, selecciona un audio que tenga un tamaño inferior a 2 GB",
  uploadReady: "Pistas cargadas exitosamente",
  goToEditTrack: "Continuar con la edición de la pista",
  proceedToEdit: "Continuar con la Edición",
  fileSelectionNeededForNextTrack:
    "Se necesita selección de archivo para la siguiente pista",
  stepIsCrucialToAchieve:
    "Este paso es crucial para lograr una coincidencia perfecta entre los archivos entregados y tus contratos.",
  readyToFineTuneYourTrack:
    "¿Listo para ajustar tus pistas? Edita los detalles de la pista para un lanzamiento sin problemas.",
  wavFilesAutoConverted:
    "Archivos WAV convertidos automáticamente a copias MP3 de 320 kbps",
  weAutomaticallyConvertYourWavFilesToMp3:
    "Convertimos automáticamente tus archivos WAV a MP3 de 320 kbps, garantizando audio de alta calidad tanto para la entrega como para la transmisión. Esto asegura que tu música esté lista para impresionar, sin ningún trabajo adicional de tu parte.",
  mp3StreamingTracksTagAutoGenerated:
    "Etiqueta de pistas de streaming MP3 generada automáticamente",
  autoGeneratedMp3TagsSimplifyYourTrack:
    "Las etiquetas MP3 generadas automáticamente simplifican la transmisión de tus pistas y aumentan la seguridad. Tu música está lista para ser descubierta y disfrutada en nuestras plataformas por oyentes de todo el mundo.",
  yourOfferIsLowerThanMinimumOfferPrice:
    "Tu oferta es inferior al precio mínimo de oferta",
  resumeEditingYourTrackAwait: "Continuar edición: ¡Tus pistas te esperan",
  desResumeEditingYourTrackAwait:
    "Pistas adicionales están esperando ser editadas. Por favor, dirígete a 'Revisar tus Subidas'; tus pistas están más cerca de llegar a tu audiencia.",
  reviewYourUploads: "Revisa tus Subidas",
  enabled: "Activado",
  highlight: "Resaltar",
  popular: "POPULAR",
  quickStepNeeded: "¡Paso rápido necesario! 👍 ",
  toAddItemsToYourCart:
    "Para agregar elementos a tu carrito, se requiere un inicio de sesión rápido o configuración de cuenta. Ayuda a mantener segura y personalizada tu experiencia de compra. ",
  shoppingContinue:
    "No te preocupes, es rápido y volverás inmediatamente a esta página para continuar tu experiencia de compra con nosotros.",
  alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
  verificationCode: "Código de Verificación",
  pleaseEnterYourConfirmationWeSentViaEmail:
    "Por favor, ingresa la confirmación que te enviamos por correo electrónico",
  promoCodeAccepted: "¡Código promocional aceptado!",
  desPromoCodeAccepted:
    "Hemos aplicado tu código promocional. Tu descuento se refleja en el total. ¿Listo para realizar tu pedido?",
  share: "Compartir",
  like: "Me gusta",
  goToTrack: "Ir a la Pista",
  goToProfile: "Ir al Perfil",
  unlike: "Desmarcar",
  buy: "Comprar",
  goToSoundPack: "Ir a Sound Kit",
  coverArt: "Portada",
  badges: "Insignias",
  paymentsActive: "Pagos Activos",
  hotProducer: "Productor de Moda",
  spVerified: "SP Verificado",
  searchByArtistSpotify: "Buscar por Artista en Spotify",
  searchByTrackSpotify: "Buscar por Canción",
  artistWhoIWorkWith: "Artista con los que trabajo",
  artists: "Artistas",
  expertise: "Pericia",
  daw: "DAW",
  accountSettings: "Configuración de la Cuenta",
  loginAndSecurity: "Inicio de Sesión y Seguridad",
  currentPassword: "Contraseña Actual",
  newPassword: "Nueva Contraseña",
  confirmNewPassword: "Confirmar Nueva Contraseña",
  passwordMustBeAtLeastCharacters:
    "La contraseña debe tener al menos 8 caracteres",
  passwordDoesNotMatch: "La contraseña no coincide",
  logoutAllDevices: "Cerrar sesión en todos los dispositivos",
  Connect: "Conectar",
  searchListMailChimp: "Buscar lista mailchimp",
  linkedAccounts: "Complementos de Marketing de Terceros",
  emailCapture: "Captura de correo electrónico",
  inputYourEmailAddressAndFullName:
    "Introduce tu dirección de correo electrónico y tu nombre completo",
  weWillDeliverYourFreeTrackToTheEmailAddress:
    "Entregaremos tu pista gratuita de inmediato. ¡Gracias!",
  emailAddress: "Dirección de correo electrónico",
  provideEmailAddress: "Proporciona dirección de correo electrónico",
  provideName: "Proporciona nombre",
  fullName: "Nombre completo",
  continueUploadMoreTracks: "Continuar subiendo más pistas",
  startOverWithNewUpload: "Comenzar de nuevo con una nueva carga",
  bulkTrackUpload: "Carga masiva de pistas",
  lastName: "Apellido",
  provideLastName: "Proporcione Apellido",
  customers: "Clientes",
  myCustomers: "Mis Clientes",
  type: "Tipo",
  downloadDate: "Fecha de Descarga",
  downloadedItem: "Artículo Descargado",
  downloadOn: "Descargado el",
  searchEmail: "Buscar Correo Electrónico",
  deleted: "Eliminado",
  recentCustomerActivity: "Actividad Reciente del Cliente",
  startNowDayTrial: "Comienza Ahora la Prueba de 7 Días",
  deactivateTrial: "Desactivar Prueba",
  upgradeYourPlanToContinue: "Actualiza tu plan para continuar",
  featureIsLocked:
    "Esta función está bloqueada—Actualiza tu plan para obtener acceso",
  upgradeYourPlan: "Actualiza tu plan",
  titleUpgradeYourPlanToEnableFreeDownload:
    "Actualiza tu plan para habilitar la descarga gratuita",
  desUpgradeYourPlanToEnableFreeDownload:
    "¡Hola! Para habilitar las opciones de descarga gratuita, por favor actualiza tu plan",
  titleUpgradeYourPlanAddCollaborator:
    "Actualiza tu plan para agregar un colaborador",
  desUpgradeYourPlanAddCollaborator:
    "¡Hola! Para agregar un colaborador, por favor actualiza tu plan",
  titleUpgradeYourPlanChooseAContractExclusive:
    "Actualiza tu plan para elegir un contrato exclusivo",
  desUpgradeYourPlanChooseAContractExclusive:
    "¡Hola! Para elegir un contrato exclusivo, por favor actualiza tu plan",
  chooseYourFeeStructure: "Elige tu estructura de tarifas",
  agreedToCoverFee: "Acepté cubrir el 10% de la tarifa",
  buyerCoverFee: "El comprador cubre el 10% de la tarifa",
  joinNow: "Únete ahora!",
  selectChooseYourFeeStructure: "Selecciona Elige Tu Estructura de Tarifas",
  totalService: "Servicio Total",
};
