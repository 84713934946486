import { Box, Flex, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import "./styles.scss";
export default function SkeletonLicenseExpiryMobile() {
  return (
    <Box
      m={{ base: "25px 0px", md: "30px 0px" }}
      className="item-license-expiry"
    >
      <Skeleton
        mb={{ base: "25px", md: "30px" }}
        w="280px"
        h={{ base: "24px", md: "28px", xl: "32px" }}
        borderRadius="20px"
      />
      <Box
        padding={{ base: "15px 20px 20px 20px", xl: "50px 50px 50px 50px" }}
        background="#25252A"
        borderRadius="8px"
      >
        <Flex justifyContent="end">
          <Skeleton
            mb="30px"
            w={{ base: "100%", md: "350px" }}
            h={{ base: "28px", md: "36px", xl: "40px" }}
            borderRadius="6px"
          />
        </Flex>

        <Box>
          {Array.from({ length: 10 }).map((item, index) => (
            <Box key={index}>
              <Box mb="20px" borderRadius="4px">
                <Flex justifyContent="space-between" mb="10px">
                  <Skeleton w="110px" />
                  <Skeleton w="110px" mr="0px" />
                </Flex>
                <Flex justifyContent="space-between" mb="15px">
                  <Flex alignContent="center">
                    <Skeleton
                      w={"35px"}
                      h={"36px"}
                      background="#535353"
                      borderRadius="6px"
                    />
                    <Box>
                      <Skeleton w="120px" h="14px" />
                      <Flex alignItems="center">
                        <Skeleton borderRadius="4px" />
                        <Skeleton w="80px" h="14px" />
                      </Flex>
                    </Box>
                  </Flex>
                  <Flex>
                    <Skeleton w="40px" h="40px" borderRadius="full" />
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Skeleton h="14px" mr="0px" />
                      <Skeleton h="14px" mr="0px" />
                    </Box>
                  </Flex>
                </Flex>

                <Flex justifyContent="space-between" mb="10px">
                  <Skeleton w="110px" />
                  <Skeleton w="110px" mr="0px" />
                </Flex>
                <Flex justifyContent="space-between" mb="15px">
                  <Box>
                    <Skeleton w="90px" borderRadius="4px" />
                  </Box>
                  <Box display="flex" flexDirection="column" alignItems="end">
                    <Skeleton w="90px" borderRadius="4px" mr="0px" />
                    <Skeleton w="120px" h="14px" mr="0px" />
                  </Box>
                </Flex>
                <Flex justifyContent="space-between" mb="15px">
                  <Skeleton w="110px" />
                  <Skeleton w="110px" mr="0px" />
                </Flex>
                <Flex justifyContent="space-between">
                  <Skeleton h="20px" w="60px" background="#535353" />
                  <Skeleton
                    w={{ base: "70px", md: "100%", lg: "50px" }}
                    h="40px"
                    borderRadius="4px"
                    background="#535353"
                    mr="0px"
                  />
                </Flex>
              </Box>
              <Divider m="20px 0px" borderColor="#535353" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
