import { createSelector } from "@reduxjs/toolkit";
import { TrackSoundKit } from "app/models";
import { RootState } from "types";

const selectorMySoundKit = (state: RootState) => state.mySoundKit;

export const favoriteSKSelector = createSelector(
  selectorMySoundKit,
  ({ favoritesSK }) => favoritesSK
);

export const releasesSKSelector = createSelector(
  selectorMySoundKit,
  ({ releasedSK }) => releasedSK
);

export const privateSKSelector = createSelector(
  selectorMySoundKit,
  ({ privateSK }) => privateSK
);

export const scheduleSKSelector = createSelector(
  selectorMySoundKit,
  ({ scheduleSK }) => scheduleSK
);

export const draftsSKSelector = createSelector(
  selectorMySoundKit,
  ({ draftsSK }) => draftsSK
);

export const pendingSKSelector = createSelector(
  selectorMySoundKit,
  ({ pendingSK }) => pendingSK
);

export const isLoadingSoundkitSelector = createSelector(
  selectorMySoundKit,
  ({ isLoading }) => isLoading
);

export const isFavoritesSKSelector = (item?: TrackSoundKit) =>
  createSelector(selectorMySoundKit, ({ favoritesSK }) => {
    return (
      (favoritesSK || []).findIndex(
        (favItem: TrackSoundKit) => favItem._id === item?._id
      ) !== -1
    );
  });

export const repostSKSelector = createSelector(
  selectorMySoundKit,
  ({ repostsSK }) => repostsSK
);

export const isRepostsSKSelector = (item?: TrackSoundKit) =>
  createSelector(selectorMySoundKit, ({ repostsSK }) => {
    return (
      (repostsSK || []).findIndex(
        (favItem: TrackSoundKit) => favItem._id === item?._id
      ) !== -1
    );
  });

export const individualSoundKitSelector = createSelector(
  selectorMySoundKit,
  ({ soundkitDetail }) => soundkitDetail
);
