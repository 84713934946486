import { createReducer } from "@reduxjs/toolkit";
import { ItemNotifications } from "app/models";
import * as notificationActions from "app/redux/actions/notifications";

export interface InitNotificationState {
  data: {
    items: ItemNotifications[];
    options: {
      page: number;
      loadingMore: boolean;
      hasMore: boolean;
    };
  };
}

const initialState: InitNotificationState = {
  data: {
    items: [],
    options: {
      page: 1,
      loadingMore: false,
      hasMore: true,
    },
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(notificationActions.getNotifications.pending, (state, action) => {
      return {
        ...state,
        data: {
          ...state.data,
          options: {
            ...state.data.options,
            loadingMore: action.meta.arg?.loadingMore || false,
            page: action.meta.arg?.page,
          },
        },
      };
    })
    .addCase(
      notificationActions.getNotifications.fulfilled,
      (state, action) => {
        const newNotification =
          state.data.options.page === 1
            ? action?.payload?.data
            : [...state.data.items, ...action?.payload?.data];
        return {
          ...state,
          data: {
            ...state.data,
            items: newNotification,
            options: {
              ...state.data.options,
              hasMore: action.payload?.data?.length === 25,
              loadingMore: false,
            },
          },
        };
      }
    )
    .addCase(
      notificationActions.readNotifications.fulfilled,
      (state, action) => {
        const { data } = state;
        const ids = action.payload?.data || [];
        const currentNotifications = data.items || [];
        const newNotifications = currentNotifications.map((notification) => {
          if (ids.includes(notification?._id)) {
            return {
              ...notification,
              isRead: true,
            };
          }
          return notification;
        });

        return {
          ...state,
          data: {
            ...state.data,
            items: newNotifications,
          },
        };
      }
    )
    .addCase(
      notificationActions.deleteNotifications.fulfilled,
      (state, action) => {
        return {
          ...state,
          data: {
            ...state.data,
            items: [],
          },
        };
      }
    )
    .addCase(
      notificationActions.deleteNotifications.rejected,
      (state, action) => {
        return {
          ...state,
          data: {
            ...state.data,
            items: [...state.data.items],
          },
        };
      }
    )
);
