import {
  Button,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
interface ModalProps {
  isOpen?: boolean;
  handleClick?: any;
  licenseId: string;
}

export default function ModalDeleteLicense({
  isOpen = false,
  handleClick,
  licenseId,
}: ModalProps) {
  const { t } = useTranslation();
  const { handleDeleteLicenseContract } = useLicenseContracts();

  const handleDeleteLicense = (id: string) => {
    handleDeleteLicenseContract(id);
    handleClick("delete");
  };
  return (
    <Modal
      colorScheme="black"
      isOpen={isOpen}
      onClose={() => handleClick("delete")}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p="16px 24px 0px"
          justifyContent="center"
          display="flex"
          fontSize="32px"
          mt="20px"
        >
          {t("deleteLicense")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => handleClick("delete")}
          right={2}
          top="15px"
          fontSize="22px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody display="flex" justifyContent="center">
          <Text w="70%" textAlign="center">
            {t("notRecoverIt")}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center" mb="20px">
          <Flex justifyContent="center" w="full">
            <Button
              backgroundColor="#212125"
              borderRadius="4px"
              mr={3}
              onClick={() => handleClick("delete")}
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
            >
              {t("cancel")}
            </Button>
            <Button
              backgroundColor="#ED3536"
              color="white"
              borderRadius="4px"
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
              onClick={() => handleDeleteLicense(licenseId)}
            >
              {t("delete")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
