/**
 * Create the store with dynamic reducers
 */

import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import rootReducer from "app/redux/reducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { RootState } from "types";
import { WebStorage } from "redux-persist/es/types";

interface PersistConfigProps {
  key: string;
  storage: WebStorage;
  blacklist: string[];
  timeout: null;
}

const persistConfig: PersistConfigProps = {
  key: "root",
  storage,
  blacklist: [
    "error",
    "status",
    "modal",
    "sticky",
    "track",
    "addSoundkit",
    "profile",
    "licenseAndContracts",
    "player",
    "analytics",
    "promoCode",
    "bulkOffer",
  ],
  timeout: null,
};

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const store = configureStore({
    reducer: persistReducer<RootState>(persistConfig, rootReducer),
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== "production",
  });
  const persistor = persistStore(store);

  return { store, persistor };
}
