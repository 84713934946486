import React from "react";

const IconFileMusic = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 3C3.25 2.0335 4.0335 1.25 5 1.25H15.5C15.7126 1.25 15.9152 1.34024 16.0575 1.49828L20.5575 6.49828C20.6814 6.63599 20.75 6.81472 20.75 7V21C20.75 21.9665 19.9665 22.75 19 22.75H5C4.03351 22.75 3.25 21.9665 3.25 21V3ZM5 2.75C4.86193 2.75 4.75 2.86193 4.75 3V21C4.75 21.1381 4.86192 21.25 5 21.25H19C19.1381 21.25 19.25 21.1381 19.25 21V7.2878L15.166 2.75H5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1722 9.66756C16.3559 10.0388 16.2039 10.4887 15.8326 10.6724L13.25 11.9503V16.7502C13.25 17.1644 12.9142 17.5002 12.5 17.5002C12.0858 17.5002 11.75 17.1644 11.75 16.7502V11.4846C11.75 11.1994 11.9118 10.9388 12.1674 10.8124L15.1674 9.32795C15.5386 9.14426 15.9885 9.2963 16.1722 9.66756Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 15.25C9.80964 15.25 9.25 15.8096 9.25 16.5C9.25 17.1904 9.80964 17.75 10.5 17.75C11.1904 17.75 11.75 17.1904 11.75 16.5C11.75 15.8096 11.1904 15.25 10.5 15.25ZM7.75 16.5C7.75 14.9812 8.98122 13.75 10.5 13.75C12.0188 13.75 13.25 14.9812 13.25 16.5C13.25 18.0188 12.0188 19.25 10.5 19.25C8.98122 19.25 7.75 18.0188 7.75 16.5Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 1.25C15.4142 1.25 15.75 1.58579 15.75 2V6.25H20C20.4142 6.25 20.75 6.58579 20.75 7C20.75 7.41421 20.4142 7.75 20 7.75H15C14.5858 7.75 14.25 7.41421 14.25 7V2C14.25 1.58579 14.5858 1.25 15 1.25Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconFileMusic;
