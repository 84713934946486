import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import BackgroundImagePlaylistIcon from "app/assets/icons/BackgroundImagePlaylistIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";

export default function SkeletonPlaylistItemProfile() {
  const fakeDataSekeleton = Array.from({ length: 10 });

  return (
    <Box>
      {fakeDataSekeleton.map((item, index) => (
        <Flex
          padding={{ base: "10px 0px", md: "10px 5px 10px 0px" }}
          marginTop="15px"
          alignItems="center"
          key={index}
          w="100%"
        >
          <Box w="8%" display={{ base: "none", md: "block" }} />
          <Flex alignItems="center" w={{ base: "22%", md: "10%" }}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box>
                <BackgroundImagePlaylistIcon />
              </Box>
              <Image
                fallbackSrc={ImageDefault}
                borderRadius="4px"
                objectFit="cover"
                w="55px"
                height="55px"
                marginTop="-35px"
              />
            </Box>
          </Flex>

          <Box w={{ base: "58%", md: "32%" }}>
            <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
            <Flex>
              <Skeleton h="16px" w="35px" mb="0px" />
              <Skeleton
                h="16px"
                w="109px"
                borderRadius="30px"
                mb="0px"
                mr="0px"
              />
            </Flex>
          </Box>

          <Flex
            alignItems="center"
            w="30%"
            display={{ base: "none", md: "flex" }}
          >
            <Skeleton w="40px" h="40px" borderRadius="full" />
            <Box>
              <Skeleton w="120px" ml="20px" />
              <Skeleton w="80px" ml="20px" />
            </Box>
          </Flex>
          <Flex gap="30px" w="20%">
            <Box>
              <Skeleton w="40px" />
              <Skeleton w="70px" />
            </Box>
            <Box>
              <Skeleton w="40px" />
              <Skeleton w="70px" />
            </Box>
          </Flex>
        </Flex>
      ))}
    </Box>
  );
}
