import { selectUserAdmin } from "app/redux/selectors/users";
import {
  collection,
  collectionGroup,
  doc,
  orderBy,
  query,
  updateDoc,
  where,
  getDocs,
} from "firebase/firestore";
import { useState, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { db } from "../config/firebase";
import { useCollectionQuery } from "./useCollectionQuery";
const today = new Date();
export const useConversation = () => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState<string[]>([]);
  const history = useHistory();
  const [totalMsg, setTotalMsg] = useState([]);
  const currentUser = useSelector(selectUserAdmin);
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = useMemo(() => {
    return new Date(year, month, 1, 0, 0, 0);
  }, [month, year]);
  const lastDay = useMemo(() => {
    return new Date(year, month + 1, 0, 23, 59, 59);
  }, [month, year]);

  const getAllMsgCurrentUser = useCallback(async () => {
    let contentMsg: any = [];
    const msg = query(
      collectionGroup(db, "messages"),
      where("sender", "==", currentUser._id),
      where("createdAt", ">=", firstDay),
      where("createdAt", "<=", lastDay)
    );
    const querySnapshot = await getDocs(msg);
    querySnapshot.forEach((doc) => {
      contentMsg.push(doc.data().content);
    });
    setTotalMsg(contentMsg);
  }, [currentUser._id, firstDay, lastDay]);

  const { data, loading } = useCollectionQuery(
    "conversations",
    query(
      collection(db, "conversations"),
      orderBy("updatedAt", "desc"),
      where("users", "array-contains", currentUser?._id),
      where("wasDeleted", "==", false)
    )
  );

  const allConversations = useMemo(() => {
    return data?.docs?.map((item) => {
      const details = item?.data();
      const readByMe = details?.lastMessageId
        ? details?.lastMessageId === details?.seen[currentUser?._id]
        : true; // lastMessageId === null -> readByMe = true
      return {
        ...details,
        readByMe,
        id: item.id,
      };
    });
  }, [data?.docs, currentUser?._id]);

  const handleCheckbox = useCallback(
    (id: string) => {
      const isChecked = !!selectAllCheckbox.find((item: string) => item === id);

      if (isChecked) {
        setSelectAllCheckbox(selectAllCheckbox.filter((item) => item !== id));
      } else {
        setSelectAllCheckbox([...selectAllCheckbox, id]);
      }
    },
    [selectAllCheckbox]
  );

  const handleSelectAll = useCallback(() => {
    const idConversation = (allConversations || [])?.map((item) => item?.id);
    setSelectAllCheckbox(idConversation);
  }, [allConversations]);

  const onDeleteConversation = () => {
    selectAllCheckbox?.forEach((item) =>
      updateDoc(doc(db, "conversations", item), {
        wasDeleted: true,
      })
    );
    history.push("/messages?tab=all");
  };

  return {
    totalMsg,
    handleCheckbox,
    selectAllCheckbox,
    allConversations,
    handleSelectAll,
    onDeleteConversation,
    getAllMsgCurrentUser,
    loading,
  };
};
