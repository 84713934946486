import { Icon } from "@chakra-ui/react";
import React from "react";

const IconSkeletonMusicEmailCustomers = () => {
  return (
    <Icon width="36px" height="36px" viewBox="0 0 72 72" fill="none">
      <rect width="72" height="72" rx="4" fill="#535353" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M60.9757 45.5176C60.9705 46.7693 61.0183 48.0387 60.7754 49.2687C59.9661 53.3635 55.3504 56.0737 49.9254 55.7426C46.6642 55.5431 44.3492 54.3165 43.1137 51.9802C41.6281 49.1713 43.0485 46.1336 46.4795 44.6402C48.5813 43.7256 50.9278 43.4353 53.2434 43.0771C56.4149 42.5864 56.7552 42.3019 56.7514 39.8005C56.7443 34.9349 56.7178 30.0692 56.6991 25.2041C56.6984 25.0577 56.6991 24.9113 56.6868 24.7654C56.5977 23.7142 56.132 23.4342 54.7872 23.644C50.0191 24.3877 34.7794 26.7525 31.9271 27.1867C30.1393 27.4589 29.8182 27.7458 29.811 29.1669C29.7742 36.7475 29.7581 44.3279 29.6954 51.9085C29.687 52.8408 29.5546 53.7914 29.2866 54.6996C28.2434 58.2404 25.0074 60.4112 20.1901 60.9113C17.5509 61.1849 15.1268 60.8671 13.1735 59.3698C9.72688 56.7274 10.4974 51.909 14.6488 49.9765C16.759 48.994 19.1289 48.605 21.4961 48.2085C22.1329 48.1019 22.7659 47.9757 23.3892 47.8312C24.7837 47.508 25.5019 46.7785 25.5214 45.6576C25.5415 44.4884 25.5602 43.3188 25.5615 42.1496C25.5699 34.4437 25.5731 26.7383 25.5796 19.032C25.5815 16.9246 26.3527 16.1848 29.032 15.7417C38.0702 14.2479 47.1085 12.754 56.1483 11.2705C56.9435 11.1403 57.756 11.0401 58.5666 11.0038C59.6918 10.9527 60.5269 11.4243 60.7769 12.2691C60.9377 12.8124 60.9914 13.3847 60.9933 13.9447C61.0082 19.6243 60.9965 40.674 60.9759 45.5181L60.9757 45.5176Z"
        fill="#24252A"
      />
    </Icon>
  );
};
export default IconSkeletonMusicEmailCustomers;
