import SelectInput from "app/components/SelectInput";
import React, { useMemo } from "react";
import { Control, Controller } from "react-hook-form";
import "./styles.scss";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import { TRACK_LICENSE } from "constants/contractType";
import ToastPlanSubscription from "../toast/toastplan";
import { useTranslation } from "react-i18next";

interface SelectItemProps {
  label: string;
  value: any;
}

interface SelectProps {
  data: SelectItemProps[];
  placeholder?: string;
  label?: string;
  error?: string | boolean;
  name: string;
  control: Control<any>;
  onValueChange?: (value: string) => string | void;
  className?: string;
  marginTop?: string;
  id?: string;
}

export default function SelectInputController({
  data = [],
  placeholder,
  label,
  error,
  name,
  control,
  className,
  marginTop,
  onValueChange = () => {},
  ...restProps
}: SelectProps) {
  const { t } = useTranslation();
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  return (
    <Controller
      name={name}
      render={({ field }) => (
        <SelectInput
          placeholder={placeholder}
          label={label}
          data={data}
          {...field}
          onChange={(value: string) => {
            if (
              field.name === "contractType" &&
              value === TRACK_LICENSE.EXCLUSIVE &&
              checkPlanSubscription
            ) {
              ToastPlanSubscription({
                title: t("titleUpgradeYourPlanChooseAContractExclusive"),
                description: t("desUpgradeYourPlanChooseAContractExclusive"),
              });
            } else {
              field.onChange({
                target: {
                  value,
                },
              });
              onValueChange(value);
            }
          }}
          error={error}
          className={className}
          marginTop={marginTop}
          {...restProps}
        />
      )}
      control={control}
    />
  );
}
