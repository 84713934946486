import { Box, Text } from "@chakra-ui/react";
import { CHARACTER_TITLE } from "constants/statusType";
import React from "react";
import { useTranslation } from "react-i18next";

interface CharaterLimitErrorProps {
  title: string;
}

export default function CharaterLimitError({ title }: CharaterLimitErrorProps) {
  const { t } = useTranslation();

  return (
    <Box>
      {title?.length > 0 &&
        title?.length < CHARACTER_TITLE.MAXIMUM_CHARACTERS && (
          <Text fontSize="14px" fontFamily="Cera Pro Regular">
            {CHARACTER_TITLE.MAXIMUM_CHARACTERS - title?.length}{" "}
            {t("charactersRemaining")}
          </Text>
        )}
      {title?.length === CHARACTER_TITLE.MAXIMUM_CHARACTERS && (
        <Text fontSize="14px" color="red" fontFamily="Cera Pro Regular">
          {t("youHaveExceededTheMaximum")}
        </Text>
      )}
      <Text
        fontSize="14px"
        color="#535353"
        mt="5px"
        fontFamily="Cera Pro Regular"
      >
        {t("aMinimumOfMaximum")}
      </Text>
    </Box>
  );
}
