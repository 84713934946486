import { ItemNotifications } from "app/models";
import {
  getNotifications,
  readNotifications,
} from "app/redux/actions/notifications";
import {
  notificationsSelector,
  selectNotificationOptions,
} from "app/redux/selectors/notifications";
import { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { timeFormat, todayTime, yesterdayTime } from "utils/timeAgo";

export const useNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  const optionsNotifications = useSelector(selectNotificationOptions);

  const [data, setData] = useState([]);

  const onGetNotifications = useCallback(
    (page) => {
      dispatch(getNotifications(page));
    },
    [dispatch]
  );

  const onReadNotifications = useCallback(
    (body) => {
      dispatch(readNotifications(body));
    },
    [dispatch]
  );

  const convertNotifications = useCallback(() => {
    const convertCondition = (notifications || []).reduce(
      (ac: any, item: ItemNotifications) => {
        let dateTime = timeFormat(item?.createdAt, "DD-MM-YYYY");
        const today = todayTime();
        const yesterday = yesterdayTime();

        if (dateTime === today) {
          dateTime = "Today";
        }
        if (dateTime === yesterday) {
          dateTime = "Yesterday";
        }
        ac[dateTime] = (ac[dateTime] || []).concat(item);
        return ac;
      },
      {}
    );

    const newNotifications = Object.entries(convertCondition).map(([k, v]) => ({
      [k]: v,
    }));

    setData(newNotifications);
  }, [notifications]);

  useEffect(() => {
    convertNotifications();
  }, [convertNotifications]);

  const totalUnread = useMemo(() => {
    return notifications?.reduce(function (sum, record) {
      return record?.isRead ? sum : sum + 1;
    }, 0);
  }, [notifications]);

  const notificationsUnread = (notifications || []).filter(
    (item: ItemNotifications) => !item.isRead
  );

  const totalNotificationsUnread = notificationsUnread?.length;
  const totalNotifications = notifications?.length;

  return {
    optionsNotifications,
    data,
    totalUnread,
    onGetNotifications,
    totalNotificationsUnread,
    totalNotifications,
    onReadNotifications,
    notifications,
  };
};
