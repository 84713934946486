import React, { useMemo, useCallback } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  CloseButton,
  ModalBody,
  Flex,
  Box,
  Text,
  Image,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import IconShareFeedPage from "app/assets/icons/IconShareFeedPage";
import IconHeartFeedPageUnlike from "app/assets/icons/IconHeartFeedPageUnlike";
import IconRepeatFeed from "app/assets/icons/IconRepeatFeedPage";
import IconLyricsProfile from "app/assets/icons/IconLyricsProfile";
import IconMyPlaylistProfile from "app/assets/icons/IconMyPlaylistProfile";
import Music from "app/assets/icons/musicSvg";
import IconPeopleProfile from "app/assets/icons/IconPeopleProfile";
import { TrackSoundKit, ShortLinkModel } from "app/models";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { TRACK_TYPE } from "constants/trackTypes";
import {
  isFavoritesSKSelector,
  isRepostsSKSelector,
} from "app/redux/selectors/mySoundKit";
import {
  isFavoriteSelector,
  isRepostsSelector,
} from "app/redux/selectors/myTrack";
import { useSelector, useDispatch } from "react-redux";
import { useProfile } from "hooks/profile/useProfile";
import { selectLoggedIn } from "app/redux/selectors/users";
import { getLyrics } from "app/redux/actions/tracks";
import { useHistory } from "react-router-dom";
import { shortLinkTrackSoundkitDetail } from "utils/getData";
import IconHeartFeedPageLike from "app/assets/icons/IconHeartFeedPageLike";
import IconUnRepeatFeed from "app/assets/icons/IconUnRepeatFeedPage";

interface ModalPopoverProfileProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  track: TrackSoundKit;
  isSK: boolean;
  isRecentAction: boolean;
  onOpenOptionsModal?: (track: TrackSoundKit, isLyric?: boolean) => void;
  onOpenModalShareLink: (track: TrackSoundKit) => void;
  setShortLinkUrl: (value: ShortLinkModel) => void;
  setLoadingUrl: (value: boolean) => void;
  handleShowModalAddTrack?: (id: string) => void;
}
export default function ModalPopoverProfile({
  showModal,
  setShowModal,
  track,
  isSK,
  isRecentAction,
  onOpenOptionsModal = () => {},
  onOpenModalShareLink = () => {},
  setShortLinkUrl,
  setLoadingUrl,
  handleShowModalAddTrack,
}: ModalPopoverProfileProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const isFavorite = useSelector(
    isSK ? isFavoritesSKSelector(track) : isFavoriteSelector(track)
  );
  const isRepost = useSelector(
    isSK ? isRepostsSKSelector(track) : isRepostsSelector(track)
  );
  const loggedIn = useSelector(selectLoggedIn);

  const { handleAddFavorites, handleAddRepost } = useProfile();
  const handleRedirectPage = useCallback(
    (router) => {
      history.push(router);
    },
    [history]
  );
  const handleGetLyrics = useCallback(() => {
    if (isSK) {
      onOpenOptionsModal(track, true);
      dispatch(getLyrics({ soundPackId: track?._id, isSoundPack: true }));
      return;
    }
    onOpenOptionsModal(track, true);
    dispatch(getLyrics({ trackId: track?._id }));
  }, [dispatch, isSK, onOpenOptionsModal, track]);
  const handleShortLoading = useCallback(
    (shortLink: ShortLinkModel) => {
      setShortLinkUrl(shortLink);
      setLoadingUrl(false);
    },
    [setLoadingUrl, setShortLinkUrl]
  );

  const createShortLinkUrl = useCallback(async () => {
    const hostName = window.location.host;
    setLoadingUrl(true);
    onOpenModalShareLink(track);
    if (isSK) {
      const shortLink = await shortLinkTrackSoundkitDetail({
        originalURL: `${hostName}/soundkit/${track?.slug}`,
      });
      handleShortLoading(shortLink?.data?.data);
    } else {
      const shortLink = await shortLinkTrackSoundkitDetail({
        originalURL: `${hostName}/track/${track?.slug}`,
      });
      handleShortLoading(shortLink?.data?.data);
    }
  }, [handleShortLoading, isSK, onOpenModalShareLink, setLoadingUrl, track]);

  const listMenu = useMemo(
    () => [
      {
        name: t("share"),
        id: 0,
        icon: <IconShareFeedPage />,
        onClickButton: () => (loggedIn ? createShortLinkUrl() : null),
        show: true,
      },
      {
        name: isFavorite ? t("unlike") : t("like"),
        id: 1,
        icon: isFavorite ? (
          <IconHeartFeedPageLike />
        ) : (
          <IconHeartFeedPageUnlike color="#d4f70e" />
        ),
        onClickButton: loggedIn
          ? () => handleAddFavorites(track, isSK, isFavorite, isRecentAction)
          : () => null,
        show: true,
      },
      {
        name: t("repost"),
        id: 2,
        icon: isRepost ? <IconRepeatFeed /> : <IconUnRepeatFeed />,
        onClickButton: loggedIn
          ? () => handleAddRepost(track, isSK, isRepost, isRecentAction)
          : () => null,
        show: true,
      },
      {
        name: t("writeLyrics"),
        id: 3,
        icon: <IconLyricsProfile />,
        onClickButton: loggedIn ? handleGetLyrics : () => null,
        show: true,
      },
      {
        name: t("addToPlaylist"),
        id: 4,
        icon: <IconMyPlaylistProfile />,
        onClickButton: !loggedIn
          ? () => null
          : isSK
          ? () => null
          : () => handleShowModalAddTrack(track?._id),
        show: isSK ? false : true,
      },
      {
        name: isSK ? t("goToSoundPack") : t("goToTrack"),
        id: 5,
        icon: <Music />,
        onClickButton: () =>
          isSK
            ? handleRedirectPage(`/soundkit/${track?.slug}`)
            : handleRedirectPage(`/track/${track?.slug}`),
        show: true,
      },
      {
        name: t("goToProfile"),
        id: 6,
        icon: <IconPeopleProfile />,
        onClickButton: loggedIn
          ? () =>
              handleRedirectPage(
                `/user/${track?.createdBy?.name}?profileType=tracks`
              )
          : () => null,
        show: true,
      },
    ],
    [
      createShortLinkUrl,
      handleAddFavorites,
      handleAddRepost,
      handleGetLyrics,
      handleRedirectPage,
      handleShowModalAddTrack,
      isFavorite,
      isRecentAction,
      isRepost,
      isSK,
      t,
      track,
      loggedIn,
    ]
  );

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent margin={{ base: "10px", md: "0px" }} maxH="600px">
        <ModalHeader padding={"20px 40px"} fontFamily="Cera Pro Regular" />
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => setShowModal(false)}
          right={4}
          top="5px"
          fontSize="20px"
          position="absolute"
          color="#d4f70e"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "20px",
          }}
          padding="20px"
        />
        <ModalBody maxH="700px" overflow="scroll" padding="0px 16px 20px 16px">
          <Flex overflow="hidden" alignItems="center">
            <Image
              w={"72px"}
              h={"72px"}
              src={track?.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              border={
                !track?.coverUrl
                  ? "1px solid rgba(255, 255, 255, 0.14)"
                  : "none"
              }
            />
            <Box w="70%" ml="10px">
              <Text
                fontSize={{ base: "12px", md: "14px", xl: "16px" }}
                lineHeight="16px"
                fontWeight="900"
                color={"white"}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                fontFamily="Cera Pro Bold"
              >
                {track?.title?.slice(0, 55)}
              </Text>
              <Flex mt="5px">
                <Box
                  display="flex"
                  alignItems="center"
                  height="20px"
                  borderRadius="4px"
                  padding="5px 10px"
                  mr="5px"
                  mb="5px"
                  backgroundColor="#2b2e1b"
                >
                  <Text
                    fontSize={{ base: "10px", md: "12px" }}
                    lineHeight="14px"
                    color="#d4f70e"
                    fontFamily="Cera Pro Regular"
                    w="max-content"
                  >
                    {t(TRACK_TYPE[track?.type])}
                  </Text>
                </Box>
                <Text
                  fontSize={{ base: "12px", md: "14px" }}
                  color="#464649"
                  _hover={{
                    textDecor: "underline",
                    color: "white",
                  }}
                  cursor="pointer"
                  fontFamily="Cera Pro Regular"
                  noOfLines={1}
                >
                  {`${t("by")} ${track?.createdBy?.name}`}
                </Text>
              </Flex>
            </Box>
          </Flex>
          <Divider borderColor="#535353" m="10px 0px" />
          {listMenu.map(
            (item) =>
              item.show && (
                <Flex
                  alignItems="center"
                  key={item.id}
                  _hover={{
                    background:
                      "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                    borderLeft: "1px solid #D4F70E",
                    borderRadius: "0px",
                  }}
                  padding="13px 10px"
                  cursor="pointer"
                  mb="3px"
                  onClick={() => {
                    item?.onClickButton();
                    setShowModal(false);
                  }}
                >
                  <Box w="20px">{item.icon}</Box>

                  <Text
                    className="fontCeraProRegular"
                    ml="20px"
                    fontSize="16px"
                    lineHeight="20px"
                  >
                    {item.name}
                  </Text>
                </Flex>
              )
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
