import { Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ErrorMessage({ error }: { error: string | undefined }) {
  const { t } = useTranslation();
  return (
    <Text color="red" fontSize="15px">
      {error && <Text color="red">{t(String(error))}</Text>}
    </Text>
  );
}
