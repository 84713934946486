import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Negotiations } from "app/models";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import EmptyNegotiation from "./emptyNegotiations";
import ItemNegotiationsDashboard from "./itemNegotiations";
import SkeletonNegotiations from "./skeletonNegotiations";

export default function NegotiationDashboard() {
  const { t } = useTranslation();

  const {
    activeNegotiations,
    inactiveNegotiations,
    onGetNegotiationsActive,
    onGetNegotiationsInactive,
    isLoading,
  } = useNegotiations();

  useEffect(() => {
    onGetNegotiationsActive();
    onGetNegotiationsInactive();
  }, [onGetNegotiationsActive, onGetNegotiationsInactive]);

  const renderNegotiationItems = (
    negotiations: Negotiations[],
    title: string,
    active = false
  ) => {
    return (
      <Box>
        <Text
          fontSize={{ base: "20px", md: "22px", xl: "24px" }}
          lineHeight="24px"
          mb="25px"
          position={{ base: "static", md: "sticky" }}
          top="317px"
          background="#18181c"
          zIndex={90}
          fontFamily="Cera Pro Regular"
          textAlign={{ base: "center", md: "start" }}
        >
          {title}
        </Text>

        {negotiations?.length > 0 ? (
          negotiations?.map((negotiation: Negotiations) => (
            <ItemNegotiationsDashboard
              negotiation={negotiation}
              isActive={active}
              key={negotiation._id}
            />
          ))
        ) : (
          <EmptyNegotiation />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        position={{ base: "static", md: "sticky" }}
        top="228px"
        zIndex={90}
        height="90px"
        background="#18181c"
        justifyContent={{ base: "center", md: "start" }}
        my="20px"
      >
        <Text fontSize={{ base: "28px", md: "32px" }} lineHeight="24px">
          {t("negotiations")}
        </Text>
      </Flex>
      {isLoading ? (
        <SkeletonNegotiations />
      ) : (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          spacing={{ base: "32px", md: "50px" }}
        >
          {renderNegotiationItems(activeNegotiations, t("active"), true)}
          {renderNegotiationItems(inactiveNegotiations, t("inActive"))}
        </SimpleGrid>
      )}
    </Box>
  );
}
