import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Back } from "app/assets/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import ReferralPerformance from "./referralPerformance";
import InviteYourFriendEmail from "./inviteYourFriends";
import CriteriaNewReferrals from "./criteriaNewReferrals";
import ShareSocialMedia from "./shareSocialMedia";
import RecentReferrals from "./recentReferrals";
import { useReferrals } from "hooks/Referrals/useReferrals";
import SkeletonReferrals from "./skeletonReferrals";

export default function ReferralLinkPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onGetGeneralInfoReferrals,
    onGetRecentReferrals,
    isLoadingGeneralInfoReferrals,
    isLoadingRecentReferrals,
  } = useReferrals();

  const redirectDashboard = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    onGetGeneralInfoReferrals();
    onGetRecentReferrals();
  }, [onGetGeneralInfoReferrals, onGetRecentReferrals]);

  return (
    <Box p={{ base: "40px 16px", lg: "40px", xl: "40px 80px" }}>
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center" mb="32px">
          <Box
            onClick={redirectDashboard}
            cursor="pointer"
            className="vector-button"
          >
            <Back />
          </Box>
          <Text fontSize={{ base: "24px", md: "32px" }}>{t("referrals")}</Text>
        </Flex>
      </Flex>
      {isLoadingGeneralInfoReferrals || isLoadingRecentReferrals ? (
        <SkeletonReferrals />
      ) : (
        <Flex
          gap={{ base: "20px", md: "30px", lg: "40px", xl: "70px" }}
          display={{ base: "block", md: "flex" }}
        >
          <Box w={{ base: "100%", md: "50%" }}>
            <ReferralPerformance />
            <InviteYourFriendEmail />
            <CriteriaNewReferrals />
          </Box>
          <Box w={{ base: "100%", md: "50%" }}>
            <ShareSocialMedia />
            <RecentReferrals />
          </Box>
        </Flex>
      )}
    </Box>
  );
}
