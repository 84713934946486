import { STATUS } from "constants/statusType";

const { ERROR, LOADING, NOT_STARTED, SUCCESS } = STATUS;

export interface InitStatusState {
  [key: string]: string;
}

const statusReducer = (state = {}, { type }: { type: string }) => {
  const matchesStart = /(.*)(_|\/)(REQUEST|pending)/.exec(type);
  const matchesError = /(.*)(_|\/)(ERROR|rejected)/.exec(type);
  const matchesSuccess = /(.*)(_|\/)(SUCCESS|fulfilled)/.exec(type);

  let status = NOT_STARTED;
  let key = null;

  if (matchesStart) {
    const [, requestName] = matchesStart;
    key = requestName;
    status = LOADING;
  } else if (matchesError) {
    const [, requestName] = matchesError;
    key = requestName;
    status = ERROR;
  } else if (matchesSuccess) {
    const [, requestName] = matchesSuccess;
    key = requestName;
    status = SUCCESS;
  }

  if (key) {
    return { ...state, [key]: status };
  }

  return state;
};

export default statusReducer;
