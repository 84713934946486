import { createReducer } from "@reduxjs/toolkit";
import {
  fetchSubscriptions,
  cancelSubscription,
  checkCreateNewLicense,
} from "../actions/subscriptions";

export interface InitSubscriptionState {
  data: [];
  isCreateNewLicense: boolean;
}

const initialState: InitSubscriptionState = {
  data: [],
  isCreateNewLicense: true,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(fetchSubscriptions.fulfilled, (state, action) => {
      return {
        ...state,
        data: action.payload,
      };
    })
    .addCase(cancelSubscription.fulfilled, (state) => ({
      ...state,
    }))
    .addCase(checkCreateNewLicense.fulfilled, (state, action) => {
      return {
        ...state,
        isCreateNewLicense: action.payload,
      };
    })
);
