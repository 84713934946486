import { Box, Flex, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import CartSummary from "../CartSummary";
import "../styles.scss";
import CardElementForm from "./CardElementStripe";

export default function AddStripeCard() {
  const { t } = useTranslation();
  const history = useHistory();
  const [name, setName] = useState<string>();

  const redirectDashboard = () => {
    history.push({
      pathname: "/cart/payment-methods",
    });
  };

  return (
    <Box
      p={{ base: "20px 20px", md: "20px 30px", xl: "40px 80px" }}
      className="payment-methods"
    >
      <Flex alignItems="center" mb="32px">
        <Box
          cursor="pointer"
          className="vector-button"
          onClick={redirectDashboard}
        >
          <Back />
        </Box>
        <Text fontSize="32px">{t("paymentMethods")}</Text>
      </Flex>
      <Flex
        gridGap="20px"
        className="card-element-form"
        justifyContent="space-between"
        display={{ base: "block", md: "flex" }}
      >
        <Box
          w={{ base: "100%", md: "55%", xl: "55%" }}
          ml={{ base: "0px", md: "20px", xl: "80px" }}
        >
          <CardElementForm name={name} setName={setName} />
        </Box>
        <Box w={{ base: "100%", md: "40%", xl: "25%" }} mt="20px">
          <CartSummary nameStripe={name} />
        </Box>
      </Flex>
    </Box>
  );
}
