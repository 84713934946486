import { Box } from "@chakra-ui/react";
import React from "react";

export default function TimelineNegotiations() {
  return (
    <Box>
      <Box
        w="7px"
        height="7px"
        background="#D8FD01"
        borderRadius="100%"
        margin="auto"
        mt="15px"
        mb="8px"
        opacity="0.1"
      />
      <Box
        w="7px"
        height="7px"
        background="#D8FD01"
        borderRadius="100%"
        margin="auto"
        mb="8px"
        opacity="0.2"
      />
      <Box
        w="7px"
        height="7px"
        background="#D8FD01"
        borderRadius="100%"
        margin="auto"
        mb="15px"
        opacity="0.6"
      />
      <Box
        w="15px"
        height="7px"
        background="#D8FD01"
        borderRadius="6px"
        margin="auto"
        mb="24px"
        display="flex"
        transform="rotate(-90deg)"
      />
    </Box>
  );
}
