import React from "react";

const GoogleSignup = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5004 10.231C20.5012 9.54942 20.4424 8.86901 20.3246 8.19727H10.7024V12.0494H16.2136C16.1008 12.6647 15.8621 13.2512 15.5119 13.7736C15.1617 14.2959 14.7071 14.7434 14.1758 15.0889V17.5895H17.4649C19.3908 15.8488 20.5004 13.2747 20.5004 10.231Z"
        fill="#4285F4"
      />
      <path
        d="M10.7023 20.0038C13.4557 20.0038 15.7742 19.1175 17.4648 17.5894L14.1756 15.0889C13.2602 15.6973 12.0811 16.0446 10.7023 16.0446C8.04092 16.0446 5.78203 14.2859 4.97427 11.916H1.58594V14.4929C2.43517 16.1495 3.73738 17.5421 5.34718 18.5152C6.95697 19.4883 8.811 20.0037 10.7023 20.0038Z"
        fill="#34A853"
      />
      <path
        d="M4.97431 11.9172C4.54728 10.6753 4.54728 9.33049 4.97431 8.08863V5.51172H1.58598C0.871574 6.90527 0.499512 8.44329 0.499512 10.0029C0.499512 11.5625 0.871574 13.1006 1.58598 14.4941L4.97431 11.9172Z"
        fill="#FBBC04"
      />
      <path
        d="M10.7023 3.9598C12.1574 3.93649 13.5634 4.47542 14.6164 5.4601L17.5285 2.60535C15.6819 0.905113 13.2355 -0.0283484 10.7023 0.000656227C8.811 0.000740724 6.95697 0.516115 5.34718 1.48924C3.73738 2.46237 2.43517 3.85494 1.58594 5.5115L4.97427 8.08841C5.78203 5.71849 8.04092 3.9598 10.7023 3.9598Z"
        fill="#EA4335"
      />
    </svg>
  );
};
export default GoogleSignup;
