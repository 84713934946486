import { Box, Text, useMediaQuery, Flex } from "@chakra-ui/react";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import EmptyTrackSoundkit from "app/components/MySoundkits/emptyTrackSoundkit";
import { RecentAction, TrackSoundKit, User } from "app/models";
import {
  fetchDiscount,
  fetchFreeBeats,
  fetchHotProducer,
  fetchNewArtists,
  fetchNewProducers,
  fetchRecentActionsHomePage,
  fetchRecommendedMusic,
} from "app/redux/actions/home";
import { getFavoritesSK, getRepostsSK } from "app/redux/actions/mySoundKit";
import { fetchFavorites, fetchReposts } from "app/redux/actions/myTrack";
import {
  discountSelector,
  freeBeatsSelector,
  newArtistsSelector,
  newProducersSelector,
  recommendedMusicSelector,
  selectorsHotProducers,
  selectRecentActionsItemsHomePage,
  selectRecentActionsOptionsHomePage,
} from "app/redux/selectors/home";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectLoggedIn } from "app/redux/selectors/users";
import useTrackSelect from "hooks/track/useTrackSelect";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import { FadeLoader } from "react-spinners";
import { RootState } from "types";
import { filterTracks, heightLoadMore } from "utils/convertTracks";
import HotProducers from "./hotProducers";
import "./hotProducersSlider.scss";
import ModalShareLinkRecentActions from "./modalShareLinkRecentActions";
import NewTalents from "./newTalents";
import RecentActionsHomePage from "./recentActions";
import RecommendedHomePage from "./recommendedHomePage";
import {
  fetchRepostPlaylists,
  fetchFavoritesPlaylists,
} from "app/redux/actions/playlist";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalShareLinkPlaylist from "./modalShareLinkPlaylist";
import Skeleton from "app/components/common/Skeleton/skeleton";
import SkeletonHotProducers from "./skeletonHotproducers";
import SkeletonRecentActions from "./skeletonRecentActions";
import SkeletonNewTalents from "./skeletonNewTalents";
import SkeletonItemDiscover from "./skeletonItemDiscover";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import ModalLyrics from "app/components/ModalShowLyrics";
import HotProducersMobile from "./hotProducersMobile";
import SkeletonHotProducersMobile from "./skeletonHotproducersMobile";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";

interface FeedHomeProps {
  isDiscover?: boolean;
  isExplore?: boolean;
  isLoadingExplore?: boolean;
}

export default function FeedHome({
  isDiscover = false,
  isExplore = false,
  isLoadingExplore = false,
}: FeedHomeProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const hotProducerItem = useSelector(selectorsHotProducers);
  const recentActionsItem = useSelector(selectRecentActionsItemsHomePage);
  const newArtists = useSelector(newArtistsSelector);
  const newProducers = useSelector(newProducersSelector);
  const recommendedMusic = useSelector(recommendedMusicSelector);
  const freeBeats = useSelector(freeBeatsSelector);
  const discount = useSelector(discountSelector);
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  // const recommendedArtists = useSelector(recommendedArtistsSelector);
  const existingOptions = useSelector(selectRecentActionsOptionsHomePage);
  const location = useLocation();
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchRecentActionsHomePage.typePrefix], state)
  );
  const isLoadingHotProducer = useSelector((state: RootState) =>
    isLoadingSelector([fetchHotProducer.typePrefix], state)
  );
  const isLoadingNewArtists = useSelector((state: RootState) =>
    isLoadingSelector([fetchNewArtists.typePrefix], state)
  );
  const isLoadingNewProducers = useSelector((state: RootState) =>
    isLoadingSelector([fetchNewProducers.typePrefix], state)
  );
  const isLoadingRecommendedMusic = useSelector((state: RootState) =>
    isLoadingSelector([fetchRecommendedMusic.typePrefix], state)
  );
  const isLoadingFreeBeats = useSelector((state: RootState) =>
    isLoadingSelector([fetchFreeBeats.typePrefix], state)
  );
  const isLoadingDiscount = useSelector((state: RootState) =>
    isLoadingSelector([fetchDiscount.typePrefix], state)
  );
  const loggedIn = useSelector(selectLoggedIn);

  const currentRoute = useMemo(
    () => (location.pathname || "").split("/").pop(),
    [location.pathname]
  );

  const dataNewTalents = useMemo(() => {
    if (newArtists && newProducers) {
      return [...newArtists, ...newProducers];
    } else if (newArtists) {
      return [...newArtists];
    } else if (newProducers) {
      return [...newProducers];
    }

    return [];
  }, [newArtists, newProducers]);

  const filterDuplicateRecentActions = filterTracks(recentActionsItem);
  const listPlayTracksSoundpack = useMemo(() => {
    return (filterDuplicateRecentActions || [])
      ?.map((item) => item?.track || item?.soundkit)
      ?.filter(Boolean);
  }, [filterDuplicateRecentActions]);

  useEffect(() => {
    switch (currentRoute) {
      case "home":
        dispatch(fetchHotProducer(loggedIn ? false : true));
        dispatch(
          fetchRecentActionsHomePage({
            page: 1,
            loggedInGuest: loggedIn ? false : true,
          })
        );
        if (loggedIn) {
          dispatch(fetchReposts());
          dispatch(getRepostsSK());
          dispatch(fetchFavorites());
          dispatch(getFavoritesSK());
          dispatch(fetchFavoritesPlaylists());
          dispatch(fetchRepostPlaylists());
        }

        break;
      case "discover":
        dispatch(fetchNewArtists());
        dispatch(fetchNewProducers());
        dispatch(fetchRecommendedMusic());
        dispatch(fetchFreeBeats());
        dispatch(fetchDiscount());
        break;
      case "explore":
        break;

      default:
        dispatch(fetchHotProducer(loggedIn ? false : true));
        dispatch(
          fetchRecentActionsHomePage({
            page: 1,
            loggedInGuest: loggedIn ? false : true,
          })
        );
        if (loggedIn) {
          dispatch(fetchReposts());
          dispatch(getRepostsSK());
          dispatch(fetchFavorites());
          dispatch(getFavoritesSK());
        }
        break;
    }
  }, [currentRoute, dispatch, loggedIn]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(existingOptions, e)) {
        dispatch(
          fetchRecentActionsHomePage({
            page: 1 + existingOptions?.page,
            loadingMore: true,
          })
        );
      }
    },
    [dispatch, existingOptions]
  );

  useEffect(() => {
    if (currentRoute === "home") {
      window.addEventListener("scroll", handleScrollPage);
      return () => {
        window.removeEventListener("scroll", handleScrollPage);
      };
    }
  }, [currentRoute, handleScrollPage]);

  const {
    selectedTrack,
    showOptionsModal,
    showModalShareLink,
    shortLink,
    loadingUrl,
    setShowOptionsModal,
    onOpenOptionsModal,
    setShowModalShareLink,
    showModalAndGetdataShareLink,
    setShortLink,
    setLoadingUrl,
    showModalFreeDownload,
    setShowModalFreeDownload,
    handleFreeDownload,
    linkTitleDownload,
    showLyrics,
    setShowLyrics,
    setIsEditLyrics,
    isEditLyrics,
    lyricsData,
    setShowModalFreeMailchimp,
    showModalFreeMailchimp,
    handleShowModalFreeDownloadMailchimp,
  } = useTrackSelect();

  const {
    setPlaylistItem,
    playlistItem,
    showModalShareLinkPlaylist,
    setShowModalShareLinkPlaylist,
    shortLinkPlaylist,
    setShortLinkPlaylist,
  } = usePlaylists();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,

    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const settingsHotProducers = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    loop: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 854,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <Box>
      {isLoadingHotProducer ||
      isLoadingNewArtists ||
      isLoadingNewProducers ||
      isLoadingExplore ? (
        <Box mr="15px" ml={{ base: "0px", md: isExplore ? "45px" : "0px" }}>
          <Skeleton
            mt={{ base: "5px", md: "7px", lg: "10px" }}
            w={{ base: "50px", md: "70px", lg: "85px" }}
            h={{ base: "17px", md: "20px", lg: "24px" }}
            mb={{ base: "20px", md: "25px", lg: "30px" }}
          />
          <Skeleton
            w={{ base: "350px", md: "100%", lg: "97%" }}
            h={{ base: "14px", md: "17px", lg: "20px" }}
          />
          <Box h={{ base: "2px", md: "8px" }} />
          <Skeleton
            w={{ base: "350px", md: "85%", lg: "14%" }}
            h={{ base: "14px", md: "17px", lg: "20px" }}
            mb={{ md: "30px", lg: "43px" }}
          />
          <Box h={{ base: "2px", md: "8px" }} />
          <Skeleton
            display={{ base: "block", md: "none" }}
            mb="20px"
            mt="5px"
            w={{ base: "80px", md: "300px" }}
            h={{ base: "14px", md: "17px" }}
          />
        </Box>
      ) : (
        <Box
          className="home-item__feed"
          mr="15px"
          ml={{ base: "0px", md: isExplore ? "45px" : "0px" }}
        >
          <Text
            fontSize={{ base: "20px", md: "28px", lg: "32px" }}
            lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
            fontWeight="500"
            mb={{ base: "10px", md: "10px", lg: "15px" }}
            fontFamily="Cera Pro Regular"
            w="max-content"
          >
            {isDiscover ? t("discover") : isExplore ? t("explore") : t("feed")}
          </Text>
          <Text
            fontSize={{ base: "14px", md: "20px" }}
            lineHeight={{ base: "20px", md: "32px" }}
            fontWeight="400"
            fontFamily="Cera Pro Regular"
            mb={{ base: "20px", md: "30px", lg: "50px" }}
          >
            {isDiscover
              ? t("textDescriptionDiscoverHome")
              : isExplore
              ? t("textDescriptionExploreHome")
              : t("textDescriptionFeedHome")}
          </Text>
        </Box>
      )}

      {isLoadingHotProducer || isLoadingNewArtists || isLoadingNewProducers ? (
        <Skeleton
          mt={{ base: "24px", md: "29px", lg: "39px" }}
          mb={{ base: "24px", md: "29px", lg: "39px" }}
          w={{ base: "100px", md: "140px", lg: "220px" }}
          h={{ base: "14px", md: "20px", lg: "24px" }}
        />
      ) : (
        !isExplore && (
          <Text
            mt={{ base: "10px", md: "20px", lg: "30px" }}
            mb={{ base: "10px", md: "20px", lg: "30px" }}
            fontSize={{ base: "16px", md: "24px", lg: "32px" }}
            lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
            fontWeight="500"
            fontFamily="Cera Pro Regular"
          >
            {isDiscover ? t("newTalents") : t("hotProducers")}
          </Text>
        )
      )}
      {!isDiscover &&
        !isExplore &&
        (isLoadingHotProducer ? (
          isLessThan766 ? (
            <SkeletonHotProducersMobile />
          ) : (
            <SkeletonHotProducers />
          )
        ) : (
          <Box className="hot-producers-slider">
            {hotProducerItem?.length <= 0 ? (
              <EmptyTrackSoundkit isHomePage />
            ) : isLessThan766 ? (
              <Flex overflow="scroll" m="10px 0px">
                {(hotProducerItem || [])?.map((item: User, index: number) => (
                  <HotProducersMobile key={item._id} hotproducers={item} />
                ))}
              </Flex>
            ) : (
              <Slider {...settingsHotProducers}>
                {(hotProducerItem || [])?.map((item: User, index: number) => (
                  <HotProducers key={item._id} hotproducers={item} />
                ))}
              </Slider>
            )}
          </Box>
        ))}
      {isDiscover &&
        (isLoadingNewArtists || isLoadingNewProducers ? (
          <SkeletonNewTalents />
        ) : (
          <Box className="hot-producers-slider new-talents-slider">
            {dataNewTalents?.length <= 0 ? (
              <EmptyTrackSoundkit isHomePage />
            ) : (
              <Slider {...settings}>
                {(dataNewTalents || []).map((item) => {
                  return <NewTalents key={item._id} item={item} />;
                })}
              </Slider>
            )}
          </Box>
        ))}
      {!isDiscover &&
        !isExplore &&
        (isLoading && !existingOptions?.loadingMore ? (
          <Box>
            <Skeleton
              mt={{ base: "30px", md: "35px", lg: "39px" }}
              mb={{ base: "30px", md: "35px", lg: "39px" }}
              w={{ base: "115px", md: "170px", lg: "216px" }}
              h={{ base: "16px", md: "18px", lg: "23px" }}
            />
            <SkeletonRecentActions />
          </Box>
        ) : (
          <Box>
            <Text
              m={{ base: "10px 0px", md: "30px 0px" }}
              fontSize={{ base: "16px", md: "24px", lg: "32px" }}
              lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
              fontWeight="500"
              fontFamily="Cera Pro Regular"
            >
              {t("recentActions")}
            </Text>
            {(filterDuplicateRecentActions || []).map((item: RecentAction) => {
              return (
                <RecentActionsHomePage
                  key={item?._id}
                  item={item}
                  onOpenOptionsModal={onOpenOptionsModal}
                  onOpenModalShareLink={showModalAndGetdataShareLink}
                  setShortLinkUrl={setShortLink}
                  setLoadingUrl={setLoadingUrl}
                  setPlaylistItem={setPlaylistItem}
                  setShowModalShareLinkPlaylist={setShowModalShareLinkPlaylist}
                  setShortLinkPlaylist={setShortLinkPlaylist}
                  handleFreeDownload={handleFreeDownload}
                  listPlayTracksSoundpack={listPlayTracksSoundpack}
                  handleShowModalFreeDownloadMailchimp={
                    handleShowModalFreeDownloadMailchimp
                  }
                />
              );
            })}
            <Box display="flex" justifyContent="center">
              <FadeLoader
                color={"#d4f70e"}
                loading={isLoading && existingOptions?.loadingMore}
              />
            </Box>
          </Box>
        ))}
      {isDiscover && (
        <>
          {isLoadingRecommendedMusic ? (
            <SkeletonItemDiscover />
          ) : (
            <Box>
              <Text
                m={{ base: "10px 0px 15px 0px", md: "15px 0px 30px 0px" }}
                fontSize={{ base: "16px", md: "24px", lg: "32px" }}
                lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
                fontWeight="500"
                fontFamily="Cera Pro Regular"
              >
                {t("recommended")}
              </Text>
              <Box className="hot-producers-slider new-talents-slider">
                {recommendedMusic?.length <= 0 ? (
                  <EmptyTrackSoundkit isHomePage />
                ) : (
                  <Slider {...settings}>
                    {(recommendedMusic || [])?.map((item: TrackSoundKit) => {
                      return (
                        <RecommendedHomePage
                          key={item?._id}
                          item={item}
                          lengthItem={recommendedMusic?.length}
                        />
                      );
                    })}
                  </Slider>
                )}
              </Box>
            </Box>
          )}
          {isLoadingFreeBeats ? (
            <SkeletonItemDiscover />
          ) : (
            <Box>
              <Text
                m={{ base: "10px 0px 15px 0px", md: "50px 0px 35px 0px" }}
                fontSize={{ base: "16px", md: "24px", lg: "32px" }}
                lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {t("freeBeats")}
              </Text>
              <Box className="hot-producers-slider new-talents-slider">
                {freeBeats?.length <= 0 ? (
                  <EmptyTrackSoundkit isHomePage />
                ) : (
                  <Slider {...settings}>
                    {(freeBeats || []).map((item: TrackSoundKit) => {
                      return (
                        <RecommendedHomePage
                          key={item?._id}
                          item={item}
                          lengthItem={freeBeats?.length}
                        />
                      );
                    })}
                  </Slider>
                )}
              </Box>
            </Box>
          )}
          {isLoadingDiscount ? (
            <SkeletonItemDiscover />
          ) : (
            <Box>
              <Text
                m={{ base: "10px 0px 15px 0px", md: "50px 0px 35px 0px" }}
                fontSize={{ base: "16px", md: "24px", lg: "32px" }}
                lineHeight={{ base: "28px", md: "32px", lg: "40px" }}
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {t("discounts")}
              </Text>
              <Box className="hot-producers-slider new-talents-slider">
                {discount?.length <= 0 ? (
                  <EmptyTrackSoundkit isHomePage />
                ) : (
                  <Slider {...settings}>
                    {(discount || []).map((item: TrackSoundKit) => {
                      return (
                        <RecommendedHomePage
                          key={item?._id}
                          item={item}
                          lengthItem={discount?.length}
                        />
                      );
                    })}
                  </Slider>
                )}
              </Box>
            </Box>
          )}
        </>
      )}
      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalShareLinkPlaylist && (
        <ModalShareLinkPlaylist
          showModalShareLink={showModalShareLinkPlaylist}
          setShowModalShareLink={setShowModalShareLinkPlaylist}
          selectedPlaylist={playlistItem}
          shortLinkUrl={shortLinkPlaylist}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
    </Box>
  );
}
