import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  Text,
  ModalFooter,
  Button,
  Input,
} from "@chakra-ui/react";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HandleFreeDownloadModel, TrackSoundKit } from "app/models";
import { addMemberMailChimp } from "app/redux/actions/users";
import { checkTypeDownload } from "utils/convertTracks";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { BeatLoader } from "react-spinners";

interface ModalShowFreeDownloadFormMailChimpProps {
  showModal: boolean;
  setShowModal: () => void;
  selectedTrack: TrackSoundKit | null;
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
}

export default function ModalShowFreeDownloadFormMailChimp({
  showModal,
  setShowModal,
  selectedTrack,
  handleFreeDownload,
}: ModalShowFreeDownloadFormMailChimpProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([addMemberMailChimp.typePrefix], state)
  );
  const urlDownload = useMemo(() => {
    return !selectedTrack?.trackState
      ? selectedTrack?.downloadFilesUrl || ""
      : checkTypeDownload(selectedTrack)
      ? (selectedTrack?.mergeAudio as string)
      : (selectedTrack?.untaggedFileUrl as string);
  }, [selectedTrack]);

  const isSelectedMailchimp = useMemo(() => {
    if (
      selectedTrack?.createdBy?.mailchimp?.mailLists?.some(
        (item) => item?.isSelected
      )
    ) {
      return selectedTrack?.createdBy?.mailchimp?.mailLists?.find(
        (item) => item?.isSelected
      );
    }
    return selectedTrack?.createdBy?.mailchimp?.mailLists?.[0];
  }, [selectedTrack?.createdBy?.mailchimp?.mailLists]);

  const handleSendEmailmailchimp = useCallback(async () => {
    const idTrackSoundPack = !selectedTrack?.trackState
      ? { soundPackId: selectedTrack?._id }
      : { trackId: selectedTrack?._id };
    await dispatch(
      addMemberMailChimp({
        ...idTrackSoundPack,
        webId: isSelectedMailchimp?.webId,
        email: emailAddress,
        name: name,
        lastName: lastName,
        dc: selectedTrack?.createdBy?.mailchimp?.dc,
        accessToken: isSelectedMailchimp?.accessToken,
        userId: selectedTrack?.createdBy?._id,
      })
    );
    handleFreeDownload({
      url: urlDownload,
      title: selectedTrack?.title || "",
      _id: selectedTrack?._id || "",
      isTrack: selectedTrack?.trackState ? true : false,
    });
    setShowModal();
  }, [
    dispatch,
    emailAddress,
    handleFreeDownload,
    isSelectedMailchimp?.accessToken,
    isSelectedMailchimp?.webId,
    lastName,
    name,
    selectedTrack?._id,
    selectedTrack?.createdBy?._id,
    selectedTrack?.createdBy?.mailchimp?.dc,
    selectedTrack?.title,
    selectedTrack?.trackState,
    setShowModal,
    urlDownload,
  ]);

  return (
    <Modal
      isOpen={showModal}
      onClose={setShowModal}
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "500px" }}
        m={{ base: "15px", xl: "0px" }}
      >
        <ModalHeader
          display="flex"
          justifyContent="center"
          fontSize={{ base: "20px", md: "24px" }}
          fontWeight="700"
          padding={{ base: "20px 20px", md: "30px", xl: "35px 40px 30px 40px" }}
          textAlign="center"
        >
          {t("freeDownloadButton")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={2}
          top="10px"
          fontSize="24px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody
          padding={{
            base: "0px 20px 20px 20px",
            md: "0px 30px 30px 30px",
            xl: "0px 40px 40px 40px",
          }}
        >
          <Text mb="10px" fontSize="16px" color="#FEFFFF" fontWeight="400">
            {t("inputYourEmailAddressAndFullName")}
          </Text>
          <Text fontSize="20px" fontWeight="700" color="#D4F70E" mb="20px">
            {t("weWillDeliverYourFreeTrackToTheEmailAddress")}
          </Text>
          <Text mb="10px" fontSize="16px" color="#535353">
            {t("emailAddress")}
          </Text>
          <Input
            cursor="pointer"
            borderRadius="4px"
            h="48px"
            borderColor="#383839"
            background={"#000000"}
            placeholder={t("provideEmailAddress")}
            mb="20px"
            _hover={{
              border: "none",
            }}
            _placeholder={{
              color: "#535353",
            }}
            type="email"
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <Text mb="10px" fontSize="16px" color="#535353">
            {t("name")}
          </Text>
          <Input
            cursor="pointer"
            borderRadius="4px"
            h="48px"
            mb="20px"
            borderColor="#383839"
            background={"#000000"}
            placeholder={t("provideName")}
            _hover={{
              border: "none",
            }}
            _placeholder={{
              color: "#535353",
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <Text mb="10px" fontSize="16px" color="#535353">
            {t("lastName")}
          </Text>
          <Input
            cursor="pointer"
            borderRadius="4px"
            h="48px"
            borderColor="#383839"
            background={"#000000"}
            placeholder={t("provideLastName")}
            _hover={{
              border: "none",
            }}
            _placeholder={{
              color: "#535353",
            }}
            onChange={(e) => setLastName(e.target.value)}
          />
        </ModalBody>

        <ModalFooter
          padding={{
            base: "10px 20px 40px",
            md: "10px 30px 30px",
            xl: "10px 40px 40px",
          }}
          display="flex"
          justifyContent="start"
          alignItems="center"
          gap="15px"
        >
          <Button
            fontSize="14px"
            fontWeight="500"
            w="50%"
            borderRadius="4px"
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            padding="25px 0px"
            onClick={handleSendEmailmailchimp}
            disabled={
              !emailAddress || !name || !lastName || !regex.test(emailAddress)
            }
            isLoading={isLoading}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("send")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
