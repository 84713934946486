const ACTIONS = {
  RELEASE_MUSIC: "RELEASE_MUSIC",
  CREATE_PLAYLIST: "CREATE_PLAYLIST",
  RELEASE_VIDEO: "RELEASE_VIDEO",
  REPOST: "REPOST",
  FAVORITE: "FAVORITE",
  FOLLOW: "FOLLOW",
  RELEASE_PLAYLIST: "RELEASE_PLAYLIST",
};

export const TYPE_PARAMS = {
  musicians: "musicians",
  tracks: "tracks",
  soundKits: "soundKits",
  playlists: "playlists",
  tags: "tags",
  track: "track",
  soundKit: "soundkit",
};

export const recentActions = {
  title: "Recent actions",
  releasedA: "Released a",
  releaseA: "Released a",
  createPlaylist: "created a playlist",
  addToFavorites: "add to favorites",
  favorites: "Favorites",
  track: "Track",
  soundKit: "Sound Kit",
  followed: "followed",
  reposted: "Reposted a",
  repost: "Repost a",
  releasedVideo: "uploaded a new video",
  releaseVideo: "upload new videos",
  filter: "Filter",
  filterBy: "Filter by",
  all: "All",
  tracks: "Tracks",
  albums: "Albums",
  videos: "Videos",
  playlists: "Playlists",
  addTo: "Add to",
  addedTo: "Add ",
  uploadNew: "Upload new",
  createA: "Create a",
  createdA: "Create a",
  playlist: "Playlist",
  video: "Video",
};

export const actionText = (action: string) => {
  switch (action) {
    case ACTIONS.RELEASE_MUSIC:
      return recentActions.releasedA;
    case ACTIONS.RELEASE_PLAYLIST:
      return recentActions.releaseA;
    case ACTIONS.REPOST:
      return recentActions.reposted;
    case ACTIONS.FAVORITE:
      return recentActions.addedTo;
    case ACTIONS.RELEASE_VIDEO:
      return recentActions.releasedVideo;
    default:
      return null;
  }
};

export const typeMusic = {
  SOUND_KIT: "SOUNDKIT",
  TRACK: "TRACK",
};

export const typeMyTrackRouter = {
  MY_PRIVATE_TRACK: "my-private-track",
  MY_DRAFT_TRACK: "my-draft-track",
  MY_SCHEDULE_TRACK: "my-schedule-track",
  MY_PENDING_TRACK: "my-pending-track",
  MY_SCHEDULE_SOUNDKITS: "my-schedule-soundkits",
  MY_PRIVATE_SOUNDKITS: "my-private-soundkits",
  MY_DRAFT_SOUNDKITS: "my-draft-soundkits",
  MY_PENDING_SOUNDKITS: "my-pending-soundkits",
};

export const indexNameAlgolia = (params: string) => {
  const name: { [name: string]: string } = {
    musicians: process.env.REACT_APP_KEY_MUSICIANS_SEARCH as string,
    tracks: process.env.REACT_APP_KEY_TRACKS_SEARCH as string,
    soundKits: process.env.REACT_APP_KEY_SOUNDPACKS_SEARCH as string,
    playlists: process.env.REACT_APP_KEY_PLAYLISTS_SEARCH as string,
    tags: process.env.REACT_APP_KEY_TAGS_SEARCH as string,
  };

  return name[params];
};

export const textTitleSearchAlgolia = (tabActive: number) => {
  const title: { [index: number]: { name: string; params: string } } = {
    0: { name: "Musician Name", params: "musicians" },
    1: { name: "Track Name", params: "tracks" },
    2: { name: "Sound Kit Name", params: "soundKits" },
    3: { name: "Playlist Name", params: "playlists" },
    4: { name: "Name Tags", params: "tags" },
  };

  return title[tabActive];
};

export const ACTIONSADDTRACK = {
  RELEASE_TRACK: "RELEASE_TRACK",
  RELEASE_TRACK_DRAFT: "RELEASE_TRACK_DRAFT",
  TRACK_DRAFT: "TRACK_DRAFT",
  EDIT_TRACK: "EDIT_TRACK",
  PRIVATE_TRACK: "PRIVATE_TRACK",
  SCHEDULED_TRACK: "SCHEDULED_TRACK",
};

export const ACTIONSADDSOUNDKIT = {
  RELEASE_SOUNDKIT: "RELEASE_SOUNDKIT",
  RELEASE_SOUNDKIT_DRAFT: "RELEASE_SOUNDKIT_DRAFT",
  SOUNDKIT_DRAFT: "SOUNDKIT_DRAFT",
  EDIT_SOUNDKIT: "EDIT_SOUNDKIT",
  PRIVATE_SOUNDKIT: "PRIVATE_SOUNDKIT",
  SCHEDULED_SOUNDKIT: "SCHEDULED_SOUNDKIT",
  ADD_COLLABORATORS_SOUNDKIT: "ADD_COLLABORATORS_SOUNDKIT",
};
