import { Text, Flex, Box } from "@chakra-ui/react";
import React from "react";
import { formatAudioDuration } from "utils/formatAudioDuration";
import "./styles.scss";

interface IWaveFormProps {
  wavesurfer: any;
  waveformRef: any;
  isIndividualTrack?: boolean;
}

const WaveForm = ({
  wavesurfer,
  waveformRef,
  isIndividualTrack,
}: IWaveFormProps): JSX.Element => {
  return (
    <Flex
      className="c_wave_progress"
      alignItems="center"
      marginLeft="20px"
      minWidth="350px"
      display={isIndividualTrack ? "block" : "none"}
    >
      <Box className="progress-bar-content">
        <Box className="audio-main">
          <Box className="progress-bar">
            <Box id="waveform" ref={waveformRef} />
          </Box>
        </Box>
      </Box>
      <div className="c_wave_progress_cover">&nbsp;</div>
      <Text
        fontSize="14px"
        lineHeight="17px"
        color="white"
        className="mb-display-none"
        fontWeight="500"
        display="none"
      >
        {wavesurfer?.current &&
          formatAudioDuration(wavesurfer?.current?.getDuration())}
      </Text>
    </Flex>
  );
};

export default React.memo(WaveForm);
