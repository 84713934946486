import { NewBulkOfferState } from "app/redux/reducers/bulkOffer";
import client from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class BulkOfferService {
  static async getBulkOffers(userId = "") {
    try {
      const response = await client.get(
        `/bulk-offers${userId ? `/${userId}` : ""}`
      );

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async createBulkOffer(bulkOffer: NewBulkOfferState) {
    try {
      const response = await client.post("/bulk-offers", bulkOffer);
      ToastPlanSubscription({
        description: "Add bulkoffer successfully!",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async editBulkOffer(bulkOffer: NewBulkOfferState) {
    try {
      const response = await client.put(
        `/bulk-offers/${bulkOffer._id}`,
        bulkOffer
      );

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async changeActiveBulkOffer(id: string) {
    try {
      const response = await client.patch(`/bulk-offers/${id}/active`);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async deleteBulkOffer(id: string) {
    try {
      await client.delete(`/bulk-offers/${id}`);
      return id;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default BulkOfferService;
