import React from "react";

const IconEmptyNegotiation = () => {
  return (
    <svg
      width="126"
      height="126"
      viewBox="0 0 126 126"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M59.3059 107.482V122.31C59.3059 124.348 60.9587 126.001 62.9973 126.001H122.306C124.345 126.001 125.997 124.348 125.997 122.31V107.482C125.997 105.444 124.345 103.791 122.306 103.791H62.9973C60.9584 103.791 59.3059 105.444 59.3059 107.482ZM66.6887 111.174H118.614V118.618H66.6887V111.174Z"
        fill="#282829"
      />
      <path
        d="M3.25002 111.145C5.4149 113.31 8.25876 114.393 11.1024 114.393C13.946 114.393 16.7898 113.31 18.9547 111.145L63.5243 66.5758L68.7882 71.8397L66.1563 74.4717C64.7146 75.9133 64.7146 78.2505 66.1563 79.6921L76.6408 90.1767C77.3616 90.8975 78.3064 91.258 79.2509 91.258C80.1954 91.258 81.1404 90.8977 81.861 90.1767L113.314 58.7234C114.756 57.2818 114.756 54.9446 113.314 53.503L102.83 43.0184C101.388 41.5768 99.0509 41.5768 97.6093 43.0184L94.9773 45.6504L68.7447 19.4178L71.3766 16.7858C72.0689 16.0936 72.458 15.1547 72.458 14.1758C72.458 13.1966 72.0689 12.258 71.3766 11.5657L60.8923 1.08112C59.4507 -0.360497 57.1133 -0.36025 55.6719 1.08112L24.2189 32.5344C22.7773 33.976 22.7773 36.3131 24.2189 37.7547L34.7033 48.2391C35.3953 48.9313 36.3344 49.3204 37.3133 49.3204C38.2923 49.3204 39.2314 48.9313 39.9234 48.2391L42.5554 45.6071L47.8196 50.8713L3.25002 95.4406C-1.07976 99.7704 -1.07976 106.816 3.25002 111.145ZM63.5462 14.1758L37.3133 40.4084L32.0494 35.1444L58.282 8.91182L63.5462 14.1758ZM73.987 77.082L100.22 50.8494L105.484 56.1133L79.2509 82.346L73.987 77.082ZM74.0089 66.6193L47.776 40.3867L63.5245 24.6384L89.7571 50.8711L74.0089 66.6193ZM8.4704 100.661L53.0399 56.0917L58.3039 61.3556L13.7346 105.925C12.2831 107.376 9.92211 107.376 8.4704 105.925C7.01919 104.474 7.01919 102.112 8.4704 100.661Z"
        fill="#282829"
      />
    </svg>
  );
};
export default IconEmptyNegotiation;
