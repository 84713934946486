import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import Collaborators from "app/components/AddTrackComponents/Collaborators";
import Divider from "app/components/AddTrackComponents/Divider";
import Metadata from "app/components/AddTrackComponents/Metadata";
import SubmitButtonGroup from "app/components/AddTrackComponents/SubmitButtonGroup";
import { AddSoundkitState } from "app/redux/reducers/addSoundkit";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import Description from "./Description";
import GeneralInfo from "./GeneralInfo";
import SidebarMenu from "app/components/AddTrackComponents/SidebarMenu";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import SuccessModalAddTrackSoundPack from "../SuccessModal/successModalAddTrackSoundPack";
import { ACTIONSADDSOUNDKIT } from "utils/actionText";
interface IAddSoundkitComponentProps {
  isLoading: boolean;
  showSuccessModal: boolean;
  onPublishSoundkit: (values: AddSoundkitState) => void;
  onSubmitDraft: () => void;
  toggleShowSuccessModal: () => void;
  isEdit?: boolean;
  addSoundkitDraft?: boolean;
  addSoundkitPrivate?: boolean;
  addSoundkitScheduled?: boolean;
  releaseSoundkitDraft?: boolean;
  addCollaboratorsSoundkit?: boolean;
}

export default function AddSoundkitComponent({
  showSuccessModal,
  isLoading,
  onPublishSoundkit,
  onSubmitDraft,
  toggleShowSuccessModal,
  isEdit = false,
  addSoundkitDraft,
  addSoundkitPrivate,
  addSoundkitScheduled,
  releaseSoundkitDraft,
  addCollaboratorsSoundkit,
}: IAddSoundkitComponentProps) {
  const { handleSubmit, watch } = useFormContext();
  const history = useHistory();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const onBackToFeed = useCallback(() => {
    toggleShowSuccessModal();
    history.push("/dashboard/my-soundkits");
  }, [history, toggleShowSuccessModal]);
  const { isDraft } = watch();

  const checkButtonSaveDraft = !isDraft && isEdit;
  const titleModal = useMemo(() => {
    let titleDes = { title: ACTIONSADDSOUNDKIT.RELEASE_SOUNDKIT };
    switch (true) {
      case addSoundkitDraft:
        titleDes.title = ACTIONSADDSOUNDKIT.SOUNDKIT_DRAFT;
        break;
      case addSoundkitPrivate:
        titleDes.title = ACTIONSADDSOUNDKIT.PRIVATE_SOUNDKIT;
        break;
      case addSoundkitScheduled:
        titleDes.title = ACTIONSADDSOUNDKIT.SCHEDULED_SOUNDKIT;
        break;
      case releaseSoundkitDraft:
        titleDes.title = ACTIONSADDSOUNDKIT.RELEASE_SOUNDKIT_DRAFT;
        break;
      case addCollaboratorsSoundkit:
        titleDes.title = ACTIONSADDSOUNDKIT.ADD_COLLABORATORS_SOUNDKIT;
        break;
      case checkButtonSaveDraft:
        titleDes.title = ACTIONSADDSOUNDKIT.EDIT_SOUNDKIT;
        break;
      default:
        break;
    }
    return titleDes;
  }, [
    addSoundkitDraft,
    addSoundkitPrivate,
    addSoundkitScheduled,
    releaseSoundkitDraft,
    addCollaboratorsSoundkit,
    checkButtonSaveDraft,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(onPublishSoundkit)}>
        <Box className="add-track-item-components">
          {isLessThan766 && (
            <Box display={{ base: "block", md: "none" }}>
              <SidebarMenu
                isSoundkit
                onSubmitDraft={onSubmitDraft}
                isLoading={isLoading}
                checkButtonSaveDraft={!checkButtonSaveDraft}
                isEdit={isEdit}
              />
            </Box>
          )}
          <GeneralInfo />
          <Divider />
          <Description />
          <Divider />
          <Metadata isSoundkit />
          <Divider />

          <Collaborators isAddSoundkit={true} />

          <Flex mt="50px">
            <SubmitButtonGroup
              isSoundkit
              isLoading={isLoading}
              onSubmitDraft={onSubmitDraft}
              checkButtonSaveDraft={!checkButtonSaveDraft}
              isEdit={isEdit}
              isDraft={isDraft}
              width={{ base: "100%", md: "50%" }}
            />
          </Flex>
          <Box display={{ base: "none", md: "block" }}>
            <SidebarMenu
              isSoundkit
              onSubmitDraft={onSubmitDraft}
              isLoading={isLoading}
              checkButtonSaveDraft={!checkButtonSaveDraft}
              isEdit={isEdit}
              isDraft={isDraft}
            />
          </Box>
        </Box>
      </form>
      {showSuccessModal && (
        <SuccessModalAddTrackSoundPack
          isOpen={showSuccessModal}
          onClose={toggleShowSuccessModal}
          onButtonClick={onBackToFeed}
          buttonLabel={"backToMyTrack"}
          title={titleModal.title}
        />
      )}
    </>
  );
}
