import { createAsyncThunk } from "@reduxjs/toolkit";
import AnalyticsController from "app/apis/analytics";
import { AnalyticsQuery } from "app/models";

export const TYPES = {
  GET_ANALYTICS_TRACKS: "GET_ANALYTICS_TRACKS",
  GET_LIST_ANALYTICS_TOTAL_TRACK: "GET_LIST_ANALYTICS_TOTAL_TRACK",
  GET_LIST_ANALYTICS_USER: "GET_LIST_ANALYTICS_USER",
  GET_LIST_ANALYTICS_TOP_COUNTRY: "GET_LIST_ANALYTICS_TOP_COUNTRY",
  GET_LIST_ANALYTICS_TRACKS: "GET_LIST_ANALYTICS_TRACKS",
  GET_TOTAL_SALES_ANALYTICS_TRACKS: "GET_TOTAL_SALES_ANALYTICS_TRACKS",
};

export const getAnalyticsTracks = createAsyncThunk(
  TYPES.GET_ANALYTICS_TRACKS,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getAnalyticsTracks(queryAnalyrics);
  }
);

export const getListAnalyticsTotalTrack = createAsyncThunk(
  TYPES.GET_LIST_ANALYTICS_TOTAL_TRACK,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getListPlaysAnalyticsTracks(
      queryAnalyrics
    );
  }
);

export const getListAnalyticsTracks = createAsyncThunk(
  TYPES.GET_LIST_ANALYTICS_TRACKS,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getListPlaysAnalyticsTracks(
      queryAnalyrics
    );
  }
);

export const getListAnalyticsUser = createAsyncThunk(
  TYPES.GET_LIST_ANALYTICS_USER,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getListUserAnalytics(queryAnalyrics);
  }
);

export const getListAnalyticsTopCountry = createAsyncThunk(
  TYPES.GET_LIST_ANALYTICS_TOP_COUNTRY,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getListTopCountryAnalytics(queryAnalyrics);
  }
);

export const getTotalSalesAnalyticsTracks = createAsyncThunk(
  TYPES.GET_TOTAL_SALES_ANALYTICS_TRACKS,
  async (queryAnalyrics: AnalyticsQuery) => {
    return await AnalyticsController.getListPlaysAnalyticsTracks(
      queryAnalyrics
    );
  }
);
