import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonHotProducersMobile() {
  return (
    <Flex gap={{ base: "10px", xl: "45px" }} mb="15px" overflow="scroll">
      {Array.from({ length: 5 }).map((item, index) => (
        <Flex key={index}>
          <Skeleton
            mr="0px"
            w={{ base: "68px", md: "148px" }}
            h={{ base: "68px", md: "148px" }}
            borderRadius="full"
          />
          <Box
            background="#25252A"
            padding="5px 10px"
            w="145px"
            h="68px"
            borderRadius="6px"
            ml="5px"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <Skeleton w="80%" h="12px" />
            <Skeleton w="80%" h="12px" />
            <Skeleton w="100%" h="12px" />
          </Box>
        </Flex>
      ))}
    </Flex>
  );
}
