import { Box, Text } from "@chakra-ui/react";
import React from "react";

interface EmptyAnalyticsProps {
  title: string;
  icon?: JSX.Element;
}

export default function EmptyAnalytic({ title, icon }: EmptyAnalyticsProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      alignItems="center"
      height="40vh"
    >
      {icon}
      <Text mt="30px" fontSize="16px" lineHeight="20px" color="#535353">
        {title}
      </Text>
    </Box>
  );
}
