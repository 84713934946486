import React from "react";

const MutedAudio = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.75 23.625L3.5 4.375"
        stroke="#DC251C"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M12.2498 7.48815V9.33659C12.2499 9.39443 12.2729 9.44988 12.3138 9.49081L13.6263 10.8033C13.6568 10.8339 13.6958 10.8548 13.7382 10.8632C13.7806 10.8717 13.8245 10.8674 13.8645 10.8509C13.9045 10.8344 13.9387 10.8064 13.9627 10.7705C13.9868 10.7346 13.9997 10.6923 13.9998 10.6491V6.59401C14.0022 6.35193 13.9391 6.11369 13.8171 5.90457C13.6951 5.69545 13.5188 5.52324 13.3069 5.4062C13.0885 5.28864 12.8411 5.23562 12.5937 5.25334C12.3463 5.27106 12.1091 5.35879 11.9096 5.50628C11.8971 5.51496 11.8851 5.52427 11.8735 5.53417L10.1268 6.96425C10.1032 6.98362 10.0839 7.00772 10.0702 7.03499C10.0565 7.06227 10.0486 7.09211 10.0471 7.12261C10.0456 7.1531 10.0505 7.18357 10.0615 7.21206C10.0725 7.24054 10.0894 7.26641 10.111 7.288L11.0434 8.22097C11.0818 8.2594 11.1332 8.28214 11.1875 8.28477C11.2418 8.2874 11.2951 8.26972 11.337 8.23518L12.2498 7.48815Z"
        fill="#DC251C"
      />
      <path
        d="M12.25 20.5122L7.98055 17.0166C7.6671 16.762 7.27527 16.6237 6.87148 16.625H3.5V11.375H6.27375C6.31699 11.3749 6.35924 11.362 6.39516 11.338C6.43109 11.3139 6.45906 11.2797 6.47557 11.2397C6.49207 11.1998 6.49636 11.1558 6.48788 11.1134C6.47941 11.071 6.45856 11.032 6.42797 11.0015L5.11547 9.68898C5.07454 9.6481 5.01909 9.6251 4.96125 9.625H3.0625C2.7144 9.625 2.38056 9.76328 2.13442 10.0094C1.88828 10.2556 1.75 10.5894 1.75 10.9375V17.0625C1.75 17.4106 1.88828 17.7444 2.13442 17.9906C2.38056 18.2367 2.7144 18.375 3.0625 18.375H6.8775L11.8737 22.4656C11.8853 22.4755 11.8973 22.4848 11.9098 22.4935C12.1118 22.643 12.3524 22.7312 12.6031 22.7476C12.8538 22.7639 13.1039 22.7078 13.3235 22.5859C13.5311 22.4677 13.7034 22.2961 13.8223 22.0888C13.9413 21.8816 14.0026 21.6463 14 21.4074V18.6637C13.9999 18.6059 13.9769 18.5505 13.936 18.5095L12.6235 17.197C12.593 17.1664 12.554 17.1456 12.5116 17.1371C12.4692 17.1286 12.4252 17.1329 12.3853 17.1494C12.3453 17.1659 12.3111 17.1939 12.287 17.2298C12.263 17.2658 12.2501 17.308 12.25 17.3512V20.5122Z"
        fill="#DC251C"
      />
      <path
        d="M19.2501 14.0015C19.2501 12.6583 18.9324 11.383 18.2794 10.1039C18.1715 9.90154 17.9885 9.74966 17.7697 9.68089C17.551 9.61213 17.3139 9.63199 17.1097 9.7362C16.9054 9.84041 16.7502 10.0206 16.6775 10.2381C16.6048 10.4556 16.6203 10.6929 16.7208 10.899C17.2453 11.9266 17.5001 12.9416 17.5001 14.0015C17.5001 14.1473 17.4944 14.2955 17.4831 14.4461C17.4785 14.5088 17.4874 14.5718 17.5093 14.6308C17.5312 14.6898 17.5655 14.7434 17.61 14.7879L18.6852 15.8636C18.713 15.8915 18.7479 15.9115 18.7861 15.9212C18.8244 15.931 18.8645 15.9302 18.9024 15.919C18.9402 15.9078 18.9743 15.8865 19.0011 15.8575C19.0278 15.8285 19.0462 15.7928 19.0543 15.7542C19.1837 15.1789 19.2494 14.5911 19.2501 14.0015Z"
        fill="#DC251C"
      />
      <path
        d="M22.7501 14.0018C22.7501 11.2024 22.0348 9.41408 20.8809 7.43603C20.7624 7.23882 20.5709 7.09622 20.3481 7.03911C20.1252 6.982 19.8888 7.01496 19.69 7.13087C19.4913 7.24679 19.3462 7.43631 19.2862 7.65842C19.2261 7.88053 19.256 8.11734 19.3693 8.31759C20.4023 10.0884 21.0001 11.5863 21.0001 14.0018C21.0001 15.305 20.8202 16.3468 20.4877 17.3186C20.4609 17.3961 20.4565 17.4795 20.4749 17.5594C20.4932 17.6392 20.5337 17.7123 20.5916 17.7703L21.5087 18.6869C21.534 18.7123 21.5652 18.7311 21.5995 18.7416C21.6339 18.7522 21.6702 18.7541 21.7055 18.7472C21.7407 18.7404 21.7737 18.7249 21.8016 18.7023C21.8294 18.6797 21.8513 18.6505 21.8652 18.6174C22.4269 17.2765 22.7501 15.8563 22.7501 14.0018Z"
        fill="#DC251C"
      />
      <path
        d="M26.2497 14.0007C26.2497 9.93956 25.1456 7.37745 23.4875 4.7798C23.3626 4.58399 23.165 4.44582 22.9383 4.39566C22.7115 4.34551 22.4741 4.3875 22.2783 4.51237C22.0825 4.63725 21.9444 4.8348 21.8942 5.06156C21.844 5.28832 21.886 5.52571 22.0109 5.72151C23.5055 8.0605 24.4997 10.3639 24.4997 14.0007C24.4997 16.5956 24.013 18.4916 23.2097 20.1803C23.1901 20.2211 23.1837 20.2669 23.1912 20.3114C23.1988 20.356 23.2199 20.3971 23.2518 20.4292L24.2263 21.4048C24.2507 21.4293 24.2805 21.4477 24.3133 21.4585C24.3462 21.4692 24.3811 21.4721 24.4153 21.4667C24.4494 21.4614 24.4818 21.4481 24.5098 21.4278C24.5378 21.4075 24.5606 21.3809 24.5763 21.3501C25.751 19.0439 26.2497 16.7897 26.2497 14.0007Z"
        fill="#DC251C"
      />
    </svg>
  );
};
export default MutedAudio;
