import React from "react";

const IconRemoveCreditCard = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.04004 0C6.45779 0 4.91107 0.469192 3.59548 1.34824C2.27989 2.22729 1.25451 3.47672 0.649006 4.93853C0.0435054 6.40034 -0.114921 8.00887 0.19376 9.56072C0.502442 11.1126 1.26437 12.538 2.38319 13.6569C3.50201 14.7757 4.92747 15.5376 6.47932 15.8463C8.03117 16.155 9.6397 15.9965 11.1015 15.391C12.5633 14.7855 13.8127 13.7602 14.6918 12.4446C15.5708 11.129 16.04 9.58225 16.04 8C16.04 6.94942 15.8331 5.90914 15.4311 4.93853C15.029 3.96793 14.4398 3.08601 13.6969 2.34315C12.954 1.60028 12.0721 1.011 11.1015 0.608964C10.1309 0.206926 9.09062 0 8.04004 0ZM8.04004 14.4C6.77424 14.4 5.53687 14.0246 4.48439 13.3214C3.43192 12.6182 2.61161 11.6186 2.12721 10.4492C1.64281 9.27972 1.51607 7.9929 1.76302 6.75142C2.00996 5.50994 2.6195 4.36957 3.51456 3.47452C4.40961 2.57946 5.54998 1.96992 6.79146 1.72297C8.03294 1.47603 9.31977 1.60277 10.4892 2.08717C11.6587 2.57157 12.6582 3.39188 13.3614 4.44435C14.0647 5.49682 14.44 6.7342 14.44 8C14.44 9.69738 13.7658 11.3252 12.5655 12.5255C11.3653 13.7257 9.73743 14.4 8.04004 14.4Z"
        fill="#D4F70E"
      />
      <path
        d="M11.1625 5.3H9.8625V5.0875C9.8625 4.75929 9.73212 4.44453 9.50005 4.21246C9.26797 3.98038 8.95321 3.85 8.625 3.85H7.9C7.57179 3.85 7.25703 3.98038 7.02496 4.21246C6.79288 4.44453 6.6625 4.75929 6.6625 5.0875V5.3H5.3625C5.22658 5.3 5.09622 5.354 5.00011 5.45011C4.904 5.54622 4.85 5.67658 4.85 5.8125C4.85 5.94842 4.904 6.07878 5.00011 6.17489C5.09622 6.271 5.22658 6.325 5.3625 6.325H5.575V10.1625C5.575 10.4907 5.70538 10.8055 5.93746 11.0375C6.16953 11.2696 6.48429 11.4 6.8125 11.4H9.7125C10.0407 11.4 10.3555 11.2696 10.5875 11.0375C10.8196 10.8055 10.95 10.4907 10.95 10.1625V6.325H11.1625C11.2984 6.325 11.4288 6.271 11.5249 6.17489C11.621 6.07878 11.675 5.94842 11.675 5.8125C11.675 5.67658 11.621 5.54622 11.5249 5.45011C11.4288 5.354 11.2984 5.3 11.1625 5.3ZM7.6875 5.0875C7.6875 5.03114 7.70989 4.97709 7.74974 4.93724C7.78959 4.89739 7.84364 4.875 7.9 4.875H8.625C8.68136 4.875 8.73541 4.89739 8.77526 4.93724C8.81511 4.97709 8.8375 5.03114 8.8375 5.0875V5.3H7.6875V5.0875ZM9.925 10.1625C9.925 10.2189 9.90261 10.2729 9.86276 10.3128C9.82291 10.3526 9.76886 10.375 9.7125 10.375H6.8125C6.75614 10.375 6.70209 10.3526 6.66224 10.3128C6.62239 10.2729 6.6 10.2189 6.6 10.1625V6.325H9.925V10.1625Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        strokeWidth="0.3"
      />
    </svg>
  );
};
export default IconRemoveCreditCard;
