import { Box, Flex, Text } from "@chakra-ui/react";
import Discounts from "app/components/Discounts";
import LicensesContract from "app/components/LicensesContracts";
import MyTrackTab from "app/components/MyTrack";
import StatsComponents from "app/components/StatsComponents";
import Subscription from "app/components/Subscription";
import classNames from "classnames";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import CollaborationsDashboard from "../Collaborations/CollaborationsDashboard/collaborationsDashboard";
import ConnectPayment from "../connectPayment";
import MySoundkitsTab from "../MySoundkits";
import NegotiationDashboard from "../Negotiations/NegotiationsDashboard/negotiationsDaboard";
import "./styles.scss";
import DashboardCover from "app/pages/DashboardCover";
import CustomersPage from "app/pages/Customers";

export default function TabsPanels() {
  const { path } = useRouteMatch();
  const { t } = useTranslation();
  const location = useLocation();

  const isActiveRoute = useCallback(
    (routeName, childRoute = []) =>
      location.pathname === routeName ||
      childRoute.some((route: string) => location.pathname.includes(route)),
    [location.pathname]
  );

  const tabsMenu = useMemo(
    () => [
      {
        id: "",
        exact: true,
        component: <DashboardCover />,
      },
      {
        name: t("analytics"),
        id: "/analytics",
        exact: true,
        component: <StatsComponents />,
      },
      {
        name: t("tracks"),
        id: "/my-track",
        component: <MyTrackTab />,
        childRoute: [
          "my-schedule-track",
          "my-private-track",
          "my-draft-track",
          "my-pending-track",
        ],
      },
      {
        name: t("SoundKits"),
        id: "/my-soundkits",
        component: <MySoundkitsTab />,
        childRoute: [
          "my-private-soundkits",
          "my-schedule-soundkits",
          "my-draft-soundkits",
        ],
      },
      {
        name: t("collaborations"),
        id: "/collaborations",
        component: <CollaborationsDashboard />,
      },
      { name: t("discounts"), id: "/discounts", component: <Discounts /> },
      { name: t("customers"), id: "/customers", component: <CustomersPage /> },
      { name: t("payment"), id: "/payment", component: <ConnectPayment /> },
      {
        name: t("subscription"),
        id: "/subscription",
        component: <Subscription />,
      },
      {
        name: t("contracts"),
        id: "/contracts",
        component: <LicensesContract />,
      },
      {
        name: t("negotiations"),
        id: "/negotiations",
        component: <NegotiationDashboard />,
      },
    ],
    [t]
  );

  return (
    <Box className="tabspanel-components">
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className="tab-list-panels"
        backgroundColor="#24252A"
        whiteSpace="nowrap"
        overflow="scroll"
        borderRadius="8px 8px 0px 0px"
        position="sticky"
        top="170px"
        zIndex={90}
      >
        {tabsMenu.map((item, index) => {
          return (
            item?.name && (
              <Flex alignItems="center" key={index}>
                <Link to={`${path}${item.id}`}>
                  <Text
                    px="20px"
                    py="20px"
                    cursor="pointer"
                    color="#FFFFFF"
                    opacity="0.5"
                    fontSize="14px"
                    lineHeight="120%"
                    className={classNames("fontCeraProRegular", {
                      "active-route": isActiveRoute(
                        `${path}${item.id}`,
                        item.childRoute
                      ),
                    })}
                    mr="13px"
                  >
                    {t(item?.name)}
                  </Text>
                </Link>
                {index !== tabsMenu.length - 1 && (
                  <Box
                    height="24px"
                    minW="1px"
                    backgroundColor="rgba(255, 255, 255, 0.1)"
                  />
                )}
              </Flex>
            )
          );
        })}
      </Flex>
      <Box>
        <Switch>
          {tabsMenu.map((tab, index) => {
            return (
              <Route key={index} exact={tab.exact} path={`${path}${tab.id}`}>
                {tab.component}
              </Route>
            );
          })}
        </Switch>
      </Box>
    </Box>
  );
}
