import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon, Back } from "app/assets/icons";
import AddNewCard from "app/assets/icons/addIcon";
import { Playlists } from "app/models";
import { prepareEditPlaylist } from "app/redux/actions/playlist";
import classNames from "classnames";
import { PlaylistType, searchPlaylists } from "constants/playlistType";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  useHistory,
} from "react-router-dom";
import EmptyPlaylist from "./emptyPlaylist";
import ModalCreatePlaylist from "./modalCreatePlaylist";
import PlaylistItem from "./playlistItem";
import SkeletonPlaylistItem from "./skeletonPlaylistItem";
import "./styles.scss";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { BeatLoader } from "react-spinners";

export default function PlaylistsPage() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const [isLessThan990] = useMediaQuery("(max-width: 990px)");

  const redirectDashboard = () => {
    history.push("/dashboard");
  };
  const {
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    handleGetPlaylists,
    playlists,
    isLoading,
    editPlaylist,
    setEditPlaylist,
    choosePlaylist,
    handleCheckbox,
    isSelectAll,
    handleSelectAll,
    handleRemovePlaylist,
    isLoadingDeletePlaylist,
    handleGetPlaylistsFollowing,
    isLoadingGetPlaylistsFollowing,
    playlistsFollowing,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();

  const isActiveTrackRoute = useCallback(
    (routeName) => {
      return location.pathname === routeName;
    },
    [location.pathname]
  );

  const checkRouter = useMemo(() => {
    return location?.pathname === "/playlists";
  }, [location?.pathname]);

  useEffect(() => {
    if (checkRouter) {
      handleGetPlaylists();
    } else {
      handleGetPlaylistsFollowing();
    }
  }, [checkRouter, handleGetPlaylists, handleGetPlaylistsFollowing]);

  const dataPlaylists = useMemo(() => {
    if (checkRouter) {
      return searchPlaylists(playlists, debouncedSearchText);
    } else {
      return searchPlaylists(playlistsFollowing, debouncedSearchText);
    }
  }, [checkRouter, debouncedSearchText, playlists, playlistsFollowing]);

  const handleCreatePlaylist = useCallback(() => {
    setShowModalCreatePlaylist();
    setEditPlaylist(false);
    dispatch(
      prepareEditPlaylist({
        visibility: PlaylistType.PUBLIC,
      })
    );
  }, [dispatch, setEditPlaylist, setShowModalCreatePlaylist]);

  const renderPlaylistItem = useMemo(() => {
    if (isLoading || isLoadingGetPlaylistsFollowing) {
      return <SkeletonPlaylistItem />;
    } else if (checkRouter) {
      return playlists?.length > 0 ? (
        (dataPlaylists || []).map((item: Playlists, index: number) => (
          <PlaylistItem
            key={item._id}
            playlist={item}
            index={index}
            setShowModalCreatePlaylist={setShowModalCreatePlaylist}
            setEditPlaylist={setEditPlaylist}
            handleCheckBox={handleCheckbox}
            choosePlaylists={choosePlaylist}
          />
        ))
      ) : (
        <EmptyPlaylist />
      );
    } else {
      return playlistsFollowing?.length > 0 ? (
        (dataPlaylists || []).map((item: Playlists, index: number) => (
          <PlaylistItem
            key={item._id}
            playlist={item}
            index={index}
            setShowModalCreatePlaylist={setShowModalCreatePlaylist}
            setEditPlaylist={setEditPlaylist}
            handleCheckBox={handleCheckbox}
            choosePlaylists={choosePlaylist}
            isFollowing
          />
        ))
      ) : (
        <EmptyPlaylist />
      );
    }
  }, [
    checkRouter,
    choosePlaylist,
    dataPlaylists,
    handleCheckbox,
    isLoading,
    isLoadingGetPlaylistsFollowing,
    playlists?.length,
    playlistsFollowing?.length,
    setEditPlaylist,
    setShowModalCreatePlaylist,
  ]);

  const myPlaylistRouters = [
    {
      id: 0,
      name: "myPlaylist",
      exact: true,
      value: "",
      component: renderPlaylistItem,
    },
    {
      id: 1,
      name: "following",
      value: "/following",
      component: renderPlaylistItem,
    },
  ];

  const columnHeader = useMemo(
    () => [
      {
        name: t("playlistNo"),
        width: isLessThan990 ? "21%" : "16%",
        marginLeft: "35px",
        display: "block",
      },
      {
        name: t("playlistName"),
        width: isLessThan990 ? "30%" : "20%",
        display: "block",
      },
      {
        name: t("playlistStatus"),
        width: "10%",
        display: "block",
      },
      {
        name: t("collaborators"),
        width: isLessThan990 ? "0%" : "20%",
        display: isLessThan990 ? "none" : "block",
      },
      {
        name: t("playlistData"),
        width: isLessThan990 ? "20%" : "15%",
        display: "block",
      },
      {
        name: t("dateAdded"),
        width: isLessThan990 ? "12%" : "14%",
        display: "block",
      },
      {
        name: t("actions"),
        width: isLessThan990 ? "7%" : "5%",
        textAlign: "end",
        display: checkRouter ? "block" : "none",
      },
    ],
    [checkRouter, isLessThan990, t]
  );

  const columnHeaderMobile = useMemo(
    () => [
      {
        name: t("playlistNo"),
        width: "20%",
      },
      {
        name: t("playlistName"),
        width: "65%",
        textAlign: "center",
      },
      {
        name: t("actions"),
        width: "15%",
        textAlign: "end",
      },
    ],
    [t]
  );
  const headerMenu = useMemo(() => {
    return isLessThan766 ? columnHeaderMobile : columnHeader;
  }, [columnHeader, columnHeaderMobile, isLessThan766]);

  return (
    <Box
      p={{ base: "40px 16px", md: "40px 30px", xl: "40px 80px" }}
      overflow="scroll"
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        display={{ base: "block", md: "flex" }}
      >
        <Flex alignItems="center" mb={{ base: "20px", md: "32px" }}>
          <Box
            onClick={redirectDashboard}
            cursor="pointer"
            className="vector-button"
          >
            <Back />
          </Box>
          <Text fontSize={{ base: "24px", md: "32px" }}>
            {t("playlistsHub")}
          </Text>
        </Flex>

        {isLoading || isLoadingGetPlaylistsFollowing ? (
          <Flex justifyContent="center">
            <Skeleton
              w={{ base: "90%", md: "159px" }}
              h="48px"
              borderRadius="6px"
            />
          </Flex>
        ) : (
          <Flex justifyContent="center">
            <Button
              leftIcon={<AddNewCard />}
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              padding="25px 20px"
              onClick={handleCreatePlaylist}
              w="90%"
            >
              {t("createNew")}
            </Button>
          </Flex>
        )}
      </Flex>

      <Box
        w="100%"
        mt={{ base: "40px", md: "70px" }}
        className="playlist-tab"
        position="sticky"
        top={0}
      >
        <Flex
          display={{ base: "block", md: "flex" }}
          justifyContent="space-between"
          background="#18181c"
        >
          <Flex justifyContent="start" mb={{ base: "15px", md: "0px" }}>
            {myPlaylistRouters.map((item) => (
              <Link key={item.id} to={`${path}${item.value}`}>
                <Text
                  cursor="pointer"
                  padding="5px 10px"
                  color="#49494D"
                  fontSize="16px"
                  lineHeight="29px"
                  className={classNames({
                    "active-playlist": isActiveTrackRoute(
                      `${path}${item.value}`
                    ),
                  })}
                  _hover={{
                    border: "1px solid #38383D",
                    background: "#222227 !important",
                    borderRadius: "6px",
                  }}
                >
                  {t(item.name)}
                </Text>
              </Link>
            ))}
          </Flex>

          <Flex
            alignItems="center"
            paddingLeft="5px"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
          >
            {isLoading || isLoadingGetPlaylistsFollowing ? (
              <>
                <Skeleton mr="30px" />
                <Skeleton w="140px" />
              </>
            ) : (
              <>
                <Text mr="15px" color="#49494D">
                  {t("filter")}
                </Text>
                <InputGroup
                  border="none"
                  borderLeft="1px solid rgba(255, 255, 255, 0.04)"
                >
                  <InputLeftElement children={<SearchIcon />} />
                  <Input
                    type="tel"
                    placeholder={t("searchPlaylist")}
                    border="0px"
                    p="0px 0px 0px 40px"
                    _placeholder={{ color: "#FFFFFF" }}
                    fontSize="14px"
                    cursor="pointer"
                    onChange={handleSearchText}
                  />
                </InputGroup>
              </>
            )}
          </Flex>
        </Flex>
        <Flex mt="20px">
          {headerMenu.map((item) => (
            <Text
              display={item?.display || "block"}
              fontSize="14px"
              fontWeight="400"
              pl={item?.marginLeft || "0px"}
              color="#8B8B8D"
              key={item.name}
              w={item.width}
              textAlign={item?.textAlign || "unset"}
            >
              {item.name}
            </Text>
          ))}
        </Flex>

        <Switch>
          {myPlaylistRouters.map((tab) => {
            return (
              <Route
                key={tab.id}
                exact={tab.exact}
                path={`${path}${tab.value}`}
              >
                {tab.component}
              </Route>
            );
          })}
        </Switch>
      </Box>
      {choosePlaylist.length > 1 && checkRouter && (
        <Box mt={{ base: "20px", md: "0px" }}>
          <Box className="basket-item">
            <Checkbox
              onChange={(e) => handleSelectAll(e.target.checked)}
              _focusVisible={{ outline: "none" }}
              size="lg"
              mt="20px"
              isChecked={isSelectAll}
            >
              <Text ml="30px" color="#D8FD01">
                {t("selectAll")}
              </Text>
            </Checkbox>
          </Box>
          <Button
            mt={{ base: "30px", md: "50px" }}
            backgroundColor="rgba(212, 247, 14, 0.10)"
            border="1px solid #D4F70E"
            _hover={{ backgroundColor: "none" }}
            borderRadius="4px"
            _active={{ backgroundColor: "#D8FD01" }}
            color="#D4F70E"
            onClick={handleRemovePlaylist}
            isLoading={isLoadingDeletePlaylist}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("remove")}
          </Button>
        </Box>
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
          editPlaylist={editPlaylist}
        />
      )}
    </Box>
  );
}
