export const TRACK_LICENSE = {
  NON_EXCLUSIVE: "Non-Exclusive",
  EXCLUSIVE: "Exclusive",
};

export const TRACK_TYPE = {
  SUBSCRIPTION: "SUBSCRIPTION",
  TRACK: "TRACK",
};

export const TYPE_FILTER_SORT = {
  TYPEOFCONTRACT: "Type of contract",
  COUNTRY: "country",
};

const trackTypes = [
  { label: TRACK_LICENSE.NON_EXCLUSIVE, value: TRACK_LICENSE.NON_EXCLUSIVE },
  {
    label: TRACK_LICENSE.EXCLUSIVE,
    value: TRACK_LICENSE.EXCLUSIVE,
  },
];

export default trackTypes;

export const PROFILE_TYPE = {
  LAST_ACTIVITY: "last-activity",
  TRACKS: "tracks",
  SOUND_PACKS: "soundKits",
  CREDITS: "credits",
  PLAYLISTS: "playlists",
};

export const ROUTER_HEADER = {
  USER: "user",
  HOME: "home",
  MESSAGES: "messages",
  LYRICS: "lyrics",
  PLAYLISTS: "playlists",
  DASHBOARD: "dashboard",
  PURCHASED: "purchased",
  REFERRAL: "referral",
};

export const PRO_LIST_COUNTRY = [
  { label: "AACIMH", value: "Honduras", name: "HN" },
  {
    label: "ABRAMUS",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "ACAM",
    value: "Costa Rica",
    name: "CR",
  },
  {
    label: "ACDAM",
    value: "Cuba",
    name: "CU",
  },
  {
    label: "ACEMLA",
    value: "Puerto Rico",
    name: "PR",
  },
  {
    label: "ACUM",
    value: "Israel",
    name: "IL",
  },
  {
    label: "ADDAF",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "AEI",
    value: "Guatemala",
    name: "GT",
  },
  {
    label: "AGADU",
    value: "Uruguay",
    name: "UY",
  },
  {
    label: "ALBAUTOR",
    value: "Albania",
    name: "AL",
  },
  {
    label: "AMAR",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "AMCOS",
    value: "Australia",
    name: "AU",
  },
  {
    label: "AMRA",
    value: "United States",
    name: "US",
  },
  {
    label: "ANACIM",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "APA",
    value: "Paraguay",
    name: "PY",
  },
  {
    label: "APDAYC",
    value: "Peru",
    name: "PE",
  },
  {
    label: "APRA",
    value: "Australia",
    name: "AU",
  },
  {
    label: "APSAV",
    value: "Peru",
    name: "PE",
  },
  {
    label: "ARTISJUS",
    value: "Hungary",
    name: "HU",
  },
  {
    label: "ASCAP",
    value: "United States",
    name: "US",
  },
  {
    label: "AUSTRO-MECHANA",
    value: "Austria",
    name: "AT",
  },
  {
    label: "AUTODIA",
    value: "Greece",
    name: "GR",
  },
  {
    label: "BMI",
    value: "United States",
    name: "US",
  },
  {
    label: "BSCAP",
    value: "Belize",
    name: "BZ",
  },
  {
    label: "BUMA",
    value: "Netherlands",
    name: "NL",
  },
  {
    label: "CAPAC",
    value: "Canada",
    name: "CA",
  },
  {
    label: "CASH",
    value: "Hong Kong",
    name: "HK",
  },
  {
    label: "CMRRA",
    value: "Canada",
    name: "CA",
  },
  {
    label: "COMPASS",
    value: "Singapore",
    name: "SG",
  },
  {
    label: "COSCAP",
    value: "Barbados",
    name: "BB",
  },
  {
    label: "COTT",
    value: "Trinidad & Tobago",
    name: "TT",
  },
  {
    label: "EAU",
    value: "Estonia",
    name: "EE",
  },
  {
    label: "EUCADA",
    value: "Central African Republic",
    name: "CF",
  },
  {
    label: "FILSCAP",
    value: "Phillipines",
    name: "PH",
  },
  {
    label: "HDS-ZAMP",
    value: "Croatia",
    name: "HR",
  },
  {
    label: "GEMA",
    value: "Germany",
    name: "DE",
  },
  {
    label: "IMRA",
    value: "Ireland",
    name: "IE",
  },
  {
    label: "IPRS",
    value: "India",
    name: "IN",
  },
  {
    label: "JACAP",
    value: "Jamaica",
    name: "JM",
  },
  {
    label: "JASRAC",
    value: "Japan",
    name: "JP",
  },
  {
    label: "KCI",
    value: "Indonesia",
    name: "ID",
  },
  {
    label: "KODA",
    value: "Denmark",
    name: "DK",
  },
  {
    label: "KOMCA",
    value: "Republic of Corea",
    name: "CD",
  },
  {
    label: "LITA",
    value: "Slovakia",
    name: "SK",
  },
  {
    label: "MACP",
    value: "Malaysia",
    name: "MY",
  },
  {
    label: "MCPS",
    value: "United Kingdom",
    name: "GB",
  },
  {
    label: "MCSC",
    value: "China",
    name: "CN",
  },
  {
    label: "MCT",
    value: "Thailand",
    name: "TH",
  },
  {
    label: "MESAM",
    value: "Turkey",
    name: "TR",
  },
  {
    label: "MSG",
    value: "Turkey",
    name: "TR",
  },
  {
    label: "MUSICAUTOR",
    value: "Bulgaria",
    name: "BG",
  },
  {
    label: "MUST",
    value: "Taiwan",
    name: "TW",
  },
  {
    label: "MUSICMARK",
    value: "United States",
    name: "US",
  },
  {
    label: "NASCAM",
    value: "Namibia",
    name: "NA",
  },
  {
    label: "NCB",
    value: "Denmark",
    name: "DK",
  },
  {
    label: "NS",
    value: "United States",
    name: "US",
  },
  {
    label: "OSA",
    value: "Taiwan",
    name: "TW",
  },
  {
    label: "PROCAN",
    value: "Canada",
    name: "CA",
  },
  {
    label: "PRS",
    value: "United Kingdom",
    name: "GB",
  },
  {
    label: "RAO",
    value: "Russia",
    name: "RU",
  },
  {
    label: "SABAM",
    value: "Belgium",
    name: "BE",
  },
  {
    label: "SABEM",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SACD",
    value: "France",
    name: "FR",
  },
  {
    label: "SACEM",
    value: "France",
    name: "FR",
  },
  {
    label: "SACM",
    value: "Mexico",
    name: "MX",
  },
  {
    label: "SACIM",
    value: "El Salvador",
    name: "SV",
  },
  {
    label: "SACVEN",
    value: "Venezuela",
    name: "VE",
  },
  {
    label: "SADAIC",
    value: "Argentina",
    name: "AR",
  },
  {
    label: "SADEMBRA",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SARRAL",
    value: "South Africa",
    name: "ZA",
  },
  {
    label: "SAYCE",
    value: "Ecuador",
    name: "EC",
  },
  {
    label: "SAYCO",
    value: "Colombia",
    name: "CO",
  },
  {
    label: "SAZAS",
    value: "Slovenia",
    name: "SI",
  },
  {
    label: "SBACEM",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SBAT",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SCD",
    value: "Chile",
    name: "CL",
  },
  {
    label: "SDRM",
    value: "France",
    name: "FR",
  },
  {
    label: "SESAC",
    value: "United States",
    name: "US",
  },
  {
    label: "SGAE",
    value: "Spain",
    name: "ES",
  },
  {
    label: "SGAGEDOM",
    value: "Dominican Republic",
    name: "DO",
  },
  {
    label: "SIAE",
    value: "Italy",
    name: "IT",
  },
  {
    label: "SICAM",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SOBODAYCOM",
    value: "Bolivia",
    name: "BO",
  },
  {
    label: "SOCAN",
    value: "Canada",
    name: "CA",
  },
  {
    label: "SOCINPRO",
    value: "Brazil",
    name: "BR",
  },
  {
    label: "SODRAC",
    value: "Canada",
    name: "CA",
  },
  {
    label: "SOKOJ",
    value: "Serbia",
    name: "RS",
  },
  {
    label: "SOZA",
    value: "Slovakia",
    name: "SK",
  },
  {
    label: "SPA",
    value: "Canada",
    name: "CA",
  },
  {
    label: "SPAC",
    value: "United States",
    name: "US",
  },
  {
    label: "SPACEM",
    value: "France",
    name: "FR",
  },
  {
    label: "SQN",
    value: "Bosnia and Herzegovina",
    name: "BA",
  },
  {
    label: "STEF",
    value: "Iceland",
    name: "IS",
  },
  {
    label: "STEMRA",
    value: "Netherlands",
    name: "NL",
  },
  {
    label: "STIM",
    value: "Sweden",
    name: "SE",
  },
  {
    label: "SUISA",
    value: "Switzerland",
    name: "CH",
  },
  {
    label: "TEOSTO",
    value: "Finland",
    name: "FI",
  },
  {
    label: "TONO",
    value: "Norway",
    name: "NO",
  },
  {
    label: "UACRR-ADA",
    value: "Ukraine",
    name: "UA",
  },
  {
    label: "WAMI",
    value: "Indonesia",
    name: "ID",
  },
  {
    label: "ZAIKS",
    value: "Poland",
    name: "PL",
  },
  {
    label: "ZAMP",
    value: "Slovenia",
    name: "SL",
  },
];
