import React from "react";

const EditUser = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#2D2D31" />
      <path
        d="M11.3333 20.0016H14.1599C14.2477 20.0021 14.3346 19.9853 14.4159 19.9521C14.4971 19.9189 14.571 19.87 14.6333 19.8083L19.2466 15.1883L21.1399 13.3349C21.2024 13.2729 21.252 13.1992 21.2858 13.118C21.3197 13.0367 21.3371 12.9496 21.3371 12.8616C21.3371 12.7736 21.3197 12.6864 21.2858 12.6052C21.252 12.524 21.2024 12.4502 21.1399 12.3883L18.3133 9.52825C18.2513 9.46577 18.1775 9.41617 18.0963 9.38233C18.0151 9.34848 17.9279 9.33105 17.8399 9.33105C17.7519 9.33105 17.6648 9.34848 17.5835 9.38233C17.5023 9.41617 17.4286 9.46577 17.3666 9.52825L15.4866 11.4149L10.8599 16.0349C10.7981 16.0972 10.7492 16.1711 10.7161 16.2523C10.6829 16.3335 10.6661 16.4205 10.6666 16.5083V19.3349C10.6666 19.5117 10.7368 19.6813 10.8618 19.8063C10.9869 19.9313 11.1564 20.0016 11.3333 20.0016ZM17.8399 10.9416L19.7266 12.8283L18.7799 13.7749L16.8933 11.8883L17.8399 10.9416ZM11.9999 16.7816L15.9533 12.8283L17.8399 14.7149L13.8866 18.6683H11.9999V16.7816ZM21.9999 21.3349H9.99992C9.82311 21.3349 9.65354 21.4052 9.52851 21.5302C9.40349 21.6552 9.33325 21.8248 9.33325 22.0016C9.33325 22.1784 9.40349 22.348 9.52851 22.473C9.65354 22.598 9.82311 22.6683 9.99992 22.6683H21.9999C22.1767 22.6683 22.3463 22.598 22.4713 22.473C22.5963 22.348 22.6666 22.1784 22.6666 22.0016C22.6666 21.8248 22.5963 21.6552 22.4713 21.5302C22.3463 21.4052 22.1767 21.3349 21.9999 21.3349Z"
        fill="white"
      />
    </svg>
  );
};
export default EditUser;
