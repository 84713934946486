import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import HomePageService from "app/apis/home";
import SoundKitService from "app/apis/soundKit";
import TrackService from "app/apis/tracks";
import { QueryPlayTrack } from "app/models";
import { selectRecentActionsOptionsHomePage } from "app/redux/selectors/home";
import {
  setFavoriteSKRecentActionsHomePage,
  setRepostSKRecentActionsHomePage,
  unsetFavoriteSKRecentActionsHomePage,
  setFavoriteSKRecentActionsHomePageError,
} from "../mySoundKit";
import {
  setFavoriteRecentActionsHomePage,
  setRepostRecentActionsHomePage,
  unsetFavoriteRecentActionsHomePage,
  setFavoriteErrorRecentActionsHomePage,
} from "../myTrack";
import { likePlaylistsError, unLikePlaylistsError } from "../playlist";

export const TYPES = {
  FETCH_HOT_PRODUCERS: "FETCH_HOT_PRODUCERS",
  FETCH_RECENT_ACTIONS: "FETCH_RECENT_ACTIONS",
  FETCH_NEW_ARTISTS: "FETCH_NEW_ARTISTS",
  FETCH_NEW_PRODUCERS: "FETCH_NEW_PRODUCERS",
  FETCH_RECOMMENDED_ARTISTS: "FETCH_RECOMMENDED_ARTISTS",
  FETCH_RECOMMENDED_MUSIC: "FETCH_RECOMMENDED_MUSIC",
  FETCH_FREE_BEATS: "FETCH_FREE_BEATS",
  FETCH_BULK_OFFERS: "FETCH_BULK_OFFERS",
  FOLLOW_UNFOLLOW_RECENT_ACTIONS: "FOLLOW_UNFOLLOW_RECENT_ACTIONS",
  LIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE:
    "LIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE",
  UNLIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE:
    "UNLIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE",
  LIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE:
    "LIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE",
  UNLIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE:
    "UNLIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE",
  REPOST_TRACK_IN_RECENT_ACTIONS_HOMEPAGE:
    "REPOST_TRACK_IN_RECENT_ACTIONS_HOMEPAGE",
  REPOST_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE:
    "REPOST_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE",
  LIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE:
    "LIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE",
  UNLIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE:
    "UNLIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE",
  REPOST_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE:
    "REPOST_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE",
  ERROR_LIKE_RECENT_ACTIONS_HOMEPAGE: "ERROR_LIKE_RECENT_ACTIONS_HOMEPAGE",
  ERROR_LIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE:
    "ERROR_LIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE",
  ERROR_UNLIKE_RECENT_ACTIONS_HOMEPAGE: "ERROR_UNLIKE_RECENT_ACTIONS_HOMEPAGE",
  ERROR_UNLIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE:
    "ERROR_UNLIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE",
  ERROR_LIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE:
    "ERROR_LIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE",
  ERROR_UNLIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE:
    "ERROR_UNLIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE",
};

export const fetchHotProducer = createAsyncThunk(
  TYPES.FETCH_HOT_PRODUCERS,
  async (isLoginGuest: boolean) =>
    await HomePageService.getHotProducers(isLoginGuest)
);

export const fetchRecentActionsHomePage = createAsyncThunk(
  TYPES.FETCH_RECENT_ACTIONS,
  async (newOptions: any, thunkAPI) => {
    const existingOptions = selectRecentActionsOptionsHomePage(
      thunkAPI.getState()
    );
    const options = { ...existingOptions, ...newOptions };

    return await HomePageService.getRecentActions({
      page: options.page,
      filterType: options.filterBy,
      isLoginGuest: options.loggedInGuest,
    });
  }
);

export const fetchNewArtists = createAsyncThunk(
  TYPES.FETCH_NEW_ARTISTS,
  async () => await HomePageService.getNewArtists()
);

export const fetchNewProducers = createAsyncThunk(
  TYPES.FETCH_NEW_PRODUCERS,
  async () => await HomePageService.getNewProducers()
);

export const fetchRecommendedArtists = createAsyncThunk(
  TYPES.FETCH_RECOMMENDED_ARTISTS,
  async () => await HomePageService.getRecommendedArtists()
);

export const fetchRecommendedMusic = createAsyncThunk(
  TYPES.FETCH_RECOMMENDED_MUSIC,
  async () => await HomePageService.getRecommendedMusic()
);

export const fetchFreeBeats = createAsyncThunk(
  TYPES.FETCH_FREE_BEATS,
  async () => await HomePageService.getFreeBeats()
);

export const fetchDiscount = createAsyncThunk(
  TYPES.FETCH_BULK_OFFERS,
  async () => await HomePageService.getBulkOffers()
);

export const followUnfollowRecentActionsHome = createAction(
  TYPES.FOLLOW_UNFOLLOW_RECENT_ACTIONS
);
export const errorLikeRecentActionsHomepage = createAction(
  TYPES.ERROR_LIKE_RECENT_ACTIONS_HOMEPAGE
);
export const errorLikeSoundkitRecentActionsHomepage = createAction(
  TYPES.ERROR_LIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE
);
export const errorUnLikeRecentActionsHomepage = createAction(
  TYPES.ERROR_UNLIKE_RECENT_ACTIONS_HOMEPAGE
);
export const errorUnLikeSoundkitRecentActionsHomepage = createAction(
  TYPES.ERROR_UNLIKE_SOUNDKIT_RECENT_ACTIONS_HOMEPAGE
);
export const errorLikePlaylistsRecentActionsHomepage = createAction(
  TYPES.ERROR_LIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE
);

export const errorUnLikePlaylistsRecentActionsHomepage = createAction(
  TYPES.ERROR_UNLIKE_PLAYLISTS_RECENT_ACTIONS_HOMEPAGE
);

export const likeTrackInRecentActionsHomepage = createAsyncThunk(
  TYPES.LIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE,
  async (query: QueryPlayTrack, thunkAPI) => {
    thunkAPI.dispatch(setFavoriteRecentActionsHomePage(query));
    setTimeout(async () => {
      try {
        await TrackService.setFavorite({
          ...query,
        });
      } catch (error) {
        thunkAPI.dispatch(setFavoriteErrorRecentActionsHomePage(query));
        thunkAPI.dispatch(errorLikeRecentActionsHomepage(query.id));
      }
    }, query.timeCallAPi);
    return { trackId: query?.id };
  }
);

export const unLikeTrackInRecentActionsHomepage = createAsyncThunk(
  TYPES.UNLIKE_TRACK_IN_RECENT_ACTIONS_HOMEPAGE,
  async (
    { id, timeCallApi }: { id: string; timeCallApi?: number },
    thunkAPI
  ) => {
    return thunkAPI.dispatch(
      unsetFavoriteRecentActionsHomePage({
        id: id,
        timeCallApi: timeCallApi,
      })
    );
  }
);

export const likeSoundkitInRecentActionsHomepage = createAsyncThunk(
  TYPES.LIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE,
  async (query: QueryPlayTrack, thunkAPI) => {
    thunkAPI.dispatch(setFavoriteSKRecentActionsHomePage(query.id));
    setTimeout(async () => {
      try {
        await SoundKitService.setFavorite(query);
      } catch (error) {
        thunkAPI.dispatch(setFavoriteSKRecentActionsHomePageError(query.id));
        thunkAPI.dispatch(errorLikeSoundkitRecentActionsHomepage(query.id));
      }
    }, query.timeCallAPi);
    return { soundkitId: query?.id };
  }
);

export const unLikeSoundkitInRecentActionsHomepage = createAsyncThunk(
  TYPES.UNLIKE_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE,
  async (
    { id, timeCallApi }: { id: string; timeCallApi?: number },
    thunkAPI
  ) => {
    return thunkAPI.dispatch(
      unsetFavoriteSKRecentActionsHomePage({
        id: id,
        timeCallApi: timeCallApi,
      })
    );
  }
);

export const repostTrackInRecentActionsHomepage = createAsyncThunk(
  TYPES.REPOST_TRACK_IN_RECENT_ACTIONS_HOMEPAGE,
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setRepostRecentActionsHomePage(id));
    TrackService.setRepost(id);
    return { trackId: id };
  }
);

export const repostSoundkitInRecentActionsHomepage = createAsyncThunk(
  TYPES.REPOST_SOUNDKIT_IN_RECENT_ACTIONS_HOMEPAGE,
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setRepostSKRecentActionsHomePage(id));
    SoundKitService.setRepostSK(id);
    return id;
  }
);

export const likePlaylistInRecentActionsHomepage = createAsyncThunk(
  TYPES.LIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE,
  async (query: QueryPlayTrack, thunkAPI) => {
    setTimeout(async () => {
      try {
        await TrackService.setFavoritePlaylist(query);
      } catch (error) {
        thunkAPI.dispatch(likePlaylistsError(query.id));
        thunkAPI.dispatch(errorLikePlaylistsRecentActionsHomepage(query.id));
      }
    }, query.timeCallAPi);
    return { playlistId: query?.id };
  }
);

export const unlikePlaylistInRecentActionsHomepage = createAsyncThunk(
  TYPES.UNLIKE_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE,
  async (query: QueryPlayTrack, thunkAPI) => {
    setTimeout(async () => {
      try {
        await TrackService.unsetFavoritePlaylist(query);
      } catch (error) {
        thunkAPI.dispatch(unLikePlaylistsError(query.id));
        thunkAPI.dispatch(errorUnLikePlaylistsRecentActionsHomepage(query.id));
      }
    }, query.timeCallAPi);
    return { playlistId: query?.id };
  }
);

export const repostPlaylistInRecentActionsHomepage = createAsyncThunk(
  TYPES.REPOST_PLAYLIST_IN_RECENT_ACTIONS_HOMEPAGE,
  async (playlistId: string) => {
    TrackService.setRepostPlaylist(playlistId);
    return { playlistId: playlistId };
  }
);
