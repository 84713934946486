import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";

export default function SkeletonTrackSoundPack() {
  return (
    <>
      {Array.from({ length: 6 }).map((item, index) => (
        <Box py={{ base: "8px", md: "12px", xl: "15px" }}>
          <Flex
            alignItems="center"
            justifyContent="space-between"
            mt={{ base: "0px", xl: "8px" }}
            mb={{ base: "2px", xl: "10px" }}
          >
            <Flex alignItems="center" ml={{ base: "0px", xl: "25px" }}>
              <Skeleton w="35px" h="35px" borderRadius="full" />
              <Skeleton />
            </Flex>
            <Skeleton />
          </Flex>
          <Flex
            mt={{ base: "2px", xl: "12px" }}
            h="80px"
            alignItems="center"
            mb="12px"
          >
            <Box
              w="8%"
              display={{ base: "none", xl: "flex" }}
              justifyContent="center"
            />
            <Flex w="50%" alignItems="center">
              <Box mr="12px">
                <IconSkeletonMusic />
              </Box>
              <Box>
                <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
                <Flex>
                  <Skeleton h="16px" w="35px" mb="0px" />
                  <Skeleton
                    h="16px"
                    w="109px"
                    borderRadius="30px"
                    mb="0px"
                    mr="0px"
                  />
                </Flex>
              </Box>
            </Flex>

            <Flex
              w="50%"
              justifyContent="end"
              display={{ base: "flex", md: "none" }}
            >
              <Flex alignItems="center" gap="8px" w="max-content">
                <Skeleton w="32px" h="32px" borderRadius="full" />
                <Skeleton w="10px" h="40px" />
              </Flex>
            </Flex>

            <Flex
              justifyContent={{ md: "end", xl: "center" }}
              display={{ base: "none", md: "flex" }}
              w={{ md: "50%", xl: "23%" }}
            >
              <Skeleton w="80px" h="20px" borderRadius="6px" />
              <Skeleton
                w="80px"
                h="20px"
                display={{ md: "none", xl: "block" }}
                borderRadius="6px"
              />
            </Flex>
            <Flex
              display={{ base: "none", xl: "flex" }}
              position="relative"
              w="12%"
              justifyContent="flex-end"
            >
              <Skeleton h="32px" w="70px" borderRadius="20px" />
              <Skeleton
                position="absolute"
                right="5px"
                top="4px"
                h="24px"
                w="24px"
                borderRadius="full"
                background="#24252A"
              />
            </Flex>
            <Flex
              w="6%"
              justifyContent="end"
              display={{ base: "none", xl: "flex" }}
            >
              <Skeleton w="10px" h="32px" />
            </Flex>
          </Flex>
        </Box>
      ))}
    </>
  );
}
