import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  ListItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Spinner,
  Td,
  Text,
  Tr,
  UnorderedList,
  useBoolean,
} from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PlayButtonGroup from "app/components/FooterPlayer/PlayButton";
import TagGenreButton from "app/components/TagGenreButton";
import { Licenses, TrackSoundKit } from "app/models";
import {
  deleteSoundkitDraft,
  deleteSoundkitRelease,
  prepareEditSoundkit,
} from "app/redux/actions/addSoundkit";
import {
  deleteTrackDraft,
  deleteTrackRelease,
} from "app/redux/actions/myTrack";
import {
  checkIsPlayPlaylists,
  checkPlaylistIdPlaying,
} from "app/redux/actions/playlist";
import { getLyrics, prepareEditTrack } from "app/redux/actions/tracks";
import { selectorIsLoadingDelete } from "app/redux/selectors/myTrack";
import { isLoadingSelector } from "app/redux/selectors/status";
import { PROFILE_TYPE } from "constants/contractType";
import { TRACK_STATE } from "constants/index";
import { planType } from "constants/planType";
import { TRACK_TYPE } from "constants/trackTypes";
import saveAs from "file-saver";
import { usePlayers } from "hooks/player/usePlayers";
import { useSubscription } from "hooks/subscription/useSubscription";
import { t } from "i18next";
import JSZip from "jszip";
import { default as React, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RootState } from "types";
import { fileToDeliverRecentAction } from "utils/fileToDeliver";
import { timeFromNow } from "utils/timeAgo";
import BackgroundDrop from "../BackgroundDrop";
import "./styles.scss";
import { BsThreeDots } from "react-icons/bs";

interface MusicCardReleasedTrackProps {
  item?: TrackSoundKit;
  isRecentAction?: boolean;
  isModalLyrics?: boolean;
  index?: number;
  isProfile?: boolean;
  handleCheckbox?: (id: string) => void;
  handleCheckboxSK?: (id: string) => void;
  selectCheckbox?: string[];
  selectCheckboxSK?: string[];
  isMyTrack?: boolean;
  isSK?: boolean;
  isMyTrackRelease?: boolean;
  isPlaylists?: boolean;
  isDraft?: boolean;
  isMySoundkit?: boolean;
  setShowModalLinkExpiration?: (track: TrackSoundKit) => void;
  selectItem?: number;
  setSelectIem?: (index: number) => void;
}

export default function MusicCardMyTrack({
  item: track,
  isRecentAction = false,
  isProfile = false,
  isModalLyrics = false,
  isMyTrack = false,
  isMyTrackRelease = false,
  isMySoundkit = false,
  isPlaylists = false,
  isDraft = false,
  isSK = false,
  index,
  handleCheckbox = () => {},
  handleCheckboxSK = () => {},
  selectCheckbox = [],
  selectCheckboxSK = [],
  setShowModalLinkExpiration = () => {},
  selectItem,
  setSelectIem = () => {},
}: MusicCardReleasedTrackProps) {
  const {
    playingTrackId,
    isPlaying,
    handlePlayOrPause,
    handleTogglePlayPause,
    isLoading,
    playlistId,
    isPlayAllPlaylist,
    handlePlayPlaylists,
  } = usePlayers();
  const dispatch = useDispatch();
  const history = useHistory();
  const [modalEditDelete, setModalEditDelete] = useBoolean();
  const { path } = useRouteMatch();
  const isLoadingDelete = useSelector(selectorIsLoadingDelete);
  const styleHoverBg = {
    background: "rgba(36, 37, 42, 0.8)",
  };
  const styleHoverPrimary = {
    borderBottomLeftRadius: "4px",
    borderTopLeftRadius: "4px",
    background: "rgba(36, 37, 42, 0.8)",
    height: "97px",
  };
  const styleHoverEnd = {
    borderBottomRightRadius: "4px",
    borderTopRightRadius: "4px",
    background: "rgba(36, 37, 42, 0.8)",
  };

  const onClickToTrack = useCallback(() => {
    if (!track?.mergeAudio && !track.audioFileUrl) {
      return;
    }
    if (!isPlayAllPlaylist && !isPlaylists) {
      handlePlayPlaylists([]);
    }
    if (playlistId) {
      dispatch(checkIsPlayPlaylists(false));
      dispatch(checkPlaylistIdPlaying(playlistId));
    } else {
      dispatch(checkIsPlayPlaylists(true));
      dispatch(checkPlaylistIdPlaying(""));
    }
    if (playingTrackId !== track?._id) {
      handlePlayOrPause(track);
    } else {
      handleTogglePlayPause();
    }
  }, [
    dispatch,
    handlePlayOrPause,
    handlePlayPlaylists,
    handleTogglePlayPause,
    isPlayAllPlaylist,
    isPlaylists,
    playlistId,
    playingTrackId,
    track,
  ]);

  const handlePlayTrackSoundkit = useCallback(() => {
    !isDraft && onClickToTrack();
  }, [isDraft, onClickToTrack]);

  const isLoadingDeleteSKRelease = useSelector((state: RootState) =>
    isLoadingSelector([deleteSoundkitRelease.typePrefix], state)
  );
  const isLoadingDeleteSKDraft = useSelector((state: RootState) =>
    isLoadingSelector([deleteSoundkitDraft.typePrefix], state)
  );
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );
  const [selectedTrackId, setSelectedTrackId] = useState("");
  const licensesName = useMemo(
    () => (track?.licenses || []).map((license: Licenses) => license?.name),
    [track]
  );
  const isExclusive = useMemo(
    () => (track?.licenses || []).every((license) => license.exclusive),
    [track]
  );
  const fileInSoundkit = useMemo(
    () => [
      {
        name: "MP3",
      },
      {
        name: "ZIP",
      },
    ],
    []
  );

  const handleEditTrackSoundkit = useCallback(() => {
    if (track?.isSK) {
      dispatch(prepareEditSoundkit(track));
      history.push({
        pathname: "/add-soundkit",
        search: "type=isEdit",
      });
      return;
    } else {
      dispatch(getLyrics({ trackId: track?._id }));
      history.push({
        pathname: "/add-track",
        search: "type=isEdit",
      });
      dispatch(prepareEditTrack(track));
    }
  }, [dispatch, history, track]);

  const handleDeleteSoundkit = useCallback(() => {
    setModalEditDelete.off();
    setSelectedTrackId(track?._id);
    dispatch(deleteSoundkitRelease(track?._id));
  }, [dispatch, setModalEditDelete, track?._id]);

  const handleTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      if (isSK) {
        history.push(`/soundkit/${track?.slug}`);
        return;
      }
      history.push(`/track/${track?.slug}`);
    },
    [history, isSK, track?.slug]
  );

  const redirectProfile = useCallback(
    (e, name) => {
      const checkParams = path.split("/")[1];
      e.stopPropagation();
      if (checkParams === "user") {
        history.push(`${name}?profileType=${PROFILE_TYPE.TRACKS}`);
        return;
      }
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history, path]
  );

  const handleDeleteTrack = useCallback(() => {
    setModalEditDelete.off();
    setSelectedTrackId(track?._id);
    if (track?.isDraft) {
      dispatch(deleteTrackDraft(track?._id));
      return;
    }
    dispatch(deleteTrackRelease(track?._id));
  }, [dispatch, setModalEditDelete, track?._id, track?.isDraft]);

  const checkS3Amazon = useCallback((link?: string) => {
    if (link) {
      return link?.includes("amazonaws.com");
    }
  }, []);

  const fileDownload = useMemo(() => {
    return [
      checkS3Amazon(track?.untaggedFileUrl) ? "" : track?.untaggedFileUrl,
      track?.untaggedFileUrlWav,
      track?.trackStemsUrl,
      track?.taggedFileUrl,
    ];
  }, [
    checkS3Amazon,
    track?.taggedFileUrl,
    track?.trackStemsUrl,
    track?.untaggedFileUrl,
    track?.untaggedFileUrlWav,
  ]);

  const trackCollaboration = useMemo(() => {
    return track.trackState === TRACK_STATE.PENDING && !track.isDraft;
  }, [track.isDraft, track.trackState]);

  const handleShowLinkExpiration = useCallback(() => {
    setModalEditDelete.off();
    setShowModalLinkExpiration(track);
  }, [setModalEditDelete, setShowModalLinkExpiration, track]);

  const handleDownloadFiles = useCallback(async () => {
    const zip = new JSZip();
    const promises = (fileDownload || []).map((url, index) => {
      if (url) {
        const lastTailMatch = url.match(/\.([^.?]+)(\?.*)?$/i);
        const lastTail = lastTailMatch ? lastTailMatch[1] : null;
        return fetch(url)
          .then((response) => {
            if (!response.ok) {
              throw new Error("Network response was not ok");
            }
            return response.blob();
          })
          .then((data) => {
            zip.file(`${track?.title}${index + 1}.${lastTail}`, data);
          })
          .catch((error) => {
            console.error(`Error fetching data for file ${index + 1}:`, error);
          });
      }
    });
    await Promise.all(promises);
    zip.generateAsync({ type: "blob" }).then((blob) => {
      saveAs(blob, `${track?.title}.zip`);
    });
    setModalEditDelete.off();
  }, [fileDownload, setModalEditDelete, track?.title]);

  return (
    <>
      <Tr
        cursor="pointer"
        onMouseEnter={(e) => {
          setSelectIem(index);
        }}
        onMouseLeave={(e) => {
          setSelectIem(-1);
        }}
      >
        <Td
          borderColor="rgba(36, 37, 42, 0.8)"
          position="sticky"
          left="0px"
          zIndex="2"
          p="0px"
          backdropFilter={{ base: "blur(20px)", xl: "blur(0px)" }}
        >
          <Flex
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            {!isProfile && !isModalLyrics && !trackCollaboration ? (
              <Flex justifyContent="end" w="50%">
                <Checkbox
                  borderRadius="4px"
                  _focusVisible={{ outline: "none" }}
                  size="lg"
                  mr="12px"
                  onChange={() =>
                    !isSK
                      ? handleCheckbox(track?._id ?? "")
                      : handleCheckboxSK(track?._id ?? "")
                  }
                  isChecked={
                    !isSK
                      ? !!selectCheckbox.find(
                          (value: string) => value === track?._id
                        )
                      : !!selectCheckboxSK.find(
                          (value: string) => value === track?._id
                        )
                  }
                />
              </Flex>
            ) : (
              <Box w="30%"></Box>
            )}
            <Flex
              style={selectItem === index ? styleHoverPrimary : {}}
              w={trackCollaboration ? "70%" : "50%"}
              h="100%"
              onClick={handlePlayTrackSoundkit}
              justifyContent="center"
              alignItems="center"
            >
              {selectItem === index || playingTrackId === track?._id ? (
                <>
                  <Flex
                    w={"45px"}
                    h={"45px"}
                    borderRadius="4px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PlayButtonGroup
                      size={35}
                      isPlaying={playingTrackId === track?._id && isPlaying}
                      isLoading={isLoading}
                    />
                  </Flex>
                </>
              ) : (
                <Text
                  textAlign="center"
                  _groupHover={{
                    display: !isDraft && "none",
                  }}
                >
                  {(index || 0) + 1}
                </Text>
              )}
            </Flex>
          </Flex>
        </Td>
        <Td
          p="12px"
          style={selectItem === index ? styleHoverBg : {}}
          borderColor="rgba(36, 37, 42, 0.8)"
        >
          <Flex
            position="sticky"
            zIndex="1"
            left="0px"
            alignItems="center"
            backdropFilter="auto"
            backdropBlur="20px"
            onClick={handlePlayTrackSoundkit}
            overflow="hidden"
          >
            <Image
              w={"72px"}
              h={"72px"}
              src={track?.coverUrl}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              border={
                !track?.coverUrl
                  ? "1px solid rgba(255, 255, 255, 0.14)"
                  : "none"
              }
              mr={{ base: "8px", md: "12px", xl: "20px" }}
            />
            <Box mr={{ base: "12px", xl: "0px" }}>
              {track?.title ? (
                <Flex
                  alignItems="center"
                  className="title-name"
                  _hover={{ textDecoration: "underline" }}
                  onClick={handleTrackDetail}
                  cursor="pointer"
                >
                  <Text
                    className="fontSfProDisplayBold title-text"
                    fontSize={{ base: "12px", md: "14px", xl: "16px" }}
                    lineHeight="16px"
                    fontWeight="900"
                    color={"white"}
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="clip"
                    fontFamily="Cera Pro Bold"
                    w={{ base: "100%", xl: "75%" }}
                  >
                    {track?.title?.slice(0, 55)}
                  </Text>
                </Flex>
              ) : (
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="500"
                  color={"#ED3636"}
                >
                  {t("missingTrackTitle")}
                </Text>
              )}
              <Flex
                mt="5px"
                w={{ base: "120px", md: "100%" }}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
              >
                {TRACK_TYPE[track?.type] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    height="20px"
                    borderRadius="4px"
                    padding="5px 10px"
                    mr="5px"
                    mb="5px"
                    backgroundColor="#2b2e1b"
                  >
                    <Text
                      fontSize={{ base: "10px", md: "12px" }}
                      lineHeight="14px"
                      color="#d4f70e"
                    >
                      {t(TRACK_TYPE[track?.type])}
                    </Text>
                  </Box>
                )}
                <Text
                  fontSize={{ base: "12px", md: "14px" }}
                  color="#464649"
                  _hover={{
                    textDecor: "underline",
                    color: "white",
                  }}
                  cursor="pointer"
                  fontFamily="Cera Pro Regular"
                  onClick={(e) => redirectProfile(e, track?.createdBy?.name)}
                >
                  {`${t("by")} ${track?.createdBy?.name}`}
                </Text>
              </Flex>

              <Flex alignItems="center" overflow="scroll">
                {track?.bpm && (
                  <Text fontSize="12px" mr="5px">
                    Bpm <span>{track?.bpm}</span>
                  </Text>
                )}

                <UnorderedList display="flex" gap="25px">
                  {track?.key && (
                    <ListItem fontSize="12px">KEY: {track?.key}</ListItem>
                  )}
                  {track?.mainGenre && (
                    <ListItem fontSize="12px">{track?.mainGenre}</ListItem>
                  )}
                  {track?.subGenre && (
                    <ListItem fontSize="12px">{track?.subGenre}</ListItem>
                  )}
                </UnorderedList>
              </Flex>
            </Box>
          </Flex>
        </Td>
        {track?.trackState && (
          <Td
            p="12px"
            style={selectItem === index ? styleHoverBg : {}}
            borderColor="rgba(36, 37, 42, 0.8)"
          >
            <Flex alignItems="center" flexWrap="wrap">
              <TagGenreButton data={licensesName} isMyTrack />
              {track?.licenses?.length > 0 && (
                <Box
                  display="flex"
                  alignItems="center"
                  height="20px"
                  borderRadius="4px"
                  padding="5px 10px"
                  mr="5px"
                  mb="5px"
                  width="fit-content"
                  backgroundColor="#2b2e1b"
                >
                  <Text
                    fontSize="14px"
                    lineHeight="17px"
                    fontWeight="500"
                    color="#d4f70e"
                    fontFamily="Cera Pro Regular"
                  >
                    {isExclusive
                      ? t("exclusiveContract")
                      : t("nonExclusiveContract")}
                  </Text>
                </Box>
              )}
            </Flex>
          </Td>
        )}
        <Td
          p="12px 24px"
          style={selectItem === index ? styleHoverBg : {}}
          borderColor="rgba(36, 37, 42, 0.8)"
        >
          <Text
            fontSize="14px"
            lineHeight="17px"
            fontWeight="500"
            color="#FFFFFF"
            opacity="0.22"
            fontFamily="Cera Pro Regular"
          >
            {timeFromNow(track, "D MMM YYYY")}
          </Text>
        </Td>
        <Td
          p="12px 24px"
          style={selectItem === index ? styleHoverBg : {}}
          borderColor="rgba(36, 37, 42, 0.8)"
        >
          <TagGenreButton
            isRequiredFiles
            data={
              isMySoundkit ? fileInSoundkit : fileToDeliverRecentAction(track)
            }
            isMyTrack={isMyTrack}
          />
        </Td>
        <Td
          style={selectItem === index ? styleHoverEnd : {}}
          borderColor="rgba(36, 37, 42, 0.8)"
        >
          <Flex justifyContent="center">
            {modalEditDelete && <BackgroundDrop />}
            <Popover
              isOpen={modalEditDelete}
              closeOnBlur={true}
              onOpen={setModalEditDelete.on}
              onClose={setModalEditDelete.off}
            >
              <PopoverTrigger>
                <Box
                  padding="8px"
                  cursor="pointer"
                  _hover={{
                    background: "#24252A",
                    padding: "3px 5px",
                    border: "1px solid #38383D",
                    borderRadius: "8px",
                  }}
                >
                  {(isLoadingDelete ||
                    isLoadingDeleteSKRelease ||
                    isLoadingDeleteSKDraft) &&
                  track?._id === selectedTrackId ? (
                    <Spinner color="#d4f70e" />
                  ) : (
                    <BsThreeDots size={24} />
                  )}
                </Box>
              </PopoverTrigger>
              <Box zIndex={120}>
                <PopoverContent
                  backgroundColor="#25252A"
                  border="none"
                  mt="5px"
                  maxW="200px"
                >
                  <PopoverArrow
                    backgroundColor="#25252A"
                    borderColor="#25252A "
                    boxShadow="unset !important"
                  />

                  <PopoverBody>
                    <Flex flexDirection="column">
                      <Button
                        backgroundColor="#25252A"
                        borderColor="#37383C"
                        padding="25px 0px"
                        mb="10px"
                        _hover={{
                          background: "#2D2E33",
                          border: "1px solid #37383C",
                        }}
                        onClick={handleEditTrackSoundkit}
                      >
                        {t("Edit")}
                      </Button>
                      {!checkPlanSubscription && track.private && (
                        <Button
                          backgroundColor="#25252A"
                          borderColor="#37383C"
                          padding="25px 0px"
                          mb="10px"
                          _hover={{
                            background: "#2D2E33",
                            border: "1px solid #37383C",
                          }}
                          onClick={handleShowLinkExpiration}
                        >
                          {t("sharePrivately")}
                        </Button>
                      )}
                      {isMyTrackRelease && (
                        <Button
                          backgroundColor="#25252A"
                          borderColor="#37383C"
                          mb="10px"
                          padding="25px 0px"
                          _hover={{
                            background: "#2D2E33",
                            border: "1px solid #37383C",
                          }}
                          onClick={handleDownloadFiles}
                        >
                          {t("downloadFiles")}
                        </Button>
                      )}
                      <Button
                        color="red"
                        _hover={{
                          background: "#2C262A",
                          border: "1px solid #ED363680",
                        }}
                        padding="25px 0px"
                        borderColor="#ED363680"
                        backgroundColor="#25252A"
                        onClick={
                          track?.isSK ? handleDeleteSoundkit : handleDeleteTrack
                        }
                      >
                        {t("delete")}
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Popover>
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
