import { Box, Flex, SimpleGrid } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonNegotiations() {
  const renderSkeleton = () => {
    return (
      <Box>
        <Flex
          background="#24252A"
          p={{
            base: "8px 12px",
            md: "8px 20px 8px 20px",
            xl: "8px 30px 8px 40px",
          }}
          alignItems="center"
          borderRadius="8px"
        >
          <Skeleton w="98px" h="98px" borderRadius="full" m="0px" />
          <Box>
            <Skeleton w="80px" h="18px" />
            <Skeleton w="40px" h="18px" mb="20px" />
            <Skeleton w={{ base: "150px", xl: "250px" }} h="18px" />
          </Box>
        </Flex>
      </Box>
    );
  };

  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      spacing={{ base: "20px", md: "50px" }}
    >
      {Array.from({ length: 6 }).map(() => renderSkeleton())}
    </SimpleGrid>
  );
}
