import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddStripeCard from "../AddStripeCard";
import React from "react";

export default function HocElementStripe() {
  const stripePromise = loadStripe(
    process.env.REACT_APP_PUBLISHKEY_STRIPE as string
  );
  return (
    <Elements stripe={stripePromise}>
      <AddStripeCard />
    </Elements>
  );
}
