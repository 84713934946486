import React from "react";

const PauseAudio = () => {
  return (
    <svg
      width="8"
      height="11"
      viewBox="0 0 8 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.88464e-06 1.35097L1.13291e-06 9.94975C1.06008e-06 10.7829 0.959102 11.2507 1.61564 10.7378L7.01096 6.52269C7.51723 6.12716 7.52453 5.36368 7.02591 4.95855L1.63059 0.574856C0.97708 0.0438751 1.95825e-06 0.508936 1.88464e-06 1.35097Z"
        fill="white"
      />
    </svg>
  );
};
export default PauseAudio;
