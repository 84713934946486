import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSwipeable } from "react-swipeable";
import "./styles.scss";
import LeftArrow from "app/assets/icons/leftArrow";
import RightArrow from "app/assets/icons/rightArrow";

interface CarouselProps {
  slidesDefault: Array<any>;
  autoplay?: boolean;
  arrows?: boolean;
  arrowBorders?: boolean;
  isPayment?: boolean;
}

export function Carousel({
  slidesDefault,
  autoplay = false,
  arrows = true,
  arrowBorders = true,
  isPayment = false,
}: CarouselProps) {
  const [slideTotal, setSlideTotal] = useState(0);
  const [slideCurrent, setSlideCurrent] = useState(0);
  const [slides, setSlides] = useState<any[]>([]);
  const nextRef = useRef<any>();
  const handlers = useSwipeable({
    onSwipedLeft: () => slideRight(),
    onSwipedRight: () => slideLeft(),
    trackMouse: true,
  });
  useEffect(() => {
    if (isPayment) {
      const locSlides: any[] = [];
      slidesDefault.forEach((slide, index) => {
        let slideobject = {};
        if (index === 0) {
          slideobject = {
            class: "slider-single active",
            element: slide,
          };
        } else {
          slideobject = {
            class: "slider-single proactive",
            element: slide,
          };
        }

        locSlides.push(slideobject);
      });
      setSlides(locSlides);
      setSlideTotal(locSlides.length - 1);
    } else {
      const locSlides: any[] = [];
      slidesDefault.forEach((slide, index) => {
        let slideobject = {};
        if (index === 0) {
          slideobject = {
            class: "slider-single active",
            element: slide,
          };
        }
        if (index === 1) {
          slideobject = {
            class: "slider-single proactive",
            element: slide,
          };
        }
        if (index !== 0 && index !== 1) {
          slideobject = {
            class: "slider-single proactivede",
            element: slide,
          };
        }

        locSlides.push(slideobject);
      });
      setSlides(locSlides);
      setSlideTotal(locSlides.length - 1);
    }
  }, [slidesDefault]);

  const slideRight = () => {
    let preactiveSlide;
    let proactiveSlide;
    let slideCurrentLoc = slideCurrent;

    const activeClass = "slider-single active";
    const slide = [...slides];
    if (slideTotal > 0) {
      if (slideCurrentLoc < slideTotal) {
        slideCurrentLoc++;
      } else {
        slideCurrentLoc = 0;
      }
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      const activeSlide = slide[slideCurrentLoc];
      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }

      slide.forEach((slid, index) => {
        if (slid.class.includes("preactivede")) {
          slid.class = "slider-single proactivede";
        }
        if (slid.class.includes("preactive")) {
          slid.class = "slider-single preactivede";
        }
      });

      preactiveSlide.class = "slider-single preactive";
      activeSlide.class = activeClass;
      proactiveSlide.class = "slider-single proactive";
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);
    } else if (slide[0] && slide[0].class !== activeClass) {
      slide[0].class = activeClass;
      setSlides(slide);
      setSlideCurrent(0);
    }
  };
  const slideLeft = () => {
    if (slideTotal > 0) {
      let preactiveSlide;
      let proactiveSlide;
      let slideCurrentLoc = slideCurrent;
      const slide = [...slides];
      if (slideCurrentLoc > 0) {
        slideCurrentLoc--;
      } else {
        slideCurrentLoc = slideTotal;
      }

      if (slideCurrentLoc < slideTotal) {
        proactiveSlide = slide[slideCurrentLoc + 1];
      } else {
        proactiveSlide = slide[0];
      }
      let activeSlide = slide[slideCurrentLoc];
      if (slideCurrentLoc > 0) {
        preactiveSlide = slide[slideCurrentLoc - 1];
      } else {
        preactiveSlide = slide[slideTotal];
      }
      slide.forEach((slid, index) => {
        if (slid.class.includes("proactivede")) {
          slid.class = "slider-single preactivede";
        }
        if (slid.class.includes("proactive")) {
          slid.class = "slider-single proactivede";
        }
      });
      preactiveSlide.class = "slider-single preactive";
      activeSlide.class = "slider-single active";
      proactiveSlide.class = "slider-single proactive";
      setSlides(slide);
      setSlideCurrent(slideCurrentLoc);
    }
  };

  const sliderClass = (direction: any) => {
    let sliderClass = `slider-${direction}`;
    if (!arrows) {
      sliderClass = "slider-disabled";
    } else if (arrows && !arrowBorders) {
      sliderClass = `slider-${direction}-noborders`;
    }
    return sliderClass;
  };

  const onResize = useCallback(() => {
    const el = document.getElementById("carouselTopId");
    if (el) {
      const child = el.getElementsByClassName("active")[0];
      if (child) {
        el.style.height = `${child.clientHeight}px`;
        const pagination = document.getElementById("sliderPaginationId");
        if (pagination) {
          if ((child.clientHeight / 400) * 32 > 10) {
            pagination.style.marginTop = `${(child.clientHeight / 400) * 32}px`;
          } else {
            pagination.style.marginTop = `5px`;
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const el = document.getElementById("carouselTopId");
      if (el) {
        const child = el.getElementsByClassName("active")[0];
        if (child) {
          el.style.height = `${child.clientHeight}px`;
          const pagination = document.getElementById("sliderPaginationId");
          if (pagination) {
            if ((child.clientHeight / 400) * 32 > 10) {
              pagination.style.marginTop = `${
                (child.clientHeight / 400) * 32
              }px`;
            } else {
              pagination.style.marginTop = `5px`;
            }
          }
        }
      }
    }, 1000);

    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
      clearTimeout(timer);
    };
  }, [onResize]);

  return (
    <div className={isPayment ? "payment-slide" : ""}>
      <div id="carouselTopId" className="react-3d-carousel" {...handlers}>
        {slides && slides.length > 0 && (
          <div className="slider-container">
            <div className="slider-content">
              {slides.map((slider, index) => (
                <div className={slider.class} key={index}>
                  <div className={sliderClass("left")} onClick={slideLeft}>
                    <div>
                      <LeftArrow />
                    </div>
                  </div>
                  <div
                    className={sliderClass("right")}
                    onClick={slideRight}
                    ref={nextRef}
                  >
                    <div>
                      <RightArrow />
                    </div>
                  </div>

                  <div className="slider-single-content">{slider.element}</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div id="sliderPaginationId" className="pagination">
        {slides.map((slider, index) => (
          <div
            key={index}
            className={`page ${slideCurrent === index ? "selected" : ""}`}
          ></div>
        ))}
      </div>
    </div>
  );
}
