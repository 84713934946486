import React from "react";

const IconFreeDownloads = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 2.75C6.89136 2.75 2.75 6.89136 2.75 12C2.75 17.1086 6.89136 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89136 17.1086 2.75 12 2.75Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V16.5C12.75 16.9142 12.4142 17.25 12 17.25C11.5858 17.25 11.25 16.9142 11.25 16.5V7.5C11.25 7.08579 11.5858 6.75 12 6.75Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.96967 11.4697C7.26256 11.1768 7.73744 11.1768 8.03033 11.4697L12 15.4393L15.9697 11.4697C16.2626 11.1768 16.7374 11.1768 17.0303 11.4697C17.3232 11.7626 17.3232 12.2374 17.0303 12.5303L12.5303 17.0303C12.2374 17.3232 11.7626 17.3232 11.4697 17.0303L6.96967 12.5303C6.67678 12.2374 6.67678 11.7626 6.96967 11.4697Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconFreeDownloads;
