import {
  AspectRatio,
  Box,
  Image,
  Input,
  Text,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import ErrorMessage from "app/components/ErrorMessage";
import ModalCropImage from "app/components/ModalCropImage";
import ProgressBar from "app/components/ProgressBar";
import { useUploadCover } from "hooks/track/useUploadCover";
import useFileUpload from "hooks/useFileUpload";
import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import AddCoverIcon from "app/assets/icons/AddCover";
import EmptyCoverIcon from "app/assets/icons/EmptyCover";
import AddCoverPlaylist from "app/assets/icons/AddCoverPlaylist";

interface CoverUploadProps {
  isCreatePlaylist?: boolean;
}

export default function CoverUpload({
  isCreatePlaylist = false,
}: CoverUploadProps) {
  const { t } = useTranslation();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();

  const {
    coverImage,
    toggleModalCropCover,
    setToggleModalCropCover,
    onCoverChange,
  } = useUploadCover();

  const [progress, handleUpload] = useFileUpload({
    onValueChange: (imageUrl) => setValue("coverUrl", imageUrl),
  });

  const coverUrl = watch("coverUrl");

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <>
      <Controller
        name={"coverUrl"}
        render={() => (
          <Box
            id="coverUrl"
            backgroundColor={isCreatePlaylist ? "none" : "#25252A"}
            mt={isCreatePlaylist ? "30px" : "20px"}
            ml={isCreatePlaylist ? "3px" : "0px"}
            p={
              isCreatePlaylist
                ? "10px"
                : isLessThan766
                ? "65px 75px 15px"
                : "45px 45px 15px"
            }
            borderTopRightRadius="4px"
            borderTopLeftRadius="4px"
            w={isCreatePlaylist ? "120px" : ""}
            height={isCreatePlaylist ? "120px" : ""}
          >
            <Input
              id="cover-track-upload"
              type="file"
              className="input-file-banner"
              accept="image/png, image/gif, image/jpeg"
              onChangeCapture={(e: React.ChangeEvent<HTMLInputElement>) =>
                onCoverChange(e)
              }
            />
            <AspectRatio ratio={1} w="100%">
              {coverUrl ? (
                <label htmlFor="cover-track-upload">
                  <Image
                    w="100%"
                    borderRadius="8px"
                    objectFit="cover"
                    alt="Track cover"
                    src={coverUrl}
                    title="Upload cover"
                    cursor="pointer"
                  />
                </label>
              ) : (
                <Box
                  backgroundColor="#535353"
                  borderRadius={isCreatePlaylist ? "4px" : "8px"}
                  w="100px"
                  height="200px"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                  title="Upload cover"
                >
                  <label htmlFor="cover-track-upload">
                    <Box
                      mx="auto"
                      w={isCreatePlaylist ? "46px" : "60px"}
                      h={isCreatePlaylist ? "46px" : "60px"}
                      borderRadius="50px"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      cursor="pointer"
                    >
                      <EmptyCoverIcon />
                    </Box>
                    {!isCreatePlaylist && (
                      <Flex alignItems="center" gridGap="8px">
                        <AddCoverIcon />
                        <Text
                          fontSize={isCreatePlaylist ? "8px" : "16px"}
                          fontFamily="Cera Pro Regular"
                        >
                          {t("uploadCover")}
                        </Text>
                      </Flex>
                    )}
                  </label>
                </Box>
              )}
            </AspectRatio>
            {coverUrl && !isCreatePlaylist && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                mt="10px"
              >
                <label htmlFor="cover-track-upload">
                  <Flex alignItems="center" gridGap="6px" cursor="pointer">
                    <AddCoverIcon />
                    <Text
                      fontWeight="600"
                      ml="10px"
                      fontFamily="Cera Pro Medium"
                    >
                      {t("uploadCover")}
                    </Text>
                  </Flex>
                </label>
              </Box>
            )}
            <Box textAlign="center">
              <ErrorMessage error={errors.coverUrl?.message} />
            </Box>
            {isCreatePlaylist && (
              <label htmlFor="cover-track-upload">
                <Flex
                  justifyContent="center"
                  mt="5px"
                  alignItems="center"
                  gridGap="8px"
                  cursor="pointer"
                >
                  <AddCoverPlaylist />
                  <Text
                    fontSize={"10px"}
                    fontFamily="Cera Pro Regular"
                    fontWeight="400"
                  >
                    {t("uploadCover")}
                  </Text>
                </Flex>
              </label>
            )}
            {isCreatePlaylist && progress > 0 && (
              <ProgressBar
                isCreatePlaylist={isCreatePlaylist}
                progress={progress}
              />
            )}

            {!isCreatePlaylist &&
              (progress && progress > 0 ? (
                <ProgressBar progress={progress} />
              ) : (
                <Text
                  textAlign="center"
                  color="#66666A"
                  fontSize="11px"
                  lineHeight="16px"
                  mt="10px"
                  fontFamily="Cera Pro Regular"
                >
                  {t("noteUploadCover")}
                </Text>
              ))}
          </Box>
        )}
        control={control}
      />
      {coverImage && (
        <ModalCropImage
          isOpen={toggleModalCropCover}
          file={coverImage}
          onClose={() => setToggleModalCropCover()}
          handleUpload={handleUpload}
        />
      )}
    </>
  );
}
