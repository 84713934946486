import React from "react";

const FreeDownload = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11.25C12 11.6642 11.6642 12 11.25 12H0.75C0.335789 12 0 11.6642 0 11.25C0 10.8358 0.335789 10.5 0.75 10.5H11.25C11.6642 10.5 12 10.8358 12 11.25ZM5.46968 8.87409C5.61614 9.02055 5.80805 9.09377 6 9.09377C6.19191 9.09377 6.38391 9.02053 6.53032 8.87409L9.18762 6.21682C9.48052 5.92392 9.48052 5.44905 9.18762 5.15616C8.89472 4.86326 8.41985 4.86326 8.12695 5.15616L6.75 6.53311V0.75C6.75 0.335789 6.41421 0 6 0C5.58579 0 5.25 0.335789 5.25 0.75V6.53311L3.87305 5.15616C3.58015 4.86326 3.10528 4.86326 2.81238 5.15616C2.51948 5.44905 2.51948 5.92392 2.81238 6.21682L5.46968 8.87409Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default FreeDownload;
