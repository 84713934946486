const keys = [
  { label: "C Major", value: "C_MAJOR" },
  { label: "C# Major / Db Major", value: "C_MAJOR_DB_MAJOR" },
  { label: "D Major", value: "D_MAJOR" },
  { label: "D# Major / Eb Major", value: "D_MAJOR_EB_MAJOR" },
  { label: "E Major", value: "E_MAJOR" },
  { label: "F Major", value: "F_MAJOR" },
  { label: "F# Major / Gb Major", value: "F_MAJOR_GB_MAJOR" },
  { label: "G Major", value: "G_MAJOR" },
  { label: "G# Major / Ab Major", value: "G_MAJOR_AB_MAJOR" },
  { label: "A Major", value: "A_MAJOR" },
  { label: "A# Major / Bb Major", value: "A_MAJOR_BB_MAJOR" },
  { label: "B Major", value: "B_MAJOR" },
  { label: "A Minor", value: "A_MINOR" },
  { label: "A# Minor / Bb Minor", value: "A_MINOR_BB_MINOR" },
  { label: "B Minor", value: "B_MINOR" },
  { label: "C Minor", value: "C_MINOR" },
  { label: "C# Minor / Db Minor", value: "C_MINOR_DB_MINOR" },
  { label: "D Minor", value: "D_MINOR" },
  { label: "D# Minor / Eb Minor", value: "D_MINOR_EB_MINOR" },
  { label: "E Minor", value: "E_MINOR" },
  { label: "F Minor", value: "F_MINOR" },
  { label: "F# Minor / Gb Minor", value: "F_MINOR_GB_MINOR" },
  { label: "G Minor", value: "G_MINOR" },
  { label: "G# Minor / Ab Minor", value: "G_MINOR_AB_MINOR" },
];

export default keys;

export const valueKey = [
  "C_MAJOR",
  "C_MAJOR_DB_MAJOR",
  "D_MAJOR",
  "D_MAJOR_EB_MAJOR",
  "E_MAJOR",
  "F_MAJOR",
  "F_MAJOR_GB_MAJOR",
  "G_MAJOR",
  "G_MAJOR_AB_MAJOR",
  "A_MAJOR",
  "A_MAJOR_BB_MAJOR",
  "B_MAJOR",
  "A_MINOR",
  "A_MINOR_BB_MINOR",
  "B_MINOR",
  "C_MINOR",
  "C_MINOR_DB_MINOR",
  "D_MINOR",
  "D_MINOR_EB_MINOR",
  "E_MINOR",
  "F_MINOR",
  "F_MINOR_GB_MINOR",
  "G_MINOR",
  "G_MINOR_AB_MINOR",
];
