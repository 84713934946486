import { createSelector } from "@reduxjs/toolkit";
import { Genre } from "app/models";
import { RootState } from "types";

export const selectGenres = (state: RootState) => state.genres;

export const selectAllSubgenres = createSelector(selectGenres, ({ data }) => {
  return data.flatMap((genre: Genre) =>
    genre.subGenres.map((subGenre) => ({
      label: subGenre,
      highlight: false,
      selected: false,
    }))
  );
});

export const selectAllGenres = createSelector(selectGenres, ({ data }) => {
  return data?.map((genre: Genre) => ({
    ...genre,
    label: genre.displayName,
    value: genre.displayName,
  }));
});
