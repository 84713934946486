import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonMusicians() {
  return (
    <>
      {Array.from({ length: 6 }).map((item, index) => (
        <Flex
          key={index}
          padding={{ base: "5px", md: "15px" }}
          mt="10px"
          alignItems="center"
        >
          <Skeleton
            w={{ base: "75px", md: "90px" }}
            h={{ base: "75px", md: "90px" }}
            borderRadius="50%"
          />
          <Box ml={{ base: "10px", md: "20px" }}>
            <Skeleton w="120px" h={{ base: "14px", md: "17px" }} />
            <Skeleton w="120px" h={{ base: "14px", md: "17px" }} />
            <Skeleton w="250px" h={{ base: "14px", md: "17px" }} />
          </Box>
        </Flex>
      ))}
    </>
  );
}
