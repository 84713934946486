import React from "react";

const IconDashboardProfile = () => {
  return (
    <svg
      width="22"
      height="24"
      viewBox="0 0 22 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.2907 6.33806L10.69 11.7752L20.0893 6.33806M10.69 22.6173V11.7645M20.38 16.0711V7.45779C20.3796 7.08018 20.2799 6.70931 20.091 6.38238C19.902 6.05545 19.6304 5.78397 19.3033 5.59516L11.7667 1.28849C11.4393 1.0995 11.068 1 10.69 1C10.312 1 9.94068 1.0995 9.61333 1.28849L2.07667 5.59516C1.74964 5.78397 1.47802 6.05545 1.28904 6.38238C1.10007 6.70931 1.00039 7.08018 1 7.45779V16.0711C1.00039 16.4487 1.10007 16.8196 1.28904 17.1465C1.47802 17.4735 1.74964 17.745 2.07667 17.9338L9.61333 22.2404C9.94068 22.4294 10.312 22.5289 10.69 22.5289C11.068 22.5289 11.4393 22.4294 11.7667 22.2404L19.3033 17.9338C19.6304 17.745 19.902 17.4735 20.091 17.1465C20.2799 16.8196 20.3796 16.4487 20.38 16.0711Z"
        stroke="#D4F70E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconDashboardProfile;
