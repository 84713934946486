import { Box, Grid, GridItem, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, Switch, useLocation } from "react-router-dom";
import ExploreHome from "./exploreHome";
import FeedHome from "./feedHome";

function HomePage() {
  const { t } = useTranslation();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [widthFeed, setWidthFeed] = useState(false);
  const checkRouterExplore = useMemo(() => {
    return location.pathname.slice(6, 13) === "explore";
  }, [location.pathname]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
      setIsLoading(true);
    };
  }, [location.pathname]);

  const isActiveRoute = useCallback(
    (routeName) => {
      return location.pathname === routeName;
    },
    [location.pathname]
  );

  const menuTabs = [
    {
      link: "/home",
      name: t("feed"),
      id: 1,
      component: <FeedHome />,
      exact: true,
      activeLink: isActiveRoute("/home") || isActiveRoute("/"),
    },
    {
      name: t("discover"),
      id: 2,
      component: <FeedHome isDiscover />,
      link: "/home/discover",
      activeLink: isActiveRoute("/home/discover"),
    },
    {
      name: t("explore"),
      id: 3,
      component: <FeedHome isExplore isLoadingExplore={isLoading} />,
      link: "/home/explore",
      activeLink: isActiveRoute("/home/explore"),
    },
  ];

  const heightLoadMore = useCallback((e) => {
    if (e.target.documentElement.scrollTop >= 180) {
      setWidthFeed(true);
    } else {
      setWidthFeed(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", heightLoadMore);
    return () => {
      window.removeEventListener("scroll", heightLoadMore);
    };
  }, [heightLoadMore]);

  return (
    <Box m={{ base: "20px 0px ", md: "80px 0px 20px 0px" }}>
      <Grid
        templateColumns="repeat(5, 1fr)"
        gap={4}
        display={{ base: "block", md: "grid" }}
      >
        <GridItem
          colSpan={1}
          mt={{ base: "10px", md: checkRouterExplore ? "-15px" : "160px" }}
          display={{ base: "flex", md: "grid" }}
          mb={{ base: "20px", md: "0px" }}
          position="sticky"
          top="94px"
          zIndex={10}
          ml={{ base: widthFeed ? "-20px" : "0px", md: "0px" }}
          mr={{ base: widthFeed ? "-20px" : "0px", md: "0px" }}
        >
          <Box
            borderRadius={widthFeed ? "0px" : "8px"}
            borderLeft={{ base: "none", md: "4px solid rgba(58, 58, 62, 0.2)" }}
            padding={{
              base: widthFeed ? "0px 20px" : "0px",
              md: "0px 10px",
            }}
            position="sticky"
            top={"300px"}
            display={{ base: "flex", md: "block" }}
            justifyContent={{ base: "space-evenly", md: "unset" }}
            w={"100%"}
            height="max-content"
            background={{ base: "#25252A", md: "none" }}
            zIndex={10}
          >
            {menuTabs.map((item, index) => {
              return (
                <Link to={item.link} key={item.id}>
                  <Box
                    padding={{ base: "10px 0px", md: "0px" }}
                    borderLeft={{
                      base: "none",
                      md: item.activeLink ? "4px solid #d4f70e" : "",
                    }}
                    marginLeft={{
                      base: 0,
                      md: item.activeLink ? "-13px" : "0px",
                    }}
                    borderBottom={{
                      base: item.activeLink ? "2px solid #d4f70e" : "",
                      md: "none",
                    }}
                  >
                    <Text
                      color={item.activeLink ? "#d4f70e" : "white"}
                      fontSize="24px"
                      lineHeight="30px"
                      fontWeight="500"
                      key={item.id}
                      marginTop={{
                        base: "0px",
                        md: index !== 0 ? "20px" : "0px",
                      }}
                      fontFamily="Cera Pro Regular"
                      marginLeft={{
                        base: 0,
                        md: item.activeLink ? "10px" : "0px",
                      }}
                      padding="0px 8px"
                      _hover={{
                        background: "#24252A",
                        border: "1px solid #38383D",
                        borderRadius: "4px",
                        padding: "0px 8px",
                        w: "max-content",
                      }}
                      borderWidth="1px"
                      borderColor={{ base: "#25252A", md: "#18181c" }}
                    >
                      {item.name}
                    </Text>
                  </Box>
                </Link>
              );
            })}
          </Box>
        </GridItem>
        <GridItem colSpan={4} w="100%">
          <Switch>
            <Route exact={true} path={"/"}>
              <FeedHome />
            </Route>
            {menuTabs.map((item) => {
              return (
                <Route exact={item.exact} key={item.id} path={item.link}>
                  {item.component}
                </Route>
              );
            })}
          </Switch>
        </GridItem>
      </Grid>

      {checkRouterExplore && <ExploreHome isLoading={isLoading} />}
    </Box>
  );
}

export default React.memo(HomePage);
