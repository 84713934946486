import { Box } from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import InfiniteHits from "app/pages/Home/infiniteHits";
import React, { useCallback, useEffect, useState } from "react";
import MusicCard from "../MusicCard";
import {
  useInfiniteHits,
  Configure,
  useInstantSearch,
} from "react-instantsearch";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import SkeletonTrackItem from "../Skeleton/skeletonTrackItem";
import ModalShowFreeDownload from "../ModalShowFreeDownload";
import useTrackSelect from "hooks/track/useTrackSelect";
import ModalShareLinkRecentActions from "app/pages/Home/modalShareLinkRecentActions";
import ModalLyrics from "../ModalShowLyrics";
import ModalShowFreeDownloadFormMailChimp from "../ModalShowFreeDownloadFormMailchimp";

interface MySoundKitProfileProps {
  userId: string | undefined;
}

function MySoundKitProfile({ userId }: MySoundKitProfileProps) {
  const { hits } = useInfiniteHits();
  const { status } = useInstantSearch();
  const [isLoading, setIsLoading] = useState(true);

  const {
    showModalFreeDownload,
    setShowModalFreeDownload,
    linkTitleDownload,
    handleFreeDownload,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    selectedTrack,
    showLyrics,
    setShowLyrics,
    onOpenOptionsModal,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const Hit = useCallback(
    ({ hit, index }: TrackSoundKit) => {
      return (
        <Box>
          <MusicCard
            onOpenOptionsModal={onOpenOptionsModal}
            item={hit}
            isProfile
            index={index}
            isSK={true}
            handleFreeDownload={handleFreeDownload}
            onOpenModalShareLink={showModalAndGetdataShareLink}
            setShortLinkUrl={setShortLink}
            setLoadingUrl={setLoadingUrl}
            listPlaylistTracksSoundPacks={hits as TrackSoundKit[]}
            handleShowModalFreeDownloadMailchimp={
              handleShowModalFreeDownloadMailchimp
            }
          />
        </Box>
      );
    },
    [
      handleFreeDownload,
      setLoadingUrl,
      setShortLink,
      showModalAndGetdataShareLink,
      onOpenOptionsModal,
      hits,
      handleShowModalFreeDownloadMailchimp,
    ]
  );

  const renderData = useCallback(() => {
    if (isLoading) {
      return <SkeletonTrackItem isProfile />;
    }
    if (
      hits?.length > 0 &&
      hits?.every((item) => item?.createdBy?._id === userId)
    ) {
      return <InfiniteHits hitComponent={Hit} />;
    }
    return ["loading", "stalled"].includes(status) ? (
      <SkeletonTrackItem isProfile />
    ) : (
      <EmptyTrackSoundkit />
    );
  }, [Hit, hits, userId, status, isLoading]);

  return (
    <Box paddingBottom="50px">
      <Configure hitsPerPage={15} filters={`createdBy._id:${userId}`} />
      {renderData()}

      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}
export default React.memo(MySoundKitProfile);
