import { Box, Flex, Divider, Image } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";
import IconPlaylistIndividual from "app/assets/icons/IconPlaylistIndividual";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import SkeletonTrackItem from "app/components/Skeleton/skeletonTrackItem";

export default function SkeletonIndividualPlaylist() {
  return (
    <Flex w="100%" flexWrap="nowrap" gridGap="30px">
      <Box borderRadius="8px" w="30%">
        <Box background="rgba(36, 37, 42, 0.3)" padding="20px 40px">
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box>
              <IconPlaylistIndividual />
            </Box>
            <Image
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              w="180px"
              height="180px"
              mt="-130px"
            />
          </Box>
          <Skeleton
            margin="auto"
            mr="none"
            background="#535353"
            w="130px"
            h="20px"
            mt="10px"
          />
          <Skeleton
            margin="auto"
            mr="none"
            background="#535353"
            w="180px"
            h="20px"
            mt="10px"
          />
          <Flex mt="20px" justifyContent="space-between">
            {Array.from({ length: 4 }).map(() => (
              <Skeleton background="#535353" h="40px" w="40px" />
            ))}
          </Flex>
          <Skeleton mt="30px" background="#535353" w="100%" h="48px" />

          <Divider
            m="20px 0px 15px 0px"
            borderColor="rgba(255, 255, 255, 0.04)"
          />
          <Skeleton mb="15px" background="#535353" w="100px" h="20px" />
          {Array.from({ length: 3 }).map(() => (
            <Flex mt="10px" justifyContent="space-between">
              <Skeleton background="#535353" w="100px" h="20px" />
              <Skeleton background="#535353" w="100px" h="20px" />
            </Flex>
          ))}
          <Skeleton mt="15px" background="#535353" w="100px" h="20px" />
          <Skeleton mt="15px" background="#535353" w="100%" h="20px" />
          <Skeleton mt="15px" background="#535353" w="100%" h="20px" />
        </Box>
      </Box>
      <Box w="70%">
        <Skeleton background="#535353" w="150px" h="30px" mt="10px" />
        <SkeletonTrackItem isProfile />
      </Box>
    </Flex>
  );
}
