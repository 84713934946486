import { Box, Image, Text } from "@chakra-ui/react";
import React from "react";
import AvataNewtalents from "app/assets/images/ImageDefaultLoading.png";
import { RecommendedModel } from "app/models";
import RedirectPage from "./redirectPage";

interface NewTalentsProps {
  item: RecommendedModel;
}

export default function NewTalents({ item }: NewTalentsProps) {
  const { pageProfile, redirectPage } = RedirectPage();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      mb={{ base: "0px", md: "20px" }}
      padding="0px 10px 15px 10px"
    >
      <Image
        cursor="pointer"
        w={{ base: "180px", md: "240px" }}
        height={{ base: "180px", md: "240px" }}
        src={item.profileImage}
        fallbackSrc={AvataNewtalents}
        borderRadius="full"
        onClick={() => redirectPage(pageProfile(item?.name))}
      />
      <Text
        m={{ base: "15px 0px 5px 0px", md: "25px 0px 15px 0px" }}
        fontSize={{ base: "24px", md: "32px" }}
        fontWeight={500}
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
        w="300px"
        textAlign="center"
        lineHeight="32px"
        _hover={{
          textDecor: "underline",
        }}
        cursor="pointer"
        fontFamily="Cera Pro Medium"
        onClick={() => redirectPage(pageProfile(item?.name))}
      >
        {item.name}
      </Text>
      <Text
        textAlign="center"
        fontSize={{ base: "16px", md: "20px" }}
        lineHeight={{ base: "16px", md: "22px" }}
        fontWeight={400}
        color="#5B5C5F"
        fontFamily="Cera Pro Regular"
      >
        {item.role[0]}
      </Text>
    </Box>
  );
}
