import { createSelector } from "@reduxjs/toolkit";

export const profileState = (state: any) => state.profile;

export const selectRecentActionsOptionProfile = createSelector(
  profileState,
  (profile) => {
    return profile.recentActions.options;
  }
);

export const selectRecentActionsItemsProfile = createSelector(
  profileState,
  (profile) => {
    return profile.recentActions.items;
  }
);

export const selectTracksOptionProfile = createSelector(
  profileState,
  (profile) => {
    return profile.tracks.options;
  }
);

export const selectTracksItemsProfile = createSelector(
  profileState,
  (profile) => {
    return profile.tracks.items;
  }
);

export const selectSoundKitOptionProfile = createSelector(
  profileState,
  (profile) => {
    return profile.soundKits.options;
  }
);

export const selectSoundKitItemsProfile = createSelector(
  profileState,
  (profile) => {
    return profile.soundKits.items;
  }
);

export const selectUserBulkOffers = createSelector(profileState, (profile) => {
  return profile.bulkOffers;
});

export const selectUserFollowers = createSelector(profileState, (profile) => {
  return profile.followers;
});

export const selectIsLoadingProfile = createSelector(
  profileState,
  (profile) => {
    return profile.isLoading;
  }
);
