import { CloseIcon, HamburgerIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Text,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Cart from "app/assets/icons/cart";
import LogoSuperproductorHeader from "app/assets/icons/logoSuperproductorHeader";
import ModalQuickStepNeeded from "app/components/ModalQuickStepNeeded";
import Notification from "app/components/Notification";
import ProfileHeader from "app/components/ProfileComponent";
import {
  totalCartSelector,
  totalDiscountCartSelector,
  totalServiceSelector,
} from "app/redux/selectors/cart";
import { selectLoggedIn } from "app/redux/selectors/users";
import { planType } from "constants/planType";
import { setSessionStorage } from "helpers/session";
import { useSubscription } from "hooks/subscription/useSubscription";
import { useToggle } from "hooks/useToggle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import DrawerSideBarHeader from "./drawerSideBar";
import LanguageDropdown from "./languageDropdown";
import ModalNotificationAddTrack from "./modalNotificationAddTrack";
import SearchInput from "./searchInput";
import UploadHeader from "./uploadHeader";
import LogoSuperproductorHeaderMobile from "app/assets/icons/logoSuperproductorHeaderMobile";
import { useTranslation } from "react-i18next";

export default function Header() {
  const history = useHistory();
  const totalCart = useSelector(totalCartSelector);
  const totalDiscount = useSelector(totalDiscountCartSelector);
  const totalService = useSelector(totalServiceSelector);
  const activeCart = useMemo(() => Number(totalCart) > 0, [totalCart]);
  const loggedIn = useSelector(selectLoggedIn);
  const [showModalAddTrack, setShowModalAddTrack] = useToggle();
  const [showModalQuickStep, setShowModalQuickStep] = useToggle();
  const [showInputSearch, setShowInputSearch] = useState(false);
  const [isPaymentMethods, setIsPaymentMethods] = useState(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { activeSubscription, getSubscriptions } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );
  const { t } = useTranslation();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    isLargerThan768 && setShowInputSearch(false);
  }, [isLargerThan768, setShowInputSearch]);

  useEffect(() => {
    if (loggedIn) {
      getSubscriptions();
    }
  }, [getSubscriptions, loggedIn]);

  const handleClickUser = useCallback(
    (props) => history.push(`/${props}`),
    [history]
  );

  const historyLogin = useCallback(async () => {
    history.push("/auth/login");
  }, [history]);

  const handleCartItem = () => {
    if (!loggedIn) {
      setSessionStorage("router", "cart");
      setShowModalQuickStep();
    } else {
      history.push("/cart");
    }
  };

  return (
    <Box
      className="header-components"
      p={{ base: "15px", md: "20px 15px 15px 20px" }}
    >
      <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
        {showInputSearch ? (
          <Flex w="100%" alignItems="center" justifyContent="center">
            <Box mr="15px" onClick={() => setShowInputSearch(false)}>
              <CloseIcon />
            </Box>
            <SearchInput />
          </Flex>
        ) : (
          <Flex
            alignItems="center"
            w={{ base: "100%", md: "0%" }}
            justifyContent="space-between"
          >
            <Flex
              mb={{ base: "0px", md: "10px" }}
              cursor="pointer"
              display={{ base: "flex", md: "none" }}
              alignItems="center"
            >
              <IconButton
                size={"lg"}
                icon={isOpen ? <CloseIcon /> : <HamburgerIcon w={7} h={7} />}
                aria-label={"Open Menu"}
                display={{ base: "flex", md: "none" }}
                onClick={isOpen ? onClose : onOpen}
                backgroundColor="#18181c"
                _hover={{ backgroundColor: "none" }}
                _active={{ backgroundColor: "none" }}
                mr="5px"
              />
              <Box onClick={() => handleClickUser("home")}>
                <LogoSuperproductorHeaderMobile />
              </Box>
            </Flex>
            <Flex
              mb={{ base: "0px", md: "10px" }}
              cursor="pointer"
              onClick={() => handleClickUser("home")}
              flexDirection="column"
              display={{ base: "none", md: "flex" }}
            >
              <LogoSuperproductorHeader />
            </Flex>
            <Box display={{ base: "none", md: "block" }}>
              <SearchInput />
            </Box>
            <Flex display={{ base: "flex", md: "none" }} alignItems="center">
              <IconButton
                backgroundColor="#24252A"
                aria-label="Search database"
                icon={<SearchIcon />}
                _hover={{ backgroundColor: "none" }}
                _active={{ backgroundColor: "none" }}
                onClick={() => setShowInputSearch(true)}
              />
              <Box
                height="40px"
                ml="10px"
                minWidth="60px"
                bg={activeCart ? "#D4F70E" : "#38383D"}
                borderRadius="8px"
                alignItems="center"
                justifyContent="space-between"
                padding="2px 5px 2px 10px"
                cursor="pointer"
                onClick={handleCartItem}
                color={activeCart ? "black" : "white"}
                display="flex"
              >
                <Text
                  mr="5px"
                  fontSize="14px"
                  fontWeight="600"
                  fontFamily="Cera Pro Regular"
                >
                  {`$${(
                    Number(totalCart) +
                    Number(totalService) -
                    Number(totalDiscount)
                  ).toFixed(2)}`}
                </Text>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  height="25px"
                  width="25px"
                  borderRadius="50%"
                  bg="#24252A"
                >
                  <Cart />
                </Box>
              </Box>
              <Box display={{ base: "block", md: "none" }}>
                {!loggedIn ? (
                  <Button
                    ml="10px"
                    borderRadius="8px"
                    type="submit"
                    background="#34391A"
                    color="#D4F70E"
                    _hover={{ background: "#34391A" }}
                    _active={{
                      border: "1px solid #D4F70E",
                    }}
                    border="1px solid #D4F70E"
                    fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                    padding="10px 30px"
                    onClick={historyLogin}
                  >
                    {t("login")}
                  </Button>
                ) : (
                  <Notification />
                )}
              </Box>
            </Flex>
          </Flex>
        )}

        <Flex
          gap={{ base: "0px", md: "10px", lg: "20px" }}
          alignItems="center"
          justifyContent="flex-end"
          display={{ base: "none", md: "flex" }}
        >
          {loggedIn && (
            <UploadHeader
              checkPlanSubscription={checkPlanSubscription}
              setIsPaymentMethods={setIsPaymentMethods}
              setShowModalAddTrack={setShowModalAddTrack}
            />
          )}
          <ProfileHeader />
          <Box
            height="33px"
            minWidth="60px"
            bg={activeCart ? "#D4F70E" : "#38383D"}
            borderRadius="full"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="2px 2px 2px 10px"
            cursor="pointer"
            onClick={handleCartItem}
            color={activeCart ? "black" : "white"}
          >
            <Text
              mr="5px"
              fontSize="14px"
              fontWeight="600"
              fontFamily="Cera Pro Regular"
            >
              {`$${(
                Number(totalCart) +
                Number(totalService) -
                Number(totalDiscount)
              ).toFixed(2)}`}
            </Text>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="25px"
              width="25px"
              borderRadius="50%"
              bg="#24252A"
            >
              <Cart />
            </Box>
          </Box>
          {loggedIn && <Notification />}
          <LanguageDropdown />
          {!loggedIn && (
            <Button
              ml="10px"
              borderRadius="8px"
              type="submit"
              background="#34391A"
              color="#D4F70E"
              _hover={{ background: "#34391A" }}
              _active={{
                border: "1px solid #D4F70E",
              }}
              border="1px solid #D4F70E"
              fontSize={{ base: "14px", md: "16px", lg: "16px" }}
              padding="10px 15px"
              onClick={historyLogin}
            >
              {t("joinNow")}
            </Button>
          )}
        </Flex>
        <DrawerSideBarHeader
          onClose={onClose}
          isOpen={isOpen}
          setShowModalAddTrack={setShowModalAddTrack}
          setIsPaymentMethods={setIsPaymentMethods}
          checkPlanSubscription={checkPlanSubscription}
        />
      </Flex>

      <ModalNotificationAddTrack
        showModal={showModalAddTrack}
        setShowModal={setShowModalAddTrack}
        isPaymentMethods={isPaymentMethods}
      />
      {showModalQuickStep && (
        <ModalQuickStepNeeded
          showModal={showModalQuickStep}
          setShowModal={setShowModalQuickStep}
        />
      )}
    </Box>
  );
}
