import ToastPlanSubscription from "app/components/toast/toastplan";
import { bodyReadNotification } from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import getOptions from "utils/getOptions";

class NotificationController {
  static async getNotifications(page: { page: number }) {
    try {
      const options = { ...getOptions(page.page) };
      const { data } = await axiosService.get("/notifications", {
        params: options,
      });
      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async createNotificationFromMessage(body: any) {
    try {
      const response = await axiosService.post(`/notifications/message`, body);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async readNotifications(body: bodyReadNotification) {
    try {
      const { data } = await axiosService.put("/notifications/read", body);

      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async deleteNotifications() {
    try {
      const { data } = await axiosService.delete("/notifications/delete-noti");
      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
}
export default NotificationController;
