import React from "react";

const IconExportNegotiation = () => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.996094" width="22" height="21.9999" rx="11" fill="#24252A" />
      <rect x="3" y="4" width="16" height="16" rx="8" fill="#F87F27" />
      <path
        d="M14.792 8H7.20801C7.09331 8 7.00003 8.09331 7.00003 8.20798V10.6066C7.00003 10.7213 7.09331 10.8146 7.20801 10.8146H7.76262C7.87729 10.8146 7.97061 10.7213 7.97061 10.6066V8.97058H14.0294V15.0294H7.97058V13.3934C7.97058 13.2787 7.87729 13.1854 7.7626 13.1854H7.20798C7.09331 13.1854 7 13.2787 7 13.3934V15.792C7 15.9067 7.09331 16 7.20798 16H14.792C14.9067 16 15 15.9067 15 15.792V8.20798C15 8.09331 14.9067 8 14.792 8Z"
        fill="#231F20"
      />
      <path
        d="M7.49756 11.7209V12.2755C7.49756 12.3902 7.59084 12.4835 7.70554 12.4835H10.6936L9.75204 13.425C9.71275 13.4643 9.69112 13.5165 9.69112 13.5721C9.69112 13.6276 9.71275 13.6799 9.75204 13.7191L10.1442 14.1113C10.1835 14.1506 10.2357 14.1723 10.2913 14.1723C10.3468 14.1723 10.399 14.1506 10.4384 14.1113L12.4044 12.1453C12.4437 12.106 12.4653 12.0538 12.4653 11.9982C12.4653 11.9427 12.4437 11.8905 12.4044 11.8512L10.4384 9.88514C10.3991 9.84588 10.3469 9.82422 10.2913 9.82422C10.2358 9.82422 10.1835 9.84585 10.1442 9.88514L9.75204 10.2773C9.67099 10.3584 9.67099 10.4904 9.75207 10.5715L10.6936 11.513H7.70554C7.59084 11.5129 7.49756 11.6062 7.49756 11.7209Z"
        fill="#231F20"
      />
    </svg>
  );
};
export default IconExportNegotiation;
