import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import AddNegotiations from "app/assets/icons/AddNegotiations";
import React, { useCallback, useMemo } from "react";
import AvatarUser from "app/assets/images/UserAvatar.png";
import TimelineNegotiations from "./timline";
import { useTranslation } from "react-i18next";
import { timeFormat } from "utils/timeAgo";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import {
  ACTIVE_STATUS,
  getStatusColor,
  STATUS_COLOR,
} from "constants/negotiationType";
import IconSuccessNegotiation from "app/assets/icons/IconSuccessNegotiation";
import IconExpireNegotiation from "app/assets/icons/IconExpireNegotiation";
import IconExportNegotiation from "app/assets/icons/IconExportNegotiation";
import { Negotiations } from "app/models";

interface NegotiationTimlineProps {
  hasTimeline?: boolean;
  isExpired?: boolean;
  negotiationItem: Negotiations;
  onAcceptNegotiation?: (negotiationItem) => void;
  onCancelOrRejectNegotiation?: (negotiationId: string, status: string) => void;
  onCounterOffer?: () => void;
  setShowModal?: () => void;
}

export default function NegotiationTimelineItem({
  hasTimeline = true,
  isExpired = false,
  negotiationItem,
  onCancelOrRejectNegotiation,
  setShowModal,
  onCounterOffer,
  onAcceptNegotiation,
}: NegotiationTimlineProps) {
  const { t } = useTranslation();
  const { createdByMe, countdownTime, isSeller, statusColor, currentUser } =
    useNegotiations();

  const displayTime = useMemo(
    () => timeFormat(negotiationItem?.expiredDate),
    [negotiationItem?.expiredDate]
  );

  const renderText = useCallback(() => {
    if (!hasTimeline) {
      if (!createdByMe(negotiationItem?.createdBy?._id)) {
        return `${negotiationItem?.createdBy?.name} ${t("offersYou")} $${
          negotiationItem?.offerPrice
        } ${t("for")} ${(negotiationItem?.tracks || []).length} ${t("Item")}:`;
      } else {
        return `${t("youCreatedAOffers")} $${negotiationItem?.offerPrice} ${t(
          "for"
        )} ${(negotiationItem?.tracks || []).length} ${t("Item")}:`;
      }
    }
    switch (negotiationItem?.status) {
      case STATUS_COLOR.PENDING:
        return `${t("offerWithPrice")} $${negotiationItem?.offerPrice} ${t(
          "isPending"
        )}`;
      case STATUS_COLOR.EXPIRED:
        return `${t("your")} $${negotiationItem?.offerPrice} ${t(
          "offerHasExpire"
        )}`;
      case STATUS_COLOR.ACCEPTED:
        return `${t("offer")} $${negotiationItem?.offerPrice} ${t(
          "hasAccepted"
        )}`;
      case STATUS_COLOR.CANCEL_BY_BUYER:
        return `${t("offer")} $${negotiationItem?.offerPrice} ${t(
          "hasBeenCanceledBy"
        )} ${negotiationItem?.createdBy?.name}`;
      case STATUS_COLOR.REJECT_BY_SELLER:
        return `${t("offer")} ${t("hasBeenRejectBy")} ${
          negotiationItem?.createdBy?.name
        }`;
      case STATUS_COLOR.COUNTEROFFER:
        if (!createdByMe(negotiationItem?.createdBy?._id)) {
          return `${negotiationItem?.createdBy?.name} ${t("hasSent")} ${t(
            "youA"
          )} ${t("counterOfferPrice")} $${negotiationItem?.offerPrice}. ${t(
            "expiredDayOn"
          )} ${countdownTime(negotiationItem?.createdAt)?.time} ${t(
            "fromNow"
          )}`;
        } else {
          return `${t("you")} ${t("hasSent")} ${t("counterOfferPrice")} $${
            negotiationItem?.offerPrice
          }`;
        }
      case STATUS_COLOR.COUNTEROFFER_ACCEPTED:
        if (!createdByMe(negotiationItem?.createdBy?._id)) {
          return `${negotiationItem?.createdBy?.name} ${t("hasAccepted")} ${t(
            "counterOfferPrice"
          )}: $${negotiationItem?.offerPrice}`;
        } else {
          return `${t("you")} ${t("hasAccepted")} ${t("counterOfferPrice")}: $${
            negotiationItem?.offerPrice
          }`;
        }
      case STATUS_COLOR.COMPLETED:
        if (!createdByMe(negotiationItem?.createdBy?._id)) {
          return `${t("youHaveReceivedPayment")} $${
            negotiationItem?.offerPrice
          }`;
        } else {
          return `${t("thanksForYourPayment")} `;
        }
      default:
        break;
    }
  }, [createdByMe, hasTimeline, negotiationItem, countdownTime, t]);

  const renderIconStatus = useCallback(() => {
    if (
      [STATUS_COLOR.COUNTEROFFER, STATUS_COLOR.COUNTEROFFER_ACCEPTED].includes(
        negotiationItem?.status
      )
    ) {
      return <IconExportNegotiation />;
    }
    return ACTIVE_STATUS.includes(negotiationItem?.status) ? (
      <IconSuccessNegotiation />
    ) : (
      <IconExpireNegotiation />
    );
  }, [negotiationItem?.status]);

  const currentStatus = useMemo(
    () => statusColor(negotiationItem?.status, isExpired),
    [isExpired, negotiationItem?.status, statusColor]
  );

  const handleCancleNegotiations = useCallback(() => {
    onCancelOrRejectNegotiation(
      negotiationItem?._id,
      currentUser?._id === negotiationItem?.createdBy?._id
        ? STATUS_COLOR.CANCEL_BY_BUYER
        : STATUS_COLOR.REJECT_BY_SELLER
    );
    setShowModal();
  }, []);

  const handleAcceptNegotiation = useCallback(
    (data) => {
      onAcceptNegotiation(data);
      setShowModal();
    },
    [onAcceptNegotiation, setShowModal]
  );

  return (
    <Box>
      {hasTimeline && <TimelineNegotiations />}
      <Box
        background="#24252A"
        padding="25px "
        borderRadius="8px"
        w={{ base: "100%", md: "343px" }}
        mb="7px"
      >
        <Text
          fontSize="12px"
          lineHeight="16px"
          fontWeight="500"
          color="rgba(255, 255, 255, 0.3)"
          textAlign="center"
        >
          {!hasTimeline && t("expiredDayOn")} {displayTime}
        </Text>
        {!hasTimeline ? (
          <Box>
            <Text
              fontSize={{ base: "16px", md: "18px" }}
              lineHeight="20px"
              fontWeight="500"
              textAlign="center"
              mt="15px"
            >
              {t("exclusiveLicense")} (MP3
              {negotiationItem?.licenses?.[0]?.extraWav && ", WAV"}
              {negotiationItem?.licenses?.[0]?.trackStems && " and TRACK STEMS"}
              )
            </Text>
            <Text
              fontSize={{ base: "16px", md: "18px" }}
              lineHeight="20px"
              fontWeight="500"
              textAlign="center"
              mt="5px"
            >
              {t("publishingNegotiation")}{" "}
              {negotiationItem?.licenses?.[0]?.beatsPublishing}%
            </Text>
          </Box>
        ) : (
          <Text
            fontSize={{ base: "16px", md: "18px" }}
            lineHeight="20px"
            fontWeight="500"
            textAlign="center"
            mt="15px"
          >
            {renderText()}
          </Text>
        )}

        <Flex
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt="25px"
          mb="20px"
        >
          {!hasTimeline && (
            <>
              <Image
                w="63px"
                height="63px"
                borderRadius="full"
                alt="User"
                src={negotiationItem?.userId?.profileImage}
                fallbackSrc={AvatarUser}
              />
              <Box m="0px 15px">
                <AddNegotiations />
              </Box>
            </>
          )}
          <Box position="relative">
            <Image
              w="63px"
              height="63px"
              borderRadius="full"
              alt="User"
              src={negotiationItem?.createdBy?.profileImage}
              border="2px solid #D4F70E"
              fallbackSrc={AvatarUser}
            />
            <Box position="absolute" right="0px" top="48px">
              {renderIconStatus()}
            </Box>
          </Box>
        </Flex>

        {!hasTimeline &&
        ACTIVE_STATUS.includes(negotiationItem?.status) &&
        !isExpired ? (
          <>
            <Flex
              display="flex"
              gridGap="10px"
              justifyContent="center"
              mb="10px"
            >
              {negotiationItem?.status !== STATUS_COLOR.ACCEPTED &&
                negotiationItem?.status !==
                  STATUS_COLOR.COUNTEROFFER_ACCEPTED &&
                (isSeller(negotiationItem) ||
                  negotiationItem?.status === STATUS_COLOR.COUNTEROFFER) &&
                !createdByMe(negotiationItem?.updatedBy?._id) && (
                  <Box
                    minW="132px"
                    height="44px"
                    background={
                      getStatusColor(negotiationItem?.status).backgroundColor
                    }
                    borderRadius="8px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => handleAcceptNegotiation(negotiationItem)}
                    cursor="pointer"
                  >
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="19px"
                      fontWeight="500"
                      color={getStatusColor(negotiationItem?.status).color}
                    >
                      {t("accept")}
                    </Text>
                  </Box>
                )}
              {![
                STATUS_COLOR.ACCEPTED,
                STATUS_COLOR.COUNTEROFFER_ACCEPTED,
              ].includes(negotiationItem?.status) &&
                !createdByMe(negotiationItem?.updatedBy?._id) && (
                  <Box
                    minW="132px"
                    height="44px"
                    background={getStatusColor().backgroundColor}
                    borderRadius="8px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={onCounterOffer}
                  >
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="19px"
                      fontWeight="500"
                      color={getStatusColor().color}
                    >
                      {t("counteroffer")}
                    </Text>
                  </Box>
                )}
              {!isSeller(negotiationItem) &&
                [
                  STATUS_COLOR.ACCEPTED,
                  STATUS_COLOR.COUNTEROFFER_ACCEPTED,
                ].includes(negotiationItem?.status) && (
                  <Box
                    minW="132px"
                    height="40px"
                    background={getStatusColor().backgroundColor}
                    borderRadius="8px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    onClick={() => onAcceptNegotiation(negotiationItem)}
                    cursor="pointer"
                  >
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight="19px"
                      fontWeight="500"
                      color={getStatusColor().color}
                    >
                      {t("paynow")}
                    </Text>
                  </Box>
                )}
            </Flex>
            {(!isSeller(negotiationItem) ||
              negotiationItem?.status !== STATUS_COLOR.ACCEPTED ||
              negotiationItem?.status !==
                STATUS_COLOR.COUNTEROFFER_ACCEPTED) && (
              <Box
                w="50%"
                height="44px"
                background={
                  getStatusColor(STATUS_COLOR.REJECT_BY_SELLER).backgroundColor
                }
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="auto"
                onClick={handleCancleNegotiations}
                cursor="pointer"
              >
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  lineHeight="19px"
                  fontWeight="500"
                  color={getStatusColor(STATUS_COLOR.REJECT_BY_SELLER).color}
                >
                  {isSeller(negotiationItem) ? t("reject") : t("cancel")}
                </Text>
              </Box>
            )}
          </>
        ) : hasTimeline ? (
          <Box display="flex" justifyContent="center" mt="60px">
            <Button
              background={currentStatus?.backgroundColor}
              borderRadius="24px"
              height="24px"
              fontSize="12px"
              lineHeight="14px"
              color={currentStatus?.color}
              padding="0px 20px"
              minW="100px"
            >
              {isExpired
                ? STATUS_COLOR.EXPIRED
                : t(`negotiationsStatus.${negotiationItem?.status}`)}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
