import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Controller } from "react-hook-form";

interface CheckBoxProps {
  placeholder?: string;
  control?: any;
  label?: string;
  name?: string;
  errors?: any;
  defaultChecked?: boolean;
  disabled?: boolean;
}

export const CheckBoxField = ({
  control,
  label = "",
  name = "",
  errors = {},
  defaultChecked = false,
  disabled = false,
  ...props
}: CheckBoxProps) => {
  return (
    <FormControl isInvalid={!!errors[name]?.message}>
      <Flex>
        <Controller
          name={name}
          control={control}
          {...props}
          render={({ field }) => {
            return (
              <>
                <Checkbox
                  isChecked={field.value}
                  className="checkbox-license"
                  size="lg"
                  _focusVisible={{ outline: "none" }}
                  defaultChecked={defaultChecked}
                  {...field}
                >
                  <Text fontSize={{ base: "14px", md: "16px" }}>{label}</Text>
                </Checkbox>
              </>
            );
          }}
        />
      </Flex>
      <FormErrorMessage color="#e41717">
        {errors[name]?.message}
      </FormErrorMessage>
    </FormControl>
  );
};
