import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface RadioProps {
  onChange?: (value: string | null) => void;
  title?: string;
  value: string;
  isChecked: boolean;
}

export default function Radio({
  onChange,
  title,
  isChecked,
  value,
}: RadioProps) {
  return (
    <Flex cursor="pointer" alignItems="center" my="9px">
      <Flex
        className="custom-radio"
        w="16px"
        height="16px"
        borderRadius="23px"
        border="1px"
        borderStyle="solid"
        borderColor="#49494D"
        alignItems="center"
        justifyContent="center"
        backgroundColor={isChecked ? "rgba(219, 243, 82, 0.2)" : "#24252A"}
        onClick={() => onChange && onChange(!isChecked ? value : null)}
      >
        {isChecked && (
          <Box
            borderRadius="23px"
            backgroundColor="#DBF352"
            width="8px"
            height="8px"
            boxShadow="0px 4px 8px #DBF352"
          />
        )}
      </Flex>
      {title && (
        <Text
          className="fontCeraProRegular"
          ml={{ base: "15px", md: "5px", lg: "15px" }}
          fontSize="14px"
          lineHeight="19px"
          fontWeight="600"
        >
          {title}
        </Text>
      )}
    </Flex>
  );
}
