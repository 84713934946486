import * as yup from "yup";

const formSchema = yup.object().shape({
  name: yup.string(),
  minimumTracks: yup
    .number()
    .typeError("minimumTracksRequired")
    .positive()
    .required("minimumTracksRequired"),
  freeTracks: yup
    .number()
    .typeError("minimumTracksRequired")
    .lessThan(yup.ref("minimumTracks"), "freeTracksLessThan")
    .required("freeTracksRequired"),
  licenses: yup.array().min(1, "atLeastOneLicense"),
});

export default formSchema;
