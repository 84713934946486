import React from "react";

const Edit = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.75 13.5014H6.93C7.0287 13.502 7.12655 13.4831 7.21793 13.4457C7.30931 13.4084 7.39242 13.3534 7.4625 13.2839L12.6525 8.08642L14.7825 6.00142C14.8528 5.9317 14.9086 5.84875 14.9467 5.75735C14.9847 5.66596 15.0043 5.56793 15.0043 5.46892C15.0043 5.36991 14.9847 5.27188 14.9467 5.18049C14.9086 5.08909 14.8528 5.00614 14.7825 4.93642L11.6025 1.71892C11.5328 1.64862 11.4498 1.59283 11.3584 1.55475C11.267 1.51667 11.169 1.49707 11.07 1.49707C10.971 1.49707 10.873 1.51667 10.7816 1.55475C10.6902 1.59283 10.6072 1.64862 10.5375 1.71892L8.4225 3.84142L3.2175 9.03892C3.14799 9.109 3.093 9.19211 3.05567 9.28349C3.01835 9.37487 2.99943 9.47271 3 9.57142V12.7514C3 12.9503 3.07902 13.1411 3.21967 13.2817C3.36032 13.4224 3.55109 13.5014 3.75 13.5014ZM11.07 3.30892L13.1925 5.43142L12.1275 6.49642L10.005 4.37392L11.07 3.30892ZM4.5 9.87892L8.9475 5.43142L11.07 7.55392L6.6225 12.0014H4.5V9.87892ZM15.75 15.0014H2.25C2.05109 15.0014 1.86032 15.0804 1.71967 15.2211C1.57902 15.3617 1.5 15.5525 1.5 15.7514C1.5 15.9503 1.57902 16.1411 1.71967 16.2817C1.86032 16.4224 2.05109 16.5014 2.25 16.5014H15.75C15.9489 16.5014 16.1397 16.4224 16.2803 16.2817C16.421 16.1411 16.5 15.9503 16.5 15.7514C16.5 15.5525 16.421 15.3617 16.2803 15.2211C16.1397 15.0804 15.9489 15.0014 15.75 15.0014Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default Edit;
