import {
  getGeneralInfoReferrals,
  setTotalReferred,
  getRecentReferrals,
} from "app/redux/actions/referrals";
import {
  selectorsGenreralInfoReferrals,
  selectorsReferrals,
} from "app/redux/selectors/referrals";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types/RootState";
import { isLoadingSelector } from "app/redux/selectors/status";

export const useReferrals = () => {
  const dispatch = useDispatch();
  const generalReferrals = useSelector(selectorsGenreralInfoReferrals);
  const referrals = useSelector(selectorsReferrals);
  const isLoadingGeneralInfoReferrals = useSelector((state: RootState) =>
    isLoadingSelector([getGeneralInfoReferrals.typePrefix], state)
  );
  const isLoadingRecentReferrals = useSelector((state: RootState) =>
    isLoadingSelector([getRecentReferrals.typePrefix], state)
  );

  const onGetGeneralInfoReferrals = useCallback(() => {
    dispatch(getGeneralInfoReferrals());
  }, [dispatch]);

  const handleSetTotalReferred = useCallback(() => {
    dispatch(setTotalReferred());
  }, [dispatch]);

  const onGetRecentReferrals = useCallback(() => {
    dispatch(getRecentReferrals());
  }, [dispatch]);

  return {
    onGetGeneralInfoReferrals,
    generalReferrals,
    handleSetTotalReferred,
    onGetRecentReferrals,
    referrals,
    isLoadingGeneralInfoReferrals,
    isLoadingRecentReferrals,
  };
};
