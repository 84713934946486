import React from "react";

const IconVideoStream = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5683 14.4733L21.4453 17.2024C21.5595 17.2531 21.6846 17.2746 21.8091 17.2647C21.9337 17.2549 22.0539 17.214 22.1586 17.1459C22.2634 17.0779 22.3495 16.9847 22.4092 16.8748C22.4688 16.765 22.5 16.642 22.5 16.5171V7.48144C22.5 7.35647 22.4688 7.23349 22.4092 7.12366C22.3495 7.01383 22.2634 6.92064 22.1586 6.85255C22.0539 6.78446 21.9337 6.74363 21.8091 6.73378C21.6846 6.72393 21.5595 6.74536 21.4453 6.79612L17.5683 9.52519C17.47 9.59439 17.3897 9.68622 17.3343 9.79292C17.279 9.89963 17.25 10.0181 17.25 10.1383V13.8602C17.25 13.9804 17.279 14.0989 17.3343 14.2056C17.3897 14.3123 17.47 14.4041 17.5683 14.4733Z"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5625 17.998H3.9375C3.2916 17.9962 2.67269 17.7388 2.21598 17.2821C1.75926 16.8253 1.50186 16.2064 1.5 15.5605V8.43555C1.50186 7.78965 1.75926 7.17074 2.21598 6.71402C2.67269 6.25731 3.2916 5.9999 3.9375 5.99805H12.585C13.2249 6.00002 13.838 6.2551 14.2905 6.70757C14.7429 7.16004 14.998 7.77316 15 8.41305V15.5605C14.9981 16.2064 14.7407 16.8253 14.284 17.2821C13.8273 17.7388 13.2084 17.9962 12.5625 17.998Z"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
      <path
        d="M8.485 10.7399C8.58936 10.7399 8.68945 10.7813 8.76324 10.8551C8.83703 10.9289 8.87849 11.029 8.87849 11.1334C8.87849 11.2781 8.93599 11.4169 9.03834 11.5193C9.14069 11.6216 9.2795 11.6791 9.42424 11.6791C9.56899 11.6791 9.7078 11.6216 9.81015 11.5193C9.9125 11.4169 9.97 11.2781 9.97 11.1334C9.9695 10.8358 9.87971 10.5452 9.71226 10.2993C9.54481 10.0533 9.30741 9.86325 9.03076 9.75369V9.54576C9.03076 9.40101 8.97326 9.2622 8.87091 9.15985C8.76856 9.0575 8.62974 9 8.485 9C8.34026 9 8.20144 9.0575 8.09909 9.15985C7.99674 9.2622 7.93924 9.40101 7.93924 9.54576V9.75369C7.61786 9.88088 7.35089 10.1161 7.1843 10.419C7.01771 10.7218 6.96193 11.0733 7.02657 11.4128C7.0912 11.7523 7.27221 12.0587 7.53842 12.2792C7.80462 12.4996 8.13937 12.6203 8.485 12.6205C8.5628 12.6205 8.63886 12.6436 8.70355 12.6868C8.76825 12.73 8.81869 12.7915 8.84848 12.8633C8.87828 12.9352 8.88611 13.0143 8.87097 13.0906C8.85583 13.1669 8.81841 13.237 8.76343 13.2921C8.70846 13.3471 8.63839 13.3846 8.5621 13.3999C8.48581 13.4151 8.40671 13.4074 8.3348 13.3777C8.26289 13.348 8.20139 13.2977 8.15809 13.233C8.11479 13.1684 8.09162 13.0924 8.09151 13.0146C8.09151 12.8698 8.03401 12.731 7.93166 12.6287C7.82931 12.5263 7.6905 12.4688 7.54576 12.4688C7.40101 12.4688 7.2622 12.5263 7.15985 12.6287C7.0575 12.731 7 12.8698 7 13.0146C7.00068 13.3119 7.09056 13.6021 7.25801 13.8478C7.42546 14.0935 7.66277 14.2833 7.93924 14.3926V14.6016C7.93924 14.7464 7.99674 14.8852 8.09909 14.9875C8.20144 15.0899 8.34026 15.1474 8.485 15.1474C8.62974 15.1474 8.76856 15.0899 8.87091 14.9875C8.97326 14.8852 9.03076 14.7464 9.03076 14.6016V14.3926C9.35214 14.2654 9.61911 14.0301 9.7857 13.7273C9.95229 13.4245 10.0081 13.073 9.94343 12.7335C9.8788 12.394 9.69779 12.0876 9.43158 11.8671C9.16538 11.6467 8.83063 11.526 8.485 11.5258C8.38064 11.5258 8.28055 11.4843 8.20676 11.4105C8.13297 11.3367 8.09151 11.2366 8.09151 11.1323C8.09151 11.0279 8.13297 10.9278 8.20676 10.854C8.28055 10.7802 8.38064 10.7399 8.485 10.7399Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconVideoStream;
