import { TrackSoundKit } from "app/models";
import {
  paymentMethodSelector,
  paymentMethodsSelector,
  paymentTypeSelector,
  shoppingCartSelector,
} from "app/redux/selectors/cart";
import { PAYMENT_TYPE } from "constants/paymentType";
import { useMemo, useState, useCallback } from "react";
import { useSelector } from "react-redux";

export const useCart = () => {
  const paymentType = useSelector(paymentTypeSelector);
  const listCart = useSelector(shoppingCartSelector);
  const paymentMethods = useSelector(paymentMethodsSelector);
  const paymentMethod = useSelector(paymentMethodSelector);

  const [selectAllCheckbox, setSelectAllCheckbox] = useState<string[]>([]);
  const [index, setIndex] = useState(0);

  const canSelectStripe = useMemo(
    () =>
      listCart?.every(
        (track: TrackSoundKit) => track.createdBy.sellerConnectedStripe
      ),
    [listCart]
  );

  const canSelectPaypal = useMemo(
    () =>
      listCart?.every(
        (track: TrackSoundKit) => track.createdBy.sellerConnectedPayPal
      ),
    [listCart]
  );

  const isStripeSelect = useMemo(
    () => paymentType === PAYMENT_TYPE[0].type,
    [paymentType]
  );

  const isPaypalSelect = useMemo(
    () => paymentType === PAYMENT_TYPE[1].type,
    [paymentType]
  );

  const handleCheckbox = useCallback(
    (id: string) => {
      const isChecked = selectAllCheckbox.find((item: string) => item === id);
      if (isChecked) {
        setSelectAllCheckbox(selectAllCheckbox.filter((item) => item !== id));
      } else {
        setSelectAllCheckbox([...selectAllCheckbox, id]);
      }
    },
    [selectAllCheckbox]
  );

  const handleSelectAll = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        const selectedTracks = listCart?.map((item: TrackSoundKit) => {
          return item?._id;
        });

        setSelectAllCheckbox(selectedTracks);
      } else {
        setSelectAllCheckbox([]);
      }
    },
    [listCart]
  );

  return {
    listCart,
    canSelectStripe,
    canSelectPaypal,
    isPaypalSelect,
    isStripeSelect,
    handleCheckbox,
    handleSelectAll,
    selectAllCheckbox,
    paymentType,
    index,
    setIndex,
    paymentMethods,
    paymentMethod,
    setSelectAllCheckbox,
  };
};
