import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import "./styles.scss";
export default function SkeletonLicenseExpiry() {
  const customLength = useBreakpointValue({ base: 2, md: 6 }) || 6;

  return (
    <Box
      m={{ base: "25px 0px", md: "30px 0px" }}
      className="item-license-expiry"
    >
      <Skeleton
        mb={{ base: "25px", md: "30px" }}
        w="280px"
        h={{ base: "24px", md: "28px", xl: "32px" }}
        borderRadius="20px"
      />
      <Box
        padding={{ base: "15px 20px 20px 20px", xl: "50px 50px 50px 50px" }}
        background="#25252A"
        borderRadius="8px"
      >
        <Flex justifyContent="end">
          <Skeleton
            mb="30px"
            w={{ base: "100%", md: "350px" }}
            h={{ base: "28px", md: "36px", xl: "40px" }}
            borderRadius="6px"
          />
        </Flex>

        <Flex
          gap={{ base: "34%", md: "10px" }}
          display={{ base: "none", md: "flex" }}
        >
          {Array.from({ length: customLength }).map((item, index) => (
            <Box className={`table-${index} flex-${index}`}>
              <Skeleton mr={"5px"} />
            </Box>
          ))}
        </Flex>
        {Array.from({ length: 10 }).map(() => (
          <Flex
            gap="10px"
            alignItems="center"
            padding="25px 0px 30px 0px"
            borderBottom="1px"
            borderBottomColor="#2D2E33"
            display={{ base: "block", md: "flex" }}
          >
            <Flex w={{ base: "100%", md: "30%" }} alignItems="center">
              <Skeleton
                w={{ base: "72px", md: "48px" }}
                h={{ base: "72px", md: "48px" }}
                borderRadius="4px"
              />
              <Box ml={{ base: "10px", md: "5px" }}>
                <Skeleton w={"140px"} h="16px" />
                <Flex alignItems="center">
                  <Skeleton w={"34px"} h="16px" borderRadius="8px" />
                  <Skeleton w={"80px"} h="16px" />
                </Flex>
              </Box>
            </Flex>
            <Skeleton
              w="90px"
              mt="16px"
              mb="12px"
              display={{ base: "block", md: "none" }}
            />
            <Flex w={{ base: "100%", md: "20%" }}>
              <Skeleton w="40px" h="40px" borderRadius="full" />
              <Box>
                <Skeleton w={{ base: "50px", md: "93px" }} h="16px" />
                <Skeleton w="27px" h="16px" />
              </Box>
            </Flex>
            <Skeleton
              w="90px"
              mt="16px"
              mb="12px"
              display={{ base: "block", md: "none" }}
            />
            <Box w={{ base: "100%", md: "10%" }}>
              <Skeleton w="81px" h="22px" borderRadius="4px" />
            </Box>
            <Skeleton
              w="90px"
              mt="16px"
              mb="12px"
              display={{ base: "block", md: "none" }}
            />
            <Box
              display="flex"
              w={{ base: "100%", md: "15%" }}
              flexDirection="column"
              alignItems={{ base: "start", md: "center" }}
            >
              <Skeleton w={"81px"} h={"16px"} borderRadius="4px" />

              <Skeleton w={"59px"} h="10px" />
              <Skeleton w={"59px"} h="10px" />
              <Skeleton w={"59px"} h="10px" />
            </Box>

            <Skeleton
              w="90px"
              mt="16px"
              mb="12px"
              display={{ base: "block", md: "none" }}
            />
            <Box w={{ base: "100%", md: "15%" }} display="flex">
              <Skeleton w={"59px"} h="16px" />
              <Skeleton
                w={"90px"}
                h="16px"
                display={{ base: "block", md: "none" }}
              />
            </Box>
            <Skeleton
              w="90px"
              mt="16px"
              mb="12px"
              display={{ base: "block", md: "none" }}
            />
            <Box
              justifyContent={{ base: "start", md: "end" }}
              w={{ base: "15%", md: "10%" }}
            >
              <Skeleton
                w={{ base: "40px", md: "24px" }}
                h={{ base: "30px", md: "22px" }}
                borderRadius="4px"
                mr={{ base: "0px", md: "30px" }}
              />
            </Box>
          </Flex>
        ))}
      </Box>
    </Box>
  );
}
