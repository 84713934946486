import { Divider, Flex } from "@chakra-ui/react";
import PopoverInfoProfile from "app/components/RecentAction/popoverInfoProfile";
import { ConversationInfo } from "app/models";
import { selectUserAdmin } from "app/redux/selectors/users";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useUsersInfo } from "../../hooks/useUsersInfo";

interface ChatHeaderProps {
  conversation?: ConversationInfo | undefined;
}

export default function ChatHeader({ conversation }: ChatHeaderProps) {
  const { data: users } = useUsersInfo(conversation.users);
  const currentUser = useSelector(selectUserAdmin);

  const filtered = users?.filter((user) => user.id !== currentUser?._id);
  const iddMessages = users?.map((user) => user.id);
  const filterIdMessages = iddMessages?.filter(
    (item) => item !== currentUser?._id
  );
  const infoMessages = useMemo(() => {
    return {
      id: filterIdMessages?.[0],
      name: filtered?.[0]?.data()?.name,
      profileImage: filtered?.[0]?.data()?.profileImage,
    };
  }, [filterIdMessages, filtered]);

  return (
    <>
      <Flex alignItems="center" p={{ base: "16px", md: "0px" }}>
        <PopoverInfoProfile
          isPageMessages
          isRecentActions
          item={infoMessages}
        />
      </Flex>
      <Divider
        mt={{ base: "24px", md: "30px" }}
        borderColor="rgba(255, 255, 255, 0.1)"
      />
    </>
  );
}
