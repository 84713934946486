import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";
import { Playlists } from "app/models";

const selectPlaylist = (state: RootState) => state.playlist;

export const selectorPlaylist = createSelector(
  selectPlaylist,
  (state) => state
);

export const isFavoritePlaylistSelector = (item: Playlists) =>
  createSelector(selectorPlaylist, ({ favoritesPlaylists }) => {
    return (
      (favoritesPlaylists || []).findIndex(
        (favItem: Playlists) => favItem?._id === item?._id
      ) !== -1
    );
  });

export const isRepostPlaylistSelector = (item: Playlists) =>
  createSelector(selectorPlaylist, ({ repostPlaylists }) => {
    return (
      (repostPlaylists || []).findIndex(
        (favItem: Playlists) => favItem?._id === item?._id
      ) !== -1
    );
  });
