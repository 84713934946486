import { Box, Button, Flex, Text } from "@chakra-ui/react";
import IconAddNewCard from "app/assets/icons/iconAddNewCard";
import IconPaypalCard from "app/assets/icons/iconPaypal";
import IconStripeCard from "app/assets/icons/iconStripe";
import {
  listStripePaymentMethod,
  selectPaymentMethods,
  selectPaymentType,
} from "app/redux/actions/cart";
import { currentCartSelector } from "app/redux/selectors/cart";
import { selectUserAdmin } from "app/redux/selectors/users";
import { PAYMENT_TYPE, PAYMENT_TYPE_METHOD } from "constants/paymentType";
import { useCart } from "hooks/cart/useCart";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import CreditCard from "../AddStripeCard/CreditCard";
import "./styles.scss";
import IconRemoveCreditCard from "app/assets/icons/RemoveCreditCard";
import ModalRemoveCard from "../ModalRemoveCard/modalRemoveCard";
import { useToggle } from "hooks/useToggle";

export default function SelectPayment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useToggle();
  const history = useHistory();

  const {
    canSelectPaypal,
    canSelectStripe,
    isPaypalSelect,
    isStripeSelect,
    paymentType,
    paymentMethods,
    paymentMethod,
  } = useCart();

  const userAdmin = useSelector(selectUserAdmin);

  const listCart = useSelector(currentCartSelector);

  const checkCollaborations = (listCart || []).some(
    (item) => item?.collaborators?.length > 1
  );

  const onSelectPaymentType = useCallback(
    (type: string) => {
      dispatch(selectPaymentType(type));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      userAdmin?.customerStripeId &&
      paymentType === PAYMENT_TYPE_METHOD.STRIPE &&
      canSelectStripe &&
      !checkCollaborations
    ) {
      dispatch(listStripePaymentMethod());
    }
  }, [
    canSelectStripe,
    dispatch,
    paymentType,
    userAdmin?.customerStripeId,
    checkCollaborations,
  ]);

  const handleAddStripeCard = useCallback(() => {
    history.push("/cart/stripe-payment");
  }, [history]);

  const indexSlider = (paymentMethods || [])?.findIndex(
    (index: any) => index?.id === paymentMethod?.payload?.id
  );

  useEffect(() => {
    dispatch(selectPaymentMethods(paymentMethods[paymentMethods.length - 1]));
  }, [dispatch, paymentMethods]);

  const settings = useMemo(() => {
    return {
      initialSlide: indexSlider,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      afterChange: function (index: number) {
        dispatch(selectPaymentMethods(paymentMethods[index]));
      },
    };
  }, [dispatch, indexSlider, paymentMethods]);

  useEffect(() => {
    if (checkCollaborations) {
      dispatch(selectPaymentType(PAYMENT_TYPE[1].type));
    }
  }, [checkCollaborations, dispatch]);

  return (
    <Box>
      <Text fontSize={{ base: "20px", md: "24px" }}>{t("paymentMethods")}</Text>
      <Flex
        display={{ base: "block", md: "flex" }}
        gridGap="25px"
        mt="25px"
        cursor="pointer"
      >
        {canSelectStripe && !checkCollaborations && (
          <Box
            background="#25252A"
            w={{ base: "100%", md: "250px" }}
            height="105px"
            alignItems="center"
            display="flex"
            justifyContent="center"
            borderRadius="8px"
            mb={{ base: "20px", md: "0px" }}
            onClick={() => onSelectPaymentType(PAYMENT_TYPE[0].type)}
            border={isStripeSelect ? "1px solid #D4F70E" : "none"}
          >
            <IconStripeCard />
          </Box>
        )}

        {canSelectPaypal && (
          <Box
            background="#25252A"
            w={{ base: "100%", md: "250px" }}
            height="105px"
            alignItems="center"
            display="flex"
            justifyContent="center"
            borderRadius="8px"
            onClick={() => onSelectPaymentType(PAYMENT_TYPE[1].type)}
            border={isPaypalSelect ? "1px solid #D4F70E" : "none"}
          >
            <IconPaypalCard />
          </Box>
        )}
      </Flex>
      {isStripeSelect && canSelectStripe && !checkCollaborations && (
        <Box>
          <Button
            mt={{ base: "20px", md: "25px" }}
            leftIcon={<IconAddNewCard />}
            background="#25252A"
            borderRadius="4px"
            w={{ base: "100%", lg: "81%", xl: "74%" }}
            _hover={{ background: "#25252A" }}
            _active={{ background: "#D4F70E" }}
            onClick={handleAddStripeCard}
          >
            {t("addNewCard")}
          </Button>
          <Box className="select-payment" mt={{ base: "20px", md: "50px" }}>
            <Slider {...settings}>
              {paymentMethods?.map((paymentMethod: any) => {
                return (
                  <CreditCard
                    key={paymentMethod.id}
                    name={paymentMethod?.billing_details?.name}
                    accountNumber={paymentMethod?.card?.last4}
                    date={paymentMethod?.card}
                    isPaymentMethod
                    brand={paymentMethod.card.brand}
                  />
                );
              })}
            </Slider>
          </Box>
          {paymentMethods?.length > 0 && (
            <Button
              mt={{ base: "20px", md: "35px" }}
              leftIcon={<IconRemoveCreditCard />}
              borderRadius="4px"
              w={{ base: "100%", lg: "81%", xl: "74%" }}
              backgroundColor="#ED3636"
              _hover={{ backgroundColor: "none" }}
              onClick={setShowModal}
            >
              {t("removeCard")}
            </Button>
          )}
        </Box>
      )}
      <ModalRemoveCard showModal={showModal} setShowModal={setShowModal} />
    </Box>
  );
}
