import React from "react";

interface IconPaypalProps {
  isCheckout?: boolean;
}

const IconPaypalCard = ({ isCheckout = false }: IconPaypalProps) => {
  return (
    <svg
      width={isCheckout ? "18" : "34"}
      height={isCheckout ? "20" : "38"}
      viewBox="0 0 34 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.4086 2.86663C26.6369 0.85975 23.4307 0 19.329 0H7.42548C6.58711 0 5.87461 0.605625 5.74161 1.425L0.785054 32.6111C0.687679 33.2262 1.16743 33.782 1.79443 33.782H9.14268L10.9881 22.1682L10.9311 22.534C11.0617 21.7146 11.7718 21.109 12.6102 21.109H16.1014C22.9628 21.109 28.3327 18.3445 29.9026 10.3479C29.9501 10.1104 30.0237 9.65438 30.0237 9.65438C30.4701 6.69513 30.0212 4.68825 28.4086 2.86663Z"
        fill="white"
      />
      <path
        d="M31.8829 11.1689C30.1777 19.0421 24.7365 23.2078 16.101 23.2078H12.9708L10.6338 37.9993H15.7115C16.4454 37.9993 17.07 37.4697 17.184 36.7501L17.2434 36.4366L18.4119 29.0954L18.4879 28.6917C18.6019 27.9721 19.2265 27.4424 19.958 27.4424H20.8867C26.8883 27.4424 31.586 25.0247 32.9588 18.0303C33.5098 15.2231 33.2438 12.8694 31.8829 11.1689Z"
        fill="white"
      />
    </svg>
  );
};
export default IconPaypalCard;
