import React from "react";

interface IconLikeIndividualTrackProps {
  isFavorite?: boolean;
}

const IconLikeIndividualTrack = ({
  isFavorite,
}: IconLikeIndividualTrackProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="30"
      viewBox="0 0 32 30"
      fill="none"
    >
      <path
        d="M28.4553 2.91407C26.7928 1.24041 24.5887 0.222838 22.2438 0.0464166C19.8989 -0.130005 17.5689 0.54644 15.6777 1.95273C13.6853 0.461299 11.2055 -0.214989 8.73753 0.0600585C6.26958 0.335106 3.99683 1.54106 2.37697 3.43506C0.757109 5.32907 -0.0895429 7.77044 0.00750908 10.2676C0.104561 12.7647 1.13811 15.132 2.90002 16.8929L14.5659 28.6338C14.7115 28.7815 14.8847 28.8988 15.0755 28.9788C15.2663 29.0588 15.471 29.1 15.6777 29.1C15.8844 29.1 16.0891 29.0588 16.2799 28.9788C16.4707 28.8988 16.6439 28.7815 16.7895 28.6338L28.4553 16.8929C29.3677 15.9752 30.0915 14.8857 30.5853 13.6864C31.0791 12.4871 31.3333 11.2017 31.3333 9.90348C31.3333 8.60531 31.0791 7.31986 30.5853 6.12059C30.0915 4.92131 29.3677 3.83172 28.4553 2.91407ZM26.2474 14.6708L15.6777 25.2928L5.10792 14.6708C4.17649 13.7295 3.54199 12.5323 3.28402 11.2294C3.02605 9.92654 3.15609 8.57602 3.65783 7.34725C4.15958 6.11849 5.01068 5.0662 6.10437 4.32239C7.19806 3.57858 8.48565 3.17638 9.80559 3.16623C11.569 3.17058 13.2585 3.87909 14.5033 5.13619C14.6488 5.2839 14.822 5.40114 15.0128 5.48115C15.2037 5.56116 15.4083 5.60235 15.615 5.60235C15.8218 5.60235 16.0264 5.56116 16.2173 5.48115C16.4081 5.40114 16.5813 5.2839 16.7268 5.13619C18.0082 4.01864 19.6636 3.43279 21.358 3.49722C23.0524 3.56164 24.6592 4.27153 25.8531 5.48319C27.0471 6.69486 27.7391 8.31781 27.789 10.0236C27.839 11.7293 27.2432 13.3904 26.1222 14.6708H26.2474Z"
        fill={isFavorite ? "#D4F70E" : "white"}
      />
    </svg>
  );
};
export default IconLikeIndividualTrack;
