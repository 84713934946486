import { useTranslation } from "react-i18next";
import PaymentAndWallets from "app/apis/paymentAndWallets";
import {
  getAccountDetails,
  getTotalProfitPayment,
  lastestTransaction,
  paypalSellerStatus,
  updateConnectPaymentType,
} from "app/redux/actions/paymentWallets";
import { getUserInfo } from "app/redux/actions/users";
import {
  isLoadingPaymentWalletsSelector,
  selectAccountDetails,
  selectFetchTransactionOptions,
  selectpaymentWallets,
  selectStripeBalance,
  selectMerchantIdInfoSelector,
} from "app/redux/selectors/paymentWallets";
import { PAYMENT_TYPE } from "constants/index";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAYMENT_TYPE_METHOD } from "constants/paymentType";
import ToastPlanSubscription from "app/components/toast/toastplan";

export const usePaymentWallets = () => {
  const dispatch: any = useDispatch();
  const lastestTransactions = useSelector(selectpaymentWallets);
  const fetchTransactionOptions = useSelector(selectFetchTransactionOptions);
  const [isLoadingDisconnect, setIsLoadingDisconnect] = useState(false);

  const merchantIdInfo = useSelector(selectMerchantIdInfoSelector);
  const accountDetails = useSelector(selectAccountDetails);
  const stripeBalance = useSelector(selectStripeBalance);
  const isLoading = useSelector(isLoadingPaymentWalletsSelector);

  const [isOpenOption, setIsOpenOption] = useState(false);
  const [paymentType, setPaymentType] = useState("");
  const { t } = useTranslation();
  const getLastestTransaction = useCallback(
    (option) => {
      dispatch(lastestTransaction(option));
    },
    [dispatch]
  );

  const getPaypalSellerStatus = useCallback(
    (merchantIdInPayPal) => {
      dispatch(paypalSellerStatus(merchantIdInPayPal));
    },
    [dispatch]
  );

  const handleUpdatePaypalMerchantID = useCallback(
    async (params) => {
      try {
        const data = await PaymentAndWallets.updatePaypalMerchantID({
          trackID: params.merchantId,
          merchantIdInPayPal: params.merchantIdInPayPal,
        });
        if (data.success) {
          dispatch(getUserInfo());
        }
      } catch (e) {
        throw e;
      }
    },
    [dispatch]
  );

  const handleCreateStripe = useCallback(async () => {
    dispatch(getAccountDetails()).then((res) => {
      if (res?.external_accounts?.data?.length > 0) {
        dispatch(updateConnectPaymentType({ sellerConnectedStripe: true }));
      }
    });
  }, [dispatch]);

  const confirmDisconnect = useCallback(async () => {
    try {
      setIsLoadingDisconnect(true);
      const data = await PaymentAndWallets.updateConnectPaymentType(
        paymentType === PAYMENT_TYPE.PAYPAL
          ? { sellerConnectedPayPal: false, merchantIdInPayPal: null }
          : { sellerConnectedStripe: false }
      );
      if (data.success) {
        dispatch(getUserInfo());
        setIsOpenOption(false);
        setIsLoadingDisconnect(false);
        ToastPlanSubscription({
          description: t("disconnectSuccessfully"),
          isError: false,
        });
      }
    } catch (e) {
      setIsLoadingDisconnect(false);
      ToastPlanSubscription({ description: t("disconnectFailed") });
    }
  }, [paymentType, dispatch, t]);

  const handleDisconnect = useCallback((paymentType, connected) => {
    if (connected) {
      setIsOpenOption(true);
      setPaymentType(paymentType);
    }
  }, []);

  const handleCancelDisconnectPayment = useCallback(() => {
    setIsOpenOption(false);
  }, []);

  const onGetTotalProfit = useCallback(() => {
    dispatch(getTotalProfitPayment(PAYMENT_TYPE_METHOD.STRIPE));
    dispatch(getTotalProfitPayment(PAYMENT_TYPE_METHOD.PAYPAL));
  }, [dispatch]);

  return {
    getLastestTransaction,
    lastestTransactions,
    getPaypalSellerStatus,
    merchantIdInfo,
    handleUpdatePaypalMerchantID,
    handleCreateStripe,
    isOpenOption,
    confirmDisconnect,
    handleDisconnect,
    handleCancelDisconnectPayment,
    accountDetails,
    stripeBalance,
    onGetTotalProfit,
    isLoading,
    fetchTransactionOptions,
    isLoadingDisconnect,
    paymentType,
  };
};
