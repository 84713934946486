import { Flex, useBreakpointValue } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonNewTalents() {
  const length = useBreakpointValue({ base: 2, lg: 3 }) || 3;
  return (
    <Flex overflow="hidden">
      {Array.from({ length }).map((item, index) => (
        <Flex
          padding={{ base: "0px 3px 5px 8px", md: "0px 10px 15px 10px" }}
          flexDirection="column"
          alignItems="center"
          w="341px"
          h={{ base: "262px", md: "348px" }}
          key={index}
        >
          <Skeleton
            w={{ base: "180px", md: "240px" }}
            h={{ base: "180px", md: "240px" }}
            borderRadius="full"
            mb="20px"
          />
          <Skeleton
            w={{ base: "120px", md: "239px" }}
            h={{ base: "18px", md: "27px" }}
            mb="10px"
          />
          <Skeleton
            w={{ base: "60px", md: "89px" }}
            h={{ base: "18px", md: "27px" }}
          />
        </Flex>
      ))}
    </Flex>
  );
}
