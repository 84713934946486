import { Box, Flex, Text } from "@chakra-ui/react";
import { ItemNotifications } from "app/models";
import { PROFILE_TYPE } from "constants/contractType";
import { renderIcon, renderTitle } from "constants/notification";
import { useNotification } from "hooks/useNotifications/useNotifications";
import React, { useCallback } from "react";
import { FaEllipsisH } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { fromNow } from "utils/timeAgo";

interface NotificationItemProps {
  notification: ItemNotifications;
  handleShowSelectNegotiation?: any;
}

export default function NotificationItem({
  notification,
  handleShowSelectNegotiation,
}: NotificationItemProps) {
  const history = useHistory();

  const { onReadNotifications } = useNotification();

  const handleNewFollowers = useCallback(
    (url) => {
      history.push(url);
      onReadNotifications({ ids: [notification?._id] });
    },
    [history, notification?._id, onReadNotifications]
  );

  const onPressItem = useCallback(() => {
    switch (notification.type) {
      case "NEW_NEGOTIATION":
      case "COUNTER_NEGOTIATION":
      case "ACCEPT_COUNTER_NEGOTIATION":
      case "ACCEPT_NEGOTIATION":
      case "NEGOTIATION_COMPLETE":
      case "CANCEL_NEGOTIATION":
      case "EXPIRED":
        handleNewFollowers("/dashboard/negotiations");
        break;
      case "NEW_TRACK":
      case "LIKE_TRACK":
      case "REPOSTS_TRACK":
        handleNewFollowers(`/track/${notification?.trackId?.slug}`);
        break;
      case "NEW_DRAFT_TRACK":
        handleNewFollowers("/dashboard/my-track/my-draft-track");
        break;
      case "NEW_SOUNDKIT":
        handleNewFollowers(`/soundkit/${notification?.soundkitId?.slug}`);
        break;
      case "NEW_FOLLOWER":
        handleNewFollowers(
          `/user/${notification?.followerId?.name}?profileType=${PROFILE_TYPE.TRACKS}`
        );
        break;
      case "NEW_MESSAGE":
        handleNewFollowers(
          `/messages?tab=all&room=${notification?.conversationId}`
        );
        break;
      case "NEW_COLLABORATIONS":
      case "ACCEPT_COLLABORATIONS":
      case "REJECT_COLLABORATIONS":
      case "UPDATE_COLLABORATION":
        handleNewFollowers(`/dashboard/collaborations`);
        break;
      case "NEW_SALE":
      case "NEW_PURCHASE":
        handleNewFollowers(`/dashboard/payment`);
        break;
      case "EXPIRING_PURCHASED":
        handleNewFollowers(`/purchased?type=expiring`);
        break;
      default:
        break;
    }
  }, [
    notification.type,
    notification?.trackId?.slug,
    notification?.soundkitId?.slug,
    notification?.followerId?.name,
    notification?.conversationId,
    handleNewFollowers,
  ]);

  return (
    <Flex onClick={onPressItem} overflow="auto" flexDirection="column">
      <Box
        p={{ base: "16px", md: "16px 26px" }}
        bg="#1F1F23"
        borderRadius="6px"
        mb="16px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        cursor="pointer"
      >
        <Flex alignItems="center">
          <Box mr={{ base: "15px", md: "26px" }}>
            {renderIcon(notification.type)}
          </Box>

          <Box color={notification.isRead ? "#535353" : "#FFFFFF"}>
            <Flex alignItems="center">
              <Box
                mr="10px"
                bg="#D4F70E"
                borderRadius="full"
                minWidth="10px"
                minHeight="10px"
              />
              <Text cursor="pointer" fontSize={{ base: "14px", md: "16px" }}>
                {renderTitle(notification.type, notification)}
              </Text>
            </Flex>

            <Text pt="10px" color="rgba(255, 255, 255, 0.5);" fontSize="12px">
              {fromNow(notification.createdAt)}
            </Text>
          </Box>
        </Flex>

        <Box cursor="pointer">
          <FaEllipsisH />
        </Box>
      </Box>
    </Flex>
  );
}
