import { AbsoluteCenter, Box, Divider, Text } from "@chakra-ui/react";
import { t } from "i18next";
import React from "react";

export default function SectionDivider() {
  return (
    <Box position="relative" my={{ base: "22px", md: "26px", lg: "30px" }}>
      <Divider borderColor="#E1DFEC" />
      <AbsoluteCenter bg="#111518" px="10px">
        <Text
          color="#828282"
          fontWeight="600"
          fontSize={{ base: "12px", md: "14px" }}
        >
          {t("SignUpWithEmail")}
        </Text>
      </AbsoluteCenter>
    </Box>
  );
}
