import React from "react";

const IconExpireNegotiation = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="22" height="22" rx="11" fill="#24252A" />
      <rect
        x="0.443359"
        y="11"
        width="14.7696"
        height="14.7696"
        rx="7.38478"
        transform="rotate(-45 0.443359 11)"
        fill="#ED3636"
      />
      <path
        d="M13.1885 12.8873C13.3239 13.0227 13.3239 13.2424 13.1885 13.3778L12.9432 13.6231C12.8077 13.7586 12.5881 13.7586 12.4527 13.6231L8.52822 9.69867C8.39275 9.5632 8.39275 9.34357 8.52822 9.20811L8.77349 8.96283C8.90896 8.82737 9.12859 8.82737 9.26405 8.96283L13.1885 12.8873Z"
        fill="#222327"
      />
      <path
        d="M12.4527 8.96283C12.5881 8.82737 12.8077 8.82737 12.9432 8.96283L13.1885 9.20811C13.3239 9.34357 13.3239 9.5632 13.1885 9.69867L9.26405 13.6231C9.12859 13.7586 8.90896 13.7586 8.77349 13.6231L8.52822 13.3778C8.39275 13.2424 8.39275 13.0227 8.52822 12.8873L12.4527 8.96283Z"
        fill="#222327"
      />
    </svg>
  );
};
export default IconExpireNegotiation;
