import { combineReducers } from "redux";
import genres from "./genres";
import licenseAndContracts from "./licenseAndContracts";
import track from "./track";
import user from "./user";
import status from "./status";
import profile from "./profile";
import subscription from "./subscription";
import cart from "./cart";
import paymentWallets from "./paymentWallets";
import player from "./player";
import playlist from "./playlist";
import myTrack from "./myTrack";
import mySoundKit from "./mySoundKit";
import addSoundkit from "./addSoundkit";
import bulkOffer from "./bulkOffer";
import promoCode from "./promoCode";
import negotiations from "./negotiations";
import collaborations from "./collaborations";
import notifications from "./notifications";
import analytics from "./analytics";
import homePage from "./home";
import purchased from "./purchased";
import referrals from "./referrals";
import filesUpload from "./filesUpload";

export default combineReducers({
  user,
  licenseAndContracts,
  track,
  genres,
  status,
  profile,
  subscription,
  cart,
  paymentWallets,
  player,
  playlist,
  myTrack,
  mySoundKit,
  addSoundkit,
  bulkOffer,
  promoCode,
  negotiations,
  collaborations,
  notifications,
  analytics,
  homePage,
  purchased,
  referrals,
  filesUpload,
});
