import { Box, Flex } from "@chakra-ui/react";
import { default as React } from "react";
import Skeleton from "../common/Skeleton/skeleton";

export default function SkeletonMyTrackNewMobile(s) {
  const fakeDataSekeleton = Array.from({ length: 20 });
  return (
    <>
      <Flex mt="20px">
        <Box w="20%">
          <Skeleton />
        </Box>
        <Box w="65%">
          <Skeleton w="150px" />
        </Box>
        <Box w="15%">
          <Skeleton />
        </Box>
      </Flex>
      {fakeDataSekeleton.map(() => (
        <Flex
          w="100%"
          alignItems="center"
          padding="15px 0px 25px 0px"
          borderBottom="1px solid #212125"
        >
          <Flex w="15%">
            <Skeleton w="20px" h="20px" borderRadius="4px" mr="12px" />
            <Skeleton w="10px" h="20px" borderRadius="4px" mr="0px" />
          </Flex>
          <Box w="70%" overflow="hidden">
            <Flex>
              <Skeleton w="35px" h="36px" borderRadius="4px" />
              <Box>
                <Skeleton w="110px" h="14px" />
                <Flex>
                  <Skeleton w="70px" h="14px" borderRadius="4px" />
                  <Skeleton w="80px" h="14px" />
                </Flex>
              </Box>
            </Flex>

            <Flex mt="10px">
              <Skeleton w="110px" borderRadius="4px" />
              <Skeleton w="110px" borderRadius="4px" />
            </Flex>
          </Box>
          <Flex justifyContent="end" w="15%">
            <Skeleton w="10px" h="30px" />
          </Flex>
        </Flex>
      ))}
    </>
  );
}
