import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import TrackService from "app/apis/tracks";
import {
  Licenses,
  LyricsModel,
  QueryPlayTrack,
  User,
  GetLyrics,
  CreateDraftTracksModel,
} from "app/models";
import { NewTrackState } from "app/redux/reducers/track";
import { COLLABORATOR_TYPE } from "constants/collaboratorType";
import { convertNewCollaborators } from "utils/convertCollaborators";

export const TYPES = {
  SUBMIT_TRACK: "SUBMIT_TRACK",
  CLEAR_FORM: "CLEAR_FORM",
  FETCH_MOODS: "FETCH_MOODS",
  PREPARE_TRACK_COLLABORATORS: "PREPARE_TRACK_COLLABORATORS",
  GET_LYRICS: "GET_LYRICS",
  UPDATE_LYRICS: "UPDATE_LYRICS",
  CREATE_LYRICS: "CREATE_LYRICS",
  PREPARE_EDIT_TRACK: "PREPARE_EDIT_TRACK",
  GET_COLLABORATORS: "GET_COLLABORATORS",
  INCREASING_TRACK_PLAY: "INCREASING_TRACK_PLAY",
  CHECK_UPLOAD_TRACK: "CHECK_UPLOAD_TRACK",
  GET_ALL_TRACK_HAD_LYRICS: "GET_ALL_TRACK_HAD_LYRICS",
  DELETE_LYRIC: "DELETE_LYRIC",
  INCREASING_SOUNDPACK_PLAY: "INCREASING_SOUNDPACK_PLAY",
  CHECK_FOLLOW_USER: "CHECK_FOLLOW_USER",
  CREATE_DRAFT_TRAKCS: "CREATE_DRAFT_TRAKCS",
  REMOVE_LINK_UPLOAD_TRACKS_FILES: "REMOVE_LINK_UPLOAD_TRACKS_FILES",
  CONVERT_AUTO_TAGGED: "CONVERT_AUTO_TAGGED",
  PREPARE_UPLOAD_TRACK_FILE: "PREPARE_UPLOAD_TRACK_FILE",
  CREATE_DOWNLOAD_TRACK_MODEL_ANALYTICS:
    "CREATE_DOWNLOAD_TRACK_MODEL_ANALYTICS",
  SET_FILE_AUTO_TAGGED: "SET_FILE_AUTO_TAGGED",
  SET_FILE_CUSTOM_TAGGED: "SET_FILE_CUSTOM_TAGGED",
  SET_FILE_TRACKOUT_STEMS: "SET_FILE_TRACKOUT_STEMS",
  SET_FILE_AUTO_TAGGED_CLOSE_PAGE: "SET_FILE_AUTO_TAGGED_CLOSE_PAGE",
  SET_FILE_CUSTOM_TAGGED_CLOSE_PAGE: "SET_FILE_CUSTOM_TAGGED_CLOSE_PAGE",
  SET_FILE_TRACKOUT_STEMS_CLOSE_PAGE: "SET_FILE_TRACKOUT_STEMS_CLOSE_PAGE",
};

export const submitTrack = createAsyncThunk(
  TYPES.SUBMIT_TRACK,
  async (values: NewTrackState, thunkApi) => {
    const { idLyrics, isEdit, ...body } = values;

    try {
      const licenses = (body.licenses || []).map(
        (license: Licenses) => license._id
      );
      const licenseChanges = (body.licenses || []).reduce(
        (accumObj: Licenses, license: Licenses) => ({
          ...accumObj,
          [license._id]: Number(
            license?.trackPrice
              ? license?.trackPrice
              : license?.defaultTrackPrice
          ),
        }),
        {}
      );

      const newCollaborators = convertNewCollaborators(body?.collaborators);

      const newTrack = {
        ...body,
        bpm: Number(values.bpm),
        collaborators: newCollaborators,
        licenses,
        licenseChanges,
        idLyrics,
        typeLoopSample: JSON.parse(body.typeLoopSample),
      };
      delete newTrack.playUntaggedFile;
      if (values.isDraft && values._id && values.isEdit) {
        await TrackService.updateDraft(newTrack);
      } else if (values.isDraft && values._id) {
        await TrackService.publishDraft(newTrack);
      } else if (values.isDraft) {
        await TrackService.createDraft(newTrack);
      } else if (isEdit) {
        await TrackService.editTrack(newTrack);
      } else {
        await TrackService.submitTrack(newTrack);
      }
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  }
);

export const clearForm = createAction(TYPES.CLEAR_FORM);
export const prepareEditTrack = createAction(
  TYPES.PREPARE_EDIT_TRACK,
  (payload) => {
    return {
      payload,
    };
  }
);

export const fetchMoods = createAsyncThunk(
  TYPES.FETCH_MOODS,
  TrackService.loadAllMoods
);

export const prepareTrackCollaborator = createAsyncThunk(
  TYPES.PREPARE_TRACK_COLLABORATORS,
  (user: User) => {
    return {
      user: user._id,
      name: user.name,
      avatar: user.profileImage,
      role: COLLABORATOR_TYPE.PRODUCER,
      profitShare: 100,
      publishing: user?.publishing ? user?.publishing : 50,
    };
  }
);

export const getLyrics = createAsyncThunk(
  TYPES.GET_LYRICS,
  async (query: GetLyrics) => {
    return await TrackService.getLyrics(query);
  }
);

export const updateLyrics = createAsyncThunk(
  TYPES.UPDATE_LYRICS,
  async (data: LyricsModel) => {
    return await TrackService.updateLyrics(data);
  }
);

export const createLyrics = createAsyncThunk(
  TYPES.CREATE_LYRICS,
  async (data: LyricsModel) => {
    return await TrackService.createLyrics(data);
  }
);

export const fetchCollaborators = createAsyncThunk(
  TYPES.GET_COLLABORATORS,
  async (value?: string) => {
    return await TrackService.fetchCollaborators(value);
  }
);

export const increasingTrackPlay = createAsyncThunk(
  TYPES.INCREASING_TRACK_PLAY,
  async (query: QueryPlayTrack) => TrackService.increasingTrackPlay(query)
);

export const increasingSoundPackPlay = createAsyncThunk(
  TYPES.INCREASING_SOUNDPACK_PLAY,
  async (query: QueryPlayTrack) => TrackService.increasingSoundPackPlay(query)
);

export const checkUploadTrack = createAsyncThunk(
  TYPES.CHECK_UPLOAD_TRACK,
  TrackService.checkUploadTrack
);

export const getAllTrackLyrics = createAsyncThunk(
  TYPES.GET_ALL_TRACK_HAD_LYRICS,
  TrackService.getAllTrackLyrics
);

export const deleteLyric = createAsyncThunk(
  TYPES.DELETE_LYRIC,
  async ({
    trackId,
    isSoundPack,
  }: {
    trackId: string;
    isSoundPack?: boolean;
  }) => {
    return await TrackService.deleteLyric(trackId, isSoundPack);
  }
);

export const checkFollowUser = createAsyncThunk(
  TYPES.CHECK_FOLLOW_USER,
  async (userId: string) => {
    return await TrackService.checkFollowUser(userId);
  }
);

export const createDraftTracks = createAsyncThunk(
  TYPES.CREATE_DRAFT_TRAKCS,
  async (body: CreateDraftTracksModel) => {
    return await TrackService.createDraftTracks(body);
  }
);

export const removeLinkUploadTrackFiles = createAsyncThunk(
  TYPES.REMOVE_LINK_UPLOAD_TRACKS_FILES,
  async (body: string[]) => {
    return await TrackService.removeLinkUploadTrackFiles(body);
  }
);

export const convertAutoTagged = createAsyncThunk(
  TYPES.CONVERT_AUTO_TAGGED,
  async (link: string) => {
    return await TrackService.convertAutoTagged(link);
  }
);

export const prepareUploadTrackFile = createAction(
  TYPES.PREPARE_UPLOAD_TRACK_FILE,
  (payload) => {
    return {
      payload,
    };
  }
);

export const createModalDownloadTrackAnalytics = createAsyncThunk(
  TYPES.CREATE_DOWNLOAD_TRACK_MODEL_ANALYTICS,
  async ({ _id, isTrack }: { _id: string; isTrack?: boolean }) => {
    return await TrackService.createModalDownloadTrackAnalytics({
      _id,
      isTrack,
    });
  }
);

export const setFileAutoTagged = createAction(
  TYPES.SET_FILE_AUTO_TAGGED,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setFileCustomTagged = createAction(
  TYPES.SET_FILE_CUSTOM_TAGGED,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setFileTrackoutStems = createAction(
  TYPES.SET_FILE_TRACKOUT_STEMS,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setFileAutoTaggedClosePage = createAction(
  TYPES.SET_FILE_AUTO_TAGGED_CLOSE_PAGE,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setFileCustomTaggedClosePage = createAction(
  TYPES.SET_FILE_CUSTOM_TAGGED_CLOSE_PAGE,
  (payload) => {
    return {
      payload,
    };
  }
);

export const setFileTrackoutStemsClosePage = createAction(
  TYPES.SET_FILE_TRACKOUT_STEMS_CLOSE_PAGE,
  (payload) => {
    return {
      payload,
    };
  }
);
