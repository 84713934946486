import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import AddNegotiations from "app/assets/icons/AddNegotiations";
import React, { useCallback, useMemo } from "react";
import AvatarUser from "app/assets/images/UserAvatar.png";
import { useTranslation } from "react-i18next";
import RectangleBox from "./rectangleBox";
import { CollaborationsModel } from "app/models";
import { useCollaborations } from "hooks/Collaborations/useCollaborations";
import { timeFormat } from "utils/timeAgo";
import {
  getBorderColor,
  roleText,
  getStatusColor,
  TypeStatusCollaboration,
} from "constants/collaboratorType";
import { STATUS_COLOR } from "constants/negotiationType";
import IconTickCollaborations from "app/assets/icons/IconTickCollaborations";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import { typeMusic } from "utils/actionText";

interface CollaborationsContentProps {
  collaboration: CollaborationsModel;
  typeCollaborations?: boolean;
  isRectangBox?: boolean;
}

export default function CollaborationsContent({
  collaboration,
  typeCollaborations,
  isRectangBox = false,
}: CollaborationsContentProps) {
  const { t } = useTranslation();
  const { createdByMe, handleCollaboration } = useCollaborations();
  const { countdownTime } = useNegotiations();
  const displayTime = useMemo(
    () => timeFormat(collaboration?.updatedAt),
    [collaboration?.updatedAt]
  );

  const collaborationName = useMemo(() => {
    return collaboration?.user?.name;
  }, [collaboration?.user?.name]);

  const collaborationRole = useMemo(() => {
    return roleText(collaboration?.role);
  }, [collaboration?.role]);

  const collaborationCreatedByName = useMemo(() => {
    return collaboration?.createdBy?.name;
  }, [collaboration?.createdBy?.name]);

  const renderText = useCallback(() => {
    if (createdByMe(collaboration?.createdBy?._id)) {
      return `${t("youInvite")} ${collaborationName} ${t(
        "toCollaborateAs"
      )} ${collaborationRole} :`;
    } else {
      return `${collaborationCreatedByName} ${t(
        "inviteYouToCollaborateAs"
      )} ${collaborationRole} :`;
    }
  }, [
    collaboration?.createdBy?._id,
    collaborationCreatedByName,
    collaborationName,
    collaborationRole,
    createdByMe,
    t,
  ]);

  const renderStatusText = useCallback(() => {
    if (createdByMe(collaboration?.createdBy?._id)) {
      const collaborationsStatus = {
        [STATUS_COLOR.PENDING]: `${t(
          "invitationSentToCollaborateAs"
        )} ${collaborationRole}.`,
        [STATUS_COLOR.ACCEPTED]: `${collaborationName} ${t("hasAccepted")} ${t(
          "invitationToCollaborateAs"
        )} ${collaborationRole}.`,
        [STATUS_COLOR.REJECTED]: `${collaborationName} ${t("hasRejected")} ${t(
          "invitationToCollaborateAs"
        )} ${collaborationRole}.`,
      };
      return collaborationsStatus[collaboration?.status];
    } else {
      const collaborationsStatus = {
        [STATUS_COLOR.ACCEPTED]: `${t("you")} ${t(
          "hasAccepted"
        )} ${collaborationCreatedByName} ${t(
          "invitationToCollaborateAs"
        )} ${collaborationRole}.`,
        [STATUS_COLOR.REJECTED]: `${t("you")} ${t(
          "hasRejected"
        )} ${collaborationCreatedByName} ${t(
          "invitationToCollaborateAs"
        )} ${collaborationRole}.`,
      };
      return collaborationsStatus[collaboration?.status];
    }
  }, [
    collaboration?.createdBy?._id,
    collaboration?.status,
    collaborationCreatedByName,
    collaborationName,
    collaborationRole,
    createdByMe,
    t,
  ]);

  const renderStatus = useCallback(() => {
    const buttonStatus = {
      [STATUS_COLOR.PENDING]: t("negotiationsStatus.PENDING"),
      [STATUS_COLOR.ACCEPTED]: t("accepted"),
      [STATUS_COLOR.REJECTED]: t("rejected"),
    };

    return buttonStatus[collaboration?.status];
  }, [collaboration?.status, t]);

  const borderColor = getBorderColor(collaboration?.status);
  const statusColor = getStatusColor(collaboration?.status);
  const timeCountDown = countdownTime(collaboration?.updatedAt);

  const isPendingCollaborationDeal =
    collaboration?.status === STATUS_COLOR.PENDING &&
    timeCountDown?.time === TypeStatusCollaboration.Expired;

  const colorBorderColor = isPendingCollaborationDeal ? "#7E2C2F" : borderColor;

  const handleAcceptRejectCollaborations = useCallback(
    (status: string) => {
      if (typeCollaborations) {
        handleCollaboration({
          collaborationId: collaboration?._id,
          status: status,
          trackId: collaboration?.trackId?._id,
          type: typeMusic.TRACK,
        });
        return;
      }
      handleCollaboration({
        collaborationId: collaboration?._id,
        status: status,
        soundkitId: collaboration?.soundkitId?._id,
        type: typeMusic.SOUND_KIT,
      });
    },
    [
      collaboration?._id,
      collaboration?.soundkitId?._id,
      collaboration?.trackId?._id,
      handleCollaboration,
      typeCollaborations,
    ]
  );

  return (
    <Box mb="10px">
      {isRectangBox && <RectangleBox color={colorBorderColor} />}
      <Box
        w={{ md: "343px" }}
        background="#24252A"
        borderRadius="8px"
        padding={{ base: "12px", md: "16px", xl: "25px" }}
        minHeight={{ base: "180px", md: "270px" }}
      >
        <Text
          fontSize="12px"
          lineHeight="16px"
          fontWeight="500"
          color="rgba(255, 255, 255, 0.3)"
          textAlign="center"
          mb={{ base: "10px", md: "16px", xl: "20px" }}
        >
          {displayTime}
        </Text>
        <Text
          fontSize="18px"
          lineHeight="20px"
          fontWeight="500"
          textAlign="center"
        >
          {!isRectangBox ? renderText() : renderStatusText()}
        </Text>

        <Flex
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={{ base: "32px", md: "20px", xl: "25px" }}
          mb={{ base: "0px", md: "16px", xl: "20px" }}
        >
          {!isRectangBox && (
            <>
              <Image
                w="63px"
                h="63px"
                borderRadius="full"
                alt="User"
                src={collaboration?.createdBy?.profileImage || AvatarUser}
                fallbackSrc={AvatarUser}
              />
              <Box m="0px 15px">
                <AddNegotiations />
              </Box>
            </>
          )}

          <Box position="relative">
            <Image
              w="63px"
              h="63px"
              borderRadius="full"
              alt="User"
              src={collaboration?.user?.profileImage || AvatarUser}
              border={`2px solid ${colorBorderColor}`}
              fallbackSrc={AvatarUser}
            />
            {isRectangBox && (
              <Box position="absolute" right="0px" top="48px">
                <IconTickCollaborations color={colorBorderColor} />
              </Box>
            )}
          </Box>
        </Flex>
        {!createdByMe(collaboration?.createdBy?._id) &&
          !isPendingCollaborationDeal &&
          collaboration?.status === STATUS_COLOR.PENDING && (
            <Flex
              display="flex"
              gridGap="10px"
              justifyContent="center"
              m="30px 0px 10px 0px"
            >
              <Box
                minW="132px"
                height="44px"
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                background="rgba(212, 247, 14, 0.2)"
                onClick={() =>
                  handleAcceptRejectCollaborations(STATUS_COLOR.ACCEPTED)
                }
              >
                <Text
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="500"
                  color="#D4F70E"
                >
                  {t("accept")}
                </Text>
              </Box>

              <Box
                minW="132px"
                height="44px"
                borderRadius="8px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                background="rgba(237, 54, 54, 0.08)"
                onClick={() =>
                  handleAcceptRejectCollaborations(STATUS_COLOR.REJECTED)
                }
              >
                <Text
                  fontSize="16px"
                  lineHeight="19px"
                  fontWeight="500"
                  color="#ED3636"
                >
                  {t("reject")}
                </Text>
              </Box>
            </Flex>
          )}
        {isRectangBox && (
          <Box display="flex" justifyContent="center" mt="30px">
            <Button
              background={
                isPendingCollaborationDeal
                  ? "rgba(237, 54, 54, 0.1)"
                  : statusColor?.backgroundColor
              }
              borderRadius="24px"
              height="24px"
              fontSize="12px"
              lineHeight="14px"
              color={
                isPendingCollaborationDeal ? "#ED3636" : statusColor?.color
              }
              padding="0px 20px"
              minW="100px"
            >
              {isPendingCollaborationDeal
                ? TypeStatusCollaboration.Expired
                : renderStatus()}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
