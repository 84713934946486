import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import MonneyButton from "app/assets/icons/MoneyButton";
import React from "react";
import { Controller } from "react-hook-form";

interface InputItemProps {
  placeholder?: string;
  control?: any;
  type?: string;
  label?: string;
  name?: string;
  value?: string;
  errors?: any;
  disabled?: boolean;
  iconInput?: boolean;
}

export const InputField = ({
  placeholder = "",
  control,
  label = "",
  name = "",
  type = "",
  value = "",
  disabled = false,
  errors = {},
  iconInput = false,
  ...props
}: InputItemProps) => {
  return (
    <FormControl
      isInvalid={!!errors[name]?.message}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      <FormLabel mb="5px" color="#535353">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...props}
        render={({ field }) => {
          return (
            <>
              {iconInput ? (
                <InputGroup size="lg">
                  <InputLeftAddon
                    children={<MonneyButton />}
                    backgroundColor="#212125"
                    border="none"
                  />
                  <Input
                    id={name}
                    type={type}
                    placeholder={placeholder}
                    disabled={disabled}
                    {...field}
                    border="none"
                    backgroundColor="#25252A"
                    onFocus={(e) =>
                      e.target.addEventListener(
                        "wheel",
                        function (e) {
                          e.preventDefault();
                        },
                        { passive: false }
                      )
                    }
                  />
                </InputGroup>
              ) : (
                <Input
                  id={name}
                  type={type}
                  placeholder={placeholder}
                  disabled={disabled}
                  {...field}
                  borderRadius="4px"
                  backgroundColor="#25252A"
                  border="none"
                  size="lg"
                  onFocus={(e) =>
                    e.target.addEventListener(
                      "wheel",
                      function (e) {
                        e.preventDefault();
                      },
                      { passive: false }
                    )
                  }
                />
              )}
              <FormErrorMessage color="#e41717">
                {errors[name]?.message}
              </FormErrorMessage>
            </>
          );
        }}
      />
    </FormControl>
  );
};
