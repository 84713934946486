import {
  CloseButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  ModalHeader,
  Flex,
  Box,
  Button,
} from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import CoverUpload from "app/components/AddTrackComponents/CoverUpload";
import { FormProvider, Controller } from "react-hook-form";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import TextInput from "app/components/TextInput";
import "./styles.scss";
import AddTag from "app/components/AddTrackComponents/AddTag";
import ErrorMessage from "app/components/ErrorMessage";
import CheckBox from "app/components/Checkbox";
import ImageUploadCoverPlaylist from "app/assets/icons/ImageUploadCoverPlaylist";
import { PlaylistType } from "constants/playlistType";
import { useDispatch } from "react-redux";
import {
  createPlaylist,
  editPlaylist as updatePlaylist,
} from "app/redux/actions/playlist";
import { BeatLoader } from "react-spinners";

interface ModalCreatePlaylistProps {
  showModal: boolean;
  setShowModal: () => void;
  editPlaylist?: boolean;
}

export default function ModalCreatePlaylist({
  showModal,
  setShowModal,
  editPlaylist = false,
}: ModalCreatePlaylistProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    methods,
    selectedPlaylist,
    isLoadingCreatePlaylist,
    isLoadingEditPlaylist,
  } = usePlaylists();
  const visibility = methods.watch("visibility");

  const typeVisibility = [
    {
      name: "Public",
      value: PlaylistType.PUBLIC,
      id: 0,
    },
    {
      name: "Unlisted",
      value: PlaylistType.UNLISTED,
      id: 1,
    },
    {
      name: "private",
      value: PlaylistType.PRIVATE,
      id: 2,
    },
  ];

  const handleCreatePlaylist = useCallback(
    async (values) => {
      if (selectedPlaylist?._id) {
        await dispatch(updatePlaylist(values));
      } else {
        await dispatch(createPlaylist(values));
      }
      methods.reset();
      setShowModal();
    },
    [dispatch, methods, selectedPlaylist?._id, setShowModal]
  );

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <FormProvider {...methods}>
        <ModalContent
          minW={{ base: "350px", md: "600px", xl: "750px" }}
          margin={{ base: "0px 10px", md: "0px" }}
          maxH="600px"
        >
          <ModalHeader
            padding="20px 40px"
            fontSize={{ base: "24px", md: "32px" }}
            fontFamily="Cera Pro Regular"
          >
            {editPlaylist ? t("editPlaylist") : t("createNewPlaylist")}
          </ModalHeader>
          <CloseButton
            _focus={{
              borderWidth: "0px",
            }}
            onClick={setShowModal}
            right={3}
            top={{ base: "20px", md: "25px" }}
            fontSize={{ base: "20px", md: "24px" }}
            position="absolute"
            borderColor="#38383D"
            _hover={{
              border: "1px solid #38383D !important",
              padding: "16px",
            }}
            padding="16px"
            color="#d4f70e"
          />
          <ModalBody
            overflow="scroll"
            padding={{
              base: "10px 20px",
              md: "10px 20px 30px 20px",
              lg: "10px 20px 50px 40px",
            }}
            className="create-playlist"
          >
            <form onSubmit={methods.handleSubmit(handleCreatePlaylist)}>
              <>
                <Flex gap="60px" display={{ base: "block", md: "flex" }}>
                  <Box
                    w="20%"
                    mt="10px"
                    ml={{ base: "30px", md: "20px", lg: "30px" }}
                    position="relative"
                    mb={{ base: "20px", md: "0px" }}
                    left={{ base: "25%", md: "0" }}
                  >
                    <Box position="absolute">
                      <ImageUploadCoverPlaylist />
                    </Box>
                    <CoverUpload isCreatePlaylist={true} />
                  </Box>
                  <Box w="100%">
                    <Box>
                      <TextInput
                        labelClassName="label-text"
                        placeholder={t("enterPlaylistName")}
                        className="date-time"
                        name="name"
                        type={"text"}
                        label={t("name")}
                        errors={methods.formState.errors}
                        control={methods.control}
                      />
                    </Box>
                    <Controller
                      name={"tags"}
                      render={({ field }) => (
                        <>
                          <AddTag
                            currentTags={field?.value ?? []}
                            handleAddTag={(tags) =>
                              field.onChange({
                                target: {
                                  value: tags,
                                },
                              })
                            }
                            isCreatePlaylist
                          />
                          <ErrorMessage
                            error={methods.formState.errors.tags?.message}
                          />
                        </>
                      )}
                      control={methods.control}
                    />
                    <Box mt="2px">
                      <TextInput
                        labelClassName="label"
                        className="input-textarea"
                        type="textarea"
                        name="description"
                        placeholder={t("enterAnOptionalDescription")}
                        errors={methods.formState.errors}
                        control={methods.control}
                        label={t("fileDescription")}
                      />
                    </Box>
                    <Box mt="10px">
                      <Text
                        fontSize="14px"
                        fontFamily="Cera Pro Regular"
                        fontWeight="500"
                      >
                        {t("visibility")}
                      </Text>
                      <Flex gap="20px" mt="10px" alignItems="center">
                        <Controller
                          name={"visibility"}
                          render={({ field }) => {
                            return (
                              <>
                                {typeVisibility.map((item) => (
                                  <Flex key={item.id}>
                                    <CheckBox
                                      filledCheckbox
                                      isCreatePlaylist
                                      isChecked={visibility === item.value}
                                      onChange={(value) => {
                                        field.onChange({
                                          target: {
                                            value: item.value,
                                          },
                                        });
                                      }}
                                    />
                                    <Text
                                      ml="15px"
                                      fontSize="14px"
                                      fontFamily="Cera Pro Regular"
                                    >
                                      {item.name}
                                    </Text>
                                  </Flex>
                                ))}
                              </>
                            );
                          }}
                          control={methods.control}
                        />
                      </Flex>
                    </Box>
                  </Box>
                </Flex>
                <Flex gap="10px" mt="60px" justifyContent="flex-end">
                  <Button
                    background="rgba(255, 255, 255, 0.04)"
                    color="#383839"
                    padding="20px"
                    _hover={{ background: "rgba(255, 255, 255, 0.04)" }}
                    onClick={setShowModal}
                  >
                    {t("Cancle")}
                  </Button>
                  <Button
                    background="#34391A"
                    color="#D4F70E"
                    border="1px solid #D4F70E"
                    _hover={{
                      background: "#34391A",
                    }}
                    padding="0px 20px"
                    type="submit"
                    isLoading={isLoadingCreatePlaylist || isLoadingEditPlaylist}
                    spinner={<BeatLoader size={8} color="white" />}
                  >
                    {editPlaylist ? t("editPlaylist") : t("savePlaylist")}
                  </Button>
                </Flex>
              </>
            </form>
          </ModalBody>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
}
