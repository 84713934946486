export const renderSoundkit = (item, payload, totalFavsOrTotalRepost) => {
  return {
    ...item,
    soundkit: {
      ...item.soundkit,
      [payload]: totalFavsOrTotalRepost
        ? item.soundkit[payload] + 1
        : item.soundkit[payload] - 1,
    },
  };
};

export const renderTrack = (item, payload, totalFavsOrTotalRepost) => {
  return {
    ...item,
    track: {
      ...item.track,
      [payload]: totalFavsOrTotalRepost
        ? item.track[payload] + 1
        : item.track[payload] - 1,
    },
  };
};

export const renderPlaylist = (item, payload, totalFavsOrTotalRepost) => {
  return {
    ...item,
    playlist: {
      ...item.playlist,
      [payload]: totalFavsOrTotalRepost
        ? item.playlist[payload] + 1
        : item.playlist[payload] - 1,
    },
  };
};

export const addFavoritesRecentAction = (items, payload, likeOrUnLike) => {
  return items.map((item) =>
    item?.track?._id === payload?.track?._id
      ? renderTrack(item, "totalFavs", likeOrUnLike)
      : item
  );
};

export const addFavoritesPlaylistRecentAction = (
  items,
  payload,
  likeOrUnLike
) => {
  return items.map((item) =>
    item?.playlist?._id === payload.playlist._id
      ? renderPlaylist(item, "totalFavs", likeOrUnLike)
      : item
  );
};

export const addFavoritesSKRecentAction = (items, payload, likeOrUnLike) => {
  return items.map((item) =>
    item?.soundkit?._id === payload.soundkit._id
      ? renderSoundkit(item, "totalFavs", likeOrUnLike)
      : item
  );
};

export const addFavoritesSKProfile = (items, payload, likeOrUnLike) => {
  return items.map((item) =>
    item._id === payload?._id
      ? {
          ...item,
          totalFavs: likeOrUnLike
            ? payload?.totalFavs + 1
            : payload?.totalFavs - 1,
        }
      : item
  );
};

export const addRepostProfile = (items, payload, repostOrUnRepost) => {
  return items.map((item) =>
    item._id === payload?._id
      ? {
          ...item,
          totalRepost: repostOrUnRepost
            ? payload?.totalRepost + 1
            : payload?.totalRepost - 1,
        }
      : item
  );
};

export const addRepostRecentAction = (items, payload, repostOrUnRepost) => {
  return items.map((item) =>
    item?.track?._id === payload?.track?._id
      ? renderTrack(item, "totalRepost", repostOrUnRepost)
      : item
  );
};

export const addRepostSKRecentAction = (items, payload, repostOrUnRepost) => {
  return items.map((item) =>
    item?.soundkit?._id === payload.soundkit._id
      ? renderSoundkit(item, "totalRepost", repostOrUnRepost)
      : item
  );
};

export const addRepostPlaylistRecentAction = (
  items,
  payload,
  repostOrUnRepost
) => {
  return items.map((item) =>
    item?.playlist?._id === payload?.playlist?._id
      ? renderPlaylist(item, "totalRepost", repostOrUnRepost)
      : item
  );
};

export const handleLikeOrUnlikeSoundKitRecentActionProfile = (
  state,
  payload,
  likeOrUnLike
) => {
  const likeOrUnLikeSoundKitInRecentAction = (
    state.recentActions.items || []
  ).find((item) => {
    return item?.soundkit?._id === payload._id;
  });
  const newArray = addFavoritesSKRecentAction(
    state.recentActions.items,
    likeOrUnLikeSoundKitInRecentAction,
    likeOrUnLike
  );
  return {
    ...state,
    recentActions: {
      ...state.recentActions,
      items: [...newArray],
    },
  };
};
export const handleLikeOrUnlikeSoundKitProfile = (
  state,
  payload,
  likeOrUnLike
) => {
  const soundKitLikedOrUnLike = (state.soundKits.items || []).find(
    (item) => item._id === payload._id
  );

  const newListSoundKit = addFavoritesSKProfile(
    state.soundKits.items,
    soundKitLikedOrUnLike,
    likeOrUnLike
  );

  return {
    ...state,
    soundKits: {
      ...state.soundKits,
      items: [...newListSoundKit],
    },
  };
};

export const handleLikeOrUnLikeTrackInRecentAction = (
  state,
  payload,
  likeOrUnLike
) => {
  const recentActionLiked = (state.recentActions.items || []).find((item) => {
    return item?.track?._id === payload._id;
  });

  const newRecentAction = addFavoritesRecentAction(
    state.recentActions.items,
    recentActionLiked,
    likeOrUnLike
  );
  return {
    ...state,
    recentActions: {
      ...state.recentActions,
      items: [...newRecentAction],
    },
  };
};

export const handleLikeOrUnLikeTrack = (state, payload, likeOrUnLike) => {
  const trackLiked = (state.tracks.items || []).find(
    (item) => item._id === payload._id
  );

  const newListTrack = addFavoritesSKProfile(
    state.tracks.items,
    trackLiked,
    likeOrUnLike
  );
  return {
    ...state,
    tracks: {
      ...state.tracks,
      items: [...newListTrack],
    },
  };
};

export const handleLikeOrUnLikePlaylist = (state, payload, likeOrUnLike) => {
  const recentActionLiked = (state.recentActions.items || []).find((item) => {
    return item?.playlist?._id === payload._id;
  });

  const newRecentAction = addFavoritesPlaylistRecentAction(
    state.recentActions.items,
    recentActionLiked,
    likeOrUnLike
  );
  return {
    ...state,
    recentActions: {
      ...state.recentActions,
      items: [...newRecentAction],
    },
  };
};
