import React from "react";

interface HeartProps {
  isProfile?: boolean;
  likesMusic?: boolean;
  isInvidualTrack?: boolean;
}

const Heart = ({
  isProfile = false,
  likesMusic = false,
  isInvidualTrack = false,
}: HeartProps) => {
  return (
    <svg
      width={isProfile ? "11" : isInvidualTrack ? "18" : "14"}
      height={isProfile ? "10" : isInvidualTrack ? "20" : "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.76 2.68898C11.1407 2.06948 10.3196 1.69283 9.44605 1.62753C8.57251 1.56223 7.70454 1.81261 7 2.33314C6.2578 1.7811 5.33399 1.53077 4.41462 1.63258C3.49524 1.73439 2.64859 2.18076 2.04515 2.88182C1.44171 3.58287 1.12631 4.48653 1.16247 5.41082C1.19862 6.33511 1.58364 7.21137 2.24 7.86314L6.58583 12.209C6.64006 12.2637 6.70458 12.307 6.77566 12.3367C6.84675 12.3663 6.92299 12.3815 7 12.3815C7.077 12.3815 7.15325 12.3663 7.22433 12.3367C7.29542 12.307 7.35994 12.2637 7.41416 12.209L11.76 7.86314C12.0999 7.52348 12.3695 7.12018 12.5535 6.67627C12.7374 6.23237 12.8321 5.75657 12.8321 5.27606C12.8321 4.79555 12.7374 4.31975 12.5535 3.87585C12.3695 3.43194 12.0999 3.02864 11.76 2.68898ZM10.9375 7.04064L7 10.9723L3.0625 7.04064C2.71552 6.69222 2.47915 6.24909 2.38305 5.76684C2.28695 5.2846 2.33539 4.78471 2.5223 4.32989C2.70922 3.87507 3.02627 3.48557 3.4337 3.21026C3.84113 2.93494 4.32078 2.78607 4.8125 2.78231C5.4694 2.78392 6.0988 3.04617 6.5625 3.51148C6.61673 3.56615 6.68124 3.60955 6.75233 3.63916C6.82341 3.66878 6.89966 3.68403 6.97666 3.68403C7.05367 3.68403 7.12992 3.66878 7.201 3.63916C7.27209 3.60955 7.3366 3.56615 7.39083 3.51148C7.86819 3.09782 8.48487 2.88098 9.11606 2.90482C9.74726 2.92867 10.3458 3.19143 10.7906 3.63992C11.2354 4.08841 11.4932 4.68913 11.5118 5.3205C11.5304 5.95188 11.3084 6.56674 10.8908 7.04064H10.9375Z"
        fill={
          isProfile || isInvidualTrack
            ? "white"
            : likesMusic
            ? "#656566"
            : "#D4F70E"
        }
      />
    </svg>
  );
};
export default Heart;
