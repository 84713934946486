import { Box, Divider, Text, Flex, Checkbox, Image } from "@chakra-ui/react";
import ErrorMessage from "app/components/ErrorMessage";
import Radio from "app/components/Radio";
import { selectUserAdmin } from "app/redux/selectors/users";
import {
  DOWNLOAD_OPTIONS,
  freeDownloadOptions,
  freeDownloadSoundkitOptions,
} from "constants/downloadOptions";
import React, { useMemo, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CoverUpload from "./CoverUpload";
import "./styles.scss";
import SubmitButtonGroup from "./SubmitButtonGroup";
import VoiceTagPreview from "./VoiceTagPreview";
import IconSuperRequireDownload from "app/assets/images/IconSuperRequireDownload.svg";
import ToastPlanSubscription from "../toast/toastplan";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";

interface MenuAddTrackProp {
  isSoundkit?: boolean;
  isLoading: boolean;
  onSubmitDraft: () => void;
  checkButtonSaveDraft?: boolean;
  isEdit?: boolean;
  isDraft?: boolean;
}

export default function MenuAddTrack({
  isSoundkit,
  isLoading,
  onSubmitDraft,
  checkButtonSaveDraft,
  isEdit,
  isDraft,
}: MenuAddTrackProp) {
  const { t } = useTranslation();
  const { name } = useSelector(selectUserAdmin);

  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  const title = watch("title");
  const untaggedFileUrl = watch("untaggedFileUrl");
  const playUntaggedFileUrl = watch("playUntaggedFile");
  const bpm = watch("bpm");
  const contractType = watch("contractType");

  const bpmContract = useMemo(
    () => [
      {
        name: t("bpm"),
        value: bpm,
      },
      {
        name: t("contract"),
        value: contractType,
      },
    ],
    [bpm, contractType, t]
  );

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <Box
      position={{ base: "unset", md: "absolute" }}
      right={{ md: "20px", lg: "30px", xl: "40px" }}
      top="140px"
      className="menu-add-track"
      w={{ base: "100%", md: "32%", lg: "25%", xl: "22%" }}
    >
      <Box display={{ base: "none", lg: "block" }}>
        <SubmitButtonGroup
          isSoundkit={isSoundkit}
          isLoading={isLoading}
          onSubmitDraft={onSubmitDraft}
          checkButtonSaveDraft={checkButtonSaveDraft}
          isEdit={isEdit}
          isDraft={isDraft}
        />
      </Box>
      <Text fontSize="20px" display={{ base: "block", md: "none" }}>
        {t("coverArt")}
      </Text>

      <CoverUpload isCreatePlaylist={false} />

      <Divider borderBottom="1px solid #535353" />
      <Box
        backgroundColor="#25252A"
        p="25px 45px"
        borderBottomRightRadius="4px"
        borderBottomLeftRadius="4px"
      >
        <Text fontSize="16px" lineHeight="19px" fontFamily="Cera Pro Regular">
          {title}
        </Text>
        <Text
          color="#66666A"
          fontSize="14px"
          mb="20px"
          fontFamily="Cera Pro Regular"
        >
          By {name}
        </Text>
        {untaggedFileUrl && (
          <VoiceTagPreview
            p="0px"
            audioUrl={
              playUntaggedFileUrl ? playUntaggedFileUrl : untaggedFileUrl
            }
          />
        )}
        {!isSoundkit &&
          (bpmContract || []).map((item) => (
            <Box key={item.name} display="flex" justifyContent="space-between">
              <Text fontSize="14px" fontFamily="Cera Pro Regular">
                {item.name}
              </Text>
              <Text
                fontSize="14px"
                color="#66666A"
                fontFamily="Cera Pro Regular"
              >
                {item.value}
              </Text>
            </Box>
          ))}
      </Box>

      <Box
        backgroundColor="#25252A"
        p="25px 15px"
        mt="30px"
        borderRadius="4px"
        display={{ base: "none", md: "block" }}
      >
        <Text fontFamily="Cera Pro Medium" fontWeight="600">
          {t("requireDownloadersTo")}
        </Text>
        <Flex alignItems="center" padding="20px 0px 15px">
          <Controller
            name={"requireDownloadSp"}
            render={({ field }) => {
              return (
                <Checkbox
                  size="md"
                  isChecked={field.value}
                  className="checkbox-download"
                  {...field}
                >
                  <Flex alignItems="center">
                    <Image
                      ml="10px"
                      mr="10px"
                      fallbackSrc={IconSuperRequireDownload}
                    />
                    <Text fontWeight="500" fontSize="14px" lineHeight="16px">
                      Superproductor
                    </Text>
                  </Flex>
                </Checkbox>
              );
            }}
            control={control}
          />
        </Flex>
        <Flex alignItems="center" mb="30px">
          <Controller
            name={"requireEmailCapture"}
            render={({ field }) => {
              return (
                <Checkbox
                  size="md"
                  isChecked={field.value}
                  className="checkbox-download"
                  {...field}
                >
                  <Flex alignItems="center">
                    <Text
                      ml="10px"
                      fontWeight="500"
                      fontSize="14px"
                      lineHeight="16px"
                    >
                      {t("emailCapture")}
                    </Text>
                  </Flex>
                </Checkbox>
              );
            }}
            control={control}
          />
        </Flex>

        <Text fontFamily="Cera Pro Medium" fontWeight="600">
          {t("freeDownload")}
        </Text>
        <Controller
          name={"freeDownloadEnabled"}
          render={({ field: { onChange, value } }) => (
            <Box id="freeDownloadEnabled">
              {(isSoundkit
                ? freeDownloadSoundkitOptions
                : freeDownloadOptions
              ).map((item, index) => {
                const { label: labelValue, value: optionValue } = item;
                return (
                  <Radio
                    key={index}
                    title={t(labelValue)}
                    value={optionValue}
                    onChange={(checked) => {
                      if (checkPlanSubscription) {
                        if (
                          checked === DOWNLOAD_OPTIONS.NO ||
                          optionValue === DOWNLOAD_OPTIONS.NO
                        ) {
                          onChange({ target: { value: checked } });
                        } else {
                          ToastPlanSubscription({
                            title: t(
                              "titleUpgradeYourPlanToEnableFreeDownload"
                            ),
                            description: t(
                              "desUpgradeYourPlanToEnableFreeDownload"
                            ),
                          });
                        }
                      } else {
                        onChange({ target: { value: checked } });
                      }
                    }}
                    isChecked={optionValue === value}
                  />
                );
              })}
            </Box>
          )}
          control={control}
        />
        <ErrorMessage error={errors.freeDownloadEnabled?.message} />

        {/* SHOWME later */}
        {/* <Divider mt="20px" borderBottom="2px solid #2B2B30" />
        <Flex alignItems="center" mt="20px">
          <Checkbox mr="15px" />
          <Text ml="10px" fontSize="14px">
            {t("allowAnonymous")}
          </Text>
        </Flex> */}
      </Box>

      <Box
        backgroundColor="#25252A"
        p="25px 15px"
        mt="30px"
        borderRadius="4px"
        display={{ base: "none", md: "block" }}
      >
        <Box flexWrap="wrap" display="flex">
          <Text fontFamily="Cera Pro Regular">
            {t("beforeYouAddCollaborators")}
          </Text>
          <Text mt="20px" color="#66666A" fontFamily="Cera Pro Regular">
            {t("youMustBe")}
          </Text>
          <Text color="#66666A" mt="10px" fontFamily="Cera Pro Regular">
            {t("makeSure")}
          </Text>
          <Text color="#66666A" mt="10px" fontFamily="Cera Pro Regular">
            {t("yourCollaborator")}
          </Text>
          <Text color="#66666A" mt="10px" fontFamily="Cera Pro Regular">
            {t("needToHave")}
          </Text>
          <Text color="#66666A" mt="10px" fontFamily="Cera Pro Regular">
            {t("youCanOnly")}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
