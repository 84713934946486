import { createReducer } from "@reduxjs/toolkit";
import {
  TopUserAnalytics,
  TrackSoundKit,
  AnalyticsCurrentUserModel,
  transaction,
  User,
} from "app/models";
import * as analyticActions from "app/redux/actions/Analytics";
import * as analyticCurrentUserActions from "app/redux/actions/users";
import { AnalyticType } from "constants/analyticsType";

export interface InitAnalyticsState {
  playsAnalytics: {
    labels: string[];
    values: number[];
  };
  purchasedAnalytics: {
    labels: string[];
    values: number[];
  };
  soldAnalytics: {
    labels: string[];
    values: number[];
  };
  downloadAnalytics: {
    labels: string[];
    values: number[];
  };
  favoritesAnalytics: {
    labels: string[];
    values: number[];
  };
  analyticsListTracks: TrackSoundKit[];
  analyticsListUser: TopUserAnalytics[];
  analyticsTopCountry: TopUserAnalytics[];
  analyticsTracks: TopUserAnalytics[];
  analyticsTotalSalesTracks: TrackSoundKit[];
  analyticsCurrentUser: AnalyticsCurrentUserModel;
  tracksUpcomingLicenseExpiry: transaction[];
  userOverView: User;
  listArtistSpotify: any[];
  listTrackSpotify: any[];
  artistsSpotifyOfUser: any[];
  tracksSpotifyOfUser: any[];
}

const initialState: InitAnalyticsState = {
  playsAnalytics: {
    labels: [],
    values: [],
  },
  purchasedAnalytics: {
    labels: [],
    values: [],
  },
  soldAnalytics: {
    labels: [],
    values: [],
  },
  downloadAnalytics: {
    labels: [],
    values: [],
  },
  favoritesAnalytics: {
    labels: [],
    values: [],
  },

  analyticsListTracks: [],
  analyticsListUser: [],
  analyticsTopCountry: [],
  analyticsTracks: [],
  analyticsTotalSalesTracks: [],
  analyticsCurrentUser: {
    totalFavorites: 0,
    totalFavoritesOfMonth: 0,
    totalFavoritesOfLastMonth: 0,
    totalRepost: 0,
    totalRepostOfMonth: 0,
    totalRepostOfLastMonth: 0,
    totalFollowersOfMonth: 0,
    totalFollowersOfLastMonth: 0,
    totalNegotiations: 0,
    totalDownloadsMonth: 0,
    totalDownloadsLastMonth: 0,
    totalCollaborations: 0,
  },
  tracksUpcomingLicenseExpiry: [],
  userOverView: {},
  listArtistSpotify: [],
  listTrackSpotify: [],
  artistsSpotifyOfUser: [],
  tracksSpotifyOfUser: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(analyticActions.getAnalyticsTracks.fulfilled, (state, action) => {
      const values: number[] = Object?.values(action?.payload);
      const labels = Object?.keys(action?.payload);
      switch (action.meta.arg.analyticType) {
        case AnalyticType.PLAYED:
          return {
            ...state,
            playsAnalytics: {
              labels: labels,
              values: values,
            },
          };
        case AnalyticType.PURCHASED:
          return {
            ...state,
            purchasedAnalytics: {
              labels: labels,
              values: values,
            },
          };
        case AnalyticType.SOLD:
          return {
            ...state,
            soldAnalytics: {
              labels: labels,
              values: values,
            },
          };
        case AnalyticType.DOWNLOADED:
          return {
            ...state,
            downloadAnalytics: {
              labels: labels,
              values: values,
            },
          };
        case AnalyticType.FAVORITED:
          return {
            ...state,
            favoritesAnalytics: {
              labels: labels,
              values: values,
            },
          };
        default:
          break;
      }
    })
    .addCase(
      analyticActions.getListAnalyticsTotalTrack.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsListTracks: action.payload,
        };
      }
    )
    .addCase(
      analyticActions.getListAnalyticsUser.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsListUser: action.payload,
        };
      }
    )
    .addCase(
      analyticActions.getListAnalyticsTopCountry.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsTopCountry: action.payload,
        };
      }
    )
    .addCase(
      analyticActions.getListAnalyticsTracks.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsTracks: action.payload,
        };
      }
    )
    .addCase(
      analyticActions.getTotalSalesAnalyticsTracks.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsTotalSalesTracks: action.payload,
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getAnalyticsCurrentUser.fulfilled,
      (state, action) => {
        return {
          ...state,
          analyticsCurrentUser: action.payload,
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getListLicenseExpiry.fulfilled,
      (state, action) => {
        return {
          ...state,
          tracksUpcomingLicenseExpiry: action.payload,
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getUserOverView.pending,
      (state, action) => {
        return {
          ...state,
          userOverView: {},
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getUserOverView.fulfilled,
      (state, action) => {
        return {
          ...state,
          userOverView: {
            ...state.userOverView,
            ...action.payload,
          },
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getListArtistSpotify.fulfilled,
      (state, action) => {
        return {
          ...state,
          listArtistSpotify: action.payload,
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getListTracksSpotify.fulfilled,
      (state, action) => {
        return {
          ...state,
          listTrackSpotify: action.payload,
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getArtistsSpotifyOfUser.fulfilled,
      (state, action) => {
        return {
          ...state,
          artistsSpotifyOfUser: action.payload,
          listArtistSpotify: [...state.listArtistSpotify, ...action.payload],
        };
      }
    )
    .addCase(
      analyticCurrentUserActions.getTracksSpotifyOfUser.fulfilled,
      (state, action) => {
        return {
          ...state,
          tracksSpotifyOfUser: action.payload,
          listTrackSpotify: [...state.listTrackSpotify, ...action.payload],
        };
      }
    )
);
