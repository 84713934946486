import { Box, Flex } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonHotProducers() {
  return (
    <Flex gap={{ base: "30px", xl: "45px" }} mb="15px" overflow="hidden">
      {Array.from({ length: 5 }).map((item, index) => (
        <Box
          borderRadius="8px"
          background="#24252B"
          w="168px"
          h="290px"
          alignItems="center"
          padding="10px"
          key={index}
        >
          <Skeleton
            margin="auto"
            w={{ base: "138px", md: "148px" }}
            h={{ base: "138px", md: "148px" }}
            borderRadius="full"
            mb="20px"
          />
          <Skeleton w="50%" mb="15px" />
          <Skeleton w="100%" />
          <Skeleton w="100%" />
        </Box>
      ))}
    </Flex>
  );
}
