import { createAsyncThunk } from "@reduxjs/toolkit";
import SubscriptionsService from "app/apis/subscriptions";

export const TYPES = {
  FETCH_SUBSCRIPTIONS: "FETCH_SUBSCRIPTIONS",
  CANCEL_SUBSCRIPTION: "CANCEL_SUBSCRIPTION",
  CREATE_SUBSCRIPTION: "CREATE_SUBSCRIPTION",
  CHECK_CREATE_LICENSE: "CHECK_CREATE_LICENSE",
  SEND_EMAIL_REFERRAL: "SEND_EMAIL_REFERRAL",
};

export const fetchSubscriptions = createAsyncThunk(
  TYPES.FETCH_SUBSCRIPTIONS,
  SubscriptionsService.loadAllSubscriptions
);

export const cancelSubscription = createAsyncThunk(
  TYPES.CANCEL_SUBSCRIPTION,
  async () => {
    await SubscriptionsService.cancelSubscription();
  }
);

export const createSubscription = createAsyncThunk(
  TYPES.CREATE_SUBSCRIPTION,
  async (plan: string) => {
    return await SubscriptionsService.createSubscriptions(plan);
  }
);

export const checkCreateNewLicense = createAsyncThunk(
  TYPES.CHECK_CREATE_LICENSE,
  async () => {
    return await SubscriptionsService.checkCreateNewLicense();
  }
);

export const senEmailReferral = createAsyncThunk(
  TYPES.SEND_EMAIL_REFERRAL,
  async (email: string) => {
    return await SubscriptionsService.sendEmailReferral(email);
  }
);
