import React from "react";

interface IconLyricsFeedPageMobileProps {
  color?: string;
}

const IconLyricsFeedPageMobile = ({
  color = "#D4F70E",
}: IconLyricsFeedPageMobileProps) => {
  return (
    <svg
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 4.66667H13.3333V7H0.5V4.66667ZM0.5 2.33333H13.3333V0H0.5V2.33333ZM0.5 11.6667H8.66667V9.33333H0.5V11.6667ZM18.0117 8.015L18.84 7.18667C18.9479 7.07851 19.0761 6.99271 19.2173 6.93416C19.3584 6.87562 19.5097 6.84548 19.6625 6.84548C19.8153 6.84548 19.9666 6.87562 20.1077 6.93416C20.2489 6.99271 20.3771 7.07851 20.485 7.18667L21.3133 8.015C21.7683 8.47 21.7683 9.205 21.3133 9.66L20.485 10.4883L18.0117 8.015ZM17.1833 8.84333L11 15.0267V17.5H13.4733L19.6567 11.3167L17.1833 8.84333Z"
        fill={color}
      />
    </svg>
  );
};
export default IconLyricsFeedPageMobile;
