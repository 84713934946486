import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Controller } from "react-hook-form";

interface OptionProps {
  name?: string;
  value?: string;
}

interface SelectProps {
  placeholder?: string;
  control?: any;
  label?: string;
  name?: string;
  value?: string;
  errors?: any;
  disabled?: boolean;
  options?: Array<OptionProps>;
  onChangePerformance?: any;
}

export const SelectField = ({
  placeholder = "",
  control,
  label = "",
  name = "",
  value = "",
  disabled = false,
  errors = {},
  options,
  onChangePerformance,
  ...props
}: SelectProps) => {
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <FormControl id={name} isInvalid={!!errors[name]?.message}>
      <FormLabel mb="5px" color="#535353">
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...props}
        render={({ field }) => {
          const { onChange } = field;

          return (
            <>
              <Select
                id={name}
                {...field}
                placeholder={placeholder}
                backgroundColor="#25252A"
                border="none"
                size="lg"
                onChange={(e) => {
                  onChange(e);
                  onChangePerformance &&
                    onChangePerformance(e.target.name, e.target.value);
                }}
              >
                {options?.map((item: any, index: any) => {
                  return (
                    <option key={index} value={item.value}>
                      {item.name}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage color="#e41717">
                {errors[name]?.message}
              </FormErrorMessage>
            </>
          );
        }}
      />
    </FormControl>
  );
};
