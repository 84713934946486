import React from "react";

const LogoSuperproductorHeader = () => {
  return (
    <svg
      width="80"
      height="88"
      viewBox="0 0 80 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_b_14002_17134)">
        <rect
          x="32"
          y="77"
          width="25"
          height="10"
          rx="2"
          fill="white"
          fill-opacity="0.1"
        />
        <rect
          x="31.5"
          y="76.5"
          width="26"
          height="11"
          rx="2.5"
          stroke="white"
          stroke-opacity="0.2"
        />
        <path
          d="M39.7103 82.464C39.7103 83.352 39.0303 84 37.9983 84H35.7343V78.72H37.5823C38.5343 78.72 39.1663 79.312 39.1663 80.128C39.1663 80.536 39.0143 80.872 38.7263 81.12C39.3423 81.32 39.7103 81.816 39.7103 82.464ZM37.5023 79.464H36.5663V80.888H37.5023C38.0223 80.888 38.3183 80.608 38.3183 80.168C38.3183 79.728 38.0223 79.464 37.5023 79.464ZM38.8543 82.44C38.8543 81.936 38.5103 81.616 37.9263 81.616H36.5663V83.256H37.9263C38.5103 83.256 38.8543 82.944 38.8543 82.44ZM40.6796 84V78.72H44.1516V79.48H41.5276V80.832H43.5036V81.568H41.5276V83.24H44.2476V84H40.6796ZM46.4743 84V79.48H44.6983V78.72H49.1063V79.48H47.3223V84H46.4743ZM52.8408 84L52.4648 83.12H50.0888L49.7128 84H48.8088L51.1368 78.632H51.4248L53.7528 84H52.8408ZM52.1608 82.392L51.2808 80.208L50.3928 82.392H52.1608Z"
          fill="white"
          fill-opacity="0.2"
        />
      </g>
      <g filter="url(#filter1_f_14002_17134)">
        <circle cx="60.3258" cy="64.6044" r="8.39539" fill="black" />
      </g>
      <g filter="url(#filter2_d_14002_17134)">
        <path
          d="M66.9521 54.4411C66.9521 57.9567 65.5556 61.3282 63.0697 63.8141C60.5839 66.3 57.2123 67.6965 53.6967 67.6965H37.7903C34.2747 67.6965 30.9032 66.3 28.4173 63.8141C25.9315 61.3282 24.5349 57.9567 24.5349 54.4411H66.9521ZM66.9521 53.1148H37.7903C34.2747 53.1148 30.9032 51.7182 28.4173 49.2324C25.9315 46.7465 24.5349 43.375 24.5349 39.8594H53.6967C57.2123 39.8594 60.5839 41.256 63.0697 43.7418C65.5556 46.2277 66.9521 49.5992 66.9521 53.1148ZM66.9521 38.5347H24.5349C24.5349 35.0191 25.9315 31.6476 28.4173 29.1617C30.9032 26.6758 34.2747 25.2793 37.7903 25.2793H53.6967C57.2123 25.2793 60.5839 26.6758 63.0697 29.1617C65.5556 31.6476 66.9521 35.0191 66.9521 38.5347Z"
          fill="url(#paint0_radial_14002_17134)"
        />
        <path
          d="M66.8518 54.5411C66.8255 57.994 65.4425 61.2999 62.999 63.7434C60.5319 66.2105 57.1858 67.5965 53.6967 67.5965H37.7903C34.3013 67.5965 30.9551 66.2105 28.488 63.7434C26.0445 61.2999 24.6615 57.994 24.6353 54.5411H66.8518ZM66.8518 53.0148H37.7903C34.3013 53.0148 30.9551 51.6288 28.488 49.1617C26.0445 46.7181 24.6615 43.4123 24.6353 39.9594H53.6967C57.1858 39.9594 60.5319 41.3454 62.999 43.8125C65.4425 46.2561 66.8255 49.5619 66.8518 53.0148ZM24.6353 38.4347C24.6615 34.9818 26.0445 31.676 28.488 29.2324C30.9551 26.7653 34.3013 25.3793 37.7903 25.3793H53.6967C57.1858 25.3793 60.5319 26.7653 62.999 29.2324C65.4425 31.676 66.8255 34.9818 66.8518 38.4347H24.6353Z"
          stroke="url(#paint1_linear_14002_17134)"
          stroke-width="0.2"
        />
      </g>
      <g filter="url(#filter3_f_14002_17134)">
        <path
          d="M43.9769 22.6268C43.9769 26.2874 34.8234 35.8829 31.1628 35.8829C27.5023 35.8829 21 43.0785 21 39.418C21 35.7575 27.5023 22.627 31.1628 22.627C34.8234 22.627 43.9769 18.9663 43.9769 22.6268Z"
          fill="white"
          fill-opacity="0.6"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_14002_17134"
          x="7"
          y="52"
          width="75"
          height="60"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="12" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_14002_17134"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_14002_17134"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f_14002_17134"
          x="40.9304"
          y="45.209"
          width="38.7908"
          height="38.791"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="5.5"
            result="effect1_foregroundBlur_14002_17134"
          />
        </filter>
        <filter
          id="filter2_d_14002_17134"
          x="24.5349"
          y="25.2793"
          width="42.4172"
          height="43.418"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_14002_17134"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_14002_17134"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_f_14002_17134"
          x="0"
          y="0"
          width="64.9769"
          height="61.457"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.5"
            result="effect1_foregroundBlur_14002_17134"
          />
        </filter>
        <radialGradient
          id="paint0_radial_14002_17134"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.0465 4.95361) rotate(52.7447) scale(78.8301 65.2247)"
        >
          <stop stop-color="#D8FD01" />
          <stop offset="0.385417" stop-color="#F4FFB3" />
          <stop offset="0.795958" stop-color="#D8FD01" />
          <stop offset="1" stop-color="#95AE04" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_14002_17134"
          x1="27.1861"
          y1="33.6747"
          x2="56.349"
          y2="57.0934"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default LogoSuperproductorHeader;
