import { Box, Flex, useMediaQuery } from "@chakra-ui/react";
import { NewTrackState } from "app/redux/reducers/track";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Collaborators from "./Collaborators";
import Description from "./Description";
import Divider from "./Divider";
import GeneralInfo from "./GeneralInfo";
import LicenseAndAvailability from "./LicenseAndAvailability";
import LoopAndSamples from "./LoopAndSamples";
import Lyrics from "./Lyrics";
import Metadata from "./Metadata";
import SidebarMenu from "./SidebarMenu";
import "./styles.scss";
import SubmitButtonGroup from "./SubmitButtonGroup";
import { ACTIONSADDTRACK } from "utils/actionText";
import SuccessModalAddTrackSoundPack from "../SuccessModal/successModalAddTrackSoundPack";
import collaborationInvite from "../../assets/LottieIAnimations/collaborationsInvite.json";
import ModalTransactionProcess from "../SuccessModal/modalTransactionProcess";
import { useMyTrack } from "hooks/track/useMyTrack";
interface AddTrackProps {
  isSoundKit?: boolean;
  isLoading: boolean;
  showSuccessModal: boolean;
  onPublishTrack: (values: NewTrackState) => void;
  onSubmitDraft: (value?: string | NewTrackState) => void;
  toggleShowSuccessModal: () => void;
  isEdit?: boolean;
  showModalCollaborationInvite: boolean;
  setShowModalCollaborationInvite: () => void;
  addTrackDraft: boolean;
  setAddTrackDraft: (value: boolean) => void;
  releaseTrackDraft: boolean;
  addTrackPrivate: boolean;
  addTrackScheduled: boolean;
}

export default function AddTrack({
  isSoundKit,
  showSuccessModal,
  isLoading,
  onPublishTrack,
  onSubmitDraft,
  toggleShowSuccessModal,
  isEdit = false,
  showModalCollaborationInvite,
  setShowModalCollaborationInvite,
  addTrackDraft,
  setAddTrackDraft,
  releaseTrackDraft,
  addTrackPrivate = false,
  addTrackScheduled = false,
}: AddTrackProps) {
  const { handleSubmit, watch } = useFormContext();
  const history = useHistory();
  const { filesAutoTagged } = useMyTrack();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const { isDraft, _id } = watch();

  const onBackToFeed = useCallback(() => {
    toggleShowSuccessModal();
    setShowModalCollaborationInvite();
    if (filesAutoTagged?.length > 0) {
      history.push("/upload-track-files");
      return;
    }
    history.push("/dashboard/my-track");
  }, [
    filesAutoTagged?.length,
    history,
    setShowModalCollaborationInvite,
    toggleShowSuccessModal,
  ]);

  const handleDraft = useCallback(() => {
    if (!!_id) {
      onSubmitDraft("edit");
      setAddTrackDraft(true);
    } else {
      onSubmitDraft();
      setAddTrackDraft(true);
    }
  }, [_id, onSubmitDraft, setAddTrackDraft]);

  const checkButtonSaveDraft = !isDraft && isEdit;

  const titleModal = useMemo(() => {
    let titleDes = { title: ACTIONSADDTRACK.RELEASE_TRACK };
    switch (true) {
      case addTrackDraft:
        titleDes.title = ACTIONSADDTRACK.TRACK_DRAFT;
        break;
      case addTrackPrivate:
        titleDes.title = ACTIONSADDTRACK.PRIVATE_TRACK;
        break;
      case addTrackScheduled:
        titleDes.title = ACTIONSADDTRACK.SCHEDULED_TRACK;
        break;
      case isEdit:
        titleDes.title = ACTIONSADDTRACK.EDIT_TRACK;
        break;
      case releaseTrackDraft:
        titleDes.title = ACTIONSADDTRACK.RELEASE_TRACK_DRAFT;
        break;
      default:
        break;
    }
    return titleDes;
  }, [
    addTrackDraft,
    addTrackPrivate,
    isEdit,
    releaseTrackDraft,
    addTrackScheduled,
  ]);

  return (
    <>
      <form onSubmit={handleSubmit(isDraft ? onSubmitDraft : onPublishTrack)}>
        <Box className="add-track-item-components">
          {isLessThan766 && (
            <Box display={{ base: "block", md: "none" }}>
              <SidebarMenu
                checkButtonSaveDraft={!checkButtonSaveDraft}
                onSubmitDraft={handleDraft}
                isLoading={isLoading}
                isEdit={isEdit}
                isDraft={isDraft}
              />
            </Box>
          )}
          <GeneralInfo isSoundKit={isSoundKit} />
          <Divider marginTop="10px" />
          {!isSoundKit && <LicenseAndAvailability />}
          <Divider />
          <Description />
          <Lyrics />
          <Divider />
          <Metadata />
          <Divider />

          <LoopAndSamples />

          <Divider />
          <Collaborators />

          <Flex mt="50px">
            <SubmitButtonGroup
              isLoading={isLoading}
              onSubmitDraft={handleDraft}
              checkButtonSaveDraft={!checkButtonSaveDraft}
              isEdit={isEdit}
              width={{ base: "100%", md: "50%" }}
              isDraft={isDraft}
            />
          </Flex>
          <Box display={{ base: "none", md: "block" }}>
            <SidebarMenu
              checkButtonSaveDraft={!checkButtonSaveDraft}
              onSubmitDraft={handleDraft}
              isLoading={isLoading}
              isEdit={isEdit}
              isDraft={isDraft}
            />
          </Box>
        </Box>
      </form>

      {showSuccessModal && (
        <SuccessModalAddTrackSoundPack
          isOpen={showSuccessModal}
          onClose={toggleShowSuccessModal}
          onButtonClick={onBackToFeed}
          buttonLabel={
            filesAutoTagged?.length > 0
              ? "continueUploadMoreTracks"
              : "backToMyTrack"
          }
          title={titleModal.title}
        />
      )}
      {showModalCollaborationInvite && (
        <ModalTransactionProcess
          isOpen={showModalCollaborationInvite}
          onClose={setShowModalCollaborationInvite}
          iconJson={collaborationInvite}
          buttonLabel={"backToMyTrack"}
          onButtonClick={onBackToFeed}
        />
      )}
    </>
  );
}
