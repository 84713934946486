import React from "react";

const IconSortByAlgolia = () => {
  return (
    <svg
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7833 1.83366H1.21663C0.99562 1.83366 0.783659 1.74586 0.627379 1.58958C0.471098 1.4333 0.383301 1.22134 0.383301 1.00033C0.383301 0.779312 0.471098 0.56735 0.627379 0.41107C0.783659 0.25479 0.99562 0.166992 1.21663 0.166992H18.7833C19.0043 0.166992 19.2163 0.25479 19.3726 0.41107C19.5288 0.56735 19.6166 0.779312 19.6166 1.00033C19.6166 1.22134 19.5288 1.4333 19.3726 1.58958C19.2163 1.74586 19.0043 1.83366 18.7833 1.83366Z"
        fill="#535353"
      />
      <path
        d="M12.6416 6.83366H1.21663C0.99562 6.83366 0.783659 6.74586 0.627379 6.58958C0.471098 6.4333 0.383301 6.22134 0.383301 6.00033C0.383301 5.77931 0.471098 5.56735 0.627379 5.41107C0.783659 5.25479 0.99562 5.16699 1.21663 5.16699H12.6416C12.8626 5.16699 13.0746 5.25479 13.2309 5.41107C13.3872 5.56735 13.475 5.77931 13.475 6.00033C13.475 6.22134 13.3872 6.4333 13.2309 6.58958C13.0746 6.74586 12.8626 6.83366 12.6416 6.83366Z"
        fill="#535353"
      />
      <path
        d="M6.44997 11.8337H1.21663C0.99562 11.8337 0.783659 11.7459 0.627379 11.5896C0.471098 11.4333 0.383301 11.2213 0.383301 11.0003C0.383301 10.7793 0.471098 10.5673 0.627379 10.4111C0.783659 10.2548 0.99562 10.167 1.21663 10.167H6.44997C6.67098 10.167 6.88294 10.2548 7.03922 10.4111C7.1955 10.5673 7.2833 10.7793 7.2833 11.0003C7.2833 11.2213 7.1955 11.4333 7.03922 11.5896C6.88294 11.7459 6.67098 11.8337 6.44997 11.8337Z"
        fill="#535353"
      />
    </svg>
  );
};
export default IconSortByAlgolia;
