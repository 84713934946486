import { createReducer } from "@reduxjs/toolkit";
import * as actions from "app/redux/actions/tracks";

export interface InitFileProgressState {
  filesAutoTaggedProgress: [];
  filesCustomTaggedProgress: [];
  filesTrackoutStemsProgress: [];
}

const initialState: InitFileProgressState = {
  filesAutoTaggedProgress: [],
  filesCustomTaggedProgress: [],
  filesTrackoutStemsProgress: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(actions.setFileAutoTaggedClosePage, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesAutoTaggedProgress: updateProgress,
      };
    })
    .addCase(actions.setFileCustomTaggedClosePage, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesCustomTaggedProgress: updateProgress,
      };
    })
    .addCase(actions.setFileTrackoutStemsClosePage, (state, action) => {
      const updateProgress = action.payload?.map((item) => {
        return {
          ...item,
          progress: 100,
        };
      });
      return {
        ...state,
        filesTrackoutStemsProgress: updateProgress,
      };
    })
);
