import {
  Box,
  Flex,
  Image,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import AvatarUser from "app/assets/images/UserAvatar.png";
import IconArrowDownNegotiation from "app/assets/icons/IconArrowDownNegotiation";
import ModalNegotiations from "./ModalNegotiations/modalNegotiations";
import { userRole } from "constants/negotiationType";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import { Negotiations } from "app/models";

interface ItemNegotiationsProps {
  isActive?: boolean;
  negotiation?: Negotiations;
}

export default function ItemNegotiationsDashboard({
  negotiation,
  isActive = false,
}: ItemNegotiationsProps) {
  const {
    renderStatus,
    isExpiredDate,
    statusColor,
    showNegotiationDetail,
    setShowNegotiationDetail,
    negotiationTrack,
    setNegotiationTrack,
    setShowContent,
    showContent,
  } = useNegotiations();

  const renderRole = useCallback(
    () =>
      (negotiation?.createdBy?.role || []).map(
        (roleString: string) => userRole[roleString]
      ),
    [negotiation?.createdBy?.role]
  );

  const currentStatus = useMemo(() => {
    return statusColor(
      negotiation?.status,
      isExpiredDate(negotiation?.expiredDate)
    );
  }, [
    isExpiredDate,
    statusColor,
    negotiation?.status,
    negotiation?.expiredDate,
  ]);

  const handleShowSelectNegotiation = useCallback(() => {
    setShowNegotiationDetail();
    setNegotiationTrack(negotiation);
    setShowContent({
      showContentNegotiationDeal: true,
      showNegotiationOffer: false,
      showSubmitNegotiation: false,
    });
  }, [
    negotiation,
    setNegotiationTrack,
    setShowNegotiationDetail,
    setShowContent,
  ]);

  return (
    <Box>
      <Flex
        background="#24252A"
        borderRadius="8px"
        padding={{
          base: "8px 16px",
          md: "8px 20px 8px 20px",
          xl: "8px 30px 8px 40px",
        }}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        mb="20px"
        cursor="pointer"
        onClick={handleShowSelectNegotiation}
      >
        <Flex>
          <Image
            w="102px"
            height="102px"
            borderRadius="full"
            alt="User"
            src={negotiation?.createdBy?.profileImage || AvatarUser}
            fallbackSrc={AvatarUser}
          />
          <Box
            ml="15px"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box>
              <Text fontSize="14px" lineHeight="17px" fontWeight="600">
                {negotiation?.createdBy?.name || "no title"}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="rgba(255, 255, 255, 0.51)"
                mt="5px"
              >
                {renderRole()}
              </Text>
            </Box>

            <Flex display="flex" flexWrap="wrap" alignItems="center">
              <Box
                minW="80px"
                height="24px"
                background={currentStatus.backgroundColor}
                borderRadius="24px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                padding="0px 20px"
                whiteSpace="nowrap"
              >
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="600"
                  color={currentStatus.color}
                >
                  {renderStatus(negotiation?.status, negotiation?.expiredDate)}
                </Text>
              </Box>
              <Text
                fontSize="12px"
                lineHeight="18px"
                color="rgba(255, 255, 255, 0.32)"
                fontWeight="400"
                ml="10px"
              >
                {negotiation?.tracks?.length} Item
              </Text>
              <UnorderedList>
                <ListItem
                  fontSize="12px"
                  color="rgba(255, 255, 255, 0.32)"
                  fontWeight="400"
                  ml="10px"
                >
                  <span style={{ marginLeft: "-8px" }}>
                    ${negotiation?.offerPrice}
                  </span>
                </ListItem>
              </UnorderedList>
            </Flex>
          </Box>
        </Flex>

        <IconArrowDownNegotiation />
      </Flex>
      <ModalNegotiations
        showModal={showNegotiationDetail}
        setShowModal={setShowNegotiationDetail}
        negotiationsItem={negotiationTrack}
        showContent={showContent}
        setShowContent={setShowContent}
      />
    </Box>
  );
}
