import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Flex,
} from "@chakra-ui/react";
import { usePaymentWallets } from "hooks/usePaymentWallets";
import queryString from "query-string";
import React, { useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import SuccessIcon from "app/assets/icons/Success";
import { useTranslation } from "react-i18next";

export default function ConnectPayment() {
  const location: any = useLocation();
  const token = location.search;
  const { t } = useTranslation();
  const history = useHistory();
  const { handleUpdatePaypalMerchantID, handleCreateStripe } =
    usePaymentWallets();

  useEffect(() => {
    if (token.includes("?paypal=true")) {
      let params = queryString.parse(token.replace("?paypal=true", ""));
      handleUpdatePaypalMerchantID(params);
    }
  }, [handleUpdatePaypalMerchantID, token]);

  useEffect(() => {
    if (token.includes("?stripe=true")) {
      handleCreateStripe();
    }
  }, [handleCreateStripe, token]);

  const handleHistory = useCallback(() => {
    history.push("/dashboard/payment");
  }, [history]);

  return (
    <Modal colorScheme="black" isOpen={true} onClose={handleHistory}>
      <ModalOverlay />
      <ModalContent p="40px">
        <ModalCloseButton color="#D8FD02" />
        <ModalBody p="0px" mt="20px">
          <Flex alignItems="center" flexDirection="column">
            <Flex
              alignItems="center"
              justifyContent="center"
              w="125px"
              height="122px"
              backgroundColor="#D8FD01"
              borderRadius="23px"
              mb="30px"
              boxShadow="-31px -12px 40px 22px rgba(216,253,1,0.1)"
            >
              <SuccessIcon />
            </Flex>
            <Text fontSize="32px" lineHeight="48px" pb="8px" color="#D8FD02">
              {t("registerSuccessfully")}
            </Text>
          </Flex>
        </ModalBody>

        <ModalFooter justifyContent="center" p="0px" mt="30px">
          <Button
            backgroundColor="rgba(255, 255, 255, 0.08)"
            color="white"
            fontSize="14px"
            borderRadius="4px"
            p="15px 30px"
            _hover={{ boxShadow: "none" }}
            onClick={handleHistory}
          >
            {t("backToPayment")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
