export const LOOP_TYPE = {
  NO_LOOP: "NO_LOOP",
  LOOP_ONE: "LOOP_ONE",
  LOOP: "LOOP",
};

export const SHUFFLE_TYPE = {
  NO_SHUFFLE: "NO_SHUFFLE",
  SHUFFLE: "SHUFFLE",
};

export const MUTE_TYPE = {
  NO_MUTE: "NO_MUTE",
  MUTE: "MUTE",
};

export const TYPE_UPLOAD = {
  ZIP: ".zip,.rar,.7zip",
  AUDIO: "audio/*",
};
