export interface ParseAudioType {
  bitrate: number;
  duration: number;
}
export const parseAudioFile = (file: File): Promise<ParseAudioType> => {
  return new Promise((resolve, reject) => {
    try {
      if (file && file.type.includes("audio")) {
        const audioContext = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
        const reader: FileReader = new FileReader();
        reader.onload = function () {
          const arrayBuffer = reader.result as any;
          audioContext.decodeAudioData(arrayBuffer).then(function (buffer) {
            const duration = buffer.duration || 1;
            const bitrate = Math.floor((file.size * 0.008) / duration);
            resolve({ bitrate, duration });
          });
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (error) {
      reject(error);
    }
  });
};
