import { Box, Text } from "@chakra-ui/react";
import BasketEmpty from "app/assets/icons/basketEmpty";
import React from "react";
import { useTranslation } from "react-i18next";

export default function CartEmpty() {
  const { t } = useTranslation();

  return (
    <Box
      height="50vh"
      display="flex"
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <BasketEmpty />
      <Text fontSize="14px" color="#535353" marginTop="40px" textAlign="center">
        {t("basketIsEmpty")}
      </Text>
    </Box>
  );
}
