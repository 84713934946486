import { Box, Image, Text, Flex } from "@chakra-ui/react";
import ImageHotProducers from "app/assets/images/ImageDefaultLoading.png";
import { User } from "app/models";
import React from "react";
import RedirectPage from "./redirectPage";

interface HotProducersProps {
  hotproducers: User;
}

export default function HotProducers({ hotproducers }: HotProducersProps) {
  const { redirectPage, pageProfile } = RedirectPage();

  return (
    <Box>
      <Box
        padding="10px"
        height={{ base: "275px", md: "290px" }}
        borderRadius="8px"
        m="10px"
        background={"#24252A"}
        _hover={{
          background: "#3D4419",
          border: "1px solid #D4F70E",
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          <Image
            cursor="pointer"
            height={{ base: "138px", md: "148px" }}
            w={{ base: "138px", md: "148px" }}
            src={hotproducers.profileImage}
            fallbackSrc={ImageHotProducers}
            borderRadius="full"
            onClick={() => redirectPage(pageProfile(hotproducers?.name))}
          />
        </Flex>

        <Text
          onClick={() => redirectPage(pageProfile(hotproducers?.name))}
          mt="15px"
          fontSize="18px"
          lineHeight="21px"
          fontWeight="500"
          fontFamily="Cera Pro Regular"
          _hover={{
            textDecor: "underline",
          }}
          cursor="pointer"
        >
          {hotproducers.name}
        </Text>
        {!!hotproducers?.newRelease?.title && (
          <Text
            fontSize="16px"
            lineHeight="22px"
            fontWeight="400"
            color="rgba(255, 255, 255, 0.32)"
            mt="5px"
            noOfLines={2}
            fontFamily="Cera Pro Regular"
            _hover={{
              textDecor: "underline",
            }}
            cursor="pointer"
            onClick={() =>
              redirectPage(`track/${hotproducers?.newRelease?.slug}`)
            }
          >
            New release: {hotproducers?.newRelease?.title}
          </Text>
        )}
      </Box>
    </Box>
  );
}
