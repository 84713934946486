import { Box } from "@chakra-ui/react";
import EmptyPlaylist from "app/pages/Playlists/emptyPlaylist";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import React, { useEffect } from "react";
import PlaylistItemInProfile from "../MyFeed/playlistItemProfile";
import SkeletonPlaylistItemProfile from "../MyFeed/skeletonPlaylistItemProfile";

interface MyPlaylistsProfileProps {
  userId: string;
}

export default function MyPlaylistsProfile({
  userId,
}: MyPlaylistsProfileProps) {
  const {
    playlistsInProfile,
    isLoadingGetPlaylistsInProfile,
    handleGetPlaylistsInProfile,
  } = usePlaylists();

  useEffect(() => {
    handleGetPlaylistsInProfile(userId);
  }, [handleGetPlaylistsInProfile, userId]);

  return (
    <Box>
      {isLoadingGetPlaylistsInProfile ? (
        <SkeletonPlaylistItemProfile />
      ) : playlistsInProfile?.length > 0 ? (
        (playlistsInProfile || []).map((item, index) => (
          <PlaylistItemInProfile
            playlist={item}
            index={index}
            key={item._id}
            isProfile
          />
        ))
      ) : (
        <EmptyPlaylist />
      )}
    </Box>
  );
}
