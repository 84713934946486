import {
  Box,
  Divider,
  Flex,
  Image,
  Text,
  useMediaQuery,
  UnorderedList,
  ListItem,
  AbsoluteCenter,
} from "@chakra-ui/react";
import IconLikeIndividualTrack from "app/assets/icons/iconLikeIndividual";
import IconLyricsIndividualTrack from "app/assets/icons/iconLyricsIndividual";
import IconRepostIndividualTrack from "app/assets/icons/iconRepostIndividual";
import AvatarUser from "app/assets/images/ImageDefaultLoading.png";
import AvatarCoverUrl from "app/assets/images/AvatarMusicLoading.svg";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { CollaborationsModel, TrackSoundKit } from "app/models";
import {
  likeTrackDetail,
  repostTrackDetail,
  unLikeTrackDetail,
} from "app/redux/actions/myTrack";
import { getLyrics } from "app/redux/actions/tracks";
import {
  isFavoriteSelector,
  isRepostsSelector,
  selectorTrackDetail,
} from "app/redux/selectors/myTrack";
import { roleText } from "constants/collaboratorType";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import LinkToTrackOrProfile from "./linkToTrackProfile";
import { selectLoggedIn } from "app/redux/selectors/users";
import { CgAddR } from "react-icons/cg";
import IconLikeIndividualTrackMobile from "app/assets/icons/iconLikeIndividualMobile";
import IconRepostIndividualTrackMobile from "app/assets/icons/iconRepostIndividualMobile";
import IconLyricsIndividualTrackMobile from "app/assets/icons/iconLyricsIndividualTrackMobile";
import { TRACK_TYPE } from "constants/trackTypes";
import AudioVisualizer from "app/components/AudioVisualizer";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import TooltipLabel from "app/components/TooltipLabel";
import { usePlayers } from "hooks/player/usePlayers";
import PauseAudioIcon from "app/assets/icons/PauseAudio";

interface LeftIndividualTrackProps {
  onOpenOptionsModal: (track: TrackSoundKit, isLyric?: boolean) => void;
  handleShowModalAddTrack: (id: string) => void;
}

export default function LeftIndividualTrack({
  onOpenOptionsModal,
  handleShowModalAddTrack,
}: LeftIndividualTrackProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const trackDetail: TrackSoundKit = useSelector(selectorTrackDetail);
  const loggedIn = useSelector(selectLoggedIn);
  const [delayTimeCallApi, setDelayTimeCallApi] = useState(0);
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const {
    isPlaying,
    handleTogglePlayPause,
    handlePlayOrPause,
    playingTrack,
    isLoading,
    handlePlayPlaylists,
  } = usePlayers();

  const isRepost = useSelector(isRepostsSelector(trackDetail));
  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 2000);
  }, []);

  const isFavorite = useSelector(isFavoriteSelector(trackDetail));
  const handleRepostTrackDetail = useCallback(() => {
    if (isRepost) {
      ToastPlanSubscription({ description: t("alreadyRepost") });
    } else {
      dispatch(repostTrackDetail(trackDetail?._id || ""));
    }
  }, [dispatch, isRepost, t, trackDetail?._id]);

  const onClickToTrack = useCallback(
    async (track) => {
      if (!track?.mergeAudio && !track?.audioFileUrl) {
        return;
      }
      handlePlayOrPause(track);
      handlePlayPlaylists([]);
    },
    [handlePlayOrPause, handlePlayPlaylists]
  );

  const handleLikeUnlikeTrackDetail = useCallback(async () => {
    increaseTime();
    if (isFavorite) {
      dispatch(
        unLikeTrackDetail({
          id: trackDetail?._id || "",
          timeCallApi: delayTimeCallApi,
        })
      );
      return;
    }
    dispatch(
      likeTrackDetail({ id: trackDetail?._id, timeCallAPi: delayTimeCallApi })
    );
  }, [delayTimeCallApi, dispatch, increaseTime, isFavorite, trackDetail?._id]);

  const isLyrics = useMemo(() => {
    return !!trackDetail.myLyric;
  }, [trackDetail.myLyric]);

  const handleGetLyrics = useCallback(() => {
    onOpenOptionsModal(trackDetail, true);
    dispatch(getLyrics({ trackId: trackDetail?._id }));
  }, [dispatch, onOpenOptionsModal, trackDetail]);

  const iconTrackDetail = useMemo(
    () => [
      {
        icon: isLessThan768 ? (
          <IconLikeIndividualTrackMobile isFavorite={isFavorite} />
        ) : (
          <IconLikeIndividualTrack isFavorite={isFavorite} />
        ),
        total: trackDetail?.totalFavs || 0,
        id: 0,
        onClick: () => handleLikeUnlikeTrackDetail(),
      },
      {
        icon: isLessThan768 ? (
          <IconRepostIndividualTrackMobile isRepost={isRepost} />
        ) : (
          <IconRepostIndividualTrack isRepost={isRepost} />
        ),
        total: trackDetail?.totalRepost || 0,
        id: 1,
        onClick: () => handleRepostTrackDetail(),
        marginTop: "-5px",
      },
      {
        icon: isLessThan768 ? (
          <IconLyricsIndividualTrackMobile isLyric={isLyrics} />
        ) : (
          <IconLyricsIndividualTrack isLyric={isLyrics} />
        ),
        total: "",
        id: 2,
        onClick: () => handleGetLyrics(),
      },
      {
        icon: <CgAddR size={isLessThan768 ? 24 : 36} />,
        total: "",
        id: 3,
        onClick: () => handleShowModalAddTrack(trackDetail?._id),
      },
    ],
    [
      isLessThan768,
      handleShowModalAddTrack,
      handleGetLyrics,
      handleLikeUnlikeTrackDetail,
      handleRepostTrackDetail,
      isFavorite,
      isLyrics,
      isRepost,
      trackDetail,
    ]
  );

  const metaData = useMemo(
    () => [
      {
        name: "Key",
        label: TRACK_KEY_NOTATION[trackDetail?.key],
        id: 0,
      },
      {
        name: "Genre",
        label: trackDetail?.subGenre,
        id: 1,
      },
      {
        name: "BPM",
        label: trackDetail?.bpm,
        id: 2,
      },
    ],
    [trackDetail?.bpm, trackDetail?.key, trackDetail?.subGenre]
  );
  const checkPlaying = useMemo(() => {
    return trackDetail?._id === playingTrack?._id;
  }, [playingTrack?._id, trackDetail?._id]);

  const collaborationRole = useCallback((role) => {
    return roleText(role);
  }, []);

  const redirectTagPage = useCallback(
    (item) => {
      history.push(`/search?type=tags&q=${item}`);
    },
    [history]
  );

  return (
    <Box w={{ base: "100%", md: "35%", xl: "30%" }} className="c_tleft_tag">
      <Box
        background="rgba(36, 37, 42, 0.3)"
        padding={{ base: "25px 10px", xl: "25px 35px" }}
        borderRadius="8px"
        mb="16px"
      >
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ base: "row", md: "column" }}
        >
          <Box
            position="relative"
            w={{ base: "100px", md: "132px", lg: "210px" }}
            h={{ base: "100px", md: "132px", lg: "210px" }}
          >
            <Image
              src={trackDetail?.coverUrl}
              fallbackSrc={AvatarCoverUrl}
              borderRadius="8px"
              objectFit="cover"
              w={{ base: "100px", md: "132px", lg: "210px" }}
              height={{ base: "100px", md: "132px", lg: "210px" }}
              opacity={{ base: 0.5, md: 1 }}
            />
            <AbsoluteCenter display={{ base: "block", md: "none" }}>
              {checkPlaying ? (
                <>
                  <PlayButton
                    handleTogglePlayPause={handleTogglePlayPause}
                    isPlaying={isPlaying}
                    size={40}
                    isLoading={isLoading}
                  />
                </>
              ) : (
                <>
                  <Flex
                    width="40px"
                    height="40px"
                    border="1px solid #D1F310"
                    borderRadius="25px"
                    alignItems="center"
                    justifyContent="center"
                    cursor="pointer"
                    onClick={() => onClickToTrack(trackDetail)}
                  >
                    <TooltipLabel label={t("Play")}>
                      <Flex
                        background="rgba(255, 255, 255, 0.06)"
                        width="28px"
                        height="28px"
                        borderRadius="25px"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <PauseAudioIcon />
                      </Flex>
                    </TooltipLabel>
                  </Flex>
                </>
              )}
            </AbsoluteCenter>
          </Box>
          <Text
            cursor="pointer"
            fontSize="24px"
            lineHeight="38px"
            fontWeight="700"
            textAlign={{ base: "unset", md: "center" }}
            m={{ base: "0px", md: "20px 0px 0px 0px" }}
            noOfLines={2}
            _hover={{
              textDecoration: "underline",
            }}
            fontFamily="Cera Pro Regular"
            display={{ base: "none", md: "-webkit-box" }}
          >
            {trackDetail?.title}
          </Text>
          <Text
            display={{ base: "none", md: "block" }}
            cursor="pointer"
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight="400"
            textAlign={{ base: "unset", md: "center" }}
            color="rgba(255, 255, 255, 0.51)"
            fontFamily="Cera Pro Regular"
          >
            <LinkToTrackOrProfile
              name={trackDetail?.createdBy?.name || ""}
              type="user"
            />
          </Text>
          <Box
            background={`url(${trackDetail?.coverUrl})`}
            className="c_rt_ptlt_box"
            overflow="scroll"
            w={{ base: "75%", md: "100%" }}
            ml="7px"
            mr="-2px"
            display={{ base: "block", md: "none" }}
          >
            <Box
              padding={"20px 9px"}
              borderRadius="8px"
              position="relative"
              className="c_rt_tlt_box"
            >
              <Text
                cursor="pointer"
                fontSize="24px"
                lineHeight="38px"
                fontWeight="700"
                textAlign={{ base: "unset", md: "center" }}
                m={{ base: "0px", md: "20px 0px 0px 0px" }}
                noOfLines={1}
                _hover={{
                  textDecoration: "underline",
                }}
                fontFamily="Cera Pro Regular"
              >
                {trackDetail?.title}
              </Text>
              <Flex>
                <Text
                  fontSize={{ base: "14px", md: "16px" }}
                  lineHeight="19px"
                  color="#d4f70e"
                  padding="0px 5px"
                  borderRadius="4px"
                  fontFamily="Cera Pro Regular"
                  mr="10px"
                  backgroundColor="#2b2e1b"
                >
                  {t(TRACK_TYPE[trackDetail?.type])}
                </Text>

                <Text
                  cursor="pointer"
                  fontSize={{ base: "14px", md: "16px" }}
                  fontWeight="400"
                  textAlign={{ base: "unset", md: "center" }}
                  color="rgba(255, 255, 255, 0.51)"
                  fontFamily="Cera Pro Regular"
                >
                  <LinkToTrackOrProfile
                    name={trackDetail?.createdBy?.name || ""}
                    type="user"
                  />
                </Text>
              </Flex>
            </Box>
          </Box>
        </Box>
        <Flex
          className="c_rt_ptlt_box"
          background={`url(${trackDetail?.coverUrl})`}
          mt="4px"
          alignItems="center"
          justifyContent="center"
          display={{ base: "flex", md: "none" }}
        >
          <Flex
            justifyContent="center"
            w="100%"
            className="c_rt_tlt_box"
            padding="2px"
            borderRadius="4px"
          >
            {trackDetail?.bpm && (
              <Text fontSize="12px" mr="5px">
                Bpm <span>{trackDetail?.bpm}</span>
              </Text>
            )}

            <UnorderedList display="flex" gap="25px">
              {trackDetail?.key && (
                <ListItem fontSize="12px">KEY: {trackDetail?.key}</ListItem>
              )}
              {trackDetail?.mainGenre && (
                <ListItem fontSize="12px">{trackDetail?.mainGenre}</ListItem>
              )}
              {trackDetail?.subGenre && (
                <ListItem fontSize="12px">{trackDetail?.subGenre}</ListItem>
              )}
            </UnorderedList>
          </Flex>
        </Flex>

        {isLessThan766 && (
          <Box
            background={`url(${trackDetail?.coverUrl})`}
            className="c_rt_ptlt_box"
            display={{ base: "block", md: "none" }}
            mt="4px"
            mr="-2px"
          >
            <Box
              padding={"15px 10px"}
              borderRadius="8px"
              position="relative"
              mb="10px"
              className="c_rt_tlt_box"
              minH="70px"
            >
              {checkPlaying && <AudioVisualizer checkPlaying={checkPlaying} />}
            </Box>
          </Box>
        )}

        <Flex
          justifyContent={{ base: "space-around", md: "space-between" }}
          mt="30px"
        >
          {iconTrackDetail.map((item) => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              key={item.id}
              onClick={loggedIn ? item.onClick : () => null}
              cursor="pointer"
              justifyContent="space-between"
            >
              <Box
                _hover={{
                  border: "1px solid #38383D",
                  background: "#222227 !important",
                  borderRadius: "6px",
                  padding: "10px",
                }}
                padding="10px"
                borderWidth="1px"
                borderColor="rgba(36, 37, 42, 0.3)"
                mt={item?.marginTop}
              >
                {item.icon}
              </Box>
              <Text
                fontSize="20px"
                lineHeight="24px"
                fontWeight="400"
                color="#535353"
              >
                {item.total}
              </Text>
            </Box>
          ))}
        </Flex>
        <Divider
          m="20px 0px 15px 0px"
          borderColor="rgba(255, 255, 255, 0.04)"
        />
        <Text fontSize="16px" lineHeight="19px" fontWeight="600">
          {t("fileDescription")}
        </Text>
        <Text
          color="rgba(255, 255, 255, 0.5)"
          fontSize="16px"
          lineHeight="19px"
          fontWeight="600"
          m="15px 0px"
          fontFamily="Cera Pro Regular"
        >
          {trackDetail?.description || "no description"}
        </Text>
        <Text mb="15px" fontSize="16px" lineHeight="19px" fontWeight="600">
          {t("meataData")}
        </Text>
        {metaData.map((item) => (
          <Flex mb="15px" justifyContent="space-between" key={item.id}>
            <Text
              color="rgba(255, 255, 255, 0.5)"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="600"
            >
              {item.name}
            </Text>
            <Text
              color="rgba(255, 255, 255, 0.5)"
              fontSize="16px"
              lineHeight="19px"
              fontWeight="600"
            >
              {item.label}
            </Text>
          </Flex>
        ))}
        <Flex flexWrap="wrap">
          {(trackDetail?.tags || []).map((item) => (
            <Box
              cursor="pointer"
              background="#2E2E32"
              border="1px solid #464649"
              padding="0px 7px"
              mr="5px"
              borderRadius="4px"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              mb="5px"
              onClick={() => redirectTagPage(item)}
              _hover={{
                background: "#454C22",
                color: "#d4f70e",
                border: "1px solid #d4f70e",
              }}
            >
              <Text
                fontSize="16px"
                fontWeight="500"
                fontFamily="Cera Pro Regular"
              >
                {item}
              </Text>
            </Box>
          ))}
        </Flex>
      </Box>
      {trackDetail?.collaborators?.length > 1 && (
        <Box
          background="rgba(36, 37, 42, 0.3)"
          padding={{ base: "25px 10px", xl: "25px 35px" }}
          borderRadius="8px"
        >
          <Text fontSize="16px" lineHeight="19px" fontWeight="600" mb="16px">
            {t("collaborators")}
          </Text>
          {(trackDetail?.collaborators || []).map(
            (item: CollaborationsModel) => (
              <Link to={`/user/${item.user.name}`}>
                <Flex alignItems="center" mb="20px">
                  <Image
                    w={"48px"}
                    h={"48px"}
                    src={item?.user?.profileImage}
                    fallbackSrc={AvatarUser}
                    borderRadius="full"
                    cursor="pointer"
                  />

                  <Box ml="16px">
                    <Text
                      cursor="pointer"
                      _hover={{
                        textDecor: "underline",
                      }}
                      fontSize="16px"
                      lineHeight="16px"
                      fontWeight="600"
                    >
                      {item?.user?.name}
                    </Text>
                    <Text
                      fontSize="13px"
                      lineHeight="24px"
                      fontWeight="400"
                      opacity={0.5}
                    >
                      {collaborationRole(item.role)}
                    </Text>
                  </Box>
                </Flex>
              </Link>
            )
          )}
        </Box>
      )}
    </Box>
  );
}
