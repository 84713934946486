import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import { SortDownButton } from "app/assets/icons";
import IconEmptyAnalytics from "app/assets/icons/EmptyAnalytics";
import AvatarImageTrack from "app/assets/images/AvatarMusicLoading.svg";
import { Collaborator, PlayAnalyticsModel, TrackSoundKit } from "app/models";
import LinkToTrackOrProfile from "app/pages/IndividualTrackPage/linkToTrackProfile";
import { getListAnalyticsTracks } from "app/redux/actions/Analytics";
import { selectorsAnalyticsTracks } from "app/redux/selectors/analytics";
import { selectUserAdmin } from "app/redux/selectors/users";
import { analyticsTypeDispatch } from "constants/analyticsType";
import { TRACK_TYPE } from "constants/trackTypes";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { checkTypeTrackOrSoundPack } from "utils/convertTracks";
import EmptyAnalytic from "../EmptyAnalytics";
import PopularLicense from "./popularLicense";

interface trackItemProps {
  analyticsType: PlayAnalyticsModel;
  timeDay: string;
}

export default function TrackItem({ analyticsType, timeDay }: trackItemProps) {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const widthPosition =
    useBreakpointValue({ base: "120px", md: "15%" }) || "15%";
  const widthTrackName =
    useBreakpointValue({ base: "20%", md: "25%" }) || "25%";

  const headerTrackItem = useMemo(
    () => [
      { name: t("position"), width: widthPosition, id: 1 },
      { name: t("trackName"), width: widthTrackName, id: 2 },
      { name: t("itemSold"), width: "10%", icon: <SortDownButton />, id: 3 },
      { name: t("gross"), width: "10%", icon: <SortDownButton />, id: 4 },
      { name: t("discount"), width: "10%", icon: <SortDownButton />, id: 5 },
      { name: t("collab"), width: "10%", icon: <SortDownButton />, id: 6 },
      { name: t("super"), width: "10%", icon: <SortDownButton />, id: 7 },
      { name: t("earnings"), width: "10%", icon: <SortDownButton />, id: 8 },
    ],
    [t, widthPosition, widthTrackName]
  );

  const analyticsTypeQuery = analyticsTypeDispatch(analyticsType.type);
  const analyticsTracks = useSelector(selectorsAnalyticsTracks);

  const currentUser = useSelector(selectUserAdmin);

  useEffect(() => {
    dispatch(
      getListAnalyticsTracks({
        timeType: timeDay,
        analyticType: analyticsTypeQuery,
      })
    );
  }, [analyticsTypeQuery, dispatch, timeDay]);

  const percentCollab = useCallback(
    (collab) => {
      const filterCollab = (collab || []).filter(
        (item: Collaborator) => item.user === currentUser._id
      );
      return 100 - filterCollab?.[0]?.profitShare;
    },
    [currentUser._id]
  );

  const convertToPositive = useCallback((price) => {
    if (Number(price) < 0) {
      return -price;
    }
    return price;
  }, []);

  const renderTrackItem = useCallback(() => {
    return (
      <Box w={{ md: "930px" }}>
        {(analyticsTracks || [])?.length <= 0 ? (
          <EmptyAnalytic
            title={t("youHaveNoAnalytics")}
            icon={<IconEmptyAnalytics />}
          />
        ) : (
          (analyticsTracks || []).map((item: TrackSoundKit, index: number) => {
            const linkDetail = checkTypeTrackOrSoundPack(item)
              ? `/track/${item?.slug}`
              : `/soundkit/${item?.slug}`;
            return (
              <Flex
                key={item?._id}
                position="relative"
                alignItems="center"
                w={{ base: "930px", md: "100%" }}
                py={{ base: "6px", md: "15px" }}
                gap={{ base: "26px", md: "0px" }}
              >
                <Box
                  position="sticky"
                  zIndex="2"
                  left="0px"
                  backdropFilter="blur(20px)"
                  h={{ base: "104px", md: "100%" }}
                  display="flex"
                  alignItems="center"
                  minWidth={{ base: "11%", md: "15%" }}
                  fontSize={{ base: "14px", md: "16px" }}
                >
                  <Text mr={{ base: "20px", md: "30px" }}>{index + 1}</Text>
                  <Image
                    w={{ base: "60px", md: "72px" }}
                    h={{ base: "60px", md: "72px" }}
                    src={item?.coverUrl}
                    fallbackSrc={AvatarImageTrack}
                    borderRadius="4px"
                  />
                </Box>

                <Box
                  w={{ base: "20%", md: "25%" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  minWidth={{ base: "20%", md: "25%" }}
                >
                  <Link to={linkDetail}>
                    <Text
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      w="90%"
                      _hover={{
                        textDecor: "underline",
                      }}
                    >
                      {item?.title}
                    </Text>
                  </Link>

                  <Flex overflow="hidden">
                    <Box
                      display="flex"
                      alignItems="center"
                      borderRadius="4px"
                      padding="2px 5px"
                      mr="5px"
                      mb="5px"
                      backgroundColor="#2b2e1b"
                    >
                      <Text
                        _groupHover={{
                          fontSize: "15px",
                        }}
                        fontSize="12px"
                        lineHeight="14px"
                        color="#d4f70e"
                        w="max-content"
                      >
                        {t(TRACK_TYPE[item?.type])}
                      </Text>
                    </Box>
                    <Text fontSize="14px" color="#464649" noOfLines={1}>
                      {`By `}
                      <LinkToTrackOrProfile
                        name={item?.createdBy?.name || ""}
                        type="user"
                      />
                    </Text>
                  </Flex>
                </Box>

                <Text
                  w="10%"
                  fontSize={{ base: "14px", md: "16px" }}
                  textAlign={{ base: "unset", xl: "center" }}
                >
                  {item?.profitExtended?.itemSold || 0}
                </Text>
                <Text
                  w="10%"
                  ml={{ base: "-20px", md: "0px" }}
                  fontSize={{ base: "14px", md: "16px" }}
                  color="#D2F312"
                  textAlign={{ base: "unset", xl: "center" }}
                >
                  ${Number(item?.profitExtended?.gross || 0)?.toFixed(2)}
                </Text>
                <Text
                  w="10%"
                  fontSize={{ base: "14px", md: "16px" }}
                  color={
                    item?.profitExtended?.discount &&
                    convertToPositive(item?.profitExtended?.discount) > 0
                      ? "red"
                      : "#303033"
                  }
                  textAlign={{ base: "unset", xl: "center" }}
                >
                  -${Number(item?.profitExtended?.discount || 0)?.toFixed(2)}
                </Text>
                <Text
                  w="10%"
                  fontSize={{ base: "14px", md: "16px" }}
                  color={
                    percentCollab(item?.collaborators) <= 0 ? "#303033" : "red"
                  }
                  textAlign={{ base: "unset", xl: "center" }}
                >
                  -{percentCollab(item?.collaborators) || 0}%
                </Text>
                <Text
                  w="10%"
                  fontSize={{ base: "14px", md: "16px" }}
                  color={
                    item?.profitExtended?.superFee &&
                    item?.profitExtended?.superFee > 0
                      ? "red"
                      : "#303033"
                  }
                  textAlign={{ base: "unset", xl: "center" }}
                >
                  -{Number(item?.profitExtended?.superFee || 0)?.toFixed(2)}%
                </Text>
                <Text
                  w="10%"
                  color="#D2F312"
                  fontSize={{ base: "14px", md: "16px" }}
                  textAlign={{ base: "unset", xl: "end" }}
                >
                  +$
                  {Number(item?.profitExtended?.earnings || 0)?.toFixed(2)}
                </Text>
              </Flex>
            );
          })
        )}
      </Box>
    );
  }, [analyticsTracks, convertToPositive, percentCollab, t]);

  return (
    <Flex
      mt="30px"
      gridGap="20px"
      w="100%"
      direction={{ base: "column", md: "row" }}
    >
      <Box w={{ base: "100%", md: "75%" }} maxHeight="450px" overflow="scroll">
        <Flex
          alignItems="center"
          mb={{ base: "0px", md: "20px" }}
          minW="930px"
          gap={{ base: "30px", md: "0px" }}
        >
          {headerTrackItem.map((item, index) => (
            <Flex
              key={item.id}
              alignItems="center"
              w={item.width}
              className={`colums-${index}`}
              position={index === 0 ? "sticky" : "static"}
              left={index === 0 ? "0px" : ""}
              zIndex={index === 0 ? "1" : ""}
              backdropFilter={index === 0 ? "blur(20px)" : " "}
              py="4px"
            >
              <Text fontSize={{ base: "12px", md: "14px" }} mr="5px">
                {item.name}
              </Text>
              {item.icon}
            </Flex>
          ))}
        </Flex>
        {renderTrackItem()}
      </Box>
      <Box width={{ base: "100%", md: "25%" }} maxHeight="450px">
        <PopularLicense analyticsTrack={analyticsTracks} />
      </Box>
    </Flex>
  );
}
