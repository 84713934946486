import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { TRANSLATIONS_SPA } from "./spa/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_POR } from "./por/translations";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: TRANSLATIONS_EN,
      },
      es: {
        translation: TRANSLATIONS_SPA,
      },
      pt: {
        translation: TRANSLATIONS_POR,
      },
    },
  });

i18n.changeLanguage("en");
