import { Box, Flex } from "@chakra-ui/react";
import NewLicense from "app/components/NewLicense";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import React, { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import NoUploadTrack from "../AddTrack/noUploadTrack";
import TabsMenuHeader from "../AddTrack/TabHeader";
import "./styles.scss";

export default function CreateNewLicense() {
  const { t } = useTranslation();
  const location = useLocation();
  const isEditLicense = useMemo(
    () => location?.state?.label === "licensesEdit",
    [location?.state?.label]
  );

  const {
    methods,
    checkCreateLicense,
    isCreateNewLicense,
    isLoading,
    tabsMenu,
  } = useLicenseContracts();

  useEffect(() => {
    checkCreateLicense();
  }, [checkCreateLicense]);

  return (
    <FormProvider {...methods}>
      {isLoading ? (
        <Flex justifyContent="center" height="50px">
          <FadeLoader color={"#d4f70e"} loading={isLoading} />
        </Flex>
      ) : isCreateNewLicense || isEditLicense ? (
        <Box className="create-new-license" mb="50px">
          <Box
            w={{ base: "100%", md: "90%", lg: "80%" }}
            className="tabs-panels"
            variant="unstyled"
          >
            <Box display={{ base: "none", md: "block" }}>
              <TabsMenuHeader tabsMenu={tabsMenu} />
            </Box>
            <NewLicense />
          </Box>
        </Box>
      ) : (
        <NoUploadTrack text={t("limitNewLicense")} />
      )}
    </FormProvider>
  );
}
