import { Box, SimpleGrid, Text, Flex } from "@chakra-ui/react";
import ErrorMessage from "app/components/ErrorMessage";
import keys from "constants/keys";
import { useGenre } from "hooks/genre/useGenre";
import { useMood } from "hooks/mood/useMood";
import React, { useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddTag from "./AddTag";
import SelectInput from "./SelectInput";
import "./styles.scss";
import TextInput from "./TextInput";
import CheckBox from "../Checkbox";
import Divider from "app/components/AddTrackComponents/Divider";
import { alphabeticallyItems } from "constants/alphabeticallyItems";

interface MetaDataProps {
  isSoundkit?: boolean;
}

export default function Metadata({ isSoundkit = false }: MetaDataProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();

  const { getMoods, getMoodsType } = useMood();
  const { genres, getSubGenres, getGenres } = useGenre();
  const mainGenre = watch("mainGenre");
  const secondaryMood = watch("secondaryMood");
  const primaryMood = watch("primaryMood");
  const isPrivate = watch("private");
  const isExcludeFromBulkDiscounts = watch("excludeFromBulkDiscounts");
  const isNotForSale = watch("notForSale");

  useEffect(() => {
    getGenres();
  }, [getGenres]);

  useEffect(() => {
    getMoods();
  }, [getMoods]);

  const convertGenres = alphabeticallyItems(genres);
  const subGenres = useMemo(
    () => getSubGenres(mainGenre),
    [getSubGenres, mainGenre]
  );
  const convertSubGenres = alphabeticallyItems(subGenres);

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <Box>
      <Text fontSize="20px" mb="20px" fontFamily="Cera Pro Medium">
        {t("metadata")}
      </Text>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing="15px">
        <SelectInput
          id="mainGenre"
          name={"mainGenre"}
          placeholder={t("selectGenre")}
          label={t("Genre")}
          data={convertGenres}
          error={errors?.mainGenre?.message}
          control={control}
        />
        <SelectInput
          id="subGenre"
          name={"subGenre"}
          placeholder={t("selectSubGenre")}
          label={t("Sub-Genre")}
          data={convertSubGenres}
          error={errors?.subGenre?.message}
          control={control}
        />

        {!isSoundkit && (
          <>
            <SelectInput
              id="key"
              name={"key"}
              placeholder={t("selectKey")}
              label={t("key")}
              data={keys}
              error={errors?.key?.message}
              control={control}
            />
            <TextInput
              mt="10px"
              label={t("bpm")}
              name={"bpm"}
              errors={errors}
              placeholder={t("enterBpm")}
              type="number"
              min={0}
              max={999}
              control={control}
            />
            <SelectInput
              id="primaryMood"
              name={"primaryMood"}
              label={t("Primary Mood")}
              placeholder={t("selectPrimaryMood")}
              data={getMoodsType(secondaryMood)}
              error={errors?.primaryMood?.message}
              control={control}
            />
            <SelectInput
              id="secondaryMood"
              name={"secondaryMood"}
              label={t("Second Mood")}
              placeholder={t("selectSecondMood")}
              data={getMoodsType(primaryMood)}
              error={errors?.secondaryMood?.message}
              control={control}
            />
          </>
        )}
      </SimpleGrid>
      <Controller
        name={"tags"}
        render={({ field }) => (
          <Box id="tags">
            <AddTag
              currentTags={field?.value ?? []}
              handleAddTag={(tags) =>
                field.onChange({
                  target: {
                    value: tags,
                  },
                })
              }
            />
            <ErrorMessage error={errors.tags?.message} />
          </Box>
        )}
        control={control}
      />
      {isSoundkit && (
        <Box>
          <Divider />
          <Text fontSize="20px" mb="20px" fontFamily="Cera Pro Medium">
            {t("availability")}
          </Text>
          <Flex
            fontFamily="Cera Pro Regular"
            display={{ base: "block", md: "flex" }}
            mt="20px"
            mb="30px"
            gridGap={{ md: "10px", lg: "30px" }}
          >
            <Controller
              name={"private"}
              render={() => (
                <CheckBox
                  title={t("private")}
                  isChecked={isPrivate}
                  onChange={(value) => setValue("private", value)}
                />
              )}
              control={control}
            />
            <Box
              mt={{ base: "10px", md: "0px" }}
              mb={{ base: "10px", md: "0px" }}
            >
              <Controller
                name={"excludeFromBulkDiscounts"}
                render={() => (
                  <CheckBox
                    title={t("excludeBulkOfferDiscout")}
                    isChecked={isExcludeFromBulkDiscounts}
                    onChange={(value) =>
                      setValue("excludeFromBulkDiscounts", value)
                    }
                  />
                )}
                control={control}
              />
            </Box>

            <Controller
              name={"notForSale"}
              render={() => (
                <CheckBox
                  title={t("notForSale")}
                  defaultChecked={isNotForSale}
                  onChange={(value) => setValue("notForSale", value)}
                />
              )}
              control={control}
            />
          </Flex>
        </Box>
      )}
    </Box>
  );
}
