import { STATUS_COLOR } from "./negotiationType";

export const COLLABORATOR_TYPE = {
  PRODUCER: "PRODUCER",
  ENGINEER: "ENGINEER",
  SONGWRITER: "SONGWRITER",
  MAIN_PERFORMER: "MAIN_PERFORMER",
  FEATURED_PERFORMER: "FEATURED_PERFORMER",
  CONTEST_PARTNER: "CONTEST_PARTNER",
  RECORD_LABEL: "RECORD_LABEL",
  MANAGER: "MANAGER",
  CHARITY: "CHARITY",
  ARTIST: "ARTIST",
  USER: "USER",
};

export const TypeStatusCollaboration = {
  Expired: "Expired",
};

export const collaboratorType = [
  {
    label: "producer",
    value: COLLABORATOR_TYPE.PRODUCER,
  },
  { label: "Engineer", value: COLLABORATOR_TYPE.ENGINEER },
  { label: "Songwriter", value: COLLABORATOR_TYPE.SONGWRITER },
  { label: "Main Performer", value: COLLABORATOR_TYPE.MAIN_PERFORMER },
  { label: "Featured Performer", value: COLLABORATOR_TYPE.FEATURED_PERFORMER },
  { label: "Contest Partner", value: COLLABORATOR_TYPE.CONTEST_PARTNER },
  { label: "Record Label", value: COLLABORATOR_TYPE.RECORD_LABEL },
  { label: "Manager", value: COLLABORATOR_TYPE.MANAGER },
  { label: "Charity", value: COLLABORATOR_TYPE.CHARITY },
];

export const roleText = (role: string) => {
  const roleText = {
    [COLLABORATOR_TYPE.PRODUCER]: "Producer",
    [COLLABORATOR_TYPE.ENGINEER]: "Engineer",
    [COLLABORATOR_TYPE.SONGWRITER]: "Songwriter",
    [COLLABORATOR_TYPE.MAIN_PERFORMER]: "Main Performer",
    [COLLABORATOR_TYPE.FEATURED_PERFORMER]: "Featured Performer",
    [COLLABORATOR_TYPE.CONTEST_PARTNER]: "Contest Partner",
    [COLLABORATOR_TYPE.RECORD_LABEL]: "Record Label",
    [COLLABORATOR_TYPE.MANAGER]: "Manager",
    [COLLABORATOR_TYPE.CHARITY]: "Charity",
    [COLLABORATOR_TYPE.ARTIST]: "Artist",
    [COLLABORATOR_TYPE.USER]: "User",
  };
  return roleText[role];
};

export const getBorderColor = (status: string) => {
  const borderColor = {
    [STATUS_COLOR.PENDING]: "#1DA1F2",
    [STATUS_COLOR.ACCEPTED]: "#D8FD01",
    [STATUS_COLOR.REJECTED]: "#7E2C2F",
    [STATUS_COLOR.DEFAULT]: "#7E2C2F",
  };
  return borderColor[status] || borderColor[STATUS_COLOR.DEFAULT];
};

export const getStatusColor = (status: string) => {
  const colorStatus = {
    [STATUS_COLOR.PENDING]: {
      backgroundColor: "rgba(29, 161, 242, 0.1)",
      color: "#1DA1F2",
    },
    [STATUS_COLOR.ACCEPTED]: {
      backgroundColor: "rgba(212, 247, 14, 0.1)",
      color: "#D8FD01",
    },
    [STATUS_COLOR.REJECTED]: {
      backgroundColor: "rgba(237, 54, 54, 0.1)",
      color: "#ED3636",
    },
    [STATUS_COLOR.DEFAULT]: {
      backgroundColor: "rgba(29, 161, 242, 0.1)",
      color: "#1DA1F2",
    },
  };
  return colorStatus[status] || colorStatus[STATUS_COLOR.DEFAULT];
};
