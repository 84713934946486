import {
  Box,
  CloseButton,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import IconLicenseReview from "app/assets/icons/iconLicenseReview";
import PlayButton from "app/assets/icons/iconPlayTrack";
import RemoveTrack from "app/assets/icons/iconRemove";
import ImageTrackPaypal from "app/assets/images/AvatarMusicLoading.svg";
import { TrackSoundKit } from "app/models";
import { removeFromCart } from "app/redux/actions/cart";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";

interface ModalPurchaseProps {
  showModal: boolean;
  setShowModal: () => void;
  selectedTrack: TrackSoundKit | null;
  setShowModalPlanDetail?: () => void;
}

export default function ModalPurchase({
  showModal,
  selectedTrack,
  setShowModalPlanDetail,
  setShowModal,
}: ModalPurchaseProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    handlePlayOrPause,
    handleTogglePlayPause,
    playingTrackId,
    handlePlayPlaylists,
  } = usePlayers();

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart([selectedTrack?._id]));
    setShowModal();
  }, [dispatch, selectedTrack?._id, setShowModal]);

  const redirectLicenseReview = useCallback(() => {
    history.push(`cart/license-review/${selectedTrack?._id}`);
    setShowModal();
  }, [history, selectedTrack?._id, setShowModal]);

  const onClickToTrack = useCallback(async () => {
    setShowModal();
    if (selectedTrack?.mergeAudio && selectedTrack?.audioFileUrl) {
      return;
    }
    if (playingTrackId !== selectedTrack?._id) {
      handlePlayOrPause(selectedTrack);
    } else {
      handleTogglePlayPause();
    }
    handlePlayPlaylists([]);
  }, [
    handlePlayOrPause,
    handlePlayPlaylists,
    handleTogglePlayPause,
    playingTrackId,
    selectedTrack,
    setShowModal,
  ]);

  const checkTrackOrSoundPack = useMemo(() => {
    return selectedTrack?.trackState;
  }, [selectedTrack?.trackState]);

  return (
    <Modal isOpen={showModal} onClose={setShowModal} isCentered>
      <ModalOverlay />
      <ModalContent
        minW={{ base: "300px", md: "500px" }}
        margin={{ base: "0px 10px", md: "0px" }}
      >
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={2}
          top={{ base: "15px", md: "25px" }}
          fontSize={{ base: "20px", md: "24px" }}
          position="absolute"
          color="#d4f70e"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
        />
        <ModalBody padding={{ base: "15px 20px", md: "10px 40px" }}>
          <Flex alignItems="center" mt="20px" mb="60px">
            <Image
              w="60px"
              h="60px"
              src={selectedTrack?.coverUrl}
              fallbackSrc={ImageTrackPaypal}
              borderRadius="4px"
            />
            <Flex ml="15px" flexDirection="column" gap="8px">
              <Link to={`/track/${selectedTrack?.slug}`}>
                <Text
                  fontSize={{ base: "20px", md: "30px" }}
                  cursor="pointer"
                  _hover={{
                    textDecor: "underline",
                  }}
                  noOfLines={1}
                >
                  {selectedTrack?.title}
                </Text>
              </Link>

              <Flex>
                {TRACK_TYPE[selectedTrack?.type] && (
                  <Box
                    display="flex"
                    alignItems="center"
                    borderRadius="4px"
                    padding="2px 6px"
                    mr="5px"
                    mb="5px"
                    backgroundColor="#2b2e1b"
                  >
                    <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                      {t(TRACK_TYPE[selectedTrack?.type])}
                    </Text>
                  </Box>
                )}

                <Text
                  cursor="pointer"
                  fontWeight="600"
                  fontSize="14px"
                  color="#38383D"
                >
                  {t("by")}
                  <Link to={`/user/${selectedTrack?.createdBy?.name}`}>
                    <Text
                      _hover={{
                        textDecor: "underline",
                      }}
                      as="span"
                      ml="5px"
                    >
                      {selectedTrack?.createdBy?.name}
                    </Text>
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
          {!!checkTrackOrSoundPack && (
            <Flex
              alignItems="center"
              mt="40px"
              cursor="pointer"
              onClick={redirectLicenseReview}
            >
              <IconLicenseReview />
              <Text ml="20px">{t("licenseReview")}</Text>
            </Flex>
          )}
          <Flex
            onClick={onClickToTrack}
            alignItems="center"
            mt="30px"
            cursor="pointer"
          >
            <PlayButton />
            <Text ml="20px">{t("playTrack")}</Text>
          </Flex>
          <Flex
            alignItems="center"
            mt="30px"
            mb="30px"
            onClick={handleRemoveToCartButton}
            cursor="pointer"
          >
            <RemoveTrack />
            <Text ml="20px">{t("remove")}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
