import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { PromoCode } from "app/models";
import React from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { dayjsFormat } from "utils/timeAgo";
import ErrorMessage from "app/components/ErrorMessage";

interface IInitStatusProps {
  nonExpiring: boolean;
  expirationDate: string;
}

interface IUpdateStatusModalProps {
  item?: PromoCode;
  isShowModal: boolean;
  setShowModal: (value: boolean) => void;
  onUpdateStatus?: (values: IInitStatusProps) => void;
}
export default function UpdateStatusModal({
  item,
  isShowModal,
  setShowModal,
  onUpdateStatus,
}: IUpdateStatusModalProps) {
  const { nonExpiring = false, expirationDate = "" } = item;
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IInitStatusProps>({
    resolver: yupResolver(
      yup.object().shape({
        nonExpiring: yup.boolean().notRequired(),
        expirationDate: yup.date().when("nonExpiring", {
          is: true,
          then: yup.date(),
          otherwise: yup
            .date()
            .min(new Date(), "mustBeLaterThanToday")
            .required("expirationDateRequired"),
        }),
      })
    ),
    defaultValues: {
      nonExpiring,
      expirationDate: dayjsFormat(expirationDate).format("YYYY-MM-DDThh:mm"),
    },
  });

  const onSubmit = (data: IInitStatusProps) => onUpdateStatus(data);

  return (
    <Modal isOpen={isShowModal} onClose={() => setShowModal(false)}>
      <ModalOverlay />
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent minW="560px">
          <ModalHeader>{t("Reactivate")}</ModalHeader>
          <CloseButton
            _focus={{
              borderWidth: "0px",
            }}
            onClick={() => setShowModal(false)}
            right={2}
            top="15px"
            fontSize="26px"
            position="absolute"
            borderColor="#38383D"
            _hover={{
              border: "1px solid #38383D !important",
              padding: "18px",
            }}
            padding="18px"
            color="#d4f70e"
          />
          <ModalBody>
            <Controller
              name={"expirationDate"}
              render={({ field }) => (
                <Box>
                  <Input
                    borderRadius="4px"
                    backgroundColor="#25252A"
                    border="none"
                    size="lg"
                    type="datetime-local"
                    {...field}
                  />
                </Box>
              )}
              control={control}
            />
            <ErrorMessage error={errors.expirationDate?.message} />
            <Controller
              name={"nonExpiring"}
              render={({ field }) => {
                const { value, onChange } = field;
                return (
                  <Flex pt="20px" pb="10px">
                    <Checkbox onChange={onChange} isChecked={value} pr="16px" />
                    <Text fontSize="14px">{t("NonExpiring")}</Text>
                  </Flex>
                );
              }}
              control={control}
            />
            <ErrorMessage error={errors.nonExpiring?.message} />
          </ModalBody>
          <ModalFooter pb="30px" alignSelf="center">
            <Button
              _hover={{
                background: "black",
              }}
              color="white"
              bg="rgba(255, 255, 255, 0.04)"
              fontSize="14px"
              fontWeight="500"
              borderRadius="4px"
              mr={3}
              onClick={() => setShowModal(false)}
              height="50px"
              w="80px"
              _active={{ background: "black" }}
            >
              {t("cancel")}
            </Button>
            <Button
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              fontSize="14px"
              fontWeight="500"
              borderRadius="4px"
              height="48px"
              w="150px"
              type="submit"
            >
              {t("Activate")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </form>
    </Modal>
  );
}
