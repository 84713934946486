import { Box, Select, Text } from "@chakra-ui/react";
import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import ErrorMessage from "app/components/ErrorMessage";
interface SelectItemProps {
  label: string;
  value: string;
}

interface SelectProps {
  data: SelectItemProps[];
  placeholder?: string;
  label?: string;
  labelColor?: string;
  error?: string | boolean;
  name?: string;
  onChange?: (value: string) => string | void;
  className?: string;
  marginTop?: string;
}

export default function SelectInput({
  data = [],
  placeholder,
  label,
  error,
  labelColor = "#535353",
  onChange,
  className,
  marginTop,
  ...restProps
}: SelectProps) {
  const { t } = useTranslation();

  return (
    <Box>
      <Text
        mt={marginTop}
        className={className}
        color={labelColor}
        fontFamily="Cera Pro Regular"
      >
        {label}
      </Text>
      <Select
        fontFamily="Cera Pro Medium"
        placeholder={placeholder}
        border="none"
        backgroundColor="#24252A"
        mt="10px"
        borderRadius="4px"
        size="lg"
        onChange={(e) => onChange && onChange(e.target.value)}
        {...restProps}
        className={className}
        sx={{
          "> option": {
            background: "black",
            color: "white",
          },
        }}
        fontSize={{ base: "14px", md: "16px" }}
      >
        {data?.map((item: SelectItemProps) => {
          return (
            <option value={item.value} key={item.value}>
              {t(`${item.label}`)}
            </option>
          );
        })}
      </Select>
      {error && <ErrorMessage error={error} />}
    </Box>
  );
}
