import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PlayButtonGroup from "app/components/FooterPlayer/PlayButton";
import { LyricsModel, TrackSoundKit } from "app/models";
import { deleteLyric, getLyrics } from "app/redux/actions/tracks";
import { isLoadingSelector } from "app/redux/selectors/status";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import "./styles.scss";
import { BeatLoader } from "react-spinners";
import { useHistory } from "react-router-dom";
import { PROFILE_TYPE } from "constants/contractType";

interface ItemLyricsProps {
  onOpenOptionsModal: (value: TrackSoundKit, isLyrics: boolean) => void;
  lyricItem: LyricsModel;
  index: number;
}

export default function ItemLyrics({
  onOpenOptionsModal,
  lyricItem,
  index,
}: ItemLyricsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectTrack, setSelectTrack] = useState("");
  const isLoadingDeleteLyric = useSelector((state: RootState) =>
    isLoadingSelector([deleteLyric.typePrefix], state)
  );

  const {
    playingTrackId,
    isPlaying,
    handlePlayOrPause,
    handleTogglePlayPause,
    isLoading,
    handlePlayPlaylists,
  } = usePlayers();

  const trackItem = useMemo(() => {
    if (lyricItem?.trackId) {
      return lyricItem?.trackId as TrackSoundKit;
    }
    return lyricItem?.soundPackId as TrackSoundKit;
  }, [lyricItem?.soundPackId, lyricItem?.trackId]);

  const handleGetLyrics = useCallback(
    (e, track, isSoundPack) => {
      const data = isSoundPack
        ? { soundPackId: track?._id, isSoundPack }
        : { trackId: track?._id };
      e.stopPropagation();
      onOpenOptionsModal(track, true);
      dispatch(getLyrics({ ...data }));
    },
    [dispatch, onOpenOptionsModal]
  );

  const onClickToTrack = useCallback(
    async (track) => {
      if (!track?.mergeAudio && !track.audioFileUrl) {
        return;
      }
      if (playingTrackId !== track?._id) {
        handlePlayOrPause(track);
        handlePlayPlaylists([]);
      } else {
        handleTogglePlayPause();
      }
    },
    [
      handlePlayOrPause,
      handlePlayPlaylists,
      handleTogglePlayPause,
      playingTrackId,
    ]
  );

  const handleDeleteLyric = useCallback(
    (e, trackId, isSoundPack) => {
      const data = isSoundPack
        ? { trackId: trackId, isSoundPack }
        : { trackId: trackId };
      e.stopPropagation();
      setSelectTrack(trackId);
      dispatch(deleteLyric({ ...data }));
    },
    [dispatch]
  );

  const currentPlayingTrack = useMemo(() => {
    return playingTrackId === trackItem?._id;
  }, [playingTrackId, trackItem?._id]);

  const handleTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      const path = lyricItem?.trackId
        ? `/track/${lyricItem?.trackId?.slug}`
        : `/soundkit/${lyricItem?.soundPackId?.slug}`;
      history.push(path);
      return;
    },
    [history, lyricItem?.soundPackId?.slug, lyricItem?.trackId]
  );

  const redirectProfile = useCallback(
    (e, name) => {
      e.stopPropagation();
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );
  return (
    <Box className="lyric-page">
      <Box
        role="group"
        cursor="pointer"
        className="bodlevard"
        onClick={() =>
          onClickToTrack(
            lyricItem?.trackId ? lyricItem?.trackId : lyricItem?.soundPackId
          )
        }
        padding="16px 0px"
        display={{ base: "block", md: "flex" }}
      >
        <Flex gap={{ base: "15px", md: "20px" }} alignItems="center">
          <Text
            justifyContent="center"
            w="45px"
            display={currentPlayingTrack ? "none" : "flex"}
            _groupHover={{
              display: "none",
            }}
          >
            {index + 1}
          </Text>
          <Flex
            w={"45px"}
            h={"45px"}
            borderRadius="4px"
            alignItems="center"
            justifyContent="center"
            display={currentPlayingTrack ? "flex" : "none"}
            _groupHover={{
              display: "flex",
            }}
          >
            <PlayButtonGroup
              size={35}
              isPlaying={currentPlayingTrack && isPlaying}
              isLoading={isLoading}
            />
          </Flex>

          <Image
            w="72px"
            height="72px"
            borderRadius="4px"
            fallbackSrc={ImageDefault}
            src={trackItem?.coverUrl}
          />
          <Box className="broken">
            <Text onClick={(e) => handleTrackDetail(e)}>
              {trackItem?.title}
            </Text>

            <Flex mt="5px">
              {TRACK_TYPE[trackItem?.type] && (
                <Box
                  display="flex"
                  alignItems="center"
                  height="20px"
                  borderRadius="4px"
                  padding="5px 10px"
                  mr="5px"
                  mb="5px"
                  backgroundColor="#2b2e1b"
                >
                  <Text
                    fontSize="12px"
                    lineHeight="14px"
                    color="#d4f70e"
                    fontFamily="Cera Pro Regular"
                  >
                    {t(TRACK_TYPE[trackItem?.type])}
                  </Text>
                </Box>
              )}
              <Text
                fontSize="14px"
                color="#464649"
                _hover={{
                  textDecor: "underline",
                  color: "white",
                }}
                cursor="pointer"
                fontFamily="Cera Pro Regular"
                onClick={(e) => redirectProfile(e, trackItem?.createdBy?.name)}
              >
                {trackItem?.createdBy?.name}
              </Text>
            </Flex>
          </Box>
        </Flex>
        <Flex
          justifyContent="center"
          gap="15px"
          mt={{ base: "20px", md: "0px" }}
          padding={{ base: "0px 20px", md: "0px" }}
        >
          <Button
            w={{ base: "50%", md: "132px" }}
            h="44px"
            className="buttonview"
            onClick={(e) =>
              handleGetLyrics(
                e,
                lyricItem?.trackId
                  ? lyricItem?.trackId
                  : lyricItem?.soundPackId,
                lyricItem?.trackId ? false : true
              )
            }
          >
            {t("view")}
          </Button>
          <Button
            isLoading={isLoadingDeleteLyric && selectTrack === trackItem?._id}
            w={{ base: "50%", md: "132px" }}
            h="44px"
            className="buttonremove"
            onClick={(e) =>
              handleDeleteLyric(
                e,
                trackItem?._id,
                lyricItem?.trackId ? false : true
              )
            }
            spinner={<BeatLoader size={8} color="white" />}
          >
            {t("remove")}
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}
