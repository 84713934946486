import { Flex, Image, Box } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";

export default function SkeletonItemDiscover() {
  return (
    <Box mb="30px" overflow="hidden">
      <Skeleton
        w={{ base: "160px", md: "227px" }}
        h={{ base: "17px", md: "23px" }}
        mt={{ base: "15px", md: "15px" }}
        mb={{ base: "15px", md: "30px" }}
      />
      <Flex>
        {Array.from({ length: 3 }).map((item, index) => (
          <Flex
            w="341px"
            padding="0px 10px"
            flexDirection="column"
            mb="20px"
            key={index}
          >
            <Image
              mb="30px"
              w={{ base: "170px", md: "272px" }}
              h={{ base: "170px", md: "272px" }}
              fallbackSrc={ImageDefault}
            />
            <Skeleton w="140px" mb="10px" ml="5px" />
            <Flex ml="5px" alignItems="center">
              <Skeleton w="34px" h="21px" borderRadius="2px" />
              <Skeleton
                w={{ base: "80px", md: "109px" }}
                h={{ base: "16px", md: "21px" }}
              />
            </Flex>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
}
