import ToastPlanSubscription from "app/components/toast/toastplan";
import { SubscriptionPlan } from "app/models";
import {
  cancelSubscription,
  createSubscription,
  fetchSubscriptions,
} from "app/redux/actions/subscriptions";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import { selectAllSubscriptions } from "app/redux/selectors/subscription";
import { planType } from "constants/planType";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { openLinkCreateSubscription } from "utils/capitalize";

export const useSubscription = () => {
  const dispatch = useDispatch();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const subscriptions = useSelector(selectAllSubscriptions);
  const timePlans = ["MONTHLY", "ANNUAL"];

  const checkCurrentPlans = (subscriptions || []).find(
    (item) => item.currentPlan
  )?.plan;
  const checkIsPlanMainstream = timePlans.includes(checkCurrentPlans || "");

  const isLoadingCancelSubscription = useSelector((state: RootState) =>
    isLoadingSelector([cancelSubscription.typePrefix], state)
  );
  const isLoadingCheckoutPlan = useSelector((state: RootState) =>
    isLoadingSelector([createSubscription.typePrefix], state)
  );

  const isLoading = isLoadingCancelSubscription || isLoadingCheckoutPlan;
  const isLoadingGetSubscription = useSelector((state: RootState) =>
    isLoadingSelector([fetchSubscriptions.typePrefix], state)
  );
  const isSuccess = useSelector((state: RootState) =>
    successSelector([cancelSubscription.typePrefix], state)
  );

  const getSubscriptions = useCallback(() => {
    dispatch(fetchSubscriptions());
  }, [dispatch]);

  const activeSubscription = useMemo(() => {
    return (subscriptions || []).find(
      (item: SubscriptionPlan) => item?.currentPlan
    );
  }, [subscriptions]);

  const currentPaidPlan = useMemo(() => {
    if (
      activeSubscription?.plan === planType.ANNUAL ||
      activeSubscription?.plan === planType.MONTHLY
    ) {
      return activeSubscription?.plan;
    }
  }, [activeSubscription]);

  const activePlanCheck = useCallback(
    (_id) => activeSubscription?._id === _id,
    [activeSubscription]
  );

  const handleCancelPlan = useCallback(() => {
    dispatch(cancelSubscription());
    setShowModalCancel(true);
  }, [dispatch]);

  const handleCheckoutUrl = useCallback(
    (plan) => {
      dispatch(createSubscription(plan)).then((res) => {
        if (res.error) {
          return ToastPlanSubscription({ description: res.error.message });
        } else {
          return openLinkCreateSubscription(res.payload.url);
        }
      });
    },
    [dispatch]
  );

  return {
    isLoading,
    isSuccess,
    currentPaidPlan,
    subscriptions,
    activeSubscription,
    checkIsPlanMainstream,
    isLoadingGetSubscription,
    getSubscriptions,
    activePlanCheck,
    handleCancelPlan,
    handleCheckoutUrl,
    showModalCancel,
    setShowModalCancel,
  };
};
