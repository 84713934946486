import React from "react";

const BiDotsComlumnFooterPlayer = () => {
  return (
    <svg
      width="4"
      height="16"
      viewBox="0 0 4 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="1.8" cy="1.84615" rx="1.8" ry="1.84615" fill="white" />
      <ellipse cx="1.8" cy="7.9985" rx="1.8" ry="1.84615" fill="white" />
      <ellipse cx="1.8" cy="14.1547" rx="1.8" ry="1.84615" fill="white" />
    </svg>
  );
};
export default BiDotsComlumnFooterPlayer;
