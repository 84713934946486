import { Negotiations } from "app/models";
import { addToCart, selectedLisence } from "app/redux/actions/cart";
import {
  acceptCounteroffer,
  acceptNegotiation,
  cancelOrRejectNegotiation,
  counterofferNegotiation,
  getNegotiation,
  getNegotiations,
} from "app/redux/actions/negotiations";
import {
  selectActiveNegotiations,
  selectInactiveNegotiations,
} from "app/redux/selectors/negotiations";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectUserAdmin } from "app/redux/selectors/users";
import {
  ACTIVE_STATUS,
  getStatusColor,
  STATUS_COLOR,
} from "constants/negotiationType";
import { useToggle } from "hooks/useToggle";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "types";
import { dayjsFormat, fromNow, getTimeString } from "utils/timeAgo";

export const useNegotiations = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showNegotiationDetail, setShowNegotiationDetail] = useToggle();
  const [showContent, setShowContent] = useState({
    showContentNegotiationDeal: false,
    showNegotiationOffer: false,
    showSubmitNegotiation: false,
  });
  const [negotiationTrack, setNegotiationTrack] = useState<Negotiations>(null);
  const activeNegotiations = useSelector(selectActiveNegotiations);

  const inactiveNegotiations = useSelector(selectInactiveNegotiations);
  const currentUser = useSelector(selectUserAdmin);

  const isExpiredDate = useCallback(
    (expiredDate) => !dayjsFormat().isBefore(dayjsFormat(expiredDate)),
    []
  );

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getNegotiations.typePrefix], state)
  );

  const createdByMe = useCallback(
    (createdById) => createdById === currentUser?._id,
    [currentUser?._id]
  );

  const onGetNegotiationsActive = useCallback(() => {
    dispatch(
      getNegotiations({
        isActive: true,
      })
    );
  }, [dispatch]);

  const onGetNegotiationsInactive = useCallback(() => {
    dispatch(
      getNegotiations({
        isActive: false,
      })
    );
  }, [dispatch]);

  const renderStatus = useCallback(
    (status, expiredDate) => {
      const validStatuses = [STATUS_COLOR.COMPLETED, STATUS_COLOR.REJECT_BY_SELLER,
        STATUS_COLOR.CANCEL_BY_BUYER, STATUS_COLOR.SOLD_TO_SOMEONE_ELSE];
      if (validStatuses.includes(status)) {
        return t(`negotiationsStatus.${status}`);
      }
      if (isExpiredDate(expiredDate)) {
        return t("negotiationsStatus.EXPIRED");
      }
      if (status === STATUS_COLOR.PENDING) {
        return fromNow(expiredDate);
      }
      return t(`negotiationsStatus.${status}`);
    },
    [isExpiredDate, t]
  );

  const statusColor = useCallback((status, isExpired) => {
    return getStatusColor(status === STATUS_COLOR.COMPLETED ? status : isExpired ? STATUS_COLOR.EXPIRED : status);
  }, []);

  const countdownTime = (updatedAt: any) => {
    const totalMins = dayjsFormat(updatedAt)
      .add(1, "day")
      .diff(dayjsFormat(), "m");
    const time = totalMins < 0 ? "Expired" : getTimeString(totalMins);

    return { totalMins, time };
  };

  const isSeller = useCallback(
    (negotiationItem) => negotiationItem?.userId?._id === currentUser?._id,
    [currentUser?._id]
  );

  const onCancelOrRejectNegotiation = useCallback(
    (negotiationId: string, status: string) => {
      const data = {
        negotiationId: negotiationId,
        status: status,
      };

      dispatch(cancelOrRejectNegotiation(data));
    },
    [dispatch]
  );

  const onCounterofferNegotiation = useCallback(
    (body) => {
      dispatch(counterofferNegotiation(body));
    },
    [dispatch]
  );

  const onAddToCart = useCallback(
    (selectedNegotiation) => {
      (selectedNegotiation?.tracks || []).forEach((track, index) => {
        dispatch(
          addToCart({
            ...track,
            createdBy: selectedNegotiation?.userId,
            basicPrice: selectedNegotiation?.offerPrice,
            licenses: selectedNegotiation?.licenses,
          })
        );
        dispatch(
          selectedLisence({
            trackId: track?._id,
            license: selectedNegotiation?.licenses?.[index],
            negotiation: {
              _id: selectedNegotiation?._id,
              offerPrice: selectedNegotiation?.offerPrice,
            },
          })
        );
      });
      setShowNegotiationDetail();
      history.push("/cart");
    },
    [dispatch, history, setShowNegotiationDetail]
  );

  const onAcceptNegotiation = useCallback(
    (trackNegotiation) => {
      if (!ACTIVE_STATUS.includes(trackNegotiation?.status)) {
        return;
      }
      const selectedPrice = (trackNegotiation?.priceChanges || []).find(
        (element) => element.createdBy !== currentUser?._id
      );
      if (trackNegotiation?.status === STATUS_COLOR.PENDING) {
        // Accept negotiation
        dispatch(
          acceptNegotiation({
            negotiationId: trackNegotiation?._id,
            price: Number(selectedPrice.price),
          })
        );
        return;
      }
      if (trackNegotiation?.status === STATUS_COLOR.COUNTEROFFER) {
        // Accept counter offer
        if (selectedPrice?.price) {
          dispatch(
            acceptCounteroffer({
              negotiationId: trackNegotiation?._id,
              status: STATUS_COLOR.COUNTEROFFER_ACCEPTED,
              price: Number(selectedPrice.price),
            })
          );
        }

        return;
      }
      if (
        [STATUS_COLOR.ACCEPTED, STATUS_COLOR.COUNTEROFFER_ACCEPTED].includes(
          trackNegotiation?.status
        )
      ) {
        // payment
        onAddToCart(trackNegotiation);
        return;
      }
    },
    [currentUser?._id, dispatch, onAddToCart]
  );
  const [isLoadingShowModalNotification, setIsLoadingShowModalNotification] =
    useState(false);
  const handleSelecNotification = (idNegotiation: string) => {
    setIsLoadingShowModalNotification(true);
    dispatch(getNegotiation(idNegotiation)).then((negotiation: any) => {
      setNegotiationTrack(negotiation?.payload?.[0]);
      setIsLoadingShowModalNotification(false);
    });
  };

  return {
    activeNegotiations,
    inactiveNegotiations,
    onGetNegotiationsActive,
    onGetNegotiationsInactive,
    renderStatus,
    isExpiredDate,
    statusColor,
    showNegotiationDetail,
    setShowNegotiationDetail,
    negotiationTrack,
    setNegotiationTrack,
    createdByMe,
    countdownTime,
    isSeller,
    onCancelOrRejectNegotiation,
    currentUser,
    showContent,
    setShowContent,
    onCounterofferNegotiation,
    onAcceptNegotiation,
    handleSelecNotification,
    isLoadingShowModalNotification,
    isLoading,
  };
};
