import React from "react";

const BuyTrackSuccess = () => {
  return (
    <svg
      width="85"
      height="87"
      viewBox="0 0 85 87"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_8732_42491)">
        <path
          d="M84.9043 58.8823C84.9043 65.9134 82.1112 72.6565 77.1395 77.6283C72.1678 82.6 65.4246 85.3931 58.3935 85.3931H26.5806C19.5495 85.3931 12.8064 82.6 7.83465 77.6283C2.86292 72.6565 0.0698242 65.9134 0.0698242 58.8823H84.9043ZM84.9043 56.2296H26.5806C19.5495 56.2296 12.8064 53.4365 7.83465 48.4648C2.86292 43.4931 0.0698242 36.7499 0.0698242 29.7188H58.3935C65.4246 29.7188 72.1678 32.5119 77.1395 37.4837C82.1112 42.4554 84.9043 49.1985 84.9043 56.2296ZM84.9043 27.0694H0.0698242C0.0698242 20.0383 2.86292 13.2952 7.83465 8.32342C12.8064 3.35169 19.5495 0.558594 26.5806 0.558594L58.3935 0.558594C65.4246 0.558594 72.1678 3.35169 77.1395 8.32342C82.1112 13.2952 84.9043 20.0383 84.9043 27.0694Z"
          fill="url(#paint0_radial_8732_42491)"
        />
        <path
          d="M84.8041 58.9823C84.7778 65.9508 81.9982 72.6281 77.0688 77.5576C72.1158 82.5105 65.3981 85.2931 58.3935 85.2931H26.5806C19.576 85.2931 12.8583 82.5105 7.90536 77.5576C2.97596 72.6281 0.196361 65.9508 0.170013 58.9823H84.8041ZM84.8041 56.1296H26.5806C19.576 56.1296 12.8583 53.3471 7.90536 48.3941C2.97596 43.4647 0.196362 36.7873 0.170013 29.8188H58.3935C65.3981 29.8188 72.1158 32.6014 77.0688 37.5544C81.9982 42.4838 84.7778 49.1611 84.8041 56.1296ZM0.170013 26.9694C0.196362 20.0009 2.97596 13.3235 7.90536 8.39413C12.8583 3.44115 19.576 0.658594 26.5806 0.658594H58.3935C65.3981 0.658594 72.1158 3.44115 77.0688 8.39413C81.9982 13.3235 84.7778 20.0009 84.8041 26.9694H0.170013Z"
          stroke="url(#paint1_linear_8732_42491)"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_8732_42491"
          x="0.0698242"
          y="0.558594"
          width="84.8345"
          height="85.8345"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_8732_42491"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_8732_42491"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_8732_42491"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-22.907 -40.0928) rotate(52.7447) scale(157.66 130.449)"
        >
          <stop stopColor="#D8FD01" />
          <stop offset="0.385417" stopColor="#F4FFB3" />
          <stop offset="0.795958" stopColor="#D8FD01" />
          <stop offset="1" stopColor="#95AE04" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_8732_42491"
          x1="5.37218"
          y1="17.3494"
          x2="63.6981"
          y2="64.1869"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default BuyTrackSuccess;
