import { CurrentLicense, RecentAction, TrackSoundKit } from "app/models";

export const getPriceRecentAction = (item: RecentAction) => {
  if (!item?.track) {
    return 0;
  }
  return getPriceTrack(item?.track);
};

export const getPriceTrack = (item: TrackSoundKit) => {
  if (item?.basicPrice) {
    return item?.basicPrice;
  }
  if (item?.licenses && item?.licenses[0]?.exclusive) {
    return item?.licenses[0]?.exclusiveLicensePrice;
  }
  if (
    item?.licenses &&
    (item?.licenses[0]?.trackPrice || item?.licenses[0]?.defaultTrackPrice)
  ) {
    return item?.licenses[0]?.trackPrice
      ? item?.licenses[0]?.trackPrice
      : item?.licenses[0]?.defaultTrackPrice;
  }
  return 0;
};

export const getPriceTrackCartItem = (licenses: CurrentLicense) => {
  if (licenses?.negotiation) {
    return licenses?.negotiation?.offerPrice;
  }
  if (licenses?.license?.exclusive) {
    return licenses?.license?.exclusiveLicensePrice;
  }
  if (licenses?.license?.trackPrice || licenses?.license?.defaultTrackPrice) {
    return licenses?.license?.trackPrice
      ? licenses?.license?.trackPrice
      : licenses?.license?.defaultTrackPrice;
  }

  return 0;
};

export const numberTotalCart = (price: number) => Number(price).toFixed(2);

export const totalAnalytics = (number: number) => {
  return number >= 1e6
    ? (number / 1e6).toFixed(1) + "m"
    : number >= 1e3
    ? (number / 1e3).toFixed(1) + "k"
    : number.toString();
};
