import { Box, Flex, Checkbox, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "../common/Skeleton/skeleton";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";
import "./styles.scss";

export default function SkeletonCartItem() {
  const fakeDataSekeleton = Array.from({ length: 5 });
  return (
    <Flex
      gridGap="20px"
      className="basket-item"
      display={{ base: "block", md: "flex" }}
    >
      <Box w={{ base: "100%", md: "65%", xl: "75%" }}>
        {fakeDataSekeleton.map((item, index) => (
          <Flex alignItems="center" mb="30px" key={index}>
            <Flex w={{ base: "30%", md: "25%", xl: "20%" }} alignItems="center">
              <Checkbox
                borderRadius="4px"
                _focusVisible={{ outline: "none" }}
                size="lg"
                mb={{ base: "7px", md: "0px" }}
              />
              <Skeleton
                ml={{ base: "10px", md: "20px", xl: "40px" }}
                mr={{ base: "5px", md: "10px" }}
                w="10px"
                h="21px"
                borderRadius="2px"
              />
              <IconSkeletonMusic />
            </Flex>
            <Box w={{ base: "30%", md: "40%", xl: "30%" }}>
              <Skeleton
                h="16px"
                w={{ base: "100px", md: "150px" }}
                borderRadius="30px"
                mr="0px"
              />
              <Flex>
                <Skeleton h="16px" w="35px" mb="0px" />
                <Skeleton
                  h="16px"
                  w={{ base: "50px", md: "109px" }}
                  borderRadius="30px"
                  mb="0px"
                  mr="0px"
                />
              </Flex>
            </Box>

            <Flex w="30%">
              <Skeleton w="110px" h="21px" borderRadius="2px" />
              <Skeleton w="110px" h="21px" borderRadius="2px" />
            </Flex>

            <Flex
              w="20%"
              display={{ base: "none", xl: "flex" }}
              justifyContent="end"
            >
              <Skeleton w="180px" h="48px" />
            </Flex>
          </Flex>
        ))}
      </Box>

      <Box w={{ base: "100%", md: "35%", xl: "25%" }}>
        <Box
          backgroundColor="#24252A"
          borderRadius="8px"
          padding="25px 25px 15px 25px"
        >
          <Skeleton w="110px" />
          <Flex mt="10px" justifyContent="space-between">
            <Skeleton w="100px" />
            <Skeleton w="100px" />
          </Flex>
          <Divider mt="20px" borderColor="rgba(250, 250, 250, 0.04)" />
          <Skeleton w="110px" mt="25px" />
          <Flex mt="10px" justifyContent="space-between">
            <Skeleton w="100px" />
            <Skeleton w="100px" />
          </Flex>
          <Skeleton w="80px" h="22px" mt="15px" mb="15px" />
          <Divider mb="10px" borderColor="rgba(250, 250, 250, 0.04)" />
          <Flex mt="10px" justifyContent="center">
            <Skeleton w="130px" />
          </Flex>
        </Box>
        <Skeleton w="100%" h="48px" mt="35px" />
        <Skeleton w="100%" h="48px" mt="20px" />
        <Flex justifyContent="center" mt="20px">
          <Skeleton w="190px" h="22px" />
        </Flex>
      </Box>
    </Flex>
  );
}
