import { Button, Text } from "@chakra-ui/react";
import PriceButton from "app/assets/icons/priceButton";
import { transaction } from "app/models";
import { selectUserAdmin } from "app/redux/selectors/users";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "./styles.scss";

interface PriceProps {
  isMyTrack?: boolean;
  transactionItems?: transaction;
}

export default function PriceDownloadButton({
  isMyTrack = false,
  transactionItems,
}: PriceProps) {
  const { t } = useTranslation();
  const userAdmin = useSelector(selectUserAdmin);
  const isReceive = userAdmin._id === transactionItems?.sellerId._id;

  return (
    <Button
      rightIcon={<PriceButton isMyTrack={isMyTrack} isReceive={isReceive} />}
      backgroundColor="#2E2E31"
      _hover={{ backgroundColor: "none" }}
      _active={{ backgroundColor: "none" }}
      borderRadius="34px"
      padding="0px 2px 0px 10px"
      height="36px"
      fontSize="14px"
    >
      {transactionItems?.price && transactionItems?.price > 0 ? (
        <Text fontSize="14px">
          {isReceive ? "+" : "-"} {"$"}
          {isReceive
            ? Number(transactionItems?.sellerWillReceive)?.toFixed(2)
            : Number(transactionItems?.buyerWillPay)?.toFixed(2)}
        </Text>
      ) : (
        <Text fontSize="14px">{t("Free")}</Text>
      )}
    </Button>
  );
}
