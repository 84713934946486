import React from "react";

const IconFireHotProducer = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.125 7.49875C7.125 6.9051 6.33542 6.74775 6.12594 7.30321C5.61921 8.64685 5.25 9.84884 5.25 10.4987C5.25 12.5698 6.92893 14.2487 9 14.2487C11.0711 14.2487 12.75 12.5698 12.75 10.4987C12.75 9.80052 12.3238 8.46503 11.7588 7.00127C11.0269 5.10513 10.661 4.15705 10.2092 4.10599C10.0647 4.08965 9.90701 4.11903 9.77804 4.18632C9.375 4.39663 9.375 5.43067 9.375 7.49875C9.375 8.12007 8.87132 8.62375 8.25 8.62375C7.62868 8.62375 7.125 8.12007 7.125 7.49875Z"
        stroke="#D4F70E"
      />
      <circle cx="9" cy="9" r="8" fill="#D4F70E" fill-opacity="0.21" />
    </svg>
  );
};
export default IconFireHotProducer;
