import { Box, Text } from "@chakra-ui/react";
import React from "react";
import EmptyPlaylistIcon from "app/assets/icons/EmptyPlaylistIcon";
import { useTranslation } from "react-i18next";

interface EmptyPlaylistProps {
  height?: string;
  isPlaylistIndividual?: boolean;
}

export default function EmptyPlaylist({
  height = "50vh",
  isPlaylistIndividual = false,
}: EmptyPlaylistProps) {
  const { t } = useTranslation();
  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <EmptyPlaylistIcon />
      <Text m="5px 0px 0px 0px" fontSize="20px" fontWeight="500">
        {isPlaylistIndividual ? t("noTracks") : t("noPlaylists")}
      </Text>
      <Text fontSize="16px" fontWeight="400" color="#535353">
        {isPlaylistIndividual
          ? t("thereAreNoTracksAvailable")
          : t("thereAreNoPlaylistAvailable")}
      </Text>
    </Box>
  );
}
