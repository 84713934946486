import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import { TrackSoundKit } from "app/models";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import RedirectPage from "./redirectPage";

interface RecommendedHomePageProps {
  lengthItem: number;
  item: TrackSoundKit;
}

export default function RecommendedHomePage({
  lengthItem,
  item,
}: RecommendedHomePageProps) {
  const { t } = useTranslation();
  const { redirectPage, pageProfile } = RedirectPage();
  const {
    handlePlayOrPause,
    handleTogglePlayPause,
    playingTrackId,
    isPlaying,
    isLoading,
    handlePlayPlaylists,
  } = usePlayers();

  const onClickToTrack = useCallback(async () => {
    if (!item?.mergeAudio && !item?.audioFileUrl) {
      return;
    }
    if (playingTrackId !== item?._id) {
      handlePlayOrPause(item);
      handlePlayPlaylists([]);
    } else {
      handleTogglePlayPause();
    }
  }, [
    handlePlayOrPause,
    handlePlayPlaylists,
    handleTogglePlayPause,
    item,
    playingTrackId,
  ]);

  return (
    <Box>
      <Box
        padding="0px 10px"
        display="flex"
        flexDirection="column"
        alignItems="center"
        mr={lengthItem < 3 ? "30px" : "0px"}
      >
        <Flex position="relative" role="group" onClick={onClickToTrack}>
          <Image
            cursor="pointer"
            w={{ base: "180px", md: "272px" }}
            height={{ base: "180px", md: "272px" }}
            borderRadius="8px"
            src={item.coverUrl}
            fallbackSrc={ImageDefault}
            _groupHover={{
              opacity: "0.2",
            }}
          />
          <Flex
            display={"none"}
            _groupHover={{
              display: "flex",
            }}
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
          >
            <PlayButton
              size={60}
              isPlaying={playingTrackId === item?._id && isPlaying}
              isLoading={isLoading}
            />
          </Flex>
        </Flex>
        <Text
          m={{ base: "10px 0px 5px 0px", md: "20px 0px 10px 0px" }}
          fontSize={{ base: "18px", md: "24px" }}
          fontWeight="600"
          lineHeight="32px"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
          w={{ base: "170px", md: "300px" }}
          textAlign="center"
          _hover={{
            textDecor: "underline",
          }}
          cursor="pointer"
          fontFamily="Cera Pro Regular"
          onClick={() => redirectPage(`track/${item?.slug}`)}
        >
          {item.title}
        </Text>
        <Box display="flex" alignItems="center" overflow="hidden">
          <Text
            fontSize={{ base: "14px", md: "16px" }}
            lineHeight={{ base: "14px", md: "16px" }}
            color="#d4f70e"
            padding="2px 5px"
            borderRadius="4px"
            mr="10px"
            fontFamily="Cera Pro Regular"
            backgroundColor="#2b2e1b"
          >
            {t(TRACK_TYPE[item?.type])}
          </Text>
          <Text
            fontSize={{ base: "14px", md: "16px" }}
            fontWeight="400"
            lineHeight={{ base: "14px", md: "16px" }}
            color="#5B5C5F"
            cursor="pointer"
            fontFamily="Cera Pro Regular"
            _hover={{
              textDecor: "underline",
            }}
            onClick={() => redirectPage(pageProfile(item?.createdBy?.name))}
          >
            {item?.createdBy?.name}
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
