import { Box, Flex, Text } from "@chakra-ui/react";
import { BackReturn } from "app/assets/icons";
import TabsMenuHeader from "app/components/AddTrack/TabHeader";
import AddSoundkitComponent from "app/components/AddSoundkitComponents";
import { useAddSoundkit } from "hooks/soundkit/useAddSoundkit";
import React, { useCallback, useMemo, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import NoUploadTrack from "app/components/AddTrack/noUploadTrack";
import "./styles.scss";
import { useDispatch } from "react-redux";
import { checkUploadSoundPacks } from "app/redux/actions/addSoundkit";
import { FadeLoader } from "react-spinners";

export default function AddSoundkit() {
  const { t } = useTranslation();
  const {
    methods,
    tabsMenu,
    isLoadingCheckUploadSoundPack,
    isUploadSoundPacks,
    ...rest
  } = useAddSoundkit();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const isEditTrackSoundkit = queryString.parse(location.search);

  const isEdit = useMemo(() => {
    return isEditTrackSoundkit.type === "isEdit";
  }, [isEditTrackSoundkit.type]);

  const handleBackMySoundkit = useCallback(() => {
    history.push({
      pathname: "/dashboard/my-soundkits",
    });
  }, [history]);

  useEffect(() => {
    dispatch(checkUploadSoundPacks());
  }, [dispatch]);

  return (
    <Box
      className="add-soundkit"
      p={{
        base: "60px 16px",
        md: "60px 20px",
        lg: "60px 30px",
        xl: "60px 80px 100px 80px",
      }}
      position="relative"
    >
      <Flex alignItems="center">
        <Box onClick={handleBackMySoundkit} cursor="pointer">
          <BackReturn />
        </Box>
        <Text
          fontSize={{ base: "24px", md: "32px" }}
          ml="15px"
          lineHeight="24px"
        >
          {isEditTrackSoundkit.type === "isEdit"
            ? t("editSoundkit")
            : t("addSoundkit")}
        </Text>
      </Flex>
      {isLoadingCheckUploadSoundPack ? (
        <Flex justifyContent="center" height="50px" mt="40px">
          <FadeLoader
            color={"#d4f70e"}
            loading={isLoadingCheckUploadSoundPack}
          />
        </Flex>
      ) : isEdit || isUploadSoundPacks ? (
        <FormProvider {...methods}>
          <Box
            mt="32px"
            className="tabs-panels"
            w={{ base: "100%", md: "65%", lg: "72%", xl: "77%" }}
          >
            <Box display={{ base: "none", lg: "block" }}>
              <TabsMenuHeader tabsMenu={tabsMenu} />
            </Box>
            <AddSoundkitComponent isEdit={isEdit} {...rest} />
          </Box>
        </FormProvider>
      ) : (
        <NoUploadTrack text={t("limitUploadSoundPacks")} />
      )}
    </Box>
  );
}
