import { Box, Button, Flex, Text, Image, Divider } from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import GoogleSignup from "app/assets/icons/googleSignup";
import { createUserGoogle, refLinkClickedCount } from "app/redux/actions/users";
import { useSignup } from "hooks/signup/useSignup";
import useRunOnce from "hooks/useRunOnce";
import React, { memo, useCallback, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Logo from "app/assets/images/logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import FormSignUp from "./formSignup";
import "./styles.scss";
import GifSuperproductorSignup from "app/assets/images/ezgif.com-signup.gif";
import SectionDivider from "app/components/SectionDivider";
import AuthText from "app/components/AuthText";

export const Signup = memo(() => {
  const { t } = useTranslation();

  const { stepSignup, handleStepForm, methods } = useSignup();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location?.search) {
      localStorage.setItem("ref", location?.search);
    }
  }, [location?.search]);

  const refBy = localStorage.getItem("ref")?.split("=")?.[1];

  useRunOnce({
    fn: () => {
      const currentRefBy = location.search?.split("=")?.[1];
      if (currentRefBy || refBy) {
        dispatch(
          refLinkClickedCount(decodeURIComponent(currentRefBy || refBy))
        );
      }
    },
    sessionKey: "test",
  });

  const redirectLogin = useCallback(() => {
    history.push("/auth/login");
  }, [history]);

  const responseGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (credentialResponse) => {
      dispatch(
        createUserGoogle(
          {
            idToken: credentialResponse?.code,
            refBy: decodeURIComponent(refBy || ""),
          },
          redirectLogin
        )
      );
    },
  });

  return (
    <Flex
      w="100%"
      className="page-signup"
      maxW={{ base: "100%", lg: "1440px" }}
      px={{ lg: "100px" }}
    >
      <Box
        className="w-signup"
        backgroundColor="#111518"
        w={{ base: "100%", md: "50%" }}
        p={{ base: "16px", md: "32px", lg: "50px 60px" }}
      >
        <Box className="mg-center">
          <Flex justifyContent={{ base: "center", md: "start" }}>
            <Image src={Logo} w="214px" h="24px" />
          </Flex>
          <Divider
            borderColor="#292929"
            m="20px 0px"
            display={{ base: "block", md: "none" }}
          />

          {stepSignup === 0 && (
            <>
              <Box
                mt={{ base: "12px", md: "16px", lg: "20px" }}
                mb={{ base: "22px", md: "26px", lg: "30px" }}
              >
                <AuthText
                  title={t("signUp")}
                  description={t("seeYourSupport")}
                />
              </Box>
            </>
          )}
          {stepSignup === 1 && (
            <>
              <Text
                fontSize="30px"
                fontWeight="600"
                lineHeight="40px"
                mt="20px"
                mb="10px"
              >
                {t("whoAreYou")}
              </Text>
              <Text mb="30px">{t("tellUsAboutYour")}</Text>
            </>
          )}
          {stepSignup === 0 && (
            <>
              <Flex w="100%">
                <Button
                  borderRadius="4px"
                  backgroundColor="#181C1E"
                  _hover={{ background: "none" }}
                  border="1px solid #2D2D2D"
                  size="lg"
                  w="100%"
                  onClick={() => responseGoogle()}
                >
                  <GoogleSignup />
                </Button>
              </Flex>
              <SectionDivider />
            </>
          )}

          <FormProvider {...methods}>
            <FormSignUp
              stepSignup={stepSignup}
              handleStepForm={handleStepForm}
            />
          </FormProvider>
        </Box>
      </Box>

      <Flex
        w="50%"
        justifyContent="center"
        alignItems="center"
        m="auto"
        display={{ base: "none", md: "block" }}
      >
        <Image width="100%" height="100vh" src={GifSuperproductorSignup} />
      </Flex>
    </Flex>
  );
});
