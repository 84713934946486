import client from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class SubscriptionsService {
  static async loadAllSubscriptions() {
    try {
      const res = await client.get("/subscriptions");
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async cancelSubscription() {
    try {
      const res = await client.post("/subscriptions/cancel-subscription");
      return res;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async createSubscriptions(plan: string) {
    try {
      const res = await client.post(
        `/subscriptions/generate-checkout-new-url/${plan}`
      );

      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async checkCreateNewLicense() {
    try {
      const res = await client.get("/subscriptions/check-create-license");
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async sendEmailReferral(email: string) {
    try {
      const res = await client.post("/shortio/send-referral", { email: email });
      return res?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default SubscriptionsService;
