import {
  Box,
  Flex,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { PlayAnalyticsModel } from "app/models";
import { queryAnalyticsType } from "constants/analyticsType";
import React from "react";
interface PlayDownloadProps {
  data: PlayAnalyticsModel[];
  setAnalyticsType: (value: PlayAnalyticsModel) => void;
  selectAnalyticsType: PlayAnalyticsModel;
}

export default function PlayDownloadComponents({
  data,
  setAnalyticsType,
  selectAnalyticsType,
}: PlayDownloadProps) {
  const marginLeft =
    useBreakpointValue({ base: "0px", md: "10px", xl: "20px" }) || "20px";
  return (
    <SimpleGrid
      columns={5}
      spacing="5px"
      width={{ base: "max-content", lg: "100%" }}
    >
      {(data || []).map((item: PlayAnalyticsModel, index: number) => {
        return (
          <Box
            ml={index !== 0 ? marginLeft : "0px"}
            key={index}
            onClick={() => setAnalyticsType(item)}
          >
            <Flex
              alignItems="center"
              mt={{ base: "10px", md: "20px", xl: "30px" }}
              p={{ base: "12px", md: "20px" }}
              borderRadius="8px"
              backgroundColor={
                item.name === selectAnalyticsType.name ? "#21231B" : "#1c1c20"
              }
              cursor="pointer"
              _hover={{
                border: "1px solid #38383D",
                background: "#222227 !important",
                borderRadius: "6px",
              }}
              borderColor="#18181c"
              borderWidth="1px"
            >
              {item.icon}
              <Box ml="15px">
                <Text
                  fontSize="14px"
                  color="white"
                  opacity={0.4}
                  className="fontCeraProRegular"
                >
                  {item.name}
                </Text>
                <Text fontSize={{ base: "20px", md: "22px", xl: "24px" }}>
                  {`${item.type === queryAnalyticsType.Sales ? "$" : ""} ${
                    item.price
                  }`}
                </Text>
              </Box>
            </Flex>
          </Box>
        );
      })}
    </SimpleGrid>
  );
}
