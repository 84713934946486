import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { toast } from "react-toastify";
import "./styles.scss";
import IconWarningBulkTrack from "app/assets/icons/IconWarningUploadBulkTrack";

function ToastMessage(props) {
  const { data } = props;
  return (
    <Box>
      <Text
        color="#D4F70E"
        fontSize="16px"
        lineHeight="24px"
        fontWeight="700"
        fontFamily="Cera Pro Medium"
      >
        {data.title}
      </Text>
      <Text
        color="white"
        fontSize="14px"
        lineHeight="24px"
        fontWeight="400"
        fontFamily="Cera Pro Regular"
      >
        {data.description}
      </Text>
      {(data?.cancelButtonText || data?.submitButtonText) && (
        <Flex gap="20px" mt="10px">
          <Text
            borderRadius="6px"
            padding="10px"
            cursor="pointer"
            fontSize="16px"
            color="white"
            onClick={data.onClickCancelButton}
            _hover={{ border: "1px solid #D4F70E", color: "#D4F70E" }}
          >
            {data.cancelButtonText}
          </Text>
          <Text
            borderRadius="6px"
            padding="10px"
            fontSize="16px"
            cursor="pointer"
            color="white"
            onClick={data.onClickButton}
            _hover={{ border: "1px solid #D4F70E", color: "#D4F70E" }}
          >
            {data.submitButtonText}
          </Text>
        </Flex>
      )}
    </Box>
  );
}

export default function ToastResumeUploading({
  title,
  description,
  isError = true,
  cancelButtonText,
  submitButtonText,
  onClickButton,
  onClickCancelButton,
}: {
  title?: string;
  description?: string;
  isError?: boolean;
  cancelButtonText?: string;
  submitButtonText?: string;
  onClickCancelButton?: Function;
  onClickButton?: Function;
}) {
  return toast[isError ? "error" : "success"](<ToastMessage />, {
    data: {
      title: title,
      description: description ? description : "Internal Server Error",
      cancelButtonText: cancelButtonText,
      submitButtonText: submitButtonText,
      onClickButton: onClickButton,
      onClickCancelButton: onClickCancelButton,
    },
    className: "toast-resume-uploading",
    position: "top-center",
    autoClose: 5000,
    icon: <IconWarningBulkTrack />,
  });
}
