import React, { useCallback, useMemo, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  Image,
  CloseButton,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  ClearRefinements,
  useRange,
  useRefinementList,
} from "react-instantsearch";
import "./styles.scss";
import { TYPE_PARAMS } from "utils/actionText";
import countryList from "react-select-country-list";
import { alphabeticallyItems } from "constants/alphabeticallyItems";
import { TYPE_FILTER_SORT } from "constants/contractType";
import IconGenresFilter from "app/assets/images/IconGenreFilter.png";
import IconPriceFilter from "app/assets/images/IconPriceFilter.png";
import IconSubGenresFilter from "app/assets/images/IconSubGenresFilter.png";
import IconMoodsFilter from "app/assets/icons/IconMoodsFilter";
import MusicHomePage from "app/assets/icons/MusicHomePage";
import IconTypeFilter from "app/assets/icons/IconTypeFilter";
import IconContractTypeFilter from "app/assets/icons/IconContractTypeFilter";
import IconLocationFilter from "app/assets/images/IconLocationFilter.png";
import IconTagFilter from "app/assets/images/IconTagFilter.png";
import { capitalizeTheFirst } from "utils/convertTracks";

const CustomRefinementList = (props) => {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  const fullNameCountry = useCallback(
    (label) => options.filter((item) => item.value === label),
    [options]
  );
  const { items, refine, isShowingMore, toggleShowMore, canToggleShowMore } =
    useRefinementList({
      attribute: props?.options,
      showMore: true,
    });

  const convertNameByAlphabeticalOrder = alphabeticallyItems(items);
  return (
    <Box>
      {convertNameByAlphabeticalOrder.map((item, index) => {
        return (
          <Box key={index}>
            <Flex>
              <Checkbox
                isChecked={item.isRefined}
                onChange={() => refine(item.value)}
                fontSize="20px"
              >
                {props.name === t("tyOfContract")
                  ? item.label === "false"
                    ? t("nonExclusive")
                    : t("Exclusive")
                  : props.options === TYPE_FILTER_SORT.COUNTRY
                  ? fullNameCountry(item?.label)?.[0]?.label
                  : capitalizeTheFirst(item?.label)}
              </Checkbox>
            </Flex>
          </Box>
        );
      })}
      {canToggleShowMore && (
        <Button
          background="rgba(212, 247, 14, 0.04)"
          fontSize={{ base: "14px", md: "16px" }}
          onClick={toggleShowMore}
          _hover={{
            background: "rgba(212, 247, 14, 0.04)",
          }}
          _focus={{
            boxShadow: "none",
          }}
          color="#D4F70E"
        >
          {isShowingMore ? t("showLess") : t("showMore")}
        </Button>
      )}
    </Box>
  );
};

const CustomRangeInput = (typeParams: any) => {
  const { t } = useTranslation();

  const { range, refine } = useRange({
    attribute: `${
      typeParams === "tracks" || typeParams === "tags"
        ? "priceSearch"
        : "basicPrice"
    }`,
  });
  const [minMaxPrice, setMinMaxPrice] = useState([range.min, range.max]);
  const onRangeSubmit = useCallback(
    (value: number[]) => {
      setMinMaxPrice(value);
      refine([
        Number.isFinite(value[0]) ? value[0] : 1,
        Number.isFinite(value[1]) ? value[1] : undefined,
      ]);
    },
    [refine]
  );

  return (
    <Box>
      <Flex justifyContent="space-between">
        {minMaxPrice.map((item, index) => (
          <Flex key={index}>
            <Text fontSize="20px" fontWeight="500" color="#D4F70E">
              {item}
            </Text>
            <Text fontSize="20px" fontWeight="500" color="#67676A" ml="5px">
              {t("price")}
            </Text>
          </Flex>
        ))}
      </Flex>
      <RangeSlider
        aria-label={["min", "max"]}
        defaultValue={[Number(range.min), Number(range.max)]}
        min={0}
        max={Number(range.max)}
        onChangeEnd={(value) => onRangeSubmit(value)}
      >
        <RangeSliderTrack background="#2A2B30" style={{ zoom: "1.1" }}>
          <RangeSliderFilledTrack background="#D4F70E" />
        </RangeSliderTrack>
        <RangeSliderThumb index={0} />
        <RangeSliderThumb index={1} />
      </RangeSlider>
    </Box>
  );
};

interface ModalFilterSearchProps {
  setShowModal: (value: boolean) => void;
  typeParams: any;
}

export default function ModalFilterSearch({
  setShowModal,
  typeParams,
}: ModalFilterSearchProps) {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState([]);
  const musiciansOptions = useMemo(
    () => [
      {
        name: t("location"),
        options: "country",
        icon: <Image fallbackSrc={IconLocationFilter} />,
      },
    ],
    [t]
  );

  const trackOptions = useMemo(
    () => [
      {
        name: t("genres"),
        options: "mainGenre",
        icon: <Image w="19px" h="14px" fallbackSrc={IconGenresFilter} />,
      },
      {
        name: t("subGenres"),
        options: "subGenre",
        icon: <Image w="19px" h="18spx" fallbackSrc={IconSubGenresFilter} />,
      },
      {
        name: t("moods"),
        options: "primaryMood",
        icon: <IconMoodsFilter />,
      },
      {
        name: t("trackType"),
        options: "type",
        icon: <IconTypeFilter />,
      },
      {
        name: t("key"),
        options: "key",
        icon: <MusicHomePage />,
      },
      {
        name: t("tyOfContract"),
        options: "licenses.exclusive",
        icon: <IconContractTypeFilter />,
      },
    ],
    [t]
  );
  const soundPacksOptions = useMemo(
    () => [
      {
        name: t("genres"),
        options: "mainGenre",
        icon: <Image w="19px" h="14px" fallbackSrc={IconGenresFilter} />,
      },
      {
        name: t("subGenres"),
        options: "subGenre",
        icon: <Image w="19px" h="18spx" fallbackSrc={IconSubGenresFilter} />,
      },
      {
        name: t("soundKitType"),
        options: "type",
        icon: <IconTypeFilter />,
      },
    ],
    [t]
  );
  const playlistOptions = useMemo(
    () => [
      {
        name: t("tags"),
        options: "tags",
        icon: <Image fallbackSrc={IconTagFilter} w="13px" h="21px" />,
      },
    ],
    [t]
  );

  const filterOptions = useMemo(() => {
    switch (typeParams) {
      case TYPE_PARAMS.tracks:
      case TYPE_PARAMS.tags:
        return trackOptions;
      case TYPE_PARAMS.musicians:
        return musiciansOptions;
      case TYPE_PARAMS.soundKits:
        return soundPacksOptions;
      case TYPE_PARAMS.playlists:
        return playlistOptions;
      default:
        break;
    }
  }, [
    musiciansOptions,
    playlistOptions,
    soundPacksOptions,
    trackOptions,
    typeParams,
  ]);

  return (
    <Box
      className="filter-search-algolia"
      width="100%"
      height="100%"
      position="fixed"
      left="0"
      top="0"
      background="#18181c"
      zIndex={999999999}
    >
      <Box
        padding={{ base: "10px", md: "10px", xl: "20px 25px" }}
        borderRadius="8px"
        className="filter-search-header"
      >
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => {
            setShowModal(false);
            setExpandedIndex([]);
          }}
          fontSize="20px"
          color="#d4f70e"
          padding="20px"
          display="flex"
          justifyContent="flex-end"
          w="100%"
        />
        <Flex
          mb={{ base: "10px", md: "15px", xl: "20px" }}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px="16px"
          pt="4px"
          pb="10px"
        >
          <Text
            fontSize={{ base: "18px", md: "24px" }}
            lineHeight="29px"
            fontWeight="700"
            color="#D8FD01"
          >
            {t("filter")}
          </Text>
          <ClearRefinements
            translations={{
              resetButtonText: t("clear"),
            }}
          />
        </Flex>

        <Box overflow="scroll" h="100vh">
          <Accordion
            allowToggle={true}
            allowMultiple
            index={expandedIndex}
            onChange={(index) => setExpandedIndex(index)}
          >
            {(filterOptions || []).map((item) => {
              return (
                <AccordionItem
                  maxHeight="max-content"
                  overflow="scroll"
                  key={item.name}
                >
                  <AccordionButton _focus={{ boxShadow: "none" }}>
                    <Box
                      display="flex"
                      alignItems="center"
                      flex="1"
                      padding={{ base: "10px 0px", md: "20px 0px" }}
                      justifyContent="space-between"
                    >
                      <Flex
                        alignItems="center"
                        gap={{ base: "8px", md: "16px", xl: "24px" }}
                      >
                        <Box>{item.icon}</Box>
                        <Text
                          fontSize={{
                            base: "16px",
                            md: "18px",
                            xl: "20px",
                          }}
                          fontWeight="600"
                        >
                          {item.name}
                        </Text>
                      </Flex>

                      <AccordionIcon />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <CustomRefinementList {...item} />
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
            {typeParams !== TYPE_PARAMS.musicians &&
              typeParams !== TYPE_PARAMS.playlists && (
                <AccordionItem maxHeight="400px" overflow="scroll">
                  <AccordionButton _focus={{ boxShadow: "none" }}>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      flex="1"
                      padding={{ base: "10px 0px", md: "20px 0px" }}
                    >
                      <Flex
                        alignItems="center"
                        gap={{ base: "8px", md: "16px", xl: "24px" }}
                      >
                        <Box>
                          <Image
                            fallbackSrc={IconPriceFilter}
                            w="16px"
                            h="20px"
                          />
                        </Box>
                        <Text
                          fontSize={{ base: "16px", md: "20px" }}
                          fontWeight="600"
                        >
                          {t("price")}
                        </Text>
                      </Flex>

                      <AccordionIcon />
                    </Box>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <CustomRangeInput typeParams={typeParams} />
                  </AccordionPanel>
                </AccordionItem>
              )}
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
}
