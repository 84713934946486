import { Box, Flex, Text } from "@chakra-ui/react";
import { Genre, SubGenre } from "app/models";
import React from "react";

interface ItemInterestPros {
  interest: Genre | SubGenre;
  indexType: number;
  handleChooseInterest: any;
}

export default function ItemInterest({
  interest,
  indexType,
  handleChooseInterest,
}: ItemInterestPros) {
  const borderColor = (pillType: number) => {
    const border: {
      [index: number]: {
        borderTopLeftRadius?: string;
        borderTopRightRadius?: string;
        borderBottomRightRadius?: string;
        borderBottomLeftRadius?: string;
      };
    } = {
      0: { borderTopRightRadius: "40px" },
      1: { borderTopLeftRadius: "40px", borderTopRightRadius: "40px" },
      2: { borderTopLeftRadius: "40px", borderBottomRightRadius: "40px" },
      3: { borderBottomLeftRadius: "40px", borderBottomRightRadius: "40px" },
      4: { borderTopRightRadius: "40px", borderBottomLeftRadius: "40px" },
    };

    return border[pillType];
  };

  return (
    <Flex>
      <Box
        minW={{ base: "135px", xl: "155px" }}
        height={{ base: "45px", xl: "55px" }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        border={{ base: "4px solid #C8E815", xl: "6px solid #C8E815" }}
        borderTopLeftRadius={borderColor(indexType).borderTopLeftRadius}
        borderTopRightRadius={borderColor(indexType).borderTopRightRadius}
        borderBottomLeftRadius={borderColor(indexType).borderBottomLeftRadius}
        borderBottomRightRadius={borderColor(indexType).borderBottomRightRadius}
        padding="0px 15px"
        margin="5px"
        onClick={handleChooseInterest}
        cursor="pointer"
        background={interest.selected ? "#C8E815" : "null"}
        color={interest.selected ? "black" : "white"}
      >
        <Text
          fontSize="14px"
          lineHeight={{ base: "14px", xl: "17px" }}
          fontWeight="500"
        >
          {interest.label}
        </Text>
      </Box>
    </Flex>
  );
}
