export const normalizeDraftData = <T>(data: T) => {
  const parseData = JSON.parse(JSON.stringify(data));

  Object.keys(data).forEach((dataProps) => {
    if (parseData[dataProps] === "") {
      delete parseData[dataProps];
    }
  });
  return parseData;
};
