import React from "react";

const IconPlaylistIndividualMobile = () => {
  return (
    <svg
      width="92"
      height="71"
      viewBox="0 0 92 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M89 68.9234H3C1.89543 68.9234 1 68.028 1 66.9234V20.7433C1 19.6387 1.89543 18.7433 3 18.7433H47.0976C47.8875 18.7433 48.6034 18.2783 48.9247 17.5567L50.6374 13.71C50.9587 12.9884 51.6746 12.5234 52.4645 12.5234H71.0828H89C90.1046 12.5234 91 13.4189 91 14.5234V66.9234C91 68.028 90.1046 68.9234 89 68.9234Z"
        fill="url(#paint0_linear_15506_40252)"
        stroke="url(#paint1_linear_15506_40252)"
        stroke-width="0.5"
      />
      <path
        opacity="0.75"
        d="M89 70.1862H3C1.75735 70.1862 0.75 69.1788 0.75 67.9362V3C0.75 1.75736 1.75735 0.75 3 0.75H39.8237C40.6385 0.75 41.3897 1.1906 41.7875 1.90183L43.4116 4.80621C43.721 5.35939 44.3052 5.70208 44.939 5.70208H89C90.2426 5.70208 91.25 6.70944 91.25 7.95208V67.9362C91.25 69.1788 90.2426 70.1862 89 70.1862Z"
        stroke="url(#paint2_linear_15506_40252)"
        stroke-width="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_15506_40252"
          x1="46"
          y1="12.5234"
          x2="46"
          y2="68.9234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#535353" />
          <stop offset="0.166667" stop-color="#535353" />
          <stop offset="0.333333" stop-color="#535353" />
          <stop offset="0.5" stop-color="#535353" />
          <stop
            offset="0.666667"
            stop-color="#535353"
            stop-opacity="0.666941"
          />
          <stop
            offset="0.833333"
            stop-color="#535353"
            stop-opacity="0.454995"
          />
          <stop offset="1" stop-color="#535353" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_15506_40252"
          x1="46"
          y1="12.5234"
          x2="46"
          y2="68.9234"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#535353" />
          <stop offset="1" stop-color="#535353" stop-opacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_15506_40252"
          x1="46"
          y1="1"
          x2="46"
          y2="69.9362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#535353" />
          <stop offset="1" stop-color="#535353" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default IconPlaylistIndividualMobile;
