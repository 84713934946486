import React from "react";

const BasketEmpty = () => {
  return (
    <svg
      width="107"
      height="103"
      viewBox="0 0 107 103"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M106.407 41.7898C89.1927 78.248 83.5917 78.248 80.25 78.248H47.3269C35.7956 78.248 26.9599 71.4658 24.8158 60.9634C24.7499 60.6424 18.2312 28.6452 16.5644 19.7066C15.7455 15.3237 13.6631 12.4018 6.17308 12.4018C2.76142 12.4018 0 9.64039 0 6.22874C0 2.81709 2.76142 0.0556641 6.17308 0.0556641C18.6015 0.0556641 26.6018 6.22874 28.7007 17.439C30.351 26.283 36.8492 58.1731 36.915 58.4982C38.2278 64.9429 44.0017 65.9059 47.3269 65.9059H78.6244C81.9867 62.2926 89.6495 48.3703 95.2423 36.518C96.6992 33.4355 100.382 32.1227 103.461 33.5714C106.547 35.0241 107.864 38.7032 106.407 41.7898ZM43.2115 82.3633C37.5282 82.3633 32.9231 86.9685 32.9231 92.6518C32.9231 98.3352 37.5282 102.94 43.2115 102.94C48.8949 102.94 53.5 98.3352 53.5 92.6518C53.5 86.9685 48.8949 82.3633 43.2115 82.3633ZM80.25 82.3633C74.5667 82.3633 69.9615 86.9685 69.9615 92.6518C69.9615 98.3352 74.5667 102.94 80.25 102.94C85.9333 102.94 90.5385 98.3352 90.5385 92.6518C90.5385 86.9685 85.9333 82.3633 80.25 82.3633ZM60.3439 48.7983C60.7554 49.1316 61.2616 49.2921 61.7678 49.2921C62.274 49.2921 62.772 49.1316 63.1917 48.7983C63.6033 48.4732 74.83 37.5221 80.9537 28.4107C81.427 27.7028 81.4723 26.7974 81.0689 26.0484C80.678 25.3035 79.8961 24.8344 79.0524 24.8344H69.8916C69.8916 24.8344 68.9985 7.17528 68.4141 6.35632C67.5664 5.14639 64.0642 4.32332 61.7637 4.32332C59.455 4.32332 55.891 5.14639 55.0392 6.35632C54.4589 7.17116 53.6893 24.8344 53.6893 24.8344H44.4091C43.5613 24.8344 42.7835 25.3035 42.3843 26.0484C41.9851 26.7974 42.0304 27.7069 42.4996 28.4107C48.6274 37.5262 59.9365 48.4732 60.3439 48.7983Z"
        fill="#282829"
      />
    </svg>
  );
};
export default BasketEmpty;
