import { Box, Flex, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import SkeletonTrackItem from "app/components/Skeleton/skeletonTrackItem";

export default function SkeletonExplore() {
  return (
    <Box mt={{ base: "40px", xl: "98px" }}>
      <Skeleton w={{ base: "120px", xl: "150px" }} h="23px" mb="35px" />
      <Flex gap={{ base: "16px", xl: "10px" }} wrap="wrap" mb="30px">
        <Skeleton
          w="275px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="115px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="81px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="68px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="120px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="90px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="125px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="175px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="100px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
        <Skeleton
          w="90px"
          h={{ base: "28px", xl: "36px" }}
          borderRadius="5px"
        />
      </Flex>
      <Divider
        my={{ base: "16px", md: "24px", xl: "28px" }}
        borderColor="#2A2B30"
      />
      <SkeletonTrackItem />
    </Box>
  );
}
