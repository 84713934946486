import { Box, chakra, Flex, Text } from "@chakra-ui/react";
import Pause from "app/assets/icons/Pause";
import Play from "app/assets/icons/PlayButton";
import { AUDIO_SUPERPRODUCTOR } from "constants/index";
import { useAudio } from "hooks/useAudio";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatAudioDuration } from "utils/formatAudioDuration";
import "./styles.scss";

interface VoiceTagPreviewProps {
  audioUrl?: string;
  hasTitle?: boolean;
  tagDefaultId?: string;
}

export default function VoiceTagPreview({
  tagDefaultId = "audio-untag",
  audioUrl,
  hasTitle,
  ...restProps
}: VoiceTagPreviewProps) {
  const { t } = useTranslation();
  const {
    playing,
    duration,
    currentTime,
    toggleAudioPlay,
    seekTo,
    audio
  } = useAudio(audioUrl ? audioUrl : AUDIO_SUPERPRODUCTOR);

  useEffect(() => {
    return () => {
      audio.pause()
    };
  }, [audio])

  return (
    <Box>
      {hasTitle && (
        <Text color="#535353" mt="25px" fontFamily="Cera Pro Regular">
          {t("tagPreview")}
        </Text>
      )}
      <Flex
        mt="10px"
        background="#24252A"
        p="15px"
        cursor="pointer"
        borderRadius="8px"
        flex={1}
        flexDirection="row"
        alignItems="center"
        {...restProps}
      >
        <Box cursor="pointer" onClick={() => toggleAudioPlay(tagDefaultId)}>
          {playing ? <Pause /> : <Play />}
        </Box>
        <Flex ml="16px" flex={1} flexDirection="column">
          <Flex justifyContent="space-between" className='audio-play'>
            <Flex flex={1} flexDirection="column">
              {hasTitle && (
                <Text color="#D8FD01" fontSize="10px" lineHeight="13px">
                  {!audioUrl ? (
                    t("defaultAudioVoiceTag")
                  ) : (
                      <Fragment>
                        <chakra.span
                          color="white"
                          fontSize="10px"
                          lineHeight="13px"
                        >
                          {t("uploadFile")}
                        </chakra.span>
                        {t("uploadAudioVoiceTag")}
                      </Fragment>
                    )}
                </Text>
              )}
              <input
                id={tagDefaultId}
                onChange={seekTo}
                className={`progress-bar`}
                type="range"
                min="1"
                max="100"
                defaultValue="0"
                step="0.1"
              />
            </Flex>
          </Flex>
          <Flex justifyContent="space-between">
            <Text fontSize="10px" lineHeight="13px" opacity={0.2}>
              {formatAudioDuration(currentTime)}
            </Text>
            <Text fontSize="12px" lineHeight="13px" opacity={0.3}>
              {formatAudioDuration(duration)}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
}
