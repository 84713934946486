import { Box, Text, Flex } from "@chakra-ui/react";
import { AnalyticType } from "constants/analyticsType";

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import TopTrackItem from "./topTrackItem";
import TopUserItem from "./topUserItem";

export default function TopTrackComponent() {
  const { t } = useTranslation();
  const [selectOptionMost, setSelectOptionMost] = useState(
    AnalyticType.LISTENER
  );

  // const titleTopUser = useCallback(() => {
  //   const title = {
  //     [AnalyticType.LISTENER]: "listeners",
  //     [AnalyticType.DOWNLOADER]: "downloader",
  //     [AnalyticType.REPOSTER]: "reposter",
  //     [AnalyticType.BUYER]: "buyer",
  //   };

  //   return title[selectOptionMost];
  // }, [selectOptionMost]);

  return (
    <>
      <Flex flexWrap="wrap" background="#1C1C20" mt="25px">
        <Box mt={{ base: "20px", md: "40px" }} w={{ base: "100%", md: "50%" }}>
          <Text fontSize="20px" lineHeight="24px" ml="22px">
            {t("productorPerformance")}
          </Text>
          <TopTrackItem />
        </Box>
        <Box mt={{ base: "20px", md: "40px" }} w={{ base: "100%", md: "50%" }}>
          <Text fontSize="20px" lineHeight="24px" ml="22px">
            {t("consumerBeHaviour")}
          </Text>

          <TopUserItem
            selectOptionMost={selectOptionMost}
            setSelectOptionMost={setSelectOptionMost}
          />
        </Box>
      </Flex>
    </>
  );
}
