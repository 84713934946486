import React from "react";

interface Props  {
  isLoopAndSample?:boolean
}
const Plus = ({
  isLoopAndSample = false,

}: Props) => {
  return (
    <svg
      width={isLoopAndSample ? "10" : "12"}
      height={isLoopAndSample ? "10" : "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="5.31445"
        width="12"
        height="1.37143"
        rx="0.685714"
        fill="#D8FD01"
      />
      <rect
        x="5.31445"
        y="12"
        width="12"
        height="1.37143"
        rx="0.685714"
        transform="rotate(-90 5.31445 12)"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default Plus;
