import { yupResolver } from "@hookform/resolvers/yup";
import { Collaborator } from "app/models";
import { fetchCollaborators } from "app/redux/actions/tracks";
import { selectListCollaborators } from "app/redux/selectors/tracks";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import addCollaboratorSchema from "./addCollaboratorSchema";

export const useAddCollaborator = (
  newCollaboration?: Collaborator,
  isSoundkit?: boolean
) => {
  const dispatch = useDispatch();

  const methods = useForm({
    resolver: yupResolver(
      addCollaboratorSchema(
        newCollaboration?.profitShare,
        newCollaboration?.publishing,
        isSoundkit
      )
    ),
  });

  const currentUser = useSelector(selectUserAdmin);
  const collaborations = useSelector(selectListCollaborators);

  const getCollaborators = useCallback(() => {
    if (currentUser?._id) {
      dispatch(fetchCollaborators());
    }
  }, [currentUser?._id, dispatch]);

  return {
    methods,
    collaborations,
    getCollaborators,
  };
};
