import {
  Box,
  Flex,
  Divider,
  Image,
  useMediaQuery,
  SimpleGrid,
} from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";
import IconPlaylistIndividual from "app/assets/icons/IconPlaylistIndividual";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import IconPlaylistIndividualMobile from "app/assets/icons/IconPlaylistIndividualMobile";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";

export default function SkeletonIndividualPlaylistMobile() {
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const [isLessThan990] = useMediaQuery("(max-width: 990px)");
  return (
    <Flex w="100%" flexWrap="nowrap" display="block">
      <Box borderRadius="8px">
        <Box
          background="rgba(36, 37, 42, 0.3)"
          padding={{
            base: "20px 0px",
            md: "20px 40px",
            lg: "20px",
            xl: "20px 40px",
          }}
        >
          <Box
            display="flex"
            flexDirection={{ base: "row", lg: "column" }}
            alignItems="center"
            w="100%"
          >
            <Box>
              <Box>
                {isLessThan766 ? (
                  <IconPlaylistIndividualMobile />
                ) : (
                  <IconPlaylistIndividual />
                )}
              </Box>
              <Image
                fallbackSrc={ImageDefault}
                borderRadius="4px"
                objectFit="cover"
                w={{ base: "65px", md: "150px", lg: "180px" }}
                height={{ base: "65px", md: "150px", lg: "180px" }}
                mt={{ base: "-40px", md: "-130px" }}
                ml={{ base: "15px", md: "50px" }}
              />
            </Box>
            <Box ml="10px" w="100%">
              <Skeleton mr="none" background="#535353" w="130px" h="16px" />
              <Skeleton
                mr="none"
                background="#535353"
                w="180px"
                h="16px"
                mt="10px"
              />
              <Skeleton
                mr="none"
                background="#535353"
                w={"90%"}
                h="14px"
                mt="10px"
              />
              <Skeleton
                mr="none"
                background="#535353"
                w={"90%"}
                h="14px"
                mt="10px"
              />
            </Box>
          </Box>

          <Flex mt="20px" justifyContent="center" gap="30px">
            {Array.from({ length: 4 }).map(() => (
              <Skeleton background="#535353" h="40px" w="40px" />
            ))}
          </Flex>
          <Flex justifyContent="center">
            <Skeleton mt="30px" background="#535353" w="70%" h="48px" />
          </Flex>

          <Divider
            m="20px 0px 15px 0px"
            borderColor="rgba(255, 255, 255, 0.04)"
          />
        </Box>
      </Box>
      <Box w="100%">
        <Skeleton background="#535353" w="150px" h="30px" mt="10px" />
        <Box mt="20px">
          {Array.from({ length: 10 }).map((item, index) => (
            <Flex
              alignItems="center"
              mb={{ base: "12px", md: "30px" }}
              key={index}
            >
              <Flex w="50%" alignItems="center">
                <Box mr="12px">
                  <IconSkeletonMusic />
                </Box>
                <Box>
                  <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
                  <Flex>
                    <Skeleton h="16px" w="35px" mb="0px" />
                    <Skeleton
                      h="16px"
                      w="109px"
                      borderRadius="30px"
                      mb="0px"
                      mr="0px"
                    />
                  </Flex>
                </Box>
              </Flex>

              <Flex
                w="50%"
                justifyContent="end"
                display={{ base: "flex", md: "none" }}
              >
                <Flex alignItems="center" gap="8px" w="max-content">
                  <Skeleton w="32px" h="32px" borderRadius="full" />
                  <Skeleton w="10px" h="40px" />
                </Flex>
              </Flex>

              <SimpleGrid
                columns={2}
                display={{ base: "none", md: "grid" }}
                w={{ md: "25%" }}
              >
                <Skeleton w="80px" h="20px" borderRadius="6px" />
                <Skeleton w="80px" h="20px" borderRadius="6px" />
                <Skeleton w="80px" h="20px" borderRadius="6px" />
                <Skeleton w="80px" h="20px" borderRadius="6px" />
              </SimpleGrid>
              <Flex
                display={{ base: "none", md: "flex" }}
                position="relative"
                w="20%"
                justifyContent="flex-end"
              >
                <Skeleton h="32px" w="70px" borderRadius="20px" />
                <Skeleton
                  position="absolute"
                  right="5px"
                  top="4px"
                  h="24px"
                  w="24px"
                  borderRadius="full"
                  background="#24252A"
                />
              </Flex>
              <Flex
                w="6%"
                justifyContent="end"
                display={{ base: "none", md: "flex" }}
              >
                <Skeleton w="10px" h="32px" />
              </Flex>
            </Flex>
          ))}
        </Box>
      </Box>
    </Flex>
  );
}
