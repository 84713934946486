import { createSelector } from "@reduxjs/toolkit";
import {
  maxFreeTracks,
  promoCodeApplyCheck,
  sumTotalFeeService,
  sumTotalItems,
} from "utils/sumTotal";
import { RootState } from "types";
import { typeMusic } from "utils/actionText";
import { checkTypeTrackOrSoundPack } from "utils/convertTracks";

export const selectCartState = (state: RootState) => state.cart;

export const totalCartSelector = createSelector(
  selectCartState,
  ({ cart, subscription, licenses }) => {
    return (
      Number(sumTotalItems(cart, licenses)) +
      Number(sumTotalItems(subscription, []))
    ).toFixed(2);
  }
);

export const totalWillPaidCartSelector = createSelector(
  selectCartState,
  ({ cart, subscription, licenses }) => {
    if (subscription.length > 0) {
      return Number(sumTotalItems(subscription, [])).toFixed(2);
    }
    return Number(sumTotalItems(cart, licenses)).toFixed(2);
  }
);

export const shoppingCartSelector = createSelector(
  selectCartState,
  ({ cart }) => {
    return cart;
  }
);
export const loadingSelector = createSelector(
  selectCartState,
  ({ loading }) => {
    return loading;
  }
);

export const paymentTypeSelector = createSelector(
  selectCartState,
  ({ paymentType }) => paymentType
);
export const paymentMethodsSelector = createSelector(
  selectCartState,
  ({ paymentMethods }) => paymentMethods
);

export const paymentMethodSelector = createSelector(
  selectCartState,
  ({ paymentMethod }) => {
    return paymentMethod;
  }
);

export const selectedSubscriptionSelector = createSelector(
  selectCartState,
  ({ subscription }) => {
    return subscription;
  }
);

export const isInCartSelector = (item: any) =>
  createSelector(
    selectCartState,
    ({ cart }) =>
      cart?.findIndex((cartItem: any) => cartItem?._id === item?._id) !== -1
  );

export const cartLicensesSelector = createSelector(
  selectCartState,
  ({ licenses }) => licenses
);

export const licenseReview = (id: string) =>
  createSelector(selectCartState, ({ cart }) => {
    return cart.find((cartItem: any) => cartItem._id === id);
  });

export const lastCartSelector = createSelector(
  selectCartState,
  ({ lastCart }) => lastCart
);

export const lastSubscriptionSelector = createSelector(
  selectCartState,
  ({ lastSubscription }) => lastSubscription
);

export const lastLicensesSelector = createSelector(
  selectCartState,
  ({ lastLicenses }) => lastLicenses
);

export const cartErrorSelector = createSelector(
  selectCartState,
  ({ cartError }) => cartError
);

export const isSubscriptionSelector = createSelector(
  selectCartState,
  ({ subscription }) => subscription.length > 0
);

export const currentCartSelector = createSelector(
  selectCartState,
  ({ cart }) => {
    return cart;
  }
);

const trackPrice = (license, basicPrice) => {
  return Number(
    !license
      ? basicPrice
      : license?.exclusive
      ? license?.exclusiveLicensePrice
      : license?.trackPrice
      ? license?.trackPrice
      : license?.defaultTrackPrice
  );
};

export const totalDiscountCartSelector = createSelector(
  selectCartState,
  ({ cart, licenses, promoCode }) => {
    let discountPromocode = 0;
    const bulkOffers = maxFreeTracks(cart, licenses);

    const totalBulkOffersAndPromoCodeAmount = cart.reduce((acum, item) => {
      const currentLicence = licenses.find(
        (license) => license?.trackId === item?._id
      );
      const isApplyBulk = bulkOffers.some((bulk) =>
        (bulk?.usingLicenses || []).some(
          (license) => license?.trackId === item?._id && bulk.hasApplyBulkOffer
        )
      );

      const isApplyPromoCode = promoCodeApplyCheck(
        currentLicence,
        promoCode,
        item?._id,
        item.createdBy?._id
      );

      const isApplyPromoCodeSoundPacks =
        promoCode?.createdBy === item?.createdBy?._id &&
        !checkTypeTrackOrSoundPack(item) &&
        promoCode?.productType === typeMusic.SOUND_KIT;

      if (isApplyPromoCode || promoCode?.productType === typeMusic.SOUND_KIT) {
        discountPromocode = promoCode.percent;
      }
      if (isApplyBulk) {
        if (currentLicence) {
          const licensePrice = trackPrice(
            currentLicence?.license,
            item?.basicPrice
          );
          return acum + licensePrice;
        } else {
          const defaultPrice = trackPrice(item.licenses[0], item?.basicPrice);
          return acum + defaultPrice;
        }
      } else if (
        (isApplyPromoCode && currentLicence) ||
        isApplyPromoCodeSoundPacks
      ) {
        let promoCodePrice;
        if (currentLicence?.negotiation) {
          promoCodePrice = currentLicence?.negotiation?.offerPrice;
        } else {
          promoCodePrice = trackPrice(
            currentLicence?.license,
            item?.basicPrice
          );
        }

        return acum + (promoCodePrice * discountPromocode) / 100;
      } else {
        return acum + 0;
      }
    }, 0);

    return Number(totalBulkOffersAndPromoCodeAmount).toFixed(2);
  }
);

export const bulkOffersSelector = createSelector(
  selectCartState,
  ({ cart, licenses }) => {
    return maxFreeTracks(cart, licenses);
  }
);

export const selectValidatedPromoCode = createSelector(
  selectCartState,
  ({ validatedPromoCode }) => validatedPromoCode
);

export const selectInvalidCode = createSelector(
  selectCartState,
  ({ invalidCode }) => invalidCode
);

export const cartPromoCodeSelector = createSelector(
  selectCartState,
  ({ promoCode }) => promoCode
);

export const totalServiceSelector = createSelector(
  selectCartState,
  ({ cart, licenses }) => {
    return Number(sumTotalFeeService(cart, licenses)).toFixed(2);
  }
);
