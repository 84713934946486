import * as yup from "yup";

const formSchema = () =>
  yup.object().shape({
    name: yup.string().required("nameIsRequired"),
    firstName: yup.string().required("firstNameIsRequired"),
    lastName: yup.string().required("lastNameIsRequired"),
    proCountry: yup.lazy((value) => {
      switch (typeof value) {
        case "object":
          return yup.object();
        case "string":
          return yup.string();
        default:
          return yup.mixed();
      }
    }),
    publisherIpi: yup.string().when("proCountry", (proCountry, schema) => {
      if (proCountry) {
        return schema
          .required("proSocietyIsRequired")
          .min(7, "minIpi")
          .max(11, "maxIpi");
      }
    }),
  });

export default formSchema;
