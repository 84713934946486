import React from "react";

interface IconLyricsIndividualTrackProps {
  isLyric?: boolean
}

const IconLyricsIndividualTrack = ({ isLyric }: IconLyricsIndividualTrackProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34" fill="none">
      <g clip-path="url(#clip0_7014_43012)">
        <path d="M22.7929 14.227C22.7929 13.5126 22.2139 12.9336 21.4996 12.9336H8.17797C7.46365 12.9336 6.88461 13.5126 6.88461 14.227C6.88461 14.9413 7.46365 15.5203 8.17797 15.5203H21.4996C22.2139 15.5203 22.7929 14.9413 22.7929 14.227Z" fill={isLyric ? '#D4F70E' : "white"} />
        <path d="M8.17797 18.1074C7.46365 18.1074 6.88461 18.6865 6.88461 19.4008C6.88461 20.1151 7.46365 20.6941 8.17797 20.6941H16.2686C16.983 20.6941 17.562 20.1151 17.562 19.4008C17.562 18.6865 16.983 18.1074 16.2686 18.1074H8.17797Z" fill={isLyric ? '#D4F70E' : "white"} />
        <path d="M11.2173 30.5233H6.89171C5.4654 30.5233 4.305 29.3629 4.305 27.9366V5.17344C4.305 3.74712 5.4654 2.58672 6.89171 2.58672H22.7929C24.2192 2.58672 25.3796 3.74712 25.3796 5.17344V13.1276C25.3796 13.8419 25.9586 14.421 26.6729 14.421C27.3873 14.421 27.9663 13.8419 27.9663 13.1276V5.17344C27.9663 2.3208 25.6455 0 22.7929 0H6.89171C4.03908 0 1.71828 2.3208 1.71828 5.17344V27.9366C1.71828 30.7892 4.03908 33.11 6.89171 33.11H11.2173C11.9316 33.11 12.5107 32.531 12.5107 31.8166C12.5107 31.1023 11.9316 30.5233 11.2173 30.5233Z" fill={isLyric ? '#D4F70E' : "white"} />
        <path d="M30.2572 18.7256C28.7444 17.2128 26.2828 17.2127 24.771 18.7246L17.6702 25.8096C17.5194 25.9601 17.4082 26.1454 17.3462 26.3492L15.7998 31.4402C15.6627 31.8916 15.7822 32.3817 16.1116 32.7194C16.3584 32.9723 16.6936 33.1096 17.0374 33.1096C17.1525 33.1096 17.2686 33.0942 17.3826 33.0626L22.603 31.6165C22.8178 31.557 23.0136 31.443 23.1714 31.2855L30.2573 24.2129C31.7701 22.7001 31.7701 20.2386 30.2572 18.7256ZM21.5847 29.2145L18.9584 29.942L19.727 27.4116L24.5182 22.631L26.3476 24.4605L21.5847 29.2145ZM28.4291 22.3831L28.1785 22.6332L26.3494 20.804L26.5991 20.5548C27.1034 20.0505 27.9239 20.0505 28.4282 20.5548C28.9325 21.0591 28.9325 21.8796 28.4291 22.3831Z" fill={isLyric ? '#D4F70E' : "white"} />
        <path d="M21.4996 7.75977H8.17797C7.46365 7.75977 6.88461 8.3388 6.88461 9.05313C6.88461 9.76745 7.46365 10.3465 8.17797 10.3465H21.4996C22.2139 10.3465 22.7929 9.76745 22.7929 9.05313C22.7929 8.3388 22.2139 7.75977 21.4996 7.75977Z" fill={isLyric ? '#D4F70E' : "white"} />
      </g>
      <defs>
        <clipPath id="clip0_7014_43012">
          <rect width="33.11" height="33.11" fill={isLyric ? '#D4F70E' : "white"} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconLyricsIndividualTrack;
