import {
  Box,
  Button,
  CloseButton,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  applyPromoCode,
  clearValidatedPromoCode,
  validatePromoCode,
} from "app/redux/actions/cart";
import {
  selectInvalidCode,
  selectValidatedPromoCode,
} from "app/redux/selectors/cart";
import React, { useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ToastPlanSubscription from "app/components/toast/toastplan";

interface ModalPromoCodeProps {
  showModalPromCode: boolean;
  setShowModalPromoCode: () => void;
}

export default function ModalPromoCode({
  showModalPromCode,
  setShowModalPromoCode,
}: ModalPromoCodeProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const validatedPromoCode = useSelector(selectValidatedPromoCode);
  const invalidCode = useSelector(selectInvalidCode);
  const [valuePromoCode, setValuePromoCode] = useState(
    validatedPromoCode && validatedPromoCode.promoCode
  );
  const [checkPromoCode, setCheckPromoCode] = useState(false);

  const closeModalPromoCodeAndClear = useCallback(() => {
    setShowModalPromoCode();
    dispatch(clearValidatedPromoCode());
    setCheckPromoCode(false);
  }, [dispatch, setCheckPromoCode, setShowModalPromoCode]);

  const handleApplyPromoCode = useCallback(() => {
    if (!validatedPromoCode.promoCode) {
      dispatch(validatePromoCode(valuePromoCode));
    } else {
      dispatch(applyPromoCode(validatedPromoCode));
      ToastPlanSubscription({
        title: t("promoCodeAccepted"),
        description: t("desPromoCodeAccepted"),
        isError: false,
      });
      closeModalPromoCodeAndClear();
    }
  }, [
    validatedPromoCode,
    dispatch,
    valuePromoCode,
    t,
    closeModalPromoCodeAndClear,
  ]);
  useEffect(() => {
    setValuePromoCode(validatedPromoCode?.promoCode);
  }, [validatedPromoCode?.promoCode]);

  return (
    <Modal
      isOpen={showModalPromCode}
      onClose={closeModalPromoCodeAndClear}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "300px", md: "500px" }}
        margin={{ base: "0px 10px", md: "0px" }}
      >
        <ModalHeader
          padding={"16px 40px"}
          fontSize={{ base: "18px", md: "24px" }}
        >
          {t("applyPromoCode")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={closeModalPromoCodeAndClear}
          right={3}
          top={{ base: "10px", md: "25px" }}
          fontSize={{ base: "20px", md: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody padding="10px 40px">
          <Text mb="10px"> {t("applyPromoCode")}</Text>
          <Box
            minH={{ base: "200px", md: "300px" }}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Input
                fontSize={{ base: "14px", md: "16px" }}
                border="none"
                backgroundColor="#25252A"
                placeholder="Enter your promo code..."
                size="md"
                onChange={(e) =>
                  setValuePromoCode(e.target.value.toUpperCase())
                }
                value={valuePromoCode}
              />
              {invalidCode && (
                <Text fontSize="14px" color="red">
                  {t("invalidCode")}
                </Text>
              )}
            </Box>

            <Button
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              borderRadius="4px"
              _active={{ background: "#D4F70E" }}
              w="100%"
              mb="20px"
              onClick={handleApplyPromoCode}
              fontSize={{ base: "14px", md: "16px" }}
            >
              {!validatedPromoCode?.promoCode && !checkPromoCode
                ? t("checkPromoCode")
                : t("applyPromoCode")}
            </Button>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
