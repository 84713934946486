import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import "./styles.scss";
import React from "react";

interface InputItemProps {
  placeholder?: string;
  className?: string;
  control?: any;
  type?: string;
  label?: string;
  name?: string;
  value?: string;
  errors?: any;
}

export const InputItem = ({
  placeholder = "",
  className = "",
  control,
  label = "",
  name = "",
  type = "",
  value = "",
  errors = {},
  ...props
}: InputItemProps) => {
  return (
    <FormControl id={name} className={className} isInvalid={!!errors.message}>
      <FormLabel
        color="white !important "
        fontSize="14px"
        fontWeight={800}
        lineHeight="17.5px"
        mb="8px"
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...props}
        render={({ field }) => (
          <>
            <Input
              variant="outline"
              type={type}
              placeholder={placeholder}
              defaultValue={value}
              {...field}
              borderRadius="4px !important"
              backgroundColor="#181C1E"
              size="lg"
              _placeholder={{ color: "#434141" }}
              border="1px solid #2D2D2D !important"
              fontSize="14px"
            />
            <FormErrorMessage mt="4px">{errors.message}</FormErrorMessage>
          </>
        )}
      />
    </FormControl>
  );
};
