import React from "react";

interface IconHeartFeedPageLikeMobileProps {
  color?: string;
}

const IconHeartFeedPageLikeMobile = ({
  color = "#D4F70E",
}: IconHeartFeedPageLikeMobileProps) => {
  return (
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 18.0011C9.35502 17.4291 8.62602 16.8341 7.85502 16.2011H7.84502C5.13002 13.9811 2.05302 11.4691 0.694023 8.45912C0.24754 7.50085 0.0109268 6.45825 1.1406e-05 5.40112C-0.00297039 3.95061 0.578794 2.56011 1.61383 1.54389C2.64887 0.527673 4.04981 -0.0284812 5.50002 0.00112428C6.68065 0.00298908 7.83586 0.344205 8.82802 0.984124C9.26399 1.26709 9.65844 1.60938 10 2.00112C10.3435 1.61092 10.7381 1.26883 11.173 0.984124C12.1648 0.344079 13.3197 0.00284283 14.5 0.00112428C15.9502 -0.0284812 17.3512 0.527673 18.3862 1.54389C19.4213 2.56011 20.003 3.95061 20 5.40112C19.9898 6.45994 19.7532 7.50432 19.306 8.46412C17.947 11.4741 14.871 13.9851 12.156 16.2011L12.146 16.2091C11.374 16.8381 10.646 17.4331 10.001 18.0091L10 18.0011ZM5.50002 2.00112C4.56853 1.98947 3.67009 2.34597 3.00002 2.99312C2.35441 3.62729 1.99358 4.49617 1.99994 5.40112C2.01135 6.17162 2.18585 6.93098 2.51202 7.62912C3.15353 8.92783 4.01913 10.1032 5.06902 11.1011C6.06002 12.1011 7.20002 13.0691 8.18602 13.8831C8.45902 14.1081 8.73702 14.3351 9.01502 14.5621L9.19002 14.7051C9.45702 14.9231 9.73302 15.1491 10 15.3711L10.013 15.3591L10.019 15.3541H10.025L10.034 15.3471H10.039H10.044L10.062 15.3321L10.103 15.2991L10.11 15.2931L10.121 15.2851H10.127L10.136 15.2771L10.8 14.7321L10.974 14.5891C11.255 14.3601 11.533 14.1331 11.806 13.9081C12.792 13.0941 13.933 12.1271 14.924 11.1221C15.9741 10.1247 16.8397 8.94964 17.481 7.65112C17.8131 6.94692 17.9901 6.17963 18.0001 5.40112C18.0042 4.49896 17.6435 3.63342 17 3.00112C16.3312 2.35104 15.4326 1.99161 14.5 2.00112C13.3619 1.99145 12.274 2.46849 11.51 3.31212L10 5.05212L8.49002 3.31212C7.72609 2.46849 6.6381 1.99145 5.50002 2.00112Z"
        fill={color}
      />
    </svg>
  );
};
export default IconHeartFeedPageLikeMobile;
