import React from "react";

const IconCartSaleNotification = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.8891 8.1128C16.6714 15.2 15.6245 15.2 14.9999 15.2H8.84608C6.69071 15.2 5.03919 13.8816 4.63842 11.84C4.62612 11.7776 3.40766 5.5576 3.09613 3.82C2.94305 2.968 2.55382 2.4 1.15384 2.4C0.51615 2.4 0 1.8632 0 1.2C0 0.5368 0.51615 0 1.15384 0C3.47689 0 4.97227 1.2 5.36457 3.3792C5.67303 5.0984 6.88763 11.2976 6.89994 11.3608C7.14533 12.6136 8.22455 12.8008 8.84608 12.8008H14.696C15.3245 12.0984 16.7568 9.392 17.8022 7.088C18.0745 6.4888 18.7629 6.2336 19.3383 6.5152C19.9152 6.7976 20.1614 7.5128 19.8891 8.1128ZM8.07686 16C7.01456 16 6.1538 16.8952 6.1538 18C6.1538 19.1048 7.01456 20 8.07686 20C9.13916 20 9.99992 19.1048 9.99992 18C9.99992 16.8952 9.13916 16 8.07686 16ZM14.9999 16C13.9376 16 13.0768 16.8952 13.0768 18C13.0768 19.1048 13.9376 20 14.9999 20C16.0622 20 16.9229 19.1048 16.9229 18C16.9229 16.8952 16.0622 16 14.9999 16ZM11.2791 9.4752C11.3561 9.54 11.4507 9.5712 11.5453 9.5712C11.6399 9.5712 11.733 9.54 11.8114 9.4752C11.8884 9.412 13.9868 7.2832 15.1314 5.512C15.2199 5.3744 15.2283 5.1984 15.153 5.0528C15.0799 4.908 14.9337 4.8168 14.776 4.8168H13.0637C13.0637 4.8168 12.8968 1.384 12.7876 1.2248C12.6291 0.9896 11.9745 0.8296 11.5445 0.8296C11.113 0.8296 10.4468 0.9896 10.2876 1.2248C10.1791 1.3832 10.0353 4.8168 10.0353 4.8168H8.3007C8.14224 4.8168 7.99686 4.908 7.92224 5.0528C7.84763 5.1984 7.85609 5.3752 7.94378 5.512C9.08916 7.284 11.203 9.412 11.2791 9.4752Z" fill="#D4F70E" />
    </svg>

  );
};
export default IconCartSaleNotification;
