import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  Flex,
  useMediaQuery,
} from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import { useMyTrack } from "hooks/track/useMyTrack";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FadeLoader } from "react-spinners";
import { heightLoadMore, searchData } from "utils/convertTracks";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import "./styles.scss";
import MusicCardMyTrack from "../MusicCard/MusicCardMyTrack";
import MusicCardMyTrackMobile from "../MusicCard/MusicCardMyTrackMobile";

interface MyDraftTrackProps {
  searchTextItem: string;
  handleCheckbox?: (id: String) => void;
  selectCheckbox?: string[];
}

const columnHeader = [
  { label: t("trackNo") },
  { label: t("trackName") },
  { label: t("licenseAndContract") },
  { label: t("dateReleased") },
  { label: t("fileDelivery") },
  { label: t("actions") },
];

export default function MyDraftTrack({
  searchTextItem,
  selectCheckbox,
  handleCheckbox,
}: MyDraftTrackProps) {
  const { drafts, onGetDraftsTracks, isLoading } = useMyTrack();
  const { t } = useTranslation();
  const [selectItem, setSelectIem] = useState(-1);
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  useEffect(() => {
    onGetDraftsTracks({ page: 1 });
  }, [onGetDraftsTracks]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(drafts, e)) {
        onGetDraftsTracks({ page: 1 + drafts?.page, loadingMore: true });
      }
    },
    [drafts, onGetDraftsTracks]
  );

  const filterDraftsTrack = searchData(drafts?.items, searchTextItem);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  return (
    <Box mt="20px">
      {filterDraftsTrack?.length === 0 && !isLoading ? (
        <EmptyTrackSoundkit />
      ) : isLessThan766 ? (
        <Box>
          <Flex>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="20%">
              {t("trackNo")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="65%">
              {t("trackName")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="15%">
              {t("actions")}
            </Text>
          </Flex>
          {(filterDraftsTrack || []).map(
            (item: TrackSoundKit, index: number) => (
              <MusicCardMyTrackMobile
                item={item}
                selectCheckbox={selectCheckbox}
                handleCheckbox={handleCheckbox}
                key={index}
                index={index}
                isMyTrackRelease={true}
              />
            )
          )}
        </Box>
      ) : (
        <TableContainer>
          <Table fontFamily="Cera Pro Regular">
            <Thead>
              <Tr>
                {!isLoading &&
                  columnHeader.map((i, k) => (
                    <Th
                      key={k}
                      fontSize="14px"
                      color="#8B8B8D"
                      textTransform="capitalize"
                      borderColor="transparent"
                      position={k === 0 ? "sticky" : "static"}
                      left={k === 0 ? "0px" : ""}
                      zIndex={k === 0 ? "1" : ""}
                      backdropFilter={k === 0 ? "blur(20px)" : " "}
                    >
                      {i.label}
                    </Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {(isLoading ? [] : filterDraftsTrack || []).map(
                (item: TrackSoundKit, index: number) => (
                  <MusicCardMyTrack
                    isDraft
                    isMyTrack
                    key={index}
                    item={item}
                    index={index}
                    selectCheckbox={selectCheckbox}
                    handleCheckbox={handleCheckbox}
                    selectItem={selectItem}
                    setSelectIem={setSelectIem}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Box display="flex" justifyContent="center">
        <FadeLoader color={"#d4f70e"} loading={drafts.loadingMore} />
      </Box>
      {!drafts.hasMore && !isLoading && drafts?.items?.length > 25 && (
        <Text fontSize="14px" textAlign="center" color="#464649">
          {t("upToDate")}
        </Text>
      )}
    </Box>
  );
}
