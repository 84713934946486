import React from "react";

const SelectTrackFilesIcon = () => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2929 23.6445C23.6834 23.2539 24.3166 23.2539 24.7071 23.6445L32.7071 31.6445C33.0976 32.035 33.0976 32.6681 32.7071 33.0587C32.3166 33.4492 31.6834 33.4492 31.2929 33.0587L24 25.7658L16.7071 33.0587C16.3166 33.4492 15.6834 33.4492 15.2929 33.0587C14.9024 32.6681 14.9024 32.035 15.2929 31.6445L23.2929 23.6445Z"
        fill="#D4F70E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 23.3516C24.5523 23.3516 25 23.7993 25 24.3516V42.3516C25 42.9038 24.5523 43.3516 24 43.3516C23.4477 43.3516 23 42.9038 23 42.3516V24.3516C23 23.7993 23.4477 23.3516 24 23.3516Z"
        fill="#D4F70E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.3633 5.3709C19.9313 5.27629 22.4872 5.76505 24.839 6.80044C27.1909 7.83583 29.2774 9.39091 30.9417 11.3488C32.4376 13.1085 33.5575 15.1524 34.2358 17.3545H35.9999C38.4438 17.3561 40.8183 18.1715 42.7473 19.672C44.6763 21.1726 46.0506 23.2728 46.6534 25.6412C47.2562 28.0096 47.0532 30.5113 46.0763 32.7514C45.0994 34.9916 43.4043 36.8427 41.2586 38.0125C40.7737 38.2768 40.1663 38.098 39.9019 37.6131C39.6376 37.1282 39.8163 36.5208 40.3013 36.2565C42.0569 35.2994 43.4438 33.7849 44.243 31.952C45.0423 30.1191 45.2084 28.0723 44.7152 26.1345C44.222 24.1967 43.0975 22.4784 41.5193 21.2507C39.941 20.0229 37.9988 19.3558 35.9993 19.3545H33.4799C33.024 19.3545 32.6259 19.0461 32.5118 18.6048C31.9442 16.4096 30.8864 14.3716 29.4179 12.6441C27.9494 10.9166 26.1083 9.54449 24.0332 8.63091C21.958 7.71733 19.7028 7.28607 17.437 7.36955C15.1712 7.45303 12.9538 8.04908 10.9515 9.11289C8.94917 10.1767 7.21409 11.6806 5.87667 13.5115C4.53925 15.3424 3.6343 17.4526 3.22984 19.6836C2.82538 21.9146 2.93195 24.2083 3.54153 26.3921C4.15111 28.576 5.24783 30.5933 6.74926 32.2923C7.11497 32.7061 7.07596 33.3381 6.66211 33.7038C6.24827 34.0695 5.61631 34.0305 5.25059 33.6167C3.54898 31.6911 2.30602 29.4049 1.61517 26.9298C0.92431 24.4548 0.803535 21.8553 1.26192 19.3269C1.7203 16.7984 2.74592 14.4068 4.26166 12.3318C5.7774 10.2567 7.74382 8.55234 10.0131 7.34669C12.2824 6.14104 14.7954 5.46552 17.3633 5.3709Z"
        fill="#D4F70E"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.2929 23.6445C23.6834 23.2539 24.3166 23.2539 24.7071 23.6445L32.7071 31.6445C33.0976 32.035 33.0976 32.6681 32.7071 33.0587C32.3166 33.4492 31.6834 33.4492 31.2929 33.0587L24 25.7658L16.7071 33.0587C16.3166 33.4492 15.6834 33.4492 15.2929 33.0587C14.9024 32.6681 14.9024 32.035 15.2929 31.6445L23.2929 23.6445Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default SelectTrackFilesIcon;
