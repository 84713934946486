import {
  Box,
  Checkbox,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import IconSkeletonMusic from "app/assets/icons/iconSkeletonMusic";
import { default as React } from "react";
import Skeleton from "../common/Skeleton/skeleton";

interface ColumnHeader {
  label: string;
}

interface Props {
  columnHeader: ColumnHeader[];
  isSoundkit?: boolean;
}

export default function SkeletonMyTrackNew({
  columnHeader,
  isSoundkit = false,
}: Props) {
  const fakeDataSekeleton = Array.from({ length: 20 });
  return (
    <>
      <TableContainer>
        <Table fontFamily="Cera Pro Regular" mt="20px">
          <Thead>
            <Tr>
              {columnHeader.map((i, k) => (
                <Th
                  key={k}
                  fontSize="14px"
                  color="#8B8B8D"
                  textTransform="capitalize"
                  borderColor="transparent"
                >
                  {i.label}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {fakeDataSekeleton.map((i, k) => (
              <Tr alignItems="center" key={k} mb="30px">
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Flex justifyContent="center" alignItems="center" gap="10px">
                    <Checkbox
                      borderRadius="4px"
                      _focusVisible={{ outline: "none" }}
                      size="lg"
                    />
                    <Skeleton w="10px" h="16px" mt="6px" borderRadius="2px" />
                  </Flex>
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Flex gap="10px">
                    <IconSkeletonMusic />
                    <Flex
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Skeleton
                        h="16px"
                        w="150px"
                        borderRadius="30px"
                        mr="0px"
                      />
                      <Flex>
                        <Skeleton h="16px" w="35px" mb="0px" />
                        <Skeleton
                          h="16px"
                          w="109px"
                          borderRadius="30px"
                          mb="0px"
                          mr="0px"
                        />
                      </Flex>
                    </Flex>
                  </Flex>
                </Td>
                {!isSoundkit && (
                  <Td borderColor="rgba(36, 37, 42, 0.8)">
                    <Flex>
                      <Skeleton w="84px" h="21px" borderRadius="2px" />
                      <Skeleton w="84px" h="21px" borderRadius="2px" />
                    </Flex>
                  </Td>
                )}
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Box>
                    <Skeleton w="80px" />
                  </Box>
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  {" "}
                  <Flex>
                    <Skeleton w="34px" h="21px" borderRadius="2px" />
                    <Skeleton w="34px" h="21px" borderRadius="2px" />
                  </Flex>
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton w="34px" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
