import React from "react";

const IconHomeProfile = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 6.97917L8.6875 1L16.375 6.97917V16.375C16.375 16.8281 16.195 17.2626 15.8746 17.583C15.5543 17.9033 15.1197 18.0833 14.6667 18.0833H2.70833C2.25526 18.0833 1.82073 17.9033 1.50036 17.583C1.17998 17.2626 1 16.8281 1 16.375V6.97917Z"
        stroke="#D4F70E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 18.0827V9.54102H11.125V18.0827"
        stroke="#D4F70E"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default IconHomeProfile;
