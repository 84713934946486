import React from "react";

const IconToggleOpenFooterPlayer = () => {
  return (
    <svg
      width="42"
      height="33"
      viewBox="0 0 42 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4.5C0 2.01472 2.01472 0 4.5 0H36.8521C39.3374 0 41.3521 2.01472 41.3521 4.5V28.2C41.3521 30.6853 39.3374 32.7 36.8521 32.7H4.5C2.01472 32.7 0 30.6853 0 28.2V4.5Z"
        fill="#24252A"
        fill-opacity="0.8"
      />
      <path
        d="M4.32755 0C1.93841 0 0 1.93841 0 4.32755V28.3695C0 30.7586 1.93841 32.697 4.32755 32.697H37.0246C39.4137 32.697 41.3521 30.7586 41.3521 28.3695V4.32755C41.3521 1.93841 39.4137 0 37.0246 0H4.32755ZM4.32755 0.961678H37.0246C38.8976 0.961678 40.3905 2.45454 40.3905 4.32755V28.3695C40.3905 30.2425 38.8976 31.7354 37.0246 31.7354H4.32755C2.45454 31.7354 0.961678 30.2425 0.961678 28.3695V4.32755C0.961678 2.45454 2.45454 0.961678 4.32755 0.961678Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30 19H31.5V23.5C31.5 24.3205 30.8205 25 30 25H25.5V23.5H30V19ZM12 19H10.5V23.5C10.5 24.3205 11.1795 25 12 25H16.5V23.5H12V19ZM12 8.5H16.5V7H12C11.1795 7 10.5 7.6795 10.5 8.5V13H12V8.5ZM13.5 10H28.5V22H13.5V10ZM16.5 19H25.5V13H16.5V19ZM25.5 7V8.5H30V13H31.5V8.5C31.5 7.6795 30.8205 7 30 7H25.5Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconToggleOpenFooterPlayer;
