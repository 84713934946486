import axiosService from "services/axios.service";
import getOptions from "utils/getOptions";
import { errorMessage } from "utils/errorMessage";
import { NewTrackState as TrackPayload } from "app/redux/reducers/track";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import { convertTracks } from "utils/convertTracks";
import {
  LoadMorePage,
  LyricsModel,
  QueryPlayTrack,
  GetLyrics,
  CreateDraftTracksModel,
} from "app/models";
import { TrackSoundKit } from "app/models";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { getIpAddress } from "utils/getData";

export default class TrackService {
  static async submitTrack(track: TrackPayload) {
    try {
      const response = await axiosService.post("/tracks", track);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async editTrack(body: TrackPayload) {
    try {
      const response = await axiosService.put(`/tracks/${body._id}`, body);
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createDraft(draftTrack: TrackPayload) {
    try {
      const response = await axiosService.post("/tracks/draft", draftTrack);

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async publishDraft(draft: TrackPayload) {
    try {
      const response = await axiosService.post(
        `/tracks/${draft._id}/draft`,
        draft
      );

      return response?.data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async updateDraft(draft: TrackPayload) {
    try {
      const response = await axiosService.patch(
        `/tracks/${draft._id}/draft`,
        draft
      );

      return response?.data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async loadAllMoods() {
    try {
      const response = await axiosService.get("/moods");
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      throw new Error(message);
    }
  }
  static async getUserTracks(createdBy = null, page = 1, filterBy = "") {
    try {
      const options = { ...getOptions(page, filterBy), createdBy };

      const { data } = await axiosService.get("/tracks", {
        params: options,
      });

      return (data?.data || []).map((item: any) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });
    } catch (e: any) {
      switch (e?.response?.status) {
        case 401:
          const message = e?.response?.data?.message;
          throw new Error(message);
        default:
          throw new Error("Server Error");
      }
    }
  }
  static async getUserSoundKits(createdBy = null, page = 1, filterBy = "") {
    try {
      const options = { ...getOptions(page, filterBy), createdBy };
      const { data } = await axiosService.get("/soundkit", {
        params: options,
      });

      return (data?.data || []).map((item: any) => {
        return {
          ...item,
          isSK: true,
          stringKey: item.key,
          key: TRACK_KEY_NOTATION[item.key],
        };
      });
    } catch (e: any) {
      switch (e?.response?.status) {
        case 401:
          const message = e?.response?.data?.message;
          throw new Error(message);
        default:
          throw new Error("Server Error");
      }
    }
  }
  static async getNextTrack(trackId: string | null) {
    try {
      const res = await axiosService.get("/tracks/next-track", {
        params: {
          prevTrackId: trackId,
        },
      });
      return res?.data?.data;
    } catch (error) {
      const message = errorMessage(error);
      throw new Error(message);
    }
  }
  static async getReleased(query?: LoadMorePage, type = "") {
    try {
      const options = { ...getOptions(query?.page, type), ...query };
      const { data } = await axiosService.get("/tracks/my-track", {
        params: options,
      });

      return convertTracks(data?.data || [], false);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async getFavorites(value?: string) {
    try {
      const options = { ...getOptions(), search: value };
      const { data } = await axiosService.get("/tracks/favorites", {
        params: options,
      });
      return convertTracks(data?.data || []);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async getPurchased(value?: string) {
    try {
      const options = { ...getOptions(), search: value };
      const { data } = await axiosService.get("/tracks/purchases", {
        params: options,
      });
      return convertTracks(data?.data || []);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async getDrafts(query?: LoadMorePage) {
    try {
      const options = { ...getOptions(query?.page), isDraft: true, ...query };
      const { data } = await axiosService.get("/tracks/my-track", {
        params: options,
      });

      return convertTracks(data?.data || []);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getTracksPending(query?: LoadMorePage) {
    try {
      const options = { ...getOptions(query?.page), isPending: true, ...query };
      const { data } = await axiosService.get("/tracks/my-track", {
        params: options,
      });
      return convertTracks(data?.data || []);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deleteTrackRelease(trackId: string | undefined) {
    try {
      await axiosService.delete(`/tracks/${trackId}`);
      ToastPlanSubscription({ description: "Delete success", isError: false });
      return trackId;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: "Cannot delete the release track" });
      throw new Error(message);
    }
  }

  static async deleteTracks(trackIds: string[]) {
    try {
      await axiosService.delete("/tracks/delete", { data: trackIds });
      ToastPlanSubscription({ description: "Delete success", isError: false });
      return trackIds;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: "Cannot delete the release track" });
      throw new Error(message);
    }
  }

  static async deleteTrackDraft(trackId: string | undefined) {
    try {
      await axiosService.delete(`/tracks/${trackId}/draft`);
      ToastPlanSubscription({ description: "Delete success", isError: false });
      return trackId;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: "Cannot delete the draft track" });
      throw new Error(message);
    }
  }

  static async getLyrics(query: GetLyrics) {
    try {
      const response = await axiosService.get("/lyrics", {
        params: query?.isSoundPack
          ? { soundPackId: query?.soundPackId, isSoundPack: true }
          : { trackId: query.trackId, isSoundPack: query.isSoundPack },
      });

      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async setFavorite(query: QueryPlayTrack) {
    try {
      delete query.timeCallAPi;
      const ipAddress = await getIpAddress();
      const { data } = await axiosService.post(
        `/tracks/${query.id}/favorites`,
        { ...query, ipAddress }
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async setFavoritePlaylist(query: QueryPlayTrack) {
    try {
      delete query.timeCallAPi;
      const ipAddress = await getIpAddress();
      const { data } = await axiosService.post(
        `/playlists/${query.id}/favorites`,
        { ...query, ipAddress }
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async setRepostPlaylist(playlistId: string) {
    try {
      const { data } = await axiosService.post(
        `/playlists/${playlistId}/reposts`
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async unsetFavoritePlaylist(query: QueryPlayTrack) {
    try {
      const { data } = await axiosService.delete(
        `/playlists/${query.id}/favorites`
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async updateLyrics(data: LyricsModel) {
    const lyricUpdate = {
      lyrics: data.lyrics,
      lyricTitle: data.lyricTitle,
    };

    try {
      const response = await axiosService.put(
        `/lyrics/${data._id}`,
        lyricUpdate
      );
      ToastPlanSubscription({
        description: "Update lyrics success",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createLyrics(data: LyricsModel) {
    try {
      const response = await axiosService.post("/lyrics", data);
      ToastPlanSubscription({
        description: "Create lyrics success",
        isError: false,
      });
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }
  static async unsetFavorite(id: string) {
    try {
      const options = getOptions();
      const { data } = await axiosService.delete(`/tracks/${id}/favorites`, {
        params: options,
      });
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getReposts() {
    try {
      const options = getOptions();
      const { data } = await axiosService.get("/tracks/reposts", {
        params: options,
      });

      return data?.data?.map((item: TrackSoundKit) => {
        return {
          ...item,
          key: TRACK_KEY_NOTATION[item.key],
          stringKey: item.key,
        };
      });
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async setRepost(id: string) {
    try {
      const options = getOptions();
      const { data } = await axiosService.post(`/tracks/${id}/reposts`, {
        params: options,
      });
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async fetchCollaborators(value?: string, page = 1, type = "") {
    try {
      const options = { ...getOptions(page, type), search: value };
      const { data } = await axiosService.get("/user/collaborators", {
        params: options,
      });

      return data?.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async increasingTrackPlay(query: QueryPlayTrack) {
    try {
      await axiosService.put(`/tracks/${query.id}/play-increasing`, query);
      return query.id;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async increasingSoundPackPlay(query: QueryPlayTrack) {
    try {
      const { data } = await axiosService.put(
        `/soundkit/${query.id}/play-increasing`,
        query
      );
      return data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getIndividualTrack(body: {
    slug: string;
    loginAsGuest?: boolean;
  }) {
    try {
      const { data } = await axiosService.get(
        body.loginAsGuest
          ? `/tracks/individual-guest/${body.slug}`
          : `/tracks/individual/${body.slug}`
      );

      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async checkUploadTrack() {
    try {
      const response = await axiosService.get(
        "/subscriptions/check-upload-track"
      );
      return response?.data?.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getAllTrackLyrics() {
    try {
      const { data } = await axiosService.get("/lyrics/all-lyrics");
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async deleteLyric(trackId: string, isSoundPack?: boolean) {
    try {
      await axiosService.delete(
        isSoundPack
          ? `/lyrics/soundpacks/${trackId}`
          : `/lyrics/tracks/${trackId}`
      );
      const { data } = await axiosService.get("/lyrics/all-lyrics");
      return data.data;
    } catch (error: any) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getAllUser() {
    try {
      const { data } = await axiosService.get("/user", {
        params: {
          verifiedEmail: true,
          status: "ACTIVE",
        },
      });
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async checkFollowUser(userId: string) {
    try {
      const { data } = await axiosService.get(`/user/get-follow/${userId}`);
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async createDraftTracks(body: CreateDraftTracksModel) {
    try {
      const { data } = await axiosService.post(`/tracks/drafts`, body);
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async removeLinkUploadTrackFiles(body: string[]) {
    try {
      const { data } = await axiosService.post(
        `/tracks/remove-link-upload`,
        body
      );
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async convertAutoTagged(link: string) {
    try {
      const { data } = await axiosService.post(
        `/tracks/convert-autotagged-mp3`,
        { link }
      );
      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }

  static async createModalDownloadTrackAnalytics({
    _id,
    isTrack,
  }: {
    _id: string;
    isTrack?: boolean;
  }) {
    try {
      const ipAddress = await getIpAddress();
      if (isTrack) {
        const { data } = await axiosService.post(`/tracks/download/${_id}`, {
          ipAddress: ipAddress,
        });
        return data.data;
      } else {
        const { data } = await axiosService.post(`/soundkit/download/${_id}`, {
          ipAddress: ipAddress,
        });
        return data.data;
      }
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
    }
  }
}
