import { Box, Text, Flex, Input, Button } from "@chakra-ui/react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { senEmailReferral } from "app/redux/actions/subscriptions";
import * as yup from "yup";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { setTotalReferred } from "app/redux/actions/referrals";
import { BeatLoader } from "react-spinners";

export default function InviteYourFriendEmail() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([senEmailReferral.typePrefix], state)
  );
  const emailSchema = yup.object().shape({
    email: yup
      .string()
      .email("emailAdressIsNotValid")
      .required("emailAdressIsRequired"),
  });

  const handleSendLinkReferral = useCallback(() => {
    emailSchema
      .validate({ email })
      .then(async () => {
        setError("");
        await dispatch(senEmailReferral(email));
        dispatch(setTotalReferred());
        ToastPlanSubscription({
          description: t("sendLinkReferralSuccess"),
          isError: false,
        });
      })
      .catch((error) => {
        setError(error.errors[0]);
      });
  }, [dispatch, email, emailSchema, t]);

  return (
    <Box mt="16px">
      <Box
        borderRadius="8px"
        background="rgba(56, 56, 61, 0.25)"
        padding={{
          base: "40px 16px 30px 16px",
          md: "40px 20px",
          lg: "40px 35px 30px 35px",
        }}
      >
        <Text
          fontSize={{ base: "18px", md: "20px" }}
          fontWeight="400"
          mb="10px"
          opacity="0.8"
        >
          {t("inviteYourFriendsEmail")}
        </Text>
        <Text
          mb="16px"
          fontSize={{ base: "14px", md: "16px" }}
          fontWeight="400"
          color="#535353"
        >
          {t("doubleTheBenefits")}
        </Text>
        <Flex mb="16px">
          <Box w="100%">
            <Input
              placeholder={t("enterEmailAddress")}
              h="48px"
              border="none"
              background="#18181C"
              onChange={(e) => setEmail(e.target.value)}
              fontSize={{ base: "14px", md: "16px" }}
            />
            {error && (
              <Text mt="5px" fontSize="14px" color="red" fontWeight="400">
                {t(`${error}`)}
              </Text>
            )}
          </Box>

          <Button
            background="#24252A"
            borderRadius="0px"
            borderTopRightRadius="8px"
            borderBottomRightRadius="8px"
            border="1px solid #D4F70E"
            h="48px"
            padding="10px 30px"
            color="#D4F70E"
            _hover={{
              background: "#24252A",
            }}
            onClick={handleSendLinkReferral}
            isLoading={isLoading}
            spinner={<BeatLoader size={8} color="white" />}
            fontSize={{ base: "14px", md: "16px" }}
          >
            {t("invite")}
          </Button>
        </Flex>
        <Text
          fontWeight="400"
          fontSize={{ base: "14px", md: "16px" }}
          mb="10px"
        >
          {t("monthlyReferrals")}
        </Text>
        <Text
          fontWeight="400"
          fontSize={{ base: "14px", md: "16px" }}
          mb="15px"
        >
          {t("asTheReferrer")}
        </Text>
        <Text
          fontWeight="400"
          fontSize={{ base: "14px", md: "16px" }}
          mb="10px"
        >
          {t("annualReferrals")}
        </Text>
        <Text
          fontWeight="400"
          fontSize={{ base: "14px", md: "16px" }}
          mb="15px"
        >
          {t("commissionWaiverOnReferral")}
        </Text>
      </Box>
    </Box>
  );
}
