import React, { ReactElement } from "react";
import IconFlStudioDaw from "app/assets/icons/IconFlStudioDaw";
import IconStudioOneDaw from "app/assets/icons/IconStudioOneDaw";
import IconAbletonDaw from "app/assets/icons/IconAbletonDaw";
import IconLogicDaw from "app/assets/icons/IconLogicDaw";
import IconReasonDaw from "app/assets/icons/IconReasonDaw";
import IconProToolsDaw from "app/assets/icons/IconProToolsDaw";
import IconBandlabDaw from "app/assets/icons/IconBandlabDaw";

export const DAW_TYPE = [
  {
    name: "Studio One",
    value: "studioOne",
    icon: <IconStudioOneDaw />,
  },
  {
    name: "Fl Studio",
    value: "flStudio",
    icon: <IconFlStudioDaw />,
  },
  {
    name: "Ableton",
    value: "ableton",
    icon: <IconAbletonDaw />,
  },
  {
    name: "Logic",
    value: "logic",
    icon: <IconLogicDaw />,
  },
  {
    name: "Reason",
    value: "reason",
    icon: <IconReasonDaw />,
  },
  {
    name: "Pro Tools",
    value: "proTools",
    icon: <IconProToolsDaw />,
  },
  {
    name: "Bandlab",
    value: "bandlab",
    icon: <IconBandlabDaw />,
  },
];

export const getDawValue = (daw: string) => {
  const dawValue: Record<
    string,
    { name: string; value: string; icon: ReactElement }
  > = {
    studioOne: {
      name: "Studio One",
      value: "studioOne",
      icon: <IconStudioOneDaw />,
    },
    flStudio: {
      name: "Fl Studio",
      value: "flStudio",
      icon: <IconFlStudioDaw />,
    },
    ableton: {
      name: "Ableton",
      value: "ableton",
      icon: <IconAbletonDaw />,
    },
    logic: {
      name: "Logic",
      value: "logic",
      icon: <IconLogicDaw />,
    },
    reason: {
      name: "Reason",
      value: "reason",
      icon: <IconReasonDaw />,
    },
    proTools: {
      name: "Pro Tools",
      value: "proTools",
      icon: <IconProToolsDaw />,
    },
    bandlab: {
      name: "Bandlab",
      value: "bandlab",
      icon: <IconBandlabDaw />,
    },
  };
  return dawValue[daw];
};
