/**
 *
 * PublicRoute
 *
 */
import { getJwtLocalStorage } from "helpers/local-storage";
import { getJwtSessionStorage } from "helpers/session";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface PublicRouteProps extends RouteProps {
  layout: any;
  component: any;
  restricted: boolean;
  exact: boolean;
  name: string;
  path: string;
}

export const PublicRoute = ({
  restricted,
  layout: YourLayout,
  component: YourComponent,
  ...rest
}: PublicRouteProps) => {
  const accessToken = getJwtLocalStorage() || getJwtSessionStorage();
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        accessToken === process.env.REACT_APP_TOKEN_GUEST ? (
          <Redirect to="/home" />
        ) : accessToken && restricted ? (
          <Redirect to="/dashboard" />
        ) : (
          <YourLayout {...rest}>
            {YourComponent && <YourComponent {...routeProps} />}
          </YourLayout>
        )
      }
    />
  );
};
