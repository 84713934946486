import { Box, Button, Flex, Text, Image } from "@chakra-ui/react";
import Logo from "app/assets/images/Logo.png";
import ProPlanBg from "app/assets/icons/Image/proPlan.png";
import Tick from "app/assets/icons/tick";
import { FeaturePlans, SubscriptionPlan } from "app/models";
import {
  annualPlan,
  planStatus,
  plansText,
  planType,
  textShowPlans,
} from "constants/planType";
import { Undefinedable } from "constants/types";
import { useToggle } from "hooks/useToggle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PlanDetailModal from "../Subscription/PlanDetailModal";
import CancelModal from "./CancelModal";
import "./styles.scss";
import { BeatLoader } from "react-spinners";
import ToastPlanSubscription from "../toast/toastplan";
import { orderSub } from "constants/statusType";
import { useSelector } from "react-redux";
import { selectUserAdmin } from "app/redux/selectors/users";

interface SubscriptionItemProps {
  isSuccess: boolean;
  isLoading: boolean;
  item: Undefinedable<SubscriptionPlan>;
  activePlanCheck?: (id: string) => boolean;
  handleCancelPlan: () => void;
  handleCheckoutPlan: (plan: string) => void;
  isCurrentMainstreamPlan?: boolean;
}

export default function SubscriptionItem({
  isLoading,
  isSuccess,
  item,
  activePlanCheck,
  handleCancelPlan,
  handleCheckoutPlan,
  isCurrentMainstreamPlan,
}: SubscriptionItemProps) {
  const {
    _id = "",
    features = [],
    name = "",
    price = "",
    description = "",
    type = "",
    plan = "",
  } = item || {};
  const currentUser = useSelector(selectUserAdmin);

  const { t } = useTranslation();
  const [showModalCancel, setShowModalCancel] = useState(false);
  const [showModalPlanDetail, setShowModalPlanDetail] = useToggle();
  const arrangeFeatures = useMemo(() => {
    return (features || [])?.map((item) => {
      return {
        ...item,
        order: orderSub.indexOf(item.featureName) + 1,
      };
    });
  }, [features]);

  const sortFeatures = useMemo(() => {
    return arrangeFeatures?.sort((a, b) => a.order - b.order);
  }, [arrangeFeatures]);

  useEffect(() => {
    if (isSuccess) {
      setShowModalCancel(false);
    }
  }, [isSuccess, setShowModalCancel]);

  const isActivePlan = useMemo(
    () => activePlanCheck && activePlanCheck(String(_id)),
    [activePlanCheck, _id]
  );

  const isFreePlan = useMemo(() => type === planType.FREE, [type]);

  const displayingPlan = useMemo(() => {
    if (isFreePlan && !isActivePlan) {
      return t("Free");
    }
    if (isFreePlan) {
      return t("Active");
    }
    return currentUser?.subscriptionPlan === annualPlan.TRIAL
      ? t("deactivateTrial")
      : isActivePlan
      ? t("CancelSubscription")
      : t("startNowDayTrial");
  }, [isFreePlan, isActivePlan, t, currentUser?.subscriptionPlan]);

  const activeButton = useMemo(() => {
    if (isFreePlan) {
      return "#18181C";
    }
    return isActivePlan ? "#431E21" : "rgba(212, 247, 14, 0.04)";
  }, [isFreePlan, isActivePlan]);

  const onShowPlanDetail = useCallback(() => {
    setShowModalPlanDetail();
  }, [setShowModalPlanDetail]);

  const onButtonClick = useCallback(() => {
    if (isFreePlan) {
      return;
    }
    if (isActivePlan) {
      setShowModalCancel(true);
    } else if (isCurrentMainstreamPlan) {
      // OPEN CANCEL SUBSCRIPTION
      ToastPlanSubscription({
        description: "Please cancel current subscription",
        isError: false,
      });
    } else {
      // UPGRADE SUBSCRIPTION : Add subscription to cart
      item?._id && handleCheckoutPlan(item?.plan || "");
    }
  }, [
    handleCheckoutPlan,
    isActivePlan,
    isCurrentMainstreamPlan,
    isFreePlan,
    item?._id,
    item?.plan,
  ]);

  const onCancelClick = useCallback(() => {
    handleCancelPlan();
  }, [handleCancelPlan]);

  const buttonColor =
    isActivePlan || displayingPlan === t("Active")
      ? "#fffff"
      : displayingPlan === t("Free")
      ? "#FFFFFF"
      : "#D4F70E";

  if (plan === planType.PRO) {
    return (
      <Flex
        border={isActivePlan ? "1px solid #D4F70E" : "none"}
        boxShadow={
          isActivePlan ? "8px 100px 100px 9px rgba(212,247,14,0.22)" : "none"
        }
        bg="#1B1B1F"
        bgImg={ProPlanBg}
        w={{ base: "100%", md: "255px", lg: "300px" }}
        mb="50px"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minH="350px"
      >
        <Box mr="20px">
          <Image fallbackSrc={Logo} />
        </Box>
        <Box>
          <Text
            textAlign="center"
            fontSize="24px"
            fontWeight="500"
            fontFamily="Cera Pro Medium"
          >
            {t(name)}
          </Text>
          <Text
            textAlign="center"
            color="#D4F70E"
            fontSize="16px"
            lineHeight="24px"
            fontFamily="Cera Pro Medium"
          >
            {t(description)}
          </Text>
        </Box>
      </Flex>
    );
  }

  return (
    <Box
      className="item-subscription"
      border={isActivePlan ? "1px solid #D4F70E" : "none"}
      boxShadow={
        isActivePlan ? "8px 100px 100px 9px rgba(212,247,14,0.22)" : "none"
      }
      minHeight="663px"
      bg="#1B1B1F"
      w={{ base: "100%", md: "255px", lg: "300px" }}
      mr={{ base: "0px", md: "20px" }}
      pl="25px"
      pr="25px"
      pt="50px"
      mb="50px"
    >
      <Box minH="115px">
        <Text
          fontSize="24px"
          lineHeight="24px"
          fontWeight="500"
          pb="8px"
          fontFamily="Cera Pro Regular"
        >
          {t(name)}
        </Text>
        <Box pb="25px">
          <Text
            fontSize="20px"
            fontWeight="bold"
            color="#D4F70E"
            fontFamily="Cera Pro Regular"
          >
            {t(price)}
          </Text>
          {plan === planType.ANNUAL && (
            <Text fontSize="14px" color="#D4F70E" fontFamily="Cera Pro Regular">
              {t("thatJustMonth")}
            </Text>
          )}
        </Box>
      </Box>
      <Text
        pb="20px"
        fontSize="14px"
        lineHeight="22px"
        color="#595959"
        fontFamily="Cera Pro Regular"
      >
        {t(description)}
      </Text>

      <Button
        w="100%"
        fontSize="14px"
        fontFamily="Cera Pro Regular"
        lineHeight="18px"
        bg={activeButton}
        color={buttonColor}
        padding="10px 55px"
        alignItems="center"
        display="flex"
        justifyContent="center"
        mb="25px"
        margin="auto"
        isLoading={displayingPlan !== planStatus.ACTIVE && isLoading}
        onClick={onButtonClick}
        _hover={{
          background: activeButton,
        }}
        border={
          displayingPlan === t("Free")
            ? "1px solid #2D2D2D"
            : displayingPlan !== t("Active")
            ? "1px solid rgba(212, 247, 14, 0.1)"
            : "none"
        }
        spinner={<BeatLoader size={8} color="white" />}
      >
        {displayingPlan}
      </Button>
      {sortFeatures.slice(0, 9).map((item: FeaturePlans) => {
        return (
          <Flex mt="20px" mb="20px" alignItems="center" key={item.featureName}>
            <Box
              w="22px"
              h="22px"
              lineHeight="20px"
              borderRadius="50%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              background="#222629"
              color="#D4F70E"
              mr="20px"
            >
              <Tick />
            </Box>
            <Text
              fontSize="14px"
              lineHeight="18px"
              fontFamily="Cera Pro Regular"
            >
              {t(
                plansText(
                  textShowPlans(item),
                  plan === planType.ANNUAL ? true : false
                )?.name
              )}
            </Text>
          </Flex>
        );
      })}
      <Text
        onClick={onShowPlanDetail}
        cursor={"pointer"}
        color={isActivePlan ? "#FFFFFF" : "#595959"}
        mt="40px"
        pt="20px"
        borderTop="1px solid rgba(255, 255, 255, 0.04)"
        ml="-25px"
        textAlign="center"
        pb="20px"
        _hover={{
          color: "#FFFFFF",
        }}
        fontFamily="Cera Pro Regular"
      >
        {t("fullDetails")}
      </Text>
      <CancelModal
        isLoading={isLoading}
        onCancelClick={onCancelClick}
        isOpen={showModalCancel}
        onClose={() => setShowModalCancel(false)}
      />
      {item?._id && (
        <PlanDetailModal
          isLoading={isLoading}
          isActivePlan={isActivePlan}
          displayingPlan={displayingPlan}
          planDetail={item}
          isOpen={showModalPlanDetail}
          onClose={setShowModalPlanDetail}
          onButtonClick={onButtonClick}
          features={sortFeatures}
        />
      )}
    </Box>
  );
}
