import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import Avatar from "app/assets/images/UserAvatar.png";
import { BulkOffers, LicensesBulkOffer, User } from "app/models";
import House from "app/assets/icons/house";

interface RenderFollowerUserProps {
  typeOfUser: (value: string) => void;
  userBulkOffers: BulkOffers[];
  userById: User;
}

export default function RenderBulkOffersUser({
  typeOfUser,
  userBulkOffers,
  userById,
}: RenderFollowerUserProps) {
  return (
    <>
      <Box mb="20px" display="flex" pl="40px" alignItems="center">
        <Image
          w="80px"
          height="80px"
          borderRadius="50%"
          src={userById?.profileImage}
          fallbackSrc={Avatar}
          mr="12px"
        />
        <Box>
          <Text fontWeight="16px" fontSize="16px">
            {userById?.name}
          </Text>
          <Text color="#535353" fontSize="12px" fontWeight="400">
            {userById?.role && userById?.role[0]
              ? typeOfUser("typeOfUser")
              : ""}
          </Text>
        </Box>
      </Box>
      <Flex maxHeight="300px" overflow="auto" flexDirection="column">
        {userBulkOffers.length > 0 &&
          userBulkOffers.map((bulkOffers: BulkOffers) => {
            return bulkOffers?.licenses.map((item: LicensesBulkOffer) => {
              return (
                <Box
                  ml="40px"
                  mb="8px"
                  bgColor="#24252A"
                  h="60px"
                  borderRadius="4px"
                  display="flex"
                  alignItems="center"
                  key={item._id}
                >
                  <Box pl="16px" pr="16px" mt="-12px">
                    <House />
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Text fontSize="16px" fontWeight="500">
                      {item.name}
                    </Text>
                    <Text fontSize="14px" fontWeight="400">
                      {bulkOffers.name}
                    </Text>
                  </Box>
                </Box>
              );
            });
          })}
      </Flex>
    </>
  );
}
