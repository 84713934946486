import { CollaborationsModel } from "app/models";
import {
  getCollaborations,
  updateCollaboration,
} from "app/redux/actions/collaborations";
import {
  selectorsCollaborations,
  selectorsLoadingCollaborations,
} from "app/redux/selectors/collaborations";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useToggle } from "hooks/useToggle";
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useCollaborations = () => {
  const dispatch = useDispatch();
  const collaborations = useSelector(selectorsCollaborations);
  const loading = useSelector(selectorsLoadingCollaborations);
  const currentUser = useSelector(selectUserAdmin);
  const [showModalCollaboration, setShowModalCollaboration] = useToggle();
  const [selectCollaboration, setSelectCollaboration] =
    useState<CollaborationsModel>(null);

  const onGetCollaborations = useCallback(() => {
    dispatch(getCollaborations());
  }, [dispatch]);

  const createdByMe = useCallback(
    (createdById) => createdById === currentUser?._id,
    [currentUser?._id]
  );

  const handleCollaboration = useCallback(
    (body) => {
      dispatch(updateCollaboration(body));
      setShowModalCollaboration();
    },
    [dispatch, setShowModalCollaboration]
  );

  return {
    onGetCollaborations,
    collaborations,
    loading,
    showModalCollaboration,
    setShowModalCollaboration,
    selectCollaboration,
    setSelectCollaboration,
    createdByMe,
    handleCollaboration,
  };
};
