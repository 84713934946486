import { Box, Flex, Text, Image } from "@chakra-ui/react";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import AvatarUser from "app/assets/images/UserAvatar.png";
import IconDownloadFiles from "app/assets/icons/IconDownloadFiles";
import { transaction } from "app/models";
import { TRACK_TYPE } from "constants/trackTypes";
import { formatTimeByDayMonthYear } from "utils/timeAgo";
import { TRACK_STATE } from "constants/index";
import downloadFromUrl from "utils/convertTracks";
import DeviceDetector from "device-detector-js";
import { useDispatch } from "react-redux";
import { getIpAddress } from "utils/getData";
import { createDownloadLog } from "app/redux/actions/purchased";
import dayjs from "dayjs";
import { createModalDownloadTrackAnalytics } from "app/redux/actions/tracks";

interface ItemPurchasedProps {
  purchased: transaction;
  index: number;
}

export default function ItemPurchased({
  index,
  purchased,
}: ItemPurchasedProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const currentDate = dayjs();

  const trackOrSoundPack = useMemo(() => {
    if (!!purchased.track) {
      return purchased?.track;
    } else {
      return purchased?.soundkit;
    }
  }, [purchased?.soundkit, purchased.track]);

  const collaborations = useMemo(() => {
    if (trackOrSoundPack?.collaborators?.length > 1) {
      return trackOrSoundPack?.collaborators?.filter(
        (item) => item.user?._id !== trackOrSoundPack?.createdBy?._id
      );
    }
  }, [trackOrSoundPack?.collaborators, trackOrSoundPack?.createdBy?._id]);

  const handleCreateDownloadLog = useCallback(
    async (
      file: string,
      trackIdOrSoundPackId: string,
      title: string,
      isTrack?: boolean
    ) => {
      const ipAddress = await getIpAddress();
      let bodyCreateTrackDownloadLog = {};
      if (isTrack) {
        bodyCreateTrackDownloadLog = {
          ipAddress: ipAddress,
          fileDownload: file,
          transactionId: purchased?._id,
          trackId: trackIdOrSoundPackId,
          device: device?.os?.name,
          browser: device.client?.name,
        };
      } else {
        bodyCreateTrackDownloadLog = {
          ipAddress: ipAddress,
          fileDownload: file,
          transactionId: purchased?._id,
          soundPackId: trackIdOrSoundPackId,
          device: device.os?.name,
          browser: device.client?.name,
        };
      }
      downloadFromUrl(file, title);
      dispatch(createDownloadLog(bodyCreateTrackDownloadLog));
      dispatch(
        createModalDownloadTrackAnalytics({
          _id: trackIdOrSoundPackId,
          isTrack: isTrack,
        })
      );
    },

    [device.client?.name, device.os?.name, dispatch, purchased?._id]
  );

  const downloadFileTrack = useMemo(
    () => [
      {
        id: 1,
        name: purchased?.track?.untaggedFileUrl
          ? purchased?.track?.untaggedFileUrl?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.untaggedFileUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 4,
        name: purchased?.track?.untaggedFileUrlWav
          ? purchased?.track?.untaggedFileUrlWav?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.untaggedFileUrlWav,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 0,
        name: purchased?.track?.taggedFileUrl
          ? purchased?.track?.taggedFileUrl?.includes("mp3")
            ? "MP3"
            : "WAV"
          : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.taggedFileUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
      {
        id: 2,
        name: purchased?.track?.trackStemsUrl ? "STEMS" : "",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.track?.trackStemsUrl,
            purchased?.track?._id,
            purchased?.track?.title,
            true
          ),
      },
    ],
    [
      handleCreateDownloadLog,
      purchased?.track?._id,
      purchased?.track?.taggedFileUrl,
      purchased?.track?.title,
      purchased?.track?.trackStemsUrl,
      purchased?.track?.untaggedFileUrl,
      purchased?.track?.untaggedFileUrlWav,
    ]
  );

  const downloadFileSoundPacks = useMemo(
    () => [
      {
        id: 0,
        name: purchased?.soundkit?.audioFileUrl?.includes("mp3")
          ? "MP3"
          : "WAV",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.soundkit?.audioFileUrl,
            purchased?.soundkit?._id,
            purchased?.soundkit?.title
          ),
      },
      {
        id: 1,
        name: "ZIP",
        onClick: () =>
          handleCreateDownloadLog(
            purchased?.soundkit?.downloadFilesUrl,
            purchased?.soundkit?._id,
            purchased?.soundkit?.title
          ),
      },
    ],
    [
      handleCreateDownloadLog,
      purchased?.soundkit?._id,
      purchased?.soundkit?.audioFileUrl,
      purchased?.soundkit?.downloadFilesUrl,
      purchased?.soundkit?.title,
    ]
  );

  const createdAt = dayjs(purchased?.createdAt);

  const licensesName = useMemo(() => {
    if (!!purchased?.track) {
      return (trackOrSoundPack?.licenses || []).filter(
        (license) => license._id === purchased?.license
      );
    }
  }, [purchased?.license, purchased?.track, trackOrSoundPack?.licenses]);

  const timeAndStatusEx = useMemo(() => {
    if (!!purchased?.track) {
      const timeEx = createdAt.add(licensesName?.[0]?.licenseRenewal, "year");
      const checkTimeEx = currentDate.isAfter(
        purchased?.expirationDate || timeEx
      );
      if (licensesName?.[0]?.exclusive) {
        return { name: t("indefinitely"), status: t("Active") };
      } else {
        return {
          name: formatTimeByDayMonthYear(purchased?.expirationDate || timeEx),
          status: checkTimeEx ? t("negotiationsStatus.EXPIRED") : t("Active"),
        };
      }
    }
    return { name: t("indefinitely"), status: t("Active") };
  }, [
    createdAt,
    currentDate,
    licensesName,
    purchased?.track,
    t,
    purchased?.expirationDate,
  ]);

  return (
    <Flex
      alignItems="center"
      w="100%"
      gap={{ base: "0px", xl: "15px" }}
      padding="15px 0px 25px 0px"
      borderBottom="1px solid rgba(255, 255, 255, 0.06)"
      display={"flex"}
    >
      <Flex
        alignItems="center"
        gap={{ base: "20px", md: "7px", lg: "15px" }}
        w={"9%"}
      >
        <Text fontSize="16px" fontWeight="500">
          {index + 1}
        </Text>
        <Image
          w={{ base: "72px", md: "52px", lg: "62px", xl: "72px" }}
          h={{ base: "72px", md: "52px", lg: "62px", xl: "72px" }}
          borderRadius="6px"
          fallbackSrc={ImageDefault}
          src={trackOrSoundPack?.coverUrl}
        />
      </Flex>

      <Box w={"21%"} ml={{ base: "5px", xl: "0px" }} overflow="hidden">
        <Text noOfLines={1} fontSize="16px" fontWeight="700">
          {trackOrSoundPack?.title}
        </Text>
        <Flex mt="5px">
          <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            padding={{ base: "2px 6px", lg: "2px 10px" }}
            mr="5px"
            mb="5px"
            backgroundColor="#2b2e1b"
            maxW={{ base: "100%", md: "85px", lg: "100%" }}
            alignSelf="baseline"
          >
            <Text
              fontSize="12px"
              lineHeight="14px"
              color="#d4f70e"
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
            >
              {t(TRACK_TYPE[trackOrSoundPack?.type])}
            </Text>
          </Box>
          <Text
            fontSize="14px"
            fontWeight="400"
            color="#464649"
            cursor="pointer"
            noOfLines={1}
          >
            {`By ${trackOrSoundPack?.createdBy?.name}`}
          </Text>
        </Flex>
      </Box>
      <Flex gap="10px" w={{ base: "100%", md: "19%" }}>
        <Image
          w="40px"
          h="40px"
          borderRadius="full"
          fallbackSrc={AvatarUser}
          src={purchased?.sellerId?.profileImage}
        />
        {collaborations &&
          collaborations?.map((item) => (
            <Image
              key={item?._id}
              w="40px"
              h="40px"
              borderRadius="full"
              fallbackSrc={AvatarUser}
              src={item?.user?.profileImage}
              ml="-20px"
            />
          ))}
        <Box overflow="hidden">
          <Flex gap="5px" overflow="hidden">
            <Text fontSize={{ base: "14px", xl: "16px" }} fontWeight="700">
              {purchased?.sellerId?.name}
            </Text>
            {collaborations && (
              <Text fontSize={{ base: "14px", xl: "16px" }} fontWeight="700">
                + {collaborations?.length} {t("others")}
              </Text>
            )}
          </Flex>

          <Text fontSize="14px" fontWeight="500" color="#464649">
            {t("seller")}
          </Text>
        </Box>
      </Flex>
      <Text
        textAlign={{ base: "unset", md: "center" }}
        w={{ base: "100%", md: "8%" }}
        fontSize="14px"
        fontWeight="400"
      >
        {purchased?._id}
      </Text>
      <Box w={{ base: "100%", md: "10%" }}>
        <Text
          textAlign={{ base: "unset", md: "center" }}
          fontSize="14px"
          fontWeight="400"
        >
          {formatTimeByDayMonthYear(purchased?.createdAt)}
        </Text>
      </Box>
      {purchased?.statusDownload === TRACK_STATE.PENDING ? (
        <Flex
          w={{ base: "100%", md: "12%" }}
          justifyContent={{ base: "start", md: "center" }}
        >
          <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            padding="2px 10px"
            backgroundColor="#193347"
          >
            <Text fontSize="12px" fontWeight="500" color="#1DA1F2">
              {t("Pending")}
            </Text>
          </Box>
        </Flex>
      ) : (
        <Box
          w={{ base: "100%", md: "12%" }}
          display="flex"
          flexDirection="column"
          alignItems={{ base: "start", md: "center" }}
        >
          <Box
            display="flex"
            alignItems="center"
            borderRadius="4px"
            padding="2px 10px"
            mb="8px"
            backgroundColor="#2b2e1b"
          >
            <Text fontSize="12px" fontWeight="500" color="#d4f70e">
              {t("Downloaded")}
            </Text>
          </Box>
          <Text
            w={{ base: "100%", md: "70%" }}
            textAlign={{ base: "unset", md: "center" }}
            fontSize="10px"
            fontWeight="400"
          >
            {formatTimeByDayMonthYear(purchased?.timeDownload)}
          </Text>
        </Box>
      )}
      <Box
        w={{ base: "100%", md: "10%" }}
        display="flex"
        flexDirection="column"
        alignItems={{ base: "start", md: "center" }}
      >
        <Box
          display="flex"
          alignItems="center"
          borderRadius="4px"
          padding="2px 10px"
          mb="5px"
          backgroundColor={
            timeAndStatusEx?.status === t("Active") ? "#2b2e1b" : "#431E21"
          }
        >
          <Text
            fontSize="12px"
            fontWeight="500"
            color={
              timeAndStatusEx?.status === t("Active") ? "#d4f70e" : "#ED3636"
            }
          >
            {timeAndStatusEx?.status}
          </Text>
        </Box>
        <Box display={{ base: "flex", md: "block" }}>
          <Text
            fontSize="10px"
            fontWeight="400"
            mr={{ base: "5px", md: "0px" }}
            textAlign="center"
          >
            {t("expireOn")}
          </Text>
          <Text textAlign="center" fontSize="10px" fontWeight="400">
            {timeAndStatusEx?.name}
          </Text>
        </Box>
      </Box>
      <Flex w={{ base: "100%", md: "11%" }} gap="10px">
        {(!!purchased.track ? downloadFileTrack : downloadFileSoundPacks)?.map(
          (item) =>
            item.name && (
              <Flex key={item.id}>
                <Box
                  _hover={{
                    background: "#24252A",
                    padding: "10px",
                    border: "1px solid #38383D",
                    borderRadius: "8px",
                  }}
                  padding="10px 0px"
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    padding="3px 10px"
                    background="#3D4419"
                    color="#D4F70E"
                    borderRadius="4px"
                    onClick={item.onClick}
                    cursor="pointer"
                  >
                    <IconDownloadFiles />
                    <Text fontSize="8px" fontWeight="400">
                      {item.name}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            )
        )}
      </Flex>
    </Flex>
  );
}
