import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import {
  selectNewPromoCode,
  selectedPromoCodeSelector,
} from "app/redux/selectors/promoCode";
import { yupResolver } from "@hookform/resolvers/yup";
import formSchema from "./formSchema";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import { RootState } from "types";
import { useToggle } from "hooks/useToggle";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NewPromoCodeState } from "app/redux/reducers/promoCode";
import {
  createPromoCode,
  getPromoCodeById,
  editPromoCode,
} from "app/redux/actions/promoCode";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import { useHistory, useParams } from "react-router-dom";
import { typeMusic } from "utils/actionText";

export const useAddPromoCode = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSuccessModal, toggleShowSuccessModal] = useToggle();
  const [hasAddPromoCode, setAddPromoCode] = useState(false);
  const initialValues: NewPromoCodeState = useSelector(selectNewPromoCode);
  const { id } = useParams<{ id: string }>();

  const isLoadingCreatePromocode = useSelector((state: RootState) =>
    isLoadingSelector([createPromoCode.typePrefix], state)
  );

  const isSuccessCreatePromocode = useSelector((state: RootState) =>
    successSelector([createPromoCode.typePrefix], state)
  );

  const isLoadingEditPromocode = useSelector((state: RootState) =>
    isLoadingSelector([editPromoCode.typePrefix], state)
  );

  const isSuccessEditPromocode = useSelector((state: RootState) =>
    successSelector([editPromoCode.typePrefix], state)
  );

  const isLoading = useMemo(
    () => isLoadingCreatePromocode || isLoadingEditPromocode,
    [isLoadingCreatePromocode, isLoadingEditPromocode]
  );
  const isSuccess = useMemo(
    () => isSuccessCreatePromocode || isSuccessEditPromocode,
    [isSuccessCreatePromocode, isSuccessEditPromocode]
  );

  const selectedPromocode = useSelector(selectedPromoCodeSelector);
  const { LicenseContracts: licensesList, getLicenseAndContracts } =
    useLicenseContracts();

  useEffect(() => {
    if (id) {
      dispatch(getPromoCodeById(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (isSuccess && hasAddPromoCode) {
      setAddPromoCode(false);
    }
  }, [isSuccess, history, hasAddPromoCode, setAddPromoCode]);

  const methods = useForm<NewPromoCodeState>({
    resolver: yupResolver(formSchema),
    defaultValues: { ...initialValues, ...(selectedPromocode ?? {}) },
  });

  const { watch } = methods;
  const addPromoCodeWatch = watch();

  const { promoCode, productType, percent, licenses, expirationDate } =
    addPromoCodeWatch;

  const idEdit = useMemo(() => !!id, [id]);

  const tabsMenu = useMemo(
    () => [
      {
        name: !idEdit ? t("NewPromoCode") : t("EditPromoCode"),
        id: 0,
        isCheck: !!promoCode && !!productType && !!percent && !!expirationDate,
      },
      {
        name: t("ApplyToLicense"),
        id: 1,
        isCheck:
          productType === typeMusic.SOUND_KIT
            ? !!promoCode && !!productType && !!percent && !!expirationDate
            : licenses.length > 0,
      },
    ],
    [t, promoCode, expirationDate, productType, percent, licenses, idEdit]
  );

  const onAddPromoCode = useCallback(
    async (values: NewPromoCodeState) => {
      const { promoCode, expirationDate } = values;
      setAddPromoCode(true);
      const body = {
        ...values,
        promoCode: promoCode.toUpperCase(),
        ...(expirationDate ? { expirationDate } : {}),
      };
      if (idEdit) {
        await dispatch(editPromoCode(body));

        history.push("/dashboard/discounts");
      } else {
        await dispatch(createPromoCode(body));
        history.push("/dashboard/discounts");
      }
    },
    [idEdit, dispatch, history]
  );

  return {
    methods,
    showSuccessModal,
    tabsMenu,
    licenses: licensesList?.licenses || [],
    isLoading,
    idEdit,
    onAddPromoCode,
    toggleShowSuccessModal,
    getLicenseAndContracts,
  };
};
