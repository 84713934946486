import { createSelector } from "@reduxjs/toolkit";
import { InitPromoCodeState } from "app/redux/reducers/promoCode";

export const selectPromoCode = (state: { promoCode: InitPromoCodeState }) =>
  state.promoCode;

export const selectNewPromoCode = createSelector(
  selectPromoCode,
  ({ newPromoCode }) => {
    return newPromoCode;
  }
);
export const promoCodesSelector = createSelector(
  selectPromoCode,
  ({ promoCodes }) => {
    return promoCodes;
  }
);
export const selectedPromoCodeSelector = createSelector(
  selectPromoCode,
  ({ selectedPromoCode }) => {
    return selectedPromoCode;
  }
);
