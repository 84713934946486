import { Box, Button, Flex, Text } from "@chakra-ui/react";
import TextInput from "app/components/TextInput";
import { Negotiations } from "app/models";
import { STATUS_COLOR } from "constants/negotiationType";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ShowContentProps } from "../modalNegotiations";
import "./styles.scss";
import ToastPlanSubscription from "app/components/toast/toastplan";

interface NegotiationOfferProps {
  setShowContent: (data: ShowContentProps) => void;
  negotiationOffer: Negotiations;
}

export default function NegotiationOffer({
  setShowContent,
  negotiationOffer,
}: NegotiationOfferProps) {
  const { t } = useTranslation();
  const { currentUser } = useNegotiations();

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
  } = useFormContext();

  const counterOffer = watch("counterofferPrice");
  const minPrice = useMemo(() => {
    return negotiationOffer?.licenses[0]?.minPrice;
  }, [negotiationOffer?.licenses]);

  const handleReviewOffer = useCallback(() => {
    if (counterOffer < minPrice) {
      ToastPlanSubscription({
        description: `${t(
          "yourOfferIsLowerThanMinimumOfferPrice"
        )} min:$${minPrice}`,
      });
    } else {
      setShowContent({
        showContentNegotiationDeal: false,
        showNegotiationOffer: false,
        showSubmitNegotiation: true,
      });
    }
  }, [counterOffer, minPrice, setShowContent, t]);

  const numOfCounterofferLeft = useMemo(() => {
    const filterdNegotiations = (negotiationOffer?.negotiations || []).filter(
      (negotiation: Negotiations) =>
        negotiation.createdBy?._id === currentUser?._id &&
        negotiation?.status === STATUS_COLOR.COUNTEROFFER
    );
    return `${5 - filterdNegotiations.length}`;
  }, [currentUser?._id, negotiationOffer?.negotiations]);

  return (
    <Box px={{ base: "16px", md: "24px", xl: "30px" }}>
      <Text
        background="#24252A"
        w={{ base: "max-content", md: "375px" }}
        margin="auto"
        textAlign="center"
        fontSize="12px"
        py="10px"
        px={{ base: "36px", md: "0px" }}
        color="rgba(255, 255, 255, 0.51)"
      >
        {numOfCounterofferLeft} counteroffers left:
      </Text>
      <Box mt={{ base: "54px", md: "80px", xl: "100px" }}>
        <Text textAlign="center" fontSize="24px" lineHeight="40px">
          Price: $ {negotiationOffer?.offerPrice}
        </Text>
      </Box>

      <Flex
        mt={{ base: "70px", md: "90px" }}
        alignItems={"center"}
        justifyContent="center"
      >
        <form onSubmit={handleSubmit(handleReviewOffer)}>
          <Text
            fontSize="18px"
            lineHeight="16px"
            color="rgba(255, 255, 255, 0.3)"
            textAlign="center"
            mb="10px"
          >
            {t("yourCounterOffer")}
          </Text>
          <Flex display="flex" alignItems="center" justifyContent="center">
            <Box w="60%" textAlign="center">
              <TextInput
                name="counterofferPrice"
                type={"group"}
                control={control}
                errors={errors}
                border="none"
                className="offer-button"
                placeholder="$0"
                textAlign="center"
                backgroundColor="#18181c"
              />
            </Box>
          </Flex>

          <Button
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            w="70%"
            size="lg"
            _active={{ background: "#2A2B20", color: "black" }}
            _focus={{ boxShadow: "none" }}
            type="submit"
            display="flex"
            fontSize={{ base: "16px", xl: "18px" }}
            justifyContent="center"
            alignItems={"center"}
            margin="20px auto"
            disabled={counterOffer === "0" || !counterOffer}
          >
            {t("reviewCounteroffer")}
          </Button>
        </form>
      </Flex>
    </Box>
  );
}
