import { Box, Text } from "@chakra-ui/react";
import React from "react";
import IconEmptyUpcomingLicense from "app/assets/icons/IconEmptyUpcomingLicenseExpiry";
import { useTranslation } from "react-i18next";

export default function EmptyLicenseExpiry() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      h="60vh"
    >
      <IconEmptyUpcomingLicense />
      <Text mt="20px" fontSize="20px" fontWeight="400" color="D2D2D3">
        {t("noUpcomingLicenseExpiry")}
      </Text>
    </Box>
  );
}
