import {
  Box,
  Button,
  Flex,
  Image,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
} from "@chakra-ui/react";
import ImageSuperproductorSignup from "app/assets/images/Superproductor.png";
import { Genre, SubGenre } from "app/models";
import { editUserInfo } from "app/redux/actions/users";
import { selectUserState } from "app/redux/selectors/users";
import { useGenre } from "hooks/genre/useGenre";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  filterByGenre,
  firstGenreSubgenre,
  getSelectedInterestValues,
  getSubgenresFromSelected,
  pureReplace,
  remainingGenreSubgenre,
} from "utils/convertInterest";
import ItemInterest from "./itemInterest";
import "./styles.scss";
import { useToggle } from "hooks/useToggle";
import { BeatLoader } from "react-spinners";

export default function ChooseInterest() {
  const { t } = useTranslation();
  const [showInterest, setShowInterest] = useToggle(true);
  const dispatch = useDispatch();
  const history = useHistory();
  const { genres, getGenres } = useGenre();
  const [genresSelection, setGenresSelection] = useState<Genre[]>([]);
  const [subgenreSelection, setSubgenreSelection] = useState<SubGenre[]>([]);
  const [valueGenre, setValueGenre] = useState([]);
  const [valueSubGenre, setValueSubGenre] = useState([]);
  const { isLoading } = useSelector(selectUserState);
  const fillInterest = valueGenre.concat(valueSubGenre);

  useEffect(() => {
    getGenres();
  }, [getGenres]);

  useEffect(() => {
    setGenresSelection(genres);
  }, [genres]);

  const firstGenre = firstGenreSubgenre(genresSelection);
  const remainingGenre = remainingGenreSubgenre(genresSelection);

  const firstSubGenre = firstGenreSubgenre(subgenreSelection);
  const remainingSubGenre = remainingGenreSubgenre(subgenreSelection);

  const handleGenreInterestSelect = useCallback(
    (index) => () => {
      const newState = pureReplace(
        genresSelection,
        {
          ...genresSelection[index],
          selected: !genresSelection[index].selected,
        },
        index
      );

      if (!genresSelection[index].selected) {
        setSubgenreSelection([
          ...subgenreSelection,
          ...getSubgenresFromSelected([
            {
              ...genresSelection[index],
              selected: !genresSelection[index].selected,
            },
          ]),
        ]);
      } else {
        setSubgenreSelection(
          filterByGenre(
            subgenreSelection as SubGenre[],
            genresSelection[index].label as string
          )
        );
      }
      setGenresSelection(newState);
      setValueGenre(getSelectedInterestValues(newState));
    },
    [genresSelection, subgenreSelection]
  );

  const handleSubgenreInterestSelect = useCallback(
    (index) => () => {
      const newState = pureReplace(
        subgenreSelection,
        {
          ...subgenreSelection[index],
          selected: !subgenreSelection[index]?.selected,
        },
        index
      );
      setSubgenreSelection(newState);
      setValueSubGenre(getSelectedInterestValues(newState));
    },
    [subgenreSelection]
  );

  const handleChooseYourInterest = useCallback(() => {
    dispatch(
      editUserInfo({
        userInterest: fillInterest,
      })
    ).then((res) => {
      if (!!res) {
        history.push("/user/account-settings");
      }
    });
  }, [dispatch, fillInterest, history]);

  return (
    <Modal isOpen={showInterest} isCentered onClose={setShowInterest}>
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "650px", xl: "750px" }}
        minHeight={{ base: "570px", xl: "700px" }}
      >
        <ModalBody
          padding={{ base: "10px", md: "10px 25px", xl: "10px 30px" }}
          position="relative"
          margin="10px"
        >
          <Box>
            <Image
              src={ImageSuperproductorSignup}
              display={{ base: "none", xl: "block" }}
            />
            <Text
              fontSize={{ base: "24px", xl: "32px" }}
              fontWeight="700"
              lineHeight="40px"
              mt="20px"
              mb="10px"
              ml="8px"
            >
              {t("chooseYourInterest")}
            </Text>
            <Text
              color="rgba(255, 255, 255, 0.8)"
              mb="30px"
              fontSize={{ base: "14px", xl: "16px" }}
              lineHeight="19px"
              fontWeight="400"
              opacity="0.8"
              ml="8px"
              w="70%"
            >
              {t("descriptionInterest")}
            </Text>
          </Box>

          <Box mt={{ base: "40px", xl: "70px" }}>
            <Flex overflow="scroll">
              {(firstGenre || []).map((item, index) => (
                <ItemInterest
                  interest={item}
                  indexType={(index + 0) % 5}
                  handleChooseInterest={handleGenreInterestSelect(index)}
                />
              ))}
            </Flex>
            <Flex overflow="scroll">
              {(remainingGenre || []).map((item, index) => (
                <ItemInterest
                  interest={item}
                  indexType={(index + 1) % 5}
                  handleChooseInterest={handleGenreInterestSelect(
                    index + firstGenre?.length
                  )}
                />
              ))}
            </Flex>

            <Flex overflow="scroll">
              {(firstSubGenre || []).map((item, index) => (
                <ItemInterest
                  interest={item}
                  indexType={(index + 2) % 5}
                  handleChooseInterest={handleSubgenreInterestSelect(index)}
                />
              ))}
            </Flex>
            <Flex overflow="scroll">
              {(remainingSubGenre || []).map((item, index) => (
                <ItemInterest
                  interest={item}
                  indexType={(index + 3) % 5}
                  handleChooseInterest={handleSubgenreInterestSelect(
                    index + firstSubGenre?.length
                  )}
                />
              ))}
            </Flex>
          </Box>
          <Button
            borderRadius="4px"
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            fontSize="14px"
            lineHeight="24px"
            fontWeight="400"
            size="lg"
            onClick={handleChooseYourInterest}
            disabled={fillInterest.length === 0}
            isLoading={isLoading}
            w="92%"
            position="absolute"
            bottom="30px"
            spinner={<BeatLoader size={8} color="white" />}
          >
            Next
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
