import React, { Fragment } from "react";

import {
  formatDate,
  splitLinkFromMessage,
} from "app/pages/ConversationsPage/config/utils";
import { EMOJI_REGEX } from "app/pages/ConversationsPage/config/constants";
import { Box } from "@chakra-ui/react";
import { MessageItem } from "app/models";

interface LeftMessageProps {
  message: MessageItem;
}

const LeftMessage = ({ message }: LeftMessageProps) => {
  const formattedDate = formatDate(
    message.createdAt.seconds ? message.createdAt.seconds * 1000 : Date.now()
  );

  return (
    <Box>
      <Box className="item-left-messages">
        {message.type === "text" ? (
          <>
            {EMOJI_REGEX.test(message.content) ? (
              <Box onClick={(e) => e.stopPropagation()} title={formattedDate}>
                {message.content}
              </Box>
            ) : (
              <Box
                padding="0px"
                margin="0px"
                onClick={(e) => e.stopPropagation()}
                title={formattedDate}
              >
                {splitLinkFromMessage(message.content).map((item, index) => (
                  <Fragment key={index}>
                    {typeof item === "string" ? (
                      <span>{item}</span>
                    ) : (
                      <a
                        className="mx-1 inline underline"
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.link}
                      </a>
                    )}
                  </Fragment>
                ))}
              </Box>
            )}
          </>
        ) : (
          <Box
            onClick={(e) => e.stopPropagation()}
            title={formattedDate}
            className="border-dark-lighten rounded-lg border p-3 text-gray-400"
          >
            Message has been removed
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default LeftMessage;
