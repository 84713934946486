import {
  Box,
  Flex,
  Text,
  InputGroup,
  InputLeftElement,
  Input,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useCallback } from "react";
import { Back, SearchIcon } from "app/assets/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import EmptyPurchased from "./emptyPurchased";
import ItemPurchased from "./itemPurchased";
import SkeletonItemPurchased from "./skeletonItemPurchased";
import { usePurchased } from "hooks/usePurchased";
import "./styles.scss";
import { heightLoadMore } from "utils/convertTracks";
import { FadeLoader } from "react-spinners";
import { transaction } from "app/models";
import Skeleton from "app/components/common/Skeleton/skeleton";
import SkeletonItemTablePurchased from "./skeletonItemTablePurchased";
import ItemPurchasedMobile from "./itemPurchasedMobile";
import SkeletonItemPurchasedMobile from "./skeletonItemPurchasedMobile";

export default function PurchasedPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const checkTypeExpiring = location.search.split("=")?.[1];
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const {
    getPurchasedTransaction,
    purchasedList,
    isLoading,
    fetchTransactionOptions,
    debouncedSearchText,
    handleSearchText,
  } = usePurchased();
  const redirectDashboard = () => {
    history.push("/dashboard");
  };
  const headerTransfer = useMemo(
    () => [
      { name: "" },
      { name: t("item") },
      { name: t("seller") },
      { name: t("transactionId") },
      { name: t("purchasedDate") },
      { name: t("downloadConfirmation") },
      { name: t("licenseStatus") },
      { name: t("downloadFiles") },
    ],
    [t]
  );

  useEffect(() => {
    if (checkTypeExpiring === "expiring") {
      getPurchasedTransaction({ page: 1, type: "expiring" });
    } else {
      getPurchasedTransaction({ page: 1 });
    }
  }, [checkTypeExpiring, getPurchasedTransaction]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(fetchTransactionOptions, e)) {
        if (checkTypeExpiring === "expiring") {
          getPurchasedTransaction({
            page: 1 + fetchTransactionOptions.page,
            loadingMore: true,
            type: "expiring",
          });
        } else {
          getPurchasedTransaction({
            page: 1 + fetchTransactionOptions.page,
            loadingMore: true,
          });
        }
      }
    },
    [fetchTransactionOptions, getPurchasedTransaction, checkTypeExpiring]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const filterPurchasedHasColla = useMemo(() => {
    return purchasedList?.filter((item) => {
      if (item?.track) {
        return item?.sellerId?._id === item?.track?.createdBy?._id;
      }
      if (item?.soundkit) {
        return item?.sellerId?._id === item?.soundkit?.createdBy?._id;
      }
      return item;
    });
  }, [purchasedList]);

  const dataPurchased = useMemo(() => {
    return (filterPurchasedHasColla || []).filter((item: transaction) => {
      if (!!item.track) {
        return (
          item?.track?.title
            ?.toLowerCase()
            .indexOf(debouncedSearchText.toLowerCase()) !== -1
        );
      } else {
        return (
          item?.soundkit?.title
            ?.toLowerCase()
            .indexOf(debouncedSearchText.toLowerCase()) !== -1
        );
      }
    });
  }, [debouncedSearchText, filterPurchasedHasColla]);

  return (
    <Box
      p={{
        base: "40px 8px",
        md: "40px 10px 40px 20px",
        xl: "40px 10px 40px 80px",
      }}
    >
      <Flex alignItems="center" mb={{ base: "24px", md: "32px" }}>
        <Box
          onClick={redirectDashboard}
          cursor="pointer"
          className="vector-button"
        >
          <Back />
        </Box>
        <Text fontSize={{ base: "24px", md: "32px" }}>{t("purchased")}</Text>
      </Flex>

      <Flex
        alignItems="center"
        borderTopLeftRadius="6px"
        borderTopRightRadius="6px"
        mb="20px"
        justifyContent={{ base: "start", md: "end" }}
      >
        {isLoading ? (
          <Flex>
            <Skeleton w="33px" h="16px" mr="30px" />
            <Skeleton w="128px" h="16px" />
          </Flex>
        ) : (
          <Flex alignItems="center">
            <Text mr="15px" color="#49494D">
              {t("filter")}
            </Text>
            <InputGroup
              border="none"
              borderLeft="1px solid rgba(255, 255, 255, 0.04)"
            >
              <InputLeftElement children={<SearchIcon />} />
              <Input
                type="tel"
                placeholder={t("searchItem")}
                border="0px"
                p="0px 0px 0px 40px"
                _placeholder={{ color: "#FFFFFF" }}
                fontSize="14px"
                cursor="pointer"
                onChange={handleSearchText}
              />
            </InputGroup>
          </Flex>
        )}
      </Flex>

      {isLoading ? (
        <SkeletonItemTablePurchased />
      ) : (
        filterPurchasedHasColla?.length > 0 && (
          <Box
            className="purchased-item"
            display={{ base: "none", md: "block" }}
          >
            <Flex className="purchased-table" gap={{ base: "0px", xl: "15px" }}>
              {headerTransfer.map((item, index) => (
                <Box key={index} className={`colums-${index}`}>
                  <Text fontSize="13px" lineHeight="16px" fontWeight="400">
                    {item.name}
                  </Text>
                </Box>
              ))}
            </Flex>
          </Box>
        )
      )}

      {isLoading && !fetchTransactionOptions.loadingMore ? (
        isLessThan766 ? (
          <SkeletonItemPurchasedMobile />
        ) : (
          <SkeletonItemPurchased />
        )
      ) : dataPurchased?.length > 0 ? (
        (dataPurchased || []).map((item, index) =>
          isLessThan766 ? (
            <ItemPurchasedMobile
              index={index}
              purchased={item}
              key={item._id}
            />
          ) : (
            <ItemPurchased index={index} purchased={item} key={item._id} />
          )
        )
      ) : (
        <EmptyPurchased />
      )}
      <Box display="flex" justifyContent="center">
        <FadeLoader
          color={"#d4f70e"}
          loading={fetchTransactionOptions.loadingMore}
        />
      </Box>
    </Box>
  );
}
