import React from "react";

interface IconShareFeedPageProps {
  color?: string;
}

const IconShareFeedPage = ({ color = "#D4F70E" }: IconShareFeedPageProps) => {
  return (
    <svg
      width="25"
      height="22"
      viewBox="0 0 25 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7094 18.3337H6.25106C5.10046 18.3337 4.16772 17.5128 4.16772 16.5003V6.41699C4.16772 5.40447 5.10046 4.58366 6.25106 4.58366H10.4177V6.41699H6.25106V16.5003H17.7094V12.8337H19.7927V16.5003C19.7927 17.5128 18.86 18.3337 17.7094 18.3337ZM12.1886 12.5651L10.7198 11.2689L17.275 5.50033H13.5427V3.66699H20.8344V10.0837H18.7511V6.79741L12.1886 12.5651Z"
        fill={color}
      />
    </svg>
  );
};
export default IconShareFeedPage;
