import React from "react";

const IconTotalReferralsConverted = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <circle cx="15" cy="9" r="4" stroke="#D4F70E" stroke-width="1.5" />
      <path
        d="M21 12C22.6569 12 24 10.8807 24 9.5C24 8.11929 22.6569 7 21 7"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M9 12C7.34315 12 6 10.8807 6 9.5C6 8.11929 7.34315 7 9 7"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M20.1973 18C20.7078 18.5883 21 19.2714 21 20C21 22.2091 18.3137 24 15 24C11.6863 24 9 22.2091 9 20C9 17.7909 11.6863 16 15 16C15.3407 16 15.6748 16.0189 16 16.0553"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M23 22C24.7542 21.6153 26 20.6411 26 19.5C26 18.3589 24.7542 17.3847 23 17"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M7 22C5.24575 21.6153 4 20.6411 4 19.5C4 18.3589 5.24575 17.3847 7 17"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};
export default IconTotalReferralsConverted;
