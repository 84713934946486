/**
 *
 * PrivateRoute
 *
 */
import { getJwtLocalStorage } from "helpers/local-storage";
import { getJwtSessionStorage } from "helpers/session";
import React, { memo } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  layout: any;
  component: any;
  exact: boolean;
  name: string;
  path: string;
}

export const PrivateRoute = memo(
  ({
    layout: YourLayout,
    component: YourComponent,
    ...rest
  }: PrivateRouteProps) => {
    const accessToken = getJwtLocalStorage() || getJwtSessionStorage();
    return (
      <Route
        {...rest}
        render={(routeProps) => {
          return !!accessToken ? (
            <YourLayout {...rest}>
              {YourComponent && <YourComponent {...routeProps} />}
            </YourLayout>
          ) : (
            <Redirect to="/auth/login" />
          );
        }}
      />
    );
  }
);
