import { createSelector } from "@reduxjs/toolkit";
import { SubscriptionPlan } from "app/models";
import { InitSubscriptionState } from "app/redux/reducers/subscription";
import { planType } from "constants/planType";

export const selectSubscription = (state: {
  subscription: InitSubscriptionState;
}) => state.subscription;

export const selectAllSubscriptions = createSelector(
  selectSubscription,
  ({ data }) => {
    const normalizeData = data.map((subscription: SubscriptionPlan) => {
      const { name, type, price } = subscription;
      return {
        ...subscription,
        name: `${name}Plan`,
        basicPrice: price,
        price: type === planType.FREE ? "free" : `${name}PlanPrice`,
        description: `${name}PlanDescription`,
        priceDescription: `${name}PriceDescription`,
        discountPrice: `${name}Discount`,
      };
    });
    return [
      ...normalizeData,
      {
        name: "proPlan",
        type: "MAINSTREAM",
        plan: "PRO",
        price: "",
        features: [],
        description: "comingSoon",
        _id: 3,
      },
    ];
  }
);

export const selectIsCreateNewLicenseSelector = createSelector(
  selectSubscription,
  ({ isCreateNewLicense }) => isCreateNewLicense
);
