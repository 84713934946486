import {
  LicensePayload,
  LicenseResponse,
} from "app/redux/actions/licenseAndContracts/interface";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";
class LicenseService {
  static async getLicenseContract(): Promise<LicenseResponse> {
    try {
      const res = await axiosService.get("/licenses");
      return res.data.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deleteLicenseContract(id: string): Promise<LicenseResponse> {
    try {
      const res = await axiosService.delete(`/licenses/${id}`);
      return res.data;
    } catch (e: any) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async createLicense(
    license: LicensePayload
  ): Promise<LicenseResponse> {
    try {
      const response = await axiosService.post("/licenses", license);
      const {
        data: { success, data },
      } = response;

      if (!success) {
        throw Error("Something went wrong. You cannot create a license.");
      }

      return data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async editLicense(
    id: string,
    license: LicensePayload
  ): Promise<LicenseResponse> {
    try {
      const response = await axiosService.put(`/licenses/${id}`, license);

      const {
        data: { success, data },
      } = response;

      if (!success) {
        throw Error("Something went wrong. You cannot edit a license.");
      }

      return data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async changeActiveLicense(id: string): Promise<LicenseResponse> {
    try {
      const response = await axiosService.patch(`/licenses/${id}`);
      const {
        data: { data },
      } = response;

      return data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async changeHighlightLicense(id: string): Promise<LicenseResponse> {
    try {
      const response = await axiosService.patch(`/licenses/highlight/${id}`);
      const {
        data: { data },
      } = response;
      return data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}

export default LicenseService;
