import React from "react";

const LogoSuperproductorHeaderMobile = () => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_16016_28008)">
        <path
          d="M40 27.5C40 30.8152 38.683 33.9946 36.3388 36.3388C33.9946 38.683 30.8152 40 27.5 40H12.5C9.18479 40 6.00537 38.683 3.66117 36.3388C1.31696 33.9946 0 30.8152 0 27.5H40ZM40 26.2492H12.5C9.18479 26.2492 6.00537 24.9323 3.66117 22.5881C1.31696 20.2439 0 17.0644 0 13.7492H27.5C30.8152 13.7492 33.9946 15.0662 36.3388 17.4104C38.683 19.7546 40 22.934 40 26.2492ZM40 12.5H0C0 9.18479 1.31696 6.00537 3.66117 3.66117C6.00537 1.31696 9.18479 0 12.5 0L27.5 0C30.8152 0 33.9946 1.31696 36.3388 3.66117C38.683 6.00537 40 9.18479 40 12.5Z"
          fill="url(#paint0_radial_16016_28008)"
        />
        <path
          d="M39.8996 27.6C39.8734 30.8526 38.57 33.9663 36.2681 36.2681C33.9427 38.5936 30.7887 39.9 27.5 39.9H12.5C9.21132 39.9 6.05733 38.5936 3.73188 36.2681C1.43001 33.9663 0.126599 30.8526 0.100403 27.6H39.8996ZM39.8996 26.1492H12.5C9.21132 26.1492 6.05733 24.8428 3.73188 22.5174C1.43001 20.2155 0.126599 17.1018 0.100403 13.8492H27.5C30.7887 13.8492 33.9427 15.1557 36.2681 17.4811C38.57 19.783 39.8734 22.8967 39.8996 26.1492ZM0.100403 12.4C0.126599 9.14744 1.43001 6.03375 3.73188 3.73188C6.05733 1.40642 9.21132 0.1 12.5 0.1H27.5C30.7887 0.1 33.9427 1.40642 36.2681 3.73188C38.57 6.03375 39.8734 9.14744 39.8996 12.4H0.100403Z"
          stroke="url(#paint1_linear_16016_28008)"
          stroke-width="0.2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_16016_28008"
          x="0"
          y="0"
          width="40"
          height="41"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16016_28008"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_16016_28008"
            result="shape"
          />
        </filter>
        <radialGradient
          id="paint0_radial_16016_28008"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-10.8337 -19.1674) rotate(52.7447) scale(74.3378 61.5077)"
        >
          <stop stop-color="#D8FD01" />
          <stop offset="0.385417" stop-color="#F4FFB3" />
          <stop offset="0.795958" stop-color="#D8FD01" />
          <stop offset="1" stop-color="#95AE04" />
        </radialGradient>
        <linearGradient
          id="paint1_linear_16016_28008"
          x1="2.50009"
          y1="7.91697"
          x2="30.0011"
          y2="30.0011"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default LogoSuperproductorHeaderMobile;
