import { useState, useEffect, useCallback, useMemo } from "react";

export const useAudio = (audioUrl: string) => {
  const [audio, setAudio] = useState(new Audio(audioUrl));
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [checkId, setCheckId] = useState("");

  const audioPlayer = useMemo(() => new Audio(audioUrl), [audioUrl]);

  useEffect(() => {
    setAudio(audioPlayer);
    setDuration(0);
    setCurrentTime(0);
  }, [audioPlayer, setDuration, setCurrentTime]);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing, audio]);

  useEffect(() => {
    const audioStateListener = audio.addEventListener("ended", () =>
      setPlaying(false)
    );
    return () => audioStateListener;
  }, [audio]);

  useEffect(() => {
    const loadAudioListener = audio.addEventListener("loadedmetadata", () => {
      if (audio.readyState > 0) {
        setDuration(audio.duration);
      }
    });
    return () => loadAudioListener;
  }, [audio]);

  const setCurrentTimeValue = useCallback(
    (value) => {
      const ele: any = document.getElementById(checkId);
      if (ele) {
        ele.value = value;
        ele.style.background =
          "linear-gradient(to right, #2c2d31 0%, #d3f50f " +
          value +
          "%, #38383c " +
          value +
          "%, #38383c 100%)";
      }
    },
    [checkId]
  );

  useEffect(() => {
    const timeUpdateListener = audio.addEventListener("timeupdate", () => {
      setCurrentTime(audio.currentTime);
      setCurrentTimeValue((audio.currentTime * 100) / duration);
    });
    return () => timeUpdateListener;
  }, [audio, duration, setCurrentTimeValue]);

  const toggleAudioPlay = useCallback(
    (isAudio) => {
      setPlaying(!playing);
      setCheckId(isAudio);
    },
    [playing]
  );

  const seekTo = useCallback(
    (e) => {
      audio.currentTime = (e.target.value * duration) / 100;
    },
    [audio, duration]
  );

  return {
    currentTime,
    playing,
    duration,
    toggleAudioPlay,
    seekTo,
    audio,
  };
};
