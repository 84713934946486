import React from "react";

const IconTypeFilter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M14.089 10.4777H0.712284C0.523375 10.4777 0.342202 10.4027 0.208623 10.2691C0.075044 10.1355 0 9.95432 0 9.76541V0.712284C0 0.523375 0.075044 0.342202 0.208623 0.208623C0.342202 0.0750441 0.523375 0 0.712284 0H14.089C14.2779 0 14.4591 0.0750441 14.5926 0.208623C14.7262 0.342202 14.8013 0.523375 14.8013 0.712284V9.76541C14.8013 9.95432 14.7262 10.1355 14.5926 10.2691C14.4591 10.4027 14.2779 10.4777 14.089 10.4777ZM1.42457 9.05313H13.3767V1.42457H1.42457V9.05313Z"
        fill="#D4F70E"
      />
      <path
        d="M16.6603 13.0843H3.28357C3.09466 13.0843 2.91349 13.0092 2.77991 12.8756C2.64633 12.7421 2.57129 12.5609 2.57129 12.372V10.0499C2.57129 9.86102 2.64633 9.67985 2.77991 9.54627C2.91349 9.41269 3.09466 9.33765 3.28357 9.33765C3.47248 9.33765 3.65365 9.41269 3.78723 9.54627C3.92081 9.67985 3.99586 9.86102 3.99586 10.0499V11.6597H15.948V4.0525H14.0889C13.9 4.0525 13.7188 3.97745 13.5853 3.84388C13.4517 3.7103 13.3766 3.52912 13.3766 3.34021C13.3766 3.1513 13.4517 2.97013 13.5853 2.83655C13.7188 2.70297 13.9 2.62793 14.0889 2.62793H16.6603C16.8492 2.62793 17.0303 2.70297 17.1639 2.83655C17.2975 2.97013 17.3726 3.1513 17.3726 3.34021V12.372C17.3726 12.5609 17.2975 12.7421 17.1639 12.8756C17.0303 13.0092 16.8492 13.0843 16.6603 13.0843Z"
        fill="#D4F70E"
      />
      <path
        d="M19.232 15.6701H5.85535C5.66644 15.6701 5.48527 15.595 5.35169 15.4615C5.21811 15.3279 5.14307 15.1467 5.14307 14.9578V12.6144C5.14307 12.4255 5.21811 12.2443 5.35169 12.1107C5.48527 11.9771 5.66644 11.9021 5.85535 11.9021C6.04426 11.9021 6.22543 11.9771 6.35901 12.1107C6.49259 12.2443 6.56763 12.4255 6.56763 12.6144V14.2455H18.5198V6.61695H16.6607C16.4718 6.61695 16.2906 6.54191 16.157 6.40833C16.0235 6.27475 15.9484 6.09358 15.9484 5.90467C15.9484 5.71576 16.0235 5.53459 16.157 5.40101C16.2906 5.26743 16.4718 5.19238 16.6607 5.19238H19.232C19.421 5.19238 19.6021 5.26743 19.7357 5.40101C19.8693 5.53459 19.9443 5.71576 19.9443 5.90467V14.9578C19.9443 15.1467 19.8693 15.3279 19.7357 15.4615C19.6021 15.595 19.421 15.6701 19.232 15.6701Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconTypeFilter;
