import { RecentAction, TrackSoundKit } from "app/models";
import {
  addRepostProfile,
  addRepostRecentAction,
  addRepostSKRecentAction,
  handleLikeOrUnlikeSoundKitProfile,
  handleLikeOrUnlikeSoundKitRecentActionProfile,
  handleLikeOrUnLikeTrack,
  handleLikeOrUnLikeTrackInRecentAction,
} from "utils/addFavorite";
import { updateFollowUnfollow } from "utils/convertData";
import {
  userRecentActions,
  userTracks,
  userSoundKits,
  userBulkOffers,
  userFollowers,
  updateLikeTrack,
  updateUnLikeTrack,
  updateLikeTrackInRecentAction,
  updateUnLikeTrackInRecentAction,
  updateAddRepostsTrack,
  updateAddRepostsTrackInRecentAction,
  updateLikeSoundKitInRecentAction,
  updateLikeSoundKit,
  updateUnLikesSoundKitsInRecentAction,
  updateUnLikeSoundKit,
  updateRepostsSoundKitInRecentAction,
  updateRepostSoundKits,
} from "../actions/profile";
import { followUnfollow } from "../actions/users";

const initialState = {
  currentUser: {},
  recentActions: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      hasMore: true,
    },
  },
  basicInformation: {
    isSelf: false,
    userId: "",
    firstName: undefined,
    lastName: undefined,
    name: undefined,
    location: undefined,
    aboutMe: undefined,
    credits: undefined,
    profileImage: undefined,
    followers: undefined,
    tracks: undefined,
    plays: undefined,
    isFollowing: undefined,
  },
  tracks: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      hasMore: true,
    },
  },
  soundKits: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      hasMore: true,
    },
  },
  bulkOffers: [],
  followers: [],
  isLoading: false,
};

const profileReducer = (state = initialState, { payload, type }: any) => {
  switch (type) {
    case userRecentActions.REQUEST:
      return {
        ...state,
        isLoading: true,
        recentActions: {
          ...state.recentActions,
          options: {
            ...state.recentActions.options,
            page: payload?.page,
            loadingMore: payload?.loadingMore || false,
          },
        },
      };
    case userRecentActions.SUCCESS:
      return {
        ...state,
        recentActions: {
          ...state.recentActions,
          items:
            state.recentActions.options.page === 1
              ? Array.from(payload.items)
              : [...state.recentActions.items, ...payload.items],
          options: {
            ...state.recentActions.options,
            hasMore: payload.hasMoreItems,
            loadingMore: false,
          },
        },
        isLoading: false,
      };
    case userRecentActions.ERROR:
      return {
        isLoading: false,
        recentActions: {
          ...state.recentActions,
          options: {
            ...state.recentActions.options,
            loadingMore: false,
            page: state.recentActions.options.page - 1,
          },
        },
      };
    case userTracks.REQUEST:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          options: {
            ...state.tracks.options,
            page: payload?.page,
            loadingMore: payload?.loadingMore || false,
          },
        },
        isLoading: true,
      };
    case userTracks.SUCCESS:
      return {
        ...state,
        tracks: {
          ...state.tracks,
          items:
            state.tracks.options.page === 1
              ? Array.from(payload)
              : [...state.tracks.items, ...payload],
          options: {
            ...state.tracks.options,
            hasMore: payload.length === 25,
            loadingMore: false,
          },
        },
        isLoading: false,
      };
    case userTracks.ERROR:
      return {
        isLoading: false,
        tracks: {
          ...state.tracks,
          options: {
            ...state.tracks.options,
            loadingMore: false,
            page: state.tracks.options.page - 1,
          },
        },
      };
    case userSoundKits.REQUEST:
      return {
        ...state,
        soundKits: {
          ...state.soundKits,
          options: {
            ...state.soundKits.options,
            page: payload?.page,
            loadingMore: payload?.loadingMore || false,
          },
        },
        isLoading: true,
      };
    case userSoundKits.SUCCESS:
      return {
        ...state,
        soundKits: {
          ...state.soundKits,
          items:
            state.soundKits.options.page === 1
              ? Array.from(payload)
              : [...state.soundKits.items, ...payload],
          options: {
            ...state.soundKits.options,
            hasMore: payload.length === 25,
            loadingMore: false,
          },
        },
        isLoading: false,
      };
    case userSoundKits.ERROR:
      return {
        isLoading: false,
        soundKits: {
          ...state.soundKits,
          options: {
            ...state.soundKits.options,
            loadingMore: false,
            page: state.tracks.options.page - 1,
          },
        },
      };
    case userBulkOffers.REQUEST:
      return {
        ...state,
        bulkOffers: [],
      };
    case userBulkOffers.SUCCESS:
      return {
        ...state,
        bulkOffers: payload,
      };
    case userFollowers.REQUEST:
      return {
        ...state,
        followers: [],
      };
    case userFollowers.SUCCESS:
      return {
        ...state,
        followers: payload,
      };

    case updateLikeTrack.SUCCESS:
      return handleLikeOrUnLikeTrack(state, payload, true);

    case updateUnLikeTrack.SUCCESS:
      return handleLikeOrUnLikeTrack(state, payload, false);

    case updateLikeTrackInRecentAction.SUCCESS:
      return handleLikeOrUnLikeTrackInRecentAction(state, payload, true);

    case updateUnLikeTrackInRecentAction.SUCCESS:
      return handleLikeOrUnLikeTrackInRecentAction(state, payload, false);

    case updateAddRepostsTrack.SUCCESS:
      const trackReposted = (
        (state.tracks.items as TrackSoundKit[]) || []
      ).find((item: TrackSoundKit) => item._id === payload._id);

      const newTrackRepost = addRepostProfile(
        state.tracks.items,
        trackReposted,
        true
      );
      return {
        ...state,
        tracks: {
          ...state.tracks,
          items: [...newTrackRepost],
        },
      };

    case updateAddRepostsTrackInRecentAction.SUCCESS:
      const recentActionReposted = (
        (state.recentActions.items as RecentAction[]) || []
      ).find((it: RecentAction) => it?.track?._id === payload._id);

      const newArrRecentAction = addRepostRecentAction(
        state.recentActions.items,
        recentActionReposted,
        true
      );
      return {
        ...state,
        recentActions: {
          ...state.recentActions,
          items: [...newArrRecentAction],
        },
      };
    case updateLikeSoundKitInRecentAction.SUCCESS:
      return handleLikeOrUnlikeSoundKitRecentActionProfile(
        state,
        payload,
        true
      );

    case updateLikeSoundKit.SUCCESS:
      return handleLikeOrUnlikeSoundKitProfile(state, payload, true);

    case updateUnLikesSoundKitsInRecentAction.SUCCESS:
      return handleLikeOrUnlikeSoundKitRecentActionProfile(
        state,
        payload,
        false
      );

    case updateUnLikeSoundKit.SUCCESS:
      return handleLikeOrUnlikeSoundKitProfile(state, payload, false);

    case updateRepostsSoundKitInRecentAction.SUCCESS:
      const soundKitRecentActionRepost = (
        (state.recentActions.items as RecentAction[]) || []
      ).find((it: RecentAction) => it?.soundkit?._id === payload._id);

      const newRepost = addRepostSKRecentAction(
        state.recentActions.items,
        soundKitRecentActionRepost,
        true
      );
      return {
        ...state,
        recentActions: {
          ...state.recentActions,
          items: [...newRepost],
        },
      };
    case updateRepostSoundKits.SUCCESS:
      const soundKitRepost = (
        (state.soundKits.items as TrackSoundKit[]) || []
      ).find((item: TrackSoundKit) => item._id === payload._id);

      const newSoundKitRepost = addRepostProfile(
        state.soundKits.items,
        soundKitRepost,
        true
      );
      return {
        ...state,
        soundKits: {
          ...state.soundKits,
          items: [...newSoundKitRepost],
        },
      };

    case followUnfollow.SUCCESS:
      return {
        ...state,
        isLoading: false,
        recentActions: {
          ...state.recentActions,
          items: state.recentActions.items.map((item: RecentAction) => {
            if (item.createdBy._id === payload) {
              return {
                ...item,
                createdBy: {
                  ...item.createdBy,
                  isFollowing: updateFollowUnfollow(item).isFollowing,
                  followers: updateFollowUnfollow(item).followers,
                },
              };
            } else {
              return item;
            }
          }),
        },
      };

    default:
      return state;
  }
};
export default profileReducer;
