import { createReducer } from "@reduxjs/toolkit";
import { CollaborationsModel } from "app/models";
import * as collaborationsActions from "app/redux/actions/collaborations";

export interface InitCollaborationsState {
  collaborations: CollaborationsModel[];
  isLoading: boolean;
}

const initialState: InitCollaborationsState = {
  collaborations: [],
  isLoading: false,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      collaborationsActions.getCollaborations.pending,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    )
    .addCase(
      collaborationsActions.getCollaborations.fulfilled,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
          collaborations: action.payload,
        };
      }
    )
);
