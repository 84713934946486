import { Box, Flex, Grid, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { selectUserAdmin } from "app/redux/selectors/users";
import { TRACK_LICENSE } from "constants/contractType";
import trackTypes from "constants/trackTypes";
import React, { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { onHandleGetCollaborations } from "utils/convertCollaborators";
import FilesToDeliver from "./FilesToDeliver";
import SelectInput from "./SelectInput";
import "./styles.scss";
import TextInput from "./TextInput";
import { URLTRACKSOUNDKIT } from "constants/index";
import { slugTrackSoundkit } from "utils/convertTracks";
import { CHARACTER_TITLE } from "constants/statusType";
import CharaterLimitError from "./charaterLimitError";
interface GeneralInfoProps {
  isSoundKit?: boolean;
}

export default function GeneralInfo({ isSoundKit }: GeneralInfoProps) {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext();
  const collaborators = watch("collaborators");
  const licenseWatch = watch("licenses");
  const title = watch("title");
  const userAdmin = useSelector(selectUserAdmin);
  const contractTypes = [
    { label: t("nonExclusive"), value: TRACK_LICENSE.NON_EXCLUSIVE },
    {
      label: t("exclusive"),
      value: TRACK_LICENSE.EXCLUSIVE,
    },
  ];

  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <Box position="relative">
      <Text
        fontSize="20px"
        lineHeight="24px"
        mt="30px"
        fontFamily="Cera Pro Bold"
      >
        {t("generalInfo")}
      </Text>
      <Grid
        templateColumns="repeat(6, 1fr)"
        gap={4}
        mt={{ base: "20px", md: "30px" }}
        display={{ base: "block", lg: "grid" }}
      >
        <GridItem
          colSpan={4}
          w={{ base: "100%", lg: "96%" }}
          mb={{ base: "15px", lg: "0px" }}
        >
          <TextInput
            id="title"
            type="text"
            name="title"
            label={isSoundKit ? t("soundKitTitle") : t("trackTitle")}
            placeholder={isSoundKit ? t("soundKitName") : t("enterTrackTitle")}
            errors={errors}
            control={control}
            maxLength={CHARACTER_TITLE.MAXIMUM_CHARACTERS}
          />
          <CharaterLimitError title={title} />

          <SimpleGrid
            columns={{ base: 1, md: 3 }}
            spacing="10px"
            w="100%"
            mt="15px"
          >
            {!isSoundKit && (
              <SelectInput
                id="contractType"
                name={"contractType"}
                placeholder={t("SelectTypeOfContract")}
                label={t("typeOfContract")}
                data={contractTypes}
                control={control}
                onValueChange={(value) =>
                  onHandleGetCollaborations({
                    contractType: value,
                    licenseWatch: licenseWatch,
                    collaborators: collaborators,
                    setValue: setValue,
                    currentUser: userAdmin,
                  })
                }
                error={errors?.contractType?.message}
              />
            )}
            <Box>
              <SelectInput
                id="type"
                name={"type"}
                placeholder={t("SelectTrackType")}
                label={isSoundKit ? t("soundKitType") : t("trackType")}
                data={trackTypes}
                error={errors?.type?.message}
                control={control}
              />
            </Box>
            <TextInput
              name="releaseDate"
              label={t("releaseDate")}
              type={"datetime-local"}
              errors={errors}
              control={control}
            />
          </SimpleGrid>

          <Flex
            alignItems="center"
            mt="27px"
            background="#25252A"
            padding={{ base: "15px 10px", md: "15px" }}
            borderRadius="6px"
          >
            <Text
              color="#535353"
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="18px"
              mr="5px"
              fontFamily="Cera Pro Regular"
            >
              URL:
            </Text>
            <Text
              noOfLines={2}
              fontSize={{ base: "14px", md: "16px" }}
              lineHeight="18px"
              fontFamily="Cera Pro Regular"
            >
              {`${URLTRACKSOUNDKIT.track}${slugTrackSoundkit(title || "")}`}
            </Text>
          </Flex>
        </GridItem>
        <FilesToDeliver />
      </Grid>
    </Box>
  );
}
