import { createAsyncThunk } from "@reduxjs/toolkit";
import GenresService from "app/apis/genres";

export const TYPES = {
  FETCH_GENRES: "FETCH_GENRES",
};

export const fetchGenres = createAsyncThunk(
  TYPES.FETCH_GENRES,
  GenresService.loadAllGenres
);
