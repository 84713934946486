import React from "react";

const IconDiscordDashboardMobile = () => {
  return (
    <svg
      width="133"
      height="25"
      viewBox="0 0 133 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_467_30514)">
        <path
          d="M44.6719 5.09038H51.8076C53.303 5.04904 54.7905 5.31328 56.1724 5.86571C57.2665 6.31075 58.1976 7.06073 58.8463 8.01939C59.4447 8.97342 59.7519 10.0707 59.7327 11.1853C59.7477 12.3043 59.427 13.4037 58.8091 14.3512C58.1217 15.3565 57.1388 16.1414 55.9862 16.6054C54.5006 17.1989 52.9009 17.4803 51.2936 17.431H44.6719V5.09038ZM51.2117 14.2938C52.1811 14.3596 53.1394 14.0612 53.8856 13.461C54.203 13.1666 54.4506 12.8095 54.6113 12.4142C54.772 12.0189 54.8421 11.5948 54.8167 11.171C54.84 10.7765 54.7787 10.3816 54.6364 10.011C54.4941 9.64041 54.2741 9.30211 53.9899 9.01727C53.6459 8.73007 53.2464 8.51121 52.8144 8.37323C52.3823 8.23524 51.9262 8.18087 51.4723 8.21322H49.2378V14.2938H51.2117Z"
          fill="white"
        />
        <path
          d="M70.4241 17.4522C69.4812 17.2263 68.5794 16.8631 67.75 16.3754V13.4105C68.4749 13.9179 69.2905 14.2926 70.1559 14.516C71.1024 14.7953 72.0859 14.9404 73.0757 14.9467C73.4196 14.9672 73.7637 14.9107 74.0812 14.7816C74.3047 14.6739 74.4164 14.5304 74.4164 14.3868C74.4187 14.3043 74.4033 14.2222 74.3713 14.1456C74.3392 14.0691 74.2912 13.9996 74.2302 13.9417C74.0113 13.7839 73.7555 13.6804 73.4854 13.6402L71.3253 13.1448C70.3406 12.9879 69.4159 12.5843 68.6439 11.9747C68.3795 11.7164 68.173 11.4085 68.0372 11.0701C67.9014 10.7318 67.8391 10.3701 67.8543 10.0076C67.8478 9.35055 68.0977 8.7151 68.5545 8.22725C69.0934 7.67409 69.7743 7.26794 70.5284 7.04991C71.4924 6.76003 72.498 6.61948 73.5077 6.63353C74.4591 6.62585 75.4076 6.7344 76.3307 6.95658C77.0768 7.12193 77.7925 7.39546 78.4535 7.7678V10.5604C77.8311 10.2126 77.1619 9.94925 76.4648 9.7779C75.7098 9.58002 74.9307 9.48102 74.1483 9.48357C73.0012 9.48357 72.4277 9.67022 72.4277 10.0507C72.427 10.1337 72.4512 10.2152 72.4975 10.2852C72.5437 10.3553 72.61 10.411 72.6883 10.4455C73.0027 10.5764 73.3332 10.668 73.6716 10.7183L75.4964 11.0342C76.4687 11.1409 77.3902 11.5091 78.1556 12.0967C78.4612 12.3807 78.6983 12.7259 78.8502 13.108C79.002 13.4901 79.065 13.8997 79.0345 14.3078C79.0412 14.8215 78.907 15.3277 78.6454 15.7752C78.3838 16.2228 78.0041 16.5957 77.5448 16.8563C76.2912 17.5505 74.8539 17.875 73.4109 17.7896C72.4046 17.798 71.4011 17.6846 70.4241 17.4522Z"
          fill="white"
        />
        <path
          d="M83.3888 17.0502C82.4096 16.6155 81.5837 15.9143 81.0128 15.0329C80.4958 14.1597 80.2381 13.1663 80.268 12.1613C80.2455 11.1467 80.533 10.1481 81.0947 9.28976C81.6858 8.45668 82.5096 7.80205 83.4708 7.40169C84.6697 6.89679 85.969 6.65172 87.277 6.6838C88.875 6.62398 90.4572 7.00771 91.8354 7.78936V10.9912C91.3788 10.6817 90.8767 10.4397 90.3458 10.2733C89.7535 10.0914 89.1349 10.0018 88.5134 10.0076C87.5853 9.95849 86.6622 10.1678 85.8543 10.6107C85.5679 10.7682 85.3298 10.996 85.1642 11.2708C84.9986 11.5457 84.9113 11.8579 84.9113 12.1757C84.9113 12.4935 84.9986 12.8057 85.1642 13.0806C85.3298 13.3554 85.5679 13.5832 85.8543 13.7407C86.6774 14.1936 87.6195 14.4056 88.5656 14.3509C89.1757 14.3499 89.7827 14.2653 90.3681 14.0997C90.8893 13.9478 91.3895 13.7356 91.8578 13.4679V16.5907C90.4418 17.3861 88.8262 17.7909 87.1876 17.7609C85.882 17.8021 84.5836 17.5592 83.3888 17.0502Z"
          fill="white"
        />
        <path
          d="M96.3899 17.6177C95.3994 17.1678 94.5593 16.4443 93.9692 15.5333C93.4165 14.6424 93.1298 13.6133 93.1424 12.5661C93.115 11.5172 93.4029 10.484 93.9692 9.59903C94.5658 8.7145 95.4028 8.01728 96.3825 7.58881C98.7874 6.61688 101.478 6.61688 103.883 7.58881C104.859 8.01267 105.692 8.70809 106.282 9.59162C106.844 10.4782 107.132 11.5103 107.108 12.5587C107.126 13.6237 106.829 14.6704 106.255 15.5686C105.68 16.4668 104.853 17.1768 103.876 17.6102C102.698 18.1114 101.432 18.3698 100.151 18.3698C98.8712 18.3698 97.6043 18.1114 96.4271 17.6102L96.3899 17.6177ZM101.954 14.3835C102.179 14.1494 102.354 13.8722 102.468 13.5687C102.582 13.2652 102.632 12.9417 102.617 12.6181C102.634 12.2987 102.584 11.9793 102.47 11.6803C102.356 11.3814 102.18 11.1096 101.954 10.8823C101.707 10.6582 101.418 10.4851 101.103 10.3731C100.789 10.261 100.455 10.2122 100.122 10.2295C99.4483 10.1979 98.7896 10.4325 98.2892 10.8823C98.0633 11.1096 97.8876 11.3814 97.7734 11.6803C97.6593 11.9793 97.6092 12.2987 97.6264 12.6181C97.6117 12.9416 97.6629 13.2648 97.7768 13.5682C97.8907 13.8716 98.065 14.1489 98.2892 14.3835C98.5343 14.6107 98.823 14.7862 99.1378 14.8997C99.4527 15.0131 99.7873 15.0621 100.122 15.0437C100.456 15.0647 100.791 15.017 101.107 14.9034C101.422 14.7898 101.71 14.6128 101.954 14.3835Z"
          fill="white"
        />
        <path
          d="M118.102 7.30803V11.1201C117.557 10.805 116.927 10.6526 116.292 10.6821C115.878 10.6592 115.463 10.7238 115.077 10.8714C114.691 11.0189 114.343 11.2459 114.058 11.5364C113.486 12.3068 113.222 13.2497 113.313 14.1926V17.4519H108.844V7.1142H113.238V10.395C113.378 9.42294 113.787 8.50524 114.423 7.73877C114.686 7.45771 115.009 7.23557 115.371 7.08798C115.732 6.94039 116.123 6.87093 116.516 6.88447C117.075 6.87924 117.625 7.02603 118.102 7.30803Z"
          fill="white"
        />
        <path
          d="M133.01 4.73828V17.4522H128.541V15.119C128.202 15.9442 127.592 16.64 126.805 17.1004C125.944 17.5899 124.959 17.8385 123.96 17.8183C123.019 17.8363 122.094 17.5866 121.301 17.1004C120.534 16.6201 119.925 15.9407 119.543 15.1406C119.12 14.2592 118.909 13.2973 118.925 12.3264C118.894 11.318 119.119 10.3176 119.58 9.41178C119.997 8.58094 120.651 7.88138 121.465 7.39449C122.3 6.91015 123.26 6.66143 124.235 6.67659C125.159 6.60398 126.081 6.82704 126.86 7.31119C127.638 7.79534 128.229 8.51352 128.541 9.35434V4.75982L133.01 4.73828ZM127.863 13.8627C128.092 13.6471 128.272 13.3873 128.39 13.1004C128.508 12.8134 128.562 12.5056 128.548 12.1972C128.559 11.8976 128.503 11.5993 128.385 11.322C128.267 11.0447 128.089 10.7949 127.863 10.5891C127.35 10.1856 126.708 9.96526 126.045 9.96526C125.383 9.96526 124.741 10.1856 124.228 10.5891C123.793 11.0308 123.55 11.6167 123.55 12.2259C123.55 12.8351 123.793 13.4211 124.228 13.8627C124.466 14.0797 124.747 14.2477 125.054 14.3563C125.362 14.4649 125.689 14.5119 126.016 14.4945C126.352 14.5156 126.689 14.4705 127.007 14.3619C127.324 14.2533 127.616 14.0834 127.863 13.8627Z"
          fill="white"
        />
        <path
          d="M28.1624 2.03164C25.9784 1.06649 23.6718 0.383384 21.3024 0C20.9783 0.55661 20.6848 1.12933 20.4234 1.71577C17.9002 1.34959 15.3343 1.34959 12.8111 1.71577C12.5528 1.12795 12.2593 0.555066 11.9322 0C9.56242 0.385839 7.25581 1.07136 5.07212 2.03882C1.19828 7.31943 -0.553958 13.7763 0.141238 20.2087C2.68227 22.019 5.5266 23.3957 8.55055 24.2792C9.23544 23.3987 9.83864 22.4618 10.3531 21.4794C9.3688 21.1259 8.4191 20.6887 7.51525 20.1728L8.21541 19.6703C10.8465 20.8617 13.7177 21.4794 16.6247 21.4794C19.5317 21.4794 22.403 20.8617 25.034 19.6703C25.2549 19.8503 25.4862 20.0181 25.7268 20.1728C24.8293 20.6881 23.8872 21.1276 22.9112 21.4866C23.433 22.4628 24.0358 23.3967 24.7138 24.2792C27.7415 23.4028 30.5891 22.0281 33.1305 20.2159C33.82 13.7742 32.0541 7.31068 28.1624 2.03164ZM11.098 16.5547C10.2766 16.4708 9.51649 16.0969 8.96407 15.5051C8.41166 14.9132 8.10602 14.1453 8.10602 13.3493C8.10602 12.5532 8.41166 11.7853 8.96407 11.1934C9.51649 10.6016 10.2766 10.2277 11.098 10.1439C11.5129 10.164 11.9196 10.2632 12.2945 10.4358C12.6694 10.6084 13.0051 10.8509 13.2822 11.1493C13.5592 11.4477 13.7722 11.7961 13.9087 12.1744C14.0452 12.5526 14.1025 12.9531 14.0774 13.3528C14.1161 14.1566 13.8254 14.9428 13.268 15.5419C12.7106 16.1409 11.9312 16.5046 11.098 16.5547ZM22.144 16.5547C21.3227 16.4708 20.5626 16.0969 20.0102 15.5051C19.4578 14.9132 19.1521 14.1453 19.1521 13.3493C19.1521 12.5532 19.4578 11.7853 20.0102 11.1934C20.5626 10.6016 21.3227 10.2277 22.144 10.1439C22.5593 10.163 22.9665 10.2616 23.3419 10.4339C23.7173 10.6062 24.0534 10.8487 24.3306 11.1473C24.6079 11.4459 24.8207 11.7947 24.9568 12.1733C25.0929 12.552 25.1496 12.9529 25.1234 13.3528C25.1643 14.1536 24.8774 14.9378 24.3243 15.5366C23.7713 16.1355 22.9963 16.501 22.1664 16.5547H22.144Z"
          fill="white"
        />
        <path
          d="M63.5158 8.21989C64.7499 8.21989 65.7503 7.35208 65.7503 6.28158C65.7503 5.21107 64.7499 4.34326 63.5158 4.34326C62.2817 4.34326 61.2812 5.21107 61.2812 6.28158C61.2812 7.35208 62.2817 8.21989 63.5158 8.21989Z"
          fill="white"
        />
        <path
          d="M61.2812 9.55542C61.9887 9.84211 62.7484 9.98978 63.5158 9.98978C64.2832 9.98978 65.0429 9.84211 65.7503 9.55542V17.4882H61.2812V9.55542Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_467_30514">
          <rect width="133" height="24.2864" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconDiscordDashboardMobile;
