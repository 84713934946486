import * as yup from "yup";

const formSchema = (minOfferPrice = 0) => {
  return yup.object().shape({
    expiredDate: yup.string().required("Expire date is required"),
    offerPrice: yup
      .number()
      .min(minOfferPrice, `Your offer is lower than the minimum offer price`)
      .required("Offer price is require"),
  });
};

export default formSchema;
