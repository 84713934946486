import { Box, Flex, Image, Text } from "@chakra-ui/react";
import IconEmptyAnalytics from "app/assets/icons/EmptyAnalytics";
import { TopUserAnalytics } from "app/models";
import { getListAnalyticsUser } from "app/redux/actions/Analytics";
import { selectorsListUser } from "app/redux/selectors/analytics";
import { timeType, totalAnalyticsTrack } from "constants/analyticsType";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EmptyAnalytic from "../EmptyAnalytics";
import ImageDefault from "app/assets/images/ImageDefaultLoading.png";

import { isLoadingSelector } from "app/redux/selectors/status";
import { FadeLoader } from "react-spinners";
import { RootState } from "types";
import OptionsAnalytics from "./optionsAnalytics";
import "./styles.scss";
import { Link } from "react-router-dom";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import UpgradeYourPlan from "../NotFoundPage/upgradeYourPlan";

interface TopUserItemProps {
  selectOptionMost: string;
  setSelectOptionMost: (value: string) => void;
}

export default function TopUserItem({
  selectOptionMost,
  setSelectOptionMost,
}: TopUserItemProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectOptionPeriod, setSelectOptionPeriod] = useState(timeType.DAY);

  const analyticsTopUser = useSelector(selectorsListUser);
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  useEffect(() => {
    if (!checkPlanSubscription) {
      dispatch(
        getListAnalyticsUser({
          timeType: selectOptionPeriod,
          analyticType: selectOptionMost,
        })
      );
    }
  }, [checkPlanSubscription, dispatch, selectOptionMost, selectOptionPeriod]);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getListAnalyticsUser.typePrefix], state)
  );

  const renderTopUserItem = useCallback(
    () => (
      <Box maxHeight="450px" overflow="scroll">
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="50vh"
          >
            <FadeLoader color={"#d4f70e"} loading={isLoading} />
          </Box>
        ) : (analyticsTopUser || []).length <= 0 ? (
          <EmptyAnalytic
            title={t("youHaveNoAnalytics")}
            icon={<IconEmptyAnalytics />}
          />
        ) : (
          (analyticsTopUser || []).map(
            (item: TopUserAnalytics, index: number) => {
              const totalPrice = item.user?.purchases?.map(
                (item) => item?.price
              );
              return (
                <Flex
                  key={item._id}
                  alignItems="center"
                  mb="20px"
                  borderBottom="1px solid rgba(255, 255, 255, 0.06)"
                  padding="10px"
                  justifyContent="space-between"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    w="50%"
                    overflow="hidden"
                  >
                    <Text mr="30px">{index + 1}</Text>
                    <Link to={`/user/${item.user.name}`}>
                      <Flex alignItems="center">
                        <Image
                          w="48px"
                          height="48px"
                          src={item.user.profileImage}
                          fallbackSrc={ImageDefault}
                          borderRadius="full"
                        />
                        <Box ml="20px" overflow="hidden">
                          <Text
                            _hover={{
                              textDecor: "underline",
                            }}
                            noOfLines={1}
                          >
                            {item.user.name}
                          </Text>
                        </Box>
                      </Flex>
                    </Link>
                  </Box>
                  <Flex>
                    <Text mr="30px" fontSize="16px" lineHeight="16px">
                      {item.total}
                    </Text>
                    <Text
                      mr="10px"
                      color="#d4f70e"
                      fontSize="16px"
                      lineHeight="16px"
                      fontWeight="600"
                      minW="60px"
                    >
                      $
                      {Number(totalAnalyticsTrack(totalPrice))?.toFixed(2) || 0}
                    </Text>
                  </Flex>
                </Flex>
              );
            }
          )
        )}
      </Box>
    ),
    [analyticsTopUser, isLoading, t]
  );

  return (
    <Flex w={"100%"} padding={{ base: "16px", md: "30px" }}>
      <Box overflowY="auto" w="100%">
        <OptionsAnalytics
          selectOptionMost={selectOptionMost}
          selectOptionPeriod={selectOptionPeriod}
          setSelectOptionMost={setSelectOptionMost}
          setSelectOptionPeriod={setSelectOptionPeriod}
          isTopUser
        />
        {checkPlanSubscription ? <UpgradeYourPlan /> : renderTopUserItem()}
      </Box>
    </Flex>
  );
}
