import React from "react";

const IconGoogle = () => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M5.319 14.504l-.835 3.118-3.054.065A11.946 11.946 0 010 12c0-1.99.484-3.866 1.342-5.519l2.719.499 1.19 2.702A7.133 7.133 0 004.868 12c0 .881.16 1.725.452 2.504z"
          fill="#FBBB00"
        />
        <path
          d="M23.79 9.758a12.028 12.028 0 01-.053 4.747 11.998 11.998 0 01-4.225 6.853v-.001l-3.424-.175-.485-3.025a7.152 7.152 0 003.077-3.652h-6.416V9.758H23.79z"
          fill="#518EF8"
        />
        <path
          d="M19.511 21.358h.001A11.95 11.95 0 0112 24c-4.57 0-8.543-2.554-10.57-6.313l3.888-3.183a7.135 7.135 0 0010.285 3.654l3.908 3.2z"
          fill="#28B446"
        />
        <path
          d="M19.66 2.763l-3.888 3.182a7.137 7.137 0 00-10.52 3.736l-3.91-3.2A11.998 11.998 0 0112 0a11.95 11.95 0 017.66 2.763z"
          fill="#F14336"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconGoogle;
