import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Image,
} from "@chakra-ui/react";
import { LyricsModel, TrackSoundKit } from "app/models";
import {
  createLyrics,
  getLyrics,
  updateLyrics,
} from "app/redux/actions/tracks";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import TextInput from "../TextInput";
import EmptyLyrics from "./emptyLyrics";
import Skeleton from "../common/Skeleton/skeleton";
import SkeletonShowLyrics from "./skeletonShowLyrics";
import { BeatLoader } from "react-spinners";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import { TRACK_TYPE } from "constants/trackTypes";
import CartIcon from "../CartIcon";
import { isNotForSaleTrackSoundPacks } from "utils/convertTracks";
import { isInCartSelector } from "app/redux/selectors/cart";
import { removeFromCart } from "app/redux/actions/cart";
import { getPriceTrack } from "utils/getPrice";

interface ModalLyricsProps {
  showModal: boolean;
  setShowModal: () => void;
  selectedTrack: TrackSoundKit | null;
  onOpenOptionsModal: (track: TrackSoundKit, isLyric?: boolean) => void;
  isEditLyrics: boolean;
  setIsEditLyrics: (a: boolean) => boolean;
  lyricsData: LyricsModel;
}
export default function ModalLyrics({
  showModal,
  setShowModal,
  selectedTrack,
  onOpenOptionsModal,
  isEditLyrics = false,
  setIsEditLyrics,
  lyricsData,
}: ModalLyricsProps) {
  const { t } = useTranslation();
  const isLoadingSuccess = useSelector((state: RootState) =>
    isLoadingSelector([createLyrics.typePrefix], state)
  );
  const isInCart = useSelector(isInCartSelector(selectedTrack));

  const isDisabled = isNotForSaleTrackSoundPacks(selectedTrack);

  const isLoadingUpdate = useSelector((state: RootState) =>
    isLoadingSelector([updateLyrics.typePrefix], state)
  );
  const isLoadingGetLyrics = useSelector((state: RootState) =>
    isLoadingSelector([getLyrics.typePrefix], state)
  );

  const isSuccessCreateLyrics = useSelector((state: RootState) =>
    successSelector([createLyrics.typePrefix], state)
  );

  const isSuccessUpdateLyrics = useSelector((state: RootState) =>
    successSelector([updateLyrics.typePrefix], state)
  );
  const checkIsSK = useMemo(() => {
    return selectedTrack?.soundkitState;
  }, [selectedTrack?.soundkitState]);

  useEffect(() => {
    if (isSuccessCreateLyrics || isSuccessUpdateLyrics) {
      setIsEditLyrics(false);
    }
  }, [
    isSuccessCreateLyrics,
    isSuccessUpdateLyrics,
    setIsEditLyrics,
    lyricsData,
  ]);

  const dispatch = useDispatch();
  const { control, reset, formState, handleSubmit, watch } =
    useForm<LyricsModel>({
      defaultValues: lyricsData,
    });

  const { lyricTitle, lyrics } = watch();
  const handleChosseEdit = useCallback(() => {
    setIsEditLyrics(!isEditLyrics);
  }, [setIsEditLyrics, isEditLyrics]);

  const handleEditLyrics = useCallback(
    (values) => {
      const newLyrics = {
        lyrics: values?.lyrics,
        lyricTitle: values?.lyricTitle,
      };
      const idTrackSoundPack = !!checkIsSK
        ? { soundPackId: selectedTrack?._id }
        : { trackId: selectedTrack?._id };

      if (!lyricsData?._id) {
        dispatch(createLyrics({ ...newLyrics, ...idTrackSoundPack }));
        reset();
        return;
      }
      dispatch(updateLyrics({ ...newLyrics, _id: lyricsData?._id }));
      reset();
    },
    [checkIsSK, dispatch, lyricsData?._id, reset, selectedTrack?._id]
  );

  const isExclusive = useMemo(
    () =>
      (selectedTrack?.licenses || [])?.every((license) => license.exclusive),
    [selectedTrack]
  );

  const isMakeOffersOnly = useMemo(
    () =>
      (selectedTrack?.licenses || [])?.every(
        (license) => license?.makeOffersOnly
      ),
    [selectedTrack?.licenses]
  );

  const handleAddToCart = useCallback(
    (e) => {
      if (isDisabled) {
        return;
      }
      if (isInCart) {
        dispatch(removeFromCart([selectedTrack?._id]));
      } else {
        onOpenOptionsModal(selectedTrack);
        e.preventDefault();
      }
    },
    [dispatch, isDisabled, isInCart, onOpenOptionsModal, selectedTrack]
  );

  useEffect(() => {
    reset(lyricsData);
  }, [lyricsData, reset]);

  const isLyricsData = !!lyricsData.lyricTitle || !!lyricsData.lyrics;

  const renderEditLyrics = () => {
    if (isEditLyrics) {
      return (
        <Box mt="20px">
          <Text mb="10px">{t("lyricTitle")}</Text>
          <Box w={{ base: "100%", md: "70%" }}>
            <TextInput
              border="none"
              backgroundColor="#25252A"
              type="text"
              name="lyricTitle"
              placeholder={t("writeLyricTitle")}
              errors={formState.errors}
              control={control}
            />
          </Box>
          <Text mb="10px" mt="15px">
            {t("lyrics")}
          </Text>
          <Box>
            <TextInput
              backgroundColor="#25252A"
              type="textarea"
              name="lyrics"
              placeholder={t("writeLyricshere")}
              errors={formState.errors}
              control={control}
            />
          </Box>
        </Box>
      );
    }
    return isLyricsData ? (
      <Box>
        <Text
          fontSize={{ base: "18px", md: "24px" }}
          mt={{ base: "20px", md: "40px" }}
        >
          {lyricsData?.lyricTitle || "No title"}
        </Text>

        <Text
          fontSize={{ base: "14px", md: "16px" }}
          lineHeight="24px"
          mt={{ base: "15px", md: "25px" }}
          whiteSpace="pre-wrap"
          wordBreak="break-word"
        >
          {lyricsData?.lyrics || "No Lyrics"}
        </Text>
      </Box>
    ) : (
      <EmptyLyrics />
    );
  };

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={showModal}
      onClose={setShowModal}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        minW={{ base: "350px", md: "650px", lg: "900px" }}
        margin={{ base: "0px 10px", md: "0px" }}
      >
        {isLoadingGetLyrics ? (
          <Box padding={{ base: "20px 10px", md: "20px 40px" }}>
            <Skeleton w="171px" h="32px" />
          </Box>
        ) : (
          <ModalHeader
            padding={{ base: "20px 10px", md: "20px 40px" }}
            fontSize={{ base: "24px", md: "28px", lg: "32px" }}
            lineHeight="24px"
            className="fontCeraPro"
          >
            {t("writeLyrics")}
          </ModalHeader>
        )}
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top="15px"
          fontSize={{ base: "20px", md: "22px", lg: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <form onSubmit={handleSubmit(handleEditLyrics)}>
          <ModalBody
            padding={{ base: "10px", md: "10px 40px" }}
            overflow="scroll"
            maxH={"600px"}
          >
            {isLoadingGetLyrics ? (
              <SkeletonShowLyrics />
            ) : (
              <>
                <Flex
                  display={{ base: "block", md: "flex" }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Flex gap={{ base: "15px", md: "20px" }} alignItems="center">
                    <Image
                      w="72px"
                      height="72px"
                      borderRadius="4px"
                      fallbackSrc={ImageDefault}
                      src={selectedTrack?.coverUrl}
                    />
                    <Box>
                      <Text
                        noOfLines={1}
                        fontSize={{ base: "14px", md: "16px" }}
                      >
                        {selectedTrack?.title}
                      </Text>
                      <Flex mt="5px">
                        {TRACK_TYPE[selectedTrack?.type] && (
                          <Box
                            display="flex"
                            alignItems="center"
                            height="20px"
                            borderRadius="4px"
                            padding="5px 10px"
                            mr="5px"
                            mb="5px"
                            backgroundColor="#2b2e1b"
                          >
                            <Text
                              fontSize="12px"
                              lineHeight="14px"
                              color="#d4f70e"
                              fontFamily="Cera Pro Regular"
                            >
                              {t(TRACK_TYPE[selectedTrack?.type])}
                            </Text>
                          </Box>
                        )}
                        <Text
                          fontSize="14px"
                          color="#464649"
                          _hover={{
                            textDecor: "underline",
                            color: "white",
                          }}
                          cursor="pointer"
                          fontFamily="Cera Pro Regular"
                        >
                          {selectedTrack?.createdBy?.name}
                        </Text>
                      </Flex>
                    </Box>
                  </Flex>
                  <Box
                    w={{ base: "100%", md: "max-content" }}
                    mt={{ base: "24px", md: "0px" }}
                  >
                    <CartIcon
                      isLyricsModal={true}
                      isDisabled={isDisabled}
                      onClick={handleAddToCart}
                      price={getPriceTrack(selectedTrack)}
                      active={isInCart}
                      isOffer={
                        !selectedTrack.licenses
                          ? false
                          : isExclusive && isMakeOffersOnly
                      }
                    />
                  </Box>
                </Flex>
                {renderEditLyrics()}
                <Flex
                  justifyContent={{ base: "center", md: "end" }}
                  m="50px 0px 20px 0px"
                  gap="20px"
                >
                  <Button
                    backgroundColor={
                      isEditLyrics ? "#D4F70E" : "rgba(212, 247, 14, 0.1)"
                    }
                    borderRadius="4px"
                    color={isEditLyrics ? "black" : "#D4F70E"}
                    _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                    _active={{ background: "#D4F70E" }}
                    onClick={handleChosseEdit}
                    className="fontCeraPro"
                    w={{ base: "40%", md: "max-content" }}
                  >
                    {isLyricsData ? t("editLyrics") : t("createLyrics")}
                  </Button>

                  <Button
                    w={{ base: "40%", md: "max-content" }}
                    backgroundColor="rgba(212, 247, 14, 0.1)"
                    color="#D4F70E"
                    borderRadius="4px"
                    _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                    _active={{ background: "#D4F70E" }}
                    type="submit"
                    disabled={!isEditLyrics || !lyricTitle || !lyrics}
                    isLoading={isLoadingSuccess || isLoadingUpdate}
                    className="fontCeraPro"
                    spinner={<BeatLoader size={8} color="white" />}
                  >
                    {t("saveLyrics")}
                  </Button>
                </Flex>
              </>
            )}
          </ModalBody>
        </form>
      </ModalContent>
    </Modal>
  );
}
