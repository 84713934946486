import React from "react";

const UpgradePlanIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3626 5.63866C12.8816 2.68196 8.45744 2.29489 5.50074 4.77586C2.54403 7.25684 2.15697 11.681 4.63794 14.6377C7.11891 17.5944 11.5431 17.9815 14.4998 15.5005C17.4565 13.0195 17.8435 8.59537 15.3626 5.63866ZM5.17373 14.1881C2.94049 11.5266 3.28885 7.5449 5.95032 5.31166C8.61179 3.07842 12.5935 3.42677 14.8268 6.08825C17.06 8.74972 16.7117 12.7315 14.0502 14.9647C11.3887 17.1979 7.40697 16.8496 5.17373 14.1881Z"
        fill="#D8FD01"
      />
      <path
        d="M12.2236 12.2417L8.85815 8.23089L10.7887 8.39979C10.9817 8.41668 11.1501 8.27539 11.167 8.08233C11.1755 7.98581 11.1448 7.89599 11.0861 7.82601C11.0274 7.75603 10.9442 7.71026 10.8477 7.70181L8.0766 7.45937C7.88354 7.44248 7.71515 7.58378 7.69826 7.77683L7.45582 10.548C7.43893 10.741 7.58023 10.9094 7.77328 10.9263C7.96633 10.9432 8.13473 10.8019 8.15162 10.6088L8.32052 8.67829L11.6878 12.6913C11.8126 12.84 12.0338 12.8593 12.1825 12.7346C12.3312 12.6098 12.3484 12.3904 12.2236 12.2417Z"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default UpgradePlanIcon;
