import { yupResolver } from "@hookform/resolvers/yup";
import { login as loginAction, logout } from "app/redux/actions/users";
import { LoginPayload } from "app/redux/actions/users/interface";
import { selectUserState } from "app/redux/selectors/users";
import DeviceDetector from "device-detector-js";
import { usePlayers } from "hooks/player/usePlayers";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

interface ILoginFormInput {
  email: string;
  password: string;
  jwt?: string;
}

export const useLoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, isLoadingGuest, isLoadingLogin } =
    useSelector(selectUserState);
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const [isRememberMe, setIsRememberMe] = useState(true);
  const [showModalVerificationCode, setShowModalVerificationCode] =
    useState(false);
  const { setIsAudioPlay, wavesurfer } = usePlayers();

  const onLogout = useCallback(async () => {
    await dispatch(logout({ isLogoutAll: false }));
    if (wavesurfer) {
      wavesurfer.current?.pause();
      wavesurfer.current = null;
      setIsAudioPlay(false);
    }
  }, [dispatch, setIsAudioPlay, wavesurfer]);

  const loginValidationSchema = yup.object().shape({
    email: yup.string().label(t("emailAdress")).required().email(),
    password: yup.string().label(t("password")).required().min(8),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<ILoginFormInput>({
    resolver: yupResolver(loginValidationSchema),
  });
  const valueEmail = watch("email");
  const onSubmit = async (data: ILoginFormInput) => {
    const payload: LoginPayload = {
      email: data.email.trim(),
      password: data.password,
      isRememberMe,
      device: device?.device?.type,
    };
    dispatch(loginAction(payload)).then((res) => {
      if (res) {
        setShowModalVerificationCode(true);
      }
    });
  };

  return {
    control,
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
    setIsRememberMe,
    isRememberMe,
    isLoadingGuest,
    isLoadingLogin,
    onLogout,
    valueEmail,
    showModalVerificationCode,
    setShowModalVerificationCode,
  };
};
