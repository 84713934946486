import React from "react";

interface IconLikePlaylistIndividualProps {
  isFavorite?: boolean;
}

const IconLikePlaylistIndividual = ({
  isFavorite = false,
}: IconLikePlaylistIndividualProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M18.8095 2.4212C17.7446 1.34919 16.3328 0.697419 14.8309 0.584418C13.3289 0.471417 11.8366 0.904691 10.6252 1.80544C9.34904 0.850157 7.76066 0.416984 6.1799 0.593156C4.59914 0.769328 3.14341 1.54176 2.10586 2.7549C1.06831 3.96804 0.52602 5.53178 0.588184 7.13122C0.650347 8.73065 1.31235 10.247 2.44088 11.3748L9.91306 18.8951C10.0063 18.9897 10.1172 19.0648 10.2395 19.1161C10.3617 19.1673 10.4928 19.1937 10.6252 19.1937C10.7576 19.1937 10.8887 19.1673 11.0109 19.1161C11.1331 19.0648 11.244 18.9897 11.3373 18.8951L18.8095 11.3748C19.3939 10.7871 19.8574 10.0892 20.1737 9.32103C20.49 8.55287 20.6528 7.72952 20.6528 6.89802C20.6528 6.06652 20.49 5.24317 20.1737 4.47502C19.8574 3.70687 19.3939 3.00897 18.8095 2.4212ZM17.3953 9.95155L10.6252 16.7551L3.85508 9.95155C3.25848 9.34862 2.85208 8.5818 2.68684 7.7473C2.52161 6.91279 2.6049 6.04776 2.92627 5.26072C3.24765 4.47368 3.79279 3.79967 4.49332 3.32325C5.19385 2.84683 6.01856 2.58921 6.86401 2.58271C7.99348 2.58549 9.07567 3.03931 9.87294 3.84449C9.96618 3.93911 10.0771 4.0142 10.1993 4.06545C10.3216 4.1167 10.4527 4.14308 10.5851 4.14308C10.7175 4.14308 10.8486 4.1167 10.9708 4.06545C11.093 4.0142 11.2039 3.93911 11.2972 3.84449C12.1179 3.12869 13.1782 2.75345 14.2635 2.79471C15.3488 2.83598 16.3779 3.29067 17.1427 4.06676C17.9075 4.84285 18.3507 5.88237 18.3827 6.97493C18.4147 8.06749 18.033 9.13148 17.315 9.95155H17.3953Z"
        fill={isFavorite ? "#D4F70E" : "white"}
      />
    </svg>
  );
};
export default IconLikePlaylistIndividual;
