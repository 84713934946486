import { FeaturePlans } from "app/models";

export const monthlyPlan = {
  LIMITLESS: "LIMITLESS",
  MONTHLY: "MONTHLY",
  PRO: "PRO",
};
export const annualPlan = {
  LIMITLESS: "LIMITLESS",
  ANNUAL: "ANNUAL",
  PRO: "PRO",
  TRIAL: "TRIAL",
};

export const planType = {
  FREE: "FREE",
  LIMITLESS: "LIMITLESS",
  MONTHLY: "MONTHLY",
  ANNUAL: "ANNUAL",
  PRO: "PRO",
  MAINSTREAM: "MAINSTREAM",
};

export const periodUnit = {
  YEAR: "year",
  MONTH: "month",
  FREE: "free",
};

export const planStatus = {
  ACTIVE: "Active",
};

export const contentPlans = {
  TRACK_UPLOAD_UNLIMITED: "Track Upload Limit Unlimited",
  TRACK_UPLOAD_LIMIT_15: "Track Upload Limit 15",
  BULK_TRACK_UPLOAD: "Bulk Track Upload",
  SOUNDKIT_UPLOAD_10: "Sound Kit Upload 10",
  PAYOUT: "Payout",
  SELLER_FEE_0: "Seller Fee 0",
  SELLER_FEE_10: "Seller Fee 10",
  ACCEPT_CREDIT_CARD_PAYPAL: "Accept Credit Card and Paypal",
  SALE_AUTOMATION: "Sales Automation",
  BASIC_ANALYTICS: "Basic Analytics",
  PREMIUM_ANALYTICS: "Premium Analytics",
  ACCEPT_OFFER_NEGOTIATION: "Accept Offers & Negotiate",
  SCHEDULE_UPLOADS: "Schedule Uploads",
  COLLABORATIONS_WITH_REVENUE_SPLITS: "Collaborations With Revenue Splits",
  RE_UPLOAD: "Re Upload",
  CUSTOM_VOICE_TAG: "Custom Voice Tag",
  EMAIL_CAPTURE_MARKETING_TOOL: "Email Capture Marketing Tool",
  SOLD_LICENSE_MONITORING: "Sold Licenses Monitoring",
  SEND_PRIVATE_MESSAGES_5: "Send private monthtly messages 5",
  SEND_PRIVATE_MESSAGES_UNLIMITED: "Send private monthtly messages Unlimited",
  CREATE_PLAYLISTS: "Create Playlists",
  E_CONTRACTS_AGREEMENTS_UNLIMITED: "E-Contracts Agreements Unlimited",
  E_CONTRACTS_AGREEMENTS_4: "E-Contracts Agreements 4",
  FREE_DEMO_DOWNLOADS: "Free Demo Downloads",
  PROMO_CODES: "Promo Codes",
  BULK_OFFERS: "Bulk Offers",
  EXCLUSIVE_TRACKS_LINK_SHARING: "Exclusive Tracks Link Sharing",
  THIRD_PARTY_MARKETING_ADD_ONS: "Third-Party Marketing Add-Ons",
};

export const textShowPlans = (plan: FeaturePlans) =>
  plan?.value !== "true"
    ? `${plan.featureName} ${plan.value}`
    : `${plan.featureName}`;

export const plansText = (plan: string, annual?: boolean) => {
  const plansText = {
    [contentPlans.TRACK_UPLOAD_LIMIT_15]: {
      name: "Upload Up to 15 Tracks",
      icon: false,
    },
    [contentPlans.TRACK_UPLOAD_UNLIMITED]: { name: "Upload Unlimited Tracks" },
    [contentPlans.BULK_TRACK_UPLOAD]: {
      name: contentPlans.BULK_TRACK_UPLOAD,
      icon: false,
    },
    [contentPlans.SOUNDKIT_UPLOAD_10]: {
      name: "Upload up to 10 Sound Kits",
    },
    [contentPlans.PAYOUT]: {
      name: "Instant payouts funds",
      icon: false,
    },
    [contentPlans.SELLER_FEE_0]: {
      name: "0% Marketplace Seller Fee",
      icon: false,
    },
    [contentPlans.SELLER_FEE_10]: {
      name: "10% Marketplace Seller Fee",
      icon: false,
    },
    [contentPlans.ACCEPT_CREDIT_CARD_PAYPAL]: {
      name: "Accept Credit Card and Paypal",
    },
    [contentPlans.SALE_AUTOMATION]: {
      name: "Sales Automation",
    },
    [contentPlans.BASIC_ANALYTICS]: {
      name: contentPlans.BASIC_ANALYTICS,
    },
    [contentPlans.PREMIUM_ANALYTICS]: {
      name: contentPlans.PREMIUM_ANALYTICS,
    },
    [contentPlans.ACCEPT_OFFER_NEGOTIATION]: {
      name: "Accept Offers & Negotiate",
    },
    [contentPlans.SCHEDULE_UPLOADS]: {
      name: "Schedule Uploads",
      icon: false,
    },
    [contentPlans.COLLABORATIONS_WITH_REVENUE_SPLITS]: {
      name: contentPlans.COLLABORATIONS_WITH_REVENUE_SPLITS,
      icon: false,
    },
    [contentPlans.RE_UPLOAD]: {
      name: contentPlans.RE_UPLOAD,
      icon: false,
    },
    [contentPlans.CUSTOM_VOICE_TAG]: {
      name: "Custom Voice Tag",
    },
    [contentPlans.EMAIL_CAPTURE_MARKETING_TOOL]: {
      name: "Email Capture",
      icon: false,
    },
    [contentPlans.SOLD_LICENSE_MONITORING]: {
      name: "Sold Licenses Monitoring",
      icon: false,
    },
    [contentPlans.SEND_PRIVATE_MESSAGES_5]: {
      name: "Up to 5 Monthly Messages",
      icon: false,
    },
    [contentPlans.SEND_PRIVATE_MESSAGES_UNLIMITED]: {
      name: "Unlimited Monthly Messages",
      icon: false,
    },
    [contentPlans.CREATE_PLAYLISTS]: {
      name: contentPlans.CREATE_PLAYLISTS,
      icon: false,
    },
    [contentPlans.E_CONTRACTS_AGREEMENTS_4]: {
      name: "Up to 4 E-Contracts Agreements",
      icon: false,
    },
    [contentPlans.E_CONTRACTS_AGREEMENTS_UNLIMITED]: {
      name: "Unlimited E-Contracts Agreements",
      icon: false,
    },
    [contentPlans.FREE_DEMO_DOWNLOADS]: {
      name: "Free Demo Downloads",
      icon: false,
    },
    [contentPlans.PROMO_CODES]: {
      name: contentPlans.PROMO_CODES,
    },
    [contentPlans.BULK_OFFERS]: {
      name: contentPlans.BULK_OFFERS,
    },
    [contentPlans.EXCLUSIVE_TRACKS_LINK_SHARING]: {
      name: contentPlans.EXCLUSIVE_TRACKS_LINK_SHARING,
    },
    [contentPlans.THIRD_PARTY_MARKETING_ADD_ONS]: {
      name: contentPlans.THIRD_PARTY_MARKETING_ADD_ONS,
    },
  };
  return plansText[plan];
};
