import { useParams } from "react-router-dom";
import { selectUserState } from "app/redux/selectors/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { resetPasswordUser } from "app/redux/actions/users";

interface ResetPassword {
  password: string;
  confirmPassword: string;
}

export const useResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading } = useSelector(selectUserState);
  const { token } = useParams<{ token: string }>();

  const resetPasswordValidationSchema = yup.object().shape({
    password: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref(t("passwordUser")), null], t("PasswordNotMatch")),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ResetPassword>({
    resolver: yupResolver(resetPasswordValidationSchema),
  });

  const onSubmit = (data: ResetPassword) => {
    dispatch(resetPasswordUser(data.password, token));
  };

  return {
    control,
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
  };
};
