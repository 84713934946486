import React from "react";

const IconRepeatDashboardMobile = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.55556 15.8025L0 12.642L3.55556 9.48148V11.8519H12.4444V8.69136H14.2222V12.642C14.2222 13.0784 13.8243 13.4321 13.3333 13.4321H3.55556V15.8025ZM3.55556 7.11111H1.77778V3.16049C1.77778 2.72412 2.17575 2.37037 2.66667 2.37037H12.4444V0L16 3.16049L12.4444 6.32099V3.95062H3.55556V7.11111Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconRepeatDashboardMobile;
