import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { toast } from "react-toastify";
import "./styles.scss";

function ToastMessage(props) {
  const { data } = props;
  return (
    <Box>
      <Text
        color={data.isAddTrack ? "white" : "#535353"}
        fontSize="16px"
        lineHeight="24px"
        fontWeight="700"
        fontFamily="Cera Pro Medium"
      >
        {data.title}
      </Text>
      <Text
        color={data.isAddTrack ? "white" : "#535353"}
        fontSize="14px"
        lineHeight="24px"
        fontWeight="400"
        fontFamily="Cera Pro Regular"
      >
        {data.description}
      </Text>
      <Text fontSize="16px" color="#7C20E6" onClick={data.onClickButton}>
        {data.buttonText}
      </Text>
    </Box>
  );
}

export default function ToastPlanSubscription({
  title,
  description,
  isError = true,
  buttonText,
  onClickButton,
}: {
  title?: string;
  description?: string;
  isError?: boolean;
  buttonText?: string;
  onClickButton?: Function;
}) {
  return toast[isError ? "error" : "success"](<ToastMessage />, {
    data: {
      title: title,
      description: description ? description : "Internal Server Error",
      buttonText: buttonText,
      onClickButton: onClickButton,
    },
    className: "toast-plans-subscription",
    position: "top-center",
  });
}
