import { Box, Button, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import ErrorMessage from "app/components/ErrorMessage";
import { purchaseCart } from "app/redux/actions/cart";
import {
  cartLicensesSelector,
  paymentTypeSelector,
  shoppingCartSelector,
  totalCartSelector,
  selectedSubscriptionSelector,
  bulkOffersSelector,
  cartPromoCodeSelector,
  totalDiscountCartSelector,
} from "app/redux/selectors/cart";
import { isLoadingSelector } from "app/redux/selectors/status";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "types";
import { getIpAddress } from "utils/getData";
import "./styles.scss";
import CreditCard from "../CreditCard";
import { BeatLoader } from "react-spinners";
interface CardElementProps {
  name?: string;
  setName?: any;
}

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          color: "white",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
          fontSize: "16px",
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    []
  );

  return options;
};

const CardElementForm = ({ name, setName }: CardElementProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const listCart = useSelector(shoppingCartSelector);
  const cartLicenses = useSelector(cartLicensesSelector);
  const totalCart = useSelector(totalCartSelector);
  const totalDiscount = useSelector(totalDiscountCartSelector);

  const paymentType = useSelector(paymentTypeSelector);
  const selectedSubscription = useSelector(selectedSubscriptionSelector);
  const bulkOffers = useSelector(bulkOffersSelector);
  const promoCode = useSelector(cartPromoCodeSelector);

  const [cardNumber, setCardNumber] = useState<any>();
  const [date, setDate] = useState<any>();
  const [cvc, setCvc] = useState<any>();
  const [isRequiredName, setIsRequiredName] = useState<boolean>(false);
  const [isRemember, setIsRemember] = useState<boolean>(false);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([purchaseCart.typePrefix], state)
  );
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const ipAddress = await getIpAddress();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    if (!name) {
      setIsRequiredName(true);
      return;
    }
    setIsRequiredName(false);
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: name,
      },
    });
    if (payload?.error) {
      return;
    }
    dispatch(
      purchaseCart({
        cart: listCart,
        licenses: cartLicenses.map((license: any) => {
          return {
            trackId: license?.trackId,
            licenseId: license?.license?._id,
            negotiationId: license?.negotiation?._id,
          };
        }),
        totalAmount: Number(totalCart) - Number(totalDiscount),
        paymentType,
        paymentMethodId: payload?.paymentMethod?.id,
        rememberCard: isRemember,
        promoCode: promoCode?.promoCode,
        subscriptionId:
          selectedSubscription.length > 0
            ? selectedSubscription[0]?._id
            : undefined,
        ipAddress,
        bulkOffers,
      })
    ).then((res: any) => {
      const error =
        res?.payload &&
        res?.payload.length &&
        (res?.payload || []).every((item: any) => item?.error);
      if (res.error || error) {
        history.push("/thank-you?status=error");
      } else {
        return history.push("/thank-you?status=success");
      }
    });
  };

  return (
    <>
      <Box w={"100%"} overflow="hidden" display={{ base: "none", md: "block" }}>
        <CreditCard brand={cardNumber?.brand} />
      </Box>

      <form onSubmit={handleSubmit}>
        <label>{t("cardHolderName")}</label>
        <Input
          placeholder="Empty"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <ErrorMessage error={isRequiredName && "nameIsRequired"} />
        <label>
          {t("cardNumber")}
          <CardNumberElement
            options={options}
            onChange={(event) => {
              setCardNumber(event);
            }}
          />
          <ErrorMessage
            error={
              !cardNumber?.complete && cardNumber?.error && "errorCardNumber"
            }
          />
        </label>

        <Flex gridGap="16px">
          <Box w={{ base: "50%", lg: "40.5%", xl: "34.5%" }}>
            <label>
              {t("date")}
              <CardExpiryElement
                options={options}
                onChange={(event) => {
                  setDate(event);
                }}
              />
              <ErrorMessage
                error={!date?.complete && date?.error && "dateIsRequired"}
              />
            </label>
          </Box>

          <Box w={{ base: "50%", lg: "40.5%", xl: "34.5%" }}>
            <label>
              {t("cvc")}
              <CardCvcElement
                options={options}
                onChange={(event) => {
                  setCvc(event);
                }}
              />
              <ErrorMessage
                error={!cvc?.complete && cvc?.error && "invalidCvc"}
              />
            </label>
          </Box>
        </Flex>
        <Box m="15px 0px" className="remember-card">
          <Checkbox
            _focusVisible={{ outline: "none" }}
            size="lg"
            isChecked={isRemember}
            onChange={() => setIsRemember((pre) => !pre)}
          >
            <Text fontSize={{ base: "14px", md: "16px" }}>
              {t("rememberThisCard")}
            </Text>
          </Checkbox>
        </Box>
        <Box w={{ base: "100%" }}>
          <Button
            type="submit"
            disabled={!stripe}
            isLoading={isLoading}
            spinner={<BeatLoader size={8} color="white" />}
            w={{ base: "100%", lg: "40%", xl: "30%" }}
          >
            {t("continue")}
          </Button>
        </Box>
      </form>
    </>
  );
};

export default CardElementForm;
