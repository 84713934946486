import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import EnglandFlag from "app/assets/images/EnglandFlag.png";
import PortugalFlag from "app/assets/images/PortugalFlag.png";
import SpainFlag from "app/assets/images/SpainFlag.png";

import "./styles.scss";
import GlobalLoading from "app/components/GlobalLoading";
import i18next from "i18next";

const LanguageDropdown = () => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [language, setLanguage] = useState(`Eng`);

  const listMenuProfile = [
    {
      id: 0,
      name: t("Eng"),
      value: "en",
      icon: <Image fallbackSrc={EnglandFlag} w="18px" h="18px" />,
      label: "Eng",
    },
    {
      id: 1,
      name: t("Spa"),
      value: "es",
      icon: <Image fallbackSrc={SpainFlag} w="18px" h="18px" />,
      label: "Spa",
    },
    {
      id: 2,
      name: t("Por"),
      value: "pt",
      icon: <Image fallbackSrc={PortugalFlag} w="18px" h="18px" />,
      label: "Por",
    },
  ];
  useEffect(() => {
    if (i18next.language === "es") {
      return setLanguage("Spa");
    }
    if (i18next.language === "pt") {
      return setLanguage("Por");
    }
    return setLanguage("Eng");
  }, [t]);

  const languageActive = useCallback((lang) => lang === language, [language]);

  return (
    <Box p="0px 15px 0px 10px" className="language-component">
      {isOpen && <GlobalLoading />}
      <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Button
                bg="none"
                _hover={{ bg: "none" }}
                _active={{ bg: "none" }}
                _focus={{ boxShadow: "none" }}
                p="0px"
                rightIcon={<ChevronDownIcon color="#D4F70E" />}
                className="fontCeraProRegular"
                fontSize="14px"
              >
                {language}
              </Button>
            </PopoverTrigger>

            <PopoverContent
              background="#24252A"
              borderColor="#24252A"
              _focus={{ boxShadow: "none" }}
            >
              <PopoverArrow />
              <PopoverBody overflow="auto" padding="12px 17px">
                {listMenuProfile.map((item) => (
                  <Flex
                    alignItems="center"
                    key={item.id}
                    _hover={{
                      background:
                        "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                      borderLeft: "1px solid #D4F70E",
                      borderRadius: "0px",
                    }}
                    padding="6px 5px"
                    onClick={() => {
                      i18next.changeLanguage(item.value);
                      setLanguage(item.label);
                      onClose();
                    }}
                    cursor="pointer"
                    background={
                      languageActive(item.label)
                        ? "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)"
                        : "#1F1F23"
                    }
                    borderLeft={
                      languageActive(item.label) ? "1px solid #D4F70E" : "none"
                    }
                    mb="3px"
                    borderRadius={languageActive(item.label) ? "0px" : "2px"}
                  >
                    <Flex
                      w="28px"
                      h="28px"
                      background={
                        languageActive(item.label) ? "#1F1F23" : "#38383D"
                      }
                      borderRadius="full"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {item.icon}
                    </Flex>
                    <Text
                      className="fontCeraProRegular"
                      ml="15px"
                      fontSize="14px"
                      fontWeight="400"
                      color={languageActive(item.label) ? "#D4F70E" : "white"}
                    >
                      {item.name}
                    </Text>
                  </Flex>
                ))}
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default LanguageDropdown;
