import { CartLicenses, TrackSoundKit } from "app/models";
import { TRACK_TYPE } from "constants/contractType";

const licenseIds = (
  licenses: CartLicenses[] = [],
  item: any,
  isSoundKitCheck: boolean
) => {
  const checkIsSoundKit = licenses.find(
    (license: CartLicenses) => license.trackId === item._id
  )?.licenseId;

  return isSoundKitCheck ? !checkIsSoundKit : checkIsSoundKit;
};

const trackSoundKitIds = (
  cart: TrackSoundKit[],
  licenses: CartLicenses[],
  isSoundKitCheck: boolean
) => {
  return cart
    .filter((item) => item.type !== TRACK_TYPE.SUBSCRIPTION)
    .filter((item) => {
      return licenseIds(licenses, item, isSoundKitCheck);
    })
    .map((item) => item._id);
};

export { trackSoundKitIds };
