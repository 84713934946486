import { createSelector } from "@reduxjs/toolkit";

export const selectCollaborations = (state: any) => state.collaborations;

export const selectorsCollaborations = createSelector(
  selectCollaborations,
  ({ collaborations }) => {
    return collaborations;
  }
);

export const selectorsLoadingCollaborations = createSelector(
  selectCollaborations,
  ({ isLoading }) => {
    return isLoading;
  }
);
