import React from "react";

const IconEntertainment = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 7C2.25 3.82436 4.82439 1.25 8 1.25C11.1756 1.25 13.75 3.82436 13.75 7C13.75 10.1756 11.1756 12.75 8 12.75C4.82439 12.75 2.25 10.1756 2.25 7ZM8 2.75C5.65281 2.75 3.75 4.65279 3.75 7C3.75 9.34719 5.65281 11.25 8 11.25C10.3472 11.25 12.25 9.34719 12.25 7C12.25 4.65279 10.3472 2.75 8 2.75Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4679 7.07308C12.7597 6.77917 13.2346 6.77752 13.5285 7.06939C13.5415 7.08261 13.5593 7.10177 13.5643 7.10747L13.5731 7.11774L13.5797 7.12562L13.5849 7.13202L13.5925 7.14144L13.6149 7.16946L15.3582 9.36059C16.5346 10.8401 18.2972 13.0571 20.6459 16.0115C21.0523 16.508 21.0168 17.2325 20.5624 17.687L18.5206 19.7288C18.0637 20.1857 17.3339 20.2191 16.8372 19.8058L16.8279 19.798L8.42213 12.5692C8.10807 12.2991 8.07242 11.8256 8.3425 11.5115C8.61258 11.1975 9.08611 11.1618 9.40017 11.4319L17.624 18.5042L19.3439 16.7842C17.059 13.91 15.3391 11.7466 14.1841 10.2942L12.4429 8.10557L12.423 8.08075C12.1785 7.78697 12.1931 7.34981 12.4679 7.07308ZM19.4863 16.963C19.4851 16.9616 19.4839 16.9601 19.4827 16.9586L19.4863 16.963Z"
        fill="#D4F70E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7045 11.704C12.9974 11.4112 13.4723 11.4112 13.7652 11.704L15.1794 13.1182C15.4723 13.4111 15.4723 13.886 15.1794 14.1789C14.8865 14.4718 14.4116 14.4718 14.1187 14.1789L12.7045 12.7647C12.4116 12.4718 12.4116 11.9969 12.7045 11.704Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconEntertainment;
