import { Box } from "@chakra-ui/react";
import React from "react";
import "./styles.scss";

interface Props {}

const BackgroundDrop: React.FC<Props> = () => {
  return <Box className="background-drop-container" />;
};

export default BackgroundDrop;
