export const timeType = {
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
};

export const convertTimeType = {
  DAY: "Day",
  WEEK: "Week",
  MONTH: "Month",
  YEAR: "Year",
};

export const anaLytics:any  = {
    [`1`]: "Jan",
    [`2`]: "Feb",
    [`3`]: "Mar",
    [`4`]: "Apr",
    [`5`]: "May",
    [`6`]: "Jun",
    [`7`]: "Jul",
    [`8`]: "Aug",
    [`9`]: "Sep",
    [`10`]: "Oct",
    [`11`]: "Nov",
    [`12`]: "Dec",
  };

export const queryAnalyticsType = {
  Plays: "Plays",
  Downloads: "Downloads",
  Purchased: "Purchased",
  Sold: "Sold",
  Favorites: "Favorites",
  Sales: "Sales",
};

export const dateType = (indexTab: number) => {
  const date: { [index: number]: string } = {
    0: timeType.DAY,
    1: timeType.WEEK,
    2: timeType.MONTH,
    3: timeType.YEAR,
  };

  return date[indexTab];
};

export const AnalyticType = {
  PLAYED: "PLAYED",
  PURCHASED: "PURCHASED",
  SOLD: "SOLD",
  DOWNLOADED: "DOWNLOADED",
  FAVORITED: "FAVORITED",
  LISTENER: "LISTENER",
  DOWNLOADER: "DOWNLOADER",
  REPOSTER: "REPOSTER",
  BUYER: "BUYER",
};

export const totalAnalyticsTrack = (values: any[]) => {
  return values.reduce((sum: any, current: any) => sum + current, 0);
};

export const analyticsTypeDispatch = (type: string) => {
  const typeAnalytics: { [type: string]: string } = {
    Plays: AnalyticType.PLAYED,
    Downloads: AnalyticType.DOWNLOADED,
    Sold: AnalyticType.SOLD,
    Sales: AnalyticType.SOLD,
    Favorites: AnalyticType.FAVORITED,
  };

  return typeAnalytics[type];
};

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
