import { Box, Flex, Text } from "@chakra-ui/react";
import { InputItem } from "app/components/InputItem";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import WhoAreYou from "./whoAreYou";

interface StepTwoSignupProps {
  handleChooseRole: (value: string) => void;
  roleUser: string[];
}

export default function StepTwoSignup({
  handleChooseRole,
  roleUser,
}: StepTwoSignupProps) {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const dataWhoAreYour = [
    {
      label: t("musicListener"),
      name: t("user"),
      value: "USER",
      iconStart: false,
    },
    {
      label: t("songWrite"),
      name: t("artist"),
      value: "ARTIST",
      iconStart: true,
    },
    {
      label: t("doYouMakeBeats"),
      name: t("producer"),
      value: "PRODUCER",
      iconStart: true,
    },
  ];

  return (
    <Box>
      <Flex>
        <Text mr="1px" color="#FFFFFF" fontSize="14px" lineHeight="24px">
          {t("name")}
        </Text>
        <Text color="#D4F70E">*</Text>
      </Flex>
      <InputItem
        placeholder={"Name"}
        className="username"
        control={control}
        type="input"
        name="name"
        errors={errors.name}
      />
      {dataWhoAreYour.map((item) => (
        <WhoAreYou
          label={item.label}
          text={item.name}
          notMusic={item.iconStart}
          value={item.value}
          roleUser={roleUser}
          handleSelectRole={handleChooseRole}
        />
      ))}
    </Box>
  );
}
