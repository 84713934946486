import React from "react";

const IconCopyReferral = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 3.5C12.5 3.102 12.342 2.7205 12.0605 2.4395C11.7795 2.158 11.398 2 11 2C9.2685 2 5.7315 2 4 2C3.602 2 3.2205 2.158 2.9395 2.4395C2.658 2.7205 2.5 3.102 2.5 3.5C2.5 5.592 2.5 10.408 2.5 12.5C2.5 12.898 2.658 13.2795 2.9395 13.5605C3.2205 13.842 3.602 14 4 14C5.7315 14 9.2685 14 11 14C11.398 14 11.7795 13.842 12.0605 13.5605C12.342 13.2795 12.5 12.898 12.5 12.5V3.5ZM11.5 3.5V12.5C11.5 12.6325 11.4475 12.76 11.3535 12.8535C11.26 12.9475 11.1325 13 11 13H4C3.8675 13 3.74 12.9475 3.6465 12.8535C3.5525 12.76 3.5 12.6325 3.5 12.5V3.5C3.5 3.3675 3.5525 3.24 3.6465 3.1465C3.74 3.0525 3.8675 3 4 3H11C11.1325 3 11.26 3.0525 11.3535 3.1465C11.4475 3.24 11.5 3.3675 11.5 3.5ZM9.5 0H1.5C1.102 0 0.720499 0.157999 0.439499 0.439499C0.157999 0.720499 0 1.102 0 1.5C0 3.845 0 9.5 0 9.5C0 9.776 0.224 10 0.5 10C0.776 10 1 9.776 1 9.5V1.5C1 1.3675 1.0525 1.24 1.1465 1.1465C1.24 1.0525 1.3675 1 1.5 1H9.5C9.776 1 10 0.776 10 0.5C10 0.224 9.776 0 9.5 0Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconCopyReferral;
