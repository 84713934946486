import React from "react";

const Close = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.72188 9.7911C1.44335 10.0696 0.991757 10.0696 0.713226 9.7911L0.208899 9.28677C-0.0696325 9.00824 -0.0696325 8.55665 0.208899 8.27812L8.27812 0.208899C8.55665 -0.0696328 9.00824 -0.0696331 9.28677 0.208899L9.7911 0.713225C10.0696 0.991757 10.0696 1.44335 9.7911 1.72188L1.72188 9.7911Z"
        fill="#CAEA11"
      />
      <path
        d="M9.7911 8.27812C10.0696 8.55665 10.0696 9.00824 9.7911 9.28677L9.28677 9.7911C9.00824 10.0696 8.55665 10.0696 8.27812 9.7911L0.208899 1.72188C-0.0696328 1.44335 -0.0696331 0.991757 0.208899 0.713225L0.713225 0.208899C0.991757 -0.0696329 1.44335 -0.0696329 1.72188 0.208899L9.7911 8.27812Z"
        fill="#CAEA11"
      />
    </svg>
  );
};
export default Close;
