import React from "react";

const IconPlaylistIndividual = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="252"
      height="193"
      viewBox="0 0 252 193"
      fill="none"
    >
      <path
        opacity="0.5"
        d="M249 189.667H3C1.89543 189.667 1 188.771 1 187.667V52.2773C1 51.1727 1.89543 50.2773 3 50.2773H131.358C132.148 50.2773 132.864 49.8123 133.185 49.0907L139.821 34.1865C140.142 33.4649 140.858 33 141.648 33H249C250.105 33 251 33.8954 251 35V187.667C251 188.771 250.105 189.667 249 189.667Z"
        fill="url(#paint0_linear_11522_21548)"
        stroke="url(#paint1_linear_11522_21548)"
        strokeWidth="0.5"
      />
      <path
        opacity="0.75"
        d="M249 192.739H3.00002C1.75739 192.739 0.75 191.732 0.75 190.489V3C0.75 1.75736 1.75735 0.75 3 0.75H110.929C111.744 0.75 112.495 1.1906 112.893 1.90183L119.44 13.6099C119.749 14.1631 120.334 14.5058 120.967 14.5058H249C250.243 14.5058 251.25 15.5131 251.25 16.7558V190.489C251.25 191.732 250.243 192.739 249 192.739Z"
        stroke="url(#paint2_linear_11522_21548)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11522_21548"
          x1="126"
          y1="33"
          x2="126"
          y2="189.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="0.166667" stopColor="#535353" />
          <stop offset="0.333333" stopColor="#535353" />
          <stop offset="0.5" stopColor="#535353" />
          <stop offset="0.666667" stopColor="#535353" stopOpacity="0.666941" />
          <stop offset="0.833333" stopColor="#535353" stopOpacity="0.454995" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_11522_21548"
          x1="126"
          y1="33"
          x2="126"
          y2="189.667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_11522_21548"
          x1="126"
          y1="1"
          x2="126"
          y2="192.489"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default IconPlaylistIndividual;
