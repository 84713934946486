import { UpdateCollaboration } from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class CollaborationsService {
  static async getCollaborations() {
    try {
      const { data } = await axiosService.get("/collaborators");
      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async updateCollaboration(body: UpdateCollaboration) {
    try {
      const { data } = await axiosService.put(
        `/collaborators/${body?.collaborationId}`,
        body
      );

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}
export default CollaborationsService;
