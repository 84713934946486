import { ChevronDownIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputLeftElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  Flex,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { TYPE_PARAMS } from "utils/actionText";
import GlobalLoading from "app/components/GlobalLoading";
import "./styles.scss";

export default function SearchInput() {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("tracks");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const checkHastag = !!location.hash;
    const queryParamsParsed = queryString.parse(location.search);
    setSearchValue(
      (checkHastag ? location.hash : (queryParamsParsed?.q as string)) || ""
    );
    setSearchType((queryParamsParsed.type as string) || "tracks");
  }, [location.hash, location.search]);
  const labelType = useMemo(() => {
    const title: { [name: string]: string } = {
      musicians: t("musicians"),
      tracks: t("Tracks"),
      soundKits: t("soundPacks"),
      playlists: t("playlists"),
      tags: t("tags"),
    };

    return title[searchType];
  }, [searchType, t]);

  const listMenuTabs = useMemo(
    () => [
      {
        name: t("musicians"),
        value: "musicians",
        id: 1,
      },
      {
        name: t("Tracks"),
        value: "tracks",
        id: 2,
      },
      {
        name: t("soundPacks"),
        value: "soundKits",
        id: 3,
      },
      {
        name: t("playlists"),
        value: "playlists",
        id: 4,
      },
      {
        name: t("tags"),
        value: "tags",
        id: 5,
      },
    ],
    [t]
  );

  const handleSelectTypeSearch = useCallback(
    (item) => {
      const type = item.value;
      setSearchType(type);
      history.push(
        `/search?type=${type}${searchValue ? `&q=${searchValue}` : ""}`
      );
    },
    [history, searchValue]
  );

  const handleChangeInput = useCallback(
    (e) => {
      const queryParamsParsed = queryString.parse(location.search);
      const { value } = e.target;
      const searchValues =
        queryParamsParsed.type === TYPE_PARAMS.tags &&
        searchValue.length === 0 &&
        value !== "#"
          ? `#${value}`
          : value;
      setSearchValue(searchValues);
    },
    [location.search, searchValue.length]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter") {
        history.push(
          `/search?type=${searchType}${searchValue ? `&q=${searchValue}` : ""}`
        );
      }
    },
    [history, searchType, searchValue]
  );

  return (
    <Flex className="input-search-header">
      <InputGroup
        _focusVisible={{
          outline: "none",
          border: "none",
        }}
        border="none"
        background=" #222226"
        borderTopLeftRadius="8px"
        borderBottomLeftRadius="8px"
        _focusWithin={{
          border: "none",
        }}
        minW={{ base: "260px", md: "200px", xl: "280px" }}
      >
        <InputLeftElement children={<SearchIcon />} />
        <Input
          _focus={{
            boxShadow: "none !important",
            border: "none !important",
          }}
          placeholder={t("placeHolderSearchInputHeader")}
          border="none"
          onChange={(e) => handleChangeInput(e)}
          defaultValue={searchValue}
          value={searchValue}
          fontSize="14px"
          fontFamily="Cera Pro Medium"
          onKeyDown={handleKeyDown}
          padding="0px 10px 0px 40px"
        />
      </InputGroup>

      <Flex
        alignItems="center"
        background="#222226"
        borderTopRightRadius="8px"
        borderBottomRightRadius="8px"
        borderLeft="1px solid #2D2D31"
      >
        {isOpen && <GlobalLoading />}
        <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
          {({ onClose }) => (
            <>
              <PopoverTrigger>
                <Button
                  bg="none"
                  _hover={{ bg: "none" }}
                  _active={{ bg: "none" }}
                  _focus={{ boxShadow: "none" }}
                  rightIcon={<ChevronDownIcon color="#D4F70E" />}
                  fontSize="14px"
                  fontWeight="500"
                  cursor="pointer"
                  fontFamily="Cera Pro Medium"
                >
                  {labelType}
                </Button>
              </PopoverTrigger>
              <PopoverContent
                background="#24252A"
                borderColor="#24252A"
                marginTop="7px"
                maxWidth="120px"
                _focus={{
                  boxShadow: "none",
                }}
                _focusVisible={{
                  boxShadow: "none",
                  outline: "none",
                }}
                border="none"
              >
                <PopoverBody>
                  {listMenuTabs.map((item) => (
                    <Flex
                      onClick={() => {
                        handleSelectTypeSearch(item);
                        onClose();
                      }}
                      alignItems="center"
                      key={item.id}
                      _hover={{
                        background:
                          "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)",
                        borderLeft: "1px solid #D4F70E",
                        color: "white",
                        borderRadius: "0px",
                      }}
                      padding="6px 5px"
                      background={
                        labelType === item.name
                          ? "linear-gradient(90deg, rgba(212, 247, 14, 0.24) 1.04%, rgba(212, 247, 14, 0.01) 100%)"
                          : "#1F1F23"
                      }
                      borderLeft={
                        labelType === item.name ? "1px solid #D4F70E" : "none"
                      }
                      mb="3px"
                      borderRadius={labelType === item.name ? "0px" : "2px"}
                      cursor="pointer"
                      color={labelType === item.name ? "white" : "#9f9f9f"}
                    >
                      <Text fontSize="14px" fontWeight="500">
                        {item.name}
                      </Text>
                    </Flex>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </Flex>
    </Flex>
  );
}
