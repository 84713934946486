import { Box, Text } from "@chakra-ui/react";
import React, { useMemo } from "react";
import House from "app/assets/icons/house";
import { SelectBulkOffersModel } from "app/models";
import { useTranslation } from "react-i18next";

interface BulkOffersCartProps {
  bulkoffers: SelectBulkOffersModel;
}

export default function BulkOffersCart({ bulkoffers }: BulkOffersCartProps) {
  const { t } = useTranslation();
  const {
    minimumTracks,
    usingLicenses,
    name,
    freeTracks,
    createdBy,
    hasApplyBulkOffer,
    allCurrentLicenses,
  } = bulkoffers;
  const applyBulkOfferText = useMemo(() => {
    return `${t("accepted")} ! ${name.slice(0, -1)} ${t("from")} ${
      createdBy?.name
    }. ${t("youHave")} ${Number(freeTracks - usingLicenses.length)} ${t(
      "freeTrackRemain"
    )}`;
  }, [createdBy?.name, freeTracks, name, t, usingLicenses.length]);

  const notApplyBullOfferText = useMemo(() => {
    return `${t("add")} ${minimumTracks - allCurrentLicenses.length} ${t(
      "more"
    )} ${t("toApplyBulkOffer")} ${name.slice(0, -1)} ${t(
      "from"
    )} ${`${createdBy?.name}!`}`;
  }, [allCurrentLicenses.length, createdBy?.name, minimumTracks, name, t]);

  return (
    <Box
      backgroundColor="#2B2E1C"
      borderRadius="8px"
      padding="10px 20px"
      mb="15px"
      display="flex"
      alignItems="center"
    >
      <House />
      <Text
        ml="10px"
        fontSize="12px"
        lineHeight="14px"
        fontWeight="400"
        color="#D4F70E"
        textAlign="center"
      >
        {hasApplyBulkOffer ? applyBulkOfferText : notApplyBullOfferText}
      </Text>
    </Box>
  );
}
