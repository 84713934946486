import {
  fetchReleased,
  fetchDrafts,
  fetchPrivate,
  fetchSchedule,
  updateTracksPrivate,
  fetchTracksPending,
} from "app/redux/actions/myTrack";
import { selectorMyTrack } from "app/redux/selectors/myTrack";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isLoadingSelector } from "app/redux/selectors/status";
import { RootState } from "types";
import { useDebounce } from "hooks/useDebounce";
import { typeMyTrackRouter } from "utils/actionText";
import { useToggle } from "hooks/useToggle";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import formSchemaShortLink from "./formSchemaShortLink";
import {
  filesAutoTaggedSelector,
  filesCustomTaggedSelector,
  filesTrackoutStemsSelector,
} from "app/redux/selectors/tracks";
import {
  filesAutoTaggedProgressSelector,
  filesCustomTaggedProgressSelector,
  filesTrackoutStemsProgressSelector,
} from "app/redux/selectors/filesUpload";

export const useMyTrack = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState<string>("");
  const [selectCheckbox, setSelectCheckbox] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [showModalLinkExpiration, setShowModalLinkExpiration] = useToggle();
  const [selectedTrack, setSelectedTrack] = useState(null);
  const {
    released,
    privateTrack,
    drafts,
    scheduleTrack,
    allUser,
    tracksPending,
  } = useSelector(selectorMyTrack);
  const filesAutoTagged = useSelector(filesAutoTaggedSelector);
  const filesCustomTagged = useSelector(filesCustomTaggedSelector);
  const filesTrackoutStems = useSelector(filesTrackoutStemsSelector);
  const autoTaggedInProgress = useSelector(filesAutoTaggedProgressSelector);
  const customTaggedInProgress = useSelector(filesCustomTaggedProgressSelector);
  const trackoutStemsInProgress = useSelector(
    filesTrackoutStemsProgressSelector
  );

  const isFetchReleasedLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchReleased.typePrefix], state)
  );
  const isFetchPrivateLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchPrivate.typePrefix], state)
  );
  const isFetchDraftsLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchDrafts.typePrefix], state)
  );

  const isFetchScheduleLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchSchedule.typePrefix], state)
  );
  const isFetPendingTrackLoading = useSelector((state: RootState) =>
    isLoadingSelector([fetchTracksPending.typePrefix], state)
  );

  const initValueShortLink = {
    user: [],
    notifyUser: false,
    password: "",
    description: "",
    expiresAt: "",
  };

  const methods = useForm({
    defaultValues: initValueShortLink,
    resolver: yupResolver(formSchemaShortLink()),
  });

  const isLoading = useMemo(
    () =>
      (isFetchReleasedLoading && !released?.loadingMore) ||
      (isFetchPrivateLoading && !privateTrack?.loadingMore) ||
      (isFetchDraftsLoading && !drafts?.loadingMore) ||
      (isFetchScheduleLoading && !scheduleTrack?.loadingMore) ||
      (isFetPendingTrackLoading && !tracksPending?.loadingMore),
    [
      isFetchReleasedLoading,
      released?.loadingMore,
      isFetchPrivateLoading,
      privateTrack?.loadingMore,
      isFetchDraftsLoading,
      drafts?.loadingMore,
      isFetchScheduleLoading,
      scheduleTrack?.loadingMore,
      isFetPendingTrackLoading,
      tracksPending?.loadingMore,
    ]
  );

  const onGetReleasedTracks = useCallback(
    (filter, value?: string) => {
      dispatch(fetchReleased({ search: value, ...filter }));
    },
    [dispatch]
  );

  const onGetPrivateTracks = useCallback(
    (filter, value?: string) => {
      dispatch(fetchPrivate({ search: value, private: true, ...filter }));
    },
    [dispatch]
  );

  const onGetScheduleTracks = useCallback(
    (filter, value?: string) => {
      dispatch(fetchSchedule({ search: value, schedule: true, ...filter }));
    },
    [dispatch]
  );

  const onGetDraftsTracks = useCallback(
    (filter, value?: string) => {
      dispatch(fetchDrafts({ search: value, ...filter }));
    },
    [dispatch]
  );

  const onGetPendingTracks = useCallback(
    (filter, value?: string) => {
      dispatch(fetchTracksPending({ search: value, ...filter }));
    },
    [dispatch]
  );

  const handleCheckbox = useCallback(
    (id) => {
      const isChecked = selectCheckbox.find((item: string) => item === id);
      if (isChecked) {
        setSelectCheckbox(selectCheckbox.filter((item) => item !== id));
      } else {
        setSelectCheckbox([...selectCheckbox, id]);
      }
    },
    [selectCheckbox]
  );

  const handleRemoveTrack = useCallback(() => {
    dispatch(updateTracksPrivate(selectCheckbox));
    setSelectCheckbox([]);
  }, [dispatch, selectCheckbox]);

  const idMyTrack = useCallback((tracks) => {
    return tracks.items?.map((item) => item._id ?? "");
  }, []);

  const dataCheckBox = useCallback(
    (type) => {
      const roleText = {
        [typeMyTrackRouter.MY_SCHEDULE_TRACK]: idMyTrack(scheduleTrack),
        [typeMyTrackRouter.MY_PRIVATE_TRACK]: idMyTrack(privateTrack),
        [typeMyTrackRouter.MY_DRAFT_TRACK]: idMyTrack(drafts),
        [typeMyTrackRouter.MY_PENDING_TRACK]: idMyTrack(tracksPending),
      };
      return roleText[type] || idMyTrack(released);
    },
    [drafts, idMyTrack, privateTrack, released, scheduleTrack, tracksPending]
  );

  const handleSelectAll = useCallback(
    (isChecked: boolean, type?: string) => {
      if (isChecked) {
        setIsSelectAll(true);
        setSelectCheckbox(dataCheckBox(type));
      } else {
        setSelectCheckbox([]);
        setIsSelectAll(false);
      }
    },
    [dataCheckBox]
  );

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const debouncedSearchText = useDebounce<string>(searchText, 200);

  const handleShowModalLinkExpires = useCallback(
    (track) => {
      setShowModalLinkExpiration();
      setSelectedTrack(track);
    },
    [setShowModalLinkExpiration]
  );

  return {
    scheduleTrack,
    privateTrack,
    released,
    drafts,
    isLoading,
    handleSearchText,
    onGetDraftsTracks,
    onGetReleasedTracks,
    onGetPrivateTracks,
    onGetScheduleTracks,
    debouncedSearchText,
    handleCheckbox,
    handleRemoveTrack,
    selectCheckbox,
    handleSelectAll,
    setSelectCheckbox,
    setIsSelectAll,
    isSelectAll,
    showModalLinkExpiration,
    setShowModalLinkExpiration,
    methods,
    allUser,
    handleShowModalLinkExpires,
    selectedTrack,
    onGetPendingTracks,
    tracksPending,
    filesAutoTagged,
    filesCustomTagged,
    filesTrackoutStems,
    autoTaggedInProgress,
    customTaggedInProgress,
    trackoutStemsInProgress,
  };
};
