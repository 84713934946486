export const cartType = {
  SUBSCRIPTION: "SUBSCRIPTION",
  TRACK: "TRACK",
};

export const cardIconType = {
  AMEX: "amex",
  CARTES_BANCAIRES: "cartes_bancaires",
  DINERS: "diners",
  DISCOVER: "discover",
  JCB: "jcb",
  MASTERCARD: "mastercard",
  VISA: "visa",
  UNIONPAY: "unionpay",
  DEFAULT: "default",
};
