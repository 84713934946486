import "dayjs/locale/pt";
import "dayjs/locale/es";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const format = (item: any, locale?: string) => {
  return dayjs(item?.releaseDate)
    .locale(locale ? locale : "en")
    .fromNow();
};

export const timeFromNow = (item: any, format?: string) => {
  return dayjs(item?.releaseDate).format(format || "MMMM D, YYYY");
};

export const timeDay = (releaseDate: any) => {
  return dayjs(releaseDate).format("D");
};

export const getDayOptions = (element: any) => {
  return dayjs().add(element + 1, "d");
};

export const timeMonth = (releaseDate: any) => {
  return dayjs(releaseDate).format("MM");
};
export const timeYear = (releaseDate: any) => {
  return dayjs(releaseDate).format("YYYY");
};

export const releaseFormatDate = (releaseDate: any) => {
  return dayjs(releaseDate).format("YYYY-MM-DDTHH:mm");
};

export const isBeforeFromNow = (date: Date | string) => {
  return dayjs().isBefore(dayjs(date));
};

export const formatDate = (
  date: Date | string,
  formatType: string = "YYYY-MM-DDTHH:mm"
) => {
  return dayjs(date).format(formatType);
};

export const dayjsFormat = dayjs;

export const fromNow = (time: any, type = true) => {
  return dayjs(time).fromNow(type);
};

export const timeFormat = (time: any, formatType = "MMMM, D YYYY HH:mm A") => {
  return dayjs(time).format(formatType);
};

export const getTimeString = (totalMins: any) => {
  let time = "";
  const HOURS_PER_DAY = 24;
  const MINS_PER_HOUR = 60;
  const days = Math.floor(totalMins / HOURS_PER_DAY / MINS_PER_HOUR);
  const hours = Math.floor((totalMins / MINS_PER_HOUR) % HOURS_PER_DAY);
  const mins = Math.floor(totalMins % MINS_PER_HOUR);
  if (days > 0) {
    time += `${days}d `;
  }
  if (hours > 0) {
    time += `${hours}h `;
  }
  if (mins > 0) {
    time += `${mins}m`;
  }
  return time;
};

export const formatDayTransaction = (time: any, formatType = "MM.DD.YYYY") => {
  return dayjs(time).format(formatType);
};

export const todayTime = () => {
  return dayjs().format("DD-MM-YYYY");
};

export const yesterdayTime = () => {
  return dayjs().subtract(1, "d").format("DD-MM-YYYY");
};

export const formatTimePlaylist = (time: any) => {
  return dayjs(time).format("DD/MM/YYYY");
};

export const formatTimeByEn = (time: any) => {
  return dayjs(time).locale("en").format("MMMM D, YYYY");
};

export const formatTimeByDayMonthYear = (time: any) => {
  return dayjs(time).locale("en").format("dddd,MMM D, YYYY HH:mm:ss A");
};

export const formatMonthYear = (time: any) => {
  return dayjs(time).locale("en").format("MMMM YYYY");
};
