import UserService from "app/apis/users";
import { Dispatch } from "redux";
import {
  setLocalStorage,
  removeLocalStorage,
  getJwtLocalStorage,
} from "helpers/local-storage";
import { IS_REMEMBER_ME, JWT_LOCALSTORAGE } from "constants/index";
import { generateActionTypes } from "../actionTypes";
import { AddMemberMailchimpModel, User } from "app/models";
import { ForgotPassword, SignupPayload, VerificationCode } from "./interface";
import {
  getJwtSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from "helpers/session";
import { followUnfollowRecentActionsHome } from "../home";
import ToastPlanSubscription from "app/components/toast/toastplan";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const TYPES = {
  CLEAR_STORE: "CLEAR_STORE",
  LOGIN: "LOGIN",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  CREATE_USER: "CREATE_USER",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_ERROR: "CREATE_USER_ERROR",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  WHO_ARE_YOU: "WHO_ARE_YOU",
  MUSIC_GENRES: "MUSIC_GENRES",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_ERROR: "FORGOT_PASSWORD_ERROR",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_REQUEST: "CHANGE_PASSWORD_REQUEST",
  CHANGE_PASSWORD_ERROR: "CHANGE_PASSWORD_ERROR",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  VERIFY_ACCOUNT_REQUEST: "CHANGE_PASSWORD_REQUEST",
  VERIFY_ACCOUNT_ERROR: "CHANGE_PASSWORD_ERROR",
  VERIFY_ACCOUNT_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  AUTH_TOUCH_ID: "AUTH_TOUCH_ID",
  BIOMETRIC_REQUEST: "BIOMETRIC_REQUEST",
  BIOMETRIC_ERROR: "BIOMETRIC_ERROR",
  BIOMETRIC_SUCCESS: "BIOMETRIC_SUCCESS",
  BIOMETRIC_ENABLE_DISABLE: "BIOMETRIC_ENABLE_DISABLE",
  COMPLETE_CREATE_USER_REQUEST: "COMPLETE_CREATE_USER_REQUEST",
  COMPLETE_CREATE_USER_SUCCESS: "COMPLETE_CREATE_USER_SUCCESS",
  COMPLETE_CREATE_USER_ERROR: "COMPLETE_CREATE_USER_ERROR",
  STORE_CREDENTIALS: "STORE_CREDENTIALS",
  LINK_CLICKED_COUNT: "LINK_CLICKED_COUNT",
  GET_ANALYTICS_CURRENT_USER: "GET_ANALYTICS_CURRENT_USER",
  GET_LIST_LICENSE_EXPIRY: "GET_LIST_LICENSE_EXPIRY",
  GET_USER_OVER_VIEW: "GET_USER_OVER_VIEW",
  CHECK_OTP_CODE: "CHECK_OTP_CODE",
  LIST_SPOTIFY_ARTIST: "LIST_SPOTIFY_ARTIST",
  LIST_SPOTIFY_TRACK: "LIST_SPOTIFY_TRACK",
  GET_ARTISTS_SPOTIFY_OF_USER: "GET_ARTISTS_SPOTIFY_OF_USER",
  GET_TRACKS_SPOTIFY_OF_USER: "GET_TRACKS_SPOTIFY_OF_USER",
  CHANGE_CURRENT_PASSWORD: "CHANGE_CURRENT_PASSWORD",
  CONNECT_MAILCHIMP: "CONNECT_MAILCHIMP",
  GET_ACCESS_TOKEN_MAILCHIMP: "GET_ACCESS_TOKEN_MAILCHIMP",
  DISCONNECT_MAILCHIMP: "DISCONNECT_MAILCHIMP",
  SELECTED_LIST_MAILCHIMP: "SELECTED_LIST_MAILCHIMP",
  ADD_MEMBER_MAILCHIMP: "ADD_MEMBER_MAILCHIMP",
  CHANGE_FEE_STRUCTURE: "CHANGE_FEE_STRUCTURE",
};

export const userAdmin = generateActionTypes("GET_USER_ADMIN");
export const userInfo = generateActionTypes("GET_USER_INFO");
export const updateUser = generateActionTypes("UPDATE-USER");
export const createUserToFacebook = generateActionTypes("CREATE_USER_FACEBOOK");
export const registerGoogle = generateActionTypes("REGISTER-GOOGLE");
export const loginGoogle = generateActionTypes("LOGIN-GOOGLE");
export const clearStore = generateActionTypes("CLEAR-STORE");
export const forgotPassword = generateActionTypes("FORGOT-PASSWORD");
export const resetPassword = generateActionTypes("RESET-PASSWORD");
export const followUnfollow = generateActionTypes("FOLLOW_UNFOLLOW");
export const getRecentActionByUserId = generateActionTypes("GET_USER_ID");

export const clearStoreSuccess = () => ({
  type: clearStore.SUCCESS,
  payload: true,
});

// --------------------------------------------------------------
// LOGIN
// --------------------------------------------------------------

const loginRequest = () => ({
  type: TYPES.LOGIN_REQUEST,
  payload: null,
});

const loginError = (error: any) => ({
  type: TYPES.LOGIN_ERROR,
  payload: { error },
});

const loginSuccess = (token: string, socialMediaLogin?: boolean) => ({
  type: TYPES.LOGIN_SUCCESS,
  payload: { token, loggedIn: true, socialMediaLogin },
});

export const login =
  (credentials: {
    email: string;
    password: string;
    isRememberMe: boolean;
    device?: string;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(loginRequest());
    try {
      const token = await UserService.login(credentials);
      dispatch(loginSuccess(token));
      if (typeof token === "string") {
        if (credentials.isRememberMe) {
          setLocalStorage(JWT_LOCALSTORAGE, token);
        } else {
          setSessionStorage(JWT_LOCALSTORAGE, token);
        }
        setLocalStorage(IS_REMEMBER_ME, credentials.isRememberMe);
        window.location.reload();
        return;
      } else {
        return token;
      }
    } catch (error: any) {
      dispatch(loginError(error.message));
    }
  };

export const logout =
  ({
    isLogoutAll = false,
    userId,
  }: {
    isLogoutAll?: boolean;
    userId?: string;
  }) =>
  async (dispatch: Dispatch) => {
    try {
      const token = getJwtLocalStorage() || getJwtSessionStorage();
      UserService.logout({ token, isLogoutAll, userId });
      await removeLocalStorage(JWT_LOCALSTORAGE);
      await removeSessionStorage(JWT_LOCALSTORAGE);
    } finally {
      dispatch(clearStoreSuccess());
    }
  };

// --------------------------------------------------------------
// CREATE USER
// --------------------------------------------------------------

const createUserRequest = () => ({
  type: TYPES.CREATE_USER_REQUEST,
  payload: null,
});

const createUserError = (error: any) => ({
  type: TYPES.CREATE_USER_ERROR,
  payload: { error },
});

const createUserSuccess = (payload: any) => ({
  type: TYPES.CREATE_USER_SUCCESS,
  payload: { ...payload },
});
const createUserFacebookSuccess = () => ({
  type: createUserToFacebook.SUCCESS,
});

export const createUser =
  (body: SignupPayload) => async (dispatch: Dispatch) => {
    dispatch(createUserRequest());
    body.email = body.email.trim();
    try {
      const res = await UserService.createUser(body);
      dispatch(
        createUserSuccess({
          userType: body.role,
        })
      );
      return res;
    } catch (error: any) {
      dispatch(createUserError(error.message));
    }
  };

// GET USERINFOR

const getUserInfoRequest = () => ({
  type: userAdmin.REQUEST,
  payload: null,
});

const getUserInfoError = (error: any) => ({
  type: userAdmin.ERROR,
  payload: { error },
});

const getUserInfoSuccess = (payload: User) => ({
  type: userAdmin.SUCCESS,
  payload: { ...payload },
});

export const getUserInfo = () => async (dispatch: Dispatch) => {
  dispatch(getUserInfoRequest());
  try {
    const data = await UserService.getUserInfo();
    dispatch(getUserInfoSuccess(data));
    return data;
  } catch (error: any) {
    dispatch(getUserInfoError(error.message));
  }
};

//GET USERID

const getUserByUsernameRequest = () => ({
  type: userInfo.REQUEST,
  payload: null,
});

const getUserByUsernameError = (error: any) => ({
  type: userInfo.ERROR,
  payload: { error },
});

const getUserByUsernameSuccess = (payload: User) => ({
  type: userInfo.SUCCESS,
  payload: { ...payload },
});

export const getUserByUsername =
  (username: string, logginAsGuest: boolean) => async (dispatch: Dispatch) => {
    dispatch(getUserByUsernameRequest());
    try {
      const data = await UserService.getUserByUsername(username, logginAsGuest);
      dispatch(getUserByUsernameSuccess(data));
      return data?._id;
    } catch (error: any) {
      dispatch(getUserByUsernameError(error.message));
    }
  };

// UPDATE USER

const updateUserRequest = () => ({
  type: updateUser.REQUEST,
  payload: null,
});

const updateUserError = (error: any) => ({
  type: updateUser.ERROR,
  payload: { error },
});

const updateUserSuccess = (payload: User) => ({
  type: updateUser.SUCCESS,
  payload: { ...payload },
});

export const editUserInfo = (value: User) => async (dispatch: Dispatch) => {
  dispatch(updateUserRequest());
  try {
    const data = await UserService.editUserInfo(value);
    dispatch(updateUserSuccess(data));
    return data;
  } catch (error: any) {
    dispatch(updateUserError(error.message));
  }
};

export const loginUserFacebook =
  (accessToken: any) => async (dispatch: Dispatch) => {
    dispatch(loginRequest());
    try {
      const token = await UserService.loginFacebook(accessToken);
      setLocalStorage(JWT_LOCALSTORAGE, token);
      dispatch(loginSuccess(token, true));
    } catch (error: any) {
      dispatch(loginError(error.message));
      ToastPlanSubscription({ description: error?.message });
    }
  };

export const createUserFacebook =
  (accessToken: any, callback: any) => async (dispatch: Dispatch) => {
    dispatch(createUserRequest());
    try {
      await UserService.createUserFacebook({
        idToken: accessToken.accessToken,
        refBy: accessToken.refBy,
      });
      dispatch(createUserFacebookSuccess());
      if (callback && typeof callback === "function") {
        if (callback.constructor.name === "AsyncFunction") {
          await callback();
        } else {
          callback();
        }
      }
    } catch (error: any) {
      dispatch(createUserError(error?.message));
      ToastPlanSubscription({ description: error?.message });
    }
  };

// REGISTER GOOGLE

const createUserGoogleRequest = () => ({
  type: registerGoogle.REQUEST,
  payload: null,
});

const createUserGoogleError = (error: any) => ({
  type: registerGoogle.ERROR,
  payload: error,
});

const createUserGoogleSuccess = (payload: User) => ({
  type: registerGoogle.SUCCESS,
  payload: payload,
});

export const createUserGoogle =
  (value: any, callback: any) => async (dispatch: Dispatch) => {
    dispatch(createUserGoogleRequest());
    try {
      const data = await UserService.createUserGoogle(value);
      dispatch(createUserGoogleSuccess(data));
      if (callback && typeof callback === "function") {
        if (callback.constructor.name === "AsyncFunction") {
          await callback();
        } else {
          callback();
        }
      }
    } catch (error: any) {
      dispatch(createUserGoogleError(error.message));
    }
  };

// LOGIN GOOGLE

const loginUserGoogleRequest = () => ({
  type: loginGoogle.REQUEST,
  payload: null,
});

const loginUserGoogleError = (error: any) => ({
  type: loginGoogle.ERROR,
  payload: error,
});

const loginUserGoogleSuccess = () => ({
  type: loginGoogle.SUCCESS,
  payload: "",
});

export const LoginUserGoogle = (value: any) => async (dispatch: Dispatch) => {
  dispatch(loginUserGoogleRequest());
  try {
    await UserService.loginGoogle(value);
    dispatch(loginUserGoogleSuccess());
  } catch (error: any) {
    dispatch(loginUserGoogleError(error.message));
  }
};

// FORGOT PASSWORD
const forgotPasswordRequest = () => ({
  type: forgotPassword.REQUEST,
  payload: null,
});

const forgotPasswordError = (error: any) => ({
  type: forgotPassword.ERROR,
  payload: error,
});

const forgotPasswordSuccess = () => ({
  type: forgotPassword.SUCCESS,
  payload: "",
});
export const forgotPasswordUser =
  (payload: ForgotPassword) => async (dispatch: Dispatch) => {
    dispatch(forgotPasswordRequest());
    try {
      await UserService.forgotPasswordUser(payload);
      dispatch(forgotPasswordSuccess());
    } catch (error: any) {
      dispatch(forgotPasswordError(error.message));
    }
  };

// RESET PASSWORD

const resetPasswordRequest = () => ({
  type: resetPassword.REQUEST,
  payload: null,
});

const resetPasswordError = (error: any) => ({
  type: resetPassword.ERROR,
  payload: error,
});

const resetPasswordSuccess = () => ({
  type: resetPassword.SUCCESS,
  payload: "",
});

export const resetPasswordUser =
  (password: string, token: string) => async (dispatch: Dispatch) => {
    dispatch(resetPasswordRequest());
    try {
      await UserService.resetPasswordUser(password, token);
      dispatch(resetPasswordSuccess());
    } catch (error: any) {
      dispatch(resetPasswordError(error.message));
    }
  };

const followUnfollowRequest = () => ({
  type: followUnfollow.REQUEST,
  payload: null,
});

const followUnfollowError = (error: any) => ({
  type: followUnfollow.ERROR,
  payload: { error },
});

const followUnfollowSuccess = (payload: string) => ({
  type: followUnfollow.SUCCESS,
  payload: payload,
});

export const followUnfollowUser =
  (userId?: string) => async (dispatch: Dispatch) => {
    dispatch(followUnfollowRequest());
    try {
      await UserService.followUnfollow(userId);
      dispatch(followUnfollowSuccess(userId));
      dispatch(followUnfollowRecentActionsHome(userId));
    } catch (error: any) {
      dispatch(followUnfollowError(error.message));
    }
  };

//GET USERBYUSERID

const getUserByUserIdRequest = () => ({
  type: getRecentActionByUserId.REQUEST,
  payload: null,
});

const getUserByUserIdError = (error: any) => ({
  type: getRecentActionByUserId.ERROR,
  payload: { error },
});

const getUserByUserIdSuccess = (payload: User) => ({
  type: getRecentActionByUserId.SUCCESS,
  payload: payload,
});

export const getUserById = (id: string) => async (dispatch: Dispatch) => {
  dispatch(getUserByUserIdRequest());
  try {
    const data = await UserService.getUserByUserId(id);
    dispatch(getUserByUserIdSuccess(data));
  } catch (error: any) {
    dispatch(getUserByUserIdError(error.message));
  }
};

export const refLinkClickedCount = createAsyncThunk(
  TYPES.LINK_CLICKED_COUNT,
  async (name: string) => {
    return await UserService.refLinkClickedCount(name);
  }
);

export const getAnalyticsCurrentUser = createAsyncThunk(
  TYPES.GET_ANALYTICS_CURRENT_USER,
  UserService.getAnaLyticsCurrentUser
);

export const getListLicenseExpiry = createAsyncThunk(
  TYPES.GET_LIST_LICENSE_EXPIRY,
  async (timeExpiry: string) => {
    return await UserService.getListLicenseExpiry(timeExpiry);
  }
);

export const getUserOverView = createAsyncThunk(
  TYPES.GET_USER_OVER_VIEW,
  UserService.getUserOverView
);

export const checkOtpCode = createAsyncThunk(
  TYPES.CHECK_OTP_CODE,
  async (credentials: VerificationCode) => {
    return await UserService.checkVerificationCode(credentials);
  }
);

export const getListArtistSpotify = createAsyncThunk(
  TYPES.LIST_SPOTIFY_ARTIST,
  async (title: string) => {
    return UserService.getListSpotifyArtist(title);
  }
);

export const getListTracksSpotify = createAsyncThunk(
  TYPES.LIST_SPOTIFY_TRACK,
  async (title: string) => {
    return UserService.getListSpotifyTrack(title);
  }
);

export const getArtistsSpotifyOfUser = createAsyncThunk(
  TYPES.GET_ARTISTS_SPOTIFY_OF_USER,
  async (idArtists: string[]) => {
    return await UserService.getArtistsSpotify(idArtists);
  }
);

export const getTracksSpotifyOfUser = createAsyncThunk(
  TYPES.GET_TRACKS_SPOTIFY_OF_USER,
  async (idTracks: string[]) => {
    return await UserService.getTracksSpotify(idTracks);
  }
);

export const changeCurrentPassword = createAsyncThunk(
  TYPES.CHANGE_CURRENT_PASSWORD,
  async (body: { email: string; password: string; newPassword: string }) => {
    return await UserService.changeCurrentPassword(
      body.email,
      body?.password,
      body.newPassword
    );
  }
);

export const connectMailChimp = createAsyncThunk(
  TYPES.CONNECT_MAILCHIMP,
  UserService.connectMailChimp
);

export const getAccessTokenMailChimp = createAsyncThunk(
  TYPES.GET_ACCESS_TOKEN_MAILCHIMP,
  async (code: string, thunkApi) => {
    const user = await UserService.getAccessTokenMailChimp(code);
    return thunkApi.dispatch(updateUserSuccess(user));
  }
);

export const disconnectMailChimp = createAsyncThunk(
  TYPES.DISCONNECT_MAILCHIMP,
  async (_, thunkApi) => {
    const user = await UserService.disconnectMailChimp();
    return thunkApi.dispatch(updateUserSuccess(user));
  }
);

export const selectedListMailChimp = createAsyncThunk(
  TYPES.SELECTED_LIST_MAILCHIMP,
  async (webId: number) => {
    return await UserService.selectedListMailChimp(webId);
  }
);

export const addMemberMailChimp = createAsyncThunk(
  TYPES.ADD_MEMBER_MAILCHIMP,
  async (body: AddMemberMailchimpModel) => {
    return await UserService.addMemberMailChimp(body);
  }
);

export const changeFeeStructure = createAsyncThunk(
  TYPES.CHANGE_FEE_STRUCTURE,
  async (value: string) => {
    return await UserService.changeFeeStructure(value);
  }
);
