import React from "react";

const IconTotalItems = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M6.68951 0.7H6.6895H3.25C3.03783 0.7 2.83434 0.784285 2.68431 0.934315C2.53429 1.08434 2.45 1.28783 2.45 1.5V4.9395V4.93951C2.45005 5.15166 2.53436 5.35511 2.68439 5.50511C2.6844 5.50511 2.6844 5.50511 2.6844 5.50511L7.93439 10.7551L7.9344 10.7551C8.08442 10.9051 8.28787 10.9893 8.5 10.9893C8.71213 10.9893 8.91558 10.9051 9.0656 10.7551L9.06561 10.7551L12.5051 7.31561L12.5051 7.3156C12.6551 7.16558 12.7393 6.96213 12.7393 6.75C12.7393 6.53787 12.6551 6.33442 12.5051 6.1844L12.5051 6.18439L7.25511 0.9344C7.25511 0.934398 7.25511 0.934396 7.25511 0.934395C7.10511 0.784358 6.90166 0.700045 6.68951 0.7ZM8.5 10.1188L3.3 4.91879V1.55H6.66879L11.8688 6.75L8.5 10.1188Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.1"
      />
      <path
        d="M2.0051 5.80939C1.87382 5.67815 1.80004 5.50013 1.8 5.3145C1.8 5.3145 1.8 5.31449 1.8 5.31449V1.5V1.45H1.75C1.53783 1.45 1.33434 1.53429 1.18431 1.68431C1.03429 1.83434 0.95 2.03783 0.95 2.25V5.6895V5.68951C0.950045 5.90166 1.03436 6.10511 1.18439 6.25511C1.1844 6.25511 1.1844 6.25511 1.1844 6.25511L6.43439 11.5051L6.4344 11.5051C6.58442 11.6551 6.78787 11.7393 7 11.7393C7.21213 11.7393 7.41558 11.6551 7.5656 11.5051L7.56561 11.5051L7.59786 11.4729L7.63321 11.4375L7.59786 11.4021L2.00511 5.80939L2.0051 5.80939ZM5.125 3.7C5.0388 3.7 4.95614 3.66576 4.89519 3.60481C4.83424 3.54386 4.8 3.4612 4.8 3.375C4.8 3.2888 4.83424 3.20614 4.89519 3.14519C4.95614 3.08424 5.0388 3.05 5.125 3.05C5.2112 3.05 5.29386 3.08424 5.35481 3.14519C5.41576 3.20614 5.45 3.2888 5.45 3.375C5.45 3.4612 5.41576 3.54386 5.35481 3.60481C5.29386 3.66576 5.2112 3.7 5.125 3.7ZM5.125 4.55C5.43663 4.55 5.7355 4.42621 5.95585 4.20585C6.17621 3.9855 6.3 3.68663 6.3 3.375C6.3 3.06337 6.17621 2.7645 5.95585 2.54415C5.7355 2.32379 5.43663 2.2 5.125 2.2C4.81337 2.2 4.5145 2.32379 4.29415 2.54415C4.07379 2.7645 3.95 3.06337 3.95 3.375C3.95 3.68663 4.07379 3.9855 4.29415 4.20585C4.5145 4.42621 4.81337 4.55 5.125 4.55Z"
        fill="#D4F70E"
        stroke="#D4F70E"
        stroke-width="0.1"
      />
    </svg>
  );
};
export default IconTotalItems;
