export const TRACK_TYPE: { [key: string]: string } = {
  BEAT: "beat",
  BEAT_WITH_CHORUS: "beatWithChorus",
  TOP_LINE: "topLine",
  VOCALS: "vocals",
  SONG: "song",
  ONE_SHORT: "oneShot",
  DRUM_LOOPS: "drumLoops",
  MELODIC_LOOPS: "melodicLoops",
  EFFECTS: "effects",
  PRESETS: "presets",
  ALL: "all",
  MIDI_PACK: "midiPack",
};

const trackTypes = [
  { label: "beat", value: "BEAT" },
  {
    label: "beatWithChorus",
    value: "BEAT_WITH_CHORUS",
  },
  { label: "topLine", value: "TOP_LINE" },
  { label: "vocals", value: "VOCALS" },
  { label: "song", value: "SONG" },
];

export default trackTypes;
