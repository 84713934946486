import { Box, Image, Flex, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { usePlayers } from "hooks/player/usePlayers";
import AvataRecentActions from "app/assets/images/ImageDefaultLoading.png";
import IconToggleOpenFooterPlayer from "app/assets/icons/IconToggleOpenFooterPlayer";
import PlayButton from "./PlayButton";
import { TRACK_TYPE } from "constants/trackTypes";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import MarqueeText from "../MarqueeText";
interface ImageAudioBottomProps {
  handleTogglePlayPause: () => void;
  isPlaying: boolean;
}

export default function ImageAudioBottom({
  handleTogglePlayPause,
  isPlaying,
}: ImageAudioBottomProps) {
  const { playingTrack, handleRemoveImageBottom } = usePlayers();
  const { t } = useTranslation();

  useEffect(() => {
    const elmnt = document.getElementById("mydiv");
    if (elmnt) {
      dragElement(elmnt);
    }
  }, []);

  function dragElement(elmnt: HTMLElement) {
    let pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0;
    function dragMouseDown(e: MouseEvent) {
      e.preventDefault();
      // Get the mouse cursor position at startup
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // Call a function whenever the cursor moves
      document.onmousemove = elementDrag;
    }

    function elementDrag(e: MouseEvent) {
      e.preventDefault();
      // Calculate the new cursor position
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // Set the element's new position
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
    }

    function closeDragElement() {
      // Stop moving when the mouse button is released
      document.onmouseup = null;
      document.onmousemove = null;
    }

    elmnt.onmousedown = dragMouseDown;
  }

  return (
    <Box position="fixed" bottom="90px" right="270px">
      <Flex
        cursor="pointer"
        position="absolute"
        id="mydiv"
        background="#24252B"
        padding="8px"
        borderRadius="6px"
      >
        <Box
          onClick={handleRemoveImageBottom}
          position="relative"
          cursor="pointer"
        >
          <Box
            borderRadius="4px"
            width="64px"
            height="50px"
            background="rgba(240, 242, 236, 0.971)"
            boxShadow="15px 15px 130px 0px rgba(212, 247, 14, 0.25)"
            backdropFilter="blur(3px)"
          >
            <Image
              width="64px"
              height="50px"
              objectFit="cover"
              src={playingTrack?.coverUrl}
              borderRadius="4px"
              fallbackSrc={AvataRecentActions}
              opacity="0.5"
            />
          </Box>

          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%,-50%)"
          >
            <Box background="#24252A" borderRadius="4px">
              <IconToggleOpenFooterPlayer />
            </Box>
          </Box>
        </Box>
        <Box marginLeft="15px" w="150px">
          <MarqueeText text={playingTrack?.title} />
          <Flex>
            {playingTrack?.type && TRACK_TYPE[playingTrack?.type] && (
              <Box
                display="flex"
                alignItems="center"
                background="rgba(212, 247, 14, 0.1)"
                height="20px"
                borderRadius="4px"
                padding="0px 5px"
                mr="5px"
                mb="5px"
                whiteSpace="nowrap"
              >
                <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                  {t(TRACK_TYPE[playingTrack?.type])}
                </Text>
              </Box>
            )}
            <Link to={`/user/${playingTrack?.createdBy?.name}`}>
              <Text
                cursor="pointer"
                opacity="0.6"
                fontSize="14px"
                lineHeight="16px"
                className="text-song"
                maxW="160px"
                whiteSpace="nowrap"
                _hover={{
                  textDecor: "underline",
                }}
                color="#a0a0a1"
              >
                {playingTrack?.createdBy?.name}
              </Text>
            </Link>
          </Flex>
        </Box>
        <Box position="absolute" top="-25px" left="-20px">
          <PlayButton
            handleTogglePlayPause={handleTogglePlayPause}
            isPlaying={isPlaying}
            size={35}
          />
        </Box>
      </Flex>
    </Box>
  );
}
