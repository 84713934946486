import { RecentAction } from "app/models";
import { Box, Text } from "@chakra-ui/react";
import { selectRecentActionsOptionProfile } from "app/redux/selectors/profile";
import { useProfile } from "hooks/profile/useProfile";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RecentActions from "../RecentAction";
import ModalPriceTracks from "../ModalPriceTrack";
import ModalLyrics from "../ModalShowLyrics";
import useTrackSelect from "hooks/track/useTrackSelect";
import { FadeLoader } from "react-spinners";
import { filterTracks, heightLoadMore } from "utils/convertTracks";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalAddToPlaylist from "app/pages/Playlists/modalAddToPlaylist";
import ModalCreatePlaylist from "app/pages/Playlists/modalCreatePlaylist";
import SkeletonTrackSoundPack from "app/pages/SearchFilterPage/skeletonTracksSoundpacks";
import ModalShowFreeDownload from "../ModalShowFreeDownload";
import ModalShareLinkRecentActions from "app/pages/Home/modalShareLinkRecentActions";
import ModalShowFreeDownloadFormMailChimp from "../ModalShowFreeDownloadFormMailchimp";

interface MyFeedProps {
  userId: string;
}

export default function MyFeed({ userId }: MyFeedProps) {
  const existingOptions = useSelector(selectRecentActionsOptionProfile);
  const {
    getRecentActionItems,
    recentActionItems = [],
    isLoading,
  } = useProfile();
  const { t } = useTranslation();

  useEffect(() => {
    getRecentActionItems({ page: 1 }, userId);
  }, [getRecentActionItems, userId]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(existingOptions, e)) {
        getRecentActionItems(
          { page: 1 + existingOptions?.page, loadingMore: true },
          userId
        );
      }
    },
    [existingOptions, getRecentActionItems, userId]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const {
    selectedTrack,
    showOptionsModal,
    setShowOptionsModal,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    showModalFreeDownload,
    setShowModalFreeDownload,
    linkTitleDownload,
    handleFreeDownload,
    showModalShareLink,
    setShowModalShareLink,
    shortLink,
    loadingUrl,
    showModalAndGetdataShareLink,
    setLoadingUrl,
    setShortLink,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();

  const filterDuplicateRecentActionProfile = filterTracks(recentActionItems);

  const listPlayTracksSoundpack = useMemo(() => {
    return (filterDuplicateRecentActionProfile || [])
      ?.map((item) => item?.track || item?.soundkit)
      ?.filter(Boolean);
  }, [filterDuplicateRecentActionProfile]);

  return (
    <Box>
      {isLoading && !existingOptions?.loadingMore ? (
        <SkeletonTrackSoundPack />
      ) : (filterDuplicateRecentActionProfile || []).length > 0 ? (
        (filterDuplicateRecentActionProfile || []).map(
          (item: RecentAction, index: number) => {
            return (
              <RecentActions
                key={index}
                isProfile
                item={item}
                index={index}
                onOpenOptionsModal={onOpenOptionsModal}
                handleShowModalAddTrack={handleShowModalAddTrack}
                handleFreeDownload={handleFreeDownload}
                onOpenModalShareLink={showModalAndGetdataShareLink}
                setLoadingUrl={setLoadingUrl}
                setShortLinkUrl={setShortLink}
                listPlaylistTracksSoundPacks={listPlayTracksSoundpack}
                handleShowModalFreeDownloadMailchimp={
                  handleShowModalFreeDownloadMailchimp
                }
              />
            );
          }
        )
      ) : (
        <EmptyTrackSoundkit />
      )}

      <Box display="flex" justifyContent="center">
        <FadeLoader color={"#d4f70e"} loading={existingOptions?.loadingMore} />
      </Box>
      {!existingOptions.hasMore && recentActionItems.length > 25 && (
        <Text fontSize="14px" textAlign="center" color="#464649">
          {t("upToDate")}
        </Text>
      )}

      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showModalAddToPlaylist && (
        <ModalAddToPlaylist
          trackId={trackId}
          setShowModal={setShowModalAddToPlaylist}
          showModal={showModalAddToPlaylist}
          setShowModalCreatePlaylist={setShowModalCreatePlaylist}
          playlists={playlists}
          handleSearchText={handleSearchText}
          debouncedSearchText={debouncedSearchText}
        />
      )}
      {showModalCreatePlaylist && (
        <ModalCreatePlaylist
          showModal={showModalCreatePlaylist}
          setShowModal={setShowModalCreatePlaylist}
        />
      )}
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showModalShareLink && (
        <ModalShareLinkRecentActions
          showModalShareLink={showModalShareLink}
          setShowModalShareLink={setShowModalShareLink}
          selectedTrackSoundkit={selectedTrack}
          shortLinkUrl={shortLink}
          loadingUrl={loadingUrl}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}
