import { STATUS } from "constants/statusType";
import { RootState } from "types";

const { LOADING, NOT_STARTED, SUCCESS } = STATUS;

const matchStatus = (
  actions: string[],
  state: RootState,
  checkStatus: string,
  defaulStatus: boolean
) =>
  actions.reduce((_, value) => {
    const status = state.status[value] || NOT_STARTED;
    return status === checkStatus;
  }, defaulStatus);

export const notStartedSelector = (actions: string[], state: RootState) =>
  matchStatus(actions, state, NOT_STARTED, true);

export const isLoadingSelector = (actions: string[], state: RootState) =>
  matchStatus(actions, state, LOADING, false);

export const successSelector = (actions: string[], state: RootState) =>
  matchStatus(actions, state, SUCCESS, true);
