import client from "services/axios.service";

export const uploadMoreFilesService = (
  file: File,
  onProgress: Function,
  isUploadFiles?: boolean,
  updateValue = ""
): Promise<string | void> => {
  return new Promise(async (resolve, reject) => {
    const isImage = file.type.includes("image/");
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("type", file.type);
      formData.append("isPublic", isImage ? "true" : "false");
      let linkResponse;
      if (updateValue) {
        linkResponse = await client.put(
          `/storage/upload-more-files/${updateValue}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (ev) => {
              if (onProgress) {
                const percentCompleted = Math.round(
                  (ev.loaded / ev.total) * 100
                );
                onProgress(percentCompleted);
              }
            },
          }
        );
      } else {
        linkResponse = await client.post(
          "/storage/upload-more-files",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            onUploadProgress: (ev) => {
              if (onProgress) {
                const percentCompleted = Math.round(
                  (ev.loaded / ev.total) * 100
                );
                onProgress(percentCompleted);
              }
            },
          }
        );
      }
      const { cloudFrondSigned } = linkResponse.data;
      resolve(cloudFrondSigned);
    } catch (err) {
      reject(err);
    }
  });
};
