import {
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import IconBuyerFee from "app/assets/icons/IconBuyerFee";
import IconSellerFee from "app/assets/icons/IconSellerFee";
import { changeFeeStructure } from "app/redux/actions/users";
import { selectUserAdmin } from "app/redux/selectors/users";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

interface ModalChooseYourFeeStructureProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

export default function ModalChooseYourFeeStructure({
  showModal,
  setShowModal,
}: ModalChooseYourFeeStructureProps) {
  const { t } = useTranslation();
  const currentUser = useSelector(selectUserAdmin);

  const [platformFeeDefault, setPlatformFeeDefault] = useState<string>(
    currentUser?.platformFee === 0 ? "BUYER_COVER_FEE" : "I_AGREED_COVER_FEE"
  );

  const dispatch = useDispatch();
  const listFeeStructure = useMemo(
    () => [
      {
        des: t("agreedToCoverFee"),
        icon: <IconSellerFee />,
        value: "I_AGREED_COVER_FEE",
      },
      {
        des: t("buyerCoverFee"),
        icon: <IconBuyerFee />,
        value: "BUYER_COVER_FEE",
      },
    ],
    [t]
  );
  const handleConfirm = useCallback(() => {
    dispatch(changeFeeStructure(platformFeeDefault));
    setShowModal(false);
  }, [dispatch, platformFeeDefault, setShowModal]);

  return (
    <Modal
      isOpen={showModal}
      onClose={() => setShowModal(false)}
      isCentered
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent
        m={{ base: "15px", xl: "0px" }}
        minWidth={{ base: "350px", md: "500px" }}
        className="hihi"
        maxW="fit-content"
      >
        <ModalHeader fontSize={{ base: "20px", md: "24px" }} fontWeight="400">
          {t("chooseYourFeeStructure")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => setShowModal(false)}
          right={2}
          top="12px"
          fontSize="20px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />

        <ModalBody>
          <Flex gap="20px" display={{ base: "block", md: "flex" }}>
            {listFeeStructure.map((item) => (
              <Box
                cursor="pointer"
                key={item.des}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                background={
                  platformFeeDefault === item.value ? "#353A27" : "#38383D40"
                }
                borderRadius="6px"
                border={
                  platformFeeDefault === item.value
                    ? "1px solid #D4F70E"
                    : "1px solid #38383D"
                }
                padding="20px"
                minW="268px"
                mb={{ base: "20px", md: "0px" }}
                color={
                  platformFeeDefault === item.value ? "#D4F70E" : "#ffffff"
                }
                onClick={() => setPlatformFeeDefault(item.value)}
              >
                {item.icon}
                <Text mt="16px">{item.des}</Text>
              </Box>
            ))}
          </Flex>
        </ModalBody>
        <ModalFooter
          padding={{ base: "40px 24px 40px", xl: "40px 24px 40px" }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Button
            w="100%"
            color="#D4F70E"
            background="#353A27"
            border="1px solid #D4F70E"
            borderRadius="4px"
            fontSize="14px"
            fontWeight="500"
            padding="25px 0px"
            onClick={() => handleConfirm()}
            _hover={{
              background: "#353A27",
            }}
            _active={{
              background: "#353A27",
            }}
          >
            {t("confirm")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
