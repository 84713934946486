import React from "react";

const VideoHomePage = () => {
  return (
    <svg
      width="15"
      height="10"
      viewBox="0 0 15 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.8333 2.16797L9.75 5.08464L13.8333 8.0013V2.16797Z"
        stroke="#DBF352"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.58333 1H2.16667C1.52233 1 1 1.52233 1 2.16667V8C1 8.64433 1.52233 9.16667 2.16667 9.16667H8.58333C9.22767 9.16667 9.75 8.64433 9.75 8V2.16667C9.75 1.52233 9.22767 1 8.58333 1Z"
        stroke="#DBF352"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default VideoHomePage;
