import { RecentAction, ShortLinkModel } from "app/models";
import axios from "axios";
import { getActionType, getIcon } from "constants/index";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import axiosService from "services/axios.service";
import ToastPlanSubscription from "app/components/toast/toastplan";

export const getData = (recentAction: RecentAction) => {
  let data = {
    ...recentAction,
    action: recentAction.action,
    actionType: getActionType(recentAction.action, recentAction.type),
    icon: getIcon(recentAction.action),
  };
  if (recentAction.track) {
    data.track = {
      ...recentAction.track,
      key: TRACK_KEY_NOTATION[recentAction.track.key],
      // stringKey: recentAction.track.key,
      // stringKey: recentAction.key,
    };
  }

  if (recentAction.soundkit) {
    data.soundkit = {
      ...recentAction.soundkit,
      // key: TRACK_KEY_NOTATION[recentAction.soundkit.key],
      // stringKey: recentAction.soundkit.key,
      // stringKey: recentAction.key,
    };
  }
  return data;
};

export const getIpAddress = async () => {
  try {
    const { data } = await axios.get("https://geolocation-db.com/json/", {
      timeout: 3000,
    });
    return data?.country_code;
  } catch (error) {
    return "US";
  }
};

export const shortLinkTrackSoundkitDetail = async (
  body: ShortLinkModel,
  isExpiration?: boolean
) => {
  try {
    return await axiosService.post(
      `/shortio/${
        isExpiration ? "create-short-link-expiration" : "create-short-link"
      }`,
      body
    );
  } catch (error) {
    ToastPlanSubscription({ description: error || "" });
  }
};
