import React from "react";

interface IconLikeIndividualTrackMobileProps {
  isFavorite?: boolean;
}

const IconLikeIndividualTrackMobile = ({
  isFavorite,
}: IconLikeIndividualTrackMobileProps) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5304 1.5021C13.6815 0.639386 12.556 0.114865 11.3586 0.0239261C10.1612 -0.067013 8.9714 0.28167 8.00564 1.00656C6.98827 0.237783 5.72196 -0.110819 4.46173 0.030958C3.20149 0.172735 2.04094 0.794359 1.21377 1.77065C0.38661 2.74694 -0.0457241 4.00538 0.00383443 5.29255C0.053393 6.57972 0.581163 7.80001 1.48086 8.70767L7.43792 14.7597C7.51226 14.8358 7.60069 14.8963 7.69813 14.9375C7.79557 14.9788 7.90009 15 8.00564 15C8.1112 15 8.21571 14.9788 8.31315 14.9375C8.41059 14.8963 8.49903 14.8358 8.57336 14.7597L14.5304 8.70767C14.9963 8.23466 15.3659 7.67302 15.6181 7.05483C15.8702 6.43665 16 5.77405 16 5.10489C16 4.43573 15.8702 3.77312 15.6181 3.15494C15.3659 2.53676 14.9963 1.97511 14.5304 1.5021ZM13.403 7.56226L8.00564 13.0375L2.60831 7.56226C2.13268 7.07704 1.80868 6.45993 1.67695 5.78835C1.54522 5.11677 1.61162 4.42063 1.86783 3.78724C2.12404 3.15386 2.55865 2.61144 3.11713 2.22804C3.67562 1.84463 4.33311 1.63731 5.00712 1.63208C5.90757 1.63432 6.77033 1.99953 7.40594 2.64752C7.48027 2.72366 7.56871 2.78409 7.66615 2.82534C7.76359 2.86658 7.8681 2.88781 7.97366 2.88781C8.07922 2.88781 8.18373 2.86658 8.28117 2.82534C8.37861 2.78409 8.46704 2.72366 8.54138 2.64752C9.19571 2.07146 10.041 1.76948 10.9062 1.80269C11.7715 1.8359 12.5919 2.20182 13.2016 2.82639C13.8113 3.45096 14.1647 4.28753 14.1902 5.16678C14.2157 6.04603 13.9114 6.90229 13.339 7.56226H13.403Z"
        fill={isFavorite ? "#D4F70E" : "white"}
      />
    </svg>
  );
};
export default IconLikeIndividualTrackMobile;
