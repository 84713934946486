import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Button,
  Switch,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  InputRightElement,
  useBoolean,
  useMediaQuery,
} from "@chakra-ui/react";
import ModalDeleteLicense from "app/components/ModalDeleteLicense";
import ModalEditLicense from "app/components/ModalEditLicense";
import MonneyButton from "app/assets/icons/MoneyButton";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Licenses } from "app/models";
import { useHistory } from "react-router-dom";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { editLicense } from "app/redux/actions/licenseAndContracts";
import BackgroundDrop from "../BackgroundDrop";
import { BsThreeDots } from "react-icons/bs";

interface LicensesProps {
  licenses: Licenses;
  input?: boolean;
}

export default function LicensesContractItem({
  licenses,
  input,
}: LicensesProps) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation();
  const {
    handleStartEditLicense,
    handleChangeActiveLicense,
    handleChangeHighlightLicense,
  } = useLicenseContracts();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const history = useHistory();
  const dispatch = useDispatch();

  const { register, handleSubmit } = useForm();
  const handleShowEdit = (type: string) => {
    if (type === "edit") {
      return setShowEdit((pre) => !pre);
    } else {
      return setShowDelete((pre) => !pre);
    }
  };

  const handleEditLicense = useCallback(
    (id) => {
      handleStartEditLicense(id);
      history.push({
        pathname: "/dashboard",
        state: { label: "licensesEdit" },
      });
    },
    [handleStartEditLicense, history]
  );

  const onSubmit = useCallback(
    (values) => {
      const license = {
        ...values,
        defaultTrackPrice: values.defaultTrackPrice
          ? Number(values.defaultTrackPrice)
          : null,
      };

      dispatch(editLicense(licenses._id, license));
    },
    [dispatch, licenses._id]
  );
  const [showPopove, setShowPopove] = useBoolean();

  return (
    <Flex
      className="licenses-contract-item"
      backgroundColor="#201F23"
      p={{ base: "25px 15px", md: "30px 25px" }}
      borderRadius="4px"
      alignItems="center"
      justifyContent="space-between"
      w="100%"
      mb="15px"
      border={licenses?.highlight ? "1px solid #D4F70E" : "none"}
    >
      <Box w={{ base: "25%", lg: "20%" }}>
        <Text fontSize="16px" fontWeight="600" lineHeight="16px" mb="5px">
          {licenses?.name}
        </Text>
        <Text fontSize="14px" lineHeight="16px" color="#4C4C4F">
          {t("mp3")}
          {licenses?.trackStems && `,${t("stems")}`}
          {licenses?.extraWav && `,${t("wav")}`}
        </Text>
      </Box>
      <Flex alignItems="center" w={{ base: "30%", lg: "15%" }}>
        <Switch
          defaultChecked={licenses?.active}
          colorScheme="Chartreuse"
          size={isLessThan766 ? "md" : "lg"}
          onChange={() => handleChangeActiveLicense(licenses._id)}
        />

        <Text
          fontSize={{ base: "14px", md: "16px" }}
          ml={{ base: "10px", md: "15px" }}
          color="#4C4C4F"
        >
          {t("enabled")}
        </Text>
      </Flex>
      <Flex alignItems="center" w={{ base: "30%", lg: "15%" }}>
        <Switch
          defaultChecked={licenses?.highlight}
          colorScheme="Chartreuse"
          size={isLessThan766 ? "md" : "lg"}
          onChange={() => handleChangeHighlightLicense(licenses._id)}
        />

        <Text
          fontSize={{ base: "14px", md: "16px" }}
          ml={{ base: "10px", md: "15px" }}
          color="#4C4C4F"
        >
          {t("highlight")}
        </Text>
      </Flex>
      <Box
        w="35%"
        display={{ base: "none", lg: "flex" }}
        justifyContent="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          {input ? (
            <InputGroup maxWidth="400px" className="input-license-item">
              <InputLeftAddon
                children={<MonneyButton />}
                backgroundColor="#28282C"
                border="none"
              />
              <Input
                {...register("defaultTrackPrice")}
                placeholder={Number(licenses?.defaultTrackPrice)?.toFixed(2)}
                border="none"
                backgroundColor="#25252A"
              />
              <InputRightElement
                border="none"
                backgroundColor="#25252A"
                cursor="pointer"
                marginRight="10px"
              >
                <Button
                  backgroundColor="#25252A"
                  _hover={{ backgroundColor: "#25252A" }}
                  _active={{ backgroundColor: "none", color: "#D3F70E" }}
                  fontSize="14px"
                  type="submit"
                >
                  {t("save")}
                </Button>
              </InputRightElement>
            </InputGroup>
          ) : (
            <Text />
          )}
        </form>
      </Box>

      <Box w="15%" display="flex" justifyContent="flex-end">
        {showPopove && <BackgroundDrop />}
        <Popover
          isOpen={showPopove}
          closeOnBlur={true}
          onOpen={setShowPopove.on}
          onClose={setShowPopove.off}
        >
          <PopoverTrigger>
            <Box
              padding="3px 5px"
              cursor="pointer"
              _hover={{
                background: "#24252A",
                padding: "3px 5px",
                border: "1px solid #38383D",
                borderRadius: "8px",
              }}
              borderWidth="1px"
              borderColor="#201F23"
            >
              <BsThreeDots size={24} />
            </Box>
          </PopoverTrigger>
          <Box zIndex={120}>
            <PopoverContent
              backgroundColor="#25252A"
              border="none"
              mt="45px"
              w="100%"
              _focus={{
                boxShadow: "none",
              }}
            >
              <PopoverArrow
                backgroundColor="#25252A "
                borderColor="#25252A "
                boxShadow="unset !important"
              />
              <PopoverHeader
                justifyContent="center"
                borderBottom="none"
                display="flex"
              >
                {t("modifying")}
              </PopoverHeader>
              <PopoverBody>
                <Flex flexDirection="column">
                  <Button
                    _hover={{
                      background: "#2D2E33",
                      border: "1px solid #37383C",
                    }}
                    _active={{
                      background: "#2D2E33",
                    }}
                    borderColor="#37383C"
                    color="white"
                    bg="#25252A"
                    mb="10px"
                    onClick={() => handleEditLicense(licenses._id)}
                  >
                    {t("Edit")}
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Popover>
      </Box>
      {showEdit && (
        <ModalEditLicense isOpen={showEdit} handleClick={handleShowEdit} />
      )}
      {showDelete && (
        <ModalDeleteLicense
          isOpen={showDelete}
          handleClick={handleShowEdit}
          licenseId={licenses._id}
        />
      )}
    </Flex>
  );
}
