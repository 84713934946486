import { removeLocalStorage } from "helpers/local-storage";
import { getJwtLocalStorage } from "helpers/local-storage";
import axios from "axios";
import { getJwtSessionStorage } from "helpers/session";
const client = axios.create({
  baseURL: `${process.env.REACT_APP_URL_API}`,
  headers: {
    "Content-Type": "application/json",
  },
});

client.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error.response) {
      throw new Error("Error");
    }

    if (
      (error.response.data.statusCode === 401 &&
        error.response.data.error === "Unauthorized") ||
      error.response.data.statusCode === 403
    ) {
      if (window.location.pathname !== "/auth/login") {
        window.location.href = "/auth/login";
        removeLocalStorage("jwt");
      }
    }

    return Promise.reject(error);
  }
);

client.interceptors.request.use(
  async (config: any) => {
    const token = getJwtLocalStorage() || getJwtSessionStorage();
    config.headers.authorization = `Bearer ${token}`;
    config.params = { ...config.params, timestamp: Date.now() };
    return config;
  },
  (error) => Promise.reject(error)
);

export default client;
