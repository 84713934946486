import { Box, Button, Checkbox, Flex, Image, Text } from "@chakra-ui/react";
import House from "app/assets/icons/house";
import IconPurchaseDetail from "app/assets/icons/iconPurchaseDetail";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import CartSummary from "app/components/PaymentMethods/CartSummary";
import {
  SelectBulkOffersModel,
  SubscriptionPlan,
  TrackSoundKit,
} from "app/models";
import { purchaseCart, removeFromCart } from "app/redux/actions/cart";
import {
  bulkOffersSelector,
  cartLicensesSelector,
  cartPromoCodeSelector,
} from "app/redux/selectors/cart";
import { isLoadingSelector } from "app/redux/selectors/status";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "types";
import { getPriceTrackCartItem, numberTotalCart } from "utils/getPrice";
import { promoCodeApplyCheck } from "utils/sumTotal";
import BulkOffersCart from "./bulkoffers";
import "./styles.scss";
import { typeMusic } from "utils/actionText";
import { useCart } from "hooks/cart/useCart";
import StripeCheckoutIcon from "app/assets/images/StripeCheckoutIcon.svg";
import { TRACK_TYPE } from "constants/trackTypes";
import PaypalIconCheckout from "app/assets/icons/PaypalIconCheckout";
import { BeatLoader } from "react-spinners";
interface BasketItemProps {
  data?: TrackSoundKit[] | SubscriptionPlan[];
  onOpenOptionsModal: (track: TrackSoundKit | SubscriptionPlan) => void;
  handleCheckbox: (id: string) => void;
  valueCheckbox: any;
  handleSelectAll: (event: any) => void;
  isConfirm?: boolean;
  onClick?: () => void;
  nameStripe?: string;
  setValueCheckBox: (value: string[]) => void;
  setShowModalPromoCode: () => void;
}

export default function BasketItem({
  data,
  valueCheckbox,
  onOpenOptionsModal,
  handleSelectAll,
  handleCheckbox,
  isConfirm = false,
  onClick,
  nameStripe,
  setValueCheckBox,
  setShowModalPromoCode,
}: BasketItemProps) {
  const { t } = useTranslation();
  const location: any = useLocation();
  const checkParams = useMemo(() => {
    return location?.pathname?.split("/");
  }, [location?.pathname]);
  const confirmPage = checkParams[checkParams?.length - 1] === "confirm";

  const cartLicenses = useSelector(cartLicensesSelector);
  const { isStripeSelect, isPaypalSelect } = useCart();
  const bulkOffers = useSelector(bulkOffersSelector);
  const promoCode = useSelector(cartPromoCodeSelector);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([purchaseCart.typePrefix], state)
  );
  const dispatch = useDispatch();
  const handleCurrentLicense = useCallback(
    (id) => cartLicenses?.find((license: any) => license?.trackId === id),
    [cartLicenses]
  );

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart(valueCheckbox));
    setValueCheckBox([]);
  }, [dispatch, setValueCheckBox, valueCheckbox]);

  const applyBulkOffer = useCallback(
    (id) => {
      return bulkOffers.some((bulk) => {
        return (
          bulk.hasApplyBulkOffer &&
          bulk.usingLicenses.some((license) => license.trackId === id)
        );
      });
    },
    [bulkOffers]
  );

  const applyPromoCode = useCallback(
    (id, createdById) => {
      return cartLicenses.some((currentLicense) =>
        promoCodeApplyCheck(currentLicense, promoCode, id, createdById)
      );
    },
    [cartLicenses, promoCode]
  );

  const applyPromoCodeSoundPacks = useCallback(
    (createdById) => {
      return (
        promoCode?.createdBy === createdById &&
        promoCode?.productType === typeMusic.SOUND_KIT
      );
    },
    [promoCode?.createdBy, promoCode?.productType]
  );

  return (
    <>
      <Flex
        gridGap="20px"
        className="basket-item"
        justifyContent="space-between"
        display={{ base: "block", md: "flex" }}
      >
        <Box w={{ base: "100%", md: "65%", xl: "75%" }} overflow="scroll">
          {(data || [])?.map((item, index: number) => {
            const currentLicense = handleCurrentLicense(item?._id);
            const currentItem = item as TrackSoundKit;
            const checkPromoCode = !!currentLicense
              ? applyPromoCode(currentItem?._id, currentItem?.createdBy?._id)
              : applyPromoCodeSoundPacks(currentItem?.createdBy?._id);

            const linkTrackSoundPackDetail = !!currentLicense
              ? `/track/${currentItem?.slug}`
              : `/soundkit/${currentItem?.slug}`;

            return (
              <Flex
                alignItems="center"
                key={item?._id}
                borderBottom="1px solid #26262A"
                paddingBottom="15px"
                marginBottom="20px"
                minWidth="900px"
              >
                <Flex
                  w={{ base: "auto", xl: "20%" }}
                  alignItems="center"
                  position="sticky"
                  left={0}
                  zIndex={1}
                  backdropFilter="blur(20px)"
                  mr={{ base: "10px", xl: "0px" }}
                >
                  {!confirmPage && (
                    <Checkbox
                      _focusVisible={{ outline: "none" }}
                      size="lg"
                      onChange={() => handleCheckbox(currentItem?._id)}
                      isChecked={
                        !!valueCheckbox.find(
                          (value: string) => value === currentItem?._id
                        )
                      }
                    />
                  )}
                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    ml={{ base: "10px", xl: "40px" }}
                    mr={{ base: "5px", xl: "10px" }}
                  >
                    {index + 1}
                  </Text>
                  <Flex position="relative">
                    <Image
                      ml="10px"
                      src={currentItem?.coverUrl}
                      fallbackSrc={ImageDefault}
                      w="72px"
                      height="72px"
                      borderRadius="4px"
                    />
                    <Box
                      background={
                        applyBulkOffer(currentItem?._id) ? "#282828" : "#D8FD01"
                      }
                      padding="3px 10px"
                      position="absolute"
                      top="50%"
                      left="56%"
                      transform="translate(-50%,-50%)"
                      borderRadius="4px"
                    >
                      <Text
                        color={
                          applyBulkOffer(currentItem?._id) ? "#949497" : "black"
                        }
                        fontSize="12px"
                        lineHeight="14px"
                        fontWeight="600"
                        textDecoration={
                          applyBulkOffer(currentItem?._id)
                            ? "line-through"
                            : "none"
                        }
                      >
                        $
                        {numberTotalCart(
                          Number(
                            !!currentLicense
                              ? getPriceTrackCartItem(
                                  handleCurrentLicense(currentItem?._id)
                                )
                              : currentItem?.basicPrice
                          )
                        )}
                      </Text>
                    </Box>
                  </Flex>
                </Flex>
                <Box w="30%">
                  <Link to={linkTrackSoundPackDetail}>
                    <Text
                      cursor="pointer"
                      _hover={{
                        textDecor: "underline",
                      }}
                      fontSize="16px"
                      noOfLines={2}
                    >
                      {currentItem?.title}
                    </Text>
                  </Link>

                  <Flex>
                    <Box display="flex" alignItems="center">
                      <Text
                        fontSize="12px"
                        lineHeight="16px"
                        color="#d4f70e"
                        padding="0px 5px"
                        borderRadius="4px"
                        mr="10px"
                        backgroundColor="#2b2e1b"
                      >
                        {t(TRACK_TYPE[item?.type])}
                      </Text>
                    </Box>
                    <Link to={`/user/${currentItem?.createdBy?.name}`}>
                      <Text
                        _hover={{
                          textDecor: "underline",
                        }}
                        cursor="pointer"
                        fontSize="14px"
                        color="#464649"
                      >
                        {currentItem?.createdBy?.name}
                      </Text>
                    </Link>
                  </Flex>
                </Box>
                <Box w="29%">
                  {!!currentLicense && (
                    <Flex justifyContent="center" flexWrap="wrap">
                      <Box
                        display="flex"
                        alignItems="center"
                        height="20px"
                        borderRadius="4px"
                        padding="0px 5px"
                        mr="5px"
                        mb="5px"
                        backgroundColor="#2b2e1b"
                      >
                        <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                          {currentLicense?.license?.name} {t("license")}
                        </Text>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        height="20px"
                        borderRadius="4px"
                        padding="0px 5px"
                        mr="5px"
                        mb="5px"
                        backgroundColor="#2b2e1b"
                      >
                        <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                          {currentLicense?.license?.exclusive
                            ? t("exclusiveContract")
                            : t("nonExclusiveContract")}
                        </Text>
                      </Box>
                    </Flex>
                  )}

                  {applyBulkOffer(currentItem?._id) ? (
                    <Flex alignItems="center" justifyContent="center" mt="5px">
                      <Flex alignItems="center" mr="40px">
                        <House />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                        >
                          {t("bulkDealApplied")}
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <Text
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                          color="#949497"
                          textDecoration="line-through"
                          mr="10px"
                        >
                          $
                          {!!currentLicense
                            ? getPriceTrackCartItem(
                                handleCurrentLicense(currentItem?._id)
                              )
                            : currentItem?.basicPrice}
                        </Text>
                        <Text
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                          color="#D4F70E"
                        >
                          -$
                          {!!currentLicense
                            ? getPriceTrackCartItem(
                                handleCurrentLicense(currentItem?._id)
                              )
                            : currentItem?.basicPrice}
                        </Text>
                      </Flex>
                    </Flex>
                  ) : checkPromoCode ? (
                    <Flex alignItems="center" justifyContent="center" mt="5px">
                      <Flex alignItems="center" mr="40px">
                        <House />
                        <Text
                          ml="10px"
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                        >
                          {t("promoCodeDiscount")}
                        </Text>
                      </Flex>

                      <Flex alignItems="center">
                        <Text
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                          color="#949497"
                          textDecoration="line-through"
                          mr="10px"
                        >
                          $
                          {!!currentLicense
                            ? getPriceTrackCartItem(
                                handleCurrentLicense(currentItem?._id)
                              )
                            : currentItem?.basicPrice}
                        </Text>
                        <Text
                          fontSize="12px"
                          lineHeight="14px"
                          fontWeight="500"
                          color="#D4F70E"
                        >
                          -$
                          {(
                            (Number(promoCode?.percent || 0) *
                              (!!currentLicense
                                ? getPriceTrackCartItem(
                                    handleCurrentLicense(currentItem?._id)
                                  )
                                : currentItem?.basicPrice)) /
                            100
                          ).toFixed(2)}
                        </Text>
                      </Flex>
                    </Flex>
                  ) : null}
                </Box>
                <Box
                  w="21%"
                  display="flex"
                  justifyContent="end"
                  onClick={() => onOpenOptionsModal(item)}
                  cursor="pointer"
                >
                  <Box
                    w="90%"
                    backgroundColor=" #212125"
                    borderRadius="4px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="12px 10px"
                  >
                    <IconPurchaseDetail />
                    <Text fontSize="16px" ml="10px">
                      {t("purchaseDetail")}
                    </Text>
                  </Box>
                </Box>
              </Flex>
            );
          })}
        </Box>

        <Box w={{ base: "100%", md: "35%", xl: "25%" }}>
          <CartSummary
            setShowModalPromoCode={setShowModalPromoCode}
            nameStripe={nameStripe}
          />
          <Box mt="35px">
            {bulkOffers &&
              (bulkOffers || []).map((item: SelectBulkOffersModel) => (
                <BulkOffersCart bulkoffers={item} key={item._id} />
              ))}
          </Box>

          <Button
            backgroundColor={
              isConfirm && isPaypalSelect
                ? "#FFC43A"
                : isConfirm
                ? "#34391A"
                : "#D4F70E"
            }
            color={
              isConfirm && isPaypalSelect
                ? "#000000"
                : isConfirm
                ? "#D4F70E"
                : "#000000"
            }
            border="1px solid #D4F70E"
            fontSize="14px"
            _hover={{ backgroundColor: "none" }}
            p="22px 60px"
            borderRadius="4px"
            w="100%"
            marginTop="20px"
            onClick={onClick}
            isLoading={isConfirm && isLoading}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {isConfirm && isPaypalSelect ? (
              <PaypalIconCheckout />
            ) : isConfirm ? (
              t("placeYourOrder")
            ) : (
              t("checkOut")
            )}
          </Button>

          <Flex justifyContent="center" mt="20px">
            {isStripeSelect && isConfirm && (
              <Image fallbackSrc={StripeCheckoutIcon} />
            )}
            {isPaypalSelect && isConfirm && (
              <Text fontSize="14px" fontWeight="400">
                {t("theSaferEasierWayToPay")}
              </Text>
            )}
          </Flex>
        </Box>
      </Flex>
      {(data || []).length > 1 && !confirmPage && (
        <Box className="basket-item">
          <Checkbox
            onChange={(e) => handleSelectAll(e.target.checked)}
            _focusVisible={{ outline: "none" }}
            size="lg"
            mt="20px"
          >
            <Text ml="30px" color="#D8FD01">
              {t("selectAll")}
            </Text>
          </Checkbox>
        </Box>
      )}
      {!confirmPage && valueCheckbox?.length > 0 && (
        <Button
          mt="100px"
          backgroundColor="#24252A"
          border="1px solid #D8FD01"
          _hover={{ backgroundColor: "none" }}
          borderRadius="4px"
          _active={{ backgroundColor: "#D8FD01" }}
          onClick={handleRemoveToCartButton}
        >
          {t("removeFromCart")}
        </Button>
      )}
    </>
  );
}
