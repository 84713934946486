import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import { useMyTrack } from "hooks/track/useMyTrack";
import { useToggle } from "hooks/useToggle";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { heightLoadMore, searchData } from "utils/convertTracks";
import trackSharePrivate from "../../assets/LottieIAnimations/trackSharePrivate.json";
import EmptyTrackSoundkit from "../MySoundkits/emptyTrackSoundkit";
import ModalTransactionProcess from "../SuccessModal/modalTransactionProcess";
import ModalLinkExpiration from "./modalSetLinkExpiration";
import "./styles.scss";
import MusicCardMyTrack from "../MusicCard/MusicCardMyTrack";
import MusicCardMyTrackMobile from "../MusicCard/MusicCardMyTrackMobile";

interface MyPrivateTrackProps {
  searchTextItem: string;
  handleCheckbox?: (id: String) => void;
  selectCheckbox?: string[];
}

const columnHeader = [
  { label: t("trackNo") },
  { label: t("trackName") },
  { label: t("licenseAndContract") },
  { label: t("dateReleased") },
  { label: t("fileDelivery") },
  { label: t("actions") },
];

export default function MyPrivateTrack({
  searchTextItem,
  selectCheckbox,
  handleCheckbox,
}: MyPrivateTrackProps) {
  const {
    privateTrack,
    onGetPrivateTracks,
    isLoading,
    showModalLinkExpiration,
    setShowModalLinkExpiration,
    handleShowModalLinkExpires,
    selectedTrack,
  } = useMyTrack();
  const { t } = useTranslation();
  const [selectItem, setSelectIem] = useState(-1);
  const history = useHistory();
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");
  const [showModalSuccess, setShowModalSuccess] = useToggle();

  useEffect(() => {
    onGetPrivateTracks({ page: 1 });
  }, [onGetPrivateTracks]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(privateTrack, e)) {
        onGetPrivateTracks({ page: 1 + privateTrack?.page, loadingMore: true });
      }
    },
    [onGetPrivateTracks, privateTrack]
  );

  const filterPrivateTrack = searchData(privateTrack?.items, searchTextItem);

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const onBackToFeed = useCallback(() => {
    history.push("/home");
  }, [history]);

  return (
    <Box mt="20px">
      {filterPrivateTrack?.length === 0 && !isLoading ? (
        <EmptyTrackSoundkit />
      ) : isLessThan766 ? (
        <Box>
          <Flex>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="20%">
              {t("trackNo")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="65%">
              {t("trackName")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="15%">
              {t("actions")}
            </Text>
          </Flex>
          {(filterPrivateTrack || []).map(
            (item: TrackSoundKit, index: number) => (
              <MusicCardMyTrackMobile
                item={item}
                selectCheckbox={selectCheckbox}
                handleCheckbox={handleCheckbox}
                key={index}
                index={index}
                isMyTrackRelease={true}
              />
            )
          )}
        </Box>
      ) : (
        <>
          <TableContainer>
            <Table fontFamily="Cera Pro Regular">
              <Thead>
                <Tr>
                  {!isLoading &&
                    columnHeader.map((i, k) => (
                      <Th
                        key={k}
                        fontSize="14px"
                        color="#8B8B8D"
                        textTransform="capitalize"
                        borderColor="transparent"
                        position={k === 0 ? "sticky" : "static"}
                        left={k === 0 ? "0px" : ""}
                        zIndex={k === 0 ? "1" : ""}
                        backdropFilter={k === 0 ? "blur(20px)" : " "}
                      >
                        {i.label}
                      </Th>
                    ))}
                </Tr>
              </Thead>
              <Tbody>
                {(isLoading ? [] : filterPrivateTrack || []).map(
                  (item: TrackSoundKit, index: number) => (
                    <MusicCardMyTrack
                      isMyTrack
                      key={index}
                      item={item}
                      index={index}
                      selectCheckbox={selectCheckbox}
                      handleCheckbox={handleCheckbox}
                      setShowModalLinkExpiration={handleShowModalLinkExpires}
                      selectItem={selectItem}
                      setSelectIem={setSelectIem}
                    />
                  )
                )}
              </Tbody>
            </Table>
          </TableContainer>

          {showModalLinkExpiration && (
            <ModalLinkExpiration
              setShowModal={setShowModalLinkExpiration}
              showModal={showModalLinkExpiration}
              selectedTrack={selectedTrack}
              setShowModalSuccess={setShowModalSuccess}
            />
          )}
          {showModalSuccess && (
            <ModalTransactionProcess
              isOpen={showModalSuccess}
              iconJson={trackSharePrivate}
              onClose={setShowModalSuccess}
              onButtonClick={onBackToFeed}
              buttonLabel="backToFeed"
            />
          )}
        </>
      )}
      <Box display="flex" justifyContent="center">
        <FadeLoader color={"#d4f70e"} loading={privateTrack?.loadingMore} />
      </Box>
      {!privateTrack?.hasMore &&
        !isLoading &&
        privateTrack?.items?.length > 25 && (
          <Text fontSize="14px" textAlign="center" color="#464649">
            {t("upToDate")}
          </Text>
        )}
    </Box>
  );
}
