import { Box, Button, Divider, Flex, Text } from "@chakra-ui/react";
import { Negotiations } from "app/models";
import { STATUS_COLOR } from "constants/negotiationType";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import React, { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ShowContentProps } from "../modalNegotiations";

interface SubmitNegotiationOfferProps {
  negotiationsItem: Negotiations;
  setShowModal: () => void;
  setShowContent: (data: ShowContentProps) => void;
}

export default function SubmitNegotiationOffer({
  negotiationsItem,
  setShowModal,
  setShowContent,
}: SubmitNegotiationOfferProps) {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const { onCounterofferNegotiation } = useNegotiations();

  const CounterOffer = watch("counterofferPrice");

  const dataOffer = useMemo(
    () => [
      {
        name: t("yourOffer"),
        detail: `$ ${CounterOffer}`,
      },
      {
        name: t("offerExpiresIn"),
        detail: "1 Day",
      },
      {
        name: t("quantity"),
        detail: negotiationsItem?.tracks?.length,
      },
      {
        name: t("total"),
        detail: `$ ${CounterOffer}`,
      },
    ],
    [CounterOffer, negotiationsItem?.tracks?.length, t]
  );

  const handleSubmitOffer = useCallback(() => {
    onCounterofferNegotiation({
      negotiationId: negotiationsItem?._id,
      status: STATUS_COLOR.COUNTEROFFER,
      price: Number(CounterOffer),
    });
    setShowModal();
  }, [
    CounterOffer,
    negotiationsItem?._id,
    onCounterofferNegotiation,
    setShowModal,
  ]);

  const handleEditCounteroffer = useCallback(() => {
    setShowContent({
      showContentNegotiationDeal: false,
      showNegotiationOffer: true,
      showSubmitNegotiation: false,
    });
  }, [setShowContent]);

  return (
    <Box>
      {dataOffer.map((item, index) => (
        <>
          <Flex
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            w="343px"
            padding="20px 0px"
            key={index}
          >
            <Text fontSize="24px" lineHeight="16px" fontWeight="700">
              {item.name}
            </Text>
            <Box
              height="32px"
              padding="0px 15px"
              background="#38383D"
              borderRadius="34px"
              display="flex"
              alignItems="center"
            >
              <Text fontSize="18px" lineHeight="21px" fontWeight="600">
                {item.detail}
              </Text>
            </Box>
          </Flex>
          <Divider mt="20px" borderColor="rgba(255, 255, 255, 0.06)" />
        </>
      ))}
      <Button
        background="#34391A"
        color="#D4F70E"
        border="1px solid #D4F70E"
        _hover={{
          background: "#34391A",
        }}
        w="100%"
        size="lg"
        _active={{ background: "#2A2B20", color: "black" }}
        _focus={{ boxShadow: "none" }}
        type="submit"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        margin="30px auto 0px"
        onClick={handleSubmitOffer}
      >
        {t("submitOffer")}
      </Button>
      <Button
        background="#34391A"
        color="#D4F70E"
        border="1px solid #D4F70E"
        _hover={{
          background: "#34391A",
        }}
        w="100%"
        size="lg"
        _active={{ background: "#2A2B20", color: "black" }}
        _focus={{ boxShadow: "none" }}
        type="submit"
        display="flex"
        justifyContent="center"
        alignItems={"center"}
        margin="10px auto"
        onClick={handleEditCounteroffer}
      >
        {t("editOffer")}
      </Button>
    </Box>
  );
}
