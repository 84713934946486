import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import TabsMenuHeader from "app/components/AddTrack/TabHeader";
import { Licenses } from "app/models";
import { NewBulkOfferState } from "app/redux/reducers/bulkOffer";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import DiscountInput from "../DiscountInput";
import ApplyLicenses from "./ApplyLicenses";
import "./styles.scss";

interface ICreateBulkOfferProps {
  licenses?: Licenses[];
  onAddBulkOffer: (values: NewBulkOfferState) => void;
  isLoading: boolean;
  idEdit: boolean;
  tabsMenu: any;
}

export default function CreateBulkOffer({
  licenses,
  tabsMenu = [],
  isLoading,
  idEdit,
  onAddBulkOffer,
}: ICreateBulkOfferProps) {
  const history = useHistory();
  const { t } = useTranslation();
  const { handleSubmit, watch } = useFormContext();

  const { minimumTracks, freeTracks } = watch();

  return (
    <form onSubmit={handleSubmit(onAddBulkOffer)}>
      <Box position="relative" className="create-bulkoffer">
        <Flex
          justifyContent="space-between"
          display={{ base: "none", md: "flex" }}
        >
          <TabsMenuHeader tabsMenu={tabsMenu} />
          <Box>
            <Button
              height="50px"
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              type="submit"
              isLoading={isLoading}
              spinner={<BeatLoader size={8} color="white" />}
            >
              {!idEdit ? t("Create") : t("Edit")}
            </Button>
            <Button
              height="50px"
              ml="8px"
              bg="rgba(255, 255, 255, 0.04)"
              onClick={() => history.push("/dashboard/discounts")}
            >
              {t("Cancle")}
            </Button>
          </Box>
        </Flex>
        <Flex
          w="max-content"
          mt="30px"
          alignItems="center"
          mb="32px"
          ml={{ base: "0px", md: "10px" }}
          gap="20px"
          cursor="pointer"
          onClick={() => {
            history.push({
              pathname: "/dashboard/discounts",
            });
          }}
        >
          <Flex
            border="1px solid rgba(255, 255, 255, 0.04)"
            w="50px"
            h="50px"
            alignItems="center"
            justifyContent="center"
            borderRadius="full"
          >
            <Back />
          </Flex>
          <Text fontSize="16px" lineHeight="20px" fontWeight="500">
            {t("Back")}
          </Text>
        </Flex>

        <Text
          fontWeight="500"
          fontSize={{ base: "24px", md: "26px" }}
          lineHeight="30px"
          w={{ base: "100%", md: "79%" }}
          pb="30px"
          borderBottom="1px solid rgba(255, 255, 255, 0.04)"
        >
          {!idEdit ? t("NewBulkOffer") : t("EditBulkOffer")}
        </Text>
        <Flex mt="20px" w={{ base: "100%", md: "80%" }}>
          <Box w="90%" className="track-title">
            <Text pb="10px">
              {t("NameBulkOffer")}:{" "}
              {`${t("by")} ${minimumTracks || 0} ${t("get")} ${
                freeTracks || 0
              } ${t("free")}.`}
            </Text>
          </Box>
          <Box
            w="90%"
            className="track-title"
            display={{ base: "none", md: "block" }}
          ></Box>
        </Flex>
        <Flex
          display={{ base: "block", md: "flex" }}
          mt="20px"
          w={{ base: "100%", md: "80%" }}
        >
          <DiscountInput
            title={t("Minimum")}
            name={"minimumTracks"}
            type="number"
          />
          <DiscountInput
            title={t("willBeForFree")}
            name={"freeTracks"}
            type="number"
          />
        </Flex>
        {/* Unuse now */}
        {/* <Flex className="switch" pt="24px" alignItems="center">
          <Switch colorScheme="#D8FD01" size="lg" mr="25px" />
          <Text fontSize="14px" lineHeight="18px">
            {t("bulkDiscountOption")}
          </Text>
        </Flex> */}
        <Text
          pt="40px"
          fontSize="24px"
          lineHeight="30px"
          fontWeight="500"
          pb="10px"
          borderBottom="1px solid rgba(255, 255, 255, 0.04)"
          mb="30px"
        >
          {t("bulkDiscount")}
        </Text>
        <ApplyLicenses licenses={licenses} />
      </Box>

      <Flex
        justifyContent="center"
        gap="5px"
        display={{ base: "flex", md: "none" }}
      >
        <Button
          height="50px"
          background="#34391A"
          color="#D4F70E"
          border="1px solid #D4F70E"
          _hover={{
            background: "#34391A",
          }}
          type="submit"
          isLoading={isLoading}
          spinner={<BeatLoader size={8} color="white" />}
          w="40%"
        >
          {!idEdit ? t("Create") : t("Edit")}
        </Button>
        <Button
          height="50px"
          ml="8px"
          bg="rgba(255, 255, 255, 0.04)"
          onClick={() => history.push("/dashboard/discounts")}
          w="40%"
        >
          {t("Cancle")}
        </Button>
      </Flex>
    </form>
  );
}
