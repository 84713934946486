import React from "react";

import IconErrorNotification from "app/assets/icons/IconNotificationError";
import IconNegotiationNotification from "app/assets/icons/IconNotificationNegotiation";
import IconCartSaleNotification from "app/assets/icons/IconNotificationCartSale";
import IconNewFollowersNotification from "app/assets/icons/IconNotificationNewFollowers";
import IconPurchasedNotification from "app/assets/icons/IconNotificationPurchase";
import { ItemNotifications } from "app/models";
import Tick from "app/assets/icons/tick";
import { Message } from "app/assets/icons";
import { Link } from "react-router-dom";
import { Image, Text } from "@chakra-ui/react";
import IconHeartFeedPage from "app/assets/icons/IconHeartFeedPageLike";
import IconRepeatFeed from "app/assets/icons/IconRepeatFeedPage";
import IconCollaboration from "app/assets/images/Collabo.png";

const handleShowDescription = (
  name?: string,
  description?: string,
  isCompleted?: boolean
) => {
  return (
    <Text>
      {isCompleted && <span>Your negotiation with </span>}
      <span>
        <Link onClick={(e) => e.stopPropagation()} to={`/user/${name}`}>
          {name}
        </Link>
      </span>{" "}
      {description}
    </Text>
  );
};

const handleShowDescriptionSaleOrPurchase = (
  name?: string,
  description?: string
) => {
  return (
    <Text>
      Congrats,{" "}
      <span>
        <Link onClick={(e) => e.stopPropagation()} to={`/user/${name}`}>
          {name}
        </Link>
      </span>
      ! {description}
    </Text>
  );
};

const getName = (notification: any) => {
  const userId = notification?.userId?._id;
  const createdById = notification?.negotiation?.createdBy?._id;

  if (userId !== createdById) {
    return notification?.negotiation?.createdBy?.name;
  }
  if (notification.negotiation.tracks.length > 0) {
    return notification?.negotiation?.tracks[0]?.createdBy?.name;
  }
  return notification?.negotiation?.soundkits[0]?.createdBy?.name;
};

const handleShowDescriptionNegotiation = (
  notification?: any,
  description?: string,
  isAccepted?: boolean
) => {
  const name = getName(notification);

  return (
    <Text>
      {isAccepted && <span>Congrats! </span>}
      <span>
        <Link onClick={(e) => e.stopPropagation()} to={`/user/${name}`}>
          {name}
        </Link>
      </span>{" "}
      {description}
    </Text>
  );
};

export const renderTitle = (type: string, notification: ItemNotifications) => {
  switch (type) {
    case "NEW_NEGOTIATION":
      return handleShowDescription(
        notification?.negotiation?.createdBy?.name,
        " wants to start a negotiation with you"
      );
    case "CANCEL_NEGOTIATION":
      return handleShowDescriptionNegotiation(
        notification,
        `has rejected your $${notification?.negotiation?.offerPrice} negotiation offer`
      );
    case "EXPIRED":
      return `Negotiation offer from ${getName(notification)} has  expired`;
    case "COUNTER_NEGOTIATION":
      return handleShowDescriptionNegotiation(
        notification,
        "has sent you a negotiation counteroffer"
      );
    case "ACCEPT_COUNTER_NEGOTIATION":
    case "ACCEPT_NEGOTIATION":
      return handleShowDescriptionNegotiation(
        notification,
        "has accepted your negotiation offer",
        true
      );
    case "NEGOTIATION_COMPLETE":
      return handleShowDescription(
        notification?.negotiation?.createdBy?.name,
        "has been completed",
        true
      );
    case "NEW_SALE":
      return handleShowDescriptionSaleOrPurchase(
        notification?.userId?.name,
        "You made a sale from Superproductor Markeplace"
      );
    case "NEW_PURCHASE":
      return handleShowDescriptionSaleOrPurchase(
        notification?.userId?.name,
        "You made a purchase from Superproductor Markeplace"
      );
    case "NEW_COLLABORATIONS":
      return handleShowDescription(
        notification?.createdBy?.name,
        "wants to start a collaboration with you"
      );
    case "UPDATE_COLLABORATION":
      return handleShowDescription(
        notification?.createdBy?.name,
        "wants to update collaboration with you"
      );
    case "ACCEPT_COLLABORATIONS":
      return handleShowDescription(
        notification?.createdBy?.name,
        "has accepted collaboration with you"
      );
    case "REJECT_COLLABORATIONS":
      return handleShowDescription(
        notification?.createdBy?.name,
        "has declined collaboration with you"
      );
    case "NEW_SOUNDKIT":
      return "Success! Your file has been uploaded.";
    case "NEW_TRACK":
      return "Success! Your file has been uploaded.";
    case "NEW_DRAFT_TRACK":
      return "Success! Your track draft has been saved.";
    case "NEW_TRACK_WITH_COLLABORATIONS":
      return "Success! Your file has been uploaded.But you have to wait for the collaborator to agree to show it";
    case "NEW_MESSAGE":
      return handleShowDescription(
        notification?.createdBy?.name,
        " has sent you a message"
      );
    case "NEW_FOLLOWER":
      return handleShowDescription(
        notification?.followerId?.name,
        "is now following your profile"
      );
    case "LIKE_TRACK":
      return handleShowDescription(
        notification?.createdBy?.name,
        "like your track"
      );
    case "REPOSTS_TRACK":
      return handleShowDescription(
        notification?.createdBy?.name,
        "repost your track"
      );
    case "EXPIRING_PURCHASED":
      return "Some of your tracks are expiring soon";
    default:
      break;
  }
};

export const renderIcon = (type: string) => {
  switch (type) {
    case "ACCEPT_NEGOTIATION":
    case "NEW_NEGOTIATION":
    case "COUNTER_NEGOTIATION":
    case "ACCEPT_COUNTER_NEGOTIATION":
    case "NEGOTIATION_COMPLETE":
    case "CANCEL_NEGOTIATION":
    case "EXPIRED":
      return <IconNegotiationNotification />;
    case "NEW_TRACK":
    case "NEW_DRAFT_TRACK":
    case "NEW_SOUNDKIT":
    case "NEW_DRAFT_SOUNDKIT":
      return <Tick />;
    case "NEW_MESSAGE":
      return <Message />;
    case "NEW_PURCHASE":
      return <IconPurchasedNotification />;
    case "NEW_SALE":
      return <IconCartSaleNotification />;
    case "NEW_FOLLOWER":
      return <IconNewFollowersNotification />;
    case "LIKE_TRACK":
      return <IconHeartFeedPage />;
    case "REPOSTS_TRACK":
      return <IconRepeatFeed />;
    case "NEW_COLLABORATIONS":
    case "ACCEPT_COLLABORATIONS":
    case "REJECT_COLLABORATIONS":
    case "UPDATE_COLLABORATION":
      return <Image w="20px" h="20px" src={IconCollaboration} />;
    default:
      return <IconErrorNotification />;
  }
};
