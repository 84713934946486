import React, { Fragment } from "react";
import { doc, updateDoc } from "firebase/firestore";

import { useParams } from "react-router-dom";
import {
  formatDate,
  splitLinkFromMessage,
} from "app/pages/ConversationsPage/config/utils";
import { db } from "app/pages/ConversationsPage/config/firebase";
import { EMOJI_REGEX } from "app/pages/ConversationsPage/config/constants";
import { Box } from "@chakra-ui/react";
import "./styles.scss";
import { MessageItem } from "app/models";

interface RightMessageProps {
  message: MessageItem;
}

const RightMessage = ({ message }: RightMessageProps) => {
  const { id: conversationId } = useParams();

  const removeMessage = (messageId: string) => {
    updateDoc(
      doc(db, "conversations", conversationId as string, "messages", messageId),
      {
        type: "removed",
        file: null,
        content: "",
        reactions: [],
      }
    );
  };

  const formattedDate = formatDate(
    message.createdAt?.seconds ? message.createdAt?.seconds * 1000 : Date.now()
  );

  return (
    <Box>
      <Box className="item-right-messages">
        {message.type === "text" ? (
          <>
            {EMOJI_REGEX.test(message.content) ? (
              <Box
                onClick={(e) => e.stopPropagation()}
                title={formattedDate}
                className="text-4xl"
              >
                {message.content}
              </Box>
            ) : (
              <Box
                onClick={(e) => e.stopPropagation()}
                title={formattedDate}
                padding="0px"
                margin="0px"
              >
                {splitLinkFromMessage(message.content).map((item, index) => (
                  <Fragment key={index}>
                    {typeof item === "string" ? (
                      <span>{item}</span>
                    ) : (
                      <a
                        className="mx-1 inline underline"
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {item.link}
                      </a>
                    )}
                  </Fragment>
                ))}
              </Box>
            )}
          </>
        ) : (
          <div
            onClick={(e) => e.stopPropagation()}
            title={formattedDate}
            className="border-dark-lighten rounded-lg border p-3 text-gray-400"
          >
            Message has been removed
          </div>
        )}
      </Box>
    </Box>
  );
};

export default RightMessage;
