import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import Tick from "app/assets/icons/tick";
import { FeaturePlans, SubscriptionPlan } from "app/models";
import { plansText, planType, textShowPlans } from "constants/planType";
import React from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";

interface ModalProps {
  isLoading?: boolean;
  isActivePlan?: boolean;
  isOpen: boolean;
  displayingPlan?: string;
  planDetail: SubscriptionPlan;
  onClose: () => void;
  onButtonClick?: () => void;
  features: FeaturePlans[];
}

export default function PlanDetailModal({
  isOpen = false,
  isLoading = false,
  isActivePlan = false,
  planDetail,
  displayingPlan = "",
  onClose,
  onButtonClick,
  features,
}: ModalProps) {
  const { name, price, plan: TypePlan, description } = planDetail || {};
  const { t } = useTranslation();

  const renderContentModal = (plan: FeaturePlans, isPlacement?: boolean) => {
    return (
      <Flex mt="20px" mb="20px" alignItems="center" key={plan.featureName}>
        {textShowPlans(plan) && (
          <Box
            w="22px"
            h="22px"
            lineHeight="20px"
            borderRadius="50%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            background="#222629"
            color="#D4F70E"
            mr="20px"
          >
            <Tick />
          </Box>
        )}
        <Flex alignItems="center" w="100%">
          <Text
            w="90%"
            fontSize="14px"
            lineHeight="18px"
            fontFamily="Cera Pro Regular"
          >
            {t(
              plansText(
                textShowPlans(plan),
                TypePlan === planType.ANNUAL ? true : false
              )?.name
            )}
          </Text>
          {plansText(textShowPlans(plan))?.icon && (
            <Tooltip
              label={t(plansText(textShowPlans(plan))?.label)}
              background="#333333"
              color="#D4F70E"
              padding="10px 15px"
              borderRadius="8px"
              placement={isPlacement ? "bottom-end" : "bottom"}
            >
              <InfoOutlineIcon cursor="pointer" color="#D4F70E" boxSize={3.5} />
            </Tooltip>
          )}
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        p={{ base: "10px", md: "20px 40px" }}
        margin={{ base: "0px 10px", md: "0px" }}
        maxW="700px"
        maxH={{ base: "600px", md: "700px" }}
        overflow="scroll"
      >
        <ModalHeader p="0px">
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Text
                fontSize="24px"
                lineHeight="24px"
                fontFamily="Cera Pro Bold"
              >
                {t(name)}
              </Text>
              <Text
                fontSize="20px"
                lineHeight="24px"
                color="#D4F70E"
                mt="8px"
                fontFamily="Cera Pro Medium"
              >
                {t(price)}
              </Text>
              {TypePlan === planType.ANNUAL && (
                <Text
                  fontSize="14px"
                  color="#D4F70E"
                  fontFamily="Cera Pro Regular"
                >
                  {t("thatJustMonth")}
                </Text>
              )}
            </Box>
          </Flex>
          <Text
            maxW="250px"
            fontSize="14px"
            lineHeight="22px"
            color="#595959"
            mt="28px"
            fontFamily="Cera Pro Regular"
          >
            {t(description)}
          </Text>
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={onClose}
          right={5}
          top="15px"
          fontSize="22px"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          position="absolute"
          color="#d4f70e"
        />
        <ModalBody
          p="0px"
          mt={{ base: "0px", md: "20px" }}
          maxH={{ base: "550px", md: "unset" }}
          overflow="scroll"
        >
          <Grid
            templateColumns="repeat(2, 1fr)"
            gap={6}
            display={{ base: "none", md: "grid" }}
          >
            <Box w="100%">
              {features
                ?.slice(0, TypePlan === planType.LIMITLESS ? 6 : 12)
                .map((plan: FeaturePlans) => {
                  return renderContentModal(plan);
                })}
            </Box>
            <Box w="100%">
              {features
                ?.slice(TypePlan === planType.LIMITLESS ? 6 : 12)
                .map((plan: FeaturePlans) => {
                  return renderContentModal(plan, true);
                })}
            </Box>
          </Grid>
          <Box display={{ base: "block", md: "none" }}>
            {(features || [])?.map((plan: FeaturePlans) => {
              return renderContentModal(plan);
            })}
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="left" p="0px" mt="30px">
          {TypePlan !== planType.LIMITLESS && onButtonClick && (
            <Button
              isLoading={isLoading}
              backgroundColor={!isActivePlan ? "#D3F70E" : "#ED3636"}
              color={!isActivePlan ? "#595959" : "#FFFFFF"}
              borderRadius="4px"
              p="15px 30px"
              _hover={{ boxShadow: "none" }}
              onClick={onButtonClick}
              spinner={<BeatLoader size={8} color="white" />}
            >
              {displayingPlan}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
