import {
  Box,
  Button,
  Checkbox,
  Flex,
  Image,
  Text,
  Divider,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import IconGoogle from "app/assets/icons/IconGoogle";
import GifSuperNegotiation from "app/assets/images/ezgif.com-optimize.gif";
import Logo from "app/assets/images/logo.svg";
import GifSuper from "app/assets/images/ezgif.com-video-to-gif.gif";
import AuthText from "app/components/AuthText";
import { InputItem } from "app/components/InputItem";
import SectionDivider from "app/components/SectionDivider";
import { LoginUserGoogle, clearStoreSuccess } from "app/redux/actions/users";
import dayjs from "dayjs";
import DeviceDetector from "device-detector-js";
import { useLoginForm } from "hooks/login/useLoginForm";
import Lottie from "lottie-react";
import React, { memo, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { BeatLoader } from "react-spinners";
import progressBarSuper from "../../assets/LottieIAnimations/progressBarSuper.json";
import ModalVerificationCode from "./modalVerificationCode";
import "./styles.scss";

export const Login = memo(() => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    errors,
    isLoadingLogin,
    onSubmit,
    setIsRememberMe,
    isRememberMe,
    isLoadingGuest,
    valueEmail,
    showModalVerificationCode,
    setShowModalVerificationCode,
  } = useLoginForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const yearSuper = dayjs().year();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);

  useEffect(() => {
    dispatch(clearStoreSuccess());
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 13000,
  };
  const responseGoogle = useGoogleLogin({
    flow: "auth-code",
    onSuccess: (credentialResponse) => {
      dispatch(
        LoginUserGoogle({
          idToken: credentialResponse?.code,
          device: device?.device?.type,
        })
      );
    },
  });

  const handleChangeRememberMe = useCallback(
    (e) => {
      setIsRememberMe(e.target.checked);
    },
    [setIsRememberMe]
  );

  const handleLoginAsGuest = useCallback(() => {
    history.push("/");
  }, [history]);

  return isLoadingLogin ? (
    device.client?.name === "Safari" ? (
      <Box w="100vw" h="80vh" position="relative">
        <Box
          w={{ base: "300%", md: "200%", lg: "100%" }}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          margin="auto"
        >
          <Lottie animationData={progressBarSuper} />
        </Box>
      </Box>
    ) : (
      <Box w="100vw" h="80vh" position="relative">
        <Box
          w={{ base: "300%", md: "200%", lg: "100%" }}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          margin="auto"
        >
          <Lottie animationData={progressBarSuper} />
        </Box>
      </Box>
    )
  ) : (
    <>
      <Flex w="100%" maxW={{ base: "100%", lg: "1440px" }} px={{ lg: "100px" }}>
        <Box
          bg="#111518"
          w={{ base: "100%", md: "50%" }}
          p={{ base: "16px", md: "32px", lg: "50px 60px" }}
          mb="10px"
        >
          <Box>
            <Flex justifyContent={{ base: "center", md: "start" }}>
              <Image src={Logo} w="214px" h="24px" />
            </Flex>
            <Divider
              borderColor="#292929"
              m="20px 0px"
              display={{ base: "block", md: "none" }}
            />
            <Box mt={{ base: "12px", md: "16px", lg: "20px" }}>
              <AuthText title={t("login")} description={t("seeYourSupport")} />
            </Box>
            <Flex mt={{ base: "22px", md: "26px", lg: "30px" }}>
              <Button
                _hover={{ bg: "#171C1F" }}
                _active={{ bg: "#171C1F" }}
                bg="#171C1F"
                border="1px solid #2D2D2D"
                borderRadius="4px"
                w="100%"
                size="lg"
                onClick={() => responseGoogle()}
              >
                <Flex alignItems="center">
                  <IconGoogle />
                </Flex>
              </Button>
            </Flex>
            <SectionDivider />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex>
                <Text
                  pr="1px"
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="17.5px"
                >
                  {t("Email")}
                </Text>
                <Text
                  color="#C8E815"
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="17.5px"
                >
                  *
                </Text>
              </Flex>
              <InputItem
                placeholder={"Enter email"}
                control={control}
                name="email"
                errors={errors.email}
              />
              <Flex mt="15px">
                <Text
                  pr="1px"
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="17.5px"
                >
                  {t("Password")}
                </Text>
                <Text
                  color="#C8E815"
                  fontSize="14px"
                  fontWeight={600}
                  lineHeight="17.5px"
                >
                  *
                </Text>
              </Flex>
              <InputItem
                placeholder={"Enter password"}
                control={control}
                type="password"
                name="password"
                errors={errors.password}
              />
              <Flex
                mt="15px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Checkbox
                  defaultIsChecked={isRememberMe}
                  onChange={handleChangeRememberMe}
                  className="checkbox"
                >
                  <Text fontSize={{ base: "12px", md: "14px" }}>
                    {t("RememberMe")}
                  </Text>
                </Checkbox>

                <Link to="/auth/forgot-password">
                  <Text color="#C8E815" fontSize={{ base: "12px", md: "14px" }}>
                    {t("ForgetPassword")}
                  </Text>
                </Link>
              </Flex>
              <Flex gridGap={{ base: "20px", lg: "10px" }}>
                <Button
                  mt={{ base: "28px", md: "32px", lg: "36px" }}
                  borderRadius="4px"
                  type="submit"
                  isLoading={isLoadingLogin}
                  background="#34391A"
                  color="#D4F70E"
                  _hover={{ background: "#34391A" }}
                  _active={{
                    border: "1px solid #D4F70E",
                  }}
                  border="1px solid #D4F70E"
                  w="100%"
                  size="lg"
                  fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                  spinner={<BeatLoader size={8} color="white" />}
                >
                  {t("login")}
                </Button>
                <Button
                  mt={{ base: "28px", md: "32px", lg: "36px" }}
                  borderRadius="4px"
                  isLoading={isLoadingGuest}
                  background="#232326"
                  color="#D4F70E"
                  _hover={{ background: "#232326" }}
                  _active={{
                    border: "1px solid #5E5E5E",
                  }}
                  border="1px solid #5E5E5E"
                  fontSize={{ base: "14px", md: "16px", lg: "18px" }}
                  w="100%"
                  size="lg"
                  onClick={handleLoginAsGuest}
                  spinner={<BeatLoader size={8} color="white" />}
                >
                  Guest
                </Button>
              </Flex>

              <Flex mt="20px" fontSize="14px">
                <Text mr="2px">{t("NotRegisteredYet")}</Text>
                <Box color="#C8E815">
                  <Link to="/auth/signup">{t("CreateAnAccount")}</Link>
                </Box>
              </Flex>
              <Text mt="20px" color="#343434" textAlign="center">
                {t("Superproductor")} {yearSuper}.
              </Text>
            </form>
          </Box>
        </Box>

        <Box
          w="50%"
          display={{ base: "none", md: "block" }}
          className="slider-login"
        >
          <Slider {...settings}>
            <Image height="100vh" src={GifSuper} />
            <Image w="100%" height="100vh" src={GifSuperNegotiation} />
          </Slider>
        </Box>
      </Flex>

      {showModalVerificationCode && (
        <ModalVerificationCode
          showModal={showModalVerificationCode}
          setShowModal={setShowModalVerificationCode}
          valueEmail={valueEmail}
          isRememberMe={isRememberMe}
        />
      )}
    </>
  );
});
