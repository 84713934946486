import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { TfiFaceSad } from "react-icons/tfi";

interface NotFoundPageProps {
  marginTop?: string;
}

export default function NotFoundPage({
  marginTop = "70px",
}: NotFoundPageProps) {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      mt={marginTop}
    >
      <TfiFaceSad size={110} />
      <Text fontFamily="Cera Pro Regular" fontSize="110px" fontWeight="700">
        404
      </Text>
      <Text fontSize="60px" fontWeight="700" fontFamily="Cera Pro Regular">
        {t("pageNotFound")}
      </Text>
    </Box>
  );
}
