import { Box, Button, Divider, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { Back } from "app/assets/icons";
import {
  createLicense,
  editLicense,
} from "app/redux/actions/licenseAndContracts";
import {
  ALLOW_BROAD_CASTING,
  EXCLUSIVE_LICENSE,
  LICENSE_RENEWAL,
} from "constants/index";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import countryList from "react-select-country-list";
import TextInputController from "../AddTrackComponents/TextInput";
import { CheckBoxField } from "../CustomFIeld/CheckBoxField/CheckBoxField";
import { SelectField } from "../CustomFIeld/SelectField/SelectField";
import { InputField } from "../CustomFIeld/TextField/InputField";
import Exclusive from "./exclusive";
import NonExclusive from "./nonExclusive";
import "./styles.scss";
import ReactCountryFlag from "react-country-flag";
import Select from "react-select";
import DeviceDetector from "device-detector-js";
import { typeDivice } from "utils/checkScreen";
import { BeatLoader } from "react-spinners";

export default function NewLicense() {
  const { t } = useTranslation();
  const deviceDetector = new DeviceDetector();
  const userAgent = window.navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  const {
    licenseDetail,
    isShowInput,
    setIsShowInput,
    LicenseContracts,
    handleClearFormLicense,
  } = useLicenseContracts();

  const history = useHistory();
  const dispatch = useDispatch();

  const { watch, control, reset, handleSubmit, formState, setValue } =
    useFormContext();

  const dataCheckbox = useMemo(
    () => [
      {
        name: "extraWav",
        label: t("includeWav"),
      },
      {
        name: "trackStems",
        label: t("includeTrackout"),
      },
    ],
    [t]
  );

  const dataLicense = useMemo(
    () => [
      {
        name: t("nonExclusive"),
        value: EXCLUSIVE_LICENSE.NON_EXCLUSIVE,
      },
      {
        name: t("exclusive"),
        value: EXCLUSIVE_LICENSE.EXCLUSIVE,
      },
    ],
    [t]
  );

  const options = useMemo(() => countryList().getData(), []);

  const contryList = options.map((item) => {
    return {
      ...item,
      icon: (
        <ReactCountryFlag
          countryCode={item.value}
          style={{
            fontSize: "30px",
          }}
          svg={device.device?.brand === typeDivice.APPLE ? false : true}
        />
      ),
    };
  });
  const formatOptionLabel = ({ label, icon }: any) => (
    <Flex
      background="#1F1F23"
      alignItems="center"
      borderRadius="4px"
      justifyContent="space-between"
      height="48px"
      padding="5px 15px"
      margin="0px"
    >
      <Text fontSize="14px" fontWeight="600">
        {label}
      </Text>
      {icon}
    </Flex>
  );

  const redirectLicenseItem = useCallback(() => {
    history.push({
      pathname: "/dashboard/contracts",
    });
  }, [history]);

  const onSubmit = useCallback(
    async (values) => {
      const convertValue = (value: string | number) =>
        value === "0" || value === 0 ? 0 : -1;
      const license = {
        ...values,
        country: values?.country.value || values.country,
        minPrice: values.minPrice ? Number(values.minPrice) : null,
        defaultTrackPrice: values.defaultTrackPrice
          ? Number(values.defaultTrackPrice)
          : null,
        beatsPublishing: values.beatsPublishing
          ? Number(values.beatsPublishing)
          : null,
        beatsHookPublishing: values.beatsHookPublishing
          ? Number(values.beatsHookPublishing)
          : null,
        topLinesAndVocals: values.topLinesAndVocals
          ? Number(values.topLinesAndVocals)
          : null,
        exclusive:
          values.exclusive === EXCLUSIVE_LICENSE.EXCLUSIVE ? true : false,
        allowBroadCasting:
          values.allowBroadCasting === ALLOW_BROAD_CASTING.ALLOW ? true : false,
        profitPerformance:
          values.profitPerformance === ALLOW_BROAD_CASTING.ALLOW ? true : false,
        licenseRenewal:
          values.licenseRenewal === LICENSE_RENEWAL.TWO_YEAR
            ? 2
            : values.licenseRenewal === LICENSE_RENEWAL.ONE_YEAR
            ? 1
            : null,
        distributionCopies: convertValue(values.distributionCopies),
        freeDownloads: convertValue(values.freeDownloads),
        audioStreams: convertValue(values.audioStreams),
        musicVideo: convertValue(values.musicVideo),
        nonMonetizedVideo: convertValue(values.nonMonetizedVideo),
        monetizedVideo: convertValue(values.monetizedVideo),
        radioStation: convertValue(values.radioStation),
        notProfitPerformance: convertValue(values.notProfitPerformance),
        description: !!values.description
          ? values.description
          : values.exclusive === EXCLUSIVE_LICENSE.EXCLUSIVE
          ? t("descriptionExclusive")
          : t("descriptionNonExclusive"),
      };

      if (!licenseDetail?._id) {
        await dispatch(createLicense(license));
      } else {
        await dispatch(editLicense(licenseDetail?._id, license));
      }
      reset();
      redirectLicenseItem();
    },
    [dispatch, licenseDetail?._id, redirectLicenseItem, reset, t]
  );

  const handleResetForm = useCallback(() => {
    reset();
    reset({ exclusive: null, defaultTrackPrice: "", name: "" });
    handleClearFormLicense();
  }, [handleClearFormLicense, reset]);

  const [isValidationMp3, setIsValidationMp3] = useState<boolean>(true);

  useEffect(() => {
    const subscription = watch((value: any) => {
      setIsValidationMp3(Boolean(value.mp3));
      if (value.beatsPublishing) {
        return setIsShowInput([false, true, true]);
      }
      if (value.beatsHookPublishing) {
        return setIsShowInput([true, false, true]);
      }
      if (value.topLinesAndVocals) {
        return setIsShowInput([true, true, false]);
      }

      return setIsShowInput([false, false, false]);
    });
    return () => subscription.unsubscribe();
  }, [setIsShowInput, watch]);

  const { exclusive, makeOffersOnly } = watch();

  const checkExclusive = useMemo(() => {
    return exclusive === EXCLUSIVE_LICENSE.EXCLUSIVE;
  }, [exclusive]);

  useEffect(() => {
    if (!licenseDetail?._id) {
      setValue("beatsPublishing", null);
      setValue("makeOffersOnly", false);
      setValue("beatsHookPublishing", null);
      setValue("topLinesAndVocals", null);
      setValue("distributionCopies", 0);
      setValue("freeDownloads", 0);
      setValue("audioStreams", 0);
      setValue("musicVideo", 0);
      setValue("nonMonetizedVideo", 0);
      setValue("monetizedVideo", 0);
      setValue("allowBroadCasting", ALLOW_BROAD_CASTING.NOT_ALLOW);
      setValue("profitPerformance", ALLOW_BROAD_CASTING.NOT_ALLOW);
      setValue("radioStation", 0);
      setValue("notProfitPerformance", 0);
      setValue("licenseRenewal", null);
    }
  }, [setValue, exclusive, licenseDetail?._id]);

  return (
    <Box
      position="relative"
      className="general-info"
      mt={{ base: "0px", md: "30px" }}
    >
      <Flex alignItems="center">
        <Flex
          onClick={redirectLicenseItem}
          cursor="pointer"
          border="1px solid rgba(255, 255, 255, 0.04)"
          w="50px"
          h="50px"
          alignItems="center"
          justifyContent="center"
          borderRadius="full"
        >
          <Back />
        </Flex>
        <Text ml="25px">{t("backToLicense")}</Text>
      </Flex>
      <Text mt="40px" fontSize={{ base: "20px", md: "24px" }}>
        {t("generalInfo")}
      </Text>
      <Divider borderColor="#24252a" mt="10px" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="16px" mt="20px">
          <InputField
            name="name"
            label={t("name")}
            placeholder={t("licenseName")}
            type="tel"
            control={control}
            errors={formState.errors}
          />
          <InputField
            name="defaultTrackPrice"
            label={t("price")}
            placeholder="0"
            type="number"
            control={control}
            iconInput
            errors={formState.errors}
          />
          <SelectField
            name="exclusive"
            label={t("typeOfContract")}
            placeholder={t("selectTypeOfContract")}
            options={dataLicense}
            control={control}
            errors={formState.errors}
          />
          {makeOffersOnly && (
            <InputField
              name="minPrice"
              label={t("minimum")}
              placeholder="0"
              type="number"
              control={control}
              iconInput
              errors={formState.errors}
            />
          )}
        </SimpleGrid>
        {checkExclusive && (
          <Box mt="25px">
            <CheckBoxField
              name="makeOffersOnly"
              label={t("allowOffers")}
              control={control}
            />
          </Box>
        )}

        <Text fontSize={{ base: "20px", md: "24px" }} mt="40px">
          {t("filesToDeliver")}
        </Text>
        <Divider borderColor="#24252a" mt="10px" />
        <Flex display={{ base: "block", md: "flex" }}>
          <Box mt="30px" mr="50px">
            <CheckBoxField
              name="mp3"
              label={t("includeMp3")}
              defaultChecked
              control={control}
              errors={formState.errors}
            />
          </Box>

          {dataCheckbox.map((item, index) => (
            <Flex className="checkbox-license" mt="30px" mr="50px" key={index}>
              <CheckBoxField
                name={item.name}
                label={item.label}
                control={control}
              />
            </Flex>
          ))}
        </Flex>
        {!isValidationMp3 && (
          <Text fontSize="12px" color="red" mt="5px">
            MP3 file is required
          </Text>
        )}
        {checkExclusive ? (
          <Exclusive disable={isShowInput} />
        ) : exclusive === EXCLUSIVE_LICENSE.NON_EXCLUSIVE ? (
          <NonExclusive />
        ) : (
          ""
        )}

        <Text mt="40px" fontSize={{ base: "20px", md: "24px" }}>
          {t("address")}
        </Text>
        <Divider borderColor="#24252a" mt="10px" />
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="15px">
          <Box mt="15px">
            <InputField
              name="state"
              label={t("stateOrProvidence")}
              placeholder={t("enterStateOrProvidence")}
              type="tel"
              control={control}
              errors={formState.errors}
            />
          </Box>

          <Box mt="15px">
            <Text pb="5px" color="#535353">
              {t("country")}
            </Text>

            <Controller
              name={"country"}
              render={({ field }) => {
                const value = contryList.find(
                  (item) => item.value === field.value
                );
                return (
                  <Select
                    className="select-flag"
                    classNamePrefix="select"
                    options={contryList}
                    placeholder={t("searchCountry")}
                    formatOptionLabel={formatOptionLabel}
                    {...field}
                    value={value}
                  />
                );
              }}
              control={control}
            />
            <Text fontSize="14px" fontFamily="Cera Pro Regular" color="#e41717">
              {formState.errors?.country?.message}
            </Text>
          </Box>
        </SimpleGrid>
        <Text mt="40px" fontSize={{ base: "20px", md: "24px" }}>
          {t("optionInformation")}
        </Text>
        <Divider borderColor="#24252a" mt="10px" />
        <Box mt="24px">
          <TextInputController
            type="textarea"
            label={t("descriptionOption")}
            name="description"
            placeholder={
              checkExclusive
                ? t("descriptionExclusive")
                : t("descriptionNonExclusive")
            }
            errors={formState.errors}
            control={control}
          />
        </Box>

        <Flex mt="40px">
          <Button
            backgroundColor="#212125"
            borderRadius="4px"
            fontSize="14px"
            p="15px 60px"
            mr="25px"
            size="lg"
            color="#4D4D51"
            onClick={handleResetForm}
            type="reset"
          >
            {t("reset")}
          </Button>
          <Button
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            borderRadius="4px"
            fontSize="14px"
            p="15px 40px"
            size="lg"
            type="submit"
            isLoading={LicenseContracts?.isLoading}
            spinner={<BeatLoader size={8} color="white" />}
          >
            {licenseDetail?._id ? t("update") : t("save")}
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
