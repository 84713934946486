import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  SimpleGrid,
  Text,
  Checkbox,
  Image,
  Divider,
} from "@chakra-ui/react";
import MonneyButton from "app/assets/icons/MoneyButton";
import CheckBox from "app/components/Checkbox";
import { Licenses } from "app/models";
import { TRACK_LICENSE } from "constants/contractType";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import React, { useEffect, useMemo, useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./styles.scss";
import ErrorMessage from "app/components/ErrorMessage";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useSelector } from "react-redux";
import { onHandleGetCollaborations } from "utils/convertCollaborators";
import Radio from "app/components/Radio";
import IconSuperRequireDownload from "app/assets/images/IconSuperRequireDownload.svg";
import {
  DOWNLOAD_OPTIONS,
  freeDownloadOptions,
  freeDownloadSoundkitOptions,
} from "constants/downloadOptions";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import ToastPlanSubscription from "../toast/toastplan";

interface LicenseAndAvailabilityProps {
  isSoundkit?: boolean;
}

export default function LicenseAndAvailability({
  isSoundkit,
}: LicenseAndAvailabilityProps) {
  const { t } = useTranslation();
  const {
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext();
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  const {
    contractType: contractTypeWatch,
    licenses: selectedLicensesWatch,
    notForSale: isNotForSale,
    private: isPrivate,
    excludeFromBulkDiscounts: isExcludeFromBulkDiscounts,
    collaborators,
  } = watch();
  const userAdmin = useSelector(selectUserAdmin);

  const {
    nonExclusive = [],
    exclusive = [],
    getLicenseAndContracts,
  } = useLicenseContracts();

  useEffect(() => {
    getLicenseAndContracts();
  }, [getLicenseAndContracts]);

  const licensesWithTrackPrice = useCallback((licenses) => {
    return licenses.map((license: Licenses) =>
      license.defaultTrackPrice
        ? {
            ...license,
            trackPrice: license.defaultTrackPrice,
          }
        : { ...license }
    );
  }, []);

  const currentLicensesType = useMemo(() => {
    switch (contractTypeWatch) {
      case TRACK_LICENSE.NON_EXCLUSIVE:
        return licensesWithTrackPrice(nonExclusive);

      case TRACK_LICENSE.EXCLUSIVE:
        return licensesWithTrackPrice(exclusive);

      default:
        return [];
    }
  }, [contractTypeWatch, nonExclusive, exclusive, licensesWithTrackPrice]);

  const isExistLicense = useCallback(
    (licenseId) =>
      selectedLicensesWatch.some(
        (selectedLicense: Licenses) => selectedLicense._id === licenseId
      ),
    [selectedLicensesWatch]
  );

  const handleLicensePriceChange = useCallback(
    (value, licenseId) => {
      const updatedLicenses = selectedLicensesWatch.map((license: Licenses) =>
        license._id === licenseId
          ? { ...license, trackPrice: value }
          : { ...license }
      );
      setValue("licenses", updatedLicenses);
    },
    [setValue, selectedLicensesWatch]
  );

  const renderIncludes = useCallback(
    (license) => {
      if (license.extraWav) {
        return license.trackStems
          ? t("includesMp3WavAndTrackStems")
          : t("includesMp3AndWav");
      } else {
        return license.trackStems
          ? t("includesMp3AndTrackStems")
          : t("includesMp3");
      }
    },
    [t]
  );

  const activeCurrentLicense = currentLicensesType.filter(
    (item: Licenses) => item.active
  );

  useEffect(() => {
    setValue("licenses", []);
  }, [contractTypeWatch, setValue]);
  useEffect(() => {
    const firstError = Object.keys(errors).reduce((field, a) => {
      return !!errors[field] ? field : a;
    }, null);
    if (firstError) {
      document.getElementById(firstError)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [errors]);

  return (
    <>
      {contractTypeWatch && (
        <Box>
          <Text fontSize="20px" mb="20px" fontFamily="Cera Pro Medium">
            {t("chooseYourLicense")}
          </Text>
          <Controller
            name={"licenses"}
            render={({ field }) => {
              return (
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing={{ base: "0px", md: "15px" }}
                >
                  {activeCurrentLicense.length > 0 &&
                    activeCurrentLicense.map(
                      (license: Licenses, index: number) => {
                        return (
                          <Box key={license._id} mb="20px" id="licenses">
                            <Flex
                              backgroundColor="#24252A"
                              borderRadius="4px"
                              py="13px"
                              px="16px"
                              alignItems="center"
                            >
                              <CheckBox
                                filledCheckbox
                                defaultChecked={isExistLicense(license._id)}
                                isChecked={isExistLicense(license._id)}
                                onChange={(value: boolean) => {
                                  const licenseValue = value
                                    ? contractTypeWatch ===
                                      TRACK_LICENSE.EXCLUSIVE
                                      ? [license]
                                      : [...selectedLicensesWatch, license]
                                    : selectedLicensesWatch.filter(
                                        (selectedLicense: Licenses) =>
                                          selectedLicense._id !== license._id
                                      );
                                  field.onChange({
                                    target: {
                                      value: licenseValue,
                                    },
                                  });
                                  onHandleGetCollaborations({
                                    licenseWatch: licenseValue,
                                    contractType: contractTypeWatch,
                                    collaborators: collaborators,
                                    setValue: setValue,
                                    currentUser: userAdmin,
                                  });
                                }}
                              />
                              <Text color="white" ml="16px">
                                {license.name}
                              </Text>
                            </Flex>
                            {isExistLicense(license._id) && (
                              <Box mt="25px">
                                <Text
                                  color="#535353"
                                  fontSize="16px"
                                  lineHeight="19px"
                                >
                                  {`${license.name} ${t(
                                    "Price"
                                  )} ${renderIncludes(license)}`}
                                </Text>
                                <InputGroup
                                  borderRadius="4px"
                                  size="lg"
                                  mt="10px"
                                >
                                  <InputLeftAddon
                                    children={<MonneyButton />}
                                    backgroundColor="#28282C"
                                    border="none"
                                  />
                                  <Input
                                    type="number"
                                    onChange={(e) =>
                                      handleLicensePriceChange(
                                        e.target.value,
                                        license._id
                                      )
                                    }
                                    placeholder={`${license?.defaultTrackPrice?.toFixed(
                                      2
                                    )}`}
                                    border="none"
                                    backgroundColor="#25252A"
                                    min={license.exclusive && license.minPrice}
                                    disabled={isNotForSale}
                                    step={0.01}
                                  />
                                </InputGroup>
                                <Box
                                  background="#221930"
                                  borderRadius="8px"
                                  mt="15px"
                                  padding="5px 10px"
                                >
                                  <Text
                                    color="#7C20E6"
                                    fontSize="14px"
                                    lineHeight="17px"
                                  >
                                    {t("requiredUploadFiles")}
                                  </Text>
                                </Box>
                                <Flex mt="15px" gap="10px">
                                  <Box
                                    background="#505B18"
                                    borderRadius="8px"
                                    padding="10px"
                                  >
                                    <Text
                                      color="#D4F70E"
                                      fontSize="14px"
                                      lineHeight="17px"
                                    >
                                      {t("mp3")}
                                    </Text>
                                  </Box>
                                  {license?.extraWav && (
                                    <Box
                                      background="#505B18"
                                      borderRadius="8px"
                                      padding="10px"
                                    >
                                      <Text
                                        color="#D4F70E"
                                        fontSize="14px"
                                        lineHeight="17px"
                                      >
                                        {t("wav")}
                                      </Text>
                                    </Box>
                                  )}
                                  {license?.trackStems && (
                                    <Box
                                      background="#505B18"
                                      borderRadius="8px"
                                      padding="10px"
                                    >
                                      <Text
                                        color="#D4F70E"
                                        fontSize="14px"
                                        lineHeight="17px"
                                      >
                                        {t("stems")}
                                      </Text>
                                    </Box>
                                  )}
                                </Flex>
                              </Box>
                            )}
                          </Box>
                        );
                      }
                    )}
                </SimpleGrid>
              );
            }}
            control={control}
          />
          <ErrorMessage error={errors?.licenses?.message} />
          <Flex alignItems="center" mt="15px" gap="10px" flexWrap="wrap">
            <Text fontFamily="Cera Pro Regular">{t("licenseNote")}</Text>
            <Text color="#535353" fontFamily="Cera Pro Regular">
              {t("customizeYourLicense")}
            </Text>
            <Link to="/dashboard/contracts">
              <Text
                color="#D4F70E"
                cursor="pointer"
                fontFamily="Cera Pro Regular"
              >
                {t("LicenseAndContracts")}
              </Text>
            </Link>
          </Flex>

          <Box mt="20px">
            <Text color="#535353" fontFamily="Cera Pro Regular">
              {t("toDisableALicense")}
            </Text>
            <Text color="#535353" fontFamily="Cera Pro Regular">
              {t("thisWillDisable")}
            </Text>
          </Box>
        </Box>
      )}

      <Text mt="20px" fontSize="20px" fontFamily="Cera Pro Medium">
        {t("trackAvailability")}
      </Text>
      <Flex
        display={{ base: "block", md: "flex" }}
        mt="20px"
        mb="30px"
        gridGap={{ md: "10px", lg: "30px" }}
        fontFamily="Cera Pro Regular"
        alignItems="center"
      >
        <Controller
          name={"private"}
          render={() => (
            <CheckBox
              title={t("private")}
              isChecked={isPrivate}
              onChange={(value) => setValue("private", value)}
            />
          )}
          control={control}
        />
        <Box mt={{ base: "10px", md: "0px" }} mb={{ base: "10px", md: "0px" }}>
          <Controller
            name={"excludeFromBulkDiscounts"}
            render={() => (
              <CheckBox
                title={t("excludeBulkOfferDiscout")}
                isChecked={isExcludeFromBulkDiscounts}
                onChange={(value) =>
                  setValue("excludeFromBulkDiscounts", value)
                }
              />
            )}
            control={control}
          />
        </Box>
        <Controller
          name={"notForSale"}
          render={() => (
            <CheckBox
              title={t("notForSale")}
              defaultChecked={isNotForSale}
              onChange={(value) => setValue("notForSale", value)}
            />
          )}
          control={control}
        />
      </Flex>
      <Divider
        borderColor="#24252A"
        borderWidth="2px"
        mb="20px"
        display={{ base: "block", md: "none" }}
      />
      <Text
        fontSize="20px"
        fontWeight="700"
        display={{ base: "block", md: "none" }}
      >
        {t("requireDownloadersTo")}
      </Text>

      <Box
        backgroundColor="#25252A"
        p="25px 15px"
        mt="30px"
        borderRadius="4px"
        display={{ base: "block", md: "none" }}
      >
        <Text fontFamily="Cera Pro Medium" fontWeight="600">
          {t("requireDownloadersTo")}
        </Text>
        <Flex alignItems="center" padding="30px 0px">
          <Controller
            name={"requireDownloadSp"}
            render={({ field }) => {
              return (
                <Checkbox
                  size="md"
                  isChecked={field.value}
                  className="checkbox-download"
                  {...field}
                >
                  <Flex alignItems="center">
                    <Image
                      ml="10px"
                      mr="10px"
                      fallbackSrc={IconSuperRequireDownload}
                    />
                    <Text fontWeight="500" fontSize="14px" lineHeight="16px">
                      Superproductor
                    </Text>
                  </Flex>
                </Checkbox>
              );
            }}
            control={control}
          />
        </Flex>

        <Text fontFamily="Cera Pro Medium" fontWeight="600">
          {t("freeDownload")}
        </Text>
        <Controller
          name={"freeDownloadEnabled"}
          render={({ field: { onChange, value } }) => (
            <Box id="freeDownloadEnabled">
              {(isSoundkit
                ? freeDownloadSoundkitOptions
                : freeDownloadOptions
              ).map((item, index) => {
                const { label: labelValue, value: optionValue } = item;
                return (
                  <Radio
                    key={index}
                    title={t(labelValue)}
                    value={optionValue}
                    onChange={(checked) => {
                      if (checkPlanSubscription) {
                        if (
                          checked === DOWNLOAD_OPTIONS.NO ||
                          optionValue === DOWNLOAD_OPTIONS.NO
                        ) {
                          onChange({ target: { value: checked } });
                        } else {
                          ToastPlanSubscription({
                            title: t(
                              "titleUpgradeYourPlanToEnableFreeDownload"
                            ),
                            description: t(
                              "desUpgradeYourPlanToEnableFreeDownload"
                            ),
                          });
                        }
                      } else {
                        onChange({ target: { value: checked } });
                      }
                    }}
                    isChecked={optionValue === value}
                  />
                );
              })}
            </Box>
          )}
          control={control}
        />
        <ErrorMessage error={errors.freeDownloadEnabled?.message} />

        {/* SHOWME later */}
        {/* <Divider mt="20px" borderBottom="2px solid #2B2B30" />
        <Flex alignItems="center" mt="20px">
          <Checkbox mr="15px" />
          <Text ml="10px" fontSize="14px">
            {t("allowAnonymous")}
          </Text>
        </Flex> */}
      </Box>
    </>
  );
}
