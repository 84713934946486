import {
  Button,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Flex,
} from "@chakra-ui/react";
import { deleteCreditCard } from "app/redux/actions/cart";
import { useCart } from "hooks/cart/useCart";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
interface ModalProps {
  showModal: boolean;
  setShowModal: () => void;
}

export default function ModalRemoveCard({
  showModal,
  setShowModal,
}: ModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paymentMethod } = useCart();
  const handleDeleteCard = useCallback(() => {
    dispatch(deleteCreditCard(paymentMethod?.payload?.id));
    setShowModal();
  }, [dispatch, paymentMethod?.payload?.id, setShowModal]);

  return (
    <Modal colorScheme="black" isOpen={showModal} onClose={setShowModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          p="16px 24px 0px"
          justifyContent="center"
          display="flex"
          fontSize="32px"
          mt="20px"
        >
          {t("removeThisCard")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={setShowModal}
          right={3}
          top="15px"
          fontSize="22px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalBody display="flex" justifyContent="center">
          <Text w="65%" textAlign="center">
            {t("areYouSureRemoveCard")}
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="center" mb="20px">
          <Flex justifyContent="center" w="full">
            <Button
              backgroundColor="#212125"
              borderRadius="4px"
              mr={3}
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
              onClick={setShowModal}
            >
              {t("cancel")}
            </Button>
            <Button
              backgroundColor="#ED3536"
              color="white"
              borderRadius="4px"
              p="15px 30px"
              w="150px"
              _hover={{ boxShadow: "none" }}
              onClick={handleDeleteCard}
            >
              {t("remove")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
