import React from "react";

interface IconLyricsIndividualTrackMobileProps {
  isLyric?: boolean;
}

const IconLyricsIndividualTrackMobile = ({
  isLyric,
}: IconLyricsIndividualTrackMobileProps) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16319_8414)">
        <path
          d="M11.0078 6.875C11.0078 6.52981 10.728 6.25 10.3828 6.25H3.94531C3.60013 6.25 3.32031 6.52981 3.32031 6.875C3.32031 7.22019 3.60013 7.5 3.94531 7.5H10.3828C10.728 7.5 11.0078 7.22019 11.0078 6.875Z"
          fill={isLyric ? "#D4F70E" : "white"}
        />
        <path
          d="M3.94531 8.75C3.60012 8.75 3.32031 9.02981 3.32031 9.375C3.32031 9.72019 3.60012 10 3.94531 10H7.85503C8.20021 10 8.48003 9.72019 8.48003 9.375C8.48003 9.02981 8.20021 8.75 7.85503 8.75H3.94531Z"
          fill={isLyric ? "#D4F70E" : "white"}
        />
        <path
          d="M5.41841 14.75H3.32812C2.63887 14.75 2.07812 14.1892 2.07812 13.5V2.5C2.07812 1.81075 2.63887 1.25 3.32812 1.25H11.0122C11.7014 1.25 12.2622 1.81075 12.2622 2.5V6.34375C12.2622 6.68894 12.542 6.96875 12.8872 6.96875C13.2323 6.96875 13.5122 6.68894 13.5122 6.34375V2.5C13.5122 1.1215 12.3907 0 11.0122 0H3.32812C1.94962 0 0.828125 1.1215 0.828125 2.5V13.5C0.828125 14.8785 1.94962 16 3.32812 16H5.41841C5.76359 16 6.04341 15.7202 6.04341 15.375C6.04341 15.0298 5.76359 14.75 5.41841 14.75Z"
          fill={isLyric ? "#D4F70E" : "white"}
        />
        <path
          d="M14.6149 9.04837C13.8838 8.31731 12.6943 8.31728 11.9638 9.04787L8.53241 12.4716C8.45953 12.5443 8.40578 12.6339 8.37584 12.7324L7.62856 15.1926C7.56231 15.4107 7.62003 15.6475 7.77922 15.8107C7.89847 15.9329 8.06047 15.9992 8.22662 15.9992C8.28222 15.9992 8.33834 15.9918 8.39344 15.9765L10.9161 15.2777C11.0199 15.249 11.1145 15.1939 11.1908 15.1178L14.6149 11.7001C15.346 10.969 15.346 9.7795 14.6149 9.04837ZM10.424 14.117L9.15491 14.4686L9.52631 13.2458L11.8416 10.9356L12.7257 11.8197L10.424 14.117ZM13.7315 10.8158L13.6104 10.9367L12.7265 10.0527L12.8472 9.93231C13.0909 9.68862 13.4874 9.68862 13.7311 9.93231C13.9748 10.176 13.9748 10.5725 13.7315 10.8158Z"
          fill={isLyric ? "#D4F70E" : "white"}
        />
        <path
          d="M10.3828 3.75H3.94531C3.60013 3.75 3.32031 4.02981 3.32031 4.375C3.32031 4.72019 3.60013 5 3.94531 5H10.3828C10.728 5 11.0078 4.72019 11.0078 4.375C11.0078 4.02981 10.728 3.75 10.3828 3.75Z"
          fill={isLyric ? "#D4F70E" : "white"}
        />
      </g>
      <defs>
        <clipPath id="clip0_16319_8414">
          <rect width="16" height="16" fill={isLyric ? "#D4F70E" : "white"} />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconLyricsIndividualTrackMobile;
