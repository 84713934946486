import { selectUserState } from "app/redux/selectors/users";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { forgotPasswordUser } from "app/redux/actions/users";
import { ForgotPassword } from "app/redux/actions/users/interface";

export const useForgotPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isLoading, isEmailSent } = useSelector(selectUserState);

  const forgotPasswordValidationSchema = yup.object().shape({
    email: yup.string().label(t("emailAdress")).required().email(),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<ForgotPassword>({
    resolver: yupResolver(forgotPasswordValidationSchema),
  });

  const onSubmit = (data: ForgotPassword) => {
    const payload: ForgotPassword = {
      email: data.email,
    };

    dispatch(forgotPasswordUser(payload));
  };

  return {
    control,
    errors,
    handleSubmit,
    isLoading,
    onSubmit,
    isEmailSent,
  };
};
