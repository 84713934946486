export const DOWNLOAD_OPTIONS = {
  NO: "NO",
  YES_WITH_VOICE_TAG: "YES_WITH_VOICE_TAG",
  YES_UNTAGGED: "YES_UNTAGGED",
  YES: "YES",
};

export const freeDownloadOptions = [
  { label: "no", value: DOWNLOAD_OPTIONS.NO },
  {
    label: "yesWithVoice",
    value: DOWNLOAD_OPTIONS.YES_WITH_VOICE_TAG,
  },
  { label: "yesUntagged", value: DOWNLOAD_OPTIONS.YES_UNTAGGED },
];

export const freeDownloadSoundkitOptions = [
  { label: "no", value: "NO" },
  {
    label: "yes",
    value: "YES",
  },
];
