import { Box, Flex, Image, Text } from "@chakra-ui/react";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import PriceDownloadButton from "app/components/PriceButton";
import TagGenreButton from "app/components/TagGenreButton";
import { transaction } from "app/models";
import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { formatTimeByDayMonthYear } from "utils/timeAgo";
import { TRACK_TYPE } from "constants/trackTypes";
import countryList from "react-select-country-list";
import AvatarUserLoading from "app/assets/images/UserAvatar.png";
import { roleText } from "constants/collaboratorType";
import { TRACK_STATE } from "constants/index";
import "./styles.scss";

interface transactionItemsMobileProps {
  transactionItems: transaction;
}

export default function PaymentsTranferItemMobile({
  transactionItems,
}: transactionItemsMobileProps) {
  const { t } = useTranslation();
  const options = useMemo(() => countryList().getData(), []);
  const fullNameCountry = useCallback(
    (label) => options.find((item) => item.value === label),
    [options]
  );

  const isExclusive = useMemo(
    () =>
      (transactionItems?.track?.licenses || []).some(
        (license) => license.exclusive
      ),
    [transactionItems?.track?.licenses]
  );

  const licensesName = useMemo(
    () =>
      (transactionItems?.track?.licenses || [])
        .filter((license) => license._id === transactionItems?.license)
        .map((item) => item.name),
    [transactionItems?.license, transactionItems?.track?.licenses]
  );

  const checkTrackOrSoundPack = useMemo(() => {
    return !!transactionItems.track;
  }, [transactionItems.track]);

  return (
    <>
      <Flex
        direction={{ base: "column", lg: "row" }}
        w="100%"
        background="#1E2023"
        borderRadius="10px"
        padding="23px 20px"
        mb="20px"
        border="1px solid #343639"
      >
        <Flex alignItems="center" justifyContent="space-between" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("trackName")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("purchaser")}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" gap="10px" mb="20px">
          <Flex w="70%" overflow="hidden">
            <Image
              src={
                checkTrackOrSoundPack
                  ? transactionItems?.track?.coverUrl
                  : transactionItems?.soundkit?.coverUrl || ImageDefault
              }
              fallbackSrc={ImageDefault}
              w="35px"
              height="36px"
              borderRadius="6px"
            />
            <Text fontSize="14px" fontWeight="700" noOfLines={2} ml="8px">
              {checkTrackOrSoundPack
                ? transactionItems?.track?.title
                : transactionItems?.soundkit?.title}
            </Text>
          </Flex>

          <Flex
            alignItems="center"
            w="30%"
            overflow="hidden"
            justifyContent="end"
          >
            <Image
              w="36px"
              h="36px"
              src={transactionItems?.buyerId?.profileImage}
              fallbackSrc={AvatarUserLoading}
              borderRadius="full"
            />
            <Box ml="10px" overflow="hidden">
              <Text
                fontSize={{ base: "14px", lg: "16px" }}
                fontWeight="700"
                noOfLines={1}
              >
                {transactionItems?.buyerId?.name}
              </Text>
              <Text
                color="#4B4B4E"
                fontSize={{ base: "12px", lg: "14px" }}
                fontWeight="500"
                noOfLines={1}
              >
                {roleText(transactionItems?.buyerId?.role?.[0])}
              </Text>
            </Box>
          </Flex>
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("transactionId")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("saleDate")}
          </Text>
        </Flex>

        <Flex justifyContent="space-between" mb="10px">
          <Text
            w="50%"
            fontSize="12px"
            lineHeight="16px"
            fontWeight="400"
            noOfLines={2}
          >{`${transactionItems?._id}`}</Text>
          <Text
            textAlign="end"
            w="50%"
            fontSize="12px"
            lineHeight="16px"
            fontWeight="400"
          >
            {formatTimeByDayMonthYear(transactionItems?.createdAt)}
          </Text>
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("contractDetails")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("geographicalIpFinder")}
          </Text>
        </Flex>

        <Flex justifyContent="space-between" mb="10px">
          {checkTrackOrSoundPack ? (
            <Box
              w="60%"
              display="flex"
              flexDirection="column"
              textAlign={{ lg: "center" }}
            >
              <TagGenreButton data={licensesName} isMyTrack />
              <Box
                display="flex"
                alignItems="center"
                height="20px"
                borderRadius="4px"
                padding="2px 5px"
                backgroundColor="#2b2e1b"
                width="fit-content"
              >
                <Text fontSize="12px" lineHeight="14px" color="#d4f70e">
                  {isExclusive
                    ? t("exclusiveContract")
                    : t("nonExclusiveContract")}
                </Text>
              </Box>
            </Box>
          ) : (
            <Flex w="60%" flexDirection="column" alignItems={{ lg: "center" }}>
              <Text
                height="20px"
                borderRadius="4px"
                padding="2px 10px"
                mr="5px"
                mb="5px"
                backgroundColor="#2b2e1b"
                fontSize="12px"
                lineHeight="14px"
                color="#d4f70e"
                fontFamily="Cera Pro Regular"
                w="max-content"
              >
                {t(TRACK_TYPE[transactionItems?.soundkit?.type])}
              </Text>
            </Flex>
          )}

          <Text fontSize="12px" fontWeight="400" noOfLines={1}>
            {fullNameCountry(transactionItems?.ipAddress)?.label}
          </Text>
        </Flex>

        <Flex alignItems="center" justifyContent="space-between" mb="10px">
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("downloadConfirmation")}
          </Text>
          <Text fontSize="14px" color="#777777" fontWeight="400">
            {t("price")}
          </Text>
        </Flex>

        <Flex justifyContent="space-between">
          {transactionItems?.statusDownload !== TRACK_STATE.PENDING ? (
            <Flex w="60%">
              <Box
                display="flex"
                alignItems="center"
                borderRadius="4px"
                padding="4px 10px"
                backgroundColor="#193347"
              >
                <Text fontSize="12px" fontWeight="500" color="#1DA1F2">
                  {t("Pending")}
                </Text>
              </Box>
            </Flex>
          ) : (
            <Box
              w="65%"
              display="flex"
              flexDirection="column"
              alignItems="start"
            >
              <Box
                display="flex"
                borderRadius="4px"
                padding="3px 20px"
                backgroundColor="#2b2e1b"
                alignSelf="baseline"
              >
                <Text fontSize="12px" fontWeight="500" color="#d4f70e">
                  {t("Downloaded")}
                </Text>
              </Box>
              <Text
                mt="5px"
                textAlign="center"
                fontSize="10px"
                fontWeight="400"
              >
                {formatTimeByDayMonthYear(transactionItems?.timeDownload)}
              </Text>
            </Box>
          )}

          <Flex>
            <PriceDownloadButton transactionItems={transactionItems} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
