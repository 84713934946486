import { Box } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import "./styles.scss";
import data from "./features.json";
import { TopUserAnalytics } from "app/models";

interface MapBasicComponentsProps {
  topCountry: TopUserAnalytics[];
}

export default function MapBasicComponents({
  topCountry,
}: MapBasicComponentsProps) {
  const countries = require("country-data").countries;

  const nameTopCountry = (topCountry || []).map(
    (item) => countries[item?._id]?.alpha3
  );

  const checkInListMap = useCallback(
    (idMap) => {
      return nameTopCountry.includes(idMap);
    },
    [nameTopCountry]
  );

  return (
    <Box>
      <ComposableMap>
        <Geographies geography={data}>
          {({ geographies }) =>
            geographies.map((geo) => {
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={checkInListMap(geo.id) ? "#d4f70e" : "#282828"}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
    </Box>
  );
}
