import React from "react";

const IconNegotiationCart = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41333 17.0588V19.413C9.41333 19.7367 9.67569 19.9991 9.99931 19.9991H19.414C19.7376 19.9991 20 19.7367 20 19.413V17.0588C20 16.7351 19.7376 16.4727 19.414 16.4727H9.99931C9.67565 16.4727 9.41333 16.7351 9.41333 17.0588ZM10.5853 17.6449H18.828V18.8269H10.5853V17.6449Z"
        fill="#D4F70E"
      />
      <path
        d="M0.515484 17.6475C0.859141 17.9913 1.31058 18.1632 1.76198 18.1632C2.21337 18.1632 2.66481 17.9913 3.00847 17.6475L10.0835 10.5708L10.9191 11.4066L10.5013 11.8245C10.2724 12.0534 10.2724 12.4245 10.5013 12.6534L12.1656 14.3182C12.28 14.4326 12.43 14.4898 12.5799 14.4898C12.7299 14.4898 12.8799 14.4326 12.9943 14.3182L17.9872 9.32404C18.216 9.09514 18.216 8.72405 17.9872 8.49515L16.3229 6.83042C16.094 6.60152 15.723 6.60152 15.4942 6.83042L15.0764 7.24832L10.9122 3.08314L11.33 2.66524C11.4399 2.55532 11.5016 2.40625 11.5016 2.25081C11.5016 2.09534 11.4399 1.94631 11.33 1.83639L9.66568 0.171659C9.43684 -0.0572392 9.0658 -0.0572002 8.83699 0.171659L3.84411 5.16577C3.61527 5.39467 3.61527 5.76576 3.84411 5.99466L5.5084 7.65935C5.61826 7.76927 5.76733 7.83104 5.92273 7.83104C6.07813 7.83104 6.2272 7.76927 6.33705 7.65935L6.75486 7.24145L7.5905 8.07729L0.515484 15.154C-0.171828 15.8414 -0.171828 16.9601 0.515484 17.6475ZM10.087 2.25081L5.92273 6.416L5.08712 5.5802L9.25132 1.41501L10.087 2.25081ZM11.7443 12.239L15.9085 8.07381L16.7441 8.90962L12.5799 13.0748L11.7443 12.239ZM11.7478 10.5777L7.58358 6.41256L10.0835 3.91207L14.2477 8.07725L11.7478 10.5777ZM1.34417 15.9828L8.41919 8.90618L9.25479 9.74198L2.17982 16.8186C1.94941 17.049 1.57462 17.049 1.34417 16.8186C1.11381 16.5882 1.11381 16.2133 1.34417 15.9828Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconNegotiationCart;
