import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import CreateNewLicense from "app/components/CreateNewLicense";
import TabsPanels from "app/components/TabsPanels";
import Back from "app/assets/icons/back";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import "./styles.scss";
import IconDiscordDashboard from "app/assets/icons/IconDiscordDashboard";
import IconDiscordDashboardMobile from "app/assets/icons/IconDiscordDashboardMobile";
import IconDiscordDashboardTablet from "app/assets/icons/IconDiscordDashboardTablet";

interface DashboardProps {
  isAddTrack?: boolean;
}

export default function Dashboard({ isAddTrack }: DashboardProps) {
  const location: any = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const [isLessThan800] = useMediaQuery("(max-width: 870px)");
  const [isLessThan500] = useMediaQuery("(max-width: 500px)");

  const [showBannerDiscord, setShowBannerDiscord] = useState(true);

  const handleLicenseContracts = () => {
    history.push({
      pathname: "/dashboard/contracts",
    });
  };
  const redirectDashboard = () => {
    history.push({
      pathname: "/dashboard",
    });
  };
  const handleJoinDiscord = useCallback(() => {
    window.open("https://discord.com/invite/a3bMXTywE3");
  }, []);

  const hanleCloseBannerDiscord = useCallback((e) => {
    e.stopPropagation();
    setShowBannerDiscord(false);
  }, []);

  return (
    <Box
      p={{ base: "16px", xl: "40px 80px 20px 40px" }}
      className="dashboard-components"
    >
      {showBannerDiscord && (
        <Flex
          background="linear-gradient(180deg, rgba(90, 102, 242, 0.15) 0%, rgba(177, 153, 235, 0.15) 100%)"
          padding="10px"
          border="1px solid #5B66F2"
          borderRadius="8px"
          justifyContent="space-between"
          alignItems="center"
          mb="20px"
          pl={{ base: "30px", md: "150px" }}
          cursor="pointer"
          onClick={handleJoinDiscord}
        >
          {isLessThan500 ? (
            <IconDiscordDashboardMobile />
          ) : isLessThan800 ? (
            <IconDiscordDashboardTablet />
          ) : (
            <IconDiscordDashboard />
          )}
          <Flex
            cursor="pointer"
            onClick={(e) => hanleCloseBannerDiscord(e)}
            w="35px"
            h="35px"
            borderRadius="full"
            border="1px solid #5B66F2"
            alignItems="center"
            justifyContent="center"
          >
            x
          </Flex>
        </Flex>
      )}
      {location?.state?.label === "licenses" ? (
        <>
          <Flex
            display={{ base: "none", md: "flex" }}
            alignItems="center"
            mb="32px"
          >
            <Box
              cursor="pointer"
              onClick={handleLicenseContracts}
              className="vector-button"
            >
              <Back />
            </Box>
            <Text fontSize="32px">{t("createNewLicense")}</Text>
          </Flex>
          <CreateNewLicense />
        </>
      ) : location?.state?.label === "licensesEdit" ? (
        <>
          <Flex
            alignItems="center"
            mb="32px"
            display={{ base: "none", md: "flex" }}
          >
            <Box
              cursor="pointer"
              onClick={handleLicenseContracts}
              className="vector-button"
            >
              <Back />
            </Box>
            <Text fontSize="32px">{t("editLicenses")}</Text>
          </Flex>
          <CreateNewLicense />
        </>
      ) : (
        <>
          <Flex
            alignItems="center"
            position="sticky"
            top="94px"
            background="#18181c"
            height="80px"
            zIndex={90}
          >
            <Box
              onClick={redirectDashboard}
              cursor="pointer"
              className="vector-button"
            >
              <Back />
            </Box>
            <Text
              className="fontCeraPro"
              fontSize={{ base: "26px", md: "32px" }}
            >
              {t("dashboard")}
            </Text>
          </Flex>
          <TabsPanels />
        </>
      )}
    </Box>
  );
}
