import { Box, Text } from "@chakra-ui/react";
import IconEmptyPopularLicense from "app/assets/icons/IconEmptyPopularLicense";
import { Licenses, TrackSoundKit } from "app/models";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import EmptyAnalytic from "../EmptyAnalytics";

interface PopularLicenseProps {
  analyticsTrack: TrackSoundKit[];
}

export default function PopularLicense({
  analyticsTrack,
}: PopularLicenseProps) {
  const { t } = useTranslation();

  let licenseMap: string[] = [];

  const licenses = (analyticsTrack || []).map(
    (item: TrackSoundKit) => item?.licenses
  );
  const licensesName = (licenses || []).map((license: Licenses[]) =>
    (license || []).map((name) => licenseMap.push(name.name))
  );
  const groupByLicense = (licenseMap || [])?.reduce((group: any, product) => {
    group[product] = group[product] ?? [];
    group[product].push(product);
    return group;
  }, {});

  const convertData = useMemo(
    () => [...new Map((licenseMap || []).map((item) => [item, item])).values()],
    [licenseMap]
  );
  const percentLicense = useMemo(() => {
    return convertData.map((item) => {
      return {
        name: item,
        y: (groupByLicense[item]?.length / licenseMap?.length) * 100,
      };
    });
  }, [convertData, groupByLicense, licenseMap?.length]);

  const options = {
    title: {
      text: "",
      style: {
        display: "none",
      },
    },
    chart: {
      display: "none",
      type: "pie",
      backgroundColor: "#18181c",
      plotBorderWidth: null,
      plotShadow: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        colors: ["#D4F70E", "#6A27F8", "#FF4B4B", "#1DA1F2"],
        showInLegend: true,
      },
    },

    credits: {
      enabled: false,
    },
    tooltip: {
      borderRadius: 10,
      borderWidth: 0,
    },
    series: [
      {
        name: "License percent",
        data: percentLicense,
        colors: ["#D4F70E", "#6A27F8", "#FF4B4B", "#1DA1F2"],
      },
    ],
  };

  return (
    <Box>
      <Text fontSize="16px" lineHeight="19px" mb="20px">
        {t("mostPopularLicense")}
      </Text>
      <>
        {percentLicense?.length <= 0 ? (
          <EmptyAnalytic
            title={t("youHaveNoRecentActivity")}
            icon={<IconEmptyPopularLicense />}
          />
        ) : (
          <>
            <Box>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </Box>
          </>
        )}
      </>
    </Box>
  );
}
