import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import AvatarMusicLoading from "app/assets/images/AvatarMusicLoading.svg";
import IconArrowDownNegotiation from "app/assets/icons/IconArrowDownNegotiation";
import { CollaborationsModel } from "app/models";
import {
  getStatusColor,
  roleText,
  TypeStatusCollaboration,
} from "constants/collaboratorType";
import { TRACK_TYPE } from "constants/trackTypes";
import { useTranslation } from "react-i18next";
import ModalCollaborations from "../ModalCollaborations";
import { useCollaborations } from "hooks/Collaborations/useCollaborations";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import { STATUS_COLOR } from "constants/negotiationType";
import AvatarUserLoading from "app/assets/images/UserAvatar.png";

interface CollaborationsProps {
  collaboration: CollaborationsModel;
  isExpired?: boolean;
}

export default function CollaborationsItem({
  collaboration,
  isExpired,
}: CollaborationsProps) {
  const { t } = useTranslation();
  const currentLicense = useMemo(
    () => collaboration?.licenses?.[0],
    [collaboration?.licenses]
  );

  const checkTypeCollaborations = !!collaboration?.trackId;

  const {
    showModalCollaboration,
    setShowModalCollaboration,
    selectCollaboration,
    setSelectCollaboration,
  } = useCollaborations();
  const { countdownTime } = useNegotiations();
  const handleSelectCollaborations = useCallback(() => {
    setShowModalCollaboration();
    setSelectCollaboration(collaboration);
  }, [collaboration, setSelectCollaboration, setShowModalCollaboration]);

  const timeCountDown = countdownTime(collaboration.updatedAt);
  const statusColor = getStatusColor(collaboration?.status);

  const renderStatus = useCallback(() => {
    const buttonStatus = {
      [STATUS_COLOR.PENDING]: t("negotiationsStatus.EXPIRED"),
      [STATUS_COLOR.ACCEPTED]: t("accepted"),
      [STATUS_COLOR.REJECTED]: t("rejected"),
    };

    return buttonStatus[collaboration?.status];
  }, [collaboration?.status, t]);

  const isPendingOrExpired =
    collaboration?.status === STATUS_COLOR.PENDING &&
    timeCountDown?.time === TypeStatusCollaboration.Expired;

  return (
    <Box mb="20px">
      <Flex
        background="#24252A"
        borderTopRightRadius="8px"
        borderTopLeftRadius="8px"
        borderRadius={{ md: "8px" }}
        padding={{
          base: "16px",
          md: "8px 20px 8px 20px",
          xl: "8px 30px 8px 40px",
        }}
        justifyContent="space-between"
        alignItems="center"
        position="relative"
        mb={{ base: "0px", lg: "20px" }}
        onClick={handleSelectCollaborations}
        cursor="pointer"
        borderBottom={{ base: "1px solid #32343A", md: "none" }}
      >
        <Flex alignItems="center">
          <Image
            src={
              checkTypeCollaborations
                ? collaboration?.trackId?.coverUrl
                : collaboration?.soundkitId?.coverUrl
            }
            fallbackSrc={AvatarMusicLoading}
            borderRadius="6px"
            objectFit="cover"
            w={{ base: "67px", md: "99px" }}
            h={{ base: "69px", md: "102px" }}
          />
          <Box ml="16px">
            <Box>
              <Text
                fontSize="16px"
                lineHeight="19px"
                fontWeight={{ base: "400", md: "600" }}
                w={{ base: "150px", md: "180px", lg: "320px" }}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {checkTypeCollaborations
                  ? collaboration?.trackId?.title
                  : collaboration?.soundkitId?.title}
              </Text>
              <Flex
                direction={{ base: "row", md: "column", lg: "row" }}
                alignItems={{ base: "start", lg: "center" }}
                mt="8px"
                flexWrap="wrap"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  height="20px"
                  borderRadius="4px"
                  padding="0px 5px"
                  mr="5px"
                  backgroundColor="#2b2e1b"
                >
                  <Text
                    _groupHover={{
                      fontSize: "15px",
                    }}
                    fontSize="12px"
                    lineHeight="14px"
                    color="#d4f70e"
                    whiteSpace="nowrap"
                  >
                    {t(
                      TRACK_TYPE[
                        checkTypeCollaborations
                          ? collaboration?.trackId?.type
                          : collaboration?.soundkitId?.type
                      ]
                    )}
                  </Text>
                </Box>
                <Text
                  fontSize={{ base: "12px", md: "14px" }}
                  lineHeight="17px"
                  fontWeight="400"
                  color="rgba(255, 255, 255, 0.2)"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  w={{ base: "240px", md: "200px", lg: "250px", xl: "300px" }}
                >
                  {collaboration?.user?.name} "The&nbsp;
                  {roleText(collaboration?.role)}",&nbsp;
                  {collaboration?.createdBy?.name}
                </Text>
              </Flex>
            </Box>
            {isExpired && (
              <Box
                display={{ base: "none", md: "block" }}
                position="absolute"
                right="30px"
                top="15px"
              >
                <Text
                  fontSize="12px"
                  lineHeight="14px"
                  color="rgba(255, 255, 255, 0.32)"
                >
                  {t("invieExpiredOn")}
                </Text>
                <Text
                  mt="5px"
                  fontSize="12px"
                  lineHeight="14px"
                  color="#ED3636"
                >
                  - {timeCountDown.time}
                </Text>
              </Box>
            )}

            <Flex
              mt="10px"
              alignItems="center"
              display={{ base: "none", md: "flex" }}
            >
              {!isExpired && (
                <Button
                  background={
                    isPendingOrExpired
                      ? "rgba(237, 54, 54, 0.1)"
                      : statusColor?.backgroundColor
                  }
                  borderRadius="24px"
                  height="24px"
                  fontSize="12px"
                  lineHeight="14px"
                  color={isPendingOrExpired ? "#ED3636" : statusColor?.color}
                  padding="0px 20px"
                  minW="100px"
                  mr="10px"
                >
                  {renderStatus()}
                </Button>
              )}
              {checkTypeCollaborations && (
                <Box display={{ base: "none", lg: "block" }}>
                  <Text
                    fontSize="12px"
                    lineHeight="14px"
                    fontWeight="400"
                    color="rgba(255, 255, 255, 0.51)"
                  >
                    {currentLicense?.exclusive ? "Exclusive" : "Non Exclusive"}
                    &nbsp;Contract
                  </Text>
                  <Text
                    mt="5px"
                    fontSize="12px"
                    lineHeight="14px"
                    fontWeight="400"
                    noOfLines={1}
                  >
                    {currentLicense?.name} License
                  </Text>
                </Box>
              )}
            </Flex>
          </Box>
        </Flex>
        <Box>
          <IconArrowDownNegotiation />
        </Box>

        <Image
          w="40px"
          h="40px"
          borderRadius="full"
          alt="User"
          src={collaboration?.user?.profileImage || AvatarUserLoading}
          fallbackSrc={AvatarUserLoading}
          position="absolute"
          right={{ base: "48px", lg: "80px" }}
          bottom="8px"
          display={{ base: "none", md: "block" }}
        />
        <Image
          w="40px"
          h="40px"
          borderRadius="full"
          alt="User"
          src={collaboration?.createdBy?.profileImage || AvatarUserLoading}
          fallbackSrc={AvatarUserLoading}
          position="absolute"
          right={{ base: "22px", lg: "50px" }}
          bottom="8px"
          display={{ base: "none", md: "block" }}
        />
      </Flex>

      <Flex
        background="#24252A"
        padding="16px"
        justifyContent="space-between"
        alignItems="center"
        display={{ base: "flex", md: "none" }}
      >
        <Flex
          alignItems="center"
          w="70%"
          overflow="hidden"
          justifyContent={isExpired ? "space-between" : "unset"}
        >
          {!isExpired && (
            <Button
              background={
                isPendingOrExpired
                  ? "rgba(237, 54, 54, 0.1)"
                  : statusColor?.backgroundColor
              }
              borderRadius="24px"
              height="24px"
              fontSize="12px"
              lineHeight="14px"
              color={isPendingOrExpired ? "#ED3636" : statusColor?.color}
              padding="0px 20px"
              minW="100px"
              mr="10px"
            >
              {renderStatus()}
            </Button>
          )}
          {checkTypeCollaborations && (
            <Box>
              <Text
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
                color="rgba(255, 255, 255, 0.51)"
                noOfLines={1}
              >
                {currentLicense?.exclusive ? "Exclusive" : "Non Exclusive"}
                &nbsp;Contract
              </Text>
              <Text
                noOfLines={1}
                mt="5px"
                fontSize="12px"
                lineHeight="14px"
                fontWeight="400"
              >
                {currentLicense?.name} License
              </Text>
            </Box>
          )}
          {isExpired && (
            <Box>
              <Text
                fontSize="12px"
                lineHeight="14px"
                color="rgba(255, 255, 255, 0.32)"
              >
                {t("invieExpiredOn")}
              </Text>
              <Text mt="5px" fontSize="12px" lineHeight="14px" color="#ED3636">
                - {timeCountDown.time}
              </Text>
            </Box>
          )}
        </Flex>

        <Flex>
          <Image
            w="40px"
            h="40px"
            borderRadius="full"
            alt="User"
            src={collaboration?.user?.profileImage || AvatarUserLoading}
            fallbackSrc={AvatarUserLoading}
            mr="-20px"
          />
          <Image
            w="40px"
            h="40px"
            borderRadius="full"
            alt="User"
            src={collaboration?.createdBy?.profileImage || AvatarUserLoading}
            fallbackSrc={AvatarUserLoading}
          />
        </Flex>
      </Flex>

      {showModalCollaboration && (
        <ModalCollaborations
          showModal={showModalCollaboration}
          setShowModal={setShowModalCollaboration}
          collaborationItem={selectCollaboration}
        />
      )}
    </Box>
  );
}
