import { Checkbox, Flex, Text, Box } from "@chakra-ui/react";
import React from "react";

interface CheckBoxProps {
  defaultChecked?: boolean;
  onChange?: (value: boolean) => void;
  title?: string;
  isChecked?: boolean;
  filledCheckbox?: boolean;
  isCreatePlaylist?: boolean;
}

export default function CheckBox({
  defaultChecked,
  onChange,
  title,
  isChecked,
  filledCheckbox = false,
  isCreatePlaylist = false,
  ...restProps
}: CheckBoxProps) {
  return (
    <Flex cursor="pointer">
      {!filledCheckbox ? (
        <Checkbox
          isChecked={isChecked}
          defaultChecked={defaultChecked}
          onChange={(e) => onChange && onChange(e.target.checked)}
          size="lg"
          _focusVisible={{ outline: "none" }}
          {...restProps}
        />
      ) : (
        <Flex
          w="20px"
          height="20px"
          borderRadius={isCreatePlaylist ? "100%" : "3px"}
          border="1px"
          borderStyle="solid"
          borderColor="#49494D"
          alignItems="center"
          justifyContent="center"
          backgroundColor={isChecked ? "rgba(219, 243, 82, 0.2)" : "#24252A"}
          onClick={() => onChange && onChange(!isChecked)}
        >
          {isChecked && (
            <Box
              borderRadius={isCreatePlaylist ? "100%" : "2px"}
              backgroundColor="#DBF352"
              width="10px"
              height="10px"
              boxShadow="0px 4px 8px #DBF352"
            />
          )}
        </Flex>
      )}

      {title && <Text ml="15px">{title}</Text>}
    </Flex>
  );
}
