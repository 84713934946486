import {
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { UserFollower } from "app/models";
import RenderFollowerUser from "app/pages/Profile/renderFollowerUser";
import React from "react";
import { useTranslation } from "react-i18next";

interface ModalShowFollowerUserProps {
  isShowModal: boolean;
  handleCloseModal: () => void;
  onSearchUserFollowers?: (e) => void;
  handleClickUser?: (value: string) => void;
  userFollowers?: UserFollower[];
}

export default function ModalShowFollowerUer({
  isShowModal,
  handleCloseModal,
  handleClickUser,
  onSearchUserFollowers,
  userFollowers,
}: ModalShowFollowerUserProps) {
  const { t } = useTranslation();

  return (
    <Modal colorScheme="black" isOpen={isShowModal} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent p="40px" pt="0px" pl="0px">
        <ModalHeader
          pb="6px"
          fontWeight="400px"
          fontSize="22px"
          color="#FFFFFF"
          pl="40px"
        >
          {t("Followers")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={handleCloseModal}
          right={3}
          top="15px"
          fontSize="20px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalBody p="0px" mt="20px">
          <RenderFollowerUser
            onSearchUserFollowers={onSearchUserFollowers}
            handleClickUser={handleClickUser}
            userFollowers={userFollowers}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
