import DiscountsService from "app/apis/discounts";
import RecentActionsService from "app/apis/recentActions";
import TracksService from "app/apis/tracks";
import UserService from "app/apis/users";
import { BulkOffers, UserFollower } from "app/models";
import { Dispatch } from "redux";
import { generateActionTypes } from "../actionTypes";

export const userRecentActions = generateActionTypes("USER-RECENT-ACTIONS");
export const userTracks = generateActionTypes("USER-TRACKS");
export const userSoundKits = generateActionTypes("USER-SOUNDKITS");
export const userBulkOffers = generateActionTypes("USER-BULK-OFFERS");
export const userFollowers = generateActionTypes("USER-FOLLOWERS");
export const updateLikeTrack = generateActionTypes("UPDATE_LIKE_TRACK");
export const updateLikeSoundKit = generateActionTypes("UPDATE_LIKE_SOUNDKIT");
export const updateUnLikeTrack = generateActionTypes("UPDATE_UNLIKE_TRACK");
export const updateUnLikeSoundKit = generateActionTypes(
  "UPDATE_UNLIKE_SOUNDKIT"
);
export const updateLikeTrackInRecentAction = generateActionTypes(
  "LIKE_TRACK_IN_RECENT_ACTION"
);
export const updateUnLikeTrackInRecentAction = generateActionTypes(
  "UNLIKE_TRACK_IN_RECENT_ACTION"
);
export const updateAddRepostsTrackInRecentAction = generateActionTypes(
  "ADD_REPOST_TRACK_IN_RECENT_ACTION"
);
export const updateAddRepostsTrack = generateActionTypes("ADD_REPOST_TRACK");
export const updateLikeSoundKitInRecentAction =
  generateActionTypes("ADD_LIKE_SOUNDKIT");
export const updateUnLikesSoundKitsInRecentAction = generateActionTypes(
  "UNLIKE_SOUNDKIT_IN_RECENT_ACTION"
);
export const updateRepostsSoundKitInRecentAction = generateActionTypes(
  "REPOST_SOUNDKIT_IN_RECENT_ACTION"
);
export const updateRepostSoundKits = generateActionTypes("REPOST_SOUNDKIT");

// --------------------------------------------------------------
// GET USER RECENT ACTIONS
// --------------------------------------------------------------

const getUserRecentRequest = (payload: any) => ({
  type: userRecentActions.REQUEST,
  payload: payload,
});

const getUserRecenteError = (error: any) => ({
  type: userRecentActions.ERROR,
  payload: { error },
});

const getUserRecentSuccess = (payload: any) => ({
  type: userRecentActions.SUCCESS,
  payload: { ...payload },
});

export const getUserRecentActions =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(getUserRecentRequest(payload));
    try {
      if (payload.userId) {
        const data = await RecentActionsService.getUserRecentActions(
          payload.page,
          payload.filterBy,
          payload.userId,
          payload.loggedInGuest
        );
        dispatch(getUserRecentSuccess(data));
      }
    } catch (error: any) {
      dispatch(getUserRecenteError(error.message));
    }
  };

// --------------------------------------------------------------
// GET USER TRACKS
// --------------------------------------------------------------

const getTracksUserRequest = (payload: any) => ({
  type: userTracks.REQUEST,
  payload: payload,
});

const getTracksUserError = (error: any) => ({
  type: userTracks.ERROR,
  payload: { error },
});

const getTracksUserSuccess = (payload: any) => ({
  type: userTracks.SUCCESS,
  payload: [...payload],
});

export const getTracksUser = (payload: any) => async (dispatch: Dispatch) => {
  dispatch(getTracksUserRequest(payload));
  try {
    if (payload.userId) {
      const data = await TracksService.getUserTracks(
        payload.userId,
        payload.page,
        payload.filterBy
      );
      dispatch(getTracksUserSuccess(data));
    }
  } catch (error: any) {
    dispatch(getTracksUserError(error.message));
  }
};

// --------------------------------------------------------------
// GET USER SOUND KITS
// --------------------------------------------------------------

const getSoundKitsUserRequest = (payload: any) => ({
  type: userSoundKits.REQUEST,
  payload: payload,
});

const getSoundKitsUserSuccess = (payload: any) => ({
  type: userSoundKits.SUCCESS,
  payload: [...payload],
});

const getSoundKitsUserError = (error: any) => ({
  type: userSoundKits.ERROR,
  payload: { error },
});

export const getSoundKitsUser =
  (payload: any) => async (dispatch: Dispatch) => {
    dispatch(getSoundKitsUserRequest(payload));
    try {
      if (payload.userId) {
        const data = await TracksService.getUserSoundKits(
          payload.userId,
          payload.page,
          payload.filterBy
        );

        dispatch(getSoundKitsUserSuccess(data));
      }
    } catch (error: any) {
      dispatch(getSoundKitsUserError(error.message));
    }
  };

// --------------------------------------------------------------
// GET USER BULK OFFERS
// --------------------------------------------------------------

const getUserBulkOffersRequest = () => ({
  type: userBulkOffers.REQUEST,
  payload: [],
});

const getUserBulkOffersSuccess = (payload: BulkOffers[]) => ({
  type: userBulkOffers.SUCCESS,
  payload: [...payload],
});

const getUserBulkOffersError = (error: any) => ({
  type: userBulkOffers.ERROR,
  payload: { error },
});

export const fetchUserBulkOffers =
  (userId: string) => async (dispatch: Dispatch) => {
    dispatch(getUserBulkOffersRequest());
    try {
      const data = await DiscountsService.getBulkOffers(userId);
      dispatch(getUserBulkOffersSuccess(data));
    } catch (error: any) {
      dispatch(getUserBulkOffersError(error.message));
    }
  };

// --------------------------------------------------------------
// GET USER FOLLOWERS
// --------------------------------------------------------------

const getUserFollowersRequest = () => ({
  type: userFollowers.REQUEST,
  payload: [],
});

const getUserFollowersSuccess = (payload: UserFollower[]) => ({
  type: userFollowers.SUCCESS,
  payload: [...payload],
});

const getUserFollowersError = (error: any) => ({
  type: userFollowers.ERROR,
  payload: { error },
});

export const fetchUserFollowers =
  (userId: string) => async (dispatch: Dispatch) => {
    dispatch(getUserFollowersRequest());
    try {
      const data = await UserService.fetchUserFollowers(userId);
      dispatch(getUserFollowersSuccess(data));
    } catch (error: any) {
      dispatch(getUserFollowersError(error.message));
    }
  };
const updateLikeTrackSuccess = (payload: { _id: string }) => ({
  type: updateLikeTrack.SUCCESS,
  payload: payload,
});

export const updateLikesTrack =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateLikeTrackSuccess(payload));
  };

const updateLikeTrackInRecentActionSuccess = (payload: { _id: string }) => ({
  type: updateLikeTrackInRecentAction.SUCCESS,
  payload: payload,
});

export const updateLikesTrackInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateLikeTrackInRecentActionSuccess(payload));
  };

const updateLikesSoundKitInRecentActionSuccess = (payload: {
  _id: string;
}) => ({
  type: updateLikeSoundKitInRecentAction.SUCCESS,
  payload: payload,
});

export const updateLikesSoundKitInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateLikesSoundKitInRecentActionSuccess(payload));
  };

const updateLikesSoundKitSuccess = (payload: { _id: string }) => ({
  type: updateLikeSoundKit.SUCCESS,
  payload: payload,
});

export const updateLikesSoundKit =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateLikesSoundKitSuccess(payload));
  };

const updateUnLikesTrackSuccess = (payload: { _id: string }) => ({
  type: updateUnLikeTrack.SUCCESS,
  payload: payload,
});

export const updateUnLikesTrack =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateUnLikesTrackSuccess(payload));
  };

const updateUnLikesSoundKitSuccess = (payload: { _id: string }) => ({
  type: updateUnLikeSoundKit.SUCCESS,
  payload: payload,
});

export const updateUnLikesSoundKit =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateUnLikesSoundKitSuccess(payload));
  };

const updateUnLikeTrackInRecentActionSuccess = (payload: { _id: string }) => ({
  type: updateUnLikeTrackInRecentAction.SUCCESS,
  payload: payload,
});

export const updateUnLikesTrackInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateUnLikeTrackInRecentActionSuccess(payload));
  };

const updateUnLikesSoundKitInRecentActionSuccess = (payload: {
  _id: string;
}) => ({
  type: updateUnLikesSoundKitsInRecentAction.SUCCESS,
  payload: payload,
});

export const updateUnLikesSoundKitInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateUnLikesSoundKitInRecentActionSuccess(payload));
  };

const updateAddRepostTrackInRecentActionSuccess = (payload: {
  _id: string;
}) => ({
  type: updateAddRepostsTrackInRecentAction.SUCCESS,
  payload: payload,
});

export const updateAddRepostTrackInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateAddRepostTrackInRecentActionSuccess(payload));
  };

const updateAddRepostTrackSuccess = (payload: { _id: string }) => ({
  type: updateAddRepostsTrack.SUCCESS,
  payload: payload,
});

export const updateAddRepostTrack =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateAddRepostTrackSuccess(payload));
  };

const updateRepostSoundKitInRecentActionSuccess = (payload: {
  _id: string;
}) => ({
  type: updateRepostsSoundKitInRecentAction.SUCCESS,
  payload: payload,
});

export const updateRepostSoundKitInRecentAction =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateRepostSoundKitInRecentActionSuccess(payload));
  };

const updateRepostSoundKitSuccess = (payload: { _id: string }) => ({
  type: updateRepostSoundKits.SUCCESS,
  payload: payload,
});

export const updateRepostSoundKit =
  (payload: { _id: string }) => async (dispatch: Dispatch) => {
    dispatch(updateRepostSoundKitSuccess(payload));
  };
