import { Box, Flex, SimpleGrid, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonReferrals() {
  return (
    <Box w="100%">
      <Flex
        display={{ base: "block", md: "flex" }}
        gap={{ base: "20px", md: "30px", lg: "40px", xl: "70px" }}
      >
        <Box w={{ base: "100%", md: "50%" }}>
          <Flex
            h="115px"
            borderRadius="8px"
            w="100%"
            mb="15px"
            background="#39393a"
            justifyContent="space-between"
            alignItems="center"
            padding={{ base: "40px 20px", lg: "40px 80px" }}
          >
            <Skeleton
              w="150px"
              h={{ base: "27px", md: "35px" }}
              borderRadius="15px"
            />
            <Skeleton
              w="50px"
              h={{ base: "22px", md: "30px" }}
              borderRadius="15px"
            />
          </Flex>
          <SimpleGrid columns={2} spacing={{ base: 3, md: 5 }} mb="16px">
            {Array.from({ length: 4 }).map((item) => (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                background="#39393a"
                borderRadius="6px"
                w="100%"
                h="115px"
              >
                <Skeleton h="20px" mb="7px" />
                <Skeleton h="20px" mb="7px" />
                <Skeleton h="20px" w="150px" mb="7px" />
              </Box>
            ))}
          </SimpleGrid>
          <Box
            mb="16px"
            background="#39393a"
            padding="20px 30px"
            borderRadius="6px"
          >
            <Skeleton w="200px" h="20px" />
          </Box>
          <Box
            background="#39393a"
            padding={{
              base: "40px 16px 30px 16px",
              md: "40px 20px",
              lg: "40px 35px 30px 35px",
            }}
            borderRadius="8px"
            mb="16px"
          >
            <Skeleton w="100%" h="22px" mb="20px" />
            <Skeleton w="90%" mb="15px" />
            <Skeleton w="93%" h="48px" mb="15px" borderRadius="6px" />
            <Skeleton w="80%" mb="20px" />
            <Skeleton w="90%" />
            <Skeleton w="75%" mb="20px" />
            <Skeleton w="80%" mb="20px" />
            <Skeleton w="90%" />
            <Skeleton w="75%" />
          </Box>
          <Box
            background="#39393a"
            padding={{
              base: "40px 16px 30px 16px",
              md: "40px 20px",
              lg: "40px 35px 30px 35px",
            }}
            borderRadius="8px"
            mb={{ base: "16px", md: "0px" }}
          >
            <Skeleton w="100%" h="22px" mb="20px" />
            <Box mb="20px">
              <Skeleton w="90%" />
              <Skeleton w="88%" />
              <Skeleton w="75%" />
            </Box>
            <Box mb="20px">
              <Skeleton w="90%" />
              <Skeleton w="88%" />
              <Skeleton w="75%" />
            </Box>
            <Box mb="20px">
              <Skeleton w="90%" />
              <Skeleton w="88%" />
              <Skeleton w="75%" />
            </Box>
            <Box mb="20px">
              <Skeleton w="90%" />
              <Skeleton w="88%" />
              <Skeleton w="75%" />
            </Box>
            <Skeleton w="165px" h="54px" mt="60px" />
          </Box>
        </Box>
        <Box w={{ base: "100%", md: "50%" }}>
          <Box
            background="#39393a"
            padding={{
              base: "40px 16px 30px 16px",
              md: "40px 20px",
              lg: "40px 35px 30px 35px",
            }}
            borderRadius="8px"
            mb="16px"
          >
            <Skeleton w="100%" h="22px" mb="30px" />
            <Skeleton w="80%" mb="20px" />
            <Skeleton w="93%" h="48px" mb="15px" borderRadius="6px" />
            <Skeleton w="300px" mb="20px" />
            <Flex mt="50px" justifyContent="center">
              {Array.from({ length: 3 }).map(() => (
                <Skeleton w="58px" h="58px" borderRadius="full" mr="25px" />
              ))}
            </Flex>
          </Box>

          <Box
            background="#39393a"
            padding={{
              base: "40px 16px 30px 16px",
              md: "40px 20px",
              lg: "40px 35px 30px 35px",
            }}
            borderRadius="8px"
            mb="16px"
          >
            <Skeleton w="100%" h="22px" mb="30px" />
            <Flex justifyContent="space-between">
              <Skeleton w="120px" />
              <Skeleton w="120px" />
            </Flex>
            {Array.from({ length: 10 }).map(() => (
              <>
                <Flex
                  padding="25px 0px"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Skeleton w="170px" />
                  <Skeleton w="50px" />
                </Flex>
                <Divider borderColor="#2D2E33" />
              </>
            ))}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
}
