import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ItemCustomers from "./itemCustomers";
import { SearchIcon } from "@chakra-ui/icons";
import { usePurchased } from "hooks/usePurchased";
import { customers } from "app/models";
import EmptyTrackSoundkit from "app/components/MySoundkits/emptyTrackSoundkit";
import SkeletonEmailCustomers from "./skeletonItemCustomers";
import { heightLoadMore } from "utils/convertTracks";
import { FadeLoader } from "react-spinners";

export default function CustomersPage() {
  const { t } = useTranslation();
  const {
    getEmailCustomers,
    emailCustomers,
    handleSearchText,
    debouncedSearchText,
    isLoadingEmailCustomers,
    fetchEmailCustomersOptions,
  } = usePurchased();

  useEffect(() => {
    getEmailCustomers({ page: 1 });
  }, [getEmailCustomers]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(fetchEmailCustomersOptions, e)) {
        getEmailCustomers({
          page: 1 + fetchEmailCustomersOptions.page,
          loadingMore: true,
        });
      }
    },
    [fetchEmailCustomersOptions, getEmailCustomers]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const searchEmailCustomers = useMemo(() => {
    return (emailCustomers || []).filter((item: customers) => {
      return (
        item.name?.toLowerCase().indexOf(debouncedSearchText.toLowerCase()) !==
        -1
      );
    });
  }, [debouncedSearchText, emailCustomers]);

  return (
    <Box>
      <Box
        position="sticky"
        top="228px"
        zIndex={90}
        height="95px"
        background="#18181c"
      >
        <Text fontSize="32px" mr="15px" mb="20px" mt="10px">
          {t("myCustomers")}
        </Text>
        <Flex justifyContent="space-between" width="100%">
          <Text
            textDecoration="underline"
            textDecorationColor="#D4F70E"
            textUnderlineOffset="8px"
          >
            {t("freeDownloads")}
          </Text>

          <Flex
            alignItems="center"
            paddingLeft="5px"
            borderTopLeftRadius="6px"
            borderTopRightRadius="6px"
          >
            <Text mr="15px" color="#49494D">
              {t("filter")}
            </Text>
            <InputGroup
              border="none"
              borderLeft="1px solid rgba(255, 255, 255, 0.04)"
            >
              <InputLeftElement children={<SearchIcon />} />
              <Input
                type="tel"
                placeholder={t("searchEmail")}
                border="0px"
                p="0px 0px 0px 40px"
                _placeholder={{ color: "#FFFFFF" }}
                fontSize="14px"
                cursor="pointer"
                onChange={handleSearchText}
              />
            </InputGroup>
          </Flex>
        </Flex>
      </Box>

      {isLoadingEmailCustomers && !fetchEmailCustomersOptions.loadingMore ? (
        <SkeletonEmailCustomers />
      ) : searchEmailCustomers?.length > 0 ? (
        <ItemCustomers emailCustomers={searchEmailCustomers} />
      ) : (
        <EmptyTrackSoundkit />
      )}
      <Box display="flex" justifyContent="center">
        <FadeLoader
          color={"#d4f70e"}
          loading={fetchEmailCustomersOptions.loadingMore}
        />
      </Box>
    </Box>
  );
}
