import { Box, Flex, Text } from "@chakra-ui/react";
import Back from "app/assets/icons/back";
import CreatePromoCode from "app/components/CreatePromoCode";
import { useAddPromoCode } from "hooks/promoCode/useAddPromoCode";
import React, { useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import NoUploadTrack from "app/components/AddTrack/noUploadTrack";

export default function AddPromoCode() {
  const { t } = useTranslation();
  const history = useHistory();
  const { methods, getLicenseAndContracts, ...rest } = useAddPromoCode();
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  useEffect(() => {
    getLicenseAndContracts();
  }, [getLicenseAndContracts]);

  const goBack = () => {
    history.push("/dashboard/discounts");
  };

  return (
    <FormProvider {...methods}>
      <Box
        p={{ base: "40px 16px", md: "40px", lg: "40px 80px" }}
        className="dashboard-components"
      >
        <Flex
          alignItems="center"
          mb="32px"
          display={{ base: "none", md: "flex" }}
        >
          <Box
            cursor="pointer"
            onClick={goBack}
            className="vector-button"
            mr={{ base: "10px", md: "24px" }}
          >
            <Back />
          </Box>
          <Text fontSize={{ base: "24px", md: "32px" }}>
            {rest?.idEdit ? t("editExistPromoCode") : t("NewPromoCode")}
          </Text>
        </Flex>
        {checkPlanSubscription ? (
          <NoUploadTrack text={t("upgradeYourPlanToContinue")} />
        ) : (
          <CreatePromoCode {...rest} />
        )}
      </Box>
    </FormProvider>
  );
}
