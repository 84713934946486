import { useToggle } from "hooks/useToggle";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { selectorPlaylist } from "app/redux/selectors/playlist";
import { yupResolver } from "@hookform/resolvers/yup";
import formSchemaPlaylist from "./formSchema";
import { useCallback, useState, useMemo, ChangeEvent } from "react";
import {
  getPlaylists,
  getIndividualPlaylist,
  editPlaylist as updatePlaylist,
  createPlaylist,
  deletePlaylists,
  getPlaylistsFollowing,
  getPlaylistsInProfile,
} from "app/redux/actions/playlist";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
import { useDebounce } from "hooks/useDebounce";
import { ShortLinkModel } from "app/models";

export const usePlaylists = () => {
  const dispatch = useDispatch();
  const [showModalCreatePlaylist, setShowModalCreatePlaylist] = useToggle();
  const [showModalAddToPlaylist, setShowModalAddToPlaylist] = useToggle();
  const [showModalShareLinkPlaylist, setShowModalShareLinkPlaylist] =
    useToggle();
  const [playlistItem, setPlaylistItem] = useState({});
  const [editPlaylist, setEditPlaylist] = useState(false);
  const [trackId, setTrackId] = useState("");
  const [trackPrivate, setTrackPrivate] = useState(false);
  const [choosePlaylist, setChoosePlaylist] = useState<string[]>([]);
  const [isSelectAll, setIsSelectAll] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [shortLinkPlaylist, setShortLinkPlaylist] = useState<ShortLinkModel>({
    originalURL: "",
    shortURL: "",
  });

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([getPlaylists.typePrefix], state)
  );
  const isLoadingGetPlaylistsInProfile = useSelector((state: RootState) =>
    isLoadingSelector([getPlaylistsInProfile.typePrefix], state)
  );
  const isLoadingGetPlaylistsFollowing = useSelector((state: RootState) =>
    isLoadingSelector([getPlaylistsFollowing.typePrefix], state)
  );
  const isLoadingIndividualPlaylist = useSelector((state: RootState) =>
    isLoadingSelector([getIndividualPlaylist.typePrefix], state)
  );
  const isLoadingEditPlaylist = useSelector((state: RootState) =>
    isLoadingSelector([updatePlaylist.typePrefix], state)
  );
  const isLoadingCreatePlaylist = useSelector((state: RootState) =>
    isLoadingSelector([createPlaylist.typePrefix], state)
  );
  const isLoadingDeletePlaylist = useSelector((state: RootState) =>
    isLoadingSelector([deletePlaylists.typePrefix], state)
  );
  const {
    selectedPlaylist,
    playlists,
    playlistIndividual,
    playlistsFollowing,
    playlistsInProfile,
  } = useSelector(selectorPlaylist);

  const handleShowModalAddTrack = useCallback(
    (id: string, isPrivate?: boolean) => {
      setShowModalAddToPlaylist();
      setTrackId(id);
      setTrackPrivate(isPrivate || false);
    },
    [setShowModalAddToPlaylist, setTrackId]
  );

  const handleGetPlaylists = useCallback(
    (visibility?: string) => {
      dispatch(getPlaylists(visibility));
    },
    [dispatch]
  );

  const handleGetPlaylistsInProfile = useCallback(
    (userId?: string) => {
      dispatch(getPlaylistsInProfile(userId));
    },
    [dispatch]
  );

  const handleGetPlaylistsFollowing = useCallback(() => {
    dispatch(getPlaylistsFollowing());
  }, [dispatch]);

  const handleCheckbox = useCallback(
    (id) => {
      const isChecked = choosePlaylist.find((item: string) => item === id);
      if (isChecked) {
        setChoosePlaylist(choosePlaylist.filter((item) => item !== id));
      } else {
        setChoosePlaylist([...choosePlaylist, id]);
      }
    },
    [choosePlaylist]
  );

  const playlistsId = useMemo(() => {
    return (playlists || [])?.map((item) => item._id || "");
  }, [playlists]);

  const handleSelectAll = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        setIsSelectAll(true);
        setChoosePlaylist(playlistsId);
      } else {
        setChoosePlaylist([]);
        setIsSelectAll(false);
      }
    },
    [playlistsId]
  );

  const handleRemovePlaylist = useCallback(async () => {
    await dispatch(deletePlaylists(choosePlaylist));
    setChoosePlaylist([]);
  }, [choosePlaylist, dispatch]);

  const handleSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const debouncedSearchText = useDebounce<string>(searchText, 200);

  const methods = useForm({
    values: selectedPlaylist,
    resolver: yupResolver(formSchemaPlaylist()),
  });
  return {
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    methods,
    handleGetPlaylists,
    playlists,
    isLoading,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    trackId,
    setTrackId,
    handleShowModalAddTrack,
    playlistIndividual,
    isLoadingIndividualPlaylist,
    setEditPlaylist,
    editPlaylist,
    selectedPlaylist,
    isLoadingCreatePlaylist,
    isLoadingEditPlaylist,
    choosePlaylist,
    handleCheckbox,
    handleSelectAll,
    isSelectAll,
    handleRemovePlaylist,
    isLoadingDeletePlaylist,
    handleSearchText,
    debouncedSearchText,
    handleGetPlaylistsFollowing,
    isLoadingGetPlaylistsFollowing,
    playlistsFollowing,
    playlistsInProfile,
    isLoadingGetPlaylistsInProfile,
    handleGetPlaylistsInProfile,
    showModalShareLinkPlaylist,
    setShowModalShareLinkPlaylist,
    playlistItem,
    setPlaylistItem,
    setShortLinkPlaylist,
    shortLinkPlaylist,
    trackPrivate,
  };
};
