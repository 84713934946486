import { Box, Flex, Text } from "@chakra-ui/react";
import { Back } from "app/assets/icons";
import NotificationDetail from "app/components/NotificationDetail";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { useHistory } from "react-router-dom";

export default function NotificationsComponents() {
  const { t } = useTranslation();
  const history = useHistory();
  const handleBackFeed = useCallback(() => {
    history.push({
      pathname: "/home",
    });
  }, [history]);
  return (
    <Box
      padding={{ base: "40px 16px", md: "40px 40px", xl: "40px 80px" }}
      className="notification-components"
    >
      <Flex alignItems="center" mb="32px">
        <Box
          cursor="pointer"
          className="vector-button"
          onClick={handleBackFeed}
        >
          <Back />
        </Box>
        <Text fontSize={{ base: "24px", md: "32px" }}>
          {t("Notifications")}
        </Text>
      </Flex>
      <NotificationDetail />
    </Box>
  );
}
