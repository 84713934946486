import { createReducer } from "@reduxjs/toolkit";
import * as referralsActions from "app/redux/actions/referrals";
import { generalInfoReferrals, Referrals } from "app/models";

export interface ReferralsState {
  generalReferrals: generalInfoReferrals;
  referrals: Referrals[];
}

const initialState: ReferralsState = {
  generalReferrals: {
    totalReach: 0,
    totalUsersReferred: 0,
    totalConvertedReferrals: 0,
    totalSuccessfulReferrals: 0,
    pendingInvites: 0,
    totalBalance: 0,
  },
  referrals: [],
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(
      referralsActions.getGeneralInfoReferrals.fulfilled,
      (state, action) => {
        return {
          ...state,
          generalReferrals: action.payload,
        };
      }
    )
    .addCase(referralsActions.getRecentReferrals.fulfilled, (state, action) => {
      return {
        ...state,
        referrals: action.payload,
      };
    })
);
