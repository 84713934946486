import { Skeleton as ChakraSkeleton, SkeletonProps } from "@chakra-ui/react";
import React from "react";

interface Props extends SkeletonProps {}

export default function Skeleton({
  w = "60px",
  h = "17px",
  mr = "10px",
  mb = "5px",
  borderRadius = "10px",
  background = "#535353",
  ...props
}: Props) {
  return (
    <ChakraSkeleton
      {...props}
      h={h}
      w={w}
      mr={mr}
      mb={mb}
      borderRadius={borderRadius}
      startColor={`${background}`}
      endColor={`#39393a`}
    />
  );
}
