import React from "react";

const Tick = () => {
  return (
    <svg
      width="12"
      height="9"
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1061 2.12099L5.45011 7.77779C5.05959 8.16837 4.42639 8.16839 4.03584 7.77784L1.207 4.949C0.816535 4.55853 0.816534 3.92547 1.207 3.535C1.59747 3.14454 2.23053 3.14454 2.621 3.535L4.03583 4.94983C4.42638 5.34038 5.05959 5.34035 5.45011 4.94977L9.69201 0.707119C10.0825 0.316598 10.7156 0.31657 11.1061 0.707056C11.4965 1.0975 11.4965 1.73052 11.1061 2.12099Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default Tick;
