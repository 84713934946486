import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  useBoolean,
} from "@chakra-ui/react";
import { BulkOffers } from "app/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaEllipsisH } from "react-icons/fa";
import "./styles.scss";
import { ACTION_TYPE } from "./actionType";
import BackgroundDrop from "../BackgroundDrop";

interface IBulkOfferItemProps {
  bulkOfferItem: BulkOffers;
  onChangeActiveBulkOffer: (id: string) => void;
  onModalBulkOferOpen: (type: string, item: BulkOffers) => void;
}

export default function BulkOfferItem({
  bulkOfferItem,
  onChangeActiveBulkOffer,
  onModalBulkOferOpen,
}: IBulkOfferItemProps) {
  const { t } = useTranslation();
  const [showPopove, setShowPopove] = useBoolean();

  return (
    <Flex
      py="22px"
      justifyContent="space-between"
      alignItems="center"
      borderBottom="1px solid rgba(197, 221, 92, 0.04)"
    >
      <Flex className="custom-switch">
        <Switch
          isChecked={bulkOfferItem.active}
          colorScheme="#D8FD01"
          size="lg"
          onChange={() => onChangeActiveBulkOffer(bulkOfferItem?._id)}
        />
        <Box ml="22px">
          <Text
            fontSize="14px"
            fontWeight="bold"
            pb="3px"
            lineHeight="16px"
            fontFamily="Cera Pro Medium"
          >
            {bulkOfferItem.name}
          </Text>
          <Text
            fontSize="12px"
            lineHeight="16px"
            opacity="0.2"
            fontFamily="Cera Pro Regular"
          >
            {(bulkOfferItem?.licenses || []).map(
              (license, index) => `${index !== 0 ? ", " : ""}${license.name}`
            )}
          </Text>
        </Box>
      </Flex>
      <Box display="flex">
        {showPopove && <BackgroundDrop />}
        <Popover
          placement="top"
          isOpen={showPopove}
          closeOnBlur={true}
          onOpen={setShowPopove.on}
          onClose={setShowPopove.off}
        >
          <PopoverTrigger>
            <Box
              cursor="pointer"
              padding="6px 8px !important"
              _hover={{
                background: "#24252A",
                padding: "6px 8px",
                border: "1px solid #38383D",
                borderRadius: "8px",
              }}
              borderColor="#24252A"
              borderWidth="1px"
            >
              <FaEllipsisH />
            </Box>
          </PopoverTrigger>
          <Box zIndex={1200000}>
            <PopoverContent
              backgroundColor="#25252A"
              border="none"
              mt="45px"
              w="100%"
            >
              <PopoverArrow
                backgroundColor="#25252A "
                borderColor="#25252A "
                boxShadow="unset !important"
              />
              <PopoverHeader
                fontSize="14px"
                textAlign={"center"}
                justifyContent="center"
                borderBottom="none"
                display="flex"
              >
                {t("Modifying")}
              </PopoverHeader>
              <PopoverBody>
                <Flex flexDirection="column">
                  <Button
                    _hover={{
                      background: "#2D2E33",
                      border: "1px solid #37383C",
                    }}
                    _active={{
                      background: "#2D2E33",
                    }}
                    borderColor="#37383C"
                    color="white"
                    bg="#25252A"
                    m=" 3px 5px "
                    fontSize="14px"
                    fontWeight="500"
                    borderRadius="4px"
                    onClick={() =>
                      onModalBulkOferOpen(ACTION_TYPE.EDIT, bulkOfferItem)
                    }
                  >
                    {t("Edit")}
                  </Button>
                  <Button
                    _hover={{
                      background: "#2C262A",
                      border: "1px solid #ED363680",
                    }}
                    _active={{ background: "#2C262A" }}
                    color="red"
                    borderColor="#ED363680"
                    bg="#25252A"
                    m=" 3px 5px "
                    fontSize="14px"
                    fontWeight="500"
                    borderRadius="4px"
                    onClick={() =>
                      onModalBulkOferOpen(ACTION_TYPE.DELETE, bulkOfferItem)
                    }
                  >
                    {t("delete")}
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Popover>
      </Box>
    </Flex>
  );
}
