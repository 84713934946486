import React from "react";

const IconBandlabDaw = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_13_202508)">
        <path
          d="M6.345 10.26C7.49 10.26 8.925 9.205 8.925 7.77C8.925 7.35 8.835 6.935 8.66 6.55L6.67 1.685H10.01L9.13 0H4.365L7.095 6.745C5.92999 7 4.865 7.91 4.865 8.96C4.865 9.72 5.415 10.26 6.345 10.26C6.36096 10.26 6.345 10.26 9.32 3.55H11.27L13.35 7.14C13.7509 7.85852 13.9738 8.66263 14 9.485C14 12.11 11.93 14 9 14H5C2.065 14 0 12.11 0 9.485C0.0250017 8.66 0.249999 7.86 0.650002 7.135L2.725 3.54999H4.675L2.24 7.78499C1.94335 8.29297 1.77835 8.86703 1.76 9.45499C1.76 11.125 2.9 12.315 5.06 12.315H8.94C11.09 12.315 12.24 11.125 12.24 9.45499C12.2201 8.86659 12.0534 8.2925 11.755 7.78499L9.32 3.55"
          fill="#ED3636"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_202508">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default IconBandlabDaw;
