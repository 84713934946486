import {
  Box,
  Checkbox,
  FormControl,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  Textarea,
} from "@chakra-ui/react";
import classnames from "classnames";
import React, { useCallback } from "react";
import { Control, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";

export type InputType =
  | "text"
  | "password"
  | "number"
  | "select"
  | "textarea"
  | "datetime-local"
  | "checkbox"
  | "group";

interface TextInputProps {
  type: InputType;
  control?: Control<any>;
  name: string;
  label?: string;
  border?: string;
  backgroundColor?: string;
  errors?: any;
  placeholder?: string;
  disabled?: boolean;
  labelClassName?: string;
  className?: string;
  defaultValue?: string;
  handleChangeText?: any;
  value?: string;
  maxLength?: number;
  handleClick?: () => void;
  isSoundkit?: boolean;
  textAlign?: string;
  height?: string;
}

export default function TextInput({
  type = "text",
  label,
  border,
  backgroundColor,
  name,
  control,
  errors = {},
  className,
  placeholder,
  disabled,
  labelClassName,
  value,
  defaultValue,
  maxLength,
  handleClick,
  isSoundkit,
  textAlign,
  height = "auto",
  ...props
}: TextInputProps) {
  const { t } = useTranslation();
  const renderInputByType = useCallback(
    (type: InputType, field) => {
      if (type === "textarea") {
        return (
          <Textarea
            fontFamily="Cera Pro Regular"
            className="form-input-textarea"
            defaultValue={defaultValue}
            placeholder={placeholder}
            fontSize={{ base: "14px", md: "16px" }}
            border={border}
            backgroundColor={backgroundColor}
            value={value}
            {...field}
            {...props}
            disabled={disabled}
            _placeholder={{
              color: "#535353",
              fontSize: { base: "14px", md: "16px" },
            }}
            px={{ base: "8px", md: "12px", xl: "16px" }}
          />
        );
      }
      if (type === "checkbox") {
        return (
          <Checkbox
            className="form-input"
            defaultValue={field?.value}
            {...field}
            {...props}
          />
        );
      }
      if (type === "group") {
        return (
          <InputGroup
            className={classnames("form-input", className)}
            onBlur={handleClick}
            mt="10px"
          >
            <InputLeftAddon
              backgroundColor={backgroundColor}
              border="none"
              paddingTop="1px"
              children={field.value ? "$" : ""}
              paddingRight="3px"
              className={classnames("form-input", className)}
            />
            <Input
              type="number"
              className={classnames("form-input", className)}
              defaultValue={defaultValue}
              border={border}
              backgroundColor={backgroundColor}
              value={value}
              {...field}
              {...props}
              placeholder={placeholder}
              disabled={disabled}
              fontSize={{ base: "14px", md: "16px" }}
              _placeholder={{
                fontSize: { base: "14px", md: "16px" },
                color: "#535353",
              }}
              _focus={{ boxShadow: "none" }}
              maxLength={maxLength}
              padding="0px"
            />
          </InputGroup>
        );
      }
      return (
        <>
          <Input
            mt={1}
            fontFamily="Cera Pro Regular"
            type={type}
            className={classnames("form-input", className)}
            defaultValue={defaultValue}
            border={border}
            backgroundColor={backgroundColor}
            value={value}
            {...field}
            {...props}
            placeholder={placeholder}
            disabled={disabled}
            fontSize={{ base: "14px", md: "16px" }}
            _placeholder={{
              fontSize: { base: "14px", md: "16px" },
              color: "#535353",
            }}
            _focus={{ boxShadow: "none" }}
            maxLength={maxLength}
            textAlign={textAlign}
            px={{ base: "8px", md: "12px", xl: "16px" }}
          />
        </>
      );
    },
    [
      className,
      defaultValue,
      border,
      backgroundColor,
      value,
      props,
      placeholder,
      disabled,
      maxLength,
      handleClick,
      isSoundkit,
    ]
  );

  return (
    <Box
      height={height}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
    >
      {label && (
        <label
          className={classnames("form-label", labelClassName)}
          style={{ fontFamily: "Cera Pro Regular" }}
        >
          {label}
        </label>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <FormControl
            name={name}
            className={classnames("form-item", className)}
          >
            {renderInputByType(type, field)}
            {errors[name] && (
              <Text style={{ color: "red" }}>{t(errors[name].message)}</Text>
            )}
          </FormControl>
        )}
      />
    </Box>
  );
}
