export const STATUS = {
  ERROR: "ERROR",
  LOADING: "LOADING",
  NOT_STARTED: "NOT_STARTED",
  SUCCESS: "SUCCESS",
};

export const NOTIFICATION_TYPE = {
  ALL: "all",
  UNREAD: "unread",
};

export const CHARACTER_TITLE = {
  MAXIMUM_CHARACTERS: 70,
};

export const orderSub = [
  "Track Upload Limit",
  "Bulk Track Upload",
  "Sound Kit Upload",
  "Payout",
  "Seller Fee",
  "Accept Credit Card and Paypal",
  "Sales Automation",
  "Basic Analytics",
  "Premium Analytics",
  "Accept Offers & Negotiate",
  "Schedule Uploads",
  "Collaborations With Revenue Splits",
  "Re Upload",
  "Custom Voice Tag",
  "Email Capture Marketing Tool",
  "Sold Licenses Monitoring",
  "Send private monthtly messages",
  "Create Playlists",
  "E-Contracts Agreements",
  "Free Demo Downloads",
  "Promo Codes",
  "Bulk Offers",
  "Exclusive Tracks Link Sharing",
  "Third-Party Marketing Add-Ons",
];
