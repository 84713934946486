import { createReducer, isAnyOf } from "@reduxjs/toolkit";
import { Negotiations, TrackSoundKit } from "app/models";
import * as negotiationActions from "app/redux/actions/negotiations";

export interface InitNegotiationsState {
  activeNegotiations: Negotiations;
  inactiveNegotiations: Negotiations;
  trackNegotiation: TrackSoundKit;
  isLoading: boolean;
}

const initialState = {
  activeNegotiations: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      refreshing: false,
      hasMore: true,
    },
  },
  inactiveNegotiations: {
    items: [],
    options: {
      filterBy: "",
      page: 1,
      loadingMore: false,
      refreshing: false,
      hasMore: true,
    },
  },
  trackNegotiation: null,
  isLoading: false,
  negotiation: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(negotiationActions.getNegotiations.fulfilled, (state, action) => {
      if (action.meta.arg.isActive) {
        return {
          ...state,
          activeNegotiations: {
            ...state.activeNegotiations,
            items: action.payload,
          },
        };
      }
      return {
        ...state,
        inactiveNegotiations: {
          ...state.inactiveNegotiations,
          items: action.payload,
        },
      };
    })
    .addCase(negotiationActions.selectTrackNegotiation, (state, action) => {
      return {
        ...state,
        trackNegotiation: action.payload,
      };
    })
    .addCase(negotiationActions.createNegotiation.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(negotiationActions.getNegotiation.fulfilled, (state, action) => {
      return {
        ...state,
        negotiation: action.payload[0],
      };
    })
    .addCase(
      negotiationActions.createNegotiation.fulfilled,
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    )
    .addCase(
      negotiationActions.cancelOrRejectNegotiation.pending,
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    )
    .addCase(
      negotiationActions.cancelOrRejectNegotiation.fulfilled,
      (state, action) => {
        const { inactiveNegotiations, activeNegotiations } = state;
        const { negotiation, negotiations } = action.payload;
        const selectedNegotiation = (negotiations || []).find(
          (negotiationItem: Negotiations) =>
            negotiation?._id === negotiationItem?._id
        );
        const newActiveNegotiations = activeNegotiations.items.filter(
          (negotiationItem: Negotiations) =>
            negotiationItem?._id !== negotiation?._id
        );
        return {
          ...state,
          negotiation: selectedNegotiation,
          inactiveNegotiations: {
            ...inactiveNegotiations,
            items: negotiations,
          },
          activeNegotiations: {
            ...activeNegotiations,
            items: newActiveNegotiations,
          },
          isLoading: false,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        negotiationActions.acceptNegotiation.pending,
        negotiationActions.counterofferNegotiation.pending,
        negotiationActions.acceptCounteroffer.pending
      ),
      (state, action) => {
        return {
          ...state,
          isLoading: true,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        negotiationActions.acceptNegotiation.fulfilled,
        negotiationActions.counterofferNegotiation.fulfilled,
        negotiationActions.acceptCounteroffer.fulfilled
      ),
      (state, action) => {
        const { activeNegotiations } = state;
        const { negotiation, negotiations } = action.payload;
        const selectedNegotiation = (negotiations || []).find(
          (negotiationItem: Negotiations) =>
            negotiation?._id === negotiationItem?._id
        );
        return {
          ...state,
          negotiation: selectedNegotiation,
          activeNegotiations: {
            ...activeNegotiations,
            items: negotiations,
          },
          isLoading: false,
        };
      }
    )
    .addMatcher(
      isAnyOf(
        negotiationActions.acceptNegotiation.rejected,
        negotiationActions.counterofferNegotiation.rejected,
        negotiationActions.acceptCounteroffer.rejected
      ),
      (state, action) => {
        return {
          ...state,
          isLoading: false,
        };
      }
    )
);
