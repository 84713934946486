import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { SearchIcon } from "app/assets/icons";
import classNames from "classnames";
import { useMyTrack } from "hooks/track/useMyTrack";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import MyDraftsTrack from "./MyDraftsTrack";
import MyPendingTracks from "./MyPendingTracks";
import MyPrivateTrack from "./MyPrivateTrack";
import MyReleasedTrack from "./MyReleasedTrack";
import MyScheduleTrack from "./MyScheduleTrack";
import "./styles.scss";
import SkeletonMyTrackNew from "../Skeleton/skeletonMyTrackNew";
import SkeletonMyTrackNewMobile from "../Skeleton/skeletonMyTrackNewMobile";

export default function MyTrackTab() {
  const { t } = useTranslation();
  const columnHeader = [
    { label: t("trackNo") },
    { label: t("trackName") },
    { label: t("licenseAndContract") },
    { label: t("dateReleased") },
    { label: t("fileDelivery") },
    { label: t("actions") },
  ];
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const { path } = useRouteMatch();
  const location = useLocation();
  const {
    isLoading,
    handleSearchText,
    debouncedSearchText,
    selectCheckbox,
    handleCheckbox,
    handleRemoveTrack,
    handleSelectAll,
    setSelectCheckbox,
    isSelectAll,
    setIsSelectAll,
  } = useMyTrack();

  const myTrackRoutes = [
    {
      name: "released",
      exact: true,
      value: "",
      component: (
        <MyReleasedTrack
          selectCheckbox={selectCheckbox}
          handleCheckbox={handleCheckbox}
          searchTextItem={debouncedSearchText}
        />
      ),
    },
    {
      name: "schedule",
      value: "/my-schedule-track",
      component: (
        <MyScheduleTrack
          searchTextItem={debouncedSearchText}
          selectCheckbox={selectCheckbox}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      name: "private",
      value: "/my-private-track",
      component: (
        <MyPrivateTrack
          searchTextItem={debouncedSearchText}
          selectCheckbox={selectCheckbox}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      name: "drafts",
      value: "/my-draft-track",
      component: (
        <MyDraftsTrack
          searchTextItem={debouncedSearchText}
          selectCheckbox={selectCheckbox}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
    {
      name: "pending",
      value: "/my-pending-track",
      component: (
        <MyPendingTracks
          searchTextItem={debouncedSearchText}
          selectCheckbox={selectCheckbox}
          handleCheckbox={handleCheckbox}
        />
      ),
    },
  ];

  const isActiveTrackRoute = useCallback(
    (routeName) => {
      return location.pathname === routeName;
    },
    [location.pathname]
  );
  const routerType = location.pathname.split("/").pop();

  const handleRemoveCheckBoxAll = useCallback(() => {
    setSelectCheckbox([]);
    setIsSelectAll(false);
  }, [setIsSelectAll, setSelectCheckbox]);

  return (
    <Box className="components-mytrack-item">
      <Flex
        alignItems="center"
        position="sticky"
        top="228px"
        zIndex={90}
        height="90px"
        background="#18181c"
      >
        <Text fontSize="32px" mr="15px">
          {t("myTrack")}
        </Text>
      </Flex>

      <Box w="100%">
        <Box position="sticky" top="317px" background="#18181c" zIndex={90}>
          <Flex
            direction={{ base: "column", md: "row" }}
            justifyContent="space-between"
            gap={{ base: "10px", md: "0px" }}
          >
            <Flex>
              {myTrackRoutes.map((item, index) => (
                <Link key={index} to={`${path}${item.value}`}>
                  <Text
                    padding="5px 10px"
                    cursor="pointer"
                    color="#49494D"
                    fontSize="16px"
                    lineHeight="29px"
                    className={classNames({
                      "active-my-track": isActiveTrackRoute(
                        `${path}${item.value}`
                      ),
                    })}
                    onClick={handleRemoveCheckBoxAll}
                    _hover={{
                      border: "1px solid #38383D",
                      background: "#222227 !important",
                      borderRadius: "6px",
                    }}
                  >
                    {t(item.name)}
                  </Text>
                </Link>
              ))}
            </Flex>

            <Flex
              alignItems="center"
              paddingLeft="5px"
              borderTopLeftRadius="6px"
              borderTopRightRadius="6px"
            >
              <Text mr="15px" color="#49494D">
                {t("filter")}
              </Text>
              <InputGroup
                border="none"
                borderLeft="1px solid rgba(255, 255, 255, 0.04)"
              >
                <InputLeftElement children={<SearchIcon />} />
                <Input
                  type="tel"
                  placeholder={t("searchTrack")}
                  border="0px"
                  p="0px 0px 0px 40px"
                  _placeholder={{ color: "#FFFFFF" }}
                  onChange={handleSearchText}
                  fontSize="14px"
                  cursor="pointer"
                />
              </InputGroup>
            </Flex>
          </Flex>
        </Box>
        {isLoading &&
          (isLessThan766 ? (
            <SkeletonMyTrackNewMobile />
          ) : (
            <SkeletonMyTrackNew columnHeader={columnHeader} />
          ))}
        <Switch>
          <Box>
            {myTrackRoutes.map((tab, index) => {
              return (
                <Route
                  key={index}
                  exact={tab.exact}
                  path={`${path}${tab.value}`}
                >
                  {tab.component}
                </Route>
              );
            })}
          </Box>
        </Switch>
      </Box>

      {selectCheckbox.length > 1 && (
        <>
          <Box className="basket-item">
            <Checkbox
              onChange={(e) => handleSelectAll(e.target.checked, routerType)}
              _focusVisible={{ outline: "none" }}
              size="lg"
              mt="20px"
              isChecked={isSelectAll}
            >
              <Text ml="30px" color="#D8FD01">
                {t("selectAll")}
              </Text>
            </Checkbox>
          </Box>
          <Button
            mt="50px"
            backgroundColor="#24252A"
            border="1px solid #D8FD01"
            _hover={{ backgroundColor: "none" }}
            borderRadius="4px"
            _active={{ backgroundColor: "#D8FD01" }}
            onClick={handleRemoveTrack}
          >
            {t("removeFromTrack")}
          </Button>
        </>
      )}
    </Box>
  );
}
