import { Box, Button, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

export default function UpgradeYourPlan() {
  const { t } = useTranslation();
  const history = useHistory();
  const handleUpgradeYourPlan = useCallback(() => {
    history.push("/dashboard/subscription");
  }, [history]);
  return (
    <Box
      height="40vh"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Text fontSize="14px" fontWeight="500" mb="10px" textAlign="center">
        {t("featureIsLocked")}
      </Text>

      <Button
        _hover={{ background: "#34391A" }}
        fontSize="14px"
        background="#34391A"
        color="#D4F70E"
        border="1px solid #D4F70E"
        borderRadius="4px"
        onClick={handleUpgradeYourPlan}
      >
        {t("upgradeYourPlan")}
      </Button>
    </Box>
  );
}
