import React from "react";

interface IconAddressDashboardCoverMobileProps {
  color?: string;
}
const IconAddressDashboardCoverMobile = ({
  color = "white",
}: IconAddressDashboardCoverMobileProps) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9974 1.16406C4.7399 1.16406 2.91406 2.9899 2.91406 5.2474C2.91406 8.3099 6.9974 12.8307 6.9974 12.8307C6.9974 12.8307 11.0807 8.3099 11.0807 5.2474C11.0807 2.9899 9.2549 1.16406 6.9974 1.16406ZM4.08073 5.2474C4.08073 3.6374 5.3874 2.33073 6.9974 2.33073C8.6074 2.33073 9.91406 3.6374 9.91406 5.2474C9.91406 6.9274 8.23406 9.44156 6.9974 11.0107C5.78406 9.45323 4.08073 6.9099 4.08073 5.2474Z"
        fill={color}
      />
      <path
        d="M6.9974 6.70573C7.80281 6.70573 8.45573 6.05281 8.45573 5.2474C8.45573 4.44198 7.80281 3.78906 6.9974 3.78906C6.19198 3.78906 5.53906 4.44198 5.53906 5.2474C5.53906 6.05281 6.19198 6.70573 6.9974 6.70573Z"
        fill={color}
      />
    </svg>
  );
};
export default IconAddressDashboardCoverMobile;
