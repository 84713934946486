import React from "react";

const IconTotalReach = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M25 10V15.5458M25 10H19.4179M25 10L20.5 14.5M17.6203 17.3347C16.6227 18.3263 16.1238 18.822 15.5051 18.822C14.8864 18.8219 14.3876 18.326 13.3902 17.3342L13.1509 17.0962C12.1525 16.1035 11.6534 15.6071 11.0342 15.6074C10.4151 15.6076 9.91626 16.1043 8.91867 17.0977L5 21"
        stroke="#D4F70E"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export default IconTotalReach;
