import { Playlists } from "app/models";

export const PlaylistType = {
  PUBLIC: "PUBLIC",
  UNLISTED: "UNLISTED",
  PRIVATE: "PRIVATE",
};

export const visibilityType = (visibility: string) => {
  const visibilityText = {
    [PlaylistType.PUBLIC]: "Public",
    [PlaylistType.UNLISTED]: "Unlisted",
    [PlaylistType.PRIVATE]: "Private",
  };
  return visibilityText[visibility];
};

export const searchPlaylists = (data: Playlists[], searchText: string) => {
  return (data || []).filter(
    (item) => item?.name?.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
  );
};
export const convertToHoursAndMinutes = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

  return { hours, minutes, seconds };
};
