import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  SimpleGrid,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import ArrowDown from "app/assets/icons/arrowDown";
import ArrowUp from "app/assets/icons/arrowUp";
import BistarLicense from "app/assets/icons/BiStarLicense";
import Cart from "app/assets/icons/cart";
import FreeDownload from "app/assets/icons/freeDownload";
import IconAddToCart from "app/assets/icons/iconAddToCart";
import IconAudioSteams from "app/assets/icons/iconAudioSteams";
import IconBookRenawal from "app/assets/icons/iconBooksRenawal";
import IconFileMusic from "app/assets/icons/iconFileMusic";
import IconFreeDownloads from "app/assets/icons/iconFreeDownloads";
import IconEntertainment from "app/assets/icons/iconMusicEntertainment";
import IconMusicRecording from "app/assets/icons/iconMusicRecording";
import IconMusicVideo from "app/assets/icons/iconMusicVideo";
import IconNegotiationCart from "app/assets/icons/IconNegotiationCart";
import IconRadio from "app/assets/icons/iconRadio";
import IconVideoStream from "app/assets/icons/iconVideoStreams";
import PauseAudioIcon from "app/assets/icons/PauseAudio";
import PaypalNew from "app/assets/images/PaypalNew.svg";
import StripeNew from "app/assets/images/StripeNew.svg";
import AudioVisualizer from "app/components/AudioVisualizer";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import ToastPlanSubscription from "app/components/toast/toastplan";
import TooltipLabel from "app/components/TooltipLabel";
import { HandleFreeDownloadModel, Licenses, TrackSoundKit } from "app/models";
import {
  addToCart,
  removeFromCart,
  selectedLisence,
} from "app/redux/actions/cart";
import { selectTrackNegotiation } from "app/redux/actions/negotiations";
import { checkFollowUser } from "app/redux/actions/tracks";
import { isInCartSelector } from "app/redux/selectors/cart";
import { selectorTrackDetail } from "app/redux/selectors/myTrack";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { usePlayers } from "hooks/player/usePlayers";
import { useToggle } from "hooks/useToggle";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "types";
import {
  checkFreeDownload,
  checkTypeDownload,
  isNotForSaleTrackSoundPacks,
} from "utils/convertTracks";

interface RightIndividualTrackProps {
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

export default function RightIndividualTrack({
  handleFreeDownload,
  handleShowModalFreeDownloadMailchimp,
}: RightIndividualTrackProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserAdmin);
  const {
    isPlaying,
    handleTogglePlayPause,
    handlePlayOrPause,
    playingTrack,
    isLoading,
    handlePlayPlaylists,
  } = usePlayers();
  const [selectLicense, setSelectLicense] = useState<Licenses>();
  const [isShowUsageTerm, setIsShowUsageTerm] = useToggle(true);
  const selectedTrack = useSelector(selectorTrackDetail);
  // const trackDetail: TrackSoundKit = useSelector(selectorTrackDetail);
  const isInCart = useSelector(isInCartSelector(selectedTrack));
  const checkPlaying = useMemo(() => {
    return selectedTrack?._id === playingTrack?._id;
  }, [playingTrack?._id, selectedTrack?._id]);
  const loggedIn = useSelector(selectLoggedIn);

  const isLoadingCheckFreeDownload = useSelector((state: RootState) =>
    isLoadingSelector([checkFollowUser.typePrefix], state)
  );
  const { t } = useTranslation();
  const isDisabled = isNotForSaleTrackSoundPacks(selectedTrack);
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");
  const [isMoreThan768] = useMediaQuery("(min-width: 768px)");

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(removeFromCart([selectedTrack?._id]));
  }, [dispatch, selectedTrack?._id]);

  const handleSelectLicense = useCallback((license) => {
    setSelectLicense(license);
  }, []);

  const onSelectLicense = (trackId: string, license: Licenses) => {
    dispatch(addToCart(selectedTrack));
    dispatch(selectedLisence({ trackId, license }));
  };

  useEffect(() => {
    isLessThan768 && setIsShowUsageTerm();
  }, [isLessThan768, setIsShowUsageTerm]);

  const handleNegotiation = useCallback(() => {
    history.push("/negotiations");
    dispatch(selectTrackNegotiation(selectedTrack));
  }, [dispatch, history, selectedTrack]);

  const isExclusive = useMemo(
    () => (selectedTrack?.licenses || []).every((license) => license.exclusive),
    [selectedTrack]
  );
  const isMakeOffersOnly = useMemo(
    () =>
      (selectedTrack?.licenses || []).every(
        (license) => license?.makeOffersOnly
      ),
    [selectedTrack?.licenses]
  );

  useEffect(() => {
    const premiumLicense = selectedTrack?.licenses?.some(
      (item) => item.name === "Premium"
    );
    const popularLicense = selectedTrack?.licenses?.some(
      (item) => item?.highlight
    );
    if (popularLicense) {
      setSelectLicense(
        selectedTrack?.licenses?.find((item) => item?.highlight)
      );
    } else if (premiumLicense) {
      setSelectLicense(
        selectedTrack?.licenses?.find((item) => item.name === "Premium")
      );
    } else {
      setSelectLicense(selectedTrack?.licenses?.[0]);
    }
  }, [selectedTrack?.licenses]);

  const onClickToTrack = useCallback(
    async (track) => {
      if (!track?.mergeAudio && !track?.audioFileUrl) {
        return;
      }
      handlePlayOrPause(track);
      handlePlayPlaylists([]);
    },
    [handlePlayOrPause, handlePlayPlaylists]
  );

  const checkButtonFreeDownload = checkFreeDownload(selectedTrack);
  const handleDownloadTrack = useCallback(async () => {
    if (selectedTrack?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(selectedTrack);
      return;
    }
    const isFollowing = await dispatch(
      checkFollowUser(selectedTrack?.createdBy?._id || "")
    );
    if (
      isFollowing?.payload ||
      currentUser?._id === selectedTrack?.createdBy?._id
    ) {
      handleFreeDownload({
        url: checkTypeDownload(selectedTrack)
          ? (selectedTrack?.mergeAudio as string)
          : (selectedTrack?.untaggedFileUrl as string),
        title: selectedTrack?.title || "",
        _id: selectedTrack?._id || "",
        isTrack: true,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    currentUser?._id,
    dispatch,
    handleFreeDownload,
    handleShowModalFreeDownloadMailchimp,
    selectedTrack,
    t,
  ]);

  return (
    <Box
      w={{ base: "100%", md: "65%", xl: "70%" }}
      height="100px"
      className="c_tright_tag"
    >
      <Box
        background={`url(${selectedTrack?.coverUrl})`}
        className="c_rt_ptlt_box"
        display={{ base: "none", md: "block" }}
      >
        <Box
          padding={{ base: "25px 10px", md: "25px 20px", lg: "25px" }}
          borderRadius="8px"
          position="relative"
          mb="10px"
          className="c_rt_tlt_box"
        >
          <Text
            noOfLines={2}
            width={{ base: "90%", lg: "95%" }}
            fontSize={{ base: "14px", md: "24px", lg: "30px" }}
            lineHeight="36px"
            fontWeight="700"
            _hover={{
              textDecor: "underline",
            }}
            cursor="pointer"
          >
            {selectedTrack?.title}
          </Text>
          {isMoreThan768 && checkPlaying && (
            <AudioVisualizer checkPlaying={checkPlaying} />
          )}
          {/* <WaveForm
            wavesurfer={wavesurfer}
            waveformRef={waveformRef}
            currentTime={currentTime}
            isIndividualTrack={true}
          /> */}
          {/* <SoundWaveComponent></SoundWaveComponent>         */}
          {checkPlaying ? (
            <Box position="absolute" top="30px" right="20px">
              <PlayButton
                handleTogglePlayPause={handleTogglePlayPause}
                isPlaying={isPlaying}
                size={40}
                isLoading={isLoading}
              />
            </Box>
          ) : (
            <Box position="absolute" top="30px" right="20px">
              <Flex
                width="40px"
                height="40px"
                border="1px solid #D1F310"
                borderRadius="25px"
                alignItems="center"
                justifyContent="center"
                cursor="pointer"
                onClick={() => onClickToTrack(selectedTrack)}
              >
                <TooltipLabel label={t("Play")}>
                  <Flex
                    background="rgba(255, 255, 255, 0.06)"
                    width="28px"
                    height="28px"
                    borderRadius="25px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <PauseAudioIcon />
                  </Flex>
                </TooltipLabel>
              </Flex>
            </Box>
          )}
        </Box>
      </Box>

      <Box
        background="rgba(36, 37, 42, 0.3)"
        borderRadius="8px"
        padding={{
          base: "45px 10px 10px 10px",
          md: "45px 20px",
          lg: "45px 25px",
        }}
      >
        <Text
          fontSize={{ base: "24px", lg: "32px" }}
          lineHeight="24px"
          fontWeight="400"
        >
          {t("chooseLicense")}
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3 }}
          spacing="16px"
          mt="25px"
        >
          {selectedTrack?.licenses?.map((license, index) => {
            return (
              <Box
                key={index}
                backgroundColor={
                  license?._id === selectLicense?._id
                    ? "rgba(212, 247, 14, 0.1)"
                    : "#24252A"
                }
                borderRadius="8px"
                p="10px 15px"
                onClick={() => handleSelectLicense(license)}
                border={
                  license?._id === selectLicense?._id
                    ? "0.5px solid #D4F70E"
                    : "none"
                }
                cursor="pointer"
              >
                <Flex justifyContent="space-between" mb="7px">
                  <Text
                    fontSize="18px"
                    w={license?.highlight ? "70%" : "100%"}
                    color={
                      license?._id === selectLicense?._id ? "#D4F70E" : "white"
                    }
                    fontWeight={
                      license?._id === selectLicense?._id ? "600" : "500"
                    }
                  >
                    {license?.name?.includes("License") ||
                    license?.name?.includes("license")
                      ? license?.name
                      : `${license?.name} License`}
                  </Text>

                  {license?.highlight && (
                    <Box className="box-popular-license" height="max-content">
                      <Flex
                        padding="0px 3px"
                        borderRadius="4px"
                        alignItems="center"
                        background="linear-gradient(to right, #36204d, #38244b, #402e49, #483b43, #524a3c)"
                      >
                        <BistarLicense />
                        <Text
                          fontSize="12px"
                          color="#D4F70E"
                          fontWeight="700"
                          ml="2px"
                        >
                          {t("popular")}
                        </Text>
                      </Flex>
                    </Box>
                  )}
                </Flex>
                <Text fontSize="16px" lineHeight="19px" mb="7px">
                  $
                  {license?.exclusive
                    ? Number(license?.exclusiveLicensePrice)?.toFixed(2)
                    : Number(
                        license?.trackPrice
                          ? license?.trackPrice
                          : license?.defaultTrackPrice
                      )?.toFixed(2)}
                </Text>
                <Flex>
                  <Text
                    fontSize="12px"
                    lineHeight="14px"
                    color="#535353"
                    mb="7px"
                  >
                    MP3
                  </Text>
                  {license?.extraWav && (
                    <Text
                      fontSize="12px"
                      lineHeight="14px"
                      color="#535353"
                      mb="7px"
                    >
                      ,WAV
                    </Text>
                  )}
                  {license?.trackStems && (
                    <Text
                      fontSize="12px"
                      lineHeight="14px"
                      color="#535353"
                      mb="7px"
                    >
                      ,TRACK STEMS
                    </Text>
                  )}
                </Flex>
              </Box>
            );
          })}
        </SimpleGrid>

        {(selectedTrack?.createdBy?.sellerConnectedPayPal ||
          selectedTrack?.createdBy?.sellerConnectedStripe) && (
          <Flex
            m="30px 0px 25px 0px"
            alignItems="center"
            display={{ base: "block", md: "flex" }}
          >
            <Text
              mr="10px"
              fontSize="18px"
              lineHeight="24px"
              mb={{ base: "10px", md: "0px" }}
            >
              {t("acceptPayment")}
            </Text>
            <Flex gap="10px">
              {selectedTrack?.createdBy?.sellerConnectedStripe && (
                <Image fallbackSrc={StripeNew} />
              )}
              {selectedTrack?.createdBy?.sellerConnectedPayPal && (
                <Image fallbackSrc={PaypalNew} />
              )}
            </Flex>
          </Flex>
        )}
        {!selectedTrack?.createdBy?.sellerConnectedPayPal &&
          !selectedTrack?.createdBy?.sellerConnectedStripe && (
            <Text color="red" fontSize="14px" mt="5px">
              {t("errorAddToCart")}
            </Text>
          )}

        <Divider mt="15px" borderColor="rgba(250, 250, 250, 0.04)" />
        {selectLicense && (
          <Box m="20px 0px 0px 0px">
            <Flex onClick={setIsShowUsageTerm} alignItems="center" mb="20px">
              <Text
                mr="10px"
                color={isShowUsageTerm ? "#D4F70E" : "white"}
                fontSize={{ base: "20px", md: "24px" }}
                lineHeight="24px"
                mb="5px"
                cursor="pointer"
              >
                {t("usageTerms")}
              </Text>
              {isShowUsageTerm ? <ArrowUp /> : <ArrowDown />}
            </Flex>
            {isShowUsageTerm && (
              <SimpleGrid
                columns={{ base: 1, md: 3 }}
                spacing={{ base: "10px", md: "20px" }}
                backgroundColor="#1C1E1C"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25) inset"
                padding="20px 25px"
                borderRadius="4px"
              >
                {!selectLicense?.exclusive ? (
                  <>
                    <Flex alignItems="center">
                      <IconMusicRecording />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("useForMusicRecording")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconVideoStream />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.nonMonetizedVideo >= 0
                          ? t("dontUse")
                          : t("use")}
                        {t("nonVideoStreams")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconFileMusic />
                      <Box>
                        <Text
                          ml="10px"
                          fontSize="12px"
                          fontFamily="Cera Pro Regular"
                        >
                          {t("filesToBeDelivered")}
                        </Text>
                        <Flex ml="10px">
                          <Text
                            fontSize="12px"
                            lineHeight="15px"
                            color="#D4F70E"
                            border="0.5px solid rgba(212, 247, 14, 0.1)"
                            padding="2px 8px"
                            borderRadius="4px"
                            fontFamily="Cera Pro Regular"
                          >
                            {t("mp3")}
                          </Text>
                          {selectLicense?.extraWav && (
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                              ml="5px"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("wav")}
                            </Text>
                          )}
                          {selectLicense?.trackStems && (
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                              ml="5px"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("stems")}
                            </Text>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex alignItems="center">
                      <IconFreeDownloads />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.freeDownloads >= 0
                          ? t("upToFreeDownloads")
                          : t("unlimitedFreeDownloads")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconEntertainment />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.profitPerformance
                          ? t("allowed")
                          : t("notAllow")}
                        {t("profitPerformance")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconAudioSteams />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.audioStreams >= 0
                          ? t("UpTo")
                          : t("unlimited")}
                        {t("audioStreams")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconMusicVideo />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.musicVideo >= 0
                          ? t("UpTo")
                          : t("unlimited")}
                        {t("musicStreams")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconRadio />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.allowBroadCasting
                          ? t("allowedBroadcastingRights")
                          : t("notAllowBroadcastingRights")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconBookRenawal />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {selectLicense?.licenseRenewal === 1
                          ? `1 ${t("year")}(s)`
                          : `2 ${t("year")}(s)`}
                        {t("licenseRenewalTerm")}
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex alignItems="center">
                      <IconMusicRecording />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("useForMusicRecording")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconVideoStream />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("unlimited")} {t("monetizedVideoStreams")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconFileMusic />
                      <Box>
                        <Text
                          ml="10px"
                          fontSize="12px"
                          fontFamily="Cera Pro Regular"
                        >
                          {t("filesToBeDelivered")}
                        </Text>
                        <Flex ml="10px" mt="5px">
                          <Text
                            fontSize="12px"
                            lineHeight="15px"
                            color="#D4F70E"
                            border="0.5px solid rgba(212, 247, 14, 0.1)"
                            padding="2px 8px"
                            borderRadius="4px"
                            fontFamily="Cera Pro Regular"
                          >
                            {t("mp3")}
                          </Text>
                          {selectLicense?.extraWav && (
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                              ml="5px"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("wav")}
                            </Text>
                          )}
                          {selectLicense?.trackStems && (
                            <Text
                              fontSize="12px"
                              lineHeight="15px"
                              color="#D4F70E"
                              border="0.5px solid rgba(212, 247, 14, 0.1)"
                              padding="2px 8px"
                              borderRadius="4px"
                              ml="5px"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("stems")}
                            </Text>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                    <Flex alignItems="center">
                      <IconFreeDownloads />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("unlimitedFreeDownload")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconEntertainment />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("unlimited")} {t("profitPerformance")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconAudioSteams />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("unlimited")} {t("audioStreams")}
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <IconMusicVideo />
                      <Text
                        ml="10px"
                        fontSize="12px"
                        fontFamily="Cera Pro Regular"
                      >
                        {t("unlimited")} {t("musicStreams")}
                      </Text>
                    </Flex>
                  </>
                )}
              </SimpleGrid>
            )}
            <Divider
              m="30px 0px 15px 0px"
              borderColor="rgba(250, 250, 250, 0.04)"
            />

            <Flex
              m="40px 0px 10px 0px"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text fontSize="24px" lineHeight="24px">
                $
                {selectLicense?.exclusive
                  ? Number(selectLicense?.exclusiveLicensePrice)?.toFixed(2)
                  : selectLicense?.trackPrice
                  ? Number(selectLicense?.trackPrice)?.toFixed(2)
                  : Number(selectLicense?.defaultTrackPrice).toFixed(2)}
              </Text>
              {isInCart ? (
                <Button
                  leftIcon={
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="25px"
                      width="25px"
                      borderRadius="50%"
                      bg="#24252A"
                    >
                      <Cart />
                    </Box>
                  }
                  backgroundColor="#D4F70E"
                  borderRadius="4px"
                  color="black"
                  _hover={{ background: "#D4F70E" }}
                  onClick={handleRemoveToCartButton}
                >
                  {t("inCart")}
                </Button>
              ) : (
                <Flex
                  gridGap="10px"
                  alignItems="center"
                  display={{ base: "inline-grid", md: "flex" }}
                >
                  {checkButtonFreeDownload &&
                    (isLoadingCheckFreeDownload ? (
                      <Spinner color="#d4f70e" />
                    ) : (
                      <Button
                        leftIcon={<FreeDownload />}
                        backgroundColor="rgba(212, 247, 14, 0.1)"
                        borderRadius="4px"
                        color="#D4F70E"
                        _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                        _active={{ background: "#D4F70E" }}
                        onClick={loggedIn ? handleDownloadTrack : () => null}
                      >
                        {t("freeDownloadButton")}
                      </Button>
                    ))}
                  {isExclusive && isMakeOffersOnly && (
                    <Button
                      leftIcon={<IconAddToCart />}
                      isDisabled={
                        (!selectedTrack?.createdBy?.sellerConnectedPayPal &&
                          !selectedTrack?.createdBy?.sellerConnectedStripe) ||
                        isDisabled
                      }
                      backgroundColor="rgba(212, 247, 14, 0.1)"
                      borderRadius="4px"
                      color="#D4F70E"
                      border="1px solid rgba(212, 247, 14, 0.1)"
                      _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                      _active={{ background: "#D4F70E" }}
                      onClick={() =>
                        (selectedTrack?.createdBy.sellerConnectedPayPal ||
                          selectedTrack?.createdBy.sellerConnectedStripe) &&
                        isExclusive &&
                        onSelectLicense(selectedTrack?._id, selectLicense)
                      }
                    >
                      {t("addToCart")}
                    </Button>
                  )}
                  <Button
                    leftIcon={
                      isExclusive && isMakeOffersOnly ? (
                        <IconNegotiationCart />
                      ) : (
                        <IconAddToCart />
                      )
                    }
                    isDisabled={
                      (!selectedTrack?.createdBy?.sellerConnectedPayPal &&
                        !selectedTrack?.createdBy?.sellerConnectedStripe) ||
                      isDisabled
                    }
                    border="1px solid rgba(212, 247, 14, 0.1)"
                    backgroundColor="rgba(212, 247, 14, 0.1)"
                    borderRadius="4px"
                    color="#D4F70E"
                    _hover={{ background: "rgba(212, 247, 14, 0.1)" }}
                    _active={{ background: "#D4F70E" }}
                    onClick={() =>
                      (selectedTrack?.createdBy.sellerConnectedPayPal ||
                        selectedTrack?.createdBy.sellerConnectedStripe) &&
                      isExclusive &&
                      isMakeOffersOnly &&
                      loggedIn
                        ? handleNegotiation()
                        : onSelectLicense(selectedTrack?._id, selectLicense)
                    }
                  >
                    {isExclusive && isMakeOffersOnly
                      ? t("startNegotiation")
                      : t("addToCart")}
                  </Button>
                </Flex>
              )}
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
}
