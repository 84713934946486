import React, { useState } from "react";
import { useToggle } from "hooks/useToggle";

export const useUploadCover = () => {
  const [toggleModalCropCover, setToggleModalCropCover] = useToggle();
  const [coverImage, setCoverImage] = useState<File | null>(null);

  const onCoverChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    const [type] = fileObj.type.split("/");
    if (!type || type !== "image") {
      return;
    }
    event.target.value = "";
    setToggleModalCropCover();
    setCoverImage(fileObj);
  };

  return {
    coverImage,
    toggleModalCropCover,
    onCoverChange,
    setToggleModalCropCover,
  };
};
