import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import Logo from "app/assets/images/logo.svg";
import AuthText from "app/components/AuthText";
import { InputItem } from "app/components/InputItem";
import { useForgotPassword } from "hooks/forgotPassword/useForgotPassword";
import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";

export const ForgotPassword = memo(() => {
  const { control, handleSubmit, errors, isLoading, onSubmit, isEmailSent } =
    useForgotPassword();
  const { t } = useTranslation();

  return (
    <Flex>
      <Box bg="#111518" w="400px" m="auto">
        <Box>
          <Image src={Logo} w="214px" h="24px" />
          <Box my="20px">
            <AuthText
              title={t("forgotYourPassword")}
              description={t("enterYourEmail")}
            />
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex>
              <Text
                pr="1px"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                {t("Email")}
              </Text>
              <Text
                color="#C8E815"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                *
              </Text>
            </Flex>
            <InputItem
              placeholder={"Email address"}
              control={control}
              name="email"
              errors={errors.email}
            />
            <Button
              mt={{ base: "28px", md: "32px", lg: "36px" }}
              borderRadius="4px"
              type="submit"
              isLoading={isLoading}
              background="#34391A"
              color="#D4F70E"
              border="1px solid #D4F70E"
              _hover={{
                background: "#34391A",
              }}
              w="100%"
              size="lg"
              spinner={<BeatLoader size={8} color="white" />}
            >
              {t("Send")}
            </Button>
            {isEmailSent && (
              <Text mt="15px" fontSize="12px">
                {t("emailSent")}
              </Text>
            )}
            <Text
              textAlign="center"
              mt={{ base: "50px", md: "100px" }}
              color="#343434"
            >
              {t("Superproductor")}
            </Text>
          </form>
        </Box>
      </Box>
    </Flex>
  );
});
