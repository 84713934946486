import { Box, Divider, Flex, SimpleGrid } from "@chakra-ui/react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import React from "react";

export default function SkeletonDiscounts() {
  const renderSkeleton = () => {
    return (
      <Box w="100%">
        <Skeleton
          w="150px"
          h="22px"
          mb="20px"
          mt={{ base: "50px", md: "0px" }}
        />
        <Box bg="#24252A" borderRadius="8px">
          <Flex
            py="30px"
            px={{ base: "16px", md: "20px", xl: "24px" }}
            alignItems="center"
            justifyContent="space-between"
            mb="40px"
          >
            <Flex gap={{ base: "12px", md: "16px", xl: "20px" }}>
              <Skeleton
                w={{ base: "60px", md: "80px" }}
                h="30px"
                borderRadius="20px"
              />
              <Box>
                <Skeleton
                  w={{ base: "140px", md: "170px", xl: "210px" }}
                  h="20px"
                  mb="10px"
                />
                <Flex>
                  <Skeleton
                    w={{ base: "60px", md: "80px", xl: "100px" }}
                    h="20px"
                  />
                  <Skeleton
                    w={{ base: "60px", md: "80px", xl: "100px" }}
                    h="20px"
                  />
                </Flex>
              </Box>
            </Flex>
            <Skeleton w="40px" h="22px" borderRadius="8px" />
          </Flex>
          <Divider borderColor="#535353" />
          <Flex
            py="20px"
            px={{ base: "16px", md: "20px", xl: "24px" }}
            alignItems="center"
            justifyContent="space-between"
          >
            <Skeleton w="150px" h="22px" />
            <Skeleton w="35px" h="35px" borderRadius="full" />
          </Flex>
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing="30px">
        {Array.from({ length: 2 }).map(() => renderSkeleton())}
      </SimpleGrid>
    </Box>
  );
}
