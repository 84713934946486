import React from "react";

interface PlayButtonProps {
  isModalLyrics?: boolean;
}

const PlayButton = ({ isModalLyrics = false }: PlayButtonProps) => {
  return (
    <svg
      width={isModalLyrics ? "50" : "28"}
      height={isModalLyrics ? "50" : "28"}
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="13.5" stroke="#D8FD01" />
      <path
        d="M17.4806 13.1318C18.1524 13.5157 18.1524 14.4843 17.4806 14.8682L13.4961 17.1451C12.8295 17.526 12 17.0446 12 16.2768L12 11.7232C12 10.9554 12.8295 10.474 13.4961 10.8549L17.4806 13.1318Z"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default PlayButton;
