import { createSelector } from "@reduxjs/toolkit";
import { InitSoundkitState } from "app/redux/reducers/addSoundkit";
import { InitTrackState } from "../reducers/track";

export const selectAddSoundkit = (state: { addSoundkit: InitSoundkitState }) =>
  state.addSoundkit;

export const selectTrack = (state: { track: InitTrackState }) => state.track;

export const selectNewSoundkit = createSelector(
  selectAddSoundkit,
  ({ newSoundkit }) => newSoundkit
);

export const checkSoundkitSlugSelectors = createSelector(
  selectAddSoundkit,
  ({ checkSlugSoundkit }) => checkSlugSoundkit
);

export const checkUploadSoundPacksSelectors = createSelector(
  selectAddSoundkit,
  ({ isUploadSoundPack }) => isUploadSoundPack
);
