import { Box, Flex } from "@chakra-ui/react";
import NotFoundPage from "app/components/NotFoundPage/notfoundPage";
import { TrackSoundKit } from "app/models";
import { selectedTrackDetail } from "app/redux/actions/myTrack";
import {
  selectorIsLoading,
  selectorTrackDetail,
} from "app/redux/selectors/myTrack";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { checkHadData } from "utils/errorMessage";
import LeftIndividualTrack from "./leftIndividualTrack";
import RightIndividualTrack from "./rightIndividualTrack";
import SkeletonIndividualTrack from "./skeletonIndividualTrack";
import "./style.css";
import ModalLyrics from "app/components/ModalShowLyrics";
import useTrackSelect from "hooks/track/useTrackSelect";
import { selectLoggedIn } from "app/redux/selectors/users";
import { usePlaylists } from "hooks/playlists/usePlaylists";
import ModalAddToPlaylist from "../Playlists/modalAddToPlaylist";
import ModalCreatePlaylist from "../Playlists/modalCreatePlaylist";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";

export default function IndividualTrackPage() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(selectorIsLoading);
  const trackDetail: TrackSoundKit = useSelector(selectorTrackDetail);
  const checkTrackDetail = checkHadData(trackDetail);
  const loggedIn = useSelector(selectLoggedIn);
  useEffect(() => {
    dispatch(
      selectedTrackDetail({
        slug,
        loginAsGuest: loggedIn ? false : true,
      })
    );
  }, [dispatch, loggedIn, slug]);

  const {
    selectedTrack,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    handleFreeDownload,
    setShowModalFreeDownload,
    showModalFreeDownload,
    linkTitleDownload,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const {
    trackId,
    setShowModalAddToPlaylist,
    showModalAddToPlaylist,
    handleShowModalAddTrack,
    showModalCreatePlaylist,
    setShowModalCreatePlaylist,
    playlists,
    handleSearchText,
    debouncedSearchText,
  } = usePlaylists();

  return (
    <Box
      padding={{ base: "40px 16px 0px", xl: "40px 80px 0px" }}
      className="c_it_box"
      overflow="auto"
    >
      {isLoading ? (
        <SkeletonIndividualTrack />
      ) : checkTrackDetail ? (
        <NotFoundPage />
      ) : (
        <Flex
          display={{ base: "block", md: "flex" }}
          w="100%"
          flexWrap="nowrap"
          gridGap={{ base: "10px ", md: "15px", lg: "20px", xl: "30px" }}
        >
          <LeftIndividualTrack
            handleShowModalAddTrack={handleShowModalAddTrack}
            onOpenOptionsModal={onOpenOptionsModal}
          />
          <RightIndividualTrack
            handleFreeDownload={handleFreeDownload}
            handleShowModalFreeDownloadMailchimp={
              handleShowModalFreeDownloadMailchimp
            }
          />
          {showLyrics && (
            <ModalLyrics
              showModal={showLyrics}
              setShowModal={setShowLyrics}
              selectedTrack={selectedTrack}
              onOpenOptionsModal={onOpenOptionsModal}
              isEditLyrics={isEditLyrics}
              setIsEditLyrics={setIsEditLyrics}
              lyricsData={lyricsData}
            />
          )}

          {showModalAddToPlaylist && (
            <ModalAddToPlaylist
              trackId={trackId}
              setShowModal={setShowModalAddToPlaylist}
              showModal={showModalAddToPlaylist}
              setShowModalCreatePlaylist={setShowModalCreatePlaylist}
              playlists={playlists}
              handleSearchText={handleSearchText}
              debouncedSearchText={debouncedSearchText}
            />
          )}
          {showModalCreatePlaylist && (
            <ModalCreatePlaylist
              showModal={showModalCreatePlaylist}
              setShowModal={setShowModalCreatePlaylist}
            />
          )}
          {showModalFreeDownload && (
            <ModalShowFreeDownload
              showModal={showModalFreeDownload}
              setShowModal={setShowModalFreeDownload}
              linkTitleFreeDownload={linkTitleDownload}
            />
          )}
          {showModalFreeMailchimp && (
            <ModalShowFreeDownloadFormMailChimp
              showModal={showModalFreeMailchimp}
              setShowModal={setShowModalFreeMailchimp}
              selectedTrack={selectedTrack}
              handleFreeDownload={handleFreeDownload}
            />
          )}
        </Flex>
      )}
    </Box>
  );
}
