import { LoadMorePage, CreateDownloadLog } from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import getOptions from "utils/getOptions";
import ToastPlanSubscription from "app/components/toast/toastplan";

export default class PurchasedService {
  static async getPurchased(payload: LoadMorePage) {
    const options = getOptions(payload.page, payload.search);
    try {
      const { data } = await axiosService.get(
        "/purchases/purchased-transaction",
        {
          params: { ...options, type: payload.type },
        }
      );
      return data?.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      throw new Error(errorMessage(e));
    }
  }

  static async createDownloadLog(payload: CreateDownloadLog) {
    try {
      const { data } = await axiosService.post("/download-log", payload);
      return data?.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      throw new Error(errorMessage(e));
    }
  }

  static async getListEmailCustomers(payload: LoadMorePage) {
    const options = getOptions(payload.page, payload.search);
    try {
      const { data } = await axiosService.get("/user/list-email/customers", {
        params: { ...options },
      });
      return data?.data;
    } catch (e) {
      ToastPlanSubscription({ description: errorMessage(e) });
      throw new Error(errorMessage(e));
    }
  }
}
