import { Box, Flex, Text } from "@chakra-ui/react";
import SubscriptionItem from "app/components/SubcriptionItem";
import { useSubscription } from "hooks/subscription/useSubscription";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import SkeletonSubscription from "./skeletonSubscription";
import "./styles.scss";
import subscriptionConfirm from "../../assets/LottieIAnimations/subsctiptionConfirm.json";
import subscriptionCancel from "../../assets/LottieIAnimations/subsctiptionCancel.json";
import { useHistory, useLocation } from "react-router-dom";
import ModalTransactionProcess from "../SuccessModal/modalTransactionProcess";

export default function Subscription() {
  const { t } = useTranslation();
  const {
    subscriptions,
    isLoading,
    isSuccess,
    checkIsPlanMainstream,
    activePlanCheck,
    getSubscriptions,
    handleCancelPlan,
    handleCheckoutUrl,
    isLoadingGetSubscription,
    showModalCancel,
    setShowModalCancel,
  } = useSubscription();

  const history = useHistory();
  const location = useLocation();
  const checkSubscriptionSucceeded = location.search.includes("succeeded");

  useEffect(() => {
    getSubscriptions();
  }, [getSubscriptions]);

  const handleCloseModalSuccess = useCallback(() => {
    history.push("/home");
    setShowModalCancel(true);
  }, [history, setShowModalCancel]);

  return (
    <Box className="subscription-box">
      {isLoadingGetSubscription ? (
        <SkeletonSubscription />
      ) : (
        <Flex flexDirection="column" alignItems="center">
          <Text
            color="#D4F70E"
            fontSize="14px"
            lineHeight="48px"
            fontFamily="Cera Pro Medium"
          >
            {t("PRICINGPLANS")}
          </Text>
          <Text
            fontSize={{ base: "24px", md: "32px" }}
            fontFamily="Cera Pro Medium"
            lineHeight="48px"
          >
            {t("flexiblePlan")}
          </Text>
          <Box py={{ base: "20px", md: "40px" }}>
            {/* <Flex
              py={{ base: "20px", md: "40px" }}
              alignItems="center"
              justifyContent="center"
            >
              {planTabs.map((plan) => {
                const { id, name, type } = plan;
                return (
                  <Box
                    key={id}
                    borderRadius="4px"
                    cursor="pointer"
                    py="6px"
                    px="24px"
                    backgroundColor={
                      isActiveTab(type) ? "rgba(212, 247, 14, 0.04)" : "#24252A"
                    }
                    color={isActiveTab(type) ? "#D4F70E" : "#FFFFFF"}
                    border={
                      isActiveTab(type)
                        ? "1px solid rgba(212, 247, 14, 0.1)"
                        : "none"
                    }
                    _focus={{ boxShadow: "none" }}
                    onClick={() => setCurrentTab(type)}
                    fontSize="14px"
                    fontFamily="Cera Pro Medium"
                  >
                    {t(name)}
                  </Box>
                );
              })}
            </Flex> */}

            {subscriptions.length > 0 && (
              <Flex flexWrap="wrap">
                {subscriptions?.map((item) => (
                  <SubscriptionItem
                    key={item._id}
                    isSuccess={isSuccess}
                    isLoading={isLoading}
                    item={item}
                    activePlanCheck={activePlanCheck}
                    handleCancelPlan={handleCancelPlan}
                    handleCheckoutPlan={handleCheckoutUrl}
                    isCurrentMainstreamPlan={checkIsPlanMainstream}
                  />
                ))}
              </Flex>
            )}
          </Box>
        </Flex>
      )}

      {showModalCancel && (
        <ModalTransactionProcess
          isOpen={showModalCancel}
          iconJson={subscriptionCancel}
          buttonLabel={"backToFeed"}
          onButtonClick={handleCloseModalSuccess}
        />
      )}
      {checkSubscriptionSucceeded && (
        <ModalTransactionProcess
          isOpen={checkSubscriptionSucceeded}
          iconJson={subscriptionConfirm}
          buttonLabel={"backToFeed"}
          onButtonClick={handleCloseModalSuccess}
        />
      )}
    </Box>
  );
}
