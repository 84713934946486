import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Text,
  Image,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ClearRefinements,
  useRange,
  useRefinementList,
} from "react-instantsearch";
import "./styles.scss";
import { alphabeticallyItems } from "constants/alphabeticallyItems";
import IconGenresFilter from "app/assets/images/IconGenreFilter.png";
import IconSubGenresFilter from "app/assets/images/IconSubGenresFilter.png";
import IconMoodsFilter from "app/assets/icons/IconMoodsFilter";
import IconTypeFilter from "app/assets/icons/IconTypeFilter";
import MusicHomePage from "app/assets/icons/MusicHomePage";
import IconContractTypeFilter from "app/assets/icons/IconContractTypeFilter";
import IconPriceFilter from "app/assets/images/IconPriceFilter.png";
import { capitalizeTheFirst } from "utils/convertTracks";

interface FilterTrendingBeatsProps {
  tabActive?: number;
  isTrackSearch?: boolean;
}

function FilterTrendingBeats({
  tabActive,
  isTrackSearch,
}: FilterTrendingBeatsProps) {
  const { t } = useTranslation();

  const filterTrackOptions = useMemo(
    () => [
      {
        name: t("genres"),
        options: "mainGenre",
        icon: <Image w="19px" h="14px" fallbackSrc={IconGenresFilter} />,
      },
      {
        name: t("subGenres"),
        options: "subGenre",
        icon: <Image w="19px" h="18spx" fallbackSrc={IconSubGenresFilter} />,
      },
      {
        name: t("moods"),
        options: "primaryMood",
        icon: <IconMoodsFilter />,
      },
      {
        name: t("trackType"),
        options: "type",
        icon: <IconTypeFilter />,
      },
      {
        name: t("key"),
        options: "key",
        icon: <MusicHomePage />,
      },
      {
        name: t("tyOfContract"),
        options: "licenses.exclusive",
        icon: <IconContractTypeFilter />,
      },
    ],
    [t]
  );

  const filterSoundpacksOptions = useMemo(
    () => [
      {
        name: t("genres"),
        options: "mainGenre",
        icon: <Image w="19px" h="14px" fallbackSrc={IconGenresFilter} />,
      },
      {
        name: t("subGenres"),
        options: "subGenre",
        icon: <Image w="19px" h="18spx" fallbackSrc={IconSubGenresFilter} />,
      },
      {
        name: t("soundKitType"),
        options: "type",
        icon: <IconTypeFilter />,
      },
    ],
    [t]
  );

  const filterSearchOptions = useMemo(() => {
    return isTrackSearch
      ? filterTrackOptions
      : tabActive === 1
      ? filterTrackOptions
      : filterSoundpacksOptions;
  }, [filterSoundpacksOptions, filterTrackOptions, isTrackSearch, tabActive]);

  const CustomRangeInput = () => {
    const { range, refine } = useRange({
      attribute: "priceSearch",
    });

    const [minMaxPrice, setMinMaxPrice] = useState([range.min, range.max]);

    const onRangeSubmit = (value: number[]) => {
      setMinMaxPrice(value);
      refine([
        Number.isFinite(value[0]) ? value[0] : undefined,
        Number.isFinite(value[1]) ? value[1] : undefined,
      ]);
    };

    return (
      <>
        <Flex justifyContent="space-between">
          {minMaxPrice.map((item, index) => (
            <Flex key={index}>
              <Text fontSize="20px" fontWeight="500" color="#D4F70E">
                {item}
              </Text>
              <Text fontSize="20px" fontWeight="500" color="#67676A" ml="5px">
                {t("price")}
              </Text>
            </Flex>
          ))}
        </Flex>
        <RangeSlider
          aria-label={["min", "max"]}
          defaultValue={[10, 50]}
          min={0}
          max={Number(range.max)}
          onChangeEnd={(value) => onRangeSubmit(value)}
        >
          <RangeSliderTrack background="#2A2B30" style={{ zoom: "1.1" }}>
            <RangeSliderFilledTrack background="#D4F70E" />
          </RangeSliderTrack>
          <RangeSliderThumb index={0} />
          <RangeSliderThumb index={1} />
        </RangeSlider>
      </>
    );
  };

  const CustomRefinementList = (props) => {
    const { items, refine, isShowingMore, toggleShowMore, canToggleShowMore } =
      useRefinementList({
        attribute: props.options,
        showMore: true,
      });

    const convertNameByAlphabeticalOrder = alphabeticallyItems(items);

    return (
      <Box>
        {convertNameByAlphabeticalOrder.map((item) => (
          <Box key={item.label}>
            <Flex>
              <Checkbox
                isChecked={item.isRefined}
                onChange={() => refine(item.value)}
              >
                {props.name === t("tyOfContract")
                  ? item.label === "false"
                    ? t("nonExclusive")
                    : t("Exclusive")
                  : capitalizeTheFirst(item?.label)}
              </Checkbox>
            </Flex>
          </Box>
        ))}
        {canToggleShowMore && (
          <Button
            background="rgba(212, 247, 14, 0.04)"
            onClick={toggleShowMore}
            _hover={{
              background: "rgba(212, 247, 14, 0.04)",
            }}
            _focus={{
              boxShadow: "none",
            }}
            color="#D4F70E"
          >
            {isShowingMore ? t("showLess") : t("showMore")}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Box
      background="rgba(36, 37, 42, 0.3)"
      padding={{ base: "10px", md: "10px 15px", xl: "20px 25px" }}
      mt={{ base: "0px", xl: "20px" }}
      borderRadius="8px"
      className="filter-trending-beats"
    >
      <Flex
        mb="20px"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text
          fontSize={{ base: "18px", xl: "24px" }}
          lineHeight="29px"
          fontWeight="700"
          color="#ffffff"
          fontFamily="Cera Pro Medium"
        >
          {t("filter")}
        </Text>
        <ClearRefinements
          translations={{
            resetButtonText: t("clear"),
          }}
        />
      </Flex>

      <Accordion allowToggle allowMultiple>
        {filterSearchOptions.map((item) => (
          <AccordionItem maxHeight="400px" overflow="scroll" key={item.name}>
            <AccordionButton _focus={{ boxShadow: "none" }} padding="8px 0px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flex="1"
                padding={{ base: "10px 0px", xl: "20px 0px" }}
              >
                <Flex alignItems="center">
                  <Box w="40px">{item.icon}</Box>
                  <Text
                    fontSize={{ base: "16px", xl: "20px" }}
                    fontWeight="600"
                  >
                    {item.name}
                  </Text>
                </Flex>
                <AccordionIcon />
              </Box>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <CustomRefinementList {...item} />
            </AccordionPanel>
          </AccordionItem>
        ))}

        <AccordionItem maxHeight="400px" overflow="scroll">
          <AccordionButton _focus={{ boxShadow: "none" }} padding="8px 0px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flex="1"
              padding={{ base: "10px 0px", md: "20px 0px" }}
            >
              <Flex alignItems="center">
                <Box w="40px">
                  <Image fallbackSrc={IconPriceFilter} w="16px" h="20px" />
                </Box>
                <Text fontSize={{ base: "16px", xl: "20px" }} fontWeight="600">
                  {t("price")}
                </Text>
              </Flex>
              <AccordionIcon />
            </Box>
          </AccordionButton>
          <AccordionPanel pb={4}>
            <CustomRangeInput />
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
}
export default React.memo(FilterTrendingBeats);
