import { yupResolver } from "@hookform/resolvers/yup";
import {
  submitSoundkit,
  checkUploadSoundPacks,
} from "app/redux/actions/addSoundkit";
import { prepareTrackCollaborator } from "app/redux/actions/tracks";
import { AddSoundkitState } from "app/redux/reducers/addSoundkit";
import {
  selectNewSoundkit,
  checkUploadSoundPacksSelectors,
} from "app/redux/selectors/addSoundkit";
import { isLoadingSelector, successSelector } from "app/redux/selectors/status";
import { selectCollaborators } from "app/redux/selectors/tracks";
import { selectUserAdmin } from "app/redux/selectors/users";
import { useToggle } from "hooks/useToggle";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "types";
import { normalizeDraftData } from "utils/normalizeData";
import formSchema from "./formSchema";
import { slugTrackSoundkit } from "utils/convertTracks";
import dayjs from "dayjs";

export const useAddSoundkit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showSuccessModal, toggleShowSuccessModal] = useToggle();
  const [showModal, setShowModal] = useState(false);
  const initValueSoundkit: AddSoundkitState = useSelector(selectNewSoundkit);
  const currentUser = useSelector(selectUserAdmin);
  const currentCollaboratorUser = useSelector(selectCollaborators);
  const isUploadSoundPacks = useSelector(checkUploadSoundPacksSelectors);
  const isLoadingCheckUploadSoundPack = useSelector((state: RootState) =>
    isLoadingSelector([checkUploadSoundPacks.typePrefix], state)
  );
  const [addSoundkitDraft, setAddSoundkitDraft] = useState(false);
  const [addSoundkitPrivate, setAddSoundkitPrivate] = useState(false);
  const [addSoundkitScheduled, setAddSoundkitScheduled] = useState(false);
  const [releaseSoundkitDraft, setReleaseSoundkitDraft] = useState(false);
  const [addCollaboratorsSoundkit, setAddCollaboratorsSoundkit] =
    useState(false);

  const initialValues = useMemo(() => {
    const userIds = currentCollaboratorUser.map((item) => item.user);

    return {
      ...initValueSoundkit,
      collaborators: [
        ...currentCollaboratorUser,
        ...initValueSoundkit.collaborators.filter(
          (item) => !userIds.includes(item.user)
        ),
      ],
      releaseDate: dayjs().format("YYYY-MM-DDTHH:mm"),
    };
  }, [currentCollaboratorUser, initValueSoundkit]);

  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([submitSoundkit.typePrefix], state)
  );
  const isSuccess = useSelector((state: RootState) =>
    successSelector([submitSoundkit.typePrefix], state)
  );

  const methods = useForm<AddSoundkitState>({
    resolver: yupResolver(formSchema),
    defaultValues: initialValues,
  });

  const { watch, reset } = methods;
  const addSoundkitWatch = watch();

  useEffect(() => {
    dispatch(prepareTrackCollaborator(currentUser));
  }, [currentUser, dispatch]);

  useEffect(() => {
    reset(initialValues);
  }, [initialValues, reset, isSuccess]);

  const { title, type, description, mainGenre, subGenre, tags, collaborators } =
    addSoundkitWatch;

  useEffect(() => {
    if (isSuccess && showModal) {
      toggleShowSuccessModal();
    }
  }, [isSuccess, showModal, toggleShowSuccessModal]);
  const compareArrays = useCallback((arrA, arrB) => {
    if (arrA.length !== arrB.length) {
      return false;
    }
    // Tạo một đối tượng để lưu trữ dữ liệu user từ mảng B
    const userDataB = {};
    for (const item of arrB) {
      userDataB[item.user] = {
        profitShare: item.profitShare,
        publishing: item.publishing,
        role: item.role,
      };
    }

    for (const item of arrA) {
      const userDataA = userDataB[item.user];
      if (
        !userDataA ||
        userDataA.profitShare !== item.profitShare ||
        userDataA.publishing !== item.publishing ||
        userDataA.role !== item.role
      ) {
        return false;
      }
    }

    return true;
  }, []);

  const onPublishSoundkit = (values: AddSoundkitState) => {
    const checkChangeColla = compareArrays(
      initialValues.collaborators,
      values?.collaborators
    );

    if (addSoundkitWatch?._id) {
      dispatch(
        submitSoundkit({
          ...values,
          basicPrice: Number(values.basicPrice),
          slug: slugTrackSoundkit(title),
          isEdit: true,
          isDraft: false,
        })
      );
      setShowModal(true);
      if (values?.isDraft) {
        setReleaseSoundkitDraft(true);
      }
      if (!checkChangeColla) {
        setAddCollaboratorsSoundkit(true);
      }
      if (values?.private) {
        setAddSoundkitPrivate(true);
      }
      if (dayjs().add(0.5, "h").isBefore(values?.releaseDate)) {
        setAddSoundkitScheduled(true);
      }
      return;
    }

    dispatch(
      submitSoundkit({
        ...values,
        basicPrice: Number(values.basicPrice),
        slug: slugTrackSoundkit(title),
      })
    );
    setShowModal(true);
    if (values?.collaborators?.length > 1) {
      setAddCollaboratorsSoundkit(true);
    }
    if (values?.private) {
      setAddSoundkitPrivate(true);
    }
    if (dayjs().add(0.5, "h").isBefore(values?.releaseDate)) {
      setAddSoundkitScheduled(true);
    }
  };

  const onSubmitDraft = () => {
    const soundkit = normalizeDraftData(addSoundkitWatch);
    if (addSoundkitWatch?._id) {
      dispatch(
        submitSoundkit({
          ...soundkit,
          slug: slugTrackSoundkit(title),
          isDraft: true,
          basicPrice: Number(soundkit.basicPrice),
        })
      );
      setAddSoundkitDraft(true);
      setShowModal(true);
      return;
    }
    dispatch(
      submitSoundkit({
        ...soundkit,
        slug: slugTrackSoundkit(title),
        isDraft: true,
        basicPrice: Number(soundkit.basicPrice),
      })
    );
    setAddSoundkitDraft(true);
    setShowModal(true);
  };

  const tabsMenu = useMemo(
    () => [
      {
        name: t("generalInfo"),
        id: 0,
        isCheck: !!title && !!type,
      },
      { name: t("description"), id: 1, isCheck: !!description },
      {
        name: t("metadata"),
        id: 2,
        isCheck: !!mainGenre && !!subGenre && tags.length >= 3,
      },
      { name: t("collaborators"), id: 4, isCheck: collaborators?.length > 1 },
    ],
    [
      t,
      title,
      type,
      description,
      mainGenre,
      subGenre,
      tags.length,
      collaborators?.length,
    ]
  );

  return {
    methods,
    showSuccessModal,
    isLoading,
    tabsMenu,
    onPublishSoundkit,
    onSubmitDraft,
    toggleShowSuccessModal,
    isLoadingCheckUploadSoundPack,
    isUploadSoundPacks,
    addSoundkitDraft,
    addSoundkitPrivate,
    addSoundkitScheduled,
    releaseSoundkitDraft,
    addCollaboratorsSoundkit,
  };
};
