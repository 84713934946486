import { Text, Box } from "@chakra-ui/react";
import React from "react";
import "./styles.scss";

export default function MarqueeText({ text }: { text?: string }) {
  return (
    <Box className="marquee-container">
      <Box className="marquee">
        <Text color="white" fontSize={{ base: "14px", md: "16px" }}>
          {text}
        </Text>
      </Box>
    </Box>
  );
}
