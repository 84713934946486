import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "types";

export const selectReferrals = (state: RootState) => state.referrals;

export const selectorsGenreralInfoReferrals = createSelector(
  selectReferrals,
  ({ generalReferrals }) => {
    return generalReferrals;
  }
);

export const selectorsReferrals = createSelector(
  selectReferrals,
  ({ referrals }) => {
    return referrals;
  }
);
