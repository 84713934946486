import React from "react";

const IconHeartFeedPageLike = () => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 21.0001C12.7744 20.4281 11.9543 19.8331 11.0869 19.2001H11.0757C8.02128 16.9801 4.55965 14.4681 3.03078 11.4581C2.52848 10.4999 2.26229 9.45727 2.25001 8.40015C2.24666 6.94963 2.90114 5.55913 4.06556 4.54291C5.22998 3.5267 6.80603 2.97054 8.43753 3.00015C9.76573 3.00201 11.0653 3.34323 12.1815 3.98315C12.672 4.26612 13.1157 4.6084 13.5 5.00015C13.8864 4.60994 14.3303 4.26786 14.8196 3.98315C15.9354 3.3431 17.2346 3.00187 18.5625 3.00015C20.194 2.97054 21.7701 3.5267 22.9345 4.54291C24.0989 5.55913 24.7534 6.94963 24.75 8.40015C24.7386 9.45896 24.4723 10.5033 23.9693 11.4631C22.4404 14.4731 18.9799 16.9841 15.9255 19.2001L15.9143 19.2081C15.0458 19.8371 14.2268 20.4321 13.5012 21.0081L13.5 21.0001ZM8.43753 5.00015C7.3896 5.00015Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconHeartFeedPageLike;
