// https://stackoverflow.com/questions/71045643/could-not-reach-cloud-firestore-backend-react-native-firebase-v9
import { initializeFirestore } from "firebase/firestore";

import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_CONFIG_APIKEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_CONFIG_AUTHDOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_CONFIG_PROJECTID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_CONFIG_STORAGEBUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_CONFIG_MESSAGING_SENDERID}`,
  appId: `${process.env.REACT_APP_FIREBASE_CONFIG_APPID}`,
  measurementId: `${process.env.REACT_APP_FIREBASE_CONFIG_MEASUREMENTID}`,
};

const app = initializeApp(firebaseConfig);
export const db = initializeFirestore(app, {
  experimentalForceLongPolling: true,
});
