import { Box, Text } from "@chakra-ui/react";
import IconEmptyConversation from "app/assets/icons/IconEmptyConversation";
import React from "react";
import { useTranslation } from "react-i18next";

export default function EmptyConversation() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      margin="auto"
      height={{ base: "350px", md: "400px", xl: "516px" }}
    >
      <IconEmptyConversation />
      <Text
        mt="20px"
        fontSize={{ base: "16px", md: "20px", xl: "24px" }}
        lineHeight="30px"
        color="#535353"
      >
        {t("emptyConversation")}
      </Text>
    </Box>
  );
}
