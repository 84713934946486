import TextInput, { InputType } from "app/components/TextInput";
import React from "react";
import "./styles.scss";

interface TextInputProps {
  label?: string;
  placeholder: string;
  name: string;
  value?: string | undefined;
  errors?: any;
  type: InputType;
  control?: any;
  maxLength?: number;
  onClick?: () => void;
  isSoundkit?: boolean;
  id?: string;
}

export default function TextInputController({
  label,
  name,
  placeholder,
  errors,
  type,
  control,
  maxLength,
  onClick,
  isSoundkit,
  ...restProps
}: TextInputProps) {
  return (
    <TextInput
      type={type}
      name={name}
      border="none"
      labelClassName="label-add-track"
      backgroundColor="#25252A"
      label={label}
      placeholder={placeholder}
      control={control}
      errors={errors}
      maxLength={maxLength}
      handleClick={onClick}
      isSoundkit={isSoundkit}
      {...restProps}
    />
  );
}

TextInputController.defaultProps = {
  title: "Title",
  placeholder: "Placeholder",
  type: "text",
};
