import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import Logo from "app/assets/images/logo.svg";
import { InputItem } from "app/components/InputItem";
import { successSelector } from "app/redux/selectors/status";
import { useResetPassword } from "hooks/resetPassword/useResetPassword";
import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { RootState } from "types";

export const ResetPassword = memo(() => {
  const { control, handleSubmit, errors, isLoading, onSubmit } =
    useResetPassword();
  const { t } = useTranslation();
  const history = useHistory();

  const isSuccess = useSelector((state: RootState) =>
    successSelector(["RESET-PASSWORD"], state)
  );

  useEffect(() => {
    if (isSuccess) history.push("/auth/login");
  }, [history, isSuccess]);

  return (
    <Flex w="100%" px="16px">
      <Box bg="#111518" w={{ base: "100%", md: "400px" }} m="auto">
        <Box>
          <Image src={Logo} w="214px" h="24px" />
          <Text
            fontWeight="600"
            fontSize="30px"
            lineHeight="40px"
            mt="20px"
            mb="15px"
          >
            {t("ResetYourPassword")}
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex>
              <Text
                pr="1px"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                {t("password")}
              </Text>
              <Text
                color="#C8E815"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                *
              </Text>
            </Flex>
            <InputItem
              placeholder={"password"}
              control={control}
              name="password"
              type="password"
              errors={errors.password}
            />
            <Flex mt="20px">
              <Text
                pr="1px"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                {t("confirmPassword")}
              </Text>
              <Text
                color="#C8E815"
                fontSize="14px"
                fontWeight={600}
                lineHeight="17.5px"
              >
                *
              </Text>
            </Flex>
            <InputItem
              placeholder={"Confirm password"}
              control={control}
              name="confirmPassword"
              type="password"
              errors={errors.confirmPassword}
            />
            <Button
              mt="36px"
              borderRadius="24px"
              type="submit"
              isLoading={isLoading}
              bg="#C8E815"
              color="#101518"
              _hover={{ bg: "#C8E815" }}
              _active={{ bg: "#C8E815" }}
              w="100%"
              size="lg"
              spinner={<BeatLoader size={8} color="white" />}
            >
              {t("Send")}
            </Button>
            <Text
              textAlign="center"
              mt={{ base: "50px", md: "100px" }}
              color="#343434"
            >
              {t("Superproductor")}
            </Text>
          </form>
        </Box>
      </Box>
    </Flex>
  );
});
