import React from "react";

const PaypalIconCheckout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="16"
      viewBox="0 0 61 16"
      fill="none"
    >
      <path
        d="M22.9438 3.95703H19.628C19.4011 3.95703 19.2081 4.12188 19.1727 4.34588L17.8316 12.8487C17.8049 13.0164 17.9349 13.1677 18.1051 13.1677H19.6881C19.915 13.1677 20.108 13.0028 20.1434 12.7784L20.5051 10.485C20.54 10.2605 20.7334 10.0957 20.9598 10.0957H22.0095C24.1938 10.0957 25.4544 9.03873 25.7836 6.94418C25.932 6.02782 25.7899 5.30782 25.3608 4.80358C24.8895 4.24988 24.0537 3.95703 22.9438 3.95703ZM23.3264 7.06249C23.1451 8.2523 22.236 8.2523 21.3569 8.2523H20.8566L21.2076 6.03024C21.2285 5.89594 21.3448 5.79703 21.4806 5.79703H21.7099C22.3087 5.79703 22.8735 5.79703 23.1654 6.13836C23.3395 6.342 23.3928 6.64455 23.3264 7.06249Z"
        fill="#253B80"
      />
      <path
        d="M32.856 7.02433H31.2681C31.1328 7.02433 31.016 7.12324 30.9951 7.25755L30.9248 7.70167L30.8138 7.5407C30.4701 7.04179 29.7035 6.875 28.9384 6.875C27.1837 6.875 25.6851 8.20397 25.3932 10.0682C25.2414 10.9982 25.4572 11.8874 25.9847 12.5075C26.4686 13.0777 27.161 13.3152 27.9847 13.3152C29.3985 13.3152 30.1825 12.4062 30.1825 12.4062L30.1117 12.8474C30.0851 13.0161 30.215 13.1674 30.3842 13.1674H31.8145C32.0419 13.1674 32.2339 13.0025 32.2698 12.778L33.128 7.34336C33.1551 7.17609 33.0257 7.02433 32.856 7.02433ZM30.6427 10.1148C30.4894 11.0219 29.7694 11.6309 28.8511 11.6309C28.3901 11.6309 28.0216 11.483 27.785 11.2028C27.5503 10.9245 27.4611 10.5283 27.5357 10.0871C27.6788 9.18773 28.4109 8.55888 29.3151 8.55888C29.7661 8.55888 30.1326 8.7087 30.3741 8.99136C30.616 9.27694 30.712 9.67548 30.6427 10.1148Z"
        fill="#253B80"
      />
      <path
        d="M41.3122 7.02344H39.7165C39.5643 7.02344 39.4213 7.09907 39.335 7.22562L37.1342 10.4673L36.2014 7.35217C36.1427 7.15726 35.9628 7.02344 35.7592 7.02344H34.1912C34.0007 7.02344 33.8683 7.20962 33.9289 7.38901L35.6865 12.5468L34.0341 14.8794C33.9042 15.0632 34.0351 15.3158 34.2596 15.3158H35.8533C36.0045 15.3158 36.1461 15.2421 36.2319 15.118L41.5391 7.45738C41.6661 7.2741 41.5357 7.02344 41.3122 7.02344Z"
        fill="#253B80"
      />
      <path
        d="M46.5962 3.95703H43.2798C43.0534 3.95703 42.8604 4.12188 42.825 4.34588L41.484 12.8487C41.4573 13.0164 41.5872 13.1677 41.7564 13.1677H43.4583C43.6163 13.1677 43.7516 13.0523 43.7763 12.8952L44.1569 10.485C44.1918 10.2605 44.3853 10.0957 44.6117 10.0957H45.6609C47.8456 10.0957 49.1058 9.03873 49.4355 6.94418C49.5843 6.02782 49.4413 5.30782 49.0122 4.80358C48.5414 4.24988 47.706 3.95703 46.5962 3.95703ZM46.9787 7.06249C46.7979 8.2523 45.8888 8.2523 45.0093 8.2523H44.5094L44.8609 6.03024C44.8818 5.89594 44.9972 5.79703 45.1334 5.79703H45.3627C45.961 5.79703 46.5264 5.79703 46.8183 6.13836C46.9923 6.342 47.0452 6.64455 46.9787 7.06249Z"
        fill="#179BD7"
      />
      <path
        d="M56.5063 7.02433H54.9194C54.7831 7.02433 54.6672 7.12324 54.6469 7.25755L54.5766 7.70167L54.465 7.5407C54.1213 7.04179 53.3552 6.875 52.5901 6.875C50.8355 6.875 49.3373 8.20397 49.0454 10.0682C48.8941 10.9982 49.1089 11.8874 49.6364 12.5075C50.1213 13.0777 50.8127 13.3152 51.6364 13.3152C53.0503 13.3152 53.8343 12.4062 53.8343 12.4062L53.7635 12.8474C53.7368 13.0161 53.8667 13.1674 54.0369 13.1674H55.4667C55.6932 13.1674 55.8861 13.0025 55.9215 12.778L56.7802 7.34336C56.8064 7.17609 56.6764 7.02433 56.5063 7.02433ZM54.2929 10.1148C54.1407 11.0219 53.4197 11.6309 52.5014 11.6309C52.0413 11.6309 51.6718 11.483 51.4352 11.2028C51.2006 10.9245 51.1123 10.5283 51.186 10.0871C51.33 9.18773 52.0612 8.55888 52.9654 8.55888C53.4163 8.55888 53.7829 8.7087 54.0243 8.99136C54.2672 9.27694 54.3632 9.67548 54.2929 10.1148Z"
        fill="#179BD7"
      />
      <path
        d="M58.3781 4.19073L57.0172 12.8492C56.9905 13.0169 57.1204 13.1682 57.2896 13.1682H58.6579C58.8853 13.1682 59.0782 13.0033 59.1132 12.7788L60.4552 4.27655C60.4819 4.10879 60.3519 3.95703 60.1827 3.95703H58.6506C58.5153 3.95752 58.399 4.05643 58.3781 4.19073Z"
        fill="#179BD7"
      />
      <path
        d="M4.06122 14.8185L4.3148 13.2078L3.74995 13.1947H1.05273L2.92716 1.30963C2.93298 1.27375 2.95189 1.24029 2.97952 1.21654C3.00716 1.19278 3.04255 1.17969 3.0794 1.17969H7.62728C9.1371 1.17969 10.179 1.49387 10.723 2.11399C10.9781 2.4049 11.1405 2.7089 11.219 3.04344C11.3015 3.39448 11.3029 3.81387 11.2224 4.32538L11.2166 4.36272V4.69048L11.4716 4.83496C11.6864 4.9489 11.8571 5.07932 11.988 5.22866C12.2062 5.47738 12.3473 5.79351 12.4069 6.16829C12.4685 6.55375 12.4481 7.01241 12.3473 7.53169C12.2309 8.12902 12.0428 8.64926 11.7887 9.07496C11.555 9.4672 11.2573 9.79254 10.9039 10.0447C10.5664 10.2842 10.1655 10.466 9.71213 10.5824C9.27285 10.6968 8.77201 10.7545 8.22267 10.7545H7.86873C7.61564 10.7545 7.36983 10.8456 7.17686 11.009C6.9834 11.1758 6.8554 11.4037 6.81613 11.6529L6.78946 11.7979L6.34146 14.6367L6.3211 14.7409C6.31576 14.7739 6.30655 14.7904 6.29298 14.8015C6.28086 14.8117 6.2634 14.8185 6.24643 14.8185H4.06122Z"
        fill="#253B80"
      />
      <path
        d="M11.7134 4.40234C11.6999 4.48913 11.6844 4.57786 11.6669 4.66901C11.0671 7.74828 9.01526 8.81204 6.39466 8.81204H5.06036C4.73987 8.81204 4.46981 9.04477 4.41987 9.36089L3.73672 13.6935L3.54326 14.9216C3.51078 15.1291 3.67078 15.3163 3.88023 15.3163H6.24678C6.52702 15.3163 6.76508 15.1126 6.8092 14.8363L6.83248 14.716L7.27805 11.8884L7.30666 11.7333C7.35029 11.4559 7.58884 11.2523 7.86908 11.2523H8.22302C10.5159 11.2523 12.3108 10.3214 12.8354 7.62756C13.0545 6.50222 12.9411 5.56259 12.3612 4.90174C12.1857 4.70246 11.968 4.53713 11.7134 4.40234Z"
        fill="#179BD7"
      />
      <path
        d="M11.0861 4.14887C10.9945 4.1222 10.8999 4.09796 10.803 4.07614C10.7055 4.05481 10.6056 4.0359 10.5028 4.01941C10.1431 3.96123 9.74889 3.93359 9.32659 3.93359H5.76198C5.67422 3.93359 5.59083 3.95347 5.51616 3.98935C5.3518 4.06838 5.22962 4.22402 5.20004 4.41456L4.44174 9.21747L4.41992 9.35759C4.46986 9.04147 4.73992 8.80874 5.06041 8.80874H6.39471C9.01532 8.80874 11.0672 7.7445 11.667 4.66571C11.6849 4.57456 11.6999 4.48584 11.7135 4.39905C11.5617 4.31856 11.3974 4.24971 11.2204 4.19105C11.1768 4.1765 11.1317 4.16244 11.0861 4.14887Z"
        fill="#222D65"
      />
      <path
        d="M5.20039 4.41644C5.22997 4.2259 5.35215 4.07026 5.51651 3.99171C5.59166 3.95584 5.67457 3.93596 5.76233 3.93596H9.32693C9.74924 3.93596 10.1434 3.96359 10.5032 4.02178C10.606 4.03826 10.7058 4.05717 10.8033 4.0785C10.9003 4.10032 10.9948 4.12456 11.0864 4.15123C11.132 4.16481 11.1771 4.17887 11.2212 4.19293C11.3982 4.25159 11.5626 4.32093 11.7143 4.40093C11.8928 3.26299 11.7129 2.4882 11.0976 1.78662C10.4193 1.01426 9.19506 0.683594 7.62851 0.683594H3.08063C2.76063 0.683594 2.48766 0.916321 2.43821 1.23293L0.543906 13.2402C0.506572 13.4778 0.689845 13.6921 0.92936 13.6921H3.73712L4.44209 9.21935L5.20039 4.41644Z"
        fill="#253B80"
      />
    </svg>
  );
};
export default PaypalIconCheckout;
