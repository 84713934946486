import { Box, Flex, Text, Divider } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useReferrals } from "hooks/Referrals/useReferrals";
import EmptyRecentReferrals from "./emptyRecentReferrals";

export default function RecentReferrals() {
  const { t } = useTranslation();
  const { referrals } = useReferrals();

  return (
    <Box mt="16px">
      <Box
        borderRadius="8px"
        background="rgba(56, 56, 61, 0.25)"
        padding={{ base: "40px 16px", md: "40px 20px", lg: "40px" }}
      >
        <Text
          fontSize={{ base: "18px", md: "20px" }}
          fontWeight="400"
          mb="35px"
          color="#D3D3D4"
        >
          {t("recentReferrals")}
        </Text>
        <Flex mb="20px" justifyContent="space-between" alignItems="center">
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight="400"
            color="#D3D3D4"
          >
            {t("users")}
          </Text>
          <Text
            fontSize={{ base: "18px", md: "20px" }}
            fontWeight="400"
            color="#D3D3D4"
          >
            {t("comissionsEarn")}
          </Text>
        </Flex>

        {referrals?.length > 0 ? (
          (referrals || [])?.map((item, index) => (
            <Box key={index}>
              <Flex
                padding="25px 10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
                  {index + 1}. {item?.referred?.email}
                </Text>
                <Text fontSize={{ base: "14px", md: "16px" }} fontWeight="400">
                  ${item?.earned}
                </Text>
              </Flex>
              <Divider borderColor="#2D2E33" />
            </Box>
          ))
        ) : (
          <EmptyRecentReferrals />
        )}
      </Box>
    </Box>
  );
}
