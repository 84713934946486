import React from "react";

const Playlist = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1 13.6H4.5C3.94305 13.6 3.4089 13.3788 3.01508 12.9849C2.62125 12.5911 2.4 12.057 2.4 11.5V4.5C2.4 4.31435 2.32625 4.1363 2.19497 4.00503C2.0637 3.87375 1.88565 3.8 1.7 3.8C1.51435 3.8 1.3363 3.87375 1.20503 4.00503C1.07375 4.1363 1 4.31435 1 4.5V11.5C1 12.4283 1.36875 13.3185 2.02513 13.9749C2.6815 14.6313 3.57174 15 4.5 15H10.1C10.2857 15 10.4637 14.9263 10.595 14.795C10.7263 14.6637 10.8 14.4857 10.8 14.3C10.8 14.1143 10.7263 13.9363 10.595 13.805C10.4637 13.6737 10.2857 13.6 10.1 13.6ZM5.9 8.7C5.9 8.88565 5.97375 9.0637 6.10503 9.19497C6.2363 9.32625 6.41435 9.4 6.6 9.4H10.1C10.2857 9.4 10.4637 9.32625 10.595 9.19497C10.7263 9.0637 10.8 8.88565 10.8 8.7C10.8 8.51435 10.7263 8.3363 10.595 8.20503C10.4637 8.07375 10.2857 8 10.1 8H6.6C6.41435 8 6.2363 8.07375 6.10503 8.20503C5.97375 8.3363 5.9 8.51435 5.9 8.7ZM13.6 5.858C13.5927 5.79369 13.5786 5.73034 13.558 5.669V5.606C13.5243 5.53403 13.4794 5.46787 13.425 5.41L9.225 1.21C9.16714 1.15555 9.10098 1.11066 9.029 1.077C9.00811 1.07403 8.9869 1.07403 8.966 1.077C8.89489 1.03622 8.81636 1.01004 8.735 1H5.9C5.34305 1 4.8089 1.22125 4.41508 1.61508C4.02125 2.0089 3.8 2.54305 3.8 3.1V10.1C3.8 10.657 4.02125 11.1911 4.41508 11.5849C4.8089 11.9788 5.34305 12.2 5.9 12.2H11.5C12.057 12.2 12.5911 11.9788 12.9849 11.5849C13.3788 11.1911 13.6 10.657 13.6 10.1V5.9C13.6 5.9 13.6 5.9 13.6 5.858ZM9.4 3.387L11.213 5.2H10.1C9.91435 5.2 9.7363 5.12625 9.60503 4.99497C9.47375 4.8637 9.4 4.68565 9.4 4.5V3.387ZM12.2 10.1C12.2 10.2857 12.1263 10.4637 11.995 10.595C11.8637 10.7262 11.6857 10.8 11.5 10.8H5.9C5.71435 10.8 5.5363 10.7262 5.40503 10.595C5.27375 10.4637 5.2 10.2857 5.2 10.1V3.1C5.2 2.91435 5.27375 2.7363 5.40503 2.60503C5.5363 2.47375 5.71435 2.4 5.9 2.4H8V4.5C8.00189 4.7388 8.04451 4.97553 8.126 5.2H6.6C6.41435 5.2 6.2363 5.27375 6.10503 5.40503C5.97375 5.5363 5.9 5.71435 5.9 5.9C5.9 6.08565 5.97375 6.2637 6.10503 6.39497C6.2363 6.52625 6.41435 6.6 6.6 6.6H12.2V10.1Z"
        fill="#D4F70E"
        stroke="#1B1B20"
        strokeWidth="0.5"
      />
    </svg>
  );
};
export default Playlist;
