import {
  Box,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useOutsideClick,
} from "@chakra-ui/react";
import UnMutedAudioIcon from "app/assets/icons/UnMutedAudio";
import MutedAudioIcon from "app/assets/icons/MutedAudio";
import React, { useRef, useState } from "react";
import "./styles.scss";

interface ISoundControlProps {
  onVolumeChange: (value: number) => void;
  volume: number;
}

const SoundControl = ({
  volume,
  onVolumeChange,
}: ISoundControlProps): JSX.Element => {
  const soundref = useRef<any>(null);
  const [isDisplayControl, toggleDisplayControl] = useState(false);

  useOutsideClick({
    ref: soundref,
    handler: () => toggleDisplayControl(false),
  });

  return (
    <Box
      ref={soundref}
      cursor="pointer"
      position="relative"
      display={{ base: "none", xl: "flex" }}
    >
      {isDisplayControl && (
        <Flex
          height="5x"
          width="100px"
          position="absolute"
          bottom="7px"
          left="-110px"
          justifyContent="center"
          className="sound-control-player"
        >
          <Slider onChange={onVolumeChange} value={volume * 100}>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </Flex>
      )}

      <Box onClick={() => toggleDisplayControl((val) => !val)}>
        {volume > 0 ? <UnMutedAudioIcon volume={volume} /> : <MutedAudioIcon />}
      </Box>
    </Box>
  );
};

export default React.memo(SoundControl);
