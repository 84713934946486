import { useCallback, useState } from "react";
import { uploadFileService } from "services/uploadFile";

interface FileUploadProps {
  onValueChange?: (url: string | void) => void;
  fileType?: string;
}

const useFileUpload = ({
  onValueChange,
}: FileUploadProps): [number, (file: File) => void] => {
  const [progress, setProgress] = useState<number>(0);

  const handleUpload = useCallback(
    async (file: File) => {
      try {
        const url = await uploadFileService(file, setProgress);
        onValueChange && onValueChange(url);
        setProgress(0);
      } catch (err) {
        setProgress(0);
        throw err;
      }
    },
    [setProgress, onValueChange]
  );

  return [progress, handleUpload];
};

export default useFileUpload;
