import { Text } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

interface LinkToTrackOrProfileProps {
  name: string;
  type: string;
}

export default function LinkToTrackOrProfile({
  name,
  type,
}: LinkToTrackOrProfileProps) {
  return (
    <Link to={`/${type}/${name}`}>
      <Text
        as="span"
        _hover={{
          textDecor: "underline",
        }}
      >
        {name}
      </Text>
    </Link>
  );
}
