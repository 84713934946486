import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import IconFilterSearch from "app/assets/icons/IconFilterSearch";
import MyCreditProfile from "app/components/MyCreditProfile";
import MyFeed from "app/components/MyFeed";
import SkeletonPlaylistItemProfile from "app/components/MyFeed/skeletonPlaylistItemProfile";
import MyPlaylistsProfile from "app/components/MyPlaylistsProfile";
import MySoundKitProfile from "app/components/MySoundKitProfile";
import MyTrackProfile from "app/components/MyTrackProfile";
import SkeletonTrackItem from "app/components/Skeleton/skeletonTrackItem";
import { User } from "app/models";
import { PROFILE_TYPE } from "constants/contractType";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ModalFilterTrendingBeats from "../Home/modalFilterTrendingBeats";
import CustomSortBy from "./customSortBy";
import "./styles.scss";
import CurrentRefinements from "../SearchFilterPage/CurrentRefinements";
import SkeletonCreditProfile from "app/components/MyCreditProfile/skeletonCreditProfile";
interface TabListProfileProps {
  userById: User;
  tabActive: number;
  setTabActive: (value: number) => void;
  isLoading?: boolean;
}

function TabListProfile({
  userById,
  tabActive,
  setTabActive,
  isLoading,
}: TabListProfileProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const typeParams = queryString.parse(location.search);
  const [showModalFilter, setShowModalFilter] = useState(false);
  const [widthFeed, setWidthFeed] = useState(false);

  const nameParams = useCallback((value) => {
    const textParams: { [index: number]: string } = {
      0: PROFILE_TYPE.LAST_ACTIVITY,
      1: PROFILE_TYPE.TRACKS,
      2: PROFILE_TYPE.SOUND_PACKS,
      3: PROFILE_TYPE.PLAYLISTS,
      4: PROFILE_TYPE.CREDITS,
    };
    return textParams[value];
  }, []);

  const sortByAlolia = useMemo(
    () => [
      { label: t("sortBy"), value: process.env.REACT_APP_KEY_TRACKS_SEARCH },
      {
        label: t("lowestPrice"),
        value: process.env.REACT_APP_KEY_TRACKS_SEARCH_PRICE_ASC,
      },
      {
        label: t("highestPrice"),
        value: process.env.REACT_APP_KEY_TRACKS_SEARCH_PRICE_DESC,
      },
    ],
    [t]
  );

  const listTabMenu = useMemo(
    () => [
      {
        id: 0,
        name: t("LastActivity"),
      },
      {
        id: 1,
        name: t("Tracks"),
      },
      {
        id: 2,
        name: t("SoundKits"),
      },
      {
        id: 3,
        name: t("playlists"),
      },
      {
        id: 4,
        name: t("Credits"),
      },
    ],
    [t]
  );

  useEffect(() => {
    switch (typeParams.profileType) {
      case PROFILE_TYPE.LAST_ACTIVITY:
        setTabActive(0);
        break;
      case PROFILE_TYPE.TRACKS:
        setTabActive(1);
        break;
      case PROFILE_TYPE.SOUND_PACKS:
        setTabActive(2);
        break;
      case PROFILE_TYPE.PLAYLISTS:
        setTabActive(3);
        break;
      case PROFILE_TYPE.CREDITS:
        setTabActive(4);
        break;
      default:
        break;
    }
  }, [setTabActive, typeParams.profileType]);

  const handleChangTabsProfile = useCallback(
    (value) => {
      setTabActive(value);
      const nameRouter = nameParams(value);
      history.push(`?profileType=${nameRouter}`);
    },
    [history, nameParams, setTabActive]
  );

  const heightLoadMore = useCallback((e) => {
    if (e.target.documentElement.scrollTop >= 300) {
      setWidthFeed(true);
    } else {
      setWidthFeed(false);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("scroll", heightLoadMore);
    return () => {
      window.removeEventListener("scroll", heightLoadMore);
    };
  }, [heightLoadMore]);

  return (
    <>
      <Box
        w={{ base: "100%", md: "60%", lg: "70%", xl: "73%" }}
        display={{ base: "block", md: "block" }}
      >
        <Tabs
          onChange={(value) => handleChangTabsProfile(value)}
          index={tabActive}
          variant="unstyled"
          className="tabs-panels"
          w="100%"
        >
          <Box position="sticky" top="95px" zIndex={90}>
            <TabList
              className="tab-list-panels"
              background="none"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              backgroundColor={{ base: "#25252A", md: "#18181c" }}
              margin={{ base: widthFeed ? "0px" : "0px 10px", md: "0px" }}
              borderRadius={{ base: widthFeed ? "0px" : "8px", md: "0px" }}
            >
              <Flex
                justifyContent={{ base: "space-evenly", md: "unset" }}
                w="100%"
              >
                {listTabMenu.map((item) => (
                  <Tab
                    key={item.id}
                    _selected={{
                      color: "#FFFFFF",
                    }}
                    color="#49494D"
                    className="tab"
                    _focus={{ border: "none" }}
                    fontFamily="Cera Pro Medium"
                    _hover={{
                      border: "1px solid #38383D",
                      background: "#222227 !important",
                      borderRadius: "6px",
                    }}
                    borderColor="#38383D"
                  >
                    <Text
                      padding={{ base: "10px 0px", md: "0px 5px" }}
                      fontSize={{ base: "14px", md: "16px" }}
                    >
                      {item.name}
                    </Text>
                  </Tab>
                ))}
              </Flex>
              {tabActive === 1 && userById?._id && (
                <CustomSortBy sortBy={sortByAlolia} />
              )}
            </TabList>
            <Flex
              px="16px"
              pt="24px"
              pb="8px"
              gap="8px"
              display={{ base: "flex", md: "none" }}
              justifyContent="space-between"
              alignItems="center"
              backgroundColor="#18181c"
            >
              <Text>
                {tabActive === 0 && t("LastActivity")}
                {tabActive === 1 && t("Tracks")}
                {tabActive === 2 && t("SoundKits")}
                {tabActive === 3 && t("playlists")}
                {tabActive === 4 && t("Credits")}
              </Text>
              {(tabActive === 1 || tabActive === 2) && (
                <Flex
                  alignItems="center"
                  gap="8px"
                  onClick={() => setShowModalFilter(true)}
                >
                  <IconFilterSearch />
                  <Text fontSize="14px" color="#858585">
                    Filter
                  </Text>
                </Flex>
              )}
            </Flex>
            {(tabActive === 1 || tabActive === 2) && (
              <Box
                padding={{ base: "0px 16px", xl: "0px 50px" }}
                backgroundColor="#18181c"
              >
                <CurrentRefinements />
              </Box>
            )}
          </Box>

          <TabPanels overflow="auto" position="sticky" top="0px">
            <TabPanel>
              {tabActive === 0 && <MyFeed userId={userById?._id} />}
            </TabPanel>
            <TabPanel overflow="scroll" height="120vh">
              {tabActive === 1 && userById?._id ? (
                <MyTrackProfile userId={userById?._id} />
              ) : (
                <SkeletonTrackItem isProfile />
              )}
            </TabPanel>
            <TabPanel overflow="scroll" height="120vh">
              {tabActive === 2 && userById?._id ? (
                <MySoundKitProfile userId={userById?._id} />
              ) : (
                <SkeletonTrackItem isProfile />
              )}
            </TabPanel>
            <TabPanel>
              {tabActive === 3 && userById?._id ? (
                <MyPlaylistsProfile userId={userById?._id} />
              ) : (
                <SkeletonPlaylistItemProfile />
              )}
            </TabPanel>
            <TabPanel>
              {tabActive === 4 && userById?._id ? (
                <MyCreditProfile />
              ) : (
                <SkeletonCreditProfile />
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      {(tabActive === 1 || tabActive === 2) && (
        <Box display={showModalFilter ? "block" : "none"}>
          <ModalFilterTrendingBeats
            setShowModal={setShowModalFilter}
            isTrackSearch={true}
          />
        </Box>
      )}
    </>
  );
}

export default React.memo(TabListProfile);
