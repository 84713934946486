import { createReducer } from "@reduxjs/toolkit";
import { fetchGenres } from "../actions/genres";

export interface InitGenresState {
  data: [];
}

const initialState: InitGenresState = {
  data: [],
};

export default createReducer(initialState, (builder) =>
  builder.addCase(fetchGenres.fulfilled, (state, action) => {
    return {
      ...state,
      data: action.payload,
    };
  })
);
