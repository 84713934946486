import { createSelector } from "@reduxjs/toolkit";
import { Mood } from "app/models";
import { InitTrackState } from "app/redux/reducers/track";

export const selectTrack = (state: { track: InitTrackState }) => state.track;

export const selectAllMoods = createSelector(selectTrack, ({ moods }) =>
  moods.map((mood: Mood) => ({
    label: mood.displayName,
    value: mood.displayName,
  }))
);

export const selectNewTrack = createSelector(
  selectTrack,
  ({ newTrack, collaborators }) => {
    const userIds = collaborators.map((item) => item.user);

    return {
      ...newTrack,
      collaborators: [
        ...collaborators,
        ...newTrack.collaborators.filter(
          (item) => !userIds.includes(item?.user)
        ),
      ],
    };
  }
);

export const selectCollaborators = createSelector(
  selectTrack,
  ({ collaborators }) => collaborators || []
);

export const selectListCollaborators = createSelector(
  selectTrack,
  ({ listCollaborators }) => listCollaborators || []
);

export const lyricsSelector = createSelector(selectTrack, ({ lyricsTrack }) => {
  return lyricsTrack;
});

export const isUploadTrackSelector = createSelector(
  selectTrack,
  ({ isUploadTrack }) => {
    return isUploadTrack;
  }
);

export const allLyricsSelector = createSelector(
  selectTrack,
  ({ trackHadLyrics }) => {
    return trackHadLyrics;
  }
);

export const filesAutoTaggedSelector = createSelector(
  selectTrack,
  ({ filesAutoTagged }) => {
    return filesAutoTagged;
  }
);

export const filesCustomTaggedSelector = createSelector(
  selectTrack,
  ({ filesCustomTagged }) => {
    return filesCustomTagged;
  }
);

export const filesTrackoutStemsSelector = createSelector(
  selectTrack,
  ({ filesTrackoutStems }) => {
    return filesTrackoutStems;
  }
);
