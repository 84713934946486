import React from "react";

const MonneyButton = () => {
  return (
    <svg
      width="13"
      height="24"
      viewBox="0 0 13 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.55561 10.9022C8.61382 10.3817 7.61255 9.98518 6.62119 9.57377C6.0462 9.33584 5.49599 9.05826 5.01023 8.67163C4.05357 7.90829 4.23697 6.66909 5.3572 6.17837C5.67444 6.03958 6.00654 5.99497 6.34361 5.97514C7.64229 5.90574 8.87653 6.14367 10.0513 6.70874C10.6362 6.99128 10.8295 6.90206 11.0278 6.29237C11.236 5.64799 11.4094 4.99369 11.6028 4.34435C11.7316 3.90816 11.573 3.62066 11.1616 3.43726C10.4082 3.10516 9.63492 2.86723 8.822 2.73835C7.76125 2.57478 7.76125 2.56982 7.75629 1.50411C7.75134 0.00220305 7.75134 0.00220302 6.24447 0.00220302C6.02637 0.00220302 5.80827 -0.00275377 5.59017 0.00220302C4.88631 0.0220302 4.76735 0.14595 4.74752 0.854771C4.7376 1.17201 4.74752 1.48924 4.74256 1.81143C4.73761 2.75322 4.73265 2.73835 3.83051 3.0655C1.64952 3.85859 0.301275 5.34563 0.157528 7.72489C0.028651 9.83152 1.12906 11.2541 2.85898 12.2901C3.92469 12.9295 5.10441 13.3062 6.23456 13.8069C6.67571 14.0002 7.09704 14.2232 7.46384 14.5306C8.54938 15.4277 8.35111 16.9197 7.06234 17.4848C6.37335 17.7872 5.6447 17.8615 4.89622 17.7674C3.74129 17.6236 2.63592 17.3212 1.595 16.781C0.985312 16.4637 0.806867 16.548 0.598682 17.2072C0.420237 17.7773 0.26162 18.3523 0.103003 18.9272C-0.110139 19.7005 -0.0308309 19.8839 0.707731 20.2457C1.64952 20.7018 2.65575 20.9347 3.68181 21.0983C4.48481 21.2272 4.50959 21.2619 4.51951 22.0946C4.52446 22.4713 4.52446 22.853 4.52942 23.2297C4.53438 23.7056 4.76239 23.9832 5.25311 23.9931C5.80827 24.003 6.36839 24.003 6.92355 23.9881C7.37958 23.9782 7.61255 23.7304 7.61255 23.2694C7.61255 22.7539 7.63733 22.2334 7.6175 21.7179C7.59272 21.1925 7.82073 20.9248 8.32632 20.786C9.49117 20.4688 10.4825 19.8442 11.2459 18.9124C13.3674 16.3348 12.5594 12.5627 9.55561 10.9022Z"
        fill="white"
      />
    </svg>
  );
};
export default MonneyButton;
