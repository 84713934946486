import React from "react";

const RemoveIcon = () => {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 4.00033H11.3333V3.16699C11.3333 2.50395 11.0699 1.86807 10.6011 1.39923C10.1323 0.930384 9.49637 0.666992 8.83333 0.666992H7.16667C6.50363 0.666992 5.86774 0.930384 5.3989 1.39923C4.93006 1.86807 4.66667 2.50395 4.66667 3.16699V4.00033H1.33333C1.11232 4.00033 0.900358 4.08812 0.744078 4.2444C0.587797 4.40068 0.5 4.61265 0.5 4.83366C0.5 5.05467 0.587797 5.26663 0.744078 5.42291C0.900358 5.5792 1.11232 5.66699 1.33333 5.66699H2.16667V14.8337C2.16667 15.4967 2.43006 16.1326 2.8989 16.6014C3.36774 17.0703 4.00363 17.3337 4.66667 17.3337H11.3333C11.9964 17.3337 12.6323 17.0703 13.1011 16.6014C13.5699 16.1326 13.8333 15.4967 13.8333 14.8337V5.66699H14.6667C14.8877 5.66699 15.0996 5.5792 15.2559 5.42291C15.4122 5.26663 15.5 5.05467 15.5 4.83366C15.5 4.61265 15.4122 4.40068 15.2559 4.2444C15.0996 4.08812 14.8877 4.00033 14.6667 4.00033ZM6.33333 3.16699C6.33333 2.94598 6.42113 2.73402 6.57741 2.57774C6.73369 2.42146 6.94565 2.33366 7.16667 2.33366H8.83333C9.05435 2.33366 9.26631 2.42146 9.42259 2.57774C9.57887 2.73402 9.66667 2.94598 9.66667 3.16699V4.00033H6.33333V3.16699ZM12.1667 14.8337C12.1667 15.0547 12.0789 15.2666 11.9226 15.4229C11.7663 15.5792 11.5543 15.667 11.3333 15.667H4.66667C4.44565 15.667 4.23369 15.5792 4.07741 15.4229C3.92113 15.2666 3.83333 15.0547 3.83333 14.8337V5.66699H12.1667V14.8337Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default RemoveIcon;
