import { Divider, SimpleGrid, Text } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputField } from "../CustomFIeld/TextField/InputField";
import "./styles.scss";

interface ExclusiveProps {
  disable: boolean[];
}

export default function Exclusive({ disable }: ExclusiveProps) {
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  return (
    <>
      <Text mt="40px" fontSize="24px">
        {t("QuickCustomization")}
      </Text>
      <Divider borderColor="#24252a" mt="10px" />
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} mt="25px" spacing="15px">
        <InputField
          name="beatsPublishing"
          label={t("BeatsPublishing")}
          placeholder={t("enterBeatsPublishing")}
          type="number"
          control={control}
          disabled={disable[0]}
          errors={formState.errors}
        />
        <InputField
          name="beatsHookPublishing"
          label={t("BeatsWithHook")}
          placeholder={t("enterBeatsHookPublishing")}
          type="number"
          control={control}
          disabled={disable[1]}
          errors={formState.errors}
        />
        <InputField
          name="topLinesAndVocals"
          label={t("TopLines")}
          placeholder={t("enterTopLinesVocalsPublishing")}
          type="number"
          control={control}
          disabled={disable[2]}
          errors={formState.errors}
        />
      </SimpleGrid>
    </>
  );
}
