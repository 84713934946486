import { createSelector } from "@reduxjs/toolkit";

export const selectAnalytics = (state: any) => state.analytics;

export const selectorsPlaysAnalytics = createSelector(
  selectAnalytics,
  ({ playsAnalytics }) => {
    return playsAnalytics;
  }
);
export const selectorsPurchasedAnalytics = createSelector(
  selectAnalytics,
  ({ purchasedAnalytics }) => {
    return purchasedAnalytics;
  }
);

export const selectorsSoldAnalytics = createSelector(
  selectAnalytics,
  ({ soldAnalytics }) => {
    return soldAnalytics;
  }
);

export const selectorsDownloadAnalytics = createSelector(
  selectAnalytics,
  ({ downloadAnalytics }) => {
    return downloadAnalytics;
  }
);

export const selectorsFavoritesAnalytics = createSelector(
  selectAnalytics,
  ({ favoritesAnalytics }) => {
    return favoritesAnalytics;
  }
);

export const selectorsListTracks = createSelector(
  selectAnalytics,
  ({ analyticsListTracks }) => {
    return analyticsListTracks;
  }
);

export const selectorsListUser = createSelector(
  selectAnalytics,
  ({ analyticsListUser }) => {
    return analyticsListUser;
  }
);

export const selectorsAnalyticsTopCountry = createSelector(
  selectAnalytics,
  ({ analyticsTopCountry }) => {
    return analyticsTopCountry;
  }
);

export const selectorsAnalyticsTracks = createSelector(
  selectAnalytics,
  ({ analyticsTracks }) => {
    return analyticsTracks;
  }
);

export const selectorsAnalyticsTotalSalesTracks = createSelector(
  selectAnalytics,
  ({ analyticsTotalSalesTracks }) => {
    return analyticsTotalSalesTracks;
  }
);

export const selectorsAnalyticsCurrentUser = createSelector(
  selectAnalytics,
  ({ analyticsCurrentUser }) => {
    return analyticsCurrentUser;
  }
);

export const selectorsLicenseExpiry = createSelector(
  selectAnalytics,
  ({ tracksUpcomingLicenseExpiry }) => {
    return tracksUpcomingLicenseExpiry;
  }
);

export const selectorsUserOverView = createSelector(
  selectAnalytics,
  ({ userOverView }) => {
    return userOverView;
  }
);

export const selectorsListArtistSpotify = createSelector(
  selectAnalytics,
  ({ listArtistSpotify }) => {
    return listArtistSpotify;
  }
);
export const selectorsListTrackSpotify = createSelector(
  selectAnalytics,
  ({ listTrackSpotify }) => {
    return listTrackSpotify;
  }
);

export const selectorsArtistsSpotifyOfUser = createSelector(
  selectAnalytics,
  ({ artistsSpotifyOfUser }) => {
    return artistsSpotifyOfUser;
  }
);

export const selectorsTracksSpotifyOfUser = createSelector(
  selectAnalytics,
  ({ tracksSpotifyOfUser }) => {
    return tracksSpotifyOfUser;
  }
);
