import { Button, Flex } from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";

interface SubmitButtonGroupProps {
  isSoundkit?: boolean;
  isLoading: boolean;
  onSubmitDraft: () => void;
  checkButtonSaveDraft?: boolean;
  isEdit?: boolean;
  width?: string;
  isDraft?: boolean;
}

export default function SubmitButtonGroup({
  isSoundkit,
  isLoading,
  onSubmitDraft,
  checkButtonSaveDraft,
  isEdit,
  width = "100%",
  isDraft,
}: SubmitButtonGroupProps) {
  const { t } = useTranslation();
  const history = useHistory();

  const textButton = useMemo(() => {
    if (isEdit && isSoundkit && isDraft) {
      return t("publishSoundkit");
    }
    if (isEdit && isSoundkit) {
      return t("saveChanges");
    }
    if (isDraft && isEdit) {
      return t("publishTrack");
    }
    if (isEdit) {
      return t("saveChanges");
    }
    if (isSoundkit) {
      return t("publishSoundkit");
    }
    return t("publishTrack");
  }, [isDraft, isEdit, isSoundkit, t]);

  const handleCancelButton = useCallback(() => {
    isSoundkit
      ? history.push("dashboard/my-soundkits")
      : history.push("dashboard/my-track");
  }, [history, isSoundkit]);

  return (
    <Flex w={width} gridGap="10px" justifyContent="space-between">
      {checkButtonSaveDraft ? (
        <Button
          background="#24252A"
          p="30px 10px"
          borderRadius="8px"
          isLoading={isLoading}
          onClick={onSubmitDraft}
          className="fontCeraProRegular"
          fontSize="16px"
          whiteSpace="normal"
          w="50%"
          spinner={<BeatLoader size={8} color="white" />}
          _hover={{
            background: "#24252A",
          }}
          _active={{
            background: "#34391A",
          }}
        >
          {t("saveToDraft")}
        </Button>
      ) : (
        <Button
          background="#24252A"
          p="30px 10px"
          borderRadius="8px"
          isLoading={isLoading}
          onClick={handleCancelButton}
          className="fontCeraProRegular"
          fontSize="16px"
          whiteSpace="normal"
          w="50%"
          spinner={<BeatLoader size={8} color="white" />}
          _hover={{
            background: "#24252A",
          }}
          _active={{
            background: "#34391A",
          }}
        >
          {t("cancel")}
        </Button>
      )}

      <Button
        _hover={{
          background: "#24252A",
        }}
        background="#24252A"
        type="submit"
        p="30px 10px"
        isLoading={isLoading}
        borderRadius="8px"
        className="fontCeraProRegular"
        fontSize="16px"
        w={"50%"}
        whiteSpace="normal"
        spinner={<BeatLoader size={8} color="white" />}
        _active={{
          background: "#34391A",
        }}
      >
        {textButton}
      </Button>
    </Flex>
  );
}
