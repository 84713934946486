import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { CollaborationsModel } from "app/models";
import { TypeStatusCollaboration } from "constants/collaboratorType";
import { STATUS_COLOR } from "constants/negotiationType";
import { useCollaborations } from "hooks/Collaborations/useCollaborations";
import { useNegotiations } from "hooks/negotiations/useNegotiations";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import CollaborationsItem from "./collaborationsItem";
import EmptyCollaborations from "./emptyCollaborations";
import SkeletonCollaborations from "./skeletonCollaborations";

export default function CollaborationsDashboard() {
  const { t } = useTranslation();

  const { onGetCollaborations, collaborations, loading } = useCollaborations();
  const { countdownTime } = useNegotiations();
  useEffect(() => {
    onGetCollaborations();
  }, [onGetCollaborations]);

  const invitedCollaborations = (collaborations || [])?.filter(
    (item: CollaborationsModel) => item?.user?._id !== item?.createdBy?._id
  );

  const closeInvitations = useMemo(() => {
    return (invitedCollaborations || [])?.filter(
      (item: CollaborationsModel) =>
        (item?.status === STATUS_COLOR.PENDING &&
          countdownTime(item.updatedAt)?.time ===
            TypeStatusCollaboration.Expired) ||
        [STATUS_COLOR.ACCEPTED, STATUS_COLOR.REJECTED].includes(item?.status)
    );
  }, [countdownTime, invitedCollaborations]);

  const openInvitations = useMemo(() => {
    return (invitedCollaborations || [])?.filter(
      (item: CollaborationsModel) =>
        item?.status === STATUS_COLOR.PENDING &&
        countdownTime(item.updatedAt)?.time !== TypeStatusCollaboration.Expired
    );
  }, [countdownTime, invitedCollaborations]);

  const renderInvitations = (
    invitations: CollaborationsModel[],
    title: string,
    isExpired = false
  ) => {
    return (
      <Box>
        <Text
          fontSize={{ base: "20px", md: "22px", xl: "24px" }}
          lineHeight="24px"
          mb="25px"
          position={{ base: "static", md: "sticky" }}
          top="317px"
          background="#18181c"
          zIndex={90}
          fontFamily="Cera Pro Regular"
          textAlign={{ base: "center", md: "start" }}
        >
          {title}
        </Text>

        {invitations?.length > 0 ? (
          invitations?.map((collaboration: CollaborationsModel) => (
            <CollaborationsItem
              collaboration={collaboration}
              isExpired={isExpired}
              key={collaboration._id}
            />
          ))
        ) : (
          <EmptyCollaborations />
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        position={{ base: "static", md: "sticky" }}
        top="228px"
        zIndex={90}
        height="90px"
        background="#18181c"
        justifyContent={{ base: "center", md: "start" }}
        my="20px"
      >
        <Text fontSize={{ base: "28px", md: "32px" }}>
          {t("collaborations")}
        </Text>
      </Flex>
      {loading ? (
        <SkeletonCollaborations />
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="50px">
          {renderInvitations(openInvitations, t("openCollaborations"), true)}
          {renderInvitations(closeInvitations, t("closeInvitations"))}
        </SimpleGrid>
      )}
    </Box>
  );
}
