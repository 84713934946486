import { Box, Flex, Text } from "@chakra-ui/react";
import { Back } from "app/assets/icons";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ItemLyrics from "./ItemLyrics";
import ModalLyrics from "app/components/ModalShowLyrics";
import useTrackSelect from "hooks/track/useTrackSelect";
import { useHistory } from "react-router-dom";
import { useLyrics } from "hooks/lyrics/useLyrics";
import EmptyTrackSoundkit from "app/components/MySoundkits/emptyTrackSoundkit";
import SkeletonMyTrack from "app/components/Skeleton/skeletonMyTrack";
import ModalShowFreeDownload from "app/components/ModalShowFreeDownload";
import ModalPriceTracks from "app/components/ModalPriceTrack";
import ModalShowFreeDownloadFormMailChimp from "app/components/ModalShowFreeDownloadFormMailchimp";

export default function LyricsPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const { onGetAllLyrics, allLyrics, isLoading } = useLyrics();

  const {
    selectedTrack,
    onOpenOptionsModal,
    showLyrics,
    setShowLyrics,
    isEditLyrics,
    setIsEditLyrics,
    lyricsData,
    handleFreeDownload,
    showModalFreeDownload,
    setShowModalFreeDownload,
    linkTitleDownload,
    showOptionsModal,
    setShowOptionsModal,
    handleShowModalFreeDownloadMailchimp,
    showModalFreeMailchimp,
    setShowModalFreeMailchimp,
  } = useTrackSelect();

  const redirectDashboard = () => {
    history.push("/dashboard");
  };

  useEffect(() => {
    onGetAllLyrics();
  }, [onGetAllLyrics]);

  return (
    <Box padding={{ base: "20px", md: "30px", xl: "40px 80px" }}>
      <Flex alignItems="center" mb={{ base: "20px", xl: "32px" }}>
        <Box
          onClick={redirectDashboard}
          cursor="pointer"
          className="vector-button"
        >
          <Back />
        </Box>
        <Text fontSize={{ base: "24px", md: "28px", xl: "32px" }}>
          {t("lyrics")}
        </Text>
      </Flex>
      {isLoading ? (
        <Box mt="50px">
          <SkeletonMyTrack isLyricPage={true} />
        </Box>
      ) : allLyrics && allLyrics?.length > 0 ? (
        allLyrics?.map((item, index) => (
          <ItemLyrics
            onOpenOptionsModal={onOpenOptionsModal}
            index={index}
            lyricItem={item}
            key={item._id}
          />
        ))
      ) : (
        <EmptyTrackSoundkit />
      )}

      {showLyrics && (
        <ModalLyrics
          showModal={showLyrics}
          setShowModal={setShowLyrics}
          selectedTrack={selectedTrack}
          onOpenOptionsModal={onOpenOptionsModal}
          isEditLyrics={isEditLyrics}
          setIsEditLyrics={setIsEditLyrics}
          lyricsData={lyricsData}
        />
      )}
      {showOptionsModal && (
        <ModalPriceTracks
          selectedTrack={selectedTrack}
          showModal={showOptionsModal}
          setShowModal={setShowOptionsModal}
          handleFreeDownload={handleFreeDownload}
          handleShowModalFreeDownloadMailchimp={
            handleShowModalFreeDownloadMailchimp
          }
        />
      )}
      {showModalFreeDownload && (
        <ModalShowFreeDownload
          showModal={showModalFreeDownload}
          setShowModal={setShowModalFreeDownload}
          linkTitleFreeDownload={linkTitleDownload}
        />
      )}
      {showModalFreeMailchimp && (
        <ModalShowFreeDownloadFormMailChimp
          showModal={showModalFreeMailchimp}
          setShowModal={setShowModalFreeMailchimp}
          selectedTrack={selectedTrack}
          handleFreeDownload={handleFreeDownload}
        />
      )}
    </Box>
  );
}
