/**
 *
 * RegisterRouter
 *
 */
import React, { memo } from "react";
import { Route, RouteProps } from "react-router-dom";

interface RegisterRouterProps extends RouteProps {
  layout: any;
  component: any;
  name: string;
  path: string;
}

export const RegisterRouter = memo(
  ({
    layout: YourLayout,
    component: YourComponent,
    ...rest
  }: RegisterRouterProps) => {
    return (
      <Route
        {...rest}
        render={(routeProps) => (
          <YourLayout {...rest}>
            {YourComponent && <YourComponent {...routeProps} />}
          </YourLayout>
        )}
      />
    );
  }
);
