import { Box, Flex, Checkbox, Image } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import "./styles.scss";
import BackgroundImagePlaylistIcon from "app/assets/icons/BackgroundImagePlaylistIcon";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";

export default function SkeletonPlaylistItem() {
  const fakeDataSekeleton = Array.from({ length: 10 });

  return (
    <Box className="playlist-item">
      {fakeDataSekeleton.map((item, index) => (
        <Flex
          padding={{ base: "10px 0px 30px 0px", md: "2px 0px 15px 0px" }}
          borderBottom="1px solid rgba(255, 255, 255, 0.06)"
          marginTop="15px"
          alignItems="center"
          key={index}
          w="100%"
        >
          <Flex alignItems="center" w={{ base: "20%", md: "21%", lg: "16%" }}>
            <Checkbox
              borderRadius="4px"
              _focusVisible={{ outline: "none" }}
              size="lg"
              mr="15px"
            />
            <Skeleton w="10px" h="21px" borderRadius="2px" />

            <Box
              display={{ base: "none", md: "flex" }}
              flexDirection="column"
              alignItems="center"
            >
              <Box>
                <BackgroundImagePlaylistIcon />
              </Box>
              <Image
                fallbackSrc={ImageDefault}
                borderRadius="4px"
                objectFit="cover"
                w="55px"
                height="55px"
                marginTop="-35px"
              />
            </Box>
          </Flex>

          <Box
            w={{ base: "65%", md: "30%", lg: "20%" }}
            display={{ base: "flex", md: "block" }}
            alignItems="center"
            overflow="hidden"
          >
            <Image
              display={{ base: "block", md: "none" }}
              fallbackSrc={ImageDefault}
              borderRadius="4px"
              objectFit="cover"
              w={{ base: "35px", md: "55px" }}
              height={{ base: "36px", md: "55px" }}
              mr="10px"
            />
            <Box>
              <Skeleton h="16px" w="150px" borderRadius="30px" mr="0px" />
              <Flex>
                <Skeleton h="16px" w="35px" mb="0px" />
                <Skeleton
                  h="16px"
                  w="109px"
                  borderRadius="30px"
                  mb="0px"
                  mr="0px"
                />
              </Flex>
            </Box>
          </Box>
          <Box w="10%" display={{ base: "none", md: "flex" }}>
            <Skeleton borderRadius="4px" h="20px" />
          </Box>

          <Flex
            alignItems="center"
            w="20%"
            display={{ base: "none", lg: "flex" }}
          >
            <Skeleton w="40px" h="40px" borderRadius="full" />
            <Box>
              <Skeleton w="120px" ml="0px" />
              <Skeleton w="80px" ml="0px" />
            </Box>
          </Flex>
          <Flex
            gap={{ lg: "0px", xl: "10px" }}
            w={{ md: "20%", lg: "15%" }}
            display={{ base: "none", md: "flex" }}
          >
            <Box>
              <Skeleton w="40px" />
              <Skeleton w={{ md: "55px", lg: "70px" }} />
            </Box>
            <Box>
              <Skeleton w="40px" />
              <Skeleton w={{ md: "55px", lg: "70px" }} />
            </Box>
          </Flex>

          <Box w="15%" display={{ base: "none", md: "flex" }}>
            <Skeleton w={{ md: "80px", xl: "170px" }} />
          </Box>
          <Flex justifyContent="flex-end" w={{ base: "15%", md: "4%" }}>
            <Skeleton w="40px" />
          </Flex>
        </Flex>
      ))}
    </Box>
  );
}
