import { createSelector } from "@reduxjs/toolkit";

export const selectNotification = (state: any) => state.notifications;

export const notificationsSelector = createSelector(
  selectNotification,
  ({ data }) => data?.items
);

export const selectNotificationOptions = createSelector(
  selectNotification,
  ({ data }) => data?.options
);
