import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
  Flex,
} from "@chakra-ui/react";
import { TrackSoundKit } from "app/models";
import { useMySoundkit } from "hooks/soundkit/useMySoundkit";
import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FadeLoader } from "react-spinners";
import { heightLoadMore, searchData } from "utils/convertTracks";
import MusicCardMyTrack from "../MusicCard/MusicCardMyTrack";
import EmptyTrackSoundkit from "./emptyTrackSoundkit";
import "./styles.scss";
import MusicCardMyTrackMobile from "../MusicCard/MusicCardMyTrackMobile";

interface MyReleasedSoundkitProps {
  handleCheckboxSK?: (id: String) => void;
  selectCheckboxSK?: string[];
  searchTextItem: string;
}

const columnHeader = [
  { label: t("packNo") },
  { label: t("soundPacksName") },
  { label: t("dateReleased") },
  { label: t("fileDelivery") },
  { label: t("actions") },
];

export default function MyReleasedSoundkit({
  handleCheckboxSK,
  selectCheckboxSK,
  searchTextItem,
}: MyReleasedSoundkitProps) {
  const { onGetReleasedSoundkit, releasedSK, isLoading } = useMySoundkit();
  const { t } = useTranslation();
  const [selectItem, setSelectIem] = useState(-1);
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  useEffect(() => {
    onGetReleasedSoundkit({ page: 1 });
  }, [onGetReleasedSoundkit]);

  const filterRelease = searchData(releasedSK?.items, searchTextItem);
  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(releasedSK, e)) {
        onGetReleasedSoundkit({ page: 1 + releasedSK.page, loadingMore: true });
      }
    },
    [onGetReleasedSoundkit, releasedSK]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  return (
    <Box mt="20px">
      {filterRelease?.length === 0 && !isLoading ? (
        <EmptyTrackSoundkit />
      ) : isLessThan766 ? (
        <Box>
          <Flex>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="20%">
              {t("trackNo")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="65%">
              {t("trackName")}
            </Text>
            <Text color="#8B8B8D" fontSize="14px" fontWeight="700" w="15%">
              {t("actions")}
            </Text>
          </Flex>
          {(filterRelease || []).map((item: TrackSoundKit, index: number) => (
            <MusicCardMyTrackMobile
              item={item}
              handleCheckboxSK={handleCheckboxSK}
              selectCheckboxSK={selectCheckboxSK}
              key={index}
              index={index}
              isMyTrackRelease={true}
              isSK
            />
          ))}
        </Box>
      ) : (
        <TableContainer>
          <Table fontFamily="Cera Pro Regular">
            <Thead>
              <Tr>
                {!isLoading &&
                  columnHeader.map((i, k) => (
                    <Th
                      key={k}
                      fontSize="14px"
                      color="#8B8B8D"
                      textTransform="capitalize"
                      borderColor="transparent"
                      position={k === 0 ? "sticky" : "static"}
                      left={k === 0 ? "0px" : ""}
                      zIndex={k === 0 ? "1" : ""}
                      backdropFilter={k === 0 ? "blur(20px)" : " "}
                      textAlign={k === 4 ? "center" : "start"}
                    >
                      {i.label}
                    </Th>
                  ))}
              </Tr>
            </Thead>
            <Tbody>
              {(isLoading ? [] : filterRelease || []).map(
                (item: TrackSoundKit, index: number) => (
                  <MusicCardMyTrack
                    handleCheckboxSK={handleCheckboxSK}
                    selectCheckboxSK={selectCheckboxSK}
                    isMySoundkit
                    isMyTrack
                    key={index}
                    item={item}
                    index={index}
                    isSK
                    selectItem={selectItem}
                    setSelectIem={setSelectIem}
                  />
                )
              )}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      <Box display="flex" justifyContent="center">
        <FadeLoader color={"#d4f70e"} loading={releasedSK.loadingMore} />
      </Box>
      {!releasedSK.hasMore && !isLoading && releasedSK?.items?.length > 25 && (
        <Text fontSize="14px" textAlign="center" color="#464649">
          {t("upToDate")}
        </Text>
      )}
    </Box>
  );
}
