import {
  Box,
  Flex,
  Image,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useMediaQuery,
} from "@chakra-ui/react";
import Cart from "app/assets/icons/cart";
import FreeDownload from "app/assets/icons/freeDownload";
import IconHeartFeedPageLike from "app/assets/icons/IconHeartFeedPageLike";
import IconHeartFeedPageLikeMobile from "app/assets/icons/IconHeartFeedPageLikeMobile";
import IconHeartFeedPageUnlike from "app/assets/icons/IconHeartFeedPageUnlike";
import IconLyricsFeedPageMobile from "app/assets/icons/IconLyricsFeedPageMobile";
import IconRepeatFeed from "app/assets/icons/IconRepeatFeedPage";
import IconRepeatFeedMobile from "app/assets/icons/IconRepeatFeedPageMobile";
import IconShareFeedPage from "app/assets/icons/IconShareFeedPage";
import IconShareFeedPageMobile from "app/assets/icons/IconShareFeedPageMobile";
import IconUnRepeatFeed from "app/assets/icons/IconUnRepeatFeedPage";
import Verified from "app/assets/icons/VerifiedHomePage";
import ImageDefault from "app/assets/images/AvatarMusicLoading.svg";
import AvatarUser from "app/assets/images/UserAvatar.png";
import CartIcon from "app/components/CartIcon";
import PlayButton from "app/components/FooterPlayer/PlayButton";
import PopoverInfoProfile from "app/components/RecentAction/popoverInfoProfile";
import ToastPlanSubscription from "app/components/toast/toastplan";
import {
  HandleFreeDownloadModel,
  Playlists,
  RecentAction,
  ShortLinkModel,
  TrackSoundKit,
} from "app/models";
import { addToCart, removeFromCart } from "app/redux/actions/cart";
import {
  likeSoundkitInRecentActionsHomepage,
  likeTrackInRecentActionsHomepage,
  repostSoundkitInRecentActionsHomepage,
  repostTrackInRecentActionsHomepage,
  unLikeSoundkitInRecentActionsHomepage,
  unLikeTrackInRecentActionsHomepage,
} from "app/redux/actions/home";
import * as playlistActions from "app/redux/actions/playlist";
import { checkFollowUser, getLyrics } from "app/redux/actions/tracks";
import { isInCartSelector } from "app/redux/selectors/cart";
import {
  isFavoritesSKSelector,
  isRepostsSKSelector,
} from "app/redux/selectors/mySoundKit";
import {
  isFavoriteSelector,
  isRepostsSelector,
} from "app/redux/selectors/myTrack";
import { isLoadingSelector } from "app/redux/selectors/status";
import { selectLoggedIn, selectUserAdmin } from "app/redux/selectors/users";
import { LOOP_TYPE } from "constants/audioOptions";
import { PROFILE_TYPE } from "constants/contractType";
import { ACTIONS } from "constants/index";
import { TRACK_TYPE } from "constants/trackTypes";
import { usePlayers } from "hooks/player/usePlayers";
import i18next from "i18next";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { RootState } from "types";
import { actionText, typeMusic } from "utils/actionText";
import { infoProfile } from "utils/convertData";
import {
  checkFreeDownload,
  checkIsSKInRecentAction,
  checkTypeDownload,
  formatNumber,
} from "utils/convertTracks";
import { shortLinkTrackSoundkitDetail } from "utils/getData";
import { getPriceTrack } from "utils/getPrice";
import { format } from "utils/timeAgo";
import PlaylistItemRecentAction from "./playlistItemRecentActions";

interface RecentActionsHomePageProps {
  item: RecentAction;
  onOpenOptionsModal: (track: TrackSoundKit, isLyric?: boolean) => void;
  onOpenModalShareLink: (track: TrackSoundKit) => void;
  setShortLinkUrl: (value: ShortLinkModel) => void;
  setLoadingUrl: (value: boolean) => void;
  setShowModalShareLinkPlaylist: () => void;
  setPlaylistItem: (item: Playlists) => void;
  setShortLinkPlaylist: (value: ShortLinkModel) => void;
  handleFreeDownload: ({
    url,
    title,
    _id,
    isTrack,
  }: HandleFreeDownloadModel) => void;
  listPlayTracksSoundpack: TrackSoundKit[];
  handleShowModalFreeDownloadMailchimp: (track: TrackSoundKit) => void;
}

export default function RecentActionsHomePage({
  item,
  onOpenOptionsModal = () => {},
  onOpenModalShareLink = () => {},
  setShortLinkUrl,
  setLoadingUrl,
  setPlaylistItem,
  setShowModalShareLinkPlaylist,
  setShortLinkPlaylist,
  handleFreeDownload,
  listPlayTracksSoundpack,
  handleShowModalFreeDownloadMailchimp,
}: RecentActionsHomePageProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserAdmin);
  const isLoadingCheckFreeDownload = useSelector((state: RootState) =>
    isLoadingSelector([checkFollowUser.typePrefix], state)
  );
  const [isLessThan768] = useMediaQuery("(max-width: 768px)");

  const [selectIdTrack, setSelectIdTrack] = useState("");
  const checkType = (type: string) => {
    return typeMusic.SOUND_KIT === type;
  };
  const isInCart = useSelector(
    isInCartSelector(checkType(item.type) ? item?.soundkit : item?.track)
  );

  const [delayTimeCallApi, setDelayTimeCallApi] = useState(0);

  const loggedIn = useSelector(selectLoggedIn);

  const isDisabled = useMemo(
    () =>
      item?.track?.notForSale ||
      item?.track?.private ||
      item?.soundkit?.notForSale,
    [item?.soundkit?.notForSale, item?.track?.notForSale, item?.track?.private]
  );

  const checkFreeBeats = checkFreeDownload(
    checkType(item?.type) ? item?.soundkit : item?.track
  );

  const increaseTime = useCallback(() => {
    setDelayTimeCallApi((pre) => pre + 2000);
  }, []);

  const {
    handlePlayOrPause,
    handleTogglePlayPause,
    playingTrackId,
    isPlaying,
    isLoading,
    handlePlayPlaylists,
  } = usePlayers();

  const isExclusive = useMemo(
    () => (item?.track?.licenses || []).every((license) => license.exclusive),
    [item?.track?.licenses]
  );
  const isMakeOffersOnly = useMemo(
    () =>
      (item?.track?.licenses || []).every((license) => license?.makeOffersOnly),
    [item?.track?.licenses]
  );

  const handleRemoveToCartButton = useCallback(() => {
    dispatch(
      removeFromCart([
        checkType(item?.type) ? item?.soundkit?._id : item?.track?._id,
      ])
    );
  }, [dispatch, item?.soundkit?._id, item?.track?._id, item?.type]);

  const addToCarts = useCallback(() => {
    if (isDisabled) {
      return;
    }
    if (isInCart) {
      handleRemoveToCartButton();
    } else if (checkType(item?.type)) {
      dispatch(addToCart(item?.soundkit));
    } else {
      onOpenOptionsModal(item?.track as TrackSoundKit);
    }
  }, [
    isDisabled,
    isInCart,
    handleRemoveToCartButton,
    onOpenOptionsModal,
    dispatch,
    item,
  ]);

  const redirectProfile = useCallback(
    (e, name) => {
      e.stopPropagation();
      history.push(`/user/${name}?profileType=${PROFILE_TYPE.TRACKS}`);
    },
    [history]
  );

  const handleTrackDetail = useCallback(
    (e) => {
      e.stopPropagation();
      const path = checkType(item?.type)
        ? `/soundkit/${item?.soundkit?.slug}`
        : `/track/${item?.track?.slug}`;
      if (checkType(item?.type)) {
        history.push(path);
        return;
      }
      history.push(path);
    },
    [history, item?.soundkit?.slug, item?.track?.slug, item?.type]
  );

  const onClickToTrack = useCallback(async () => {
    if (!item?.track?.mergeAudio && !item?.soundkit?.audioFileUrl) {
      return;
    }
    if (
      playingTrackId !==
      (checkType(item?.type) ? item?.soundkit?._id : item?.track?._id)
    ) {
      handlePlayOrPause(checkType(item?.type) ? item?.soundkit : item?.track);
      handlePlayPlaylists(listPlayTracksSoundpack);
      dispatch(playlistActions.setLoopAudioType(LOOP_TYPE.LOOP));
    } else {
      handleTogglePlayPause();
    }
  }, [
    item?.track,
    item?.soundkit,
    item?.type,
    playingTrackId,
    handleTogglePlayPause,
    handlePlayOrPause,
    handlePlayPlaylists,
    listPlayTracksSoundpack,
    dispatch,
  ]);

  const handleShortLoading = useCallback(
    (shortLink: ShortLinkModel) => {
      setShortLinkUrl(shortLink);
      setLoadingUrl(false);
    },
    [setLoadingUrl, setShortLinkUrl]
  );

  const createShortLinkUrl = useCallback(async () => {
    const hostName = window.location.host;
    setLoadingUrl(true);
    onOpenModalShareLink(
      checkType(item?.type)
        ? (item?.soundkit as TrackSoundKit)
        : (item?.track as TrackSoundKit)
    );
    if (checkType(item?.type)) {
      const shortLink = await shortLinkTrackSoundkitDetail({
        originalURL: `${hostName}/soundkit/${item?.soundkit?.slug}`,
      });
      handleShortLoading(shortLink?.data?.data);
    } else {
      const shortLink = await shortLinkTrackSoundkitDetail({
        originalURL: `${hostName}/track/${item?.track?.slug}`,
      });
      handleShortLoading(shortLink?.data?.data);
    }
  }, [
    handleShortLoading,
    item?.soundkit,
    item?.track,
    item?.type,
    onOpenModalShareLink,
    setLoadingUrl,
  ]);

  const handleSearchTags = useCallback(
    (tag) => {
      history.push(`/search?type=tags&q=${tag}`);
    },
    [history]
  );

  const handleDownloadTrack = useCallback(async () => {
    if (item?.track?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(item?.track);
      return;
    }
    setSelectIdTrack(item?._id);
    const isFollowing = await dispatch(
      checkFollowUser(item?.track?.createdBy?._id || "")
    );
    if (
      isFollowing?.payload ||
      currentUser?._id === item?.track?.createdBy?._id
    ) {
      handleFreeDownload({
        url: checkTypeDownload(item?.track)
          ? (item?.track?.mergeAudio as string)
          : (item?.track?.untaggedFileUrl as string),
        title: item.track?.title || "",
        _id: item?.track?._id || "",
        isTrack: true,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    item.track,
    item?._id,
    dispatch,
    currentUser?._id,
    handleShowModalFreeDownloadMailchimp,
    handleFreeDownload,
    t,
  ]);

  const handleDownloadSoundkit = useCallback(async () => {
    if (item?.soundkit?.requireEmailCapture) {
      handleShowModalFreeDownloadMailchimp(item?.soundkit);
      return;
    }
    setSelectIdTrack(item?._id);
    const isFollowing = await dispatch(
      checkFollowUser(item?.soundkit?.createdBy?._id || "")
    );
    if (
      isFollowing?.payload ||
      currentUser?._id === item?.soundkit?.createdBy?._id
    ) {
      handleFreeDownload({
        url: item?.soundkit?.downloadFilesUrl || "",
        title: item.soundkit?.title || "",
        _id: item?.soundkit?._id || "",
        isTrack: false,
      });
    } else {
      ToastPlanSubscription({
        title: t("followForAFreeDownload"),
        description: t("desFollowGetDownload"),
      });
    }
  }, [
    currentUser?._id,
    dispatch,
    handleFreeDownload,
    handleShowModalFreeDownloadMailchimp,
    item?._id,
    item.soundkit,
    t,
  ]);

  const isFavorite = useSelector(
    checkIsSKInRecentAction(item)
      ? isFavoritesSKSelector(item?.soundkit)
      : isFavoriteSelector(item?.track)
  );

  const isRepost = useSelector(
    checkIsSKInRecentAction(item)
      ? isRepostsSKSelector(item?.soundkit)
      : isRepostsSelector(item?.track)
  );

  const handleLikeUnlikeRecentActionsHomepage = useCallback(async () => {
    increaseTime();
    if (checkIsSKInRecentAction(item)) {
      if (isFavorite) {
        dispatch(
          unLikeSoundkitInRecentActionsHomepage({
            id: item?.soundkit?._id || "",
            timeCallApi: delayTimeCallApi,
          })
        );
      } else {
        dispatch(
          likeSoundkitInRecentActionsHomepage({
            id: item?.soundkit?._id || "",
            timeCallAPi: delayTimeCallApi,
          })
        );
      }
    } else {
      if (isFavorite) {
        dispatch(
          unLikeTrackInRecentActionsHomepage({
            id: item?.track?._id || "",
            timeCallApi: delayTimeCallApi,
          })
        );
      } else {
        dispatch(
          likeTrackInRecentActionsHomepage({
            id: item?.track?._id || "",
            timeCallAPi: delayTimeCallApi,
          })
        );
      }
    }
  }, [delayTimeCallApi, dispatch, increaseTime, isFavorite, item]);

  const handleRepostRecentActionsHomePage = useCallback(() => {
    if (isRepost) {
      ToastPlanSubscription({
        description: checkIsSKInRecentAction(item)
          ? t("alreadyRepostSK")
          : t("alreadyRepost"),
      });
    } else {
      if (checkIsSKInRecentAction(item)) {
        dispatch(
          repostSoundkitInRecentActionsHomepage(item?.soundkit?._id || "")
        );
      } else {
        dispatch(repostTrackInRecentActionsHomepage(item?.track?._id || ""));
      }
    }
  }, [dispatch, isRepost, item, t]);
  const handleGetLyrics = useCallback(() => {
    if (loggedIn) {
      if (checkType(item?.type)) {
        onOpenOptionsModal(item?.soundkit, true);
        dispatch(
          getLyrics({ soundPackId: item?.soundkit?._id, isSoundPack: true })
        );
        return;
      }
      onOpenOptionsModal(item?.track, true);
      dispatch(getLyrics({ trackId: item?.track?._id }));
    } else {
      return null;
    }
  }, [
    dispatch,
    item?.soundkit,
    item?.track,
    item?.type,
    onOpenOptionsModal,
    loggedIn,
  ]);

  const checkLyrics = useMemo(() => {
    if (checkType(item?.type)) {
      return item?.soundkit?.myLyric?.length > 0 && !!item?.soundkit?.myLyric;
    } else {
      return !!item?.track?.myLyric;
    }
  }, [item?.soundkit?.myLyric, item?.track?.myLyric, item?.type]);

  return (
    <Box
      className="home-page__recent-action"
      mr={{ base: "0px", md: "5px" }}
      overflow="hidden"
    >
      <Flex display="flex" alignItems="center" justifyContent="space-between">
        <Flex display="flex" alignItems="center">
          {!loggedIn ? (
            <Flex
              alignItems="center"
              onClick={(e) => redirectProfile(e, item?.createdBy?.name)}
            >
              <Image
                w={{ base: "30px", md: "35px" }}
                h={{ base: "30px", md: "35px" }}
                src={item?.createdBy?.profileImage}
                fallbackSrc={AvatarUser}
                borderRadius="full"
                cursor="pointer"
              />
              <Text
                cursor={"pointer"}
                fontSize={{ base: "14px", md: "16px" }}
                ml="6px"
                mr="6px"
                lineHeight="18px"
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {item?.createdBy?.name}
              </Text>
            </Flex>
          ) : (
            <PopoverInfoProfile isRecentActions item={infoProfile(item)} />
          )}
          {item?.isFromAdmin && <Verified />}
          {item?.icon && <item.icon />}
          <Text
            fontSize={{ base: "14px", md: "16px" }}
            lineHeight="18px"
            fontWeight="500"
            color="#5B5C5F"
            m="0px 5px"
            fontFamily="Cera Pro Medium"
          >
            {t(actionText(item?.action || ""))}
          </Text>
          <Text
            fontSize={{ base: "14px", md: "16px" }}
            lineHeight="18px"
            fontWeight="500"
            fontFamily="Cera Pro Medium"
          >
            {item.type === ACTIONS.PLAYLIST
              ? t("playlist")
              : checkType(item?.type)
              ? t("soundPack")
              : t("track")}
          </Text>
        </Flex>
        <Text
          fontSize={{ base: "14px", md: "16px" }}
          lineHeight="18px"
          fontWeight="500"
          fontFamily="Cera Pro Medium"
          color="rgba(255, 255, 255, 0.27)"
        >
          {format(item, i18next.language)}
        </Text>
      </Flex>

      {item.type === ACTIONS.PLAYLIST ? (
        <PlaylistItemRecentAction
          playlist={item.playlist}
          setPlaylistItem={setPlaylistItem}
          setShowModalShareLinkPlaylist={setShowModalShareLinkPlaylist}
          setShortLinkPlaylist={setShortLinkPlaylist}
          setLoadingUrl={setLoadingUrl}
        />
      ) : (
        <>
          <Box
            mt="20px"
            background="#24252B"
            padding={{ base: "10px", md: "20px" }}
            borderRadius={{ base: "8px", md: "8px" }}
          >
            <Flex alignItems={{ base: "unset", md: "center" }}>
              <Flex
                position="relative"
                role="group"
                w={{ base: "85px", md: "180px" }}
                justifyContent="center"
              >
                <Image
                  w={{ base: "82px", md: "180px" }}
                  h={{ base: "82px", md: "180px" }}
                  src={
                    checkType(item?.type)
                      ? item.soundkit?.coverUrl
                      : item?.track?.coverUrl
                  }
                  fallbackSrc={ImageDefault}
                  borderRadius="6px"
                  onClick={onClickToTrack}
                  cursor="pointer"
                  _groupHover={{
                    opacity: "0.2",
                  }}
                />

                <Flex
                  w={{ base: "45px", md: "55px" }}
                  h={{ base: "45px", md: "55px" }}
                  borderRadius="4px"
                  alignItems="center"
                  justifyContent="center"
                  display={"none"}
                  _groupHover={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  position="absolute"
                  top={{ base: "40%", md: "50%" }}
                  left="50%"
                  transform="translate(-50%,-50%)"
                  onClick={onClickToTrack}
                >
                  <PlayButton
                    size={45}
                    isPlaying={
                      playingTrackId ===
                        (checkType(item?.type)
                          ? item?.soundkit?._id
                          : item?.track?._id) && isPlaying
                    }
                    isLoading={isLoading}
                  />
                </Flex>
              </Flex>

              <Box
                ml={{ base: "15px", md: "20px" }}
                w={{ base: "75%", md: "70%", lg: "85%" }}
                position="relative"
              >
                <Text
                  w="75%"
                  fontSize={{ base: "16px", md: "32px" }}
                  fontWeight="700"
                  lineHeight={{ base: "24px", md: "32px" }}
                  noOfLines={1}
                  onClick={handleTrackDetail}
                  _hover={{
                    textDecoration: "underline",
                    color: "white",
                  }}
                  cursor="pointer"
                  fontFamily="Cera Pro Medium"
                >
                  {checkType(item?.type)
                    ? item?.soundkit?.title
                    : item?.track?.title}
                </Text>
                <Flex
                  position="absolute"
                  right="-5px"
                  top="-5px"
                  display={{ base: "flex", md: "none" }}
                >
                  {checkFreeBeats && (
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      height="38px"
                      width="38px"
                      borderRadius="50%"
                      bg="#333625"
                      border="1px solid #62701B"
                      mr="5px"
                    >
                      <FreeDownload />
                    </Box>
                  )}
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height="38px"
                    width="38px"
                    borderRadius="50%"
                    bg={isInCart ? "#D4F70E" : "#24252A"}
                    border="1px solid #505155"
                    onClick={addToCarts}
                  >
                    <Cart fill={isInCart ? "#24252A" : "#D4F70E"} />
                  </Box>
                </Flex>

                <Box
                  display="flex"
                  alignItems="center"
                  mt={{ base: "8px", md: "13px" }}
                  overflow="hidden"
                  gap="5px"
                  flexWrap="wrap"
                >
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="#2b2e1b"
                    padding="0px 5px"
                    borderRadius="4px"
                    w="max-content"
                  >
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      color="#d4f70e"
                      fontFamily="Cera Pro Regular"
                      noOfLines={1}
                    >
                      {t(
                        TRACK_TYPE[
                          checkType(item?.type)
                            ? item?.soundkit?.type || ""
                            : item?.track?.type || ""
                        ]
                      )}
                    </Text>
                  </Flex>

                  <Text
                    fontSize={{ base: "14px", md: "16px" }}
                    fontWeight="400"
                    lineHeight="19px"
                    color="#5B5C5F"
                    cursor={"pointer"}
                    fontFamily="Cera Pro Regular"
                    _hover={{
                      textDecoration: "underline",
                      color: "white",
                    }}
                    noOfLines={1}
                    onClick={(e) =>
                      redirectProfile(
                        e,
                        checkType(item?.type)
                          ? item?.soundkit?.createdBy?.name
                          : item?.track?.createdBy?.name
                      )
                    }
                  >
                    {`${t("by")} ${
                      checkType(item?.type)
                        ? item?.soundkit?.createdBy?.name
                        : item?.track?.createdBy?.name
                    }`}
                  </Text>
                  <Flex alignItems="center" ml="10px">
                    <Box
                      w="4px"
                      h="4px"
                      borderRadius="full"
                      background="#a0a0a1"
                      mr="5px"
                    />
                    <Text
                      textAlign="center"
                      color="#a0a0a1"
                      fontSize="14px"
                      lineHeight={{ base: "14px", md: "16px" }}
                    >
                      {`${formatNumber(
                        Number(
                          checkType(item?.type)
                            ? item?.soundkit?.totalPlay || 0
                            : item?.track?.totalPlay || 0
                        )
                      )} ${t("Plays")}`}
                    </Text>
                  </Flex>
                </Box>

                <Flex
                  gap="5px"
                  w="100%"
                  mt="8px"
                  display={{ base: "flex", md: "none" }}
                >
                  {item?.track?.tags?.slice(0, 3)?.map((item) => (
                    <Flex
                      background="#2E2E32"
                      border="1px solid #464649"
                      borderRadius="2px"
                      alignItems="center"
                      justifyContent="center"
                      overflow="hidden"
                      key={item}
                      w="85px"
                      h="20px"
                      _hover={{
                        background: "#454C22",
                        color: "#d4f70e",
                        border: "1px solid #d4f70e",
                      }}
                      onClick={() => handleSearchTags(item)}
                    >
                      <Text
                        fontSize="12px"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        whiteSpace="nowrap"
                        w="90%"
                        textAlign="center"
                      >
                        {item}
                      </Text>
                    </Flex>
                  ))}
                </Flex>

                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  display={{ base: "none", md: "flex" }}
                >
                  <Box w={{ base: "70%", md: "80%" }}>
                    <Text
                      fontSize={{ base: "14px", md: "16px" }}
                      lineHeight={{ base: "18px", md: "24px" }}
                      fontWeight="400"
                      noOfLines={3}
                      fontFamily="Cera Pro Regular"
                      mt={{ base: "0px", md: "15px" }}
                    >
                      {checkType(item?.type)
                        ? item?.soundkit?.description || t("noDescription")
                        : item?.track?.description || t("noDescription")}
                    </Text>
                  </Box>
                  <Box>
                    <CartIcon
                      isDisabled={isDisabled}
                      onClick={addToCarts}
                      price={getPriceTrack(
                        checkType(item?.type) ? item?.soundkit : item?.track
                      )}
                      active={isInCart}
                      isOffer={
                        checkType(item.type)
                          ? false
                          : isExclusive && isMakeOffersOnly
                      }
                    />
                    {checkFreeBeats && (
                      <Flex
                        mt="5px"
                        background="#3D4419"
                        color="#D4F70E"
                        padding="8px 13px"
                        borderRadius="34px"
                        cursor="pointer"
                        onClick={
                          !loggedIn
                            ? () => null
                            : checkType(item?.type)
                            ? handleDownloadSoundkit
                            : handleDownloadTrack
                        }
                      >
                        {isLoadingCheckFreeDownload &&
                        selectIdTrack === item._id ? (
                          <Spinner />
                        ) : (
                          <>
                            <Text
                              mr="10px"
                              fontSize="12px"
                              lineHeight="14px"
                              fontWeight="400"
                              fontFamily="Cera Pro Regular"
                            >
                              {t("FREE")}
                            </Text>
                            <FreeDownload />
                          </>
                        )}
                      </Flex>
                    )}
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Flex
            alignItems="center"
            overflow="scroll"
            m="2px 0px"
            background="#24252B"
            borderRadius="4px"
            justifyContent="center"
            padding={{ base: "2px", md: "10px" }}
          >
            {!checkType(item?.type) && item?.track.bpm && (
              <Text fontSize="12px" mr="5px">
                Bpm <span>{item?.track?.bpm}</span>
              </Text>
            )}

            <UnorderedList display="flex" gap="25px">
              {checkType(item?.type)
                ? item?.soundkit?.key
                : item?.track?.key && (
                    <ListItem fontSize="12px">
                      KEY:{" "}
                      {checkType(item?.type)
                        ? item?.soundkit?.key
                        : item?.track?.key}
                    </ListItem>
                  )}
              <ListItem fontSize="12px">
                {checkType(item?.type)
                  ? item?.soundkit?.mainGenre
                  : item?.track?.mainGenre}
              </ListItem>
              <ListItem fontSize="12px">
                {checkType(item?.type)
                  ? item?.soundkit?.subGenre
                  : item?.track?.subGenre}
              </ListItem>
            </UnorderedList>
          </Flex>
          <Flex
            w="100%"
            background="#1F2026"
            padding={{ base: "10px", md: "15px" }}
            justifyContent="space-between"
            mb="20px"
            borderRadius="0px 0px 8px 8px"
            alignItems="center"
          >
            <Flex
              alignItems="center"
              onClick={
                loggedIn ? handleLikeUnlikeRecentActionsHomepage : () => null
              }
              cursor="pointer"
              _hover={{
                border: "1px solid #38383D",
                borderRadius: "8px",
                padding: "5px",
              }}
              borderWidth="1px"
              borderColor="#1F2026"
              padding="5px"
            >
              {isFavorite ? (
                isLessThan768 ? (
                  <IconHeartFeedPageLikeMobile />
                ) : (
                  <IconHeartFeedPageLike />
                )
              ) : isLessThan768 ? (
                <IconHeartFeedPageLikeMobile color="#656566" />
              ) : (
                <IconHeartFeedPageUnlike />
              )}
              <Text
                ml="5px"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {checkType(item.type)
                  ? item?.soundkit?.totalFavs
                  : item?.track?.totalFavs}
              </Text>
            </Flex>

            <Flex
              cursor="pointer"
              alignItems="center"
              onClick={
                loggedIn ? handleRepostRecentActionsHomePage : () => null
              }
              _hover={{
                border: "1px solid #38383D",
                borderRadius: "8px",
                padding: "5px",
              }}
              borderWidth="1px"
              borderColor="#1F2026"
              padding="5px"
            >
              {isRepost ? (
                isLessThan768 ? (
                  <IconRepeatFeedMobile />
                ) : (
                  <IconRepeatFeed />
                )
              ) : isLessThan768 ? (
                <IconRepeatFeedMobile color="#656566" />
              ) : (
                <IconUnRepeatFeed />
              )}

              <Text
                ml="5px"
                fontSize="16px"
                lineHeight="19px"
                fontWeight="500"
                fontFamily="Cera Pro Medium"
              >
                {checkType(item.type)
                  ? item?.soundkit?.totalRepost
                  : item?.track?.totalRepost}
              </Text>
            </Flex>
            <Flex
              padding="5px"
              onClick={handleGetLyrics}
              cursor="pointer"
              _hover={{
                border: "1px solid #38383D",
                borderRadius: "8px",
                padding: "5px",
              }}
            >
              <IconLyricsFeedPageMobile
                color={checkLyrics ? "#D4F70E" : "#656566"}
              />
            </Flex>

            <Flex
              display="flex"
              alignItems="center"
              cursor="pointer"
              onClick={createShortLinkUrl}
              _hover={{
                border: "1px solid #38383D",
                borderRadius: "8px",
                padding: "5px",
              }}
              borderWidth="1px"
              borderColor="#1F2026"
              padding="5px"
            >
              {isLessThan768 ? (
                <IconShareFeedPageMobile color="#656566" />
              ) : (
                <IconShareFeedPage color="#656566" />
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}
