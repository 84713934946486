import React from "react";

interface IconRepostIndividualTrackMobileProps {
  isRepost?: boolean;
}

const IconRepostIndividualTrackMobile = ({
  isRepost,
}: IconRepostIndividualTrackMobileProps) => {
  return (
    <svg
      width="17"
      height="21"
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.79474 0.243453C6.72484 0.314121 6.66936 0.398197 6.6315 0.490832C6.59364 0.583466 6.57415 0.682826 6.57415 0.783178C6.57415 0.883531 6.59364 0.982891 6.6315 1.07552C6.66936 1.16816 6.72484 1.25224 6.79474 1.3229L8.97229 3.54262L3.00639 3.54262C2.21526 3.54262 1.45653 3.86298 0.897123 4.43323C0.337711 5.00347 0.0234371 5.77689 0.0234371 6.58334L0.0234375 14.4664C0.0234375 14.668 0.102006 14.8614 0.241859 15.0039C0.381712 15.1465 0.571393 15.2266 0.769175 15.2266C0.966957 15.2266 1.15664 15.1465 1.29649 15.0039C1.43634 14.8614 1.51491 14.668 1.51491 14.4664L1.51491 6.58334C1.51491 6.18011 1.67205 5.79341 1.95176 5.50828C2.23146 5.22316 2.61082 5.06298 3.00639 5.06298L8.97229 5.06298L6.79474 7.2827C6.65134 7.42484 6.56922 7.61922 6.56642 7.82308C6.56362 8.02694 6.64038 8.22359 6.77982 8.36976C6.91926 8.51592 7.10995 8.59964 7.30994 8.60249C7.50993 8.60534 7.70283 8.5271 7.84623 8.38496L11.299 4.87294C11.3671 4.79926 11.4225 4.71446 11.4631 4.62208C11.5025 4.53108 11.5229 4.43269 11.5229 4.33321C11.5229 4.23373 11.5025 4.13534 11.4631 4.04434C11.4253 3.9504 11.3696 3.8651 11.299 3.79348L7.84623 0.273859C7.71048 0.128161 7.52367 0.0431744 7.32664 0.0374762C7.1296 0.031779 6.93836 0.105834 6.79474 0.243453Z"
        fill={isRepost ? "#D4F70E" : "white"}
      />
      <path
        d="M9.25214 19.9675C9.32203 19.8968 9.37751 19.8127 9.41537 19.7201C9.45323 19.6275 9.47273 19.5281 9.47273 19.4278C9.47273 19.3274 9.45323 19.228 9.41537 19.1354C9.37751 19.0428 9.32203 18.9587 9.25214 18.888L7.07458 16.6683L13.0405 16.6683C13.8316 16.6683 14.5903 16.348 15.1498 15.7777C15.7092 15.2075 16.0234 14.434 16.0234 13.6276L16.0234 5.74455C16.0234 5.54294 15.9449 5.34959 15.805 5.20703C15.6652 5.06446 15.4755 4.98437 15.2777 4.98437C15.0799 4.98437 14.8902 5.06446 14.7504 5.20703C14.6105 5.34959 14.532 5.54294 14.532 5.74455L14.532 13.6276C14.532 14.0308 14.3748 14.4175 14.0951 14.7027C13.8154 14.9878 13.436 15.148 13.0405 15.148L7.07458 15.148L9.25214 12.9282C9.39553 12.7861 9.47766 12.5917 9.48045 12.3879C9.48325 12.184 9.40649 11.9874 9.26705 11.8412C9.12762 11.695 8.93693 11.6113 8.73694 11.6084C8.53695 11.6056 8.34404 11.6838 8.20065 11.826L4.74788 15.338C4.67981 15.4117 4.62436 15.4965 4.58382 15.5889C4.54437 15.6799 4.52399 15.7782 4.52399 15.8777C4.52399 15.9772 4.54437 16.0756 4.58382 16.1666C4.62153 16.2605 4.67731 16.3458 4.74788 16.4175L8.20065 19.9371C8.33639 20.0828 8.5232 20.1678 8.72024 20.1735C8.91727 20.1792 9.10851 20.1051 9.25214 19.9675Z"
        fill={isRepost ? "#D4F70E" : "white"}
      />
    </svg>
  );
};
export default IconRepostIndividualTrackMobile;
