import { Box, Flex, Text } from "@chakra-ui/react";
import { BackReturn } from "app/assets/icons";
import AddTrackItem from "app/components/AddTrackComponents";
import { checkUploadTrack } from "app/redux/actions/tracks";
import { useAddTrack } from "hooks/track/useAddTrack";
import queryString from "query-string";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormProvider } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import NoUploadTrack from "./noUploadTrack";
import "./styles.scss";
import TabsMenuHeader from "./TabHeader";
import {
  setDurationTagTracks,
  setDurationUnTagTracks,
} from "app/redux/actions/player";

interface AddTrackProps {
  isSoundKits?: boolean;
}

export default function AddTrack({ isSoundKits }: AddTrackProps) {
  const { t } = useTranslation();
  const {
    methods,
    tabsMenu,
    isUploadTrack,
    isLoadingCheckUploadTrack,
    ...rest
  } = useAddTrack();
  const title = methods.watch("title");
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isEditTrackSoundkit = queryString.parse(location.search);

  const isEdit = useMemo(() => {
    return isEditTrackSoundkit.type === "isEdit";
  }, [isEditTrackSoundkit.type]);

  const handleBackMyTrack = useCallback(() => {
    history.push({
      pathname: "/dashboard/my-track",
    });
  }, [history]);

  useEffect(() => {
    dispatch(checkUploadTrack());
    dispatch(setDurationTagTracks(""));
    dispatch(setDurationUnTagTracks(""));
  }, [dispatch]);

  useEffect(() => {
    const unlisten = history.block((location) => {
      const confirmationMessage =
        "You are in the process of adding a track. If you exit, your data will be lost. Do you want to continue?";
      if (title?.length <= 0) {
        return;
      } else if (window.confirm(confirmationMessage)) {
        unlisten();
      } else {
        return false;
      }
    });
    return () => {
      unlisten();
    };
  }, [dispatch, history, title?.length]);

  return (
    <Box
      className="add-track"
      p={{
        base: "60px 16px",
        md: "60px 20px",
        lg: "60px 30px",
        xl: "60px 80px 100px 80px",
      }}
      position="relative"
    >
      <Flex alignItems="center">
        <Box onClick={handleBackMyTrack} cursor="pointer">
          <BackReturn />
        </Box>
        <Text
          className="fontCeraPro"
          fontSize={{ base: "24px", md: "28px", lg: "32px" }}
          ml="15px"
          fontWeight="700"
          fontFamily="Cera Pro Regular"
        >
          {isSoundKits
            ? t("addSoundKit")
            : isEditTrackSoundkit.type === "isEdit"
            ? t("editTrack")
            : t("addTrack")}
        </Text>
      </Flex>
      {isLoadingCheckUploadTrack ? (
        <Flex justifyContent="center" height="50px" mt="40px">
          <FadeLoader color={"#d4f70e"} loading={isLoadingCheckUploadTrack} />
        </Flex>
      ) : isEdit || isUploadTrack ? (
        <FormProvider {...methods}>
          <Box
            mt="32px"
            className="tabs-panels"
            w={{ base: "100%", md: "65%", lg: "72%", xl: "77%" }}
          >
            <Box display={{ base: "none", lg: "block" }}>
              <TabsMenuHeader tabsMenu={tabsMenu} />
            </Box>
            <AddTrackItem isEdit={isEdit} isSoundKit={isSoundKits} {...rest} />
          </Box>
        </FormProvider>
      ) : (
        <NoUploadTrack text={t("limitUploadTrack")} />
      )}
    </Box>
  );
}
