import { Box, Button, Flex, Text } from "@chakra-ui/react";
import LicensesContractItem from "app/components/LicensesContractsItem";
import { checkCreateNewLicense } from "app/redux/actions/subscriptions";
import { useLicenseContracts } from "hooks/licenseAndContracts/useLicenseContracts";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ToastPlanSubscription from "../toast/toastplan";
import SkeletonLicenseContract from "./skeletonLicensesContracts";
import "./styles.scss";
import Skeleton from "../common/Skeleton/skeleton";
import { BeatLoader } from "react-spinners";

export default function LicensesContract() {
  const { t } = useTranslation();

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    LicenseContracts,
    nonExclusive,
    exclusive,
    getLicenseAndContracts,
    handleClearFormLicense,
    isLoading,
  } = useLicenseContracts();

  const redirectLicense = useCallback(async () => {
    const checkCreateLicense = await dispatch(checkCreateNewLicense());
    if (checkCreateLicense.payload) {
      handleClearFormLicense();
      history.push({
        pathname: "/dashboard",
        state: { label: "licenses" },
      });
      return;
    }
    ToastPlanSubscription({
      title: t("titleLicenseLimit"),
      description: t("descriptionLimit"),
    });
  }, [dispatch, handleClearFormLicense, history, t]);

  useEffect(() => {
    getLicenseAndContracts();
  }, [getLicenseAndContracts]);

  const renderLicenseContract = (title: string, isExclusive?: boolean) => {
    return (
      <Box>
        <Text
          mt="35px"
          mb="15px"
          fontSize={{ base: "20px", md: "24px" }}
          lineHeight="24px"
        >
          {title}
        </Text>
        {isExclusive
          ? exclusive?.length > 0 &&
            exclusive?.map((item: any, index: any) => {
              return <LicensesContractItem licenses={item} key={index} />;
            })
          : nonExclusive?.length > 0 &&
            nonExclusive?.map((item: any, index: any) => {
              return <LicensesContractItem licenses={item} key={index} input />;
            })}
      </Box>
    );
  };

  return (
    <Box className="licenses-contract">
      <Flex
        alignItems={{ base: "start", md: "center" }}
        justifyContent={{ base: "end", md: "space-between" }}
        position="sticky"
        top="228px"
        zIndex={90}
        height="110px"
        background="#18181c"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Text
          mb={{ base: "20px", md: "0px" }}
          fontSize={{ base: "24px", md: "32px" }}
          lineHeight="24px"
        >
          {t("licensesContracts")}
        </Text>
        {LicenseContracts.isLoading ? (
          <Skeleton
            w={{ base: "100%", md: "198px" }}
            h="48px"
            borderRadius="6px"
            mb="0px"
          />
        ) : (
          <Button
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            fontSize="14px"
            lineHeight="17px"
            p="20px 60px"
            borderRadius="4px"
            onClick={redirectLicense}
            isLoading={isLoading}
            spinner={<BeatLoader size={10} color="white" />}
            w={{ base: "100%", md: "max-content" }}
          >
            {t("createALicense")}
          </Button>
        )}
      </Flex>
      {LicenseContracts.isLoading ? (
        <SkeletonLicenseContract />
      ) : (
        <Box>
          {renderLicenseContract(t("nonExclusive"))}
          {renderLicenseContract(t("exclusive"), true)}
        </Box>
      )}
    </Box>
  );
}
