import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface WhoAreYouProps {
  label: string;
  text: string;
  value: string;
  notMusic?: boolean;
  roleUser: string[];
  handleSelectRole: (value: string) => void;
}

export default function WhoAreYou({
  label,
  text,
  notMusic,
  value,
  roleUser,
  handleSelectRole,
}: WhoAreYouProps) {
  const checkRoleUser = roleUser.includes(value);

  return (
    <Box mt="30px">
      <Flex>
        <Text mr="1px" color="#FFFFFF" fontSize="14px" lineHeight="24px">
          {label}
        </Text>
        {notMusic && <Text color="#D4F70E">*</Text>}
      </Flex>
      <Flex
        mt="5px"
        background="#181C1E"
        padding="12px 24px"
        alignItems="center"
        borderRadius="4px"
        border="1px solid #2D2D2D !important"
        cursor="pointer"
        onClick={() => handleSelectRole(value)}
      >
        <Flex
          className="custom-radio"
          w="16px"
          height="16px"
          borderRadius="4px"
          border="1px"
          borderStyle="solid"
          borderColor="#49494D"
          alignItems="center"
          justifyContent="center"
          backgroundColor={
            checkRoleUser ? "rgba(219, 243, 82, 0.2)" : "#24252A"
          }
        >
          {checkRoleUser && (
            <Box
              borderRadius="23px"
              backgroundColor="#DBF352"
              width="8px"
              height="8px"
              boxShadow="0px 4px 8px #DBF352"
            />
          )}
        </Flex>
        <Text ml="10px" fontSize="16px" lineHeight="19px" fontWeight="400">
          {text}
        </Text>
      </Flex>
    </Box>
  );
}
