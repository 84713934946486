import { Box, SimpleGrid } from "@chakra-ui/react";
import CheckBox from "app/components/Checkbox";
import ErrorMessage from "app/components/ErrorMessage";
import { Licenses } from "app/models";
import React, { useCallback } from "react";
import { Controller, useFormContext } from "react-hook-form";
import "./styles.scss";

interface IApplyLicensesProps {
  licenses?: Licenses[];
}

export default function ApplyLicenses({ licenses }: IApplyLicensesProps) {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();
  const { licenses: licensesWatch } = watch();

  const onChangeLicenses = useCallback(
    (value, licenseId, onChange) => {
      const newLicenses = value
        ? [...licensesWatch, licenseId]
        : licensesWatch.filter(
            (licenseItemId: string) => licenseItemId !== licenseId
          );
      onChange({
        target: {
          value: newLicenses,
        },
      });
    },
    [licensesWatch]
  );

  const renderLicenseText = (license: Licenses) => {
    const wav = license?.extraWav ? "(WAV)" : "";
    const stems = license?.trackStems ? "(STEMS)" : "";
    return `(MP3) ${wav} ${stems}`.trim();
  };

  return (
    <>
      <Controller
        name={"licenses"}
        render={({ field: { onChange } }) => {
          return (
            <SimpleGrid columns={{ base: 1, md: 3, xl: 4 }} spacing="20px">
              {licenses?.map((license) => {
                return (
                  <CheckBox
                    key={license?._id}
                    isChecked={licensesWatch.includes(license?._id)}
                    title={`${license.name} ${renderLicenseText(license)}`}
                    onChange={(value) =>
                      onChangeLicenses(value, license?._id, onChange)
                    }
                  />
                );
              })}
            </SimpleGrid>
          );
        }}
        control={control}
      />
      <Box mt={10} />
      <ErrorMessage error={errors?.licenses?.message} />
    </>
  );
}
