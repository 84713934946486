import React from "react";

const IconLyricsProfile = () => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7678 8.59375C12.7678 8.16227 12.4181 7.8125 11.9866 7.8125H3.9397C3.50821 7.8125 3.15845 8.16227 3.15845 8.59375C3.15845 9.02523 3.50821 9.375 3.9397 9.375H11.9866C12.4181 9.375 12.7678 9.02523 12.7678 8.59375Z"
        fill="#D8FD01"
      />
      <path
        d="M3.9397 10.9375C3.50821 10.9375 3.15845 11.2873 3.15845 11.7188C3.15845 12.1502 3.50821 12.5 3.9397 12.5H8.82684C9.25832 12.5 9.60809 12.1502 9.60809 11.7188C9.60809 11.2873 9.25832 10.9375 8.82684 10.9375H3.9397Z"
        fill="#D8FD01"
      />
      <path
        d="M5.77569 18.4375H3.16284C2.30128 18.4375 1.60034 17.7366 1.60034 16.875V3.125C1.60034 2.26344 2.30128 1.5625 3.16284 1.5625H12.7679C13.6294 1.5625 14.3304 2.26344 14.3304 3.125V7.92969C14.3304 8.36117 14.6801 8.71094 15.1116 8.71094C15.5431 8.71094 15.8929 8.36117 15.8929 7.92969V3.125C15.8929 1.40188 14.491 0 12.7679 0H3.16284C1.43972 0 0.0378418 1.40188 0.0378418 3.125V16.875C0.0378418 18.5981 1.43972 20 3.16284 20H5.77569C6.20718 20 6.55694 19.6502 6.55694 19.2188C6.55694 18.7873 6.20718 18.4375 5.77569 18.4375Z"
        fill="#D8FD01"
      />
      <path
        d="M17.2767 11.3114C16.3629 10.3976 14.876 10.3976 13.9627 11.3108L9.67356 15.5905C9.58247 15.6814 9.51528 15.7934 9.47786 15.9165L8.54376 18.9917C8.46095 19.2643 8.53309 19.5604 8.73208 19.7643C8.88114 19.9171 9.08364 20 9.29134 20C9.36083 20 9.43099 19.9907 9.49985 19.9716L12.6532 19.0982C12.7829 19.0622 12.9012 18.9934 12.9965 18.8982L17.2767 14.626C18.1906 13.7122 18.1906 12.2253 17.2767 11.3114ZM12.0381 17.6472L10.4517 18.0867L10.9159 16.5582L13.8101 13.6705L14.9151 14.7756L12.0381 17.6472ZM16.1724 13.5207L16.0211 13.6718L14.9162 12.5669L15.067 12.4164C15.3717 12.1118 15.8673 12.1118 16.1719 12.4164C16.4765 12.721 16.4765 13.2166 16.1724 13.5207Z"
        fill="#D8FD01"
      />
      <path
        d="M11.9866 4.6875H3.9397C3.50821 4.6875 3.15845 5.03727 3.15845 5.46875C3.15845 5.90023 3.50821 6.25 3.9397 6.25H11.9866C12.4181 6.25 12.7678 5.90023 12.7678 5.46875C12.7678 5.03727 12.4181 4.6875 11.9866 4.6875Z"
        fill="#D8FD01"
      />
    </svg>
  );
};
export default IconLyricsProfile;
