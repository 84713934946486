import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import IconSkeletonMusicEmailCustomers from "app/assets/icons/iconSkeletonMusicEmailCustomers";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { default as React } from "react";

export default function SkeletonEmailCustomers() {
  const fakeDataSekeleton = Array.from({ length: 10 });
  return (
    <>
      <TableContainer>
        <Table fontFamily="Cera Pro Regular" mt="20px">
          <Thead>
            <Tr>
              {Array.from({ length: 6 }).map((item, k) => (
                <Th borderColor="transparent">
                  <Flex
                    justifyContent={
                      k === 1 || k === 4 || k === 5 ? "center" : "start"
                    }
                  >
                    <Skeleton
                      w={k === 1 || k === 4 || k === 5 ? "110px" : "70px"}
                    />
                  </Flex>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {fakeDataSekeleton.map((i, k) => (
              <Tr alignItems="center" key={k} mb="30px">
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton h="18px" />
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton w="200px" h="18px" borderRadius="8px" />
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton w="100px" h="18px" borderRadius="8px" />
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton w="100px" h="18px" borderRadius="8px" />
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Flex gap="10px">
                    <IconSkeletonMusicEmailCustomers />
                    <Flex
                      alignItems="center"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Skeleton
                        h="16px"
                        w="150px"
                        borderRadius="30px"
                        mr="0px"
                      />
                    </Flex>
                  </Flex>
                </Td>
                <Td borderColor="rgba(36, 37, 42, 0.8)">
                  <Skeleton w="194px" />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}
