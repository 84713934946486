import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import TextInput from "./TextInput";

export default function Lyrics() {
  const { t } = useTranslation();

  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <Box mt="30px">
      <Text fontSize="20px" mb="28px" fontFamily="Cera Pro Medium">
        {t("lyrics")}
      </Text>
      <TextInput
        type="text"
        name="lyricTitle"
        placeholder={t("writeLyricTitle")}
        label={t("lyricTitle")}
        errors={errors}
        control={control}
      />
      <Box mt="30px">
        <TextInput
          type="textarea"
          name="lyrics"
          placeholder={t("writeLyricshere")}
          label={t("lyrics")}
          errors={errors}
          control={control}
        />
      </Box>
    </Box>
  );
}
