import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Avatar from "app/assets/images/UserAvatar.png";
import { UserFollower } from "app/models";

interface RenderFollowerUserProps {
  onSearchUserFollowers: (e) => void;
  userFollowers: UserFollower[];
  handleClickUser: (value: string) => void;
}

export default function RenderFollowerUser({
  onSearchUserFollowers,
  userFollowers,
  handleClickUser,
}: RenderFollowerUserProps) {
  return (
    <Box pl="40px">
      <InputGroup mb="8px" h="48px" borderRadius="4px" bgColor="#24252A">
        <InputRightElement
          h="48px"
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
          pr="42px"
        />
        <Input
          border="none"
          h="48px"
          w="94%"
          placeholder={"Search followers by name"}
          _placeholder={{ color: "#FFFFFF" }}
          _focus={{ boxShadow: "none" }}
          onChange={onSearchUserFollowers}
        />
      </InputGroup>
      <Box
        p="8px"
        borderRadius="4px"
        height="300px"
        overflow="auto"
        bgColor="#24252A"
      >
        {userFollowers.map((item: UserFollower) => {
          return (
            <Box
              bgColor="#1F1F23"
              display="flex"
              justifyContent="space-between"
              height="48px"
              alignItems="center"
              p="14px"
              onClick={() => handleClickUser(item?.follower?.name)}
              mb="8px"
              cursor="pointer"
              borderRadius="4px"
              key={item?.follower?._id}
            >
              <Text>{item?.follower?.name}</Text>
              <Image
                w="30px"
                height="30px"
                borderRadius="50%"
                src={item?.follower?.profileImage}
                fallbackSrc={Avatar}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
