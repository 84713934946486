import * as yup from "yup";

const formSchema = yup.object().shape({
  title: yup.string().required("soundkitTitleRequired"),
  type: yup.string().required("soundkitTypeRequired"),
  basicPrice: yup.string().required("soundkitBasicPriceRequired"),
  downloadFilesUrl: yup.string().url().required("downloadFilesUrlRequired"),
  audioFileUrl: yup.string().url().required("audioFileUrlRequired"),
  coverUrl: yup.string().required("coverUrlRequired"),
  description: yup
    .string()
    .required("descriptionRequired")
    .max(355, "maximunDescription"),
  mainGenre: yup.string().required("primaryGenreRequired"),
  subGenre: yup.string().required("secondaryGenreRequired"),
  tags: yup.array(yup.string()).required("tagsRequired").min(4, "tagsRequired"),
  releaseDate: yup.date().required(),
  freeDownloadEnabled: yup.string().required("freeDownloadEnabledRequired"),
  collaborators: yup
    .array(yup.object())
    .required("collaboratorsRequired")
    .min(1, "collaboratorsRequired"),
});

export default formSchema;
