import { Licenses } from "app/models";
import {
  DELETE_LICENSE,
  GET_LICENSE,
  CREATE_LICENSE,
  START_EDIT_LICENSE,
  EDIT_LICENSE,
  CLEAR_FORM_LICENSE,
  CHANGE_ACTIVE_LICENSE,
  CHANGE_HIGHLIGHT_LICENSE,
} from "../actions/licenseAndContracts";

const initialState = {
  licenses: [],
  license: {
    _id: undefined,
    name: undefined,
    extraWav: false,
    exclusive: undefined,
    trackStems: false,
    distributionCopies: undefined,
    freeDownloads: undefined,
    audioStreams: undefined,
    musicVideo: undefined,
    nonMonetizedVideo: undefined,
    monetizedVideo: undefined,
    allowBroadCasting: false,
    radioStation: undefined,
    profitPerformance: false,
    notProfitPerformance: undefined,
    beatsPublishing: undefined,
    beatsHookPublishing: undefined,
    topLinesAndVocals: undefined,
    licenseRenewal: undefined,
    minPrice: undefined,
    defaultTrackPrice: undefined,
    makeOffersOnly: false,
    state: undefined,
    country: undefined,
    description: undefined,
  },
};

const licenseAndContractsReducer = (
  state = initialState,
  { payload, type }: any
) => {
  switch (type) {
    case GET_LICENSE.REQUEST:
      return {
        ...state,
        isLoading: true,
        licenses: payload,
      };
    case GET_LICENSE.SUCCESS:
      return {
        ...state,
        licenses: payload,
        isLoading: false,
      };
    case GET_LICENSE.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_LICENSE.SUCCESS:
      return {
        ...state,
        licenses: (state?.licenses || []).filter(
          (item: Licenses) => item?._id !== payload
        ),
      };

    case CREATE_LICENSE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_LICENSE.SUCCESS:
      return {
        ...state,
        licenses: [...state.licenses, payload],
        isLoading: false,
      };
    case CREATE_LICENSE.ERROR:
      return {
        ...state,
        isLoading: false,
      };

    case START_EDIT_LICENSE.REQUEST:
      const license: any = (state?.licenses || []).find(
        (item: Licenses) => item?._id === payload
      );

      return {
        ...state,
        license: {
          ...license,
        },
      };

    case EDIT_LICENSE.REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case EDIT_LICENSE.SUCCESS:
      const newLicense = (state?.licenses || []).map((license: Licenses) =>
        license?._id === payload?._id ? payload : license
      );
      return {
        ...state,
        licenses: newLicense,
        isLoading: false,
      };

    case CLEAR_FORM_LICENSE.SUCCESS:
      return {
        ...initialState,
      };

    case CHANGE_ACTIVE_LICENSE.SUCCESS:
      const newLicenses = (state?.licenses || []).map((license: Licenses) =>
        license?._id === payload?._id ? payload : license
      );
      return {
        ...state,
        licenses: newLicenses,
      };

    case CHANGE_HIGHLIGHT_LICENSE.SUCCESS:
      const updateLicense = (state?.licenses || []).map((license: Licenses) =>
        license?._id === payload?._id ? payload : license
      );
      return {
        ...state,
        licenses: updateLicense,
      };

    default:
      return state;
  }
};

export default licenseAndContractsReducer;
