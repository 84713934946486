import { createAsyncThunk } from "@reduxjs/toolkit";
import CollaborationsService from "app/apis/collaborations";
import { UpdateCollaboration } from "app/models";

export const TYPES = {
  GET_COLLABORATIONS: "GET_COLLABORATIONS",
};

export const getCollaborations = createAsyncThunk(
  TYPES.GET_COLLABORATIONS,
  CollaborationsService.getCollaborations
);

export const updateCollaboration = createAsyncThunk(
  TYPES.GET_COLLABORATIONS,
  async (body: UpdateCollaboration) => {
    await CollaborationsService.updateCollaboration(body);
    const getCollaborations = await CollaborationsService.getCollaborations();
    return getCollaborations;
  }
);
