import ToastPlanSubscription from "app/components/toast/toastplan";
import { BulkOffers, CartLicenses, CreditCard } from "app/models";
import axiosService from "services/axios.service";

interface purchasePaypalProps {
  licenses: CartLicenses[];
  bulkOffers: BulkOffers[];
  totalAmount: number;
  paymentType: string;
  paymentMethodId: string | undefined;
  subscriptionId: string | undefined;
  trackIds: string[];
  soundkitIds: string[];
  rememberCard: boolean | undefined;
  promoCode: string;
  ipAddress?: string;
}
interface capturePaypalProps {
  orderId: string;
  licenses: CartLicenses[];
  bulkOffers: BulkOffers[];
  trackIds: string[];
  soundkitIds: string[];
  ipAddress: string;
}

export default class CartService {
  static async purchaseCart({
    subscriptionId,
    licenses,
    totalAmount,
    soundkitIds,
    paymentType,
    paymentMethodId,
    trackIds,
    bulkOffers,
    rememberCard,
    promoCode,
    ipAddress,
  }: purchasePaypalProps) {
    try {
      const response = await axiosService.post("/payments", {
        trackIds,
        paymentMethodId,
        subscriptionId,
        licenses,
        totalAmount,
        soundkitIds,
        paymentType,
        rememberCard,
        bulkOffers,
        promoCode,
        ipAddress,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      return response?.data?.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async capturePaypal(body: capturePaypalProps) {
    try {
      const bodyTz = {
        ...body,
        tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const { data } = await axiosService.post(
        "/payments/paypal-consolidate",
        bodyTz
      );
      return data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getTrackCart(body: { trackIds: String[] }) {
    try {
      const { data } = await axiosService.get("/tracks/cart", { params: body });
      return data?.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getSoundkitCart(body: { soundkitIds: String[] }) {
    try {
      const { data } = await axiosService.get("/soundkit/cart", {
        params: body,
      });
      return data?.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async listPaymentMethods() {
    try {
      const { data } = await axiosService.get(
        "/payments/retrieve-payment-methods"
      );
      return data.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async validatePromoCode(promoCode: string) {
    try {
      const { data } = await axiosService.get(`/promo-codes/${promoCode}`);
      return data.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async updateCreditCard(payload: CreditCard) {
    try {
      const { data } = await axiosService.post(
        `/payments/update-credit-card`,
        payload
      );

      ToastPlanSubscription({
        description: "Update credit card success",
        isError: false,
      });
      return data.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deleteCreditCard(paymentMethodId: string) {
    try {
      await axiosService.delete(
        `/payments/delete-credit-card/${paymentMethodId}`
      );
      const { data } = await axiosService.get(
        "/payments/retrieve-payment-methods"
      );
      ToastPlanSubscription({
        description: "Delete credit card success",
        isError: false,
      });
      return data.data;
    } catch (e: any) {
      const message = e.response.data.message;
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}
