import { Box } from "@chakra-ui/react";
import React from "react";
import ItemSelectMessages from "./Conversations/LeftConversations";
import "./styles.scss";

export default function ConversationsComponents() {
  return (
    <Box
      p={{ base: "16px", md: "30px", xl: "40px 80px" }}
      className="conversation-components"
      position="relative"
    >
      <ItemSelectMessages />
    </Box>
  );
}
