import React from "react";

const AddCoverPlaylist = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <g clip-path="url(#clip0_11525_22553)">
        <path
          d="M5 0.9375C2.75996 0.9375 0.9375 2.75996 0.9375 5C0.9375 7.24004 2.75996 9.0625 5 9.0625C7.24004 9.0625 9.0625 7.24004 9.0625 5C9.0625 2.75996 7.24004 0.9375 5 0.9375ZM6.5625 5.3125H5.3125V6.5625C5.3125 6.64538 5.27958 6.72486 5.22097 6.78347C5.16236 6.84207 5.08288 6.875 5 6.875C4.91712 6.875 4.83763 6.84207 4.77903 6.78347C4.72042 6.72486 4.6875 6.64538 4.6875 6.5625V5.3125H3.4375C3.35462 5.3125 3.27513 5.27958 3.21653 5.22097C3.15792 5.16236 3.125 5.08288 3.125 5C3.125 4.91712 3.15792 4.83763 3.21653 4.77903C3.27513 4.72042 3.35462 4.6875 3.4375 4.6875H4.6875V3.4375C4.6875 3.35462 4.72042 3.27513 4.77903 3.21653C4.83763 3.15792 4.91712 3.125 5 3.125C5.08288 3.125 5.16236 3.15792 5.22097 3.21653C5.27958 3.27513 5.3125 3.35462 5.3125 3.4375V4.6875H6.5625C6.64538 4.6875 6.72486 4.72042 6.78347 4.77903C6.84207 4.83763 6.875 4.91712 6.875 5C6.875 5.08288 6.84207 5.16236 6.78347 5.22097C6.72486 5.27958 6.64538 5.3125 6.5625 5.3125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_11525_22553">
          <rect width="10" height="10" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default AddCoverPlaylist;
