import { createSelector } from "@reduxjs/toolkit";

export const paymentWalletsState = (state: any) => state.paymentWallets;

export const selectpaymentWallets = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.lastestTransaction;
  }
);

export const selectFetchTransactionOptions = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.fetchTransactionOptions;
  }
);

export const totalPaypalProfitSelector = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.totalPaypalProfit;
  }
);

export const totalStripeProfitSelector = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.totalStripeProfit;
  }
);

export const selectMerchantIdInfoSelector = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.merchantIdInfo;
  }
);

export const selectAccountDetails = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.accountDetails;
  }
);

export const selectStripeBalance = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.stripeBalance;
  }
);

export const isLoadingPaymentWalletsSelector = createSelector(
  paymentWalletsState,
  (paymentWallets) => {
    return paymentWallets.isLoading;
  }
);
