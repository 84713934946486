import { totalProfit } from "app/models";
import { CURRENCY } from "constants/index";
import { PAYMENT_TYPE_METHOD } from "constants/paymentType";
import {
  getlastestTransaction,
  getPaypalSeller,
  BalanceInStripe,
  getAccountDetail,
  getTotalProfit,
} from "../actions/paymentWallets";
import { Amount } from "../actions/paymentWallets/interface";

const initialState = {
  lastestTransaction: [],
  fetchTransactionOptions: {
    page: 1,
    loadingMore: false,
    refreshing: false,
    hasMore: true,
  },
  merchantIdInfo: {},
  accountDetails: {},
  stripeBalance: null,
  totalPaypalProfit: 0,
  totalStripeProfit: 0,
  isLoading: false,
};

const paymentWalletsReducer = (
  state = initialState,
  { payload, type }: any
) => {
  switch (type) {
    case getlastestTransaction.REQUEST:
      return {
        ...state,
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          page: payload.page,
          loadingMore: payload.loadingMore || false,
        },
        isLoading: true,
      };
    case getlastestTransaction.SUCCESS:
      return {
        ...state,
        lastestTransaction:
          state.fetchTransactionOptions.page === 1
            ? Array.from(payload)
            : [...state.lastestTransaction, ...payload],
        isLoading: false,
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          loadingMore: false,
          hasMore: payload.length === 25,
        },
      };
    case getlastestTransaction.ERROR:
      return {
        ...state,
        isLoading: false,
        fetchTransactionOptions: {
          ...state.fetchTransactionOptions,
          page: payload.page - 1,
          loadingMore: false,
        },
      };
    case getPaypalSeller.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getPaypalSeller.SUCCESS:
      return {
        ...state,
        isLoading: false,
        merchantIdInfo: payload,
      };
    case getAccountDetail.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getAccountDetail.SUCCESS:
      return {
        ...state,
        isLoading: false,
        accountDetails: payload.external_accounts?.data[0],
      };
    case getAccountDetail.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case BalanceInStripe.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case BalanceInStripe.SUCCESS:
      const availableBalance = payload.available?.find(
        (amount: Amount) => amount.currency === CURRENCY.USD
      );
      return {
        ...state,
        isLoading: false,
        stripeBalance: availableBalance,
      };
    case BalanceInStripe.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case getTotalProfit.REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case getTotalProfit.SUCCESS:
      const data = (payload?.data || []).find(
        (item: totalProfit) => item?.totalProfit
      );
      const totalProfit = data?.totalProfit || 0;
      const paymentType = payload.paymentType;
      return {
        ...state,
        ...(paymentType === PAYMENT_TYPE_METHOD.PAYPAL
          ? { totalPaypalProfit: totalProfit }
          : { totalStripeProfit: totalProfit }),
        isLoading: false,
      };
    case getTotalProfit.ERROR:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
export default paymentWalletsReducer;
