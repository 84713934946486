import { createAsyncThunk } from "@reduxjs/toolkit";
import ReferralsService from "app/apis/referrals";

export const TYPES = {
  GET_GENERAL_INFO_REFERRALS: "GET_GENERAL_INFO_REFERRALS",
  SET_TOTAL_REFERRED: "SET_TOTAL_REFERRED",
  GET_RECENT_REFERRALS: "GET_RECENT_REFERRALS",
};

export const getGeneralInfoReferrals = createAsyncThunk(
  TYPES.GET_GENERAL_INFO_REFERRALS,
  ReferralsService.getGeneralInfoReferrals
);

export const setTotalReferred = createAsyncThunk(
  TYPES.SET_TOTAL_REFERRED,
  ReferralsService.setTotalReferred
);

export const getRecentReferrals = createAsyncThunk(
  TYPES.GET_RECENT_REFERRALS,
  ReferralsService.getRecentReferrals
);
