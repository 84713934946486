
import React from 'react';

const Minus = () => {
    return (
        <svg width='10' height='10' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect y='5.31445' width='12' height='1.37143' rx='0.685714' fill='#ED3636' />
        </svg>
    );
};
export default Minus;
