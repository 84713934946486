import { createReducer, PayloadAction, current } from "@reduxjs/toolkit";
import { TrackSoundKit, User } from "app/models";
import * as tracksActions from "app/redux/actions/myTrack";
import {
  fetchFavoritesTrack,
  fetchRepost,
  setFavorites,
  setReposts,
  unSetFavorites,
  updateTrackReleased,
} from "app/redux/actions/myTrack";
import { Nullable, Undefinedable } from "constants/types";
import { filterTracks } from "utils/convertTracks";

export interface MyTrackState {
  released: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  purchased: TrackSoundKit[];
  favorites: TrackSoundKit[];
  reposts: TrackSoundKit[];
  drafts: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  track: Nullable<TrackSoundKit>;
  deletedDraft: Undefinedable<TrackSoundKit>;
  deletedRelease: Undefinedable<TrackSoundKit>;
  deletedPrivate: Undefinedable<TrackSoundKit>;
  privateTrack: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  scheduleTrack: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
  trackDetail: TrackSoundKit;
  isLoadingDelete: boolean;
  isLoading: boolean;
  allUser: User[];
  tracksPending: {
    items: TrackSoundKit[];
    hasMore: boolean;
    page: number;
    loadingMore: boolean;
  };
}

const initialState: MyTrackState = {
  released: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  purchased: [],
  favorites: [],
  drafts: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  track: null,
  reposts: [],
  deletedDraft: undefined,
  deletedRelease: undefined,
  deletedPrivate: undefined,
  privateTrack: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  scheduleTrack: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
  trackDetail: {},
  isLoadingDelete: false,
  isLoading: false,
  allUser: [],
  tracksPending: {
    items: [],
    hasMore: true,
    page: 1,
    loadingMore: false,
  },
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(tracksActions.fetchReleased.pending, (state, action) => {
      return {
        ...state,
        released: {
          ...state.released,
          loadingMore: action.meta.arg?.loadingMore || false,
          page: action.meta.arg?.page,
        },
      };
    })
    .addCase(
      tracksActions.fetchReleased.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newRelease =
          state.released.page === 1
            ? action.payload
            : [...state.released.items, ...action.payload];

        const filterRelease = filterTracks(newRelease);

        return {
          ...state,
          released: {
            ...state.released,
            items: filterRelease,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
        };
      }
    )
    .addCase(tracksActions.fetchReleased.rejected, (state, action) => {
      return {
        ...state,
        released: {
          ...state.released,
          loadingMore: false,
          page: action.meta.arg?.page - 1,
        },
      };
    })
    .addCase(updateTrackReleased.SUCCESS, (state, action) => {
      const currentReleaseState = current(state).released;
      const currentScheduleState = current(state).scheduleTrack;
      const currentPrivateState = current(state).privateTrack;
      const currentDraftsState = current(state).drafts;
      const currentPendingTracksState = current(state).tracksPending;

      const newTrack = (trackOld) => {
        return trackOld.items.filter((item) => {
          return !action.payload.includes(item._id);
        });
      };
      return {
        ...state,
        released: {
          ...state.released,
          items: newTrack(currentReleaseState),
        },
        scheduleTrack: {
          ...state.scheduleTrack,
          items: newTrack(currentScheduleState),
        },
        privateTrack: {
          ...state.privateTrack,
          items: newTrack(currentPrivateState),
        },
        drafts: {
          ...state.drafts,
          items: newTrack(currentDraftsState),
        },
        tracksPending: {
          ...state.tracksPending,
          items: newTrack(currentPendingTracksState),
        },
      };
    })
    .addCase(
      tracksActions.fetchPurchased.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          purchased: action.payload,
        };
      }
    )
    .addCase(tracksActions.fetchFavorites.pending, (state) => {
      return {
        ...state,
        favorites: [],
      };
    })
    .addCase(
      tracksActions.fetchFavorites.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          favorites: action.payload,
        };
      }
    )
    .addCase(tracksActions.fetchPrivate.pending, (state, action) => {
      return {
        ...state,
        privateTrack: {
          ...state.privateTrack,
          loadingMore: action.meta.arg?.loadingMore || false,
          page: action.meta.arg?.page,
        },
      };
    })
    .addCase(
      tracksActions.fetchPrivate.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newPrivate =
          state.privateTrack.page === 1
            ? action.payload
            : [...state.privateTrack.items, ...action.payload];
        const filterPrivate = filterTracks(newPrivate);
        return {
          ...state,
          privateTrack: {
            ...state.privateTrack,
            items: filterPrivate,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
        };
      }
    )
    .addCase(tracksActions.fetchPrivate.rejected, (state, action) => {
      return {
        ...state,
        privateTrack: {
          ...state.privateTrack,
          loadingMore: false,
          page: action.meta.arg?.page - 1,
        },
      };
    })
    .addCase(tracksActions.fetchSchedule.pending, (state, action) => {
      return {
        ...state,
        scheduleTrack: {
          ...state.scheduleTrack,
          loadingMore: action.meta.arg?.loadingMore || false,
          page: action.meta.arg?.page,
        },
      };
    })
    .addCase(
      tracksActions.fetchSchedule.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newScheduleTrack =
          state.scheduleTrack.page === 1
            ? action.payload
            : [...state.scheduleTrack.items, ...action.payload];
        const filterScheduleTrack = filterTracks(newScheduleTrack);
        return {
          ...state,
          scheduleTrack: {
            ...state.scheduleTrack,
            items: filterScheduleTrack,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
        };
      }
    )
    .addCase(tracksActions.fetchSchedule.rejected, (state, action) => {
      return {
        ...state,
        scheduleTrack: {
          ...state.scheduleTrack,
          loadingMore: false,
          page: action.meta.arg?.page - 1,
        },
      };
    })
    .addCase(tracksActions.fetchDrafts.pending, (state, action) => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loadingMore: action.meta.arg?.loadingMore || false,
          page: action.meta.arg?.page,
        },
      };
    })
    .addCase(
      tracksActions.fetchDrafts.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newDraftsTracks =
          state.drafts.page === 1
            ? action.payload
            : [...state.drafts.items, ...action.payload];

        const filterDraftsTracks = filterTracks(newDraftsTracks);
        return {
          ...state,
          drafts: {
            ...state.drafts,
            items: filterDraftsTracks,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
        };
      }
    )
    .addCase(tracksActions.fetchDrafts.rejected, (state, action) => {
      return {
        ...state,
        drafts: {
          ...state.drafts,
          loadingMore: false,
          page: action.meta.arg?.page - 1,
        },
      };
    })
    .addCase(tracksActions.fetchTracksPending.pending, (state, action) => {
      return {
        ...state,
        tracksPending: {
          ...state.tracksPending,
          loadingMore: action.meta.arg?.loadingMore || false,
          page: action.meta.arg?.page,
        },
      };
    })
    .addCase(
      tracksActions.fetchTracksPending.fulfilled,
      (state, action: PayloadAction<any>) => {
        const newTracksPending =
          state.tracksPending.page === 1
            ? action.payload
            : [...state.tracksPending.items, ...action.payload];

        const filterTracksPending = filterTracks(newTracksPending);
        return {
          ...state,
          tracksPending: {
            ...state.tracksPending,
            items: filterTracksPending,
            hasMore: action.payload.length === 25,
            loadingMore: false,
          },
        };
      }
    )
    .addCase(tracksActions.fetchTracksPending.rejected, (state, action) => {
      return {
        ...state,
        tracksPending: {
          ...state.tracksPending,
          loadingMore: false,
          page: action.meta.arg?.page - 1,
        },
      };
    })
    .addCase(
      tracksActions.updateTracks,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          ...action.payload,
        };
      }
    )
    .addCase(setFavorites.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favorites: [...state.favorites, action.payload],
      };
    })
    .addCase(setFavorites.ERROR, (state, action: PayloadAction<any>) => {
      const removeFavorites = state.favorites.filter(
        (item) => item._id !== action.payload?._id
      );
      return {
        ...state,
        favorites: removeFavorites,
      };
    })
    .addCase(
      fetchFavoritesTrack.SUCCESS,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          favorites: action.payload,
        };
      }
    )
    .addCase(fetchRepost.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        reposts: action.payload,
      };
    })
    .addCase(unSetFavorites.SUCCESS, (state, action: PayloadAction<any>) => {
      const favorites = state.favorites.filter(
        (item: TrackSoundKit) => item?._id !== action.payload?._id
      );
      return {
        ...state,
        favorites: [...favorites],
      };
    })
    .addCase(unSetFavorites.ERROR, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        favorites: [...state.favorites, { _id: action?.payload }],
      };
    })
    .addCase(setReposts.SUCCESS, (state, action: PayloadAction<any>) => {
      return {
        ...state,
        reposts: [...state.reposts, action.payload],
      };
    })
    .addCase(tracksActions.deleteTrackRelease.pending, (state, action) => {
      return {
        ...state,
        isLoadingDelete: true,
      };
    })
    .addCase(
      tracksActions.deleteTrackRelease.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoadingDelete: false,
          released: {
            ...state.released,
            items: state.released.items.filter(
              (item) => item._id !== action.payload
            ),
          },
          privateTrack: {
            ...state.privateTrack,
            items: state.privateTrack.items.filter(
              (item) => item._id !== action.payload
            ),
          },
          scheduleTrack: {
            ...state.scheduleTrack,
            items: state.scheduleTrack.items.filter(
              (item) => item._id !== action.payload
            ),
          },
        };
      }
    )

    .addCase(tracksActions.deleteTrackRelease.rejected, (state, action) => {
      return {
        ...state,
        isLoadingDelete: false,
      };
    })

    .addCase(tracksActions.deleteTrackDraft.pending, (state, action) => {
      return {
        ...state,
        isLoadingDelete: true,
      };
    })
    .addCase(
      tracksActions.deleteTrackDraft.fulfilled,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoadingDelete: false,
          drafts: {
            ...state.drafts,
            items: state.drafts.items.filter(
              (item) => item._id !== action.payload
            ),
          },
        };
      }
    )
    .addCase(
      tracksActions.deleteTrackDraft.rejected,
      (state, action: PayloadAction<any>) => {
        return {
          ...state,
          isLoadingDelete: false,
        };
      }
    )
    .addCase(tracksActions.selectedTrackDetail.pending, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(tracksActions.selectedTrackDetail.fulfilled, (state, action) => {
      return {
        ...state,
        trackDetail: action.payload,
        isLoading: false,
      };
    })
    .addCase(tracksActions.selectedTrackDetail.rejected, (state) => {
      return {
        ...state,
        trackDetail: {},
        isLoading: false,
      };
    })
    .addCase(tracksActions.repostTrackDetail.fulfilled, (state, action) => {
      const currentTrackDetail = current(state).trackDetail;
      return {
        ...state,
        trackDetail: {
          ...state.trackDetail,
          totalRepost: Number(currentTrackDetail.totalRepost) + 1,
        },
      };
    })
    .addCase(tracksActions.likeTrackDetail.fulfilled, (state, action) => {
      const currentTrackDetail = current(state).trackDetail;
      return {
        ...state,
        trackDetail: {
          ...state.trackDetail,
          totalFavs: Number(currentTrackDetail.totalFavs) + 1,
        },
      };
    })
    .addCase(tracksActions.errorLikeTrackDetail, (state, action) => {
      const currentTrackDetail = current(state).trackDetail;
      return {
        ...state,
        trackDetail: {
          ...state.trackDetail,
          totalFavs: Number(currentTrackDetail.totalFavs) - 1,
        },
      };
    })
    .addCase(tracksActions.unLikeTrackDetail.fulfilled, (state, action) => {
      const currentTrackDetail = current(state).trackDetail;
      return {
        ...state,
        trackDetail: {
          ...state.trackDetail,
          totalFavs: Number(currentTrackDetail.totalFavs) - 1,
        },
      };
    })
    .addCase(tracksActions.errorUnLikeTrackDetail, (state, action) => {
      const currentTrackDetail = current(state).trackDetail;
      return {
        ...state,
        trackDetail: {
          ...state.trackDetail,
          totalFavs: Number(currentTrackDetail.totalFavs) + 1,
        },
      };
    })
    .addCase(tracksActions.getAllUser.fulfilled, (state, action) => {
      return {
        ...state,
        allUser: action.payload,
      };
    })
);
