export const userRole = {
  PRODUCER: "Producer",
  ARTIST: "Artist",
  USER: "User",
};

export const STATUS_COLOR = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  COUNTEROFFER: "COUNTEROFFER",
  COUNTEROFFER_ACCEPTED: "COUNTEROFFER_ACCEPTED",
  EXPIRED: "EXPIRED",
  REJECT_BY_SELLER: "REJECT_BY_SELLER",
  CANCEL_BY_BUYER: "CANCEL_BY_BUYER",
  COMPLETED: "COMPLETED",
  REJECTED: "REJECTED",
  DEFAULT: "DEFAULT",
  SOLD_TO_SOMEONE_ELSE: "SOLD_TO_SOMEONE_ELSE",
};

export const ACTIVE_STATUS = [
  "PENDING",
  "ACCEPTED",
  "COUNTEROFFER",
  "COUNTEROFFER_ACCEPTED",
];

export const getStatusColor = (status?: string) => {
  switch (status) {
    case STATUS_COLOR.PENDING:
    case STATUS_COLOR.ACCEPTED:
    case STATUS_COLOR.COMPLETED:
      return { backgroundColor: "#474F24", color: "#D4F70E" };
    case STATUS_COLOR.COUNTEROFFER:
      return { backgroundColor: "#392E2A", color: "#F87F27" };
    case STATUS_COLOR.COUNTEROFFER_ACCEPTED:
      return { backgroundColor: "#392E2A", color: "#F87F27" };
    case STATUS_COLOR.EXPIRED:
    case STATUS_COLOR.REJECT_BY_SELLER:
    case STATUS_COLOR.CANCEL_BY_BUYER:
    case STATUS_COLOR.SOLD_TO_SOMEONE_ELSE:
      return { backgroundColor: "#38272B", color: "#ED3636" };
    default:
      return {
        backgroundColor: "#313236",
        color: "#FFFFFF",
      };
  }
};
