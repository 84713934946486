import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import PromoCodeService from "app/apis/promoCode";

export const TYPES = {
  FETCH_PROMO_CODE: "FETCH_PROMO_CODE",
  GET_PROMO_CODE_BY_ID: "GET_PROMO_CODE_BY_ID",
  CREATE_PROMO_CODE: "CREATE_PROMO_CODE",
  CHANGE_ACTIVE_PROMO_CODE: "CHANGE_ACTIVE_PROMO_CODE",
  EDIT_PROMO_CODE: "EDIT_PROMO_CODE",
  DELETE_PROMO_CODE: "DELETE_PROMO_CODE",
  REMOVE_PROMOCODE: "REMOVE_PROMOCODE",
};

export const fetchPromoCode = createAsyncThunk(
  TYPES.FETCH_PROMO_CODE,
  PromoCodeService.getPromoCodes
);

export const getPromoCodeById = createAction(
  TYPES.GET_PROMO_CODE_BY_ID,
  (id) => {
    return {
      payload: id,
    };
  }
);

export const removeProCode = createAction(TYPES.REMOVE_PROMOCODE);

export const createPromoCode = createAsyncThunk(
  TYPES.CREATE_PROMO_CODE,
  PromoCodeService.createPromoCode
);

export const editPromoCode = createAsyncThunk(
  TYPES.EDIT_PROMO_CODE,
  PromoCodeService.editPromoCode
);

export const changeActivePromoCode = createAsyncThunk(
  TYPES.CHANGE_ACTIVE_PROMO_CODE,
  PromoCodeService.changeActivePromoCode
);

export const deletePromoCode = createAsyncThunk(
  TYPES.DELETE_PROMO_CODE,
  PromoCodeService.deletePromoCode
);
