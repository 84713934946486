import {
  Box,
  Button,
  Modal,
  ModalBody,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactAvatarEditor from "react-avatar-editor";

import "./styles.scss";

interface ModalProps {
  isOpen: boolean;
  file?: File | null;
  handleUpload?: (file: File) => void;
  onClose?: () => void;
}

export default function ModalCropImage({
  isOpen,
  file,
  handleUpload,
  onClose,
}: ModalProps) {
  const { t } = useTranslation();
  let editor = useRef<any>(null);
  const [isLessThan766] = useMediaQuery("(max-width: 766px)");

  const nameFile = file?.name?.split(".");
  const [cropConfig, setCropConfig] = useState({
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
    width: isLessThan766 ? 300 : 500,
    height: isLessThan766 ? 300 : 500,
  });

  const handlePositionChange = useCallback(
    (position) => {
      setCropConfig({
        ...cropConfig,
        position,
      });
    },
    [setCropConfig, cropConfig]
  );

  const onComplete = useCallback(() => {
    if (editor && editor.current) {
      const canvas = editor.current.getImage();
      canvas.toBlob(function (myBlob: Blob) {
        const file = new File([myBlob], `cover-image/${nameFile?.[0]}`, {
          type: "image/png",
        });

        handleUpload && handleUpload(file);
        onClose && onClose();
      });
    }
  }, [handleUpload, nameFile, onClose]);

  const onScale = useCallback(
    (scale) => {
      setCropConfig({
        ...cropConfig,
        scale,
      });
    },
    [setCropConfig, cropConfig]
  );

  return (
    <Modal
      colorScheme="black"
      isOpen={isOpen}
      onClose={() => onClose && onClose()}
      isCentered
    >
      <ModalOverlay />
      <ModalContent
        p={{ base: "10px", md: "10px 40px" }}
        minW={{ base: "350px", sm: "450px", md: "650px", xl: "890px" }}
        margin="0px 16px"
      >
        <ModalHeader fontSize={{ base: "24px", md: "32px" }} p="0px 10px">
          {t("cropCover")}
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={() => onClose && onClose()}
          right={2}
          top={{ base: "10px", md: "15px" }}
          fontSize={{ base: "20px", md: "26px" }}
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "18px",
          }}
          padding="18px"
          color="#d4f70e"
        />
        <ModalBody p="0px" mt="20px" alignSelf="center">
          {file && (
            <ReactAvatarEditor
              ref={editor}
              scale={cropConfig.scale}
              width={cropConfig.width}
              height={cropConfig.height}
              position={cropConfig.position}
              rotate={cropConfig.rotate}
              image={file}
              onPositionChange={handlePositionChange}
              className="editor-canvas"
            />
          )}
          <Box padding={{ base: "0px 5px", md: "0px" }}>
            <Slider
              min={1}
              max={2}
              step={0.01}
              aria-label="slider-ex-2"
              defaultValue={1}
              mt="20px"
              backgroundColor="#18181C"
              h="45px"
              borderRadius="4px"
              onChange={onScale}
            >
              <SliderTrack backgroundColor="#25252A">
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb backgroundColor="#D8FD01" />
            </Slider>
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="center" p="0px" mt="30px">
          <Button
            onClick={onComplete}
            background="#34391A"
            color="#D4F70E"
            border="1px solid #D4F70E"
            _hover={{
              background: "#34391A",
            }}
            borderRadius="4px"
            p="15px 30px"
            mb="30px"
          >
            {t("Complete")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
