import { Flex, Spinner } from "@chakra-ui/react";
import PauseAudioIcon from "app/assets/icons/PauseAudio";
import PlayAudioIcon from "app/assets/icons/PlayAudioIcon";
import TooltipLabel from "app/components/TooltipLabel";
import React from "react";
import { useTranslation } from "react-i18next";

interface IPlayButtonProps {
  size?: number;
  isPlaying: boolean;
  handleTogglePlayPause?: () => void;
  isLoading?: boolean
}

const PlayButton = ({
  size = 46,
  isPlaying,
  handleTogglePlayPause,
  isLoading
}: IPlayButtonProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Flex
      width={`${size}px`}
      height={`${size}px`}
      border="1px solid #D1F310"
      borderRadius={"50%"}
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      onClick={handleTogglePlayPause}
    >
      <TooltipLabel label={!isPlaying ? t("Play") : t("Pause")}>
        <Flex
          background="rgba(255, 255, 255, 0.06)"
          width={`${size - 12}px`}
          height={`${size - 12}px`}
          borderRadius="25px"
          alignItems="center"
          justifyContent="center"
        >
          {isLoading ? <Spinner color="#D1F310" /> : !isPlaying ? <PauseAudioIcon /> : <PlayAudioIcon />}
        </Flex>
      </TooltipLabel>
    </Flex>
  );
};

export default React.memo(PlayButton);
