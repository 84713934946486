import ToastPlanSubscription from "app/components/toast/toastplan";
import { QueryPlayTrack, LoadMorePage } from "app/models";
import { TRACK_KEY_NOTATION } from "constants/trackKey";
import axiosService from "services/axios.service";
import { convertTracks } from "utils/convertTracks";
import { errorMessage } from "utils/errorMessage";
import getOptions from "utils/getOptions";
import { getIpAddress } from "utils/getData";

export default class SoundKitService {
  static async getFavoritesSK(value?: string) {
    try {
      const options = { ...getOptions(), search: value };
      const { data } = await axiosService.get("/soundkit/favorites", {
        params: options,
      });
      return convertTracks(data?.data || [], true);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async setFavorite(query: QueryPlayTrack) {
    try {
      delete query.timeCallAPi;
      const ipAddress = await getIpAddress();
      const { data } = await axiosService.post(
        `/soundkit/${query.id}/favorites`,
        { ...query, ipAddress }
      );
      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
  static async unsetFavorite(id: string) {
    try {
      const options = getOptions();
      const { data } = await axiosService.delete(`/soundkit/${id}/favorites`, {
        params: options,
      });

      return data?.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async deleteSoundkits(soundkitIds: string[]) {
    try {
      await axiosService.delete("/soundkit/delete", { data: soundkitIds });
      ToastPlanSubscription({ description: "Delete success", isError: false });
      return soundkitIds;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({
        description: "Cannot delete the release soundkit",
      });
      throw new Error(message);
    }
  }

  static async getReleasedSK(query: LoadMorePage, type = "") {
    try {
      const options = { ...getOptions(query.page, type), ...query };
      const { data } = await axiosService.get("/soundkit", {
        params: options,
      });

      return convertTracks(data?.data || [], true);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getDraftsSK(value?: string, page = 1, type = "") {
    try {
      const options = {
        ...getOptions(page, type),
        search: value,
        isDraft: true,
      };
      const { data } = await axiosService.get("/soundkit", {
        params: options,
      });

      return convertTracks(data?.data || [], true);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getPendingSK(query: LoadMorePage, type = "") {
    try {
      const options = {
        ...getOptions(query.page, type),
        isPending: true,
      };
      const { data } = await axiosService.get("/soundkit", {
        params: options,
      });
      return convertTracks(data?.data || [], true);
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getRepostsSK(value?: string) {
    try {
      const options = { ...getOptions(), search: value };
      const { data } = await axiosService.get("/soundkit/reposts", {
        params: options,
      });
      return data.data.map((item: any) => {
        return {
          ...item,
          isSK: true,
          key: TRACK_KEY_NOTATION[item.key],
        };
      });
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async setRepostSK(id: string) {
    try {
      const options = getOptions();
      await axiosService.post(`/soundkit/${id}/reposts`, {
        params: options,
      });
      return id;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }

  static async getSoundkitSlug(slug: string) {
    try {
      const { data } = await axiosService.get(`/soundkit/individual/${slug}`);

      return data.data;
    } catch (error) {
      const message = errorMessage(error);
      ToastPlanSubscription({ description: message });
      throw new Error(message);
    }
  }
}
