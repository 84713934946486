import { Box, Flex, Input, Text, Tooltip } from "@chakra-ui/react";
import ProgressBar from "app/components/ProgressBar";
import { FileDeliver, Licenses } from "app/models";
import { MINIMUM_AUDIO_BITRATE } from "constants/index";
import { FILE_UPLOAD_TYPE } from "constants/fileUploadTypes";
import useFileUpload from "hooks/useFileUpload";
import React, { useCallback, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { parseAudioFile, ParseAudioType } from "utils/getMetadataAudio";
import "./styles.scss";
import { TYPE_UPLOAD } from "constants/audioOptions";
import ToastPlanSubscription from "../toast/toastplan";
import { formatAudioDuration } from "utils/formatAudioDuration";
import { useDispatch } from "react-redux";
import {
  setDurationUnTagTracks,
  setDurationTagTracks,
} from "app/redux/actions/player";

interface AudioProps {
  data: FileDeliver;
  error: string;
  value?: string;
  setFileUrl: (url: string | void) => void;
  licensesData?: Licenses[];
  isSoundkit?: boolean;
}

export default function AudioFile({
  data,
  error,
  value,
  setFileUrl,
  licensesData = [],
  isSoundkit = false,
}: AudioProps) {
  const { t } = useTranslation();
  const {
    name,
    title,
    detail,
    icon,
    type,
    fileType: fileTypeAudio,
    enableBitrateCheck,
    isNotUpload,
  } = data;
  const dispatch = useDispatch();
  const [progress, handleUpload] = useFileUpload({
    onValueChange: setFileUrl,
  });

  const onUploadFile = useCallback(
    async (e, name?) => {
      try {
        // if (fileType === FILE_TYPE.URL) {
        //   setFileUrl(e.target.value);
        //   return;
        // }
        const file = e.target.files && e.target.files[0];

        if (!file) return;

        if (type === FILE_UPLOAD_TYPE.AUDIO) {
          const { bitrate, duration }: ParseAudioType = await parseAudioFile(
            file
          );
          if (name === "untaggedFileUrl") {
            dispatch(setDurationUnTagTracks(formatAudioDuration(duration)));
          }
          if (name === "taggedFileUrl") {
            dispatch(setDurationTagTracks(formatAudioDuration(duration)));
          }

          if (enableBitrateCheck && Number(bitrate) < MINIMUM_AUDIO_BITRATE) {
            ToastPlanSubscription({ description: t("minimunBitrate") });
            return;
          }
        }
        handleUpload && handleUpload(file);
      } catch (e) {
        ToastPlanSubscription({ description: t("errorUploadAudio") });
      }
    },
    [type, handleUpload, enableBitrateCheck, dispatch, t]
  );

  const typeFileAudio = useMemo(() => {
    return type === FILE_UPLOAD_TYPE.AUDIO ? "upload-audio" : "upload-compress";
  }, [type]);

  const handleCheckTypeBeforeUpload = useCallback(
    (e) => {
      if (licensesData?.length > 0 || isSoundkit) {
        if (isNotUpload) {
          ToastPlanSubscription({
            title: t("upgradeYourPlanToUpload"),
            description: t("pleaseUpgradeYourPlan"),
          });
          e.preventDefault();
          return;
        }
        e.stopPropagation();
      } else {
        e.preventDefault();
      }
    },
    [isNotUpload, isSoundkit, licensesData?.length, t]
  );
  useEffect(() => {
    if (error) {
      document.getElementById(error)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [error]);

  return (
    <Tooltip
      label={
        licensesData?.length <= 0 &&
        !isSoundkit &&
        "Please choose a license at first"
      }
      placement="top"
    >
      <Box id={name}>
        <Box
          mt="5px"
          background="#24252A"
          p="15px"
          cursor="pointer"
          borderRadius="8px"
        >
          <Flex justifyContent="space-between">
            <Box cursor="pointer">
              <Input
                id={`${typeFileAudio}-${name}`}
                accept={
                  type === FILE_UPLOAD_TYPE.ZIP
                    ? TYPE_UPLOAD.ZIP
                    : fileTypeAudio ?? TYPE_UPLOAD.AUDIO
                }
                type="file"
                onChange={(e) => onUploadFile(e, name)}
                display="none"
                onClick={(e) => handleCheckTypeBeforeUpload(e)}
              />
              <label htmlFor={`${typeFileAudio}-${name}`}>
                <Box cursor="pointer">
                  <Text fontSize="14px">{t(title)}</Text>
                  {value ? (
                    <Text fontSize="13px" color="#D8FD01">
                      {t("uploadSuccess")}
                    </Text>
                  ) : (
                    <Text whiteSpace="pre-line" fontSize="12px" color="#535353">
                      {t(detail)}
                    </Text>
                  )}
                </Box>
              </label>
            </Box>

            <Box>{icon}</Box>
          </Flex>
          {progress > 0 && <ProgressBar progress={progress} />}
        </Box>
        {error && <Text color="red">{t(String(error))}</Text>}
      </Box>
    </Tooltip>
  );
}
