import { createAction, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import TrackService from "app/apis/tracks";
import { QueryPlayTrack, TrackSoundKit } from "app/models";
import { generateActionTypes } from "../actionTypes";
import {
  updateAddRepostTrack,
  updateAddRepostTrackInRecentAction,
  updateLikesTrack,
  updateLikesTrackInRecentAction,
  updateUnLikesTrack,
  updateUnLikesTrackInRecentAction,
} from "../profile";
import { errorUnLikeRecentActionsHomepage } from "../home";

export const TYPES = {
  FETCH_RELEASED_TRACKS: "FETCH_RELEASED_TRACKS",
  FETCH_FAVORITES_TRACKS: "FETCH_FAVORITES_TRACKS",
  FETCH_PURCHASED_TRACKS: "FETCH_PURCHASED_TRACKS",
  FETCH_DRAFTS_TRACKS: "FETCH_DRAFTS_TRACKS",
  DELETE_TRACK: "DELETE_TRACK",
  DELETE_TRACK_DRAFT: "DELETE_TRACK_DRAFT",
  UPDATE_TRACKS: "UPDATE_TRACKS",
  FETCH_PRIVATE_TRACKS: "FETCH_PRIVATE_TRACKS",
  FETCH_SCHEDULE_TRACKS: "FETCH_SCHEDULE_TRACKS",
  SELECT_TRACK_DETAIL: "SELECT_TRACK_DETAIL",
  REPOST_TRACK_DETAIL: "REPOST_TRACK_DETAIL",
  LIKE_TRACK_DETAIL: "LIKE_TRACK_DETAIL",
  UNLIKE_TRACK_DETAIL: "UNLIKE_TRACK_DETAIL",
  GET_ALL_USER: "GET_ALL_USER",
  FETCH_TRACKS_PENDING: "FETCH_TRACKS_PENDING",
  LIKE_TRACK_DETAIL_ERROR: "LIKE_TRACK_DETAIL_ERROR",
  UNLIKE_TRACK_DETAIL_ERROR: "UNLIKE_TRACK_DETAIL_ERROR",
};

export const fetchReleased = createAsyncThunk(
  TYPES.FETCH_RELEASED_TRACKS,
  async (query?: {}) => {
    return await TrackService.getReleased(query);
  }
);
export const setFavorites = generateActionTypes("SET_FAVORITE");
export const unSetFavorites = generateActionTypes("UNSET_FAVORITE");
export const setReposts = generateActionTypes("SET_REPOSTS");
export const fetchRepost = generateActionTypes("FETCH_REPOST");
export const updateTrackReleased = generateActionTypes("UPDATE_TRACK_RELEASED");

// --------------------------------------------------------------
// FETCH_FAVORITES_TRACKS
// --------------------------------------------------------------

export const fetchFavorites = createAsyncThunk(
  TYPES.FETCH_FAVORITES_TRACKS,
  TrackService.getFavorites
);

export const fetchPrivate = createAsyncThunk(
  TYPES.FETCH_PRIVATE_TRACKS,
  async (query?: {}) => {
    return await TrackService.getReleased(query);
  }
);

export const fetchSchedule = createAsyncThunk(
  TYPES.FETCH_SCHEDULE_TRACKS,
  async (query?: {}) => {
    return await TrackService.getReleased(query);
  }
);

export const fetchPurchased = createAsyncThunk(
  TYPES.FETCH_PURCHASED_TRACKS,
  TrackService.getPurchased
);

export const fetchDrafts = createAsyncThunk(
  TYPES.FETCH_DRAFTS_TRACKS,
  async (query?: {}) => {
    return await TrackService.getDrafts(query);
  }
);

export const fetchTracksPending = createAsyncThunk(
  TYPES.FETCH_TRACKS_PENDING,
  async (query?: {}) => {
    return await TrackService.getTracksPending(query);
  }
);

export const updateTracks = createAction(TYPES.UPDATE_TRACKS, (payload) => {
  return {
    payload,
  };
});

export const deleteTrackRelease = createAsyncThunk(
  TYPES.DELETE_TRACK,
  async (trackId: string | undefined) => {
    return await TrackService.deleteTrackRelease(trackId);
  }
);

export const deleteTrackDraft = createAsyncThunk(
  TYPES.DELETE_TRACK_DRAFT,
  async (trackId: string | undefined) => {
    return await TrackService.deleteTrackDraft(trackId);
  }
);

export const fetchFavoritesTrack = generateActionTypes(
  "FETCH_FAVORITES_TRACKS"
);

// --------------------------------------------------------------
// SET_FAVORITE
// --------------------------------------------------------------

const setFavoritesSuccess = (payload: { _id?: string }) => ({
  type: setFavorites.SUCCESS,
  payload: payload,
});

const setFavoritesError = (error: any) => ({
  type: setFavorites.ERROR,
  payload: error,
});

export const setFavoriteRecentActionsHomePage =
  (query: QueryPlayTrack) => async (dispatch: any) => {
    const body = { _id: query.id };
    dispatch(setFavoritesSuccess(body));
  };
export const setFavoriteErrorRecentActionsHomePage =
  (query: QueryPlayTrack) => async (dispatch: any) => {
    const body = { _id: query.id };
    dispatch(setFavoritesError(body));
  };

export const setFavorite =
  (query: QueryPlayTrack, isRecentAction: boolean) => async (dispatch: any) => {
    const body = { _id: query.id };
    dispatch(setFavoritesSuccess(body));
    if (isRecentAction) {
      dispatch(updateLikesTrackInRecentAction(body));
    } else {
      dispatch(updateLikesTrack(body));
    }
    setTimeout(async () => {
      try {
        await TrackService.setFavorite(query);
      } catch (error) {
        dispatch(setFavoritesError(body));
      }
    }, query.timeCallAPi);
  };

// --------------------------------------------------------------
// UNSET_FAVORITE
// --------------------------------------------------------------

const unSetFavoritesSuccess = (payload: { _id: string }) => ({
  type: unSetFavorites.SUCCESS,
  payload: payload,
});

const unSetFavoritesError = (error: any) => ({
  type: unSetFavorites.ERROR,
  payload: error,
});

export const unsetFavoriteRecentActionsHomePage =
  ({ id, timeCallApi }: { id: string; timeCallApi?: number }) =>
  async (dispatch: any) => {
    const body = { _id: id };
    dispatch(unSetFavoritesSuccess(body));
    setTimeout(async () => {
      try {
        await TrackService.unsetFavorite(id);
      } catch (error) {
        dispatch(unSetFavoritesError(id));
        dispatch(errorUnLikeRecentActionsHomepage(id));
        dispatch(errorUnLikeTrackDetail(id));
      }
    }, timeCallApi);
    return { trackId: id };
  };

export const unsetFavorite =
  (
    {
      id,
      timeCallApi,
    }: {
      id: string;
      timeCallApi?: number;
    },
    isRecentAction: boolean
  ) =>
  async (dispatch: any) => {
    const body = { _id: id };
    dispatch(unSetFavoritesSuccess(body));
    if (isRecentAction) {
      dispatch(updateUnLikesTrackInRecentAction(body));
    } else {
      dispatch(updateUnLikesTrack(body));
    }
    setTimeout(async () => {
      try {
        await TrackService.unsetFavorite(id);
      } catch (error) {
        dispatch(unSetFavoritesError(id));
      }
    }, timeCallApi);
  };

// --------------------------------------------------------------
// SET_REPOST
// --------------------------------------------------------------

const setRepostSuccess = (payload: { _id: string }) => ({
  type: setReposts.SUCCESS,
  payload: payload,
});

const setRepostError = (error: any) => ({
  type: setReposts.ERROR,
  payload: error,
});

export const setRepostRecentActionsHomePage =
  (id: string) => async (dispatch: any) => {
    const body = { _id: id };
    dispatch(setRepostSuccess(body));
  };

export const setRepost =
  (id: string, isRecentAction: boolean) => async (dispatch: any) => {
    try {
      const body = { _id: id };
      dispatch(setRepostSuccess(body));
      if (isRecentAction) {
        dispatch(updateAddRepostTrackInRecentAction(body));
      } else {
        dispatch(updateAddRepostTrack(body));
      }
      await TrackService.setRepost(id);
    } catch (error: any) {
      dispatch(setRepostError(error.message));
    }
  };

// --------------------------------------------------------------
// FETCH_REPOST
// --------------------------------------------------------------
const fetchRepostsRequest = () => ({
  type: fetchRepost.REQUEST,
  payload: [],
});

const fetchRepostsSuccess = (payload: TrackSoundKit[]) => ({
  type: fetchRepost.SUCCESS,
  payload: payload,
});

const fetchRepostsError = (error: any) => ({
  type: fetchRepost.ERROR,
  payload: error,
});

export const fetchReposts = () => async (dispatch: Dispatch) => {
  dispatch(fetchRepostsRequest());
  try {
    const data = await TrackService.getReposts();
    dispatch(fetchRepostsSuccess(data));
  } catch (error: any) {
    dispatch(fetchRepostsError(error.message));
  }
};

const updateTracksReleasedRequest = () => ({
  type: updateTrackReleased.REQUEST,
  payload: [],
});

const updateTracksReleasedSuccess = (payload: string[]) => ({
  type: updateTrackReleased.SUCCESS,
  payload: payload,
});

const updateTracksReleasedError = (error: any) => ({
  type: updateTrackReleased.ERROR,
  payload: error,
});

export const updateTracksPrivate =
  (selectCheckbox: string[]) => async (dispatch: Dispatch) => {
    dispatch(updateTracksReleasedRequest());
    try {
      await TrackService.deleteTracks(selectCheckbox);
      dispatch(updateTracksReleasedSuccess(selectCheckbox));
    } catch (error: any) {
      dispatch(updateTracksReleasedError(error.message));
    }
  };

export const selectedTrackDetail = createAsyncThunk(
  TYPES.SELECT_TRACK_DETAIL,
  async (body: { slug: string; loginAsGuest?: boolean }) => {
    return await TrackService.getIndividualTrack(body);
  }
);

export const repostTrackDetail = createAsyncThunk(
  TYPES.REPOST_TRACK_DETAIL,
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setRepostRecentActionsHomePage(id));
    TrackService.setRepost(id);
    return id;
  }
);

export const likeTrackDetail = createAsyncThunk(
  TYPES.LIKE_TRACK_DETAIL,
  async (query: QueryPlayTrack, thunkAPI) => {
    thunkAPI.dispatch(setFavoriteRecentActionsHomePage(query));
    setTimeout(async () => {
      try {
        await TrackService.setFavorite(query);
      } catch (error) {
        thunkAPI.dispatch(setFavoriteErrorRecentActionsHomePage(query));
        thunkAPI.dispatch(errorLikeTrackDetail(query.id));
      }
    }, query.timeCallAPi);
    return { trackId: query?.id };
  }
);

export const unLikeTrackDetail = createAsyncThunk(
  TYPES.UNLIKE_TRACK_DETAIL,
  async (
    { id, timeCallApi }: { id: string; timeCallApi?: number },
    thunkAPI
  ) => {
    thunkAPI.dispatch(
      unsetFavoriteRecentActionsHomePage({ id: id, timeCallApi: timeCallApi })
    );
  }
);

export const getAllUser = createAsyncThunk(
  TYPES.GET_ALL_USER,
  TrackService.getAllUser
);

export const errorLikeTrackDetail = createAction(TYPES.LIKE_TRACK_DETAIL_ERROR);
export const errorUnLikeTrackDetail = createAction(
  TYPES.UNLIKE_TRACK_DETAIL_ERROR
);
