import React from "react";

const IconStudioOneDaw = () => {
  return (
    <svg
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 8.10821L0.91886 10.3304C0.995512 10.5158 1.2205 10.6684 1.42067 10.6713L6.65737 10.7448C7.39326 10.7552 8.42328 10.3549 8.95912 9.84953L12.9391 6.09611C13.4255 5.63741 13.6318 4.7573 13.3991 4.12894L11.9555 0.228516L11.1858 0.252976L11.6428 1.3054C11.8556 1.79583 11.7118 2.48849 11.32 2.85455L6.40138 7.44691C6.01025 7.81213 5.26043 8.10821 4.72411 8.10821H0ZM9.38937 11.7454C9.25471 11.3676 9.38816 10.8442 9.68786 10.5766L14.401 6.367C14.5503 6.23368 14.8342 6.12433 15.0339 6.12287L22.4393 6.06681C22.7074 6.06475 23.0196 6.25814 23.1368 6.49948L23.5553 7.36166C23.6135 7.48167 23.5521 7.5806 23.4184 7.58254L17.2252 7.67517C16.9581 7.67917 16.5783 7.82678 16.3784 8.00346L11.7428 12.1032C11.4925 12.3244 11.3703 12.7633 11.4692 13.0812L12.2349 15.5438C12.2747 15.6716 12.1974 15.7734 12.0642 15.7711L10.9392 15.7522C10.8719 15.751 10.7991 15.6991 10.7769 15.6366L9.38937 11.7454Z"
        fill="white"
      />
    </svg>
  );
};
export default IconStudioOneDaw;
