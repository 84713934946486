import { createSelector } from "@reduxjs/toolkit";
import { InitBulkOfferState } from "app/redux/reducers/bulkOffer";

export const selectBulkOffer = (state: { bulkOffer: InitBulkOfferState }) =>
  state.bulkOffer;

export const selectNewBulkOffer = createSelector(
  selectBulkOffer,
  ({ newBulkOffer }) => {
    return newBulkOffer;
  }
);
export const bulkOfferSelector = createSelector(
  selectBulkOffer,
  ({ bulkOffer }) => {
    return bulkOffer;
  }
);
export const selectedBulkSelector = createSelector(
  selectBulkOffer,
  ({ selectedBulk }) => {
    return selectedBulk;
  }
);
