import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import BulkOfferService from "app/apis/bulkOffer";

export const TYPES = {
  FETCH_BULK_OFFERS: "FETCH_BULK_OFFERS",
  GET_BULK_OFFER_BY_ID: "GET_BULK_OFFER_BY_ID",
  CREATE_BULK_OFFER: "CREATE_BULK_OFFER",
  CHANGE_ACTIVE_BULK_OFFER: "CHANGE_ACTIVE_BULK_OFFER",
  EDIT_BULK_OFFER: "EDIT_BULK_OFFER",
  DELETE_BULK_OFFER: "DELETE_BULK_OFFER",
  REMOVE_BULK_OFFER: "REMOVE_BULK_OFFER",
};

export const fetchBulkOffers = createAsyncThunk(
  TYPES.FETCH_BULK_OFFERS,
  BulkOfferService.getBulkOffers
);

export const getBulkOfferById = createAction(
  TYPES.GET_BULK_OFFER_BY_ID,
  (id) => {
    return {
      payload: id,
    };
  }
);

export const removeBulkOffer = createAction(TYPES.REMOVE_BULK_OFFER);

export const createBulkOffer = createAsyncThunk(
  TYPES.CREATE_BULK_OFFER,
  BulkOfferService.createBulkOffer
);

export const editBulkOffer = createAsyncThunk(
  TYPES.EDIT_BULK_OFFER,
  BulkOfferService.editBulkOffer
);

export const changeActiveBulkOffer = createAsyncThunk(
  TYPES.CHANGE_ACTIVE_BULK_OFFER,
  BulkOfferService.changeActiveBulkOffer
);

export const deleteBulkOffer = createAsyncThunk(
  TYPES.DELETE_BULK_OFFER,
  BulkOfferService.deleteBulkOffer
);
