import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Switch,
  Text,
  CloseButton,
  SimpleGrid,
  useMediaQuery,
} from "@chakra-ui/react";
import { transaction, User } from "app/models";
import paypal from "app/assets/images/PaypalIcon.svg";
import stripe from "app/assets/images/Stripe.svg";
import { PAYMENT_TYPE } from "constants/index";
import { usePaymentWallets } from "hooks/usePaymentWallets";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import CardPayment from "../CardPayment";
import PaymentsTranferItem from "../PaymentsTranferItem";
import "./styles.scss";
import SkeletonItemPaymentTransfer from "./skeletonItemPaymentTransfer";
import { heightLoadMore } from "utils/convertTracks";
import { FadeLoader, BeatLoader } from "react-spinners";
import Skeleton from "../common/Skeleton/skeleton";
import { Carousel } from "../Carousel";
import { v4 as uuidv4 } from "uuid";
import ModalTransactionProcess from "../SuccessModal/modalTransactionProcess";
import restrictionOnPaypal from "../../assets/LottieIAnimations/RestrictionPaypalReceive.json";
import restrictionPaypalConfirmEmail from "../../assets/LottieIAnimations/RestrictionPaypalConfirmEmail.json";
import { useHistory } from "react-router-dom";
import { checkHadData } from "utils/errorMessage";
import PaymentsTranferItemMobile from "../PaymentsTranferItem/paymentsTranferItemMobile";
import SkeletonItemPaymentTransferMobile from "./skeletonItemPaymentTransferMobile";
import { BsThreeDots } from "react-icons/bs";
import ModalChooseYourFeeStructure from "../ModalChooseYourFeeStructure";

interface PaymentAndWalletsProps {
  onAddWallet?: () => void;
  userAdmin: User;
  isLoadingAddPayment?: boolean;
}

function PaymentAndWallets({
  onAddWallet,
  userAdmin,
  isLoadingAddPayment,
}: PaymentAndWalletsProps) {
  const { t } = useTranslation();
  const [isLoadingSliderPayment, SetIsLoadingSliderPayment] = useState(true);
  const [showModalRestrictionPaypal, setShowModalRestrictionPaypal] =
    useState(false);
  const [showModalFeeStructure, setShowModalFeeStructure] = useState(false);
  const history = useHistory();
  const [isLessThan990] = useMediaQuery("(max-width: 990px)");

  const {
    getLastestTransaction,
    lastestTransactions,
    isOpenOption,
    handleCancelDisconnectPayment,
    confirmDisconnect,
    handleDisconnect,
    accountDetails,
    onGetTotalProfit,
    isLoading,
    fetchTransactionOptions,
    getPaypalSellerStatus,
    isLoadingDisconnect,
    merchantIdInfo,
    paymentType,
  } = usePaymentWallets();
  useEffect(() => {
    if (userAdmin?.merchantIdInPayPal && userAdmin?.sellerConnectedPayPal) {
      getPaypalSellerStatus(userAdmin?.merchantIdInPayPal);
    }
  }, [
    getPaypalSellerStatus,
    userAdmin?._id,
    userAdmin?.merchantIdInPayPal,
    userAdmin?.sellerConnectedPayPal,
  ]);

  useEffect(() => {
    if (userAdmin._id) {
      getLastestTransaction({ page: 1 });
    }
  }, [getLastestTransaction, userAdmin._id]);

  useEffect(() => {
    if (
      !checkHadData(merchantIdInfo) &&
      (!merchantIdInfo?.payments_receivable ||
        !merchantIdInfo?.primary_email_confirmed)
    ) {
      setShowModalRestrictionPaypal(true);
    }
  }, [
    merchantIdInfo,
    merchantIdInfo?.payments_receivable,
    merchantIdInfo?.primary_email_confirmed,
  ]);

  const headerTransfer = useMemo(
    () => [
      { name: "" },
      { name: t("name") },
      { name: t("purchaser") },
      { name: t("transactionId") },
      { name: t("saleDate") },
      { name: t("contractDetails") },
      { name: t("geographicalIpFinder") },
      { name: t("downloadConfirmation") },
      { name: t("price") },
    ],
    [t]
  );

  useEffect(() => {
    onGetTotalProfit();
  }, [onGetTotalProfit]);

  const handleScrollPage = useCallback(
    (e) => {
      if (heightLoadMore(fetchTransactionOptions, e)) {
        getLastestTransaction({
          page: 1 + fetchTransactionOptions.page,
          loadingMore: true,
        });
      }
    },
    [fetchTransactionOptions, getLastestTransaction]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollPage);
    return () => {
      window.removeEventListener("scroll", handleScrollPage);
    };
  }, [handleScrollPage]);

  const filterPurchasedHasColla = useMemo(() => {
    return lastestTransactions?.filter((item) => {
      if (item?.track) {
        return item?.sellerId?._id === item?.track?.createdBy?._id;
      }
      if (item?.soundkit) {
        return item?.sellerId?._id === item?.soundkit?.createdBy?._id;
      }
      return item;
    });
  }, [lastestTransactions]);

  const slides = [
    {
      key: uuidv4(),
      content: userAdmin?.sellerConnectedPayPal ? (
        <CardPayment
          userAdmin={userAdmin}
          isPaypal
          legalName={merchantIdInfo?.legal_name}
        />
      ) : (
        <CardPayment
          onAddWallet={onAddWallet}
          userAdmin={userAdmin}
          addWallets
          isLoadingAddPayment={isLoadingAddPayment}
        />
      ),
    },
    {
      key: uuidv4(),
      content: userAdmin?.sellerConnectedStripe ? (
        <CardPayment accountDetails={accountDetails} userAdmin={userAdmin} />
      ) : (
        <CardPayment
          onAddWallet={onAddWallet}
          userAdmin={userAdmin}
          addWallets
          isLoadingAddPayment={isLoadingAddPayment}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      SetIsLoadingSliderPayment(false);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  const handleCloseModal = useCallback(() => {
    history.push("/home");
  }, [history]);

  const popover = () => {
    return (
      <>
        <Popover>
          <PopoverTrigger>
            <Button
              backgroundColor="#18181c"
              _hover={{
                border: "1px solid #38383D !important",
                background: "#222227 !important",
                padding: "3px 5px",
              }}
              _active={{ backgroundColor: "none" }}
              borderColor="#18181c"
              borderWidth="1px"
              padding="3px 5px"
            >
              <BsThreeDots size={24} />
            </Button>
          </PopoverTrigger>
          <PopoverContent borderColor="#24252A" bg="#24252A">
            <PopoverArrow />
            <PopoverCloseButton color="#CAEA11" />
            <PopoverHeader fontWeight="400" fontSize="16px" border="none">
              {t("paymentAccounts")}
            </PopoverHeader>
            <PopoverBody>
              <Box
                bgColor=" #1F1F23"
                borderRadius="4px"
                display="flex"
                justifyContent="space-between"
                w="100%"
                height="50px"
                alignItems="center"
                p="6px"
              >
                <Image src={paypal} height="16px" />
                <Switch
                  colorScheme="Chartreuse"
                  isChecked={userAdmin?.sellerConnectedPayPal}
                  onChange={() =>
                    handleDisconnect(
                      PAYMENT_TYPE.PAYPAL,
                      userAdmin?.sellerConnectedPayPal
                    )
                  }
                  _focus={{ boxShadow: "none" }}
                  _selected={{ boxShadow: "none" }}
                />
              </Box>
            </PopoverBody>
            <PopoverBody>
              <Box
                bgColor=" #1F1F23"
                borderRadius="4px"
                display="flex"
                justifyContent="space-between"
                w="100%"
                height="50px"
                alignItems="center"
                p="6px"
              >
                <Image src={stripe} height="35px" />
                <Switch
                  colorScheme="Chartreuse"
                  isChecked={userAdmin?.sellerConnectedStripe}
                  onChange={() =>
                    handleDisconnect(
                      PAYMENT_TYPE.STRIPE,
                      userAdmin?.sellerConnectedStripe
                    )
                  }
                  _focus={{ boxShadow: "none" }}
                  _selected={{ boxShadow: "none" }}
                />
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </>
    );
  };

  return (
    <Box mt="30px">
      <Flex justifyContent="space-between" mb="10px">
        <Text
          fontSize={{ base: "28px", md: "32px" }}
          textColor="white"
          mb="30px"
        >
          {t("payment")}
        </Text>
        <Box display={{ base: "block", md: "none" }} className="payment-wallet">
          {popover()}
        </Box>
      </Flex>
      <Flex
        position="relative"
        pb="50px"
        justifyContent="center"
        overflow="hidden"
      >
        {isLoadingSliderPayment ? (
          <Flex direction={{ base: "column" }} justifyContent="center" w="100%">
            <Skeleton
              w={{ base: "100%", md: "420px" }}
              h="210px"
              m={{ md: "auto" }}
              background="#535353"
              borderRadius="6px"
            />
            <Flex justifyContent="center" mt="15px">
              <Skeleton
                w="40px"
                h="6px"
                background="#535353"
                borderRadius="4px"
              />
              <Skeleton
                w="20px"
                h="6px"
                background="#535353"
                borderRadius="4px"
              />
            </Flex>
          </Flex>
        ) : (
          <Carousel
            slidesDefault={slides.map((s) => s.content)}
            arrowBorders={false}
            isPayment
          />
        )}
        <Box
          position="absolute"
          top="0px"
          right={{ md: "50px", xl: "200px" }}
          className="payment-wallet"
          display={{ base: "none", md: "block" }}
        >
          {popover()}
        </Box>
      </Flex>
      <Flex
        className="payments-transfer-item"
        display={{ base: "none", lg: "flex" }}
        minWidth="1024px"
        gap="15px"
      >
        {headerTransfer.map((item, index) =>
          isLoading ? (
            <Box className={`colums-${index} flex-${index}`}>
              <Skeleton display={index === 0 ? "none" : "block"} />
            </Box>
          ) : (
            <Box key={index} className={`colums-${index}`}>
              <Text fontSize="12px" lineHeight="16px">
                {item.name}
              </Text>
            </Box>
          )
        )}
      </Flex>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 1 }}
        spacing={{ md: "40px", lg: "0px" }}
      >
        {isLoading && !fetchTransactionOptions.loadingMore ? (
          isLessThan990 ? (
            <SkeletonItemPaymentTransferMobile />
          ) : (
            <SkeletonItemPaymentTransfer />
          )
        ) : (
          (filterPurchasedHasColla || [])?.map(
            (item: transaction, index: number) => (
              <Box key={index}>
                {isLessThan990 ? (
                  <PaymentsTranferItemMobile transactionItems={item} />
                ) : (
                  <PaymentsTranferItem transactionItems={item} />
                )}
              </Box>
            )
          )
        )}
      </SimpleGrid>
      <Box display="flex" justifyContent="center" mt="16px">
        <FadeLoader
          color={"#d4f70e"}
          loading={fetchTransactionOptions.loadingMore}
        />
      </Box>
      {showModalRestrictionPaypal && (
        <ModalTransactionProcess
          isOpen={showModalRestrictionPaypal}
          onClose={handleCloseModal}
          iconJson={
            !merchantIdInfo?.payments_receivable
              ? restrictionOnPaypal
              : restrictionPaypalConfirmEmail
          }
          buttonLabel={"backToFeed"}
          onButtonClick={handleCloseModal}
        />
      )}
      {showModalFeeStructure && (
        <ModalChooseYourFeeStructure
          showModal={showModalFeeStructure}
          setShowModal={setShowModalFeeStructure}
        />
      )}
      <Box className="modal">
        <Modal
          colorScheme="black"
          isOpen={isOpenOption}
          onClose={handleCancelDisconnectPayment}
        >
          <ModalOverlay />
          <ModalContent p="40px 30px">
            <CloseButton
              _focus={{
                borderWidth: "0px",
              }}
              onClick={handleCancelDisconnectPayment}
              right={3}
              top="25px"
              fontSize="20px"
              position="absolute"
              borderColor="#38383D"
              _hover={{
                border: "1px solid #38383D !important",
                padding: "14px",
              }}
              padding="14px"
              color="#d4f70e"
            />
            <ModalBody p="0px" mt="20px">
              <Flex alignItems="center" flexDirection="column">
                <Text textAlign="center" fontSize="15px">
                  {paymentType === PAYMENT_TYPE.PAYPAL
                    ? t("youWantToDisconnect")
                    : t("desDiconnectStripe")}
                </Text>
                <Text fontSize="14px" lineHeight="24px" textAlign="center">
                  {t("paymentConflict")}
                </Text>
              </Flex>
            </ModalBody>

            <ModalFooter justifyContent="center" p="0px" mt="30px">
              <Button
                backgroundColor="rgba(255, 255, 255, 0.08)"
                color="white"
                fontSize="14px"
                borderRadius="4px"
                p="15px 30px"
                _hover={{ boxShadow: "none" }}
                onClick={handleCancelDisconnectPayment}
                mr="4px"
              >
                {t("cancel")}
              </Button>
              <Button
                backgroundColor="#ED3636"
                color="white"
                fontSize="14px"
                borderRadius="4px"
                p="15px 30px"
                _hover={{ boxShadow: "none" }}
                onClick={confirmDisconnect}
                isLoading={isLoadingDisconnect}
                spinner={<BeatLoader size={8} color="white" />}
              >
                {t("disconnect")}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </Box>
  );
}

export default React.memo(PaymentAndWallets);
