import React from "react";

const IconAddTrackPlaylist = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <g filter="url(#filter0_b_6483_50380)">
        <rect width="28" height="28" rx="14" fill="#24252A" />
        <rect
          x="1"
          y="1"
          width="26"
          height="26"
          rx="13"
          stroke="#D4F70E"
          strokeWidth="2"
        />
      </g>
      <rect
        x="8.75"
        y="14.875"
        width="1.75"
        height="10.5"
        rx="0.875"
        transform="rotate(-90 8.75 14.875)"
        fill="#CAEA11"
      />
      <rect
        x="14.875"
        y="19.25"
        width="1.75"
        height="10.5"
        rx="0.875"
        transform="rotate(180 14.875 19.25)"
        fill="#CAEA11"
      />
      <defs>
        <filter
          id="filter0_b_6483_50380"
          x="-14"
          y="-14"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_6483_50380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_6483_50380"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
export default IconAddTrackPlaylist;
