import { AnalyticsQuery } from "app/models";
import axiosService from "services/axios.service";
import { errorMessage } from "utils/errorMessage";
import ToastPlanSubscription from "app/components/toast/toastplan";

class AnalyticsController {
  static async getAnalyticsTracks(analyticsQuery: AnalyticsQuery) {
    try {
      const { data } = await axiosService.get("/analytics/analytics-track", {
        params: {
          timeType: analyticsQuery.timeType,
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
          analyticType: analyticsQuery.analyticType,
        },
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getListPlaysAnalyticsTracks(analyticsQuery: AnalyticsQuery) {
    try {
      const { data } = await axiosService.get("/analytics/top", {
        params: {
          timeType: analyticsQuery.timeType,
          analyticType: analyticsQuery.analyticType,
        },
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getListUserAnalytics(analyticsQuery: AnalyticsQuery) {
    try {
      const { data } = await axiosService.get("/analytics/top-users", {
        params: {
          timeType: analyticsQuery.timeType,
          analyticType: analyticsQuery.analyticType,
        },
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }

  static async getListTopCountryAnalytics(analyticsQuery: AnalyticsQuery) {
    try {
      const { data } = await axiosService.get("/analytics/top-country", {
        params: {
          timeType: analyticsQuery.timeType,
          analyticType: analyticsQuery.analyticType,
        },
      });

      return data.data;
    } catch (e) {
      const message = errorMessage(e);
      ToastPlanSubscription({ description: message });
      return message;
    }
  }
}

export default AnalyticsController;
