import {
  Button,
  Flex,
  Modal,
  CloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { BeatLoader } from "react-spinners";

interface ModalProps {
  isOpen: boolean;
  isLoading: boolean;
  onClose: () => void;
  onCancelClick: () => void;
}

export default function ModalCancelSubscription({
  isOpen,
  isLoading,
  onClose,
  onCancelClick,
}: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent minW="476px">
        <ModalHeader p="8px">
          <Flex flexDirection="column">
            <Text textAlign="center" fontSize="24px">
              {t("MainstreamPlan")}
            </Text>
            <Text
              textAlign="center"
              fontSize="14px"
              mt="16px"
              mb="32px"
              padding="0px 40px"
            >
              {t("cancelYourCurrent")}
            </Text>
          </Flex>
        </ModalHeader>
        <CloseButton
          _focus={{
            borderWidth: "0px",
          }}
          onClick={onClose}
          right={3}
          top="9px"
          fontSize="15px"
          position="absolute"
          borderColor="#38383D"
          _hover={{
            border: "1px solid #38383D !important",
            padding: "16px",
          }}
          padding="16px"
          color="#d4f70e"
        />
        <ModalFooter p="0px">
          <Flex w="100%" justifyContent="center" mb="20px">
            <Button
              fontSize="14px"
              bg="rgba(255, 255, 255, 0.04)"
              mr={3}
              onClick={onClose}
              lineHeight="17px"
              borderRadius="4px"
              height="48px"
              w="150px"
              _hover={{
                bg: "rgba(255, 255, 255, 0.04)",
                color: "white",
              }}
            >
              {t("cancel")}
            </Button>
            <Button
              height="48px"
              w="150px"
              borderRadius="4px"
              bg="#ED3636"
              mr={3}
              _hover={{
                bg: "#ED3636",
                color: "white",
              }}
              isLoading={isLoading}
              onClick={onCancelClick}
              spinner={<BeatLoader size={8} color="white" />}
            >
              {t("Proceed")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
