import { Button, Text } from "@chakra-ui/react";
import React from "react";

interface ButtonItemProps {
  onClick?: () => void;
  icon?: any;
  text: string;
  minWidth: string;
  backgroundColor?: string;
  color?: string;
  border?: string;
}

export default function ButtonItem({
  onClick,
  icon: Icon,
  text,
  minWidth,
  backgroundColor = "#18181C",
  color = "#F1F2F3",
  border,
}: ButtonItemProps) {
  return (
    <Button
      padding="0px 10px"
      ml="4px"
      borderRadius="4px"
      height="48px"
      bg={backgroundColor}
      color={color}
      minW={minWidth}
      _hover={{ bg: "#18181C" }}
      _active={{ bg: "#363B1E" }}
      _focus={{ boxShadow: "none" }}
      cursor="pointer"
      onClick={onClick}
      mt="10px"
      fontSize="15px"
      fontFamily="Cera Pro Bold"
      border={border}
    >
      {Icon}
      <Text fontWeight="400" fontSize="14px" lineHeight="17px" ml="6px">
        {text}
      </Text>
    </Button>
  );
}
