import {
  Box,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  Button,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { Bell } from "app/assets/icons";
import { ItemNotifications } from "app/models";
import { deleteNotifications } from "app/redux/actions/notifications";
import { selectLoggedIn } from "app/redux/selectors/users";
import { renderIcon, renderTitle } from "constants/notification";
import { NOTIFICATION_TYPE } from "constants/statusType";
import { useNotification } from "hooks/useNotifications/useNotifications";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fromNow } from "utils/timeAgo";
import "./styles.scss";
import GlobalLoading from "../GlobalLoading";
import { RootState } from "types";
import { isLoadingSelector } from "app/redux/selectors/status";
const Notification = () => {
  const { t } = useTranslation();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const loggedIn = useSelector(selectLoggedIn);

  const history = useHistory();
  const dispatch = useDispatch();
  const handleClickNottifications = useCallback(
    (type) => {
      history.push(`/notification?notificationType=${type}`);
    },
    [history]
  );
  const isLoading = useSelector((state: RootState) =>
    isLoadingSelector([deleteNotifications.typePrefix], state)
  );
  const { notifications, onGetNotifications, totalUnread } = useNotification();

  useEffect(() => {
    if (loggedIn) {
      onGetNotifications({ page: 1 });
    }
  }, [loggedIn, onGetNotifications]);

  const listNotification = notifications?.slice(0, 3);
  const unReadNotification = (notifications || []).filter(
    (item: ItemNotifications) => !item.isRead
  );

  const sliceUnReadNotification = unReadNotification?.slice(0, 3);
  const handleClearAll = useCallback(() => {
    dispatch(deleteNotifications());
  }, [dispatch]);

  const tabList = [
    {
      id: 0,
      name: t("all"),
    },
    {
      id: 1,
      name: t("unRead"),
    },
  ];

  const renderNotification = (
    listNotification: ItemNotifications[],
    type: string,
    onClose?: any
  ) => {
    return (listNotification || []).map(
      (item: ItemNotifications, index: number) => {
        return (
          <Flex
            key={index}
            borderRadius="8px"
            bg="#1F1F23"
            mb="8px"
            p="16px"
            cursor="pointer"
            onClick={() => {
              handleClickNottifications(type);
              onClose();
            }}
          >
            <Box pt="6px" pr="18px">
              {renderIcon(item.type)}
            </Box>
            <Box>
              <Text
                fontSize="14px"
                w="100%"
                color={item.isRead ? "#535353" : "#FFFFFF"}
              >
                {renderTitle(item.type, item)}
              </Text>
              <Text
                pt="10px"
                color="rgba(255, 255, 255, 0.5)"
                fontSize="12px"
                maxWidth="90%"
              >
                {fromNow(item.createdAt)}
              </Text>
            </Box>
          </Flex>
        );
      }
    );
  };

  return (
    <Box w="35px" className="notification-component">
      {isOpen && <GlobalLoading />}
      <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
        {({ onClose }) => (
          <>
            <PopoverTrigger>
              <Flex position="relative">
                <Button
                  bg="none"
                  _hover={{ bg: "none" }}
                  _active={{ bg: "none" }}
                  p="0px"
                >
                  <Bell />
                </Button>
                {totalUnread > 0 && (
                  <Box position="absolute" right="-2px" top="3px">
                    <Text
                      color="black"
                      lineHeight="11px"
                      fontWeight="700"
                      fontSize="10px"
                      background="#D4F70E"
                      padding={totalUnread < 10 ? "2px 5px" : "2px"}
                      borderRadius="4px"
                    >
                      {totalUnread}
                    </Text>
                  </Box>
                )}
              </Flex>
            </PopoverTrigger>

            <PopoverContent
              background="#24252A"
              borderColor="#24252A"
              className="menu-list-noti"
            >
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                <Flex pt="16px" justifyContent="space-between">
                  <Text
                    pl="16px"
                    fontSize="16px"
                    color=" #FFFFFF"
                    padding="6px 10px"
                  >
                    {t("Notifications")}
                  </Text>
                  {isLoading ? (
                    <Spinner color="#D1F310" mr="20px" />
                  ) : (
                    <Text
                      onClick={handleClearAll}
                      cursor="pointer"
                      pr="16px"
                      color="#D8FD01"
                      fontSize="16px"
                      _hover={{
                        border: "1px solid #38383D !important",
                        padding: "5px 10px",
                      }}
                      borderWidth="1px"
                      borderColor="#24252A"
                      padding="5px 10px"
                      borderRadius="8px"
                    >
                      {t("ClearAll")}
                    </Text>
                  )}
                </Flex>
              </PopoverHeader>
              <PopoverBody overflow="auto">
                <Tabs className="tabs-panels">
                  <TabList className="tab-list-panels">
                    {tabList.map((item) => (
                      <Tab
                        _hover={{
                          background: "#24252A",
                          padding: "6px",
                          border: "1px solid #38383D !important",
                          borderRadius: "8px",
                          borderBottom: "none",
                          color: "white !important",
                        }}
                        padding="6px"
                        borderWidth="1px"
                        key={item.id}
                        borderColor="#24252A !important"
                      >
                        {item.name}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      {renderNotification(
                        listNotification,
                        NOTIFICATION_TYPE.ALL,
                        onClose
                      )}
                      {listNotification?.length === 0 && (
                        <Text m="20px" textAlign="center">
                          {t("NoNotification")}
                        </Text>
                      )}
                    </TabPanel>
                    <TabPanel>
                      {renderNotification(
                        sliceUnReadNotification,
                        NOTIFICATION_TYPE.UNREAD
                      )}
                      {sliceUnReadNotification?.length === 0 && (
                        <Text m="20px" textAlign="center">
                          {t("NoNotification")}
                        </Text>
                      )}
                    </TabPanel>
                  </TabPanels>
                </Tabs>

                <Box
                  m="0px 16px 16px 15px"
                  pt="15px"
                  pb="15px"
                  bg="rgba(255, 255, 255, 0.04)"
                  borderRadius="4px"
                  onClick={() => {
                    handleClickNottifications(NOTIFICATION_TYPE.ALL);
                    onClose();
                  }}
                  cursor="pointer"
                >
                  <Text fontSize="14px" textAlign="center" color="#D4F70E">
                    {t("allNotification")}
                  </Text>
                </Box>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};

export default Notification;
