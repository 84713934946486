import { Collaborator, Licenses, User } from "app/models";
import { TRACK_LICENSE } from "constants/contractType";

const profitSum = (collaborators: any) =>
  collaborators
    .slice(1)
    .reduce((acc: number, item: Collaborator) => acc + item?.profitShare, 0);

const publishingSum = (collaborators: any) => {
  return collaborators
    .slice(1)
    .reduce((acc: number, item: Collaborator) => acc + item?.publishing, 0);
};
export const convertNewCollaborators = (
  collaborators: any,
  isAddSoundkit?: boolean
) => {
  return (collaborators || [])?.map((item: Collaborator, index: number) => {
    const newProfitShare = 100 - profitSum(collaborators);
    const newPublishing = item?.publishing - publishingSum(collaborators);

    if (index === 0) {
      return {
        ...item,
        profitShare: newProfitShare,
        publishing: isAddSoundkit ? 0 : newPublishing,
      };
    }
    return { ...item };
  });
};

export const onHandleGetCollaborations = ({
  contractType,
  licenseWatch,
  collaborators,
  setValue,
  currentUser,
}: {
  contractType: string;
  licenseWatch: Licenses[];
  collaborators: Collaborator[];
  setValue: any;
  currentUser: User;
}) => {
  const currentCollaboration = (collaborators || [])?.find(
    (collaborator: Collaborator) => collaborator.user === currentUser._id
  );

  if (!currentCollaboration) {
    return;
  }
  if (contractType === TRACK_LICENSE.NON_EXCLUSIVE) {
    setValue("collaborators", [
      {
        ...currentCollaboration,
        publishing: 50,
      },
    ]);
  } else if (
    contractType === TRACK_LICENSE.EXCLUSIVE &&
    (licenseWatch || [])?.length
  ) {
    const licensePercent = licenseWatch.find(
      (license: Licenses) => license.exclusive
    );
    const publishing =
      licensePercent?.beatsPublishing ||
      licensePercent?.beatsHookPublishing ||
      licensePercent?.topLinesAndVocals;

    setValue("collaborators", [
      {
        ...currentCollaboration,
        publishing,
      },
    ]);
  } else {
    setValue("collaborators", [
      {
        ...currentCollaboration,
        publishing: 100,
      },
    ]);
  }
};
