import React from "react";

const PreviousIcon = () => {
  return (
    <svg
      width="9"
      height="13"
      viewBox="0 0 9 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99999 10.6489V2.05024C8.99999 1.21709 8.04088 0.749294 7.38434 1.26221L1.98905 5.47726C1.48277 5.87279 1.47548 6.63627 1.9741 7.04141L7.3694 11.4251C8.02291 11.956 8.99999 11.491 8.99999 10.6489Z"
        fill="white"
      />
      <rect width="1.5" height="12.9999" rx="0.749999" fill="white" />
    </svg>
  );
};
export default PreviousIcon;
