import { Box, Text } from "@chakra-ui/react";
import React from "react";
import IconEmptyRecentReferrals from "app/assets/icons/IconEmptyRecentReferrals";
import { useTranslation } from "react-i18next";

export default function EmptyRecentReferrals() {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minH="600px"
    >
      <IconEmptyRecentReferrals />
      <Text mt="20px" fontSize="20px" fontWeight="400" opacity="0.8">
        {t("noRecentReferrals")}
      </Text>
    </Box>
  );
}
