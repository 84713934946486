import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { NoDiscount } from "app/assets/icons";
import Plus from "app/assets/icons/plus";
import { BulkOffers, PromoCode } from "app/models";
import { getBulkOfferById, removeBulkOffer } from "app/redux/actions/bulkOffer";
import { getPromoCodeById, removeProCode } from "app/redux/actions/promoCode";
import { useBulkOffer } from "hooks/bulkOffer/useBulkOffer";
import { usePromoCode } from "hooks/promoCode/usePromoCode";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BulkOfferItem from "./BulkOfferItem";
import ModalActionBulkOffer from "./ModalActionBulkOffer";
import PromoCodeItem from "./PromoCodeItem";
import "./styles.scss";
import UpdateStatusModal from "./UpdateStatusModal";
import { ACTION_TYPE } from "./actionType";
import SkeletonDiscounts from "./skeletonDiscount";
import { useSubscription } from "hooks/subscription/useSubscription";
import { planType } from "constants/planType";
import ToastPlanSubscription from "../toast/toastplan";

export default function Discounts() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    isLoading,
    bulkOffer = [],
    onFetchBulkOffers,
    onDeleteBulkOffer,
    onChangeActiveBulkOffer,
  } = useBulkOffer();

  const {
    promoCodes = [],
    isActivePromoCode,
    onFetchPromoCode,
    onDeletePromoCode,
    onChangeActivePromoCode,
  } = usePromoCode();
  const { activeSubscription } = useSubscription();
  const checkPlanSubscription = useMemo(
    () => activeSubscription?.plan === planType.LIMITLESS,
    [activeSubscription?.plan]
  );

  useEffect(() => {
    onFetchBulkOffers();
  }, [onFetchBulkOffers]);

  useEffect(() => {
    onFetchPromoCode();
  }, [onFetchPromoCode]);

  const handleNewPromoCode = () => {
    if (checkPlanSubscription) {
      ToastPlanSubscription({
        description: t("upgradeYourPlanToContinue"),
      });
    } else {
      history.push("/add-promocode");
      dispatch(removeProCode());
    }
  };

  const handleNewBulkOffer = () => {
    if (checkPlanSubscription) {
      ToastPlanSubscription({
        description: t("upgradeYourPlanToContinue"),
      });
    } else {
      history.push("/add-bulkoffer");
      dispatch(removeBulkOffer());
    }
  };

  const [isActionOpen, setActionOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<BulkOffers | PromoCode>();
  const [isShowModal, setShowModal] = useState<boolean>(false);
  const [actionType, setActionType] = useState<string>();

  const onModalOpen = (type: string, item: PromoCode | BulkOffers) => {
    setActionOpen(true);
    setActionType(type);
    setSelectedItem(item);
  };

  const isPromocodeSelected = useMemo(
    () => !!(selectedItem as PromoCode)?.promoCode,
    [selectedItem]
  );

  const onActionClick = useCallback(() => {
    if (isPromocodeSelected) {
      if (actionType === ACTION_TYPE.EDIT) {
        dispatch(getPromoCodeById(selectedItem?._id));
        history.push(`/edit-promocode/${selectedItem?._id}`);
      } else {
        onDeletePromoCode(selectedItem?._id);
      }
    } else {
      if (actionType === ACTION_TYPE.EDIT) {
        dispatch(getBulkOfferById(selectedItem?._id));
        history.push(`/edit-bulkoffer/${selectedItem?._id}`);
      } else {
        onDeleteBulkOffer(selectedItem?._id);
      }
    }

    setActionOpen(false);
  }, [
    isPromocodeSelected,
    actionType,
    dispatch,
    selectedItem?._id,
    history,
    onDeletePromoCode,
    onDeleteBulkOffer,
  ]);

  const onTogglePromoCode = useCallback(
    (promoCode: PromoCode) => {
      const { _id, active = false, nonExpiring, expirationDate } = promoCode;
      if (isActivePromoCode(active, nonExpiring, expirationDate)) {
        onChangeActivePromoCode({ promoCodeId: _id, active: false });
        return;
      }
      setSelectedItem(promoCode);
      setShowModal(true);
    },
    [isActivePromoCode, onChangeActivePromoCode]
  );
  const onUpdateStatus = useCallback(
    (values) => {
      onChangeActivePromoCode({
        ...values,
        promoCodeId: selectedItem?._id,
        active: true,
      });
      setShowModal(false);
    },
    [onChangeActivePromoCode, selectedItem?._id]
  );

  const renderUIPromocodeBulkOffer = (title: string, isPromocode?: boolean) => {
    return (
      <Box>
        <Text
          position={{ base: "static", md: "sticky" }}
          top="320px"
          background="#18181c"
          zIndex={90}
          fontSize={{ base: "20px", md: "22px", xl: "24px" }}
          fontWeight="500"
          mb="24px"
          fontFamily="Cera Pro Regular"
        >
          {title}
        </Text>
        {isPromocode ? (
          <Box
            bg="#24252A"
            borderRadius="8px"
            minHeight="206px"
            pr={{ base: "10px", md: "15px" }}
            pl={{ base: "15px", md: "25px" }}
          >
            {promoCodes.length > 0 ? (
              promoCodes.map((item, index) => {
                return (
                  <PromoCodeItem
                    key={index}
                    isActivePromoCode={isActivePromoCode}
                    promoCodeItem={item as PromoCode}
                    onChangeActivePromoCode={onTogglePromoCode}
                    onModalPromoCodeOpen={onModalOpen}
                  />
                );
              })
            ) : (
              <Flex justifyContent="center" h="200px" alignItems="center">
                <NoDiscount />
                <Text color="rgba(255, 255, 255, 0.07)" ml="10px">
                  {t("noDiscounts")}
                </Text>
              </Flex>
            )}
            <Flex
              borderTop="1px solid rgba(255, 255, 255, 0.04) "
              mt={promoCodes?.length === 0 ? "0px" : "75px"}
              pt="25px"
              justifyContent="space-between"
            >
              <Text ml="25px" pb="30px">
                {t("createPromo")}
              </Text>
              <Box
                w="28px"
                height="28px"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr="20px"
                cursor="pointer"
                onClick={() => handleNewPromoCode()}
                bg="rgb(255, 247, 0, 0.1)"
              >
                <Plus />
              </Box>
            </Flex>
          </Box>
        ) : (
          <Box
            bg="#24252A"
            borderRadius="8px"
            minHeight="206px"
            pr={{ base: "10px", md: "15px" }}
            pl={{ base: "15px", md: "25px" }}
          >
            {bulkOffer.length > 0 ? (
              bulkOffer.map((bulkOfferItem) => {
                return (
                  <BulkOfferItem
                    key={bulkOfferItem?._id}
                    bulkOfferItem={bulkOfferItem}
                    onChangeActiveBulkOffer={onChangeActiveBulkOffer}
                    onModalBulkOferOpen={onModalOpen}
                  />
                );
              })
            ) : (
              <Flex h="200px" alignItems="center" justifyContent="center">
                <NoDiscount />
                <Text color="rgba(255, 255, 255, 0.07)" ml="10px">
                  {t("noBulkOffers")}
                </Text>
              </Flex>
            )}
            <Flex
              borderTop="1px solid rgba(255, 255, 255, 0.04) "
              mt={bulkOffer.length === 0 ? "0px" : "75px"}
              pt="22px"
              pb="30px"
              justifyContent="space-between"
            >
              <Text ml="25px"> {t("NewBulkOffer")}</Text>
              <Box
                w="28px"
                height="28px"
                bg="rgb(255, 247, 0, 0.1)"
                borderRadius="50%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr="20px"
                cursor="pointer"
                onClick={() => handleNewBulkOffer()}
              >
                <Plus />
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      <Flex
        alignItems="center"
        position={{ base: "static", md: "sticky" }}
        top="228px"
        zIndex={90}
        height="90px"
        background="#18181c"
      >
        <Text fontSize={{ base: "24px", md: "28px", xl: "32px" }}>
          {t("Discounts")}
        </Text>
      </Flex>
      {isLoading ? (
        <SkeletonDiscounts />
      ) : (
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 10, md: 5 }}>
          {renderUIPromocodeBulkOffer(t("PromoCode"), true)}
          {renderUIPromocodeBulkOffer(t("BulkOffer"))}
        </SimpleGrid>
      )}

      <ModalActionBulkOffer
        isPromocodeSelected={isPromocodeSelected}
        isOpen={isActionOpen}
        onActionClick={onActionClick}
        setOpen={setActionOpen}
        isEdit={actionType === ACTION_TYPE.EDIT}
      />
      {isShowModal && (
        <UpdateStatusModal
          isShowModal={isShowModal}
          setShowModal={setShowModal}
          item={selectedItem as PromoCode}
          onUpdateStatus={onUpdateStatus}
        />
      )}
    </Box>
  );
}
