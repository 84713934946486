import { Box, Flex, Divider } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function SkeletonFilterSearch() {
  return (
    <Box
      background="rgba(36, 37, 42, 0.30)"
      padding={{ base: "10px", md: "10px", xl: "20px 25px" }}
      borderRadius="8px"
      overflow="scroll"
      height={{ base: "30vh", md: "70vh" }}
    >
      <Flex
        justifyContent="space-between"
        alignItems="center"
        mb="20px"
        px={{ base: "16px" }}
      >
        <Skeleton
          w={{ base: "100px" }}
          h={{ base: "16px", md: "23px" }}
          mr="0px"
        />
        <Skeleton w="45px" h="13px" mr="0px" />
      </Flex>
      {Array.from({ length: 7 }).map((item, index) => (
        <>
          <Flex
            justifyContent="space-between"
            padding="25px 0px"
            px={{ base: "16px" }}
            key={index}
          >
            <Flex>
              <Skeleton w="23px" h="13px" borderRadius="2px" />
              <Skeleton w="76px" h="13px" />
            </Flex>
            <ChevronDownIcon color="#49494d" />
          </Flex>
          <Divider borderColor="#49494d" />
        </>
      ))}
    </Box>
  );
}
