import { isEmpty } from "ramda";

/**
 * [setSessionStorage description]
 */
const setSessionStorage = (key: string, value: any) => {
  sessionStorage.setItem(key, JSON.stringify(value));
};

/**
 * [getSessionStorage description]
 */
const getSessionStorage = (key: string) => {
  return JSON.parse(sessionStorage.getItem(key) || "{}");
};

/**
 * [removeSessionStorage description]
 */
const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key);
};

/**
 * [getJwtSessionStorage description]
 */
const getJwtSessionStorage = () => {
  const jwt = getSessionStorage("jwt");
  return !isEmpty(jwt) ? jwt : null;
};

export {
  setSessionStorage,
  getSessionStorage,
  removeSessionStorage,
  getJwtSessionStorage,
};
