import {
  Box,
  Flex,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import Minus from "app/assets/icons/minus";
import Plus from "app/assets/icons/plus";
import { LoopSampleType } from "constants/index";
import React, { useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import InputLoopSampleController from "./InputLoopSample";
import "./styles.scss";

export default function LoopAndSamples() {
  const { t } = useTranslation();
  const {
    formState: { errors },
    control,
    watch,
    setValue,
  } = useFormContext();
  const typeLoopSample = watch("typeLoopSample");
  const loopAndSamplesType = watch("data");
  const notCompletedItem = useMemo(() => {
    return loopAndSamplesType?.find(
      (item: { nameLoopSample: string; whereLoopSample: string }) =>
        !item?.nameLoopSample || !item?.whereLoopSample
    );
  }, [loopAndSamplesType]);

  useEffect(() => {
    if (JSON.parse(typeLoopSample)) {
      setValue("data", [
        {
          id: 0,
          nameLoopSample: "",
          whereLoopSample: "",
        },
      ]);
    }
  }, [typeLoopSample]);

  useEffect(() => {
    if (loopAndSamplesType?.length === 0) {
      setValue("typeLoopSample", "false");
    }
  }, [loopAndSamplesType]);

  const handleAddLoopSample = useCallback(() => {
    setValue("data", [
      ...loopAndSamplesType,
      {
        id: loopAndSamplesType.length,
        nameLoopSample: "",
        whereLoopSample: "",
      },
    ]);
  }, [loopAndSamplesType]);

  const handleRemoveLoopSample = useCallback(
    (id: number) => {
      const newData = [...loopAndSamplesType].filter(
        (item: { id: number }) => item.id !== id
      );
      setValue("data", [...newData]);
    },
    [loopAndSamplesType]
  );

  const handleValueInput = useCallback(
    (item: { id: number }, key: string) => {
      return loopAndSamplesType.find(
        (it: { id: number }) => it.id === item.id
      )?.[key];
    },
    [loopAndSamplesType]
  );

  return (
    <Box mt="30px">
      <Text fontSize="20px" mb="28px">
        {t("loopAndSample")}
      </Text>
      <Controller
        name={"typeLoopSample"}
        render={({ field }) => {
          return (
            <RadioGroup {...field}>
              <SimpleGrid columns={{ base: 2, md: 3 }} spacing="15px">
                <Box className={"form-input radio-loop"}>
                  <Radio ml="20px" value="false">
                    {t("no")}
                  </Radio>
                </Box>
                <Box className={"form-input radio-loop"}>
                  <Radio ml="20px" value="true">
                    {t("yes")}
                  </Radio>
                </Box>
              </SimpleGrid>
            </RadioGroup>
          );
        }}
        control={control}
      />
      <Controller
        name="data"
        control={control}
        render={({ field }) => (
          <>
            {JSON.parse(typeLoopSample) &&
              (loopAndSamplesType || []).map((item: any, index: string) => {
                return (
                  <Box key={item.id}>
                    <Flex w="100%">
                      <SimpleGrid
                        w="100%"
                        columns={{ base: 2, md: 3 }}
                        spacing="15px"
                      >
                        <Box>
                          <Text color=" #535353">{t("nameSample")}</Text>
                          <InputLoopSampleController
                            value={handleValueInput(
                              item,
                              LoopSampleType.NAME_LOOP
                            )}
                            item={item}
                            field={field}
                            loopAndSamplesType={loopAndSamplesType}
                            name="nameLoopSample"
                          />
                          {errors.data && (
                            <Text color="red">
                              {t(errors.data[index]?.nameLoopSample?.message)}
                            </Text>
                          )}
                        </Box>
                        <Box>
                          <Text color=" #535353">{t("thisSample")}</Text>
                          <InputLoopSampleController
                            value={handleValueInput(
                              item,
                              LoopSampleType.WHERE_LOOP
                            )}
                            item={item}
                            field={field}
                            loopAndSamplesType={loopAndSamplesType}
                            name="whereLoopSample"
                          />
                          {errors.data && (
                            <Text color="red">
                              {t(errors.data[index]?.whereLoopSample?.message)}
                            </Text>
                          )}
                        </Box>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Flex>
                            {!notCompletedItem && (
                              <Box
                                className="icon-loop"
                                onClick={handleAddLoopSample}
                                mr="10px"
                              >
                                <Plus isLoopAndSample />
                              </Box>
                            )}
                            <Box
                              className="icon-loop"
                              onClick={() => handleRemoveLoopSample(item.id)}
                            >
                              <Minus />
                            </Box>
                          </Flex>
                          <Text>{t("addSample")}</Text>
                        </Box>
                      </SimpleGrid>
                    </Flex>
                  </Box>
                );
              })}
          </>
        )}
      />
    </Box>
  );
}
