import { Text } from "@chakra-ui/react";
import React from "react";

interface Props {
  title: string;
  description: string;
}

export default function AuthText({ title = "", description = "" }: Props) {
  return (
    <>
      <Text
        fontWeight="600"
        fontSize={{ base: "26px", md: "28px", lg: "30px" }}
        lineHeight="40px"
      >
        {title}
      </Text>
      <Text
        fontWeight="600"
        fontSize={{ base: "14px", md: "16px" }}
        mt={{ base: "4px", md: "6px", lg: "10px" }}
      >
        {description}
      </Text>
    </>
  );
}
