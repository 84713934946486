import React, { useEffect } from "react";
import { Box } from "@chakra-ui/react";

export default function TermConditions() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <Box
      padding={{ base: "0px", md: "0px 20px", xl: "20px 80px" }}
      className="term-condition"
      width="-webkit-fill-available"
      height="100%"
    >
      <div
        name="termly-embed"
        data-id="1013e820-f73b-4df3-9b79-a5472a83cb35"
        data-type="iframe"
      ></div>
    </Box>
  );
}
