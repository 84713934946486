import React from "react";

const ImageUploadCoverPlaylist = () => {
  return (
    <svg
      width="126"
      height="97"
      viewBox="0 0 126 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.5"
        d="M123 95.7067H3C1.89543 95.7067 1 94.8112 1 93.7067V28.5695C1 27.465 1.89543 26.5695 3 26.5695H65.003C65.7929 26.5695 66.5088 26.1046 66.83 25.383L69.5889 19.1865C69.9102 18.4649 70.6261 18 71.416 18H123C124.105 18 125 18.8954 125 20V93.7067C125 94.8112 124.105 95.7067 123 95.7067Z"
        fill="url(#paint0_linear_4537_8221)"
        stroke="url(#paint1_linear_4537_8221)"
        strokeWidth="0.5"
      />
      <path
        d="M123 96.2287H3C1.75736 96.2287 0.75 95.2214 0.75 93.9787V3C0.75 1.75736 1.75736 0.75 3 0.75H54.9335C55.7484 0.75 56.4996 1.1906 56.8973 1.90183L59.5676 6.677C59.877 7.23017 60.4613 7.57286 61.095 7.57286H123C124.243 7.57286 125.25 8.58022 125.25 9.82286V93.9787C125.25 95.2214 124.243 96.2287 123 96.2287Z"
        stroke="url(#paint2_linear_4537_8221)"
        strokeWidth="0.5"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4537_8221"
          x1="63"
          y1="18"
          x2="63"
          y2="95.7067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="0.166667" stopColor="#535353" />
          <stop offset="0.333333" stopColor="#535353" />
          <stop offset="0.5" stopColor="#535353" />
          <stop offset="0.666667" stopColor="#535353" stopOpacity="0.666941" />
          <stop offset="0.833333" stopColor="#535353" stopOpacity="0.454995" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4537_8221"
          x1="63"
          y1="18"
          x2="63"
          y2="95.7067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4537_8221"
          x1="63"
          y1="1"
          x2="63"
          y2="95.9787"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#535353" />
          <stop offset="1" stopColor="#535353" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ImageUploadCoverPlaylist;
