import { Flex, Checkbox, Box } from "@chakra-ui/react";
import React from "react";
import Skeleton from "app/components/common/Skeleton/skeleton";

export default function SkeletonMessagesPage() {
  return (
    <>
      {Array.from({ length: 6 }).map((item, index) => (
        <Flex key={index} mb="20px">
          <Checkbox
            borderRadius="4px"
            _focusVisible={{ outline: "none" }}
            size="lg"
          />
          <Flex
            w="100%"
            py="15px"
            px={{ base: "8px", md: "12px", xl: "12px" }}
            background="#1F1F23"
            ml="25px"
            gap={{ base: "8px", md: "12px", xl: "20px" }}
          >
            <Box>
              <Skeleton w="48px" h="48px" borderRadius="full" />
            </Box>

            <Flex w="100%" justifyContent="space-between">
              <Box>
                <Flex alignItems="center" mb="10px">
                  <Skeleton w="8px" h="8px" borderRadius="full" />
                  <Skeleton w="65px" h="16px" />
                </Flex>
                <Skeleton
                  w={{ base: "150px", md: "250px", xl: "382px" }}
                  h="16px"
                  ml="18px"
                />
              </Box>

              <Box>
                <Skeleton w="65px" h="16px" />
              </Box>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </>
  );
}
