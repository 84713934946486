import React from "react";

const IconRepeatFeed = () => {
  return (
    <svg
      width="27"
      height="24"
      viewBox="0 0 27 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.875 22L3.375 18L7.875 14V17H19.125V13H21.375V18C21.375 18.5523 20.8713 19 20.25 19H7.875V22ZM7.875 11H5.625V6C5.625 5.44772 6.12868 5 6.75 5H19.125V2L23.625 6L19.125 10V7H7.875V11Z"
        fill="#D4F70E"
      />
    </svg>
  );
};
export default IconRepeatFeed;
